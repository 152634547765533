// dateUtils.js
import moment from 'moment';
import 'moment-timezone';

/**
 * Convert a server-provided date string to the local time format.
 * @param {string} serverDateString - The server date string to convert.
 * @param {string} [format='YYYY-MM-DD hh:mm:ss A'] - The format to use for output.
 * @returns {string} The formatted date string in the local time zone.
 */
export function formatServerDateToLocal(serverDateString, format = 'DD MMMM YYYY HH:mm') {
  // Parse the server date string into a moment object
  const localDate = moment.tz(serverDateString, moment.tz.guess());

  // Format the date in the local time zone
  return localDate.format(format);
}
export function ConverttolocalwithStroke(item) {
  if (item != "-" && item != null && item != "") {
    let todate = new Date(Date.parse(item));
    let timezone = todate.getTimezoneOffset() * 60000;
    let newDate = new Date(todate.getTime() - timezone);
    let output = "";
    let inhours = newDate.getHours();
    let inmins = newDate.getMinutes();
    output = 
      newDate.getUTCFullYear() +
      "/" +
      padZero(newDate.getUTCMonth() + 1) +
      "/" +
      padZero(newDate.getUTCDate()) +
      " " +
      padZero(inhours) +
      ":" +
      padZero(inmins);
    return output;
  } else {
    return "-";
  }
}
function padZero(value) {
  return value < 10 ? "0" + value : value;
}
