<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" class="mt-6 ml-1" @click="back()" style="cursor: pointer;">
        <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
      </v-btn>
      <p class="ml-4 headtext txt-header">
        {{ $t("Back") }}
      </p>
    </div>
    
    <v-card class="pt-4" style="margin-bottom: 5rem; margin-left: 1.5rem; margin-right: 1.5rem; padding-bottom: 20px;">
      <v-row class="d-flex py-3">
        <v-col class="d-flex justify-start" cols="6" xl="8" lg="8" md="9" sm="6">
          <v-img :src="annouimages.announcement" class="alerticon ml-5 mt-1"></v-img>
          <p class="ml-4 mt-1" style="font-size: 25px; color: #444444;">
            {{ Announcementdetail.title }}
          </p>
        </v-col>
        <v-col class="d-flex justify-end mt-2" cols="6" xl="4" lg="4" md="3" sm="6">
          <v-img src="@/assets/images/preview-svgrepo-com 1.svg" style="cursor: pointer;" class="alerticon mr-5" @click="mobileview()"></v-img>
          <div  class="mr-5"
            :style="{ backgroundColor: Announcementdetail.announcement_Status == 'Expired' ? '#FFCCCC' : Announcementdetail.announcement_Status == 'Waiting' ? '#FFEACD' : '#CCF3E9',
              borderRadius: '20px',
              padding: '5px',
              width: '150px',
              height: '35px',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }">
            <p class="pt-4" :style="{color:Announcementdetail.announcement_Status == 'Expired' ? '#FF4B55' : Announcementdetail.announcement_Status == 'Waiting'? '#F89D1F': '#00C092',
              justifyContent: 'center',display: 'contents'}">
              {{ Announcementdetail.announcement_Status == "Expired" ? $t("Expired") : Announcementdetail.announcement_Status == "Waiting" ? $t("Waiting") : $t("announcement")}}
            </p>
          </div>
        </v-col> 
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="image-wrapper">
            <img
              v-if="imageUrl"
              :src="imageUrl"
              @click="zoom(imageUrl)"
              class="annouimage" />
          </div>
        </v-col>
      </v-row>
      <v-card-text class="px-15">
        <v-row class="ml-3 mr-3">
          <v-list-item-content>
            <v-list-item-title class="myfont">
              {{ Announcementdetail.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-row>
        <v-row class="ml-3 mr-3">
          <v-list-item-content class="py-0">
            <p class="mt-3 textcolor"
              style="word-break: break-word"
              v-html="Announcementdetail.detail">
            </p>
          </v-list-item-content>
        </v-row>
        <v-row class="ml-3 mr-3 mb-5">
          <v-list-item-content>
            <v-list-item-title class="myfont" v-if="Announcementdetail.attachment.length != 0">{{
              $t("attachment")
            }}</v-list-item-title>
            <v-list-item-subtitle class="my-5 textcolor d-flex">
              <div v-for="file in pdfAttachments" :key="file.attachment_Name" class="d-flex" style="
                cursor: pointer;
                position: relative;"
              >
                <v-img class="mb-2 mx-2" src="@/assets/images/pdf.png" width="40px" height="40px"> 
                </v-img>
                <a :href="file.attachment_Url" class="mt-3" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">
                  <span style="color: #000000;">{{ file.attachment_Name }}</span>
                </a>
              </div>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="mt-3 textcolor">
              <div>
                <swiper :options="swiperOptions" ref="mySwiper">
                  <swiper-slide v-for="(image, index) in filteredAttachments" :key="index">
                    <div class="slidecontainer" style="background-color: #D9D5D542;">
                      <img :src="image.attachment_Url" :alt="image.attachment_Name" @click="zoom(image.attachment_Url)" class="swiperimg">
                    </div>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div v-if="showNextButton && filteredAttachments.length >= 4" class="swiper-button-next" @click="slideNext" slot="button-next"></div>
                  <div v-if="showPrevButton" class="swiper-button-prev" @click="slidePrev" slot="button-prev"></div>
                </swiper>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-row>
        
        <v-row class="mx-3 mt-4">
          <v-list-item-avatar>
            <v-img :src="profileimageUrl ? profileimageUrl : images.admin"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="black--text">{{ profileimageUrl ? Announcementdetail.profilename  : "Optimistic Product Admin" }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ Announcementdetail.createdate }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-row>
      </v-card-text>

      <v-card-text class="px-15">
        <v-divider></v-divider>
        <v-row class="mt-1" v-if="showcomment == false">
          <v-col cols="1" lg="1" md="2" sm="3" class="d-flex ">
            <v-list-item-avatar style="bottom:23%; margin-left: 20px;">
              <v-img :src="$store.state.profileimage != null
                        ? $store.state.profileimage
                        :  images.admin
                        "></v-img>
            </v-list-item-avatar>
          </v-col>
          <v-col cols="10" lg="11" md="10" sm="9" class="pl-0">
            <v-text-field placeholder="Write a comment" color="#F99D20" v-model="requestcomment" outlined>
            </v-text-field>
            <div class="d-flex justify-end">
              <v-btn class="mr-2 text-capitalize" style="color: #3C4043!important; border-color: rgba(0, 0, 0, 0.12)!important;" outlined width="80" max-height="35" @click="clear()">
                {{ $t("Cancel") }}
              </v-btn>
              <v-btn color="#F99D20" class="white--text text-capitalize" max-height="35" width="80" @click="Addcomment()">
                {{ $t("Send") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="tempimport.length != 0">
          <span style="font-size: 20px;" class="ml-5">
            {{ $t("Comments")}} ({{ tempimport.length }})
          </span>
        </v-row>
        <v-row class="mr-3 mb-2" v-for="item in tempimport" :key="item.comment_ID"> 
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="12" md="11" sm="10" class="pr-0 pb-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img :src="item.image ? item.image : images.admin"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      item.isAdmin
                        ? item.name
                          ? item.name + " " + item.lastName
                          : " "
                        : "Optimistic Product Admin"}}
                        <span style="color:#7D7A7A; font-size: 14px;">{{ item.timeDifference }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <div style="float: left; max-width: 6ch00px">
                        <p style="font-size: 16px" class="mb-0">{{ item.comment }}</p>
                      </div>
                    </v-list-item-subtitle>
                    <v-spacer></v-spacer>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div style="height: auto" class="d-flex">
                      <div
                        v-show="item.hideeditanddel && item.image"
                        style="float: left; margin-left: 30px"
                      >
                        <v-btn icon @click="EditThisComment(item.countcomment)">
                          <img class="editIcon" :src="annouimages.edit" />
                        </v-btn>
                      </div>
                      <div v-show="item.hideeditanddel && item.image">
                        <v-btn icon @click="deletethiscomment(item.countcomment)">
                          <img class="deleteIcon" :src="annouimages.delete" />
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row v-if="item.hideedit == true" class>
              <v-col cols="12" class="pb-0 d-flex justify-end" style="margin-left: 25px">
                <v-textarea
                  solo
                  height="80"
                  v-model="item.comment"
                  name="input-7-4"
                  class="mr-3"
                ></v-textarea>
                <div>
                  <v-btn
                    width="100px"
                    text
                    outlined
                    class="text-capitalize mr-2"
                    @click="hideeditpost(item.countcomment)"
                    >{{ $t("Cancel") }}</v-btn
                  >
                  <v-btn
                    width="100px"
                    color="#F99D20"
                    class="text-capitalize white--text"
                    @click="editpost(item.countcomment)"
                    >{{ $t("Save") }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row v-if="item.hidedelete == true" class="ml-3 pt-0">
              <v-col cols="12" class="px-0">
                <p>{{ item.comment }}</p>
              
              <div class="d-flex justify-end">
                <v-btn
                  width="100px"
                  text
                  outlined
                  class="text-capitalize mr-2"
                  @click="hideeditpost(item.countcomment)"
                  >{{ $t("Cancel") }}</v-btn
                >
                <v-btn
                  width="100px"
                  color="#FF4B55"
                  text
                  class="text-capitalize"
                  @click="Deletepost(item.comment_ID)"
                  >{{ $t("delete") }}</v-btn
                >
              </div>
            </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <v-dialog v-model="ImageDialog" persistent width="600">
      <v-card class="pb-4">
        <v-card-title>
          <span> </span>
          <v-spacer></v-spacer>
          <v-btn @click="ImageDialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10" md="10">
              <div v-if="selectedImage" style="background-color:#7D7A7A ;">
                <img
                  :src="selectedImage"
                  alt=""
                  contain
                  aspect-ratio="2"
                  width="100%"
                  height="100%"
                  style="
                    background: #ffffff;
                    color: #707070;
                    font-size: 25px;
                    margin-left: auto;
                    margin-right: auto;
                  "
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mobileImageDialog" persistent width="300">
      <v-card class="pb-4">
        <v-card-title>
          <span> </span>
          <v-spacer></v-spacer>
          <v-btn @click="mobileImageDialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="12">
              <div v-if="selectedImage">
                <img
                  :src="selectedImage"
                  alt=""
                  contain
                  aspect-ratio="2"
                  width="100%"
                  height="100%"
                  style="
                    background: #ffffff;
                    color: #707070;
                    margin-left: auto;
                    margin-right: auto;
                  "
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Mobileview dialog -->
    <v-dialog v-model="mobiledialog" persistent width="400" class="mobileviewdialog">
      <v-card style="overflow: hidden ;">
        <v-card-title class="py-1 px-2">
          <span> </span>
          <v-spacer></v-spacer>
          <v-btn @click="mobiledialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
          <v-row class="d-flex justify-space-between">
            <v-col cols="2">
              <v-btn icon color="white" @click="mobiledialog = false" class="ml-2">
              <img src="@/assets/images/back button.png" />
            </v-btn>
            </v-col>
            <v-col cols="7" class="d-flex justify-center">
              <p style="font-size: 18px;" class="mt-2">
              {{ $t("announcements") }}
            </p>
            </v-col>
            <v-col cols="3" class="d-flex">
              <v-img src="@/assets/images/circle-minus-minor 1.svg" class="mobileicon" @click="decreaseFontSize" style="margin-top: 10px;"></v-img>
              <span style="font-size: 14px; margin-top: 10px;">A</span>
              <span style="font-size: 20px; margin-top: 3px;">A</span>
              <v-img src="@/assets/images/circle-plus 1.svg" style="margin-top: 10px;" @click="increaseFontSize" class="mobileicon"></v-img>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
          <v-col class="d-flex justify-center">
            <div class="image-wrapper1">
              <img
                v-if="imageUrl"
                :src="imageUrl"
                @click="zoom(imageUrl)"
                class="mobileimage" />
            </div>
          </v-col>
          </v-row>
          <v-card-title class="mobiletitle" style="color: #21A8D6;" :style="{ fontSize: currentFontSize + 'px',}">
            {{ Announcementdetail.title }}
          </v-card-title>
          <v-row class="ml-5 mr-3">
          <v-list-item-content>
            <p
              class="mt-3 textcolor mb-0"
              :style="{ fontSize: currentFontSize + 'px',}"
              style="word-break: break-word"
              v-html="Announcementdetail.detail"
            ></p>
          </v-list-item-content>
        </v-row>
        <v-row class="ml-3 mr-3 mb-5">
          <v-list-item-content>
            <v-list-item-title class="myfont" v-if="Announcementdetail.attachment.length != 0">{{
              $t("attachment")
            }}</v-list-item-title>
            <v-list-item-subtitle class="my-5 textcolor">
              <div v-for="file in pdfAttachments" :key="file.attachment_Name" class="d-flex mb-2" style="
                width: 150px;
                cursor: pointer;
                position: relative;"
              >
                <v-img class="mb-2 mx-2" src="@/assets/images/pdf.png" width="40px" height="40px"> 
                </v-img>
                <a :href="file.attachment_Url" class="mt-3" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">
                  <span style="color: #000000;">{{ file.attachment_Name }}</span>
                </a>
              </div>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="mt-3 textcolor">
              <div>
                <swiper :options="mobileswiperOptions" ref="mymobileSwiper">
                  <swiper-slide v-for="(image, index) in filteredAttachments" :key="index">
                    <div class="slidecontainer">
                      <img :src="image.attachment_Url" @click="zoommobile(image.attachment_Url)" :alt="image.attachment_Name" class="swiperimg">
                    </div>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div v-if="showNextmobileButton && filteredAttachments.length > 1" style="background-size:15px!important;" class="swiper-button-next" @click="mobileslideNext" slot="button-next"></div>
                  <div v-if="showPrevmobileButton" style="background-size:15px!important;" class="swiper-button-prev" @click="mobileslidePrev" slot="button-prev"></div>
                </swiper>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-row>

        <v-row class="mx-5 my-4">
          <v-list-item-avatar>
            <v-img
              :src="profileimageUrl ? profileimageUrl : images.admin"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="black--text" :style="{ fontSize: currentFontSize + 'px',}">
              {{profileimageUrl ? Announcementdetail.profilename : "Optimistic Product Admin"}}
            </v-list-item-title>
            <v-list-item-subtitle :style="{ fontSize: currentFontSize + 'px',}">{{ Announcementdetail.createdate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-row>
        
        <v-container class="px-3">
        <v-divider></v-divider>
        <v-row class="mt-1" v-if="showcomment == false">
          <v-col cols="1" lg="3" md="2" sm="3" class="d-flex justify-end">
            <v-list-item-avatar style="bottom:23%;" class="mr-7">
              <v-img :src="$store.state.profileimage != null
                        ? $store.state.profileimage
                        :  images.admin
                        "></v-img>
            </v-list-item-avatar>
          </v-col>
          <v-col cols="10" lg="9" md="10" sm="9" class="pl-0">
            <v-text-field placeholder="Write a comment" color="#F99D20" dense v-model="requestcomment" outlined>
            </v-text-field>
            <div class="d-flex justify-end">
              <v-btn color="darken-1" style="color:#3C4043!important; border-color: rgba(0, 0, 0, 0.12)!important;" class="mr-2 text-capitalize" dense outlined width="80" @click="clear()">
                {{ $t("Cancel") }}
              </v-btn>
              <v-btn color="#F99D20" class="white--text text-capitalize" dense width="80" @click="Addcomment()">
                {{ $t("Send") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="tempimport.length != 0">
          <span :style="{ fontSize: currentFontSize + 'px',}" class="ml-5">
            {{ $t("Comments")}} ({{ tempimport.length }})
          </span>
        </v-row>
        <v-row class="mb-2" v-for="item in tempimport" :key="item.comment_ID"> 
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="12" md="11" sm="10" class="pl-1 pb-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img :src="item.image ? item.image : images.admin"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title :style="{ fontSize: currentFontSize + 'px',}">{{
                      item.isAdmin
                        ? item.name
                          ? item.name + " " + item.lastName
                          : " "
                        : "Optimistic Product Admin"}}
                        <span style="color:#7D7A7A; font-size: 14px;" :style="{ fontSize: currentFontSize + 'px',}">{{ item.timeDifference }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <div style="float: left; max-width: 6ch00px">
                        <p class="mb-0" :style="{ fontSize: currentFontSize + 'px',}">{{ item.comment }}</p>
                      </div>
                    </v-list-item-subtitle>
                    <v-spacer></v-spacer>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div style="height: auto" class="d-flex">
                      <div
                        v-show="item.hideeditanddel && item.image"
                        style="float: left; margin-left: 30px"
                      >
                        <v-btn icon @click="EditThisComment(item.countcomment)">
                          <img class="editIcon" :src="annouimages.edit" />
                        </v-btn>
                      </div>
                      <div v-show="item.hideeditanddel && item.image">
                        <v-btn icon @click="deletethiscomment(item.countcomment)">
                          <img class="deleteIcon" :src="annouimages.delete" />
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row v-if="item.hideedit == true" class>
              <v-col cols="12" class="pb-0" >
                <v-textarea
                  solo
                  height="60"
                  v-model="item.comment"
                  name="input-7-4"
                  class="mr-3"
                  :style="{ fontSize: currentFontSize + 'px',}"
                ></v-textarea>
                <div class="d-flex justify-end">
                  <v-btn
                    width="80px"
                    dense
                    text
                    outlined
                    class="text-capitalize mr-2"
                    color="darken-1"
                    @click="hideeditpost(item.countcomment)"
                    >{{ $t("Cancel") }}</v-btn
                  >
                  <v-btn
                    width="80px"
                    dense
                    color="#F99D20"
                    class="text-capitalize commonsavebtn"
                    @click="editpost(item.countcomment)"
                    >{{ $t("Save") }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row v-if="item.hidedelete == true" class=" pt-1">
              <v-col cols="12">
                <p style="margin-left: 10px;" :style="{ fontSize: currentFontSize + 'px',}">{{ item.comment }}</p>
              
              <div class="d-flex justify-end">
                <v-btn
                  width="80px"
                  dense
                  text
                  outlined
                  max-height="30"
                  class="text-capitalize mr-2"
                  @click="hideeditpost(item.countcomment)"
                  >{{ $t("Cancel") }}</v-btn
                >
                <v-btn
                  width="80px"
                  max-height="30"
                  color="#FF4B55"
                  text
                  class="text-capitalize"
                  @click="Deletepost(item.comment_ID)"
                  >{{ $t("delete") }}</v-btn
                >
              </div>
            </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      </v-card>
    </v-dialog>

    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import { formatServerDateToLocal } from '@/plugins/toLocal.js';
import LoadingDialog from "@/components/Loading.vue";


export default {
  components: {
    LoadingDialog,
  },
  data() {
    return {
      url: enurl.apiUrl,
      loadingdialog: false,
      Announcementdetail: {
        title: "",
        detail: null,
        expirydate: "",
        startdate: "",
        disablecomment: false,
        profilename: "",
        createdate: "",
        attachment: [],
        announcement_Status: "",
      },
      ImageDialog: false,
      mobileImageDialog: false,
      showeditcomment: true,
      editcomment: false,
      deletecomment: false,
      editanddeletebtn: store.state.employeeID,
      updatepost: "",
      showpost: true,
      selectedImage: null,
      imageUrl: "",
      profileimageUrl: "",
      requestcomment: "",
      showcomment: true,
      Detaillist: [],
      Commentlist: [],
      commentdetail: {
        date: "",
        title: "",
        commentUrl: "",
        comment: "",
      },
      images: {
        admin: require("@/assets/images/Group 3166.png"),
        editpic: require("@/assets/images/Mask Group 178.png"),
        deletepic: require("@/assets/images/Mask Group 177.png"),
      },
      tempimport: [],
      mobiledialog: false,
      mobilefontSizes: [14, 16, 18],
      currentIndex: 1,
      showPrevButton: false,
      showPrevmobileButton: false,
      showNextmobileButton: true,
      showNextButton: true,
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          init: this.onSlideChange,
          slideChange: this.onSlideChange
        }
      },
      mobileswiperOptions: {
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          init: this.onmobileSlideChange,
          slideChange: this.onmobileSlideChange
        }
      },
      empComment:'',
    };
  },
  computed:{
    annouimages() {
      return this.$store.state.images;
    },
    currentFontSize() {
      return this.mobilefontSizes[this.currentIndex];
    },
    filteredAttachments() {
      return this.Announcementdetail.attachment.filter(attachment => {
        const extension = attachment.attachment_Url.split('.').pop();
        return ['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase());
      });
    },
    pdfAttachments() {
      return this.Announcementdetail.attachment.filter(attachment => {
        const extension = attachment.attachment_Url.split('.').pop().toLowerCase();
        return extension === 'pdf';
      });
    }
  },
  // watch: {
  //   '$refs.mySwiper.swiper': {
  //     handler(swiper) {
  //       this.updateButtonVisibility();
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },

  mounted() {
    this.getDetail();
    this.getComment();
    this.viewCount();
    this.onSlideChange();
  },

  methods: {
    back() {
      this.$router.push({ name: "home" });
    },
    clear(){
      this.requestcomment = '';
    },
    mobileview(){
      this.mobiledialog = true;
    },

    formatTimeDifference(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diff = now - date;    
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      if (seconds < 60)
      {
        return 'right now';
      }
      else if (minutes < 60)
      {
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      }
      else if (hours < 24)
      {
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      }
      else if (days <= 28)
      {
        return `${days} day${days !== 1 ? 's' : ''} ago`;
      }
      else
      {
        return date.toLocaleDateString();
      }
    },

    onSlideChange() {
      const swiper = this.$refs.mySwiper.swiper
      this.showPrevButton = swiper.activeIndex !== 0
      this.showNextButton = swiper.activeIndex !== swiper.slides.length - swiper.params.slidesPerView
    },
    onmobileSlideChange(){
      const swiper = this.$refs.mymobileSwiper.swiper
      this.showPrevmobileButton = swiper.activeIndex !== 0
      this.showNextmobileButton = swiper.activeIndex !== swiper.slides.length - swiper.params.slidesPerView
    },
    slideNext() {
      this.$refs.mySwiper.swiper.slideNext();
      this.updateButtonVisibility();
    },
    slidePrev() {
      this.$refs.mySwiper.swiper.slidePrev();
      this.updateButtonVisibility();
    },
    mobileslideNext() {
      this.$refs.mymobileSwiper.swiper.slideNext();
      this.updatemobileButtonVisibility();
    },
    mobileslidePrev() {
      this.$refs.mymobileSwiper.swiper.slidePrev();
      this.updatemobileButtonVisibility();
    },
    updateButtonVisibility() {
      const swiper = this.$refs.mySwiper.swiper;
      this.showPrevButton = swiper.activeIndex !== 0;
      this.showNextButton =
        swiper.activeIndex !== swiper.slides.length - swiper.params.slidesPerView;
    },
    updatemobileButtonVisibility() {
      const swiper = this.$refs.mymobileSwiper.swiper;
      this.showPrevmobileButton = swiper.activeIndex !== 0;
      this.showNextmobileButton =
        swiper.activeIndex !== swiper.slides.length - swiper.params.slidesPerView;
    },

    
    increaseFontSize() {
      if (this.currentIndex < this.mobilefontSizes.length - 1)
      {
        this.currentIndex++;
      }
    },

    decreaseFontSize() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },

    zoom(url) {
      this.ImageDialog = true;
      this.selectedImage = url;
    },
    zoommobile(url) {
      this.mobileImageDialog = true;
      this.selectedImage = url;
    },
    changecmt() {
      this.showpost = false;
    },

    hidepost() {
      this.showpost = true;
      this.requestcomment = "";
    },

    EditThisComment(id) {
      let self = this;
      let i = id;
      self.tempimport[i].hidedefault = false;
      self.tempimport[i].hideedit = true;
      self.tempimport[i].hidedelete = false;
      self.updatepost = self.tempimport[i].comment;
    },

    editpost(id) {
      let self = this;
      let i = id;
      let temp = {
        comment_ID: self.tempimport[i].comment_ID,
        comment: self.tempimport[i].comment,
      };
      axios.post(`${self.url}Comment/UpdateComment`, temp)
      .then(function (response) {
        if (response.data.status == 0) {
          alert(response.data.message);
          self.getComment();
        }
      });
    },

    deletethiscomment(id) {
      let self = this;
      let i = id;
      self.tempimport[i].hidedefault = false;
      self.tempimport[i].hideedit = false;
      self.tempimport[i].hidedelete = true;
    },

    Deletepost(id) {
      let self = this;
      let i = id;
      let temp = {
        comment_ID: i,
      };
      axios.post(`${self.url}Comment/DeleteComment`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.getComment();
        }
      });
    },

    hideeditpost(id) {
      let self = this;
      let i = id;
      self.getComment();
      self.tempimport[i].hidedefault = true;
      self.tempimport[i].hideedit = false;
      self.tempimport[i].hidedelete = false;
    },

    gotoeditcomment() {
      let self = this;
      self.editcomment = true;
      self.showeditcomment = false;
    },

    viewCount (){
      let self = this;
      let temp = {
        announcement_ID: parseInt(self.$route.params.announcementid),
      };
      axios.post(`${self.url}Announcement/AnnouncementViewCount`, temp);
    },

    getDetail() {
      let self = this;
      self.loadingdialog = true;
      let temp = {
        announcement_ID: parseInt(self.$route.params.announcementid),
        timezoneOffset: new Date().getTimezoneOffset()
      };
      axios.post(`${self.url}Announcement/GetAnnouncementbyID`, temp)
      .then(function (response) {
        self.loadingdialog = false; 
        if(response.data.status === 0)
        {
          self.Detaillist = response.data.data;
          //.log(formatServerDateToLocal(self.Detaillist[0].createdAnnouncement_Date))
          self.Announcementdetail.title = self.Detaillist[0].announcement_Title;
          self.Announcementdetail.detail = self.Detaillist[0].announcement_Detail.replace(/\n/g, "<br/>");
          self.Announcementdetail.expirydate = self.Detaillist[0].announcement_Expiry_Date;
          self.Announcementdetail.startdate = self.Detaillist[0].announcement_Start_Date;
          self.Announcementdetail.createdate = formatServerDateToLocal(self.Detaillist[0].createdAnnouncement_Date);
          self.showcomment = self.Detaillist[0].disable_Comments;
          self.imageUrl = self.Detaillist[0].announcement_Image;
          self.profileimageUrl = self.Detaillist[0].image;
          self.Announcementdetail.profilename = self.Detaillist[0].name + "   " + self.Detaillist[0].lastName;
          self.Announcementdetail.attachment = self.Detaillist[0].attachments;
          self.Announcementdetail.announcement_Status = self.Detaillist[0].announcement_Status;
        }
        else
        {
          alert(response.data.message);
        }
      });
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    Addcomment() {
      let self = this;
      if (self.requestcomment != "")
      {
        let temp = {
          comment: self.requestcomment,
          announcement_ID: parseInt(self.$route.params.announcementid),
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}Comment/AddComment`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.getComment();
            self.requestcomment = "";
            self.showpost = true;
          }
        });
      }
    },

    getComment() {
      let self = this;
      let temp = {
        announcement_ID: parseInt(self.$route.params.announcementid),
      };
      axios.post(`${self.url}Comment/Getcomment`, temp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.Commentlist = response.data.data;         
          self.tempimport = [];
          for (let i = 0; i < self.Commentlist.length; i++)
          {
            let showbtn = false;
            if (self.Commentlist[i].employee_ID == store.state.employeeID)
            {
              showbtn = true;
            }
            else
            {
              showbtn = false;
            }
            const date = new Date(self.Commentlist[i].date); // Assuming self.Commentlist[i].date is a valid date string or Date object
            const dateString = date.toString(); // Converts date to string
            let localCreateDate = formatServerDateToLocal(dateString);
            let timeDifference = self.formatTimeDifference(localCreateDate);
            let list = {
              ...self.Commentlist[i],
              date: localCreateDate,
              timeDifference: timeDifference,
              hidedefault: true,
              hideeditanddel: showbtn,
              hideedit: false,
              hidedelete: false,
              countcomment: i,
            };
            self.tempimport.push(list);
          }
        }
        else
        {
          alert(response.data.message)
        }
      });
    },
    convertUTCDateToLocalDate(date) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output =
        newDate.getDate() +
        "/" +
        newDate.getMonth() +
        "/" +
        newDate.getFullYear() +
        "   ";

      return output;
    },
  },
};
</script>
<style scoped>
.mycard {
  height: 100%;
}
.commentcard {
  overflow-y: auto;
  height: 300px;
  overflow-x: hidden;
}
.myfont {
  color: #696969;
  font-weight: 700!important;
  font-size: 16px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
>>> .v-dialog::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}
>>> .v-dialog::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
}
image {
  max-width: 400px;
  max-height: 300px;
}
.imageContainer {
  width: 200px;
  height: auto;
  background-size: contain;
  margin-left: 20px;
}
.alerticon{
  max-width: 32px!important;
  height: 32px!important;
}
.image-wrapper {
    width: 500px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
    object-fit: contain;
    background-color: #f0f0f0; 
}
.image-wrapper1 {
    width: 400px;
    height: 206px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
    object-fit: contain;
}

.annouimage {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
}
.textcolor{
  color:#696969!important;
  font-size: 16px;
}
::v-deep .mobileviewdialog{
  max-height: 900px;
  overflow-y: hidden!important;
}
.mobileicon{
  max-width: 20px!important;
  height: 20px!important;
  cursor: pointer;
}
.mobileimage{
  max-width: 370px!important;
  height: 210px!important;
  border-radius: 10px;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slidecontainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 180px; 
  overflow: hidden;
}
.swiperimg{
  object-fit: contain;
  width: 100%;
  height: 180px!important;
}
::v-deep .swiper-button-next{
  height: 100%;
  top: 0%;
  margin-top: 0px;
  background-image: url(~@/assets/images/arrow-right.svg);
  background-color: rgba(217, 213, 213, 0.5);
  right: 0%;
}
/* ::v-deep .swiper-button-next1{
  height: 100%;
  top: 0%;
  margin-top: 0px;
  background-color: rgba(217, 213, 213, 0.5);
  right: 0%;
  background-size: 15px;
} */
::v-deep .swiper-button-prev{
  height: 100%;
  top: 0%;
  margin-top: 0px;
  background-image: url(~@/assets/images/arrow-left.svg);
  background-color: rgba(217, 213, 213, 0.5);
  left: 0%;
}
::v-deep .v-dialog {
  scrollbar-width: none!important;
}
::v-deep .v-text-field__details{
  display: none;
}
</style>
