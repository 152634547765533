<template>
  <div style="margin-left: 1.5rem; margin-bottom: 5rem;margin-right: 1.5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 15px">
        {{ $t("NewUserList") }}
      </p>
    </div>
    <v-card class="mt-6 mb-7">
      <div>
        <v-row
          class="pl-md-0 pt-9"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-col cols="12" xs="12" sm="12" md="2" lg="3">
            <v-text-field
              v-model="searchtxt"
              autocomplete="off"
              :placeholder="$t('Search')"
              color="#F99D20"
              dense
              style="margin-top: -0.3rem; margin-left: 1rem"
            ></v-text-field>
          </v-col>
          <v-btn
            color="#F99D20"
            @click="Search"
            class="white--text text-capitalize ml-5"
            width="120"
            max-height="35"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
          <p class="ml-9 mt-2">{{ $t("departments") }}</p>
          <v-col
            class="ml-5 depart_text"
            cols="12"
            xs="12"
            sm="12"
            md="2"
            lg="3"
          >
            <v-autocomplete
              v-model="department"
              :items="DepartmentList"
              item-text="department_Name"
              item-value="department_Name"
              color="#F99D20"
              class="text"
              clearable
              outlined
              style="
                border-radius: 15px;
                margin-top: -1rem;
                margin-right: 1rem;
              "
              dense
            >
              <v-icon
                slot="append"
                class="v-icon notranslate mdi mdi-chevron-down theme--light"
              />
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-data-table
          style="margin-left: 40px; margin-right: 40px"
          :items-per-page="itemsPerPage"
          :items="PersonalListFilter"
          :page.sync="page"
          :headers="headers"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>
                  <label class="form-checkbox">
                    <input
                      type="checkbox"
                      v-model="selectAll"
                      @click="select"
                    />
                    <i class="form-icon"></i>
                  </label>
                </th>
                <th>{{ $t("EmployeeID") }}</th>
                <th>{{ $t("EmployeeName") }}</th>
                <th>{{ $t("PositionName") }}</th>
                <th>{{ $t("department") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <label class="form-checkbox">
                  <input
                    type="checkbox"
                    :value="
                      item.employee_ID != null
                        ? {
                            emmpid: item.employee_ID,
                            emmpname: item.employee_Name,
                          }
                        : { emmpid: emmpid, emmpname: emmpname }
                    "
                    v-model="selected"
                  />
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.employee_Code }}</td>
              <td>{{ item.employee_Name }}</td>
              <td>{{ item.position_Name }}</td>
              <td>{{ item.department_Name }}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          style="border-radius: 18px"
          class="mr-2 text-capitalize"
          width="120"
          height="40"
          @click="goto"
          text
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          style="border-radius: 18px"
          color="#F99D20"
          class="mr-4 text-capitalize"
          width="120"
          height="40"
          @click="GetEmpDetails()"
          >{{ $t("save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [utils],
  data() {
    return {
      headers: [
        {
          text: "Employee ID",
          value: "employee_Code",
          align: "left",
          sortable: false,
        },
        {
          text: "Employee Name",
          value: "employee_Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Position Name",
          value: "position_Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Department",
          value: "department_Name",
          align: "left",
          sortable: false,
        },

        {
          text: "Actions",
          value: "actionadd",
          align: "right",
          sortable: false,
        },
      ],
      department: "",
      DepartmentList: [],
      loadingdialog: false,
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      searchtxt: "",
      PersonalList: [],
      selected: [],
      selectAll: false,
      tempid: [],
      empid: [],
      empname: [],
      tempuser: null,
      tempeid: [],
      detailsresult: [],
      resultugp: null,
      resulturole: null,
      UserGroup: [],
      UserRole: [],
      tempUserGroup: [],
      tempRole: [],
      SelectedUgp: [],
      SelectedRole: [],
    };
  },
  watch: {
    searchtxt: function () {
      this.GetPersonal();
    },
  },
  mounted() {
    this.GetPersonal();
    this.GetDepartment();
  },
  computed: {
    PersonalListFilter() {
      if (!this.department) return this.PersonalList;
      return this.PersonalList.filter(
        (x) => x.department_Name == this.department
      );
    },
  },
  methods: {
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.PersonalList;
        self.PersonalList = [];
        let temp = List.filter(
          (v) =>
            v.employee_Code.indexOf(self.searchtxt) > -1 ||
            v.employee_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.position_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.department_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.PersonalList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetPersonal();
      }
    },
    GetDepartment() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      axios
        .post(`${self.url}Department/GetDepartmentsByCompanyID`, temp)
        .then(function (response) {
          self.DepartmentList = response.data.data;
        })
        .catch(function (error) {
          alert(error);
        });
    },

    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.PersonalList) {
          this.selected.push({
            emmpid: this.PersonalList[i].employee_ID,
            emmpname: this.PersonalList[i].employee_Name,
          });
        }
      }
    },
    goto() {
      this.search = null;
      this.$router.push("/usermanagement/user").catch(() => {});
      localStorage.setItem("NewUserAssign", null);
    },
    GetPersonal() {
      let self = this;
      //self.loadingdialog = true;
      let tempp = store.state.companyID;
      axios
        .get(
          `${self.url}Confirmemployee/GetNotConfirmEmployeeByCompanyID?Company_ID=${tempp}`
        )
        .then(function (response) {
          self.PersonalList = response.data.data;
          //self.loadingdialog = false;
          self.tempuser = JSON.parse(localStorage.getItem("NewUserAssign"));
          if (self.tempuser != null) {
            for (let i in self.tempuser) {
              self.selected.push({
                emmpid: self.tempuser[i].emmpid,
                emmpname: self.tempuser[i].emmpname,
              });
            }
          }
        });
    },

    // AddUser() {
    //   let self = this;
    //   if (self.selected.length != 0) {
    //     self.loadingdialog = true;
    //     localStorage.setItem("NewUserAssign", JSON.stringify(self.selected));
    //     let temparray = self.selected.map((x) => x.emmpid);
    //     for (let x = 0; x < temparray.length; x++) {
    //       self.tempeid.push({
    //         employee_ID: temparray[x],
    //       });
    //     }
    //     self.GetEmpDetails();
    //     if (self.detailsresult.length == 0) {
    //       self.$router.push({ name: "newuserassign" });
    //     } else {
    //       alert(
    //         "Selected Employees are different roles and user groups! Please select same role and usergroup"
    //       );
    //       self.loadingdialog = false;
    //     }
    //   } else {
    //     alert("Please select Employee");
    //     self.loadingdialog = false;
    //   }
    // },

    GetEmpDetails() {
      let self = this;
      if (self.selected.length != 0) {
        self.loadingdialog = true;
        localStorage.setItem("NewUserAssign", JSON.stringify(self.selected));
        let temparray = self.selected.map((x) => x.emmpid);
        for (let x = 0; x < temparray.length; x++) {
          self.tempeid.push({
            employee_ID: temparray[x],
          });
        }
        let temp = {
          empID: self.tempeid,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}Confirmemployee/GetNotConfrimEmpDetails`, temp)
          .then(function (response) {
            self.detailsresult = response.data.data;
            self.UserGroup = response.data.data.map((x) =>
              x.empUserGroup.map((y) => y.user_Group_ID)
            );

            self.UserRole = response.data.data.map((x) =>
              x.empRoleData.map((y) => y.role_ID)
            );
            if (self.detailsresult.length > 0) {
              self.tempUserGroup = self.UserGroup.flat();
              self.tempRole = self.UserRole.flat();
              const allEqual = (arr) => arr.every((val) => val === arr[0]);
              self.resultugp = allEqual(self.tempUserGroup);
              self.resulturole = allEqual(self.tempRole);
              if (self.resultugp != true || self.resultugp != true) {
                alert(
                  "Selected Employees are different roles and user groups! Please select same role and usergroup"
                );
                self.selected = [];
                self.tempeid = [];
                self.UserGroup = [];
                self.UserRole = [];
                self.loadingdialog = false;
              } else {
                self.$router.push({ name: "newuserassign" }).catch(() => {});
                self.SelectedUgp = response.data.data
                  .map((x) => x.empUserGroup)
                  .flat();

                self.SelectedUgp = [
                  ...new Map(
                    self.SelectedUgp.map((item) => [item.user_Group_ID, item])
                  ).values(),
                ];
                localStorage.setItem(
                  "NewUserEditUserGp",
                  JSON.stringify(self.SelectedUgp)
                );
                self.SelectedRole = response.data.data
                  .map((x) => x.empRoleData)
                  .flat();
                self.SelectedRole = [
                  ...new Map(
                    self.SelectedRole.map((item) => [item.role_ID, item])
                  ).values(),
                ];
                localStorage.setItem(
                  "NewUserEditRole",
                  JSON.stringify(self.SelectedRole)
                );
              }
            } else {
              self.$router.push({ name: "newuserassign" }).catch(() => {});
              self.SelectedUgp = response.data.data
                .map((x) => x.empUserGroup)
                .flat();
              self.SelectedUgp = [
                ...new Map(
                  self.SelectedUgp.map((item) => [item.user_Group_ID, item])
                ).values(),
              ];
              localStorage.setItem(
                "NewUserEditUserGp",
                JSON.stringify(self.SelectedUgp)
              );
              self.SelectedRole = response.data.data
                .map((x) => x.empRoleData)
                .flat();
              self.SelectedRole = [
                ...new Map(
                  self.SelectedRole.map((item) => [item.role_ID, item])
                ).values(),
              ];
              localStorage.setItem(
                "NewUserEditRole",
                JSON.stringify(self.SelectedRole)
              );
            }
          });
      } else {
        alert("Please select Employee");
        self.loadingdialog = false;
      }
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
  .depart_text {
    margin-top: 1rem !important;
  }
}

>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>