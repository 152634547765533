<template>
  <div>
    <div style="background-color: #f0f0f7; margin-left: 1%">
      <p
        style="
          font-size: 26px;
          color: #43425d;
          padding-top: 18px;
          padding-left: 18px;
          margin-top: 0.2rem;
        "
      >
        {{ $t("notifications") }}
      </p>
    </div>
    <v-row style="margin-right: 0; margin-left: -2px; margin-top: -1.5rem">
      <v-col cols="12" md="12" sm="12">
        <v-card
          class="mb-5"
          style="
            margin-bottom: 5rem;
            margin-left: 1%;
            margin-right: 0.3%;
            height: auto;
            min-height: 700px;
          "
        >
          <div style="margin: 20px">
            <v-row>
              <v-col cols="12" class="mt-5">
                <div style="float: left">
                  <v-icon color="#F89D1F" size="20">mdi-bell</v-icon>
                </div>
                <div style="float: left">
                  <p
                    class=""
                    text
                    style="
                      cursor: pointer;
                      font-size: 17px;
                      padding-left: 9px;
                      color: #f89d1f;
                    "
                  >
                    {{ $t("notifications") }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" sm="4" lg="3">
                <div class="d-flex">
                  <p class="mt-3" style="width: 50px">
                    {{ $t("from") }}
                  </p>
                  <date-picker
                    v-model="reportDateFrom"
                    format="DD/MM/YYYY"
                    @change="GetrequestList()"
                    width="100%"
                    :disabled-date="(date) => date > new Date(reportDateTo)"
                    placeholder="DD/MM/YYYY"
                    style="margin-top: 0.5rem; width: 150px"
                  >
                    <template slot="icon-calendar">
                      <img
                        src="@/assets/images/calendaricon.png"
                        style="width: 20px; height: 20px"
                      />
                    </template>
                  </date-picker>
                </div>
              </v-col>

              <v-col cols="12" sm="4" md="3" lg="3">
                <div class="d-flex">
                  <p class="mt-3" style="width: 50px">
                    {{ $t("To") }}
                  </p>
                  <date-picker
                    v-model="reportDateTo"
                    format="DD/MM/YYYY"
                    width="100%"
                    :disabled-date="(date) => date < new Date(reportDateFrom)"
                    @change="GetrequestList()"
                    placeholder="DD/MM/YYYY"
                    style="margin-top: 0.5rem; width: 150px"
                  >
                    <template slot="icon-calendar">
                      <img
                        src="@/assets/images/calendaricon.png"
                        style="width: 20px; height: 20px"
                      />
                    </template>
                  </date-picker>
                </div>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item-group>
                <v-list-item
                  router
                  :to="item.route"
                  v-for="(item, i) in visiblePages"
                  :key="i"
                  @click="
                    sendDataWithParams(
                      item.wF_ID,
                      item.notificationID,
                      i,
                      item.seen,
                      item
                    )
                  "
                  v-bind:style="{ background: colorList[i] }"
                >
                  <v-row style="width: 100%; text-align: left">
                    <v-col v-if="item.senderImage" cols="1" class="pa-0 ma-0">
                      <img
                        :src="item.senderImage"
                        class="profileimagefornoti"
                      />
                    </v-col>
                    <v-col v-if="!item.senderImage" cols="1" class="pa-0 ma-0">
                      <img
                        src="@/assets/profile.svg"
                        class="profileimagefornoti"
                      />
                    </v-col>
                    <v-col cols="8" class="pl-4 pt-5">
                      <div
                        class="text"
                        style="
                          text-align: left;
                          text-transform: none;
                          font-weight: normal;
                        "
                      >
                        {{ item.senderName }}
                        {{ checkingTranslate(item.description) }}
                      </div>
                    </v-col>
                    <v-col
                      cols="3"
                      class="text ma-0 pl-1 pt-5"
                      style="text-align: right; :10px "
                    >
                      {{ convertUTCDateToLocalDate(item.noti_date) }}
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-row class="pr-6" v-if="!requestData.length">
              <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                <p style="color: #f99d20; font-size: 16px">
                  There has no notifications within selected date range.
                </p>
              </v-col>
            </v-row>
            <!-- pagination -->
            <div class="text-right" v-if="requestData.length">
              <v-row justify="end" class="ml-5 mt-5 mr-1">
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :length="Math.ceil(requestData.length / perPage)"
                    color="#ED9721"
                    :total-visible="6"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select
                    dense
                    style="width: 120px; float: right"
                    solo
                    label="10/page"
                    v-model="perPage"
                    :items="items"
                    @input="perPage = parseInt($event, 10)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  components: { DatePicker },
  data() {
    return {
      page: 1,
      pageCount: 15,
      perPage: 10,
      items: [10, 20, 30],
      customImgHeight: "100%",
      rememberme: "false",
      showLists: false,
      url: enurl.apiUrl,
      requestData: [],
      profileimage: null,
      colorList: [],
      NotiFeedback: [],
      reportDateFrom: new Date(),
      reportDateTo: new Date(),
    };
  },
  computed: {
    visiblePages() {
      return this.requestData.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    checkingTranslate() {
      return function (value) {
        return value == "requested for Time Off"
          ? this.$t("requestedTime")
          : value == "requested for OT"
          ? this.$t("requestedOT")
          : value == "approved your OT request"
          ? this.$t("approvedOT")
          : value == "approved your Time Off request"
          ? this.$t("approvedTimeOff")
          : value == "denied your Time Off request"
          ? this.$t("deniedTimeOff")
          : value == "has replied to your feedback"
          ? this.$t("Replyyourfeedback")
          : this.$t("deniedOT");
      };
    },
  },
  mounted() {
    this.GetrequestList();
  },
  methods: {
    convertUTCDateToLocalDate(date) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);

      let output = null;

      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }

      return output;
    },
    formatDate(date) {
      // let finaldate = date.slice(0, 10).split("-");
      let spliteDateAndTime = date.split("T");
      let Date = spliteDateAndTime[0].split("-");
      let time = spliteDateAndTime[1].split(":");
      return (
        Date[0] + "/" + Date[1] + "/" + Date[2] + " " + time[0] + ":" + time[1]
      );
    },
    showListChange() {
      let self = this;
      self.showLists = !self.showLists;
    },
    async GetrequestList() {
      let self = this;
      self.requestData = [];
      self.colorList = [];
      self.NotiFeedback = [];
      let tempresult = [];
      self.GetfeedbackNotiList();
      let temp = {
        company_ID: store.state.companyID,
        receiver_ID: store.state.employeeID,
      };
      await axios
        .post(`${self.url}NotificationInfo/GetLatestNoti`, temp)
        .then(function (response) {
          let temps = response.data.data;
          tempresult = [...temps, ...self.NotiFeedback];
          let filterList = tempresult.filter((v) => {
            let stillUtc = moment.utc(v.noti_date).toDate(); //still utc
            let request_On = moment(stillUtc).local().format("YYYY-MM-DD");
            return (
              request_On >= moment(self.reportDateFrom).format("YYYY-MM-DD") &&
              request_On <= moment(self.reportDateTo).format("YYYY-MM-DD")
            );
          });
          let order = filterList
            .slice()
            .sort((a, b) => b.notificationID - a.notificationID);
          order.forEach(function (item) {
            self.requestData.push(item);
            if (item.seen == false) {
              self.colorList.push("#D0D0D0");
            } else {
              self.colorList.push("white");
            }
          });
        });
    },
    async GetfeedbackNotiList() {
      let self = this;
      self.NotiFeedback = [];
      let temp = {
        company_ID: store.state.companyID,
        receiver_ID: store.state.employeeID,
      };
      await axios
        .post(`${self.url}NotificationInfo/GetLatestNotiFeedback`, temp)
        .then(function (response) {
          let temp = response.data.data;
          temp
            .slice()
            .reverse()
            .forEach(function (item) {
              if (self.NotiFeedback.length < 10) {
                self.NotiFeedback.push({
                  noti_date: item.noti_date,
                  notificationID: item.notificationID,
                  feedback_ID: item.feedback_ID,
                  seen: item.seen,
                  senderID: item.senderID,
                  senderImage: item.senderImage,
                  senderName: item.senderName,
                  status: "replied",
                  wF_ID: item.wF_ID,
                  wF_Name: item.wF_Name,
                  description: item.description,
                  delicated: item.delicated,
                });
              }
            });
        });
    },
    async sendDataWithParams(input, notificationID, index, status, item) {
      let temp = {
        notificationID: notificationID,
      };

      await axios
        .post(`${this.url}NotificationInfo/EditnotificationState`, temp)
        .then(function () {
          // if (response.data.data) {
          //   console.log(response.data.data);
          // }
        });

      if (item.delicated) {
        alert(
          "This task was delegated to someone else so it's no longer available"
        );
      } else if (!item.delicated) {
        if (
          item.description === "requested for Time Off" ||
          item.description === "requested for OT"
        ) {
          this.$router
            .push({ name: "mytaskNoti", params: { notiString: input } })
            .catch(() => {});
        } else {
          if (item.wF_Name === "OT") {
            this.$router.push({
              name: "MyOTrequestNoti",
              params: {
                notiString: input,
                condition:
                  item.description === "denied your OT request"
                    ? "Denied"
                    : "Approved",
              },
            }).catch(()=>{});
          } else if (item.wF_Name === "Feedback") {
            this.$router.push({
              path: `/HelpFeedback/NewFeedback/SentFeedback/${input}`,
            }).catch(()=>{});
          } else {
            this.$router.push({
              name: "MyTimeOffRequestNoti",
              params: {
                notiString: input,
                condition:
                  item.description === "approved your Time Off request"
                    ? "Approved"
                    : "Denied",
              },
            }).catch(()=>{});
          }
        }
      }
    },
  },

  watch: {},
};
</script>

<style scoped>
/* .list:nth-of-type(even) {
    background-color: #fff !important;
} */
.profileimagefornoti {
  border: 1px solid #43425d;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  margin-top: 15px;
}

.text {
  font-size: 15px;
}
@media (max-width: 370px) and (max-height: 640px) {
  .text {
    font-size: 13px;
  }
}

@media (min-width: 410px) and (min-height: 720px) {
  .text {
    font-size: 13px;
  }
}

@media (max-width: 320px) and (max-height: 570px) {
  .text {
    font-size: 13px;
  }
}

@media (min-width: 370px) and (min-height: 660px) {
  .text {
    font-size: 13px;
  }
}

@media (min-width: 760px) and (min-height: 1020px) {
  .text {
    font-size: 13px;
  }
  .profileimagefornoti {
    border: 1px solid #43425d;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    margin-top: 15px;
    margin-left: 20px;
  }
}

@media (min-width: 1240px) and (min-height: 700px) {
  .text {
    font-size: 13px;
  }
  .profileimagefornoti {
    border: 1px solid #43425d;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    margin-top: 15px;
    margin-left: 20px;
  }
}

@media (min-width: 1000px) and (min-height: 1300px) {
  .text {
    font-size: 17px;
  }
  .profileimagefornoti {
    border: 1px solid #43425d;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    margin-top: 15px;
    margin-left: 20px;
  }
}
</style>
