<template>
    <!-- Loading Dialog -->
    <v-dialog v-model="LoadingDialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          <span class="mt-2">{{ message }} </span>
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    LoadingDialog: {
      type: Boolean,
      required: true,
    },
    message:{
      type: String,
      default: "Loading Please Wait...",
    }
  },
};
</script>

<style scoped>

</style>