import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
//Mytask

import MyTask from "./views/MyTask.vue";
import task from "./views/Task.vue";

// login
import welcome from "./views/Welcome/Login.vue";
import home from "./views/Home/home.vue";
import ForgetPassword from "./views/Welcome/ForgetPrd.vue";
import ResetPassword from "./views/Welcome/ResetPassword.vue";
import NewAnnouncement from "./views/Home/AddNewAnnouncement.vue";
import EditNewAnnouncement from "./views/Home/EditNewAnnouncement.vue";
import EditAnnouncement from "./views/Home/EditAnnouncement.vue";
import AnnouncementList from "./views/Home/AnnouncementList.vue";
import FullCalendar from "./views/Home/LeaveFullCalendar.vue";
import ReadMore from "./views/Home/ReadMore.vue";
import personal from "./views/Personal/personal.vue";

//Personal
import PersonalInformation from "./views/Personal/PersonalInformation.vue";
import Salary from "./views/Personal/salary.vue";
import PersonalIncomeTax from "./views/Personal/IncomeTax.vue";
import SocialSecurity from "./views/Personal/SocialSecurity.vue";
import ProvidentFund from "./views/Personal/ProvidentFund.vue";
import HealthInformation from "./views/Personal/HealthInformation.vue";
import PersonalBenefit from "./views/Personal/BenefitEmployee.vue";
import Guarantor from "./views/Personal/Guarantor.vue";
import Family from "./views/Personal/Family.vue";
import Benefit from "./views/Benefit/Benefit.vue";
import EditEducational from "./views/Personal/EditEducational.vue";
import Educationalbackground from "./views/Personal/EducationalBackground.vue";
import WorkPermit from "./views/Personal/WorkPermit.vue";
import Otherinformation from "./views/Personal/Otherinformation.vue";
import DepartmentTransfer from "./views/Personal/DepartmentTransfer.vue";
import Passport from "./views/Personal/Passport.vue";
import PersonalID from "./views/Personal/PersonalID.vue";
import SalarychangeHistory from "./views/Personal/SalarychangeHistory.vue";
import salary1 from "./views/Personal/SalarychangeHistory.vue";
import Account from "./views/Personal/Account/Account.vue";
import PUserGroup from "./views/Personal/Account/EditUserGroup.vue";
import PRole from "./views/Personal/Account/EditRole.vue";
import PPermission from "./views/Personal/Account/EditPermission.vue";
import NUserGroup from "./views/Personal/Account/EditUserGroup.vue";
import NRole from "./views/Personal/Account/EditRole.vue";
import NPermission from "./views/Personal/Account/EditPermission.vue";

//Time off
import LeaveType from "./views/Organization/LeaveType.vue";
import MyTimeOffRequest from "./views/TimeOff/MyTimeOffRequest.vue";
import EmployeesTimeOffRequest from "./views/TimeOff/EmployeesTimeOffRequest.vue";
import NewEmployeesTimeOffRequest from "./views/TimeOff/NewEmployeesTimeOffRequest.vue";
// import TimeOffSetting from "./views/TimeOff/TimeOffSetting.vue";

// Payroll
import PayRoll from "./views/Payroll/Payrolll.vue";
import PayRollCalculationPeriod from "./views/Payroll/PayRollCalculationPeriod";
import Deductionsetting from "./views/Payroll/DeductionSetting";
import MainPayRoll from "./views/Payroll/MainPayRoll";

//PersonalMangement
import EmployeeList from "./views/PersonalManagement/EmployeeList.vue";

//Setting
import Usermanagement from "./views/Setting/Usermanagement.vue";
// import User from "./views/Setting/User.vue";
import Usergroup from "./views/Setting/UserGroup/UserGroup.vue";
import UsergroupAdd from "./views/Setting/UserGroup/UserGroupAdd.vue";
import UsergroupEdit from "./views/Setting/UserGroup/UserGroupEdit.vue";
import UsergroupEditMember from "./views/Setting/UserGroup/UserGroupEditMember.vue";
import UsergroupEditPermission from "./views/Setting/UserGroup/UserGroupEditPermission.vue";
import UsergroupEditRole from "./views/Setting/UserGroup/UserGroupEditRole.vue";
import UsergroupMember from "./views/Setting/UserGroup/UserGroupMember.vue";
import UsergroupPermission from "./views/Setting/UserGroup/UserGroupPermission.vue";
import UsergroupRole from "./views/Setting/UserGroup/UserGroupRole.vue";
import Permission from "./views/Setting/Permission.vue";
import Role from "./views/Setting/Role.vue";
import RoleAdd from "./views/Setting/RoleAdd.vue";
import RoleEdit from "./views/Setting/RoleEdit.vue";
import RolePermission from "./views/Setting/RolePermission.vue";
import RoleEditPermission from "./views/Setting/RoleEditPermission.vue";
import UserEditUserGroup from "./views/Setting/UserEditUserGroup.vue";
import UserEditRole from "./views/Setting/UserEditRole.vue";
import UserEditPermission from "./views/Setting/UserEditPermission.vue";
import Newuser from "./views/Setting/Newuser.vue";
import EditUser from "./views/Setting/EditUser.vue";
import NewUserAssign from "./views/Setting/NewUserAssign.vue";
import NewUserAssignUgp from "./views/Setting/NewUserAssignUgp.vue";
import NewUserAssignRole from "./views/Setting/NewUserAssignRole.vue";
import NewUserAssignPermis from "./views/Setting/NewUserAssignPermis.vue";
import OtherSetting from "./views/Setting/OtherSetting/OtherSetting.vue";

//Setting/CompanySetUp
import CompanySetUp from "./views/Setting/CompanySetup/CompanySetUp.vue";
import TimeAttendance from "./views/Setting/CompanySetup/TimeAttendance.vue";
import TimeOff from "./views/Setting/CompanySetup/TimeOff.vue";

//Setting/HelpFeedback
import HelpFeedback from "./views/Setting/HelpFeedback/HelpFeedback.vue";
import NewFeedback from "./views/Setting/HelpFeedback/NewFeedback.vue";
import BrandIdentity from "./views/Setting/BrandIdentity/BrandIdentity.vue";
import DefaultLanguage from "./views/Setting/DefaultLanguage/DefaultLanguage.vue";

//Setting/Usageplan
import UsagePlan from "./views/Setting/UsagePlan/UsagePlan.vue";
import OldFeedback from "./views/Setting/HelpFeedback/OldFeedback.vue";

//Reports
import Reports from "./views/Report/Reports.vue";
import EmploymentReport from "./views/Report/EmploymentReport.vue";
import TimeAttendanceReport from "./views/Report/TimeAttendanceReport.vue";
import TimeOffReport from "./views/Report/TimeOffReport.vue";
import PayrollReport from "./views/Report/PayrollReport.vue";
import AnnualReport from "./views/Report/AnnualReport.vue";
import Addition from "./views/Report/AdditionandTermination/Addition.vue";
import Termination from "./views/Report/AdditionandTermination/Termination.vue";
import AgeGender from "./views/Report/Demographics/AgeGender.vue";
import LastPayChange from "./views/Report/Payroll/LastPayChange.vue";
import SalaryHistory from "./views/Report/Payroll/SalaryHistory.vue";
import Timeoffused from "./views/Report/TimeOffUse/Timeoffused​.vue";
import SocialReport from "./views/Report/OtherDocuments/SocialSecurityReport.vue";
import TaxCertificate from "./views/Report/OtherDocuments/WithholdTaxCertificate.vue";

//MasterData
import MasterData from "./views/MasterData/master.vue";
import Address from "./views/MasterData/Address.vue";
import Bank from "./views/MasterData/Bank.vue";
import shiftcode from "./views/MasterData/shiftcode.vue";
import MBenefits from "./views/MasterData/Benefits.vue";
import SocialSecurityFund from "./views/MasterData/Socialsecurityfund.vue";
import Providentfund from "./views/MasterData/providentfund.vue";
import Organization from "./views/MasterData/Organization.vue";
import IncomeTax from "./views/MasterData/Taxes.vue";
import PayRollAccounts from "./views/MasterData/PayRollAccount.vue";
import IncomeType from "./views/MasterData/IncomeType.vue";

// time attendance
import TimeEntries from "./views/TimeAttendance/Timeentries.vue";
import HROT from "./views/TimeAttendance/HROTrequest.vue";
import TimeEntriesSheetView from "./views/TimeAttendance/Timeentriessheetview.vue";
import StaffTimeAttendance from "./views/TimeAttendance/StaffTimeAttendance.vue";
import StaffTimeAttendanceCal from "./views/TimeAttendance/StaffTimeAttendanceCal";
import Workschedule from "./views/TimeAttendance/WorkSchedule.vue";
import OT from "./views/TimeAttendance/OT.vue";
import StaffOT from "./views/TimeAttendance/StaffOT.vue";
import OTrequest from "./views/TimeAttendance/OTrequest.vue";
import Timesheetdetail from "./views/TimeAttendance/stafftimeattendancesheetdetail.vue";
import Monthsheetdetail from "./views/TimeAttendance/MonthSheetDetail.vue";
import GoogleMap from "./views/TimeAttendance/GoogleMap.vue";
import SyncTimedata from "./views/TimeAttendance/SyncTimedata.vue";

// time attendance // setting
// import Setting from "./views/TimeAttendance/Setting/Setting";

//test
import test from "./views/test.vue";

//MobileView
import AnnouncementDetail from "./views/MobileView/AnnouncementDetail.vue";
//Hansontalbe
import Hansontable from "./views/MasterData/Hansontable.vue";

//Version and Release
import Version from "./views/versionAndRelease/versionAndRelease.vue";
import Notification from "./views/notification/notification";

//Self service
import MyOT from "./views/SelfService/MyOTRequest.vue";
import MyTimeOff from "./views/SelfService/MyTimeOffRequest.vue";
import NewTimeOffRequest from "./views/TimeOff/NewTimeOffRequest.vue";
import ChangePassword from "./views/SelfService/ChangePassword.vue";
//import PersonalProfile from "./views/SelfService/PersonalProfile.vue";
import Timeentries from "./views/SelfService/TimeEntry/TimeEntries.vue";
import TimeEntriesSheetDetail from "./views/SelfService/TimeEntry/TimeEntriesSheetDetail.vue";
import SalaryDocument from "./views/SelfService/SalaryDocument.vue";

//Organization
import CompanyProfile from "./views/Organization/CompanyProfile.vue";
import Org from "./views/Organization/Organization.vue";
import Atten from "./views/Organization/Attendance.vue";
import Timeof from "./views/Organization/TimeOff.vue";
import CompanyInformation from "./views/Setting/BrandIdentity/BrandIdentity.vue";
import PayRollAccount from "./views/MasterData/PayRollAccount";
import WorkingCondition from "./views/MasterData/WorkingCondition.vue";
import MDepartment from "./views/MasterData/Department.vue";
import Position from "./views/MasterData/Position.vue";
import ShiftAssignment from "./views/TimeAttendance/Setting/ShiftAssignment.vue";
import ShiftCode from "./views/TimeAttendance/Setting/ShiftCode.vue";
import TimeEntriesView from "./views/TimeAttendance/Setting/TimeEntriesView.vue";
import TimeAttendanceSetting from "./views/TimeAttendance/Setting/TimeAttendanceSetting.vue";
import PayrollSetting from "./views/Organization/PayrollSetting.vue";
import WorkinLocation from "./views/TimeAttendance/Setting/WorkinLocation.vue";
import TimeWorkdayCalendar from "./views/TimeAttendance/Setting/WorkdayCalendar.vue";
import TimeOffType from "./views/MasterData/Timeoff.vue";
import AddTimeOff from "./views/MasterData/Timeoffadd.vue";
import EditTimeOff from "./views/MasterData/Timeoffedit.vue";
import TimeOffCount from "./views/TimeOff/TimeOffTap.vue";

//Dashboard
import dashboard from "./views/Dashboard/Dashboard.vue";

import store from "./store";
let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    //Dashboard
    {
      path: "/dashboard",
      name: "dashboard",
      component: dashboard,
    },
    //MobileView
    {
      path: "/AnnouncementMobView/:announcementid",
      name: "AnnouncementDetail",
      component: AnnouncementDetail,
    },
    //Organization
    //Company Profile
    {
      path: "/organization/companyprofile/:id",
      component: CompanyProfile,
      props: true,
      children: [
        {
          path: "",
          name: "companyinformation",
          component: CompanyInformation,
        },
        {
          path: "payrollaccount",
          name: "CompanyPayrollAccount",
          component: PayRollAccount,
        },
        {
          path: "workingcondition",
          name: "workingcondition",
          component: WorkingCondition,
        },
      ],
    },
    //Orgnization
    {
      path: "/organization/org/:id",
      component: Org,
      props: true,
      children: [
        {
          path: "",
          name: "department",
          component: MDepartment,
        },
        {
          path: "position",
          name: "position",
          component: Position,
        },
      ],
    },
    //Attendance
    {
      path: "/organization/attendance/:id",
      component: Atten,
      props: true,
      children: [
        {
          path: "",
          name: "workinlocation",
          component: WorkinLocation,
        },
        {
          path: "timeworkdaycalendar",
          name: "timeworkdaycalendar",
          component: TimeWorkdayCalendar,
        },
        // {
        //   path: "timegroups",
        //   name: "timegroups",
        //   component: TimeGroups,
        // },
        // {
        //   path: "shiftassignment",
        //   name: "shiftassignment",
        //   component: ShiftAssignment,
        // },
        
        {
          path: "shiftcode",
          name: "shiftcode",
          component: ShiftCode,
        },
        {
          path: "timeattendancesetting",
          name: "timeattendancesetting",
          component: TimeAttendanceSetting,
        },
        {
          path: "timeentriesview",
          name: "timeentriesview",
          component: TimeEntriesView,
        },
      ],
    },
    //TimeOff
    {
      path: "/organization/leavetype",
      name: "LeaveType",
      component: LeaveType,
    },
    {
      path: "/organization/payrollsetting",
      name: "PayrollSetting",
      component: PayrollSetting,
    },
    {
      path: "/organization/timeoff/:id",
      component: Timeof,
      props: true,
      children: [
        {
          path: "",
          name: "timeofftype",
          component: TimeOffType,
        },
        {
          path: "add",
          name: "addtimeoff",
          component: AddTimeOff,
        },
        {
          path: "edittoff/:to",
          name: "edittimeoff",
          component: EditTimeOff,
        },
        {
          path: "timeoffdaycount",
          name: "TimeOffCount",
          component: TimeOffCount,
        },
      ],
    },
    // Report
    {
      path: "/reports",
      name: "Reports",
      component: Reports,
    },
    {
      path: "/shiftassignment",
      name: "shiftassignment",
      component: ShiftAssignment,
    },
    {
      path: "/employmentreport",
      name: "EmploymentReport",
      component: EmploymentReport,
    },
    {
      path: "/timeattendancereport",
      name: "TimeAttendanceReport",
      component: TimeAttendanceReport,
    },
    {
      path: "/timeoffreport",
      name: "TimeOffReport",
      component: TimeOffReport,
    },
    {
      path: "/payrollreport",
      name: "PayrollReport",
      component: PayrollReport,
    },
    {
      path: "/annualreport",
      name: "AnnualReport",
      component: AnnualReport,
    },
    {
      path: "/employmentreport/addition",
      name: "Addition",
      component: Addition,
    },
    {
      path: "/employmentreport/termination",
      name: "Termination",
      component: Termination,
    },
    {
      path: "/employmentreport/agegender",
      name: "AgeGender",
      component: AgeGender,
    },
    {
      path: "/payrollreport/lastpaychange",
      name: "LastPayChange",
      component: LastPayChange,
    },
    {
      path: "/payrollreport/salaryhistory",
      name: "SalaryHistory",
      component: SalaryHistory,
    },
    {
      path: "/timeoffreport/timeoffused",
      name: "Timeoffused",
      component: Timeoffused,
    },
    {
      path: "/payrollreport/socialreport",
      name: "SocialReport",
      component: SocialReport,
    },
    {
      path: "/annualreport/taxcertificate",
      name: "TaxCertificate",
      component: TaxCertificate,
    },
    {
      path: "/test",
      name: "test",
      component: test,
    },
    {
      path: "/mytask",
      name: "mytask",
      component: task,
    },
    // {
    //path: "/mytask",
    // name: "mytask",
    // component: MyTask,
    // },
    {
      path: "/mytask/:notiString",
      name: "mytaskNoti",
      component: MyTask,
    },
    {
      path: "/googlemap",
      name: "GoogleMap",
      component: GoogleMap,
    },
    {
      path: "/home",
      name: "home",
      component: home,
    },
    {
      path: "/home/newAnnouncement",
      name: "newAnnouncement",
      component: NewAnnouncement,
    },
    {
      path: "/home/EditAnnouncement/:announcementid",
      name: "EditAnnouncement",
      component: EditNewAnnouncement,
    },
    {
      path: "/home/AnnouncementList",
      name: "AnnouncementList",
      component: AnnouncementList,
    },
    {
      path: "/home/FullCalendar",
      name: "FullCalendar",
      component: FullCalendar,
    },
    {
      path: "/home/editAnnouncement/:announcementid",
      name: "editAnnouncement",
      component: EditAnnouncement,
    },
    {
      path: "/home/readMore/:announcementid",
      name: "readMore",
      component: ReadMore,
    },
    {
      path: "/",
      name: "welcome",
      component: welcome,
    },

    {
      path: "/new",
      name: "createNewCompany",
      component: welcome,
    },
    {
      path: "/join",
      name: "joinCompany",
      component: welcome,
    },
    {
      path: "/forgetPassword",
      name: "forgetPassword",
      component: ForgetPassword,
    },
    {
      path: "/resetPassword/:forgetId",
      name: "ResetPassword",
      component: ResetPassword,
    },

    {
      path: "/Benefit",
      name: "Benefit",
      component: Benefit,
    },

    //Personal Information
    {
      path: "/selfservice/PersonalProfile/:id",
      name: "PersonalProfile",
      component: personal,
      props: true,
      children: [
        {
          path: "",
          name: "PersonalInformation",
          component: PersonalInformation,
        },
        {
          path: "Salary",
          name: "Salary",
          component: Salary,
        },
        {
          path: "PersonalIncomeTax",
          name: "PersonalIncomeTax",
          component: PersonalIncomeTax,
        },

        {
          path: "SocialSecurity",
          name: "SocialSecurity",
          component: SocialSecurity,
        },
        {
          path: "ProvidentFund",
          name: "ProvidentFund",
          component: ProvidentFund,
        },
        {
          path: "HealthInformation",
          name: "HealthInformation",
          component: HealthInformation,
        },
        {
          path: "PersonalBenefit",
          name: "PersonalBenefit",
          component: PersonalBenefit,
        },
        {
          path: "Guarantor",
          name: "Guarantor",
          component: Guarantor,
        },
        {
          path: "Family",
          name: "Family",
          component: Family,
        },
        // {
        //   path:"Account",
        //   name:"Account",
        //   component: Account,
        // }
      ],
    },
    {
      path: "/selfservice/PersonalProfile/PersonalInformation/Salary/SalaryChangeHistory/:sid",
      name: "PersonalSalaryHistory",
      component: SalarychangeHistory,
    },
    {
      path: "/selfservice/PersonalProfile/PersonalInformation/PersonalInfoID",
      name: "PersonalInfoID",
      component: PersonalID,
    },
    {
      path: "/selfservice/PersonalProfile/PersonalInformation/WorkPermit",
      name: "PersonalInfoWorkPermit",
      component: WorkPermit,
    },
    {
      path: "/selfservice/PersonalProfile/PersonalInformation/OtherInformation",
      name: "PersonalOtherInfo",
      component: Otherinformation,
    },
    {
      path: "/selfservice/PersonalProfile/PersonalInformation/Passport",
      name: "PersonalPassport",
      component: Passport,
    },
    {
      path: "/selfservice/PersonalProfile/PersonalInformation/EditEducational/:eduid",
      name: "PersonalEducationBackground",
      component: EditEducational,
    },
    {
      path: "/selfservice/PersonalProfile/PersonalInformation/DepartmentTransfer/:personalid",
      name: "PersonalDepartmentTransferr1",
      component: DepartmentTransfer,
    },

    {
      path: "/PersonnelManagement",
      name: "PersonnelManagement",
      component: EmployeeList,
    },
    //New Employee
    {
      path: "/PersonnelManagement/NewEmployee/:nid",
      name: "NewEmployee",
      component: personal,
      props: true,
      children: [
        {
          path: "",
          name: "NewPersonalInformation",
          component: PersonalInformation,
        },
        {
          path: "Salary",
          name: "NewSalary",
          component: Salary,
        },
        {
          path: "PersonalIncomeTax",
          name: "NewPersonalIncomeTax",
          component: PersonalIncomeTax,
        },

        {
          path: "SocialSecurity",
          name: "NewSocialSecurity",
          component: SocialSecurity,
        },
        {
          path: "ProvidentFund",
          name: "NewProvidentFund",
          component: ProvidentFund,
        },
        {
          path: "HealthInformation",
          name: "NewHealthInformation",
          component: HealthInformation,
        },
        {
          path: "PersonalBenefit",
          name: "NewPersonalBenefit",
          component: PersonalBenefit,
        },
        {
          path: "Guarantor",
          name: "NewGuarantor",
          component: Guarantor,
        },
        {
          path: "Family",
          name: "NewFamily",
          component: Family,
        },
        {
          path: "Account",
          name: "NewAccount",
          component: Account,
        },
      ],
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/PersonalID",
      name: "newpersonalid",
      component: PersonalID,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/EducationalBackground",
      name: "neweducationbackground",
      component: Educationalbackground,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/OtherInformation",
      name: "newotherinformation",
      component: Otherinformation,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/Passport",
      name: "newpassport",
      component: Passport,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/DepartmentTransfer",
      name: "newdepartmenttransferr",
      component: DepartmentTransfer,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/WorkPermit",
      name: "newworkpermit",
      component: WorkPermit,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/Salary/SalaryChangehistory",
      name: "newsalarychangehistory",
      component: SalarychangeHistory,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/NewAccount/:usergroup",
      name: "NUserGroup",
      component: NUserGroup,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/NewAccount/:role",
      name: "NRole",
      component: NRole,
    },
    {
      path: "/PersonnelManagement/NewEmployee/NewPersonalInformation/NewAccount/:permission",
      name: "NPermission",
      component: NPermission,
    },

    //Edit Employee
    {
      path: "/PersonnelManagement/EditEmployee/:id/:editid",
      name: "EditEmployee",
      component: personal,
      props: true,
      children: [
        {
          path: "",
          name: "EditPersonalInformation",
          component: PersonalInformation,
        },
        {
          path: "Salary",
          name: "EditSalary",
          component: Salary,
        },
        {
          path: "PersonalIncomeTax",
          name: "EditPersonalIncomeTax",
          component: PersonalIncomeTax,
        },

        {
          path: "SocialSecurity",
          name: "EditSocialSecurity",
          component: SocialSecurity,
        },
        {
          path: "ProvidentFund",
          name: "EditProvidentFund",
          component: ProvidentFund,
        },
        {
          path: "HealthInformation",
          name: "EditHealthInformation",
          component: HealthInformation,
        },
        {
          path: "PersonalBenefit",
          name: "EditPersonalBenefit",
          component: PersonalBenefit,
        },
        {
          path: "Guarantor",
          name: "EditGuarantor",
          component: Guarantor,
        },
        {
          path: "Family",
          name: "EditFamily",
          component: Family,
        },
        {
          path: "Account",
          name: "EditAccount",
          component: Account,
        },
      ],
    },
    {
      path: "/PersonnelManagement/EditEmployee/:id/EditPersonalInformation/:personalid",
      name: "personalid1",
      component: PersonalID,
    },
    {
      path: "/PersonnelManagement/EditEmployee/:id/EditPersonalInformation/:passportid",
      name: "passport1",
      component: Passport,
    },
    {
      path: "/PersonnelManagement/EditEmployee/:id/EditPersonalInformation/EditOtherInfo/:otherid",
      name: "otherinformation1",
      component: Otherinformation,
    },
    {
      path: "/PersonnelManagement/EditEmployee/:id/EditPersonalInformation/EditEducational/:eduid",
      name: "editEducational",
      component: EditEducational,
    },
    {
      path: "/PersonnelManagement/EditEmployee/:id/EditPersonalInformation/DepartmentTransfer/:personalid",
      name: "departmenttransfer",
      component: DepartmentTransfer,
    },
    {
      path: "/PersonnelManagement/EditEmployee/:id/EditPersonalInformation/EditWorkPermit/:workid",
      name: "workpermit1",
      component: WorkPermit,
    },
    {
      path: "/PersonnelManagement/EditEmployee/:id/EditPersonalInformation/Salary/EditedSalaryChangeHistory/:salaryid",
      name: "salarychangehistory1",
      component: SalarychangeHistory,
    },
    {
      path: "/PersonnelManagement/EditEmployee/EditPersonalInformation/Account/:id/:usergroup",
      name: "PUserGroup",
      component: PUserGroup,
    },
    {
      path: "/PersonnelManagement/EditEmployee/EditPersonalInformation/Account/:id/:role",
      name: "PRole",
      component: PRole,
    },
    {
      path: "/PersonnelManagement/EditEmployee/EditPersonalInformation/Account/:id/:permission",
      name: "PPermission",
      component: PPermission,
    },

    {
      path: "/salary",
      name: "salary",
      component: salary1,
    },
    // {
    //   path: "/healthinformation",
    //   name: "HealthInformation",
    //   component: HealthInformation,
    // },
    {
      path: "/MyTimeOffRequest",
      name: "MyTimeOffRequests",
      component: MyTimeOffRequest,
    },
    {
      path: "/EmployeesTimeOffRequest",
      name: "EmployeesTimeOffRequest",
      component: EmployeesTimeOffRequest,
    },
    {
      path: "/EmployeesTimeOffRequest/New",
      name: "NewEmployeesTimeOffRequest",
      component: NewEmployeesTimeOffRequest,
    },
    {
      path: "/MyTimeOffRequest/:notiString",
      name: "MyTimeOffRequestNoti",
      component: MyTimeOffRequest,
    },
    // {
    //   path: "/TimeOff/Setting/:id",
    //   component: TimeOffSetting,
    //   props: true,
    //   children: [
    //     {
    //       path: "",
    //       name: "timeofftap",
    //       component: TimeOffTap,
    //     },
    //   ],
    // },
    {
      path: "/NewTimeOffRequest",
      name: "NewTimeOffRequests",
      component: NewTimeOffRequest,
    },

    //Master Date
    {
      path: "/masterdata",
      name: "Masterdata",
      component: MasterData,
    },

    {
      path: "/masterdata/MOrganization",
      name: "Organization",
      component: Organization,
    },
    {
      path: "/masterdata/MDepartment",
      name: "MDepartment",
      component: MDepartment,
    },
    {
      path: "/masterdata/positionmasterdata",
      name: "position1",
      component: Position,
    },
    {
      path: "/masterdata/Address",
      name: "Address",
      component: Address,
    },
    {
      path: "/masterdata/SocialSecurityFund",
      name: "SocialSecurityFund",
      component: SocialSecurityFund,
    },
    {
      path: "/masterdata/providentfund",
      name: "providentfund",
      component: Providentfund,
    },
    {
      path: "/masterdata/Bank",
      name: "Bank",
      component: Bank,
    },
    {
      path: "/masterdata/shiftcode",
      name: "MShiftcode",
      component: shiftcode,
    },
    {
      path: "/masterdata/MBenefits",
      name: "MBenefits",
      component: MBenefits,
    },
    {
      path: "/masterdata/incometax",
      name: "incometax",
      component: IncomeTax,
    },
    {
      path: "/masterdata/incometype",
      name: "incometype",
      component: IncomeType,
    },
    {
      path: "/masterdata/PayRollAccounts",
      name: "PayRollAccounts",
      component: PayRollAccounts,
    },

    //Master Date

    //hansontabletest
    {
      path: "/hansontable",
      name: "Hansontable",
      component: Hansontable,
    },

    //Usermanagement
    {
      path: "/usermanagement/:id",
      component: Usermanagement,
      props: true,
      children: [
        // {
        //   path: "",
        //   name: "user",
        //   component: User,
        // },
        {
          path: "",
          name: "usergroup",
          component: Usergroup,
        },
        {
          path: "permission",
          name: "permission",
          component: Permission,
        },
        {
          path: "role",
          name: "role",
          component: Role,
        },
      ],
    },

    {
      path: "/usermanagement/usergroup/:usergroupadd",
      name: "usergroupadd",
      component: UsergroupAdd,
    },
    {
      path: "/usermanagement/usergroup/:usergroupedit/:ugid",
      name: "usergroupedit",
      component: UsergroupEdit,
    },
    {
      path: "/usermanagement/usergroup/:usergroupedit/:ugid/:ugmem",
      name: "usergroupeditmember",
      component: UsergroupEditMember,
    },
    {
      path: "/usermanagement/usergroup/:usergroupedit/:ugid/:ugper",
      name: "usergroupeditpermission",
      component: UsergroupEditPermission,
    },
    {
      path: "/usermanagement/usergroup/:usergroupedit/:ugid/:ugrole",
      name: "usergroupeditrole",
      component: UsergroupEditRole,
    },
    {
      path: "/usermanagement/usergroup/:ugmem",
      name: "usergroupmember",
      component: UsergroupMember,
    },
    {
      path: "/usermanagement/usergroup/:ugper",
      name: "usergrouppermission",
      component: UsergroupPermission,
    },
    {
      path: "/usermanagement/usergroup/:ugrole",
      name: "usergrouprole",
      component: UsergroupRole,
    },

    {
      path: "/usermanagement/usergroup/role/:roleadd",
      name: "roleadd",
      component: RoleAdd,
    },
    {
      path: "/usermanagement/usergroup/role/:roleedit/:rid",
      name: "roleedit",
      component: RoleEdit,
    },
    {
      path: "/usermanagement/usergroup/role/:roleper",
      name: "roleper",
      component: RolePermission,
    },
    {
      path: "/usermanagement/usergroup/role/roleeditper/:role/:rid/:roleper",
      name: "roleeditper",
      component: RoleEditPermission,
    },
    {
      path: "/usermanagement/usergroup/newuser/:adduser",
      name: "newuser",
      component: Newuser,
    },
    {
      path: "/usermanagement/usergroup/useredit/:uid",
      name: "useredit",
      component: EditUser,
    },
    {
      path: "/usermanagement/usergroup/usereditgp/:uid/:usergroup",
      name: "usereditgp",
      component: UserEditUserGroup,
    },
    {
      path: "/usermanagement/usergroup/usereditrole/:uid/:role",
      name: "usereditrole",
      component: UserEditRole,
    },
    {
      path: "/usermanagement/usergroup/usereditper/:uid/:permission",
      name: "usereditper",
      component: UserEditPermission,
    },
    {
      path: "/usermanagement/usergroup/newuser/newuser/newuserassign",
      name: "newuserassign",
      component: NewUserAssign,
    },
    {
      path: "/usermanagement/usergroup/newuser/newuser/newuserassignugp",
      name: "newuserassignugp",
      component: NewUserAssignUgp,
    },
    {
      path: "/usermanagement/usergroup/newuser/newuser/newuserassignrole",
      name: "newuserassignrole",
      component: NewUserAssignRole,
    },
    {
      path: "/usermanagement/usergroup/newuser/newuser/newuserassignper",
      name: "newuserassignper",
      component: NewUserAssignPermis,
    },

    //CompanySetup //Setting

    {
      path: "/companysetup/:id",
      component: CompanySetUp,
      props: true,
      children: [
        {
          path: "",
          name: "timeAttendance",
          component: TimeAttendance,
        },
        {
          path: "timeoff",
          name: "timeOff",
          component: TimeOff,
        },
      ],
    },

    //BrandIdentity //Setting
    { path: "/OtherSetting", name: "OtherSetting", component: OtherSetting },
    {
      path: "/BrandIdentity",
      name: "BrandIdentity",
      component: BrandIdentity,
    },
    {
      path: "/DefaultLanguage",
      name: "DefaultLanguage",
      component: DefaultLanguage,
    },

    {
      path: "/HelpFeedback/:id",
      component: HelpFeedback,
      props: true,
      children: [
        {
          path: "",
          name: "NewFeedback",
          component: NewFeedback,
        },

        {
          path: "SentFeedback",
          name: "OldFeedback",
          component: OldFeedback,
        },
        {
          path: "SentFeedback/:notiString",
          name: "HelpAndFeedbackNotification",
          component: OldFeedback,
        },
      ],
    },
    //UsagePlan
    {
      path: "/UsagePlan",
      name: "UsagePlan",
      component: UsagePlan,
    },

    //time attendance
    {
      path: "/timeEntries",
      name: "timeentries",
      component: TimeEntries,
    },
    {
      path: "/timeEntries/timeentriessheetview/:id",
      name: "timeentriessheetview",
      component: TimeEntriesSheetView,
    },
    {
      path: "/StaffTimeAttendance",
      name: "StaffTimeAttendance",
      component: StaffTimeAttendance,
    },
    {
      path: "/EmployeeOTrequest/NewOTReq",
      name: "MyOTrequest",
      component: HROT,
    },
    {
      path: "/SyncTimedata",
      name: "SyncTimedata",
      component: SyncTimedata,
    },
    {
      path: "/StaffTimeAttendance/StaffTimeAttendanceCal/:id",
      name: "StaffTimeAttendanceCal",
      component: StaffTimeAttendanceCal,
    },
    {
      path: "/StaffTimeAttendance/Timesheetdetail/:id",
      name: "Timesheetdetail",
      component: Timesheetdetail,
    },
    {
      path: "/StaffTimeAttendance/Monthsheetdetail/:id",
      name: "Monthsheetdetail",
      component: Monthsheetdetail,
    },
    {
      path: "/StaffTimeAttendance/Monthsheetdetail/Timesheetdetail/:id",
      name: "Timesheetdetail1",
      component: Timesheetdetail,
    },
    {
      path: "/Workschedule",
      name: "Workschedule",
      component: Workschedule,
    },
    // {
    //   path: "/MyOTrequest",
    //   name: "MyOTrequest",
    //   component: HROT,
    // },

    {
      path: "/EmployeeOTrequest",
      name: "EmployeeOTrequest",
      component: OT,
    },
    {
      path: "/MyOTrequest/:notiString",
      name: "MyOTrequestNoti",
      component: HROT,
    },
    {
      path: "/StaffOT",
      name: "StaffOT",
      component: StaffOT,
    },
    {
      path: "/OTrequest",
      name: "OTrequest",
      component: OTrequest,
    },

    // Payroll
    {
      path: "/Payrolls",
      name: "PayRoll",
      component: MainPayRoll,
    },
    {
      path: "/PayRoll/Setting/:id",
      component: PayRoll,
      props: true,
      children: [
        {
          path: "",
          name: "payrollcalculationperiod",
          component: PayRollCalculationPeriod,
        },

        {
          path: "payrollaccount",
          name: "PayrollAccount",
          component: PayRollAccount,
        },
        {
          path: "Deductionsetting",
          name: "Deductionsetting",
          component: Deductionsetting,
        },
      ],
    },
    //Self-Service
    // {
    //   path: "/selfservice/personalprofile",
    //   name: "PersonalProfile",
    //   component: PersonalProfile,
    // },
    {
      path: "/selfservice/timeentries",
      name: "TimeEntries",
      component: Timeentries,
    },
    {
      path: "/selfservice/timeentries/timedetail/:id",
      name: "TimeEntriesSheetDetail",
      component: TimeEntriesSheetDetail,
    },
    {
      path: "/selfservice/myotrequest",
      name: "MyOTRequest",
      component: MyOT,
    },
    {
      path: "/selfservice/myotrequest/:notiString",
      name: "MyOTrequestNotification",
      component: MyOT,
    },
    {
      path: "/selfservice/mytimeoffrequest",
      name: "MyTimeOffRequest",
      component: MyTimeOff,
    },
    {
      path: "/selfservice/mytimeoffrequest/:notiString",
      name: "MyTimeOffRequestNotification",
      component: MyTimeOff,
    },
    {
      path: "/selfservice/mytimeoffrequest/newtimeoff",
      name: "NewTimeOffRequest",
      component: NewTimeOffRequest,
    },
    {
      path: "/selfservice/changepassword",
      name: "ChangePassword",
      component: ChangePassword,
    },
    {
      path: "/selfservice/salarydocument",
      name: "SalaryDocument",
      component: SalaryDocument,
    },
    // {
    //   path:"/"
    // },

    //version and Release
    {
      path: "/Version",
      name: "Version",
      component: Version,
    },

    //see all Notification

    {
      path: "/Notification",
      name: "Notification",
      component: Notification,
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});
export default router;
