<script>
  import { Bar } from 'vue-chartjs'

  export default {
    extends: Bar,
    // data() {
    //   return {
    //     chartData: {
    //       labels: ["20-25y", "26-30y","31-35y","36-40y","41-45y","46-50y","aye old nay lite"
    //       ],
    //       datasets: [{
            
    //         borderWidth: 1,
    //         barThickness: 80,
    //         backgroundColor: '#F89D1F',
    //         borderColor: [
    //           'rgba(255,99,132,1)',
    //           'rgba(54, 162, 235, 1)',
    //           'rgba(255, 206, 86, 1)',
    //           'rgba(75, 192, 192, 1)',
    //           'rgba(153, 102, 255, 1)',
    //           'rgba(255, 159, 64, 1)',
    //           'rgba(255,99,132,1)',
    //           'rgba(54, 162, 235, 1)',
    //           'rgba(255, 206, 86, 1)',
    //           'rgba(75, 192, 192, 1)',
    //           'rgba(153, 102, 255, 1)',
    //           'rgba(255, 159, 64, 1)'
    //         ],
    //         pointBorderColor: '#2554FF',
    //         data: [20,12,10,20,40]
    //       }]
    //     },
    //     options: {
    //       scales: {
    //         yAxes: [{
    //           ticks: {
    //             beginAtZero: true
    //           },
    //           gridLines: {
    //             drawBorder: true, lineWidth: 0 ,
    //             borderWidth:200,
                
    //           },
             
    //         }],
    //         xAxes: [{
    //           gridLines: {
    //            drawBorder: true, lineWidth: 0 
    //           }
    //         }]
    //       },
    //       legend: {
    //         display: true
    //       },
    //       responsive: true,
        
    //       maintainAspectRatio: false
    //     }
    //   }
    // },
  

    props: ['chartData', 'options'],
    mounted() {
      this.renderBarChart();
    },
    methods: {
        renderBarChart: function(){
            this.renderChart(this.chartData, this.options);
        }
    },
    watch: {
        chartData: function () {
            this.renderBarChart();
        }
    }
  }
</script>