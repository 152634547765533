<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="ml-4 headtext txt-header">
        {{ $t("Payroll") }}
      </p>
    </div>
    
    <v-row style="margin-right: 0; margin-left: -2px">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pt-1 mb-5 card" height="100%">
          <div class="di">
            <v-divider v-if="salaryhistory == true || lastpaychange == true || ssf == true" />
            <v-list v-show="salaryhistory">
              <v-list-item router to="/payrollreport/salaryhistory">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.salaryhis"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("salaryhistoryrp")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="salaryhistory" />
            <v-list v-show="lastpaychange">
              <v-list-item router to="/payrollreport/lastpaychange">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.lastpay"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("lastpaychangerp")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="lastpaychange" />
            <v-list v-show="ssf">
              <v-list-item router to="/payrollreport/socialreport">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.socialreport"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("socialsecreport") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="ssf" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      salaryhis: "Salary History​",
      paychange: "Last Pay Change",
      item: {
        lastpay: require("@/assets/images/Icon metro-money@2x.png"),
        salaryhis: require("@/assets/images/salary@2x.png"),
        socialreport: require("@/assets/images/Icon metro-security.png"),
      },

      salaryhistory: false,
      lastpaychange: false,
      ssf: false,
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      this.addition = this.salaryhistory = this.lastpaychange = this.ssf = true;
    }
    // if (perid.some((e) => e == 49)) {
    //   this.items[0].showthis = true;
    // }

    if (perid.some((e) => e == 178)) {
      this.salaryhistory = true;
    }
    if (perid.some((e) => e == 180)) {
      this.lastpaychange = true;
    }
    if (perid.some((e) => e == 184)) {
      this.ssf = true;
    }
  },
};
</script>
<style scoped>
.card {
  /* margin-bottom: 5rem; */
  margin-left: 1%;
  margin-right: 0.3%;
  /* height: 1000px; */
  /* margin-top: -1.5rem; */
}
.titles {
  color: #f89d1f;
  font-weight: bold;
  font-size: 17px;
  margin-top: -1rem;
}
.di {
  margin: 20px;
  margin-bottom: 3rem;
}
</style>

