<template>
  <div>
    <!-- <div
      style="
        padding-left: 0px;
        padding-right: 1%;
        margin-bottom: 1rem;
        margin-left: 1rem;
      "
    >
      <v-row class="mr-0">
        <v-btn @click="back()" icon color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p
          class="headtext"
          style="
            cursor: pointer;
            font-size: 25px;
            font-family: 'Kanit';
            margin-left: 4px;
            margin-top: 20px;
          "
          @click="back()"
        >
          {{ $t("salaryhistoryrp") }}
        </p>
      </v-row>
    </div> -->
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" style="cursor: pointer" class="mt-6 ml-1" @click="back()">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("salaryhistoryrp") }}
        </p>
    </div>
    
    <v-card class="pt-4 commonmb" style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="ml-5">
        <v-col cols="12" xs="12" sm="3" md="6" lg="3" xl="2">
          <v-row class="pt-2" style="height: 30px; padding-top: 5px">
            <p style="color: #8a8c8e; margin-left: 15px; margin-top: 10px">
              {{ $t("Year") }}
            </p>
            <p :style="thethaihead ? 'padding-left:110px;' : ''" style="color: #8a8c8e; padding-left: 86px; margin-top: 10px">
              {{ $t("Month") }}
            </p>
          </v-row>
          <div
            id="duo"
            class="d-flex"
            style="
              background-color: #f99d20;
              border-radius: 5px;
              margin-top: 25px;
            "
          >
            <date-picker
              name="payrollyearpicker"
              v-model="todayyears"
              v-on:change="getstart()"
              type="year"
              color="#F99D20"
              class="minizebox ml-1 mr-1 mt-1 mb-1 pl-0 today_years"
            >
              <template slot="icon-calendar">
                <img
                  src="@/assets/images/down1@2x.png"
                  style="
                    width: 30px;
                    height: 15px;
                    margin-top: 4px;
                    margin-right: -6px;
                  "
                  class="pl-2"
                />
              </template>
            </date-picker>
            <v-autocomplete
              class="selectbox minizebox ml-0 mt-1 mr-1 mb-1 pl-1"
              v-model="monthselet"
              height="34px"
              single-line
              :items="months"
              item-text="txt"
              item-value="val"
              background-color="#FFFFFF"
              color="#f99d20"
              persistent-hint
              v-on:change="getstart()"
              style="max-width: 200px"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
          <v-text-field
            :placeholder="$t('SearchEmployee')"
            v-model.lazy="searchtxt"
            color="#F99D20"
            dense
            class="mt-7 pt-1"
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
          <v-select
            :items="options"
            item-text="department_Name"
            item-value="department_Name"
            @change="Apply"
            :label="$t('department')"
            v-model="theDep"
            color="#F99D20"
            class="movelf ml-2 mt-8"
            outlined
            persistent-hint
            multiple
            style="border-radius: 4px"
            dense
          >
            <img
              src="@/assets/images/filter-svgrepo-com (1) 1.svg"
              height="24"
              width="24"
            />
            <template v-slot:selection="{ index }">
              <span v-if="index === 0">
                {{ $t("department") }} ({{ theDep.length }})</span
              >
            </template>
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2" class="pl-0">
          <v-autocomplete
            ref="input"
            :items="exportItem"
            item-text="exptxt"
            item-value="expval"
            color="#F99D20"
            class="movelf mt-8"
            outlined
            v-model="expit"
            :placeholder="$t(`Export`)"
            style="max-width: 180px; border-radius: 4px; margin-top: 2.5rem"
            dense
            v-on:change="changetoexport(expit)"
            :disabled="permission.export"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- <v-row class="pl-md-0 mt-0" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
          <p class="mt-0">{{ $t("Month") }}</p>
          <v-autocomplete
            :items="months"
            item-text="txt"
            item-value="val"
            color="#F99D20"
            class="movelf"
            outlined
            v-model="monthselet"
            style="min-width: 130px; max-width: 130px; border-radius: 15px"
            dense
            v-on:change="getstart()"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
        <v-col class="ml-4" cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
          <p class="mt-0">{{ $t("Year") }}</p>
          <date-picker
            v-model="todayyears"
            v-on:change="getstart()"
            type="year"
            class="mr-4 mb-1 pl-0"
            style="min-width: 100px; max-width: 100px; border-radius: 15px"
          >
            <template slot="icon-calendar">
              <img
                src="@/assets/images/down1@2x.png"
                style="
                  width: 20px;
                  height: 20px;
                  margin-right: -7px;
                  padding-top: 3px;
                "
              />
            </template>
          </date-picker>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4" lg="2" xl="2">
          <p class="mt-0">{{ $t("Employees") }}</p>
          <v-autocomplete
            :items="empsItem"
            item-text="emptxt"
            item-value="empval"
            color="#F99D20"
            class="movelf"
            outlined
            v-model="employsort"
            style="min-width: 160px; max-width: 160px; border-radius: 15px"
            dense
            v-on:change="changetoshowDep(employsort)"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
        <v-col v-if="showDep" cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
          <p class="mt-0 ml-2">{{ $t("department") }}</p>
          <v-select
            :items="options"
            item-text="department_Name"
            item-value="department_Name"
            v-model="theDep"
            color="#F99D20"
            class="movelf ml-2"
            outlined
            multiple
            style="min-width: 170px; max-width: 170px; border-radius: 15px"
            dense
          >
            <template v-slot:selection="{ index }">
              <span v-if="index === 0">
                {{ $t("department") }} ({{ theDep.length }})</span
              >
            </template>
          </v-select>
        </v-col>
        <v-col v-if="showDep" cols="12" xs="12" sm="4" md="4" lg="3" xl="2">
          <v-row class="lefz" style="margin-top: 2.6rem">
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize ml-3 mr-5"
              width="150"
              max-height="35"
              max-width="100"
              min-width="100"
              @click="Apply()"
              >{{ $t("Apply") }}</v-btn
            >
            <p class="mt-2" style="cursor: pointer" @click="reseto()">
              {{ $t("Reset") }}
            </p>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="mr-5" cols="12" xs="12" sm="4" md="3" lg="2" xl="1">
          <p class="mt-0">{{ $t("Showing") }}</p>
          <v-autocomplete
            :items="showingItem"
            item-text="showtxt"
            item-value="showval"
            color="#F99D20"
            class="movelf"
            outlined
            v-model="showact"
            style="min-width: 140px; max-width: 140px; border-radius: 15px"
            dense
            v-on:change="getstart()"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-autocomplete
            :items="exportItem"
            item-text="exptxt"
            item-value="expval"
            color="#F99D20"
            class="movelf"
            outlined
            v-model="expit"
            :placeholder="$t(`Export`)"
            style="
              min-width: 130px;
              max-width: 130px;
              border-radius: 15px;
              margin-top: 2.5rem;
            "
            dense
            @click="getstart()"
            v-on:change="changetoexport(expit)"
            :disabled="permission.export"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
      </v-row> -->
      <v-row class="ma-3 ml-6">
        <v-tabs class="typetabs" center-active style="border-bottom: 1px solid #eaeaea">
          <v-tabs-slider color="#F89D27"></v-tabs-slider>
          <v-tab class="text-capitalize" @click="showAllTabs()">
            {{ this.$t("Showall") }}
          </v-tab>
          <v-tab
            v-for="(date, index) in allDate"
            :key="index"
            class="text-capitalize"
            @click="changeTab(date)"
          >
            {{ DateFormat(date.payment_Date) }}
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </v-row>
      <v-row class="ml-4">
        <v-col cols="12" xl="1" lg="2" md="4" class="pb-0">
          <p v-show="showAll" style="font-size: 16px; font-family: 'Kanit'">
            {{ $t("Transaction") }}
          </p>
          <p v-show="!showAll" style="font-size: 16px; font-family: 'Kanit'">
            {{ $t("PersonnelManagement") }}
          </p>
          <p
            style="
              font-size: 18px;
              font-family: 'Kanit';
              color: #f99d20;
              margin-bottom: 0px !important;
            ">
            {{ salaryHistoryList.length }}
          </p>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" xl="1" lg="2" md="4" class="pb-0">
          <p style="font-size: 16px; font-family: 'Kanit'">
            {{ $t("TotalNetPaid") }}
          </p>
          <p
            style="
              font-size: 18px;
              font-family: 'Kanit';
              color: #f99d20;
              margin-bottom: 0px !important;
            ">
            {{ totalNetPaid }}
          </p>
        </v-col>
      </v-row>
      <div class="mt-5">
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem;text-align:right;"
          :headers="headers"
          :items="salaryHistoryList"
          :search="searchtxt"
          hide-default-footer
          hide-default-header
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th style="min-width: 120px;text-align: right;">{{ $t("EmployeeID") }}</th>
                <th style="min-width: 180px;text-align: right;">{{ $t("employee_name") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("position") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("department") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("Salary") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("Overtime") }}</th>
                <th style="min-width: 120px;text-align: right;"
                  v-show="!thethaihead"
                  v-for="(item, index) in incomeList"
                  :key="index"
                >
                  {{ item.incomeTypeName_EN }}
                </th>
                <th style="min-width: 120px;text-align: right;"
                  v-show="thethaihead"
                  v-for="item in incomeTHList"
                  :key="item.income_Type_ID"
                >
                  {{ item.incomeTypeName_TH }}
                </th>
                <th style="min-width: 130px;text-align: right;">{{ $t("TotalIncome") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("PersonalTax") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("social_security") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("provident_fund") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("OtherDedu") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("LateDeduction") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("NotStampDeduction") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("TotalDedu") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("NetPaid") }}</th>
                <th style="min-width: 130px;text-align: right;">{{ $t("PaymentDate") }}</th>
              </tr>
            </thead>
          </template>
        </v-data-table>
        <div v-show="showtablerow" class="text-right">
          <PaginationComponent
              :page.sync="page"
              :pageCount="pageCount"
              :itemsPerPage.sync="itemsPerPage"
            />
          <!-- <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#f99d20"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row> -->
        </div>
      </div>
    </v-card>

    <!-- print dialog -->
    <v-dialog v-model="printDialog" max-width="100%">
      <v-card style="min-width: 100%">
        <div class="canvas_div_pdf">
          <v-card-text class="print-break-page" ref="content">
            <div style="margin-left: 1rem">
              <v-row class="pl-md-0 mt-0 pt-2">
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                  <p
                    class="headtext"
                    style="font-size: 28px; font-family: 'Kanit'"
                  >
                    {{ $t("salaryhistory") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  class="mr-0 pr-0"
                  cols="3"
                  xs="6"
                  sm="2"
                  md="1"
                  lg="1"
                  xl="1"
                >
                  <v-img
                    class="ggg"
                    style="padding: -10px; padding-right: 0px"
                    :src="logo.image"
                    max-height="60"
                    max-width="60"
                    min-width="60"
                    min-height="60"
                    contain
                  />
                </v-col>
                <v-col
                  style="min-width: 150px"
                  cols="4"
                  xs="6"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="mt-2 ml-0 pl-0"
                >
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: 0px;
                      font-size: 18px;
                      color: #0fa7d8 !important;
                    "
                  >
                    Optimistic
                  </p>
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: -5px;
                      font-size: 12px;
                      color: #f99d20 !important;
                    "
                  >
                    Powered by Optimistic
                  </p>
                </v-col>
              </v-row>
              <hr />
            </div>
            <div class="mt-4" id="middlecol">
              <v-data-table
                style="margin-left: 2rem; margin-right: 2rem"
                :headers="headers"
                :items="salaryHistoryList"
                hide-default-footer
                hide-default-header
                :items-per-page="itemsPerPage1"
              >
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th style="text-align: right;">{{ $t("EmployeeID") }}</th>
                      <th style="text-align: right;">{{ $t("employee_name") }}</th>
                      <th style="text-align: right;">{{ $t("position") }}</th>
                      <th style="text-align: right;">{{ $t("department") }}</th>
                      <th style="text-align: right;">{{ $t("Salary") }}</th>
                      <th style="text-align: right;">{{ $t("Overtime") }}</th>
                      <th style="text-align: right;"
                        v-show="!thethaihead"                       
                        v-for="(item, index) in incomeList"
                        :key="index"
                      >
                        {{ item.incomeTypeName_EN }}
                      </th>
                      <th style="text-align: right;"
                        v-show="thethaihead"
                        v-for="item in incomeTHList"
                        :key="item.income_Type_ID"
                      >
                        {{ item.incomeTypeName_TH }}
                      </th>
                      <th style="text-align: right;">{{ $t("TotalIncome") }}</th>
                      <th style="text-align: right;">{{ $t("PersonalTax") }}</th>
                      <th style="text-align: right;">{{ $t("social_security") }}</th>
                      <th style="min-width: 130px;text-align: right;">{{ $t("provident_fund") }}</th>
                      <th style="min-width: 130px;text-align: right;">{{ $t("OtherDedu") }}</th>
                      <th style="min-width: 130px;text-align: right;">{{ $t("LateDeduction") }}</th>
                      <th style="min-width: 130px;text-align: right;">{{ $t("NotStampDeduction") }}</th>
                      <th style="text-align: right;">{{ $t("TotalDedu") }}</th>
                      <th style="text-align: right;">{{ $t("NetPaid") }}</th>
                      <th style="text-align: right;">{{ $t("PaymentDate") }}</th>
                    </tr>
                  </thead>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-3 text-capitalize fix-btn"
            width="150"
            max-height="35"
            text
            @click="
              printDialog = false;
              expit = '';
            "
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-3 text-capitalize white--text fix-btn"
            width="150"
            max-height="35"
            style="font-family: kanit, Regular; font-weight: normal"
            @click="downloadPDF()"
          >
            {{ $t("Print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import CheckViewExport from "@/function/RolePermissions.js";
import jsPDF from "jspdf";
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import DatePicker from "vue2-datepicker";
import XLSX from "xlsx";
import exceljs from "exceljs";
import moment from "moment";
import { saveAs } from "file-saver";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';
import $ from "jquery";

export default {
  components: { DatePicker, LoadingDialog, PaginationComponent },
  mixins: [CheckViewExport],
  data() {
    return {
      permission: {
        export: true,
      },
      allDate: [],
      temparr1: [],
      dateSalary: [],
      searchtxt: "",
      NameOfCompany: "",
      url: enurl.apiUrl,
      tot: 0,
      totalnetpaid: 0,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      itemsPerPage1: 500,
      showtablerow: false,
      LoadingDialog: false,
      todaydate: new Date(),
      todayyears: new Date(),
      expit: "",
      employsort: 1,
      showact: 1,
      monthselet: "",
      showDep: false,
      sheetTitle: null,
      exportItem: [
        { expval: 1, exptxt: "Excel" },
        { expval: 2, exptxt: "PDF" },
        { expval: 3, exptxt: "CSV" },
      ],
      theExportList: [],
      headers: [],
      theDep: [],
      options: [],
      printDialog: false,
      incomeList: [],
      incomeTHList: [],
      salaryHistoryList: [],
      Temp: [],
      Temp1: [],
      Temp2: [],
      Temp3: [],
      filter: [],
      filter1: [],
      thelang: "",
      logo: {
        image: require("@/assets/images/logo@2x.png"),
      },
      showAll: true,
      currentTab: ''
    };
  },

  watch: {
    thelang() {
      this.whatthehead();
      this.getstart();
    },
  },

  computed: {
    empsItem() {
      return [
        { empval: 1, emptxt: this.$t("AllEmployees") },
        { empval: 2, emptxt: this.$t("department") },
      ];
    },

    totalNetPaid() {
      return this.praseFloatComma(this.salaryHistoryList.reduce(
        (total, item) => total + parseFloat(item.difftotal.replace(/,/g, '')),0))
    },

    showingItem() {
      return [
        { showval: 1, showtxt: this.$t("Active") },
        { showval: 2, showtxt: this.$t("Inactive") },
      ];
    },
    months() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
    thethaihead() {
      return this.whatthehead();
    },
  },

  async mounted() {
    let d = new Date();
    this.LoadingDialog = true;
    this.monthselet = d.getMonth() + 1;
    await this.GetIncomeType();
    //this.getstart();
    await this.GetDepartment();
    await this.GetCompanyInfo();
    this.permission = this.CheckViewExport(178, 179);
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem("Templang");
    }, 100);
    this.LoadingDialog = false;
  },

  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย")
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    back() {
      this.$router.push(`/payrollreport`).catch(() => {});
    },

    showAllTabs() {
      this.salaryHistoryList = this.Temp;
      this.theExportList = this.Temp1;
      this.Temp2 = this.salaryHistoryList;
      this.Temp3 = this.theExportList;
      this.showAll = true;
    },

    changeTab(date) {
      let self = this;
      self.showAll = false;
      self.currentTab = date;
      let dd = self.DateFormat(date.payment_Date);
      self.salaryHistoryList = [];
      self.theExportList = [];
      let tempp = self.Temp.filter((x) => x.payment_Date == dd && x.payroll_ID == date.payroll_ID);
      for (let i = 0; i < tempp.length; i++)
       {
        self.salaryHistoryList.push(tempp[i]);
      }
      let teeem = self.Temp1.filter((x) => (localStorage.Templang === "ประเทศไทย" ? x["วันจ่าย"] === dd : x["Payment Date"] === dd) && x.payroll_ID == date.payroll_ID);
      for (let i = 0; i < teeem.length; i++) 
      {
        self.theExportList.push(teeem[i]);
      }
      self.Temp2 = self.salaryHistoryList;
      self.Temp3 = self.theExportList;
    },

    Apply() {
      let self = this;
      if (self.theDep.length != 0) {
        self.LoadingDialog = true;
        let List = self.Temp2;
        let List1 = self.Temp3;
        self.salaryHistoryList = [];
        self.theExportList = [];
        for (let j = 0; j < self.theDep.length; j++) 
        {
          self.filter = List.filter(
            (v) => v.department_Name === self.theDep[j]
          );
          if (localStorage.Templang == "ประเทศไทย") 
          {
            self.filter1 = List1.filter((v) => v.แผนก === self.theDep[j]);
          } 
          else 
          {
            if (sessionStorage.flag == "Thai") 
            {
              self.filter1 = List1.filter((v) => v.แผนก === self.theDep[j]);
            } 
            else 
            {
              self.filter1 = List1.filter(
                (v) => v.Department === self.theDep[j]
              );
            }
          }

          for (let i = 0; i < self.filter.length; i++) 
          {
            self.salaryHistoryList.push(self.filter[i]);
          }
          for (let i = 0; i < self.filter1.length; i++) 
          {
            self.theExportList.push(self.filter1[i]);
          }
        }
        self.LoadingDialog = false;
      }
      else 
      {
        //self.getstart();
        self.salaryHistoryList = self.Temp2;
        self.theExportList = self.Temp3;
      }
    },

    reseto() {
      let self = this;
      self.employsort = 1;
      self.showDep = false;
      self.theDep = [];
      self.getstart();
    },

    changetoshowDep(x) {
      let self = this;
      if (x == 2) 
      {
        self.showDep = true;
      } 
      else 
      {
        self.showDep = false;
        self.theDep = [];
        self.getstart();
      }
    },

    async getstart() {
      try {
        let self = this;
        self.salaryHistoryList = [];
        self.headers = [];
        self.theExportList = [];
        self.LoadingDialog = true;
        let thisYear = "";
        if (self.todayyears.getFullYear()) 
        {
          thisYear = self.todayyears.getFullYear();
        } 
        else 
        {
          self.todayyears = new Date();
          thisYear = new Date().getFullYear();
        }
        let temp = {
          year: thisYear,
          month: self.monthselet,
          active: self.showact,
          companyID: store.state.companyID,
        };
        await axios.post(`${self.url}Payment_Due/GetSalaryHistoryReport`, temp)
          .then(function (response) {
            let theList = response.data.data;
            self.allDate = theList.map(function (x) {
              return { payroll_ID : x.payroll_ID, payment_Date : x.payment_Date.replace("T00:00:00", "") }
            }).sort((a, b) => new Date(a.payment_Date) - new Date(b.payment_Date));
            self.allDate = self.allDate.filter(
              (item, index, self) =>
                index === self.findIndex((t) => (
                  t.payment_Date === item.payment_Date && t.payroll_ID === item.payroll_ID
                ))
            );
            self.headers.push(
              { text: "Employee ID", value: "employee_Code",align: 'end' },
              { text: "Employee Name", value: "employee_Name" ,align: 'end'},
              { text: "Position", value: "positionName",align: 'end' },
              { text: "Department", value: "department_Name",align: 'end'},
              { text: "Salary", value: "salary_Amount",align: 'end' },
              { text: "Overtime", value: "oT_Pay",align: 'end' }
            );
            if (self.incomeList.length != 0) 
            {
              for (let i = 0; i < self.incomeList.length; i++) 
              {
                let k = i + 1;
                self.headers.push({
                  text: self.incomeList[i].incomeTypeName_EN,
                  value: "income" + k,
                  align: 'end'
                });
              }
            }
            self.headers.push(
              { text: "Total Income", value: "totalincome",align: 'end' },
              { text: "Personal Tax", value: "tax_Month",align: 'end'},
              { text: "Social Security", value: "social_Security",align: 'end' },
              { text: "Provident Fund", value: "provident_Fund",align: 'end' },
              { text: "Other Deduction", value: "other_Deduction",align: 'end' },
              { text: "Late Deduction", value: "late_Deduction",align: 'end' },
              { text: "Not ClockIn Deduction", value: "not_ClockIn_Deduction",align: 'end' },
              { text: "Total Deduction", value: "totaldeduction",align: 'end' },
              { text: "Net Paid", value: "difftotal",align: 'end' },
              { text: "Payment Date", value: "payment_Date",align: 'end' }
            );
            if (theList.length != 0)
            {
              self.tot = theList.length;
              self.showtablerow = true;
              // let temparr1 = [];
              let temparr3 = [];
              let temparr4 = [];
              for (let j = 0; j < theList.length; j++) 
              {
                let totaldedu = theList[j].tax_Month + theList[j].social_Security + theList[j].other_Deduction + theList[j].provident_Fund + theList[j].late_Deduction + theList[j].not_ClockIn_Deduction;
                let totalinco = 0;
                for (let l = 0; l < theList[j].income_List.length; l++) 
                {
                  totalinco = totalinco + theList[j].income_List[l].income_Type_Amount;
                }
                self.temparr1.push({
                  payroll_ID: theList[j].payroll_ID,
                  employee_Code: theList[j].employee_Code,
                  employee_Name: theList[j].employee_Name,
                  positionName: theList[j].positionName,
                  salary_Amount: self.praseFloatComma(theList[j].salary_Amount),
                  oT_Pay: self.praseFloatComma(theList[j].oT_Pay),
                  totalincome: self.praseFloatComma(totalinco + parseInt(theList[j].salary_Amount) + theList[j].oT_Pay),
                  tax_Month: self.praseFloatComma(theList[j].tax_Month),
                  social_Security: self.praseFloatComma(theList[j].social_Security),
                  provident_Fund : self.praseFloatComma(theList[j].provident_Fund),
                  other_Deduction: self.praseFloatComma(theList[j].other_Deduction),
                  late_Deduction : self.praseFloatComma(theList[j].late_Deduction),
                  not_ClockIn_Deduction : self.praseFloatComma(theList[j].not_ClockIn_Deduction),
                  totaldeduction: self.praseFloatComma(totaldedu),
                  difftotal: self.praseFloatComma(totalinco + parseInt(theList[j].salary_Amount) + theList[j].oT_Pay - totaldedu),
                  department_Name: theList[j].department_Name,
                  payment_Date: self.DateFormat(theList[j].payment_Date),
                });
                let temparr = [];
                let temparr2 = [];
                if (localStorage.Templang == "ประเทศไทย") 
                {
                  temparr3.push({
                    ["รหัสพนักงาน"]: theList[j].employee_Code,
                    ["ชื่อพนักงาน"]: theList[j].employee_Name,
                    ["ตำแหน่ง"]: theList[j].positionName,
                    แผนก: theList[j].department_Name,
                    ["เงินเดือน"]: self.praseFloatComma(theList[j].salary_Amount),
                    ["ค่าล่วงเวลา"]: self.praseFloatComma(theList[j].oT_Pay),
                  });
                  temparr4.push({
                    ["รายได้รวม"]: self.praseFloatComma(totalinco + parseInt(theList[j].salary_Amount) + theList[j].oT_Pay),
                    ["ภาษีส่วนบุคคล"]: self.praseFloatComma(theList[j].tax_Month),
                    ["ประกันสังคม"]: self.praseFloatComma(theList[j].social_Security),
                    ["กองทุนสำรองเลี้ยงชีพ"]: self.praseFloatComma(theList[j].provident_Fund),
                    ["การหักเงินอื่นๆ"]: self.praseFloatComma(theList[j].other_Deduction),
                    ["การหักเงินล่าช้า"]: self.praseFloatComma(theList[j].late_Deduction),
                    ["หักเงินการไม่ลงบันทึกเวลา"]: self.praseFloatComma(theList[j].not_ClockIn_Deduction),
                    ["การหักเงินทั้งหมด"]: self.praseFloatComma(totaldedu),
                    ["เน็ตจ่าย"]: self.praseFloatComma(totalinco + parseInt(theList[j].salary_Amount) + theList[j].oT_Pay - totaldedu),
                    ["วันจ่าย"]: self.DateFormat(theList[j].payment_Date),
                    payroll_ID: theList[j].payroll_ID,
                  });
                  for (let n = 0; n < self.incomeTHList.length; n++) 
                  {
                    let me = n + 1;
                    let filter = theList[j].income_List.filter((x) => x.incomeTypeName_TH == self.incomeTHList[n].incomeTypeName_TH);
                    var totalChildth = filter.reduce((accum, item) => accum + item.income_Type_Amount,0);
                    temparr.push({["income" + me]: self.praseFloatComma(totalChildth)});
                    temparr2.push({[self.incomeTHList[n].incomeTypeName_TH]: self.praseFloatComma(totalChildth)});
                  }
                } 
                else 
                {
                  temparr3.push({
                    ["Employee ID"]: theList[j].employee_Code,
                    ["Employee Name"]: theList[j].employee_Name,
                    ["Position"]: theList[j].positionName,
                    Department: theList[j].department_Name,
                    ["Salary"]: self.praseFloatComma(theList[j].salary_Amount),
                    ["Overtime"]: self.praseFloatComma(theList[j].oT_Pay),
                  });
                  temparr4.push({
                    ["Total Income"]: self.praseFloatComma(totalinco + parseInt(theList[j].salary_Amount) + theList[j].oT_Pay),
                    ["Personal Tax"]: self.praseFloatComma(theList[j].tax_Month),
                    ["Social Security"]: self.praseFloatComma(theList[j].social_Security),
                    ["Provident Fund"]: self.praseFloatComma(theList[j].provident_Fund),
                    ["Other Deduction"]: self.praseFloatComma(theList[j].other_Deduction),
                    ["Late Deduction"]: self.praseFloatComma(theList[j].late_Deduction),
                    ["Not ClockIn Deduction"]: self.praseFloatComma(theList[j].not_ClockIn_Deduction),
                    ["Total Deduction"]: self.praseFloatComma(totaldedu),
                    ["Net Paid"]: self.praseFloatComma(totalinco + parseInt(theList[j].salary_Amount) + theList[j].oT_Pay - totaldedu),
                    ["Payment Date"]: self.DateFormat(theList[j].payment_Date),
                    payroll_ID: theList[j].payroll_ID,
                  });
                  for (let m = 0; m < self.incomeList.length; m++)
                  {
                    let me = m + 1;
                    let filter = theList[j].income_List.filter((x) => x.incomeTypeName_EN == self.incomeList[m].incomeTypeName_EN);
                    var totalChild = filter.reduce((accum, item) => accum + item.income_Type_Amount,0);
                    temparr.push({["income" + me]: self.praseFloatComma(totalChild)});
                    temparr2.push({[self.incomeList[m].incomeTypeName_EN]: self.praseFloatComma(totalChild)});
                  }
                }
                let result = self.merge(temparr, self.temparr1);
                let result1 = self.merge(temparr3, temparr2);
                let result2 = self.merge(result1, temparr4);
                self.salaryHistoryList.push(result[0]);
                self.theExportList.push(result2[0]);
              }
              self.Temp = self.salaryHistoryList;
              self.Temp1 = self.theExportList;
              self.Temp2 = self.Temp;
              self.Temp3 = self.Temp1;
              if (self.theDep.length != 0) 
              {
                let List = self.Temp;
                let List1 = self.Temp1;
                self.salaryHistoryList = [];
                self.theExportList = [];
                for (let j = 0; j < self.theDep.length; j++) 
                {
                  self.filter = List.filter((v) => v.department_Name === self.theDep[j]);
                  if (localStorage.Templang == "ประเทศไทย") 
                  {
                    self.filter1 = List1.filter((v) => v.แผนก === self.theDep[j]);
                  } 
                  else 
                  {
                    if (sessionStorage.flag == "Thai") 
                    {
                      self.filter1 = List1.filter((v) => v.แผนก === self.theDep[j]);
                    } 
                    else 
                    {
                      self.filter1 = List1.filter((v) => v.Department === self.theDep[j]);
                    }
                  }
                  for (let i = 0; i < self.filter.length; i++) 
                  {
                    self.salaryHistoryList.push(self.filter[i]);
                  }
                  for (let i = 0; i < self.filter1.length; i++) 
                  {
                    self.theExportList.push(self.filter1[i]);
                  }
                }
              }
            } 
            else 
            {
              self.showtablerow = false;
            }
            self.LoadingDialog = false;
            if(!self.showAll)
            {
              self.changeTab(self.currentTab);
            }
          });
      } 
      catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },

    praseFloatComma(item)
    {
      if (item != null) {
        let resData = parseFloat(item).toFixed(2);
        return resData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },  

    async GetDepartment() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      await axios.post(`${self.url}Department/GetDepartmentsByCompanyID`, temp)
        .then(function (response) {
          let temp = response.data.data;
          for (let i = 0; i < temp.length; i++) 
          {
            let tempimport = {
              department_Name: temp[i].department_Name,
              checked: false,
            };
            self.options.push(tempimport);
          }
        }).catch(function (error) {
          alert(error);
          this.LoadingDialog = false;
        });
    },

    async GetIncomeType() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      await axios.post(`${self.url}IncomeType/GetIncomeTypeByCompanyID`, temp)
        .then(function (response) {
          let List = response.data.data;
          self.incomeList = List.filter((v) => v.status == true);
          self.incomeTHList = List.filter((v) => v.status == true);
        }).catch(function (error) {
          alert(error);
          this.LoadingDialog = false;
        });
    },

    merge(...arrays) {
      const merged = {};
      arrays.forEach((data) =>
        data.forEach((o) => Object.assign((merged[o.name] ??= {}), o))
      );
      return Object.values(merged);
    },

    async GetCompanyInfo() {
      try 
      {
        let self = this;
        let tempID = store.state.companyID;
        let temp = {
          company_ID: tempID,
        };
        await axios.post(`${self.url}Company/GetCompanyID`, temp)
          .then(function (response) {
            self.companyList = response.data.data;
            self.NameOfCompany = self.companyList.companyName;
          }).catch(function (error) {
          alert(error);
          this.LoadingDialog = false;
        });
      } 
      catch (error) 
      {
        alert(error);
      }
    },

    parsePaymentDate(data) {     
      if(data != [] && "วันจ่าย" in data)
      {
        return [data["payroll_ID"],moment(data["วันจ่าย"], "DD/MM/YYYY").format("DD MMM YYYY")];
      }
      else
      {
        return [data["payroll_ID"],moment(data["Payment Date"], "DD/MM/YYYY").format("DD MMM YYYY")];
      }
    },

    findDuplicateDates(data) {
      let dateCount = {};
      let result = {};
      for (let key in data) 
      {
        let date = key.split(',')[1];
        if (!dateCount[date]) 
        {
            dateCount[date] = 1;
        } 
        else 
        {
            dateCount[date]++;
        }
        let newKey = key.split(',')[0] + ',' + (dateCount[date] > 1 ? date + ' (' + dateCount[date] + ')' : date)
        result[newKey] = data[key];
      }
      let entries = Object.entries(result);
      // Sort the array by date
      entries.sort((a, b) => {
          let dateA = new Date(a[0].split(',')[1].trim());
          let dateB = new Date(b[0].split(',')[1].trim());
          return dateA - dateB;
      });
      // Convert the sorted array back to an object
      let sortedResult = {};
      for (let [key, value] of entries) {
          sortedResult[key] = value;
      }
      return sortedResult;
    },

    totalRowsTH(data){
      let totalValues = {};
      let mergedData = [];
      let excludedFields = ["รหัสพนักงาน", "ชื่อพนักงาน", "ตำแหน่ง", "แผนก", "วันจ่าย", "payroll_ID"];
      for (let key in data) 
      {
        data[key].forEach(item => {
          for (let field in item) {
            if (!excludedFields.includes(field)) 
            {
              if (!totalValues[field]) 
              {
                totalValues[field] = 0;
              }
              if (item[field]) 
              {
                totalValues[field] = totalValues[field] == 0 ? item[field].replace(/,/g, '') : ( parseFloat(item[field].replace(/,/g, '')) + (parseFloat(totalValues[field].replace(/,/g, ''))));
                totalValues[field] = this.praseFloatComma(totalValues[field]);
              }
            }
          }
        });
        mergedData = {
          "รหัสพนักงาน": "",
          "ชื่อพนักงาน": "",
          "ตำแหน่ง": "",
          "วันจ่าย": "",
          ...totalValues,
          "Payment Date": ""
        };
        data[key].push(mergedData);
        totalValues = {};
      }
      return data;
    },

    totalRowsEN(data){
      let totalValues = {};
      let mergedData = [];
      let excludedFields = ["Employee ID", "Employee Name", "Position", "Department", "Payment Date", "payroll_ID"];
      for (let key in data) 
      {
        data[key].forEach(item => {
          for (let field in item) {
            if (!excludedFields.includes(field)) 
            {
              if (!totalValues[field]) 
              {
                totalValues[field] = 0;
              }
              if (item[field]) 
              {
                totalValues[field] = totalValues[field] == 0 ? item[field].replace(/,/g, '') : ( parseFloat(item[field].replace(/,/g, '')) + (parseFloat(totalValues[field].replace(/,/g, ''))));
                totalValues[field] = this.praseFloatComma(totalValues[field]);
              }
            }
          }
        });
        mergedData = {
          "Employee ID": "",
          "Employee Name": "",
          "Position": "",
          "Department": "",
          ...totalValues,
          "Payment Date": ""
        };
        data[key].push(mergedData);
        totalValues = {};
      }
      return data;
    },

    async changetoexport(v) {
      let self = this;
      if (v == 1) 
      {   
        if (self.salaryHistoryList.length != 0) 
        {
          let wsdata = self.theExportList;
          const workbook = new exceljs.Workbook();
          // Separate data based on "Payment Date" and create sheets
          let groupedData = {};
          wsdata.forEach((data) => {
            const paymentDate = self.parsePaymentDate(data);
            if (!groupedData[paymentDate]) 
            {
              groupedData[paymentDate] = [];
            }
            groupedData[paymentDate].push(data);
          });
          let duplicateArray = this.findDuplicateDates(groupedData);
          let total = localStorage.Templang == "ประเทศไทย" ? this.totalRowsTH(duplicateArray) : this.totalRowsEN(duplicateArray);
          // Create sheets and add data
          for (const paymentDate in total) 
          {
            const sheetName = paymentDate.split(',');
            const worksheet = workbook.addWorksheet(sheetName[1],{views: [{showGridLines: false}]});
            // Format the date as "11 Mar 2024"
            const currentDate = new Date();
            const monthIndex = currentDate.getMonth();
            const year = currentDate.getFullYear();
            const monthsArray = self.months;
            const translatedMonth = monthsArray.find(month => month.val === monthIndex + 1).txt;
            const formattedDate = `${currentDate.getDate()} ${translatedMonth} ${year}`;
            // Add fixed data to all sheets
            // Merge cells A1 to C1 and add Company Name
            worksheet.mergeCells("A1:B1");
            worksheet.getCell("A1").value = self.NameOfCompany;
            // Add "Date of report" in D1 and today's date in E1
            //worksheet.mergeCells("D1:E1");
            worksheet.getCell("D1").value = self.$t("ReportDate");
            //worksheet.mergeCells("F1:G1");
            worksheet.getCell("E1").value = formattedDate;
            // Add "Pay period" in D2 and current month in E2
            worksheet.mergeCells("A2:B2");
            worksheet.getCell("A2").value = self.$t("salaryReport");
            //worksheet.mergeCells("D2:E2");
            worksheet.getCell("D2").value = self.$t("Pay Period");
            //worksheet.mergeCells("F2:G2");
            worksheet.getCell("E2").value = translatedMonth;
            // Add data rows under fixed data
            const headers = Object.keys(total[paymentDate][0]);
            headers.splice(0, 0, localStorage.Templang == "ประเทศไทย" ? "ลำดับที่" : "No.");
            headers.forEach((header, index) => {
              if(header !== 'payroll_ID')
              {
                const cell = worksheet.getCell(
                `${String.fromCharCode(65 + index)}3`
              );
                cell.value = header;
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "EEEEEE" },
                };
                cell.font = {
                  bold: true,
                  color: { argb: "fa7d00" },
                };
                cell.alignment = {
                  vertical: "middle",
                  horizontal: "center",
                  wrapText: true,
                };
                cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
                // Adjust height and width based on text length
                //const textLength = header.length;
                const preferredHeight = 30; // Set your preferred height (in points)
                //const preferredWidth = Math.max(10, textLength * 1.5); // Set your preferred width (in characters)
                worksheet.getRow(3).height = preferredHeight;
                //worksheet.getColumn(index + 1).width = ( header === 'Employee Name' ||  header === 'Position' || header === 'Department' || 'ชื่อพนักงาน' ||  header === 'ตำแหน่ง' || header === 'แผนก' ) ? preferredWidth + 5 : preferredWidth;
              }
            });
            let totalLength = total[paymentDate].length - 1;
            let headerLength = headers.length - 3;
            // Add data rows under headers
            total[paymentDate].forEach((data, rowIndex) => {
              headers.forEach((header, colIndex) => {
                if(header !== 'payroll_ID')
                {
                  const cell = worksheet.getCell(
                  `${String.fromCharCode(65 + colIndex)}${4 + rowIndex}`
                  );    
                  if(totalLength === rowIndex) {
                    cell.value = colIndex == 0 ? '' : data[header];
                    cell.border = { top: { style: "thin" }, left: {}, bottom: { style: "double" }, right: {} };
                    if(headerLength === colIndex) {
                      cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffd9d9d9" } };
                    }
                  } else {
                    cell.value = colIndex == 0 ? rowIndex + 1 : data[header];
                    cell.border = {};
                  }
                  const alignLeftHeaders = ['No.', 'Employee ID', 'Employee Name', 'Position', 'Department','ลำดับที่', 'รหัสพนักงาน', 'ชื่อพนักงาน', 'ตำแหน่ง', 'แผนก'];
                  cell.alignment = {
                    vertical: "middle",
                    horizontal: alignLeftHeaders.includes(header) ? "left" : "right",
                    wrapText: true,
                  };       
                  
                  // // Remove borders for data cells
                  // if(totalLength === rowIndex)
                  // {
                  //   cell.value = colIndex == 0 ? '' : data[header];
                  //   cell.border = {
                  //   top: { style: "thin" },
                  //   left: { style: "none" },
                  //   bottom: { style: "double" },
                  //   right: { style: "none" },
                  //   };
                  //   if(headerLength === colIndex)
                  //   {
                  //     cell.fill = {
                  //     type: "pattern",
                  //     pattern: "solid",
                  //     fgColor: { argb: "ffd9d9d9" },
                  //   };
                  //   }
                  // }
                  // else
                  // {
                  //   cell.value = colIndex == 0 ? rowIndex + 1 : data[header];
                  //   cell.border = {
                  //     top: { style: "none" },
                  //     left: { style: "none" },
                  //     bottom: { style: "none" },
                  //     right: { style: "none" },
                  //   };
                  // }
                  // if(header === 'Employee Name' || header === 'Position' || header === 'Department' || header === 'ชื่อพนักงาน' || header === 'ตำแหน่ง' || header === 'แผนก')
                  // {
                  //   cell.alignment = {
                  //     vertical: "middle",
                  //     horizontal: "left",
                  //     wrapText: true,
                  //   };
                  // }
                  // else
                  // {
                  //   cell.alignment = {
                  //   vertical: "middle",
                  //   horizontal: "right",
                  //   wrapText: true,
                  // };
                  // }
                  const preferredHeight = 30;
                // const textLength = data[header].length;
                // const preferredWidth = Math.max(10, textLength * 1.5); // Set your preferred width (in characters)
                  worksheet.getRow(4).height = preferredHeight;
                // worksheet.getColumn(colIndex + 1).width = preferredWidth;
                }
              });
            });
            self.AdjustColumnWidth(worksheet);
          }
          
          // Generate file name with today's date
          const todayDate = moment().format("YYYYMMDD");
          const fileName = `Salary History Report - ${todayDate}.xlsx`;
          // Save the workbook with the generated file name
          console.log(`Excel file "${fileName}" generated successfully.`);
          workbook.xlsx.writeBuffer().then((val) => {
            let blob = new Blob([val], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            saveAs(blob, fileName);
          });
        }
        else 
        {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      } 
      else if (v == 2) 
      {
        // PDF
        self.printDialog = true;
        self.todaydate = new Date();
        self.itemsPerPage1 = self.salaryHistoryList.length;
        self.LoadingDialog = false;
      } 
      else 
      {
        //CSV
        if (self.salaryHistoryList.length != 0) 
        {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = self.theExportList;
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Salary History Report`;
          self.sheetTitle = `Salary History`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.csv`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } 
        else 
        {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      }
      this.$refs.input.reset();
    },

    AdjustColumnWidth(worksheet) {
      worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v.toString().length);
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
        column.width = maxLength + 5;
      })
    },

    downloadPDF() {
      html2canvas($(".canvas_div_pdf")[0], {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm");
        var position = 0;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save("Salary History Report.pdf");
      });
    },

    DateFormat(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${day}/${month}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },

    DateFormatonTop(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${month}/${day}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },

    DateFormatonTopbet(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${year}-${month}-${day}`;
        return output;
      } catch (err) {
        return output;
      }
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}
>>> ::-webkit-scrollbar-thumb {
  background: #f99d20;
  border-radius: 1px;
  width: 300px;
}
>>> ::-webkit-scrollbar {
  height: 8px;
}
/* >>> .mx-input {
  height: 42px;
  border-radius: 15px;
  width: 100px;
  border: 1px solid #808080ab !important;
} */
>>> .selectbox {
  width: 130px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}
.today_years {
  width: 120px !important;
  border-radius: 4px !important;
  border: none !important;
  height: 34px !important;
}
.v-tabs .v-tab--active {
  background: none !important;
}
.backGG {
  background-color: #f8f8f8;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}
.linecht {
  height: 368px;
  width: 800px;
  position: "relative";
}
.ddl {
  float: right;
}
.container {
  display: flex;
  justify-content: center;
  height: 350px;
  width: 930px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  opacity: 1;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}

.v-data-table .theme--light .v-data-table__wrapper tbody tr{
  text-align: right !important;
  color: red !important;
}

>>> .mdi-checkbox-marked::before {
  content: "\F0132";
  color: orange;
}

.print-break-page {
  page-break-after: always;
}
.ggg {
  margin-left: 30px;
}
@media print {
  body {
    overflow: auto;
    height: auto;
  }
  .scroll-y {
    height: auto;
    overflow: visible;
  }
}
@media only screen and (max-width: 850px) {
  .linecht {
    width: 200px !important;
  }
  .ddl {
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .linecht {
    width: 600px !important;
  }
  .ddl {
    float: right;
  }
}
@media (max-width: 860px) {
  .ggg {
    margin-left: 0px;
  }
}
@media (min-width: 960px) {
  .lefz {
    margin-left: 20px;
  }
}
@media (min-width: 950px) and (max-width: 1200px) {
  .ggg {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1900px) {
  .movelf {
    margin-right: 2rem;
  }
}
</style>