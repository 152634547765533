<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <p class="txt-header">
        {{ $t("HelpandFeedback") }}
      </p>
      <v-tabs
        class="mt-6"
        grey--text
        background-color="#f0f8fc"
        slider-color="#F99D20"
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          class="text-capitalize"
          v-show="tab.showthis"
          style="
            border-radius: 10px 10px 0px 5px;
            width: 180px;
            margin-right: 3px;
            font-size: 16px;
          "
          v-for="tab in tabs"
          :key="tab.id"
          :to="tab.route"
          exact
        >
          {{
            tab.name == "New Feedback" ? $t("NewFeedback") : $t("SentFeedback")
          }}
        </v-tab>
      </v-tabs>
      <v-card style="margin-bottom: 5rem">
        <router-view></router-view>
      </v-card>
    </div>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  props: ["id"],
  mixins: [utils],
  data() {
    return {
      activeTab: `/HelpFeedback/${this.id}`,
      tabs: [
        {
          id: 1,
          name: "New Feedback",
          route: `/HelpFeedback/${this.id}`,
          showthis: true,
        },
        {
          id: 2,
          name: "Sent Feedback",
          route: `/HelpFeedback/${this.id}/SentFeedback`,
          showthis: true,
        },
      ],
    };
  },
  mounted() {
    // let perid = sessionStorage.getItem("permissionID").split(",");
    // let roleID = sessionStorage.getItem("roleID");
    // if (roleID == "Customer Admin") {
    //   for (let j = 0; this.tabs.length; j++) {
    //     this.tabs[j].showthis = true;
    //   }
    // }
    // if (perid.some((e) => e == 135)) {
    //   this.tabs[0].showthis = true;
    // }
    // if (perid.some((e) => e == 137)) {
    //   this.tabs[1].showthis = true;
    // }
    // if (this.$route.path == "/HelpFeedback/NewFeedback") {
    //   for (let j = 0; this.tabs.length; j++) {
    //     if (this.tabs[j].showthis == true) {
    //       this.activeTab = this.tabs[j].route;
    //       this.$router.push(this.tabs[j].route);
    //       break;
    //     }
    //   }
    // }
  },
};
</script>
<style scoped>
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
}
.active {
  cursor: pointer !important;
}
.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}
>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
</style>