<template>
  <div>
    <div style="margin-left: 1%; display: flex; align-items: center;">
      <v-btn icon color="white" class="mt-3 ml-1">
        <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
      </v-btn>
      <p class="txt-header2 mb-0" @click="back()">
        {{ $t("home") }}
      </p>
    </div>

    <!-- Main Calendar Card -->
    <v-card class="pt-4 calendar-card">
      <v-row class="fill-height">
        <v-col>
          <!-- Toolbar for Navigation and Filters -->
          <v-sheet height="64">
            <v-toolbar flat>
              <v-menu bottom left :close-on-content-click="false" min-width="150px" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-1" outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <img class="birthdayIcon12 mr-1 mt-1" src="@/assets/images/filter-svgrepo-com 1.png" />
                    <span>{{ $t("Filter") }}</span>
                    <v-icon class="mt-1" right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <p class="mb-1">Show on Calendar</p>
                  <v-checkbox class="custom-checkbox mt-3" color="#F99D20" v-model="birthdaycheck" hide-details
                    style="margin: 0" label="Birthday"></v-checkbox>
                  <v-checkbox class="custom-checkbox mt-3" color="#F99D20" v-model="anniversarycheck" hide-details
                    style="margin: 0" label="Anniversary"></v-checkbox>
                </v-list>
              </v-menu>
              <div class="text-center" style="flex: 1; display: flex; justify-content: center; align-items: center;">
                <v-row>
                  <div style="flex-grow: 1; display: flex; justify-content: center;">
                    <img class="birthdayIcon1 mr-5 mt-2" src="@/assets/images/backimg.png" style="cursor: pointer;"
                      @click="prev" />
                    <v-toolbar-title style="font-size: 24px;" v-show="!thethaihead">{{ SortingDate }}</v-toolbar-title>
                    <v-toolbar-title style="font-size: 24px;" v-show="thethaihead">{{ SortingDateTH }}</v-toolbar-title>
                    <img class="birthdayIcon1 ml-5 mt-2" src="@/assets/images/nextimg.png" style="cursor: pointer;"
                      @click="next" />
                  </div>
                </v-row>
              </div>
            </v-toolbar>
          </v-sheet>

          <!-- Calendar Display -->
          <v-sheet min-height="600" class="ml-5 mr-5">
            <v-calendar ref="calendar" v-model="focus" :events="sortedEvents" :event-color="getEventColor" type="month"
              @click:event="showEvent" :weekday-format="fullDayFormat">
              <!-- Event Slot for Custom Event Rendering -->
              <template v-slot:event="{ event }">
                <div v-if="event.dayType === 'Leave'"
                  :class="['custom-event', { 'event-selected': event === selectedEvent }]"
                  >
                  {{ event.fullName }}
                </div>
                <div v-else :class="['custom-event1', { 'event-selected1': event === selectedEvent }]" 
                  style="margin-left: 5px; display: flex; align-items: center;">
                  <img 
                    v-if="event.dayType === 'Birthday'"
                    :class="['birthdayIcon1', { 'birthdayIcon-orange': event === selectedEvent }]"
                    :src="images.birthday" 
                    alt="birthday" 
                    style="margin-right: 5px;"
                  />
                  <img 
                    v-if="event.dayType === 'Anniversary'" 
                    :class="['birthdayIcon1', { 'birthdayIcon-orange': event === selectedEvent }]"
                    :src="images.anniversary" 
                    alt="anniversary" 
                    style="margin-right: 5px;"
                  />
                  <span class="pl-1">{{ event.fullName }}</span>
                </div>
              </template>
            </v-calendar>

            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x
              min-width="350px">
              <v-row>
                <v-col cols="2">
                  <img
                    :src="selectedEvent.profile_Image != null ? selectedEvent.profile_Image : 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png'"
                    alt="User" class="v-avatar profilesize" />
                </v-col>
                <v-col cols="10">
                  <v-row class="d-flex justify-end customemp-width">
                    <v-col>
                      <p class="mb-0" style="font-size:20px;color:#444444">{{ selectedEvent.fullName }}</p>
                      <p class="mb-0" style="font-size:14px;color:#686868">{{ selectedEvent.position }}</p>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end pt-1 pr-0">
                      <v-btn @click="clear()" icon>
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedEvent.dayType == 'Leave'" class="ml-0 mb-1 mt-2"
                    style="font-size:14px;color:#686868">
                    <img class="birthdayIcon3" src="@/assets/images/calendar-svgrepo-com (2) 1.png" alt="leave" />
                    <p class="mb-0 ml-3">{{ formatDate(selectedEvent.start) }}</p>
                    <p class="mb-0 ml-1" v-if="!selectedEvent.samedate"> - {{ formatDate(selectedEvent.end) }}</p>
                    <p class="mb-0 ml-2" v-if="!selectedEvent.wholeDay"> Half day</p>
                    <p class="mb-0 ml-5 orange--text" v-show="!thethaihead">{{ selectedEvent.timeoff_TypeEN }}</p>
                    <p class="mb-0 ml-5 orange--text" v-show="thethaihead">{{ selectedEvent.timeoff_TypeTH }}</p>
                  </v-row>
                  <v-row v-if="selectedEvent.dayType == 'Birthday'" class="ml-0 mb-1 mt-2" style="font-size:14px;">
                    <img class="birthdayIcon1" :src="images.birthday" alt="birthday" />
                    <p class="mb-0 ml-5 orange--text">Birthday!</p>
                  </v-row>
                  <v-row v-if="selectedEvent.dayType == 'Anniversary'" class="ml-0 mb-1 mt-2" style="font-size:14px;">
                    <img class="birthdayIcon1" :src="images.anniversary" alt="anniversary" />
                    <p class="mb-0 ml-5 orange--text"> {{ howmanyYear(selectedEvent.start_Date) }} Anniversary!</p>
                  </v-row>
                </v-col>
              </v-row>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>

    <!-- Loading Dialog Component -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LoadingDialog from "@/components/Loading.vue";
export default {
  components: {
    LoadingDialog,
  },
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      focus: '',
      type: 'month',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      SortingDate: "",
      SortingDateTH: "",
      todate: new Date(),
      birthdaycheck: true,
      anniversarycheck: true,
      events: [],
      thelang: "",
      lastitem: null,
      monthNames: [
        { val: 1, txt: "January" },
        { val: 2, txt: "February" },
        { val: 3, txt: "March" },
        { val: 4, txt: "April" },
        { val: 5, txt: "May" },
        { val: 6, txt: "June" },
        { val: 7, txt: "July" },
        { val: 8, txt: "August" },
        { val: 9, txt: "September" },
        { val: 10, txt: "October" },
        { val: 11, txt: "November" },
        { val: 12, txt: "December" },
      ],
      monthNamesTH: [
        { val: 1, txt: "มกราคม" },
        { val: 2, txt: "กุมภาพันธ์" },
        { val: 3, txt: "มีนาคม" },
        { val: 4, txt: "เมษายน" },
        { val: 5, txt: "พฤษภาคม" },
        { val: 6, txt: "มิถุนายน" },
        { val: 7, txt: "กรกฎาคม" },
        { val: 8, txt: "สิงหาคม" },
        { val: 9, txt: "กันยายน" },
        { val: 10, txt: "ตุลาคม" },
        { val: 11, txt: "พฤศจิกายน" },
        { val: 12, txt: "ธันวาคม" },
      ],
      choseevent: false,
      lastid: 0,
      boxPosition: { top: 0, left: 0 }
    }
  },
  computed: {
    images() {
      return this.$store.state.images;
    },
    customColumns() {
      return [
        this.$t("Sunday"),
        this.$t("Monday"),
        this.$t("Tuesday"),
        this.$t("Wednesday"),
        this.$t("Thursday"),
        this.$t("Friday"),
        this.$t("Saturday"),
      ];
    },
    sortedEvents() {
      return this.events.slice().sort((a, b) => {
        if (a.dayType === 'Leave' && b.dayType !== 'Leave') {
          return -1; // Move "Leave" events before others
        } else if (a.dayType !== 'Leave' && b.dayType === 'Leave') {
          return 1; // Keep "Leave" events first
        } else {
          return 0; // Leave order unchanged for other events
        }
      });
    },
    thethaihead() {
      return this.whatthehead();
    },
  },
  watch: {
    thelang() {
      this.whatthehead();
    },
    birthdaycheck() {
      this.updateFilteredItems();
    },
    anniversarycheck() {
      this.updateFilteredItems();
    },
    focus() {
      let caldate = new Date(this.focus);
      if (caldate.getMonth() != this.todate.getMonth()) {
        this.todate = new Date(this.todate.getFullYear(), caldate.getMonth(), 1);
        this.GetSortDate();
      }
    },
    selectedOpen() {
      if (this.selectedOpen == false) {
        this.clear();
      } else {
        this.selectedEvent = this.lastitem;
      }
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.GetSortDate();
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  methods: {
    back() {
      this.$router.push({ name: "home" }).catch(() => { });
    },
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    fullDayFormat({ weekday }) {
      return this.customColumns[weekday];
    },
    updateFilteredItems() {
      let self = this;
      let updatedItems = self.theList;
      if (!self.birthdaycheck) {
        updatedItems = updatedItems.filter(item => item.dayType !== 'Birthday');
      } if (!self.anniversarycheck) {
        updatedItems = updatedItems.filter(item => item.dayType !== 'Anniversary');
      }
      self.events = updatedItems;
      self.choseevent = true;
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      let self = this;
      self.$refs.calendar.prev();
      let month = self.todate.getMonth() - 1;
      let year = self.todate.getFullYear();
      self.todate = new Date(year, month, 1);
      self.GetSortDate();
      self.choseevent = true;
    },
    next() {
      let self = this;
      self.$refs.calendar.next();
      let month = self.todate.getMonth() + 1;
      let year = self.todate.getFullYear();
      if (month > 11) {
        month = 0;
        year = year + 1;
        self.todate = new Date(year, month, 1);
      } else {
        self.todate = new Date(year, month, 1);
      }
      self.GetSortDate();
      self.choseevent = true;
    },
    showEvent({ nativeEvent, event }) {
      if (this.choseevent == true || this.selectedOpen == false) {
        this.showEvent1({ nativeEvent, event });
      } else {
        this.showEvent2({ nativeEvent, event });
      }
    },
    showEvent1({ nativeEvent, event }) {
      this.choseevent = false;
      this.lastid = event.id;
      // Get the correct activator element from event path
      const open = () => {
        this.selectedEvent = event;
        this.lastitem = event;

        // Use `nativeEvent.path` or `nativeEvent.composedPath()` to ensure correct element is captured
        const path = nativeEvent.path || (nativeEvent.composedPath && nativeEvent.composedPath());
        this.selectedElement = path ? path[0] : nativeEvent.target;

        // Open the menu after ensuring the correct element is set
        this.$nextTick(() => {
          requestAnimationFrame(() => {
            this.selectedOpen = true;
            // Update menu dimensions to recalculate its position
            this.$refs.menu.updateDimensions();
          });
        });
      };

      // If the menu is already open, close it and reopen with correct activator
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    showEvent2({ nativeEvent, event }) {
      this.lastid = event.id;
      const open = () => {
        this.selectedEvent = event;
        this.lastitem = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
        this.selectedOpen = true;
      }
      nativeEvent.stopPropagation();
    },
    clear() {
      this.selectedEvent = {},
        //this.selectedElement = null,
        this.selectedOpen = false;
      //this.choseevent = true;
    },
    formatDate(date) {
      if (!date) return null;
      let da = new Date(date);
      let month = da.toLocaleString('en-US', { month: 'short' });
      let day = da.getDate();
      return `${day} ${month}`;
    },
    howmanyYear(date) {
      if (!date) return null;
      let da = new Date(date);
      let today = new Date();
      let year = da.getFullYear();
      return `${today.getFullYear() - year} - Year`;
    },
    GetSortDate() {
      let self = this;
      self.birthdaycheck = true;
      self.anniversarycheck = true;
      var realmonth = self.todate.getMonth() + 1;
      self.SortingDate =
        self.monthNames[self.todate.getMonth(realmonth)].txt + " " + self.todate.getFullYear();
      self.SortingDateTH =
        self.monthNamesTH[self.todate.getMonth(realmonth)].txt + " " + (self.todate.getFullYear()); //+ 543 if thai year
      self.GetEventForCal();
    },
    getthisyearDate(date) {
      let dd = new Date(date);
      return new Date(this.todate.getFullYear(), dd.getMonth(), dd.getDate());
    },
    GetEventForCal() {
      let self = this;
      self.LoadingDialog = true;
      let mon = self.todate.getMonth() + 1;
      let yer = self.todate.getFullYear();
      let tempp = {
        company_ID: store.state.companyID,
        month: mon,
        year: yer
      };
      axios.post(`${self.url}TimeOff_Request/GetTimeOff_ForCalendar`, tempp)
        .then(function (response) {
          if (response.data.status === 0) {
            let thels = response.data.data;
            self.events = thels.map((v, index) => ({
              ...v,
              id: index,
              start: self.getthisyearDate(v.start_Date),
              end: v.end_Date ? self.getthisyearDate(v.end_Date) : self.getthisyearDate(v.start_Date),
              samedate: v.start_Date === v.end_Date
            }));
            self.theList = self.events;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error);
        });
    },
  },

}

</script>
<style scoped>
.birthdayIcon1 {
  width: 18px;
  height: 18px;
}

.birthdayIcon3 {
  width: 16px;
  height: 16px;
}

.birthdayIcon12 {
  width: 10px;
  height: 10px;
}

.profilesize {
  width: 50px;
  height: 50px;
}

/* Main calendar styling */
.calendar-card {
  margin-bottom: 5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  min-height: 1107px;
}

/* Toolbar and buttons styling */
.v-toolbar {
  background-color: #f5f5f5;
}

.v-btn {
  color: #444;
}

/* Custom Event Styling */
.custom-event {
  display: flex;
  border-left: 5px solid #717070;
  background-color: #eee;
  border-radius: 4px;
  padding: 0px 8px;
  font-size: 12px;
  margin-left: 3px;
  color: #333;
  flex-wrap: wrap;
}

.custom-event1{
  color: #333333;
}

.event-row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  border-radius: 4px;
}

/* Styling for Selected Event */
.event-selected {
  background-color: #717070 !important;
  color: white !important;
}

.event-selected1 {
  color: #F99D20 !important;
}

/* Adjust the height of the event rows to prevent overlap */
::v-deep.v-calendar .v-event {
  height: auto;
}

::v-deep.v-calendar .v-calendar-daily__events {
  display: flex;
  flex-direction: column;
  /* Stack events vertically */
  align-items: flex-start;
  padding: 4px;
  /* Adjust padding */
  height: auto;
}


/* Styling for Today Date */
::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__day .v-present {
  background-color: #FFF2E3 !important;
}

/* Popup Menu Styling */
.v-menu__content {
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 110px;
}

.v-toolbar__title {
  font-size: 1.2em;
  font-weight: bold;
}

::v-deep .v-calendar-daily__day {
  height: auto !important;
  min-height: 500px;
}

::v-deep .v-calendar-weekly__week {
  min-height: 200px;
}

::v-deep .v-calendar-weekly__head-weekday {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 10px;
  flex: 1 0 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 15px px 0px 4px;
  background-color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
}

::v-deep .v-calendar-weekly__day-label {
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: left;
  margin: 4px 0 0 0;
}

::v-deep .theme--light.v-btn.v-btn--has-bg {
  background-color: transparent;
  cursor: default;
}

::v-deep .v-calendar-weekly__day.v-present {
  background-color: #FFF2E3;
}

::v-deep .button.v-btn.v-btn--fab.v-btn--has-bg.v-btn--round.theme--light.v-size--small {
  background-color: #FFF2E3 !important;
}

::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: #000000;
}

::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: #EEE !important;
}

::v-deep .v-calendar.v-calendar-events .v-calendar-weekly__head-weekday {
  font-size: 14px;
}

::v-deep .v-calendar .v-event {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  line-height: 25px;
  margin-right: -1px;
  z-index: 1;
  border-radius: 4px;
  min-height: 25px;
}
</style>