<template>
  <v-card class="mycardthree">
    <div class="d-flex ml-2 fixed-header">
      <v-row class="d-flex-row flex-wrap align-start ma-3 mb-1 pr-1">
          <img class="commonstatisticsIcon mt-2" :src="timecalimages" alt="commonstatistics" />
            <div style="margin-left: 10px; margin-top: 7px;">
              <p style="color: #F99D20; font-size: 17px;">
                {{ $t("Employeesonleave") }}
              </p>
            </div>
            <v-spacer></v-spacer>
            <div class="text-capitalize mt-2" style="font-size: 16px; color: #F99D20;text-decoration: underline;cursor: pointer;" @click="gotocalpg()">
              {{ $t("FullCalendar") }}
            </div>
        </v-row>
    </div>

    <div class="d-flex ml-2">
      <div style="margin-left: 0.5rem" class="ma-2">
        <p style="margin-top: 10px; color: #7D7A7A">{{ $t("today") }} ( {{todayList.length}} )</p>
      </div>
    </div>
    <div class="mycard">
      <template v-if="todayList.length">
        <div v-for="item in todayList" :key="item.employee_ID">
          <v-list-item>
            <v-avatar width="35" height="35" style="margin-left: auto; margin-right: auto">
              <img :src="item.image != null ? item.image : 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png'" alt="profileImage"/>
            </v-avatar>
            <v-list-item-content class="ml-3">
              <v-list-item-title style="font-size: 17px">
                {{ item.employee_Name }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 15px">
                <p v-show="!thethaihead">{{ item.timeoff_Name }}</p>
                <p v-show="thethaihead">{{ item.timeoff_NameTH }}</p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </template>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12" v-else>
        <p style="font-size:15px; color: #7D7A7A; margin-left: 0.5rem;">{{ $t("NobodyRequested") }}</p>
      </v-col>

      <div class="d-flex ml-2">
        <div style="margin-left: 0.5rem" class="ma-2">
          <p style="margin-top: 10px; color: #7D7A7A;">{{ $t("tomorrow") }} ( {{tmrList.length}} )</p>
        </div>
      </div>
      <template v-if="tmrList.length">
        <v-list>
          <div v-for="item in tmrList" :key="item.employee_ID">
            <v-list-item :ripple="false">
              <v-avatar width="35" height="35" style="margin-left: auto; margin-right: auto">
                <img :src="item.image != null ? item.image : 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png'" alt="profileImage"/>
              </v-avatar>
              <v-list-item-content class="ml-3">
                <v-list-item-title style="font-size: 17px">
                  {{ item.employee_Name }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 15px">
                  <p v-show="!thethaihead">{{ item.timeoff_Name }}</p>
                  <p v-show="thethaihead">{{ item.timeoff_NameTH }}</p>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </template>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" v-else>
        <p style="font-size:15px; color: #7D7A7A; margin-left: 0.5rem;">{{ $t("nobodyRequested") }}</p>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import Axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";

export default {
  data: () => ({
    url: enurl.apiUrl,
    todayList: [],
    tmrList: [],
    thelang: "",
    timecalimages: require("@/assets/images/calendar-8-svgrepo-com 1.png"),
  }),
  mounted() {
    this.GetTimeOff();
    this.GetTimeOffTmr();
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
    this.$emit('component-ready');
  },
  computed: {
    thethaihead() {
      return this.whatthehead();
    },
  },
  watch: {
    thelang() {
      this.whatthehead()
    },
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    gotocalpg(){
      this.$router.push({ name: "FullCalendar"});
    },
    async GetTimeOff() {
      try {
        let temp = {
          company_ID: store.state.companyID,
        };
        const response = await Axios.post(
          `${this.url}TimeOff_Request/GetTimeOff_ForHomePage`,
          temp
        );

        let list = response.data.data;
        if (list.length) {
          this.todayList = list;
        }
      } catch (ex) {
        throw ex;
      }
    },
    async GetTimeOffTmr() {
      try {
        let temp = {
          company_ID: store.state.companyID,
        };
        const response = await Axios.post(
          `${this.url}TimeOff_Request/GetTimeOff_ForTmrHomePage`,
          temp
        );

        let list = response.data.data;
        if (list.length) {
          this.tmrList = list;
        }
      } catch (ex) {
        throw ex;
      }
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0 !important;
}
.mycard {
  overflow-y: auto;
  overflow-x: hidden;
}
.mycardthree {
  overflow-y: auto;
  height: 460px;
  overflow-x: hidden;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}
.mycardthree::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Opera */
}
.fixed-header {
  position: sticky;
  top: 0;
  background-color: white; /* or your card background color */
  z-index: 5;
  padding-top: 10px; /* optional padding */
}
</style>
