<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 20px">
        {{ $t("Setting") }}
      </p>

      <v-tabs
        class="mt-6"
        grey--text
        background-color="#f0f8fc"
        slider-color="#F99D20"
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          class="text-capitalize"
          style="
            border-radius: 10px 10px 0px 5px;
            width: 180px;
            margin-right: 3px;
          "
          v-for="tab in tabs"
          v-show="tab.showthis"
          :key="tab.id"
          :to="tab.route"
          exact
          >{{ translate(tab.name) }}</v-tab
        >
      </v-tabs>
      <v-card style="margin-bottom: 3rem">
        <router-view></router-view>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    return {
      activeTab: `/PayRoll/Setting/${this.id}`,

      tabs: [
        {
          id: 1,
          name: "Payroll",
          route: `/PayRoll/Setting/${this.id}`,
          showthis: false,
        },
        {
          id: 2,
          name: "Deduction setting",
          route: `/PayRoll/Setting/${this.id}/Deductionsetting`,
          showthis: false,
        },
      ],
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleid = sessionStorage.getItem("roleID");
    if (roleid == "Admin" || roleid == "HR") {
      for (let j = 0;j < this.tabs.length; j++) {
        this.tabs[j].showthis = true;
      }
    }
    if (perid.some((e) => e == 45)) {
      this.tabs[0].showthis = true;
    }
    if (perid.some((e) => e == 197)) {
      this.tabs[1].showthis = true;
    }
    if (this.$route.path == "/PayRoll/Setting/payrollcalculationperiod") {
      for (let j = 0;j < this.tabs.length; j++) {
        if (this.tabs[j].showthis == true) {
          this.activeTab = this.tabs[j].route;
          this.$router.push(this.tabs[j].route).catch(()=>{});
          break;
        }
      }
    }
  },

  computed: {
    translate() {
      return function (salut) {
        let value = salut;
        return value == "Payroll"
          ? this.$t("PayrollCalculationPeriod")
          : this.$t("Deductionsetting");
      };
    },
  },
};
</script>

<style scoped>
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
}
.active {
  cursor: pointer !important;
}
.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}
>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
</style>