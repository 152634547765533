<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 15px">
        {{ $t("UserGroup") }}
      </p>
    </div>
    <v-card class="mt-7">
      <div>
        <v-row
          class="pl-md-0 pt-9"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-col cols="12" xs="12" sm="4" md="4" lg="3">
            <v-text-field
              autocomplete="off"
              v-model="searchtxt"
              :placeholder="$t('Search')"
              color="#F99D20"
              dense
              style="margin-top: -0.3rem; margin-left: 1rem"
            ></v-text-field>
          </v-col>
          <v-btn
            color="#F99D20"
            @click="Search"
            class="white--text text-capitalize ml-5"
            width="120"
            max-height="35"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-row>
        <v-data-table
          style="margin-left: 40px; margin-right: 40px"
          :items="GroupList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :headers="headers"
          hide-default-footer
          @page-count="pageCount = $event"
          hide-default-header
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("GroupID") }}</th>
                <th>{{ $t("GroupName") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <!-- <label class="form-checkbox">
                  <input
                    type="checkbox"
                    :value="{
                      user_Group_ID: item.user_Group_ID,
                      user_Group_Name: item.user_Group_Name,
                    }"
                    v-model="selected"
                    @click="checkonce"
                  />
                  <i class="form-icon"></i>
                </label> -->
                <v-checkbox class="custom-checkbox mt-0" 
                    v-model="item.checked"
                    @change="checkmyemp(item.user_Group_ID)" 
                    color="#F89D1F" hide-details>
                </v-checkbox>
              </td>
              <td>{{ GetIndex(item) }}</td>
              <td>{{ item.user_Group_Name }}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-right">
          <PaginationComponent
            :page.sync="page"
            :pageCount="pageCount"
            :itemsPerPage.sync="itemsPerPage"
          />
        </div>
      </div>
      <v-card-actions class="pb-6 mr-1">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-2 text-capitalize commoncancelbtn"
          text
          @click="Cancel"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="mr-4 text-capitalize commonsavebtn"
          @click="save"
          >{{ $t("save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
  <script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [utils],
  data() {
    return {
      GroupList: [],
      loadingdialog: false,
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      searchtxt: "",
      selected: [],
      tempuser: [],
      headers: [
        {
          text: "Group ID",
          value: "user_Group_ID",
          align: "left",
          sortable: true,
        },
        {
          text: "Group Name",
          value: "user_Group_Name",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    searchtxt: function () {
      this.GetGroup();
    },
  },
  mounted() {
    this.GetGroup();
      this.GetUserGroupByEmpID();
      let id = localStorage.getItem ("empcode");
      if(id != null){
      this.ChangeCodeToID();
    }
  },
  methods: {
    async ChangeCodeToID() {
      let self = this;
      let Id = localStorage.getItem("empcode");
      let temp = {
        employee_Code: Id,
        company_ID: store.state.companyID,
      };
      const response = await axios.post(
        `${self.url}PersonalInfo/GetPersonalInformationWithCode`,
        temp
      );
      let empId = response.data.data.employee_ID;
      return empId;
    },
    GetIndex(val) {
      let self = this;
      let data = self.GroupList.indexOf(val);
      data++;
      return data;
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.GroupList;
        self.GroupList = [];
        let temp = List.filter(
          (v) =>
            v.user_Group_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.GroupList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetGroup();
      }
    },
    checkonce() {
      this.selected = [];
    },
    checkmyemp(id) {
      this.GroupList = this.GroupList.map((v) => ({
        ...v,
        checked: id === v.user_Group_ID,
      }));
      this.selected = 
      this.GroupList
      .filter(user => user.checked)
      .map((v) => {
        return {
          user_Group_ID: v.user_Group_ID,
          user_Group_Name: v.user_Group_Name,
        };
      });
    },
    Cancel() {
      let self = this;
      self.uid = parseInt(self.$route.params.id);
      localStorage.setItem("UserEditUserGp", JSON.stringify(null));
      if (self.$route.params.id != null) {
        self.$router.push(
          `/PersonnelManagement/EditEmployee/${self.uid}/EditPersonalInformation/Account`
        ).catch(()=>{});
      } else if (self.$route.name == "NUserGroup") {
        self.$router.push(
          "/PersonnelManagement/NewEmployee/NewPersonalInformation/Account"
        ).catch(()=>{});
      }
    },
    save() {
      let self = this;
      localStorage.setItem("UserEditUserGp", JSON.stringify(self.selected));
      self.uid = parseInt(self.$route.params.id);
      if (self.$route.params.id != null) {
        self.$router.push(
          `/PersonnelManagement/EditEmployee/${self.uid}/EditPersonalInformation/Account`
        ).catch(()=>{});
      } else if (self.$route.name == "NUserGroup") {
        self.$router.push(
          "/PersonnelManagement/NewEmployee/NewPersonalInformation/Account"
        ).catch(()=>{});
      }
    },
    GetRoleByUserGroup() {
      let self = this;
      self.loadingdialog = true;

      if (self.selected.length != 0) {
        let ugpId = self.selected[0].user_Group_ID;
        let tempp = {
          company_ID: store.state.companyID,
          user_Group_ID: ugpId,
        };
        axios
          .post(`${self.url}UserGroupRole/GetUserGroupRole`, tempp)
          .then(function (response) {
            let temprole = response.data.data;
            localStorage.setItem("UserEditRole", JSON.stringify(temprole));
            self.uid = parseInt(self.$route.params.id);
            self.$router.push(
              `/PersonnelManagement/EditEmployee/${self.uid}/EditPersonalInformation/Account`
            ).catch(()=>{});
          });
      } else {
        self.uid = parseInt(self.$route.params.id);
        self.$router.push(
          `/PersonnelManagement/EditEmployee/${self.uid}/EditPersonalInformation/Account`
        ).catch(()=>{});
      }
    },

    GetGroup() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroup/GetUserGroup`, tempp)
        .then(function (response) {
          let llist = response.data.data;
          self.GroupList = llist.map((v) => ({
            ...v,
            checked: false,
          }));
        });
    },
    async GetUserGroupByEmpID() {
      let self = this;
      self.loadingdialog = true;
      let id = null;
      if (self.$route.params.id == null || self.$route.params.id == undefined) {
        id = await self.ChangeCodeToID();
      }
      setTimeout(() => {
        let tempp = null;
        if (self.$route.params.id != null) {
          tempp = {
            employee_ID: parseInt(self.$route.params.id),
            company_ID: store.state.companyID,
          };
        }
        else if (self.$route.params.id == undefined) {
          tempp = {
            employee_ID: id,
            company_ID: store.state.companyID,
          };
        }
        self.tempuser = JSON.parse(localStorage.getItem("UserEditUserGp"));
        if (self.tempuser != null) {
          self.selected.push({
            user_Group_ID: self.tempuser[0].user_Group_ID,
            user_Group_Name: self.tempuser[0].user_Group_Name,
          });
          self.GroupList = self.GroupList.map((v) => ({
            ...v,
            checked: self.selected.some((user) => user.user_Group_ID === v.user_Group_ID),
          }));
        } else {
          axios
            .post(`${self.url}UserGroupMember/GetUserGroup`, tempp)
            .then(function (response) {
              self.UserGp = response.data.data;
              for (let i = 0; i < self.UserGp.length; i++) {
                self.selected.push({
                  user_Group_ID: self.UserGp[i].user_Group_ID,
                  user_Group_Name: self.UserGp[i].user_Group_Name,
                });
              }
              self.GroupList = self.GroupList.map((v) => ({
                ...v,
                checked: self.selected.some((user) => user.user_Group_ID === v.user_Group_ID),
              }));
            });
        }
        self.loadingdialog = false;
      }, 1000);
    },
  },
};
</script>
  <style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>