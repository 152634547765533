<script>
export default {
  data() {
    return {
    //   time: 10000,
    };
  },
  created() {
    let timerId = setInterval(() => {
      // this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
    //   if (this.time < 1) {
        clearInterval(timerId);
        // Your logout function should be over here
        // alert("logout user....");
        this.$router.push("/").catch(() => {});
    //   }
    }, 1000);
  },
};
</script>
