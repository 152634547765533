<template>
  <div>
    <div>
      <v-tabs
        grey--text
        slider-color="#F89D1F"
        class="active"
        background-color="#fff"
        style="cursor: pointer; padding-left: 25px; padding-top: 30px"
        show-arrows
      >
        <v-tab
          class="text-capitalize"
          style="border: 1.4px solid #f89d1f; width: 20rem"
          @click="reloadPage()"
          >{{ $t("calmethod") }}</v-tab
        >
        <v-tab
          class="text-capitalize"
          style="border: 1.4px solid #f89d1f; width: 20rem"
          >{{ $t("PersonalAllowance") }}</v-tab
        >
        <v-tab-item style="cursor: context-menu; margin-left: -40px">
          <CalculationMethod v-if="renderComponent" />
        </v-tab-item>
        <v-tab-item style="cursor: context-menu; margin-left: -40px">
          <personal-allowance />
        </v-tab-item>
      </v-tabs>
    </div>

    <!-- <div v-show="!fullTime">
      <div>
        <v-row class="pt-12" style="margin-left: 15px; margin-right: 10px">
          <v-spacer></v-spacer>
          <v-btn
            color="#F99D20"
            class="orange--text text-capitalize mr-5"
            width="150"
            max-height="35"
            suffix="THB"
            outlined
            @click="AUploaddialog = true"
            >Upload</v-btn
          >
          <v-btn
            color="#F99D20"
            class="white--text text-capitalize mr-5"
            width="150"
            max-height="35"
            @click="addDialog = true"
            >Add</v-btn
          >
        </v-row>

        <v-data-table
          style="margin-left: 40px; margin-right: 40px"
          :headers="partTimeData"
          hide-default-footer
          :items="partTimeList"
          @page-count="pageCount = $event"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.tax_Items }}</td>
              <td>{{ item.percentage_of_Salary }}</td>
              <td>{{ item.status == true ? "Active" : "In Active" }}</td>
              <td>{{ formatDate(item.effective_Start_Date) }}</td>
              <td>{{ formatDate(item.effective_End_Date) }}</td>

              <td>
                <v-btn @click="EditPTime(item)" icon>
                  <img :src="images.editpic" class="mr-2 mb-1" />
                </v-btn>
                <v-btn
                  @click="DeleteShift(item.partTimeTax_ID)"
                  icon
                  class="ml-2"
                >
                  <img :src="images.deletepic" class="mr-2 mb-1" />
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table> -->
    <!-- <div style="cursor:pointer;padding-left:40px;padding-top:30px;padding-bottom:30px;">
          <p
            style="text-decoration:underline; color:#FF0000;"
            @click="goFullTime"
          >Go to Full-Time staff or-consultants</p>
        </div>-->

    <!-- upload diaglog  -->
    <!-- <v-dialog v-model="AUploaddialog" presistent max-width="700">
          <v-card style="border-radius: 15px">
            <v-card-title style="text-align: center">
              <p
                style="
                  margin-top: 10px;
                  margin-bottom: -30px;
                  margin-left: 10px;
                "
              >
                Upload File
              </p>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col style="margin-left: 3px" align="center" cols="5">
                    <v-file-input
                      v-show="showup"
                      color="white"
                      class="myfile"
                      id="fileInputButton"
                      v-model="chosenfile"
                      prepend-icon
                      accept=".xlsx"
                    >
                      <template v-slot:append>
                        <v-btn icon>
                          <img
                            width="20"
                            height="20"
                            @click="closeinput"
                            src="@/assets/closered.png"
                            alt
                          />
                        </v-btn>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" md="4">
                    <v-btn
                      color="#ACACAC"
                      hide-details
                      class="white--text text-capitalize mt-4"
                      style="text-align: left; float: left"
                      width="150"
                      max-height="35"
                      @click="getupload"
                      >Browse</v-btn
                    >
                  </v-col>
                  <v-col cols="6" md="6">
                    <p
                      style="
                        margin-top: 25px;
                        margin-left: -40px;
                        color: #f74747;
                      "
                    >
                      ** This file type must be .xlsx, .xsl only.
                    </p>
                  </v-col>
                </v-row>
                <hr class="mt-5" />
                <v-row class="mt-7">
                  <v-col style="margin-top: -20px" align="left" cols="12">
                    <p
                      style="
                        font-size: 18px;
                        font-family: 'Kanit', sans-serif;
                        color: #444444;
                      "
                    >
                      Excel file upload formats
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p>Document Template.xlsx</p>
                  </v-col>
                  <v-col cols="2">
                    <a
                      style="color: #f99d20"
                      href="https://optimisticpathfile.blob.core.windows.net/optimisticfile/Part Time Tax.xlsx"
                      >Download</a
                    >
                  </v-col>
                </v-row>
                <hr class="mt-5" />
              </v-container>
            </v-card-text>
            <v-card-actions class="pb-6 mr-2">
              <v-spacer></v-spacer>
              <v-btn
                class="mr-2 text-capitalize"
                width="159"
                height="35"
                text
                @click="closeUpload"
                >Cancel</v-btn
              >
              <v-btn
                color="#F99D20"
                class="white--text mr-4 text-capitalize"
                width="159"
                height="35"
                v-show="uploadAdd"
                @click="previewFilesAdd()"
                >Upload</v-btn
              >
              <v-btn
                color="#F99D20"
                class="white--text mr-4 text-capitalize"
                width="159"
                v-show="!uploadAdd"
                height="35"
                @click="previewFiles()"
                >Upload</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog> -->

    <!-- delete Shift dialog -->
    <!-- <v-dialog v-model="Deletedialog" persistent max-width="532">
          <v-card style="border-radius: 15px">
            <v-card-title>
              <p style="margin-top: 10px; margin-bottom: -30px">Delete item</p>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col align="center" cols="12">
                    <p style="font-size: 16px; color: #444444" class="pt-4">
                      Are you sure you want to delete?
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="pb-6">
              <v-spacer></v-spacer>
              <v-btn
                class="mr-5 text-capitalize"
                width="150"
                max-height="35"
                @click="Deletedialog = false"
                text
                >Cancel</v-btn
              >
              <v-btn
                color="#FF2727"
                class="mr-5 white--text text-capitalize"
                width="150"
                max-height="35"
                @click="DeletePartTimerow()"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog> -->

    <!-- add  dialog -->
    <!-- <v-dialog
          v-model="addDialog"
          persistent
          width="660"
          style="height: 440"
          max-width="700px"
        >
          <v-card style="border-radius: 15px">
            <v-card-title>
              <span class="headline">New Tax Item</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    Item/Name
                    <v-text-field
                      color="#F99D20"
                      v-model="addPartTime.tax_Items"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    Percent of salary per payment
                    <v-text-field
                      color="#F99D20"
                      v-model="addPartTime.percentage_of_Salary"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="6">
                    Effective Start Time
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      fluid
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-calendar-month-outline"
                          v-model="computedDateFormatted1"
                          class="kanit-medium"
                          color="#8A8C8E"
                          readonly
                          persistent-hint
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="addPartTime.effective_Start_Date"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" md="6">
                    Effective End Time
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      fluid
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-calendar-month-outline"
                          v-model="computedDateFormatted2"
                          class="kanit-medium"
                          color="#8A8C8E"
                          readonly
                          persistent-hint
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="addPartTime.effective_End_Date"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <div class="ml-2">
                    <label style="font-size: 18px">Status</label>
                    <v-radio-group v-model="addPartTime.status" row>
                      <v-radio
                        label="Active"
                        value="true"
                        class="pr-11"
                      ></v-radio>
                      <v-radio label="InActive" value="false"></v-radio>
                    </v-radio-group>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions v-show="!partTimeEditADD">
              <v-spacer></v-spacer>
              <v-btn
                color="darken-1"
                class="mb-4 mr-7 text-capitalize"
                width="150"
                max-height="35"
                text
                @click="CloseDialogLang"
                >Cancel</v-btn
              >
              <v-btn
                color="#F99D20"
                class="mb-5 mr-5 text-capitalize"
                width="150"
                max-height="35"
                @click="addPartAdd()"
                >Add</v-btn
              >
            </v-card-actions>
            <v-card-actions v-show="partTimeEditADD">
              <v-spacer></v-spacer>
              <v-btn
                color="darken-1"
                class="mb-4 mr-7 text-capitalize"
                width="150"
                max-height="35"
                text
                @click="CloseDialogLang"
                >Cancel</v-btn
              >
              <v-btn
                color="#F99D20"
                class="mb-5 mr-5 text-capitalize"
                width="150"
                max-height="35"
                @click="addPartEdit()"
                >Add</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog> -->
    <!-- Edit dialog -->
    <!-- <v-dialog
          v-model="Editdialog"
          persistent
          width="660"
          style="height: 440"
          max-width="700px"
        >
          <v-card style="border-radius: 15px">
            <v-card-title>
              <span class="headline">New Tax Item</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    Item/Name
                    <v-text-field
                      color="#F99D20"
                      v-model="editPartTime.tax_Items"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    Percent of salary per payment
                    <v-text-field
                      color="#F99D20"
                      v-model="editPartTime.percentage_of_Salary"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="6">
                    Effective Start Time
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      fluid
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-calendar-month-outline"
                          v-model="computedDateFormatted3"
                          class="kanit-medium"
                          color="#8A8C8E"
                          readonly
                          persistent-hint
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editPartTime.effective_Start_Date"
                        no-title
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" md="6">
                    Effective End Time
                    <v-menu
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      fluid
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          append-icon="mdi-calendar-month-outline"
                          v-model="computedDateFormatted4"
                          class="kanit-medium"
                          color="#8A8C8E"
                          readonly
                          persistent-hint
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editPartTime.effective_End_Date"
                        no-title
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <div class="ml-2">
                    <label style="font-size: 18px">Status</label>
                    <v-radio-group v-model="editPartTime.status" row>
                      <v-radio
                        label="Active"
                        value="true"
                        class="pr-11"
                      ></v-radio>
                      <v-radio label="InActive" value="false"></v-radio>
                    </v-radio-group>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions v-show="!partTimeEditADD">
              <v-spacer></v-spacer>
              <v-btn
                color="darken-1"
                class="mb-4 mr-7 text-capitalize"
                width="150"
                max-height="35"
                text
                @click="CloseDialogLang"
                >Cancel</v-btn
              >
              <v-btn
                color="#F99D20"
                class="mb-5 mr-5 text-capitalize"
                width="150"
                max-height="35"
                @click="UpdatePartTimeAdd()"
                >Update</v-btn
              >
            </v-card-actions>
            <v-card-actions v-show="partTimeEditADD">
              <v-spacer></v-spacer>
              <v-btn
                color="darken-1"
                class="mb-4 mr-7 text-capitalize"
                width="150"
                max-height="35"
                text
                @click="Editdialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#F99D20"
                class="mb-5 mr-5 text-capitalize"
                width="150"
                max-height="35"
                @click="UpdatePartTime()"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import CalculationMethod from "@/views/Personal/IncomeTax/CalculationMethod";
import PersonalAllowance from "@/views/Personal/IncomeTax/personal-allowance";

export default {
  components: {
    CalculationMethod,
    PersonalAllowance,
  },
  data(vm) {
    return {
      additem: false,
      isCheck: false,
      isCheck5: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      status1: false,
      row: null,
      url: enurl.apiUrl,
      addDialog: false,
      renderComponent: true,

      partTimeData: [
        {
          text: "Tax Items",
          align: "left",
          sortable: false,
        },
        {
          text: "Percent (of salary)",

          align: "left",
          sortable: false,
        },
        {
          text: "Status",

          align: "left",
          sortable: false,
        },
        {
          text: "Effective Start Date",

          align: "left",
          sortable: false,
        },
        {
          text: "Effective End Date",

          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          value: "actionadd",
          align: "center",
          sortable: false,
        },
      ],

      images: {
        editpic: require("@/assets/images/Mask Group 178.png"),
        deletepic: require("@/assets/images/Mask Group 177.png"),
      },
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      AUploaddialog: false,
      showup: false,
      chosenfile: null,
      partTimeList: [],
      partTimeList1: [],
      SalaryList: [],
      IncomeList: [],
      empType: "",
      partTimeEditADD: false,

      total: 0,
      addition: 0,
      addPartTime: {
        tax_Items: "",
        percentage_of_Salary: "",
        status: [],
        effective_Start_Date: "",
        effective_End_Date: "",
      },
      error: {
        tax_Items: "",
        percentage_of_Salary: "",
        status: false,
        effective_Start_Date: "",
        effective_End_Date: "",
      },
      editPartTime: {
        partTimeTax_ID: 0,
        tax_Items: "",
        percentage_of_Salary: "",
        status: [],
        effective_Start_Date: "",
        effective_End_Date: "",
      },
      Editdialog: false,
      deleteId: 0,
      Deletedialog: false,
      calcu1: "",
      calcu2: "",
      totalList: [],

      update: true,

      importDataAdd: [],
      uploadAdd: false,
      Personallist: [],
    };
  },
  methods: {
    reloadPage() {
      this.renderComponent = false;

      // If you like promises better you can
      // also use nextTick this way
      this.$nextTick().then(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    CloseDialogLang() {
      this.addDialog = false;
    },
    closeUpload() {
      this.AUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      this.showup = true;
    },
    addPartAdd() {
      let self = this;
      self.error.tax_Items = !self.addPartTime.tax_Items
        ? "Please fill in the required fields"
        : "";
      self.error.percentage_of_Salary = !self.addPartTime.percentage_of_Salary
        ? "Please fill in the required fields"
        : "";
      self.error.effective_Start_Date = !self.addPartTime.effective_Start_Date
        ? "Please fill in the required fields"
        : "";

      self.error.effective_End_Date = !self.addPartTime.effective_End_Date
        ? "Please fill in the required fields"
        : "";
      let tempvali = self.ValidateAddTrue();
      if (tempvali == true) {
        let tempShift = {
          employee_ID: localStorage.empcode,
          tax_Items: self.addPartTime.tax_Items,
          status: self.addPartTime.status,
          percentage_of_Salary: self.addPartTime.percentage_of_Salary,
          effective_Start_Date: new Date(self.addPartTime.effective_Start_Date),
          effective_End_Date: new Date(self.addPartTime.effective_End_Date),
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}PartTimeTax/AddPartTimeTax`, tempShift)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.addPartTime.percentage_of_Salary = null;
              self.addPartTime.effective_Start_Date = null;
              self.addPartTime.effective_End_Date = null;
              self.addPartTime.tax_Items = null;
              self.addPartTime.status = null;
              self.addDialog = false;
              self.GetPartTimeCode();
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        this.ValidateAddFalse();
      }
    },
    GetPartTimeCode() {
      let self = this;
      let tempp = {
        employee_ID: localStorage.empcode,
      };
      axios
        .post(`${self.url}PartTimeTax/GetPartTimeTaxByEmployeeCode`, tempp)
        .then(function (response) {
          self.partTimeList = response.data.data;
        });
    },
    addPartEdit() {
      let self = this;
      self.error.tax_Items = !self.addPartTime.tax_Items
        ? "Please fill in the required fields"
        : "";
      self.error.percentage_of_Salary = !self.addPartTime.percentage_of_Salary
        ? "Please fill in the required fields"
        : "";
      self.error.effective_Start_Date = !self.addPartTime.effective_Start_Date
        ? "Please fill in the required fields"
        : "";

      self.error.effective_End_Date = !self.addPartTime.effective_End_Date
        ? "Please fill in the required fields"
        : "";
      let tempvali = self.ValidateAddTrue();
      if (tempvali == true) {
        let tempShift = {
          employee_ID: parseInt(self.$route.params.id),
          tax_Items: self.addPartTime.tax_Items,
          status: self.addPartTime.status,
          percentage_of_Salary: self.addPartTime.percentage_of_Salary,
          effective_Start_Date: new Date(self.addPartTime.effective_Start_Date),
          effective_End_Date: new Date(self.addPartTime.effective_End_Date),
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}PartTimeTax/AddPartTimeTax`, tempShift)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.addPartTime.percentage_of_Salary = null;
              self.addPartTime.effective_Start_Date = null;
              self.addPartTime.effective_End_Date = null;
              self.addPartTime.tax_Items = null;
              self.addPartTime.status = null;
              self.addDialog = false;
              self.GetPartTime();
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        this.ValidateAddFalse();
      }
    },
    GetPartTime() {
      let self = this;
      let tempp = {
        employee_ID: parseInt(self.$route.params.id),
      };
      axios
        .post(`${self.url}PartTimeTax/GetPartTimeTaxByEmployeeID`, tempp)
        .then(function (response) {
          self.partTimeList = response.data.data;
        });
    },
    ValidateAddTrue() {
      let self = this;
      if (
        self.addPartTime.tax_Items != null &&
        self.addPartTime.percentage_of_Salary != null &&
        self.addPartTime.effective_Start_Date != null &&
        self.addPartTime.effective_End_Date != null &&
        self.addPartTime.shift_Code != "" &&
        self.addPartTime.start_Time != "" &&
        self.addPartTime.effective_Start_Date != "" &&
        self.addPartTime.effective_End_Date != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateAddFalse() {
      let self = this;
      if (
        self.addPartTime.tax_Items == null ||
        self.addPartTime.percentage_of_Salary == null ||
        self.addPartTime.effective_Start_Date == null ||
        self.addPartTime.effective_End_Date == null ||
        self.addPartTime.tax_Items == "" ||
        self.addPartTime.percentage_of_Salary == "" ||
        self.addPartTime.effective_End_Date == "" ||
        self.addPartTime.effective_Start_Date == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },
    EditPTime(temp) {
      let self = this;
      self.Editdialog = true;
      self.editPartTime.partTimeTax_ID = temp.partTimeTax_ID;
      self.editPartTime.tax_Items = temp.tax_Items;
      self.editPartTime.percentage_of_Salary = temp.percentage_of_Salary;
      self.editPartTime.effective_Start_Date = temp.effective_Start_Date;
      self.editPartTime.effective_End_Date = temp.effective_End_Date;
      self.editPartTime.status = temp.status.toString();
    },
    UpdatePartTime() {
      let self = this;
      self.error.tax_Items = !self.editPartTime.tax_Items
        ? "Please fill in the required fields"
        : "";
      self.error.percentage_of_Salary = !self.editPartTime.percentage_of_Salary
        ? "Please fill in the required fields"
        : "";
      self.error.effective_Start_Date = !self.editPartTime.effective_Start_Date
        ? "Please fill in the required fields"
        : "";

      self.error.effective_End_Date = !self.editPartTime.effective_End_Date
        ? "Please fill in the required fields"
        : "";
      let tempvali = self.ValidateEditTrue();
      if (tempvali == true) {
        let tempShift = {
          employee_ID: parseInt(self.$route.params.id),
          partTimeTax_ID: self.editPartTime.partTimeTax_ID,
          tax_Items: self.editPartTime.tax_Items,
          status: self.editPartTime.status,
          percentage_of_Salary: self.editPartTime.percentage_of_Salary,
          effective_Start_Date: new Date(
            self.editPartTime.effective_Start_Date
          ),
          effective_End_Date: new Date(self.editPartTime.effective_End_Date),
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}PartTimeTax/UpdatePartTimeTax`, tempShift)
          .then(function (response) {
            if (response.data.status == 0) {
              self.Editdialog = false;
              alert(response.data.message);
              self.editPartTime.percentage_of_Salary = null;
              self.editPartTime.effective_Start_Date = null;
              self.editPartTime.effective_End_Date = null;
              self.editPartTime.tax_Items = null;
              self.editPartTime.status = null;
              self.addDialog = false;
              self.GetPartTime();
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        this.ValidateEditFalse();
      }
    },
    UpdatePartTimeAdd() {
      let self = this;
      self.error.tax_Items = !self.editPartTime.tax_Items
        ? "Please fill in the required fields"
        : "";
      self.error.percentage_of_Salary = !self.editPartTime.percentage_of_Salary
        ? "Please fill in the required fields"
        : "";
      self.error.effective_Start_Date = !self.editPartTime.effective_Start_Date
        ? "Please fill in the required fields"
        : "";

      self.error.effective_End_Date = !self.editPartTime.effective_End_Date
        ? "Please fill in the required fields"
        : "";
      let tempvali = self.ValidateEditTrue();
      if (tempvali == true) {
        let tempShift = {
          employee_ID: localStorage.empcode,
          partTimeTax_ID: self.editPartTime.partTimeTax_ID,
          tax_Items: self.editPartTime.tax_Items,
          status: self.editPartTime.status,
          percentage_of_Salary: self.editPartTime.percentage_of_Salary,
          effective_Start_Date: new Date(
            self.editPartTime.effective_Start_Date
          ),
          effective_End_Date: new Date(self.editPartTime.effective_End_Date),
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}PartTimeTax/UpdatePartTimeTax`, tempShift)
          .then(function (response) {
            if (response.data.status == 0) {
              self.Editdialog = false;
              alert(response.data.message);
              self.editPartTime.percentage_of_Salary = null;
              self.editPartTime.effective_Start_Date = null;
              self.editPartTime.effective_End_Date = null;
              self.editPartTime.tax_Items = null;
              self.editPartTime.status = null;
              self.addDialog = false;
              self.GetPartTimeCode();
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        this.ValidateEditFalse();
      }
    },
    ValidateEditTrue() {
      let self = this;
      if (
        self.editPartTime.tax_Items != null &&
        self.editPartTime.percentage_of_Salary != null &&
        self.editPartTime.effective_Start_Date != null &&
        self.editPartTime.effective_End_Date != null &&
        self.editPartTime.shift_Code != "" &&
        self.editPartTime.start_Time != "" &&
        self.editPartTime.effective_Start_Date != "" &&
        self.editPartTime.effective_End_Date != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateEditFalse() {
      let self = this;
      if (
        self.editPartTime.tax_Items == null ||
        self.editPartTime.percentage_of_Salary == null ||
        self.editPartTime.effective_Start_Date == null ||
        self.editPartTime.effective_End_Date == null ||
        self.editPartTime.tax_Items == "" ||
        self.editPartTime.percentage_of_Salary == "" ||
        self.editPartTime.effective_End_Date == "" ||
        self.editPartTime.effective_Start_Date == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },
    DeleteShift(id) {
      let self = this;
      this.deleteId = id;
      self.Deletedialog = true;
    },

    DeletePartTimerow() {
      let self = this;
      let id = self.deleteId;
      let tempdel = {
        partTimeTax_ID: id,
      };
      axios
        .post(`${self.url}PartTimeTax/DeletePartTimeTax`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.Deletedialog = false;
            if (self.$route.params.id != null) {
              self.GetPartTime();
            } else {
              self.GetPartTimeCode();
            }
          }
        });
    },
    previewFilesAdd() {
      let self = this;
      let files = self.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          self.importDataAdd = XLSX.utils.sheet_to_json(worksheet);
          let combileArray = [];
          for (let i = 0; i < self.importDataAdd.length; i++) {
            self.tempimport = {
              ...self.importDataAdd[i],
              company_ID: store.state.companyID,
              employee_ID: localStorage.empcode,
            };
            combileArray.push(self.tempimport);
          }
          if (filename == "Part Time Tax.xlsx") {
            axios
              .post(
                `https://optimisticwebapi.azurewebsites.net/api/PartTimeTax/ImportPartTimeTax`,
                combileArray
              )
              .then(function (response) {
                if (response.data.status == 0) {
                  alert(response.data.message);
                  self.chosenfile = null;
                  self.showup = false;
                  self.AUploaddialog = false;
                  self.GetPartTimeCode();
                }
              })
              .catch(function (error) {
                alert(error);
              });
          } else {
            alert("File name must be Part Time Tax.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
    previewFiles() {
      let self = this;
      let files = self.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          self.importDataAdd = XLSX.utils.sheet_to_json(worksheet);
          let combileArray = [];
          for (let i = 0; i < self.importDataAdd.length; i++) {
            self.tempimport = {
              ...self.importDataAdd[i],
              company_ID: store.state.companyID,
              employee_ID: parseInt(self.$route.params.id),
            };
            combileArray.push(self.tempimport);
          }
          if (filename == "Part Time Tax.xlsx") {
            axios
              .post(
                `https://optimisticwebapi.azurewebsites.net/api/PartTimeTax/ImportEditPartTimeTax`,
                combileArray
              )
              .then(function (response) {
                if (response.data.status == 0) {
                  alert(response.data.message);
                  self.chosenfile = null;
                  self.showup = false;
                  self.AUploaddialog = false;
                  self.GetPartTime();
                }
              })
              .catch(function (error) {
                alert(error);
              });
          } else {
            alert("File name must be Part Time Tax.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
  },
};
</script>
<style scoped>
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
  border-radius: 0px 0px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 0px 0px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
  width: 140px;
}
.active {
  cursor: pointer !important;
}
.v-tab--active {
  color: #3c4043;
  border-radius: 15px 15px 0px 0px;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  font-weight: normal;
  font-size: 16px;
  color: #f99d20;
}
</style>
<style>
.mdi-close::before {
  /* content: "\F0156"; */
  color: red !important;
  display: none !important;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f89d1f !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
}
.list:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
</style>
