<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <v-row wrap style="margin-top: 0.01rem; margin-right: 1.5rem">
        <v-col>
          <v-card-subtitle
            class="ml-2"
            style="font-size: 20px; font-weight: 300; color: black"
          >
            {{ $t("UserDetails") }} </v-card-subtitle
          >
        </v-col>
        <v-spacer></v-spacer>

        <div>
          <v-col>
            <v-row class="mr-5 mt-1">
              <v-card-subtitle
                style="
                  font-size: 17px;
                  color: #3c4043;
                  margin-right: 1rem;
                  margin-top: 0.5rem;
                "
                class="ml-1"
              >
                {{ $t("EnableLogin") }}</v-card-subtitle
              >
              <div style="margin-top: 1rem" class="ml-4">
                <input
                  type="checkbox"
                  v-model="edituser.enable_Login"
                  @change="Change_Switch()"
                  :disabled="emp_status != 1 || permission.edit"
                />
              </div>
            </v-row>
          </v-col>
        </div>
        <div>
          <v-col>
            <v-row class="mt-1">
              <v-card-subtitle
                style="
                  font-size: 17px;
                  color: #3c4043;
                  margin-right: 1rem;
                  margin-top: 0.5rem;
                "
                class="ml-1"
              >
                {{ $t("time_attendance_system") }}</v-card-subtitle
              >
              <div style="margin-top: 1rem" class="ml-4">
                <input
                  type="checkbox"
                  v-model="edituser.time_attendance"
                  @change="Change_Switch_Attendance()"
                  :disabled="permission.edit"
                />
              </div>
            </v-row>
          </v-col>
        </div>
      </v-row>
      <div>
        <v-form ref="formEdit" lazy-validation>
          <v-row class="ml-1 mr-4" wrap>
            <v-col cols="12" sm="6" md="3" class="mr-4">
              <v-text-field
                readonly
                autocomplete="off"
                color="#F99D20"
                :disabled="disable || permission.edit"
                v-model="edituser.employeename"
                ><template v-slot:label>
                  <span>
                    {{ $t("EmployeeName")
                    }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-4" wrap>
            <v-col cols="12" sm="6" md="3" class="mr-4">
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                v-model="edituser.usergroup"
                :disabled="disable || permission.edit"
                readonly
                ><template v-slot:label>
                  <span> {{ $t("UserGroup") }}</span>
                </template></v-text-field
              >
            </v-col>
            <v-col class="mt-3">
              <v-btn
                class="text-capitalize accountbtn"
                :disabled="disable || permission.edit"
                router
                :to="
                  this.$route.params.id != null
                    ? {
                        name: 'PUserGroup',
                        params: {
                          id: `${this.$route.params.id}`,
                          usergroup: 'usergroup',
                        },
                      }
                    : {
                        name: 'NUserGroup',
                        params: { usergroup: 'usergroup' },
                      }
                "
                >{{ $t("edit") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-4" wrap>
            <v-col cols="12" sm="6" md="3" class="mr-4">
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                :rules="userroleEdit"
                v-model="edituser.userrole"
                :disabled="disable || permission.edit"
                readonly
                ><template v-slot:label>
                  <span>
                    {{ $t("Role") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col class="mt-3">
              <v-btn
                class="text-capitalize accountbtn"
                :disabled="disable || permission.edit"
                router
                :to="
                  this.$route.params.id != null
                    ? {
                        name: 'PRole',
                        params: {
                          id: `${this.$route.params.id}`,
                          role: 'role',
                        },
                      }
                    : { name: 'NRole', params: { role: 'role' } }
                "
                >{{ $t("edit") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-4" wrap>
            <v-col cols="12" sm="6" md="3" class="mr-4">
              <v-text-field
                autocomplete="off"
                :rules="useridEdit"
                :disabled="disable || permission.edit"
                color="#F99D20"
                v-model="edituser.userid"
                ><template v-slot:label>
                  <span>
                    {{ $t("username") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" sm="6" md="3" class="mr-4">
              <v-text-field
                autocomplete="off"
                v-show="showbtn == false"
                :type="show ? 'text' : 'password'"
                v-on:keyup="keymonitor"
                color="#F99D20"
                :rules="passwordEdit"
                v-model.lazy="edituser.password"
                :disabled="disable || permission.edit"
                ><template v-slot:label>
                  <span>
                    {{ $t("password") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
              <v-text-field
                autocomplete="off"
                v-show="showbtn == true"
                v-on:keyup="keymonitor"
                color="#F99D20"
                :rules="passwordEdit"
                v-model.lazy="edituser.password"
                :disabled="disable || permission.edit"
                ><template v-slot:label>
                  <span>
                    {{ $t("password") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" sm="6" md="3" class="ml-1 mr-4">
              <v-btn
                :disabled="lockdisable || disable || permission.edit"
                class="mt-3"
                icon
                @click="showlock = !showlock"
              >
                <img :src="showlock ? lockimage.lock : lockimage.unlock" />
              </v-btn>
            </v-col>
          </v-row>

          <div>
            <v-row class="mt-3">
              <v-col cols="12" sm="6" md="4" align="right">
                <v-btn
                  color="#444444"
                  class="text-capitalize mr-2"
                  style="font-weight: normal"
                  text
                >
                  {{ $t("Advance") }} &nbsp;
                  <img width="30" height="30" :src="Advanceimage.up" alt />
                </v-btn>
              </v-col>
            </v-row>
            <div v-show="arrow">
              <v-row class="ml-1 mr-4" wrap>
                <v-col cols="12" sm="6" md="4" class="mr-4">
                  <v-text-field
                    autocomplete="off"
                    :label="$t('Permission')"
                    color="#F99D20"
                    v-model="edituser.userpermis"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col class="mt-3">
                  <v-btn
                    router
                    :to="
                      this.$route.params.id != null
                        ? {
                            name: 'PPermission',
                            params: {
                              id: `${this.$route.params.id}`,
                              permission: 'permission',
                            },
                          }
                        : {
                            name: 'NPermission',
                            params: { permission: 'permission' },
                          }
                    "
                    class="text-capitalize accountbtn"
                    :disabled="disable || permission.edit"
                    >{{ $t("edit") }}</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>
      </div>

      <v-card-actions class="pb-6 mr-5 mt-5">
        <v-row wrap class="pb-5">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn
            class="text-capitalize commonsavebtn"
            v-show="disable == true"
            @click="disable = false"
            >{{ $t("edit") }}</v-btn
          >
          <v-btn
            v-show="disable == false && this.$route.name == 'EditAccount'"
            class="mr-7 text-capitalize commoncancelbtn"
            outlined
            @click="goto"
            :disabled="disable || permission.edit"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            v-show="disable == false"
            class="text-capitalize commonsavebtn"
            :disabled="permission.edit"
            @click="UpdateEmpInfoWithIDWithoutEmail()"
            >{{ $t("save") }}</v-btn
          >
        </v-row>
      </v-card-actions>
    </div>
    <!-- Loading -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>

    <!-- Email Confrim dialog -->
    <v-dialog v-model="confirmdialog" class="dialog" max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px">
            {{ $t("EnableLogin") }}
          </p>
        </v-card-title>
        <v-card-text>
          <p style="font-size: 17px; margin-top: 1rem; color: #444444">
            {{ $t("confirmemail") }}
          </p>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            color="#EEEEEE"
            class="mr-5 black--text text-capitalize"
            width="150"
            depressed
            max-height="35"
            style="border-radius: 2rem"
            @click="UpdateEmpInfoWithIDWithoutEmail"
            >{{ $t("NotsendEmail") }}</v-btn
          >
          <v-btn
            depressed
            color="#F99D20"
            class="mr-5 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateEmpInfoWithID"
            style="border-radius: 2rem"
            >{{ $t("Confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewEdit from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils, CheckViewEdit],
  data() {
    return {
      empcode: localStorage.getItem("empcode"),
      permission: {
        edit: true,
      },
      confirmdialog: false,
      disable: false,
      url: enurl.apiUrl,
      size: 10,
      userroleEdit: [],
      useridEdit: [],
      passwordEdit: [],
      arrow: true,
      showlock: false,
      showbtn: true,
      loadingdialog: false,
      characters: "a-z,A-Z,0-9",
      edituser: {
        employeename: null,
        usergroup: [],
        userrole: [],
        userid: null,
        password: null,
        userpermis: [],
        enable_Login: false,
        time_attendance: false,
      },
      lockdisable: true,
      user_ID: 0,
      emp: [],
      getusergp: [],
      TempGpList: [],
      updateusergp: [],
      updaterole: [],
      updateper: [],
      show: false,
      Advanceimage: {
        up: require("@/assets/images/up1@2x.png"),
        down: require("@/assets/images/down1@2x.png"),
      },
      lockimage: {
        lock: require("@/assets/images/padlock.png"),
        unlock: require("@/assets/images/padunlock.png"),
      },
      tempuser: null,
      temprole: null,
      tempper: null,
      tempusergpname: [],
      tempuserrole: [],
      tempuserper: [],
      UserNameList: [],
      rolelist: [],
      Personallist: [],
      tempGroup: [],
      tempRole: [],
      emp_status: 0,
      tempRoute: {},
      temp_ID: {},
    };
  },
  watch: {
    "edituser.userid"() {
      this.useridEdit = [];
    },
    "edituser.password"() {
      this.passwordEdit = [];
    },
    "edituser.userrole"() {
      this.userroleEdit = [];
    },
  },
  async mounted() {
    this.loadingdialog = true;
    this.getRightID();
    this.permission = this.CheckViewEdit(191, 192);
    await this.Getprofile();
    await this.GetEmpByID();
    this.GetUserNameByCompany();
    this.GetRoleByUserGroup();
    this.loadingdialog = false;
  },

  methods: {
    // ConfirmEmail() {
    //   let self = this;
    //   if (self.edituser.enable_Login == true) {
    //     self.confirmdialog = true;
    //   } else {
    //     self.confirmdialog = false;
    //     self.UpdateEmpInfoWithIDWithoutEmail();
    //   }
    // },
    Change_Switch() {
      let self = this;
      let temp = self.edituser.enable_Login;
      localStorage.setItem("enable_Login", temp);
      return temp;
    },
    Change_Switch_Attendance() {
      let self = this;
      let temp = self.edituser.time_attendance;
      localStorage.setItem("time_attendance", temp);
      return temp;
    },
    async getRightID() {
      let self = this;
      if (self.$route.params.id != null) {
        self.tempRoute = {
          company_ID: store.state.companyID,
          employee_ID: parseInt(self.$route.params.id),
        };
        self.temp_ID = {
          employee_ID: parseInt(self.$route.params.id),
        };
      } else {
        self.tempRoute = {
          company_ID: store.state.companyID,
          employee_ID: await self.ChangeCodeToID(),
        };
        self.temp_ID = {
          employee_ID: await self.ChangeCodeToID(),
        };
      }
    },
    async ChangeCodeToID() {
      let self = this;
      let Id = self.empcode;
      if (Id != null && Id != undefined) {
        let temp = {
          employee_Code: Id,
          company_ID: store.state.companyID,
        };
        const response = await axios.post(
          `${self.url}PersonalInfo/GetPersonalInformationWithCode`,
          temp
        );
        let empId = response.data.data.employee_ID;
        return empId;
      } else {
        return null;
      }
    },
    async GetRoleByUserGroup() {
      let self = this;
      let ugpId = JSON.parse(localStorage.getItem("UserEditUserGp"));
      if (ugpId != null && ugpId != undefined) {
        if(ugpId.length != 0){
          let tempp = {
          company_ID: store.state.companyID,
          user_Group_ID: ugpId[0].user_Group_ID,
        };
        await axios
          .post(`${self.url}UserGroupRole/GetUserGroupRole`, tempp)
          .then(function (response) {
            self.rolelist = response.data.data;
            // console.log("RoleAPI", self.rolelist);
            localStorage.removeItem("TempUserGroup");
          });
        }
      } else {
        let ugpIds = localStorage.getItem("TempUserGroup");
        if (ugpIds != null && ugpIds != "" && ugpIds != "null") {
          let temp = {
            company_ID: store.state.companyID,
            user_Group_ID: parseInt(ugpIds),
          };
          await axios
            .post(`${self.url}UserGroupRole/GetUserGroupRole`, temp)
            .then(function (response) {
              self.rolelist = response.data.data;
              // console.log("RoleAPI", self.rolelist);
            });
        }
      }
    },
    ValidateUserGroupRole() {
      let self = this;
      let RoleId = JSON.parse(localStorage.getItem("UserEditRole"));
      let RoleIds = localStorage.getItem("TempRole");
      // console.log("RoleID", RoleId);

      // if(self.rolelist.length == 0){
      //   alert("Selected UserGroup and Role doesn't match.Please recheck");
      //   return false;
      // }
      // else{
        if (
        self.updateusergp.length != 0 &&
        self.updaterole.length != 0 &&
        RoleId != null
        ) {
          if (self.rolelist[0].role_ID == RoleId[0].role_ID) {
            return true;
          } else {
            return false;
          }
        } else if (
          self.updateusergp.length != 0 &&
          self.updaterole.length != 0 &&
          RoleIds != ""
        ) {
          if (self.rolelist[0].role_ID == parseInt(RoleIds)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      // }
    },
    keymonitor: function (event) {
      if (event.key == "Backspace") {
        this.edituser.password = null;
        this.show = true;
      }
    },
    async Getprofile() {
      let self = this;
      // let local_ID = self.ChangeCodeToID();
      await self.getRightID();
      let temp = self.tempRoute;
      await axios
        .post(`${self.url}PersonalInfo/GetShortInfo`, temp)
        .then(function (response) {
          self.Personallist = response.data.data;
          self.emp_status = response.data.data[0].employeeStatus;
          self.Personallist[0].image = self.Personallist[0].image != null ? self.Personallist[0].image : 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png';
          sessionStorage.setItem("personalimg", self.Personallist[0].image);
          store.state.personalimg = sessionStorage.getItem("personalimg");
        });
    },
    async GetEmpByID() {
      let self = this;
      await self.getRightID();
      let temp = self.tempRoute;
      await axios
        .post(`${self.url}Confirmemployee/GetConfirmEmployeeInfo`, temp)
        .then(function (response) {
          self.emp = response.data.data;
          //console.log(self.emp);
          if (self.emp != null) {
            setTimeout(() => {
              if(response.data.data.empUserGroup != null){
                self.tempGroup = response.data.data.empUserGroup.map(
                  (x) => x.user_Group_ID
                );
                localStorage.setItem("TempUserGroup", self.tempGroup);
              }
              self.tempRole = response.data.data.empRoleData.map(
                (x) => x.role_ID
              );
              localStorage.setItem("TempRole", self.tempRole);
            }, 100);
            self.edituser.employeename = response.data.data.employee_Name;
            self.user_ID = response.data.data.user_ID;
            let temp_enable = localStorage.getItem("enable_Login");
            let temp_time_attendnace = localStorage.getItem("time_attendance");
            if (
              temp_enable != "null" &&
              temp_enable != null &&
              temp_enable != "" &&
              temp_enable != undefined
            ) {
              self.edituser.enable_Login = JSON.parse(temp_enable);
            } else {
              self.edituser.enable_Login = response.data.data.enable_Login;
            }
            if (
              temp_time_attendnace != "null" &&
              temp_time_attendnace != null &&
              temp_time_attendnace != "" &&
              temp_time_attendnace != undefined
            ) {
              self.edituser.time_attendance = JSON.parse(temp_time_attendnace);
            } else {
              self.edituser.time_attendance =
                response.data.data.time_Attendance;
            }
            let lckshow = response.data.data.is_Locked;
            if (lckshow == true) {
              self.showlock = lckshow;
              self.lockdisable = false;
            } else {
              self.showlock = lckshow;
              self.lockdisable = true;
            }

            //CombinewithLocalstore
            //UserGroup
            if(response.data.data.empUserGroup != null){
                self.edituser.usergroup = response.data.data.empUserGroup.map(
                (x) => x.user_Group_Name
                );
              
                self.updateusergp = [];
                let tempuid = response.data.data.empUserGroup.map(
                  (x) => x.user_Group_ID
                );
                for (let x = 0; x < tempuid.length; x++) {
                  self.updateusergp.push({
                    user_Group_ID: tempuid[x],
                  });
                }
              
            } else {
              self.tempuser = JSON.parse(localStorage.getItem("UserEditUserGp"));
              if (self.tempuser != null) {
                self.edituser.usergroup = [
                  // ...self.edituser.usergroup,
                  ...self.tempuser.map((x) => x.user_Group_Name),
                ];
              
                self.tempusergpname = [
                  ...self.tempuser.map((x) => x.user_Group_ID),
                ];
              
                self.edituser.usergroup = [...new Set(self.edituser.usergroup)];
              
                if (self.tempusergpname != null) {
                  self.updateusergp = [];
                  for (let i = 0; i < self.tempusergpname.length; i++) {
                    self.updateusergp.push({
                      user_Group_ID: self.tempusergpname[i],
                    });
                  }
                }
              }
            }
            

            //CombinewithLocalstore
            //UserRole

            self.edituser.userrole = response.data.data.empRoleData.map(
              (x) => x.role_Name
            );
            self.temprole = JSON.parse(localStorage.getItem("UserEditRole"));
            if (self.temprole != null) {
              self.edituser.userrole = [
                ...self.temprole.map((x) => x.role_Name),
              ];
              self.tempuserrole = [...self.temprole.map((x) => x.role_ID)];

              self.edituser.userrole = [...new Set(self.edituser.userrole)];
              if (self.tempuserrole != null) {
                self.updaterole = [];
                for (let j = 0; j < self.tempuserrole.length; j++) {
                  self.updaterole.push({
                    role_ID: self.tempuserrole[j],
                  });
                }
              }
            } else {
              self.updaterole = [];
              let temprid = response.data.data.empRoleData.map(
                (x) => x.role_ID
              );
              for (let x = 0; x < temprid.length; x++) {
                self.updaterole.push({
                  role_ID: temprid[x],
                });
              }
            }

            //CombinewithLocalstore
            //UserPermission
            self.edituser.userpermis = [];
            if(response.data.data.empPermisData != null){
              self.edituser.userpermis = response.data.data.empPermisData.map(
                (x) => x.permission_Name
              );
            }
            self.tempper = JSON.parse(localStorage.getItem("UserEditPer"));
            if (self.tempper != null) {
              self.edituser.userpermis = [
                ...self.tempper.map((x) => x.pername),
              ];
              self.edituser.userpermis = [...new Set(self.edituser.userpermis)];
              self.tempuserper = [...self.tempper.map((x) => x.perid)];

              if (self.tempuserper != null) {
                self.updateper = [];
                for (let j = 0; j < self.tempuserper.length; j++) {
                  self.updateper.push({
                    permission_ID: self.tempuserper[j],
                  });
                  //console.log(self.updaterole)
                }
              }
            } else {
              self.updateper = [];
              let tempr = [];
              if(response.data.data.empPermisData != null){
                tempr = response.data.data.empPermisData.map(
                  (x) => x.permission_ID
                );
              }
              for (let x = 0; x < tempr.length; x++) {
                self.updateper.push({
                  permission_ID: tempr[x],
                });
              }
              //console.log(self.updateper);
            }

            //UserName //Password
            if (response.data.data.user_Name != null) {
              self.edituser.userid = response.data.data.user_Name;
            } else {
              self.edituser.userid = self.Personallist[0].employee_Code;
            }
            if (response.data.data.password != null) {
              self.showbtn = false;
              self.show = false;
              self.edituser.password = response.data.data.password;
            } else {
              self.showbtn = true;
              self.edituser.password = self.generate();
            }
          } else {
            let firstname = self.Personallist[0].name;
            let lastname = self.Personallist[0].lastName;
            self.edituser.employeename = `${firstname} ${lastname}`;
            self.edituser.userid = self.Personallist[0].employee_Code;
            let temp_enable = localStorage.getItem("enable_Login");
            let temp_time_attendnace = localStorage.getItem("time_attendance");
            if (
              temp_enable != "null" &&
              temp_enable != null &&
              temp_enable != "" &&
              temp_enable != undefined
            ) {
              self.edituser.enable_Login = JSON.parse(temp_enable);
            } else {
              self.edituser.enable_Login = self.Personallist[0].enable_Login;
            }
            if (
              temp_time_attendnace != "null" &&
              temp_time_attendnace != null &&
              temp_time_attendnace != "" &&
              temp_time_attendnace != undefined
            ) {
              self.edituser.time_attendance = JSON.parse(temp_time_attendnace);
            } else {
              self.edituser.time_attendance =
                response.data.data.time_Attendance;
            }
            self.showbtn = true;
            self.edituser.password = self.generate();
            // Get Role
            self.temprole = JSON.parse(localStorage.getItem("UserEditRole"));
            if (self.temprole != null) {
              self.edituser.userrole = [
                ...self.temprole.map((x) => x.role_Name),
              ];
              self.tempuserrole = [...self.temprole.map((x) => x.role_ID)];

              self.edituser.userrole = [...new Set(self.edituser.userrole)];
              if (self.tempuserrole != null) {
                self.updaterole = [];
                for (let j = 0; j < self.tempuserrole.length; j++) {
                  self.updaterole.push({
                    role_ID: self.tempuserrole[j],
                  });
                }
              }
            }
            //Get UserGroup
            self.tempuser = JSON.parse(localStorage.getItem("UserEditUserGp"));
            if (self.tempuser != null) {
              self.edituser.usergroup = [
                // ...self.edituser.usergroup,
                ...self.tempuser.map((x) => x.user_Group_Name),
              ];

              self.tempusergpname = [
                ...self.tempuser.map((x) => x.user_Group_ID),
              ];

              self.edituser.usergroup = [...new Set(self.edituser.usergroup)];

              if (self.tempusergpname != null) {
                self.updateusergp = [];
                for (let i = 0; i < self.tempusergpname.length; i++) {
                  self.updateusergp.push({
                    user_Group_ID: self.tempusergpname[i],
                  });
                }
              }
            }

            // Get Permission
            self.tempper = JSON.parse(localStorage.getItem("UserEditPer"));
            if (self.tempper != null) {
              self.edituser.userpermis = [
                ...self.tempper.map((x) => x.pername),
              ];
              self.edituser.userpermis = [...new Set(self.edituser.userpermis)];
              self.tempuserper = [...self.tempper.map((x) => x.perid)];

              if (self.tempuserper != null) {
                self.updateper = [];
                for (let j = 0; j < self.tempuserper.length; j++) {
                  self.updateper.push({
                    permission_ID: self.tempuserper[j],
                  });
                  //console.log(self.updaterole)
                }
              }
            }
          }
        });
    },
    goto() {
      let self = this;
      localStorage.removeItem("UserEditUserGp");
      localStorage.removeItem("UserEditRole");
      localStorage.removeItem("UserEditPer");
      localStorage.removeItem("enable_Login");
      localStorage.removeItem("time_attendance");
      self.disable = true;
      self.GetEmpByID();
      self.userroleEdit = [];
      self.useridEdit = [];
      self.passwordEdit = [];
      // this.$router.push("/usermanagement/user");
    },
    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < this.size; i++) {
        tempPassword += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return tempPassword;
    },
    generateUserID() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      for (let i = 0; i < this.size; i++) {
        tempPassword += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return tempPassword;
    },
    async DeleteoldUserGP() {
      let self = this;
      await self.getRightID();
      self.loadingdialog = true;
      // let id = parseInt(self.$route.params.id);
      let tempdel = self.temp_ID;
      await axios
        .post(`${self.url}Confirmemployee/DeleteOldData`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    Validation() {
      let self = this;
      if (
        self.edituser.employeename != null &&
        self.edituser.usergroup != null &&
        self.edituser.userrole != null &&
        self.edituser.userid != null &&
        self.edituser.password != null &&
        self.edituser.userpermis != null &&
        self.edituser.employeename != "" &&
        self.edituser.usergroup != "" &&
        self.edituser.userrole != "" &&
        self.edituser.userid != "" &&
        self.edituser.password != "" &&
        self.edituser.userpermis != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateAccount() {
      let self = this;
      let result;
      let FilterUserNameList = self.UserNameList.filter(
        (person) => person.userId != self.user_ID
      );
      for (let i = 0; i < FilterUserNameList.length; i++) {
        if (
          FilterUserNameList[i].userName.toLowerCase() ==
          self.edituser.userid.toLowerCase()
        ) {
          alert(
            "This username is already taken by another user. Please use a different username."
          );
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    GetUserNameByCompany() {
      let self = this;
      let tempp = {
        companyId: store.state.companyID,
      };

      axios
        .post(`${self.url}UserInfo/GetUserbyCompanyId`, tempp)
        .then(function (response) {
          self.UserNameList = response.data.data;
        });
    },
    async UpdateEmpInfoWithID() {
      let self = this;
      self.loadingdialog = true;
      let IsExistedUserName = self.ValidateAccount();
      let CheckMatch = self.ValidateUserGroupRole();
      self.userroleEdit = [(v) => !!v || "Role is required"];
      self.useridEdit = [(v) => !!v || "Username is required"];
      self.passwordEdit = [(v) => !!v || "Password is required"];
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.edituser.userrole != "" &&
        self.edituser.userrole != null &&
        self.edituser.userid != "" &&
        self.edituser.userid != null &&
        self.edituser.password != "" &&
        self.edituser.password != null &&
        self.edituser.enable_Login != null &&
        self.edituser.time_attendance != null
      ) {
        if (IsExistedUserName == true) {
          if (CheckMatch == true) {
            self.DeleteoldUserGP();
            let temp = {
              employee_ID:
                parseInt(self.$route.params.id) != undefined
                  ? parseInt(self.$route.params.id)
                  : self.ChangeCodeToID(),
              user_ID: self.user_ID,
              company_ID: store.state.companyID,
              user_Name: self.edituser.userid,
              password: self.edituser.password,
              is_Locked: self.showlock,
              enable_Login: self.edituser.enable_Login,
              time_Attendance: self.edituser.time_attendance,
              empUserGroup: self.updateusergp,
              empRoleData: self.updaterole,
              empPermisData: self.updateper,
            };

            axios
              .post(`${self.url}Confirmemployee/EditConfirmEmployee`, temp)
              .then(function (response) {
                if (response.data.status == 0) {
                  // alert("Update Successfully");
                  self.SendEmail();
                  self.confirmdialog = false;
                  self.disable = true;
                  self.GetEmpByID();
                  self.loadingdialog = false;
                  localStorage.removeItem("UserEditUserGp");
                  localStorage.removeItem("UserEditRole");
                  localStorage.removeItem("UserEditPer");
                  localStorage.removeItem("enable_Login");
                  localStorage.removeItem("time_attendance");
                  localStorage.removeItem("TempRole");
                  localStorage.removeItem("TempUserGroup");
                  //self.LogTrace(null, "Update User", 34, "Medium");
                }
              })
              .catch(function (error) {
                // alert(error);
                self.LogTrace(error, "Update User Fail", 34, "Medium");
                self.confirmdialog = false;
                self.loadingdialog = false;
              });
          } else {
            alert("Selected UserGroup and Role doesn't match.Please recheck");
            self.loadingdialog = false;
            self.confirmdialog = false;
          }
        } else {
          self.loadingdialog = false;
          self.confirmdialog = false;
        }
      } else {
        self.loadingdialog = false;
        if (self.edituser.userrole == "" || self.edituser.userrole == null) {
          alert("Please select Role");
          self.confirmdialog = false;
        } else {
          alert("please fill all the fields");
          self.confirmdialog = false;
        }
      }
    },
    async UpdateEmpInfoWithIDWithoutEmail() {
      let self = this;
      self.loadingdialog = true;
      let IsExistedUserName = self.ValidateAccount();
      let CheckMatch = self.ValidateUserGroupRole();
      self.userroleEdit = [(v) => !!v || "Role is required"];
      self.useridEdit = [(v) => !!v || "Username is required"];
      self.passwordEdit = [(v) => !!v || "Password is required"];
      let tempvalidate = self.$refs.formEdit.validate();
      let id = null;
      if (self.$route.params.id == null || self.$route.params.id == undefined) {
        id = await self.ChangeCodeToID();
      }

      if (
        tempvalidate == true &&
        self.edituser.userrole != "" &&
        self.edituser.userrole != null &&
        self.edituser.userid != "" &&
        self.edituser.userid != null &&
        self.edituser.password != "" &&
        self.edituser.password != null &&
        self.edituser.enable_Login != null &&
        self.edituser.time_attendance != null
      ) {
        if (IsExistedUserName == true) {
          if (CheckMatch == true) {
            await self.DeleteoldUserGP();
            setTimeout(() => {
              let temp = null;
              if (self.$route.params.id != null) {
                temp = {
                  employee_ID: parseInt(self.$route.params.id),
                  user_ID: self.user_ID,
                  company_ID: store.state.companyID,
                  user_Name: self.edituser.userid,
                  password: self.edituser.password,
                  is_Locked: self.showlock,
                  enable_Login: self.edituser.enable_Login,
                  time_Attendance: self.edituser.time_attendance,
                  empUserGroup: self.updateusergp,
                  empRoleData: self.updaterole,
                  empPermisData: self.updateper,
                };
              } else if (self.$route.params.id == undefined) {
                temp = {
                  employee_ID: id,
                  user_ID: self.user_ID,
                  company_ID: store.state.companyID,
                  user_Name: self.edituser.userid,
                  password: self.edituser.password,
                  is_Locked: self.showlock,
                  enable_Login: self.edituser.enable_Login,
                  time_Attendance: self.edituser.time_attendance,
                  empUserGroup: self.updateusergp,
                  empRoleData: self.updaterole,
                  empPermisData: self.updateper,
                };
              }

              axios
                .post(`${self.url}Confirmemployee/EditConfirmEmployee`, temp)
                .then(function (response) {
                  if (response.data.status == 0) {
                    alert("Update Successfully");
                    self.confirmdialog = false;
                    self.disable = true;
                    self.GetEmpByID();
                    self.loadingdialog = false;
                    localStorage.removeItem("UserEditUserGp");
                    localStorage.removeItem("UserEditRole");
                    localStorage.removeItem("UserEditPer");
                    localStorage.removeItem("enable_Login");
                    localStorage.removeItem("time_attendance");
                    localStorage.removeItem("TempRole");
                    localStorage.removeItem("TempUserGroup");
                    //self.LogTrace(null, "Update User", 34, "Medium");
                  }
                })
                .catch(function (error) {
                  // alert(error);
                  self.LogTrace(error, "Update User Fail", 34, "Medium");
                  self.confirmdialog = false;
                  self.loadingdialog = false;
                });
            }, 1000);
          } else {
            alert("Selected UserGroup and Role doesn't match.Please recheck");
            self.confirmdialog = false;
            self.loadingdialog = false;
          }
        } else {
          self.loadingdialog = false;
          self.confirmdialog = false;
        }
      } else {
        self.loadingdialog = false;
        if (self.edituser.userrole == "" || self.edituser.userrole == null) {
          alert("Please select Role");
          self.confirmdialog = false;
        } else {
          alert("please fill all the fields");
          self.confirmdialog = false;
        }
      }
    },
    async SendEmail() {
      let self = this;
      let tempData = {
        employee_ID:
          parseInt(self.$route.params.id) != undefined
            ? parseInt(self.$route.params.id)
            : await self.ChangeCodeToID(),
        company_ID: store.state.companyID,
      };
      await axios
        .post(`${self.url}Confirmemployee/SendemailComfirmEmployee`, tempData)
        .then(function (response) {
          if (response.data.message == "Success") {
            alert("Update and Mail Sent Successfully");
          }
        });
    },
  },
};
</script>
    <style scoped>
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

/*  Toggle Switch  */

/* .toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-right: 50px;
    margin-top: 1.2rem;
    cursor: pointer;
    width: 40px;
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }

  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 2px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #fff;
    opacity: 1;
    left: 45%;
  }
  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #a3a7aa;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #f89d1f;
    box-shadow: inset 0 0 0 30px #f89d1f;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
} */

@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(
        circle 12px,
        white 100%,
        transparent calc(100% + 1px)
      )
      #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: white;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
  }
}
</style>