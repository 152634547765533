<template>
  <div>
    <div style="padding-left: 0px;padding-right: 1%;margin-bottom: 1rem;">
      <v-row>
        <v-btn icon @click="back()" color="white" class="mt-6 ml-6">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p text style="cursor: pointer;
            font-size: 26px;
            margin-top: 0.2rem;
            margin-left: 5px;
            padding: 18px;">
          {{ $t("otherinfo") }}
        </p>
      </v-row>
    </div>
    <v-card class="pt-5" height="100vh" style="margin-bottom: 5rem; margin-left: 2rem; margin-right: 2rem">
      <v-row class="pl-md-0" style="margin-left: 15px; margin-right: 10px; margin-top: 0rem !important">
        <v-col class="d-flex justify-end mb-5">
          <v-btn depressed color="#F99D20" class="white--text text-capitalize plusbtn" @click="uploaddialog2 = true">
            <v-icon right dark class="mr-2">mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="mx-5" v-if="$route.params.otherid == null">
        <v-data-table :headers="gethealthheader" :items="storeFiles" hide-default-footer hide-default-header>
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr class="tbheader">
                <th class="first">{{ $t("no") }}</th>
                <th>{{ $t("DocName") }}</th>
                <th>{{ $t("attachments") }}</th>
                <th class="last"></th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ item.docname }}</td>
              <td class="d-flex mt-3" @click="showiframeforDetail(item)">
                <v-img class="mb-3 filetype" v-if="item.type == 'image/png'" src="@/assets/images/png.png"></v-img>
                <v-img class="mb-3 filetype" v-if="item.type == 'image/jpeg'" src="@/assets/images/jpg.png"></v-img>
                <v-img class="mb-3 filetype" v-if="item.type == 'image/jpg'" src="@/assets/images/jpg.png"></v-img>
                <v-img class="mb-3 filetype" v-if="item.type == 'application/pdf'"
                  src="@/assets/images/pdf.png"></v-img>
                <a style="text-decoration: underline; color: black;"> {{ item.name }}</a>
              </td>
              <td>
                <v-btn icon @click="Editdia(index)">
                  <img class="editIcon" :src="images.edit" alt="editIcon" />
                </v-btn>
                <v-btn icon @click="Deletedia(index)">
                  <img class="deleteIcon" :src="images.delete" alt="deleteIcon" />
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>

      <!-- API -->
      <div class="mx-5" v-if="$route.params.otherid != null">
        <v-data-table :headers="gethealthheader" :items="APIdata" hide-default-footer hide-default-header>
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr class="tbheader">
                <th class="first">{{ $t("no") }}</th>
                <th>{{ $t("DocName") }}</th>
                <th>{{ $t("attachments") }}</th>
                <th class="last"></th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ item.attachment_Name }}</td>
              <td class="d-flex mt-3" @click="showiframeforDetail(item)">
                <v-img class="mb-3 filetype" v-if="getFileType(item.attachment_Url) === 'png'"
                  src="@/assets/images/png.png"></v-img>
                <v-img class="mb-3 filetype"
                  v-if="getFileType(item.attachment_Url) === 'jpg' || getFileType(item.attachment_Url) === 'jpeg'"
                  src="@/assets/images/jpg.png"></v-img>
                <v-img class="mb-3 filetype" v-if="getFileType(item.attachment_Url) === 'pdf'"
                  src="@/assets/images/pdf.png"></v-img>
                <a style="color: black;">{{ getFileName(item.attachment_Url) }}</a>
              </td>

              <td>
                <v-btn icon @click="Editdia(item.attachment_EmployeeInfo_ID)">
                  <img class="editIcon" :src="images.edit" alt="editIcon" />
                </v-btn>
                <v-btn icon @click="Deletedia(item.attachment_EmployeeInfo_ID)">
                  <img class="deleteIcon" :src="images.delete" alt="deleteIcon" />
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <v-dialog v-model="uploaddialog2" persistent max-width="700">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -20px; margin-left: 10px">
            {{ $t("Adddocument") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-card-subtitle class="mt-5">{{ $t("DocName") }}<span class="red--text"> *</span></v-card-subtitle>
            </v-row>
            <v-text-field class="shrink" color="#F99D20" dense outlined autocomplete="off" style="font-size: 14px"
              v-model="docname" :error-messages="errorMsgCrty">
            </v-text-field>
            <v-row>
              <v-card-subtitle>{{ $t("attachment") }}<span class="red--text"> *</span></v-card-subtitle>
            </v-row>
            <div v-if="fileuploaded == false">
              <div style="height: 80px;" @click="onpickfile" class="drop-area" @dragover.prevent="handleDragOver"
                @drop="handleFileDrop">
                <div style="text-align: center">
                  <v-img :src="images.cloudupload" class="cvicon"></v-img>
                  <p style="color: #a3a7aa">{{ $t("choosefile") }}</p>
                </div>
              </div>
              <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none" id="profile_picture"
                accept=".pdf, .png, .jpeg, .jpg" />
            </div>
            <div v-if="fileuploaded" class="mr-0 pa-3 relative">
              <div class="mt-2 d-flex">
                <v-img class="mb-3 filetype" v-if="uploadedFiles[0].type == 'image/png'"
                  src="@/assets/images/png.png"></v-img>
                <v-img class="mb-3 filetype" v-if="uploadedFiles[0].type == 'image/jpeg'"
                  src="@/assets/images/jpg.png"></v-img>
                <v-img class="mb-3 filetype" v-if="uploadedFiles[0].type == 'image/jpg'"
                  src="@/assets/images/jpg.png"></v-img>
                <v-img class="mb-3 filetype" v-if="uploadedFiles[0].type == 'application/pdf'"
                  src="@/assets/images/pdf.png"></v-img>
                <div class="text-center mt-2">
                  {{ uploadedFiles[0].name ? (uploadedFiles[0].name.length > 15 ? uploadedFiles[0].name.slice(0, 15) + "..." : uploadedFiles[0].name) : "" }}
                </div>
                <v-btn icon small @click="onpickfile" style="left: 3%;">
                  <v-img :src="images.edit" class="editicon" @change="handleFileSelect"></v-img>
                </v-btn>
                <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none"
                  id="profile_picture" accept=".pdf, .png, .jpeg, .jpg" />
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize commoncancelbtn" outlined @click="closeall()" text>
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn class="white--text mr-2 text-capitalize commonsavebtn" @click="uploadattachment">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="uploaddialog" persistent max-width="700">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -20px; margin-left: 10px">
            {{ $t("Editdocument") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-card-subtitle class="mt-5">{{ $t("DocName") }}<span class="red--text"> *</span></v-card-subtitle>
            </v-row>
            <v-text-field class="shrink" color="#F99D20" dense outlined autocomplete="off" style="font-size: 14px"
              v-model="docname" :error-messages="errorMsgCrty">
            </v-text-field>
            <v-row>
              <v-card-subtitle>{{ $t("attachment") }}<span class="red--text"> *</span></v-card-subtitle>
            </v-row>
            <div v-if="fileuploaded == false">
              <div style="height: 80px;" @click="onpickfile" class="drop-area" @dragover.prevent="handleDragOver"
                @drop="handleFileDrop">
                <div style="text-align: center">
                  <v-img :src="images.cloudupload" class="cvicon"></v-img>
                  <p style="color: #a3a7aa">{{ $t("choosefile") }}</p>
                </div>
              </div>
              <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none" id="profile_picture"
                accept=".pdf, .png, .jpeg, .jpg" />
            </div>
            <div v-if="fileuploaded" class="mr-0 pa-3 relative">
              <div class="mt-2 d-flex">
                <!-- Dynamically show icon based on file type -->
                <v-img class="mb-3 filetype"
                  v-if="uploadedFiles[0].type === 'image/png' || uploadedFiles[0].type === 'png'"
                  src="@/assets/images/png.png"></v-img>
                <v-img class="mb-3 filetype"
                  v-if="uploadedFiles[0].type === 'image/jpeg' || uploadedFiles[0].type === 'image/jpg'"
                  src="@/assets/images/jpg.png"></v-img>
                <v-img class="mb-3 filetype" v-if="uploadedFiles[0].type === 'jpeg' || uploadedFiles[0].type === 'jpg'"
                  src="@/assets/images/jpg.png"></v-img>
                <v-img class="mb-3 filetype"
                  v-if="uploadedFiles[0].type === 'application/pdf' || uploadedFiles[0].type === 'pdf'"
                  src="@/assets/images/pdf.png"></v-img>
                <!-- Display the file name with ellipsis if too long -->
                <div class="text-center mt-2">
                  {{ uploadedFiles[0].name.length > 15 ? uploadedFiles[0].name.slice(0, 15) + " ..." : uploadedFiles[0].name }}
                </div>
                <!-- Edit button to select a new file -->
                <v-btn icon small @click="onpickfile">
                  <v-img :src="images.edit" class="editicon"></v-img>
                </v-btn>
                <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none"
                  id="profile_picture" accept=".pdf, .png, .jpeg, .jpg" />
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize commoncancelbtn" outlined @click="closeall()" text>
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn class="white--text mr-2 text-capitalize commonsavebtn" @click="updateattachment">
            {{ $t("update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog v-model="Deletedialog" class="dialog" max-width="532">
      <v-card style="border-radius: 15px;">
        <v-card-title class="d-flex justify-center align-center">
          {{ $t("confirmation") }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center pb-4 ">
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" @click="Deletedialog = false" text>
            {{ $t("cancel") }}
          </v-btn>
          <v-btn class="mr-2 white--text text-capitalize commonsavebtn" @click="deleteOther">
            {{ $t("Confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Detail dialog -->
    <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg"
      style="overflow: hidden">
      <v-card>
        <v-card-title class="d-flex justify-end" style="padding-right: 1.5rem;">
          <v-btn icon @click="downloadImage()">
            <v-icon size="30">mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="dialogforDownload = false">
            <v-icon size="30">mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="padding: 0 1.5rem 1.5rem;">
          <v-img :src="imgForshow" max-width="500"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- pdf dialog -->
    <v-dialog v-model="printdialogforupdate" max-width="75%">
      <v-card style="background-color: white">
        <v-card-title class="d-flex justify-end">
          <v-btn v-if="pdfString" icon @click="downloadPDF()">
            <v-icon size="30">mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="closePreview" style="margin-right: 1rem;">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ResponsivePdfViewer v-if="pdfString" :blobUrl="pdfString" />
          <div v-else>Loading PDF...</div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
//import checkPermissionOne from "@/function/PersonalManagementPermission.js";
import axios from "axios";
import enurl from "@/api/environment";
import LoadingDialog from "@/components/Loading.vue";
import ResponsivePdfViewer from '@/components/PDFViewer.vue';

export default {
  //mixins: [checkPermissionOne],
  components: {
    LoadingDialog,
    ResponsivePdfViewer
  },
  data() {
    return {
      // permission: {
      //   upload: true,
      // },
      attachmentData: [],
      docAdd: [],
      docname: "",
      temp: null,
      fileuploaded: false,
      uploadedFiles: [],
      APIdata: [],
      editIndex: null,
      Deletedialog: false,
      errorMsgCrty: "",
      LoadingDialog: false,
      url: enurl.apiUrl,
      uploaddialog: false,
      showthis: true,
      localImageUrl1: "",
      othername: "",
      tempImageUrl: "",
      uploaddialog2: false,
      showfile: false,
      showfilename: null,
      chosenfile: null,
      imageName: "",
      imageUrl: localStorage.localotherinfoUrl,
      imageurl1: "",
      imageFile: "",
      fileName: null,
      // fileshow: false,
      workpermit: [],
      work: "",
      ind: 0,
      ss: false,
      showedit: false,
      dialogforDownload: false,
      printdialogforupdate: false,
      pdfString: "",
      iframeShow: false,
      imgForshow: "",
      dllink: "",
      pdfBlob: null,
    };
  },
  watch: {
    "docname"(v) {
      if (v) this.errorMsgCrty = "";
    },
  },
  computed: {
    images() {
      return this.$store.state.images;
    },
    storeFiles() {
      return this.$store.getters.getUploadedFiles;
    },
    gethealthheader() {
      return [
        {
          text: this.$t("no"),
          align: "start",
          value: "No",
        },
        {
          text: this.$t("DocName"),
          value: "doc_name",
        },
        { text: this.$t("attachment"), value: "attachment", sortable: true },
        { text: "Action", sortable: false },
      ];
    },
    // fileType() {
    //   const extension = this.fileAPIName.split('.').pop().toLowerCase();
    //   if (extension === 'png') return 'png';
    //   if (extension === 'jpg' || extension === 'jpeg') return 'jpg';
    //   if (extension === 'pdf') return 'pdf';

    //   return 'unknown';
    // },
    // fileAPIName() {
    //   return this.APIdata.attachment_Url.split('/').pop();
    // },
  },
  mounted() {
    let self = this;
    if (self.$route.params.otherid != null) {
      self.Getotherinfo();
      this.showedit = true;
      let ee = localStorage.editothername;
      this.othername = ee;
    }
    if (localStorage.fortext != "undefined") {
      let ww = localStorage.fortext;
      this.othername = ww;
    } else {
      this.othername = "";
    }
    //this.permission = this.checkPermissionFive(120);
    let task1 = localStorage.localeditotherinfoUrl;
    this.localImageUrl1 = task1;
  },
  created: function () {
    let taskwork = JSON.parse(localStorage.getItem("edit"));
    if (this.$route.params.otherid != null) {
      let task = localStorage.localeditotherinfoUrl;
      if (task == null) {
        this.imageUrl1 = taskwork.otherimage;
        this.ss = true;
        this.localImageUrl1 = "";
      } else {
        taskwork = "";
        this.imageUrl = task;
        this.localImageUrl1 = task;
      }
    } else {
      let taskDB = JSON.parse(localStorage.getItem("localotherinfoUrl"));

      if (taskDB == null) {
        this.localImageUrl1 = "";
        this.imageUrl1 = taskwork.otherimage;
        this.ss = true;
      } else {
        this.localImageUrl1 = taskDB;
      }
    }
  },
  methods: {
    back() {
      let workim = this.$route.params.id;
      if (
        this.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/OtherInformation"
      ) {
        this.$router.push("/selfservice/PersonalProfile/PersonalInformation").catch(() => { });
      } else if (this.$route.name == "newotherinformation") {
        this.$router.push(
          "/PersonnelManagement/NewEmployee/NewPersonalInformation"
        ).catch(() => { });
      } else if (workim != "undefined") {
        this.$router.push(
          `/PersonnelManagement/EditEmployee/${this.$route.params.id}/EditPersonalInformation`
        ).catch(() => { });
      }
    },
    getFileName(url) {
      return url ? url.split('/').pop() : '';
    },
    getFileType(url) {
      const extension = url ? url.split('.').pop().toLowerCase() : '';
      if (extension === 'png') return 'png';
      if (extension === 'jpg' || extension === 'jpeg') return 'jpg';
      if (extension === 'pdf') return 'pdf';
      return 'unknown';
    },
    async Getotherinfo() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        employee_ID: parseInt(this.$route.params.id),
      };
      await axios.post(`${self.url}PersonalInfo/GetAttachment_EmpInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.APIdata = response.data.data;
            self.LoadingDialog = false;
          }
          else {
            alert(response.data.message)
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get OtherInfo Fail", 39, "Critical");
          self.LoadingDialog = false;
        });
    },
    onpickfile() {
      this.$refs.fileupload.click();
    },
    handleDragOver(event) {
      event.preventDefault();
    },

    handleFileDrop(event) {
      event.preventDefault();
      this.handleFiledrag(event);
    },

    async handleFileSelect(event) {
      event.preventDefault();
      const file = event.target.files[0];
      let imageName = file.name;
      let filetype = file.type;
      const maxSize = 25 * 1024 * 1024; 
      if (file) {
        if (file.size > maxSize) {
          alert("File size exceeds 25MB limit. Please select a smaller file.");
          return; // Exit if file is too large
        }  
        const pdfString = URL.createObjectURL(file); // Optional: Blob URL for local display if needed
        this.uploadFilesall(imageName, filetype, pdfString, file);
      }
    },

    async uploadFilesall(imgname, type, pdfurl, file) {
      this.uploadedFiles = [];
      this.LoadingDialog = true;
      this.uploadedFiles.push({
        name: imgname,
        pdfurl: pdfurl, // Optional: Local display Blob URL
        type: type,
      });
      let formData = new FormData();
      formData.append("uploaded_file", file); // Add file to FormData as "uploaded_file"
      try 
      {
        const response = await axios.post(`${this.url}FileUpload/UploadSingleFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 120000,
          maxContentLength: 25 * 1024 * 1024, // Set limit to 50MB
          maxBodyLength: 25 * 1024 * 1024,    // Set limit to 50MB
        });
        this.LoadingDialog = false;
        if (response.data.status === 0)
        {
          this.uploadedFiles[0].imgurl = response.data.data; // Store the returned URL
          this.fileuploaded = true;
        }
        else
        {
          alert(response.data.message);
        }
      }
      catch (error)
      {
        console.error("Error uploading PDF file", error);
      }
    },

    Editdia(index) {
      let self = this;
      if (self.$route.params.otherid != null) {
        const selectedItem = self.APIdata.find(item => item.attachment_EmployeeInfo_ID === index);
        if (selectedItem) {
          self.temp = selectedItem.attachment_EmployeeInfo_ID;
          self.docname = selectedItem.attachment_Name;
          // Create consistent object structure for API files
          self.uploadedFiles = selectedItem.attachment_Url
            ? [{
              name: self.getFileName(selectedItem.attachment_Url) || "unknown",
              imgurl: selectedItem.attachment_Url,
              pdfurl: selectedItem.attachment_Url,
              type: self.getFileType(selectedItem.attachment_Url)
            }]
            : [];
          self.fileuploaded = !!self.uploadedFiles.length;
          self.uploaddialog = true;
        }
      }
      else {
        const selectedFile = self.$store.getters.getUploadedFiles[index];
        self.docname = selectedFile.docname;
        self.uploadedFiles = [selectedFile];
        self.fileuploaded = true;
        self.editIndex = index;
        self.uploaddialog = true;
        self.isEditing = true;
      }
    },

    Deletedia(index) {
      let self = this;
      self.Deletedialog = true;
      self.editIndex = index;
    },

    async deleteOther() {
      let self = this;
      if (self.$route.params.otherid != null) {
        self.LoadingDialog = true;
        let temp = {
          attachment_EmployeeInfo_ID: self.editIndex,
        };
        await axios.post(`${self.url}PersonalInfo/DeleteAttachment_EmpInfo`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              self.Deletedialog = false
              self.Getotherinfo();
            }
            else {
              alert(response.data.message);
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Delete OtherInfo Fail", 39, "Critical");
            self.LoadingDialog = false;
          });
      }
      else {
        self.$store.dispatch('deleteFile', self.editIndex);
        self.Deletedialog = false
      }
    },
    onPickFile() {
      this.$refs.image.click();
    },
    async showiframeforDetail(item) {
      // console.log("data", item);
      let self = this;
      if (self.$route.params.otherid != null) {
        let type = self.getFileType(item.attachment_Url);
        if (type == "pdf") {
          self.printdialogforupdate = true;
          self.iframeShow = true;
          const response = await fetch(item.attachment_Url);
          const blob = await response.blob();
          self.imgForshow = self.getFileName(item.attachment_Url);
          self.pdfBlob = blob;
          self.pdfString = URL.createObjectURL(blob);
          //console.log(self.pdfString)
        }
        else {
          self.dialogforDownload = true;
          self.imgForshow = item.attachment_Url;
          self.dllink = self.imgForshow;
          self.imgName = self.getFileName(self.imgForshow);
        }
      }
      else {
        if (item.type == "application/pdf") {
          self.printdialogforupdate = true;
          self.iframeShow = true;
          const response = await fetch(item.pdfurl);
          self.imgForshow = self.getFileName(item.attachment_Url);
          const blob = await response.blob();
          self.pdfBlob = blob;
          self.pdfString = URL.createObjectURL(blob);
        }
        else {
          self.dialogforDownload = true;
          self.imgForshow = item.imgurl;
          self.dllink = self.imgForshow;
          self.imgName = self.getFileName(self.imgForshow);
        }
      }


    },
    closePreview() {
      if (this.pdfString) {
        URL.revokeObjectURL(this.pdfString); // Clean up the Blob URL
        this.pdfString = null; // Reset the pdfString
        this.pdfBlob = null;
      }
      this.imgForshow = null;
      this.printdialogforupdate = false; // Close the dialog
      this.iframeShow = false; // Hide the iframe
    },
    downloadImage() {
      let self = this;
      const link = document.createElement("a");
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },
    downloadPDF() {
      if (this.pdfBlob) {
        const url = URL.createObjectURL(this.pdfBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = this.imgForshow || "document.pdf";
        link.click();
        URL.revokeObjectURL(url);
      }
    },
    onFilePicked(e) {
      e.preventDefault();
      const files = e.target.files;
      const MAX_SIZE = 2;
      // this.fileshow = false;
      this.ss = false;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        this.showfilename = files[0].name;
        let imageSize = parseFloat(files[0].size / (1024 * 1024)).toFixed(0);
        this.showfile = true;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        if (imageSize > MAX_SIZE) {
          alert(
            `Your image size is ${imageSize}MB! Maximum is ${MAX_SIZE}MB.Please choose again!`
          );
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.tempImageUrl = fr.result;

          this.imageUrl = fr.result.split(",")[1];
          //document.getElementById("hidden").style.display = "none";
          this.showthis = false;

          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
        this.tempImageUrl = "";
      }
    },

    async uploadattachment() {
      let self = this;
      self.errorMsgCrty = !self.docname ? "Please fill in the required fields" : "";
      // self.docAdd = [(v) => !!v || "Title is required"];
      if (self.docname != null && self.docname != "" && self.uploadedFiles.length > 0) {
        if (self.$route.params.otherid != null) {

          self.LoadingDialog = true;
          let temp = {
            attachment_Name: self.docname,
            attachment_Url: self.uploadedFiles[0].imgurl,
            employee_ID: parseInt(self.$route.params.id),
            filetype: self.uploadedFiles[0].type,
          };
          //console.log(temp)
          await axios.post(`${self.url}PersonalInfo/AddAttachment_EmpInfo`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                self.LoadingDialog = false;
                self.uploaddialog2 = false;
                self.Getotherinfo();
              }
              else {
                alert(response.data.message);
              }
              self.LoadingDialog = false;
            })
            .catch(function (error) {
              self.LogTrace(error, "Get OtherInfo Fail", 39, "Critical");
              self.LoadingDialog = false;
            });
        }
        else {
          const fileData = {
            docname: self.docname,
            ...self.uploadedFiles[0],
          };
          if (self.isEditing) {
            self.$store.dispatch('updateFile', { index: self.editIndex, fileData });
          } else {
            self.$store.dispatch('addFile', fileData);
          }
          self.uploaddialog2 = false;
        }
        self.resetForm();
      }

      // self.uploaddialog2 = false;
      else {
        if (
          self.docname == null ||
          self.docname == ""
        ) {
          alert("Please fill all the fields");
        }
        else {
          alert("Please Select Attachment");
        }
      }
    },
    async updateattachment() {
      let self = this;
      self.errorMsgCrty = !self.docname
        ? "Please fill in the required fields"
        : "";
      if (self.docname != null && self.docname != "" && self.uploadedFiles.length > 0) {
        if (self.$route.params.otherid != null) {
          self.LoadingDialog = true;
          let temp = {
            attachment_EmployeeInfo_ID: self.temp,
            attachment_Name: self.docname,
            attachment_Url: self.uploadedFiles[0].imgurl,
            employee_ID: parseInt(self.$route.params.id),
            filetype: self.uploadedFiles[0].type,
          };
          await axios.post(`${self.url}PersonalInfo/UpdateAttachment_EmpInfo`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                self.LoadingDialog = false;
                self.uploaddialog = false;
                self.Getotherinfo();
              }
              else {
                alert(response.data.message);
              }

            })
            .catch(function (error) {
              self.LogTrace(error, "Get OtherInfo Fail", 39, "Critical");
              self.LoadingDialog = false;
            });
        }
        else {
          const fileData = {
            docname: self.docname,
            ...self.uploadedFiles[0],
          };
          if (self.isEditing) {
            self.$store.dispatch('updateFile', { index: self.editIndex, fileData });
          } else {
            self.$store.dispatch('addFile', fileData);
          }
        }
        self.resetForm();
        // self.uploaddialog2 = false;
      } else {
        if (
          self.docname == null ||
          self.docname == ""
        ) {
          alert("Please fill all the fields");
        }
        else {
          alert("Please Select Attachment");
        }
      }
    },
    removeDataType(base64String) {
      var parts = base64String.split(",");
      if (parts.length > 1) {
        return parts[1];
      }
      return base64String;
    },
    resetForm() {
      this.docname = '';
      this.uploadedFiles = [];
      this.errorMsgCrty = "";
      this.fileuploaded = false;
      this.isEditing = false;
      this.editIndex = null;
    },
    uploadimage(a, b) {
      b.preventDefault();
      // this.fileshow = true;
      this.localImageUrl1 = this.imageUrl;
      // document.getElementById("hidden").style.display = "block";
      this.showthis = true;
      this.uploaddialog2 = false;
      localStorage.setItem("localotherinfoUrl", this.localImageUrl1);
      localStorage.setItem("othername", this.imageName);
    },
    editimage(a, b) {
      b.preventDefault();
      // this.fileshow = true;
      this.localImageUrl1 = this.imageUrl;
      //document.getElementById("hidden").style.display = "block";
      this.showthis = true;
      this.uploaddialog2 = false;
      localStorage.setItem("localeditotherinfoUrl", this.localImageUrl1);
      localStorage.setItem("editothername", this.imageName);
    },

    closeall() {
      // let taskwork = JSON.parse(localStorage.getItem("edit"));
      if (this.$route.params.otherid != null) {
        if (localStorage.localeditotherinfoUrl == null) {
          this.ss = true;
        } else {
          let ff = localStorage.localeditotherinfoUrl;
          this.imageUrl = ff;
        }
      } else {
        let gg = localStorage.localotherinfoUrl;

        this.imageUrl = gg;
      }
      this.showthis = true;
      this.uploaddialog2 = false;
      this.uploaddialog = false;
      this.resetForm();
      this.showfile = false;
    },
    closeimage() {
      this.showfile = false;
    },
  },
};
</script>
<style scoped>
>>>.mdi-paperclip::before {
  content: none;
}

::v-deep .v-dialog {
  border-radius: 15px;
}

.plusbtn {
  max-width: 50px !important;
  min-width: 50px !important;
}

::v-deep .tbheader {
  font-weight: normal;
  background-color: #EEEEEE;
}

::v-deep .tbheader th {
  color: #717171;
  font-size: 18px !important;
  font-family: 'Kanit' !important;
  font-weight: normal !important;
}

::v-deep .v-text-field__details {
  display: none;
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 7px;
  padding: 15px 20px;
  cursor: pointer;
}

.cvicon {
  max-width: 30px;
  height: 30px;
  margin: auto;
}

.editicon {
  max-width: 20px;
  height: 20px;
  cursor: pointer;
}

.filetype {
  max-width: 30px !important;
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.first {
  border-radius: 10px 0px 0px 0px !important;
}

.last {
  border-radius: 0px 10px 0px 0px !important;
}

@media (min-width: 280px) and (max-width: 1025px) {
  .btn {
    margin-left: 2.5rem !important;
  }
}
</style>