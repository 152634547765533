<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("commonmaster") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("benefits") }}
        </p>
    </div>
 
    <v-card class="pt-5" style="margin-bottom: 2.5rem; margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-text-field
            :placeholder="$t('Search')"
            v-model="forsearch"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.1rem; margin-left:1rem;"
            @keyup.enter="Search()">
          </v-text-field>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="12" md="2" lg="4">
          <v-text-field
            autocomplete="off"
            v-model="forsearch"
            :placeholder="$t('SearchWithBenefitType')"
            color="#F99D20"
            dense
            style="margin-left: 1rem">
          </v-text-field>
        </v-col>
        <v-btn
          color="#F99D20"
          @click="Search"
          class="white--text text-capitalize ml-5 mt-2"
          width="120"
          max-height="35">
          <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn
          color="#F99D20"
          class="orange--text text-capitalize mr-5 mt-2"
          width="150"
          max-height="35"
          outlined
          :disabled="permission.upload"
          @click="uploaddialog = true"
          >{{ $t("Upload") }}</v-btn
        >
        <v-btn
          color="#F99D20"
          class="white--text text-capitalize mr-5 add_btn mt-2"
          width="150"
          max-height="35"
          @click="adddialog = true"
          :disabled="permission.add"
        >
          {{ $t("AddNewItem") }}</v-btn
        >
      </v-row>
      <v-data-table
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :headers="headers"
        :items="Benefitlist"
        hide-default-footer
        hide-default-header
        class="ml-8 mr-8 mt-4"
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-left"
                @click="sortFun('benefit_Type')"
              >
                {{ $t("benefittype") }}
                <v-icon small v-if="!benefit_Type">mdi-arrow-down</v-icon>
                <v-icon small v-if="benefit_Type">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-left"
                @click="sortFun('employment_length')"
              >
                {{ $t("LengthOfEmployment") }}
                <v-icon small v-if="!employment_length">mdi-arrow-down</v-icon>
                <v-icon small v-if="employment_length">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-left"
                @click="sortFun('position_Name')"
              >
                {{ $t("position") }}
                <v-icon small v-if="!position_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="position_Name">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-left"
                @click="sortFun('amount')"
              >
                {{ $t("amount") }}
                <v-icon small v-if="!amount">mdi-arrow-down</v-icon>
                <v-icon small v-if="amount">mdi-arrow-up</v-icon>
              </th>
              <th>{{ $t("Annual") }}</th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-left"
                @click="sortFun('effective_Start_Date')"
              >
                {{ $t("EffectiveStartDate") }}
                <v-icon small v-if="!effective_Start_Date"
                  >mdi-arrow-down</v-icon
                >
                <v-icon small v-if="effective_Start_Date">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text text-left"
                @click="sortFun('effective_End_Date')"
              >
                {{ $t("EffectiveEndDate") }}
                <v-icon small v-if="!effective_End_Date">mdi-arrow-down</v-icon>
                <v-icon small v-if="effective_End_Date">mdi-arrow-up</v-icon>
              </th>

              <th style="width:80px;">{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td style="text-align: left">{{ item.benefit_Type }}</td>
            <td style="text-align: left">
              {{
                item.employment_length == 1
                  ? "Less than"
                  : "null" && item.employment_length == 2
                  ? "Greater than"
                  : "null" && item.employment_length == 3
                  ? "Between"
                  : "null" && item.employment_length == 4
                  ? "Equal To"
                  : "null" && item.employment_length == 3
                  ? item.endYear
                  : "null"
              }}&nbsp;{{ item.start_Year }}&nbsp;{{
                item.employment_length == 3 ? "-" : ""
              }}&nbsp;{{
                item.employment_length == 3 ? item.end_Year : ""
              }}&nbsp;Years
            </td>
            <td style="text-align: left">{{ item.position_Name }}</td>
            <td style="text-align: left">{{ item.amount }}</td>
            <td style="text-align: left;pointer-events: none;">
              <v-checkbox class="custom-checkbox" color="#F99D20" v-model="item.annual"></v-checkbox>
            </td>
            <td style="text-align: left">
              {{ formatDate(item.effective_Start_Date) }}
            </td>
            <td style="text-align: left">
              {{ formatDate(item.effective_End_Date) }}
            </td>
            <td style="width:80px;">
              <div class="button-container">
                <v-btn @click="EditBenefit(item)" icon :disabled="permission.edit">
                  <img class="editIcon" :src="images.edit" />
                </v-btn>
                <v-btn @click="DeleteBenefit(item.benefit_ID)" icon :disabled="permission.delete">
                  <img class="deleteIcon" :src="images.delete" />
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right" v-if="Benefitlist.length">
        <PaginationComponent
              :page.sync="page"
              :pageCount="pageCount"
              :itemsPerPage.sync="itemsPerPage"
            />
      </div>
    </v-card>
    
    <!-- adddialog -->
    <v-dialog v-model="adddialog" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-8 mr-2" style="font-size: 23px">
          {{ $t("AddBenefits") }}
        </p>
        <v-card-text>
          <v-container>
            <v-form ref="formAdd" lazy-validation>
              <p>
                {{ $t("LengthOfEmployment") }} <span class="red--text"> *</span>
              </p>
              <v-row>
                <v-col>
                  <v-tabs
                    show-arrows
                    v-model="addbenefit.employmentlength"
                    fixed-tabs
                    light
                  >
                    <v-tab
                      v-for="item in items"
                      @click="showtab(item.val)"
                      :key="item.val"
                      style="margin-left: 2px; width: 60px"
                      class="text-capitalize"
                      >{{ item.text }}</v-tab
                    >
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-show="!show">
                  {{ $t("Year") }} <span class="red--text"> *</span>
                  <v-text-field
                    @keypress="onlyNumber"
                    autocomplete="off"
                    v-model="addbenefit.startyear"
                    color="#F99D20"
                    :rules="startyear"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show">
                  {{ $t("start_year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="addbenefit.startyear"
                    color="#F99D20"
                    :rules="startyear"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show">
                  {{ $t("end_year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="addbenefit.endyear"
                    color="#F99D20"
                    :rules="endyear"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("benefittype") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="addbenefit.benefittype"
                    color="#F99D20"
                    :rules="benefittype"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("position") }} <span class="red--text"> *</span>
                  <v-select
                    v-model="addbenefit.position"
                    :items="PositionList"
                    item-value="position_ID"
                    :item-text="(item) => ` ${item.position_Name}`"
                    dense
                    :rules="position"
                    color="#F99D20"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("amount") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="addbenefit.ammount"
                    color="#F99D20"
                    :rules="ammount"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("EffectiveStartDate") }}
                  <span class="red--text"> *</span>
                  <!-- <v-menu
                    v-model="effstartdate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        v-model="computedDateFormatted6"
                        :rules="effectstartdate"
                        class="kanit-medium"
                        color="#F99D20"
                        persistent-hint
                        append-icon="mdi-calendar-month-outline"
                        v-on="on"
                      >
                        <template v-slot:append>
                          <img
                            class="mr-3"
                            style="margin-top: 3px"
                            width="18px"
                            height="18px"
                            src="@/assets/images/Mask Group 160@2x.png"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addbenefit.effectstartdate"
                      no-title
                      @input="effstartdate = false"
                    ></v-date-picker>
                  </v-menu> -->
                  <date-picker :class="effectstartdate ? 'custom-vuedatepicker-er-mg' : 'custom-vuedatepicker'" value-type="YYYY-MM-DD" v-bind:clearable="false"
                  v-model="addbenefit.effectstartdate" :editable="false" format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  <span v-if="effectstartdate" class="error-message">
                    {{ effectstartdate }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("EffectiveEndDate") }} <span class="red--text"> *</span>
                  <!-- <v-menu
                    v-model="effendate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        v-model="computedDateFormatted7"
                        class="kanit-medium"
                        :rules="effectenddate"
                        color="#F99D20"
                        persistent-hint
                        append-icon="mdi-calendar-month-outline"
                        v-on="on"
                      >
                        <template v-slot:append>
                          <img
                            class="mr-3"
                            style="margin-top: 3px"
                            width="18px"
                            height="18px"
                            src="@/assets/images/Mask Group 160@2x.png"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="addbenefit.effectenddate"
                      no-title
                      @input="effendate = false"
                    ></v-date-picker>
                  </v-menu> -->
                  <date-picker :class="effectenddate ? 'custom-vuedatepicker-er-mg' : 'custom-vuedatepicker'" value-type="YYYY-MM-DD" v-bind:clearable="false"
                  v-model="addbenefit.effectenddate" :editable="false" format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  <span v-if="effectenddate" class="error-message">
                    {{ effectenddate }}</span>
                </v-col>
                <v-checkbox class="custom-checkbox ml-2 mt-3" color="#F99D20" v-model="addbenefit.annual" style="margin: 0"></v-checkbox>
                <p class="mt-3">{{ $t("Annual(benefit/year)") }}</p>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CloseDialog()"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize commonsavebtn"
            @click="AddBenefit()"
          >
            {{ $t("Add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- edit dialog -->
    <v-dialog v-model="editdialog" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-8 mr-2" style="font-size: 23px">
          {{ $t("EditBenefits") }}
        </p>
        <v-card-text>
          <v-container>
            <v-form ref="formEdit" lazy-validation>
              <p>
                {{ $t("LengthOfEmployment") }} <span class="red--text"> *</span>
              </p>
              <v-row>
                <v-col>
                  <v-tabs
                    show-arrows
                    v-model="editbenefit.employmentlength"
                    fixed-tabs
                    light
                  >
                    <v-tab
                      v-for="item in items"
                      @click="edshowtab(item.val)"
                      :key="item.val"
                      class="text-capitalize"
                      style="margin-left: 2px"
                      :class="{
                        active: item.val === editbenefit.employmentlength,
                      }"
                      >{{ item.text }}</v-tab
                    >
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-show="!show1">
                  {{ $t("Year") }} <span class="red--text"> *</span>
                  <v-text-field
                    @keypress="onlyNumber"
                    autocomplete="off"
                    v-model="editbenefit.startyear"
                    color="#F99D20"
                    :rules="startyearEdit"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show1">
                  {{ $t("start_year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="editbenefit.startyear"
                    color="#F99D20"
                    :rules="startyearEdit"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show1">
                  {{ $t("end_year") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="editbenefit.endyear"
                    color="#F99D20"
                    :rules="endyearEdit"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("BenefitType") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="editbenefit.benefittype"
                    color="#F99D20"
                    :rules="benefittypeEdit"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("position") }} <span class="red--text"> *</span>
                  <v-select
                    v-model="editbenefit.position"
                    :items="PositionList"
                    item-value="position_ID"
                    :item-text="(item) => ` ${item.position_Name}`"
                    color="#F99D20"
                    :rules="positionEdit"
                    dense>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("amount") }} <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    v-model="editbenefit.ammount"
                    color="#F99D20"
                    :rules="ammountEdit"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("EffectiveStartDate") }}
                  <span class="red--text"> *</span>
                  <!-- <v-menu
                    v-model="edeffstartdate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        v-model="computedDateFormatted8"
                        class="kanit-medium"
                        :rules="effectstartdateEdit"
                        color="#F99D20"
                        persistent-hint
                        append-icon="mdi-calendar-month-outline"
                        v-on="on"
                      >
                        <template v-slot:append>
                          <img
                            class="mr-3"
                            style="margin-top: 3px"
                            width="18px"
                            height="18px"
                            src="@/assets/images/Mask Group 160@2x.png"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editbenefit.effectstartdate"
                      no-title
                      @input="edeffstartdate = false"
                    ></v-date-picker>
                  </v-menu> -->
                  <date-picker :class="effectstartdateEdit ? 'custom-vuedatepicker-er-mg' : 'custom-vuedatepicker'" value-type="format" v-bind:clearable="false"
                   v-model="editbenefit.effectstartdate" :editable="false" format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                   <span v-if="effectstartdateEdit" class="error-message">
                    {{ effectstartdateEdit }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("EffectiveEndDate") }} <span class="red--text"> *</span>
                  <!-- <v-menu
                    v-model="edeffendate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        v-model="computedDateFormatted9"
                        class="kanit-medium"
                        :rules="effectenddateEdit"
                        color="#F99D20"
                        persistent-hint
                        append-icon="mdi-calendar-month-outline"
                        v-on="on"
                      >
                        <template v-slot:append>
                          <img
                            class="mr-3"
                            style="margin-top: 3px"
                            width="18px"
                            height="18px"
                            src="@/assets/images/Mask Group 160@2x.png"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editbenefit.effectenddate"
                      no-title
                      @input="edeffendate = false"
                    ></v-date-picker>
                  </v-menu> -->
                  <date-picker :class="effectenddateEdit ? 'custom-vuedatepicker-er-mg' : 'custom-vuedatepicker'" value-type="format" v-bind:clearable="false"
                   v-model="editbenefit.effectenddate" :editable="false" format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                   <span v-if="effectenddateEdit" class="error-message">
                    {{ effectenddateEdit }}</span>
                </v-col>
                <v-checkbox class="custom-checkbox ml-2 mt-3" color="#F99D20" v-model="editbenefit.annual" style="margin: 0"></v-checkbox>
                <p class="mt-3">{{ $t("Annual(benefit/year)") }}</p>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn
            class="mr-5 text-capitalize commoncancelbtn"
            outlined
            @click="editdialog = false"
            text
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            @click="UpdateBenefit()"
            class="text-capitalize mr-2 commonsavebtn"
            >{{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- uploaddialog -->
    <v-dialog v-model="uploaddialog" persistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInputButton"
                  class="inputbot"
                  v-model="chosenfile"
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                >
                  {{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Benefits Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <!-- <a
                  style="color: #f99d20"
                  href="https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Benefits Master Data.xlsx"
                >
                  {{ $t("download") }}</a
                > -->
                <a style="color: #f99d20" :href="CheckLang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn
            outlined 
            class="mr-2 text-capitalize commoncancelbtn"
            @click="CloseUploaddialog()"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 commonsavebtn text-capitalize"
            @click="PreviewFiles"
          >
            {{ $t("Upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("DeleteBenefit") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize commoncancelbtn"
            outlined
            @click="Deletedialog = false"
            text
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="commondeletebtn text-capitalize mr-2"
            @click="Deletebenefitrow()"
            >{{ $t("Delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  watch: {
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
    forsearch: function () {
      this.disableloading = true;
      if (this.runningvalue == 1) {
        this.GetBenefit();
      }
    },
    "addbenefit.startyear"() {
      this.startyear = [];
    },
    "addbenefit.endyear"() {
      this.endyear = [];
    },
    "addbenefit.benefittype"() {
      this.benefittype = [];
    },
    "addbenefit.position"() {
      this.position = [];
    },
    "addbenefit.ammount"() {
      this.ammount = [];
    },
    "addbenefit.effectstartdate"() {
      this.effectstartdate = null;
    },
    "addbenefit.effectenddate"() {
      this.effectenddate = null;
    },
    "editbenefit.startyear"() {
      this.startyearEdit = [];
    },
    "editbenefit.endyear"() {
      this.endyearEdit = [];
    },
    "editbenefit.benefittype"() {
      this.benefittypeEdit = [];
    },
    "editbenefit.position"() {
      this.positionEdit = [];
    },
    "editbenefit.ammount"() {
      this.ammountEdit = [];
    },
    "editbenefit.effectstartdate"() {
      this.effectstartdateEdit = null;
    },
    "editbenefit.effectenddate"() {
      this.effectenddateEdit = null;
    },
  },
  data() {
    return {
      startyear: [],
      endyear: [],
      errormsg: [],
      benefittype: [],
      position: [],
      ammount: [],
      effectstartdate: null,
      effectenddate: null,
      startyearEdit: [],
      endyearEdit: [],
      benefittypeEdit: [],
      positionEdit: [],
      ammountEdit: [],
      effectstartdateEdit: null,
      effectenddateEdit: null,
      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      page: 1,
      pageCount: 10,
      itemsPerPage: 10,
      // item: [10, 20, 30],
      url: enurl.apiUrl,
      disableloading: false,
      show1: false,
      show: false,
      adddialog: false,
      uploaddialog: false,
      editdialog: false,
      Deletedialog: false,
      chosenfile: null,
      checkbox1: "",
      effstartdate: false,
      effendate: false,
      edeffstartdate: false,
      edeffendate: false,
      LoadingDialog: false,
      editdate: "",
      editdate1: "",
      fordate: "",
      fordate1: "",
      benefitID: 0,
      Benefitlist: [],
      PositionList: [],
      benefitsearch: [],
      lengthof: 1,
      forsearch: "",
      benefit_Type: null,
      employment_length: null,
      position_Name: null,
      amount: null,
      effective_Start_Date: null,
      effective_End_Date: null,
      mytemp: {
        company_ID: store.state.companyID,
      },
      addbenefit: {
        employmentlength: null,
        startyear: null,
        endyear: null,
        benefittype: null,
        position: null,
        ammount: null,
        effectstartdate: "",
        effectenddate: "",
        annual: false,
      },
      clearbenefit: {
        employmentlength: null,
        startyear: null,
        endyear: null,
        benefittype: null,
        position: null,
        ammount: null,
        effectstartdate: "",
        effectenddate: "",
        annual: false,
      },
      duplicatelist: [],
      runningvalue: 0,
      editbenefit: {
        benefitID: null,
        employmentlength: null,
        startyear: null,
        endyear: null,
        benefittype: null,
        position: null,
        ammount: null,
        effectstartdate: "",
        effectenddate: "",
        annual: false,
      },
      headers: [
        {
          text: "Benefit Type",
          value: "benefit_Type",
          sortable: false,
          align: "center",
        },
        {
          text: "Length of Employment",
          //value: "lengthemp",
          sortable: false,
          align: "center",
        },
        {
          text: "Position",
          //value: "pos",
          sortable: false,
          align: "center",
        },
        {
          text: "Amount",
          //value: "amount",
          sortable: false,
          align: "center",
        },
        {
          text: "Annual",
          //value: "annual",
          sortable: false,
        },
        {
          text: "Effective Start Date",
          //value: "effectstart",
          sortable: false,
          align: "center",
        },
        {
          text: "Effective End Date",
          //value: "effectend",
          sortable: false,
          align: "center",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      desserts: [
        {
          benefit: "Reimbursement of Child tuition fees",
          lengthemp: "Greater than 3 years",
          pos: "Project Manager",
          ammount: 5000,
          effectstart: "4/5/2018",
          effectend: "4/5/2018",
        },
      ],
      positionName: [],
      tempimport: {},
      showup: null,
    };
  },
  
  mounted() {
    let self = this;
    self.GetBenefit();
    //self.GetBenefit();
    self.GetPosition();
    self.permission = self.CheckViewAddEditDeleteUpload(84, 85, 86, 87, 88);
  },

  computed: {
    items() {
      return [
        { text: this.$t("LessThan"), val: 1 },
        { text: this.$t("GreaterThan"), val: 2 },
        { text: this.$t("Between"), val: 3 },
        { text: this.$t("EqualTo"), val: 4 },
      ];
    },
    computedDateFormatted6() {
      return this.formatDate(this.addbenefit.effectstartdate);
    },
    computedDateFormatted7() {
      return this.formatDate(this.addbenefit.effectenddate);
    },
    computedDateFormatted8() {
      return this.formatDate(this.editbenefit.effectstartdate);
    },
    computedDateFormatted9() {
      return this.formatDate(this.editbenefit.effectenddate);
    },
    images() {
      return this.$store.state.images;
    }
  },

  methods: {
    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย")
      {
        url = "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Benefits Master Data.xlsx";
      }
      else
      {
        url = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Benefits Master Data.xlsx";
      }
      return url;
    },

    formatD(d) {
      let finaldate = this.$moment(d, "DD/MM/YYYY").add(1, "days");
      return finaldate.toISOString();
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    sortFun(rowName) {
      let self = this;
      let keyName = [
        "benefit_Type",
        "employment_length",
        "position_Name",
        "amount",
        "effective_Start_Date",
        "effective_End_Date",
      ];
      if (
        rowName == "benefit_Type" ||
        rowName == "position_Name" ||
        rowName == "effective_Start_Date" ||
        rowName == "effective_End_Date"
      )
      {
        self.Benefitlist = self.Benefitlist.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName]));
      }
      else
      {
        self.Benefitlist = self.Benefitlist.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }
      for (let x of keyName)
      {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },

    defaultSort() {
      this.benefit_Type = null;
      this.employment_length = null;
      this.position_Name = null;
      this.amount = null;
      this.effective_Start_Date = null;
      this.effective_End_Date = null;
    },

    CloseUploaddialog() {
      this.chosenfile = null;
      this.showup = false;
      this.uploaddialog = false;
    },
    CloseDialog() {
      this.adddialog = false;
      this.addbenefit.employmentlength = 0;
      this.addbenefit.startyear = null;
      this.addbenefit.endyear = null;
      this.addbenefit.benefittype = null;
      this.addbenefit.position = null;
      this.addbenefit.ammount = null;
      this.addbenefit.effectstartdate = "";
      this.addbenefit.effectenddate = "";
      this.addbenefit.annual = false;
      this.show = false;
      this.$refs.formAdd.reset();
    },

    showtab(mytab) {
      this.addbenefit = Object.assign({}, this.clearbenefit);
      this.startyear = [];
      this.endyear = [];
      this.benefittype = [];
      this.position = [];
      this.ammount = [];

      this.effectstartdate = null;

      this.effectenddate = null;

      if (mytab == 3) {
        this.show = true;
      }
      else
      {
        this.show = false;
      }
      this.lengthof = mytab;
    },

    edshowtab(edittab) {
      this.startyearEdit = [];
      this.endyearEdit = [];
      this.benefittypeEdit = [];
      this.positionEdit = [];
      this.ammountEdit = [];

      this.effectstartdateEdit = null;

      this.effectenddateEdit = null;
      if (edittab == 3) {
        this.show1 = true;
      }
      else
      {
        this.show1 = false;
      }
      // this.edlengthof = edittab;
    },
    // keymonitor: function (event) {
    //   if (event.key == "Backspace") {
    //     this.GetBenefit();
    //   }
    // },
    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => {});
    },

    getupload() {
      document.getElementById("fileInputButton").click();
      // this.showup = true;
    },

    changeeeee(d) {
      var date = new Date((d - (25567 + 2)) * 86400 * 1000);
      if (date != "Invalid Date")
      {
        return date.toISOString();
      }
      else
      {
        return false;
      }
    },

    GetPosition() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Position/GetPosition`, tempp)
      .then(function (response) {
        self.PositionList = response.data.data;
        self.positionName = response.data.data;
      });
    },

    checkexcel(data) {
      for (let i = 0; i < data.length; i++)
      {
        if (data[i].start_Year == undefined)
        {
          this.errormsg.push("start_Year");
        }
        if (data[i].end_Year == undefined)
        {
          this.errormsg.push("end_Year");
        }
        if (data[i].position_ID == undefined)
        {
          this.errormsg.push("position_ID");
        }
        if (data[i].amount == undefined)
        {
          this.errormsg.push("amount");
        }
        if (data[i].annual == undefined)
        {
          this.errormsg.push("annual");
        }
        if (data[i].effective_Start_Date == undefined)
        {
          this.errormsg.push("effective_Start_Date");
        }
        if (data[i].effective_End_Date == undefined)
        {
          this.errormsg.push("effective_End_Date");
        }
      }
    },

    PreviewFiles() {
      let self = this;
      var files = self.chosenfile;
      let combileArray = [];
      if (files)
      {
        var reader = new FileReader();
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          self.importData = XLSX.utils.sheet_to_json(worksheet);
          const file_data = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });
          // let importData = XLSX.utils.sheet_to_json(worksheet);
          let checkpos = true;
          // let checkDate = self.importData.every(
          //   (el) => el.effective_Start_Date && el.effective_End_Date
          // );
          let checkdatevali = true;

          if (filename == "Benefits Master Data.xlsx")
          {
            self.checkexcel(self.importData);
            // if (checkDate) {
            if (self.errormsg.length == 0) {
              for (let i = 0; i < self.importData.length; i++)
              {
                if (checkdatevali)
                {
                  var tempimport = {
                    benefit_Type: file_data[i + 1][0],
                    employment_length: file_data[i + 1][1],
                    start_Year: file_data[i + 1][2],
                    end_Year: file_data[i + 1][3],
                    position_ID: file_data[i + 1][4],
                    amount: file_data[i + 1][5],
                    annual: file_data[i + 1][6],
                    effective_Start_Date: file_data[i + 1][7],
                    effective_End_Date: file_data[i + 1][8],
                    company_ID: store.state.companyID,
                  };
                  if (tempimport.annual == "Yes")
                  {
                    tempimport.annual = true;
                  }
                  if (tempimport.annual == "-" || tempimport.annual == "No")
                  {
                    tempimport.annual = false;
                  }
                  if (tempimport.start_Year == "-")
                  {
                    tempimport.start_Year = 0;
                  }
                  if (tempimport.end_Year == "-")
                  {
                    tempimport.end_Year = 0;
                  }
                  if (tempimport.employment_length == "Less than")
                  {
                    tempimport.employment_length = 1;
                  }
                  else if (tempimport.employment_length == "Greater than")
                  {
                    tempimport.employment_length = 2;
                  }
                  else if (tempimport.employment_length == "Between")
                  {
                    tempimport.employment_length = 3;
                  }
                  else if (tempimport.employment_length == "Equal to")
                  {
                    tempimport.employment_length = 4;
                  }
                  let checkPosition = self.positionName.filter(
                    (element) =>
                      element.position_Name == self.importData[i].position_ID
                  );
                  if (checkPosition.length)
                  {
                    checkpos = true;
                    tempimport.position_ID = checkPosition[0].position_ID;
                    let newdate = self.changeeeee(self.importData[i].effective_Start_Date);
                    tempimport.effective_Start_Date = newdate;
                    let newdate1 = self.changeeeee(self.importData[i].effective_End_Date);
                    if (!newdate || !newdate1)
                    {
                      checkdatevali = false;
                    }
                    tempimport.effective_End_Date = newdate1;
                    tempimport.amount = tempimport.amount.toString();
                    combileArray.push(tempimport);
                  }
                  else
                  {
                    checkpos = false;
                    alert("The position added doesn't match Position Master Data. Please check");
                  }
                }
              }
            }
            // } else {
            //   alert(
            //     "Please fill in the Effective start date/Effective End date"
            //   );
            // }
            if (self.errormsg.length == 0)
            {
              if (checkpos)
              {
                if (checkdatevali) 
                {
                  self.LoadingDialog = true;
                  axios.post(`${self.url}Benefit/ImportBenefit`, combileArray)
                  .then(function (response) {
                    self.LoadingDialog = false;
                    if (response.data.status == 0) 
                    {
                      alert(response.data.message);
                      self.chosenfile = null;
                      self.showup = false;
                      self.uploaddialog = false;
                      self.GetBenefit();
                      //self.LogTrace(null, "Upload Benefit ", 9, "Verbose");
                    }
                    else
                    {
                      alert(response.data.message);
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(error,"Upload  Benefit Fail",9,"Verbose");
                    //alert(error);
                    self.LoadingDialog = false;
                  });
                }
                else
                {
                  alert("Please check date format");
                }
              }
            }
            else
            {
              let Message = [...new Set(self.errormsg)];
              alert(` Please fill ${Message.join(",")}`);
              self.errormsg = [];
            }
          }
          else
          {
            alert("File name must be Benefits Master Data.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      }
      else
      {
        alert("Please choose File");
      }
    },
    // PreviewFilesO() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   let files = self.chosenfile;
    //   if (files != null) {
    //     let reader = new FileReader();
    //     reader.onload = function(e) {
    //       let filename = files.name;
    //       let data = new Uint8Array(e.target.result);
    //       let workbook = XLSX.read(data, { type: "array" });
    //       let sheetName = workbook.SheetNames[0];
    //       let worksheet = workbook.Sheets[sheetName];
    //       self.importData = XLSX.utils.sheet_to_json(worksheet);
    //       let combileArray = [];
    //       for (let i = 0; i < self.importData.length; i++) {
    //         self.tempimport = {
    //           ...self.importData[i],
    //           company_ID: store.state.companyID,
    //         };
    //         if (self.tempimport.annual == "Yes") {
    //           self.tempimport.annual = true;
    //         } else if (
    //           self.tempimport.annual == "-" ||
    //           self.tempimport.annual == "No"
    //         ) {
    //           self.tempimport.annual = false;
    //         }
    //         if (self.tempimport.effective_Start_Date == "-") {
    //           self.tempimport.effective_Start_Date = "";
    //         }
    //         if (self.tempimport.effective_End_Date == "-") {
    //           self.tempimport.effective_End_Date = "";
    //         }
    //         if (self.tempimport.start_Year == "-") {
    //           self.tempimport.start_Year = 0;
    //         }
    //         if (self.tempimport.end_Year == "-") {
    //           self.tempimport.end_Year = 0;
    //         }
    //         if (self.tempimport.employment_length == "Less than") {
    //           self.tempimport.employment_length = 1;
    //         } else if (self.tempimport.employment_length == "Greater than") {
    //           self.tempimport.employment_length = 2;
    //         } else if (self.tempimport.employment_length == "Between") {
    //           self.tempimport.employment_length = 3;
    //         } else if (self.tempimport.employment_length == "Equal to") {
    //           self.tempimport.employment_length = 4;
    //         }
    //         self.GetPosition();
    //         for (let j = 0; j < self.PositionList.length; j++) {
    //           if (
    //             self.tempimport.position_ID ==
    //             self.PositionList[j].position_Name
    //           ) {
    //             self.tempimport.position_ID = self.PositionList[j].position_ID;
    //           }
    //         }
    //         if (self.tempimport.effective_Start_Date) {
    //           let newdate = self.changeeeee(
    //             self.tempimport.effective_Start_Date
    //           );

    //           self.tempimport.effective_Start_Date = newdate;
    //         }
    //         if (self.tempimport.effective_End_Date) {
    //           let newdate1 = self.changeeeee(
    //             self.tempimport.effective_End_Date
    //           );

    //           self.tempimport.effective_End_Date = newdate1;
    //         }
    //         combileArray.push(self.tempimport);
    //       }
    //       if (filename == "Benefits Master Data.xlsx") {
    //         axios
    //           .post(`${self.url}Benefit/ImportBenefit`, combileArray)
    //           .then(function(response) {
    //             if (response.data.status == 0) {
    //               alert(response.data.message);
    //               self.chosenfile = null;
    //               self.showup = false;
    //               self.uploaddialog = false;
    //               self.LoadingDialog = false;
    //               self.GetBenefit();
    //               self.LogTrace(null, "Upload Benefit ", 9, "Verbose");
    //             }
    //           })
    //           .catch(function(error) {
    //             self.LogTrace(error, "Upload  Benefit Fail", 9, "Verbose");
    //             alert(error);
    //             self.LoadingDialog = false;
    //           });
    //       } else {
    //         alert("File name must be Benefits Master Data.xlsx");
    //         self.LoadingDialog = false;
    //       }
    //     };
    //     reader.readAsArrayBuffer(files);
    //     self.LoadingDialog = false;
    //   } else {
    //     alert("Please choose File");
    //     self.LoadingDialog = false;
    //   }
    // },
    closeUpload() {
      this.HospitalUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },

    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    GetBenefit() {
      let self = this;
      if (!self.disableloading)
      {
        self.LoadingDialog = true;
      }
      let tempp = {
        company_ID: store.state.companyID,
      };

      axios.post(`${self.url}Benefit/GetBenefit`, tempp)
      .then(function (response) {
        self.LoadingDialog = false;
        if(response.data.status === 0)
        {
          self.Benefitlist = response.data.data;
          self.disableloading = false;
          self.runningvalue = 0;
          self.defaultSort();
        }
        else
        {
          alert(response.data.message);
        }
        //self.LogTrace(null, "Get Benefit", 9, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Benefit Fail", 9, "Critical");
        //alert(error);
        //  self.LoadingDialog = false;
      });
    },
    
    validatebenefitexist() {
      let self = this;
      let validate = true;
      if (self.Benefitlist.length != 0)
      {
        for (let i = 0; i < self.Benefitlist.length; i++)
        {
          if (validate)
          {
            if (self.lengthof == self.Benefitlist[i].employment_length)
            {
              if (self.lengthof != 3)
              {
                if (self.addbenefit.startyear == self.Benefitlist[i].start_Year &&
                  self.addbenefit.benefittype.toLowerCase() == self.Benefitlist[i].benefit_Type.toLowerCase() &&
                  self.addbenefit.position == self.Benefitlist[i].position_ID)
                {
                  validate = false;
                }
                else
                {
                  validate = true;
                }
              }
              else
              {
                if (self.addbenefit.startyear == self.Benefitlist[i].start_Year &&
                  self.addbenefit.endyear == self.Benefitlist[i].end_Year &&
                  self.addbenefit.benefittype.toLowerCase() == self.Benefitlist[i].benefit_Type.toLowerCase() &&
                  self.addbenefit.position == self.Benefitlist[i].position_ID)
                {
                  validate = false;
                }
                else
                {
                  validate = true;
                }
              }
            }
          }
        }
      }
      else
      {
        validate = true;
      }
      return validate;
      // if (validate == true) {
      //   return true;
      // } else {
      //   return false;
      // }
    },

    validateeditbenefitexist() {
      let self = this;
      let validate = true;
      if (self.Benefitlist.length != 0)
      {
        for (let i = 0; i < self.Benefitlist.length; i++)
        {
          if (validate)
          {
            let mylength = self.editbenefit.employmentlength + 1;
            if (mylength == self.Benefitlist[i].employment_length && self.editbenefit.benefitID != self.Benefitlist[i].benefit_ID)
            {
              if (mylength != 3)
              {
                if (self.editbenefit.startyear == self.Benefitlist[i].start_Year &&
                  self.editbenefit.benefittype.toLowerCase() == self.Benefitlist[i].benefit_Type.toLowerCase() &&
                  self.editbenefit.position == self.Benefitlist[i].position_ID)
                {
                  validate = false;
                }
                else
                {
                  validate = true;
                }
              }
              else
              {
                if (self.editbenefit.startyear == self.Benefitlist[i].start_Year &&
                  self.editbenefit.endyear == self.Benefitlist[i].end_Year &&
                  self.editbenefit.benefittype.toLowerCase() == self.Benefitlist[i].benefit_Type.toLowerCase() &&
                  self.editbenefit.position == self.Benefitlist[i].position_ID)
                {
                  validate = false;
                }
                else
                {
                  validate = true;
                }
              }
            }
          }
        }
      }
      else
      {
        validate = true;
      }
      return validate;
      // if (validate)
      // {
      //   return true;
      // }
      // else
      // {
      //   return false;
      // }
    },
    
    AddBenefit() {
      let self = this;
      if (self.lengthof == 1 || self.lengthof == 2 || self.lengthof == 4)
      {
        (self.startyear = [(v) => !!v || "Year is required"]),(self.addbenefit.endyear = 0);
      }
      else
      {
        self.startyear = [(v) => !!v || "Start Year is required"];
        self.endyear = [(v) => !!v || "End Year is required"];
      }
      self.benefittype = [(v) => !!v || "Benefit Type is required"];
      self.position = [(v) => !!v || "Position is required"];
      self.ammount = [(v) => !!v || "Amount is required"];
      self.effectstartdate = !self.addbenefit.effectstartdate ? "Effective Start Date is required" : "";
      self.effectenddate = !self.addbenefit.effectenddate ? "Effective End Date is required" : "";
      let tempvalidate = self.$refs.formAdd.validate();
      let duplicateexist = self.validatebenefitexist();
      if (duplicateexist)
      {
        if (
          tempvalidate == true &&
          self.lengthof != null &&
          self.addbenefit.startyear != null &&
          self.addbenefit.benefittype != null &&
          self.addbenefit.position != null &&
          self.addbenefit.ammount != null &&
          self.addbenefit.startyear != "" &&
          self.addbenefit.endyear != null &&
          self.addbenefit.benefittype != "" &&
          self.addbenefit.position != "" &&
          self.addbenefit.ammount != "" &&
          self.addbenefit.effectstartdate != null &&
          self.addbenefit.effectenddate != null &&
          self.addbenefit.effectstartdate != "" &&
          self.addbenefit.effectenddate != ""
        )
        {
          let tempadd = {
            benefit_Type: self.addbenefit.benefittype,
            employment_length: self.lengthof,
            amount: self.addbenefit.ammount,
            annual: self.addbenefit.annual,
            start_Year: parseInt(self.addbenefit.startyear),
            end_Year: parseInt(self.addbenefit.endyear ? self.addbenefit.endyear : 0),
            effective_Start_Date: new Date(self.addbenefit.effectstartdate),
            effective_End_Date: new Date(self.addbenefit.effectenddate),
            position_ID: self.addbenefit.position,
            company_ID: store.state.companyID,
          };
          if (self.$moment(tempadd.effective_Start_Date, "DD/MM/YYYY") <= (self.$moment(tempadd.effective_End_Date, "DD/MM/YYYY")))
          {
            self.LoadingDialog = true;
            axios.post(`${self.url}Benefit/AddBenefit`, tempadd)
            .then(function (response) {
              if (response.data.status == 0)
              {
                self.adddialog = false;
                alert(response.data.message);
                self.lengthof = 1;
                self.LoadingDialog = false;
                self.addbenefit.employmentlength = 0;
                self.addbenefit.startyear = null;
                self.addbenefit.endyear = null;
                self.addbenefit.benefittype = null;
                self.addbenefit.position = null;
                self.addbenefit.ammount = null;
                self.addbenefit.effectstartdate = "";
                self.addbenefit.effectenddate = "";
                self.addbenefit.annual = false;
                self.show = false;
                // self.lengthof = 0;
                // self.addbenefit.benefittype = null;
                // self.addbenefit.ammount = null;
                // self.addbenefit.annual = false;
                // self.addbenefit.startyear = null;
                // self.addbenefit.endyear = null;
                // self.addbenefit.effectstartdate = null;
                // self.addbenefit.effectenddate = null;
                // self.addbenefit.position = null;
                // self.LoadingDialog = false;
                self.GetBenefit();
                //self.LogTrace(null, "Add  Benefit ", 9, "Low");
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Benefit Fail", 9, "Low");
              //alert(error);
            });
          }
          else
          {
            alert("Effective start date must be before effective end date");
          }
        }
        else
        {
          alert("please filll all the fields");
        }
      }
      else
      {
        alert("Benefit already exists");
        self.LoadingDialog = false;
      }
    },

    EditBenefit(temp) {
      let self = this;
      self.editdialog = true;
      self.editbenefit.benefitID = temp.benefit_ID;
      self.editbenefit.employmentlength = temp.employment_length - 1;
      self.editbenefit.startyear = temp.start_Year;
      self.editbenefit.benefittype = temp.benefit_Type;
      self.editbenefit.position = temp.position_ID;
      self.editbenefit.ammount = temp.amount;
      self.editbenefit.effectstartdate = self.$moment(temp.effective_Start_Date).format('DD/MM/YYYY');
      self.editbenefit.effectenddate = self.$moment(temp.effective_End_Date).format('DD/MM/YYYY');
      self.editbenefit.annual = temp.annual;
      if (self.editbenefit.employmentlength == 2)
      {
        self.show1 = true;
        self.editbenefit.endyear = temp.end_Year;
      }
      else
      {
        this.show1 = false;
        self.editbenefit.endyear = null;
      }
      //console.log(this.editbenefit)
    },

    UpdateBenefit() {
      let self = this;
      if (self.editbenefit.employmentlength == 0 || self.editbenefit.employmentlength == 1 || self.editbenefit.employmentlength == 3)
      {
        (self.startyearEdit = [(v) => !!v || "Year is required"]),(self.editbenefit.endyear = 0);
      }
      else
      {
        self.startyearEdit = [(v) => !!v || "Start Year is required"];
        self.endyearEdit = [(v) => !!v || "End Year is required"];
      }
      self.benefittypeEdit = [(v) => !!v || "Benefit Type is required"];
      self.positionEdit = [(v) => !!v || "Position is required"];
      self.ammountEdit = [(v) => !!v || "Amount is required"];
      self.effectstartdateEdit = !self.editbenefit.effectstartdate ? "Effective Start Date is required" : "";
      self.effectenddateEdit = !self.editbenefit.effectenddate ? "Effective End Date is required" : "";
      let tempvalidate = self.$refs.formEdit.validate();
      let duplicatecheck = self.validateeditbenefitexist();
      if (duplicatecheck == true)
      {
        if (
          tempvalidate == true &&
          self.editbenefit.employmentlength != null &&
          self.editbenefit.position != null &&
          self.editbenefit.annual != null &&
          self.editbenefit.startyear != null &&
          self.editbenefit.startyear != "" &&
          self.editbenefit.endyear != null &&
          self.editbenefit.benefittype != "" &&
          self.editbenefit.ammount != "" &&
          self.editbenefit.annual != null
        )
        {
          let tempbenefit = {
            benefit_ID: self.editbenefit.benefitID,
            benefit_Type: self.editbenefit.benefittype,
            employment_length: self.editbenefit.employmentlength + 1,
            amount: self.editbenefit.ammount,
            annual: self.editbenefit.annual,
            start_Year: parseInt(self.editbenefit.startyear),
            end_Year: parseInt(self.editbenefit.endyear),
            effective_Start_Date: self.$moment(self.editbenefit.effectstartdate, "DD/MM/YYYY" ).format("YYYY-MM-DD"),
            effective_End_Date: self.$moment(self.editbenefit.effectenddate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            position_ID: self.editbenefit.position,
            company_ID: store.state.companyID,
          };
          if (tempbenefit.effective_Start_Date <= tempbenefit.effective_End_Date)
          {
            self.LoadingDialog = true;
            axios.post(`${self.url}Benefit/UpdateBenefit`, tempbenefit)
            .then(function (response) {
              if (response.data.status == 0)
              {
                self.editdialog = false;
                alert(response.data.message);
                self.LoadingDialog = false;
                self.GetBenefit();
                //self.LogTrace(null, "Update  Benefit ", 9, "Medium");
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update Benefit Fail", 9, "Medium");
              //alert(error);
            });
          }
          else
          {
            alert("Effective start date must be before effective end date");
          }
        }
        else
        {
          alert("Please fill all the fields");
        }
      }
      else
      {
        alert("Benefit already exists");
        self.LoadingDialog = false;
      }
    },

    ValidateAddTrue(obj) {
      return Object.values(obj).every((x) => x);
    },

    ValidateAddFalse(obj) {
      if (Object.values(obj).some((x) => !x))
      {
        alert("Please fill in the required fields!");
      }
    },

    DeleteBenefit(id) {
      let self = this;
      self.benefitID = id;
      self.Deletedialog = true;
    },

    Deletebenefitrow() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.benefitID;
      let tempdel = {
        benefit_ID: id,
      };
      axios.post(`${self.url}Benefit/DeleteBenefit`, tempdel)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.Deletedialog = false;
          self.LoadingDialog = false;
          self.GetBenefit();
          //self.LogTrace(null, "Delete  Benefit ", 9, "High");
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Delete Benefit Fail", 9, "High");
        //alert(error);
        self.LoadingDialog = false;
      });
    },

    Search() {
      let self = this;
      self.runningvalue = 1;
      self.LoadingDialog = true;
      if (self.forsearch != "" && self.forsearch != undefined)
      {
        let List = self.Benefitlist;
        self.Benefitlist = [];
        let temp = List.filter((v) =>
          v.benefit_Type.toLowerCase().includes(self.forsearch.toLowerCase())
        );

        for (let i = 0; i < temp.length; i++)
        {
          self.Benefitlist.push(temp[i]);
        }
        self.LoadingDialog = false;
      }
      else
      {
        alert("Please fill in search field");
        self.GetBenefit();
      }
    },
  },
};
</script>

<style scoped>
>>> .v-select__selections {
  padding-left: unset !important;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 375px) {
  .add_btn {
    margin-top: 15px;
  }
}
@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
  }
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
  }
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
  text-align: center;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 16px;
  font-weight: normal;
}

>>> .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(0, 0, 0, 0.54);
  background-color: #00000029;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
  border-radius: 5px 5px 5px 5px;
  width: 135px;
  height: 40px;
}

/* Desktop */
@media (max-width: 1300px) {
  .v-dialog--custom {
    width: 600px;
  }
}

>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 5px 5px 5px 5px;
  width: 135px;
  height: 40px;
}
.v-input__icon--append .v-icon {
  color: #f99d20;
}
.v-input__icon--append .v-icon {
  color: yellow;
}

/* t */
</style>
