<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div class="d-flex ml-3">
      <div style="margin-left: 0.5rem">
        <p style="margin-top: 30px" class="myfont"> {{ $t("FeedbackNo") }}</p>
      </div>
    </div>
    <v-col class="mydiv" cols="12" xs="12" sm="12" md="12" lg="12">
      <v-text-field
        readonly
        v-model="feedbackno"
        autocomplete="off"
        class="ml-1 ml-md-0"
        single-line
        solo
        style="caret-color: orange !important"
      ></v-text-field>
    </v-col>
    <div class="d-flex ml-3">
      <div style="margin-left: 0.5rem">
        <p class="myfont">{{ $t("Subject") }}</p>
      </div>
    </div>
    <v-col class="mydiv" cols="12" xs="12" sm="12" md="12" lg="12">
      <v-text-field
        v-model="subject"
        autocomplete="off"
        class="ml-1 ml-md-0"
        single-line
        solo
        color="#F99D20"
      ></v-text-field>
    </v-col>
    <div class="d-flex ml-1">
      <div style="margin-left: 0.5rem">
        <p class="myfont">{{ $t("Messageforfeedback") }}</p>
      </div>
    </div>
    <v-col class="mydiv" cols="12" xs="12" sm="12" md="12" lg="12">
      <v-textarea
        v-model="message"
        solo
        height="80"
        name="input-7-4"
        class="ml-1"
        style="caret-color: black !important"
      ></v-textarea>
    </v-col>
    <v-card-actions class="mr-5">
      
      <v-row class="mb-5">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        class="mr-5 text-capitalize cancel commoncancelbtn"
        @click="Cancel"
        text
        >{{ $t("cancel") }}</v-btn
      >
      <v-btn
        class="text-capitalize send commonsavebtn"
        :disabled="permission.add"
        @click="AddFeedback"
        >{{ $t("Send") }}</v-btn
      >
      </v-row>
    </v-card-actions>
    <!-- Loading -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddDelete from "@/function/RolePermissions.js";
import store from "@/store";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils , CheckViewAddDelete],
  data() {
    return {
      feedbackno: null,
      subject: null,
      message: null,
      url: enurl.apiUrl,
      loadingdialog: false,
      FeedbackList: [],
      EmpName: null,
      tokenList: [],
      permission:{
        add:true
      }
    };
  },

  mounted() {
    this.GetFeedbackList();
    this.GetToken();
    this.sendNotification();
    this.permission=this.CheckViewAddDelete(120,121,122);
  },
  methods: {
    GetToken() {
      let self = this;
      let temps = {
        user_ID: 0,
      };
      axios
        .post(`${self.url}Firebasetoken/GetFirebaseTokenByUserID`, temps)
        .then(function (response) {
          if (response.data.data.length != 0) {
            let temp = response.data.data.map((x) => x.fireBase_Token);
            self.tokenList = temp;
            //console.log(self.tokenList)
          }
        });
    },
    GetAutoGenerate() {
      let self = this;
      let start = "001";
      if (self.FeedbackList == null) {
        self.feedbackno = "F" + start;
      } else if (self.FeedbackList.length == 0) {
        self.feedbackno = "F" + start;
      } else {
        let lastdefaultno = self.FeedbackList[0].feedback_No.slice(1);
        let temp = ++lastdefaultno;
        let maxLength = 3;
        let res = temp.toString().padStart(maxLength, "0");
        self.feedbackno = "F" + res;
      }
    },
    GetFeedbackList() {
      try {
        let self = this;
        self.loadingdialog = true;
        axios
          .get(`${self.url}HelpFeedback/GetAllFeedbacksTrueOrFalse`)
          .then(function (response) {
            self.FeedbackList = response.data.data;
            //self.LogTrace(null, "Get Feedback",50, "Critical");
            self.GetAutoGenerate();
            self.loadingdialog = false;
          });
      } catch (error) {
        self.LogTrace(error, "Get Feedback Fail",50, "Critical");
        //alert(error);
        self.loadingdialog = false;
      }
    },
    Cancel() {
      let self = this;
      self.subject = null;
      self.message = null;
    },
    async sendNotification() {
      let self = this;
      let userInfoTemp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${this.url}PersonalInfo/GetShortInfo`, userInfoTemp)
        .then(function (response) {
          var username = `${response.data.data[0].name} ${response.data.data[0].lastName}`;
          self.EmpName = response.data.data[0].name;
          self.username = username;
          // self.reportID = reportID;
          // TempReportID = "0";
        });
    },

    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };

      await axios
        .post(`${self.url}HelpFeedback/GetWorkflowByFeedback`, temp)
        .then(function (response) {
          if (response) {
            let WF_ID = response.data.data.wF_ID;
            let senderID = response.data.data.requester_ID;
            let recipientID = response.data.data.approver_ID;
            self.newnotiList(WF_ID, senderID, recipientID);
          }
        });
    },
    async newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `has sent a feedback`,
      };

      await axios
        .post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.pushNotification(recipientID);
          }
        });
    },
    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "Feedback",
        body: self.username + "has sent a feedback",
        reportID: recipientID,
      };
     // console.log(noti_temp);

      await axios
        .post(`${this.url}Firebasetoken/sendNotification`, noti_temp)
        .then(function () {
         // console.log(response.data);
        });
    },

    AddFeedback() {
      let self = this;
      self.loadingdialog = true;
      if (self.EmpName != null && self.EmpName != "") {
        if (
          self.feedbackno != null &&
          self.subject != null &&
          self.message != null &&
          self.subject != "" &&
          self.message != "" &&
          self.feedbackno != ""
        ) {
          let temp = {
            feedback_No: self.feedbackno,
            feedback_Subject: self.subject,
            feedback_Message: self.message,
            employee_ID: store.state.employeeID,
            deletedFlag: true,
          };
          axios
            .post(`${self.url}HelpFeedback/AddFeedbackByEmp`, temp)
            .then(async function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                // //self.LogTrace(null, "Add Feedback",50, "Low");
                // if (response.data.message == "Add Successfully") {
                //   if (response.data.status === 0) {
                    
                //   }
                // }
                await self.getLatestData();
                self.feedbackno = null;
                self.subject = null;
                self.message = null;
                self.GetFeedbackList();
                self.loadingdialog = false;
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Feedback Fail",50, "Low");
              alert(error);
              self.loadingdialog = false;
            });
        } else {
          alert("Please fill in the fields");
          self.loadingdialog = false;
        }
      } else {
        alert(
          "Please set up your personal information before sending feedback"
        );
        self.loadingdialog = false;
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 280px) and (max-width: 320px) {
  .send {
    margin-left: -1rem !important;
    width: 90px !important;
  }
  .cancel {
    margin-left: -1rem !important;
  }
}
.myfont {
  color: #8a8c8e !important;
}
</style>