<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <br />
    <div style="margin-left: 0.5rem; margin-right: 1.5rem">
      <v-row>
        <div class="d-flex">
          <div>
            <img width="24px" height="24px" style="margin-top: auto; margin-bottom: auto"
              src="@/assets/images/Mask Group 43@2x.png" />
          </div>
          <div class="ml-3">
            <label style="font-size: 17px; color: #f89d1f">{{
              $t("fixedincome")
            }}</label>
          </div>
        </div>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn @click="gosalaryhistory()" color="#F99D20" class="white--text text-capitalize" style="font-weight: normal"
          width="200" max-height="40">{{ $t("salarychangehistory") }}</v-btn>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <span style="color:#8a8c8e;">{{  $t("EmploymentType") }}<span class="red--text"> *</span></span>
          <v-autocomplete 
            :disabled="disableall"
            :items="selectitems" 
            :item-text="item=> $t(`${item.EmploymentTypeName}`)"
            :item-value="item=> item.EmploymentTypeName"
            v-model="EmploymentType" 
            :error-messages="error.empType"
            style="font-size: 15px" 
            append-icon="mdi-chevron-down"
            color="#F99D20"
            :menu-props="{ bottom: true, offsetY: true }">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <label style="color: #8a8c8e">
            {{ $t("salary") }}<span class="red--text"> *</span>
          </label>
          <v-text-field ref="salary" v-on:click="handleClick(addsalary.salary)" @focus="selectText($event,addsalary.salary)" autocomplete="off" @focusout="handleFocusout(addsalary.salary)" @keypress="onlyNumber($event,addsalary.salary)" v-model="addsalary.salary" :disabled="disableall"
            :key="salarykey" v-on:keyup="addall()" color="#F99D20" class="empID" style="margin-top: 0.4rem; font-size: 19px" :error-messages="error.errorMessageIncome">
            <!-- <template v-slot:label>
              <span> {{ $t("salary") }}<span class="red--text"> *</span></span>
            </template> -->
            </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="12" sm="6" md="3">
          <p style="color: #8a8c8e; font-size: 17px">
            {{ $t("additionalincome") }}
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-btn color="#F99D20" class="white--text text-capitalize" style="font-weight: normal" width="160"
            :disabled="disableall" max-height="32" @click="addItem">{{ $t("addnewincome") }}</v-btn>
        </v-col>
      </v-row>
      <v-row v-for="item in items" :key="item.id" class="income">
        <v-col md="2" align="center">
          <label style="color: #8a8c8e; font-size: 15px">{{
            $t("taxcalculate")
          }}</label>
          <img class="mt-3 d-flex" style="height: 23px; width: 23px"
            :src="item.Tax == true ? rwimages.Right : rwimages.Wrong" />
        </v-col>
        <v-col md="3" align="center">
          <label style="color: #8a8c8e; font-size: 15px">{{
            $t("sscalculate")
          }}</label>
          <img class="mt-3 d-flex" style="height: 23px; width: 23px"
            :src="item.SSF == true ? rwimages.Right : rwimages.Wrong" />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <label style="color: #8a8c8e; font-size: 15px">{{$t("incometype")}}<span class="red--text"> *</span></label>
          <v-autocomplete :items="Incometypelist" v-model="item.income_Type" item-text="incomeTypeName" item-value="income_Type_ID" :disabled="disableall" @change="changeincometype(item.income_Type, item.id)" style="font-size: 16px; color: #f99d20" append-icon="mdi-chevron-down" color="#F99D20" :error-messages="item.errorincometype">
            <template v-slot:selection="data">
              <!-- {{ thethaihead == true ? data.item.incomeTypeName_TH : data.item.incomeTypeName_EN }} -->
              {{ data.item.incomeTypeName }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ data.item.incomeTypeName }}
                    <!-- {{ thethaihead == true ? data.item.incomeTypeName_TH : data.item.incomeTypeName_EN}} -->
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <label style="color: #8a8c8e; font-size: 15px">{{$t("incomeamount")}}<span class="red--text"> *</span></label>
          <v-text-field autocomplete="off" color="#F99D20" :disabled="disableall" @keypress="onlyNumberIncome($event,item)" @focus="selectTextIncome($event,item.id)"
            style="font-size: 16px" v-on:keyup="addall()" @input="intitudeHandler($event,item.id)" v-on:click="handleClickItem(item.id)" @focusout="handleFocusoutItem(item.id)" v-model="item.amount" class="empID" placeholder
            :error-messages="item.erroramount"></v-text-field>
          <!-- {{items}} -->
        </v-col>
        <v-col cols="12" sm="6" md="1">
          <!-- <v-btn style="width: 30px;" v-show="item.value1" :disabled="disableall" @click="removeitem(item.id)" icon
            class="btn-icon mt-9"> -->
            <div class="image-container mt-9" style="width:24px;" v-show="item.value1" :disabled="disableall" @click="removeitem(item.id)">
              <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
              <!-- <img class="original-image" src="@/assets/images/delete_trashbin.svg" style="width:24px;height:24px" alt="Icon"/>
              <img class="hover-image" src="@/assets/images/delete_trashbin_colored.svg" style="width:24px;height:24px" alt="Icon"/> -->
            </div>
            <!-- <img :src="images.trash" class="mb-1" /> -->
          <!-- </v-btn> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3">
          <p class="mt-8" style="color: #8a8c8e; font-size: 17px">
            {{ $t("totalincome") }}
          </p>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <label style="color: #8a8c8e; font-size: 15px">
            {{ $t("incomeamount") }}
          </label>
          <v-text-field autocomplete="off" color="#F99D20" style="font-size: 19px" :disabled="disableall" v-model="total"
            readonly class="empID" placeholder>&nbsp;THB</v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3">
          <p class="mt-8" style="color: #8a8c8e; font-size: 17px">
            {{ $t("calculatetaxforsalary") }}
          </p>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <label style="color: #8a8c8e; font-size: 15px">
            {{ $t("incomeamount") }}
          </label>
          <v-text-field autocomplete="off" color="#F99D20" style="font-size: 19px" :disabled="disableall"
            v-model="totalforcalculatetax" readonly class="empID" placeholder>&nbsp;THB</v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex mt-4">
        <div>
          <img width="24px" height="24px" style="margin-top:auto;margin:bottom:auto;"
            src="@/assets/images/Mask Group 44@2x.png" />
        </div>
        <div class="ml-3">
          <label style="font-size: 17px; color: #f89d1f">
            {{$t("payment") }}
          </label>
        </div>
      </div>

      <v-row cols="12">
        <v-col cols="12" sm="6" md="3">
          <label style="font-size: 15px; color: #8a8c8e">{{
            $t("paymenttype")
          }}</label>
          <span class="red--text"> *</span>
          <v-autocomplete style="font-size: 15px" v-model="addsalary.paymenttype" :disabled="disableall"
            :items="paymentitems" item-text="txt" item-value="val" append-icon="mdi-chevron-down"
            @change="changepayment()" color="#F99D20" :error-messages="error.errorMessagePayment"></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <label style="font-size: 15px; color: #8a8c8e">{{ $t("bankname") }} <span class="red--text"> *</span>
          </label>
          <v-autocomplete style="font-size: 15px" :disabled="disableall || disablebank" class="empID" :items="BankList" @change="updateBank()"
            v-model="addsalary.bankname" item-value="bank_ID" item-text="bankname" append-icon="mdi-chevron-down" color="#F99D20"
            :error-messages="error.errorMessageBank">
            <template v-slot:selection="data">
              <v-avatar left size="30" style="margin-right:10px;">
                <v-img :src="data.item.bankIcon"></v-img>
              </v-avatar>
              {{ data.item.bankname }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar size="30">
                  <img :src="data.item.bankIcon">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> {{ data.item.bankname }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <label style="font-size: 15px; color: #8a8c8e">
            {{ $t("bank_account_number") }}<span class="red--text"> *</span>
          </label>
          <v-text-field autocomplete="off" v-model="addsalary.banknum" :error-messages="error.errorMessageBanknum"
            :disabled="disableall || disablebank" @keypress="onlyNumberforpersonalandtax" color="#F99D20"
            style="font-size: 15px" font-family="Kanit">
          </v-text-field>
        </v-col>
      </v-row>
    </div>

    <div style="margin-top: 3rem;padding-bottom: 3rem;margin-left: 1.5rem;margin-right: 1.5rem;">
      <v-row v-show="showedit">
        <v-spacer></v-spacer>
        <v-col>
          <v-btn color="#F89D1F" class="pa-2 float-right text_transform_none commonsavebtn"
            :disabled="$route.path ==
              '/selfservice/PersonalProfile/PersonalInformation/Salary'
              ? permission.edit
              : permission_management.edit
              " @click="Showandhide">{{ $t("edit") }}</v-btn>
        </v-col>
      </v-row>
      <v-row v-show="showsavewithid">
        <v-col offset-md="10">
          <v-btn color="#F89D1F" class="pa-2 float-right text_transform_none commonsavebtn"
            :disabled="permission_management.add" @click="AddSalaryWithEid">{{ $t("save") }}</v-btn>
        </v-col>
      </v-row>
      <v-row v-show="showsavewithsid">
        <v-col offset-md="10">
          <v-btn color="#F89D1F" class="pa-2 mr-7 text_transform_none commonsavebtn"
            :disabled="permission.add" @click="AddSalaryWithSid">{{ $t("save") }}</v-btn>
        </v-col>
      </v-row>
      <v-row v-show="showsavewithcode">
        <v-col offset-md="10">
          <v-btn color="#F89D1F" class="pa-2 float-right text_transform_none commonsavebtn"
            :disabled="permission_management.add" @click="AddSalaryWithCode">{{ $t("save") }}</v-btn>
        </v-col>
      </v-row>
      <v-row v-show="showbyid">
        <v-spacer></v-spacer>
        <div>
          <v-btn color="#3C4043" @click="hidethis" class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn" outlined text>{{ $t("cancel") }}</v-btn>
          <v-btn color="#F89D1F" class="pa-2 text_transform_none save_btn commonsavebtn"
            :disabled="permission_management.edit" @click="EditSalary">{{ $t("update") }}</v-btn>
        </div>
      </v-row>
      <v-row v-show="showbysid">
        <v-spacer></v-spacer>
        <div>
          <v-btn color="#3C4043" @click="hidethis" class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn" outlined text>{{ $t("cancel") }}</v-btn>
          <v-btn color="#F89D1F" class="pa-2 text_transform_none save_btn commonsavebtn"
            :disabled="permission.edit" @click="EditSalaryWithSid">{{ $t("update") }}</v-btn>
        </div>
      </v-row>
      <v-row v-show="showbycode">
        <v-spacer></v-spacer>
        <div>
          <v-btn color="#3C4043" @click="hidethis" class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn" outlined text>{{ $t("cancel") }}</v-btn>
          <v-btn color="#F89D1F" class="pa-2 text_transform_none btn_edit save_btn commonsavebtn" :disabled="permission_management.edit" @click="EditSalaryWithCode">{{ $t("update") }}</v-btn>
        </div>
      </v-row>
    </div>
    
    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import CheckViewAddEdit from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [CheckViewAddEdit, LogTrace, utils],
  data() {
    let srcs = {
      1: "/@assets/images/Mask Group 101@2x.png",
      2: "/@assets/images/Mask Group 101@2x.png",
    };

    return {
      thelang: '',
      LoadingDialog: false,
      permission_management: {
        add: true,
        edit: true,
      },
      permission: {
        add: true,
        edit: true,
      },
      rwimages: {
        Right: require("@/assets/images/Group 8356.png"),
        Wrong: require("@/assets/images/Group 8357.png"),
      },
      Personallist: [],
      Incometypelist: [],
      tempIncometypelist: [],
      Selectedincome: 0,
      incomeTypeName: "",
      MyempTax: false,
      MySSF: false,
      disableall: false,
      showedit: false,
      showsavewithid: false,
      showsavewithsid: false,
      showsavewithcode: false,
      showbyid: false,
      showbysid: false,
      showbycode: false,
      items1: ["Kasikornbank Public Company"],
      url: enurl.apiUrl,
      // images: {
      //   plus: require("@/assets/images/—Pngtree—button plus icon_3566857.png"),
      //   minus: require("@/assets/images/Mask Group 71.png"),
      //   trash: require("@/assets/images/Mask Group 177.png"),
      // },
      items: [],
      item:[],
      addsalary: {
        salary: '',
        paymenttype: "",
        bankname: "",
        banknum: null,
      },
      validatelist: [],
      total: 0,
      totalforcalculatetax: 0,
      forother: 0,
      item2: [
        {
          id: 0,
          income_Type: "",
          amount: 0,
          value2: true,
          value3: false,
          errorincometype: "",
          erroramount: "",
        },
      ],
      did: 0,
      fixed_Income: "",
      BankList: [],
      tempBankList: [],
      selectedText: false,
      selectedTextIncome: false,
      disablebank: false,
      tempimport: [],
      payment_Type: "",
      bankName: "",
      bank_Acc_Num: "",
      errorMessageBA: "",
      tt: "",
      SalaryList: [],
      Newsalarylist: [],
      IncomeList: [],
      Newincomelist: [],
      mytotal: 0,
      mytotal1: 0,
      mask: "#######################",
      accountnum: "############",
      people: [
        { name: "Kasikornbank Public Company" },
        { name: "Connors", avatar: srcs[1] },
        { name: "Hansen", avatar: srcs[2] },
      ],
      error: {
        errorMessageIncome: "",
        errorMessagePayment: "",
        errorMessageBank: "",
        errorMessageBanknum: "",
        empType: "",
      },
      selectitems: [],
      EmploymentType:"",
      salarykey: 1,
      nextItemId: 1,
      erroramount: "",
      amount:0,
      msgg: "",
    };
  },
  watch: {
    "EmploymentType"(v) {
      if (v) this.error.empType = "";
    },

    thelang() {
      this.whatthehead();
      this.getEmploymentType();
    },

    'total'(v){
      if(v== 0) this.total = '0.00';
    },

    'totalforcalculatetax'(v){
      if(v== 0) this.totalforcalculatetax = '0.00';
    },

    "addsalary.salary"(v) {
      if (v) this.error.errorMessageIncome = "";
      if (v && v[0] === '0' && v[1] != '.' && v.length != 1)
      {
        this.addsalary.salary = v.substring(1);
      }
    },

    "addsalary.paymenttype"(v) {
      if (v) this.error.errorMessagePayment = "";
    },
    
    "addsalary.bankname"(v) {
      if (v) this.error.errorMessageBank = "";
    },

    "addsalary.banknum"(v) {
      if (v) this.error.errorMessageBanknum = "";
    },
  },
  computed: {
    incomeitems() {
      return [
        { val: "Position Fees", txt: this.$t("PositionFees") },
        { val: "House Rental Fees", txt: this.$t("HouseRentalFees") },
        { val: "Phone Fees", txt: this.$t("PhoneFees") },
        { val: "Language Fees", txt: this.$t("LanguageFees") },
        { val: "Travel Fees", txt: this.$t("TravelFees") },
      ];
    },
    paymentitems() {
      return [
        { val: "Bank Transfer", txt: this.$t("BankTransfer") },
        { val: "Cash", txt: this.$t("Cash") },
      ];
    },
    thethaihead() {
      return this.whatthehead();
    },
    images() {
      return this.$store.state.images;
    }
  },

  async mounted() {
    let self = this;
    self.getEmploymentType();
    self.getbank();
    self.Getallincome();
    self.GetaddedDATAwithcode();
    self.permission = self.CheckViewAddEdit(126, 127, 128);
    self.permission_management = self.CheckViewAddEdit(193, 224, 194);
    self.timerInterval = setInterval(() => {self.thelang = localStorage.getItem('Templang')}, 100);
    self.disable_btn();
    if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/Salary")
    {
      //console.log("1")
      await self.GetSalaryWithSid();
      await self.GetIncomeWithSid();
      self.item2.splice(0, 1);
    }
    else if (self.$route.params.id != undefined)
    {
      //console.log("2")
      await self.GetSalary();
      await self.GetIncome();
      self.item2.splice(0, 1);
    }
    else if (localStorage.empcode != null)
    {
      //console.log("3")
      self.showsavewithcode = true;
      await self.GetSalaryWithCode();
      await self.GetIncomeWithCode();
      self.item2.splice(0, 1);
      self.item2.push({
        id: 1,
        income_Type: "",
        amount: 0,
        value2: true,
        value3: false,
        errorincometype: "",
        erroramount: "",
      });
    }
    else
    {
      self.showsavewithcode = true;
    }
    self.items.forEach(item => {
      if (item.amount === "0") 
      {
        item.amount = "0.00";
      }
    });
    self.mytotal = localStorage.total;
    if (self.$route.params.id == undefined || self.$route.params.id == "undefined") 
    {
      self.item2.splice(0, 1);
      if (self.$route.path !="/selfservice/PersonalProfile/PersonalInformation/Salary") 
      {
        self.item2.push({
          id: 1,
          income_Type: "",
          amount: 0,
          value2: true,
          value3: false,
          errorincometype: "",
          erroramount: "",
        });
      }
    } 
    else 
    {
      self.showbtn = false;
      self.item2.splice(0, 1);
    }
  },

  methods: {
    whatthehead() {
      //console.log(this.tempBankList)
      this.BankList = this.transformArray(this.thelang,this.tempBankList);
      this.Incometypelist = this.transformArray1(this.thelang,this.tempIncometypelist);
      if (this.thelang == "ประเทศไทย") 
      {
        return true;
      } 
      else 
      {
        return false;
      }
    },

    getEmploymentType(){
      let self = this;
      self.selectitems = [];
      axios.get(`${self.url}EmploymentType/GetAllEmploymentType`)
      .then(function (response) {
        if(response.data.status == 0)
        {
          response.data.data.forEach(element => {
            let temp = {
              EmploymentTypeID: element.employmentTypeID,
              EmploymentTypeName: element.employmentTypeName,
            };
            self.selectitems.push(temp);
          });
        }
      })
    },

    disable_btn() {
      let self = this;
      if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/Salary") 
      {
        self.permission.add = false;
        self.permission.edit = false;
      } 
      else 
      {
        self.permission_management.add = false;
        self.permission_management.edit = false;
      }
    },

    checkerror(id) {
      let self = this;
      if (self.items[id].income_Type != "")
      {
        self.items[id].errorincometype = "";
      }
      if (self.items[id].amount != "" && self.items[id].amount != null)
      {
        self.items[id].erroramount = "";
      }
    },

    checkerrorforother(id) {
      let self = this;
      if (self.item2[id].income_Type != "") 
      {
        self.item2[id].errorincometype = "";
      }
      if (self.item2[id].amount != "" && self.item2[id].amount != null) 
      {
        self.item2[id].erroramount = "";
      }
    },

    onlyNumberforpersonalandtax($event) {
      if (
        store.state.country == "Thailand" ||
        store.state.country == "Thai" ||
        store.state.country == "ไทย" ||
        store.state.country == "ประเทศไทย"
      ) 
      {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) 
        {
          $event.preventDefault();
        }
      }
    },
    
    Getallincome() {
      let self = this;
      self.Incometypelist = [];
      let temp = { company_ID: store.state.companyID };
      axios.post(`${self.url}IncomeType/GetIncomeTypeByCompanyID`, temp)
      .then(function (response) {
        if(response.data.status == 0)
        {
          let alllist = response.data.data;
          let list = alllist.filter((date) => date.status == true);
          let numl = 1;
          for (let i = 0; i < list.length; i++) 
          {
            let temp = {
              ...list[i],
              num: numl++,
            };
            self.tempIncometypelist.push(temp);
            if (i == 0) 
            {
              self.Selectedincome = self.tempIncometypelist[0].income_Type_ID;
              self.incomeTypeName = self.thelang === 'English' ? self.tempIncometypelist[0].incomeTypeName_EN : self.tempIncometypelist[0].incomeTypeName_TH;
              // self.incomeTypeName_TH = self.tempIncometypelist[0].incomeTypeName_TH;
              // self.incomeTypeName_EN = self.tempIncometypelist[0].incomeTypeName_EN;
              self.MyempTax = self.tempIncometypelist[0].tax_Calculation;
              self.MySSF = self.tempIncometypelist[0].social_Security_Calculation;
            }
          }
          self.Incometypelist = self.transformArray1(self.thelang,self.tempIncometypelist); 
        }
      });
    },

    async hidethis() {
      let self = this;
      self.showedit = true;
      self.disableall = true;
      self.showsavewithid = false;
      self.showsavewithsid = false;
      self.showsavewithcode = false;
      self.showbyid = false;
      self.showbysid = false;
      self.showbycode = false;
      for (let i = 0; i < self.items.length; i++) 
      {
        self.items[i].value1 = false;
      }
      for (let j = 0; j < self.item2.length; j++) 
      {
        self.item2[j].value2 = false;
        self.item2[j].value3 = false;
      }
      if(self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/Salary")
      {
        await self.GetSalaryWithSid();
        await self.GetIncomeWithSid();
      }
      else
      {
        await self.GetSalary();
        await self.GetIncome();
      }
    },

    validateincome() {
      let self = this;
      self.item2 = self.item2.filter(item => item.id !== 0);
      let tempitems = [...self.items, ...self.item2];
      for (let i = 0; i < tempitems.length; i++) 
      {
        if (tempitems[i].income_Type == null || tempitems[i].amount)
        {
          if (tempitems[i].income_Type == null) 
          {
            tempitems[i].errorincometype = "Please fill in the additional income type";
            self.validatelist.push("null exist");
            return false;
          }
          if (!tempitems[i].amount)
          {
            tempitems[i].erroramount = "Please fill in the amount";
            self.validatelist.push("null exist");
            return false;
          }
        }
        else 
        {
          tempitems[i].errorincometype = "";
          tempitems[i].erroramount = "";
        }
        //return true;
      }
      return true;
    },

    selectText(event,item)
    {
      // Convert input to string if it's not already
      let numStr = item === '' ? '0.00' : item.toString();
      // Split the input into integer and decimal parts
      let [integerPart, decimalPart] = numStr.split('.');
      // Remove unnecessary commas for processing
      integerPart = integerPart.replace(/,/g, '');
      // Format the integer part with commas
      integerPart = parseInt(integerPart).toLocaleString();
      // If there's no decimal part or it's zero, return the formatted integer part
      if (!decimalPart || parseFloat(decimalPart) === "00") 
      {
        this.addsalary.salary = integerPart;
      }
      // Trim trailing zeros in the decimal part
      decimalPart = decimalPart.replace(/0+$/, '');
      // If the decimal part is empty after trimming, return only the integer part
      if (decimalPart === '') {
        this.addsalary.salary = integerPart;
      }
      // Return the formatted number with the necessary decimal part
      this.addsalary.salary = decimalPart === "" ? `${integerPart}` : `${integerPart}.${decimalPart}`;

      if (this.addsalary.salary !== '0.00') {
        this.$nextTick(() => {
          event.target.select();
        });
      }
      this.selectedText = true;
    },

    selectTextIncome(event,index){
      // Convert input to string if it's not already
      let numStr = this.items.filter(temp => temp.id == index)[0].amount == '' ? '0.00' : this.items.filter(temp => temp.id == index)[0].amount.toString();
      //console.log(this.items)
      // Split the input into integer and decimal parts
      let [integerPart, decimalPart] = numStr.split('.');
      // Remove unnecessary commas for processing
      integerPart = integerPart.replace(/,/g, '');
      // Format the integer part with commas
      integerPart = parseInt(integerPart).toLocaleString();
      // If there's no decimal part or it's zero, return the formatted integer part
      if (!decimalPart || parseFloat(decimalPart) === "00") 
      {
        this.items.filter(temp => temp.id == index)[0].amount = integerPart;
      }
      // Trim trailing zeros in the decimal part
      decimalPart = decimalPart.replace(/0+$/, '');
      // If the decimal part is empty after trimming, return only the integer part
      if (decimalPart === '') 
      {
        this.items.filter(temp => temp.id == index)[0].amount = integerPart;
      }
      // Return the formatted number with the necessary decimal part
      this.items.filter(temp => temp.id == index)[0].amount = decimalPart === "" ? `${integerPart}` : `${integerPart}.${decimalPart}`;
      if (this.items.filter(temp => temp.id == index)[0].amount !== '0.00') 
      {
        this.$nextTick(() => {
          event.target.select();
        });
      }
      this.selectedTextIncome = true;
    },

    handleClick(item){
      if(item === '0.00')
      {
        this.addsalary.salary = 0;
      }
    },

    handleClickItem(index){
      if(this.items.filter(temp => temp.id == index)[0].amount === '0.00')
      {
        this.items.filter(temp => temp.id == index)[0].amount = 0;
      }
    },

    intitudeHandler(newValue, index)
    {
      if (newValue && newValue[0] === '0' && newValue[1] != '.' && newValue.length != 1)
      {
        this.items.filter(temp => temp.id == index)[0].amount = newValue.substring(1);
      }
      this.items.filter(temp => temp.id == index)[0].erroramount = "";
    },

    handleFocusout(item){
      if(item != '' && item != '.' && item != null && item != undefined && item != '0.00')
      {
        let temp = this.decimalnumberWithCommas(parseFloat(item.split(',').join('')).toFixed(2));
        this.addsalary.salary = temp;
      }
      else
      {
        this.addsalary.salary = '0.00';
      }
    },

    handleFocusoutItem(index){
      if(this.items.filter(temp => temp.id == index)[0].amount != '' && this.items.filter(temp => temp.id == index)[0].amount != '.' && this.items.filter(temp => temp.id == index)[0].amount != null && this.items.filter(temp => temp.id == index)[0].amount != undefined && this.items.filter(temp => temp.id == index)[0].amount != '0.00')
      {
        let temp1 = this.items.filter(temp => temp.id == index)[0].amount;
        let temp = this.decimalnumberWithCommas(parseFloat(temp1.split(',').join('')).toFixed(2));
        this.items.filter(temp => temp.id == index)[0].amount = temp;
      }
      else
      {
        this.items.filter(temp => temp.id == index)[0].amount = '0.00';
      }
      // Function to update amount fields
      this.items.forEach(item => {
        if (item.amount === "0") 
        {
          item.amount = "0.00";
        }
      });
      //this.addall();
    },

    onlyNumber($event,value) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      // Allow only numbers and one decimal point
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8 && keyCode !== 46) 
      {
        $event.preventDefault();
      }
      // Prevent typing a second zero at the beginning
      if (value === '0' && keyCode === 48 && !this.selectedText) 
      {
        value = '0';
        $event.preventDefault();
      }
      // Prevent typing a second decimal point
      if (value.includes('.') && keyCode === 46) 
      {
        $event.preventDefault();
      }
      // Allow maximum two decimal places
      if (!this.selectedText && value.includes('.')) 
      {
        let decimalPart = value.split('.')[1];
        if (decimalPart?.length >= 2 && keyCode >= 48 && keyCode <= 57) 
        {
          $event.preventDefault();
        }
      }
      this.selectedText = false;
    },

    onlyNumberIncome($event,value) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      let tempIncome = value.amount === 0 ? 0 : value.amount;
      //let tempIncome = this.items.filter(temp => temp.id == value)[0].amount == 0 ? '0' : this.items.filter(temp => temp.id == value)[0].amount;
      // Allow only numbers and one decimal point
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8 && keyCode !== 46) 
      {
        $event.preventDefault();
      }
      // Prevent typing a second zero at the beginning
      if (tempIncome === '0' && keyCode === 48 && !this.selectedTextIncome) 
      {
        value.amount = '0';
        $event.preventDefault();
      }
      // Prevent typing a second decimal point
      if (tempIncome.includes('.') && keyCode === 46) 
      {
        $event.preventDefault();
      }
      // Allow maximum two decimal places
      if (!this.selectedTextIncome && tempIncome.includes('.')) 
      {
        let decimalPart = tempIncome.split('.')[1];
        if (decimalPart?.length >= 2 && keyCode >= 48 && keyCode <= 57) 
        {
          $event.preventDefault();
        }
      }
      this.selectedTextIncome = false;
    },

    // disable when choose cash
    changepayment() {
      let self = this;
      if (self.addsalary.paymenttype == "Cash") 
      {
        self.disablebank = true;
        self.addsalary.bankname = 0;
        self.addsalary.banknum = "";
        self.error.errorMessageBank = "";
        self.error.errorMessageBanknum = "";
      } 
      else 
      {
        self.disablebank = false;
      }
    },

    // show and hide buttons
    Showandhide() {
      let self = this;
      self.showedit = false;
      self.disableall = false;
      if (!self.disableall)
      {
        if (self.addsalary.paymenttype == 'Cash')
        {
          this.disablebank = true;
        }
        else
        {
          this.disablebank = false;
        }
      }
      if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/Salary")
      {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = false;
        self.showbysid = true;
        self.showbycode = false;
      }
      else if (self.$route.params.id != undefined)
      {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = true;
        self.showbysid = false;
        self.showbycode = false;
      }
      else
      {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = false;
        self.showbysid = false;
        self.showbycode = true;
      }
      for (const item of self.items)
      {
        item.value1 = true;
      }
      for (const item of self.item2)
      {
        item.value2 = true;
        item.value3 = true;
      }
    },

    // direct to salary history page
    gosalaryhistory() {
      let workper = this.$route.params.id;
      if (this.$route.path =="/selfservice/PersonalProfile/PersonalInformation/Salary") 
      {
        this.$router.push({name: "PersonalSalaryHistory",params: { sid: store.state.employeeID }}).catch(() => { });
      } 
      else if (this.$route.params.id != null || this.$route.params.id != undefined) 
      {
        this.$router.push({name: "salarychangehistory1",params: { id: this.$route.params.id, salaryid: workper }}).catch(() => { });
      } 
      else if (this.$route.path == "/PersonnelManagement/NewEmployee/NewPersonalInformation/Salary") 
      {
        this.$router.push({ name: "newsalarychangehistory" }).catch(() => { });
      }
    },

    // get bank list
    getbank() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      axios.post(`${self.url}Bank/GetALLBanksByCompanyID`, temp)
      .then(function (response) {
        if(response.data.status == 0)
        {
          self.tempBankList = response.data.data;
          self.BankList = self.transformArray(self.thelang,self.tempBankList)
          //console.log(self.BankList)
        }
      });
    },

    transformArray(lang,originalArray) {
      return originalArray.map(item => {
        if (lang === 'English')
        {
          return {
            "bank_ID": item.bank_ID,
            "bankname": item.bankName_EN,
            "bankIcon": item.bankIcon,
            "base64BankIcon": item.base64BankIcon,
            "company_ID": item.company_ID
          };
      }
        else
        {
          return {
            "bank_ID": item.bank_ID,
            "bankname": item.bank_Name,
            "bankIcon": item.bankIcon,
            "base64BankIcon": item.base64BankIcon,
            "company_ID": item.company_ID
          };
        }
      });
    },

    transformArray1(lang,originalArray) {
      return originalArray.map(item => {
        if (lang === 'English')
        {
          return {
            "income_Type_ID": item.income_Type_ID,
            "incomeTypeName": item.incomeTypeName_EN,
            "tax_Calculation": item.tax_Calculation,
            "social_Security_Calculation": item.social_Security_Calculation,
            "company_ID": item.company_ID,
            "status": item.status,
            "num": item.num
          };
        }
        else
        {
          return {
            "income_Type_ID": item.income_Type_ID,
            "incomeTypeName": item.incomeTypeName_TH,
            "tax_Calculation": item.tax_Calculation,
            "social_Security_Calculation": item.social_Security_Calculation,
            "company_ID": item.company_ID,
            "status": item.status,
            "num": item.num
          };
        }
      });
    },

    addall() {
      let self = this;
      self.total = 0;
      self.totalforcalculatetax = 0;
      if (self.addsalary.salary === "") 
      {
        self.total = 0;
      }
      for (let j = 0; j < self.items.length; j++)
      {
        let tempAmount = self.items[j].amount;
        if (tempAmount === '.' || tempAmount === '0.')
        {
          // Allow intermediate values
          self.items[j].amount = tempAmount;
          continue;
        }
        tempAmount = self.removecomma(tempAmount);
        let parsedAmount = parseFloat(tempAmount);
        if (!isNaN(parsedAmount) && parsedAmount != 0) 
        {
          self.total += parsedAmount;
          if (self.items[j].Tax)
          {
            self.totalforcalculatetax += parsedAmount;
          }
          self.items[j].amount = self.numberWithCommas(tempAmount);
        }
      }

      for (let a = 0; a < self.item2.length; a++)
      {
        let tempAmount = self.item2[a].amount;
        if (tempAmount != 0 && tempAmount !== "" && tempAmount !== null) 
        {
          tempAmount = self.removecomma(tempAmount);
          let parsedAmount = parseFloat(tempAmount);
          if (!isNaN(parsedAmount) && parsedAmount != 0)
          {
            self.total += parsedAmount;
          }
          self.item2[a].amount = self.numberWithCommas(tempAmount);
        }
      }
      let tempSalary = self.addsalary.salary;
      if (tempSalary != 0 && tempSalary !== "")
      {
        if (tempSalary === '.')
        {
          tempSalary = '0';
        }
        tempSalary = self.removecomma(tempSalary);
        let parsedSalary = parseFloat(tempSalary);
        if (!isNaN(parsedSalary))
        {
          self.total += parsedSalary;
          self.totalforcalculatetax += parsedSalary;
        }
        self.addsalary.salary = self.numberWithCommas(tempSalary);
      }
      self.total = self.decimalnumberWithCommas(self.total.toFixed(2));
      self.totalforcalculatetax = self.decimalnumberWithCommas(self.totalforcalculatetax.toFixed(2));
    },

    decimalnumberWithCommas(x) {
      if (x != null && x.split(".")[0] != null) 
      {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      else
      {
        return "";
      }
    },

    numberWithCommas(x) {
      if (x != null) 
      {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    removecomma(x) {
      if (x != 0 && x != null) 
      {
        return x.replace(/,/g, "");
      } 
      else 
      {
        return "0";
      }
    },

    addItem() {
      const plusid = this.items.length === 0 ? 1 : this.items[this.items.length-1].id + 1;
      this.items.push({
        id: plusid,
        income_Type: this.Selectedincome,
        incomeTypeName: this.thelang === 'English' ? this.incomeTypeName_EN : this.incomeTypeName_TH,
        // incomeTypeName_TH: this.incomeTypeName_TH,
        // incomeTypeName_EN: this.incomeTypeName_EN,
        amount: '0.00',
        employee_ID: parseInt(this.$route.params.id),
        value1: true,
        errorincometype: "",
        erroramount: "",
        Tax: this.MyempTax,
        SSF: this.MySSF,
      });
    },

    isNumeric(num) {
      return !isNaN(num);
    },

    isDecimal(num) {
      return !isNaN(parseFloat(num)) && num.toString().indexOf('.') !== -1;
    },

    changeincometype(incometype, ID) {
      const selectedIncomeType = this.Incometypelist.find(item => item.income_Type_ID === incometype);
      if (selectedIncomeType)
      {
        const copiedIncomeType = JSON.parse(JSON.stringify(selectedIncomeType));
        this.items.filter(temp => temp.id == ID)[0].Tax = copiedIncomeType.tax_Calculation;
        this.items.filter(temp => temp.id == ID)[0].SSF = copiedIncomeType.social_Security_Calculation;
        this.items.filter(temp => temp.id == ID)[0].income_Type = copiedIncomeType.income_Type_ID;
        this.items.filter(temp => temp.id == ID)[0].incomeTypeName = copiedIncomeType.incomeTypeName;
        this.items.filter(temp => temp.id == ID)[0].errorincometype = "";
        this.validatelist = [];
        
      }
    },

    // Get salary information
    async GetSalary(v) {
      let self = this;
      if (this.$route.params.id != "undefined")
      {
        if(v != 1){
          self.LoadingDialog = true;
        }
        let tempp = {
          employee_ID: parseInt(this.$route.params.id),
          company_ID: store.state.companyID,
        };
        await axios.post(`${self.url}Salary/GetSalaryInfo`, tempp)
        .then(function (response) {
          self.SalaryList = response.data.data;
          if(v != 1){
            self.LoadingDialog = false;
          }
          if (self.SalaryList.length != 0) 
          {
            self.disableall = true;
            self.showedit = true;
            self.showsavewithid = false;
            self.showsavewithsid = false;
            self.showsavewithcode = false;
            self.showbyid = false;
            self.showbysid = false;
            self.showbycode = false;
            self.addsalary.salary = self.SalaryList[0].salary_Amount;
            self.addsalary.paymenttype = self.SalaryList[0].payment_Type;
            self.addsalary.bankname = self.SalaryList[0].bank_ID;
            self.addsalary.banknum = self.SalaryList[0].bank_Acc_Num;
            self.EmploymentType = self.SalaryList[0].employmentTypeID==2 ? "Daily Payment" : (self.SalaryList[0].employmentTypeID==1 ? "Monthly Payment" : "");
            let cc = 0;
            self.total = 0;
            self.totalforcalculatetax = 0;
            if (self.addsalary.salary != "") 
            {
              self.addsalary.salary = self.isDecimal(self.addsalary.salary) ? self.removecomma(self.addsalary.salary) : parseFloat(self.addsalary.salary).toFixed(2);
              cc = parseFloat(self.addsalary.salary);
              self.total = parseInt(self.total) + cc;
              self.totalforcalculatetax = parseInt(self.totalforcalculatetax) + cc;
            }
            if (Number.isInteger(self.total) != true)
            {
              self.total = parseFloat(self.total).toFixed(2);
              if (self.isNumeric(self.total) == false)
              {
                self.total = parseFloat(self.total).toFixed(2);
              }
            }
            if (Number.isInteger(self.totalforcalculatetax) != true) 
            {
              self.totalforcalculatetax = parseFloat(self.totalforcalculatetax).toFixed(2);
              if (self.isNumeric(self.totalforcalculatetax) == false) 
              {
                self.totalforcalculatetax = parseFloat(self.totalforcalculatetax).toFixed(2);
              }
            }
            self.addsalary.salary = self.numberWithCommas(self.addsalary.salary);
            self.total = self.decimalnumberWithCommas(parseFloat(self.total).toFixed(2));
            self.totalforcalculatetax = self.decimalnumberWithCommas(parseFloat(self.totalforcalculatetax).toFixed(2));
            //self.LogTrace(null, "Get Salary", 15, "Critical");
          }
          if (self.$route.params.id != null && self.SalaryList.length == 0) 
          {
            self.showsavewithid = true;
          }
          if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/Salary" && self.SalaryList.length != 0) 
          {
            self.disableall = false;
            self.showedit = false;
            self.showsavewithid = false;
            self.showsavewithsid = true;
            self.showsavewithcode = false;
            self.showbyid = false;
            self.showbysid = false;
            self.showbycode = false;
            self.addsalary.salary = self.SalaryList[0].salary_Amount;
            self.addsalary.paymenttype = self.SalaryList[0].payment_Type;
            self.addsalary.bankname = self.SalaryList[0].bank_ID;
            self.addsalary.banknum = self.SalaryList[0].bank_Acc_Num;
            let cc = parseFloat(self.addsalary.salary);
            self.total = self.total + cc;
            self.totalforcalculatetax = self.totalforcalculatetax + cc;
            if (Number.isInteger(self.total) != true) 
            {
              self.total = (parseFloat(self.total)).toFixed(2);
              //self.total = parseFloat(self.total.toFixed(2));
            }
            if (Number.isInteger(self.totalforcalculatetax) != true) 
            {
              self.totalforcalculatetax = parseFloat(self.totalforcalculatetax.toFixed(2));
            }
            //self.LogTrace(null, "Get Salary", 15, "Critical");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          self.LogTrace(error, "Get Salary Fail", 15, "Critical");
        });
      }
    },

    // Get salary information for personal profile
    async GetSalaryWithSid(v) {
      if (store.state.employeeID != "undefined") 
      {
        let self = this;
        if(v != 1){
          self.LoadingDialog = true;
        }
        
        let tempp = {
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        await axios.post(`${self.url}Salary/GetSalaryInfo`, tempp)
        .then(function (response) {
          self.SalaryList = response.data.data;
          if(v != 1){
            self.LoadingDialog = false;
          }
          if (self.SalaryList.length != 0) 
          {
            self.disableall = true;
            self.showedit = true;
            self.showsavewithid = false;
            self.showsavewithsid = false;
            self.showsavewithcode = false;
            self.showbyid = false;
            self.showbysid = false;
            self.showbycode = false;
            self.addsalary.salary = self.SalaryList[0].salary_Amount;
            self.addsalary.paymenttype = self.SalaryList[0].payment_Type;
            self.addsalary.bankname = self.SalaryList[0].bank_ID;
            self.addsalary.banknum = self.SalaryList[0].bank_Acc_Num;
            self.EmploymentType = self.SalaryList[0].employmentTypeID==2 ? "Daily Payment" : (self.SalaryList[0].employmentTypeID==1 ? "Monthly Payment" : "");
            let cc = 0;
            self.total = 0;
            if (self.addsalary.salary != "") 
            {
              self.addsalary.salary = self.isDecimal(self.addsalary.salary) ? self.removecomma(self.addsalary.salary) : parseFloat(self.addsalary.salary).toFixed(2);
              cc = parseFloat(self.addsalary.salary);
              self.total = parseInt(self.total) + cc;
              self.totalforcalculatetax = parseInt(self.total);
            }

            if (Number.isInteger(self.total) != true) 
            {
              self.total = parseFloat(self.total);
              if (self.isNumeric(self.total) == false) 
              {
                self.total = parseFloat(self.total).toFixed(2);
                self.totalforcalculatetax = self.total;
              }
            }
            self.addsalary.salary = self.numberWithCommas(self.addsalary.salary);
            // self.total = self.numberWithCommas(self.total);
            self.total = self.decimalnumberWithCommas(parseFloat(self.total).toFixed(2));
            self.totalforcalculatetax = self.decimalnumberWithCommas(parseFloat(self.totalforcalculatetax).toFixed(2));
          }
          else if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/Salary" && self.SalaryList.length == 0) 
          {
            self.disableall = false;
            self.showedit = false;
            self.showsavewithid = false;
            self.showsavewithsid = true;
            self.showsavewithcode = false;
            self.showbyid = false;
            self.showbysid = false;
            self.showbycode = false;
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          self.LogTrace(error, "Get Salary Fail", 15, "Critical");
        });
      }
    },

    // Get salary information when adding new
    async GetSalaryWithCode(v) {
      let self = this;
      if(v != 1){
        self.LoadingDialog = true;
      }
      self.total = 0;
      let tempp = {
        employee_ID: localStorage.empcode,
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}Salary/GetnewsalaryinfowithEmpcode`, tempp)
      .then(function (response) {
        self.Newsalarylist = response.data.data;
        if(v != 1){
          self.LoadingDialog = false;
        }
        if (self.Newsalarylist.length != 0) 
        {
          self.addsalary.salary = self.Newsalarylist[0].salary_Amount;
          self.addsalary.paymenttype = self.Newsalarylist[0].payment_Type;
          self.addsalary.bankname = self.Newsalarylist[0].bank_ID;
          self.addsalary.banknum = self.Newsalarylist[0].bank_Acc_Num;
          self.EmploymentType = self.Newsalarylist[0].employmentTypeID==2 ? "Daily Payment" : "Monthly Payment";
          let cc = parseFloat(self.addsalary.salary);
          if (self.addsalary.salary != "") 
          {
            self.addsalary.salary = self.removecomma(self.addsalary.salary);
            cc = parseFloat(self.addsalary.salary);
            self.total = self.total + cc;
            self.totalforcalculatetax = self.total;
          }
          if (Number.isInteger(self.total) != true) 
          {
            self.total = (parseFloat(self.total)).toFixed(2);
            self.totalforcalculatetax = (parseFloat(self.total)).toFixed(2);
          }
          self.disableall = true;
          self.showedit = true;
          self.showsavewithid = false;
          self.showsavewithsid = false;
          self.showsavewithcode = false;
          self.showbyid = false;
          self.showbysid = false;
          self.showbycode = false;
          self.addsalary.salary = self.numberWithCommas(self.addsalary.salary);
          self.total = self.numberWithCommas(self.total);
          self.totalforcalculatetax = self.total;
        }
        else
        {
          self.disableall = false;
          self.showedit = false;
          self.showsavewithid = false;
          self.showsavewithsid = false;
          self.showsavewithcode = true;
          self.showbyid = false;
          self.showbysid = false;
          self.showbycode = false;
        }
      })
      .catch(function (error) {
        self.LoadingDialog = false;
        self.LogTrace(error, "Get Salary Fail", 15, "Critical");
      });
    },

    updateBank() {
      this.addsalary.banknum = "";
    },

    // Get income information with employee code
    async GetIncomeWithCode() {
      let self = this;
      let tempp = {
        employee_ID: localStorage.empcode,
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}Incometype/GetNewIncomeInfo`, tempp)
      .then(function (response) {
        self.Newincomelist = response.data.data;
        if (self.Newincomelist.length != 0) 
        {
          for (let i = 0; i < self.Newincomelist.length; i++) 
          {
            const filter = self.tempIncometypelist.find((x) => x.income_Type_ID == self.Newincomelist[i].income_Type_ID);
            if (i == 0)
            {
              if (self.items.length > 0) 
              {
                self.items[0].income_Type = self.Newincomelist[i].income_Type_ID;
                self.items[0].amount = self.Newincomelist[i].amount;
                self.items[0].employee_ID = self.Newincomelist[i].employee_ID;
                self.items[0].Tax = filter.tax_Calculation;
                self.items[0].SSF = filter.social_Security_Calculation;
              }
              else
              {
                if (self.tempIncometypelist.length > 0) 
                {
                  self.items.push({
                    id: i,
                    income_Type: self.Newincomelist[i].income_Type_ID,
                    amount: self.Newincomelist[i].amount,
                    employee_ID: self.Newincomelist[i].employee_ID,
                    incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                    value1: false,
                    errorincometype: "",
                    erroramount: "",
                    Tax: filter.tax_Calculation,
                    SSF: filter.social_Security_Calculation,
                  });
                }
                else 
                {
                  self.items.push({
                    income_Type: self.Selectedincome,
                    incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                    // incomeTypeName_EN: self.incomeTypeName_EN,
                    // incomeTypeName_TH: self.incomeTypeName_TH,
                    amount: 0,
                    value1: false,
                    id: 0,
                    errorincometype: "",
                    erroramount: "",
                    Tax: self.MyempTax,
                    SSF: self.MySSF,
                  });
                }
              }
            }
            else
            {
              self.items.push({
                id: i,
                income_Type: self.Newincomelist[i].income_Type_ID,
                amount: self.Newincomelist[i].amount,
                employee_ID: self.Newincomelist[i].employee_ID,
                value1: false,
                errorincometype: "",
                erroramount: "",
                Tax: filter.tax_Calculation,
                incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                SSF: filter.social_Security_Calculation,
              });
            }
            if (typeof self.total === "string") 
            {
              self.total = self.removecomma(self.total);
            }
            if (typeof self.totalforcalculatetax === "string") 
            {
              self.totalforcalculatetax = self.removecomma(self.totalforcalculatetax);
            }

            let gg = parseFloat(self.items[i].amount);
            let tax = 0;
            if (self.items[i].Tax == true) 
            {
              tax = parseFloat(self.items[i].amount);
            }
            self.items[i].amount = self.numberWithCommas(self.items[i].amount);
            self.total = parseFloat(self.total);
            self.totalforcalculatetax = parseFloat(self.totalforcalculatetax);
            self.total += gg;
            self.totalforcalculatetax += tax;
          }
        }
        // Function to update amount fields
        self.items.forEach(item => {
          if (item.amount === "0") 
          {
            item.amount = "0.00";
          }
        });
        self.total = self.numberWithCommas(self.total);
        self.totalforcalculatetax = self.numberWithCommas(self.totalforcalculatetax);
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Income Fail", 15, "Critical");
      });
    },

    // get income with route employee ID
    async GetIncome() {
      let self = this;
      self.items = [];
      if (self.$route.params.id != undefined) 
      {
        let tempp = {
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        await axios.post(`${self.url}Incometype/GetIncomeInfo`, tempp)
        .then(function (response) {
          self.IncomeList = response.data.data;
          if (self.IncomeList.length != 0)
          {
            for (let i = 0; i < self.IncomeList.length; i++)
            {
              const filter = self.tempIncometypelist.find((x) => x.income_Type_ID == self.IncomeList[i].income_Type_ID);
              if (i == 0)
              {
                if (self.items.length > 0)
                {
                  self.items[0].income_Type = self.IncomeList[i].income_Type_ID;
                  self.items[0].amount = self.IncomeList[i].amount;
                  self.items[0].employee_ID = self.IncomeList[i].employee_ID;
                  self.items[0].Tax = filter.tax_Calculation;
                  self.items[0].SSF = filter.social_Security_Calculation;
                }
                else
                {
                  if (self.tempIncometypelist.length > 0)
                  {
                    self.items.push({
                      id: i,
                      income_Type: self.IncomeList[i].income_Type_ID,
                      amount: self.IncomeList[i].amount,
                      employee_ID: self.IncomeList[i].employee_ID,
                      incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                      value1: false,
                      errorincometype: "",
                      erroramount: "",
                      Tax: filter.tax_Calculation,
                      SSF: filter.social_Security_Calculation,
                    });
                  }
                  else
                  {
                    self.items.push({
                      income_Type: self.Selectedincome,
                      // incomeTypeName_TH: self.incomeTypeName_TH,
                      // incomeTypeName_EN: self.incomeTypeName_EN,
                      incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                      amount: 0,
                      value1: false,
                      id: 0,
                      errorincometype: "",
                      erroramount: "",
                      Tax: self.MyempTax,
                      SSF: self.MySSF,
                    });
                  }
                }
              }
              else
              {
                self.items.push({
                  id: i,
                  income_Type: self.IncomeList[i].income_Type_ID,
                  amount: self.IncomeList[i].amount,
                  employee_ID: self.IncomeList[i].employee_ID,
                  incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                  value1: false,
                  errorincometype: "",
                  erroramount: "",
                  Tax: filter.tax_Calculation,
                  SSF: filter.social_Security_Calculation,
                });
              }
              if (typeof self.total === "string")
              {
                self.total = self.removecomma(self.total);
              }
              if (typeof self.totalforcalculatetax === "string")
              {
                self.totalforcalculatetax = self.removecomma(self.totalforcalculatetax);
              }
              let gg = parseFloat(self.items[i].amount);
              let tax = 0;
              if (self.items[i].Tax == true)
              {
                tax = parseFloat(self.items[i].amount);
              }
              self.items[i].amount = self.numberWithCommas(self.items[i].amount);
              self.total = parseFloat(self.total);
              self.totalforcalculatetax = parseFloat(self.totalforcalculatetax);
              self.total += gg;
              self.totalforcalculatetax += tax;
            }
          }
          if (self.item2.length == 0) 
          {
            self.item2.push({
              income_Type: "",
              id: 0,
              amount: 0,
              value2: true,
              value3: false,
              errorincometype: "",
              erroramount: "",
            });
          }
          // Function to update amount fields
          self.items.forEach(item => {
            if (item.amount === "0") 
            {
              item.amount = "0.00";
            }
          });
          self.tt = localStorage.setItem("total", self.total);
          self.total = self.total.toString().includes(",") ? self.removecomma(self.total) : self.total;
          self.totalforcalculatetax = self.totalforcalculatetax.toString().includes(",") ? self.removecomma(self.totalforcalculatetax) : self.totalforcalculatetax;
          self.total = self.decimalnumberWithCommas(parseFloat(self.total).toFixed(2));
          self.totalforcalculatetax = self.decimalnumberWithCommas(parseFloat(self.totalforcalculatetax).toFixed(2));
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Income Fail", 15, "Critical");
        });
      }
    },

    // get income with session employee iD
    async GetIncomeWithSid() {
      if (store.state.employeeID != "undefined") 
      {
        let self = this;
        self.items = [];
        let tempp = {
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        await axios.post(`${self.url}Incometype/GetIncomeInfo`, tempp)
        .then(function (response) {
          self.IncomeList = response.data.data;
          if (self.IncomeList.length != 0)
          {
            for (let i = 0; i < self.IncomeList.length; i++)
            {
              const filter = self.tempIncometypelist.find((x) => x.income_Type_ID == self.IncomeList[i].income_Type_ID);
              if (i == 0)
              {
                if (self.items.length > 0)
                {
                  self.items[0].income_Type = self.IncomeList[i].income_Type_ID;
                  self.items[0].amount = self.IncomeList[i].amount;
                  self.items[0].employee_ID = self.IncomeList[i].employee_ID;
                  self.items[0].Tax = filter.tax_Calculation;
                  self.items[0].SSF = filter.social_Security_Calculation;
                }
                else
                {
                  if (self.tempIncometypelist.length > 0)
                  {
                    self.items.push({
                      id: i,
                      income_Type: self.IncomeList[i].income_Type_ID,
                      amount: self.IncomeList[i].amount,
                      employee_ID: self.IncomeList[i].employee_ID,
                      incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                      value1: false,
                      errorincometype: "",
                      erroramount: "",
                      Tax: filter.tax_Calculation,
                      SSF: filter.social_Security_Calculation,
                    });
                  }
                  else
                  {
                    self.items.push({
                      income_Type: self.Selectedincome,
                      incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                      // incomeTypeName_TH: self.incomeTypeName_TH,
                      // incomeTypeName_EN: self.incomeTypeName_EN,
                      amount: 0,
                      value1: false,
                      id: 0,
                      errorincometype: "",
                      erroramount: "",
                      Tax: self.MyempTax,
                      SSF: self.MySSF,
                    });
                  }
                }
              }
              else
              {
                self.items.push({
                  id: i,
                  income_Type: self.IncomeList[i].income_Type_ID,
                  amount: self.IncomeList[i].amount,
                  employee_ID: self.IncomeList[i].employee_ID,
                  incomeTypeName: self.thelang === 'English' ? self.incomeTypeName_EN : self.incomeTypeName_TH,
                  value1: false,
                  errorincometype: "",
                  erroramount: "",
                  Tax: filter.tax_Calculation,
                  SSF: filter.social_Security_Calculation,
                });
              }
              if (typeof self.total === "string")
              {
                self.total = self.removecomma(self.total);
              }
              if (typeof self.totalforcalculatetax === "string")
              {
                self.totalforcalculatetax = self.removecomma(self.totalforcalculatetax);
              }
              let gg = parseFloat(self.items[i].amount);
              let tax = 0;
              if (self.items[i].Tax == true)
              {
                tax = parseFloat(self.items[i].amount);
              }
              self.items[i].amount = self.numberWithCommas(self.items[i].amount);
              self.total = parseFloat(self.total);
              self.totalforcalculatetax = parseFloat(self.totalforcalculatetax);
              self.total += gg;
              self.totalforcalculatetax += tax;
            }

            self.Incometypelist = self.transformArray1(self.thelang,self.tempIncometypelist); 
          }
          self.items.forEach(item => {
            if (item.amount === "0") 
            {
              item.amount = "0.00";
            }
          });
          self.tt = localStorage.setItem("total", self.total);
          self.total = self.total.toString().includes(",") ? self.removecomma(self.total) : self.total;
          self.totalforcalculatetax = self.totalforcalculatetax.toString().includes(",") ? self.removecomma(self.totalforcalculatetax) : self.totalforcalculatetax;
          self.total = self.decimalnumberWithCommas(parseFloat(self.total).toFixed(2));
          self.totalforcalculatetax = self.decimalnumberWithCommas(parseFloat(self.totalforcalculatetax).toFixed(2));
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Income Fail", 15, "Critical");
        });
      }
    },

    // add salary with employeee code
    AddSalaryWithCode() {
      let self = this;
      if(!self.validateincome())
      {
        return false;
      }
      if (self.validatelist.length == 0)
      {
        let myvalidate = self.validatesalary();
        if (myvalidate == true)
        {
          self.LoadingDialog = true;
          if (self.addsalary.paymenttype != "Bank Transfer")
          {
            self.addsalary.bankname = 0;
            self.addsalary.banknum = "";
          }
          let tempadd = {
            salary_Amount: self.removecomma(self.addsalary.salary),
            payment_Type: self.addsalary.paymenttype,
            bank_ID: self.addsalary.bankname,
            bank_Acc_Num: self.addsalary.banknum,
            employee_ID: localStorage.empcode,
            acitve: true,
            company_ID: store.state.companyID,
            totalFixedIncome: self.removecomma(self.total),
            totalIncome_Tax: self.removecomma(self.totalforcalculatetax.toString()),
            employmentTypeID: self.EmploymentType == "Daily Payment" ? 2 : ( self.EmploymentType == "Monthly Payment" ? 1 : null ),
            updateEmployee_ID: store.state.employeeID,
          };

          self.AddIncomeWithCode();
          axios.post(`${self.url}Salary/AddSalarywithEmpcode`, tempadd)
          .then(async function (response) {
            if (response.data.status == 0) 
            {
              self.msgg = "Add Successfully";
              localStorage.setItem("totalsalary",self.removecomma(self.addsalary.salary));
              localStorage.setItem("locktab", "2nd");
              localStorage.setItem("mysalary",self.removecomma(self.totalforcalculatetax.toString()));
              await self.editEMPPersonalAllowance();
              await self.GetaddedDATAwithcode();
              await self.GetSalaryWithCode(1);
              self.reseterror();
              self.$router.push(`/PersonnelManagement/NewEmployee/NewPersonalInformation/PersonalIncomeTax`).catch(()=>{});
              self.showhidebutton = false;
              //alert(response.data.message);
              self.LoadingDialog = false;
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Salary Fail", 15, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        } 
        else 
        {
          alert("Please fill all the required fields");
          self.validateerror();
          self.LoadingDialog = false;
        }
      }
      else 
      {
        alert("Please fill in the additional income type");
        self.validatelist = [];
        self.LoadingDialog = false;
      }
    },

    // add salary with route employeeiD
    AddSalaryWithEid() {
      let self = this;
      const stringValue = "2nd";
      localStorage.setItem('locktab', stringValue);
      if(!self.validateincome())
      {
        return false;
      }
      if (self.validatelist.length == 0) 
      {
        let myvalidate = self.validatesalary();
        if (myvalidate == true) 
        {
          self.LoadingDialog = true;
          if (self.addsalary.paymenttype != "Bank Transfer") 
          {
            self.addsalary.bankname = 0;
            self.addsalary.banknum = "";
          }
          let tempedit = {
            salary_Amount: self.removecomma(self.addsalary.salary),
            payment_Type: self.addsalary.paymenttype,
            bank_ID: self.addsalary.bankname,
            bank_Acc_Num: self.addsalary.banknum,
            employee_ID: parseInt(self.$route.params.id),
            totalFixedIncome: self.removecomma(self.total.toString()),
            totalIncome_Tax: self.removecomma(self.totalforcalculatetax.toString()),
            employmentTypeID: self.EmploymentType == "Daily Payment" ? 2 : ( self.EmploymentType == "Monthly Payment" ? 1 : null ),
            updateEmployee_ID: store.state.employeeID,
          };
          axios.post(`${self.url}Salary/Addnewsalary`, tempedit)
          .then(async function (response) {
            if (response.data.status == 0) 
            {
              self.msgg = response.data.message;
              await self.editEMPPersonalAllowance();
              await self.GetaddedDATAwithcode();
              await self.EditIncome();
              await self.GetSalary(1);
              self.reseterror();
              self.showedit = true;
              self.disableall = true;
              self.showsavewithid = false;
              self.showsavewithsid = false;
              self.showsavewithcode = false;
              self.showbyid = false;
              self.showbysid = false;
              self.showbycode = false;
              //alert(response.data.message);
              self.LoadingDialog = false;
              //self.LogTrace(null, "Add Salary", 15, "Low");
              self.$router.push(`/PersonnelManagement/EditEmployee/${self.$route.params.id}/EditPersonalInformation/PersonalIncomeTax`).catch(()=>{});
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Salary Fail", 15, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        }
        else 
        {
          alert("Please fill all the required fields");
          self.validateerror();
          self.LoadingDialog = false;
        }
      }
      else 
      {
        alert("Please fill in the additional income type");
        self.validatelist = [];
        self.LoadingDialog = false;
      }
    },

    // add salary with session employee iD
    AddSalaryWithSid() {
      let self = this;
      if(!self.validateincome())
      {
        return false;
      }
      if (self.validatelist.length == 0) 
      {
        let myvalidate = self.validatesalary();
        if (myvalidate == true) 
        {
          self.LoadingDialog = true;
          if (self.addsalary.paymenttype != "Bank Transfer")
          {
            self.addsalary.bankname = 0;
            self.addsalary.banknum = "";
          }
          let tempedit = {
            salary_Amount: self.removecomma(self.addsalary.salary),
            payment_Type: self.addsalary.paymenttype,
            bank_ID: self.addsalary.bankname,
            bank_Acc_Num: self.addsalary.banknum,
            employee_ID: store.state.employeeID,
            totalFixedIncome: self.removecomma(self.total.toString()),
            totalIncome_Tax: self.removecomma(self.totalforcalculatetax.toString()),
            employmentTypeID: self.EmploymentType == "Daily Payment" ? 2 : ( self.EmploymentType == "Monthly Payment" ? 1 : null ),
            updateEmployee_ID: store.state.employeeID,
          };
          axios.post(`${self.url}Salary/Addnewsalary`, tempedit)
          .then(async function (response) {
            if (response.data.status == 0) 
            {
              self.msgg = response.data.message;
              localStorage.setItem("locktab", "2nd");             
              await self.editEMPPersonalAllowance();
              await self.EditIncomeWithSid();
              await self.GetSalaryWithSid(1);
              self.reseterror();
              self.disableall = true;
              self.showedit = true;
              self.showsavewithid = false;
              self.showsavewithsid = false;
              self.showsavewithcode = false;
              self.showbyid = false;
              self.showbysid = false;
              self.showbycode = false;
              //alert(response.data.message);
              self.LoadingDialog = false;
              //self.LogTrace(null, "Add Salary", 15, "Low");
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add Salary Fail", 15, "Low");
            alert(error);
            self.LoadingDialog = false;
          });
        } 
        else 
        {
          alert("Please fill all the required fields");
          self.validateerror();
          self.LoadingDialog = false;
        }
      } 
      else 
      {
        alert("Please fill in the additional income type");
        self.validatelist = [];
      }
    },

    // add income with employee code
    AddIncomeWithCode() {
      let self = this;
      let combileArray = [];
      if (self.items.length != 0)
      {
        if (self.items[0].income_Type != null && self.items[0].amount != null) 
        {
          for (let i = 0; i < self.items.length; i++) 
          {
            self.tempimport = {
              income_Type_ID: self.items[i].income_Type,
              amount: self.removecomma(self.items[i].amount),
              employee_ID: localStorage.empcode,
              company_ID: store.state.companyID,
            };
            combileArray.push(self.tempimport);
          }
        }
        axios.post(`${self.url}Incometype/AddIncomeAll`, combileArray)
        .then(function (response) {
          if (response.data.status == 0) 
          {
            self.items = [];
            if (self.items.length == 0) 
            {
              //setTimeout(function () {
                self.GetIncomeWithCode();
                alert(self.msgg);
              //}, 2000);
            }
            //self.LogTrace(null, "Add Salary", 15, "Low");
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Salary", 15, "Low");
        });
      }
    },

    // delete old income
    async Deleteoldarrayforcode() {
      let self = this;
      let temparr = [{ income_ID: null}];
      for (let i = 0; i < self.Newincomelist.length; i++) 
      {
        if (i == 0) 
        {
          temparr[i].income_ID = self.Newincomelist[i].income_ID;
        } 
        else 
        {
          temparr.push({
            income_ID: self.Newincomelist[i].income_ID,
          });
        }
      }
      await axios.post(`${self.url}Incometype/DeleteOldIncome`, temparr)
      .then(function (response) {
        if (response.data.status == 0) 
        {
          // TODO document why this block is empty
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    //delete old income
    async DeleteOldArray() {
      let self = this;
      let temparr = [{income_ID: null}];
      for (let i = 0; i < self.IncomeList.length; i++) 
      {
        if (i == 0) 
        {
          temparr[i].income_ID = self.IncomeList[i].income_ID;
        }
        else
        {
          temparr.push({
            income_ID: self.IncomeList[i].income_ID,
          });
        }
      }
      await axios.post(`${self.url}Incometype/DeleteOldIncome`, temparr)
      .then(function (response) {
        if (response.data.status == 0) {
          // TODO document why this block is empty
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    // edit salary with employee code
    EditSalaryWithCode() {
      let self = this;
      if(!self.validateincome())
      {
        return false;
      }
      if (self.validatelist.length == 0) 
      {
        let myvalidate = self.validatesalary();
        if (myvalidate == true) 
        {
          self.LoadingDialog = true;
          let tempedit = {
            salary_Amount: self.removecomma(self.addsalary.salary),
            payment_Type: self.addsalary.paymenttype,
            bank_ID: self.addsalary.bankname,
            bank_Acc_Num: self.addsalary.banknum,
            employee_ID: localStorage.empcode,
            acitve: true,
            totalFixedIncome: self.removecomma(self.total),
            totalIncome_Tax: self.removecomma(self.totalforcalculatetax.toString()),
            employmentTypeID: self.EmploymentType == "Daily Payment" ? 2 : ( self.EmploymentType == "Monthly Payment" ? 1 : null ),
            company_ID: store.state.companyID,
            updateEmployee_ID: store.state.employeeID,
          };
          axios.post(`${self.url}Salary/UpdatenewSalary`, tempedit)
          .then(async function (response) {
              if (response.data.status == 0) 
              {
                self.msgg = response.data.message;
                await self.editEMPPersonalAllowance();
                await self.GetaddedDATAwithcode();               
                localStorage.mysalary = self.removecomma(self.total);
                await self.GetSalaryWithCode(1);
                await self.EditNewIncome();
                self.reseterror();
                self.LoadingDialog = false;
               // alert(response.data.message);
              }
            });
        }
        else
        {
          alert("Please fill all the required fields");
          self.validateerror();
        }
      }
      else
      {
        alert("Please fill in the additional income type");
        self.validatelist = [];
      }
    },

    // edit salary with route employee iD
    EditSalary() {
      let self = this;
      if(!self.validateincome())
      {
        return false;
      }
      if (self.validatelist.length == 0) 
      {
        let myvalidate = self.validatesalary();
        if (myvalidate == true) 
        {
          self.LoadingDialog = true;
          let tempedit = {
            salary_Amount: self.removecomma(self.addsalary.salary),
            payment_Type: self.addsalary.paymenttype,
            bank_ID: self.addsalary.bankname,
            totalFixedIncome: self.removecomma(self.total),
            totalIncome_Tax: self.removecomma(self.totalforcalculatetax.toString()),
            bank_Acc_Num: self.addsalary.banknum,
            employmentTypeID: self.EmploymentType == "Daily Payment" ? 2 : ( self.EmploymentType == "Monthly Payment" ? 1 : null ),
            employee_ID: parseInt(self.$route.params.id),
            acitve: true,
            updateEmployee_ID: store.state.employeeID,
          };
          // alert("Stop");
          axios.post(`${self.url}Salary/UpdateSalarywithSID`, tempedit)
          .then(async function (response) {
            if (response.data.status == 0) 
            {
              // alert("Stop0");
              self.msgg = response.data.message;
              localStorage.setItem("locktab", "2nd");
              await self.editEMPPersonalAllowance();
              await self.GetSalary(1);
              await self.EditIncome();
              self.reseterror();
              self.showedit = true;
              self.disableall = true;
              self.showsavewithid = false;
              self.showsavewithsid = false;
              self.showsavewithcode = false;
              self.showbyid = false;
              self.showbysid = false;
              self.showbycode = false;
              self.LoadingDialog = false;
              
            }
            else
            {
              self.LoadingDialog = false;
              alert(response.data.message);
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Salary Fail", 15, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
        }
        else
        {
          alert("Please fill all the required fields");
          self.validateerror();
          self.LoadingDialog = false;
        }
      }
      else
      {
        alert("Please fill in the additional income type");
        self.validatelist = [];
        self.LoadingDialog = false;
      }
    },

    // edit salary with session employee iD
    EditSalaryWithSid() {
      let self = this;
      if(!self.validateincome())
      {
        return false;
      }
      if (self.validatelist.length == 0) 
      {
        let myvalidate = self.validatesalary();
        if (myvalidate == true) 
        {
          self.LoadingDialog = true;
          let tempedit = {
            salary_Amount: self.removecomma(self.addsalary.salary),
            payment_Type: self.addsalary.paymenttype,
            bank_ID: self.addsalary.bankname,
            bank_Acc_Num: self.addsalary.banknum,
            totalFixedIncome: self.removecomma(self.total),
            totalIncome_Tax: self.removecomma(self.totalforcalculatetax.toString()),
            employmentTypeID: self.EmploymentType == "Daily Payment" ? 2 : ( self.EmploymentType == "Monthly Payment" ? 1 : null ),
            employee_ID: store.state.employeeID,
            acitve: true,
            updateEmployee_ID: store.state.employeeID,
          };
          axios.post(`${self.url}Salary/UpdateSalarywithSID`, tempedit)
          .then(async function (response) {
            if (response.data.status == 0) 
            {
              self.msgg = response.data.message;
              await self.editEMPPersonalAllowance();
              await self.GetSalaryWithSid(1);
              await self.EditIncomeWithSid();
              self.reseterror();
              self.showedit = true;
              self.disableall = true;
              self.showsavewithid = false;
              self.showsavewithsid = false;
              self.showsavewithcode = false;
              self.showbyid = false;
              self.showbysid = false;
              self.showbycode = false;
              //alert(response.data.message);
              self.LoadingDialog = false;
              //self.LogTrace(null, "Update Salary", 15, "Medium");
            }
            else
            {
              self.LoadingDialog = false;
              alert(response.data.status);
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Salary Fail", 15, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
        } 
        else 
        {
          alert("Please fill all the required fields");
          self.validateerror();
          self.LoadingDialog = false;
        }
      } 
      else 
      {
        alert("Please fill in the additional income type");
        self.validatelist = [];
        self.LoadingDialog = false;
      }
    },

    // edit income with employee iD
    async EditIncome() {
    let self = this;
      // alert("stop1");
  if (self.IncomeList.length != 0) {
    self.DeleteOldArray();
  }

  if (self.items.length != 0) {
    // alert("stop2");
    await axios.post(`${self.url}Incometype/UpadateIncomebycode`, 
      self.items.map(item => ({
        income_Type_ID: item.income_Type,
        amount: self.removecomma(item.amount),
        employee_ID: parseInt(self.$route.params.id),
      }))
    )
    .then(function (response) {
      if (response.data.status == 0) {
        // alert("stop3");
        self.items = [];
        self.item2 = [];
        if (self.items.length == 0 && self.item2.length == 0) {
          self.GetIncome();
          alert(self.msgg);
        }
      }
    });
  }
},

    // edit income with session employee iD
    async EditIncomeWithSid() {
      let self = this;
      let combileArray1 = [];
      if (self.IncomeList.length != 0) 
      {
        self.DeleteOldArray();
      }
      if (self.items.length != 0) 
      {
        for (let i = 0; i < self.items.length; i++) 
        {
          self.tempimport = {
            income_Type_ID: self.items[i].income_Type,
            amount: self.removecomma(self.items[i].amount),
            employee_ID: store.state.employeeID,
          };
          combileArray1.push(self.tempimport);
        }
        await axios.post(`${self.url}Incometype/UpadateIncomebycode`, combileArray1)
        .then(function (response) {
          if (response.data.status == 0) 
          {
            self.items = [];
            if (self.items.length == 0) 
            {
              //setTimeout(function () {
                self.GetIncomeWithSid();
                alert(self.msgg);
              //}, 2000);
            }
          }
        });
      }
    },

    // edit new income
    async EditNewIncome() {
      let self = this;
      let combileArray1 = [];
      if (self.Newsalarylist.length != 0) 
      {
        self.Deleteoldarrayforcode();
      }
      if (self.items.length != 0) 
      {
        for (let i = 0; i < self.items.length; i++) 
        {
          self.tempimport = {
            income_Type_ID: self.items[i].income_Type,
            amount: self.removecomma(self.items[i].amount),
            employee_ID: localStorage.empcode,
          };
          combileArray1.push(self.tempimport);
        }
        await axios.post(`${self.url}Incometype/UpdateIncomeAll`, combileArray1)
        .then(function (response) {
          if (response.data.status == 0) 
          {
            self.items = [];
            self.item2 = [];
            if (self.items.length == 0 && self.item2.length == 0) 
            {
              //setTimeout(function () {
                self.GetIncomeWithCode();
                alert(self.msgg);
              //}, 2000);
            }
          }
        });
      }
    },

    // remove each income
    removeitem(itemId) {
      let self = this;
      let indexToRemove = self.items.findIndex(item => item.id === itemId);
      if (indexToRemove !== -1) 
      {
        self.items.splice(indexToRemove, 1);
        if (self.items.length > 0) 
        {
          self.items[0].value1 = true; 
        }
      }
      self.addall();
    },

    // remove each income
    removeitem2() {
      let self = this;
      self.item2.splice(self.item2.length - 1, 1);
      self.item2[self.item2.length - 1].value2 = true;
      self.item2[self.item2.length - 1].value3 = true;
      for (let i = 0; i < this.item2.length; i++) 
      {
        if (self.item2.length == 1) 
        {
          this.item2[i].value2 = true;
          this.item2[i].value3 = false;
        }
      }
      self.addall();
    },

    // remove each income
    validateerror() {
      let self = this;
      this.error.errorMessageIncome = !this.addsalary.salary ? "Salary is required" : "";
      this.error.errorMessagePayment = !this.addsalary.paymenttype ? "Payment type is required" : "";
      this.error.empType = !this.EmploymentType ? "Employment Type is required" : "";

      if (this.addsalary.paymenttype == "" || self.addsalary.paymenttype == "Bank Transfer") 
      {
        let bankvalid = self.bankvali(self.addsalary.banknum);
        this.error.errorMessageBank = !this.addsalary.bankname ? "Bank name is required" : "";
        this.error.errorMessageBanknum = !this.addsalary.banknum ? "Bank account number is required" : "";
        if (this.addsalary.banknum != "" && this.addsalary.banknum != null) 
        {
          if (bankvalid != true) 
          {
            this.error.errorMessageBanknum = "Please fill 10 digits bank account number";
          } 
          else 
          {
            this.error.errorMessageBanknum = "";
          }
        }
      } 
      else 
      {
        this.error.errorMessageBank = "";
        this.error.errorMessageBanknum = "";
      }
    },

    reseterror() {
      let self = this;
      self.error.errorMessageIncome = "";
      self.error.errorMessagePayment = "";
      self.error.errorMessageBank = "";
      self.error.errorMessageBanknum = "";
    },

    bankvali(bank) {
      if (store.state.country == "Thailand" || store.state.country == "Thai" || store.state.country == "ไทย" || store.state.country == "ประเทศไทย") 
      {
        let taxpay = /^\d{10}$/;
        if (taxpay.test(bank))
        {
          return true;
        } 
        else 
        {
          return "Invalid Tax format";
        }
      } 
      else 
      {
        return true;
      }
    },

    validatesalary() {
      let self = this;
      if (self.addsalary.paymenttype == "Bank Transfer") 
      {
        let bankvalid = self.bankvali(self.addsalary.banknum);
        return !!(bankvalid == true &&
          self.addsalary.salary != "" &&
          self.addsalary.salary != null &&
          self.addsalary.paymenttype != "" &&
          self.addsalary.paymenttype != null &&
          self.addsalary.bankname != "" &&
          self.addsalary.banknum != null &&
          self.addsalary.banknum != ""&&
          self.EmploymentType != null &&
          self.EmploymentType != ""
          );
      }
      else if (
          self.addsalary.salary != "" &&
          self.addsalary.salary != null &&
          self.addsalary.paymenttype != "" &&
          self.addsalary.paymenttype != null &&
          self.EmploymentType != null &&
          self.EmploymentType != ""
        ) 
      {
        return true;
      } 
      else 
      {
        return false;
      }
    },

    async editEMPPersonalAllowance() {
      let self = this;
      let tempedit = {};
      if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/Salary") 
      {
        tempedit = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          user_ID: parseInt(sessionStorage.getItem("userid")),
        };
      }
      else if (self.$route.params.id != undefined || self.$route.params.id != null)
      {
        tempedit = {
          company_ID: store.state.companyID,
          employee_ID: parseInt(self.$route.params.id),
          user_ID: parseInt(sessionStorage.getItem("userid")),
        };
      }
      else
      {
        tempedit = {
          company_ID: store.state.companyID,
          employee_Code: localStorage.empcode,
          user_ID: parseInt(sessionStorage.getItem("userid")),
        };
      }
      await axios.post(`${self.url}Emp_PersonalAllowance/EditEMP_Personalallowance`,tempedit)
      .then(function () {
        let temp = {
          employee_ID: localStorage.empcode,
        };
        axios.post(`${self.url}Emp_PersonalAllowance/GetEmpallowancewithCode`,temp)
        .then(function (response) {
          let TempData = response.data.data;
          let forCal = 0;
          if (TempData.length != 0) 
          {
            for (let i = 0; i < TempData.length; i++) 
            {
              forCal = parseFloat(forCal) + parseFloat(TempData[i].pA_Amount);
            }
            if (forCal.toFixed(2)) 
            {
              localStorage.setItem("PerAllowance", forCal.toFixed(2));
            } 
            else 
            {
              localStorage.setItem("PerAllowance", forCal);
            }
          }
        });
      });
    },

    async GetaddedDATAwithcode() {
      let self = this;
      let temp = {
        employee_ID: localStorage.empcode,
      };
      await axios.post(`${self.url}Emp_PersonalAllowance/GetEmpallowancewithCode`, temp)
      .then(function (response) {
        let TempData = response.data.data;
        let forCal = 0;
        if (TempData.length != 0)
        {
          for (let i = 0; i < TempData.length; i++)
          {
            forCal = parseFloat(forCal) + parseFloat(TempData[i].pA_Amount);
          }
          if (forCal.toFixed(2))
          {
            localStorage.setItem("PerAllowance", forCal.toFixed(2));
          }
          else
          {
            localStorage.setItem("PerAllowance", forCal);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
@media (min-width: 1600px) {
  .income {
    margin-left: -3.8rem !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {
  .income {
    margin-left: -2.5rem !important;
  }
}

::v-deep .theme--light.v-select .v-select__selection--comma {
  color: #8a8c8e !important;
}

::v-deep .theme--light.v-input input,
.theme--light.v-input textarea {
  color: #8a8c8e !important;
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .btn_edit {
    margin-left: -1rem !important;
  }
}

::v-deep .v-select__selections {
  padding-left: unset !important;
}

@media only screen and (max-width: 415px) {
  .save_btn {
    margin-top: 1rem !important;
    margin-left: 1.5rem !important;
  }

  .cancle_btn {
    margin-left: 1.5rem !important;
  }
}

.empID {
  font-size: 13px;
}

body {
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
}

h1,
.muted {
  color: #2c3e5099;
}

h1 {
  font-size: 27px;
  font-weight: 600;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -moz-text-size-adjust: none;
}

#apptt {
  max-width: 30em;
  margin: 1em auto;
}

#apptt .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

#apptt .dropdown li:last-child {
  border-bottom: none;
}

#apptt .dropdown li a {
  padding: 10px 20px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 1.25em;
}

#apptt .dropdown li a .fa {
  padding-right: 0.5em;
}

::v-deep .mdi:before,
.mdi-set {
  color: #8a8c8e;

  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::v-deep .theme--light.v-label {
  font-family: "Kanit";
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
}


::v-deep .theme--light.v-select .v-select__selections {
  color: rgb(138, 140, 142);
  min-height: 10px;

}

.image-container {
    position: relative;
    /* Set dimensions for the container */
}

.hover-image {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition effect */
}

.image-container:hover .hover-image {
    opacity: 1; /* Show on hover */
}

.btn-icon--active {
  box-shadow: none;
}

</style>
