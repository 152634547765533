<template>
    <div style="margin-left: 1.5rem; margin-right: 1.5rem">
        <div style="display:flex;">
            <p class="headtext txt-header">
                {{ $t("Dashboard") }}
            </p>
        </div>
        <v-row class="mb-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="2">
                <v-card class="firstrowcard">
                    <p class="ml-3 mb-0 mt-3">
                        {{ $t("AllEmployees") }}
                    </p>
                    <v-divider class="ml-3 mr-3 mt-2"></v-divider>
                    <v-container>
                        <v-row>
                            <v-col align="center" cols="12">
                                <p style="font-size: 48px;" class="pt-4 mb-0">
                                    {{ TotalEmp }}
                                </p>
                                <p class="employee-text">{{ $t("people") }}</p>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div class="date-container">
                        <p class="fontofastoday">{{ $t("Date as of ") }}
                            <span v-show="!thethaihead"> {{ AsTodayTxt }} </span>
                            <span v-show="thethaihead"> {{ AsTodayTxtTH }} </span>
                        </p>
                    </div>
                </v-card>
            </v-col>
            <v-col class="px-sm-and-up-0" cols="12" xs="12" sm="12" md="12" lg="10">
                <v-card class="firstrowcard1">
                    <p class="ml-3 mb-0 pt-3">
                        {{ $t("Proportionofemployees") }}
                    </p>
                    <v-divider class="ml-3 mr-3 mt-2"></v-divider>
                    <v-row class="mt-3">
                        <v-col cols="12" xs="12" sm="6" md="6" lg="3" align="center">
                            <div class="chart-wrapper">
                                <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="3">
                            <div class="card proportion-employees">
                                <v-row>
                                    <p class="ml-5">
                                        {{ $t("Full Time") }}
                                    </p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ $t("frontPeople") }} {{ FullTimeEmp }}
                                        {{ $t("backPeople") }}
                                    </p>
                                </v-row>
                                <v-row>
                                    <p class="ml-5">
                                        {{ $t("daily") }}</p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ $t("frontPeople") }} {{ DailyEmp }}
                                        {{ $t("backPeople") }}
                                    </p>
                                </v-row>
                                <v-row>
                                    <p class="ml-5">
                                        {{ $t("Part-time") }}</p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ $t("frontPeople") }} {{ PartTimeEmp }}
                                        {{ $t("backPeople") }}
                                    </p>
                                </v-row>
                                <v-row>
                                    <p class="ml-5">
                                        {{ $t("Contract") }}</p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ $t("frontPeople") }} {{ ContractEmp }}
                                        {{ $t("backPeople") }}
                                    </p>
                                </v-row>
                                <v-row>
                                    <p class="ml-5">
                                        {{ $t("Fixed Pay") }}</p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ $t("frontPeople") }} {{ FixedPayEmp }}
                                        {{ $t("backPeople") }}
                                    </p>
                                </v-row>

                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="employee-status-container">
                            <v-row>
                                <!-- New Employees and Resigns -->
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="new-employees">
                                    <p class="employee-text">{{ $t("NewEmployees") }}</p>
                                    <p class="employee-number pt-2">
                                        {{ NewEmp }}
                                    </p>
                                    <p class="employee-text">
                                        {{ $t("people") }}
                                    </p>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="resigns">
                                    <p class="employee-text">{{ $t("EmployeeResigns") }}</p>
                                    <p class="employee-number  pt-2">
                                        {{ QuitEmp }}
                                    </p>
                                    <p class="employee-text">
                                        {{ $t("people") }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <div class="date-container ">
                                <p class="mb-0 fontofastoday">
                                    {{ $t("Date as of ") }}
                                    <span v-show="!thethaihead"> {{ AsTodayTxt }} </span>
                                    <span v-show="thethaihead"> {{ AsTodayTxtTH }} </span>
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <div>
            <Attendance></Attendance>
        </div>
        <div>
            <EmpExpense></EmpExpense>
        </div>

        <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
    </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import LoadingDialog from "@/components/Loading.vue";
import Attendance from "@/views/Dashboard/Attendance.vue";
import EmpExpense from "@/views/Dashboard/EmpExpense.vue";
import enurl from "@/api/environment";
import store from "@/store";
import Axios from "axios";
export default {
    name: 'ApexDonutChart',
    components: {
        apexchart: VueApexCharts,
        Attendance,
        EmpExpense,
        LoadingDialog
    },
    data() {
        return {
            url: enurl.apiUrl,
            LoadingDialog: false,
            TotalEmp: 0,
            NewEmp: 0,
            QuitEmp: 0,
            FullTimeEmp: 0,
            DailyEmp: 0,
            PartTimeEmp: 0,
            ContractEmp: 0,
            FixedPayEmp: 0,
            AsTodayTxt: "",
            AsTodayTxtTH: "",
            monthNames: [
                { val: 1, txt: "January" },
                { val: 2, txt: "February" },
                { val: 3, txt: "March" },
                { val: 4, txt: "April" },
                { val: 5, txt: "May" },
                { val: 6, txt: "June" },
                { val: 7, txt: "July" },
                { val: 8, txt: "August" },
                { val: 9, txt: "September" },
                { val: 10, txt: "October" },
                { val: 11, txt: "November" },
                { val: 12, txt: "December" },
            ],
            monthNamesTH: [
                { val: 1, txt: "มกราคม" },
                { val: 2, txt: "กุมภาพันธ์" },
                { val: 3, txt: "มีนาคม" },
                { val: 4, txt: "เมษายน" },
                { val: 5, txt: "พฤษภาคม" },
                { val: 6, txt: "มิถุนายน" },
                { val: 7, txt: "กรกฎาคม" },
                { val: 8, txt: "สิงหาคม" },
                { val: 9, txt: "กันยายน" },
                { val: 10, txt: "ตุลาคม" },
                { val: 11, txt: "พฤศจิกายน" },
                { val: 12, txt: "ธันวาคม" },
            ],
            thelang: "",
            chartOptions: {
                labels: ['Full Time', 'Daily', 'Part-time', 'Contract', 'Fixed Pay'],
                chart: {
                    type: 'pie',
                    width: 200,
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false,
                },
                colors: ['#f4a261', '#e9c46a','#2a9d8f', '#e76f51','#ffcd56',],

            }
        };
    },
    mounted() {
        this.GetSortDate();
        //this.shuffleArray(this.chartOptions.colors);
        this.GetProportion();
        this.timerInterval = setInterval(() => {
            this.thelang = localStorage.getItem('Templang')
        }, 100);
    },
    computed: {
        series() {
            return [this.FullTimeEmp, this.DailyEmp, this.PartTimeEmp, this.ContractEmp, this.FixedPayEmp];
        },
        thethaihead() {
            return this.whatthehead();
        },
    },
    methods: {
        whatthehead() {
            if (this.thelang == "ประเทศไทย") {
                return true;
            } else {
                return false;
            }
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        },
        GetSortDate() {
            let self = this;
            let todate = new Date();
            var realmonth = todate.getMonth() + 1;
            self.AsTodayTxt =
                self.monthNames[todate.getMonth(realmonth)].txt + " " + todate.getFullYear();
            self.AsTodayTxtTH =
                self.monthNamesTH[todate.getMonth(realmonth)].txt + " " + (todate.getFullYear());
        },
        GetProportion() {
            let self = this;
            let temp = {
                company_ID: store.state.companyID,
            };
            Axios
                .post(`${self.url}Dashboard/GetEmployeeProportion`, temp)
                .then(function (response) {
                    if (response.data.status == 0) {
                        let result = response.data.data;
                        self.TotalEmp = result.total_Staff;
                        self.NewEmp = result.new_Total_Staff;
                        self.QuitEmp = result.fired_Total_Staff;
                        self.FullTimeEmp = result.fullTime_Total;
                        self.DailyEmp = result.daily_Total;
                        self.PartTimeEmp = result.partTime_Total;
                        self.ContractEmp = result.contract_Total;
                        self.FixedPayEmp = result.fixedPay_Total;
                    }
                })
                .catch(function (error) {
                    alert(error);
                });
        },
    },
    watch: {
        thelang() {
            this.whatthehead();
        },
    },
};
</script>
<style scoped>
.firstrowcard {
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Ensures elements are spaced appropriately */
    overflow-x: hidden;
    overflow-y: scroll;
    /* Enables scrolling */
    -ms-overflow-style: none;
    /* Hides scrollbar in Internet Explorer and Edge */
    scrollbar-width: none;
    /* Hides scrollbar in Firefox */
    position: relative;
}

.firstrowcard1 {
    min-height: 260px;
    /* padding-top: 0px; */
}

.firstrowcard::-webkit-scrollbar {
    display: none;
    /* Hides scrollbar in Chrome, Safari and Opera */
}

.date-container {
    margin-top: auto;
    padding: 0 16px;
    /* Adjust as needed to align with the card's padding */
    text-align: center;
}

.new-employees,
.resigns {
    text-align: center;
}

.employee-status-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Ensures that the content is spread, and the date is at the bottom */

}

.chart-wrapper {
    width: 250px;
    /* Set the fixed width for the wrapper */
    /* height: 360px; Set the fixed height for the wrapper */
    overflow: hidden;
    /* Ensure no overflow */
    /* transform: scale(0.5); /* Scale the chart down to 50% of its original size */
    /* transform-origin: top left; Ensure the scaling originates from the top-left corner */
}

.fontofastoday {
    color: #716E6E;
}

.employee-number {
    font-size: 40px;
    margin: 0;
    font-weight: lighter;
}

.employee-text {
    font-size: 20px;
    margin: 0;
    font-weight: lighter;
}
</style>