<template>
  <div style="margin-left: 1.5rem;margin-right: 1.5rem;">
    <p class="txt-header">
      {{ $t("Permissionmng") }}
    </p>
    <v-tabs
      class="mt-6"
      grey--text
      background-color="#f0f8fc"
      slider-color="#F99D20"
      v-model="activeTab"
      show-arrows
    >
      <v-tab
        class="text-capitalize"
        style="
          border-radius: 10px 10px 0px 5px;
          width: 150px;
          margin-right: 3px;
          font-size: 16px;
        "
        v-for="tab in tabs"
        :key="tab.id"
        :to="tab.route"
        v-show="tab.showthis"
        exact
        >{{
          tab.name == "Usergroup"
            ? $t("UserGroup")
            : tab.name == "Permission"
            ? $t("Permission")
            : $t("Role")
        }}</v-tab
      >
    </v-tabs>
    <v-card style="margin-bottom: 5rem">
      <router-view></router-view>
    </v-card>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  props: ["id"],
  data() {
    return {
      activeTab: `/usermanagement/${this.id}`,
      showthis: false,
      tabs: [
        {
          id: 1,
          name: "Usergroup",
          route: `/usermanagement/${this.id}`,
          showthis: false,
        },
        {
          id: 2,
          name: "Permission",
          route: `/usermanagement/${this.id}/permission`,
          showthis: false,
        },
        {
          id: 3,
          name: "Role",
          route: `/usermanagement/${this.id}/role`,
          showthis: false,
        },
      ],
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleid = sessionStorage.getItem("roleID");
    if (roleid == "Admin") {
      for (let j = 0; j < this.tabs.length; j++) {
        this.tabs[j].showthis = true;
      }
    }
    // if (perid.some((e) => e == 103)) {
    //   this.tabs[0].showthis = true;
    // }
    if (perid.some((e) => e == 107)) {
      this.tabs[0].showthis = true;
    }
    if (perid.some((e) => e == 111)) {
      this.tabs[1].showthis = true;
    }
    if (perid.some((e) => e == 112)) {
      this.tabs[2].showthis = true;
    }
    if (this.$route.path == "/usermanagement/usergroup") {
      for (let j = 0; j < this.tabs.length; j++) {
        if (this.tabs[j].showthis == true) {
          this.activeTab = this.tabs[j].route;
          this.$router.push(this.tabs[j].route).catch(()=>{}).catch(()=>{});
          break;
        }
      }
    }
  },
};
</script>
<style scoped>
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
}
.active {
  cursor: pointer !important;
}
.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}
>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
</style>