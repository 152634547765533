import firebase from "firebase/app";
import "@firebase/messaging";
import enurl from "./api/environment";
import store from "./store";
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      url: enurl.apiUrl,
      token: null,
      reportID: null,
      tokenList: [],
      noti: false,
    };
  },
  mounted() {
    this.sendNotification();
    this.generateToken();
  },
  methods: {
    async sendNotification() {
      let temp = {
        company_ID: store.state.companyID,
      };

      let token = [];
      await axios
        .post(`${this.url}ExpireCompany/GetFirebaseTokenByCompanyID`, temp)
        .then(function (response) {
          var temp = response.data.data;

          if (temp[0]) {
            temp.map(t => token.push(t.token));
          }
        });
      return token;
    },
    async generateToken() {
      var tempTokenList = await this.sendNotification();

      var firebaseConfig = {
        apiKey: "AIzaSyAI4HkLNx1R3atL7BxB7qwvEKQqjiY8k4Y",
        authDomain: "optimistic-cca96.firebaseapp.com",
        databaseURL: "https://optimistic-cca96.firebaseio.com",
        projectId: "optimistic-cca96",
        storageBucket: "optimistic-cca96.appspot.com",
        messagingSenderId: "116479546535",
        appId: "1:116479546535:web:c6cbe479b93035b75eb782",
        measurementId: "G-0L3GT2B6LB",
      };

      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
      }

      const messaging = firebase.messaging();

      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then(token => {
          this.AddFirebaseToken(token, tempTokenList);
        });
    },

    async AddFirebaseToken(token, tempTokenList) {
      let n = 0;
      await tempTokenList.forEach(element => {
        if (element == token) {
          n = n + 1;
        }
      });

      if (n == 0) {
        let tempLock = {
          company_ID: store.state.companyID,
          token: token,
        };

        axios
          .post(`${this.url}ExpireCompany/AddFirebaseTokenForCompany`, tempLock)
          .then(function () {});
      }
    },
  },
};
