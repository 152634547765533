<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("commonmaster") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
        <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
      </v-btn>
      <p class="ml-4 headtext txt-header">
        {{ $t("incometype1") }}
      </p>
    </div>
    <v-card class="pt-4" style="margin-bottom: 3.5rem; margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-text-field
            :placeholder="$t('Search')"
            v-model.lazy="searchtxt"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.1rem; margin-left:1rem;"
            @keyup.enter="Search()">
          </v-text-field>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="12" md="2" lg="4">
          <text-field autocomplete="off" v-model.lazy="searchtxt" :placeholder="$t('Search')" :textFieldProps="{
            color: '#F99D20',
            dense: false,
          }" style="margin-top: -0.5rem; margin-left: 1rem" />
        </v-col>
        <v-col>
          <v-btn color="#F99D20" @click="Search" class="white--text text-capitalize ml-5 sear" width="120"
            max-height="35">
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-col> -->
        <v-spacer></v-spacer>
        <v-btn color="#F99D20" class="white--text text-capitalize mr-5 mt-2" width="150" max-height="35"
          @click="Adddialog = true" :disabled="permission.add">{{ $t("Add") }}</v-btn>
      </v-row>
      <v-data-table :headers="headers" :items="TypeList" :page.sync="page" :items-per-page="itemsPerPage"
        hide-default-footer hide-default-header @page-count="pageCount = $event" class="ml-8 mr-8 mt-4"
        :mobile-breakpoint="0">
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th style="cursor: pointer; max-width: 180px !important" class="b-text">
                {{ $t("on/off") }}
              </th>
              <th style="cursor: pointer; max-width: 180px !important" class="b-text">
                {{ $t("no") }}
              </th>
              <th style="cursor: pointer; max-width: 180px !important" class="b-text">
                {{ $t("typename") }}
              </th>
              <th style="cursor: pointer; max-width: 180px !important" class="b-text">
                {{ $t("typenameeng") }}
              </th>
              <th style="
                  cursor: pointer;
                  max-width: 180px !important;
                  text-align: center;
                " class="b-text">
                {{ $t("taxcalculate") }}
              </th>
              <th style="
                  cursor: pointer;
                  max-width: 180px !important;
                  text-align: center;
                " class="b-text">
                {{ $t("sscalculate") }}
              </th>
              <th class="ml-5">{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td v-if="item.status" style="border-bottom: 1px solid #fff" @click.stop="changeSwtich(item)">
              <div v-if="item.status">
                <input type="checkbox" v-model="item.status" />
              </div>
            </td>
            <td style="border-bottom: 1px solid #fff" v-else @click.stop="changeSwtich(item)">
              <input type="checkbox" style="cursor: default" v-model="item.status" />
            </td>

            <td style="border-bottom: 1px solid #fff">{{ item.num }}</td>
            <td style="border-bottom: 1px solid #fff">
              {{ item.incomeTypeName_TH }}
            </td>
            <td style="border-bottom: 1px solid #fff">
              {{ item.incomeTypeName_EN }}
            </td>
            <td style="border-bottom: 1px solid #fff; text-align: center">
              <img class="mt-1" style="height: 23px; width: 23px" :src="item.tax_Calculation == true ? rwimages.Right : rwimages.Wrong
                " />
            </td>
            <td style="border-bottom: 1px solid #fff; text-align: center">
              <img class="mt-1" style="height: 23px; width: 23px" :src="item.social_Security_Calculation == true
                ? rwimages.Right
                : rwimages.Wrong
                " />
            </td>
            <td style="border-bottom: 1px solid #fff">
              <v-btn icon @click="EditIType(item)" :disabled="permission.edit">
                <img class="editIcon" :src="images.edit" />
              </v-btn>
              <v-btn icon @click="Deletedia(item.income_Type_ID)" :disabled="permission.delete">
                <img class="deleteIcon" :src="images.delete" />
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div class="text-right" v-if="TypeList.length">
        <PaginationComponent
          :page.sync="page"
          :pageCount="pageCount"
          :itemsPerPage.sync="itemsPerPage"
        />
      </div>
    </v-card>

    <!-- add dialog -->
    <v-dialog v-model="Adddialog" persistent max-width="600px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-5 mr-2" style="font-size: 22px">
          {{ $t("newincometype") }}
        </p>
        <v-card-text class="mb-0 pb-0">
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formAdd" lazy-validation>
              <v-col cols="12">
                <v-text-field autocomplete="off" color="#F99D20" v-model="addingType.incometype" :rules="incometype">
                  <template v-slot:label>
                    <span>
                      {{ $t("typename")
                      }}<span class="red--text"> *</span></span>
                  </template></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field autocomplete="off" color="#F99D20" v-model="addingType.incometypeeng"
                  :rules="incometypeeng">
                  <template v-slot:label>
                    <span>
                      {{ $t("typenameeng")
                      }}<span class="red--text"> *</span></span>
                  </template></v-text-field>
              </v-col>
              <v-row class="ml-3">
                <v-col cols="12" xs="12" sm="12" lg="6" md="6">
                  <v-row>
                    <v-checkbox class="custom-checkbox" color="#F99D20" v-model="addingType.taxcalculate" style="margin: 0"></v-checkbox>
                    <p class="mt-1">{{ $t("taxcalculate") }}</p>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" lg="6" md="6">
                  <v-row>
                    <v-checkbox class="custom-checkbox" color="#F99D20" v-model="addingType.socialcalculate" style="margin: 0"></v-checkbox>
                    <p class="mt-1">{{ $t("sscalculate") }}</p>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="CancelAdd()">
            {{ $t("Cancel") }}</v-btn>
          <v-btn @click="AddType" class="mr-2 text-capitalize add commonsavebtn">
            {{ $t("Add") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="Editdialog" persistent max-width="600px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-5 mr-2" style="font-size: 22px">
          {{ $t("editincometype") }}
        </p>
        <v-card-text class="mb-0 pb-0">
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formEdit" lazy-validation>
              <v-col cols="12">
                <v-text-field autocomplete="off" color="#F99D20" v-model="editType.incometype" :rules="incometypeEdit">
                  <template v-slot:label>
                    <span>
                      {{ $t("typename")
                      }}<span class="red--text"> *</span></span>
                  </template></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field autocomplete="off" color="#F99D20" v-model="editType.incometypeeng"
                  :rules="incometypeengEdit">
                  <template v-slot:label>
                    <span>
                      {{ $t("typenameeng")
                      }}<span class="red--text"> *</span></span>
                  </template></v-text-field>
              </v-col>
              <v-row class="ml-3">
                <v-col cols="12" xs="12" sm="12" lg="6" md="6">
                  <v-row>
                    <v-checkbox class="custom-checkbox" color="#F99D20" v-model="editType.taxcalculate" style="margin: 0"></v-checkbox>
                    <p class="mt-1">{{ $t("taxcalculate") }}</p>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" lg="6" md="6">
                  <v-row>
                    <v-checkbox class="custom-checkbox" color="#F99D20" v-model="editType.socialcalculate" style="margin: 0"></v-checkbox>
                    <p class="mt-1">{{ $t("sscalculate") }}</p>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="CancelUpdate()">
            {{ $t("Cancel") }}</v-btn>
          <v-btn @click="UpdateType" class="mr-2 text-capitalize add commonsavebtn">
            {{ $t("Update") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" @click="Deletedialog = false" text>{{
            $t("cancel") }}</v-btn>
          <v-btn class="mr-2 commondeletebtn text-capitalize add"
            @click="DeleteInType">{{ $t("delete") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

    <!-- Success Dialog -->
    <v-dialog v-model="StatusDialog" persistent width="290">
      <v-card color="#FFF4EB" dark>
        <div class="d-flex justify-center">
          <img src="@/assets/images/succesmark.png" class=" mt-5" width="40" height="40" />
        </div>
        <v-card-text class="black--text d-flex justify-center ">
          <v-col style="text-align: center;">
            <p>{{ $t("Success") }}</p>
            <p>{{ $t("SaveDataSuccess") }}</p>
            <v-btn @click="StatusDialog = false" color="#F99D20" class="mr-2 text-capitalize add" width="70"
              max-height="35">{{ $t("ok") }}</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, CheckViewAddEditDelete, utils],
  watch: {
    "addingType.incometype"() {
      this.incometype = [];
    },
    "editType.incometype"() {
      this.incometypeEdit = [];
    },
    "addingType.incometypeeng"() {
      this.incometypeeng = [];
    },
    "editType.incometypeeng"() {
      this.incometypeengEdit = [];
    },
    searchtxt: function () {
      this.GetIncomeType();
    },
  },
  data() {
    return {
      StatusDialog: false,
      permission: {
        add: true,
        edit: true,
        delete: true,
      },
      incometype: [],
      incometypeEdit: [],
      incometypeeng: [],
      incometypeengEdit: [],
      url: enurl.apiUrl,
      companyid: store.state.company_ID,
      LoadingDialog: false,
      page: 1,
      pageCount: 10,
      itemsPerPage: 10,
      // items: [10, 20, 30],
      Incometype_ID: 0,
      Adddialog: false,
      Editdialog: false,
      Deletedialog: false,
      searchtxt: "",
      serachlength: 0,
      No: null,
      Income_Type: null,
      addingType: {
        incometype: null,
        incometypeeng: null,
        taxcalculate: false,
        socialcalculate: false,
      },
      editType: {
        incometype_ID: null,
        incometype: null,
        incometypeeng: null,
        taxcalculate: false,
        socialcalculate: false,
      },
      rwimages: {
        Right: require("@/assets/images/Group 8356.png"),
        Wrong: require("@/assets/images/Group 8357.png"),
      },
      headers: [
        {
          text: "On/Off",
          value: "Status",
          align: "left",
          sortable: false,
        },
        {
          text: "No",
          value: "num",
          align: "left",
          sortable: false,
        },
        {
          text: "Type Name",
          value: "incomeTypeName_TH",
          align: "left",
          sortable: false,
        },
        {
          text: "Type Name (Eng)",
          value: "incomeTypeName_EN",
          align: "left",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actionadd",
          align: "right",
          sortable: false,
        },
      ],
      TypeList: [],
    };
  },

  mounted() {
    let self = this;
    self.GetIncomeType();
    self.permission = self.CheckViewAddEditDelete(168, 169, 170, 171);
    //self.permission = self.CheckViewAddEditDeleteUpload(79, 80, 81, 82, 83);
  },

  computed: {
    images() {
      return this.$store.state.images;
    }
  },

  methods: {
    async changeSwtich(item) {
      let self = this;
      self.LoadingDialog = true;
      item.status = !item.status;
      let temp = {
        income_Type_ID: item.income_Type_ID,
        status: item.status,
        company_ID: store.state.companyID
      };
      await axios.post(`${self.url}IncomeType/UpdateIncome_Type_Status`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          self.LoadingDialog = false;
          self.StatusDialog = true;
          self.GetIncomeType();
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Update Income Type Fail", 56, "Medium");
        alert(error + "Correlation ID is 56");
      });
    },
    GetIndex(val) {
      let self = this;
      let data = self.TypeList.indexOf(val);
      data++;
      return data;
    },
    ValidateType() {
      let self = this;
      let result;
      let Filter = self.TypeList.filter((data) => data.incomeTypeName_TH);
      let FilterEng = self.TypeList.filter((data) => data.incomeTypeName_EN);
      for (let i = 0; i < Filter.length; i++) {
        if (
          Filter[i].incomeTypeName_TH.toLowerCase() ==
          self.addingType.incometype.toLowerCase() && FilterEng[i].incomeTypeName_EN.toLowerCase() ==
          self.addingType.incometypeeng.toLowerCase()
        ) {
          alert("Income Tpye already exists");
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    EditValidateType() {
      let self = this;
      let result;
      let Filter = self.TypeList.filter(
        (data) => data.income_Type_ID != self.editType.incometype_ID
      );
      for (let i = 0; i < Filter.length; i++) {
        if (
          Filter[i].incomeTypeName_TH.toLowerCase() ==
          self.editType.incometype.toLowerCase()
        ) {
          alert("Income Tpye already exists");
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    sortFun(rowName) {
      let self = this;
      let keyName = ["Income_Type", "No"];
      if (rowName == "Income_Type") {
        self.TypeList = self.TypeList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.TypeList = self.TypeList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.incometype = null;
      this.incometypeEdit = null;
      this.incometypeeng = null;
      this.incometypeengEdit = null;
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.TypeList;
        self.TypeList = [];
        let temp = List.filter(
          (v) =>
            v.incomeTypeName_EN
              .toLowerCase()
              .includes(self.searchtxt.toLowerCase()) ||
            v.num.includes(self.searchtxt)||
            v.incomeTypeName_TH.toLowerCase()
            .includes(self.searchtxt.toLowerCase())
        );

        for (let i = 0; i < temp.length; i++) {
          self.TypeList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetIncomeType();
      }
    },
    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => { });
    },
    GetIncomeType() {
      let self = this;
      if (self.serachlength == 0 || self.serachlength != self.TypeList.length) {
        self.TypeList = [];
        self.LoadingDialog = true;
        let temp = { company_ID: store.state.companyID };
        axios
          .post(`${self.url}IncomeType/GetIncomeTypeByCompanyID`, temp)
          .then(function (response) {
            let list = response.data.data;
            self.onOff
            let numl = 1;
            for (let i = 0; i < list.length; i++) {
              let temp = {
                ...list[i],
                num: JSON.stringify(numl++),
              };
              self.TypeList.push(temp);
            }
            self.serachlength = self.TypeList.length;
            self.LoadingDialog = false;
          });
        // .catch(function (error) {
        //   self.LogTrace(error, "Get Income Type Fail", 56, "Critical");
        // });
      }
    },
    CancelAdd() {
      let self = this;
      self.incometype = [];
      self.$refs.formAdd.reset();
      self.Adddialog = false;
    },
    CancelUpdate() {
      let self = this;
      self.incometypeEdit = [];
      self.$refs.formEdit.reset();
      self.Editdialog = false;
    },
    AddType() {
      let self = this;
      self.LoadingDialog = true;
      self.incometype = [(v) => !!v || this.$t("IncomeTypeReq")];
      self.incometypeeng = [(v) => !!v || this.$t("IncomeTypeReq")];
      let tempvalidate = self.$refs.formAdd.validate();
      if (
        tempvalidate == true &&
        self.addingType.incometype != null &&
        self.addingType.incometype != "" &&
        self.addingType.incometypeeng != null &&
        self.addingType.incometypeeng != ""
      ) {
        let tempvali = self.ValidateType();
        if (tempvali == true) {
          let tempdept = {
            incomeTypeName_TH: self.addingType.incometype,
            incomeTypeName_EN: self.addingType.incometypeeng,
            status: true,
            tax_Calculation: self.addingType.taxcalculate,
            social_Security_Calculation: self.addingType.socialcalculate,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}IncomeType/AddIncomeType`, tempdept)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.addingType.incometype = null;
                self.addingType.incometypeeng = null;
                self.addingType.taxcalculate = false;
                self.addingType.socialcalculate = false;
                self.Adddialog = false;
                self.LoadingDialog = false;
                self.serachlength = 0;
                self.GetIncomeType();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add Income Type Fail", 56, "Low");
              alert(error + "Correlation ID is 56");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        alert(this.$t("Requirefield"));
        self.LoadingDialog = false;
      }
    },
    EditIType(temp) {
      var self = this;
      self.Editdialog = true;
      self.editType.incometype_ID = temp.income_Type_ID;
      self.editType.incometype = temp.incomeTypeName_TH;
      self.editType.incometypeeng = temp.incomeTypeName_EN;
      self.editType.taxcalculate = temp.tax_Calculation;
      self.editType.socialcalculate = temp.social_Security_Calculation;
    },
    UpdateType() {
      let self = this;
      self.LoadingDialog = true;
      self.incometypeEdit = [(v) => !!v || this.$t("IncomeTypeReq")];
      self.incometypeengEdit = [(v) => !!v || this.$t("IncomeTypeReq")];
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.editType.incometype != null &&
        self.editType.incometype != "" &&
        self.editType.incometypeeng != null &&
        self.editType.incometypeeng != ""
      ) {
        let tempvali = self.EditValidateType();
        if (tempvali == true) {
          let temp = {
            income_Type_ID: self.editType.incometype_ID,
            incomeTypeName_TH: self.editType.incometype,
            incomeTypeName_EN: self.editType.incometypeeng,
            tax_Calculation: self.editType.taxcalculate,
            social_Security_Calculation: self.editType.socialcalculate,
            company_ID: store.state.companyID,
            user_ID: store.state.userid,
          };
          axios
            .post(`${self.url}IncomeType/UpdateIncome_Type`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.editType.incometype = null;
                self.editType.incometypeeng = null;
                self.editType.taxcalculate = false;
                self.editType.socialcalculate = false;
                self.Editdialog = false;
                self.LoadingDialog = false;
                self.serachlength = 0;
                self.GetIncomeType();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update Income Type Fail", 56, "Medium");
              alert(error + "Correlation ID is 56");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        alert(this.$t("Requirefield"));
        self.LoadingDialog = false;
      }
    },
    Deletedia(id) {
      this.Incometype_ID = id;
      this.Deletedialog = true;
    },
    DeleteInType() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.Incometype_ID;
      let temp = {
        income_Type_ID: id,
        user_ID: store.state.userid,
      };
      axios
        .post(`${self.url}IncomeType/DeleteIncomeType`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.Deletedialog = false;
            self.LoadingDialog = false;
            self.serachlength = 0;
            self.GetIncomeType();
            self.defaultSort();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Income Type Fail", 56, "High");
          alert(error + "Correlation ID is 56");
          self.LoadingDialog = false;
        });
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 415px) {
  .sear {
    margin-left: -0.1rem !important;
  }

  .add {
    width: 90px !important;
  }
}

@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
  }
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  font-size: 15px;
  font-weight: normal;
  color: #f99d20;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}

@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(circle 12px,
        white 100%,
        transparent calc(100% + 1px)) #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: white;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
  }
}
</style>