<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="margin-bottom: 2rem">
      <v-row>
        <v-btn icon router color="white" @click="back" class="mt-6">
          <img src="@/assets/images/back button.png" class="mb-1" />
        </v-btn>
        <p style="font-size: 25px; margin-left: 8px; margin-top: 19px">
          {{ $t("departmenttransferhistory") }}
        </p>
      </v-row>
    </div>
    <v-card class="pt-5" height="700" style="margin-bottom: 5rem">
      <v-data-table
        style="margin-left: 40px; margin-right: 40px"
        :headers="department"
        :items="depart"
        hide-default-header
        hide-default-footer
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th>{{ $t("predepartment") }}</th>
              <th>{{ $t("predivision") }}</th>
              <th>{{ $t("presection") }}</th>
              <th>{{ $t("PreviousPosition") }}</th>
              <th>{{ $t("dateoftransfer") }}</th>
              <th>{{ $t("hr_incharge") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.department_Name }}</td>
            <td>{{ item.division_Name }}</td>
            <td>{{ item.section_Name }}</td>
            <td>{{ item.position_Name }}</td>
            <td>{{ formatDate(item.transfer_Date) }}</td>
            <td>{{ item.full_Name }}</td>
          </tr>
        </template>
        <!-- <template v-slot:item.transfer_Date="{ item }">{{formatDate(item.transfer_Date)}}</template> -->
      </v-data-table>
    </v-card>
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>

  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace],
  data() {
    return {
      loadingdialog: false,
      url: enurl.apiUrl,
      departID: 0,
      department: [
        {
          text: "Previous Department",
          value: "department_Name",
          sortable: false,
        },
        {
          text: "Previous Division",
          value: "division_Name",
          sortable: false,
        },
        {
          text: "Previous Section",
          value: "section_Name",
          sortable: false,
        },
        {
          text: "Previous Position",
          value: "position_Name",
          sortable: false,
        },
        {
          text: "Date Of Transfer",
          value: "transfer_Date",

          sortable: false,
        },
        {
          text: "HR In-Charge",
          value: "full_Name",
          sortable: false,
        },
      ],
      depart: [],
    };
  },
  mounted() {
    this.GetDeptHistory();
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    GetDeptHistory() {
      let self = this;
      self.departID = self.$route.params.personalid;
      if (self.departID != null || self.departID != undefined) {
        self.loadingdialog = true;
        let tempp = {
          employee_ID: parseInt(self.departID),
          company_ID: store.state.companyID,
        };
        axios
          .post(
            `${self.url}Address/GetDepartmentTransferHistoryByEmployeeID`,
            tempp
          )
          .then(function (response) {
            self.depart = response.data.data;
            //self.LogTrace(null, "Get Department Transfer", 14, "Critical");
            self.loadingdialog = false;
          })
          .catch(function (error) {
            //alert(error);
            self.loadingdialog = false;
            self.LogTrace(
              error,
              "Get Department Transfer Fail",
              14,
              "Critical"
            );
          });
      } else {
        self.loadingdialog = true;
        let tempp = {
          employee_Code: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        axios
          .post(
            `${self.url}Address/GetDepartmentTransferHistoryByEmployeeCode`,
            tempp
          )
          .then(function (response) {
            self.depart = response.data.data;
            //self.LogTrace(null, "Get Department Transfer", 14, "Critical");
            self.loadingdialog = false;
          })
          .catch(function (error) {
            //alert(error);
            self.loadingdialog = false;
            self.LogTrace(
              error,
              "Get Department Transfer Fail",
              14,
              "Critical"
            );
          });
      }
    },
    back() {
      let workim = this.$route.params.id;
      if (this.$route.name == "PersonalDepartmentTransferr1") {
        this.$router.push("/selfservice/PersonalProfile/PersonalInformation").catch(()=>{});
      } else if (workim != undefined && workim != "undefined") {
        this.$router.push(
          `/PersonnelManagement/EditEmployee/${this.$route.params.id}/EditPersonalInformation`
        ).catch(()=>{});
      } else if (this.$route.name == "newdepartmenttransferr") {
        this.$router.push(
          "/PersonnelManagement/NewEmployee/NewPersonalInformation"
        ).catch(()=>{});
      }
    },
  },
};
</script>
<style scoped>
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
.yourButton.v-btn--active::before {
  opacity: 0;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  font-size: 16px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
}
</style>