<template>
  <div>
    <EditUser></EditUser>
  </div>
</template>
<script>
import EditUser from "./AEditUser.vue";
export default {
  components: {
    EditUser,
  },
};
</script>