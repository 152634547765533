<template>
  <div> 
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("commonmaster") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("socialSecurityFund") }}
        </p>
    </div>

    <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 2.5rem;">
      <v-tabs
        grey--text
        background-color="#f0f8fc"
        slider-color="#F99D20"
        v-model="active_tab">
        <v-tab
          key="1"
          v-show="showdefault"
          @click="orginalOne"
          class="text-capitalize"
          style="border-radius: 10px 10px 5px 5px;width: 15%;">
          {{ $t("Social_Security_Rate") }}
        </v-tab>
        <v-tab
          key="2"
          @click="hostab()"
          v-show="showthis"
          class="text-capitalize"
          style="border-radius: 10px 10px 5px 5px;width: 15%;">
          {{ $t("hospital") }}
        </v-tab>
        
        <v-tab-item v-show="showdefault">
          <v-card class="pt-5 pb-5 pl-5 pr-5">
            <v-row no-gutters>
              <v-col cols="12" md="6" lg="6" xl="4" class="px-4">
                <label style="font-size: 16px; color: #7F7F7F;"
                  >{{ $t("LowBaseSalary")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  v-model="social.lowsalary"
                  outlined
                  dense
                  @keypress="onlyNum($event)"
                  :error-messages="socialerror.lowsalary"
                  color="#F99D20"
                  class="socialtextbox"
                >
                  <template v-slot:message="{}">
                    <img src="@/assets/images/alert-circle 1.png" width="13" height="13" alt="requireicon"/>
                    <span>
                      {{ socialerror.lowsalary }}</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="4" class="px-4">
                <label style="font-size: 16px; color: #7F7F7F;"
                  >{{ $t("HighBaseSalary")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  v-model="social.highsalary"
                  outlined
                  dense
                  @keypress="onlyNum($event)"
                  :error-messages="socialerror.highsalary"
                  color="#F99D20"
                  class="socialtextbox"
                >
                  <template v-slot:message="{}">
                    <img src="@/assets/images/alert-circle 1.png" width="13" height="13" alt="requireicon"/>
                    <span>
                      {{ socialerror.highsalary }}</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6" lg="6" xl="4" class="px-4">
                <label style="font-size: 16px; color: #7F7F7F;"
                  >{{ $t("LowSubvention")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  v-model="social.lowsubvention"
                  :error-messages="socialerror.lowsubvention"
                  color="#F99D20"
                  outlined
                  dense
                  readonly
                  class="socialtextbox"
                >
                  <template v-slot:message="{}">
                    <img src="@/assets/images/alert-circle 1.png" width="13" height="13" alt="requireicon"/>
                    <span>
                      {{ socialerror.lowsubvention }}</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="4" class="px-4">
                <label style="font-size: 16px; color: #7F7F7F;"
                  >{{ $t("HighSubvention")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  v-model="social.highsubvention"
                  :error-messages="socialerror.highsubvention"
                  color="#F99D20"
                  outlined
                  dense
                  readonly
                  class="socialtextbox"
                >
                  <template v-slot:message="{}">
                    <img src="@/assets/images/alert-circle 1.png" width="13" height="13" alt="requireicon"/>
                    <span>
                      {{ socialerror.highsubvention }}</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6" lg="6" xl="4" class="px-4">
                <label style="font-size: 16px; color: #7F7F7F;"
                  >{{ $t("InsuranceRate")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  v-model="social.rate"
                  :error-messages="socialerror.rate"
                  color="#F99D20"
                  outlined
                  dense
                  type="number"
                  suffix=" %"
                  class="socialtextbox"
                >
                  <template v-slot:message="{}">
                    <img src="@/assets/images/alert-circle 1.png" width="13" height="13" alt="requireicon"/>
                    <span>
                      {{ socialerror.rate }}</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="px-3">
              <v-card-text class="mx-2" style="background-color: #FFEDEE; display: flex; color:#ff4b55; border-radius: 5px; padding: 8px 16px 8px 16px">
                <img class="mr-1" style="max-height: 24px;" src="@/assets/images/alert-circle 1.png" alt="alerticon">
                <span style="align-self: center;">
                  {{ $t("ContributionrateDeduction") }}
                </span>
              </v-card-text>
            </v-row>
            <v-spacer style="min-height: 80px;"></v-spacer>
            <v-row class="px-3 mb-3" style="align-items: center;">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                {{ $t("modified") }} {{ social.updateDate }}  {{ $t("By") }} {{ social.updateByName }} ( {{ social.updateByRole }} )
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-row class="px-2" style="flex-direction: row-reverse">
                <!-- Save & Cancel -->
                  <v-btn
                    class="pa-2 text-capitalize commonsavebtn" 
                    @click="confirmDialog=!confirmDialog"
                  >
                    {{ $t("save") }}
                  </v-btn>
                  <v-btn
                    class="pa-2 mr-7 text-capitalize commoncancelbtn"
                    outlined
                    text
                    @click="GetSocialSecurityFund()"
                  >
                    {{ $t("cancel") }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <!-- Confirmation Dialog -->
          <v-dialog v-model="confirmDialog" width="34%">
            <v-card style="border-radius: 10px;">
              <v-card-text>
                <br />
                <p class="justify-center ml-7 mt-3" style="font-size: 23px; color: #444444">
                  {{ $t("confirmation") }}
                </p>
                <p class="mt-2 ml-7">
                  {{ $t("informationMessage") }}
                </p>
                <v-card-actions class="mt-3 justify-end">

                  <v-btn outlined text class="text-capitalize commoncancelbtn" @click="confirmDialog=!confirmDialog">
                    {{ $t("Cancel") }}</v-btn>
                  <v-btn class="text-capitalize commonsavebtn" 
                    :disabled="permission.edit"
                    @click="UpdateSocial()">
                    {{ $t("confirm") }}</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- Social Edit Dialog -->
          <v-dialog v-model="SocialEditdialog" persistent max-width="786px">
            <v-card class="d-flex flex-wrap">
              <p
                class="pt-5"
                style="font-size: 23px; margin-left: 2rem; margin-right: 2rem"
              >
                {{ $t("editsocail") }}
              </p>
              <v-card-text class="d-flex flex-wrap">
                <v-container class="mt-2">
                  <v-form ref="SeditForm" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          autocomplete="off"
                          v-model="editsocial.item_name"
                          color="#F89D1F"
                          :rules="item_name"
                          ><template v-slot:label>
                            <span>
                              {{ $t("itemname")
                              }}<span class="red--text"> *</span></span
                            >
                          </template></v-text-field
                        >
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          autocomplete="off"
                          v-model="editsocial.amount"
                          color="#F89D1F"
                          :rules="amount"
                          onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                          ><template v-slot:label>
                            <span>
                              {{ $t("amount")
                              }}<span class="red--text"> *</span></span
                            >
                          </template></v-text-field
                        >
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="effdate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          fluid
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              autocomplete="off"
                              style="margin-top: 0.9rem"
                              v-model="computedDateFormatted6"
                              :rules="effectiveDate"
                              color="#F89D1F"
                              persistent-hint
                              v-on="on"
                              ><template v-slot:label>
                                <span>
                                  {{ $t("effectivedate")
                                  }}<span class="red--text"> *</span></span
                                >
                              </template>
                              <template v-slot:append>
                                <img
                                  width="20"
                                  height="20"
                                  class="mr-2 mt-1"
                                  src="@/assets/images/Mask Group 160@2x.png"
                                  alt
                                />
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editsocial.effective_Date"
                            no-title
                            @input="effdate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-wrap justify-center align-center ml-auto pb-6"
              >
                <v-btn
                  class="mx-2 text-capitalize commoncancelbtn"
                  outlined
                  text
                  @click="CloseSocial"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  class="mx-2 text-capitalize commonsavebtn"
                  @click="UpdateSocial"
                  >{{ $t("Update") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tab-item>
        <!-- <v-tab-item v-show="showdefault">
          <Deduction />
        </v-tab-item> -->
        <v-tab-item v-show="showthis">
          <v-card class="pt-5 pb-5">
            <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 10px">
              <v-col cols="12" xs="12" sm="6" md="4" lg="3">
                <v-text-field
                  :placeholder="$t('Search')"
                  v-model="searchtxt"
                  color="#F99D20"
                  dense
                  outlined
                  autocomplete="off"
                  prepend-inner-icon="mdi-magnify"
                  style="font-size:14px;margin-top:-0.1rem; margin-left:1rem;"
                  @keyup.enter="Search()">
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12" xs="12" sm="12" md="3" lg="4">
                <v-text-field
                  autocomplete="off"
                  :placeholder="$t('Search')"
                  color="#F99D20"
                  v-model.lazy="searchtxt"
                  dense
                  style="margin-top: -0.3rem; margin-left: 1rem">
                </v-text-field>
              </v-col>
              <v-btn
                color="#F99D20"
                @click="Search()"
                class="white--text text-capitalize ml-5"
                width="120"
                max-height="35">
                <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
                color="#F99D20"
                class="orange--text text-capitalize mt-2 mr-5 ml-5 add_btn"
                width="150"
                max-height="35"
                outlined
                @click="HospitalUploaddialog = true"
                :disabled="permission.upload">
                {{ $t("upload") }}
              </v-btn>
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mt-2 mr-5 ml-5 add_btn"
                width="150"
                max-height="35"
                @click="HospitalAdddialog = true"
                :disabled="permission.add"
                >{{ $t("Add") }}</v-btn
              >
            </v-row>
            <v-data-table
              style="margin-left: 40px; margin-right: 40px"
              :headers="HospitalHeader"
              :items="HospitalList"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              hide-default-footer
              hide-default-header
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th
                      style="cursor: pointer; min-width: 180px !important"
                      class="b-text"
                      @click="sortFun('hospital_Name')"
                    >
                      {{ $t("hospitalName") }}
                      <v-icon small v-if="!hospital_Name"
                        >mdi-arrow-down</v-icon
                      >
                      <v-icon small v-if="hospital_Name">mdi-arrow-up</v-icon>
                    </th>

                    <th class="text-end pr-8">{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.hospital_Name }}</td>
                  <td class="text-end">
                    <v-btn
                      icon
                      @click="EditHospitals(item)"
                      :disabled="permission.edit"
                    >
                      <img class="editIcon" :src="images.edit" />
                    </v-btn>
                    <v-btn
                      icon
                      @click="DeleteHosp(item.hospital_ID)"
                      :disabled="permission.delete"
                    >
                      <img class="deleteIcon" :src="images.delete" />
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-right" v-if="HospitalList.length">
              <PaginationComponent
              :page.sync="page"
              :pageCount="pageCount"
              :itemsPerPage.sync="itemsPerPage"
            />
            </div>
          </v-card>

          <!-- Hospital Add Dialog -->
          <v-dialog v-model="HospitalAdddialog" persistent max-width="657px">
            <v-card class="d-flex flex-wrap" ref="form">
              <v-card-title>
                <p style="margin-top: 20px; margin-bottom: -30px">
                  {{ $t("addHospital") }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-container class="mt-2">
                  <v-form ref="HaddForm" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          autocomplete="off"
                          color="#F89D1F"
                          v-model="addhospital_Name"
                          :rules="hospital"
                          ><template v-slot:label>
                            <span>
                              {{ $t("hospitalName")
                              }}<span class="red--text"> *</span></span
                            >
                          </template></v-text-field
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-wrap justify-center ml-auto pb-6"
              >
                <v-btn
                  outlined
                  class="mr-2 text-capitalize commoncancelbtn"
                  @click="CloseHospitalAddDialog"
                  text
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  class="mr-2 text-capitalize commonsavebtn"
                  @click="AddHospital"
                  >{{ $t("Add") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Hospital Edit Dialog -->
          <v-dialog v-model="HospitalEditdialog" persistent max-width="657px">
            <v-card ref="form">
              <v-card-title>
                <p class="ml-2" style="margin-top: 20px; margin-bottom: -30px">
                  {{ $t("editHospital") }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-container class="mt-2">
                  <v-form ref="HeditForm" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          autocomplete="off"
                          color="#F89D1F"
                          v-model="edithospital.hospital_Name"
                          :rules="hospitalEdit"
                          ><template v-slot:label>
                            <span>
                              {{ $t("hospitalName")
                              }}<span class="red--text"> *</span></span
                            >
                          </template></v-text-field
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6" style="margin-right: 3%">
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  class="mr-2 text-capitalize commoncancelbtn"
                  @click="CloseHospitalEditDialog"
                  text
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  class="mr-2 text-capitalize commonsavebtn"
                  @click="UpdateHospital"
                  >{{ $t("Update") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Hospital Delete Dialog -->
          <v-dialog
            v-model="HospitalDeletedialog"
            class="dialog"
            max-width="532"
          >
            <v-card>
              <v-card-title>
                <p style="margin-top: 10px; margin-bottom: -30px">
                  {{ $t("deleteitem") }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-container class="mt-2">
                  <v-row>
                    <v-col align="center" cols="12">
                      <p style="font-size: 17px; color: #444444" class="pt-4">
                        {{ $t("confirmdelete") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6" style="margin-right: 3%">
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  class="mr-2 text-capitalize commoncancelbtn"
                  @click="HospitalDeletedialog = false"
                  text
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  class="mr-2 text-capitalize commondeletebtn"
                  @click="DeleteHospitals"
                  >{{ $t("delete") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Hospital Upload Dialog -->
          <v-dialog v-model="HospitalUploaddialog" presistent max-width="786">
            <v-card class="d-flex flex-wrap" style="border-radius: 15px">
              <v-card-title class="headline">{{
                $t("UploadFile")
              }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col style="margin-left: -30px" cols="5" md="5">
                    <v-file-input
                      truncate-length="100"
                      v-show="showup"
                      id="fileInputButton"
                      class="inputbot"
                      v-model="chosenfile"
                      accept=".xlsx"
                    >
                      <template v-slot:append>
                        <v-btn icon>
                          <img
                            width="20"
                            height="20"
                            @click="closeinput"
                            src="@/assets/closered.png"
                            alt
                          />
                        </v-btn>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-btn
                      color="#ACACAC"
                      class="white--text text-capitalize"
                      width="150"
                      max-height="35"
                      @click="getupload"
                      >{{ $t("browse") }}</v-btn
                    >
                  </v-col>
                  <v-col cols="12" md="9">
                    <p class="mt-2" style="color: #f74747">
                      ** {{ $t("excelFileFormat") }}
                    </p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
                <v-row>
                  <v-col cols="12" md="4">
                    <p>Hospital Template.xlsx</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <a :href="whatthelang()" style="color: #f99d20">{{
                      $t("download")
                    }}</a>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-wrap ml-auto justify-center pb-6"
              >
                <v-btn
                  outlined
                  class="mr-2 text-capitalize commoncancelbtn"
                  text
                  @click="closeUpload"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  class="mr-2 commonsavebtn text-capitalize"
                  text
                  @click="previewFiles"
                  >{{ $t("upload") }}</v-btn
                >
              </v-card-actions>
              <br />
            </v-card>
          </v-dialog>
        </v-tab-item>
      </v-tabs>
    </div>
    
    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';
// import Deduction from "@/views/MasterData/Deduction";

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  watch: {
    addhospital_Name() {
      this.hospital = [];
    },
    "edithospital.hospital_Name"() {
      this.hospitalEdit = [];
    },
    "editsocial.item_name"() {
      this.item_name = [];
    },
    "editsocial.amount"() {
      this.amount = [];
    },
    "editsocial.effective_Date"() {
      this.effectiveDate = [];
    },
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
    searchtxt: function () {
      if (this.hostsearch == 1) {
        this.GetHospital();
      }
    },
    "social.lowsalary"(){
      if(this.social.lowsalary == null || this.social.lowsalary == "0"){
        this.socialerror.lowsalary="Require";
        this.social.lowsubvention="0";
      }else{
        this.socialerror.lowsalary="";
        this.social.lowsubvention = Math.round((parseInt(this.removecomma(this.social.lowsalary))/100)*this.social.rate)+"";
      }
      this.social.lowsalary = this.numberWithCommas(this.removecomma(this.social.lowsalary));
    },
    "social.highsalary"(){
      if(this.social.highsalary == null || this.social.highsalary == "0"){
        this.socialerror.highsalary="Require";
        this.social.highsubvention = "0";
      }else{
        this.socialerror.highsalary="";
        this.social.highsubvention = Math.round((parseInt(this.removecomma(this.social.highsalary))/100)*this.social.rate)+"";
      }
      this.social.highsalary = this.numberWithCommas(this.removecomma(this.social.highsalary));
    },
    "social.lowsubvention"(){
      if(this.social.lowsubvention == "0" || this.social.lowsubvention == "0"){
        this.socialerror.lowsubvention="Require";
      }else{
        this.socialerror.lowsubvention="";
      }
      this.social.lowsubvention = this.numberWithCommas(this.removecomma(this.social.lowsubvention));
    },
    "social.highsubvention"(){
      if(this.social.highsubvention == "0" || this.social.highsubvention == ""){
        this.socialerror.highsubvention="Require";
      }else{
        this.socialerror.highsubvention="";
      }
      this.social.highsubvention = this.numberWithCommas(this.removecomma(this.social.highsubvention));
    },
    "social.rate"(){
      if(this.social.rate == null || this.social.rate == "0" || this.social.rate == ""){
        this.socialerror.rate="Require";
        this.social.highsubvention = "0";
        this.social.lowsubvention="0";
      }else{
        this.socialerror.rate="";
        this.social.lowsubvention = Math.round((parseInt(this.removecomma(this.social.lowsalary))/100)*this.social.rate)+"";
        this.social.highsubvention = Math.round((parseInt(this.removecomma(this.social.highsalary))/100)*this.social.rate)+"";
      }
    },
  },
  data: (vm) => ({
    active_tab: "",
    item_name: [],
    amount: [],
    effectiveDate: [],
    hospital: [],
    hospitalEdit: [],
    required(prop) {
      return (v) => !!v || `${prop} is required`;
    },
    permission: {
      add: true,
      edit: true,
      delete: true,
      upload: true,
    },
    showdefault: false,
    showthis: false,
    hospital_Name: null,
    url: enurl.apiUrl,
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    SocialEditdialog: false,
    HospitalAdddialog: false,
    HospitalEditdialog: false,
    HospitalDeletedialog: false,
    HospitalUploaddialog: false,
    page: 1,
    pageCount: 10,
    itemsPerPage: 10,
    // items: [10, 20, 30],
    LoadingDialog: false,
    effdate: false,
    showup: false,
    chosenfile: null,
    HOSTID: 0,
    searchtxt: "",
    exclname: "Hospital Master Data.xlsx",
    SSFList: [],
    HospitalList: [],
    hostsearch: 0,
    editsocial: {
      fund_ID: null,
      item_name: null,
      amount: null,
      effective_Date: "",
    },
    addhospital_Name: null,
    edithospital: {
      hospital_ID: null,
      hospital_Name: null,
    },
    error: {
      errorMsgItem_Name: "",
      errorMsgAmount: "",
      errorMsgEff_Date: "",
      errorMsgHospital: "",
    },
    isHidden: true,
    social:{ 
      fund_ID:0,
      lowsalary: 1650,
      highsalary: 15000,
      lowsubvention: 83,
      highsubvention: 750,
      rate: 5,
      updateByName:"",
      updateByRole:"",
      updateDate:""
    },
    socialerror:{
      lowsalary: "",
      highsalary: "",
      lowsubvention: "",
      highsubvention: "",
      rate: ""
    },
    confirmDialog:false
  }),
  mounted: function () {
    let self = this;
    self.GetSocialSecurityFund();
    self.permission = self.CheckViewAddEditDeleteUpload(69, 70, 71, 72, 73);
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      self.showthis = true;
      self.active_tab = 0;
      self.showdefault = true;
    } else {
      if (perid.some((e) => e == 69)) {
        self.showthis = true;
        self.active_tab = 2;
        self.showdefault = false;
      } else {
        self.showthis = false;
        self.showdefault = false;
      }
    }
  },
  computed: {
    HospitalHeader() {
      return [
        {
          text: this.$t("hospitalname"),
          value: "hospital_Name",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("action"),
          value: "actionadd",
          align: "center",
          sortable: false,
        },
      ];
    },
    SocialHeader() {
      return [
        {
          text: "",
          value: "fund",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("amount"),
          value: "amount",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("effectivedate"),
          value: "effDate1",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("action"),
          value: "actionadd",
          align: "center",
          sortable: false,
        },
      ];
    },
    computedDateFormatted6() {
      return this.formatDate(this.editsocial.effective_Date);
    },
    images() {
      return this.$store.state.images;
    }
  },
  methods: {
    onlyNum(evt) {
      evt = (evt) ? evt : window.event;
      let expect = this.removecomma(evt.target.value).toString() + evt.key.toString();
      
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    numberWithCommas(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    removecomma(x) {
      if (x != 0 && x != null) {
        return x.replace(/,/g, "");
      } else {
        return "0";
      }
    },

    orginalOne() {
      this.GetHospital();
      this.hospital_Name = null;
      this.page = 1;
    },
    sortFun(rowName) {
      let self = this;
      let keyName = ["hospital_Name"];
      if (rowName == "hospital_Name") {
        self.HospitalList = self.HospitalList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.HospitalList = self.HospitalList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.hospital_Name = null;
    },
    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => {});
    },
    resetForm() {
      let self = this;
      self.$refs.SeditForm.reset();
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      // if(this.chosenfile != null){
      //   this.showup = true;
      // }
    },
    whatthelang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Hospital Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Hospital Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Hospital Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Hospital Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Hospital Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Hospital Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Hospital Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Hospital Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Hospital Master Data.xlsx";
        } else {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Hospital Master Data.xlsx";
        }
      }
      return thelink;
    },
    CloseSocial() {
      this.SocialEditdialog = false;
      this.item_name = [];
      this.amount = [];
      this.effectiveDate = [];
      //this.$refs.SeditForm.reset();
    },
    CloseHospitalAddDialog() {
      this.addhospital_Name = null;
      this.hospital = [];
      this.HospitalAdddialog = false;
      this.$refs.HaddForm.reset();
    },
    CloseHospitalEditDialog() {
      this.hospitalEdit = [];
      this.HospitalEditdialog = false;
      this.$refs.HeditForm.reset();
    },
    closeUpload() {
      this.HospitalUploaddialog = false;
      this.chosenfile = null;
      this.showup = false;
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    hostab() {
      this.searchtxt = "";
      this.GetHospital();
    },
    Search() {
      let self = this;
      self.hostsearch = 1;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.HospitalList;
        self.HospitalList = [];
        let Stxt = self.searchtxt.toLowerCase();
        for (let i = 0; i < List.length; i++) {
          if (List[i].hospital_Name.toLowerCase().includes(Stxt)) {
            self.HospitalList.push(List[i]);
          }
        }
      } else {
        alert("Please enter anything!!");
        self.GetHospital();
      }
    },
    previewFiles() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          this.importData = XLSX.utils.sheet_to_json(worksheet);
          let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
          const file_data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          if (temp.length != 0) {
            let combileArray = [];
            for (let i = 0; i < this.importData.length; i++) {
              this.tempimport = {
                hospital_Name: file_data[i + 1][0],
                company_ID: store.state.companyID,
              };
              combileArray.push(this.tempimport);
            }
            if (filename == "Hospital Master Data.xlsx") {
              axios
                .post(
                  `${self.url}Hospital/ImportHospitalMasterData`,
                  combileArray
                )
                .then(function (response) {
                  if (response.data.status == 0)
                  {
                    alert(response.data.message);
                    self.chosenfile = null;
                    self.showup = false;
                    self.HospitalUploaddialog = false;
                    self.LoadingDialog = false;
                    self.GetHospital();
                    self.defaultSort();
                  }
                })
                .catch(function (error) {
                  self.LogTrace(
                    error,
                    "Upload SocialSecurityFund Fail",
                    5,
                    "Verbose"
                  );
                  alert(error);
                  self.LoadingDialog = false;
                });
            } else {
              alert("File name must be Hospital Master Data.xlsx");
              self.LoadingDialog = false;
            }
          } else {
            self.LoadingDialog = false;
            alert("Please fill hospital_Name.");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
        self.LoadingDialog = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    GetSocialSecurityFund() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}SocialSecurityFund/GetSocialSecurityFund`, tempp)
        .then(function (response) {
          self.social.lowsalary = self.numberWithCommas(parseInt(response.data.data.lowestBaseSalary));
          self.social.highsalary = self.numberWithCommas(parseInt(response.data.data.highestBaseSalary));
          self.social.lowsubvention = self.numberWithCommas(parseInt(response.data.data.lowestSubvention));
          self.social.highsubvention = self.numberWithCommas(parseInt(response.data.data.highestSubvention));
          self.social.rate = parseInt(response.data.data.contributionRate);
          self.social.fund_ID = response.data.data.fund_ID;
          self.social.updateByName = response.data.data.updateByName;
          self.social.updateByRole = response.data.data.updateByRole;
          self.social.updateDate = self.convertUTCDateToLocalDate(response.data.data.updateDate,0);
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get SocialSecurityFund Fail", 5, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    convertUTCDateToLocalDate(date, num) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);

      let output = null;

      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }
      if (num == 0) {
        return output;
      }
      else if (num == 1) {
        return newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();
      }
    },
    EditSoical(temp) {
      let self = this;
      self.SocialEditdialog = true;
      self.editsocial.fund_ID = temp.fund_ID;
      self.editsocial.item_name = temp.fund;
      self.editsocial.amount = temp.amount;
      let date = new Date(Date.parse(temp.effective_Date));
      let plusday = date.getDate() + 1;
      date.setDate(plusday);
      self.editsocial.effective_Date = new Date(date)
        .toISOString()
        .substr(0, 10);
    },
    UpdateSocial() {
      let self = this;
      self.LoadingDialog = true;
      self.socialerror.lowsalary = (!self.social.lowsalary) || (self.social.lowsalary=="0")
        ? "Require"
        : "";
      self.socialerror.highsalary = (!self.social.highsalary) || (self.social.highsalary=="0")
        ? "Require"
        : "";
      self.socialerror.lowsubvention = (!self.social.lowsubvention) || (self.social.lowsubvention=="0")
        ? "Require"
        : "";
      self.socialerror.highsubvention = (!self.social.highsubvention) || (self.social.highsubvention=="0")
        ? "Require"
        : "";
      self.socialerror.rate = (!self.social.rate) || (self.social.rate=="0")
        ? "Require"
        : "";
      if (
        self.social.highsalary != null &&
        self.social.highsalary != "0" &&
        self.social.lowsalary != null &&
        self.social.lowsalary != "0" &&
        self.social.lowsubvention != null &&
        self.social.lowsubvention != "0" &&
        self.social.highsubvention != null &&
        self.social.highsubvention != "0" &&
        self.social.rate != null &&
        self.social.rate != ""
      ) {
        let tempssf = {
          fund_ID: self.social.fund_ID,
          company_ID: store.state.companyID,
          lowestBaseSalary: self.removecomma(self.social.lowsalary)+"",
          lowestSubvention: self.removecomma(self.social.lowsubvention)+"",
          highestBaseSalary: self.removecomma(self.social.highsalary)+"",
          highestSubvention: self.removecomma(self.social.highsubvention)+"",
          contributionRate: self.social.rate+"",
          updateBy: store.state.userid,
        };
        axios.post(`${self.url}SocialSecurityFund/UpdateSocialSecurityFund`,tempssf)
        .then(function (response) {
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.GetSocialSecurityFund();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Update SocialSecurityFund Fail", 5, "Medium");
          alert(error);
          self.LoadingDialog = false;
        });
      self.confirmDialog = false;
      } else {
        self.LoadingDialog = false;
        self.confirmDialog = false;
      }
    },

    GetHospital() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Hospital/GetHospital`, tempp)
      .then(function (response) {
        self.HospitalList = response.data.data;
        self.hostsearch = 0;
        self.LoadingDialog = false;
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Hospital Fail", 5, "Critical");
        //alert(error);
        self.LoadingDialog = false;
      });
    },
    AddHospital() {
      let self = this;
      self.LoadingDialog = true;
      self.hospital = [(v) => !!v || "Hospital Name is required"];
      let tempvalidate = self.$refs.HaddForm.validate();
      if (
        tempvalidate == true &&
        self.addhospital_Name != null &&
        self.addhospital_Name != ""
      ) {
        // let samecondi = false;
        // for (let j = 0; j < self.HospitalList.length; j++) {
        //   if (self.addhospital_Name.toLowerCase() == self.HospitalList[j].hospital_Name.toLowerCase()) {
        //     samecondi = false;
        //     break;
        //   } else {
        //     samecondi = true;
        //   }
        // }
        // if (samecondi == true) {
        let temphosp = {
          hospital_Name: self.addhospital_Name,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}Hospital/AddHospital`, temphosp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.addhospital_Name = null;
              self.HospitalAdddialog = false;
              self.LoadingDialog = false;
              self.GetHospital();
              self.defaultSort();
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Add SocialSecurityFund Fail", 5, "Low");
            //alert(error);
            self.LoadingDialog = false;
          });
        // } else {
        //   alert("Hospital already exists!");
        //   self.LoadingDialog = false;
        // }
      } else {
        self.LoadingDialog = false;
      }
    },
    EditHospitals(temp) {
      let self = this;
      self.HospitalEditdialog = true;
      self.edithospital.hospital_ID = temp.hospital_ID;
      self.edithospital.hospital_Name = temp.hospital_Name;
    },
    UpdateHospital() {
      let self = this;
      self.LoadingDialog = true;
      self.hospitalEdit = [(v) => !!v || "Hospital Name is required"];
      let tempvalidate = self.$refs.HeditForm.validate();
      if (
        tempvalidate == true &&
        self.edithospital.hospital_Name != null &&
        self.edithospital.hospital_Name != ""
      ) {
        // let samecondi = false;
        // let FilterHospital = self.HospitalList.filter(
        //   (data) => data.hospital_ID != self.edithospital.hospital_ID
        // );
        // for (let j = 0; j < FilterHospital.length; j++) {
        //   if (
        //     self.edithospital.hospital_Name.toLowerCase() ==
        //     FilterHospital[j].hospital_Name.toLowerCase()
        //   ) {
        //     samecondi = false;
        //     break;
        //   } else {
        //     samecondi = true;
        //   }
        // }
        // if(FilterHospital.length == 0){
        //   samecondi = true;
        // }
        // if (samecondi == true) {
        let temphosp = {
          hospital_ID: self.edithospital.hospital_ID,
          hospital_Name: self.edithospital.hospital_Name,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}Hospital/UpdateHospital`, temphosp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.edithospital.hospital_Name = null;
              self.HospitalEditdialog = false;
              self.LoadingDialog = false;
              self.GetHospital();
              self.defaultSort();
            }
          })
          .catch(function (error) {
            this.LogTrace(error, "Update SocialSecurityFund Fail", 5, "Medium");
            alert(error);
            self.LoadingDialog = false;
          });
        // } else {
        //   alert("Hospital already exists!");
        //   self.LoadingDialog = false;
        // }
      } else {
        self.LoadingDialog = false;
      }
    },
    DeleteHosp(id) {
      this.HOSTID = id;
      this.HospitalDeletedialog = true;
    },
    DeleteHospitals() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.HOSTID;
      let tempdlt = {
        hospital_ID: id,
      };
      axios.post(`${self.url}Hospital/DeleteHospital`, tempdlt)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.HospitalDeletedialog = false;
          self.LoadingDialog = false;
          self.GetHospital();
          self.defaultSort();
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Delete SocialSecurityFund Fail", 5, "High");
        alert(error);
        self.LoadingDialog = false;
      });
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 430px) {
  .add_btn {
    margin-top: 15px;
  }
}
::v-deep .v-window{
  overflow: visible!important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
  border-radius: 15px 15px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 15px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
  width: 140px;
}
.active {
  cursor: pointer !important;
}
.v-tab--active {
  color: #3c4043;
  border-radius: 15px 15px 0px 0px;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  font-weight: normal;
  font-size: 16px;
  color: #f99d20;
}
</style>
<style>
.mdi-close::before {
  /* content: "\F0156"; */
  color: red !important;
  display: none !important;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
tbody tr:hover {
  background-color: #fff9f0 !important;
}
.socialtextbox input{
  text-align: end !important;
}
</style>
