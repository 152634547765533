<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
      <p class="txt-header">
        {{ $t("EmployeesOTRequest") }}
      </p>

    <div class="mt-6 pb-4">
      <v-card class="mt-6 mb-5" style="margin-bottom: 5rem">
        <div class="d-flex mx-7">
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="4" lg="3">
              <v-text-field
                autocomplete="off"
                v-model="searchtxt"
                :placeholder="$t('Search')"
                color="#F99D20"
              ></v-text-field>
            </v-col>
            <v-btn
              color="#F99D20"
              @click="Search()"
              class="white--text mt-6 text-capitalize ml-2"
              width="120"
              max-height="35"
            >
              <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
            </v-btn>
            <v-spacer></v-spacer>
            <div>
              <v-btn
                outlined
                color="#2E7D32"
                @click="exporting"
                class="green--text mt-6 ml-2 text-capitalize"
                :disabled="permission.export"
              >
                <v-img
                  class="mr-2 mt-1"
                  src="@/assets/images/excel.png"
                ></v-img>
                {{ $t("Export") }}
              </v-btn>
            </div>
          </v-row>
        </div>
        <v-row style="margin-left: 1rem; margin-right: 1rem">
          <v-col cols="12" md="2" sm="2" lg="2">
            <!-- <input
              type="checkbox"
              :checked="!anyRemaining"
              @change="checkSelectedAll()"
              v-model="alldate"
              class="mt-4"
              width="200"
            /> -->
            <v-checkbox class="custom-checkbox" color="#F99D20" :checked="!anyRemaining" @change="checkSelectedAll()" v-model="alldate"></v-checkbox>
            <label class="ml-2" style="display: inline">
              {{ $t("allDate") }}
            </label>
          </v-col>

          <v-col cols="12" md="3" sm="4" lg="3">
            <div class="d-flex">
              <p v-show="showdatepicker" class="mt-3" style="width: 50px">
                {{ $t("from") }}
              </p>
              <date-picker
                v-show="showdatepicker"
                v-model="reportDateFrom"
                format="DD/MM/YYYY"
                @change="Getbydatelist()"
                width="100%"
                :disabled-date="(date) => date > new Date(reportDateTo)"
                placeholder="DD/MM/YYYY"
                style="margin-top: 0.5rem; width: 150px"
              >
                <template slot="icon-calendar">
                  <img
                    src="@/assets/images/calendaricon.png"
                    style="width: 20px; height: 20px"
                  />
                </template>
              </date-picker>
            </div>
          </v-col>

          <v-col cols="12" sm="4" md="3" lg="3">
            <div class="d-flex">
              <p class="mt-3" v-show="showdatepicker" style="width: 50px">
                {{ $t("To") }}
              </p>
              <date-picker
                v-show="showdatepicker"
                v-model="reportDateTo"
                format="DD/MM/YYYY"
                width="100%"
                :disabled-date="(date) => date < new Date(reportDateFrom)"
                @change="Getbydatelist()"
                placeholder="DD/MM/YYYY"
                style="margin-top: 0.5rem; width: 150px"
              >
                <template slot="icon-calendar">
                  <img
                    src="@/assets/images/calendaricon.png"
                    style="width: 20px; height: 20px"
                  />
                </template>
              </date-picker>
            </div>
          </v-col>
        </v-row>
        <div
          style="
            margin-left: 1rem;
            margin-right: 1rem;

            margin-top: 1rem;
          "
        >
          <v-data-table
            class="mytable"
            :items="requestlist"
            :headers="headers"
            :page.sync="page1"
            hide-default-header
            :items-per-page="itemsPerPage"
            @page-count="pageCount1 = $event"
            hide-default-footer
            :mobile-breakpoint="0"
          >
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      @change="checkall()"
                      v-model="tablecheck"
                      class="mr-2"
                      width="200"
                    />
                  </th>
                  <th>{{ headers[0].text }}</th>
                  <th>{{ headers[1].text }}</th>
                  <th>{{ headers[2].text }}</th>
                  <th>{{ headers[3].text }}</th>
                  <th>{{ headers[4].text }}</th>
                  <th>{{ headers[5].text }}</th>
                  <th>{{ headers[6].text }}</th>
                  <th>{{ headers[7].text }}</th>
                  <th>{{ headers[8].text }}</th>
                  <th>{{ headers[9].text }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <input
                    type="checkbox"
                    v-model="item.check"
                    @change="checkmylist(item)"
                    class="mr-2"
                    width="200"
                  />
                </td>
                <td>{{ item.employee_Code }}</td>
                <td @click="detailrequest(item)">{{ item.name }}</td>
                <td @click="detailrequest(item)">{{ item.oT_Hours }}</td>
                <td @click="detailrequest(item)">
                  {{ formatDate(item.start_Date) }}
                </td>
                <td @click="detailrequest(item)">
                  {{ formatDate(item.end_Date) }}
                </td>
                <td @click="detailrequest(item)">{{ item.purpose_OT }}</td>
                <td @click="detailrequest(item)">
                  {{ convertUTCDateToLocalDate(item.oT_Requested_Date) }}
                </td>
                <td
                  @click="detailrequest(item)"
                  :style="{
                    color: item.status == 'Approved' ? '#22A534' : ' #FF6565',
                  }"
                >
                  {{ item.status }}
                </td>

                <td @click="detailrequest(item)">{{ item.firstName }}</td>
                <td @click="detailrequest(item)">
                  {{ convertUTCDateToLocalDate(item.oT_Approved_Date) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div class="text-right" v-if="requestlist.length">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page1"
                :length="pageCount1"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <!-- newapprovedialog -->
    <v-dialog v-model="HRdialog" persistent max-width="580px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span> {{ $t("OTRequest") }} </span>
          <v-spacer></v-spacer>
          <v-btn @click="HRdialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("EmployeeName") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-text-field
                :disabled="disabledetail"
                v-model="HRrequest.empname"
                height="10px"
                color="#F99D20"
                single-line
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 ml-2">
                <input
                  type="checkbox"
                  v-model="HRrequest.wholeDay"
                  @click="showstartandend"
                />
                <label class="ml-3"> {{ $t("wholeDay") }}</label>
              </div>
            </v-col>
          </v-row>
          <v-row v-show="!showandhide" class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("oTDate") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-menu
                v-model="menu7"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                fluid
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    autocomplete="off"
                    :disabled="disabledetail"
                    single-line
                    dense
                    outlined
                    v-model="computedDateFormatted6"
                    append-icon="mdi-calendar-month-outline"
                    color="#F99D20"
                    persistent-hint
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="HRrequest.OTdate"
                  no-title
                  @input="menu7 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-show="showandhide" class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("oTStartDate") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                fluid
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="disabledetail"
                    single-line
                    dense
                    outlined
                    v-model="computedDateFormatted4"
                    append-icon="mdi-calendar-month-outline"
                    color="#F99D20"
                    persistent-hint
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="HRrequest.OTstartdate"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-show="showandhide" class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("oTEndDate") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                fluid
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="disabledetail"
                    single-line
                    dense
                    outlined
                    v-model="computedDateFormatted5"
                    append-icon="mdi-calendar-month-outline"
                    color="#F99D20"
                    persistent-hint
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="HRrequest.OTenddate"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("otStartTime") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-menu
                ref="menu8"
                v-model="menu8"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time5"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="disabledetail"
                    v-model="HRrequest.Otstart"
                    append-icon="access_time"
                    single-line
                    outlined
                    dense
                    color="#F99D20"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu8"
                  v-model="HRrequest.Otstart"
                  no-title
                  fluid
                  @click:minute="$refs.menu8.save(HRrequest.Otstart)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("otEndTime") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-menu
                ref="menu9"
                v-model="menu9"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time6"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="disabledetail"
                    v-model="HRrequest.Otend"
                    append-icon="access_time"
                    single-line
                    outlined
                    dense
                    color="#F99D20"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu9"
                  v-model="HRrequest.Otend"
                  no-title
                  fluid
                  @click:minute="$refs.menu9.save(HRrequest.Otend)"
                ></v-time-picker
                >men
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("oTHours") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-text-field
                :disabled="disabledetail"
                height="10px"
                v-model="HRrequest.Othours"
                color="#F99D20"
                single-line
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("purposeofOT") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-text-field
                :disabled="disabledetail"
                height="10px"
                v-model="HRrequest.purpose"
                color="#F99D20"
                single-line
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("approvalSentTo") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-text-field
                :disabled="disabledetail"
                height="10px"
                v-model="HRrequest.approval"
                color="#F99D20"
                single-line
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("status") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <!-- <v-text-field v-model="HRrequest.status" height="10px" color="#F99D20" single-line outlined dense required></v-text-field> -->
              <p
                class="mt-2"
                :disabled="disabledetail"
                :style="{
                  color:
                    HRrequest.status == 'Approved' ? '#22A534' : ' #FF6565',
                }"
              >
                {{ HRrequest.status }}
              </p>
            </v-col>
          </v-row>
          <v-row v-show="showremark" class="pl-6">
            <v-col cols="3" class="deletepadd">
              <p class="mt-2">{{ $t("RemarkCancel") }}</p>
            </v-col>
            <v-col cols="8" class="deletepadd">
              <v-text-field
                :disabled="disabledetail"
                style="margin-top: 5px"
                v-model="HRrequest.reason"
                color="#F99D20"
                single-line
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- exportdialog -->
    <v-dialog v-model="exportdialog" persistent max-width="600px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span>{{ $t("ExportOTRequest") }} </span>
          <v-spacer></v-spacer>
          <v-btn @click="exportdialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="pb-0">
              <p class="mt-2">{{ $t("Employees") }}</p>
              <v-text-field
                v-model="computedForTranslate"
                readonly
                height="10px"
                class="mt-3"
                color="#F99D20"
                single-line
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col v-show="!hidestartandenddate" cols="6" class="pb-0">
              <p class="mt-2">{{ $t("date") }}</p>
              <v-text-field
                :value="this.$t('allDate')"
                readonly
                height="10px"
                class="mt-3"
                color="#F99D20"
                single-line
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row v-show="!hidestartandenddate">
            <v-col cols="6" class="pt-0">
              <p class="mt-2">{{ $t("date") }}</p>
              <v-text-field
                v-model="allselectdate"
                readonly
                height="10px"
                class="mt-3"
                color="#F99D20"
                single-line
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row v-show="hidestartandenddate">
            <v-col cols="6" class="pt-0">
              <p class="mt-2">
                {{ $t("startDate") }}<span class="red--text"> *</span>
              </p>
              <v-menu
                v-model="menu10"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                fluid
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    autocomplete="off"
                    single-line
                    dense
                    outlined
                    class="mt-3"
                    v-model="computedDateFormatted7"
                    append-icon="mdi-calendar-month-outline"
                    color="#F99D20"
                    persistent-hint
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exportrequest.Otstart"
                  no-title
                  @input="menu10 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pt-0">
              <p class="mt-2">
                {{ $t("enddata") }}<span class="red--text"> *</span>
              </p>
              <v-menu
                v-model="menu11"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                fluid
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    autocomplete="off"
                    single-line
                    dense
                    outlined
                    class="mt-3"
                    v-model="computedDateFormatted8"
                    append-icon="mdi-calendar-month-outline"
                    color="#F99D20"
                    persistent-hint
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exportrequest.Otend"
                  no-title
                  @input="menu11 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-2">
          <v-row class="ml-0 mr-0">
            <v-spacer></v-spacer>
            <v-col cols="6" md="3">
              <v-btn
                class="text-capitalize"
                text
                @click="exportdialog = false"
                width="100%"
                max-height="32"
              >
                {{ $t("Cancel") }}</v-btn
              >
            </v-col>
            <v-col cols="6" md="3">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize"
                width="100%"
                max-height="32"
                @click="Exportallexcel()"
              >
                {{ $t("Export") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import XLSX from "xlsx";
import moment from "moment";
import CheckViewExport from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils, CheckViewExport],
  data() {
    return {
      permission: {
        export: true,
      },
      desserts: [{}],
      runningvalue: 0,
      // allselectdate: "All Date",
      exportlist: [],
      LoadingDialog: false,
      tablecheck: false,
      showdatepicker: false,
      searchtxt: null,
      page: 1,
      pageCount: 15,
      page1: 1,
      pageCount1: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      itemforselect: ["Everyone", "Select Users"],
      checklist: false,
      selectusertype: "Everyone",
      HRdialog: false,
      exportdialog: false,
      showandhide: false,
      disabledetail: true,
      reportDateFrom: "",

      reportDateTo: "",
      alldate: true,
      other: true,
      HRrequest: {
        empname: null,
        wholeDay: false,
        OTdate: "",
        OTstartdate: "",
        OTenddate: "",
        Otstart: "",
        Otend: "",
        Othours: "",
        purpose: "",
        approval: "",
        status: "",
        reason: "",
      },
      exportrequest: {
        Otstart: "",
        Otend: "",
      },
      myrequestlist: [],
      menu10: false,
      menu1: false,
      menu2: false,
      time: null,
      menu3: false,
      time2: null,
      menu4: false,
      menu5: false,
      time3: null,
      menu6: false,
      time4: null,
      menu7: false,
      time5: null,
      menu8: false,
      menu9: false,
      time6: null,
      menu11: false,
      url: enurl.apiUrl,
      requestlist: [],
      myexportlist: [],
      tempexport: [],
      liesting: [],
      showremark: false,
      disableselectuser: true,
      hidestartandenddate: true,
    };
  },
  mounted() {
    this.Getrequestlist();
    this.permission = this.CheckViewExport(18, 19);
    if (this.alldate == true) {
      this.other = true;

      for (let i = 0; i < this.requestlist.length; i++) {
        if (this.requestlist[i].check == true) {
          let mydata = [];
          if (this.myexportlist.length != 0) {
            let myrequester = this.requestlist[i].requester_ID;
            mydata = this.myexportlist.filter((date) => date == myrequester);
            if (mydata.length == 0) {
              this.myexportlist.push(myrequester);
            }
          } else {
            this.myexportlist.push(this.requestlist[i].requester_ID);
          }
        }
      }
    } else {
      this.other = false;
    }
  },
  computed: {
    // computedForTranslate() {

    //   return this.selectusertype == "Select Users"
    //     ? this.$t("SelectUsers")
    //     : this.$t("Everyone");
    // },
    computedForTranslate() {
      return this.selectusertype == "Everyone"
        ? this.$t("Everyone")
        : this.selectusertype == "Selected User"
        ? this.$t("SelectUser")
        : this.$t("SelectUsers");
    },
    headers() {
      return [
        {
          text: this.$t("EmployeeID"),
          align: "left",
          sortable: true,
          value: "employee_Code",
        },
        {
          text: this.$t("EmployeeName"),
          value: "name",
          align: "left",
          sortable: true,
        },

        {
          text: this.$t("oTHours"),
          align: "left",
          sortable: true,
          filterable: false,
        },

        {
          text: this.$t("oTStartDate"),
          align: "left",
          value: "otdate",
          sortable: true,
        },
        {
          text: this.$t("oTEndDate"),
          align: "left",
          value: "otenddate",
          sortable: true,
        },
        {
          text: this.$t("purposeofOT"),
          value: "purposeot",
          sortable: true,
          align: "left",
        },
        {
          text: this.$t("requestedDate"),
          value: "requestdate",
          sortable: true,
          align: "left",
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: true,
          align: "left",
        },
        {
          text: "Supervisor",
          sortable: true,
          align: "left",
          value: "firstName",
        },
        {
          text: this.$t("dateOfAction"),
          value: "approvedate",
          sortable: true,
          align: "left",
        },
      ];
    },
    computedDateFormatted4() {
      return this.formatDate(this.HRrequest.OTstartdate);
    },
    anyRemaining() {
      return this.requestlist.filter((item) => !item.check).length != 0;
    },
    computedDateFormatted5() {
      return this.formatDate(this.HRrequest.OTenddate);
    },
    computedDateFormatted6() {
      return this.formatDate(this.HRrequest.OTdate);
    },
    computedDateFormatted7() {
      return this.formatDate(this.exportrequest.Otstart);
    },
    computedDateFormatted8() {
      return this.formatDate(this.exportrequest.Otend);
    },
  },
  watch: {
    searchtxt: function () {
      if (this.runningvalue == 1) {
        this.Getrequestlist();
      }
    },
  },
  methods: {
    Search() {
      let self = this;
      self.runningvalue = 1;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.requestlist;
        self.requestlist = [];
        let Stxt = self.searchtxt.toLowerCase();
        for (let i = 0; i < List.length; i++) {
          let yesorno = false;
          if (List[i].name != "" && List[i].name != null) {
            if (List[i].name.toLowerCase().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (List[i].employee_Code != "" && List[i].employee_Code != null) {
            if (List[i].employee_Code.toLowerCase().toString().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (List[i].oT_Hours != "" && List[i].oT_Hours != null) {
            if (List[i].oT_Hours.toLowerCase().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (List[i].start_Date != "" && List[i].start_Date != null) {
            if (List[i].start_Date.toString().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (List[i].end_Date != "" && List[i].end_Date != null) {
            if (List[i].end_Date.toString().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (List[i].purpose_OT != "" && List[i].purpose_OT != null) {
            if (List[i].purpose_OT.toLowerCase().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (List[i].status != "" && List[i].status != null) {
            if (List[i].status.toLowerCase().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (List[i].firstName != "" && List[i].firstName != null) {
            if (List[i].firstName.toLowerCase().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (
            List[i].oT_Requested_Date != "" &&
            List[i].oT_Requested_Date != null
          ) {
            if (List[i].oT_Requested_Date.toString().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (
            List[i].oT_Approved_Date != "" &&
            List[i].oT_Approved_Date != null
          ) {
            if (List[i].oT_Approved_Date.toString().includes(Stxt)) {
              yesorno = true;
            }
          }
          if (yesorno == true) {
            self.requestlist.push(List[i]);
          }
        }
      } else {
        alert("Please enter text");
        self.Getrequestlist();
      }
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    convertUTCDateToLocalDate(date) {
      if (date != null) {
        date = new Date(date);

        let timezone = date.getTimezoneOffset() * 60000;
        let newDate = new Date(date.getTime() - timezone);
        let output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear();

        return output;
      }
    },
    exporting() {
      this.exportdialog = true;
      //
      if (this.alldate == true) {
        this.hidestartandenddate = false;
      } else {
        this.hidestartandenddate = true;
      }
      this.results = this.requestlist.filter(
        (date) => (date.request_On = date.oT_Approved_Date + "Z")
      );
      this.liesting = [];
      for (let i = 0; i < this.results.length; i++) {
        if (this.results[i].check == false) {
          this.liesting.push(false);
        } else {
          this.liesting.push(true);
        }
      }
      this.checkData = false;

      if (this.liesting.some((e) => e == true)) {
        this.checkData = false;
      } else {
        this.checkData = true;
      }
      if (this.checkData == true) {
        this.selectusertype = "Everyone";
      } else {
        this.selectusertype = "Selected Individual";
      }
    },
    checkSelectedAll() {
      // this.requestlist.map((e) => (e.check = event.target.checked));
      if (this.alldate == false) {
        this.reportDateFrom = new Date();
        this.reportDateTo = new Date();
        this.showdatepicker = true;
        this.Getbydatelist();
      } else {
        this.reportDateFrom = "";
        this.reportDateTo = "";
        this.showdatepicker = false;
        this.Getrequestlist();
      }
    },
    checkall() {
      this.exportlist = [];
      this.requestlist.map((e) => (e.check = event.target.checked));
      if (this.tablecheck == true) {
        this.checklist = true;
        if (this.itemforselect[1] != "Selected individual") {
          this.itemforselect.push("Selected individual");
        }

        for (let i = 0; i < this.requestlist.length; i++) {
          if (this.requestlist[i].check == true) {
            let mydata = [];
            // this.myexportlist.push(this.requestlist[i].oT_Request_ID);
            if (this.myexportlist.length != 0) {
              let myrequester = this.requestlist[i].requester_ID;
              // if (this.myexportlist.find((x) => x != myrequester)) {
              //   this.myexportlist.push(myrequester);
              // }
              mydata = this.myexportlist.filter((date) => date == myrequester);
              if (mydata.length == 0) {
                this.myexportlist.push(myrequester);
              }
            } else {
              this.myexportlist.push(this.requestlist[i].requester_ID);
            }
          }
        }
      } else {
        this.myexportlist = [];
      }
    },
    hideall() {
      let self = this;
      self.other = false;
      self.reportDateFrom = new Date();
      self.reportDateTo = new Date();
      self.Getbydatelist();
    },
    showstartandend() {
      let self = this;
      if (self.HRrequest.wholeDay == false) {
        self.showandhide = true;
      } else {
        self.showandhide = false;
      }
    },
    async Getbydatelist() {
      try {
        let temp = {
          company_ID: store.state.companyID,
        };
        const response = await axios.post(
          `${this.url}OT_Request/GetHROT_Request`,
          temp
        );
        let list = response.data.data;
        let filterList = list.filter((v) => {
          let stillUtc = moment.utc(v.oT_Approved_Date).toDate(); //still utc
          let request_On = moment(stillUtc).local().format("YYYY-MM-DD");
          return (
            request_On >= moment(this.reportDateFrom).format("YYYY-MM-DD") &&
            request_On <= moment(this.reportDateTo).format("YYYY-MM-DD")
          );
        });

        this.requestlist = filterList.map((e) => ({
          ...e,
          check: false,
        }));
        this.LoadingDialog = false;
      } catch (ex) {
        this.LogTrace(ex, "Get OT request Fail", 24, "Critical");
        alert(ex + "Correlation ID is 24");
        throw ex;
      }
    },

    selectedtype() {
      let self = this;
      if (self.selectusertype == "Select Users") {
        self.exportdialog = false;
        let gg = self.itemforselect.length - 1;
        self.itemforselect.splice(gg, 1);
        self.item2.splice(0, 1);
      }
      if (self.selectusertype == "Selected individual") {
        self.exportdialog = false;
      }
    },
    checkmylist(temp) {
      let self = this;
      let checkvalidate = this.requestlist.filter((date) => date.check == true);
      let removeitem = [];
      if (checkvalidate.length != 0) {
        self.checklist = true;
        if (self.itemforselect[1] != "Selected individual") {
          self.itemforselect.push("Selected individual");
        }

        if (self.myexportlist.length != 0) {
          let checkduplicate = this.myexportlist.filter(
            (date) => date == temp.requester_ID
          );
          if (checkduplicate.length == 0) {
            self.myexportlist.push(temp.requester_ID);
          }
        } else {
          self.myexportlist.push(temp.requester_ID);
        }
        
        for (let i = 0; i < self.myexportlist.length; i++) {
          let a = checkvalidate.filter(
            (date) => date.requester_ID == self.myexportlist[i]
          );
          let mydata = self.myexportlist[i];
          if (a.length == 0) {
            
            removeitem.push(mydata);
          }
        }
        if (removeitem.length != 0) {
          for (let a = 0; a < removeitem.length; a++) {
            self.myexportlist.splice(
              self.myexportlist.findIndex((v) => v == removeitem[a]),
              1
            );
          }
        }
      } else {
        //self.myexportlist=[];
        self.checklist = false;
      }
    },
    showexport() {
      let self = this;
      self.exportdialog = true;
      if (self.checklist == true) {
        self.selectusertype = self.itemforselect[1];
      } else {
        self.selectusertype = "Everyone";
      }
    },
    Getforrequestlist() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}OT_Request/GetHROT_Request`, temp)
        .then(function (response) {
          // let list = response.data.data;
          self.myrequestlist = response.data.data;
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get OT request Fail", 24, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    Getrequestlist() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}OT_Request/GetHROT_Request`, temp)
        .then(function (response) {
          let list = response.data.data;
          self.myrequestlist = response.data.data;
          self.requestlist = list.map((e) => ({
            ...e,
            check: false,
          }));
          self.LoadingDialog = false;
          self.runningvalue = 0;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get OT request Fail", 24, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    checkDateExport(value) {
      if (value < 10) {
        return "0" + value;
      } else {
        return value;
      }
    },
    detailrequest(temp) {
      let self = this;
      self.HRdialog = true;
      self.HRrequest.empname = temp.name;
      self.HRrequest.wholeDay = temp.wholeDay;
      self.HRrequest.OTstartdate = temp.start_Date;
      self.HRrequest.OTenddate = temp.end_Date;
      self.HRrequest.OTdate = temp.start_Date;
      self.HRrequest.Otstart = temp.oT_Start_Time;
      self.HRrequest.Otend = temp.oT_End_Time;
      self.HRrequest.Othours = temp.oT_Hours;
      self.HRrequest.purpose = temp.purpose_OT;
      self.HRrequest.approval = temp.firstName;
      self.HRrequest.status = temp.status;
      self.HRrequest.reason = temp.reason;
      if (temp.wholeDay == false) {
        self.showandhide = false;
      } else {
        self.showandhide = true;
      }
      if (temp.status == "Canceled") {
        this.showremark = true;
      } else {
        this.showremark = false;
      }
    },

    Exportallexcel() {
      let self = this;
      self.loadingdialog = true;
      // All PettyCash Request History
      if (self.selectusertype == "Everyone" && self.alldate == true) {
        let tempsheetname = "";
        let wb = XLSX.utils.book_new();
        let wsdata = [];
        self.exportdialog = false;
        for (let v of self.requestlist) {
          wsdata.push({
            ["Employee ID"]: v.employee_Code,
            ["Employee Name"]: v.name,
            ["OT hours"]: v.oT_Hours,
            ["OT start date"]: self.formatDate(v.start_Date),
            ["OT End Date"]: self.formatDate(v.end_Date),
            ["OT Start Time"]: v.oT_Start_Time,
            ["OT End Time"]: v.oT_End_Time,
            ["Purpose Of OT"]: v.purpose_OT,
            ["Requested Date"]: self.convertUTCDateToLocalDate(
              v.oT_Requested_Date
            ),
            ["Status"]: v.status,
            ["Reason"]: v.reason,
            ["Supervisor"]: v.firstName,
            ["DateofAction"]: self.convertUTCDateToLocalDate(
              v.oT_Approved_Date
            ),
          });
        }

        // wsdata.sort(function (a, b) {
        //   var order = -1;
        //   var mydate = self.convertUTCDateToLocalDate(a.DateofAction);
        //   var mysecdate = self.convertUTCDateToLocalDate(b.DateofAction);
        //   a = mydate;
        //   b = mysecdate;
        //   var results = a > b ? -1 : a < b ? 1 : 0;

        //   return results * order;
        // });

        const ws = XLSX.utils.json_to_sheet(wsdata);
        ws["!cols"] = [
          { wch: 10 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ];
        tempsheetname = `OptimisticOTRequestHistory_AllDate`;
        self.sheetTitle = ` All OT Request History`;
        XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);

        XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
          compression: true,
        });
      } else if (self.selectusertype == "Everyone" && self.alldate == false) {
        if (
          moment(this.exportrequest.Otstart) <
          moment(this.exportrequest.Otend).add(1, "day")
        ) {
          let inputdate = self.exportrequest.Otstart.split("-");
          // let mystartdate =
          //   inputdate[2] + "/" + inputdate[1] + "/" + inputdate[0];
          let inputenddate = self.exportrequest.Otend.split("-");
          // let myenddate =
          //   inputenddate[2] + "/" + inputenddate[1] + "/" + inputenddate[0];

          // let tempsheetname = "";
          // let wb = XLSX.utils.book_new();

          // let wsdata = [];

          self.exportlist = this.myrequestlist.filter((v) => {
            if (this.exportrequest.Otstart && this.exportrequest.Otend) {
              const between = moment.utc(v.oT_Approved_Date);
              return between.isBetween(
                moment(this.exportrequest.Otstart),
                moment(this.exportrequest.Otend).add(1, "day")
              );
            }

            return true;
          });

          self.LoadingDialog = false;
          self.exportdialog = false;

          let hasData = false;
          let lastSheetData = [];

          self.selectusertype = "Everyone";
          self.myexportlist = [];
          let wb = XLSX.utils.book_new();
          let mynewstartdate = new Date(
            inputdate[0],
            inputdate[1],
            inputdate[2]
          );
          let mynewenddate = new Date(
            inputenddate[0],
            inputenddate[1],
            inputenddate[2]
          );
          let wsdata = [];
          let Difference_In_Time =
            mynewenddate.getTime() - mynewstartdate.getTime();
          let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
          let totalsheet = Difference_In_Days;

          for (let sheet = 0; sheet < totalsheet; sheet++) {
            let tempsheetdata = [];
            let input = parseInt(inputdate[2]) + sheet;
            wsdata = [];
            let startoftheyear = new Date(
              inputdate[0],
              inputdate[1] - 1,
              input
            );

            tempsheetdata = self.exportlist.filter((x) => {
              let tempdate2 = moment.utc(x.oT_Approved_Date).toDate();
              let date2 = moment(tempdate2).local().format("YYYY-MM-DD ");
              let myinputdate = date2.split("-");
              let mydatelist = new Date(
                myinputdate[0],
                myinputdate[1] - 1,
                myinputdate[2]
              );

              return mydatelist.getTime() === startoftheyear.getTime();
            });
            let tempsheetname =
              self.checkDateExport(startoftheyear.getDate()) +
              "_" +
              self.checkDateExport(startoftheyear.getMonth() + 1) +
              "_" +
              startoftheyear.getFullYear();

            if (self.exportlist.length != lastSheetData.length) {
              lastSheetData = [...lastSheetData, ...tempsheetdata];
            }
            if (tempsheetdata.length) {
              for (let j = 0; j < tempsheetdata.length; j++) {
                wsdata.push({
                  ["Employee ID"]: tempsheetdata[j].employee_Code,
                  ["Employee Name"]: tempsheetdata[j].name,
                  ["OT hours"]: tempsheetdata[j].oT_Hours,
                  ["OT start date"]: self.formatDate(
                    tempsheetdata[j].start_Date
                  ),
                  ["OT End Date"]: self.formatDate(tempsheetdata[j].end_Date),
                  ["OT Start Time"]: tempsheetdata[j].oT_Start_Time,
                  ["OT End Time"]: tempsheetdata[j].oT_End_Time,
                  ["Purpose Of OT"]: tempsheetdata[j].purpose_OT,
                  ["Requested Date"]: self.convertUTCDateToLocalDate(
                    tempsheetdata[j].oT_Requested_Date
                  ),
                  ["Status"]: tempsheetdata[j].status,
                  ["Reason"]: tempsheetdata[j].reason,
                  ["Supervisor"]: tempsheetdata[j].firstName,
                  ["Date of Action"]: self.convertUTCDateToLocalDate(
                    tempsheetdata[j].oT_Approved_Date
                  ),
                });
              }
              var ws = XLSX.utils.json_to_sheet(wsdata);
              ws["!cols"] = [
                { wch: 20 },
                { wch: 25 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
              ];
              XLSX.utils.book_append_sheet(wb, ws, tempsheetname);
              hasData = true;
            }
          }

          if (!hasData) {
            alert("Your selected date range doesn't have Data!");
          } else {
            let wsalldata = [];
            lastSheetData.sort(function (a, b) {
              var order = 1;
              a = new Date(self.convertUTCDateToLocalDate(a.oT_Approved_Date));
              b = new Date(self.convertUTCDateToLocalDate(b.oT_Approved_Date));
              // a = mydate;
              // b = mysecdate;
              var results = a > b ? -1 : a < b ? 1 : 0;

              return results * order;
            });
            lastSheetData.reverse();

            for (let j = 0; j < lastSheetData.length; j++) {
              wsalldata.push({
                ["Employee ID"]: lastSheetData[j].employee_Code,
                ["Employee Name"]: lastSheetData[j].name,
                ["OT hours"]: lastSheetData[j].oT_Hours,
                ["OT start date"]: self.formatDate(lastSheetData[j].start_Date),
                ["OT End Date"]: self.formatDate(lastSheetData[j].end_Date),
                ["OT Start Time"]: lastSheetData[j].oT_Start_Time,
                ["OT End Time"]: lastSheetData[j].oT_End_Time,
                ["Purpose Of OT"]: lastSheetData[j].purpose_OT,
                ["Requested Date"]: self.convertUTCDateToLocalDate(
                  lastSheetData[j].oT_Requested_Date
                ),
                ["Status"]: lastSheetData[j].status,
                ["Reason"]: lastSheetData[j].reason,
                ["Supervisor"]: lastSheetData[j].firstName,
                ["Date of Action"]: self.convertUTCDateToLocalDate(
                  lastSheetData[j].oT_Approved_Date
                ),
              });
            }
            ws = XLSX.utils.json_to_sheet(wsalldata);
            ws["!cols"] = [
              { wch: 20 },
              { wch: 25 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];

            let datestring1 =
              inputdate[2] + "_" + inputdate[1] + "_" + inputdate[0];
            let datestring2 =
              inputenddate[2] + "_" + inputenddate[1] + "_" + inputenddate[0];
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              datestring1 + "-" + datestring2
            );
          }

          if (hasData) {
            let exportExcelName = `OptimisticOTRequestHistory_${self.excelExportDateRange()}.xlsx`;

            self.exportList = [];
            self.exportrequest.Otstart = "";
            self.exportrequest.Otend = "";
            XLSX.writeFile(wb, exportExcelName);
          }
        } else {
          self.loadingdialog = false;
          alert("Start date can’t be after end date");
        }
      }
      // }
      else if (
        self.selectusertype == "Selected Individual" &&
        self.alldate == true
      ) {
       
        let combilearrary = [];
        
        for (let i = 0; i < self.myexportlist.length; i++) {
          self.tempexport = {
            oT_Request_ID: self.myexportlist[i],
          };
          combilearrary.push(self.tempexport);
        }
        let temp = {
          requestID: combilearrary,

          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}OT_Request/Getspecifichralldate`, temp)
          .then(function (response) {
            self.exportlist = response.data.data;
            let tempsheetname = "";
            let wb = XLSX.utils.book_new();
            self.exportdialog = false;

            let wsdata = [];
            for (let v of self.exportlist) {
              wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.name + " " + v.lastName,
                ["OT hours"]: v.oT_Hours,
                ["OT start date"]: self.formatDate(v.start_Date),
                ["OT End Date"]: self.formatDate(v.end_Date),
                ["OT Start Time"]: v.oT_Start_Time,
                ["OT End Time"]: v.oT_End_Time,
                ["Purpose Of OT"]: v.purpose_OT,
                ["Requested Date"]: self.convertUTCDateToLocalDate(
                  v.oT_Requested_Date
                ),
                ["Status"]: v.status,
                ["Reason"]: v.reason,
                ["Supervisor"]: v.firstName + " " + v.myLastName,
                ["DateofAction"]: v.oT_Approved_Date,
              });
            }

            for (var j = 0; j < wsdata.length; j++) {
              wsdata[j].DateofAction = self.convertUTCDateToLocalDate(
                wsdata[j].DateofAction
              );
            }
            const ws = XLSX.utils.json_to_sheet(wsdata);
            ws["!cols"] = [
              { wch: 10 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];
            tempsheetname = `OptimisticOTRequestHistory_AllDate`;
            self.sheetTitle = ` All OT Request History`;
            XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);

            XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
              compression: true,
            });
          });
      } else if (
        self.selectusertype == "Selected Individual" &&
        self.alldate == false
      ) {
        if (
          self.exportrequest.Otstart != "" &&
          self.exportrequest.Otend != ""
        ) {
          let inputdate = self.exportrequest.Otstart.split("-");

          let inputenddate = self.exportrequest.Otend.split("-");
          let combilearrary = [];
          for (let i = 0; i < self.myexportlist.length; i++) {
            self.tempexport = {
              oT_Request_ID: self.myexportlist[i],
            };
            combilearrary.push(self.tempexport);
          }
          let result = this.myrequestlist.filter((o1) =>
            combilearrary.some((o2) => o1.requester_ID === o2.oT_Request_ID)
          );
          self.exportlist = result.filter((v) => {
            if (this.exportrequest.Otstart && this.exportrequest.Otend) {
              const between = moment.utc(v.oT_Approved_Date);
              return between.isBetween(
                moment(this.exportrequest.Otstart),
                moment(this.exportrequest.Otend).add(1, "day")
              );
            }

            return true;
          });

          self.LoadingDialog = false;
          self.Getforrequestlist();
          self.exportdialog = false;
          let hasData = false;
          let lastSheetData = [];

          self.selectusertype = "Everyone";
          //self.myexportlist = [];
          let wb = XLSX.utils.book_new();
          let mynewstartdate = new Date(
            inputdate[0],
            inputdate[1],
            inputdate[2]
          );
          let mynewenddate = new Date(
            inputenddate[0],
            inputenddate[1],
            inputenddate[2]
          );
          let wsdata = [];
          let Difference_In_Time =
            mynewenddate.getTime() - mynewstartdate.getTime();
          let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
          let totalsheet = Difference_In_Days;

          for (let sheet = 0; sheet < totalsheet; sheet++) {
            let tempsheetdata = [];
            let input = parseInt(inputdate[2]) + sheet;
            wsdata = [];
            let startoftheyear = new Date(
              inputdate[0],
              inputdate[1] - 1,
              input
            );

            tempsheetdata = self.exportlist.filter((x) => {
              let tempdate2 = moment.utc(x.oT_Approved_Date).toDate();
              let date2 = moment(tempdate2).local().format("YYYY-MM-DD ");
              let myinputdate = date2.split("-");
              let mydatelist = new Date(
                myinputdate[0],
                myinputdate[1] - 1,
                myinputdate[2]
              );
              return mydatelist.getTime() === startoftheyear.getTime();
            });
            let tempsheetname =
              self.checkDateExport(startoftheyear.getDate()) +
              "_" +
              self.checkDateExport(startoftheyear.getMonth() + 1) +
              "_" +
              startoftheyear.getFullYear();

            if (self.exportlist.length != lastSheetData.length) {
              lastSheetData = [...lastSheetData, ...tempsheetdata];
            }
            if (tempsheetdata.length) {
              for (let j = 0; j < tempsheetdata.length; j++) {
                wsdata.push({
                  ["Employee ID"]: tempsheetdata[j].employee_Code,
                  ["Employee Name"]: tempsheetdata[j].name,
                  ["OT hours"]: tempsheetdata[j].oT_Hours,
                  ["OT start date"]: self.formatDate(
                    tempsheetdata[j].start_Date
                  ),
                  ["OT End Date"]: self.formatDate(tempsheetdata[j].end_Date),
                  ["OT Start Time"]: tempsheetdata[j].oT_Start_Time,
                  ["OT End Time"]: tempsheetdata[j].oT_End_Time,
                  ["Purpose of OT"]: tempsheetdata[j].purpose_OT,
                  ["Requested Date"]: self.convertUTCDateToLocalDate(
                    tempsheetdata[j].oT_Requested_Date
                  ),
                  ["Status"]: tempsheetdata[j].status,
                  ["Reason"]: tempsheetdata[j].reason,
                  ["Supervisor"]: tempsheetdata[j].firstName,
                  ["Date of Action"]: self.convertUTCDateToLocalDate(
                    tempsheetdata[j].oT_Approved_Date
                  ),
                });
              }
              var ws1 = XLSX.utils.json_to_sheet(wsdata);
              ws1["!cols"] = [
                { wch: 20 },
                { wch: 25 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
              ];
              XLSX.utils.book_append_sheet(wb, ws1, tempsheetname);
              hasData = true;
            }
          }

          if (!hasData) {
            alert("Your selected date range doesn't have Data!");
          } else {
            let wsalldata = [];
            lastSheetData.sort(function (a, b) {
              var order = 1;
              a = new Date(self.convertUTCDateToLocalDate(a.oT_Approved_Date));
              b = new Date(self.convertUTCDateToLocalDate(b.oT_Approved_Date));
              // a = mydate;
              // b = mysecdate;
              var results = a > b ? -1 : a < b ? 1 : 0;

              return results * order;
            });
            lastSheetData.reverse();
            for (let j = 0; j < lastSheetData.length; j++) {
              wsalldata.push({
                ["Employee ID"]: lastSheetData[j].employee_Code,
                ["Employee Name"]: lastSheetData[j].name,
                ["OT hours"]: lastSheetData[j].oT_Hours,
                ["OT start date"]: self.formatDate(lastSheetData[j].start_Date),
                ["OT End Date"]: self.formatDate(lastSheetData[j].end_Date),
                ["OT Start Time"]: lastSheetData[j].oT_Start_Time,
                ["OT End Time"]: lastSheetData[j].oT_End_Time,
                ["Purpose Of OT"]: lastSheetData[j].purpose_OT,
                ["Requested Date"]: self.convertUTCDateToLocalDate(
                  lastSheetData[j].oT_Requested_Date
                ),
                ["Status"]: lastSheetData[j].status,
                ["Reason"]: lastSheetData[j].reason,
                ["Supervisor"]: lastSheetData[j].firstName,
                ["Date of Action"]: self.convertUTCDateToLocalDate(
                  lastSheetData[j].oT_Approved_Date
                ),
              });
            }
            ws = XLSX.utils.json_to_sheet(wsalldata);
            ws["!cols"] = [
              { wch: 20 },
              { wch: 25 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];
            let datestring1 =
              inputdate[2] + "_" + inputdate[1] + "_" + inputdate[0];
            let datestring2 =
              inputenddate[2] + "_" + inputenddate[1] + "_" + inputenddate[0];
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              datestring1 + "-" + datestring2
            );
          }

          if (hasData) {
            let exportExcelName = `OptimisticOTRequestHistory_${self.excelExportDateRange()}.xlsx`;
            self.exportList = [];
            self.exportrequest.Otstart = "";
            self.exportrequest.Otend = "";
            XLSX.writeFile(wb, exportExcelName);
          }
        }
      }
    },
    excelExportDateRange() {
      let self = this;
      let inputdate = self.exportrequest.Otstart.split("-");

      let inputenddate = self.exportrequest.Otend.split("-");

      let datestring1 = inputdate[2] + "-" + inputdate[1] + "-" + inputdate[0];
      let datestring2 =
        inputenddate[2] + "-" + inputenddate[1] + "-" + inputenddate[0];
      return `${datestring1}-${datestring2}`;
    },
    getapprovename() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}OT_Request/GetLevel`, temp)
        .then(function (response) {
          self.approvelist = response.data.data;
          if (store.state.roleID == "HR") {
            self.showpend = false;
          }
        });
    },
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .btn_approveDeny {
    margin-left: -6rem !important;
    margin-top: 1rem !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .btn_approveDeny {
    margin-left: 6rem !important;
    margin-top: 1rem !important;
  }
}
.Ot_editadd {
  overflow-x: hidden;
}
@media only screen and (max-width: 320px) {
  .month_yeardate {
    margin-left: unset !important;
  }
}
@media only screen and (max-width: 768px) {
  .month_yeardate {
    width: 100px !important;
  }
}
@media only screen and (max-width: 768px) {
  .date_fmt {
    margin-right: 7rem !important;
  }
}
@media only screen and (max-width: 768px) {
  .historydate_fmt {
    margin-right: 3.8rem !important;
  }
}
.empID {
  font-family: "Kanit", sans-serif;
}
.firsttab {
  background-color: white;
  width: 140px;
}
.deletepadd {
  padding: 0;
}
.tabone {
  width: 250px;
  border: solid thin #f99d20;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 24px;
}
.actionbtn {
  width: 100%;
}
.actionbtns {
  width: 100%;
}
@media (max-width: 325px) {
  .firsttab {
    width: 100px;
  }
}
@media (max-width: 1024px) {
  .actionbtn {
    width: 7rem !important;
    margin-left: -3rem;
  }
  .actionbtns {
    width: 7rem !important;
  }
}
@media (max-width: 325px) {
  .tabone {
    width: 100px;
  }
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}
>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 70px;
}
@media (max-width: 325px) {
  .firsttab {
    width: 100px;
  }
}
@media (min-width: 1264px) {
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 20%;
    max-width: 25%;
  }
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border: unset;
}

@media (min-height: 1300px) and (min-width: 1000px) {
  .align {
    margin-left: 0;
    margin-right: 50px;
    padding: 0;
  }
}

@media (min-height: 750px) and (min-width: 1000px) {
  .align {
    margin-left: 0;
    margin-right: 50px;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-height: 700px) and (min-width: 1200px) {
  .align {
    margin-left: 0;
    margin-right: 50px;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 540px) {
  .fix-text {
    margin-left: 1rem !important;
  }
}
</style>