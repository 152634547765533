<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="ml-4 headtext txt-header">
        {{ $t("TimeOffReport") }}
      </p>
    </div>

    <v-row style="margin-right: 0; margin-left: -2px">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pt-1 mb-5 card" height="100%">
          <div class="di">
            <v-divider v-show="timeoffused" />
            <v-list v-show="timeoffused">
              <v-list-item router to="/timeoffreport/timeoffused">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.Timeoffused"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("EmployeeLeaveSummary")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="timeoffused" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      timeoff: "Time Off Used",
      item: {
        Timeoffused: require("@/assets/images/clock@2x.png"),
      },
      timeoffused: false,
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      this.timeoffused = true;
    }

    if (perid.some((e) => e == 182)) {
      this.timeoffused = true;
    }
  },
};
</script>
<style scoped>
.card {
  /* margin-bottom: 5rem; */
  margin-left: 1%;
  margin-right: 0.3%;
  /* height: 1000px; */
  /* margin-top: -1.5rem; */
}
.titles {
  color: #f89d1f;
  font-weight: bold;
  font-size: 17px;
  margin-top: -1rem;
}
.di {
  margin: 20px;
  margin-bottom: 3rem;
}
</style>

