<template>
  <div
    class="hello"
    style="padding: 0px; background: #fff2e3; width: 100%"
    v-show="rememberme == 'false'"
  >
    <v-container fluid>
      <v-row>
        <v-col
          xs="6"
          sm="6"
          md="5"
          lg="5"
          xl="6"
          class="hello-left hidden-sm-and-down pm"
        >
          <v-img
            class="image"
            src="@/assets/HomeImage.png"
            :height="customImgHeight"
          ></v-img>
        </v-col>
        <v-col
          xs="6"
          sm="6"
          md="7"
          lg="7"
          xl="6"
          class="hello-right ma-0 pr-2"
          style="background-color: #fff2e3"
          align="center"
          justify="center"
        >
          <div
            style="background:#fff2e3;width:90%;max-width:90%; padding:0;height:85%,max-height:100%;     "
          >
            <JoinCompany />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import JoinCompany from "./joinCompany";
export default {
  components: {
    JoinCompany,
  },
  data() {
    return { customImgHeight: "100%", rememberme: "false" };
  },
  mounted() {
    let self = this;
    self.rememberme = self.readCookie("rememberme");
    return self.rememberme;
  },
  methods: {
    readCookie(name) {
      var nameEQ = name + "=";

      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return this.rememberme;
    },
  },

  watch: {
    $route() {
      if (this.$route.path == "/new") {
        this.customImgHeight = "100vh";
      } else {
        this.customImgHeight = "100vh";
      }
    },
  },
};
</script>
<style scoped>
@media (max-width: 400px) {
  .hello-right {
    height: 120% !important;
  }
}
>>> .v-responsive__content {
  width: unset !important;
}
.container {
  width: 100%;
  padding: 12px;
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
}
.container {
  padding: 0px;
}
.hello {
  background-color: #fff2e3;
  height: fit-content;
  padding: 0px;
  margin: 0px;
}
.hello-left {
  padding: 0px;
  margin: 0px;
}
.image {
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 200vh;
}
.image-container {
  padding: 0px;
  margin: 0px;

  min-height: 105vh;
  background-color: green;
  max-height: 200vh;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  background: #fff2e3;
  flex-grow: 1;
  max-width: 100%;
}

@media (max-height: 800px) and (max-width: 1240px) {
  .image {
    min-height: 150vh;
    width: 100%;
    height: 120vh;
    max-width: 100%;
    max-height: 200vh;
  }
}

@media (max-height: 550px) and (max-width: 1240px) {
  .image {
    min-height: 200vh;
    width: 100%;
    height: 110vh;
    max-width: 100%;
    max-height: 200vh;
  }
}

@media (max-height: 400px) and (max-width: 1240px) {
  .image {
    min-height: 200vh;
    width: 100%;
    height: 200vh;
    max-width: 100%;
    max-height: 200vh;
  }
}

@media (max-height: 450px) and (min-height: 400px) {
  .image {
    width: 100%;
    height: 150vh;
    max-width: 100%;
    max-height: 200vh;
  }
}
@media (max-height: 400px) and (min-height: 300px) {
  .image {
    width: 100%;
    height: 300vh;
    max-width: 100%;
    max-height: 400vh;
  }
}

/* @media (max-width: 414px) {
.hello-right{
  height: 103vh;
}
} */
@media (max-width: 768px) {
  /* .container{
  height: 103vh;
   width: 1100px; 
} */
  .hello-right {
    height: 115vh;
  }
}
/* @media only screen and (max-width: 600px) {
  .hello-left {
    height: 130vh;
  }
} */
</style>