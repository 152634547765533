<template>
    <div ref="pdfContainer" class="pdf-container">
      <!-- Resize Sensor to detect container size changes -->
      <resize-sensor @resize="resizePdf"></resize-sensor>
  
      <!-- PDF Viewer with dynamic width and height based on container size -->
      <pdf
        v-if="pdfUrl"
        :src="pdfUrl"
        :page="page"
        :width="containerWidth"
        :height="containerHeight"
        @num-pages="onNumPages"
        @page-rendered="onPageRendered"
        @error="onError"
      />
  
      <!-- Pagination Controls -->
      <div class="pagination">
        <button @click="prevPage" :disabled="page <= 1">Previous</button>
        <span>Page {{ page }} of {{ numPages }}</span>
        <button @click="nextPage" :disabled="page >= numPages">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import ResizeSensor from "vue-resize-sensor";
  import pdf from "vue-pdf";
  
  export default {
    components: {
      ResizeSensor,
      pdf,
    },
    props: {
      blobUrl: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        pdfUrl: null,
        page: 1,
        numPages: 0,
        containerWidth: 0,
        containerHeight: 0,
        padding: 20,
      };
    },
    mounted() {
      this.pdfUrl = this.blobUrl;
      this.updateContainerDimensions();
    },
    methods: {
      resizePdf() {
        this.updateContainerDimensions();
      },
      updateContainerDimensions() {
        const container = this.$refs.pdfContainer;
        this.containerWidth = container.clientWidth;
        this.containerHeight = container.clientHeight;
      },
      onNumPages(num) {
        this.numPages = num;
      },
      onPageRendered() {
        // Optional: Handle any actions after a page is rendered
      },
      onError(error) {
        console.error("PDF Error:", error);
      },
      prevPage() {
        if (this.page > 1) {
          this.page -= 1;
        }
      },
      nextPage() {
        if (this.page < this.numPages) {
          this.page += 1;
        }
      },
    },
    beforeDestroy() {
      if (this.pdfUrl) {
        URL.revokeObjectURL(this.pdfUrl);
      }
    },
  };
  </script>
  
  <style scoped>
  .pdf-container {
    width: 100%;
    height: 100%;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .pagination button {
    margin: 0 10px;
    padding: 5px 10px;
  }
  </style>
  