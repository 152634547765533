<template>
  <div>
    <div style="margin-left: 1.5rem; margin-right: 1.5rem">
      <div style="display:flex;">
        <p class="ml-4 headtext txt-header">
          {{ $t("home") }}
        </p>
      </div>
      
      <div class="d-flex-row flex-wrap pl-0 pr-3 mx-0">
        <v-card class="mt-6" height="60px" :ripple="false" v-show="ShowTimeCard">
          <div class="pa-3">
            <v-btn v-show="showClockIn" color="#0FA7D8" class="white--text text-capitalize empID ml-1"
              style="font-weight: normal" width="180" @click="OpenClockInDialog()"
              :disabled="disableCin || permission.clockinout" max-height="34">
              <img src="@/assets/images/Group 3194.png" class="pr-2" />
              {{ $t("Clock-in") }}
            </v-btn>
            <v-btn v-show="showClockOut" color="#FF0000" class="white--text ml-1 text-capitalize empID" width="180"
              style="font-weight: normal" @click="OpenClockOutDialog()" :disabled="permission.clockinout" max-height="34">
              <img src="@/assets/images/Group 3195.png" class="pr-2" />
              {{ $t("Clock-out") }}
            </v-btn>
          </div>
        </v-card>

        <div>
          <v-row class="mt-1" elevation="0">
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="9">
              <v-card class="mycard" v-if="permissionview.view == true">
                <div class="fixed-header">
                  <v-row class="d-flex-row flex-wrap align-start ma-3 mb-1 pr-1">
                    <img class="commonstatisticsIcon mt-3" :src="images.announcement" alt="announcement" />
                    <p style="color: #F99D20; font-size: 18px" class="pl-3 mt-3">
                      {{ $t("announcements") }}
                    </p>
                    <v-spacer></v-spacer>
                    <div class="text-capitalize mt-2" style="font-size: 16px; color: #F99D20;text-decoration: underline;cursor: pointer;" @click="annList()">
                      {{ $t("seeAll") }}
                    </div>
                    <div v-if="permission.add == false" class="pl-3">
                      <v-btn color="#F99D20" class="text-capitalize mt-1" style="font-size: 16px; color: #ffffff" block
                        max-height="35" to="/home/newAnnouncement">
                        <img class="pr-2" style="width:100%; height:100%;" :src="images.addAnnouncement" alt="addAnnouncement" />
                        {{ $t("newPost") }}
                      </v-btn>
                    </div>
                  </v-row>
                  <v-divider class="ml-3 mr-3"></v-divider>
                </div>
                
                <v-row class="pl-6 pr-6" v-if="!resultannoncementlist.length">
                  <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="p-3">
                    <p style="color: #f99d20; font-size: 16px">
                      {{ $t("noAnnouncements") }}
                    </p>
                  </v-col>
                </v-row>

                <v-list v-else>
                  <v-list-item :ripple="false">
                    <v-row class="scroll-container">
                      <v-col v-for="item in resultannoncementlist" :key="item.announcement_ID" cols="12" xs="12" sm="12" md="12" lg="12">
                        <div class="flex-container">
                          <div class="image-container">
                            <div style="width: 255px; height: 155px;">
                              <img :src="item.announcement_Image" style="width: 100%; height: 100%; display: block;border-radius: 10px;" alt="Announcement Image" />
                            </div>
                          </div>
                          <div class="content-container ml-4" style="position: relative; overflow: hidden;">
                            <div class="card-title-container">
                              <div class="d-flex justify-space-between mt-auto" style="display: flex; justify-content: space-between;">
                                <div class="d-flex align-center" style="display: flex; align-items: center;">
                                  <p class="cardtitle line-clamp" style="font-size: 16px;color:#444444;">
                                    {{ item.announcement_Title }}
                                  </p>
                                </div>
                                <div class="d-flex align-center mr-5" style="display: flex; align-items: center; margin-right: 20px;">
                                  <v-img class="d-flex align-center pinIcon" :src="images.pinIcon" v-if="item.status"></v-img>
                                </div>
                              </div>
                              <div class="announcement-detail1 pt-3">
                                <div class="announcement-text" v-html="item.announcement_Detail"></div>
                              </div>
                              <div class="pt-5">
                                <p text style="font-size: 14px; color: #878585;">
                                  {{ item.formattedAnnouncementDate }}
                                </p>
                              </div>
                              <div class="pb-5 pl-1" style="position: absolute; bottom: 0; left: 0; right: 0;">
                                <div class="d-flex justify-space-between mt-auto" style="display: flex; justify-content: space-between;">
                                  <div class="d-flex align-center" style="display: flex; align-items: center;">
                                    <img class="eyeIcon" :src="images.eye"/>
                                    <span class="ml-1">
                                      {{ item.viewCount }}
                                    </span>
                                    <img style="margin-left: 8px;" v-if="!item.disable_Comments" class="messageIcon" src="@/assets/images/chat-dots-fill-svgrepo-com.svg" />
                                    <span class="ml-1" v-if="!item.disable_Comments">
                                      {{ item.countcomment }}
                                    </span>
                                  </div>
                                  <div class="d-flex align-center mr-5" style="display: flex; align-items: center; margin-right: 20px;">
                                    <p @click="back(item.announcement_ID)" style="font-size: 12px; color: #0fa7d8; cursor: pointer;">
                                      {{ $t("ReadMore") }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
              
              <v-card class="mycard" v-else>
                <div class="fixed-header">
                  <v-row class="d-flex-row flex-wrap align-start ma-3 mb-1 pr-1">
                  <img class="commonstatisticsIcon mt-3" :src="images.announcement" alt="announcement" />
                  <p style="color: #F99D20; font-size: 18px;" class="pl-3 mt-3">
                    {{ $t("announcements") }}
                  </p>
                  <v-spacer></v-spacer>
                  <div class="text-capitalize mt-2" style="font-size: 16px; color: #F99D20;text-decoration: underline;cursor: pointer;" @click="annList()">
                    {{ $t("seeAll") }}
                  </div>
                  <div v-if="permission.add == false" class="pl-3">
                    <v-btn color="#F99D20" class="text-capitalize mt-1" style="font-size: 16px; color: #ffffff" block
                      max-height="35" to="/home/newAnnouncement">
                      <img class="pr-2" style="width:100%; height:100%;" :src="images.addAnnouncement" alt="addAnnouncement" />
                      {{ $t("newPost") }}
                    </v-btn>
                  </div>
                </v-row>
                <v-divider class="ml-3 mr-3"></v-divider>
                </div>
                

                <v-row class="pl-6 pr-6" v-if="!resultannoncementlist.length">
                  <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="p-3">
                    <p style="color: #f99d20; font-size: 16px">
                      {{ $t("noAnnouncements") }}
                    </p>
                  </v-col>
                </v-row>

                <v-list v-else>
                  <v-list-item :ripple="false">
                    <v-row class="scroll-container">
                      <v-col v-for="item in resultannoncementlist" :key="item.announcement_ID" cols="12" xs="12" sm="12" md="12" lg="12">
                        <div class="flex-container">
                          <div class="image-container">
                            <div style="width: 255px; height: 155px;">
                              <img :src="item.announcement_Image" style="width: 100%; height: 100%; display: block;border-radius: 10px;" alt="Announcement Image" />
                            </div>
                          </div>
                          <div class="content-container ml-4" style="position: relative;overflow: hidden;">
                            <div class="card-title-container">
                              <div class="d-flex justify-space-between mt-auto" style="display: flex; justify-content: space-between;">
                                <div class="d-flex align-center" style="display: flex; align-items: center;">
                                  <p class="cardtitle line-clamp" style="font-size: 16px;font-weight:bold; color:#444444;">
                                    {{ item.announcement_Title }}
                                  </p>
                                </div>
                                <div class="d-flex align-center mr-5" style="display: flex; align-items: center; margin-right: 20px;">
                                  <v-img class="d-flex align-center pinIcon" :src="images.pinIcon" v-if="item.status"></v-img>
                                </div>
                              </div>
                              <div class="announcement-detail1 pt-3">
                                <div class="announcement-text" v-html="item.announcement_Detail"></div>
                              </div>
                              <div class="pt-5">
                                <p text style="font-size: 14px; color: #878585;">
                                  {{ item.formattedAnnouncementDate }}
                                </p>
                              </div>
                              
                              <div class="pb-5 pl-1" style="position: absolute; bottom: 0; left: 0; right: 0;">                              
                                <div class="d-flex justify-space-between mt-auto" style="display: flex; justify-content: space-between;">
                                  <div class="d-flex align-center" style="display: flex; align-items: center;">
                                    <img class="eyeIcon" :src="images.eye"/>
                                    <span class="ml-1">
                                      {{ item.viewCount }}
                                    </span>
                                    <img style="margin-left: 8px; width: 18px; height: 18px;" v-if="!item.disable_Comments" class="messageIcon" src="@/assets/images/chat-dots-fill-svgrepo-com.svg" />
                                    <span class="ml-1" v-if="!item.disable_Comments">
                                      {{ item.countcomment }}
                                    </span>
                                  </div>
                                  <div class="d-flex align-center mr-5" style="display: flex; align-items: center; margin-right: 20px;">
                                    <p @click="back(item.announcement_ID)" style="font-size: 12px; color: #0fa7d8; cursor: pointer;">
                                      {{ $t("ReadMore") }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="3">
              <v-card class="celebrationcard" :style="{ height: permissionview.view == true ? '520px' : '520px'}">
                <div class="fixed-header">
                  <div class="d-flex flex-wrap ml-2 mt-1">
                    <img class="celebrationIcon" :src="images.celebration" alt="celebration" />
                    <div style="margin-left: 0.5rem" class="ma-2">
                      <p style="margin-top: 10px; color: #F99D20; font-size: 18px;">
                        {{ $t("celebration") }}
                      </p>
                    </div>
                  </div>
                  <v-divider class="ml-3 mr-3 mt-5"></v-divider>
                </div>

                <div v-if="celebrationexist">
                  <div v-for="item in birthdaylist" :key="item.employee_ID">
                    <v-list-item>
                      <v-avatar width="35" height="35" style="margin-left: auto; margin-right: auto">
                        <img :src="item.image" alt="profileImage"/>
                      </v-avatar>
                      <v-list-item-content class="ml-3">
                        <v-list-item-title style="font-size: 17px">
                          {{ item.fullName }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 15px">
                          {{ item.birth }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <img class="birthdayIcon mr-3" :src="images.birthday" alt="birthday" />
                    </v-list-item>
                  </div>
                </div>

                <div v-if="!celebrationexist" style="
                    text-align: center;
                    align-items: center;
                    justify-content: center;">
                  <p style="color: rgba(0, 0, 0, 0.6); font-size: 16px">
                    {{ $t("noEventsToday") }}..
                  </p>
                </div>

                <div v-for="item in anniversarylist" :key="item.employee_ID">
                  <v-list-item>
                    <v-avatar width="35" height="35" style="margin-left: auto; margin-right: auto">
                      <img :src="item.image" alt="profileImage"/>
                    </v-avatar>
                    <v-list-item-content class="ml-3">
                      <v-list-item-title style="font-size: 17px">
                        {{ item.fullName }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 15px">
                        {{ item.workanni }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <img class="birthdayIcon mr-3" :src="images.anniversary" alt="anniversary" />
                  </v-list-item>
                </div>
              </v-card>
            </v-col>
          </v-row>
          
          <div v-if="permissionview.view == true">
            <v-row class="mb-3">
              <v-col cols="12" xs="12" sm="7" md="7" lg="7">
                <CommonStatistics @component-ready="onComponentReady"></CommonStatistics>
              </v-col>
              <v-col class="px-sm-and-up-0" cols="12" xs="12" sm="5" md="5" lg="5">
                <OnTimeOff @component-ready="onComponentReady"></OnTimeOff>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>

    <!-- open clok-in dialog -->
    <v-dialog v-model="ClockInDialog" persistent max-width="408px">
      <v-card ref="form">
        <v-card-title>
          <p style="margin-top: 20px; margin-bottom: 0px">
            {{ $t("Clock-in") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-0">
            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="clock.timeType" class="empID" style="margin-top: 0.95rem" :label="$t('TimeType')"
                  item-text="txt" item-value="val" :items="timeTypeItem" :error-messages="error.errormessagetimetype"
                  append-icon="mdi-chevron-down" color="#F89D1F"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">{{ $t("Clock-inTime") }}</v-col>
              <v-col cols="6">{{ cintime }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6">{{ $t("Clock-outTime") }}</v-col>
              <v-col cols="6">-</v-col>
            </v-row>
            <v-row align="center">
              <v-col align="center" cols="12">
                <p @click="openmap()" style="
                    color: #0fa7d8;
                    text-decoration: underline;
                    cursor: pointer;
                  ">
                  {{ $t("ViewClock-inMap") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-container>
          <v-row no-gutters>
            <v-col cols="12" sm="12" lg="6" xs="12" xl="6" class="pr-2">
              <v-btn class="text-capitalize mt-2" outlined width="180" block max-height="35"
                @click="CloseCinDialog()" text>{{ $t("Cancel") }}</v-btn>
            </v-col>
            <v-col cols="12" sm="12" lg="6" xs="12" xl="6" class="pr-2">
              <v-btn color="#0FA7D8" class="white--text text-capitalize mr-2 mt-2" style="font-weight: normal" block
                @click="clockingIn()" max-height="34">
                <img src="@/assets/images/Group 3194.png" class="pr-1" />
                {{ $t("Clock-in") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- open clok-out dialog -->
    <v-dialog v-model="ClockOutDialog" persistent max-width="408px">
      <v-card ref="form">
        <v-card-title>
          <p style="margin-top: 20px; margin-bottom: -30px">
            {{ $t("Clock-out") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-autocomplete class="empID" v-model="clock.timeType" style="margin-top: 0.95rem" :label="$t('TimeType')"
                  :items="timeTypeItem" item-text="txt" item-value="val" :error-messages="error.errormessagetimetype"
                  append-icon="mdi-chevron-down" readonly color="#F89D1F"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">{{ $t("Clock-inTime") }}</v-col>
              <v-col cols="6">{{ cintime }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6">{{ $t("Clock-outTime") }}</v-col>
              <v-col cols="6">{{ coutime }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6"> {{ $t("Total-Hours") }}</v-col>
              <v-col cols="6">{{ totaltime }} {{ $t("Hours") }}</v-col>
            </v-row>
            <v-row align="center">
              <v-col align="center" cols="12">
                <p @click="mapp" style="
                    color: #0fa7d8;
                    text-decoration: underline;
                    cursor: pointer;
                  ">
                  {{ $t("ViewClock-inMap") }}
                </p>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col align="center" cols="12">
                <p @click="openmapout" style="
                    color: #ff0000;
                    text-decoration: underline;
                    cursor: pointer;
                  ">
                  {{ $t("ViewClock-OutMap") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-container>
          <v-row no-gutters>
            <v-col cols="12" sm="12" lg="5" xs="12" xl="5" class="pr-2">
              <v-btn class="text-capitalize mr-2 mt-2" block style="font-weight: normal" max-height="35"
                @click="CloseCoutDialog()" text>
                {{ $t("Cancel") }}</v-btn>
            </v-col>
            <v-col cols="12" sm="12" lg="6" xs="12" xl="6" class="pr-2">
              <v-btn color="#FF0000" class="white--text text-capitalize mr-2 mt-2" block style="font-weight: normal"
                @click="clockingOut()" max-height="34">
                <img src="@/assets/images/Group 3195.png" class="pr-2" />{{
                  $t("Clock-out")
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="MapInDialog" persistent max-width="1350px">
      <v-card>
        <v-row class="ml-0 mt-0 mb-0 mr-0">
          <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9" class="pa-0">
            <gmap-map :center="center" ref="map" :zoom="15" style="width: 100%; height: 500px">
              <gmap-marker :position="marker.position" @click="center = marker.position"></gmap-marker>
            </gmap-map>
          </v-col>
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-row>
              <p style="font-size: 21px" class="ml-5 mt-5">
                {{ $t("detail") }}
              </p>
              <v-spacer></v-spacer>
              <v-btn @click="closemap()" icon style="margin-right: 1rem">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <p class="mt-7 ml-2 fontwei" style="font-size: 15px; color: #8a8c8e">
              {{ $t("address") }}
            </p>
            <v-textarea readonly v-model="location" class="mt-2 ml-2 fontwei" outlined></v-textarea>

            <p class="mt-3 ml-2 fontwei" style="font-size: 15px; color: #8a8c8e">
              latitude, longitude
            </p>
            <p class="mt-1 ml-2 fontwei" style="font-size: 17px; color: #f99d20">
              {{ locationlat }}&nbsp;{{ locationlang }}
            </p>
            <p class="ml-2 fontwei" style="font-size: 17px">
              {{ $t("clockinmaptxt") }}
            </p>
            <v-row style="margin-top: 45px" justify="center">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-btn @click="confirmcinlocate()" class="fontwei text-capitalize" width="100%" color="#F99D20">
                  {{ $t("Confirm") }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="MapOutDialog" persistent max-width="1350px">
      <v-card>
        <v-row class="ml-0 mt-0 mb-0 mr-0">
          <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0 pr-0 pb-0">
            <gmap-map :center="center" ref="map" :zoom="15" style="width: 100%; height: 500px">
              <gmap-marker :position="marker.position" @click="center = marker.position"></gmap-marker>
            </gmap-map>
          </v-col>
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-row>
              <p style="font-size: 21px" class="ml-5 mt-5">
                {{ $t("detail") }}
              </p>
              <v-spacer></v-spacer>
              <v-btn @click="closemapout()" icon style="margin-right: 1rem" class="mt-3">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <p class="mt-7 ml-2 fontwei" style="font-size: 15px; color: #8a8c8e">
              {{ $t("address") }}
            </p>
            <v-textarea readonly v-model="outlocation" class="mt-2 ml-2 fontwei" outlined></v-textarea>

            <p class="mt-3 ml-2 fontwei" style="font-size: 15px; color: #8a8c8e">
              latitude, longitude
            </p>
            <p class="mt-1 ml-2 fontwei" style="font-size: 17px; color: #f99d20">
              {{ outlocationlat }}&nbsp;{{ outlocationlang }}
            </p>
            <p class="ml-2 fontwei" style="font-size: 17px">
              {{ $t("clockinmaptxt") }}
            </p>
            <v-row style="margin-top: 45px" justify="center">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-btn @click="confirmcoutlocate()" class="fontwei text-capitalize" color="#F99D20" width="100%">
                  {{ $t("Confirm") }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="MapInEditDialog" persistent max-width="1350px">
      <v-card>
        <v-row class="ml-0 mt-0 mb-0 mr-0">
          <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0 pr-0 pb-0">
            <gmap-map :center="center" ref="map" :zoom="15" style="width: 100%; height: 500px">
              <gmap-marker :position="marker.position" @click="center = marker.position"></gmap-marker>
            </gmap-map>
          </v-col>
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-row>
              <p style="font-size: 21px" class="ml-5 mt-5">Detail</p>
              <v-spacer></v-spacer>
              <v-btn @click="closemapin" icon style="margin-right: 1rem">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <p class="mt-7 ml-2 fontwei" style="font-size: 15px; color: #8a8c8e">
              {{ $t("address") }}
            </p>
            <v-textarea readonly v-model="showlocation" class="mt-2 ml-2 fontwei" outlined></v-textarea>

            <p class="mt-3 ml-2 fontwei" style="font-size: 15px; color: #8a8c8e">
              latitude, longitude
            </p>
            <p class="mt-1 ml-2 fontwei" style="font-size: 17px; color: #f99d20">
              {{ showlocationlat }}&nbsp;{{ showlocationlang }}
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    
    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";
import LogTrace from "@/function/Log.js";
import ReportTrace from "@/function/Report.js";
import enurl from "@/api/environment";
import CheckViewAddEditDeleteClockInOut from "@/function/RolePermissions.js";
import CheckHomeStaffTimeAttPieChart_UpcomingTimeOff from "@/function/RolePermissions.js";
import OnTimeOff from "@/views/Home/OnTImeOff.vue";
import CommonStatistics from "@/views/Home/CommonStatistics.vue";
import generateToken from "../../messaging-service.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import { formatServerDateToLocal } from '@/plugins/toLocal.js';
import LoadingDialog from "@/components/Loading.vue";

export default {
  name: "GoogleMap",
  mixins: [
    ReportTrace,
    LogTrace,
    CheckViewAddEditDeleteClockInOut,
    CheckHomeStaffTimeAttPieChart_UpcomingTimeOff,
    generateToken,
    utils,
  ],
  components: {
    CommonStatistics,
    OnTimeOff,
    LoadingDialog
  },
  data() {
    return {
      permission: {
        add: true,
        edit: true,
        delete: true,
        clockinout: true,
      },
      permissionview: {
        view: false,
      },
      today: "",
      todayDate: "",
      series1: [],
      seriesData: [],
      percent: [],
      startbefore: null,
      startbeforetime: null,
      startafter: null,
      startaftertime: null,
      endbefore: null,
      endbeforetime: null,
      endafter: null,
      endaftertime: null,
      LoadingDialog: false,
      roleCheck: true,
      url: enurl.apiUrl,
      clock: {
        clockintime: "",
        clockouttime: "",
        otclockintime: "",
        otclockouttime: "",
        timeID: null,
        clockID: null,
        timeType: null,
        clockinlocate: null,
        clockoutlocate: null,
        cinlang: null,
        coutlang: null,
        cinlat: null,
        coutlat: null,
        shiftstart: null,
        shiftend: null,
        otshiftstart: null,
        otshiftend: null,
      },
      istherecheckin: false,
      cintime: "",
      coutime: "",
      totaltime: null,
      tempid: "",
      CinCoutList: [],
      ClockInDialog: false,
      ClockOutDialog: false,
      MapInDialog: false,
      MapOutDialog: false,
      MapInEditDialog: false,
      showClockIn: false,
      showClockOut: false,
      ShowTimeCard: false,
      disableCin: false,
      map: "",
      marker: "",
      center: { lat: 45.508, lng: -73.587 },
      location: "",
      locationlat: null,
      locationlang: null,
      showlocation: "",
      showlocationlat: null,
      showlocationlang: null,
      outlocation: "",
      outlocationlat: null,
      outlocationlang: null,
      //timeTypeItem: ["Regular", "Overtime"],
      automan: false,
      Deletedialog: false,
      announcementlist: [],
      realannouncementlist: [],
      resultannoncementlist: [],
      hidehire: false,
      birthdaylist: [],
      anniversarylist: [],
      personallist: [],
      HiredDate: "",
      celebrationexist: false,
      series: [60, 40],
      announcementexist: false,
      componentReadyCount: 0,
      totalComponents: 2,
      error: {
        errormessagetimetype: "",
      },
    };
  },
  computed: {
    images() {
      return this.$store.state.images;
    },

    timeTypeItem() {
      return [
        { val: "Regular", txt: this.$t("Regular") },
        { val: "Overtime", txt: this.$t("Overtime") },
      ];
    },

    // announcement_Computed() {
    //   if (store.state.roleID != "Admin") 
    //   {
    //     return this.resultannoncementlist.filter((element) => {
    //         const between = moment();
    //         return between.isBetween(
    //           moment(element.announcement_Start_Date),
    //           moment(element.announcement_Expiry_Date).add(1, "day")
    //         );
    //       }).sort((a, b) => (a.announcement_ID < b.announcement_ID ? 1 : -1));
    //   } 
    //   else 
    //   {
    //     return this.resultannoncementlist;
    //   }
    // },

    chartOptions1() {
      return {
        chart: {
          width: "100%",
          height: "100%",
          type: "pie",
        },
        labels: [this.$t("Clock-out"), this.$t("NotClock-out")],
        theme: {
          monochrome: {
            enabled: true,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5,
            },
          },
        },
        title: {
          text: this.$t("2.Clock-out/NotClock-out"),
          align: "center",
          style: {
            fontWeight: "normal",
          },
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          },
        },
        legend: {
          show: false,
        },
      };
    },
  },

 async mounted() {
    this.LoadingDialog = true;
    this.generateToken();
    await this.GetTimeAttSetting();
    this.getToday();
    this.getannouncement();
    await this.GetTimesheet();
    await this.getpersonal();
    this.permission = this.CheckViewAddEditDeleteClockInOut(1, 2, 3, 4, 5);
    this.permissionview = this.CheckHomeStaffTimeAttPieChart_UpcomingTimeOff(6);
    this.LoadingDialog = false;
  },

  methods: {
    getToday() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      this.todayDate = dd + "/" + mm + "/" + yyyy;
    },
    onComponentReady() {
      this.componentReadyCount++;
      if (this.componentReadyCount === this.totalComponents) {
        this.LoadingDialog = false;
      }
    },
    sanitizedAnnouncementDetail(str) {
      const hasForwardSlash = str.includes('/');
      if(hasForwardSlash)
      {
        return str.replace(/\\/g, '');
      }
      return str;
    },

    getPieData() {
      let self = this;
      self.series = [];
      self.series1 = [];
      self.seriesData = [];
      self.percent = [];
      self.getToday();
      let temp = {
        company_ID: store.state.companyID,
        date: self.todayDate,
        //date: "04/09/2020"
      };

      axios.post(`${self.url}ClockInOut/GetDataforPieChart`, temp)
      .then(function (response) {
        self.seriesData = response.data.data;
        self.series.push(self.seriesData.numOfClockIn);
        self.series.push(self.seriesData.numOfNotClockIn);
        self.series1.push(self.seriesData.numOfClockOut);
        self.series1.push(self.seriesData.numOfNotClockOut);
      });
    },

    fixedValue(val) {
      var result = (val / self.seriesData.totalEmp) * 100;
      return result;
    },

    async GetTimesheet() {
      let self = this;
      let gettodaydate = new Date();
      let getdate = "";
      let month = String(gettodaydate.getMonth() + 1);
      let day = String(gettodaydate.getDate());
      const year = String(gettodaydate.getFullYear());
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      getdate = `${day}/${month}/${year}`;
      let temp = {
        company_ID: store.state.companyID,
        today_Date: getdate,
        employeeID: store.state.employeeID,
      };
      await axios.post(`${self.url}Stafftimeattendance/GetDayViewOfSTA`, temp)
      .then(function (response) {
        if(response.data.status == 0)
        {
          let templist = response.data.data;
          if (templist.length != 0)
          {
            if (templist[0].time_Attendance == true)
            {
              self.ShowTimeCard = true;
              for (let i = 0; i < templist.length; i++)
              {
                if (templist[i].employee_ID == store.state.employeeID)
                {
                  let intime = "-";
                  let outtime = "-";
                  let otintime = "-";
                  let otouttime = "-";
                  let cinoutcount = 0;
                  let otcinoutcount = 0;
                  let regin = "-";
                  let latein = "-";
                  let regout = "-";
                  let lateout = "-";
                  let otin = "-";
                  let lateotin = "-";
                  let otout = "-";
                  let lateotout = "-";
                  let cinlocate = "-";
                  let cinlat = "-";
                  let cinlang = "-";
                  let coutlocate = "-";
                  let coutlat = "-";
                  let coutlang = "-";
                  let ttype = "";
                  let yesc = false;
                  let clist = [];
                  let status = null;
                  for (let f = 0; f < templist[i].regCinoutData.length; f++)
                  {
                    clist.push({
                      clock_ID: templist[i].regCinoutData[f].clock_ID,
                    });
                  }
                  for (let r = 0; r < templist[i].otCinoutData.length; r++)
                  {
                    clist.push({
                      clock_ID: templist[i].otCinoutData[r].clock_ID,
                    });
                  }
                  if (templist[i].oT_Start_Time == null || templist[i].oT_Start_Time == "") 
                  {
                    templist[i].oT_Start_Time = "-";
                  }
                  if (templist[i].oT_End_Time == null || templist[i].oT_End_Time == "")
                  {
                    templist[i].oT_End_Time = "-";
                  }
                  if (templist[i].regCinoutData.length != 0)
                  {
                    let leg = templist[i].regCinoutData.length - 1;
                    regin = templist[i].regCinoutData[0].clock_In_Time;
                    regout = templist[i].regCinoutData[leg].clock_Out_Time;
                    cinoutcount = templist[i].regCinoutData.length;
                    ttype = templist[i].regCinoutData[0].time_Type;
                    self.istherecheckin = true;
                    if (templist[i].regCinoutData.length == 1 && regin != "-" && templist[i].oT_Start_Time == "-")
                    {
                      self.automan = true;
                    }
                  }
                  if (templist[i].otCinoutData.length != 0)
                  {
                    let leg = templist[i].otCinoutData.length - 1;
                    otin = templist[i].otCinoutData[0].clock_In_Time;
                    otout = templist[i].otCinoutData[leg].clock_Out_Time;
                    otcinoutcount = templist[i].otCinoutData.length;
                    ttype = templist[i].otCinoutData[0].time_Type;
                    if (templist[i].otCinoutData.length == 1 && otin != "-" && templist[i].start_Time == "-")
                    {
                      self.automan = true;
                    }
                  }
                  if (templist[i].regCinoutData.length == 1 && templist[i].otCinoutData.length == 1 && regin != "-" && otin != "-")
                  {
                    self.automan = true;
                  }
                  if (templist[i].irrRegCinoutData.length != 0)
                  {
                    let leg = templist[i].irrRegCinoutData.length - 1;
                    latein = templist[i].irrRegCinoutData[0].clock_In_Time;
                    lateout = templist[i].irrRegCinoutData[leg].clock_Out_Time;
                  }
                  if (templist[i].irrOTCinoutData.length != 0)
                  {
                    let leg = templist[i].irrOTCinoutData.length - 1;
                    lateotin = templist[i].irrOTCinoutData[0].clock_In_Time;
                    lateotout = templist[i].irrOTCinoutData[leg].clock_Out_Time;
                  }
                  if (regin != "-" && regin != null)
                  {
                    intime = regin;
                    cinlocate = templist[i].regCinoutData[0].clock_In_Location;
                    cinlat = templist[i].regCinoutData[0].clock_In_Lattitude;
                    cinlang = templist[i].regCinoutData[0].clock_In_Longitude;
                  }
                  else if (latein != "-" && latein != null)
                  {
                    intime = latein;
                    cinlocate = templist[i].irrRegCinoutData[0].clock_In_Location;
                    cinlat = templist[i].irrRegCinoutData[0].clock_In_Lattitude;
                    cinlang = templist[i].irrRegCinoutData[0].clock_In_Longitude;
                  }

                  if (regout != "-" && regout != null)
                  {
                    outtime = regout;
                    let leg = cinoutcount - 1;
                    coutlocate = templist[i].regCinoutData[leg].clock_Out_Location;
                    coutlat = templist[i].regCinoutData[leg].clock_Out_Lattitude;
                    coutlang = templist[i].regCinoutData[leg].clock_Out_Longitude;
                  }
                  else if (lateout != "-" && lateout != null)
                  {
                    outtime = lateout;
                    let leg = cinoutcount - 1;
                    coutlocate = templist[i].irrRegCinoutData[leg].clock_Out_Location;
                    coutlat = templist[i].irrRegCinoutData[leg].clock_Out_Lattitude;
                    coutlang = templist[i].irrRegCinoutData[leg].clock_Out_Longitude;
                  }

                  if (otin != "-" && otin != null)
                  {
                    otintime = otin;
                    cinlocate = templist[i].otCinoutData[0].clock_In_Location;
                    cinlat = templist[i].otCinoutData[0].clock_In_Lattitude;
                    cinlang = templist[i].otCinoutData[0].clock_In_Longitude;
                  }
                  else if (lateotin != "-" && lateotin != null)
                  {
                    otintime = lateotin;
                    cinlocate = templist[i].irrOTCinoutData[0].clock_In_Location;
                    cinlat = templist[i].irrOTCinoutData[0].clock_In_Lattitude;
                    cinlang = templist[i].irrOTCinoutData[0].clock_In_Longitude;
                  }

                  if (otout != "-" && otout != null)
                  {
                    otouttime = otout;
                    let leg = otcinoutcount - 1;
                    coutlocate = templist[i].otCinoutData[leg].clock_Out_Location;
                    coutlat = templist[i].otCinoutData[leg].clock_Out_Lattitude;
                    coutlang = templist[i].otCinoutData[leg].clock_Out_Longitude;
                  }
                  else if (lateotout != "-" && lateotout != null)
                  {
                    otouttime = lateotout;
                    let leg = otcinoutcount - 1;
                    coutlocate = templist[i].irrOTCinoutData[leg].clock_Out_Location;
                    coutlat = templist[i].irrOTCinoutData[leg].clock_Out_Lattitude;
                    coutlang = templist[i].irrOTCinoutData[leg].clock_Out_Longitude;
                  }
                  if (regin != "-" && regin != null && regout != "-" && regout != null)
                  {
                    yesc = true;
                  }
                  if (templist[i].oT_Start_Time == "-" && templist.oT_End_Time == "-" && templist[i].start_Time == "-" && templist.end_Time == "-")
                  {
                    status = "-";
                  }
                  else if (templist[i].approve == true)
                  {
                    status = "Approved";
                  }
                  else
                  {
                    status = "Pending";
                  }
                  let Lists = {
                    ...templist[i],
                    clock_In_Time: intime,
                    clock_Out_Time: outtime,
                    oT_Clock_In_Time: otintime,
                    oT_Clock_Out_Time: otouttime,
                    clock_In_Location: cinlocate,
                    clock_In_Lattitude: cinlat,
                    clock_In_Longitude: cinlang,
                    clock_Out_Location: coutlocate,
                    clock_Out_Lattitude: coutlat,
                    clock_Out_Longitude: coutlang,
                    time_Type: ttype,
                    regcount: cinoutcount,
                    otcount: otcinoutcount,
                    clocklist: clist,
                    yescondi: yesc,
                    status: status,
                    approve: templist[i].approve,
                  };
                  self.CinCoutList.push(Lists);
                }
              }

              for (let j = 0; j < self.CinCoutList.length; j++)
              {
                if (self.CinCoutList[j].employee_ID == store.state.employeeID)
                {
                  self.clock.timeID = self.CinCoutList[j].time_ID;
                  self.clock.shiftstart = self.CinCoutList[j].start_Time;
                  self.clock.shiftend = self.CinCoutList[j].end_Time;
                  self.clock.otshiftstart = self.CinCoutList[j].oT_Start_Time;
                  self.clock.otshiftend = self.CinCoutList[j].oT_End_Time;
                  self.clock.clockintime = self.CinCoutList[j].clock_In_Time;
                  self.clock.clockouttime = self.CinCoutList[j].clock_Out_Time;
                  self.clock.otclockintime = self.CinCoutList[j].oT_Clock_In_Time;
                  self.clock.otclockouttime = self.CinCoutList[j].oT_Clock_Out_Time;
                  self.clock.clockinlocate = self.CinCoutList[j].clock_In_Location;
                  self.clock.clockoutlocate = self.CinCoutList[j].clock_Out_Location;
                  self.clock.cinlang = self.CinCoutList[j].clock_In_Longitude;
                  self.clock.coutlang = self.CinCoutList[j].clock_Out_Longitude;
                  self.clock.cinlat = self.CinCoutList[j].clock_In_Lattitude;
                  self.clock.coutlat = self.CinCoutList[j].clock_Out_Lattitude;
                  self.clock.timeType = self.CinCoutList[j].time_Type;
                  if (self.CinCoutList[j].clocklist != 0)
                  {
                    let lastn = self.CinCoutList[j].clocklist.length - 1;
                    self.clock.clockID = self.CinCoutList[j].clocklist[lastn].clock_ID;
                  }
                  if (self.CinCoutList[j].approve == false)
                  {
                    if (self.CinCoutList[j].time_Off_Whole_Day == true)
                    {
                      self.showClockIn = true;
                      self.showClockOut = false;
                      self.disableCin = true;
                    }
                    else
                    {
                      if (self.CinCoutList[j].clock_In_Time == "-" && self.CinCoutList[j].clock_Out_Time == "-" && self.CinCoutList[j].oT_Clock_In_Time == "-" && self.CinCoutList[j].oT_Clock_Out_Time == "-")
                      {
                        if (self.clock.shiftstart != "-")
                        {
                          self.showClockIn = true;
                          self.showClockOut = false;
                          self.disableCin = false;
                          break;
                        }
                        else if (self.clock.otshiftstart != "-")
                        {
                          self.showClockIn = true;
                          self.showClockOut = false;
                          self.disableCin = false;
                          break;
                        }
                        else
                        {
                          self.showClockIn = true;
                          self.showClockOut = false;
                          self.disableCin = true;
                          break;
                        }
                      }
                      else if (self.CinCoutList[j].clock_In_Time != "-" && self.CinCoutList[j].clock_Out_Time == "-")
                      {
                        self.showClockIn = false;
                        self.showClockOut = true;
                        self.disableCin = false;
                        break;
                      }
                      else if (self.CinCoutList[j].oT_Clock_In_Time != "-" && self.CinCoutList[j].oT_Clock_Out_Time == "-")
                      {
                        self.showClockIn = false;
                        self.showClockOut = true;
                        self.disableCin = false;
                        break;
                      }
                      else
                      {
                        self.showClockIn = true;
                        self.showClockOut = false;
                        self.disableCin = false;
                        break;
                      }
                    }
                  }
                  else
                  {
                    self.showClockIn = true;
                    self.showClockOut = false;
                    self.disableCin = true;
                  }
                }
              }
            }
            else
            {
              self.ShowTimeCard = false;
            }
          }
        }
      })
      .catch(function (error) {
        alert(error);
        self.showClockIn = true;
        self.showClockOut = false;
        self.disableCin = true;
        // self.LoadingDialog = false;
      });
    },

    back(id) {
      this.$router.push({ name: "readMore", params: { announcementid: id } }).catch(() => { });
    },

    annList(){
      this.$router.push({ name: "AnnouncementList"});
    },

    Go(id) {
      this.$router.push({ name: "readMore", params: { announcementid: id } }).catch(() => { });
    },

    CloseCinDialog() {
      let self = this;
      self.clock.clockouttime = null;
      self.clock.clockintime = null;
      self.clock.timeType = null;
      self.ClockInDialog = false;
    },

    CloseCoutDialog() {
      let self = this;
      self.ClockOutDialog = false;
    },

    OpenClockInDialog() {
      let self = this;
      self.clock.timeType = "";
      self.clock.clockinlocate = "";
      self.clock.cinlat = "";
      self.clock.cinlang = "";
      self.location = "";
      self.locationlat = "";
      self.locationlang = "";
      self.center = { lat: 45.508, lng: -73.587 };
      const today = new Date();
      const gethrs = today.getHours();
      const getmin = today.getMinutes();
      let time = "";
      if (gethrs < 10)
      {
        if (getmin < 10)
        {
          time = "0" + today.getHours() + ":" + "0" + today.getMinutes();
        }
        else
        {
          time = "0" + today.getHours() + ":" + today.getMinutes();
        }
      }
      else
      {
        if (getmin < 10)
        {
          time = today.getHours() + ":" + "0" + today.getMinutes();
        }
        else
        {
          time = today.getHours() + ":" + today.getMinutes();
        }
      }
      const utctime = today.getUTCFullYear() + "/" + (today.getUTCMonth() + 1) + "/" + today.getUTCDate() + " " + today.getUTCHours() + ":" + today.getUTCMinutes();
      self.ClockInDialog = true;
      self.clock.clockintime = utctime;
      self.cintime = time;
    },

    OpenClockOutDialog() {
      let self = this;
      self.ClockOutDialog = true;
      const today = new Date();
      const getmin = today.getMinutes();
      const getutcmin = today.getUTCMinutes();
      let time = "";
      let utctime = "";
      if (getmin < 10)
      {
        time = today.getHours() + ":" + "0" + getmin;
      }
      else
      {
        time = today.getHours() + ":" + today.getMinutes();
      }
      if (getutcmin < 10)
      {
        utctime = today.getUTCHours() + ":" + "0" + getutcmin;
      }
      else
      {
        utctime = today.getUTCHours() + ":" + today.getUTCMinutes();
      }
      let d = new Date(Date.parse(self.clock.clockintime));
      let timezone = d.getTimezoneOffset() * 60000;
      let newDate = new Date(d.getTime() - timezone);
      var timeDiff = Math.abs(today - newDate);
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      if (hh < 10)
      {
        hh = "0" + hh;
      }
      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      if (mm < 10)
      {
        mm = "0" + mm;
      }
      timeDiff -= mm * 1000 * 60;
      let tt = "";
      var ss = Math.floor(timeDiff / 1000);
      if (ss < 10)
      {
        ss = "0" + ss;
      }
      tt = hh + ":" + mm + " ";
      let inhours = newDate.getHours();
      let inmins = newDate.getMinutes();
      if (inhours < 10)
      {
        if (inmins < 10)
        {
          self.cintime = "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
        }
        else
        {
          self.cintime = "0" + newDate.getHours() + ":" + newDate.getMinutes();
        }
      }
      else
      {
        if (inmins < 10)
        {
          self.cintime = newDate.getHours() + ":" + "0" + newDate.getMinutes();
        }
        else
        {
          self.cintime = newDate.getHours() + ":" + newDate.getMinutes();
        }
      }
      self.coutime = time;
      self.totaltime = tt;
      self.clock.clockouttime = utctime;
    },

    openmap() {
      let self = this;
      self.MapInDialog = true;
      self.$nextTick(() => {
        self.$refs.map.$gmapApiPromiseLazy().then(self.geolocate);
      });
    },

    closemap() {
      let self = this;
      if (self.clock.clockinlocate != "" && self.clock.clockinlocate != null)
      {
        self.MapInDialog = false;
      }
      else
      {
        self.location = "";
        self.locationlat = "";
        self.locationlang = "";
        self.outlocation = "";
        self.outlocationlat = "";
        self.outlocationlang = "";
        self.center = { lat: 45.508, lng: -73.587 };
        self.MapInDialog = false;
      }
      self.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].pop();
    },

    mapp() {
      let self = this;
      self.MapInEditDialog = true;
      self.$nextTick(() => {
        self.$refs.map.$gmapApiPromiseLazy().then(self.openmapin);
      });
    },

    openmapin() {
      let self = this;
      self.showlocation = self.clock.clockinlocate;
      self.showlocationlat = self.clock.cinlat;
      self.showlocationlang = self.clock.cinlang;
      window.google.maps.event.addListener(
        this.$refs.map.$mapObject,
        "center_changed",
        function () {
          // secondChild.style["background-position"] = "0 0";
        }
      );
      var ref = this;
      if (self.clock.cinlat != 0)
      {
        navigator.geolocation.getCurrentPosition((position) => {
          /* eslint-disable no-unused-vars */
          let latlng = new window.google.maps.LatLng(
            parseFloat(position.coords.latitude),
            parseFloat(position.coords.longitude)
          );
          let latlngtemp = new window.google.maps.LatLng(
            parseFloat(ref.showlocationlat),
            parseFloat(ref.showlocationlang)
          );
          ref.center = {
            lat: parseFloat(ref.showlocationlat),
            lng: parseFloat(ref.showlocationlang),
          };
          ref.createMarker(latlngtemp);
        });
      }
    },

    closemapin() {
      let self = this;
      self.center = { lat: 45.508, lng: -73.587 };
      self.outlocation = "";
      self.MapInEditDialog = false;
    },

    openmapout() {
      let self = this;
      self.MapOutDialog = true;
      self.$nextTick(() => {
        self.$refs.map.$gmapApiPromiseLazy().then(self.geolocate);
      });
    },

    closemapout() {
      let self = this;
      if (self.clock.clockoutlocate != "" && self.clock.clockoutlocate != null)
      {
        self.MapOutDialog = false;
      }
      else
      {
        self.location = "";
        self.locationlat = "";
        self.locationlang = "";
        self.outlocation = "";
        self.outlocationlat = "";
        self.outlocationlang = "";
        self.center = { lat: 45.508, lng: -73.587 };
        self.MapOutDialog = false;
      }
      self.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].pop();
    },

    confirmcinlocate() {
      let self = this;
      if (self.location != "" && self.location != null)
      {
        self.clock.clockinlocate = self.location;
        self.clock.cinlang = self.locationlang;
        self.clock.cinlat = self.locationlat;
        self.MapInDialog = false;
        self.$refs.map.$mapObject.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].pop();
      }
      else
      {
        alert("please select your location.");
      }
    },

    async GetTimeAttSetting() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}TimeAttendanceSetting/GetTimeAttendanceSettingByCompanyID`,temp)
      .then(function (response) {
        self.TimeSettingList = response.data.data;
      });
      if (self.TimeSettingList[0])
      {
        let [stbefore, stbeforetime] = self.TimeSettingList[0].start_Time_Before.split(" ");
        let [stafter, staftertime] = self.TimeSettingList[0].start_Time_After.split(" ");
        self.startbefore = stbefore;
        self.startbeforetime = stbeforetime;
        self.startafter = stafter;
        self.startaftertime = staftertime;
        let [edbefore, edbeforetime] = self.TimeSettingList[0].end_Time_Before.split(" ");
        let [edafter, edaftertime] = self.TimeSettingList[0].end_Time_After.split(" ");
        self.endbefore = edbefore;
        self.endbeforetime = edbeforetime;
        self.endafter = edafter;
        self.endaftertime = edaftertime;
      }
    },

    confirmcoutlocate() {
      let self = this;
      if (self.location != "" && self.location != null)
      {
        self.clock.clockoutlocate = self.outlocation;
        self.clock.coutlang = self.outlocationlang;
        self.clock.coutlat = self.outlocationlat;
        self.MapOutDialog = false;
        self.$refs.map.$mapObject.controls[
          window.google.maps.ControlPosition.RIGHT_BOTTOM
        ].pop();
      }
      else
      {
        alert("please select your location.");
      }
    },

    padZero(value) {
      return value < 10 ? "0" + value : value;
    },

    clockingIn() {
      let self = this;
      self.LoadingDialog = true;
      self.error.errormessagetimetype = !self.clock.timeType ? "Please fill in the required fields" : "";
      if (
        self.clock.timeType != null &&
        self.clock.timeType != "" &&
        self.clock.clockinlocate != null &&
        self.clock.clockinlocate != "" &&
        self.clock.cinlat != null &&
        self.clock.cinlat != "" &&
        self.clock.cinlat != "-" &&
        self.clock.cinlang != null &&
        self.clock.cinlang != "" &&
        self.clock.cinlang != "-")
      {
        let incon = false;
        if (self.clock.timeType == "Regular")
        {
          if (self.clock.shiftstart != "-")
          {
            incon = true;
          }
        }
        else
        {
          if (self.clock.otshiftstart != "-")
          {
            incon = true;
          }
        }
        if (incon)
        {
          const today = new Date();
          const getutcmin = today.getUTCMinutes();
          const getutchrs = today.getUTCHours();
          let utctime = 
                today.getUTCFullYear() +
                "/" +
                (today.getUTCMonth() + 1) +
                "/" +
                today.getUTCDate() +
                " " +
                self.padZero(getutchrs) +
                ":" +
                self.padZero(getutcmin);
          self.clock.clockintime = utctime;
          let temin = self.Converttolocal1(self.clock.clockintime);
          let from = new Date(Date.parse(temin));
          let shiftstartbefore = new Date();
          let shiftstartafter = new Date();
          let [sshours, ssminutes] = "";
          if (self.clock.timeType == "Regular")
          {
            [sshours, ssminutes] = self.clock.shiftstart.split(":");
          }
          else
          {
            [sshours, ssminutes] = self.clock.otshiftstart.split(":");
          }
          shiftstartbefore.setHours(+sshours);
          shiftstartbefore.setMinutes(ssminutes);
          shiftstartbefore.setSeconds("0");
          shiftstartbefore.setMilliseconds("0");
          shiftstartafter.setHours(+sshours);
          shiftstartafter.setMinutes(ssminutes);
          if (self.startbeforetime == "hour")
          {
            let adds = shiftstartbefore.getHours() - parseInt(self.startbefore);
            shiftstartbefore.setHours(+adds);
          }
          else if (self.startbeforetime == "min")
          {
            let adds = shiftstartbefore.getMinutes() - parseInt(self.startbefore);
            shiftstartbefore.setMinutes(adds);
          }
          if (self.startaftertime == "hour")
          {
            let adds = shiftstartafter.getHours() + parseInt(self.startafter);
            shiftstartafter.setHours(+adds);
          }
          else if (self.startaftertime == "min")
          {
            let adds = shiftstartafter.getMinutes() + parseInt(self.startafter);
            shiftstartafter.setMinutes(adds);
          }
          let frnewDate = new Date(from.getTime());
          let ssnewDate = new Date(shiftstartafter.getTime());
          let ssbnewDate = new Date(shiftstartbefore.getTime());

          let temparr = {
            time_ID: self.clock.timeID,
            clock_In_Time: "-",
            clock_Out_Time: "-",
            clock_In_Location: "-",
            clock_Out_Location: "-",
            clock_In_Lattitude: "-",
            clock_In_Longitude: "-",
            clock_Out_Lattitude: "-",
            clock_Out_Longitude: "-",
            irregular_In_Time: "-",
            irregular_Out_Time: "-",
            irregular_In_Location: "-",
            irregular_Out_Location: "-",
            irregular_In_Lattitude: "-",
            irregular_In_Longitude: "-",
            irregular_Out_Lattitude: "-",
            irregular_Out_Longitude: "-",
            time_Type: self.clock.timeType,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
            shift_StartTime: self.clock.shiftstart,
            shft_EndTime: self.clock.shiftend,
            approve: false,
            user_ID: store.state.userid,
          };
          if (frnewDate > ssnewDate)
          {
            Object.assign(temparr, {
              irregular_In_Time: self.clock.clockintime,
              irregular_In_Location: self.clock.clockinlocate,
              irregular_In_Lattitude: self.clock.cinlat.toString(),
              irregular_In_Longitude: self.clock.cinlang.toString(),
            });
          }
          else if (ssbnewDate > frnewDate || self.istherecheckin)
          {
            Object.assign(temparr, {
              irregular_In_Time: self.clock.clockintime,
              irregular_In_Location: self.clock.clockinlocate,
              irregular_In_Lattitude: self.clock.cinlat.toString(),
              irregular_In_Longitude: self.clock.cinlang.toString(),
            });
          }
          else
          {
            Object.assign(temparr, {
              clock_In_Time: self.clock.clockintime,
              clock_In_Location: self.clock.clockinlocate,
              clock_In_Lattitude: self.clock.cinlat.toString(),
              clock_In_Longitude: self.clock.cinlang.toString(),
            });
          }
          axios.post(`${self.url}ClockInOut/AddClockInOut`, temparr)
          .then(function (response) {
            if (response.data.status == 0)
            {
              self.ReportTrace(3);
              self.ClockInDialog = false;
              self.location = "";
              self.locationlat = "";
              self.locationlang = "";
              self.outlocation = "";
              self.outlocationlat = "";
              self.outlocationlang = "";
              self.CinCoutList = [];
              self.showClockIn = false;
              self.showClockOut = false;
              self.center = { lat: 45.508, lng: -73.587 };
              alert("Clock-In Successfully");
              //self.LogTrace(null, "ClockIn Home", 39, "Critical");
              self.error.errormessagetimetype = null;
              self.GetTimesheet();
              self.LoadingDialog = false;
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "ClockIn Home Fail", 39, "Critical");
            self.LoadingDialog = false;
          });
        }
        else
        {
          if (self.clock.timeType == "Regular")
          {
            alert("There is no regular shift time today!");
          }
          else
          {
            alert("There is no overtime shift time today!");
          }
        }
      }
      else if (self.clock.clockinlocate == null ||
        self.clock.clockinlocate == "" ||
        self.clock.cinlat == null ||
        self.clock.cinlat == "" ||
        self.clock.cinlat == "-" ||
        self.clock.cinlang == null ||
        self.clock.cinlang == "" ||
        self.clock.cinlang == "-")
      {
        alert("Please select your location!");
      }
      else
      {
        alert("Please fill in the required fields!");
      }
      self.LoadingDialog = false;
    },

    clockingOut() {
      let self = this;
      self.error.errormessagetimetype = !self.clock.timeType
        ? "Please fill in the required fields"
        : "";
      if (
        self.clock.timeType != null &&
        self.clock.timeType != "" &&
        self.clock.clockoutlocate != null &&
        self.clock.clockoutlocate != "" &&
        self.clock.coutlat != null &&
        self.clock.coutlat != "" &&
        self.clock.coutlat != "-" &&
        self.clock.coutlang != null &&
        self.clock.coutlang != "" &&
        self.clock.coutlang != "-"
      )
      {
        const today = new Date();
        const getutcmin = today.getUTCMinutes();
        const getutchrs = today.getUTCHours();
        let utctime = 
                today.getUTCFullYear() +
                "/" +
                (today.getUTCMonth() + 1) +
                "/" +
                today.getUTCDate() +
                " " +
                self.padZero(getutchrs) +
                ":" +
                self.padZero(getutcmin);
        self.clock.clockouttime = utctime;
        let temout = self.Converttolocal1(self.clock.clockouttime);
        let to = new Date(Date.parse(temout));
        let shiftendbefore = new Date();
        let shiftendafter = new Date();
        let [sehours, seminutes] = "";
        if (self.clock.timeType == "Regular") {
          [sehours, seminutes] = self.clock.shiftend.split(":");
        } else {
          [sehours, seminutes] = self.clock.otshiftend.split(":");
        }
        shiftendbefore.setHours(+sehours);
        shiftendbefore.setMinutes(seminutes);
        shiftendbefore.setSeconds("0");
        shiftendbefore.setMilliseconds("0");
        shiftendafter.setHours(+sehours);
        shiftendafter.setMinutes(seminutes);
        if (self.endbeforetime == "hour") {
          let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
          shiftendbefore.setHours(+adds);
        } else if (self.endbeforetime == "min") {
          let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
          shiftendbefore.setMinutes(adds);
        }
        if (self.endaftertime == "hour") {
          let adds = shiftendafter.getHours() + parseInt(self.endafter);
          shiftendafter.setHours(+adds);
        } else if (self.endaftertime == "min") {
          let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
          shiftendafter.setMinutes(adds);
        }
        self.LoadingDialog = true;
        let tonewDate = new Date(to.getTime());
        let senewDate = new Date(shiftendafter.getTime());
        let sebnewDate = new Date(shiftendbefore.getTime());

        let temparr = {
            clock_ID: self.clock.clockID,
            time_ID: self.clock.timeID,
            clock_In_Time: "-",
            clock_Out_Time: "-",
            clock_In_Location: "-",
            clock_Out_Location: "-",
            clock_In_Lattitude: "-",
            clock_In_Longitude: "-",
            clock_Out_Lattitude: "-",
            clock_Out_Longitude: "-",
            irregular_In_Time: "-",
            irregular_Out_Time: "-",
            irregular_In_Location: "-",
            irregular_Out_Location: "-",
            irregular_In_Lattitude: "-",
            irregular_In_Longitude: "-",
            irregular_Out_Lattitude: "-",
            irregular_Out_Longitude: "-",
            time_Type: self.clock.timeType,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
            shift_StartTime: self.clock.shiftstart,
            shft_EndTime: self.clock.shiftend,
            approve: false,
            user_ID: store.state.userid,
          };
        if (tonewDate > senewDate || sebnewDate > tonewDate) {
          Object.assign(temparr, {
            irregular_In_Time: self.clock.clockintime,
            irregular_Out_Time: self.clock.clockouttime,
            irregular_In_Location: self.clock.clockinlocate,
            irregular_Out_Location: self.clock.clockoutlocate,
            irregular_In_Lattitude: self.clock.cinlat.toString(),
            irregular_In_Longitude: self.clock.cinlang.toString(),
            irregular_Out_Lattitude: self.clock.coutlat.toString(),
            irregular_Out_Longitude: self.clock.coutlang.toString(),
          });
        } else {
          Object.assign(temparr, {
            clock_In_Time: self.clock.clockintime,
            clock_Out_Time: self.clock.clockouttime,
            clock_In_Location: self.clock.clockinlocate,
            clock_Out_Location: self.clock.clockoutlocate,
            clock_In_Lattitude: self.clock.cinlat.toString(),
            clock_In_Longitude: self.clock.cinlang.toString(),
            clock_Out_Lattitude: self.clock.coutlat.toString(),
            clock_Out_Longitude: self.clock.coutlang.toString(),
          });
          if (self.automan == true) {
            temparr.approve = true;
          }
        }
        axios.post(`${self.url}ClockInOut/UpdateClockInOut`, temparr)
        .then(function (response) {
          if (response.data.status == 0)
          {
            self.ClockOutDialog = false;
            alert("Clock-Out Successfully");
            self.ReportTrace(4);
            self.location = "";
            self.locationlat = "";
            self.locationlang = "";
            self.outlocation = "";
            self.outlocationlat = "";
            self.outlocationlang = "";
            self.CinCoutList = [];
            // self.showClockIn = false;
            // self.showClockOut = false;
            self.center = { lat: 45.508, lng: -73.587 };
            //self.LogTrace(null, "ClockOut Home", 39, "Critical");
            self.error.errormessagetimetype = null;
            self.GetTimesheet();
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "ClockOut Home Fail", 39, "Critical");
          self.LoadingDialog = false;
        });
      }
      else if (self.clock.clockoutlocate == null ||
        self.clock.clockoutlocate == "" ||
        self.clock.coutlat == null ||
        self.clock.coutlat == "" ||
        self.clock.coutlat == "-" ||
        self.clock.coutlang == null ||
        self.clock.coutlang == "" ||
        self.clock.coutlang == "-"
      )
      {
        alert("Please select your location!");
      }
      else
      {
        alert("Please fill in the required fields!");
      }
    },

    Converttolocal1(item) {
      if (item != "-")
      {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let theresult = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10)
        {
          if (inmins < 10)
          {
            output = "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          }
          else
          {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        else
        {
          if (inmins < 10)
          {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          }
          else
          {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        theresult =
          newDate.getFullYear() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getDate() +
          " " +
          output;
        return theresult;
      }
      else
      
      {
        return "-";
      }
    },

    convertString(str)
    {
      const cleanedStr = str.replace(/[\u200B-\u200D\uFEFF]/g, '');
      // Return the cleaned string with single quotes around it
      return `'${cleanedStr}'`;
    },

    async getannouncement() {
        let self = this;
        // self.LoadingDialog = true;
        let temp = {
          company_ID: store.state.companyID,
          employee_ID: store.state.employeeID,
          timezoneOffset: new Date().getTimezoneOffset()
        };
        await axios.post(`${self.url}Announcement/GetCustomerWeb`, temp)
        .then(function (response) {
          if(response.data.status === 0)
          {
            let tempAnnouncement = response.data.data.filter(x => x.announcement_Status === 'Announcement');
            self.resultannoncementlist = tempAnnouncement.map(item => ({
              ...item,
              //announcementDetail: self.sanitizedAnnouncementDetail(item.announcement_Detail),
              //.replace(/^"(.*)"$/, "'$1'"),
              formattedAnnouncementDate: formatServerDateToLocal(item.createdAnnouncement_Date)
            }));
            self.resultannoncementlist.sort((a, b) => {           
              // Second criteria: Sort by status
              if (a.status === true && b.status !== true)
              {
                return -1;
              }
              else if (a.status !== true && b.status === true)
              {
                return 1;
              }

              // Third criteria: Sort by announcement_ID
              if (a.announcement_ID !== b.announcement_ID)
              {
                return b.announcement_ID - a.announcement_ID;
              }
              // If all criteria are equal
              return 0;
            });
          }
          else
          {
            alert(response.data.message)
          }
          //console.log(self.resultannoncementlist)
          // self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Announcement Fail", 39, "Critical");
          self.LoadingDialog = false;
        });
      },

    // async getannouncement() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   let temp = {
    //     company_ID: store.state.companyID,
    //   };
    //   await axios.post(`${self.url}Announcement/GetAnnouncementForProductAdmin`, temp)
    //   .then(function (response) {
    //     self.announcementlist = response.data.data;
    //     self.realannouncementlist = response.data.data;
    //     for (let i = 0; i < self.realannouncementlist.length; i++)
    //     {
    //       let mydate = self.formatDate(self.realannouncementlist[i].announcement_Expiry_Date);
    //       let mycurrentdate = parseInt(mydate.split("/")[0]);
    //       let mycurrentmonth = parseInt(mydate.split("/")[1]);
    //       let mycurrentyear = mydate.split("/")[2];
    //       let newdate = new Date();
    //       let currentdate = newdate.getDate();
    //       let currentmonth = newdate.getMonth() + 1;
    //       let currentyear = newdate.getFullYear();
    //       if (currentyear < mycurrentyear)
    //       {
    //         self.resultannoncementlist.push(self.realannouncementlist[i]);
    //       }
    //       if (currentyear == mycurrentyear)
    //       {
    //         if (currentmonth < mycurrentmonth)
    //         {
    //           self.resultannoncementlist.push(self.realannouncementlist[i]);
    //         }
    //         if (currentmonth == mycurrentmonth)
    //         {
    //           if (currentdate <= mycurrentdate)
    //           {
    //             self.resultannoncementlist.push(self.realannouncementlist[i]);
    //           }
    //         }
    //       }
    //     }
    //     if (self.resultannoncementlist[0] != null)
    //     {
    //       self.announcementexist = true;
    //     }
    //   })
    //   .catch(function (error) {
    //     self.LogTrace(error, "Get Announcement Fail", 39, "Critical");
    //     self.LoadingDialog = false;
    //   });

    //   let accountrole = store.state.roleID;
    //   if (accountrole != "Admin")
    //   {
    //     let temp = {
    //       employee_ID: store.state.userid,
    //       company_ID: store.state.companyID,
    //       createID: store.state.employeeID,
    //     };
    //     await axios.post(`${self.url}Announcement/GetAnnouncement`, temp)
    //     .then(function (response) {
    //       if(response.data.status === 0)
    //       {
    //         self.announcementlist = response.data.data;
    //         self.realannouncementlist = response.data.data;
    //         for (let i = 0; i < self.realannouncementlist.length; i++)
    //         {
    //           let mydate = self.formatDate(self.realannouncementlist[i].announcement_Expiry_Date);
    //           let mycurrentdate = parseInt(mydate.split("/")[0]);
    //           let mycurrentmonth = parseInt(mydate.split("/")[1]);
    //           let mycurrentyear = mydate.split("/")[2];
    //           let newdate = new Date();
    //           let currentdate = newdate.getDate();
    //           let currentmonth = newdate.getMonth() + 1;
    //           let currentyear = newdate.getFullYear();
    //           if (currentyear < mycurrentyear)
    //           {
    //             self.resultannoncementlist.push(self.realannouncementlist[i]);
    //           }
    //           if (currentyear == mycurrentyear)
    //           {
    //             if (currentmonth < mycurrentmonth)
    //             {
    //               self.resultannoncementlist.push(self.realannouncementlist[i]);
    //             }
    //             if (currentmonth == mycurrentmonth)
    //             {
    //               if (currentdate <= mycurrentdate)
    //               {
    //                 self.resultannoncementlist.push(self.realannouncementlist[i]);
    //               }
    //             }
    //           }
    //         }
    //         if (self.resultannoncementlist[0] != null)
    //         {
    //           self.announcementexist = true;
    //         }
    //       }
    //       else
    //       {
    //         alert(response.data.message);
    //       }
    //       self.LoadingDialog = false;
    //     })
    //     .catch(function (error) {
    //       self.LogTrace(error, "Get Announcement Fail", 39, "Critical");
    //       self.LoadingDialog = false;
    //     });
    //   }
    //   else
    //   {
    //     let temp = {
    //       employee_ID: store.state.userid,
    //       company_ID: store.state.companyID,
    //     };
    //     await axios.post(`${self.url}Announcement/GetAnnouncementforHR`, temp)
    //     .then(function (response) {
    //       if(response.data.status === 0)
    //       {
    //         self.announcementlist = response.data.data;
    //         self.realannouncementlist = response.data.data;
    //         for (let i = 0; i < self.realannouncementlist.length; i++)
    //         {
    //           if (self.realannouncementlist[i].announcement_Status === 'Announcement') 
    //           {
    //             self.resultannoncementlist.push(self.realannouncementlist[i]);
    //           }
    //         }
    //         if (self.resultannoncementlist[0] != null)
    //         {
    //           self.announcementexist = true;
    //         }
    //       }
    //       else
    //       {
    //         alert(response.data.message);
    //       }
    //       self.LoadingDialog = false;
    //     })
    //     .catch(function (error) {
    //       self.LogTrace(error, "Get Announcement Fail", 39, "Critical");
    //       self.LoadingDialog = false;
    //     });
    //   }
    //   self.resultannoncementlist = self.resultannoncementlist.map(item => ({
    //     ...item,
    //     formattedAnnouncementDate: formatServerDateToLocal(item.createdAnnouncement_Date)
    //   }));
    // },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    gotoeditannouncment(ss) {
      this.$router.push({
        name: "editAnnouncement",
        params: { announcementid: ss }
      })
      .catch(() => { });
    },

    getOrdinalSuffix(n) {
      let suffix = ["th", "st", "nd", "rd"],
      v = n % 100;
      return n + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
    },

    async getpersonal() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}PersonalInfo/GetPersonalInfoByCompanyID`, temp)
      .then(function (response) {
        let temp = response.data.data;
        self.personallist = temp.filter((e) => e.employeeStatus == 1);
        for (let i = 0; i < self.personallist.length; i++)
        {
          let mybirthDate = self.personallist[i].birthDate;
          let mybirthday = new Date(Date.parse(mybirthDate));
          let myhireDate = self.personallist[i].hiredDate;
          let myhireday = new Date(Date.parse(myhireDate));
          let today = new Date();
          let day = today.getDate();
          let month = today.getMonth();
          let year = today.getFullYear();
          let birthday = mybirthday.getDate();
          let birthmonth = mybirthday.getMonth();
          let workday = myhireday.getDate();
          let workmonth = myhireday.getMonth();
          let workyear = myhireday.getFullYear();
          if (day == birthday && month == birthmonth)
          {
            let list = {
              ...self.personallist[i],
              birth: "Happy Birthday",
            };
            self.birthdaylist.push(list);
          }
          if (day == workday && month == workmonth && year != workyear)
          {
            let workYears = self.getOrdinalSuffix(year - workyear);
            let worklist = {
              ...self.personallist[i],
              workanni: workYears + " Anniversary",
            };
            self.anniversarylist.push(worklist);
          }
        }
      });

      if (this.birthdaylist.length != null && this.anniversarylist.length != null)
      {
        this.celebrationexist = true;
      }
    },

    deleteshowdialog(id) {
      let self = this;
      self.Deletedialog = true;
      self.tempid = id;
    },

    createMarker: function (latlng) {
      let lats = latlng;
      let self = this;
      self.marker = new window.google.maps.Marker({
        setMap: this.$refs.map.$mapObject,
        position: lats,
        animation: window.google.maps.Animation.DROP,
      });
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: lats }, function (results, status) {
        if (status === "OK")
        {
          if (results[0])
          {
            self.location = results[0].formatted_address;
            self.outlocation = results[0].formatted_address;
          }
          else
          {
            window.alert("No results found");
          }
        }
        else
        {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    },

    geolocate: function () {
      var controlDiv = document.createElement("div");
      var firstChild = document.createElement("button");
      firstChild.style.backgroundColor = "#fff";
      firstChild.style.border = "none";
      firstChild.style.outline = "none";
      firstChild.style.width = "28px";
      firstChild.style.height = "28px";
      firstChild.style.borderRadius = "2px";
      firstChild.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
      firstChild.style.cursor = "pointer";
      firstChild.style.marginRight = "10px";
      firstChild.style.padding = "0px";
      firstChild.title = "Your Location";
      controlDiv.appendChild(firstChild);
      var secondChild = document.createElement("div");
      secondChild.style.margin = "5px";
      secondChild.style.width = "18px";
      secondChild.style.height = "18px";
      secondChild.style.backgroundImage = "url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)";
      secondChild.style.backgroundSize = "180px 18px";
      secondChild.style.backgroundPosition = "0px 0px";
      secondChild.style.backgroundRepeat = "no-repeat";
      secondChild.id = "you_location_img";
      firstChild.appendChild(secondChild);
      window.google.maps.event.addListener(
        this.$refs.map.$mapObject,
        "center_changed",
        function () {
          secondChild.style["background-position"] = "0 0";
        }
      );
      var ref = this;
      firstChild.addEventListener("click", function () {
        navigator.geolocation.getCurrentPosition((position) => {
          let latlng = new window.google.maps.LatLng(
            parseFloat(position.coords.latitude),
            parseFloat(position.coords.longitude)
          );

          ref.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          ref.locationlat = position.coords.latitude;
          ref.locationlang = position.coords.longitude;
          ref.outlocationlat = position.coords.latitude;
          ref.outlocationlang = position.coords.longitude;
          ref.createMarker(latlng);
        });
      });
      navigator.geolocation.getCurrentPosition((position) => {
        let latlng = new window.google.maps.LatLng(
          parseFloat(position.coords.latitude),
          parseFloat(position.coords.longitude)
        );

        ref.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        ref.locationlat = position.coords.latitude;
        ref.locationlang = position.coords.longitude;
        ref.outlocationlat = position.coords.latitude;
        ref.outlocationlang = position.coords.longitude;
        ref.createMarker(latlng);
      });
      controlDiv.index = 1;
      this.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(controlDiv);
    },
  },
};
</script>

<style scoped>
.chipStyle {
  border-left: 10px solid rgb(17, 190, 95);
  margin-right: 15px;
  padding: 10px 0px;
}

.v-progress-circular {
  margin: 1rem;
}

/* .mycard {
  overflow-y: auto;
  height: 520px;
  overflow-x: hidden;
  position: relative;
} */

.mycard{
  height: 520px;
  overflow-x: hidden;
  overflow-y: scroll; /* Enables scrolling */
  -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  position: relative;
}

.mycard::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Opera */
}

.celebrationcard {
  height: 520px;
  overflow-x: hidden;
  overflow-y: scroll; /* Enables scrolling */
  -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  position: relative;
}

.celebrationcard::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Opera */
}

.fixed-header {
  position: sticky;
  top: 0;
  background-color: white; /* or your card background color */
  z-index: 5;
  padding-top: 10px; /* optional padding */
}

.mycardtwo {
  overflow-y: auto;
  height: 800px;
  overflow-x: hidden;
}

.controls {
  position: relative;
}

.empID {
  font-family: "Kanit";
}

.v-tab:before {
  background: none !important;
}

.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #ffcd2c !important;
  height: 30px;
  border-radius: 30px;
}

::v-deep .v-dialog {
  border-radius: 15px !important;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  height: 30px;
  border-radius: 30px;
}

.v-tabs-bar .v-tab {
  background-color: #e9e9f0;
}

.active {
  cursor: pointer !important;
}

.v-tab--active {
  color: #3c4043;
  border-radius: 30px;
  height: 30px;
  z-index: 1;
}

.v-application p {
  margin-bottom: 0 !important;
}

.card-title-container {
  height: 50px;
  width: 100%;
  padding-top: 10px;
}

/* .cardtitle {
  font-weight: medium;
  font-size: 20px;
} */

/* @media (max-width: 780px) and (max-height: 1100px) {
  .cardtitle {
    font-weight: medium;
    font-size: 15px;
  }
} */

/* @media (max-width: 780px) and (max-height: 1100px) {
  .mycard {
    width:450px;
  }
} */
.cardaction {
  margin-left: 90px;
}

@media (max-width: 500px) and (max-height: 800px) {
  .cardaction {
    margin-left: 120px;
  }
}

@media (min-width: 600px) {
  .cardaction {
    margin-left: 20px;
  }
}

@media (min-width: 1280px) {
  .cardaction {
    margin-left: 20px;
  }
}

/* @media (min-width: 1280px)  {
  
  .cardaction{
    margin-left:60px
  }
} */
@media (max-width: 330px) and (max-height: 570px) {
  .image-container {
    padding: 0px;
    margin: 0px;
    max-width: 210px;
    max-height: 210px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__day {
  border: none !important;
}

::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
  border-right: none !important;
  border-bottom: 1px solid #0fa7d8;
  height: 30px;
  margin-top: 30px;
}

::v-deep .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: #fff;
}

::v-deep .v-calendar-weekly__head-weekday .v-present {
  color: #adadad !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .announcement-detail
{
  width: 100%;
  max-width: 675px;
} */
.announcement-detail {
  width: 100%;
  max-width: 875px;
  margin: 0 auto; /* Center align */
  box-sizing: border-box; /* Ensure padding and border are included in the width and height */
}

.announcement-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Show only 2 lines */
  line-height: 1.5; /* Adjust this line height to match your design */
  max-height: calc(1.5em * 2); /* line-height * number of lines */
  font-size: 14px;
  color: #878585;
}

.scroll-container {
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.flex-container {
  display: flex;
  height: 200px;
  border: none !important;
}

.image-container {
  display: flex;
  align-items: center;
  width: 255px;
  padding: 0;
  margin: 0;
}

/* .content-container {
  flex-grow: 1;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}

.d-flex {
  display: flex;
} */

.content-container {
  flex-grow: 1;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensures it takes the full width */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  overflow-x: auto; /* Adds a scrollbar if content overflows */
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.mt-auto {
  margin-top: auto;
}

.mr-5 {
  margin-right: 20px;
}

@media (max-width: 1200px) {
  .announcement-detail {
    max-width: 800px;
  }
}

@media (max-width: 992px) {
  .announcement-detail {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .announcement-detail {
    max-width: 600px;
  }
}

@media (max-width: 600px) {
  .announcement-detail {
    max-width: 500px;
    /* overflow-x: auto; */
  }
}

@media (max-width: 480px) {
  .announcement-detail {
    max-width: 400px;
  }
}

@media (max-width: 320px) {
  .announcement-detail {
    max-width: 300px;
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .content-container {
    padding-left: 10px;
  }
}

@media (max-width: 992px) {
  .content-container {
    padding-left: 8px;
  }
}

@media (max-width: 768px) {
  .content-container {
    padding-left: 6px;
  }
}

@media (max-width: 600px) {
  .content-container {
    padding-left: 4px;
    flex-direction: column-reverse; /* Example change */
  }

  .d-flex {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .content-container {
    padding-left: 2px;
  }
}

@media (max-width: 320px) {
  .content-container {
    padding-left: 1px;
  }
}
</style>