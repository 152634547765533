<template>
  <div style="margin-left: 2rem; margin-right: 1%; margin-bottom: 2rem">
    <div class="pt-7" style="margin-right: 10px">
      <v-row>
        <!-- <v-col cols="12" xs="12" sm="4" md="4" lg="4">
          <v-text-field class="search" v-model.lazy="forsearch" color="#F99D20" :textFieldProps="{
            placeholder: 'Search',
            color: '#F99D20',
            dense: false,
          }" dense outlined :placeholder="$t('SearchShiftCode1')">
            <template v-slot:append>
              <v-btn @click="Search" class="white--text text-capitalize" color="#F99D20" style="
                    position: absolute;
                    right: 0;
                    top: 0;
                    padding: 0;
                    height: 39px;
                  ">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col> -->
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-text-field
            :placeholder="$t('SearchShiftCode1')"
            v-model="forsearch"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.1rem; margin-left:1rem;"
            @keyup.enter="Search()"></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <!-- <v-col>
        <v-btn
          :disabled="permission.upload"
          color="#F99D20"
          class="orange--text text-capitalize mr-5 mb-5"
          width="150"
          max-height="35"
          outlined
          @click="AUploaddialog = true"
        >
          {{ $t("Upload") }}</v-btn
        >
        </v-col> -->
        <v-col class="d-flex justify-lg-end" cols="12" xs="12" sm="4" md="3" lg="2">
          <v-btn color="#F99D20" class="white--text text-capitalize add_btn" width="150" max-height="35"
            :disabled="permission.add" @click="addDialog = true">{{ $t("Add") }}</v-btn>
        </v-col>
      </v-row>
      <v-data-table class="mt-4 tbheader" :headers="headers" :items="ShiftCodeLists" :page.sync="page"
        :items-per-page="itemsPerPage" hide-default-footer hide-default-header @page-count="pageCount = $event"
        :mobile-breakpoint="0">
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th style="cursor: pointer; max-width: 180px !important" class="b-text">
                {{ $t("DefaultShift") }}
              </th>
              <th style="cursor: pointer; min-width: 180px !important" class="b-text text-left"
                @click="sortFun('shift_Code')">
                {{ $t("Shift_Code") }}
                <v-icon small v-if="!shift_Code">mdi-arrow-down</v-icon>
                <v-icon small v-if="shift_Code">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important" class="b-text text-left"
                @click="sortFun('start_Time')">
                {{ $t("WorkPeriod") }}
                <v-icon small v-if="!start_Time">mdi-arrow-down</v-icon>
                <v-icon small v-if="start_Time">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important" class="b-text text-center" @click="sortFun('wh')">
                {{ $t("WorkingHours") }}
                <v-icon small v-if="!wh">mdi-arrow-down</v-icon>
                <v-icon small v-if="wh">mdi-arrow-up</v-icon>
              </th>
              <th class="b-text text-end">{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td align="left" v-if="item.defaultShift == true" @click.stop="ChangeDefault(item)">
              <div v-if="item.defaultShift == true">
                <input :disabled="disthis" type="checkbox" v-model="item.defaultShift" />
              </div>
            </td>
            <td align="left" v-else @click.stop="ChangeDefault(item)">
              <input :disabled="disthis" type="checkbox" style="cursor: default" v-model="item.defaultShift" />
            </td>
            <td>{{ item.shift_Code }}</td>
            <td>{{ item.start_Time + " - " + item.end_Time }}</td>
            <td class="text-center">{{ item.wh }}</td>
            <td class="text-end">
              <v-btn @click="EditShiftCode(item)" icon :disabled="permission.edit">
                <img class="editIcon" :src="images.edit" />
              </v-btn>
              <v-btn @click="DeleteShift(item.shift_ID)" icon :disabled="permission.delete || disthis">
                <img class="deleteIcon" :src="images.delete" />
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right">
        <PaginationComponent
            :page.sync="page"
            :pageCount="pageCount"
            :itemsPerPage.sync="itemsPerPage"
          />
      </div>

      <!-- add shift code dialog -->
      <v-dialog v-model="addDialog" persistent width="660">
        <v-card style="border-radius: 15px">
          <p class="pt-5 ml-8 mr-2" style="font-size: 22px; font-family: 'Kanit';">
            {{ $t("ShiftWork") }}
          </p>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <span style="font-size: 16px">
                    {{ $t("Shift_Code")
                    }}<span class="red--text"> *</span></span>
                  <v-text-field autocomplete="off" v-model="add.shift_Code" :error-messages="error.shift_Code"
                    color="#F99D20" dense outlined required>
                    <template v-slot:message="{}">
                      <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" /> <span>{{
                        error.shift_Code }}</span>
                    </template>
                  </v-text-field>
                  <span class="ml-1">{{ $t("plentershifttext") }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span style="font-size: 16px">
                    {{ $t("ShiftColor")
                    }}
                    <span class="pt-1">
                      <v-badge class="batman mt-1" color="white" :value="hover" grow transition="slide-y-transition">
                        <template v-slot:badge>
                          <div class="my-badge">{{ $t("Choose the color for representing this shift in Manage Shift.") }}
                          </div>
                        </template>
                        <v-hover v-model="hover">
                          <img style="width:16px; height:16px;" :src="images.infopic" />
                        </v-hover>
                      </v-badge></span></span>
                  <v-text-field v-model="add.color_Code" hide-details dense solo readonly>
                    <template v-slot:append>
                      <v-menu v-model="colorPicker" top nudge-bottom="105" nudge-left="16"
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="switchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker class="colorPicker" v-show="colorPicker" dot-size="25"
                              v-model="ThecolorPicker" mode="hexa" hide-mode-switch style="z-index: -1">
                            </v-color-picker>
                            <v-btn @click="colorPickerForclose" color="#F99D20" class="white--text" style="
                                margin-top: -60px;
                                margin-right: 15px;
                                right: 0;
                                z-index: 10;
                                position: absolute;
                              ">
                              {{ $t("ok") }}
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col>
                  <p style="font-size: 16px">{{ $t("ShiftType") }}</p>
                  <v-radio-group v-model="add.shiftType" class="ml-2 mt-1"
                    style="margin-top: -1.5rem">
                    <v-radio color="#F99D20" :value="1">
                      <template v-slot:label>
                        {{ $t("shiftype1txt") }}
                      </template>
                    </v-radio>
                    <!-- <v-radio disabled :value="2" color="#F99D20">
                      <template v-slot:label>
                        {{ $t("shiftype2txt") }}
                      </template>
                    </v-radio>
                    <v-radio disabled :value="3" color="#F99D20">
                      <template v-slot:label>
                        {{ $t("shiftype3txt") }}
                      </template>
                    </v-radio> -->
                  </v-radio-group>
                </v-col>
              </v-row>
              <p v-if="add.shiftType == 3" class="mb-0"
                style="font-size: 17px; text-decoration: underline; color:#707070">{{ $t("Round1") }}</p>
              <v-row>
                <v-col cols="12" md="6">
                  <span style="font-size: 16px">{{ $t("StartTime") }}<span class="red--text"> *</span></span>
                  <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" v-model="add.start_Time" append-icon="access_time"
                        :error-messages="error.start_Time" color="#F99D20" outlined dense readonly v-on="on">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.start_Time }}</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker v-if="menu2" v-model="add.start_Time" no-title fluid
                      @click:minute="$refs.menu2.save(add.start_Time)"></v-time-picker>
                  </v-menu>
                  <p class="ml-2" v-if="add.shiftType == 2">{{ thetype2txt1 }}</p>
                </v-col>

                <v-col cols="12" md="6">
                  <span style="font-size: 16px">{{ $t("EndTime") }}<span class="red--text"> *</span></span>
                  <v-menu ref="menu1" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="time3" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" v-model="add.end_Time" append-icon="access_time"
                        :error-messages="error.end_Time" color="#F99D20" readonly outlined dense v-on="on">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.end_Time }}</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker v-if="menu3" v-model="add.end_Time" no-title fluid
                      @click:minute="$refs.menu1.save(add.end_Time)"></v-time-picker>
                  </v-menu>
                  <p class="ml-2" v-if="add.shiftType == 2">{{ thetype2txt2 }}</p>
                </v-col>
              </v-row>
              <p v-if="add.shiftType == 3" class="mb-0"
                style="font-size: 17px; text-decoration: underline; color:#707070">{{ $t("Round2") }}</p>
              <v-row v-if="add.shiftType == 3">
                <v-col cols="12" md="6">
                  <span style="font-size: 16px">{{ $t("StartTime") }}<span class="red--text"> *</span></span>
                  <v-menu ref="menu6" v-model="menu6" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="time6" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" v-model="add.sec_start_Time" append-icon="access_time"
                        :error-messages="error.sec_start_Time" color="#F99D20" outlined dense readonly v-on="on">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.sec_start_Time }}</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker v-if="menu6" v-model="add.sec_start_Time" no-title fluid
                      @click:minute="$refs.menu6.save(add.sec_start_Time)"></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6">
                  <span style="font-size: 16px">{{ $t("EndTime") }}<span class="red--text"> *</span></span>
                  <v-menu ref="menu7" v-model="menu7" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="time7" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" v-model="add.sec_end_Time" append-icon="access_time"
                        :error-messages="error.sec_end_Time" color="#F99D20" readonly outlined dense v-on="on">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.sec_end_Time }}</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker v-if="menu7" v-model="add.sec_end_Time" no-title fluid
                      @click:minute="$refs.menu7.save(add.sec_end_Time)"></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="cancel">
              {{ $t("Cancel") }}</v-btn>
            <v-btn class="mr-2 text-capitalize commonsavebtn" @click="AddShiftCode()">{{
              $t("Add") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- edit shift code dialog -->
      <v-dialog v-model="Editdialog" persistent max-width="700px">
        <v-card style="border-radius: 15px">
          <p class="pt-5 ml-8 mr-2" style="font-size: 22px; font-family: 'Kanit'">
            {{ $t("EditShiftCode") }}
          </p>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <span style="font-size: 16px">
                    {{ $t("Shift_Code")
                    }}<span class="red--text"> *</span></span>
                  <v-text-field autocomplete="off" v-model="edit.shift_Code" :error-messages="error.shift_Code"
                    color="#F99D20" dense outlined required>
                    <template v-slot:message="{}">
                      <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                      <span>{{
                        error.shift_Code }}</span>
                    </template>
                  </v-text-field>
                  <span class="ml-1">{{ $t("plentershifttext") }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <span style="font-size: 16px">
                    {{ $t("ShiftColor")
                    }}
                    <span class="pt-1">
                      <v-badge class="batman mt-1" color="white" :value="hover" grow transition="slide-y-transition">
                        <template v-slot:badge>
                          <div class="my-badge">{{ $t("Choose the color for representing this shift in Manage Shift.") }}
                          </div>
                        </template>
                        <v-hover v-model="hover">
                          <img style="width:16px; height:16px;" :src="images.infopic" />
                        </v-hover>
                      </v-badge></span></span>
                  <v-text-field v-model="edit.color_Code" hide-details dense solo readonly>
                    <template v-slot:append>
                      <v-menu v-model="colorPicker1" top nudge-bottom="105" nudge-left="16"
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="switchStyle1" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker class="colorPicker" v-show="colorPicker1" dot-size="25"
                              v-model="ThecolorPicker1" mode="hexa" hide-mode-switch style="z-index: -1">
                            </v-color-picker>
                            <v-btn @click="colorPickerForclose1" color="#F99D20" class="white--text" style="
                                margin-top: -60px;
                                margin-right: 15px;
                                right: 0;
                                z-index: 10;
                                position: absolute;
                              ">
                              {{ $t("ok") }}
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col>
                  <p style="font-size: 16px">{{ $t("ShiftType") }}</p>
                  <v-radio-group v-model="edit.shiftType" class="ml-2"
                    style="margin-top: -1.5rem">
                    <v-radio color="#F99D20" :value="1">
                      <template v-slot:label>
                        {{ $t("shiftype1txt") }}
                      </template>
                    </v-radio>
                    <!-- <v-radio disabled :value="2" color="#F99D20">
                      <template v-slot:label>
                        {{ $t("shiftype2txt") }}
                      </template>
                    </v-radio>
                    <v-radio disabled :value="3" color="#F99D20">
                      <template v-slot:label>
                        {{ $t("shiftype3txt") }}
                      </template>
                    </v-radio> -->
                  </v-radio-group>
                </v-col>
              </v-row>
              <p v-if="edit.shiftType == 3" class="mb-0"
                style="font-size: 17px; text-decoration: underline; color:#707070">{{ $t("Round1") }}</p>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("StartTime") }}<span class="red--text"> *</span>

                  <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="time4" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" v-model="edit.start_Time" append-icon="access_time"
                        :error-messages="error.start_Time" color="#F99D20" outlined dense readonly v-on="on">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.start_Time }}</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker v-if="menu4" v-model="edit.start_Time" no-title fluid
                      @click="$refs.menu4.save(time4)"></v-time-picker>
                  </v-menu>
                  <p v-if="edit.shiftType == 2">{{ theedittype2txt1 }}</p>
                </v-col>

                <v-col cols="12" md="6">
                  {{ $t("EndTime") }}<span class="red--text"> *</span>
                  <v-menu ref="menu5" v-model="menu5" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="time5" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" v-model="edit.end_Time" append-icon="access_time"
                        :error-messages="error.end_Time" color="#F99D20" outlined dense readonly v-on="on">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.end_Time }}</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker v-if="menu5" v-model="edit.end_Time" no-title fluid
                      @click:minute="$refs.menu5.save(edit.time5)"></v-time-picker>
                  </v-menu>
                  <p v-if="edit.shiftType == 2">{{ theedittype2txt2 }}</p>
                </v-col>
              </v-row>
              <p v-if="edit.shiftType == 3" class="mb-0"
                style="font-size: 17px; text-decoration: underline; color:#707070">{{ $t("Round2") }}</p>
              <v-row v-if="edit.shiftType == 3">
                <v-col cols="12" md="6">
                  <span style="font-size: 16px">{{ $t("StartTime") }}<span class="red--text"> *</span></span>
                  <v-menu ref="menu8" v-model="menu8" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="time8" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" v-model="edit.sec_start_Time" append-icon="access_time"
                        :error-messages="error.sec_start_Time" color="#F99D20" outlined dense readonly v-on="on">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.sec_start_Time }}</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker v-if="menu8" v-model="edit.sec_start_Time" no-title fluid
                      @click:minute="$refs.menu8.save(edit.sec_start_Time)"></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6">
                  <span style="font-size: 16px">{{ $t("EndTime") }}<span class="red--text"> *</span></span>
                  <v-menu ref="menu9" v-model="menu9" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="time9" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" v-model="edit.sec_end_Time" append-icon="access_time"
                        :error-messages="error.sec_end_Time" color="#F99D20" readonly outlined dense v-on="on">
                        <template v-slot:message="{}">
                          <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                          <span>{{
                            error.sec_end_Time }}</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker v-if="menu9" v-model="edit.sec_end_Time" no-title fluid
                      @click:minute="$refs.menu9.save(edit.sec_end_Time)"></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn class="mr-2 text-capitalize commoncancelbtn" outlined text @click="Editcancel()">
              {{ $t("Cancel") }}</v-btn>
            <v-btn class="mr-2 text-capitalize commonsavebtn" @click="UpdateShiftCode()">
              {{ $t("update") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- delete Shift dialog -->
      <v-dialog v-model="Deletedialog" persistent max-width="532">
        <v-card style="border-radius: 15px">
          <v-card-title>
            <p style="margin-top: 10px; margin-bottom: -30px">
              {{ $t("deleteitem") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col align="center" cols="12">
                  <p style="font-size: 16px; color: #444444" class="pt-4">
                    {{ $t("DeleteShitfCode") }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="Deletedialog = false" text>
              {{ $t("Cancel") }}</v-btn>
            <v-btn color="#FF2727" class="mr-2 white--text text-capitalize" width="120" max-height="35"
              @click="DeleteShiftrow()">{{ $t("Delete") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- uploadDialog -->
      <v-dialog v-model="AUploaddialog" presistent max-width="700">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
              {{ $t("UploadFile") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col style="margin-left: 3px" align="center" cols="6">
                  <v-file-input truncate-length="100" v-show="showup" color="white" class="myfile" id="fileInputButton"
                    v-model="chosenfile" prepend-icon accept=".xlsx">
                    <template v-slot:append>
                      <v-btn icon>
                        <img width="20" height="20" @click="closeinput" src="@/assets/closered.png" alt />
                      </v-btn>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-btn color="#ACACAC" class="white--text text-capitalize" width="150" max-height="35"
                    @click="getupload">
                    {{ $t("browse") }}</v-btn>
                </v-col>
                <v-col cols="12" md="9">
                  <p class="mt-2" style="color: #f74747">
                    **{{ $t("excelFileFormat") }}
                  </p>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
              <v-row>
                <v-col cols="12" md="4">
                  <p>Shift Code Template.xlsx</p>
                </v-col>
                <v-col cols="12" md="8">
                  <a style="color: #f99d20" :href="whatthelang()">{{ $t("download") }}</a>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br />
              <br />
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn class="mr-2 text-capitalize" width="120" height="35" text @click="closeUpload">{{ $t("Cancel")
            }}</v-btn>
            <v-btn color="#F99D20" class="white--text mr-2 text-capitalize" width="120" height="35"
              @click="PreviewFiles">{{ $t("Upload") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Success Dialog -->
      <v-dialog v-model="StatusDialog" persistent width="290">
        <v-card style="border-radius: 15px">
          <div class="d-flex justify-center">
            <img src="@/assets/images/succesmark.png" class=" mt-5" width="40" height="40" />
          </div>
          <v-card-text class="black--text d-flex justify-center ">
            <v-col style="text-align: center;">
              <p>{{ $t("Success") }}</p>
              <p>{{ $t("SaveDataSuccess") }}</p>
              <v-btn @click="StatusDialog = false" color="#F99D20" class="mr-2 text-capitalize add" width="70"
                max-height="35">{{ $t("ok") }}</v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Loading Dialog -->
      <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import moment from "moment";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';
// function isError(val) {
//   return `${val} is required`;
// }

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  data() {
    return {
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      time: null,
      menu2: false,
      modal2: false,
      time3: null,
      menu3: false,
      modal3: false,
      time4: null,
      menu4: false,
      modal4: false,
      time5: null,
      menu5: false,
      modal5: false,
      time6: null,
      menu6: false,
      time7: null,
      menu7: false,
      time8: null,
      menu8: false,
      time9: null,
      menu9: false,
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      addDialog: false,
      Editdialog: false,
      Deletedialog: false,
      uploaddialog: false,
      AUploaddialog: false,
      LoadingDialog: false,
      ShiftCodeList: [],
      ShiftCodeLists: [],
      ShiftCodeSearch: [],
      forsearch: "",
      thesearchnum: 0,
      editShiftId: null,
      deleteShiftId: 0,
      chosenfile: null,
      tempimport: [],
      importData: [],
      showup: false,
      start_Time: null,
      end_Time: null,
      shift_Code: null,
      wh: null,
      shiftType: true,
      ThecolorPicker: "#BDBDBD",
      colorPicker: false,
      ThecolorPicker1: "#BDBDBD",
      colorPicker1: false,
      hover: false,
      StatusDialog: false,
      thetype2txt1: "",
      thetype2txt2: "",
      theedittype2txt1: "",
      theedittype2txt2: "",
      mytemp: {
        company_ID: store.state.companyID,
      },
      disthis: false,
      search: "",
      headers: [
        {
          text: "Shift Code",
          value: "shift_Code",
        },
        {
          text: "Start Time ",
        },
        {
          text: "End Time",
        },
        {
          text: "Working Hours",
        },
        {
          text: "Action",
          value: "actionadd",
        },
      ],
      add: {
        shift_Code: "",
        company_ID: "",
        start_Time: "",
        end_Time: "",
        sec_start_Time: "",
        sec_end_Time: "",
        shiftType: 1,
        color_Code: "#BDBDBD"
      },
      edit: {
        ShiftId: "",
        shift_Code: "",
        company_ID: "",
        start_Time: "",
        end_Time: "",
        sec_start_Time: "",
        sec_end_Time: "",
        shiftType: 1,
        color_Code: "#BDBDBD"
      },
      error: {
        shift_Code: "",
        company_ID: "",
        start_Time: "",
        end_Time: "",
        sec_start_Time: "",
        sec_end_Time: "",
        color_Code: "",
      },
      errorMessage: [],
    };
  },
  watch: {
    forsearch: function () {
      if (this.thesearchnum == 1) {
        this.GetShiftCode();
      }
    },
    "add.shift_Code"(v) {
      if (v) this.error.shift_Code = "";
    },
    "add.start_Time"(v) {
      if (v) this.error.start_Time = "";
      var temp = new Date();
      this.thetype2txt1 = "Ex." + temp.getDate() + "/" + (temp.getMonth() + 1) + "/" + temp.getFullYear() + " " + v;
    },
    "add.end_Time"(v) {
      if (v) this.error.end_Time = "";
      var temp = new Date();
      this.thetype2txt2 = "Ex." + (temp.getDate() + 1) + "/" + (temp.getMonth() + 1) + "/" + temp.getFullYear() + " " + v;
    },
    "add.sec_start_Time"(v) {
      if (v) this.error.sec_start_Time = "";
    },
    "add.sec_end_Time"(v) {
      if (v) this.error.sec_end_Time = "";
    },
    "add.color_Code"(v) {
      if (v) this.error.color_Code = "";
    },
    "edit.shift_Code"(v) {
      if (v) this.error.shift_Code = "";
    },
    "edit.start_Time"(v) {
      if (v) this.error.start_Time = "";
      var temp = new Date();
      this.theedittype2txt1 = "Ex." + temp.getDate() + "/" + (temp.getMonth() + 1) + "/" + temp.getFullYear() + " " + v;
    },
    "edit.end_Time"(v) {
      if (v) this.error.end_Time = "";
      var temp = new Date();
      this.theedittype2txt2 = "Ex." + (temp.getDate() + 1) + "/" + (temp.getMonth() + 1) + "/" + temp.getFullYear() + " " + v;
    },
    "edit.sec_start_Time"(v) {
      if (v) this.error.sec_start_Time = "";
    },
    "edit.sec_end_Time"(v) {
      if (v) this.error.sec_end_Time = "";
    },
    "edit.color_Code"(v) {
      if (v) this.error.color_Code = "";
    },
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
  },
  mounted: function () {
    let self = this;
    self.GetShiftCode();
    self.permission = self.CheckViewAddEditDeleteUpload(30, 31, 32, 33, 34);
  },
  computed: {
    switchStyle() {
      if (!this.add.color_Code) 
      {
        return {
          backgroundColor: this.ThecolorPicker,
          cursor: "pointer",
          height: "35px",
          width: "60px",
          borderRadius: "4px",
          transition: "border-radius 200ms ease-in-out",
        };
      } 
      else 
      {
        return {
          backgroundColor: this.add.color_Code,
          cursor: "pointer",
          height: "35px",
          width: "60px",
          borderRadius: "4px",
          transition: "border-radius 200ms ease-in-out",
        };
      }
    },
    switchStyle1() {
      if (!this.edit.color_Code) 
      {
        return {
          backgroundColor: this.ThecolorPicker1,
          cursor: "pointer",
          height: "35px",
          width: "60px",
          borderRadius: "4px",
          transition: "border-radius 200ms ease-in-out",
        };
      } 
      else 
      {
        return {
          backgroundColor: this.edit.color_Code,
          cursor: "pointer",
          height: "35px",
          width: "60px",
          borderRadius: "4px",
          transition: "border-radius 200ms ease-in-out",
        };
      }
    },
    images() {
      return this.$store.state.images;
    }
  },
  methods: {
    colorPickerForclose() {
      this.add.color_Code = "";
      this.add.color_Code = this.ThecolorPicker.slice(0, 7);
      this.colorPicker = false;
    },
    colorPickerForclose1() {
      this.edit.color_Code = "";
      this.edit.color_Code = this.ThecolorPicker1.slice(0, 7);
      this.colorPicker1 = false;
    },
    async ChangeDefault(item) {
      let self = this;
      if(self.disthis==false){
        self.LoadingDialog = true;
        item.defaultShift = !item.defaultShift;
        let temp = {
          shift_ID: item.shift_ID,
          defaultShift: item.defaultShift,
          company_ID: store.state.companyID,
          user_ID: store.state.userid
        };
        await axios
          .post(`${self.url}ShiftCode/UpdateShiftDefault`, temp)
          .then(function (response) {
            if (response.data.status == 0)
            {
              self.LoadingDialog = false;
              self.StatusDialog = true;
              self.GetShiftCode();
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update ShiftCode Fail", 8, "Medium");
            alert(error + "Correlation ID is 8");
          });
        } else {
          alert("yo")
        }
    },
    sortFun(rowName) {
      let self = this;
      let keyName = ["end_Time", "start_Time", "shift_Code", "wh"];
      if (rowName == "end_Time" || rowName == "start_Time") {
        self.ShiftCodeLists = self.ShiftCodeLists.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else if (rowName == "wh") {
        self.ShiftCodeLists = self.ShiftCodeLists.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName], undefined, {
              numeric: true,
              sensitivity: "base",
            })
            : a[rowName].localeCompare(b[rowName], undefined, {
              numeric: true,
              sensitivity: "base",
            })
        );
      } else {
        self.ShiftCodeLists = self.ShiftCodeLists.sort((a, b) =>
          self[rowName]
            ? b[rowName] - a[rowName] ||
            b[rowName].toString().localeCompare(a[rowName].toString())
            : a[rowName] - b[rowName] ||
            a[rowName].toString().localeCompare(b[rowName].toString())
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    whatthelang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink = "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Shift Code Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink = "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Shift Code Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
        } else {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Shift Code Master Data.xlsx";
        }
      }
      return thelink;
    },
    defaultSort() {
      this.end_Time = null;
      this.start_Time = null;
      this.shift_Code = null;
      this.wh = null;
    },
    resetForm() {
      this.error.shift_Code = [];
      this.error.start_Time = [];
      this.error.end_Time = [];
      this.error.sec_start_Time = [];
      this.error.sec_end_Time = [];
      this.error.color_Code = [];
    },
    cancel() {
      this.addDialog = false;
      this.add.start_Time = null;
      this.add.end_Time = null;
      this.add.sec_start_Time = null;
      this.add.sec_end_Time = null;
      this.add.shift_Code = null;
      this.add.shiftType = 1;
      this.add.color_Code = "#BDBDBD";
      this.resetForm();
    },
    Editcancel() {
      this.Editdialog = false;
      this.edit.start_Time = null;
      this.edit.end_Time = null;
      this.edit.sec_start_Time = null;
      this.edit.sec_end_Time = null;
      this.edit.shift_Code = null;
      this.resetForm();
    },
    checkDuplicate(item) {
      return !this.ShiftCodeLists.some(
        (el) => el.shift_Code.toLowerCase() == item.toLowerCase()
      );
    },
    checkStartEnd(startTime, endTime) {
      return !this.ShiftCodeLists.some(
        (el) => el.start_Time == startTime && el.end_Time == endTime
      );
    },
    editDuplicate(item, id) {
      let notSameList = this.ShiftCodeLists.filter((el) => el.shift_ID != id);
      return !notSameList.some(
        (el) => el.shift_Code.toLowerCase() == item.toLowerCase()
      );
    },
    editStartEnd(id, startTime, endTime) {
      let notSameList = this.ShiftCodeLists.filter((el) => el.shift_ID != id);
      return !notSameList.some(
        (el) => el.start_Time == startTime && el.end_Time == endTime
      );
    },
    WorkingHours(start, end) {
      let startTime = moment(start, "HH:mm");
      let endTime = moment(end, "HH:mm");
      let minutes = endTime.diff(startTime, "minutes");
      let interval = moment().hour(0).minute(minutes);
      let result = interval.format("HH:mm");
      let getHours = moment(result, "HH:mm").format("H");
      let getMin = moment(result, "HH:mm").format("m");
      let results = "";
      if (getHours == "0") {
        results = `${getMin} minutes`;
      } else if (getMin == "0") {
        results = `${getHours} hours`;
      } else {
        results = `${getHours} hours ${getMin} minutes`;
      }

      return results;
    },
    updateDate(date) {
      var upTime = new Date(Date.parse(date));

      return upTime;
    },
    formatDate(date) {
      if (date) {
        var temp = new Date(date);
        var hour = "" + temp.getHours();
        if (hour.length < 2) {
          hour = "0" + temp.getHours();
        }
        var minut = "" + temp.getMinutes();
        if (minut.length < 2) {
          minut = "0" + temp.getMinutes();
        }
        const retvalue = hour + ":" + minut;
        return retvalue;
      } else {
        return "";
      }
    },

    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => { });
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      //this.showup = true;
    },
    closeUpload() {
      this.AUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    GetShiftCode() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}ShiftCode/GetShiftCodebyCompanyID`, tempp)
        .then(function (response) {
          self.ShiftCodeList = response.data.data;
          //let list = [];
          self.ShiftCodeLists = [];
          for (let i = 0; i < self.ShiftCodeList.length; i++) {
            let Lists = {
              ...self.ShiftCodeList[i],
              wh: self.WorkingHours(
                self.ShiftCodeList[i].start_Time,
                self.ShiftCodeList[i].end_Time
              ),
              wh1: self.WorkingHours(
                self.ShiftCodeList[i].start_Time,
                self.ShiftCodeList[i].end_Time
              ),
            };
            self.ShiftCodeLists.push(Lists);
          }
          if(self.ShiftCodeLists.length == 1){
           self.disthis = true;
          }
          self.thesearchnum = 0;
          self.defaultSort();
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get ShiftCode Fail", 8, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    AddShiftCode() {
      let self = this;
      self.error.shift_Code = !self.add.shift_Code ? self.$t("require_field") : "";
      self.error.start_Time = !self.add.start_Time ? self.$t("require_field") : "";
      self.error.end_Time = !self.add.end_Time ? self.$t("require_field") : "";
      self.error.color_Code = !self.add.color_Code ? self.$t("require_field") : "";
      if (self.add.shiftType == 3) {
        self.error.sec_start_Time = !self.add.sec_start_Time ? self.$t("require_field") : "";
        self.error.sec_end_Time = !self.add.sec_end_Time ? self.$t("require_field") : "";
      }
      let tempvali = self.ValidateAddTrue();
      if (tempvali == true) {
        if (self.checkDuplicate(self.add.shift_Code)) {
          if (self.checkStartEnd(self.add.start_Time, self.add.end_Time)) {
            self.LoadingDialog = true;

            let tempShift = {
              shift_Code: self.add.shift_Code,
              company_ID: store.state.companyID,
              start_Time: self.add.start_Time,
              end_Time: self.add.end_Time,
              defaultShift: false,
              shiftType: self.add.shiftType,
              shiftColor: self.add.color_Code,
              user_ID: store.state.userid
            };
            axios
              .post(`${self.url}ShiftCode/AddShiftCode`, tempShift)
              .then(function (response) {
                if (response.data.status == 0) {
                  alert(response.data.message);

                  self.add.shift_Code = null;
                  self.add.start_Time = null;
                  self.add.end_Time = null;
                  self.addDialog = false;
                  self.LoadingDialog = false;
                  self.ShiftCodeLists = [];
                  self.GetShiftCode();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Add ShiftCode Fail", 8, "Low");
                alert(error + "Correlation ID is 8");
              });
          } else {
            alert(
              "Shift start time/ Shift end time already exists for another shift in the Master data. Please check and revise."
            );
          }
        } else {
          alert(
            "Shift code already exists in the Master data. Please check and revise."
          );
        }
      }
    },
    ValidateAddTrue() {
      let self = this;
      if (
        self.add.shift_Code != null &&
        self.add.start_Time != null &&
        self.add.end_Time != null &&
        self.add.color_Code != null &&
        self.add.shift_Code != "" &&
        self.add.start_Time != "" &&
        self.add.end_Time != "" &&
        self.add.color_Code != ""
      ) {
        if (self.add.shiftType == 3) {
          if (
            self.add.sec_start_Time != null &&
            self.add.sec_start_Time != "" &&
            self.add.sec_end_Time != null &&
            self.add.sec_end_Time != ""
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    EditShiftCode(temp) {
      let self = this;
      self.Editdialog = true;
      self.edit.ShiftId = temp.shift_ID;
      self.edit.shift_Code = temp.shift_Code;
      self.edit.start_Time = temp.start_Time;
      self.edit.end_Time = temp.end_Time;
      self.edit.shiftType = temp.shiftType;
      self.edit.color_Code = temp.shiftColor;
    },
    UpdateShiftCode() {
      let self = this;
      self.error.shift_Code = !self.edit.shift_Code
        ? self.$t("require_field")
        : "";
      self.error.start_Time = !self.edit.start_Time
        ? self.$t("require_field")
        : "";
      self.error.end_Time = !self.edit.end_Time ? self.$t("require_field") : "";
      self.error.color_Code = !self.edit.color_Code ? self.$t("require_field") : "";
      if (self.edit.shiftType == 3) {
        self.error.sec_start_Time = !self.edit.sec_start_Time ? self.$t("require_field") : "";
        self.error.sec_end_Time = !self.edit.sec_end_Time ? self.$t("require_field") : "";
      }
      let tempvalid = self.ValidateEditTrue();
      if (tempvalid == true) {
        let editDuplicate = self.editDuplicate(
          self.edit.shift_Code,
          self.edit.ShiftId
        );
        if (editDuplicate) {
          let editStartEnd = self.editStartEnd(
            self.edit.ShiftId,
            self.edit.start_Time,
            self.edit.end_Time
          );
          if (editStartEnd) {
            self.LoadingDialog = true;

            let tempEdit = {
              shift_ID: self.edit.ShiftId,
              shift_Code: self.edit.shift_Code,
              company_ID: store.state.companyID,
              start_Time: self.edit.start_Time,
              end_Time: self.edit.end_Time,
              shiftType: self.edit.shiftType,
              shiftColor: self.edit.color_Code,
              user_ID: store.state.userid
            };
            axios
              .post(`${self.url}ShiftCode/UpdateShiftCode`, tempEdit)
              .then(function (response) {
                if (response.data.status == 0)
                {
                  alert(response.data.message);
                  self.LoadingDialog = false;
                  self.ShiftCodeLists = [];
                  self.Editdialog = false;
                  self.GetShiftCode();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Update ShiftCode Fail", 8, "Medium");
                alert(error + "Correlation ID is 8");
              });
          } else {
            alert(
              "Shift start time/ Shift end time already exists for another shift in the Master data. Please check and revise."
            );
          }
        } else {
          alert(
            "Shift code already exists in the Master data. Please check and revise."
          );
        }
      }
    },

    ValidateEditTrue() {
      let self = this;
      if (
        self.edit.shift_Code != null &&
        self.edit.start_Time != null &&
        self.edit.end_Time != null &&
        self.edit.shift_Code != "" &&
        self.edit.start_Time != "" &&
        self.edit.end_Time != ""
      ) {
        if (self.edit.shiftType == 3) {
          if (
            self.edit.sec_start_Time != null &&
            self.edit.sec_start_Time != "" &&
            self.edit.sec_end_Time != null &&
            self.edit.sec_end_Time != ""
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    DeleteShift(id) {
      let self = this;
      self.deleteShiftId = id;
      self.Deletedialog = true;
    },
    DeleteShiftrow() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.deleteShiftId;
      let tempdel = {
        shift_ID: id,
        company_ID: store.state.companyID,
        user_ID: store.state.userid
      };
      axios
        .post(`${self.url}ShiftCode/DeleteShiftCode`, tempdel)
        .then(function (response) {
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.ShiftCodeLists = [];
            self.GetShiftCode();
            self.Deletedialog = false;
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          this.LogTrace(error, "Delete ShiftCode Fail", 8, "High");
          alert(error + "Correlation ID is 8");
          self.LoadingDialog = false;
        });
    },
    ChangeToTime(d) {
      let day = new Date(Math.round((d - (25567 + 2)) * 86400) * 1000);
      const getutcmin = day.getUTCMinutes();
      const getutchrs = day.getUTCHours();
      let result = "";
      if (getutchrs < 10) {
        if (getutcmin < 10) {
          result = "0" + getutchrs + ":" + "0" + getutcmin;
        } else {
          result = "0" + getutchrs + ":" + getutcmin;
        }
      } else {
        if (getutcmin < 10) {
          result = getutchrs + ":" + "0" + getutcmin;
        } else {
          result = getutchrs + ":" + getutcmin;
        }
      }
      return result;
    },
    PreviewFiles() {
      let self = this;
      self.LoadingDialog = true;
      let files = self.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Shift Code Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.importData = XLSX.utils.sheet_to_json(worksheet);
            const file_data = XLSX.utils.sheet_to_json(worksheet, { header: 1, });
            let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            let party = [];
            for (let j = 0; j < self.importData.length; j++) {
              party.push({
                shift_Code: file_data[j + 1][0],
                StartTime: file_data[j + 1][1],
                EndTime: file_data[j + 1][2],
              })
            }
            var ShiftCodeFiltered = party.filter((org) => org.shift_Code == undefined);
            var StartTimeFiltered = party.filter((org) => org.StartTime == undefined);
            var EndTimeFiltered = party.filter((org) => org.EndTime == undefined);
            if (
              ShiftCodeFiltered.length == 0 &&
              StartTimeFiltered.length == 0 &&
              EndTimeFiltered.length == 0 &&
              temp.length != 0
            ) {
              let combileArray = [];
              let isDuplicate = [];
              for (let i = 0; i < self.importData.length; i++) {
                self.tempimport = {
                  shift_Code: file_data[i + 1][0],
                  StartTime: file_data[i + 1][1],
                  EndTime: file_data[i + 1][2],
                  start_Time: self.ChangeToTime(file_data[i + 1][1]),
                  end_Time: self.ChangeToTime(file_data[i + 1][2]),
                  company_ID: store.state.companyID,
                };

                combileArray.push(self.tempimport);
                isDuplicate.push(
                  self.checkDuplicate(combileArray[i].shift_Code)
                );
                isDuplicate.push(
                  self.checkStartEnd(
                    combileArray[i].start_Time,
                    combileArray[i].end_Time
                  )
                );
                // console.log(isDuplicate);
              }

              if (isDuplicate.every((x) => x)) {
                self.LoadingDialog = true;
                axios
                  .post(`${self.url}ShiftCode/ImportShiftCode`, combileArray)
                  .then(function (response) {
                    if (response.data.status == 0) {
                      alert("Upload Successfully");
                      self.ShiftCodeLists = [];
                      self.GetShiftCode();
                      self.chosenfile = null;
                      self.showup = false;
                      self.AUploaddialog = false;
                      self.LoadingDialog = false;
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(error, "Upload ShiftCode Fail", 8, "Verbose");
                    alert(error + "Correlation ID is 8");
                    self.LoadingDialog = false;
                  });
              } else {
                alert(
                  "Shift code/ Shift start time/ Shift end time already exists for another shift in the Master data. Please check and revise."
                );
                self.LoadingDialog = false;
              }
            } else {
              self.LoadingDialog = false;
              if (temp.length == 0) {
                alert("Please fill shift_Code ,StartTime ,EndTime");
              } else {
                if (ShiftCodeFiltered.length != 0) {
                  self.errorMessage.push("shift_Code");
                }
                if (StartTimeFiltered.length != 0) {
                  self.errorMessage.push("StartTime");
                }
                if (EndTimeFiltered.length != 0) {
                  self.errorMessage.push("EndTime");
                }
                let Message = [...new Set(self.errorMessage)];
                alert(`Please fill ${Message.join(", ")}`);
              }
            }
          } else {
            alert("File name must be Shift Code Master Data.xlsx");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Choose File.");
        self.LoadingDialog = false;
      }
    },
    Search() {
      let self = this;
      self.thesearchnum = 1;
      if (self.forsearch != "" && self.forsearch != undefined) {
        let List = self.ShiftCodeList;
        self.ShiftCodeLists = [];

        let temp = List.filter((v) =>
          v.shift_Code.toLowerCase().includes(self.forsearch.toLowerCase())
        );
        for (let i = 0; i < temp.length; i++) {
          let Lists = {
            ...temp[i],
            wh: self.WorkingHours(temp[i].start_Time, temp[i].end_Time),
          };
          self.ShiftCodeLists.push(Lists);
        }
      } else {
        alert("Please fill in search field");
        self.GetShiftCode();
      }
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 375px) {
  .add_btn {
    margin-top: -3rem;
  }

  .search {
    margin-left: -0.5rem;
  }

  .sear {
    margin-left: -0.2rem !important;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .search {
    margin-left: -0.5rem;
  }

  .add_btn {
    margin-top: -3rem;
  }

  .sear {
    margin-left: -0.2rem !important;
  }
}

@media (max-width: 375px) {
  .headtext {
    margin-left: 2rem !important;
  }
}

@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
}

>>>.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

.posbtn {
  width: 22px;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

>>>.mdi-paperclip::before {
  content: none;
}

>>>.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>>.v-dialog {
  border-radius: 15px !important;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  font-weight: normal;
  font-size: 15px;
  color: #f99d20;
}

>>>.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: rgba(0, 0, 0, 0.54);
  background-color: #00000029;
}

.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
}

.v-input__icon--append .v-icon {
  color: purple;
}

>>>.v-text-field__details {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 0px;
  min-width: 1px;
  position: relative;
}

>>>.v-messages {
  min-height: 0px;
}

.colorPicker>>>.v-color-picker__dot {
  display: none;
}

/* .colorPicker>>>canvas {
  width: 270px;
} */

>>>.v-color-picker.colorPicker.v-sheet.theme--light.theme--light {
  max-width: 300px !important;
}

.colorPicker>>>span {
  position: absolute;
  left: 20px;
  font-size: 12px;
  font-weight: bold;
  padding-top: 3px;
  font-family: Dbx;
}

.colorPicker>>>input {
  font-size: 12px;
  width: 100px;
  margin-right: 80px;
}

.my-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-right: 0.9em; */
  font-size: 13px;
  height: 34px;
  width: 300px;
  color: #FFFF;
  background: #383842;
  white-space: pre-wrap;
  border-radius: 8px;
}

/* Switch Enable login */
@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(circle 12px,
        white 100%,
        transparent calc(100% + 1px)) #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: white;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
  }
}

/* Switch Enable login */
</style>
<style>
.inputbot.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none;
}

.inputbot.v-text-field>.v-input__control>.v-input__slot::after {
  border-style: none;
}
</style>
