<template>
    <v-container grid-list-xs>
        <div style="cursor: context-menu;margin-left:-40px;">
            <table class="ml-9">
              <tr>
                <td>
                  <img style="margin-top:11px;" src="@/assets/images/Mask Group 54.png" />
                </td>
                <td>
                  <p
                    style="color:#F89D1F;font-size:16px;margin-top: 20px;margin-left: 10px;font-size:16px;"
                  >{{$t('uniform_fee')}}</p>
                </td>
              </tr>
            </table>
            <v-row class="pl-md-5" style="margin-left:10px;margin-right:10px;">
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-text-field :label="$t('Contract_number')" color="#8A8C8E"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      append-icon="mdi-calendar-month-outline"
                      v-model="computedDateFormatted3"
                      :label="$t('Contract_date')"
                      class="kanit-medium"
                      color="#8A8C8E"
                      persistent-hint
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date3" no-title @input="menu3 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-select
                  :items="items"
                  color="#8A8C8E"
                  :label="$t('Interest_method')"
                  append-icon="mdi-chevron-down"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="pl-md-5" style="margin-left:10px;margin-right:10px;">
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-text-field :label="$t('Interest_rate_per_year')" color="#8A8C8E"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-text-field :label="$t('Uniform_fee')" color="#8A8C8E"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-text-field :label="$t('Paid_back')" color="#8A8C8E"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pl-md-5" style="margin-left:10px;margin-right:10px;">
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-text-field :label="$t('Installments_year')" color="#8A8C8E"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-text-field :label="$t('Total_interest')" color="#8A8C8E"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-text-field :label="$t('Deduct_each_time')" color="#8A8C8E"></v-text-field>
              </v-col>
            </v-row>
            <div class="d-flex flex-row-reverse" style="margin-top:3rem;padding-bottom:3rem;">
              <v-btn color="#F89D1F" class="pa-2 mr-7" rounded width="160px" height="50px">{{$t('cancel')}}</v-btn>
              <v-btn
                color="#3C4043"
                class="pa-2 mr-5"
                width="160px"
                height="50px"
                text
                rounded
              >{{$t('cancel')}}</v-btn>
            </div>
    </div>
    </v-container>
</template>

<script src="https://cdn.jsdelivr.net/npm/babel-polyfill/dist/polyfill.min.js">
</script>
<script>
export default {
  data: vm => ({
    active_tab: 2,
    date3: new Date().toISOString().substr(0, 10),
    menu3: false
  }),
  computed: {
    computedDateFormatted3() {
      return this.formatDate(this.date3);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
};
</script>