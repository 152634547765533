<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="margin-bottom: 1rem">
      <v-row>
        <v-btn icon router color="white" @click="back()" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="mb-1" />
        </v-btn>
        <p
          style="
            font-size: 25px;
            font-family: 'Kanit', sans-serif;
            margin-left: 7px;
            margin-top: 20px;
          "
        >
          {{ $t("Educational_background") }}
        </p>
      </v-row>
    </div>

    <v-card class="pt-1 mb-5">
      <v-row class="mt-8 ma-3">
        <v-col cols="12" xs="12" sm="6" md="8">
          <p style="font-size: 20px; font-family: 'Kanit', sans-serif">
            {{ $t("Educational_background") }}
          </p>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="4">
          <v-row>
            <v-col cols="12" xs="12" sm="6" xl="4" class="offset-xl-4">
              <v-btn
                color="#F99D20"
                class="orange--text text-capitalize mr-5"
                block
                max-height="35"
                outlined
                @click="EbUploaddialog = true"
                >{{ $t("upload") }}</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="6" xl="4">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mr-5"
                block
                max-height="35"
                router
                @click="addDialogForEB = true"
                >{{ $t("Add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        style="margin-left: 20px; margin-right: 40px; margin-top: 30px"
        :headers="header1"
        :items="EducationList"
        :page.sync="pageEd"
        :items-per-page="itemsPerPageEd"
        hide-default-footer
        @page-count="pageCountEd = $event"
        hide-default-header
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th>{{ $t("institute") }}</th>

              <th>{{ $t("degree") }}</th>
              <th>{{ $t("GPA") }}</th>
              <th>{{ $t("start_year") }}</th>
              <th>{{ $t("end_year") }}</th>

              <th style="text-align: center">{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.institute }}</td>
            <td>{{ item.degree }}</td>

            <td>{{ item.gpa }}</td>
            <td>{{ item.start_Year }}</td>
            <td>{{ item.end_Year }}</td>

            <td align="center">
              <v-btn @click="editTask(item)" icon>
                <img :src="images.editpic" width="24px" height="24px"/>
              </v-btn>

              <v-btn @click="deleteTasks(item.education_ID)" icon class="ml-2">
                <img :src="images.deletepic" width="24px" height="24px"/>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div v-if="EducationList.length != 0" class="text-right">
        <PaginationComponent
            :page.sync="pageEd"
            :pageCount="pageCountEd"
            :itemsPerPage.sync="itemsPerPageEd"
        /> 
        <!-- <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination
              v-model="pageEd"
              :length="pageCountEd"
              color="#FFCD2C"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPageEd"
              :items="itemsEd"
              @input="itemsPerPageEd = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row> -->
      </div>

      <!-- short training -->

      <v-row class="mt-12 ma-3">
        <v-col cols="12" xs="12" sm="6" md="8">
          <p style="font-size: 20px; font-family: 'Kanit', sans-serif">
            {{ $t("short_training") }}
          </p>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-row>
            <v-col cols="12" xs="12" sm="6" xl="4" class="offset-xl-4">
              <v-btn
                color="#F99D20"
                class="orange--text text-capitalize mr-5"
                block
                max-height="35"
                outlined
                @click="StUploaddialog = true"
                >{{ $t("upload") }}</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="6" xl="4">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mr-5"
                block
                max-height="35"
                router
                @click="clearShort"
                >{{ $t("Add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        style="margin-left: 20px; margin-right: 40px; margin-top: 30px"
        :headers="header2"
        :items="shortList"
        :page.sync="pageSt"
        :items-per-page="itemsPerPageSt"
        hide-default-footer
        hide-default-header
        @page-count="pageCountSt = $event"
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th>{{ $t("training_program") }}</th>
              <th>{{ $t("institute") }}</th>
              <th></th>
              <th>{{ $t("duration") }}</th>
              <th>{{ $t("Year") }}</th>
              <th></th>

              <th></th>
              <th style="text-align: center">{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.training_Program }}</td>
            <td>{{ item.institute }}</td>
            <td></td>
            <td>{{ item.duration }}</td>

            <td>{{ item.year }}</td>
            <td></td>
            <td></td>
            <td align="center">
              <v-btn @click="editTaskSt(item)" icon>
                <img :src="images.editpic" />
              </v-btn>

              <v-btn @click="DeleteSt(item.trainingID)" icon class="ml-2">
                <img :src="images.deletepic" />
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div v-if="shortList.length != 0" class="text-right">
        <PaginationComponent
            :page.sync="pageSt"
            :pageCount="pageCountSt"
            :itemsPerPage.sync="itemsPerPageSt"
        /> 
        <!-- <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination
              v-model="pageSt"
              :length="pageCountSt"
              color="#FFCD2C"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPageSt"
              :items="itemsSt"
              @input="itemsPerPageSt = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row> -->
      </div>

      <v-row class="mt-12 ma-3">
        <v-col cols="12" xs="12" sm="6" md="8">
          <p style="font-size: 20px; font-family: 'Kanit', sans-serif">
            {{ $t("languages") }}
          </p>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-row>
            <v-col cols="12" xs="12" sm="6" xl="4" class="offset-xl-4">
              <v-btn
                color="#F99D20"
                class="orange--text text-capitalize mr-5"
                block
                max-height="35"
                outlined
                @click="LgUploaddialog = true"
                >{{ $t("upload") }}</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="6" xl="4">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mr-5"
                block
                max-height="35"
                router
                @click="addDialogForLang = true"
                >{{ $t("Add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        style="margin-left: 20px; margin-right: 40px; margin-top: 30px"
        :headers="header3"
        :items="languageList"
        :page.sync="pageLg"
        :items-per-page="itemsPerPageLg"
        hide-default-footer
        @page-count="pageCountLg = $event"
        hide-default-header
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th>{{ $t("language") }}</th>
              <th></th>
              <th>{{ $t("proficiency_lvl") }}</th>
              <th>{{ $t("test_type") }}</th>
              <th>{{ $t("score") }}</th>
              <th>{{ $t("date") }}</th>
              <th style="text-align: center">{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.language }}</td>
            <td></td>
            <td>{{ item.proficiency_Lvl }}</td>
            <td>{{ item.testType }}</td>
            <td>{{ item.score }}</td>
            <td>{{ formatDate(item.test_Date) }}</td>
            <td style="text-align: center">
              <v-btn @click="editTaskLg(item)" icon>
                <img class="editIcon" :src="images.edit" alt="editIcon"/>
              </v-btn>
              <v-btn @click="deleteLang(item.lang_ID)" icon class="ml-2">
                <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div v-if="languageList.length != 0" class="text-right">
        <PaginationComponent
            :page.sync="pageLg"
            :pageCount="pageCountLg"
            :itemsPerPage.sync="itemsPerPageLg"
        /> 
        <!-- <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination
              v-model="pageLg"
              :length="pageCountLg"
              color="#FFCD2C"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPageLg"
              :items="itemsLg"
              @input="itemsPerPageLg = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row> -->
      </div>
    </v-card>
    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

    <!-- add Education Background dialog -->
    <v-dialog
      v-model="addDialogForEB"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 25px;
            font-family: 'Kanit', sans-serif;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("NewEducationalBackground") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("institute") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="error.errorinstitute"
                  v-model="addEd.institute"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("degree") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="addEd.degree"
                  :error-messages="error.errordegree"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("GPA") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="error.errorgpa"
                  v-model="addEd.gpa"
                  onpaste="return false"
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("start_year") }} <span class="red--text"> *</span>
                <v-text-field
                  class="kanit-medium"
                  color="#F99D20"
                  v-mask="mask"
                  :error-messages="error.errorstart_Year"
                  v-model="addEd.start_Year"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("end_year") }} <span class="red--text"> *</span>
                <v-text-field
                  class="kanit-medium"
                  color="#F99D20"
                  v-mask="mask"
                  :error-messages="error.errorend_Year"
                  v-model="addEd.end_Year"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="text-capitalize mr-2"
              width="120"
              max-height="35"
              text
              @click="CloseDialog"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="text-capitalize mr-2"
              width="120"
              max-height="35"
              @click="
                addTask(institute, degree, gpa, start_Year, end_Year, $event)
              "
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edited Education Background dialog -->

    <v-dialog
      v-model="editDialogForEB"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 25px;
            font-family: 'Kanit', sans-serif;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("EditEducationBackground") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("institute") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editEd.institute"
                  :error-messages="error.errorinstitute"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("degree") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editEd.degree"
                  :error-messages="error.errordegree"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("GPA") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="error.errorgpa"
                  v-model="editEd.gpa"
                  dense
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("start_year") }} <span class="red--text"> *</span>
                <v-text-field
                  class="kanit-medium"
                  color="#F99D20"
                  v-mask="mask"
                  :error-messages="error.errorstart_Year"
                  v-model="editEd.start_Year"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("end_year") }} <span class="red--text"> *</span>
                <v-text-field
                  class="kanit-medium"
                  color="#F99D20"
                  v-mask="mask"
                  :error-messages="error.errorend_Year"
                  v-model="editEd.end_Year"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              text
              @click="closeEditDialog"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="updateTask($event)"
              >{{ $t("update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Short Traning dialog -->
    <v-dialog
      v-model="editDialogForSt"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 25px;
            font-family: 'Kanit', sans-serif;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("EditShortTraining") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("training_program") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editSt.training_Program"
                  :error-messages="errorST.training_Program"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                {{ $t("institute") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editSt.institute"
                  :error-messages="errorST.institute"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("duration") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editSt.duration"
                  :error-messages="errorST.duration"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                {{ $t("Year") }} <span class="red--text"> *</span>
                <v-text-field
                  dense
                  v-model="editSt.year"
                  :error-messages="errorST.year"
                  v-mask="mask"
                  class="kanit-medium"
                  color="#F99D20"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              text
              @click="closeSt"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="updateShortTraining"
              >{{ $t("update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Languages -->
    <v-dialog
      v-model="editDialogForLang"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 25px;
            font-family: 'Kanit', sans-serif;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("EditLanguages") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("language") }} <span class="red--text"> *</span>
                <v-text-field
                  v-model="editLang.language"
                  :error-messages="errorLang.errorlanguage"
                  color="#F99D20"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("proficiency_lvl") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="errorLang.errorproficiency_Lvl"
                  v-model="editLang.proficiency_Lvl"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("test_type") }}
                <v-text-field
                  color="#F99D20"
                  v-model="editLang.testType"
                  :error-messages="errorLang.errortestType"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("score") }}
                <v-text-field
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                  color="#F99D20"
                  :error-messages="errorLang.errorscore"
                  v-model="editLang.score"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("date") }}
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedDateFormatted3"
                      :error-messages="errorLang.errortest_Date"
                      class="kanit-medium"
                      color="#8A8C8E"
                      readonly
                      persistent-hint
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editLang.test_Date"
                    no-title
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              text
              @click="cancelLang"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="updateLanguage()"
            >
              {{ $t("update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete Education Dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("DeleteEducationBackground") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="Deletedialog = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="120"
              max-height="35"
              @click="DeltedEducationRow()"
            >
              {{ $t("Delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete Short Training -->
    <v-dialog v-model="DeletedialogSt" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("DeleteShortTraining") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="DeletedialogSt = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="120"
              max-height="35"
              @click="DeltedShortRow"
              >{{ $t("Delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete Languages -->
    <v-dialog v-model="DeletedialogLang" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("DeleteLanguages") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="DeletedialogLang = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="120"
              max-height="35"
              @click="DeltedLangRow()"
              >{{ $t("Delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add Short Traning dialog -->
    <v-dialog
      v-model="addDialogForST"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 25px;
            font-family: 'Kanit', sans-serif;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("NewShortTraining") }}
        </p>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("training_program") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="addSt.training_Program"
                  :error-messages="errorST.training_Program"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("institute") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="addSt.institute"
                  :error-messages="errorST.institute"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("duration") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="addSt.duration"
                  :error-messages="errorST.duration"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("Year") }} <span class="red--text"> *</span>
                <v-text-field
                  dense
                  v-model="addSt.year"
                  :error-messages="errorST.year"
                  v-mask="mask"
                  class="kanit-medium"
                  color="#F99D20"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              text
              @click="CloseDialogSt"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="addTaskSt"
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add Languages -->
    <v-dialog
      v-model="addDialogForLang"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 25px;
            font-family: 'Kanit', sans-serif;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("NewLanguages") }}
        </p>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("language") }} <span class="red--text"> *</span>
                <v-text-field
                  v-model="addLanguage.language"
                  color="#F99D20"
                  :error-messages="errorLang.errorlanguage"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("proficiency_lvl") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="addLanguage.proficiency_Lvl"
                  :error-messages="errorLang.errorproficiency_Lvl"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("test_type") }}
                <v-text-field
                  color="#F99D20"
                  v-model="addLanguage.testType"
                  :error-messages="errorLang.errortestType"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("score") }}
                <v-text-field
                  color="#F99D20"
                  type="number"
                  v-model="addLanguage.score"
                  :error-messages="errorLang.errorscore"
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("date") }}
                <v-menu
                  v-model="menu4"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      append-icon="mdi-calendar-month-outline"
                      v-model="computedDateFormatted4"
                      :error-messages="errorLang.errortest_Date"
                      class="kanit-medium"
                      color="#8A8C8E"
                      readonly
                      persistent-hint
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addLanguage.test_Date"
                    no-title
                    @input="menu4 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              text
              @click="CloseDialogLang"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="addLang()"
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- upload Education Background -->
    <v-dialog v-model="EbUploaddialog" presistent max-width="700">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  v-show="Ebshowup"
                  color="white"
                  class="myfile"
                  id="EbfileInputButton"
                  v-model="Ebchosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="Ebcloseinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="Ebgetupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("EducationBackgroundTemplate.xlsx") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  style="color: #f99d20"
                  href="https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Education Background.xlsx"
                  >{{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize mr-2"
              width="120"
              height="35"
              @click="EbcloseUpload"
              text
              >{{ $t("cancel") }}</v-btn
            >

            <v-btn
              color="#F99D20"
              class="mr-2 white--text text-capitalize"
              width="120"
              height="35"
              @click="previewFiles()"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- upload Short Training  -->
    <v-dialog v-model="StUploaddialog" presistent max-width="700">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  v-show="Stshowup"
                  color="white"
                  class="myfile"
                  id="StfileInputButton"
                  v-model="Stchosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="Stcloseinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="Stgetupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  {{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("ShortTrainingTemplate.xlsx") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  style="color: #f99d20"
                  href="https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Short Training.xlsx"
                  >{{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize mr-2"
              width="120"
              height="35"
              @click="StcloseUpload"
              text
              >{{ $t("cancel") }}</v-btn
            >

            <v-btn
              color="#F99D20"
              class="white--text text-capitalize mr-2"
              width="120"
              height="35"
              @click="previewFileSt"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- upload Languages -->
    <v-dialog v-model="LgUploaddialog" presistent max-width="700">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  v-show="Lgshowup"
                  color="white"
                  class="myfile"
                  id="LgfileInputButton"
                  v-model="Lgchosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="Lgcloseinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="Lggetupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("LanguagesTemplate.xlsx") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  style="color: #f99d20"
                  href="https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Languages.xlsx"
                  >{{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize mr-2"
              width="120"
              height="35"
              @click="LgcloseUpload"
              text
              >{{ $t("cancel") }}</v-btn
            >

            <v-btn
              color="#F99D20"
              class="white--text text-capitalize mr-2"
              width="120"
              height="35"
              @click="previewFilesLg"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- upload Short Training  -->
    <v-dialog v-model="StUploaddialog" presistent max-width="700">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  v-show="Stshowup"
                  color="white"
                  class="myfile"
                  id="StfileInputButton"
                  v-model="Stchosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="Stcloseinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="Stgetupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("ShortTrainingTemplate.xlsx") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  style="color: #f99d20"
                  href="https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Short Training.xlsx"
                  >{{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize mr-2"
              width="120"
              height="35"
              @click="StcloseUpload"
              text
              >{{ $t("cancel") }}</v-btn
            >

            <v-btn
              color="#F99D20"
              class="white--text text-capitalize mr-2"
              width="120"
              height="35"
              @click="previewFileSt"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import XLSX from "xlsx";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';


export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace],
  watch: {
    Ebchosenfile() {
      if (this.Ebchosenfile != null) {
        this.Ebshowup = true;
      } else {
        this.Ebshowup = false;
      }
    },
    Stchosenfile() {
      if (this.Stchosenfile != null) {
        this.Stshowup = true;
      } else {
        this.Stshowup = false;
      }
    },
    Lgchosenfile() {
      if (this.Lgchosenfile != null) {
        this.Lgshowup = true;
      } else {
        this.Lgshowup = false;
      }
    },
  },
  directives: {
    mask,
  },
  data(vm) {
    return {
      LoadingDialog: false,
      mask: "####",
      gpamask: "##############",
      addDialogForEB: false,
      addDialogForST: false,
      addDialogForLang: false,
      EbUploaddialog: false,
      Ebchosenfile: null,
      StUploaddialog: false,
      Stchosenfile: null,
      LgUploaddialog: false,
      Lgchosenfile: null,
      editDialogForEB: false,
      editDialogForSt: false,
      editDialogForLang: false,
      menu4: false,
      menu3: false,

      Ebshowup: false,
      Stshowup: false,
      Lgshowup: false,
      Deletedialog: false,
      DeletedialogSt: false,
      DeletedialogLang: false,

      id2: 0,
      ind: 0,
      EdBackground: [],
      educationl: [],
      EdimportData: [],
      StimportData: [],
      lgImport: [],

      st: {
        institute: "",
        training_Program: "",
        duration: "",
        year: "",
      },

      EdShortTraining: [],
      chosenfile: null,

      pageEd: 1,
      pageCountEd: 15,
      itemsPerPageEd: 10,

      pageSt: 1,
      pageCountSt: 15,
      itemsPerPageSt: 10,

      pageLg: 1,
      pageCountLg: 15,
      itemsPerPageLg: 10,

      institute: "",
      degree: "",
      gpa: "",
      start_Year: "",
      end_Year: "",

      EdLanguages: [],
      localEdubackground: [],
      localShiftCode: [],
      localLanguages: [],
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      header1: [
        {
          text: "Institute/University",
          value: "institute",
          sortable: false,
          align: "left",
        },
        {
          text: "Degree",
          value: "degree",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          align: "left",
          sortable: false,
        },
        {
          text: "GPA",
          value: "gpa",
          sortable: false,
          align: "left",
        },
        {
          text: "Start Year",

          sortable: false,
          align: "left",
        },
        {
          text: "End Year",

          align: "left",
          sortable: false,
        },

        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],
      header2: [
        {
          text: "Training Program",
          value: "st.training_Program",
          sortable: false,
          align: "left",
        },
        {
          text: "Institute",
          value: "st.institute",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          align: "left",
          sortable: false,
        },
        {
          text: "Duration",
          value: "st.duration",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          align: "left",
          sortable: false,
        },
        {
          text: "Year",
          value: "st.year",
          sortable: false,
          align: "left",
        },

        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],

      header3: [
        {
          text: "Language",

          sortable: false,
          align: "left",
        },
        {
          text: "",
          align: "left",
          sortable: false,
        },
        {
          text: "Proficiency Level",

          sortable: false,
          align: "left",
        },
        {
          text: "Test Type",

          sortable: false,
          align: "left",
        },
        {
          text: "score",

          sortable: false,
          align: "left",
        },
        {
          text: "Date",

          align: "left",
          sortable: false,
        },
        {
          text: "Action",

          align: "center",
          sortable: false,
        },
      ],
      deleteEduId: 0,
      deletelangId: 0,

      deleteshort: 0,

      url: enurl.apiUrl,
      workimgsend: "",
      EducationList: [],
      epID: "",

      error: {
        errorinstitute: "",
        errordegree: "",
        errorgpa: "",
        errorstart_Year: "",
        errorend_Year: "",
      },
      addEd: {
        institute: null,
        degree: null,
        gpa: null,
        start_Year: null,
        end_Year: null,
      },
      editEd: {
        institute: null,
        degree: null,
        gpa: null,
        start_Year: null,
        end_Year: null,
      },

      shortList: [],
      EMPList: [],
      errorST: {
        institute: null,
        training_Program: null,
        duration: null,
        year: null,
      },
      addSt: {
        institute: null,
        training_Program: null,
        duration: null,
        year: null,
      },

      editSt: {
        institute: null,
        training_Program: null,
        duration: null,
        year: null,
      },

      languageList: [],
      errorLang: {
        errorlanguage: null,
        errorproficiency_Lvl: null,
        errortestType: null,
        errorscore: null,
        errortest_Date: null,
      },
      addLanguage: {
        language: null,
        proficiency_Lvl: null,
        testType: null,
        score: null,
        test_Date: null,
      },
      editLang: {
        language: null,
        proficiency_Lvl: null,
        testType: null,
        score: null,
        test_Date: null,
        lang_ID: 0,
      },
      education_ID: 0,
      trainingID: null,
      lang_ID: 0,
    };
  },

  mounted() {
    this.getEd();
    this.getSt();
    this.getLang();
  },
  computed: {
    computedDateFormatted3() {
      return this.formatDate(this.editLang.test_Date);
    },
    computedDateFormatted4() {
      return this.formatDate(this.addLanguage.test_Date);
    },
    images() {
      return this.$store.state.images;
    },
    form() {
      return {
        institute: this.institute,
        degree: this.degree,
        gpa: this.gpa,
        Start: this.start_Year,
        end_Year: this.end_Year,
      };
    },
  },

  methods: {
    onlyalpha(e) {
      var regex = /^\d*\.?\d*$/;
      var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(key)) {
        e.preventDefault();
        return false;
      }
    },
    Ebcloseinput() {
      this.Ebshowup = false;
      this.Ebchosenfile = null;
    },
    Ebgetupload() {
      document.getElementById("EbfileInputButton").click();
      //this.Ebshowup = true;
    },
    StcloseUpload() {
      this.StUploaddialog = false;
      (this.Stchosenfile = null), (this.Stshowup = false);
    },
    Stcloseinput() {
      this.Stshowup = false;
      this.Stchosenfile = null;
    },
    Stgetupload() {
      document.getElementById("StfileInputButton").click();
      // this.Stshowup = true;
    },
    LgcloseUpload() {
      this.LgUploaddialog = false;
      (this.Stchosenfile = null), (this.Stshowup = false);
    },
    Lgcloseinput() {
      this.Lgshowup = false;
      this.Lgchosenfile = null;
    },
    Lggetupload() {
      document.getElementById("LgfileInputButton").click();
      // this.Lgshowup = true;
    },
    localeProbation() {
      return "";
    },

    CloseDialogLang() {
      this.addDialogForLang = false;
      this.addLanguage.language = null;
      this.addLanguage.proficiency_Lvl = null;
      this.addLanguage.test_Date = null;
      this.addLanguage.testType = null;
      this.addLanguage.score = null;

      this.resetFormLang();
    },
    EbcloseUpload() {
      this.EbUploaddialog = false;
      (this.Ebchosenfile = null), (this.Ebshowup = false);
    },
    resetFormSt() {
      this.errorST.training_Program = [];
      this.errorST.institute = [];
      this.errorST.year = [];
      this.errorST.duration = [];
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    CloseDialogSt() {
      this.addDialogForST = false;
      this.addSt.institute = null;
      this.addSt.training_Program = null;
      this.addSt.year = null;
      this.addSt.duration = null;

      this.resetFormSt();
    },

    resetForm() {
      this.error.errorinstitute = [];
      this.error.errordegree = [];
      this.error.errorgpa = [];
      this.error.errorstart_Year = [];
      this.error.errorend_Year = [];
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    CloseDialog() {
      this.addDialogForEB = false;
      this.addEd.institute = null;
      this.addEd.degree = null;
      this.addEd.gpa = null;
      this.addEd.start_Year = null;
      this.addEd.end_Year = null;
      this.resetForm();
    },
    clearShort() {
      this.addDialogForST = true;
    },

    formatDate(date) {
      if (date == "") {
        return "";
      } else if (date != null) {
        let finaldate = date.slice(0, 10).split("-");
        return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
      } else {
        return "-";
      }
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    closeEditDialog() {
      this.editDialogForEB = false;
    },
    resetFormLang() {
      this.errorLang.errorlanguage = [];
      this.errorLang.errortest_Date = [];
      this.errorLang.errortestType = [];
      this.errorLang.errorproficiency_Lvl = [];
      this.errorLang.errorscore = [];
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    cancelLang() {
      this.editDialogForLang = false;
      this.addEd.institute = null;
      this.addEd.degree = null;
      this.addEd.gpa = null;
      this.addEd.testType = null;
      this.addEd.testType = null;

      this.resetFormLang();
    },

    resetFormEd() {
      this.error.errorinstitute = [];
      this.error.errordegree = [];
      this.error.errorgpa = [];
      this.error.errorstart_Year = [];
      this.error.errorend_Year = [];
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    closeEd() {
      let self = this;
      this.editDialogForEB = false;
      self.institute = null;
      self.degree = null;
      self.gpa = null;
      self.start_Year = null;
      self.end_Year = null;
      this.resetFormEd();
    },
    closeSt() {
      let self = this;
      this.editDialogForSt = false;
      self.training_Program = null;
      self.duration = null;
      self.year = null;

      this.resetFormEd();
    },
    addDialogForlg() {
      this.addDialogForLang = true;
      if (this.addLanguage.test_Date == null) {
        this.addLanguage.test_Date = "";
      }
    },

    back() {
      let workim = this.$route.params.id;
      if (this.$route.name == "PersonalEducationBackground") {
        this.$router
          .push("/selfservice/PersonalProfile/PersonalInformation")
          .catch(() => {});
      } else if (workim != undefined && workim != "undefined") {
        this.$router
          .push(
            `/PersonnelManagement/EditEmployee/${this.$route.params.id}/EditPersonalInformation`
          )
          .catch(() => {});
      }
    },
    getEd() {
      let self = this;

      let ebIT = self.$route.params.eduid;
      let temp = {};
      // if (self.$route.name == "PersonalEducationBackground") {
      temp = {
        employee_Code: ebIT.toString(),
        company_ID: store.state.companyID,
      };
      // } else {
      //   temp = {
      //     employee_Code: store.state.employeeID,
      //     company_ID: store.state.companyID,
      //   };
      // }
      self.LoadingDialog = true;

      axios
        .post(
          `${self.url}EducationBackground/GetEducationBackgroundByEmployeIDAndCPID`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.EducationList = response.data.data;
            self.LoadingDialog = false;
            self.EducationList = self.educationl.concat(self.EducationList);
            self.LogTrace(null, "Get Educational Background", 14, "Critical");
          }
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Get Eductional Background Fail",
            14,
            "Critical"
          );
        });
    },

    addTask() {
      let self = this;

      self.error.errorinstitute = !self.addEd.institute
        ? "Please fill in the required fields"
        : "";
      self.error.errordegree = !self.addEd.degree
        ? "Please fill in the required fields"
        : "";
      self.error.errorgpa = !self.addEd.gpa
        ? "Please fill in the required fields"
        : "";
      self.error.errorstart_Year = !self.addEd.start_Year
        ? "Please fill in the required fields"
        : "";
      self.error.errorend_Year = !self.addEd.end_Year
        ? "Please fill in the required fields"
        : "";

      let tempvali = self.ValidateAddTrueEd();
      if (tempvali == true) {
        let subtxt =
          self.addEd.institute.charAt(0).toUpperCase() +
          self.addEd.institute.slice(1);
        let distxt =
          self.addEd.degree.charAt(0).toUpperCase() +
          self.addEd.degree.slice(1);

        tempvali = {
          institute: subtxt,
          degree: distxt,
          gpa: this.addEd.gpa,
          start_Year: parseInt(this.addEd.start_Year),
          end_Year: parseInt(this.addEd.end_Year),
          company_ID: store.state.companyID,
          employee_Code: self.$route.params.eduid,
        };
        // if (self.$route.name == "PersonalEducationBackground") {
        //tempvali.employee_Code = self.$route.params.eduid;
        //}

        // else if (self.$route.name == "editEducational") {
        //   tempvali.employee_Code = parseInt(self.$route.params.id);
        // }
        // else {
        //   tempvali.employee_Code = store.state.employeeID;
        // }
        self.LoadingDialog = true;
        axios
          .post(
            `${self.url}EducationBackground/AddEducationBackground`,
            tempvali
          )
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              alert(response.data.message);
              self.addDialogForEB = false;
              self.addEd.institute = null;
              self.addEd.degree = null;
              self.addEd.gpa = null;
              self.addEd.start_Year = null;
              self.addEd.end_Year = null;

              self.getEd();
              self.LogTrace(null, "Add Educatinal Background", 14, "Low");
            }
          })
          .catch(function (error) {
            alert(error);
            self.LogTrace(error, "Add Educatinal Background", 14, "Low");
          });
      } else {
        self.ValidateAddFalseEd();
      }
    },
    ValidateAddTrueEd() {
      let self = this;

      if (
        self.addEd.institute != null &&
        self.addEd.gpa != null &&
        self.addEd.degree != null &&
        self.addEd.start_Year != null &&
        self.addEd.end_Year != null &&
        self.addEd.institute != "" &&
        self.addEd.gpa != "" &&
        self.addEd.degree != "" &&
        self.addEd.start_Year != "" &&
        self.addEd.End_year != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    ValidateAddFalseEd() {
      let self = this;

      if (
        self.addEd.institute == null ||
        self.addEd.gpa == null ||
        self.addEd.degree == null ||
        self.addEd.start_Year == null ||
        self.addEd.end_Year == null
      ) {
        alert("Please fill in the required fields!");
      }
    },

    addTaskSt() {
      let self = this;

      self.errorST.duration = !self.addSt.duration
        ? "Please fill in the required fields"
        : "";
      self.errorST.institute = !self.addSt.institute
        ? "Please fill in the required fields"
        : "";
      self.errorST.year = !self.addSt.year
        ? "Please fill in the required fields"
        : "";
      self.errorST.training_Program = !self.addSt.training_Program
        ? "Please fill in the required fields"
        : "";

      let tempvalistSt = self.ValidateAddTrueSt();
      if (tempvalistSt == true) {
        let subtxt =
          self.addSt.training_Program.charAt(0).toUpperCase() +
          self.addSt.training_Program.slice(1);

        this.editDialogForSt = false;

        let tankst = {
          trainingID: self.trainingID,
          employee_Code: self.$route.params.eduid,
          company_ID: store.state.companyID,
          training_Program: subtxt,
          institute: self.addSt.institute,
          duration: self.addSt.duration,
          year: parseInt(self.addSt.year),
        };
        // if (self.$route.name == "PersonalEducationBackground") {
        tankst.employee_Code = self.$route.params.eduid;
        // } else {
        //   tankst.employee_Code = store.state.employeeID;
        // }
        self.LoadingDialog = true;
        axios
          .post(`${self.url}ShortTraining/AddShortTraining`, tankst)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              self.addDialogForST = false;
              self.getSt();
              alert(response.data.message);
              self.LogTrace(null, "Add Short Traning", 14, "Low");
              self.addDialogForST = false;
              self.addSt.training_Program = null;
              self.addSt.duration = null;
              self.addSt.institute = null;
              self.addSt.year = null;
              self.addSt.training_Program = "";
              self.addSt.duration = "";
              self.addSt.institute = "";
              self.addSt.year = "";
            }
          })
          .catch(function (error) {
            alert(error);
            self.LogTrace(error, "Add Short Traning Fail", 14, "Low");
          });
      } else {
        this.ValidateAddFalseSt();
      }
    },

    addLang() {
      let self = this;

      self.errorLang.errorlanguage = !self.addLanguage.language
        ? "Please fill in the required fields"
        : "";
      self.errorLang.errorproficiency_Lvl = !self.addLanguage.proficiency_Lvl
        ? "Please fill in the required fields"
        : "";
      // if(self.addLanguage.date=="-"){
      //   self.addLanguage.date="-"
      // }
      // self.errorLang.errorscore = !self.addLanguage.score
      //   ? "Please fill in the required fields"
      //   : "";
      // self.errorLang.errortestType = !self.addLanguage.testType
      //   ? "Please fill in the required fields"
      //   : "";
      // self.errorLang.errortest_Date = !self.addLanguage.test_Date
      //   ? "Please fill in the required fields"
      //   : "";

      let tempvaliLang = self.ValidateAddTrueLg();
      if (tempvaliLang == true) {
        let subtxt =
          self.addLanguage.language.charAt(0).toUpperCase() +
          self.addLanguage.language.slice(1);
        let distxt =
          self.addLanguage.proficiency_Lvl.charAt(0).toUpperCase() +
          self.addLanguage.proficiency_Lvl.slice(1);

        if (
          self.addLanguage.testType == "" ||
          self.addLanguage.testType == null
        ) {
          self.addLanguage.testType = "-";
        }
        //   if(self.addLanguage.score=="" || self.addLanguage.score==null){
        //         self.addLanguage.score="-";
        //  }

        let tankLgs = {
          lang_ID: self.lang_ID,
          employee_Code: self.$route.params.eduid,
          company_ID: store.state.companyID,
          language: subtxt,
          proficiency_Lvl: distxt,
          testType: self.addLanguage.testType,
          score: parseInt(self.addLanguage.score),
          test_Date: self.addLanguage.test_Date,
        };
        // if (self.$route.name == "PersonalEducationBackground") {
        tankLgs.employee_Code = self.$route.params.eduid;
        // } else {
        //   tankLgs.employee_Code = store.state.employeeID;
        // }

        self.LoadingDialog = true;
        axios
          .post(`${self.url}Languages/AddLanguages`, tankLgs)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              alert(response.data.message);
              self.LogTrace(null, "Add Language", 14, "Low");
              self.addLanguage.language = null;
              self.addLanguage.proficiency_Lvl = null;
              self.addLanguage.score = null;
              self.addLanguage.test_Date = null;
              self.addLanguage.testType = null;
              self.addLanguage.language = "";
              self.addLanguage.proficiency_Lvl = "";
              self.addLanguage.score = "";
              (self.addLanguage.test_Date = ""),
                (self.addLanguage.testType = "");
              self.addDialogForLang = false;
              self.getLang();
            }
          })
          .catch(function (error) {
            alert(error);
            self.LogTrace(error, "Add Language Fail", 14, "Low");
          });
      } else {
        this.ValidateAddFalseLang();
      }
    },

    ValidateAddTrueLg() {
      let self = this;

      if (
        self.addLanguage.language != null &&
        self.addLanguage.proficiency_Lvl != null &&
        // self.addLanguage.testType != null &&
        // self.addLanguage.score != null &&
        // self.addLanguage.test_Date != null &&
        self.addLanguage.language != "" &&
        self.addLanguage.proficiency_Lvl != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    ValidateAddFalseLang() {
      if (
        this.addLanguage.language == null ||
        this.addLanguage.proficiency_Lvl == null
        // this.addLanguage.testType == null ||
        // this.addLanguage.score == null ||
        // this.addLanguage.test_Date == null
      ) {
        alert("Please fill in the required fields!");
      }
    },

    ValidateAddTrueSt() {
      let self = this;

      if (
        self.addSt.training_Program != null &&
        self.addSt.institute != null &&
        self.addSt.year != null &&
        self.addSt.duration != null &&
        self.addSt.training_Program != "" &&
        self.addSt.institute != "" &&
        self.addSt.year != "" &&
        self.addSt.duration != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    ValidateAddFalseSt() {
      if (
        this.addSt.training_Program == null ||
        this.addSt.year == null ||
        this.addSt.institute == null ||
        this.addSt.duration == null
      ) {
        alert("Please fill in the required fields!");
      }
    },

    editTask(temp) {
      let self = this;
      self.editDialogForEB = true;
      self.education_ID = temp.education_ID;
      self.editEd.institute = temp.institute;
      self.editEd.degree = temp.degree;
      self.editEd.gpa = temp.gpa;
      self.editEd.start_Year = temp.start_Year;
      self.editEd.end_Year = temp.end_Year;
    },
    updateTask() {
      let self = this;

      self.error.errorinstitute = !self.editEd.institute
        ? "Please fill in the required fields"
        : "";
      self.error.errordegree = !self.editEd.degree
        ? "Please fill in the required fields"
        : "";
      self.error.errorgpa = !self.editEd.gpa
        ? "Please fill in the required fields"
        : "";
      self.error.errorstart_Year = !self.editEd.start_Year
        ? "Please fill in the required fields"
        : "";
      self.error.errorend_Year = !self.editEd.end_Year
        ? "Please fill in the required fields"
        : "";
      let tempvali = self.ValidateEditTrue();
      let ebIT = self.$route.params.eduid;
      if (ebIT != null) {
        if (tempvali == true) {
          let subtxt =
            self.editEd.institute.charAt(0).toUpperCase() +
            self.editEd.institute.slice(1);
          let distxt =
            self.editEd.degree.charAt(0).toUpperCase() +
            self.editEd.degree.slice(1);

          this.editDialogForEB = false;

          let temp = {
            education_ID: self.education_ID,
            employee_Code: self.$route.params.eduid,
            company_ID: store.state.companyID,
            institute: subtxt,
            degree: distxt,
            gpa: self.editEd.gpa,
            start_Year: parseInt(self.editEd.start_Year),
            end_Year: parseInt(self.editEd.end_Year),
          };
          //if (self.$route.name == "PersonalEducationBackground") {
          // temp.employee_Code = self.$route.params.eduid;
          // } else {
          //   temp.employee_Code = store.state.employeeID;
          // }
          self.LoadingDialog = true;
          axios
            .post(
              `${self.url}EducationBackground/UpdateEducationBackground`,
              temp
            )
            .then(function (response) {
              if (response.data.status == 0) {
                self.LoadingDialog = false;
                self.Editdialog = false;
                self.getEd();
                alert(response.data.message);
                self.LogTrace(
                  null,
                  "Update Educatinal Background",
                  14,
                  "Medium"
                );
              }
            })
            .catch(function (error) {
              alert(error);
              self.LogTrace(
                error,
                "Update Educatinal Background Fail",
                14,
                "Medium"
              );
            });
        } else {
          this.ValidateEditFalse();
        }
      }
    },

    ValidateEditTrue() {
      let self = this;

      if (
        self.editEd.institute != null &&
        self.editEd.gpa != null &&
        self.editEd.degree != null &&
        self.editEd.start_Year != null &&
        self.editEd.end_Year != null &&
        self.editEd.institute != "" &&
        self.editEd.gpa != "" &&
        self.editEd.degree != "" &&
        self.editEd.start_Year != "" &&
        self.editEd.end_Year != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateEditFalse() {
      if (
        this.editEd.institute == null ||
        this.editEd.gpa == null ||
        this.editEd.degree == null ||
        this.editEd.start_Year == null ||
        this.editEd.end_Year == null ||
        this.editEd.institute == "" ||
        this.editEd.gpa == "" ||
        this.editEd.degree == "" ||
        this.editEd.start_Year == "" ||
        this.editEd.end_Year == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },

    special(event) {
      let alp = /^[A-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]*$/i;
      return alp.test(event) || "Alphabet only";
    },

    getSt() {
      let self = this;
      let epID = self.$route.params.eduid;
      let temp = {};
      if (epID != null) {
        // if (self.$route.name == "PersonalEducationBackground") {
        temp = {
          employee_Code: epID,
          company_ID: store.state.companyID,
        };
        // } else {
        //   temp = {
        //     employee_Code: store.state.employeeID,
        //     company_ID: store.state.companyID,
        //   };
        // }

        axios
          .post(
            `${self.url}ShortTraining/GetShortTrainingByEmployeIDAndCPID`,
            temp
          )
          .then(function (response) {
            if (response.data.status == 0) {
              self.shortList = response.data.data;

              // self.trainingID = self.shortList[0].trainingID;
              // self.addSt.training_Program = self.shortList[0].training_Program;
              // self.addSt.institute = self.shortList[0].institute;
              // self.addSt.year = self.shortList[0].year;
              // self.addSt.duration = self.shortList[0].duration;
            }
          });
      }
    },
    editTaskSt(temp) {
      let self = this;
      self.editDialogForSt = true;
      self.editSt.trainingID = temp.trainingID;
      self.editSt.training_Program = temp.training_Program;
      self.editSt.institute = temp.institute;
      self.editSt.duration = temp.duration;
      self.editSt.year = temp.year;
    },

    updateShortTraining() {
      let self = this;

      self.errorST.duration = !self.editSt.duration
        ? "Please fill in the required fields"
        : "";
      self.errorST.institute = !self.editSt.institute
        ? "Please fill in the required fields"
        : "";
      self.errorST.year = !self.editSt.year
        ? "Please fill in the required fields"
        : "";
      self.errorST.training_Program = !self.editSt.training_Program
        ? "Please fill in the required fields"
        : "";

      let tempvalistSt = self.validateEditTrueSt();
      let ebIT = self.$route.params.eduid;
      if (ebIT != null) {
        if (tempvalistSt == true) {
          let subtxt =
            self.editSt.training_Program.charAt(0).toUpperCase() +
            self.editSt.training_Program.slice(1);

          self.editDialogForSt = false;

          let tankst = {
            trainingID: self.editSt.trainingID,
            employee_Code: ebIT,
            company_ID: store.state.companyID,
            training_Program: subtxt,
            institute: self.editSt.institute,
            duration: self.editSt.duration,
            year: parseInt(self.editSt.year),
          };
          // if (self.$route.name == "PersonalEducationBackground") {
          tankst.employee_Code = self.$route.params.eduid;
          // } else {
          //   tankst.employee_Code = store.state.employeeID;
          // }

          self.LoadingDialog = true;
          axios
            .post(`${self.url}ShortTraining/UpdateShortTraining`, tankst)
            .then(function (response) {
              if (response.data.status == 0) {
                self.LoadingDialog = false;
                self.editDialogForSt = false;
                self.getSt();
                alert(response.data.message);
                self.LogTrace(null, "Update Short Training", 14, "Medium");
              }
            })
            .catch(function (error) {
              alert(error);
              self.LogTrace(error, "Update Short Training", 14, "Medium");
            });
        } else {
          this.validateEditFalseSt();
        }
      }
    },

    validateEditTrueSt() {
      let self = this;

      if (
        self.editSt.training_Program != null &&
        self.editSt.institute != null &&
        self.editSt.year != null &&
        self.editSt.duration != null &&
        self.editSt.training_Program != "" &&
        self.editSt.institute != "" &&
        self.editSt.year != "" &&
        self.editSt.duration != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    validateEditFalseSt() {
      let self = this;

      if (
        self.editSt.training_Program == null ||
        self.editSt.institute == null ||
        self.editSt.year == null ||
        self.editSt.duration == null ||
        self.editSt.training_Program == "" ||
        self.editSt.institute == "" ||
        self.editSt.year == "" ||
        self.editSt.duration == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },

    getLang() {
      let self = this;
      let langCode = self.$route.params.eduid;
      let temp = {};
      if (langCode != null) {
        // if (self.$route.name == "PersonalEducationBackground") {
        temp = {
          employee_Code: langCode,
          company_ID: store.state.companyID,
        };
        // } else {
        //   temp = {
        //     employee_Code: store.state.employeeID,
        //     company_ID: store.state.companyID,
        //   };
        // }
        axios
          .post(`${self.url}Languages/GetLanguagesByEmployeIDAndCPID`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.languageList = response.data.data;

              // self.lang_ID = self.languageList[0].lang_ID;
              // self.addLanguage.language = self.languageList[0].language;
              // self.addLanguage.proficiency_Lvl =
              //   self.languageList[0].proficiency_Lvl;
              // self.addLanguage.testType = self.languageList[0].testType;
              // self.addLanguage.score = self.languageList[0].score;
              // self.addLanguage.test_Date = self.languageList[0].test_Date;
            }
            for (let i = 0; i < self.languageList.length; i++) {
              if (self.languageList[i].score == 0) {
                self.languageList[i].score = "-";
              }
              //   if( self.languageList[i].test_Date==null || self.languageList[i].test_Date==undefined){
              //    self.languageList[i].test_Date="-"
              //    console.log(self.languageList[i].test_Date)
              //  }
            }
          });
      }
    },

    editTaskLg(temp) {
      let self = this;
      self.lang_ID = temp.lang_ID;
      self.editDialogForLang = true;
      self.editLang.language = temp.language;
      self.editLang.testType = temp.testType;
      self.editLang.proficiency_Lvl = temp.proficiency_Lvl;
      if (temp.score == "-") {
        self.editLang.score = "0";
      } else {
        self.editLang.score = temp.score;
      }
      // self.editLang.score = temp.score;
      self.editLang.test_Date = temp.test_Date;
      if (self.editLang.test_Date.includes("T")) {
        self.editLang.test_Date = self.editLang.test_Date.split("T")[0];
      }
    },
    updateLanguage() {
      let self = this;
      self.errorLang.errorlanguage = !self.editLang.language
        ? "Please fill in the required fields"
        : "";
      self.errorLang.errorproficiency_Lvl = !self.editLang.proficiency_Lvl
        ? "Please fill in the required fields"
        : "";
      // self.errorLang.errorscore = !self.editLang.score
      //   ? "Please fill in the required fields"
      //   : "";
      // self.errorLang.errortestType = !self.editLang.testType
      //   ? "Please fill in the required fields"
      //   : "";
      // self.errorLang.errordate = !self.editLang.test_Date
      //   ? "Please fill in the required fields"
      //   : "";
      let tempvali = self.ValidateEditTrueLang();
      let ebIT = self.$route.params.eduid;
      if (ebIT != null) {
        if (tempvali == true) {
          let subtxt =
            self.editLang.language.charAt(0).toUpperCase() +
            self.editLang.language.slice(1);
          let distxt =
            self.editLang.proficiency_Lvl.charAt(0).toUpperCase() +
            self.editLang.proficiency_Lvl.slice(1);

          this.editDialogForLang = false;
          if (this.editLang.score == "" || this.editLang.score == "-") {
            this.editLang.score = 0;
          }
          let tankLg = {
            lang_ID: self.lang_ID,
            employee_Code: self.$route.params.eduid,
            company_ID: store.state.companyID,
            language: subtxt,
            proficiency_Lvl: distxt,
            testType: this.editLang.testType,
            score: parseInt(this.editLang.score),
            test_Date: this.editLang.test_Date,
          };
          // if (self.$route.name == "PersonalEducationBackground") {
          //   tankLg.employee_Code =parseInt(self.$route.params.eduid);
          // } else {
          //   tankLg.employee_Code = store.state.employeeID;
          // }
          self.LoadingDialog = true;
          axios
            .post(`${self.url}Languages/UpdateLanguages`, tankLg)
            .then(function (response) {
              if (response.data.status == 0) {
                self.LoadingDialog = false;
                self.editDialogForLang = false;
                self.getLang();
                alert(response.data.message);
                self.LogTrace(null, "Update Language", 14, "Medium");
              }
            })
            .catch(function (error) {
              alert(error);
              self.LogTrace(error, "Update Language", 14, "Medium");
            });
        } else {
          this.ValidateEditFalseLang();
        }
      }
    },

    ValidateEditTrueLang() {
      let self = this;

      if (
        self.editLang.language != null &&
        self.editLang.proficiency_Lvl != null &&
        // self.editLang.testType != null &&
        // self.editLang.score != null &&
        // self.editLang.test_Date != null &&
        self.editLang.language != "" &&
        self.editLang.proficiency_Lvl != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateEditFalseLang() {
      if (
        this.editLang.language == null ||
        this.editLang.proficiency_Lvl == null ||
        // this.editLang.testType == null ||
        // this.editLang.score == null ||
        // this.editLang.test_Date == null ||
        this.editLang.language == " " ||
        this.editLang.proficiency_Lvl == ""
        // this.editLang.testType == "" ||
        // this.editLang.score == "" ||
        // this.editLang.test_Date == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },
    OpenDelDig() {
      this.Deletedialog = true;
    },

    deleteTasks(id) {
      let self = this;

      self.deleteTask = id;
      self.Deletedialog = true;
    },
    DeltedEducationRow() {
      let self = this;
      let id = self.deleteTask;
      let tempdel = {
        education_ID: id,
      };
      self.LoadingDialog = true;
      axios
        .post(
          `${self.url}EducationBackground/DeleteEducationBackground`,
          tempdel
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
            alert(response.data.message);
            self.Deletedialog = false;
            self.LogTrace(null, "Delete Educational Background", 14, "High");
            self.getEd();
          }
        })
        .catch(function (error) {
          alert(error);
          self.LogTrace(
            error,
            "Delete Educational Background Fail",
            14,
            "High"
          );
        });
    },
    DeleteSt(id) {
      let self = this;

      self.deleteshort = id;
      self.DeletedialogSt = true;
    },

    DeltedShortRow() {
      let self = this;
      let id = self.deleteshort;
      let tempdel = {
        trainingID: id,
      };
      self.LoadingDialog = true;
      axios
        .post(`${self.url}ShortTraining/DeleteShortTraining`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
            alert(response.data.message);
            self.DeletedialogSt = false;
            self.LogTrace(null, "Delete Short Training", 14, "High");
            self.getSt();
          }
        })
        .catch(function (error) {
          alert(error);
          self.LogTrace(error, "Delete Short Training Fail", 14, "High");
        });
    },

    deleteLang(id) {
      let self = this;

      self.deletelangId = id;
      self.DeletedialogLang = true;
    },
    DeltedLangRow() {
      let self = this;
      let id = self.deletelangId;
      let tempdel = {
        lang_ID: id,
      };
      self.LoadingDialog = true;
      axios
        .post(`${self.url}Languages/DeleteLanguages`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
            alert(response.data.message);

            self.DeletedialogLang = false;
            self.LogTrace(null, "Delete Language", 14, "High");
            self.getLang();
          }
        })
        .catch(function (error) {
          alert(error);
          self.LogTrace(error, "Delete Language Fail", 14, "High");
        });
    },

    previewFiles() {
      let self = this;
      let files = self.Ebchosenfile;
      let reader = new FileReader();
      let filename = files.name;
      if (filename == "Education Background.xlsx") {
        if (self.Ebchosenfile != null && self.Ebchosenfile != "") {
          reader.onload = function (e) {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.EdimportData = XLSX.utils.sheet_to_json(worksheet);
            let combileArray = [];
            for (let i = 0; i < self.EdimportData.length; i++) {
              if (self.$route.name == "PersonalEducationBackground") {
                self.tempimport = {
                  ...self.EdimportData[i],
                  company_ID: store.state.companyID,
                  employee_Code: self.$route.params.eduid,
                };
              } else {
                var empID = self.$route.params.eduid.toString();
                self.tempimport = {
                  ...self.EdimportData[i],

                  company_ID: store.state.companyID,
                  employee_Code: empID,
                };
              }
              self.tempimport.gpa = self.tempimport.gpa.toString();
              combileArray.push(self.tempimport);
              //console.log(combileArray);
            }
            // if (filename == "Education Background.xlsx") {
            self.LoadingDialog = true;
            axios

              .post(
                `${self.url}EducationBackground/ImportEducationBackground`,
                combileArray
              )
              .then(function (response) {
                if (response.data.status == 0) {
                  self.LoadingDialog = false;
                  alert(response.data.message);
                  self.Ebchosenfile = null;
                  self.Ebshowup = false;
                  self.EbUploaddialog = false;
                  self.getEd();
                  self.LogTrace(
                    null,
                    "Upload Educational Background",
                    14,
                    "Verbose"
                  );
                  self.LoadingDialog = false;
                }
              })
              .catch(function (error) {
                alert(error);
                self.LogTrace(
                  error,
                  "Upload Educational Background Fail",
                  14,
                  "Verbose"
                );
                self.LoadingDialog = false;
              });
            // } else {
            //   alert("File name must be Education Background.xlsx");
            // }
          };
          reader.readAsArrayBuffer(files);
        } else {
          alert("Please Input File!");
          self.LoadingDialog = false;
        }
      } else {
        alert("File name must be Education Background.xlsx");
      }
    },
    previewFileSt() {
      let self = this;
      let files = self.Stchosenfile;
      let reader = new FileReader();
      if (self.Stchosenfile != null && self.Stchosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          self.StimportData = XLSX.utils.sheet_to_json(worksheet);
          let combileArray = [];
          for (let i = 0; i < self.StimportData.length; i++) {
            if (self.$route.name == "PersonalEducationBackground") {
              self.tempimport = {
                ...self.StimportData[i],
                company_ID: store.state.companyID,
                employee_Code: self.$route.params.eduid,
              };
            } else {
              self.tempimport = {
                ...self.StimportData[i],
                company_ID: store.state.companyID,
                employee_Code: self.$route.params.eduid,
              };
            }

            combileArray.push(self.tempimport);
            //console.log(combileArray);
          }
          if (filename == "Short Training.xlsx") {
            self.LoadingDialog = true;
            axios
              .post(
                `${self.url}ShortTraining/ImportShortTraining`,
                combileArray
              )

              .then(function (response) {
                if (response.data.status == 0) {
                  self.LoadingDialog = false;
                  alert(response.data.message);
                  self.Stchosenfile = null;
                  self.Stshowup = false;
                  self.StUploaddialog = false;
                  self.getSt();
                  self.LogTrace(null, "Upload Short Training", 14, "Verbose");
                }
              })
              .catch(function (error) {
                alert(error);
                self.LogTrace(
                  error,
                  "Upload Short Training Fail",
                  14,
                  "Verbose"
                );
              });
          } else {
            alert("File name must be Short Training.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },

    previewFilesLg() {
      let self = this;
      let files = self.Lgchosenfile;
      let reader = new FileReader();
      if (self.Lgchosenfile != null && self.Lgchosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          self.lgImport = XLSX.utils.sheet_to_json(worksheet);
          let combileArray = [];
          for (let i = 0; i < self.lgImport.length; i++) {
            if (self.$route.name == "PersonalEducationBackground") {
              self.tempimport = {
                ...self.lgImport[i],
                company_ID: store.state.companyID,
                employee_Code: self.$route.params.eduid,
              };
            } else {
              self.tempimport = {
                ...self.lgImport[i],
                company_ID: store.state.companyID,
                employee_Code: self.$route.params.eduid,
              };
            }
            if (self.tempimport.test_Date) {
              let newdate = self.tempimport.test_Date
                .split("/")
                .reverse()
                .join("-");
              self.tempimport.test_Date = newdate;
            }
            combileArray.push(self.tempimport);
          }
          if (filename == "Languages.xlsx") {
            self.LoadingDialog = true;
            axios
              .post(`${self.url}Languages/ImportLanguages`, combileArray)
              .then(function (response) {
                if (response.data.status == 0) {
                  self.LoadingDialog = false;
                  alert(response.data.message);
                  self.Lgchosenfile = null;
                  self.Lgshowup = false;
                  self.LgUploaddialog = false;
                  self.getLang();
                  self.LogTrace(null, "Upload Languages", 14, "Verbose");
                }
              })
              .catch(function (error) {
                alert(error);
                self.LogTrace(error, "Upload Languages", 14, "Verbose");
              });
          } else {
            alert("File name must be Languages.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
  },
};
</script>
<style scoped>
.inputscore input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.a {
  text-decoration: none;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 16px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
</style>
<style>
.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
</style>
