import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import enurl from "@/api/environment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: sessionStorage.getItem("token") || "",
    userid: parseInt(sessionStorage.getItem("userid")) || "",
    usertype: sessionStorage.getItem("usertype") || "",
    username: sessionStorage.getItem("username") || "",
    firstName: sessionStorage.getItem("firstName") || "",
    lastName: sessionStorage.getItem("lastName") || "",
    companyID: parseInt(sessionStorage.getItem("companyID")) || "",
    employeeID: parseInt(sessionStorage.getItem("employeeID")) || "",
    employeeCode: sessionStorage.getItem("employeeCode") || "",
    country: sessionStorage.getItem("country") || "",
    roleID: sessionStorage.getItem("roleID") || "",
    profileimage: sessionStorage.getItem("profileimage") || "",
    personalimg: sessionStorage.getItem("personalimg") || "",
    permissionID: sessionStorage.getItem("permissionID") || "",
    reportID: sessionStorage.getItem("reportID") || "",
    positionID: sessionStorage.getItem("positionID") || "",
    cstatus: sessionStorage.getItem("cstatus") || "",
    expiredate: sessionStorage.getItem("expiredate") || "",
    companycode: sessionStorage.getItem("companycode") || "",
    noti: sessionStorage.getItem("noti"),
    logoImage: sessionStorage.getItem("logoImage") || "",
    color_Code: sessionStorage.getItem("color_Code") || "",
    flag: sessionStorage.getItem("flag") || "",
    firstUserID: sessionStorage.getItem("firstUserID") || "",
    time_attendance: sessionStorage.getItem("time_attendance") || "",
    position_Name: sessionStorage.getItem("position_Name") || "",
    user: {},
    selected: [],
    usergrouppermit: [],
    usergroupmember: [],
    usergrouprole: [],
    uploadedFiles: [],
    show: true,
    IncomeMonth: "",
    images: {
      edit: require("@/assets/images/editIcon.svg"),
      delete: require("@/assets/images/deleteIcon.svg"),
      infopic: require("@/assets/images/information.png"),
      plus: require("@/assets/images/—Pngtree—button plus icon_3566857.png"),
      arrow: require("@/assets/images/up-arrow.png"),
      minus: require("@/assets/images/Mask Group 71.png"),
      send: require("@/assets/images/send.png"),
      plusIcon: require("@/assets/images/plus.svg"),
      downloadIcon: require("@/assets/images/download-bold-svgrepo-com 1.svg"),
      mailsendIcon: require("@/assets/images/mail-send-svgrepo-com (2) 1.svg"),
      payrollIcon1: require("@/assets/images/Monthlywage.svg"),
      payrollIcon2: require("@/assets/images/payroll-icon1.svg"),
      payrollIcon3: require("@/assets/images/payroll-icon2.svg"),
      payrollIcon4: require("@/assets/images/payroll-icon3.svg"),
      funIcon: require("@/assets/images/funIcon.svg"),
      healthIcon: require("@/assets/images/healthIcon.svg"),
      benefitIcon: require("@/assets/images/benefitIcon.svg"),
      birthday: require("@/assets/images/birthday.svg"),
      anniversary: require("@/assets/images/anniversary.svg"),
      celebration: require("@/assets/images/celebration.svg"),
      commonstatistics: require("@/assets/images/CommonStatistics.svg"),
      announcement: require("@/assets/images/announcement.svg"),
      pinIcon: require("@/assets/images/pinIcon.svg"),
      addAnnouncement: require("@/assets/images/addAnnouncement.png"),
      eye: require("@/assets/images/eye.svg"),
      mobileView : require("@/assets/images/preview-svgrepo-com 1.svg"),
      deleteEmp: require("@/assets/images/delete_emp.svg"),
      plusEmp: require("@/assets/images/Plus_emp.svg"),
      camera: require("@/assets/images/camera-plus 1.svg"),
      attachment: require("@/assets/images/attachment-clip.svg"),
      arrowright: require("@/assets/images/arrow-right.svg"),
      arrowleft: require("@/assets/images/arrow-right.svg"),
      visibility: require("@/assets/images/visibility.svg"),
      visibility_off: require("@/assets/images/visibility_off.svg"),
      export: require("@/assets/images/export.svg"),
      empstatus: require("@/assets/images/common_statEMP.svg"),
      depstatus: require("@/assets/images/common_DEP.svg"),
      posstatus: require("@/assets/images/common_statPOS.svg"),
      cloudupload: require("@/assets/images/choosefile.svg")
      // Add more image mappings as needed
    }
  },
  mutations: {
    SET_FORGOT_PWD(state, cred) {
      state.show = cred;
    },
    auth_request(state) {
      state.status = "loading";
    },
    ADD_FILE(state, file) {
      state.uploadedFiles.push(file); 
    },
    UPDATE_FILE(state, { index, fileData }) {
      state.uploadedFiles.splice(index, 1, fileData);
    },
    DELETE_FILE(state, index) {
      state.uploadedFiles.splice(index, 1);
    },
    CLEAR_LOCAL_DATA(state) {
      state.uploadedFiles = []; // Clear the array
    },
    auth_success(state, users) {
      state.status = "success";
      state.token = users.token;
      state.userid = users.user_ID;
      state.usertype = users.userType;
      state.username = users.username;
      state.firstName = users.firstName;
      state.lastName = users.lastName;
      state.companyID = users.company_ID;
      state.employeeID = users.employee_ID;
      state.employeeCode = users.employee_Code;
      state.country = users.country;
      state.roleID = users.role_Name;
      state.permissionID = users.permission_ID;
      state.profileimage = users.image;
      state.logoImage = users.logoImage;
      state.color_Code = users.color_Code;
      state.reportID = users.report_ID;
      state.positionID = users.positionID;
      state.expiredate = users.expire_Date;
      state.companycode = users.company_Code;
      state.cstatus = users.status;
      state.flag = users.flag;
      state.firstUserID = users.firstUserID;
      state.time_attendance = users.time_attendance;
      state.position_Name = users.position_Name;
      // eslint-disable-next-line no-console
      state.user = users;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.userid = "";
      state.usertype = "";
      state.companyID = "";
      state.employeeID = "";
      state.employeeCode = "";
      state.country = "";
      state.roleID = "";
      state.permissionID = "";
      state.profileimage = "";
      state.reportID = "";
      state.positionID = "";
      state.companycode = "";
      state.cstatus = "";
      state.expiredate = "";
      state.logoImage = "";
      state.color_Code = "";
      state.firstUserID = "";
      state.flag = "";
      state.firstName = "";
      state.lastName = "";
      state.time_attendance = "";
      state.position_Name = "";
    },
    setState: (state, data) => {
      for (const [key, value] of Object.entries(data)) {
        state[key] = value;
      }
    },
  },
  actions: {
    show({ commit }, cred) {
      commit("SET_FORGOT_PWD", cred);
    },
    addFile({ commit }, file) {
      commit('ADD_FILE', file);  // Action to commit the file addition
    },
    updateFile({ commit }, { index, fileData }) {
      commit('UPDATE_FILE', { index, fileData });
    },
    deleteFile({ commit }, index) {
      commit('DELETE_FILE', index);
    },
    clearLocalData({ commit }) {
      commit('CLEAR_LOCAL_DATA'); // Calls the mutation to clear the array
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: `${enurl.apiUrl}Login/Login`,
          data: user,
          method: "POST",
        })
          .then(resp => {
            if (resp.data.status != 0) {
              commit("auth_error");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("userid");
              sessionStorage.removeItem("usertype");
              sessionStorage.removeItem("username");
              sessionStorage.removeItem("firstName");
              sessionStorage.removeItem("lastName");
              sessionStorage.removeItem("companyID");
              sessionStorage.removeItem("employeeID");
              sessionStorage.removeItem("employeeCode");
              sessionStorage.removeItem("country");
              sessionStorage.removeItem("roleID");
              sessionStorage.removeItem("profileimage");
              sessionStorage.removeItem("logoImage");
              sessionStorage.removeItem("color_Code");
              sessionStorage.removeItem("permissionID");
              sessionStorage.removeItem("reportID");
              sessionStorage.removeItem("positionID");
              sessionStorage.removeItem("companycode");
              sessionStorage.removeItem("expiredate");
              sessionStorage.removeItem("cstatus");
              sessionStorage.removeItem("personalimg");
              sessionStorage.removeItem("flag");
              sessionStorage.removeItem("firstUserID");
              sessionStorage.removeItem("time_attendance");
              reject(resp.data.message);
            } else {
              if (resp.data.data.employeeStatus != 2) {
                const token = resp.data.data.token;
                const userid = resp.data.data.user_ID;
                const userType = resp.data.data.userType;
                const users = resp.data.data;
                const userName = resp.data.data.username;
                const firstName = resp.data.data.firstName;
                const lastName = resp.data.data.lastName;
                const companyID = resp.data.data.company_ID;
                const employeeID = resp.data.data.employee_ID;
                const reportID = resp.data.data.report_ID;
                const employeeCode = resp.data.data.employee_Code;
                const roleID = resp.data.data.role_Name;
                const permissionID = resp.data.data.permission_ID;
                const profileimage = resp.data.data.image;
                const positionID = resp.data.data.positionID;
                const companycode = resp.data.data.company_Code;
                const expiredate = resp.data.data.expire_Date;
                const cstatus = resp.data.data.status;
                const flag = resp.data.data.flag;
                const logoImage = resp.data.data.logoImage;
                const color_Code = resp.data.data.color_Code;
                const country = resp.data.data.country;
                const firstUserID = resp.data.data.firstUserID;
                const time_attendance = resp.data.data.time_Attendance;
                const position_Name = resp.data.data.position_Name;
                sessionStorage.setItem("position_Name", position_Name);
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("userid", userid);
                sessionStorage.setItem("usertype", userType);
                sessionStorage.setItem("username", userName);
                sessionStorage.setItem("firstName", firstName);
                sessionStorage.setItem("lastName", lastName);
                sessionStorage.setItem("companyID", companyID);
                sessionStorage.setItem("employeeID", employeeID);
                sessionStorage.setItem("country", country);
                sessionStorage.setItem("firstUserID", firstUserID);
                sessionStorage.setItem("time_attendance", time_attendance);
                sessionStorage.setItem(
                  "personalimg",
                  "https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/Group 3166.png"
                );
                sessionStorage.setItem("employeeCode", employeeCode);
                sessionStorage.setItem("roleID", roleID);
                sessionStorage.setItem("profileimage", profileimage);
                sessionStorage.setItem("logoImage", logoImage);
                sessionStorage.setItem("color_Code", color_Code);
                sessionStorage.setItem("permissionID", permissionID);
                sessionStorage.setItem("reportID", reportID);
                sessionStorage.setItem("positionID", positionID);
                sessionStorage.setItem("companycode", companycode);
                sessionStorage.setItem("expiredate", expiredate);
                sessionStorage.setItem("cstatus", cstatus);
                sessionStorage.setItem("flag", flag);
                // Add the following line:

                commit("auth_success", users);
              }
              resolve(resp);
            }
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },

    logout({ commit }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        commit("logout");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("userid");
        sessionStorage.removeItem("usertype");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("firstName");
        sessionStorage.removeItem("lastName");
        sessionStorage.removeItem("companyID");
        sessionStorage.removeItem("employeeID");
        sessionStorage.removeItem("country");
        sessionStorage.removeItem("employeeCode");
        sessionStorage.removeItem("roleID");
        sessionStorage.removeItem("permissionID");
        sessionStorage.removeItem("profileimage");
        sessionStorage.removeItem("reportID");
        sessionStorage.removeItem("positionID");
        sessionStorage.removeItem("companycode");
        sessionStorage.removeItem("expiredate");
        sessionStorage.removeItem("cstatus");
        sessionStorage.removeItem("personalimg");
        sessionStorage.removeItem("flag");
        localStorage.removeItem("Templang");
        sessionStorage.removeItem("logoImage");
        sessionStorage.removeItem("color_Code");
        sessionStorage.removeItem("firstUserID");
        sessionStorage.removeItem("firstName");
        sessionStorage.removeItem("lastName");
        sessionStorage.removeItem("time_attendance");
        sessionStorage.removeItem("position_Name");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    usertype: state => state.usertype,
    userid: state => state.userid,
    companyID: state => state.companyID,
    employeeID: state => state.employeeID,
    country: state => state.country,
    employeeCode: state => state.employeeCode,
    roleID: state => state.roleID,
    permissionID: state => state.permissionID,
    selected: state => state.selected,
    usergrouppermit: state => state.usergrouppermit,
    usergroupmember: state => state.usergroupmember,
    usergrouprole: state => state.usergrouprole,
    profileimage: state => state.profileimage,
    expiredate: state => state.expiredate,
    companycode: state => state.companycode,
    cstatus: state => state.cstatus,
    firstUserID: state => state.firstUserID,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    flag: state => state.flag,
    time_attendance: state => state.time_attendance,
    position_Name: state => state.position_Name,
    getUploadedFiles: (state) => state.uploadedFiles,
  },
});
