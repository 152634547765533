<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <p class="txt-header">
        {{ $t("othersetting") }}
      </p>
    </div>
      <v-card class="d-flex flex-wrap flex-column pa-6 commonmb">
        <v-card-title class="py-2"> <v-img alt="email" src="@/assets/images/sendemail.svg" class="commonicon mt-2 mr-3"></v-img>{{ $t("Emailnotificationsetting") }}</v-card-title>
        <!-- ChangePassword Field -->
        <v-card-text class="px-9">
          <v-col class="py-0" style="left: 4%;">
            <!-- Send Email to Approver, when have New OT request -->
            <v-col class="d-flex flex-wrap align-center py-1 custom commonunderline">
              <v-label>
                {{ $t("Send Email to Approver, when have New OT request") }}
              </v-label>
              <v-spacer></v-spacer>
              <v-switch
                v-model="status.toggle1"
                color="#F89D1F"
                inset
                :disabled="permission.edit"
                @change="handleInteraction"
              ></v-switch>
            </v-col>
            <!-- Send Email to Approver, when have New Time Off request  -->
            <v-col class="d-flex flex-wrap align-center py-1 commonunderline">
              <v-label>
                {{
                  $t("Send Email to Approver, when have New Time Off request")
                }}
              </v-label>
              <v-spacer></v-spacer>
              <v-switch
                v-model="status.toggle2"
                color="#F89D1F"
                inset
                :disabled="permission.edit"
                @change="handleInteraction"
              ></v-switch>
            </v-col>
            <!-- Send Email to Employee, when OT request has Approved/Denied -->
            <v-col class="d-flex flex-wrap align-center py-1 commonunderline">
              <v-label>
                {{
                  $t(
                    "Send Email to Employee, when OT request has Approved/Denied"
                  )
                }}
              </v-label>
              <v-spacer></v-spacer>
              <v-switch
                v-model="status.toggle3"
                color="#F89D1F"
                inset
                :disabled="permission.edit"
                @change="handleInteraction"

              ></v-switch>
            </v-col>
            <!-- Send Email to Employee, when Time Off request has Approved/Denied -->
            <v-col class="d-flex flex-wrap align-center py-1 commonunderline">
              <v-label>
                {{
                  $t(
                    "Send Email to Employee, when Time Off request has Approved/Denied"
                  )
                }}
              </v-label>
              <v-spacer></v-spacer>
              <v-switch
                v-model="status.toggle4"
                color="#F89D1F"
                inset
                :disabled="permission.edit"
                @change="handleInteraction"
              ></v-switch>
            </v-col>
          </v-col>
        </v-card-text>

        <v-card-title class="py-2 mt-5 mb-1"> <v-img alt="passcode" src="@/assets/images/passcode.svg" class="commonicon mr-3"></v-img>{{ $t("Accesspauslip") }}</v-card-title>
        <v-card-subtitle style="position: relative; left: 4%;padding-top: 1rem;">{{ $t("Openingpayslip") }}</v-card-subtitle>

        <v-card-text style="position: relative; left: 4%;" class="pb-0"> {{ $t("setpassword") }}</v-card-text>
        <v-col
          style="position: relative; left: 4%;"
          class="d-flex"
          cols="12" xl="4" lg="4" md="5" sm="5"
        >
        <v-select
          color="#F89D1F"
          :items="passcodeitems"
          :item-text="(item) => item.text"
          item-value="value"
          :label="selectedLabel"
          dense
          outlined
          solo
          v-model="selectedValue"
          @change="handleSelectChange"
        />
        </v-col>

        <v-footer v-if="showCard" style="background-color:#FFFFFF; border-top:1px solid #D9D5D5;" elevation="0" height="80px" fixed >
          <v-row style="flex-direction: row-reverse">
            <v-btn
              class="pa-2 mr-7 text-capitalize commonsavebtn"
              @click="saveData()"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              outlined
              class="pa-2 mr-7 text-capitalize commoncancelbtn"
              @click="cancelGone"
              text
              >{{ $t("cancel") }}</v-btn
            >
          </v-row>
        </v-footer>
        <!-- Loading Dialog -->
        <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
      </v-card>
  </div>
</template>
  <script>
//   import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import CheckViewEdit from "@/function/RolePermissions.js";
import LogTrace from "@/function/Log.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [CheckViewEdit, LogTrace],
  name: "ChangePassword",
  data() {
    return {
      permission: {
        edit: true,
      },
      url: enurl.apiUrl,
      SettingList: [],
      LoadingDialog: false,
      status: {
        toggle1: false,
        toggle2: false,
        toggle3: false,
        toggle4: false,
      },
      defaultStatus: {
        toggle1: false,
        toggle2: false,
        toggle3: false,
        toggle4: false,
      },
      set: [
        "Send Email to Approver, when have New OT request",
        "Send Email to Approver, when have New Time Off request",
        "Send Email to Employee, when OT request has Approved/Denied",
        "Send Email to Employee, when Time Off request has Approved/Denied",
      ],
      showCard:false,
      selectedValue: null,
      initialValue:null,
      selectedSettings: []
    };
  },
  computed: {
    passcodeitems() {
      return [
      { text: this.$t('DOB'), value: 1 },
        { text: this.$t('Digitnumber'), value: 2 },
        { text: this.$t('Lastdigit'), value: 3 }
      ];
    },
    selectedLabel() {
      return this.initialValue
        ? this.passcodeitems.find(item => item.value === this.initialValue).text
        : this.$t('Select');
    }
  },
  mounted() {
    this.getOtherSetting();
    this.permission = this.CheckViewEdit(195, 196);
  },
  methods: {
    cancelGone() {
      this.showCard = false;
    },
    handleInteraction(){
      this.showCard = true;
    },
    handleSelectChange() {
      if (this.selectedValue !== this.initialValue) {
        this.showCard = true;
      } else {
        this.showCard = false;
      }
    },

    async saveData() {
      try{
        let self = this;
        self.LoadingDialog = true;
        let switchesArray = [];

        for (let i = 0; i < self.set.length; i++) {
          const toggleKey = `toggle${i + 1}`;
          switchesArray.push({
            other_Setting_Name: self.set[i],
            o_ID: self.SettingList.statusSettings[i].o_ID,
            status: self.status[toggleKey] !== undefined ? self.status[toggleKey] : self.defaultStatus[toggleKey]
          });
        }
      let temp = {
          statusSettings: switchesArray,
          defaultpasscode: self.selectedValue,
          company_ID: parseInt(self.$store.state.companyID),
        };
        await axios.post(`${self.url}Other_Setting/SaveOtherSettings`, temp)
        .then(function (response) {
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.loadingdialog = false;
            self.showCard = false;
            self.getOtherSetting();
          }
          else
          {
            alert(response.data.message);
          }
        })
      }catch (ex) {
        throw ex;
      }
    
  },
    async getOtherSetting() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let temp = {
          user_ID: parseInt(self.$store.state.userid),
          company_ID: parseInt(self.$store.state.companyID),
        };
        await axios
          .post(`${self.url}Other_Setting/GetOtherSetting`, temp)
          .then(function (response) {
            let res = response.data.data;
            self.initialValue = res.defaultpasscode
            self.SettingList = res;
            if (res != 0 || res.length != 0) {
              self.status.toggle1 = res.statusSettings[0].status;
              self.status.toggle2 = res.statusSettings[1].status;
              self.status.toggle3 = res.statusSettings[2].status;
              self.status.toggle4 = res.statusSettings[3].status;
              self.LoadingDialog = false;
            } else {
              self.addOtherSetting();
              self.LoadingDialog = false;
            }
          });
      } catch (ex) {
        throw ex;
      }
    },
    async addOtherSetting() {
      try {
        let self = this;
        let temp = {
          user_ID: parseInt(self.$store.state.userid),
          company_ID: parseInt(self.$store.state.companyID),
        };
        await axios
          .post(`${self.url}Other_Setting/AddOtherSetting`, temp)
          .then(function () {
            self.LoadingDialog = false;
          });
      } catch (ex) {
        throw ex;
      }
    },
    ChangeStatus(x, input) {
      let self = this;
      let temp = {
        user_ID: parseInt(self.$store.state.userid),
        company_ID: parseInt(self.$store.state.companyID),
        status: input,
        other_Setting_Name: self.set[x],
      };
      axios.post(`${self.url}Other_Setting/ChangeStatus`, temp);
    },
  },
};
</script>
  
  <style scoped>
::v-deep .v-input--switch__thumb.theme--light {
  background-color: white !important;
}
::v-deep .v-input--switch--inset .v-input--switch__track{
  opacity: 1!important;
}
::v-deep .v-list-item .primary--text .v-list-item--active {
    color: #F99D20 !important;
    caret-color: #F99D20 !important;
}
.v-btn-toggle {
  width: 200px;
  height: 40px;
}
label {
  font-size: 15px;
}
.sc {
  padding: 8px;
  margin: 8px;
  font-size: 14px;
}
@media (max-width: 414px) {
  .fix-btn {
    width: 100px !important;
  }
}
</style>