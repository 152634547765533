<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <p
        style="
          font-size: 25px;
          font-family: 'Kanit';
          color: #43425d;
          margin-top: 15px;
        "
      >
        {{ $t("CompanySetUp") }}
      </p>
      <v-tabs
        class="mt-6"
        grey--text
        background-color="#f0f8fc"
        slider-color="#F99D20"
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          class="text-capitalize"
          v-show="tab.showthis"
          style="
            border-radius: 10px 10px 0px 5px;
            width: 180px;
            margin-right: 3px;
          "
          v-for="tab in tabs"
          :key="tab.id"
          :to="tab.route"
          exact
          >{{
            tab.name == "timeAttendance" ? $t("TimeAttendance") : $t("timeOff")
          }}</v-tab
        >
      </v-tabs>
      <v-card>
        <router-view></router-view>
      </v-card>
    </div>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  props: ["id"],
  mixins: [utils],
  data() {
    return {
      activeTab: `/companysetup/${this.id}`,
      tabs: [
        {
          id: 1,
          name: "timeAttendance",
          route: `/companysetup/${this.id}`,
          showthis: false,
        },
        {
          id: 2,
          name: "timeOff",
          route: `/companysetup/${this.id}/timeoff`,
          showthis: false,
        },
      ],
    };
  },
  // computed: {
  //   tabs() {
  //     return [
  //       {
  //         id: 1,
  //         name: this.$t("TimeAttendance"),
  //         route: `/companysetup/${this.id}`,
  //         showthis: false,
  //       },
  //       {
  //
  //         id: 2,
  //this.$t("timeOff")
  //         name: this.$t("timeOff"),
  //         route: `/companysetup/${this.id}/timeoff`,
  //         showthis: false,
  //       },
  //     ];
  //   },
  // },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      for (let j = 0;j < this.tabs.length; j++) {
        this.tabs[j].showthis = true;
      }
    }
    if (perid.some((e) => e == 135)) {
      this.tabs[0].showthis = true;
    }
    if (perid.some((e) => e == 137)) {
      this.tabs[1].showthis = true;
    }
    if (this.$route.path == "/companysetup/timeattendance") {
      for (let j = 0;j < this.tabs.length; j++) {
        if (this.tabs[j].showthis == true) {
          this.activeTab = this.tabs[j].route;
          this.$router.push(this.tabs[j].route).catch(()=>{});
          break;
        }
      }
    }
  },
};
</script>
<style scoped>
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
}
.active {
  cursor: pointer !important;
}
.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}
>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
</style>