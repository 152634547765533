<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" class="mt-6 ml-1" @click="back()">
        <img src="@/assets/images/back button.png" class="ml-2 mb-1" alt="backIcon"/>
      </v-btn>
      <p class="ml-2 headtext txt-header">
        {{ $t("EditAnnouncement") }}
      </p>
    </div>

    <v-card class="mt-5" style="margin-bottom: 5rem; margin-left: 1.5rem; margin-right: 1.5rem">
      <v-container>
        <v-form ref="formEdit" lazy-validation>
          <v-row class="pt-8" style="margin-left: 15px; margin-right: 10px">
            <v-spacer></v-spacer>
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize mr-5 add_btn"
              width="150"
              max-height="35"
              @click="UpdateAnnouncement()"
              >{{ $t("Update") }}</v-btn
            >
          </v-row>
          <div class="ml-3" style="margin-right: 15px">
            <div class="d-flex ml-1">
              <div style="margin-left: 0.5rem">
                <p style="margin-top: 10px" class="myfont">
                  {{ $t("title") }}<span class="red--text"> *</span>
                </p>
              </div>
            </div>
            <v-col class="mydiv" cols="12" xs="12" sm="12" md="12" lg="12">
              <v-text-field
                autocomplete="off"
                v-model="updateannouncement.title"
                class="ml-1 ml-md-0"
                single-line
                :rules="titleEdit"
                solo
              ></v-text-field>
            </v-col>
            <div class="d-flex ml-1">
              <div style="margin-left: 0.5rem">
                <p class="myfont">
                  {{ $t("details") }}<span class="red--text"> *</span>
                </p>
              </div>
            </div>
            <v-col class="mydiv" cols="12" xs="12" sm="12" md="12" lg="12">
              <v-textarea
                solo
                height="80"
                v-model="updateannouncement.detail"
                :rules="detailEdit"
                name="input-7-4"
                class="ml-1"
                hide-details
                no-resize
              ></v-textarea>
            </v-col>
            <v-row>
              <div class="d-flex"></div>
              <v-col class="mydiv" cols="12" xs="12" sm="6" md="6" lg="6">
                <div class="ml-1">
                  <div style="margin-left: 0.5rem" class="ma-2">
                    <p style="margin-top: 10px" class="myfont">
                      {{ $t("startDate") }}<span class="red--text"> *</span>
                    </p>
                  </div>
                  <date-picker v-bind:clearable="false" format="DD/MM/YYYY" :editable="false"
                    :style="{ width: '100%' }" readonly v-model="updateannouncement.startdate" value-type="format"
                    :disabled-date="allowedDates" class="custom-vuedatepicker1 px-3"></date-picker>
                  <!-- <v-menu
                    v-model="startmenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        single-line
                        class="ml-3"
                        readonly
                        solo
                        :rules="startdateEdit"
                        v-model="computedDateFormatted"
                        append-icon
                        color="#F99D20"
                        v-on="on"
                        hide-details
                      >
                        <template v-slot:append>
                          <img src="@/assets/images/Mask Group 160.png" alt />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="updateannouncement.startdate"
                      :allowed-dates="allowedDates"
                      no-title
                      @input="startmenu = false"
                    ></v-date-picker>
                  </v-menu> -->
                </div>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                <div class="ma-3">
                  <div style="margin-left: 0.5rem" class="ma-2">
                    <p style="margin-top: 10px" class="myfont">
                      {{ $t("expiryDate") }}<span class="red--text"> *</span>
                    </p>
                  </div>
                  <date-picker v-bind:clearable="false" format="DD/MM/YYYY" value-type="format" :editable="false" :disabled-date="allowedEndDates"
                    :style="{ width: '100%' }" v-model="updateannouncement.enddate" class="custom-vuedatepicker1">
                  </date-picker>
                  <!-- <v-menu
                    v-model="endmenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        single-line
                        class="ml-3"
                        readonly
                        solo
                        :rules="enddateEdit"
                        v-model="computedDateFormatted1"
                        append-icon
                        color="#F99D20"
                        v-on="on"
                        hide-details
                      >
                        <template v-slot:append>
                          <img src="@/assets/images/Mask Group 160.png" alt />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="updateannouncement.enddate"
                      :allowed-dates="allowedEndDates"
                      no-title
                      @input="endmenu = false"
                    ></v-date-picker>
                  </v-menu> -->
                </div>
              </v-col>
            </v-row>

            <div class="d-flex ml-3">
              <div style="margin-left: 0.5rem" class="ma-2">
                <p style="margin-top: 10px">
                  {{ $t("attachment") }}<span class="red--text"> *</span>
                </p>
              </div>
            </div>
            <div>
              <div style="margin-left: 1.4rem">
                <v-btn
                  color="#F89D1F"
                  v-show="showbutton"
                  width="120px"
                  rounded
                  style="float: center"
                  @click="onPickFile"
                >
                  <p
                    style="
                      letter-spacing: 0;
                      color: #3c4043;
                      font-size: 16px;
                      margin-bottom: 0px;
                    "
                    class="text-capitalize"
                  >
                    {{ $t("upload") }}
                  </p>
                  <input
                    ref="image"
                    @change="onFilePicked"
                    accept="image/*"
                    type="file"
                    style="display: none"
                    id="profile_picture"
                  />
                </v-btn>
              </div>
            </div>
            <v-hover v-if="showbutton == false" v-slot:default="{ hover }">
              <div class="imageContainer">
                <v-img
                  v-if="showbutton == false"
                  v-show="!showimage"
                  :src="imageUrl"
                  class="image"
                >
                  <v-expand-transition>
                    <div v-if="hover" style="height: 100%; float: right">
                      <v-btn
                        @click="clearImge"
                        class="ma-2"
                        text
                        icon
                        small
                        color="red lighten-2"
                      >
                        <v-img src="@/assets/images/Mask Group 71.png" />
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>
              </div>
            </v-hover>
            <v-hover v-if="imageUrl1" v-slot:default="{ hover }">
              <div class="imageContainer">
                <v-img
                  v-if="imageUrl1"
                  v-show="showimage"
                  :src="imageUrl1"
                  class="image"
                >
                  <v-expand-transition>
                    <div v-if="hover" style="height: 100%; float: right">
                      <v-btn
                        @click="clearImgefirst"
                        class="ma-2"
                        text
                        icon
                        small
                        color="red lighten-2"
                      >
                        <v-img src="@/assets/images/Mask Group 71.png" />
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>
              </div>
            </v-hover>

            <p class="ml-3 errorPtag" v-if="error.imageUrl">
              Please upload attachment
            </p>
            <div class="text mt-4" style="color: red">
              <label>** {{ $t("Filetypeannouncement") }}</label>
              <br />
              <label>** {{ $t("Imagetype") }}</label>
              <br />
              <label>** {{ $t("Filesize") }}</label>
            </div>
            <div>
              <div style="margin-left: 0.5rem">
                <p style="margin-top: 5px">
                  {{ $t("Audience") }}<span class="red--text"> *</span>
                </p>
                <v-radio-group
                  row
                  v-model="selectedOption"
                  @change="changeradio"
                >
                  <v-row>
                    <v-col cols="12" md="2" lg="2" class="pl-4 pr-0 pt-0">
                      <v-radio
                        color="black"
                        :label="$t('sendToAll')"
                        :value="0"
                      ></v-radio>
                    </v-col>
                    <v-col cols="12" md="2" lg="3" class="pl-0 pt-0">
                      <v-radio
                        color="black"
                        :label="$t('sendToSpecificEmployees')"
                        :value="1"
                      >
                      </v-radio>

                      <v-text-field
                        autocomplete="off"
                        style="width: 600px"
                        :label="$t('InputUserID')"
                        v-model="updateannouncement.userid"
                        v-show="showtext"
                        class="ml-1 ml-md-0"
                        single-line
                        solo
                        hide-details
                      ></v-text-field>
                      <span
                v-if="sendspecificcondi"
                style="font-size: 13px"
                class="red--text ml-2 mt-6"
                >{{ $t("Nospecificdata") }}</span>
                 <span
                v-if="nodatacondi"
                style="font-size: 13px"
                class="red--text ml-2 mt-6"
                >{{ $t("NoDatafound") }}</span>
                    </v-col>
                  </v-row>
                </v-radio-group>
                <label>
                  {{ $t("Comments") }}<span class="red--text"> *</span>
                  <input
                    type="checkbox"
                    class="ml-3"
                    v-model="updateannouncement.disablecomment"
                  />
                </label>
              </div>
            </div>
          </div>
        </v-form>
      </v-container>
    </v-card>
    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>

<script>
import store from "@/store";
import LogTrace from "@/function/Log.js";
import axios from "axios";
import enurl from "@/api/environment";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace],
  data() {
    return {
      error: {
        imageUrl: "",
      },
      LoadingDialog: false,
      selectedOption: 0,
      filetypeerror: "",
      filesizeerror: "",
      url: enurl.apiUrl,
      titleEdit: [],
      detailEdit: [],
      startdateEdit: [],
      enddateEdit: [],
      imageName: "",
      disabletoall: false,
      disablecheck: false,
      imageFile: "",
      imageUrl: "",
      showimage: true,
      imageUrl1: "",
      showbutton: true,
      startdate: "",
      enddate: "",
      startmenu: false,
      endmenu: false,
      sendtoall: false,
      sendspecific: false,
      showtext: false,
      editannouncementlist: [],
      updateannouncement: {
        title: null,
        detail: null,
        startdate: "",
        enddate: "",
        uploadimage: "",
        userid: "",
        disablecomment: false,
      },
      userlist: [],
      requesteddate: "",
      UserCheck: true,
      specificuserlist: [],
      sendspecificcondi:false,
      nodatacondi:false,
    };
  },
  watch: {
    imageUrl(v) {
      if (v) this.error.imageUrl = "";
    },

    "updateannouncement.title"() {
      this.titleEdit = [];
    },

    "updateannouncement.detail"() {
      this.detailEdit = [];
    },

    "updateannouncement.startdate"(newStartDate) {
      this.startdateEdit = [];
      if (new Date(newStartDate) > new Date(this.updateannouncement.enddate)) {
        this.updateannouncement.enddate = null;
      }
    },

    "updateannouncement.enddate"() {
      this.enddateEdit = [];
    },

    selectedOption(v){
      if (v == 1)
      {
        self.disabletoall = true;
        self.disablecheck = false;
        self.sendtoall = false;
        self.sendspecific = true;
        self.showtext = true;
        // alert(self.showtext);
      }
      else
      {
        self.disablecheck = false;
        self.disabletoall = false;
        self.showtext = false;
        self.sendtoall = true;
        self.sendspecific = false;
      }
    },

    nodatacondi(v)
    {
      if(v)
      {
        this.sendspecificcondi=false;
      }
    },

    sendspecificcondi(v)
    {
      if(v)
      {
          this.nodatacondi=false;
      }
    },
  },

  mounted() {
    this.geteditannouncement();
    this.getuserlist();
  },

  methods: {
    formatDate(dateStr) {
      let date = new Date(dateStr);
      let day = ('0' + date.getDate()).slice(-2);
      let month = ('0' + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },

    allowedDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0);
      return selectedDate < today;
    },

    allowedEndDates(date) {
      if (!this.updateannouncement.startdate) return false;
      const startDate = this.updateannouncement.startdate;
      const endDate = this.$moment(date).format('DD/MM/YYYY');
      return endDate < startDate;
    },

    back() {
      this.$router.push({ name: "home" }).catch(() => {});
    },

    errorMessage() {
      const { error } = this;
      error.imageUrl = !this.imageUrl ? "Please fill in the required fields" : "";
    },

    getuserlist() {
      let self = this;
      let temp = {
        companyId: store.state.companyID,
      };
      axios.post(`${self.url}UserInfo/GetUserbyCompanyId`, temp)
      .then(function (response) {
        self.specificuserlist = response.data.data;
      });
    },

    geteditannouncement() {
      let self = this;
      self.LoadingDialog = true;
      self.userlist = [];
      let temp = {
        announcement_ID: parseInt(self.$route.params.announcementid),
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Announcement/GetAnnouncementDetail`, temp)
      .then(function (response) {
          self.editannouncementlist = response.data.data;
          self.LoadingDialog = false;
          self.updateannouncement.title = self.editannouncementlist[0].announcement_Title;
          self.updateannouncement.detail = self.editannouncementlist[0].announcement_Detail;
          self.updateannouncement.startdate = self.$moment(self.editannouncementlist[0].announcement_Start_Date).format('DD/MM/YYYY');
          self.updateannouncement.enddate = self.$moment(self.editannouncementlist[0].announcement_Expiry_Date).format('DD/MM/YYYY');
          self.imageUrl1 = self.editannouncementlist[0].announcement_Image;
          self.imageUrl = self.editannouncementlist[0].announcement_Image;
          self.showbutton = false;
          self.sendtoall = self.editannouncementlist[0].announcement_Send_All;
          if (!self.sendtoall)
          {
            self.sendspecific = true;
            self.selectedOption = 1;
            self.showtext = true;
            for (let j = 0;j < self.editannouncementlist[0].specific_Person.length;j++)
            {
              self.userlist.push(self.editannouncementlist[0].specific_Person[j].announcement_Specific_Person);
              if (self.userlist.length < 2)
              {
                self.updateannouncement.userid = self.userlist[j];
              }
              else
              {
                self.updateannouncement.userid = self.userlist
                  .map((x) => x)
                  .join(",");
              }
            }
          }
          else
          {
            self.selectedOption = 0;
            self.showtext = false;
          }
          if (self.editannouncementlist[0].disable_Comments)
          {
            self.updateannouncement.disablecomment = false;
          }
          else
          {
            self.updateannouncement.disablecomment = true;
          }
        });
    },

    checktoall() {
      let self = this;
      if (self.sendtoall)
      {
        self.disabletoall = false;
        self.disablecheck = true;
        self.sendspecific = false;
        // self.updateannouncement.userid = "";
        self.showtext = false;
      }
      else
      {
        self.disablecheck = false;
      }
    },

    changeradio() {
      let self = this;
      if (self.selectedOption === 1)
      {
        self.disabletoall = true;
        self.disablecheck = false;
        self.sendtoall = false;
        self.sendspecific = true;
        self.showtext = true;
      }
      else
      {
        self.disablecheck = false;
        self.disabletoall = false;
        self.showtext = false;
        self.sendtoall = true;
        self.sendspecific = false;
      }
    },

    onPickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      const files = e.target.files;
      let self = this;
      self.filesizeerror = "";
      if (files[0] !== undefined)
      {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0)
        {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        let file = files[0];
        let filesize = Math.round(file.size / 1024).toString();
        let filetype = file.type.split("/")[1];
        if (filesize < 2048)
        {
          if (filetype == "png" || filetype == "jpeg" || filetype == "jpg")
          {
            self.filetypeerror = "";
            fr.onload = (e) => {
              var image = new Image();
              image.src = e.target.result;
              image.onload = function () {
                if (this.width > 256 || this.height > 256)
                {
                  alert("Uploaded photo must be 256x256 only");
                  self.filesizeerror = "File size error";
                  if (self.filesizeerror == "")
                  {
                    self.showbutton = false;
                  }
                  else
                  {
                    self.imageUrl = "";
                    self.showbutton = true;
                    document.getElementById("profile_picture").value = "";
                  }
                }
                else
                {
                  if (self.filesizeerror == "")
                  {
                    self.showbutton = false;
                  }
                }
              };
            };
            fr.addEventListener("load", () => {
              this.imageUrl = fr.result;
              // if (this.imageUrl) {
              //   this.showbutton = false;
              // }
              this.imageFile = files[0]; // this is an image file that can be sent to server...
              this.showimage = false;
            });
          }
          else
          {
            alert("Files must be png,jpg and jpeg only");
            self.filetypeerror = "filetypeerror";
          }
        }
        else
        {
          alert("Uploaded photo is greater than 2MB");
          this.showimage = false;
        }
      }
      else
      {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },

    clearImge() {
      this.imageName = null;
      this.imageFile = null;
      this.imageUrl = null;
      this.showbutton = true;
      document.getElementById("profile_picture").value = "";
    },

    clearImgefirst() {
      this.imageName = null;
      this.imageFile = null;
      this.imageUrl1 = null;
      this.showbutton = true;
    },

    checkdate(firstdate, seconddate) {
      let myfirstdate = this.$moment(firstdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');
      let myseconddate = this.$moment(seconddate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');
      //console.log("fisrtdate", myfirstdate);
      let myfirstcurrentyear = myfirstdate.split("-")[0];
      let myfirstcurrentmonth = parseInt(myfirstdate.split("-")[1]);
      let myfirstcurrentdate = myfirstdate.split("-")[2];
      let myrealcurrentdate = myfirstcurrentdate.split("T")[0];
      let mysecondcurrentyear = myseconddate.split("-")[0];
      let mysecondcurrentmonth = parseInt(myseconddate.split("-")[1]);
      let mysecondcurrentdate = myseconddate.split("-")[2];
      if (mysecondcurrentyear < myfirstcurrentyear)
      {
        return false;
      }
      else if (mysecondcurrentyear > myfirstcurrentyear)
      {
        return true;
      }
      else
      {
        if (mysecondcurrentmonth < myfirstcurrentmonth)
        {
          return false;
        }
        else if (mysecondcurrentmonth == myfirstcurrentmonth)
        {
          if (mysecondcurrentdate < myrealcurrentdate)
          {
            return false;
          }
          else
          {
            return true;
          }
        }
        else
        {
          return true;
        }
      }
    },

    UpdateAnnouncement() {
      let self = this;
      self.LoadingDialog = true;
      self.sendspecificcondi = false;
      self.nodatacondi=false;
      self.errorMessage();
      let realimage = "";
      if (self.imageUrl == "")
      {
        //self.imageUrl = self.imageUrl1;
        realimage = self.imageUrl;
      }
      else
      {
        if (self.imageUrl.includes(","))
        {
          realimage = self.imageUrl.split(",")[1];
        }
      }
      if(!self.sendtoall)
      {
        if(self.updateannouncement.userid!=null)
        {
            self.sendspecificcondi=true
        }
      }
      self.titleEdit = [(v) => !!v || "Title is required"];
      self.detailEdit = [(v) => !!v || "Details required"];
      self.startdateEdit = [(v) => !!v || "Start Date is required"];
      self.enddateEdit = [(v) => !!v || "End Date is required"];
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.updateannouncement.title != null &&
        self.updateannouncement.detail != null &&
        self.updateannouncement.startdate != null &&
        self.updateannouncement.enddate != null &&
        self.updateannouncement.title != "" &&
        self.updateannouncement.detail != "" &&
        self.updateannouncement.startdate != "" &&
        self.updateannouncement.enddate != "" &&
        self.imageUrl != ""
      )
      {
        if (self.filetypeerror == "")
        {
          if (self.filesizeerror == "")
          {
            let combilearrary = [];
            if (!self.sendtoall)
            {
              if (self.updateannouncement.userid != null)
              {
                let isCompanyExist = [];
                if (self.updateannouncement.userid.includes(","))
                {
                  let getuser = self.updateannouncement.userid.split(",");
                  getuser = [...new Set(getuser)];
                  getuser.forEach((element) => {
                    let sameUser = self.specificuserlist.filter(
                      (x) => x.userName == element
                    );
                    if (sameUser.length > 0) {
                      isCompanyExist.push(true);
                    } else {
                      isCompanyExist.push(false);
                    }
                  });
                  this.UserCheck = isCompanyExist.every((x) => x);

                  for (let i = 0; i < getuser.length; i++) {
                    self.tempimport = {
                      announcementPerson: getuser[i],
                    };
                    combilearrary.push(self.tempimport);
                  }
                }
                else
                {
                  self.tempimport = {
                    announcementPerson: self.updateannouncement.userid,
                  };
                  combilearrary.push(self.tempimport);
                  let sameUser = self.specificuserlist.filter(
                    (x) => x.userName == self.updateannouncement.userid
                  );
                  if (sameUser.length > 0)
                  {
                    this.UserCheck = true;
                  }
                  else
                  {
                    this.UserCheck = false;
                    this.nodatacondi=true;
                  }
                }
              } 
            }
            if (self.sendtoall)
            {
              self.tempimport = { announcementPerson: "" };
              combilearrary.push(self.tempimport);
              self.UserCheck = true;
            }
            let showComment = true;
            if (self.updateannouncement.disablecomment)
            {
              showComment = false;
            }
            else
            {
              showComment = true;
            }

            let temp = {
              announcement_ID: parseInt(self.$route.params.announcementid),
              announcement_Title: self.updateannouncement.title,
              announcement_Detail: self.updateannouncement.detail,
              announcement_Start_Date: self.$moment(self.updateannouncement.startdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              announcement_Expiry_Date: self.$moment(self.updateannouncement.enddate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              announcement_Image: realimage,
              announcement_Send_All: self.sendtoall,
              specific_Person: combilearrary,
              disable_Comments: showComment,
              employeeID: store.state.employeeID,
              company_ID: store.state.companyID,
            };

            if (!self.sendspecificcondi && !self.nodatacondi)
            {
              if (self.UserCheck)
              {
                axios.post(`${self.url}Announcement/UpdateAnnouncement`, temp)
                .then(function (response) {
                  if (response.data.status == 0)
                  {
                    alert(response.data.message);
                    self.combilearrary = [];
                    self.geteditannouncement();
                    self.LoadingDialog = false;
                    self.$router.push({ name: "home" }).catch(() => {});
                  }
                })
                .catch(function (error) {
                  self.LogTrace(error, "Update Announcement", 39, "Medium");
                  self.LoadingDialog = false;
                });
              }
              else
              {
                alert("Please check the User Name again");
                self.nodatacondi=true;
                self.LoadingDialog = false;
              }
            } 
              self.LoadingDialog = false;
          }
          else
          {
            alert("Uploaded photo must be 256x256 only");
            this.filesizeerror = "";
          }
        }
        else
        {
          alert("Files must be png,jpg and jpeg only");
          self.filetypeerror = "";
        }
      }
      else
      {
        if (
          self.updateannouncement.title == null ||
          self.updateannouncement.title == "" ||
          self.updateannouncement.detail == null ||
          self.updateannouncement.detail == "" ||
          self.updateannouncement.startdate == null ||
          self.updateannouncement.startdate == "" ||
          self.updateannouncement.enddate == null ||
          self.updateannouncement.enddate == ""
        )
        {
          alert("Please fill all the fields");
          self.LoadingDialog = false;
        }
        else
        {
          if (self.imageUrl == "")
          {
            alert("Please Select Attachment");
            self.LoadingDialog = false;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
}
.mycard {
  height: 100%;
}
.mydiv {
  padding-bottom: 0px;
}
.myfont {
  color: #8a8c8e;
}
image {
  max-width: 400px;
  max-height: 300px;
}
.imageContainer {
  width: 200px;
  height: auto;
  background-size: contain;
  margin-left: 20px;
}
.errorPtag {
  color: #ff5252 !important ;
  font-size: 13px;
}
@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(
        circle 12px,
        white 100%,
        transparent calc(100% + 1px)
      )
      #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: white;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
  }
}
</style>
  
    