<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div v-if="!showreq">
      <p class="txt-header">
        {{ $t("MyOTRequest") }}
      </p>
      <div style="height: auto" class="mt-6">
        <v-card>
          <div style="margin-left: 2%; margin-right: 2%">
            <br />
            <div>
              <v-row>
                <v-col class="mt-1" cols="12" xs="12" sm="12" md="5" lg="3" xl="2">
              <v-row>
                  <p style="margin-left: 10px; margin-top:0px">{{ $t("Year") }}</p>
                  <p class="lessleft">{{ $t("Month") }}</p>
              </v-row>
              <div class="mr-8 d-flex" style="background-color: #f99d20;border-radius: 5px;margin-left: 0px;max-width: 250px;">
                <date-picker  v-model="todayyears" type="year" color="#0FA7D8" class="
                    selectboxs
                    minizebox
                    ml-1
                    mr-1
                    mt-1
                    mb-1
                    pl-0
                    today_years" v-on:change="ChangeYear(todayyears)" :clearable="false">
                  <template slot="icon-calendar">
                    <img src="@/assets/images/down1@2x.png" style="width: 30px;height: 15px;margin-top: 4px;margin-right: -6px;" class="pl-2" />
                  </template>
                </date-picker>
              <v-select class="selectboxs minizebox monthbox ml-0 mt-1 mr-1 mb-1 pl-1" v-model="todaymonths"
                height="34px" single-line :items="monthNames" :item-text="(item) => item.txt"
                :item-value="(item) => item.val" background-color="#FFFFFF" color="#f99d20" return-object persistent-hint
                v-on:change="ChangeMonth(`${todaymonths}`)"></v-select>
              </div>
              </v-col>
                <v-col
                  class="mb-0 pb-0"
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="2"
                  xl="2"
                >
                  <div>
                    <v-autocomplete
                      v-model="itemInner"
                      :items="status"
                      item-text="txt"
                      item-value="val"
                      outlined
                      dense
                      color="#F99D20"
                      @change="changeType"
                      class="mt-5"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="3" md="2" lg="2" xl="2">
                  <v-btn
                    color="#F99D20"
                    @click="NewOT()"
                    class="white--text mr-5 mt-5 text-capitalize btnres"
                    block
                    max-height="35"
                    :disabled="permission.add">{{ $t("newRequest") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="mt-5">
              <v-data-table
                :items="requestedlist"
                hide-default-footer
                hide-default-header
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
                :mobile-breakpoint="0">
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th>{{ $t("RequestDate") }}</th>
                      <th class="text-center">{{ $t("startdate") }}</th>
                      <th class="text-center">{{ $t("enddate") }}</th>
                      <th class="text-center">{{ $t("oTHours") }}</th>
                      <th class="text-center">{{ $t("status") }}</th>
                      <th class="text-center">{{ $t("Supervisor") }}</th>
                      <th class="text-center">{{ $t("Actions") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.status != "Draft Request" ? convertUTCDateToLocalDate(item.requested_Date, 1) : "-" }}
                    </td>
                    <td class="text-center">
                      {{ formatDate(item.start_Date) }}
                    </td>
                    <td class="text-center">{{ formatDate(item.end_Date) }}</td>
                    <td class="text-center">{{ item.oT_Hours }}</td>
                    <td
                      class="d-flex pt-4 justify-center"
                      :style="{
                        color:
                          item.status == 'Approved'
                            ? '#00C092'
                            : item.status == 'Waiting Approval'
                            ? '#5B9BD5'
                            : item.status == 'Draft Request'
                            ? '#595959'
                            : item.status == 'Canceled'
                            ? '#FF0000'
                            : '#000000'}">
                      <div
                        class="d-flex justify-center"
                        :style="{
                          backgroundColor:
                            item.status == 'Approved'
                              ? '#CCF3E9'
                              : item.status == 'Waiting Approval'
                              ? '#DAE3F3'
                              : item.status == 'Draft Request'
                              ? '#D0CECE'
                              : item.status == 'Canceled'
                              ? '#FFCCCC'
                              : '#7F7F7F',
                          textAlign: 'center',
                          borderRadius: '20px',
                          padding: '5px',
                          width: '150px',
                          height: '35px',
                          justifyContent: 'center',
                          alignItems: 'center'}">
                        {{
                          item.status == "Approved"
                            ? $t("approved")
                            : item.status == "Denied"
                            ? $t("denied")
                            : item.status == "Draft Request"
                            ? $t("Draftreq")
                            : item.status == "Canceled"
                            ? $t("Canceled")
                            : $t("waitingApproval")
                        }}
                      </div>
                    </td>
                    <td class="text-center">{{ item.approvername }}</td>
                    <td class="text-center">
                      <v-btn
                        @click="Detailrequest(item)"
                        class="hover-effect"
                        icon
                        color="#F99D20">
                        <img width="40" height="40" :src="pwdimage.showpwd" alt=""/>
                        <span class="tooltip">{{ $t("Viewhover") }}</span>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="text-right" v-if="requestedlist.length">
              <PaginationComponent
                :page="page"
                :pageCount="pageCount"
                :itemsPerPage="itemsPerPage"
                @update:page="onPageChanged"
                @update:itemsPerPage="onItemsPerPageChanged"
              />
            </div>
          </div>

          <!-- requestdialog -->
          <v-dialog v-model="OTapprovedialog" persistent max-width="580px">
            <v-card style="border-radius: 15px" class="pb-4">
              <v-card-title>
                <span> {{ $t("OTRequests") }}</span>
                <v-spacer></v-spacer>
                <v-btn @click="closerequestdialog()" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("EmployeeName")
                        }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field
                        autocomplete="off"
                        v-model="requestOT.empname"
                        readonly
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-form>
                    <v-row>
                      <v-col>
                        <div class="mb-2 ml-2">
                          <input
                            v-model="requestOT.wholeday"
                            @click="showstartdate()"
                            type="checkbox"
                          />
                          <label class="ml-3"> {{ $t("wholeDay") }}</label>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-show="showandhide" class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTDate") }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          fluid
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              single-line
                              autocomplete="off"
                              dense
                              outlined
                              :rules="OTdateSent"
                              :value="computedDateFormatted1"
                              append-icon="mdi-calendar-month-outline"
                              color="#F99D20"
                              persistent-hint
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="requestOT.OTdate"
                            no-title
                            :allowed-dates="allowedDates"
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row v-show="showandhide" class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTEndDate") }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-menu
                          v-model="menu11"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          fluid
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              single-line
                              autocomplete="off"
                              dense
                              outlined
                              :rules="OTenddateSentforwholeday"
                              :value="computedDateFormatted10"
                              append-icon="mdi-calendar-month-outline"
                              color="#F99D20"
                              persistent-hint
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="requestOT.OTendddateforwholeday"
                            no-title
                            :allowed-dates="allowedDates"
                            @input="menu11 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row v-show="!showandhide" class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTStartDate")
                          }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-menu
                          v-model="menu7"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          fluid
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              single-line
                              autocomplete="off"
                              dense
                              outlined
                              :value="computedDateFormatted3"
                              :rules="OTstartdateSent"
                              append-icon="mdi-calendar-month-outline"
                              color="#F99D20"
                              persistent-hint
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="requestOT.OTstartdate"
                            no-title
                            :allowed-dates="allowedDates"
                            @input="menu7 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row v-show="!showandhide" class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTEndDate") }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-menu
                          v-model="menu8"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          fluid
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              single-line
                              autocomplete="off"
                              dense
                              outlined
                              :value="computedDateFormatted4"
                              :rules="OTenddateSent"
                              append-icon="mdi-calendar-month-outline"
                              color="#F99D20"
                              persistent-hint
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="requestOT.OTenddate"
                            no-title
                            :allowed-dates="allowedDates"
                            @input="menu8 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTStartTime")
                          }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time1"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              autocomplete="off"
                              v-model="requestOT.OTstart"
                              append-icon="access_time"
                              single-line
                              outlined
                              dense
                              :rules="OTstartSent"
                              color="#F99D20"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu2"
                            v-model="requestOT.OTstart"
                            no-title
                            fluid
                            @click:minute="$refs.menu2.save(requestOT.OTstart)"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTEndTime") }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-menu
                          ref="menu3"
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time2"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              autocomplete="off"
                              v-model="requestOT.OTend"
                              :rules="OTendSent"
                              append-icon="access_time"
                              single-line
                              outlined
                              dense
                              color="#F99D20"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu3"
                            v-model="requestOT.OTend"
                            no-title
                            fluid
                            @click:minute="$refs.menu3.save(requestOT.OTend)"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">{{ $t("oTHours") }}</p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-text-field
                          v-model="requestOT.OThours"
                          autocomplete="off"
                          auto
                          readonly
                          :rules="OThoursSent"
                          height="10px"
                          color="#F99D20"
                          single-line
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("purposeofOT")
                          }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-text-field
                          autocomplete="off"
                          v-model="requestOT.purposeOT"
                          height="10px"
                          :rules="purposeOTSent"
                          color="#F99D20"
                          single-line
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("approvalSentTo")
                        }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field
                        autocomplete="off"
                        v-model="requestOT.approver"
                        :value="requestOT.approverID"
                        readonly
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">Status</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field height="10px" color="#F99D20" single-line outlined dense required></v-text-field>
                </v-col>
              </v-row>-->
                </v-container>
              </v-card-text>
              <div class="ml-11 mr-11">
                <v-row justify="center">
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <v-btn
                      color="#F99D20"
                      class="white--text mr-5 text-capitalize btnres"
                      block
                      max-height="35"
                      @click="RequestedOT()"
                    >
                      {{ $t("SendRequest") }}</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <v-btn
                      color="#F99D20"
                      class="white--text mr-5 text-capitalize"
                      block
                      max-height="35"
                      @click="SavedraftOT()"
                      >{{ $t("SaveDraft") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-dialog>
          
          <!-- viewdialog -->
          <v-dialog persistent max-width="580px">
            <v-card style="border-radius: 15px;overflow:hidden;">
              <v-card-title>
                <span>{{ $t("OTRequests") }} 111</span>
                <v-spacer></v-spacer>
                <v-btn @click="hidedetaildialog()" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("EmployeeName")
                        }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field
                        :disabled="disablestatus"
                        v-model="EditOT.empname"
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="mb-2 ml-2">
                        <input
                          v-model="EditOT.wholeday"
                          @click="showeditdate()"
                          :disabled="disableviewdata"
                          type="checkbox"
                        />
                        <label class="ml-3"> {{ $t("wholeDay") }}</label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-show="editshowandhide" class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTDate") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-menu
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        fluid
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :disabled="disableviewdata"
                            single-line
                            dense
                            outlined
                            :value="computedDateFormatted2"
                            append-icon="mdi-calendar-month-outline"
                            color="#F99D20"
                            persistent-hint
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="EditOT.OTdate"
                          no-title
                          @input="menu4 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-show="editshowandhide" class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTEndDate") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-menu
                        v-model="menu12"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        fluid
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :disabled="disableviewdata"
                            single-line
                            dense
                            outlined
                            :value="computedDateFormatted11"
                            append-icon="mdi-calendar-month-outline"
                            color="#F99D20"
                            persistent-hint
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="EditOT.OTenddateforwholeday"
                          no-title
                          @input="menu12 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-show="!editshowandhide" class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTStartDate") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-menu
                        v-model="menu9"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        fluid
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :disabled="disableviewdata"
                            single-line
                            autocomplete="off"
                            dense
                            outlined
                            :value="computedDateFormatted5"
                            append-icon="mdi-calendar-month-outline"
                            color="#F99D20"
                            persistent-hint
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="EditOT.OTstartdate"
                          no-title
                          @input="menu9 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-show="!editshowandhide" class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTEndDate") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-menu
                        v-model="menu10"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        fluid
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :disabled="disableviewdata"
                            single-line
                            autocomplete="off"
                            dense
                            outlined
                            :value="computedDateFormatted6"
                            append-icon="mdi-calendar-month-outline"
                            color="#F99D20"
                            persistent-hint
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="EditOT.OTenddate"
                          no-title
                          @input="menu10 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTStartTime") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-menu
                        ref="menu5"
                        v-model="menu5"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time3"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :disabled="disableviewdata"
                            v-model="viewOtstart"
                            append-icon="access_time"
                            single-line
                            outlined
                            dense
                            color="#F99D20"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu5"
                          v-model="viewOtstart"
                          no-title
                          fluid
                          @click:minute="$refs.menu5.save(viewOtstart)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTEndTime") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-menu
                        ref="menu6"
                        v-model="menu6"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time4"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :disabled="disableviewdata"
                            v-model="viewOtend"
                            append-icon="access_time"
                            single-line
                            outlined
                            dense
                            color="#F99D20"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu6"
                          v-model="viewOtend"
                          no-title
                          fluid
                          @click:minute="$refs.menu6.save(viewOtend)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">{{ $t("oTHours") }}</p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field
                        :disabled="disableviewdata"
                        v-model="EditOT.OThours"
                        height="10px"
                        readonly
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-form ref="formEdit" lazy-validation>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("purposeofOT")
                          }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-text-field
                          :disabled="disableviewdata"
                          v-model="EditOT.purposeOT"
                          :rules="purposeOTEdit"
                          height="10px"
                          color="#F99D20"
                          single-line
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("approvalSentTo")
                        }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field
                        :disabled="disableviewdata"
                        v-model="EditOT.approver"
                        readonly
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">{{ $t("status") }}</p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field
                        :disabled="disablestatus"
                        v-model="EditOT.status"
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <div class="ml-11 mr-11" v-show="!showeditbtn">
                <v-row justify="center">
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <div v-show="!showeditbtn">
                      <v-btn
                        v-show="!hideeditbtn"
                        color="#F99D20"
                        class="white--text mr-5 text-capitalize btnres"
                        block
                        max-height="35"
                        :disabled="disableeditbtn || permission.edit"
                        @click="showdetaildata()"
                        >{{ $t("edit") }}</v-btn
                      >
                      <v-btn
                        v-show="hideeditbtn"
                        color="#F99D20"
                        class="white--text mr-5 text-capitalize btnres"
                        block
                        max-height="35"
                        :disabled="disableeditbtn || permission.edit"
                        @click="EditOTrequest(2)"
                        >{{ $t("save") }}</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col v-show="!showeditbtn" cols="12" xs="12" sm="4" md="4">
                    <v-btn
                      color="#F99D20"
                      class="white--text text-capitalize btnres"
                      block
                      max-height="35"
                      :disabled="disablesendbtn || permission.add"
                      @click="SendOTrequest()"
                      >{{ $t("SendRequest") }}</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <v-btn
                      color="#FF0000"
                      class="white--text text-capitalize"
                      block
                      max-height="35"
                      @click="deleteOTrequest()"
                      :disabled="disalbecanclebtn || permission.delete"
                    >
                      {{ $t("cancelRequest") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <div class="ml-11 mr-11" v-show="showeditbtn">
                <v-row justify="center">
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <v-btn
                      color="#FF0000"
                      v-if="
                        EditOT.status != 'Approved' && EditOT.status != 'Denied'
                      "
                      class="white--text text-capitalize"
                      block
                      max-height="35"
                      @click="deleteOTrequest()"
                      :disabled="disalbecanclebtn || permission.delete"
                      >{{ $t("cancelRequest") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-dialog>

          <!-- deletedialog -->
          <v-dialog v-model="Deletedialog" persistent max-width="532">
            <v-card style="border-radius: 15px">
              <v-card-title>
                <p style="margin-top: 10px; margin-bottom: -30px">
                  {{ $t("ConfirmToCancelRequest") }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col align="center" cols="12">
                      <p style="font-size: 17px; color: #444444" class="pt-4">
                        {{ $t("DoYouWantToCancelThisRequest") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions style="margin-right: 3%" class="pb-6">
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-2 text-capitalize"
                  width="120"
                  max-height="35"
                  @click="DeleteCancel"
                  text
                >
                  {{ $t("Cancel") }}</v-btn
                >
                <v-btn
                  color="#FF2727"
                  class="mr-2 white--text text-capitalize"
                  width="120"
                  max-height="35"
                  @click="DeleteOTrequestlist()"
                  >{{ $t("Confirm") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- OT-request dialog -->
        </v-card>

        <!-- New OT-Request dilaog -->
        <v-dialog v-model="showOT" persistent max-width="1100px">
          <v-card style="border-radius: 15px;overflow:hidden;">
            <v-card-title>
              <span> {{ $t("OTRequests") }} </span>
              <v-spacer></v-spacer>
              <v-btn @click="closeNoti()" icon>
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pb-4">
              <hr />
              <v-row class="mt-3">
                <!-- Requested Date -->
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("RequestDate") }}:
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-text-field
                        disabled
                        v-model="OTDetail.reqDate"
                        height="10px"
                        single-line
                        outlined
                        dense
                        readonly
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Status -->
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("status") }} :
                    </v-col>

                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-row class="mt-1">
                        <div
                          :style="{
                            maxWidth: '150px',
                            height: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'center',

                            color:
                              OTDetail.status == 'Approved' ||
                              OTDetail.status == 'Manual'
                                ? '#00C092'
                                : OTDetail.status == 'Waiting Approval'
                                ? '#5B9BD5'
                                : OTDetail.status == 'Draft Request'
                                ? '#595959'
                                : OTDetail.status == 'Denied'
                                ? '#FFFFFF'
                                : OTDetail.status == 'Canceled'
                                ? '#FF0000'
                                : '#5B9BD5',
                            backgroundColor:
                              OTDetail.status == 'Approved' ||
                              OTDetail.status == 'Manual'
                                ? '#CCF3E9'
                                : OTDetail.status == 'Waiting Approval'
                                ? '#DAE3F3'
                                : OTDetail.status == 'Draft Request'
                                ? '#D0CECE'
                                : OTDetail.status == 'Denied'
                                ? '#7F7F7F'
                                : OTDetail.status == 'Canceled'
                                ? '#FFCCCC'
                                : '#DAE3F3',
                            textAlign: 'center',
                            borderRadius: '20px',
                            padding: '5px',
                          }"
                        >
                          {{
                            OTDetail.status == "Approved" ||
                            OTDetail.status == "Manual"
                              ? $t("approved")
                              : OTDetail.status == "Denied"
                              ? $t("denied")
                              : OTDetail.status == "Draft Request"
                              ? $t("Draftreq")
                              : OTDetail.status == "Canceled"
                              ? $t("Canceled")
                              : $t("waitingApproval")
                          }}
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("EmployeeName") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-text-field
                        disabled
                        v-model="OTDetail.empname"
                        height="10px"
                        single-line
                        outlined
                        readonly
                        dense
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("position") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-text-field
                        disabled
                        v-model="OTDetail.position"
                        height="10px"
                        single-line
                        outlined
                        dense
                        readonly
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("date") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        fluid
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="computedDateFormatted8"
                            disabled
                            single-line
                            autocomplete="off"
                            dense
                            outlined
                            readonly
                            color="#F99D20"
                            persistent-hint
                            v-on="on"
                            class="readonly"
                          ></v-text-field>
                        </template>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ "OTHours" }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-menu
                        ref="menu6"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            disabled
                            v-model="OTDetail.OThours"
                            single-line
                            outlined
                            dense
                            color="#F99D20"
                            readonly
                            v-on="on"
                            class="readonly"
                          ></v-text-field>
                        </template>
                        <v-time-picker no-title fluid></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("StartTime") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-menu
                        ref="menu5"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            disabled
                            v-model="OTDetail.OTstart"
                            single-line
                            outlined
                            dense
                            color="#F99D20"
                            readonly
                            v-on="on"
                            class="readonly"
                          ></v-text-field>
                        </template>
                        <v-time-picker no-title fluid></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("EndTime") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-menu
                        ref="menu6"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            disabled
                            v-model="OTDetail.OTend"
                            single-line
                            outlined
                            dense
                            color="#F99D20"
                            readonly
                            v-on="on"
                            class="readonly"
                          ></v-text-field>
                        </template>
                        <v-time-picker no-title fluid></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
                  <v-row>
                    <v-col
                      class="des d-flex"
                      cols="12"
                      xs="3"
                      sm="3"
                      md="3"
                      lg="1"
                      xl="2"
                    >
                      {{ $t("DescriptionOT") }}:
                    </v-col>

                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="11"
                      xl="10"
                      class="pt-0 des2"
                    >
                      <div
                        :style="{
                          width: $vuetify.breakpoint.lg
                            ? '1010px'
                            : $vuetify.breakpoint.xl
                            ? '910px'
                            : $vuetify.breakpoint.md
                            ? '800px'
                            : '',
                        }"
                      >
                        <v-textarea
                          disabled
                          v-model="OTDetail.purposeOT"
                          solo
                          no-resize
                          color="#F99D20"
                          rows="3"
                          min-height="80"
                          name="input-7-4"
                          class="mb-4 readonly"
                          single-line
                          outlined
                          dense
                          :style="
                            $vuetify.breakpoint.lg
                              ? 'caret-color: orange !important;margin-left:6rem;'
                              : 'caret-color: orange !important'
                          "
                        >
                        </v-textarea>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-1">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("AttachmentOT") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <div
                        v-if="OTDetail.attachmentlist == null"
                        class="mr-0 pa-3 relative"
                        style="
                          width: 130px;
                          height: 120px;
                          border: 1px solid #ccc;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          border-radius: 7px;
                        "
                      >
                        <v-img
                          class="mb-3 mt-2"
                          src="@/assets/images/none.png"
                          width="60px"
                        ></v-img>
                        <div class="text-center">None</div>
                      </div>
                      <div
                        v-else-if="OTDetail.attachmentlist != null"
                        class="container"
                      >
                        <div
                          v-for="file in OTDetail.attachmentlist"
                          :key="file.filename"
                          class="mr-0 pa-3 relative"
                          style="
                            width: 130px;
                            height: 120px;
                            border: 1px solid #ccc;
                            border-radius: 7px;
                          "
                        >
                          <div
                            @click="showiframeforDetail(file)"
                            :style="
                              Editstate == true
                                ? 'margin-top:-1.9rem;'
                                : 'margin-top:5px;'
                            "
                          >
                            <v-img
                              class="mb-3 ml-5"
                              v-if="file.filetype == 'png'"
                              src="@/assets/images/png.png"
                              width="50px"
                            ></v-img>
                            <v-img
                              class="mb-3 ml-5"
                              v-if="file.filetype == 'jpeg'"
                              src="@/assets/images/jpg.png"
                              width="50px"
                            ></v-img>
                            <v-img
                              class="mb-3 ml-5"
                              v-if="file.filetype == 'jpg'"
                              src="@/assets/images/jpg.png"
                              width="50px"
                            ></v-img>
                            <v-img
                              class="mb-3 ml-5"
                              v-if="file.filetype == 'pdf'"
                              src="@/assets/images/pdf.png"
                              width="50px"
                            ></v-img>
                            <div class="text-center">
                              {{
                                file.filename
                                  ? file.filename.substring(0, 10) + " ..."
                                  : ""
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" xl="6"></v-col>
              </v-row>

              <v-row class="mt-10">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("approver") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-text-field
                        disabled
                        v-model="OTDetail.approver"
                        readonly
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        required
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                v-if="OTDetail.status == 'Canceled' && OTDetail.reason != null"
                class="mt-10"
              >
                <hr />
                <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="12">
                  <v-row>
                    <v-col
                      class="des d-flex"
                      cols="12"
                      xs="3"
                      sm="3"
                      md="3"
                      lg="1"
                      xl="2"
                    >
                      <span style="font-weight: bold">{{
                        $t("ActionNote")
                      }}</span
                      ><span class="red--text">*</span></v-col
                    >
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="11"
                      xl="10"
                      class="pt-0 des2"
                    >
                      <div
                        :style="{
                          width: $vuetify.breakpoint.lg
                            ? '1010px'
                            : $vuetify.breakpoint.xl
                            ? '910px'
                            : $vuetify.breakpoint.md
                            ? '800px'
                            : '',
                        }"
                      >
                        <v-textarea
                          solo
                          disabled
                          v-model="OTDetail.reason"
                          color="#F99D20"
                          no-resize
                          rows="3"
                          min-height="80"
                          name="input-7-4"
                          class="mb-4 readonly"
                          single-line
                          outlined
                          dense
                          :style="
                            $vuetify.breakpoint.lg
                              ? 'caret-color: orange !important;margin-left:6rem;'
                              : 'caret-color: orange !important'
                          "
                        >
                        </v-textarea>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Detail request -->
        <v-dialog v-model="viewdialog" persistent max-width="1000px">
          <v-card style="border-radius: 15px;overflow:hidden;">
            <v-card-title>
              <span>{{ $t("Overtime") }}</span>
              <v-spacer></v-spacer>
              <v-btn @click="hidedetaildialog()" icon>
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pb-4">
              <hr />
              <v-row class="mt-3">
                <!-- Requested Date -->
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("RequestDate") }}:
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-text-field
                        v-model="EditOT.RequestedDate"
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        readonly
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Status -->
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("status") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0">
                      <v-row class="mt-1">
                        <div
                          :style="{
                            maxWidth: '150px',
                            height: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'center',
                            color:
                              EditOT.status == 'Approved'
                                ? '#00C092'
                                : EditOT.status == 'Waiting Approval'
                                ? '#5B9BD5'
                                : EditOT.status == 'Draft Request'
                                ? '#595959'
                                : EditOT.status == 'Canceled'
                                ? '#FF0000'
                                : '#000000',
                            backgroundColor:
                              EditOT.status == 'Approved'
                                ? '#CCF3E9'
                                : EditOT.status == 'Waiting Approval'
                                ? '#DAE3F3'
                                : EditOT.status == 'Draft Request'
                                ? '#D0CECE'
                                : EditOT.status == 'Canceled'
                                ? '#FFCCCC'
                                : '#7F7F7F',
                            textAlign: 'center',
                            borderRadius: '20px',
                            width: '150px',
                            height: '35px',
                            justifyContent: 'center',
                            padding: '5px',
                            alignItems: 'center'
                          }"
                        >
                          {{
                            EditOT.status == "Approved"
                              ? $t("approved")
                              : EditOT.status == "Denied"
                              ? $t("denied")
                              : EditOT.status == "Draft Request"
                              ? $t("Draftreq")
                              : EditOT.status == "Canceled"
                              ? $t("Canceled")
                              : $t("waitingApproval")
                          }}
                        </div>
                        <v-btn
                          v-if="EditOT.status == 'Draft Request'"
                          :style="disableviewdata ? 'background-color:#F99D20;' : 'background-color:#D7DAE2;'"
                          class="text-capitalize white--text ml-5"
                          width="90"
                          max-height="35"
                          @click="showdetaildata">
                          {{ $t("edit") }}
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("EmployeeName") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-text-field
                        v-model="EditOT.empname"
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        readonly
                        dense
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("position") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-text-field
                        v-model="EditOT.positionname"
                        height="10px"
                        color="#F99D20"
                        single-line
                        outlined
                        dense
                        readonly
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("oTHours") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-text-field
                        :disabled="disableviewdata"
                        v-model="EditOT.OThours"
                        height="10px"
                        readonly
                        color="#F99D20"
                        single-line
                        :error-messages="errorOTtime"
                        outlined
                        dense
                        required
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("otType") }} :
                    </v-col>
                    <v-col
                      style="position: relative"
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <v-autocomplete
                        v-model="EditOT.OTType"
                        :disabled="disableviewdata"
                        class="readonly"
                        :items="ottypefordetail"
                        :item-text="(item) => `${item.txt}`"
                        :item-value="(item) => `${item.txt}`"
                        outlined
                        dense
                        color="#F99D20"
                      >
                        <template v-slot:message="{}">
                          <img
                            src="@/assets/images/Icon awesome-exclamation-circle.png"
                            width="13"
                            height="13"
                          />
                          <span>{{ error.otType }}</span>
                        </template>
                      </v-autocomplete>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            :src="isHovered ? outlineinfoimg : infoimg"
                            @mouseover="handleMouseOver"
                            v-bind="attrs"
                            v-on="on"
                            @mouseleave="handleMouseLeave"
                            class="infoimg2"
                          />
                        </template>
                        <span>
                          {{ $t("ot1") }}<br />
                          {{ $t("ot2") }}<br />
                          {{ $t("ot3") }}<br />
                          {{ $t("ot4") }}<br />
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                    {{ $t("startdate") }} {{ $t("Time") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <!-- <v-menu v-model="menu9" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" single-line autocomplete="off" dense outlined readonly
                            :value="computedDateFormatted5" append-icon="mdi-calendar-month-outline" color="#F99D20"
                            persistent-hint v-on="on" class="readonly">
                          </v-text-field>
                        </template> -->
                      <!-- <v-date-picker v-model="EditOT.OTstartdate" no-title @input="menu9 = false"></v-date-picker> -->
                      <!-- </v-menu> -->
                      <date-picker
                        :disabled-date="disabledates3"
                        :disabled="disableviewdata"
                        :editable="false"
                        :clearable="false"
                        class="EditOTDatepicker custom-vuedatepicker2"
                        style="width: 100%; min-height: 40px"
                        name="datetimepicker"
                        v-model="EditOT.OTstartdatetime"
                        type="datetime"
                        format="DD/MM/YYYY HH:mm"
                      >
                      </date-picker>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("enddate") }} {{ $t("Time") }} :
                    </v-col>
                    <v-col
                      cols="12"
                      xs="9"
                      sm="9"
                      md="9"
                      lg="9"
                      xl="9"
                      class="pt-0"
                    >
                      <!-- <v-menu v-model="menu10" :close-on-content-click="false" transition="scale-transition" offset-y
                        fluid max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" single-line autocomplete="off" dense outlined readonly
                            :value="computedDateFormatted6" append-icon="mdi-calendar-month-outline" color="#F99D20"
                            persistent-hint v-on="on" class="readonly"></v-text-field>
                        </template>
                        <v-date-picker v-model="EditOT.OTenddate" no-title @input="menu10 = false"></v-date-picker>
                      </v-menu> -->
                      <date-picker
                        :disabled-date="disabledates3"
                        :disabled-time="disabletime2"
                        :clearable="false"
                        :editable="false"
                        :disabled="disableviewdata"
                        class="EditOTDatepicker custom-vuedatepicker2"
                        style="width: 100%; min-height: 40px"
                        name="datetimepicker"
                        v-model="EditOT.OTenddatetime"
                        type="datetime"
                        format="DD/MM/YYYY HH:mm"
                      >
                      </date-picker>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- <v-row class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("otStartTime") }} : </v-col>
                    <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                      <v-menu ref="menu5" v-model="menu5" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="time3" transition="scale-transition" offset-y max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" v-model="viewOtstart" append-icon="access_time"
                            single-line outlined dense color="#F99D20" readonly v-on="on" class="readonly"></v-text-field>
                        </template>
                        <v-time-picker v-if="menu5" v-model="viewOtstart" no-title fluid
                          @click:minute="$refs.menu5.save(viewOtstart)"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-row>
                    <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                      {{ $t("otEndTime") }} : </v-col>
                    <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                      <v-menu ref="menu6" v-model="menu6" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="time4" transition="scale-transition" offset-y max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" v-model="viewOtend" append-icon="access_time"
                            single-line outlined dense color="#F99D20" readonly v-on="on" class="readonly"></v-text-field>
                        </template>
                        <v-time-picker v-if="menu6" v-model="viewOtend" no-title fluid
                          @click:minute="$refs.menu6.save(viewOtend)"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row> -->

              <v-row>
                <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
                  {{ $t("DescriptionOT") }} :</v-col
                >
                <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10">
                  <div
                    :style="{
                      width: $vuetify.breakpoint.lgAndUp
                        ? '823px'
                        : $vuetify.breakpoint.md
                        ? '800px'
                        : '',
                    }"
                  >
                    <v-textarea
                      :disabled="disableviewdata"
                      v-model="EditOT.purposeOT"
                      :rules="purposeOTEdit"
                      solo
                      :style="
                        $vuetify.breakpoint.mdAndUp
                          ? 'caret-color: orange !important;margin-left:-2.5rem;'
                          : 'caret-color: orange !important'
                      "
                      no-resize
                      color="#F99D20"
                      rows="3"
                      min-height="80"
                      name="input-7-4"
                      class="mb-4 readonly"
                      single-line
                      outlined
                      dense
                      required
                      style="caret-color: orange !important"
                    >
                      <template v-slot:message="{}">
                        <img
                          src="@/assets/images/Icon awesome-exclamation-circle.png"
                          width="13"
                          height="13"
                        />
                        <span>
                          {{ $t("PleaseSpecify") }}
                        </span>
                      </template>
                    </v-textarea>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
                  {{ $t("AttachmentOT") }} :</v-col
                >
                <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10">
                  <div
                    v-show="!Editstate"
                    v-if="EditOT.attachmentlist.length === 0"
                    class="mr-0 pa-3 relative"
                    :style="
                      $vuetify.breakpoint.mdAndUp
                        ? 'margin-left : -2.5rem; '
                        : ''
                    "
                    style="
                      width: 130px;
                      height: 120px;
                      border: 1px solid #ccc;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border-radius: 7px;
                    "
                  >
                    <v-img
                      class="mb-3 mt-2"
                      src="@/assets/images/none.png"
                      width="60px"
                    ></v-img>
                    <div class="text-center">None</div>
                  </div>

                  <div
                    class="container"
                    :style="
                      $vuetify.breakpoint.mdAndUp
                        ? 'margin-left : -2.5rem; '
                        : ''
                    "
                  >
                    <div
                      style="width: 130px; height: 120px"
                      v-show="Editstate"
                      @click="onpickfile"
                      class="drop-area"
                      @dragover.prevent="handleDragOver"
                      @drop="handleFileDrop"
                    >
                      <div style="text-align: center" class="mt-2">
                        <v-icon style="color: #a3a7aa">mdi-cloud-upload</v-icon>
                        <p style="color: #a3a7aa">{{ $t("choosefile") }}</p>
                      </div>
                    </div>

                    <div
                      v-for="file in EditOT.attachmentlist"
                      :key="file.filename"
                      class="mr-0 pa-3 relative"
                      style="
                        width: 130px;
                        height: 120px;
                        border: 1px solid #ccc;
                        border-radius: 7px;
                      "
                    >
                      <v-btn
                        @click="removefileEdit(file)"
                        class="close-button top-0 right-0"
                        icon
                        v-if="Editstate == true"
                        ><v-icon>close</v-icon></v-btn
                      >
                      <div
                        @click="showiframeforDetail(file)"
                        :style="
                          Editstate == true
                            ? 'margin-top:-1.9rem;'
                            : 'margin-top:5px;'
                        "
                      >
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.filetype == 'png'"
                          src="@/assets/images/png.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.filetype == 'jpeg'"
                          src="@/assets/images/jpg.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.filetype == 'jpg'"
                          src="@/assets/images/jpg.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.filetype == 'pdf'"
                          src="@/assets/images/pdf.png"
                          width="50px"
                        ></v-img>
                        <div class="text-center">
                          {{
                            file.filename
                              ? file.filename.substring(0, 10) + " ..."
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                    <input
                      ref="fileupload"
                      @change="handleFileSelect"
                      type="file"
                      style="display: none"
                      id="profile_picture"
                      accept=".pdf, .png, .jpeg, .jpg"
                    />

                    <div
                      v-for="file in uploadedFiles"
                      :key="file.name"
                      class="ml-5 mr-0 pa-3 relative"
                      style="
                        width: 130px;
                        height: 120px;
                        border: 1px solid #ccc;
                        border-radius: 7px;
                      "
                    >
                      <v-btn
                        @click="removefile(file)"
                        class="close-button top-0 right-0"
                        icon
                        ><v-icon>close</v-icon></v-btn
                      >
                      <div
                        @click="showiframe(file)"
                        style="margin-top: -1.9rem"
                      >
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.type == 'image/png'"
                          src="@/assets/images/png.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.type == 'image/jpeg'"
                          src="@/assets/images/jpg.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.type == 'image/jpg'"
                          src="@/assets/images/jpg.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.type == 'application/pdf'"
                          src="@/assets/images/pdf.png"
                          width="50px"
                        ></v-img>
                        <div class="text-center">
                          {{
                            file.name ? file.name.substring(0, 10) + "..." : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
                  {{ $t("approver") }} :</v-col
                >
                <v-col>
                  <v-row>
                    <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                      <v-text-field
                        :disabled="disableviewdata"
                        v-model="EditOT.approver"
                        readonly
                        height="10px"
                        color="#F99D20"
                        :style="
                          $vuetify.breakpoint.mdAndUp
                            ? 'margin-left : -2.5rem; '
                            : ''
                        "
                        single-line
                        outlined
                        dense
                        required
                        class="readonly"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="EditOT.status != 'Draft Request'">
                      <p class="mt-3">{{ EditOT.RequestedDate }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="showcancel == true">
                <hr />
                <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2"
                  >{{ $t("ActionNote") }}:</v-col
                >
                <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10">
                  <v-text-field
                    :placeholder="$t('cancelnote')"
                    height="30px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions style="margin-right: 3%" class="pb-6">
              <v-spacer></v-spacer>
              <div v-if="Editstate == false">
                <v-btn
                  text
                  class="mr-2 text-capitalize"
                  width="120"
                  max-height="35"
                  v-if="
                    EditOT.status != 'Approved' && EditOT.status != 'Denied'
                  "
                  @click="deleteOTrequest()"
                  :disabled="permission.delete"
                >
                  {{ $t("cancelRequest") }}</v-btn
                >
                <v-btn
                  v-if="EditOT.status == 'Draft Request'"
                  color="#F99D20"
                  class="ml-3 white--text text-capitalize"
                  width="120"
                  max-height="35"
                  @click="SendOTrequest"
                  >{{ $t("Submit") }}</v-btn
                >
              </div>
              <div v-else>
                <v-btn
                  text
                  class="mr-2 text-capitalize"
                  width="120"
                  max-height="35"
                  @click="Gobackorignial()"
                >
                  {{ $t("Cancel") }}</v-btn
                >
                <v-btn
                  color="#F99D20"
                  class="ml-3 white--text text-capitalize"
                  width="120"
                  max-height="35"
                  @click="EditOTrequest(1)"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  color="#F99D20"
                  class="ml-3 white--text text-capitalize"
                  width="120"
                  max-height="35"
                  @click="EditOTrequest(2)"
                  >{{ $t("Savesubmit") }}</v-btn
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <div v-else class="mb-10">
      <div style="display: flex; align-items: center; cursor: pointer">
        <v-btn
          icon
          color="white"
          @click="showreq = false"
          style="margin-top: 20px"
          class="pr-2">
          <img src="@/assets/images/back button.png" />
        </v-btn>
        <span class="txt-header">
          {{ $t("MyOverTime") }}
        </span>
      </div>
      <div style="height: auto" class="mt-7">
        <v-card
          min-height="60vh"
          style="margin-bottom: 1.5rem;"
          class="pl-12 pr-12 pt-4 pb-4">
          <v-form ref="formSent" lazy-validation>
            <v-row class="mt-4">
              <v-col
                cols="12"
                xs="3"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                class="mt-2"
                style="font-size: 15px"
                >{{ $t("RequestDate") }}:</v-col
              >
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="requestOT.RequestedDate"
                  autocomplete="off"
                  readonly
                  height="10px"
                  color="#F99D20"
                  single-line
                  outlined
                  dense
                  required
                  class="readonly"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                xs="3"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                class="mt-2"
                style="font-size: 15px"
              >
                {{ $t("oTStartDate") }}<span class="red--text">*</span>:
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <date-picker
                  :editable="false"
                  :class="OTstartdateSent ? 'EditOTDatepicker custom-vuedatepicker-er-mg2' : 'EditOTDatepicker custom-vuedatepicker2'"
                  :clearable="false"
                  style="width: 100%; height: 40px"
                  :disabled-date="disabledates"
                  @change="datechange(1)"
                  name="datetimepicker"
                  color="#f299d0"
                  v-model="requestOT.OTstartdate"
                  type="datetime"
                  format="DD/MM/YYYY HH:mm"
                ></date-picker>
                <span v-if="OTstartdateSent" class="error-message ml-2">
                  <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" alt=""/>
                  <span>{{ OTstartdateSent }}</span>
                </span>
              </v-col>
              <v-col
                class="d-lg-none"
                cols="12"
                xs="1"
                sm="1"
                md="1"
                lg="1"
                xl="1"
              ></v-col>
              <v-col
                cols="12"
                xs="3"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                class="mt-2"
                :style="
                  $vuetify.breakpoint.lgAndUp
                    ? 'text-align:right'
                    : 'text-align:left'
                "
                style="font-size: 15px"
              >
                {{ $t("oTEndDate") }}<span class="red--text">*</span>:
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <date-picker
                  :editable="false"
                  :class="OTenddateSent ? 'EditOTDatepicker custom-vuedatepicker-er-mg2' : 'EditOTDatepicker custom-vuedatepicker2'"
                  class="EditOTDatepicker"
                  :clearable="false"
                  style="width: 100%; height: 40px"
                  :disabled="requestOT.OTstartdate == '' ? true : false"
                  :disabled-time="disabletime"
                  :disabled-date="disabledates2"
                  @change="datechange(2)"
                  name="datetimepicker"
                  color="#f299d0"
                  v-model="requestOT.OTenddate"
                  type="datetime"
                  format="DD/MM/YYYY HH:mm"
                >
                </date-picker>
                <span v-if="OTenddateSent" class="error-message ml-2">
                  <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" alt="" />
                  <span>{{ OTenddateSent }}</span>
                </span>
              </v-col>
            </v-row>
            <br /><br />
            <v-row>
              <v-col
                cols="12"
                xs="3"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                class="mt-2"
                style="font-size: 15px"
              >
                {{ $t("oTHours") }}:
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="requestOT.OThours"
                  :value="calculateTimeDifference"
                  autocomplete="off"
                  auto
                  readonly
                  class="readonly"
                  height="10px"
                  color="#F99D20"
                  single-line
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col
                class="d-lg-none"
                cols="12"
                xs="1"
                sm="1"
                md="1"
                lg="1"
                xl="1"
              ></v-col>
              <v-col
                cols="12"
                xs="3"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                class="mt-2"
                :style="
                  $vuetify.breakpoint.lgAndUp
                    ? 'text-align:right'
                    : 'text-align:left'
                "
                style="font-size: 15px"
              >
                {{ $t("otType") }} <span class="red--text">*</span>:
                <span v-if="fixflag == false" class="ml-1 d-sm-none">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        style="width: 15px !important; height: 15px !important"
                        :src="isHovered ? outlineinfoimg : infoimg"
                        @mouseover="handleMouseOver"
                        v-bind="attrs"
                        v-on="on"
                        @mouseleave="handleMouseLeave"
                        class="infoimg"
                      />
                    </template>
                    <span>
                      {{ $t("ot1") }}<br />
                      {{ $t("ot2") }}<br />
                      {{ $t("ot3") }}<br />
                      {{ $t("ot4") }}<br />
                    </span>
                  </v-tooltip>
                </span>
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <!-- <v-text-field v-if="fixflag == true" v-model="requestOT.OTType" autocomplete="off" auto readonly height="10px" color="#F99D20"
                  class="readonly" single-line outlined dense required background-color=""></v-text-field> -->
                <v-autocomplete
                  v-model="requestOT.otType"
                  :rules="otTypesent"
                  :items="ottype"
                  :item-text="(item) => `${item.txt}`"
                  :item-value="(item) => `${item.txt}`"
                  outlined
                  dense
                  color="#F99D20"
                  :error-messages="error.otType"
                >
                  <template v-slot:message="{}">
                    <img
                      src="@/assets/images/Icon awesome-exclamation-circle.png"
                      width="13"
                      height="13"
                    />
                    <span>{{ error.otType }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <div v-show="fixflag == false" class="mt-4 d-none d-sm-block">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      :src="isHovered ? outlineinfoimg : infoimg"
                      @mouseover="handleMouseOver"
                      v-bind="attrs"
                      v-on="on"
                      @mouseleave="handleMouseLeave"
                      class="infoimg"
                    ></v-img>
                  </template>
                  <span>
                    {{ $t("ot1") }}<br />
                    {{ $t("ot2") }}<br />
                    {{ $t("ot3") }}<br />
                    {{ $t("ot4") }}<br />
                  </span>
                </v-tooltip>
              </div>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                xs="3"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                class="mt-2"
                style="font-size: 15px"
              >
                {{ $t("DescriptionOT") }}<span class="red--text">*</span>:
              </v-col>
              <v-col cols="12" xs="3" sm="9" md="9" lg="6" xl="6">
                <v-textarea
                  v-model="requestOT.purposeOT"
                  :rules="purposeOTSent"
                  :placeholder="$t('Providefurtherdetailshere')"
                  no-resize
                  rows="3"
                  min-height="70"
                  color="#F99D20"
                  single-line
                  outlined
                  dense
                  required
                >
                  <template v-slot:message="{}">
                    <img
                      src="@/assets/images/Icon awesome-exclamation-circle.png"
                      width="13"
                      height="13"
                    />
                    <span>
                      {{ $t("PleaseSpecify") }}
                    </span>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                xs="3"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                class="mt-2"
                style="font-size: 15px"
              >
                {{ $t("AttachmentOT") }}:
              </v-col>
              <v-col cols="12" xs="3" sm="9" md="9" lg="6" xl="6">
                <div>
                  <div
                    v-if="uploaddefaultshow == true"
                    @click="onpickfile"
                    class="drop-area"
                    @dragover.prevent="handleDragOver"
                    @drop="handleFileDrop"
                  >
                    <div style="text-align: center">
                      <v-icon color="#A3A7AA">mdi-cloud-upload</v-icon>
                      <p style="color: #a3a7aa">{{ $t("draganddrop") }}</p>
                    </div>
                  </div>
                  <div class="container">
                    <div
                      style="width: 130px; height: 120px; margin-right: 5px"
                      v-if="uploaddefaultshow == false"
                      @click="onpickfile"
                      class="drop-area"
                      @dragover.prevent="handleDragOver"
                      @drop="handleFileDrop"
                    >
                      <div style="text-align: center" class="mt-2">
                        <v-icon style="color: #a3a7aa">mdi-cloud-upload</v-icon>
                        <p style="color: #a3a7aa">{{ $t("choosefile") }}</p>
                      </div>
                    </div>
                    <div
                      v-for="file in uploadedFiles"
                      :key="file.name"
                      class="mr-0 pa-3 relative"
                      style="
                        width: 130px;
                        height: 120px;
                        border: 1px solid #ccc;
                        border-radius: 7px;
                      "
                    >
                      <v-btn
                        @click="removefile(file)"
                        class="close-button top-0 right-0"
                        icon
                        ><v-icon>close</v-icon></v-btn
                      >
                      <div
                        @click="showiframe(file)"
                        style="margin-top: -1.9rem"
                      >
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.type == 'image/png'"
                          src="@/assets/images/png.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.type == 'image/jpeg'"
                          src="@/assets/images/jpg.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.type == 'image/jpg'"
                          src="@/assets/images/jpg.png"
                          width="50px"
                        ></v-img>
                        <v-img
                          class="mb-3 ml-5"
                          v-if="file.type == 'application/pdf'"
                          src="@/assets/images/pdf.png"
                          width="50px"
                        ></v-img>
                        <div class="text-center">
                          {{
                            file.name ? file.name.substring(0, 10) + " ..." : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    ref="fileupload"
                    @change="handleFileSelect"
                    type="file"
                    style="display: none"
                    id="profile_picture"
                    accept=".pdf, .png, .jpeg, .jpg"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                xs="3"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                class="mt-2"
                style="font-size: 15px"
              >
                {{ $t("Approver") }}:
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  autocomplete="off"
                  v-model="requestOT.approver"
                  :value="requestOT.approverID"
                  readonly
                  height="10px"
                  color="#F99D20"
                  single-line
                  outlined
                  dense
                  required
                  class="readonly"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              @click="showreq = false"
              text
            >
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn
              color="#A9A9A9"
              class="white--text mr-1 text-capitalize"
              width="150"
              max-height="35"
              @click="SavedraftOT()"
            >
              {{ $t("SaveDraft") }}
            </v-btn>
            <v-btn
              class="text-capitalize commonsavebtn"
              @click="RequestedOT()"
            >
              {{ $t("Submit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    
    <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg" style="overflow: hidden">
      <v-card>
        <v-card-title class="d-flex justify-end" style="padding-right: 1.5rem;">
          <v-btn icon @click="downloadImage()">
            <v-icon size="30">mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="dialogforDownload = false">
            <v-icon size="30">mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="padding: 0 1.5rem 1.5rem;">
          <v-img :src="imgForshow" max-width="500"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="printdialogforupdate" max-width="75%">
      <v-card style="background-color: white; overflow: hidden;">
        <v-card-title class="d-flex justify-end">
          <v-btn icon @click="closePreview" style="margin-right: 1rem;">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- <VuePdf v-if="pdfString" :src="pdfString" /> -->
          <ResponsivePdfViewer v-if="pdfString" :blobUrl="pdfString" />
          <div v-else>Loading PDF...</div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>

<script>
import store from "../../store";
//import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import ReportTrace from "@/function/Report.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import moment from "moment";
import { ConverttolocalwithStroke } from '@/plugins/toLocal.js';
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';
import ResponsivePdfViewer from '@/components/PDFViewer.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent,
    ResponsivePdfViewer
  },
  mixins: [LogTrace, ReportTrace, utils, CheckViewAddEditDelete],
  data: () => ({
    allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    permission: {
      add: true,
      edit: true,
      delete: true,
    },
    showcancel: false,
    dialogforDownload: false,
    printdialogforupdate: false,
    iframeShow: false,
    dllink: null,
    imgForshow: "",
    imgString: "",
    pdfString: "",
    infoimg: require("@/assets/images/Vector.png"),
    outlineinfoimg: require("@/assets/images/information-variant-circle 1.png"),
    otInfoList: [],
    fixflag: false,
    salarylist: null,
    emptype: "",
    isHovered: false,
    showreq: false,
    Olddata: [],
    OTdateSent: [],
    selectedFiles: [],
    uploadedFiles: [],
    uploaddefaultshow: true,
    OTenddateSentforwholeday: [],
    OTstartdateSent: null,
    OTenddateSent: null,
    OTstartSent: [],
    OTendSent: [],
    OThoursSent: [],
    purposeOTSent: [],
    otTypesent: [],
    purposeOTEdit: [],
    url: enurl.apiUrl,
    focus: new Date().toISOString().substr(0, 10),
    FilterStartDate: "",
    FilterEndDate: new Date(),
    page: 1,
    OTapprovedialog: false,
    Editstate: false,
    viewdialog: false,
    pageCount: 0,
    itemsPerPage: 10,
    todaymonths: null,
    todayyears: null,
    menu1: false,
    menu2: false,
    mymenu2: false,
    time1: null,
    Otstart: "",
    menu3: false,
    time2: null,
    menu4: false,
    viewdate: "",
    Otend: "",
    viewOtstart: "",
    viewOtend: "",
    menu: false,
    menu5: false,
    menu6: false,
    time3: null,
    time4: null,

    menu7: false,
    menu8: false,
    menu9: false,
    menu10: false,
    menu11: false,
    menu12: false,
    menu13: false,

    LoadingDialog: false,

    // headers: [
    //   {
    //     text: "Ot Date",
    //     value: "Otdate",
    //     align: "center",
    //     sortable: false,
    //   },
    //   {
    //     text: "OT Hours",
    //     value: "Othour",
    //     align: "center",
    //     sortable: false,
    //   },
    //   {
    //     text: "Start Date",
    //     value: "startdate",
    //     align: "center",
    //     sortable: false,
    //   },
    //   {
    //     text: "End Date",
    //     value: "enddate",
    //     align: "center",
    //     sortable: false,
    //   },
    //   {
    //     text: "Purpose of OT",
    //     value: "purposeot",
    //     align: "center",
    //     sortable: false,
    //   },
    //   {
    //     text: "Requested Date",
    //     value: "requestdate",
    //     align: "center",
    //     sortable: false,
    //   },
    //   {
    //     text: "Status",
    //     value: "status",
    //     align: "center",
    //     sortable: false,
    //   },
    //   {
    //     text: "Approver",
    //     value: "approver",
    //     align: "center",
    //     sortable: false,
    //   },
    //   {
    //     text: "Action",
    //     value: "action",
    //     align: "center",
    //     sortable: false,
    //   },
    // ],
    //itemInner: "Waiting Approval",
    itemInner: "All",
    pwdimage: {
      showpwd: require("@/assets/images/reveal icon@2x.png"),
      hidepwd: require("@/assets/images/pass@2x.png"),
    },
    desserts: [
      {
        Otdate: "5/04/2020",
        Othour: "5",
        startdate: "4/04/2020",
        enddate: "14/04/2020",
        purposeot: "Complete PO",
        requestdate: "10/04/2020",
        status: "Waiting for Approval",
        approver: "David",
      },
    ],
    approverlist: [],
    requestedlist: [],
    listrequest: [],
    OTdate: "",
    showandhide: true,
    editshowandhide: true,
    hideeditbtn: false,
    mystartdate: "",
    myenddate: "",
    disalbecanclebtn: false,
    disableeditbtn: false,
    disablesendbtn: false,
    disablestatus: true,
    showeditbtn: false,
    disableviewdata: true,
    Deletedialog: false,
    error: {
      otType: "",
    },
    requestOT: {
      RequestedDate: "",
      empname: "",
      OTdate: "",
      OTendddateforwholeday: "",
      OTstartdate: "",
      OTenddate: "",
      OTstart: "",
      wholeday: true,
      OTend: "",
      OThours: "",
      purposeOT: null,
      approver: null,
      approverID: null,
      otType: null,
      maxHour: null,
      maxMinute: null,
    },
    requesttrue: false,
    EditOT: {
      OTrequest_ID: "",
      attachmentlist: [],
      empname: "",
      RequestedDate: "",
      OTdate: "",
      OTenddateforwholeday: "",
      OTstartdate: "",
      OTstartdatetime: "",
      OTenddatetime: "",
      OTenddate: "",
      OTstart: "",
      wholeday: false,
      positionname: "",
      OTend: "",
      OTType: "",
      OThours: "",
      purposeOT: null,
      approver: null,
      approverID: null,
      status: null,
      monthDay: 0,
    },
    errorOTtime: "",
    showOT: false,
    OTDetail: {
      OTrequest_ID: "",
      empname: "",
      OTdate: "",
      OTenddateforwholeday: "",
      OTstartdate: "",
      OTenddate: "",
      OTstart: "",
      wholeday: false,
      OTend: "",
      OThours: "",
      purposeOT: null,
      approver: null,
      approverID: null,
      status: null,
      wfID: null,
      attachmentlist: null,
      position: null,
      reqDate: null,
    },
    ottypefordetail: [],
  }),

  mounted() {
    const currentDate = new Date();
    this.FilterStartDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),1);
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    const updateFormattedDate = () => {
      const date = new Date();
      this.requestOT.RequestedDate = formatDate(date);
    };
    // Update the formatted date every second
    setInterval(updateFormattedDate, 1000);
    // Initial formatting
    updateFormattedDate();
    let d = new Date();
    this.todaymonths = this.monthNames[d.getMonth() + 1].val;
    this.todayyears = new Date();
    this.GetOTSettinginfo();
    this.Getapprovername();
    this.Getrequest();
    this.sendNotification();
    this.getDataFromParams();
    this.GetEmpType();
    this.permission = this.CheckViewAddEditDelete(155, 156, 157, 158);
    // this.itemInner = this.$route.params.condition
    //   ? this.$route.params.condition
    //   : "All";
  },

  computed: {
    ottype() {
      if (this.emptype == "Monthly Payment") {
        return [
          { val: 1, txt: "OT1" },
          { val: 2, txt: "OT2" },
          { val: 4, txt: "OT4" },
        ];
      } else {
        return [
          { val: 2, txt: "OT2" },
          { val: 3, txt: "OT3" },
          { val: 4, txt: "OT4" },
        ];
      }
    },

    calculateTimeDifference() {
      if (this.requestOT.OTstartdate && this.requestOT.OTenddate) {
        const startTime = new Date(this.requestOT.OTstartdate);
        const endTime = new Date(this.requestOT.OTenddate);
        const timeDifference = endTime - startTime;
        const totalHours = timeDifference / (1000 * 60 * 60);
        return `${totalHours} hours`;
      }
      return "";
    },

    disabledates() {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return (date) => date <= yesterday;
    },

    disabledates2() {
      let startdate = new Date(this.requestOT.OTstartdate);
      startdate.setDate(startdate.getDate() - 1);
      let limitdate = new Date(this.requestOT.OTstartdate);
      limitdate.setDate(limitdate.getDate());
      return (date) => {
        return date <= startdate || date > limitdate;
      };
    },

    disabletime() {
      let starttime = new Date(this.requestOT.OTstartdate);
      let limittime = new Date(this.requestOT.OTstartdate);
      starttime.getHours();
      limittime.setHours(limittime.getHours() + 8);
      return (time) => {
        return time < starttime || time > limittime;
      };
    },

    disabledates3() {
      let startdate = new Date(this.EditOT.OTstartdate);
      startdate.setDate(startdate.getDate() - 1);
      return (date) => date <= startdate;
    },

    disabletime2() {
      let starttime = new Date(this.EditOT.OTstartdatetime);
      let limittime = new Date(this.EditOT.OTstartdatetime);
      //starttime.setDate(starttime.getDate());
      starttime.getHours();
      limittime.setHours(limittime.getHours() + 8);
      return (time) => {
        return time < starttime || time > limittime;
      };
    },

    status() {
      return [
        { val: "All", txt: this.$t("All") },
        { val: "Waiting Approval", txt: this.$t("waitingApproval") },
        { val: "Approved", txt: this.$t("approved") },
        { val: "Denied", txt: this.$t("denied") },
        { val: "Canceled", txt: this.$t("Canceled") },
        { val: "Draft Request", txt: this.$t("Draftreq") },
      ];
    },

    monthNames() {
      return [
        { val: 13, txt: this.$t("All")},
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },

    itemFilter() {
      let returndata = [];
      if (this.itemInner === "All") {
        returndata = this.requestedlist;
      } else {
        returndata = this.requestedlist.filter(
          (x) => x.status === this.itemInner
        );
      }
      if (!this.itemInner) return this.requestedlist;
      return returndata;
      // if (!this.itemInner) return this.requestedlist;
      // return this.requestedlist.filter((x) => x.status == this.itemInner);
    },

    computedDateFormatted1() {
      return this.formatDate(this.requestOT.OTdate);
    },

    computedDateFormatted2() {
      return this.formatDate(this.EditOT.OTdate);
    },

    computedDateFormatted3() {
      return this.formatDate(this.requestOT.OTstartdate);
    },

    computedDateFormatted4() {
      return this.formatDate(this.requestOT.OTenddate);
    },
    computedDateFormatted5() {
      return this.formatDate(this.EditOT.OTstartdate);
    },

    computedDateFormatted6() {
      return this.formatDate(this.EditOT.OTenddate);
    },

    computedDateFormatted7() {
      return this.formatDate(this.OTDetail.OTdate);
    },

    computedDateFormatted8() {
      return this.formatDate(this.OTDetail.OTstartdate);
    },

    computedDateFormatted9() {
      return this.formatDate(this.OTDetail.OTenddate);
    },

    computedDateFormatted10() {
      return this.formatDate(this.requestOT.OTendddateforwholeday);
    },

    computedDateFormatted11() {
      return this.formatDate(this.EditOT.OTenddateforwholeday);
    },
  },

  watch: {
    "requestOT.OTstart"(v) {
      //this.changetime(v, this.requestOT.OTend);
      this.calculateOThours(v, this.requestOT.OTend);
      this.OTstartSent = [];
    },
    "requestOT.OTend"(v) {
      //this.changetime(this.requestOT.OTstart, v);
      this.calculateOThours(this.requestOT.OTstart, v);
      this.OTendSent = [];
    },
    // viewOtstart(v) {
    //   this.changetimeofview(v, this.viewOtend);
    // },
    // viewOtend(v) {
    //   this.changetimeofview(this.viewOtstart, v);
    // },
    "requestOT.OTstartdate"() {
      //this.changetime(this.requestOT.OTstart, this.requestOT.OTend);
      this.calculateOThours(this.requestOT.OTstart, this.requestOT.OTend);
      this.OTstartdateSent = null;
    },
    FilterStartDate(newStartDate) {
      if (new Date(newStartDate) > new Date(this.FilterEndDate)) {
        this.FilterEndDate = null;
      }
    },
    "requestOT.OTdate"() {
      //this.changetime(this.requestOT.OTstart, this.requestOT.OTend);
      this.calculateOThours(this.requestOT.OTstart, this.requestOT.OTend);
      this.OTdateSent = [];
    },
    "requestOT.OTendddateforwholeday"() {
      //this.changetime(this.requestOT.OTstart, this.requestOT.OTend);
      this.calculateOThours(this.requestOT.OTstart, this.requestOT.OTend);
      this.OTenddateSentforwholeday = [];
    },
    "requestOT.OTenddate"() {
      //this.changetime(this.requestOT.OTstart, this.requestOT.OTend);
      this.calculateOThours(this.requestOT.OTstart, this.requestOT.OTend);
      this.OTenddateSent = null;
    },

    "EditOT.OTstartdatetime"() {
      this.errorOTtime = "";
      let localDateObj = new Date(this.EditOT.OTstartdatetime);
      // Get the UTC equivalent date and time
      let utcDateObj = new Date(
        localDateObj.getFullYear(),
        localDateObj.getMonth(),
        localDateObj.getDate(),
        localDateObj.getHours(),
        localDateObj.getMinutes(),
        localDateObj.getSeconds()
      );
      // Format the result in ISO format
      this.EditOT.OTstartdate = utcDateObj.toISOString();
      let hours = localDateObj.getHours();
      let minutes = localDateObj.getMinutes();
      this.viewOtstart =
        hours.toString().padStart(2, "0") +
        ":" +
        minutes.toString().padStart(2, "0");
      this.EditOT.OThours = this.DateTimeDifference(
        this.EditOT.OTstartdatetime,
        this.EditOT.OTenddatetime
      );
    },
    // "EditOT.OTstartdate"() {
    //   this.changetimeofview(this.viewOtstart, this.viewOtend);
    // },
    "EditOT.OTenddatetime"() {
      this.errorOTtime = "";
      let localDateObj = new Date(this.EditOT.OTenddatetime);
      // Get the UTC equivalent date and time
      let utcDateObj = new Date(
        localDateObj.getFullYear(),
        localDateObj.getMonth(),
        localDateObj.getDate(),
        localDateObj.getHours(),
        localDateObj.getMinutes(),
        localDateObj.getSeconds()
      );
      // Format the result in ISO format
      this.EditOT.OTenddate = utcDateObj.toISOString();
      let hours = localDateObj.getHours();
      let minutes = localDateObj.getMinutes();
      this.viewOtend =
        hours.toString().padStart(2, "0") +
        ":" +
        minutes.toString().padStart(2, "0");
      this.EditOT.OThours = this.DateTimeDifference(
        this.EditOT.OTstartdatetime,
        this.EditOT.OTenddatetime
      );
    },
    // "EditOT.OTenddate"() {
    //   this.changetimeofview(this.viewOtstart, this.viewOtend);
    // },
    "requestOT.purposeOT"() {
      this.purposeOTSent = [];
    },
    "requestOT.OThours"() {
      this.OThoursSent = [];
    },
    "EditOT.purposeOT"() {
      this.purposeOTEdit = [];
    },
    "requestOT.otType"(v) {
      if (v) this.error.otType = "";
      this.otTypesent = [];
    },
    $route() {
      this.getDataFromParams();
    },
  },
  methods: {
    calculateOThours(start, end) {
      const startTime = new Date(this.requestOT.OTstartdate);
      const endTime = new Date(this.requestOT.OTenddate);
      const timeDifference = endTime - startTime;
      if (!isNaN(timeDifference) && start != null && end != null)
      {
        const totalMinutes = timeDifference / (1000 * 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        this.requestOT.maxHour = hours;
        this.requestOT.maxMinute = minutes;
        if (minutes === 0)
        {
          this.requestOT.OThours = `${this.formatTimeUnit(hours, "hour")}`;
        }
        else
        {
          this.requestOT.OThours = `${this.formatTimeUnit(hours,"hour")} ${this.formatTimeUnit(minutes, "minute")}`;
        }
      }
      else
      {
        this.requestOT.OThours = "";
        this.requestOT.maxHour = null;
        this.requestOT.maxMinute = null;
      }
    },

    formatTimeUnit(value, unit) {
      return `${value} ${value === 1 ? unit : unit + "s"}`;
    },

    datechange(time) {
      let temptime = "";
      if (time == 1) {
        if (this.requestOT.OTstartdate && this.requestOT.OTenddate) {
          if (
            new Date(this.requestOT.OTstartdate) >
            new Date(this.requestOT.OTenddate)
          ) {
            this.requestOT.OTenddate = ""; // Clear end date if start date is greater
          }
        }
        //tempdate=this.requestOT.OTstartdate.toLocaleDateString('en-GB');
        this.requestOT.OTstart =
          this.requestOT.OTstartdate.toLocaleString("en-GB");
        temptime = this.requestOT.OTstartdate.toLocaleTimeString("en-GB", {
          hour12: false,
        });
      } else {
        //tempdate=this.requestOT.OTenddate.toLocaleDateString('en-GB');
        this.requestOT.OTend = this.requestOT.OTenddate.toLocaleString("en-GB");
        temptime = this.requestOT.OTenddate.toLocaleTimeString("en-GB", {
          hour12: false,
        });
      }
      let [hours, minutes] = temptime.split(":");
      // Create a new Date object and set the time components
      let dateObject = new Date();
      dateObject.setHours(hours);
      dateObject.setMinutes(minutes);
      // Format the time as HH:mm
      let formattedHours = dateObject.getHours().toString().padStart(2, "0");
      let formattedMinutes = dateObject
        .getMinutes()
        .toString()
        .padStart(2, "0");
      temptime = `${formattedHours}:${formattedMinutes}`;
      if (time == 1)
      {
        this.requestOT.OTstart = temptime;
      }
      else
      {
        this.requestOT.OTend = temptime;
      }
    },

    allowedEndDates(date) {
      if (this.FilterStartDate)
      {
        return new Date(date) <= new Date(this.FilterStartDate);
      }
      return false;
    },

    handleMouseOver() {
      this.isHovered = true;
    },

    handleMouseLeave() {
      this.isHovered = false;
    },

    GetOTSettinginfo() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}OT_Setting/GetOTSetting`, temp)
      .then(function (response) {
        self.otInfoList = response.data.data;
        self.fixflag = self.otInfoList.fixRateFlag;
      });
    },

    onpickfile() {
      this.$refs.fileupload.click();
    },

    NewOT() {
      this.showreq = true;
      this.requestOT.RequestedDate = this.convertUTCDateToLocalDateForReqfornew();
      this.uploadedFiles = [];
    },

    handleDragOver(event) {
      event.preventDefault();
    },

    handleFileDrop(event) {
      event.preventDefault();
      this.handleFiledrag(event);
    },

    showiframe(item) {
      let self = this;
      if (
        item.type == "image/png" ||
        item.type == "image/jpeg" ||
        item.type == "image/jpg"
      )
      {
        self.imgForshow = "data:image/png;base64," + item.imgurl;
        self.dialogforDownload = true;
        self.imgString = item.imgurl;
        self.dllink = item.pdfurl;
        self.imgName = item.name;
      }
      else
      {
        self.printdialogforupdate = true;
        self.iframeShow = true;
        self.pdfString = item.pdfurl;
      }
    },

    closePreview() {
      if (this.pdfString)
      {
        URL.revokeObjectURL(this.pdfString); // Clean up the Blob URL
        this.pdfString = null; // Reset the pdfString
      }
      this.printdialogforupdate = false;
      this.iframeShow = false;
    },

    async showiframeforDetail(item) {
      let self = this;
      if (item.filetype == "pdf")
      {
        //const blob = await self.base64ToBlob(item.attachment_file,"application/pdf");
        self.printdialogforupdate = true;
        self.iframeShow = true;
        // const blobUrl = URL.createObjectURL(blob);
        // self.pdfString = blobUrl;
        const response = await fetch(item.attachment_file);
        const blob = await response.blob();
        self.pdfString = URL.createObjectURL(blob);
      }
      else
      {
        self.dialogforDownload = true;
        self.imgForshow = item.attachment_file;
        self.dllink = self.imgForshow;
        self.imgName = item.filename;
      }
    },

    async handleFileSelect(event) {
      event.preventDefault();
      const file = event.target.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file)
      {
        if (file.type === "application/pdf")
        {
          pdfString = URL.createObjectURL(file);
        }
        else if (file.type.startsWith("image/"))
        {
          pdfString = URL.createObjectURL(file);
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];
          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    async handleFiledrag(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) {
        if (file.type === "application/pdf") {
          pdfString = URL.createObjectURL(file);
        } else if (file.type.startsWith("image/")) {
          const pdfBlob = await this.convertImageToPdf(file);
          pdfString = URL.createObjectURL(pdfBlob);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];

          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },
    base64ToBlob(base64String, contentType) {
      return fetch(`data:${contentType};base64,${base64String}`)
        .then((response) => response.blob())
        .catch((error) => {
          throw error;
        });
    },

    async convertImageToPdf(selectedFile) {
      const pdf = new jsPDF();
      const imgData = await this.readFileAsDataUrl(selectedFile);
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed
      const pdfData = pdf.output("blob");
      return pdfData;
    },

    readFileAsDataUrl(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    downloadImage() {
      let self = this;
      const link = document.createElement("a");
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },

    removefile(file) {
      const index = this.uploadedFiles.findIndex((item) => item.id === file.id);
      if (index !== -1)
      {
        this.uploadedFiles.splice(index, 1);
      }
      if (this.uploadedFiles.length == 0)
      {
        this.uploaddefaultshow = true;
      }
    },

    removefileEdit(file) {
      const index = this.EditOT.attachmentlist.findIndex(
        (item) => item.id === file.id
      );
      if (index !== -1)
      {
        this.EditOT.attachmentlist.splice(index, 1);
      }
      if (this.EditOT.attachmentlist.length == 0)
      {
        this.uploaddefaultshow = true;
      }
    },

  
    uploadFilesall(imgname, type, url, pdfurl) {
      let plusid = this.uploadedFiles.length;
      this.uploadedFiles.push({
        id: plusid,
        name: imgname,
        type: type,
        imgurl: url,
        pdfurl: pdfurl,
      });
      this.uploaddefaultshow = false;
    },
    fileSizeDisplay(size) {
      const kbSize = size / 1024;
      if (kbSize < 1024) {
        return kbSize.toFixed(2) + " KB";
      } else {
        return (kbSize / 1024).toFixed(2) + " MB";
      }
    },
    async getDataFromParams() {
      try {
        let notiSring = this.$route.params.notiString;
        if (notiSring) {
          this.showOT = true;
          let temp1 = {
            wF_ID: parseInt(notiSring),
          };
          const response = await axios.post(
            `${this.url}OT_Request/GetOTByWorkFlowId`,
            temp1
          );
          let temp = response.data.data[0];

          let self = this;
          self.showOT = true;
          self.OTDetail.OTrequest_ID = temp.oT_Request_ID;

          if (temp.name && temp.lastName) {
            self.OTDetail.empname = `${temp.name} ${temp.lastName}`;
          } else if (temp.requestername) {
            self.OTDetail.empname = temp.requestername;
          }

          if (temp.firstName && temp.myLastName) {
            self.OTDetail.approver = `${temp.firstName} ${temp.myLastName}`;
          } else if (temp.requestername) {
            self.OTDetail.approver = temp.approvername;
          }
          self.OTDetail.wholeday = temp.wholeDay;
          self.OTDetail.OTdate = temp.start_Date;
          self.OTDetail.OTenddateforwholeday = temp.end_Date;
          self.OTDetail.OTstart = temp.oT_Start_Time;
          self.OTDetail.OTend = temp.oT_End_Time;
          self.OTDetail.OTstartdate = temp.start_Date;
          self.OTDetail.OTenddate = temp.end_Date;
          self.OTDetail.OThours = temp.oT_Hours;
          self.OTDetail.purposeOT = temp.purpose_OT;

          self.OTDetail.status = temp.status;
          self.OTDetail.wfID = temp.wF_ID;
          self.OTDetail.attachmentlist =
            temp.attachmentlist.length == 0 ? null : temp.attachmentlist;
          self.OTDetail.position = temp.positionName.toString();
          self.OTDetail.reqDate = self.convertUTCDateToLocalDate(
            temp.oT_Requested_Date,
            0
          );
        }
      } catch (error) {
        throw error;
      }
    },
    closeNoti() {
      this.showOT = false;
      this.$router.replace({
        name: "MyOTRequest",
        params: { condition: this.$route.params.condition },
      });
    },

    dialogToggle(item) {
      let self = this;
      if (item.workFlow_Name == "OT") {
        self.DetailrequestForOT(item);
      } else {
        self.DetailTimeOff(item);
      }
    },

    DetailrequestForOT(temp) {
      let self = this;
      self.showOT = true;
      self.OTDetail.OTrequest_ID = temp.oT_Request_ID;

      if (temp.name && temp.lastName) {
        self.OTDetail.empname = `${temp.name} ${temp.lastName}`;
      } else if (temp.requestername) {
        self.OTDetail.empname = temp.requestername;
      }

      if (temp.firstName && temp.myLastName) {
        self.OTDetail.approver = `${temp.firstName} ${temp.myLastName}`;
      } else if (temp.requestername) {
        self.OTDetail.approver = temp.approvername;
      }
      self.OTDetail.wholeday = temp.wholeDay;
      self.OTDetail.OTdate = temp.start_Date;
      self.OTDetail.OTenddateforwholeday = temp.end_Date;
      self.OTDetail.OTstart = temp.oT_Start_Time;
      self.OTDetail.OTend = temp.oT_End_Time;
      self.OTDetail.OTstartdate = temp.start_Date;
      self.OTDetail.OTenddate = temp.end_Date;
      self.OTDetail.OThours = temp.oT_Hours;
      self.OTDetail.purposeOT = temp.purpose_OT;

      self.OTDetail.status = temp.status;
      self.OTDetail.wfID = temp.wF_ID;
    },

    async sendNotification() {
      let self = this;
      let TempReportID = 0;
      await this.Getapprovername();
      var username = `${self.approverlist[0].name} ${self.approverlist[0].lastName}`;
      var reportID = self.approverlist[0].report_ID;
      self.username = username;
      self.reportID = reportID;
      TempReportID = reportID;
      if (TempReportID) {
        let temp = {
          user_ID: TempReportID,
        };
        let token = [];
        await axios
          .post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`, temp)
          .then(function (response) {
            var temp = response.data.data;
            if (temp[0]) {
              temp.map((t) => token.push(t.fireBase_Token));
            }
          });
        self.tokenList = token;
      }
    },

    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "OT Request",
        body: "OT request from " + self.username,
        reportID: recipientID,
      };
      await axios
        .post(`${this.url}Firebasetoken/sendNotification`, noti_temp)
        .then(function () {});
    },

    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await axios
        .post(`${self.url}OT_Request/GetworkFlowOfOTRequest`, temp)
        .then(function (response) {
          if (response) {
            let WF_ID = response.data.data.wF_ID;
            let senderID = response.data.data.requester_ID;
            let recipientID = response.data.data.approver_ID;
            self.newnotiList(WF_ID, senderID, recipientID);
          }
        });
    },

    async newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `requested for OT`,
      };
      await axios
        .post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.pushNotification(recipientID);
          }
        });
    },

    ChangeMonth() {
      let self = this;
      // let d = new Date();
      // d.setMonth(mth - 1);
      // let year = self.todayyears.getFullYear();
      // d.setFullYear(year);
      // self.focus = d;
      self.Getrequest();
    },
    changeType() {
      let self = this;
      self.page = 1;
      self.Getrequest();
    },
    onPageChanged(newPage) {
      this.page = newPage;
      this.Getrequest();
    },

    onItemsPerPageChanged(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.page = 1;
      this.Getrequest();
    },
    closerequestdialog() {
      let self = this;
      self.OTapprovedialog = false;
      self.requestOT.wholeday = false;
      self.requestOT.OTstartdate = "";
      self.requestOT.OTenddate = "";
      self.requestOT.OTdate = "";
      self.requestOT.OTstart = "";
      self.requestOT.OTend = "";
      self.requestOT.OThours = "";
      self.requestOT.purposeOT = null;

      self.showandhide = true;
      this.$refs.formSent.reset();
    },
    ChangeYear(year) {
      let self = this;
      let d = new Date();
      d.setMonth(self.todaymonths - 1);
      let y = year.getFullYear();
      d.setFullYear(y);
      self.focus = d;
      self.Getrequest();
    },
    formatDate(date) {
      if (!date) {
        return null;
      } else {
        let finaldate = date.slice(0, 10).split("-");
        return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
      }
    },

    myformatdate(date) {
      if (!date) {
        return null;
      } else {
        let finaldate = date.slice(0, 10).split("-");
        return finaldate[0] + "/" + finaldate[1] + "/" + finaldate[2];
      }
    },

    convertUTCDateToLocalDate(date, num) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);

      let output = null;

      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }
      if (num == 0) {
        return output;
      } else if (num == 1) {
        return (
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear()
        );
      }
    },

    async Getapprovername() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}PersonalInfo/GetShortInfo`, temp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.approverlist = response.data.data;
          self.requestOT.approver = self.approverlist[0].reportName;
          self.requestOT.empname = self.approverlist[0].name + " " + self.approverlist[0].lastName;
          self.requestOT.approverID = self.approverlist[0].report_ID; 
        }
      });
    },

    DateTimeDifference(starttime, endtime) {
      // Convert time strings to Date objects
      const time1 = new Date(starttime);
      const time2 = new Date(endtime);
      // Calculate the time difference in milliseconds
      let timeDiff = time2 - time1;
      let returndata = "";
      if (timeDiff <= 0) {
        return "00minutes";
      }
      // Convert milliseconds to hours and minutes
      const hours = Math.floor(timeDiff / (60 * 60 * 1000));
      const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));
      if (hours != 0) {
        returndata += hours + " hours";
      }
      if (minutes != 0) {
        returndata += " " + minutes + " minutes";
      }
      return returndata;
    },

    changetimeofview(start, end) {
      let self = this;
      var otstartdate = "";
      var otenddate = "";
      let start_Time = moment(start, "h:mma");
      let end_time = moment(end, "h:mma");
      if (!self.EditOT.wholeday) {
        otstartdate = self.EditOT.OTdate;
        otenddate = self.EditOT.OTenddateforwholeday;
      } else {
        otstartdate = self.EditOT.OTstartdate;
        otenddate = self.EditOT.OTenddate;
      }
      if (end_time.isBefore(start_Time)) {
        // self.EditOT.OThours = " 00 minutes";
        if (otstartdate != "" && otenddate != "") {
          end_time.add(1, "d");
          var startday = moment(self.myformatdate(otstartdate), "YYYY-MM-DD");
          var endday = moment(self.myformatdate(otenddate), "YYYY-MM-DD");
          var differentdayforpmtoam = parseInt(endday.diff(startday, "days"));
          var duration = moment.duration(end_time.diff(start_Time));
          var hourforpmtoam = duration.hours();
          var mmforpmtoam = duration.minutes();
          var totalhourforpmtoam = hourforpmtoam * differentdayforpmtoam;
          var totalminuteforpmam = mmforpmtoam * differentdayforpmtoam;
          var gethourforpmtoam = 0;
          var getminuteforpmtoam = 0;
          if (totalminuteforpmam >= 60) {
            var gethourandminuteforpmtoam =
              self.timeConvert(totalminuteforpmam);
            gethourforpmtoam = parseInt(
              gethourandminuteforpmtoam.split("hour")[0]
            );
            getminuteforpmtoam = parseInt(
              gethourandminuteforpmtoam.split("hour")[1]
            );
          }
          if (gethourforpmtoam != 0 || getminuteforpmtoam != 0) {
            totalhourforpmtoam = totalhourforpmtoam + gethourforpmtoam;
            totalminuteforpmam = getminuteforpmtoam;
          }
          if (totalminuteforpmam < 10) {
            totalminuteforpmam = "0" + totalminuteforpmam;
          }
          if (totalhourforpmtoam == 0) {
            self.EditOT.OThours = totalminuteforpmam + "minutes";
          } else if (totalminuteforpmam == 0) {
            self.EditOT.OThours = totalhourforpmtoam + " " + "hours";
          } else {
            self.EditOT.OThours =
              totalhourforpmtoam +
              " " +
              "hours" +
              " " +
              totalminuteforpmam +
              " " +
              "minutes ";
          }
        }
      } else {
        if (otstartdate != "" && otenddate != "") {
          if (start != "" && end != "") {
            let from = new Date();
            let to = new Date();
            let [frhours, frminutes] = start.split(":");
            let [tohours, tominutes] = end.split(":");
            from.setHours(+frhours);
            from.setMinutes(frminutes);
            to.setHours(+tohours);
            to.setMinutes(tominutes);
            let frtimezone = from.getTimezoneOffset() * 60000;
            let totimezone = to.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let tonewDate = new Date(to.getTime() - totimezone);
            var timeDiff = Math.abs(frnewDate - tonewDate);
            var a = moment(self.myformatdate(otstartdate), "YYYY-MM-DD");
            var b = moment(self.myformatdate(otenddate), "YYYY-MM-DD");
            var differentday = parseInt(b.diff(a, "days")) + 1;
            var hh = Math.floor(timeDiff / 1000 / 60 / 60);
            timeDiff -= hh * 1000 * 60 * 60;
            var mm = Math.floor(timeDiff / 1000 / 60);
            if (!self.EditOT.wholeday) {
              if (mm < 10) {
                mm = "0" + mm;
              }
              if (hh == "00") {
                self.EditOT.OThours = mm + "minutes";
              } else if (mm == "00") {
                self.EditOT.OThours = hh + " " + "hours";
              } else {
                self.EditOT.OThours =
                  hh + " " + "hours" + " " + mm + " " + "minutes ";
              }
            } else {
              var totalhour = hh * differentday;
              var totalminute = mm * differentday;
              var gethour = 0;
              var getminute = 0;
              if (totalminute >= 60) {
                var gethourandminute = self.timeConvert(totalminute);
                gethour = parseInt(gethourandminute.split("hour")[0]);
                getminute = parseInt(gethourandminute.split("hour")[1]);
              }
              if (gethour != 0 || getminute != 0) {
                totalhour = totalhour + gethour;
                totalminute = getminute;
              }
              if (totalminute < 10) {
                totalminute = "0" + totalminute;
              }
              if (totalhour == 0) {
                self.EditOT.OThours = totalminute + "minutes";
              } else if (totalminute == 0) {
                self.EditOT.OThours = totalhour + " " + "hours";
              } else {
                self.EditOT.OThours =
                  totalhour +
                  " " +
                  "hours" +
                  " " +
                  totalminute +
                  " " +
                  "minutes ";
              }
            }
          }
        }
        else
        {
          alert("Please Fill the Date first");
        }
      }
    },

    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hour " + rminutes;
    },

    async RequestedOT() {
      let self = this;
      self.LoadingDialog = true;
      if (self.requestOT.approver != "")
      {
        self.requestOT.OTdate = self.requestOT.OTstartdate;
        self.error.otType = !self.requestOT.otType ? " " + self.$t("PleaseSpecify") : "";
        let myDate = new Date(self.requestOT.OTstartdate);
        self.mystartdate =
          myDate.getFullYear() +
          "-" +
          ("0" + (myDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + myDate.getDate()).slice(-2) +
          "T" +
          "00" +
          ":" +
          "00" +
          ":" +
          "00" +
          "." +
          "000" +
          "Z";
        // self.mystartdate = self.requestOT.OTstartdate;
        let myEndDate = new Date(self.requestOT.OTenddate);
        self.myenddate =
          myEndDate.getFullYear() +
          "-" +
          ("0" + (myEndDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + myEndDate.getDate()).slice(-2) +
          "T" +
          "00" +
          ":" +
          "00" +
          ":" +
          "00" +
          "." +
          "000" +
          "Z";

        // self.myenddate = self.requestOT.OTenddate;
        let myattachments = [];
        if (self.uploadedFiles.length != 0)
        {
          self.uploadedFiles.map(function (data) {
            myattachments.push({
              attachment_file: data.imgurl,
              filename: data.name,
              filetype: data.type,
            });
          });
        }
        self.OTstartdateSent = !self.requestOT.OTstartdate ? "OT Start Date is required" : "";
        self.OTenddateSent = !self.requestOT.OTenddate ? "OT End Date is required" : "";
        self.OTstartSent = [(v) => !!v || "OT Start Time is required"];
        self.OTendSent = [(v) => !!v || "OT End Time Date is required"];
        self.OThoursSent = [(v) => !!v || "OT Hours is required"];
        self.purposeOTSent = [(v) => !!v || "Purpose Of OT is required"];
        self.otTypesent = [(v) => !!v || "OT Type of OT is required"];
        let tempvalidate = self.$refs.formSent.validate();
        if ((tempvalidate || !tempvalidate) &&
          self.requestOT.empname != "" &&
          self.mystartdate != "" &&
          self.myenddate != "" &&
          self.requestOT.OTstart != null &&
          self.requestOT.OTend != null &&
          self.requestOT.OThours != null &&
          self.requestOT.OTstart != "" &&
          self.requestOT.OTend != "" &&
          self.requestOT.OThours != "" &&
          self.requestOT.purposeOT != null &&
          self.requestOT.purposeOT != "" &&
          self.requestOT.approver != null &&
          self.requestOT.approver != "" &&
          self.requestOT.otType != null &&
          self.requestOT.otType != "")
        {
          if (self.requestOT.OThours != " 00 minutes")
          {
            if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day"))
            {
              if (self.requestOT.maxHour <= 8 && self.requestOT.maxHour > -1 && self.requestOT.maxMinute > -1)
              {
                if (self.requestOT.maxHour == 8 && self.requestOT.maxMinute > 0)
                {
                  self.LoadingDialog = false;
                  alert("Maximum Overtime limit is 8 hours");
                }
                else
                {
                  let validate = {
                    start_Date: self.mystartdate,
                    end_Date: self.myenddate,
                    oT_Start_Time: self.requestOT.OTstart,
                    oT_End_Time: self.requestOT.OTend,
                    company_ID: store.state.companyID,
                    employee_ID: store.state.employeeID,
                  };
                  axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
                  .then(function (response)
                  {
                    if (response.data.status == 0)
                    {
                      // if (response.data.message == "Successfully")
                      // {
                      const currentDateTime = new Date();
                      const formattedDateTime = currentDateTime.toISOString();
                      let temp = {
                        requestedDate: formattedDateTime,
                        wholeDay: self.requestOT.wholeday,
                        start_Date: self.mystartdate,
                        end_Date: self.myenddate,
                        oT_Start_Time: self.requestOT.OTstart,
                        oT_End_Time: self.requestOT.OTend,
                        oT_Hours: self.requestOT.OThours,
                        purpose_OT: self.requestOT.purposeOT,
                        saveDraft: false,
                        requester_ID: store.state.employeeID,
                        approver_ID: self.requestOT.approverID,
                        company_ID: store.state.companyID,
                        attachments: myattachments,
                        oT_Type: self.requestOT.otType,
                      };
                      axios.post(`${self.url}OT_Request/StaffOT_Request`, temp)
                      .then(async function (response) {
                        if (response.data.status == 0)
                        {
                          alert(response.data.message);
                          self.showreq = false;
                          await self.getLatestData();
                          self.Getrequest();
                          self.OTapprovedialog = false;
                          self.showandhide = true;
                          self.requestOT.wholeday = false;
                          self.requestOT.OTdate = "";
                          self.requestOT.OTendddateforwholeday = "";
                          self.requestOT.OTstartdate = "";
                          self.requestOT.OTenddate = "";
                          self.requestOT.OTstart = "";
                          self.requestOT.OTend = "";
                          self.requestOT.OThours = null;
                          self.requestOT.purposeOT = null;
                          self.uploadedFiles = [];
                          self.LoadingDialog = false;
                          self.ReportTrace(5);
                        }
                        else
                        {
                          self.LoadingDialog = false;
                          alert("Please recheck the date since there seems to be a clash with another request’ for clash with duplicated ot request");
                        }
                      })
                      .catch(function (error) {
                          self.LogTrace(
                            error,
                            "Add OT request Fail",
                            24,
                            "Low"
                          );
                          alert(error);
                          self.LoadingDialog = false;
                        });
                    }
                    else
                    {
                      self.LoadingDialog = false;
                      alert("You have either not been assigned your shift yet or the OT time you requested clashes with your regular shift time. Please check");
                    }
                  });
                }
              }
              else
              {
                self.LoadingDialog = false;
                alert("Maximum Overtime limit is 8 hours");
              }
            }
            else
            {
              self.LoadingDialog = false;
              alert("Start date can’t be after end date");
            }
          }
          else
          {
            alert("Start time can't be after end time");
            self.LoadingDialog = false;
          }
        }
        else
        {
          self.LoadingDialog = false;
          alert("Please fill all the fields");
        }
      }
      else
      {
        self.LoadingDialog = false;
        alert("Please fill Report to Field first in Personal Profile");
      }
    },

    SavedraftOT() {
      let self = this;
      self.LoadingDialog = true;
      if (self.requestOT.approver != "")
      {
        // if (self.requestOT.wholeday == false) {
        //   self.mystartdate = self.requestOT.OTdate;
        //   self.myenddate = self.requestOT.OTendddateforwholeday;
        //   self.requestOT.OTstartdate = self.requestOT.OTdate;
        //   self.requestOT.OTenddate = self.requestOT.OTendddateforwholeday;
        // } else {
        self.requestOT.OTdate = self.requestOT.OTstartdate;
        let myDate = new Date(self.requestOT.OTstartdate);
        self.mystartdate =
          myDate.getFullYear() +
          "-" +
          ("0" + (myDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + myDate.getDate()).slice(-2) +
          "T" +
          "00" +
          ":" +
          "00" +
          ":" +
          "00" +
          "." +
          "000" +
          "Z";
        // self.mystartdate = self.requestOT.OTstartdate;
        let myEndDate = new Date(self.requestOT.OTenddate);
        self.myenddate =
          myEndDate.getFullYear() +
          "-" +
          ("0" + (myEndDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + myEndDate.getDate()).slice(-2) +
          "T" +
          "00" +
          ":" +
          "00" +
          ":" +
          "00" +
          "." +
          "000" +
          "Z";
        //}
        let myattachments = [];
        if (self.uploadedFiles.length != 0) {
          self.uploadedFiles.map(function (data) {
            myattachments.push({
              attachment_file: data.imgurl,
              filename: data.name,
              filetype: data.type,
            });
          });
        }
        self.OTdateSent = [(v) => !!v || "OT Date is required"];
        self.OTendddateforwholeday = [(v) => !!v || "OT End Date is required"];
        self.OTstartdateSent = !self.requestOT.OTstartdate ? "OT Start Date is required" : "";
        self.OTenddateSent = !self.requestOT.OTenddate ? "OT End Date is required" : "";
        self.OTstartSent = [(v) => !!v || "OT Start Time is required"];
        self.OTendSent = [(v) => !!v || "OT End Time Date is required"];
        self.OThoursSent = [(v) => !!v || "OT Hours is required"];
        self.purposeOTSent = [(v) => !!v || "Purpose Of OT is required"];
        self.otTypesent = [(v) => !!v || "OT Type of OT is required"];

        let tempvalidate = self.$refs.formSent.validate();
        if (
          (tempvalidate || !tempvalidate) &&
          self.requestOT.empname != "" &&
          self.mystartdate != "" &&
          self.myenddate != "" &&
          self.requestOT.OTstart != null &&
          self.requestOT.OTstart != "" &&
          self.requestOT.OTend != null &&
          self.requestOT.OTend != "" &&
          self.requestOT.OThours != null &&
          self.requestOT.OThours != "" &&
          self.requestOT.purposeOT != null &&
          self.requestOT.purposeOT != "" &&
          self.requestOT.approver != null &&
          self.requestOT.approver != "" &&
          self.requestOT.otType != null &&
          self.requestOT.otType != ""
        )
        {
          if (self.requestOT.OThours != " 00 minutes")
          {
            if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day"))
            {
              if (
                self.requestOT.maxHour <= 8 &&
                self.requestOT.maxHour > -1 &&
                self.requestOT.maxMinute > -1
              )
              {
                if (self.requestOT.maxHour == 8 && self.requestOT.maxMinute > 0)
                {
                  self.LoadingDialog = false;
                  alert("Maximum Overtime limit is 8 hours");
                }
                else
                {
                  let validate = {
                    start_Date: self.mystartdate,
                    end_Date: self.myenddate,
                    oT_Start_Time: self.requestOT.OTstart,
                    oT_End_Time: self.requestOT.OTend,
                    company_ID: store.state.companyID,
                    employee_ID: store.state.employeeID,
                  };
                  axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
                    .then(function (response) {
                      if (response.data.status == 0)
                      {
                        if (response.data.message == "Successfully")
                        {
                          let temp = {
                            wholeDay: self.requestOT.wholeday,
                            start_Date: self.mystartdate,
                            end_Date: self.myenddate,
                            oT_Start_Time: self.requestOT.OTstart,
                            oT_End_Time: self.requestOT.OTend,
                            oT_Hours: self.requestOT.OThours,
                            purpose_OT: self.requestOT.purposeOT,
                            saveDraft: true,
                            requester_ID: store.state.employeeID,
                            approver_ID: self.requestOT.approverID,
                            company_ID: store.state.companyID,
                            attachments: myattachments,
                            oT_Type: self.requestOT.otType,
                          };
                          axios
                            .post(`${self.url}OT_Request/StaffOT_Request`, temp)
                            .then(function (response) {
                              if (response.data.status == 0) {
                                if (
                                  response.data.message ==
                                  "Posted invalid data."
                                ) {
                                  self.LoadingDialog = false;
                                  alert(
                                    "Please recheck the date since there seems to be a clash with another request"
                                  );
                                }
                                if (
                                  response.data.message == "Add Successfully"
                                ) {
                                  alert(response.data.message);
                                  self.showreq = false;
                                  self.LoadingDialog = false;
                                  self.Getrequest();
                                  self.OTapprovedialog = false;
                                  self.requestOT.wholeday = false;
                                  self.showandhide = true;
                                  self.requestOT.OTdate = "";
                                  self.requestOT.OTstartdate = "";
                                  self.requestOT.OTenddate = "";
                                  self.requestOT.OTstart = "";
                                  self.requestOT.OTend = "";
                                  self.requestOT.OThours = null;
                                  self.requestOT.purposeOT = null;
                                  //myattachments=null;
                                  self.uploadedFiles = [];
                                }
                              }
                            });
                        }
                        else
                        {
                          self.LoadingDialog = false;
                          alert("You have either not been assigned your shift yet or the OT time you requested clashes with your regular shift time. Please check");
                        }
                      }
                    });
                }
              }
              else
              {
                self.LoadingDialog = false;
                alert("Maximum Overtime limit is 8 hours");
              }
            }
            else
            {
              alert("Start date can't be after end date");
              self.LoadingDialog = false;
            }
          }
          else
          {
            alert("Start time can't be after end time");
            self.LoadingDialog = false;
          }
        }
        else
        {
          alert("Please fill all the fields");
          self.LoadingDialog = false;
        }
      }
      else
      {
        self.LoadingDialog = false;
        alert("Please fill Report to Field first in Personal Profile");
      }
    },

    showstartdate() {
      let self = this;
      if (!self.requestOT.wholeday)
      {
        self.showandhide = false;
      }
      else
      {
        self.showandhide = true;
      }
    },

    showeditdate() {
      let self = this;
      if (!self.EditOT.wholeday)
      {
        self.editshowandhide = false;
      }
      else
      {
        self.editshowandhide = true;
      }
    },

    showdetaildata() {
      let self = this;
      self.Editstate = true;
      self.disableviewdata = false;
      self.hideeditbtn = true;
      self.showeditbtn = false;
      self.disablesendbtn = true;
      self.disalbecanclebtn = true;
    },

    Getrequest() {
      let self = this;
      self.LoadingDialog = true;
      let myyear = self.todayyears;
      let y = myyear.getFullYear();
      let thisyear = "";
      if (self.todaymonths.val == undefined)
      {
        thisyear = self.todaymonths;
      }
      else
      {
        thisyear = self.todaymonths.val;
      }
      let temp = {
        month_Num: thisyear,
        year_Num: y,
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
        status : self.itemInner,
        page: self.page,
        perpage: self.itemsPerPage,
      };
      axios.post(`${self.url}OT_Request/GetOT_RequestForspecificPagination`, temp)
      .then(function (response) {
        if (response.data.status === 0)
        {
          let filterList = response.data.data.result;
          self.pageCount = response.data.data.totalCount / self.itemsPerPage;
            if (self.pageCount % 1 != 0)
            {
              self.pageCount = parseInt(self.pageCount + 1);
            }
            else
            {
              self.pageCount;
            }
          self.requestedlist = filterList;
          self.LoadingDialog = false;
          //self.LogTrace(null, "Get OT request", 24, "Critical");
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Get OT request Fail", 24, "Critical");
        alert(error);
        self.LoadingDialog = false;
      });
    },

    GetEmpType() {
      let self = this;
      const emptype = sessionStorage.getItem("employeeID");
      const empTypeID = parseInt(emptype);
      let request = {
        employee_ID: empTypeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Salary/GetSalaryInfo`, request)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.salarylist = response.data.data;
          self.emptype = self.salarylist[0].employmentTypeID == 1 ? self.$t("Monthly Payment") : self.$t("Daily Payment");
        }
      });
    },

    hidedetaildialog() {
      let self = this;
      self.viewdialog = false;
      self.hideeditbtn = false;
      self.disablesendbtn = false;
      self.disalbecanclebtn = false;
      self.disableviewdata = true;
      self.Editstate = false;
    },

    convertUTCDateToLocalDateForReq(date) {
      date = new Date(date);
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = null;
      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }
      return output;
    },

    convertUTCDateToLocalDateForReqfornew() {
      let date = new Date();
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = null;
      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }
      return output;
    },

    Detailrequest(temp) {
      let self = this;
      self.Olddata = temp;
      self.viewdialog = true;
      self.uploadedFiles = [];
      self.EditOT.attachmentlist = [];
      let alllist = temp.attachmentlist;
      for (let i = 0; i < alllist.length; i++) {
        let templist = {
          ...alllist[i],
          id: i,
        };
        self.EditOT.attachmentlist.push(templist);
      }
      self.EditOT.attachmentlist = temp.attachmentlist;
      self.EditOT.OTrequest_ID = temp.oT_Request_ID;
      self.EditOT.positionname = temp.positionname;
      self.EditOT.RequestedDate = self.convertUTCDateToLocalDateForReq(temp.requested_Date);
      if (temp.saveDraft)
      {
        self.showeditbtn = false;
      }
      else
      {
        self.showeditbtn = true;
      }
      self.EditOT.empname = temp.requestername;
      self.EditOT.wholeday = temp.wholeDay;
      if (temp.wholeDay)
      {
        self.editshowandhide = false;
        let date1 = new Date(temp.start_Date);
        let plusday1 = date1.getDate() + 1;
        date1.setDate(plusday1);
        self.EditOT.OTdate = new Date(date1).toISOString().substr(0, 10);
        self.viewOtstart = temp.oT_Start_Time;
        self.viewOtend = temp.oT_End_Time;
      }
      else
      {
        self.editshowandhide = true;
        let date1 = new Date(temp.start_Date);
        let date2 = new Date(temp.end_Date);
        let plusday1 = date1.getDate() + 1;
        date1.setDate(plusday1);
        let plusday2 = date2.getDate() + 1;
        date2.setDate(plusday2);
        self.EditOT.OTdate = new Date(date1).toISOString().substr(0, 10);
        self.EditOT.OTenddateforwholeday = new Date(date2)
          .toISOString()
          .substr(0, 10);
        self.viewOtstart = temp.oT_Start_Time;
        self.viewOtend = temp.oT_End_Time;
      }
      let date1 = new Date(temp.start_Date);
      let plusday1 = date1.getDate() + 1;
      date1.setDate(plusday1);
      let date2 = new Date(temp.end_Date);
      let plusday2 = date2.getDate() + 1;
      date2.setDate(plusday2);
      self.ottypefordetail = [];
      let frotemp = self.ottype;
      self.ottypefordetail.push(frotemp[0]);
      self.ottypefordetail.push(frotemp[1]);
      self.ottypefordetail.push(frotemp[2]);
      let findval = frotemp.find((o) => o.txt === temp.oT_Type);
      if (findval != null || findval == undefined)
      {
        let toadd = { val: temp.oT_Type == "OT1" ? 1 : 3, txt: temp.oT_Type };
        self.ottypefordetail.push(toadd);
        self.ottypefordetail.sort(function (a, b) {
          return a.val - b.val;
        });
      }
      self.EditOT.OTType = temp.oT_Type == null ? "OT2" : temp.oT_Type;
      //self.EditOT.OTstartdatetime = self.ConvertISOdatetime(temp.start_Date,temp.oT_Start_Time);
      //self.EditOT.OTenddatetime = self.ConvertISOdatetime(temp.end_Date,temp.oT_End_Time);
      let localstartdate = ConverttolocalwithStroke(temp.start_Date);
      let localenddate = ConverttolocalwithStroke(temp.end_Date);
      self.EditOT.OTstartdatetime = new Date(localstartdate);
      self.EditOT.OTenddatetime = new Date(localenddate);
      self.EditOT.OTstartdate = new Date(date1).toISOString().substr(0, 10);
      self.EditOT.OTenddate = new Date(date2).toISOString().substr(0, 10);
      self.EditOT.OThours = temp.oT_Hours;
      self.EditOT.purposeOT = temp.purpose_OT;
      self.EditOT.approver = temp.approvername;
      self.EditOT.status = temp.status;
    },

    ConvertISOdatetime(date, time) {
      var dateObj = new Date(date);
      var [hours, minutes] = time.split(":");
      dateObj.setHours(dateObj.getHours() + parseInt(hours, 10));
      dateObj.setMinutes(dateObj.getMinutes() + parseInt(minutes, 10));
      return dateObj;
    },

    Gobackorignial() {
      let self = this;
      // self.Olddata=self.Olddata;
      self.viewdialog = true;
      self.Editstate = false;
      self.disableviewdata = true;
      self.EditOT.OTrequest_ID = self.Olddata.oT_Request_ID;
      self.EditOT.positionname = self.Olddata.positionname;
      self.EditOT.RequestedDate = self.convertUTCDateToLocalDateForReq(self.Olddata.requested_Date);
      if (self.Olddata.saveDraft)
      {
        self.showeditbtn = false;
      }
      else
      {
        self.showeditbtn = true;
      }
      self.EditOT.empname = self.Olddata.requestername;
      self.EditOT.wholeday = self.Olddata.wholeDay;
      if (self.Olddata.wholeDay)
      {
        self.editshowandhide = false;
        let date1 = new Date(self.Olddata.start_Date);
        let plusday1 = date1.getDate() + 1;
        date1.setDate(plusday1);
        self.EditOT.OTdate = new Date(date1).toISOString().substr(0, 10);
        self.viewOtstart = self.Olddata.oT_Start_Time;
        self.viewOtend = self.Olddata.oT_End_Time;
      }
      else
      {
        self.editshowandhide = true;
        let date1 = new Date(self.Olddata.start_Date);
        let date2 = new Date(self.Olddata.end_Date);
        let plusday1 = date1.getDate() + 1;
        date1.setDate(plusday1);
        let plusday2 = date2.getDate() + 1;
        date2.setDate(plusday2);
        self.EditOT.OTdate = new Date(date1).toISOString().substr(0, 10);
        self.EditOT.OTenddateforwholeday = new Date(date2)
          .toISOString()
          .substr(0, 10);
        self.viewOtstart = self.Olddata.oT_Start_Time;
        self.viewOtend = self.Olddata.oT_End_Time;
      }
      let date1 = new Date(self.Olddata.start_Date);
      let plusday1 = date1.getDate() + 1;
      date1.setDate(plusday1);
      let date2 = new Date(self.Olddata.end_Date);
      let plusday2 = date2.getDate() + 1;
      date2.setDate(plusday2);
      self.EditOT.OTstartdate = new Date(date1).toISOString().substr(0, 10);
      self.EditOT.OTenddate = new Date(date2).toISOString().substr(0, 10);
      self.EditOT.OThours = self.Olddata.oT_Hours;
      self.EditOT.purposeOT = self.Olddata.purpose_OT;
      self.EditOT.approver = self.Olddata.approvername;
      self.EditOT.status = self.Olddata.status;
      self.errorOTtime = "";
    },

    isDateTimeGreaterThan8Hours(hours, minutes) {
      const inputTotalMinutes = hours * 60 + minutes;
      // Calculate the total minutes for 8 hours
      const eightHoursTotalMinutes = 8 * 60;
      // Check if the input time is greater than 8 hours
      return inputTotalMinutes > eightHoursTotalMinutes;
    },

    EditOTrequest(v) {
      let self = this;
      self.LoadingDialog = true;
      self.EditOT.OTstart = self.viewOtstart;
      self.EditOT.OTend = self.viewOtend;
      if (!self.EditOT.wholeday)
      {
        self.mystartdate = self.EditOT.OTdate;
        self.myenddate = self.EditOT.OTenddateforwholeday;
      }
      else
      {
        self.mystartdate = self.EditOT.OTstartdate;
        self.myenddate = self.EditOT.OTenddate;
      }
      self.purposeOTEdit = [(v) => !!v || "Purpose Of OT is required"];
      var draft = false;
      if (v == 1)
      {
        draft = true;
      }
      else
      {
        draft = false;
      }
      let myattachments = [];
      if (self.uploadedFiles.length != 0)
      {
        self.uploadedFiles.map(function (data) {
          myattachments.push({
            attachment_file: data.imgurl,
            filename: data.name,
            filetype: data.type,
          });
        });
      }
      if (self.EditOT.attachmentlist != 0)
      {
        self.EditOT.attachmentlist.map(function (data) {
          myattachments.push({
            attachment_file: data.attachment_file,
            filename: data.filename,
            filetype: data.filetype,
          });
        });
      }
      const match = self.EditOT.OThours.split(" ");
      // Extract hours and minutes from the matched groups
      let minutes = 0;
      let hours = 0;
      if (match != null)
      {
        if (match.length > 2)
        {
          hours = parseInt(match[0]);
          minutes = parseInt(match[2]);
        }
        else if (match[1] === "hours")
        {
          hours = parseInt(match[0]);
        }
        else
        {
          minutes = parseInt(match[0]);
        }
      }
      self.errorOTtime = self.EditOT.OThours == "00minutes" ? "Invalid OT Time" : self.isDateTimeGreaterThan8Hours(hours, minutes) ? "OT Time is not grather than 8 hours" : "";
      //let tempvalidate = self.$refs.formEdit.validate();
      if (
        // tempvalidate == true &&
        self.EditOT.empname != "" &&
        self.mystartdate != "" &&
        self.myenddate != "" &&
        self.EditOT.OTstart != "" &&
        self.EditOT.OTend != "" &&
        self.EditOT.OThours != "" &&
        self.EditOT.purposeOT != "" &&
        self.EditOT.purposeOT != null &&
        self.EditOT.approver != null &&
        self.EditOT.OTType != null &&
        self.EditOT.OTType != "" &&
        self.EditOT.OTstartdatetime != null &&
        self.EditOT.OTstartdatetime != "" &&
        self.EditOT.OTenddatetime != null &&
        self.EditOT.OTenddatetime != "" &&
        self.EditOT.OThours != "00minutes" &&
        self.errorOTtime == ""
      )
      {
        if (self.EditOT.OThours != " 00 minutes")
        {
          // if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day"))
          if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day"))
          {
            let validate = {
              start_Date: self.mystartdate,
              end_Date: self.myenddate,
              oT_Start_Time: self.EditOT.OTstart,
              oT_End_Time: self.EditOT.OTend,
              company_ID: store.state.companyID,
              employee_ID: store.state.employeeID,
            };
            axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
            .then(function (response) {
              if (response.data.status == 0)
              {
                // if (response.data.message == "Successfully")
                // {
                  let temp = {
                    wholeDay: self.EditOT.wholeday,
                    oT_Request_ID: self.EditOT.OTrequest_ID,
                    start_Date: self.mystartdate,
                    end_Date: self.myenddate,
                    oT_Start_Time: self.EditOT.OTstart,
                    oT_End_Time: self.EditOT.OTend,
                    oT_Hours: self.EditOT.OThours,
                    purpose_OT: self.EditOT.purposeOT,
                    saveDraft: draft,
                    requester_ID: store.state.employeeID,
                    attachments: myattachments,
                    company_ID: store.state.companyID,
                  };
                  axios.post(`${self.url}OT_Request/UpdateOT_Request`, temp)
                  .then(function (response) {
                    if (response.data.status == 0)
                    {
                      alert(response.data.message);
                      self.viewdialog = false;
                      self.LoadingDialog = false;
                      self.Getrequest();
                      self.hideeditbtn = false;
                      self.disablesendbtn = false;
                      self.disalbecanclebtn = false;
                      self.disableviewdata = true;
                    }
                    else
                    {
                      self.LoadingDialog = false;
                      alert("Please recheck the date since there seems to be a clash with another request’ for clash with duplicated ot request");
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(error,"Update OT Request Fail",24,"Medium");
                    alert(error);
                    self.LoadingDialog = false;
                  });
              }
              else
              {
                alert("You have either not been assigned your shift yet or the OT time you requested clashes with your regular shift time. Please check");
                self.LoadingDialog = false;
              }
            });
          }
          else
          {
            self.LoadingDialog = false;
            alert("Start date can’t be after end date");
          }
        }
        else
        {
          alert("Start time can't be after end time");
          self.LoadingDialog = false;
        }
      }
      else
      {
        alert("Please fill all the fields");
        self.LoadingDialog = false;
      }
    },

    SendOTrequest() {
      let self = this;
      if (!self.EditOT.wholeday)
      {
        self.mystartdate = self.EditOT.OTdate;
        self.myenddate = self.EditOT.OTenddateforwholeday;
        self.EditOT.OTstart = "0";
        self.EditOT.OTend = "0";
      }
      else
      {
        self.mystartdate = self.EditOT.OTstartdate;
        self.myenddate = self.EditOT.OTenddate;
        self.EditOT.OTstart = "0";
        self.EditOT.OTend = "0";
      }
      if (self.EditOT.empname != "" &&
        self.mystartdate != "" &&
        self.myenddate != "" &&
        self.viewOtstart != "" &&
        self.viewOtend != "" &&
        self.EditOT.OThours != "" &&
        self.EditOT.purposeOT != null &&
        self.EditOT.approver != null)
      {
        let temp = {
          wholeDay: self.EditOT.wholeday,
          oT_Request_ID: self.EditOT.OTrequest_ID,
          start_Date: self.mystartdate,
          end_Date: self.myenddate,
          oT_Start_Time: self.viewOtstart,
          oT_End_Time: self.viewOtend,
          oT_Hours: self.EditOT.OThours,
          purpose_OT: self.EditOT.purposeOT,
          saveDraft: false,
          requester_ID: store.state.employeeID,
          approver_ID: self.EditOT.approverID,
          company_ID: store.state.companyID,
          attachments: self.EditOT.attachmentlist,
        };
        axios.post(`${self.url}OT_Request/UpdateOT_Request`, temp)
        .then(async function (response) {
          if (response.data.status == 0)
          {
            await self.getLatestData();
            alert(response.data.message);
            self.Getrequest();
            self.viewdialog = false;
            self.hideeditbtn = false;
            self.disablesendbtn = false;
            self.disalbecanclebtn = false;
            self.disableviewdata = true;
          }
        });
      }
      else
      {
        alert("Please fill all the fields");
      }
    },

    deleteOTrequest() {
      let self = this;
      self.Deletedialog = true;
    },

    DeleteCancel() {
      let self = this;
      self.Deletedialog = false;
    },

    DeleteOTrequestlist() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        oT_Request_ID: self.EditOT.OTrequest_ID,
      };
      axios.post(`${self.url}OT_Request/DeleteOT_Request`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.Getrequest();
          self.LoadingDialog = false;
          self.Deletedialog = false;
          self.viewdialog = false;
          //self.LogTrace(null, "Delete OT request", 24, "High");
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Delete OT Request Fail", 24, "High");
        alert(error);
        self.LoadingDialog = false;
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}

.close-button {
  position: relative;
  top: -15px;
  left: 80px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: black;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip-button:hover .tooltip {
  opacity: 1;
}

.hover-effect {
  position: relative;
}

.hover-effect:hover .tooltip {
  opacity: 1;
}

.image-iframe {
  width: 400px;
  /* Set your desired width */
  height: 400px;
  /* Set your desired height */
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

.hoverable-icon:hover {
  color: rgb(249, 157, 32);
}

.hoverable-icon {
  color: #858585;
  cursor: pointer;
}

>>> .selectboxs {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}

>>> .v-data-table tr td {
  height: 70px;
}

>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 70px;
}

.deletepadd {
  padding: 0;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

>>> .mdi-download:hover {
  color: #f99d20;
}

>>> .mdi-menu-down::before {
  color: black;
}

>>> .v-select.v-text-field input {
  margin-left: 10px;
}

>>> .v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}

@media (max-width: 320px) {
  .minizebox {
    width: 105px;
    height: 35px;
  }
}

@media (max-width: 280px) {
  .minizebox {
    width: 80px;
    height: 35px;
  }
}
.des {
  display: flex !important;
  margin-right: -118px !important;
}

@media only screen and (min-width: 1290px) {
  .des {
    display: flex !important;
    margin-right: -44px !important;
  }
}

.readonly.v-text-field > .v-input__control > .v-input__slot {
  background-color: #eeeeee !important;
}

.infoimg {
  display: inline-block;
  width: 18px !important;
  position: relative;
  transition: transform 0.6s ease-in-out;
  margin-bottom: -4px !important;
}
@media only screen and (min-width: 1024px) {
  @media only screen and (max-width: 1024px) {
    .selectbox {
      width: 138px;
      margin: 0px;
      padding: 0px;
      border-radius: 3px;
      border-color: orange;
    }
  }
}
@media only screen and (min-width: 768px) {
  @media only screen and (max-width: 768px) {
    .selectbox {
      width: 105px !important;
      margin: 0px;
      padding: 0px;
      border-radius: 3px;
      border-color: orange;
    }
  }
}
.selectbox {
  width: 137px;
  max-width: 300px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color: orange;
}
.lessleft{
  margin-left: 100px;
  margin-top: 0px;
}
@media (max-width: 300px) {
  .lessleft {
    margin-left: 50px;
  }
}
@media (min-width: 1904px) and (max-width: 2000px) {
  .lessleft {
    margin-left: 70px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 820px) {
  .selectbox {
    width: 137.6px !important;
    margin: 0px;
    padding: 0px;
    width: 268px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media only screen and (min-width: 375px) and (max-width: 375px) {
  .selectbox {
    width: 231px !important;
    margin: 0px;
    padding: 0px;
    width: 268px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media only screen and (min-width: 412px) and (max-width: 412px) {
  .selectbox {
    width: 268px !important;
    margin: 0px;
    padding: 0px;
    width: 268px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media only screen and (min-width: 540px) and (max-width: 540px) {
  .selectbox {
    width: 396px !important;
    margin: 0px;
    padding: 0px;
    width: 268px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1280px) {
  .selectbox {
    width: 126.6px !important;
    margin: 0px;
    padding: 0px;
    width: 268px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media (min-width: 375px) and (max-width: 375px) {
  .selectbox {
    width: 231px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media (min-width: 360px) and (max-width: 360px) {
  .selectbox {
    width: 216.2px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media (min-width: 1920px) and (max-width: 1920px) {
  .selectbox {
    width: 232.2px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media (min-width: 1536px) and (max-width: 1536px) {
  .selectbox {
    width: 168.66px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media (min-width: 1680px) and (max-width: 1720px) {
  .selectbox {
    width: 197.66px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media (min-width: 1300px) and (max-width: 1400px) {
  .selectbox {
    width: 145.66px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
@media (min-width: 1129px) and (max-width: 1229px) {
  .selectbox {
    width: 188.26px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border-color: orange;
  }
}
.infoimg2 {
  display: inline-block;
  width: 15px !important;
  position: absolute;
  top: 13px;
  right: -7px;
  transition: transform 0.6s ease-in-out;
  margin-bottom: -4px !important;
}
</style>