<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="ml-4 headtext txt-header">
        {{ $t("companyprofile") }}
      </p>
    </div>
    <!-- <p class="txt-header">
        {{ $t("companyprofile") }}
      </p> -->

    <div style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-tabs class="mt-3" grey--text background-color="#f0f8fc" slider-color="#F99D20" v-model="activeTab" show-arrows>
        <v-tab class="text-capitalize" style="
            border-radius: 10px 10px 0px 5px;
            width: 150px;
            margin-right: 3px;
            font-size: 16px;
          " v-for="tab in tabs" :key="tab.id" :to="tab.route" v-show="tab.showthis" exact>
          {{
          tab.name == "BrandIdentity" ? $t("BrandIdentity")
            : tab.name == "PayrollAccount" ? $t("PayrollAccount")
              : $t("WorkingCondition")
        }}</v-tab>
      </v-tabs>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  props: ["id"],
  data() {
    return {
      activeTab: `/organization/companyprofile/${this.id}`,
      showthis: false,
      tabs: [
        {
          id: 1,
          name: `BrandIdentity`,
          route: `/organization/companyprofile/${this.id}`,
          showthis: false,
        },
        {
          id: 2,
          name: "PayrollAccount",
          route: `/organization/companyprofile/${this.id}/payrollaccount`,
          showthis: false,
        },
        {
          id: 3,
          name: "WorkingCondition",
          route: `/organization/companyprofile/${this.id}/workingcondition`,
          showthis: false,
        },
      ],
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleid = sessionStorage.getItem("roleID");
    if (roleid == "Admin") {
      for (let j = 0; j < this.tabs.length; j++) {
        this.tabs[j].showthis = true;
      }
    } else if (roleid == "HR") {
      for (let h = 0; h < this.tabs.length; h++) {
        this.tabs[h].showthis = true;
      }
    } else {
      if (perid.some((e) => e == 116)) {
        this.tabs[0].showthis = true;
      }
      if (perid.some((e) => e == 99)) {
        this.tabs[1].showthis = true;
      }
      if (perid.some((e) => e == 228)) {
        this.tabs[2].showthis = true;
      }
    }

    if (this.$route.path == "/organization/companyprofile/companyinformation") {
      for (let j = 0; j < this.tabs.length; j++) {
        if (this.tabs[j].showthis == true) {
          this.activeTab = this.tabs[j].route;
          this.$router.push(this.tabs[j].route).catch(() => { });
          break;
        }
      }
    }
  },
};
</script>
<style scoped>
.v-tab:before {
  background: none !important;
}

.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}

.v-tabs-bar .v-tab {
  background-color: white;
}

.active {
  cursor: pointer !important;
}

.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}

>>>.v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
</style>