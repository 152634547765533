<template>
  <div>
    <div class="d-flex-row flex-wrap" style="margin: 20px; ">
      <v-list class="d-flex-row flex-wrap ml-3">
        <v-list-item-group class="d-flex-row flex-wrap" v-model="item">
          <v-list-item :ripple="false">
            <v-row>
              <v-col cols="12" lg="9" md="8" sm="7" xl="10">
                <v-list-item-title class="text-wrap"  style="margin-top: 0.4rem">
                  {{ $t("TotalIncome/Month") }}</v-list-item-title
                >
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="5" xl="2">
                <v-text-field
                  color="#ACACAC"
                  hide-details
                  readonly
                  v-model="income.totalIncomeMonth"
                  :suffix="$t('THB')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item :ripple="false">
            <v-row>
              <v-col cols="12" lg="9" md="8" sm="7" xl="10">
                <v-list-item-title class="text-wrap"  style="margin-top: 0.4rem">{{
                  $t("TotalIncome/year")
                }}</v-list-item-title>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="5" xl="2">
                <v-text-field
                  color="#ACACAC"
                  hide-details
                  readonly
                  v-model="income.totalIncomeYear"
                  :suffix="$t('THB')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item :ripple="false">
            <v-row>
              <v-col cols="12" lg="9" md="8" sm="7" xl="10">
                <v-list-item-title class="text-wrap"  style="margin-top: 0.4rem">{{
                  $t("TotalDeductions/year")
                }}</v-list-item-title>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="5" xl="2">
                <v-text-field
                  color="#ACACAC"
                  hide-details
                  readonly
                  :suffix="$t('THB')"
                  v-model="income.totalDeduYear"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item :ripple="false">
            <v-row>
              <v-col cols="12" lg="9" md="8" sm="7" xl="10">
                <v-list-item-title class="text-wrap" style="margin-top: 0.4rem">{{
                  $t("NetIncome/year")
                }}</v-list-item-title>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="5" xl="2">
                <v-text-field
                  color="#ACACAC"
                  hide-details
                  readonly
                  v-model="income.netIncomeYear"
                  :suffix="$t('THB')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item :ripple="false">
            <v-row>
              <v-col cols="12" lg="9" md="8" sm="7" xl="10">
                <v-list-item-title class="text-wrap" >{{
                  $t("PersonalTax/year")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-wrap" style="word-break: break-word !important;">{{
                  $t("PersaonlTaxDetail")
                }}</v-list-item-subtitle>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="5" xl="2">
                <v-text-field
                  color="#ACACAC"
                  hide-details
                  readonly
                  v-model="income.personalTaxYear"
                  :suffix="$t('THB')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item :ripple="false">
            <v-row>
              <v-col cols="12" lg="9" md="8" sm="7" xl="10">
                <v-list-item-title class="text-wrap" style="margin-top: 0.4rem">{{
                  $t("PersonalTax/Month")
                }}</v-list-item-title>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="5" xl="2">
                <v-text-field
                  color="#ACACAC"
                  hide-details
                  readonly
                  :suffix="$t('THB')"
                  v-model="income.personalTaxMonth"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- Loading Dialog -->
      <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import GetIncome from "@/function/Income.js";
import LogTrace from "@/function/Log.js";
import LoadingDialog from "@/components/Loading.vue";


export default {
  components:{
    LoadingDialog,
  },
  mixins: [GetIncome, LogTrace],

  data() {
    return {
      LoadingDialog: false,
      item: "",
      emptype: 1,
      url: enurl.apiUrl,
      request: {
        company_ID: store.state.companyID,
        employee_ID: parseInt(this.$route.params.id),
      },
      income: {
        totalIncomeMonth: "",
        totalIncomeYear: "",
        totalDeduYear: "",
        netIncomeYear: "",
        personalTaxYear: "",
        personTaxMonth: "",
      },
      Seperate: {
        totalIncomeMonth: "",
        totalIncomeYear: "",
        totalDeduYear: 0,
        netIncomeYear: "",
        personalTaxYear: "",
        personTaxMonth: "",
      },
      calculation: {
        salary_Amount: "",
        totalSalary: 0,
      },
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.getemmpersonalallowance();
      await this.getSalary();
      await this.getIncomePersonal();
    } else {
      this.GetaddedDATAwithcode();
      this.getIdless();
    }
  },
  methods: {
    async getIncomePersonal() {
      try {
        let temp = {};
        if (
          this.$route.path ==
          "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax"
        ) {
          temp = { employee_ID: store.state.employeeID };
        } else if (this.$route.params.id != undefined) {
          temp = { employee_ID: parseInt(this.$route.params.id) };
        }

        const response = await Axios.post(
          `${this.url}IncomeTaxPersonalAllowance/GetIncomeTaxPersonalAllowanceByEmployeeID`,
          temp
        );
        let paymentList = response.data.data;
        if (paymentList.length) {
          //  this.income.totalDeduYear = paymentList[0].fatherHealthInsurance;
          let seperate = paymentList[0].fatherHealthInsurance;
          this.Seperate.totalDeduYear = seperate.replace(/,/g, "");
        }
      } catch (error) {
        throw error;
      }
    },
    async getemmpersonalallowance() {
      try {
        let temp = {};
        if (
          this.$route.path ==
          "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax"
        ) {
          temp = { employee_ID: store.state.employeeID };
        } else if (this.$route.params.id != undefined) {
          temp = { employee_ID: parseInt(this.$route.params.id) };
        }
        const response = await Axios.post(
          `${this.url}Emp_PersonalAllowance/Gettotalpersonal`,
          temp
        );
        let mylist = response.data.data;
        if (mylist != 0) {
          this.income.totalDeduYear = mylist.totalpersonal;
        } else {
          this.income.totalDeduYear = parseFloat(this.income.totalDeduYear).toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }
      } catch (error) {
        throw error;
      }
    },
    async getSalary() {
      try {
        if (
          this.$route.path ==
          "/selfservice/PersonalProfile/PersonalInformation/PersonalIncomeTax"
        ) {
          this.request.employee_ID = store.state.employeeID;
        } else if (this.$route.params.id != undefined) {
          this.request;
        }
        this.LoadingDialog = true;
        const response = await Axios.post(
          `${this.url}Salary/GetSalaryInfo`,
          this.request
        );
        this.LoadingDialog = false;
        let salaryList = response.data.data;

        if (salaryList.length) {
          // let salary = salaryList[0].totalIncome_Tax;
          this.emptype = salaryList[0].employmentTypeID
          let salary = this.emptype == 2 ? 0 : salaryList[0].totalIncome_Tax;
          this.GetIncomeing(salary);
        }
      } catch (error) {
        throw error;
      }
    },

    getIdless() {
      this.total = localStorage.getItem("mysalary");
      let totalDeduYear = localStorage.getItem("PerAllowance");

      if (!this.$route.params.id && this.total) {
        if (this.total == null || this.total == undefined) {
          this.total = 0;
        } else {
          this.Seperate.totalIncomeMonth = parseFloat(this.total);
          this.$store.state.IncomeMonth = this.Seperate.totalIncomeMonth;
          this.income.totalIncomeMonth = this.Seperate.totalIncomeMonth
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          //  console.log(this.income.totalIncomeMonth);
          this.Seperate.totalIncomeYear = 12 * this.Seperate.totalIncomeMonth;
          this.income.totalIncomeYear = this.Seperate.totalIncomeYear
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

          // this.income.totalDeduYear = 0;
          this.income.totalDeduYear = totalDeduYear;
          let seperate = totalDeduYear;
          this.Seperate.totalDeduYear = seperate
            ? seperate.replace(/,/g, "")
            : "";

          if(this.emptype == 1){
            this.Seperate.netIncomeYear =
            this.Seperate.totalIncomeYear - this.income.totalDeduYear;
            this.income.netIncomeYear = this.Seperate.netIncomeYear
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          }
          else {
            this.income.netIncomeYear = "0.00";
          }
          

          //   let residual = this.Seperate.netIncomeYear;
          let tax = this.GetIncome(this.total, this.income.totalDeduYear);
          // if (residual < 150000) {
          //   this.income.personalTaxYear = tax;
          // } else {
          //   this.range = 150000 - 0;
          //   let secondRange = 300000 - 150000;
          //   residual = residual - this.range; //367000
          //   if (residual > secondRange) {
          //     tax = 150000 * 0.05;
          //   } else {
          //     tax = residual * 0.05;
          //   }

          //   let thirdRange = 500000 - 300000;
          //   if (residual > secondRange) {
          //     residual = residual - secondRange;
          //     if (residual > thirdRange) {
          //       tax += thirdRange * 0.1;
          //     } else {
          //       tax += residual * 0.1;
          //     }
          //   }

          //   let fourRange = 750000 - 500000;
          //   if (residual > thirdRange) {
          //     residual = residual - thirdRange;
          //     if (residual > fourRange) {
          //       tax += fourRange * 0.15;
          //     } else {
          //       tax += residual * 0.15;
          //     }
          //   }

          //   let fiveRange = 1000000 - 750000;
          //   if (residual > fourRange) {
          //     residual = residual - fourRange;
          //     if (residual > fiveRange) {
          //       tax += fiveRange * 0.2;
          //     } else {
          //       tax += residual * 0.2;
          //     }
          //   }

          //   let sixRange = 2000000 - 1000000;
          //   if (residual > fiveRange) {
          //     residual = residual - fiveRange;
          //     if (residual > sixRange) {
          //       tax += sixRange * 0.25;
          //     } else {
          //       tax += residual * 0.25;
          //     }
          //   }

          //   let sevenRange = 5000000 - 2000000;
          //   if (residual > sixRange) {
          //     residual = residual - sixRange;
          //     if (residual > sevenRange) {
          //       tax += sevenRange * 0.3;
          //     } else {
          //       tax += residual * 0.3;
          //     }
          //   }
          // }
          this.income.personalTaxYear = tax
            .toFixed(2)

            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

          let taxPerYear = this.income.personalTaxYear.replace(/,/g, "");
          let personaltaxmonth = taxPerYear / 12;
          personaltaxmonth = personaltaxmonth.toFixed(2);
          this.income.personalTaxMonth = personaltaxmonth.toString();
          this.income.totalDeduYear = parseFloat(this.income.totalDeduYear).toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        
        }
      }
    },

    async GetIncomeing(salary) {
      try {
        this.request;
        let self = this;
        let total = 0;
        const resq = await Axios.post(
          `${this.url}IncomeTaxPersonalAllowance/GetSalaryByEmployeeID`,
          this.request
        );
        let list = resq.data.data;

        let salaryPerYear = list.total_Salary;
        // alert(salaryPerYear)
        let total_Month = list.total_Month;
        let totalSalary = 0;
        if (salary != "") {
          totalSalary = parseFloat(salary) + total;
        }

        this.$store.state.IncomeMonth = totalSalary;
        this.Seperate.totalIncomeMonth = totalSalary.toFixed(2);
        this.income.totalIncomeMonth = this.Seperate.totalIncomeMonth
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        let separteYear = salaryPerYear + total;

        this.Seperate.totalIncomeYear = (separteYear / total_Month) * 12;
        this.Seperate.totalIncomeYear = totalSalary * 12;
        //alert(this.Seperate.totalIncomeYear)
        this.income.totalIncomeYear = this.Seperate.totalIncomeYear
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

        // this.income.totalDeduYear = 0;
       
        
        if(self.emptype == 1){
            self.Seperate.netIncomeYear =
            self.Seperate.totalIncomeYear - parseInt(self.income.totalDeduYear);
            self.income.netIncomeYear = self.Seperate.netIncomeYear
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          }
          else {
            self.income.netIncomeYear = "0.00";
          }

        // let residual = totalSalary;
        let tax = this.GetIncome(
          parseFloat(this.Seperate.totalIncomeMonth),
          this.income.totalDeduYear
        );

        this.income.personalTaxYear = tax;
        this.income.personalTaxYear = this.income.personalTaxYear
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

        let taxPerYear = this.income.personalTaxYear.replace(/,/g, "");
        let personaltaxmonth = taxPerYear / 12;
        personaltaxmonth = personaltaxmonth.toFixed(2);
        this.income.personalTaxMonth = personaltaxmonth
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        this.income.totalDeduYear = parseFloat(this.income.totalDeduYear).toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        //this.LogTrace(null, "Get Personal Allowance", 44, "Critical");
      } catch (error) {
        this.LogTrace(error, "Get Personal Allowance Fail", 42, "Critical");
        //alert(error + "Correlation ID is 44");
        throw error;
      }
    },
    GetaddedDATAwithcode() {
      let self = this;  let temp = {
        employee_ID: localStorage.empcode,
      }; 
        Axios .post(
          `${self.url}Emp_PersonalAllowance/GetEmpallowancewithCode`,
          temp
        ).then(function (response) {
          let TempData = response.data.data;
          let forCal = 0;
          if (TempData.length != 0) {
            for (let i = 0; i < TempData.length; i++) {
              forCal = parseFloat(forCal)+parseFloat(TempData[i].pA_Amount);
            }
              localStorage.setItem("PerAllowance", parseFloat(forCal).toFixed(2));
          } 
        })      
    },
  },
};
</script>

<style scoped>
>>> .theme--light.v-list-item:hover::before,
.theme--light.v-list-item::before {
  opacity: 0;
}
</style>