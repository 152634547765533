<template>
  <div>
    <v-card class="pt-5" style="margin-bottom: 2.5rem">
      <v-row class="pl-md-0 mt-5" style="margin-left: 25px; margin-right: 20px">
        <!-- <v-col cols="12" xs="12" sm="12" md="2" lg="4">
          <v-text-field
            autocomplete="off"
            :placeholder="$t('Search')"
            v-model.lazy="searchtxt"
            color="#F99D20"
            dense
          ></v-text-field>
        </v-col>
        <v-btn
          color="#F99D20"
          @click="Search"
          class="white--text text-capitalize ml-5 mt-2"
          width="120"
          max-height="35"
        >
          <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
        </v-btn> -->
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-text-field
            :placeholder="$t('Search')"
            v-model="searchtxt"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.1rem; margin-left:1rem;"
            @keyup.enter="Search()">
          </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          color="#F99D20"
          class="orange--text mr-5 mt-2 text-capitalize"
          width="150"
          max-height="35"
          outlined
          @click="uploaddialog = true"
          :disabled="permission.upload"
          >{{ $t("upload") }}</v-btn
        >
        <v-btn
          color="#F99D20"
          class="white--text btnres mt-2 mr-5 text-capitalize"
          width="150"
          max-height="35"
          @click="Adddialog = true"
          :disabled="permission.add"
          >{{ $t("Add") }}</v-btn
        >
      </v-row>
      <v-data-table
        class="tbheader"
        style="margin-left: 40px; margin-right: 40px"
        :headers="headers"
        :items="departmentList"
        :page.sync="page"
        hide-default-header
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr></tr>
            <tr>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('department_Code')"
              >
                {{ $t("departmentCode") }}
                <v-icon small v-if="!department_Code">mdi-arrow-down</v-icon>
                <v-icon small v-if="department_Code">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('department_Name')"
              >
                {{ $t("Departmentheader") }}
                <v-icon small v-if="!department_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="department_Name">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 150px !important"
                class="b-text"
                @click="sortFun('division_Name')"
              >
                {{ $t("division") }}
                <v-icon small v-if="!division_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="division_Name">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 150px !important"
                class="b-text"
                @click="sortFun('section_Name')"
              >
                {{ $t("section") }}
                <v-icon small v-if="!section_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="section_Name">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 140px !important"
                class="b-text"
                @click="sortFun('level_of_Department')"
              >
                {{ $t("organizationLevel") }}
                <v-icon small v-if="!level_of_Department"
                  >mdi-arrow-down</v-icon
                >
                <v-icon small v-if="level_of_Department">mdi-arrow-up</v-icon>
              </th>
              <th>{{ $t("action") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.department_Code }}</td>
            <td>{{ item.department_Name }}</td>
            <td>{{ item.division_Name != "" ? item.division_Name : "-" }}</td>
            <td>{{ item.section_Name != "" ? item.section_Name : "-" }}</td>
            <td>{{ item.level_of_Department }}</td>
            <td class="ml-6">
              <v-btn
                icon
                @click="EditDepartment(item)"
                :disabled="permission.edit"
              >
                <img class="editIcon" :src="images.edit"/>
              </v-btn>

              <v-btn
                icon
                @click="Deletedia(item.department_ID)"
                :disabled="permission.delete"
              >
                <img class="deleteIcon" :src="images.delete"/>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right" v-if="departmentList.length">
        <PaginationComponent
              :page.sync="page"
              :pageCount="pageCount"
              :itemsPerPage.sync="itemsPerPage"
            />
      </div>
    </v-card>
    
    <!-- add department dialog -->
    <v-dialog v-model="Adddialog" persistent max-width="700px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-5 mr-2" style="font-size: 23px">
          {{ $t("addNewDepartment") }}
        </p>
        <v-card-text>
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-form ref="formAdd" lazy-validation>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="add.deptcode"
                  :rules="deptcode"
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("departmentCode")
                      }}<span class="red--text"> *</span></span
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  autocomplete="off"
                  v-model="add.deptname"
                  @change="ValidationOrgLvl()"
                  color="#F99D20"
                  :rules="deptname"
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("departmentName")
                      }}<span class="red--text"> *</span></span
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-row style="margin-left: 0.2rem">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    v-model="add.division"
                    :rules="division"
                    @change="ValidationOrgLvl()"
                    color="#F99D20"
                    required
                  >
                    <template v-slot:label>
                      <span>
                        {{ $t("division")
                        }}<span class="red--text"> *</span></span
                      >
                    </template></v-text-field
                  >
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    v-model="add.section"
                    @change="ValidationOrgLvl()"
                    color="#F99D20"
                    :rules="section"
                    required
                    ><template v-slot:label>
                      <span>
                        {{ $t("section")
                        }}<span class="red--text"> *</span></span
                      >
                    </template></v-text-field
                  >
                </v-col>
              </v-row>
              <v-row style="margin-left: 0.2rem">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    :label="$t('OrgLevel')"
                    v-model="add.deptlvl"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row wrap>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              text
              @click="AddCancel()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="AddDepartment()"
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit department dialog -->
    <v-dialog v-model="Editdialog" persistent max-width="750px">
      <v-card style="border-radius: 15px; overflow: hidden">
        <p class="pt-5 ml-5 mr-2" style="font-size: 23px">
          {{ $t("EditDepartment") }}
        </p>
        <v-card-text>
          <v-container style="margin-left: -1.5rem">
            <v-form ref="formEdit" lazy-validation>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edit.deptcode"
                  :rules="deptcodeEdit"
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("departmentCode")
                      }}<span class="red--text"> *</span></span
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  autocomplete="off"
                  v-model="edit.deptname"
                  color="#F99D20"
                  @change="ValidationOrgLvlEdit()"
                  :rules="deptnameEdit"
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("departmentName")
                      }}<span class="red--text"> *</span></span
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-row style="margin-left: 0.2rem">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    v-model="edit.division"
                    color="#F99D20"
                    @change="ValidationOrgLvlEdit()"
                    :rules="divisionEdit"
                    ><template v-slot:label>
                      <span>
                        {{ $t("division")
                        }}<span class="red--text"> *</span></span
                      >
                    </template></v-text-field
                  >
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    v-model="edit.section"
                    color="#F99D20"
                    @change="ValidationOrgLvlEdit()"
                    :rules="sectionEdit"
                    ><template v-slot:label>
                      <span>
                        {{ $t("section")
                        }}<span class="red--text"> *</span></span
                      >
                    </template></v-text-field
                  >
                </v-col>
              </v-row>
              <v-row style="margin-left: 0.2rem">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    :label="$t('OrgLevel')"
                    v-model="edit.deptlvl"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <!-- <v-col cols="12" sm="6" md="6">
                         <v-text-field label="Level of Section in Division" v-model="add.sectionlvl" required></v-text-field>
            </v-col>-->
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%">
          <v-row wrap>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              class="mb-4 mr-2 text-capitalize commoncancelbtn"
              text
              outlined
              @click="EditCancel()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mb-5 mr-2 text-capitalize commonsavebtn"
              @click="UpdateDepartment()"
              >{{ $t("Update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete department dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("departmentDelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              @click="Deletedialog = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="150"
              max-height="35"
              @click="DeleteDepartment()"
              >{{ $t("delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploaddialog" presistent max-width="750">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("upload") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  truncate-length="100"
                  v-show="chosenfile"
                  color="white"
                  class="myfile"
                  id="myFileInput"
                  v-model="chosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon v-show="chosenfile">
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Department Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="CheckLang()">{{
                  $t("download")
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn
              outlined
              class="mr-5 text-capitalize commoncancelbtn"
              @click="closeUpload"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="PreviewFiles"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  watch: {
    "add.deptcode"() {
      this.deptcode = [];
    },
    "add.deptname"() {
      this.deptname = [];
    },
    "add.division"() {
      this.division = [];
    },
    "add.section"() {
      this.section = [];
    },
    "edit.deptcode"() {
      this.deptcodeEdit = [];
    },
    "edit.deptname"() {
      this.deptnameEdit = [];
    },
    "edit.division"() {
      this.devisionEdit = [];
    },
    "edit.section"() {
      this.sectionEdit = [];
    },
    searchtxt: function () {
      if (this.searchlength == 1) {
        this.GetDepartment();
      }
    },
  },
  data() {
    return {
      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      deptcode: [],
      deptname: [],
      division: [],
      section: [],
      deptcodeEdit: [],
      deptnameEdit: [],
      divisionEdit: [],
      sectionEdit: [],
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      showup: false,
      department_Code: null,
      department_Name: null,
      division_Name: null,
      section_Name: null,
      level_of_Department: null,
      companyid: store.state.companyID,
      chosenfile: null,
      importData: [],
      tempimport: [],
      uploaddialog: false,
      searchtxt: "",
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      // items: [10, 20, 30],
      department_ID: 0,
      Adddialog: false,
      Editdialog: false,
      Deletedialog: false,
      LoadingDialog: false,
      headers: [
        {
          text: "Dept Code",
          value: "department_Code",
          align: "left",
          sortable: false,
        },
        {
          text: "Name",
          value: "department_Name",
          align: "left",
          sortable: false,
        },

        {
          text: "Division",
          // value: "division_Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Section",
          // value: "section_Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Organization Level",
          // value: "level_of_Department",
          align: "left",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actionadd",
          align: "left",
          sortable: false,
        },
      ],

      department: [],
      departmentList: [],
      add: {
        deptcode: "",
        deptname: "",
        deptlvl: "",
        division: "",
        section: "",
      },
      edit: {
        department_ID: null,
        deptcode: null,
        deptname: null,
        deptlvl: null,
        division: null,
        section: null,
      },
      errorMessage: [],
      searchlength: 0,
    };
  },

  mounted() {
    let self = this;
    self.GetDepartment();
    self.permission = self.CheckViewAddEditDeleteUpload(54, 55, 56, 57, 58);
  },

  computed: {
    images() {
      return this.$store.state.images;
    }
  },

  methods: {
    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย") {
        url =
          "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Department Master Data.xlsx";
      } else {
        url =
          "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Department Master Data.xlsx";
      }
      return url;
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        self.searchlength = 1;
        let List = self.departmentList;
        self.departmentList = [];
        let temp = List.filter(
          (v) =>
            v.department_Code
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.department_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.division_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.section_Name.toLowerCase().indexOf(self.searchtxt.toLowerCase()) >
              -1 ||
            v.level_of_Department.toString().indexOf(self.searchtxt) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.departmentList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetDepartment();
      }
    },
    AddCancel() {
      let self = this;
      self.add.deptcode = "";
      self.add.deptname = "";
      self.add.deptlvl = "";
      self.add.division = "";
      self.add.section = "";
      self.deptcode = [];
      self.deptname = [];
      self.division = [];
      self.section = [];
      self.$refs.formAdd.reset();
      self.Adddialog = false;
    },
    EditCancel() {
      let self = this;
      self.edit.department_ID = null;
      self.edit.deptcode = null;
      self.edit.deptname = null;
      self.edit.deptlvl = null;
      self.edit.division = null;
      self.edit.section = null;
      self.deptcodeEdit = [];
      self.deptnameEdit = [];
      self.divisionEdit = [];
      self.sectionEdit = [];
      self.$refs.formEdit.reset();
      self.Editdialog = false;
    },
    sortFun(rowName) {
      let self = this;
      let keyName = [
        "department_Code",
        "department_Name",
        "division_Name",
        "section_Name",
        "level_of_Department",
      ];
      if (
        rowName == "department_Code" ||
        rowName == "department_Name" ||
        rowName == "division_Name" ||
        rowName == "section_Name"
      ) {
        self.departmentList = self.departmentList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.departmentList = self.departmentList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.department_Code = null;
      this.department_Name = null;
      this.division_Name = null;
      this.section_Name = null;
      this.level_of_Department = null;
    },
    ValidationOrgLvl() {
      if (
        this.add.deptname == "" &&
        this.add.division == "" &&
        this.add.section == ""
      ) {
        this.add.deptlvl = "";
      } else if (
        this.add.deptname == "" &&
        this.add.division == "" &&
        this.add.section != ""
      ) {
        this.add.deptlvl = "";
      } else if (
        this.add.deptname == "" &&
        this.add.division != "" &&
        this.add.section != ""
      ) {
        this.add.deptlvl = "";
      } else if (
        this.add.deptname != "" &&
        this.add.division != "" &&
        this.add.section != ""
      ) {
        this.add.deptlvl = 4;
      } else if (
        this.add.deptname != "" &&
        this.add.division != "" &&
        this.add.section == ""
      ) {
        this.add.deptlvl = 3;
      } else if (
        this.add.deptname != "" &&
        this.add.division == "" &&
        this.add.section == ""
      ) {
        this.add.deptlvl = 2;
      } else if (
        this.add.deptname == "" &&
        this.add.division != "" &&
        this.add.section != ""
      ) {
        this.add.deptlvl = "";
      } else if (
        this.add.deptname != "" &&
        this.add.division == "" &&
        this.add.section != ""
      ) {
        this.add.deptlvl = 2;
      }
    },

    ValidationOrgLvlEdit() {
      if (
        this.edit.deptname == "" &&
        this.edit.division == "" &&
        this.edit.section == ""
      ) {
        this.edit.deptlvl = "";
      } else if (
        this.edit.deptname == "" &&
        this.edit.division == "" &&
        this.edit.section != ""
      ) {
        this.edit.deptlvl = "";
      } else if (
        this.edit.deptname == "" &&
        this.edit.division != "" &&
        this.edit.section != ""
      ) {
        this.edit.deptlvl = "";
      } else if (
        this.edit.deptname != "" &&
        this.edit.division != "" &&
        this.edit.section != ""
      ) {
        this.edit.deptlvl = 4;
      } else if (
        this.edit.deptname != "" &&
        this.edit.division != "" &&
        this.edit.section == ""
      ) {
        this.edit.deptlvl = 3;
      } else if (
        this.edit.deptname != "" &&
        this.edit.division == "" &&
        this.edit.section == ""
      ) {
        this.edit.deptlvl = 2;
      } else if (
        this.edit.deptname == "" &&
        this.edit.division != "" &&
        this.edit.section != ""
      ) {
        this.edit.deptlvl = "";
      } else if (
        this.edit.deptname != "" &&
        this.edit.division == "" &&
        this.edit.section != ""
      ) {
        this.edit.deptlvl = 2;
      }
    },

    closeUpload() {
      this.uploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    getupload() {
      document.getElementById("myFileInput").click();
      this.showup = true;
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    PreviewFiles() {
      let self = this;
      self.LoadingDialog = true;
      var files = self.chosenfile;
      if (files != null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Department Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            let range = XLSX.utils.decode_range(
              workbook.Sheets[sheetName]["!ref"]
            );
            range.s.c = 0; // 0 == XLSX.utils.decode_col("A")
            range.e.c = 4; // 4 == XLSX.utils.decode_col("E")
            let new_range = XLSX.utils.encode_range(range);
            let file = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              blankrows: false,
              defval: "",
              range: new_range,
            });
            if (file.length != 1) {
              let tempsec = file.filter((obj) => obj[3] == "");
              let tempdiv = file.filter((obj) => obj[2] == "");
              let tempdept = file.filter((obj) => obj[1] == "");
              let templvl = file.filter((obj) => obj[4] == "");
              let tempcode = file.filter((obj) => obj[0] == "");

              if (
                tempsec.length == 0 &&
                tempdiv.length == 0 &&
                tempdept.length == 0 &&
                templvl.length == 0 &&
                tempcode.length == 0 &&
                temp.length != 0
              ) {
                this.importData = XLSX.utils.sheet_to_json(worksheet);
                let combileArray = [];
                for (let i = 0; i < this.importData.length; i++) {
                  this.tempimport = {
                    Level_of_Organization: file[i + 1][4],
                    section_Name: file[i + 1][3].toString(),
                    division_Name: file[i + 1][2],
                    department_Name: file[i + 1][1],
                    department_Code: file[i + 1][0].toString(),
                    company_ID: store.state.companyID,
                  };
                  combileArray.push(this.tempimport);
                }
                self.LoadingDialog = true;

                axios.post(`${self.url}Department/ImportDepartmentMasterData`,combileArray)
                .then(function (response) {
                  if (response.data.status == 0)
                  {
                    alert(response.data.message);
                    self.chosenfile = null;
                    self.showup = false;
                    self.uploaddialog = false;
                    self.LoadingDialog = false;
                    self.GetDepartment();
                    self.defaultSort();
                  }
                })
                .catch(function (error) {
                  //alert(error + "Correlation ID is 2");
                  self.LogTrace(
                    error,
                    "Upload  Department Fail",
                    2,
                    "Verbose"
                  );
                  self.LoadingDialog = false;
                });
              } else {
                self.LoadingDialog = false;
                if (temp.length == 0) {
                  alert(
                    "Please fill department_Code ,department_Name ,division_Name ,section_Name and Level_of_Organization."
                  );
                } else {
                  if (tempsec.length != 0) {
                    self.errorMessage.push("section_Name");
                  }
                  if (tempdiv.length != 0) {
                    self.errorMessage.push("division_Name");
                  }
                  if (tempdept.length != 0) {
                    self.errorMessage.push("department_Name");
                  }
                  if (templvl.length != 0) {
                    self.errorMessage.push("Level_of_Organization");
                  }
                  if (tempcode.length != 0) {
                    self.errorMessage.push("department_Code");
                  }
                  let Message = [...new Set(self.errorMessage)];
                  alert(`Please fill ${Message.join(", ")}`);
                  self.errorMessage = [];
                }
              }
            } else {
              self.LoadingDialog = false;
              alert(
                "Please fill department_Code ,department_Name ,division_Name ,section_Name and Level_of_Organization."
              );
            }
          } else {
            alert("File name must be Department Master Data.xlsx");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
        self.LoadingDialog = false;
      } else {
        alert("Please choose File");
        self.LoadingDialog = false;
      }
    },

    back() {
      this.$router.push({ name: "Masterdata" }).catch(() => {});
    },
    ValidateSection() {
      let self = this;
      let result;
      let FilterSectionList = self.departmentList;
      for (let i = 0; i < FilterSectionList.length; i++) {
        if (
          FilterSectionList[i].department_Name == self.add.deptname &&
          FilterSectionList[i].division_Name == self.add.division &&
          FilterSectionList[i].section_Name == self.add.section
        ) {
          alert(
            "This department,division and section have already been added !"
          );
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    EditValidateSection() {
      let self = this;
      let result;
      let FilterSectionList = self.departmentList.filter(
        (data) => data.department_ID != self.edit.department_ID
      );
      for (let i = 0; i < FilterSectionList.length; i++) {
        if (
          FilterSectionList[i].department_Name == self.edit.deptname &&
          FilterSectionList[i].division_Name == self.edit.division &&
          FilterSectionList[i].section_Name == self.edit.section
        ) {
          alert(
            "This department,division and section have already been added !"
          );
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },

    GetDepartment() {
      let self = this;
      self.LoadingDialog = true;
      self.searchlength = 0;
      let temp = { company_ID: store.state.companyID };
      axios.post(`${self.url}Department/GetDepartmentsByCompanyID`, temp)
      .then(function (response) {
        self.department = response.data.data;
        self.departmentList = response.data.data;
        self.LoadingDialog = false;
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Department Fail", 2, "Critical");
        //alert(error + "Correlation ID is 2");
        self.LoadingDialog = false;
      });
    },
    AddDepartment() {
      let self = this;
      self.LoadingDialog = true;
      self.deptcode = [(v) => !!v || "Department Code is required"];
      self.deptname = [(v) => !!v || "Department Name is required"];
      self.division = [(v) => !!v || "Division is required"];
      self.section = [(v) => !!v || "Section is required"];
      let tempvalidate = self.$refs.formAdd.validate();
      if (
        tempvalidate == true &&
        self.add.deptcode != "" &&
        self.add.deptname != "" &&
        self.add.division != "" &&
        self.add.section != "" &&
        self.add.deptcode != null &&
        self.add.deptname != null &&
        self.add.division != null &&
        self.add.section != null
      ) {
        let tempsection = self.ValidateSection();
        if (tempsection == true) {
          let tempdept = {
            department_Code: self.add.deptcode,
            department_Name: self.add.deptname,
            level_of_Organization: self.add.deptlvl,
            division_Name: self.add.division,
            section_Name: self.add.section,
            company_ID: store.state.companyID,
          };
          axios
            .post(`${self.url}Department/AddDepartment`, tempdept)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.add.deptcode = "";
                self.add.deptname = "";
                self.add.division = "";
                self.add.deptlvl = "";
                self.add.section = "";
                self.Adddialog = false;
                self.$refs.formAdd.reset();
                self.LoadingDialog = false;
                self.GetDepartment();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Add  Department Fail", 2, "Low");
              //alert(error + "Correlation ID is 2");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        // alert("Please fill in the fields");
        self.LoadingDialog = false;
      }
    },
    EditDepartment(temp) {
      var self = this;
      self.Editdialog = true;
      self.edit.department_ID = temp.department_ID;
      self.edit.deptcode = temp.department_Code;
      self.edit.deptname = temp.department_Name;
      self.edit.division = temp.division_Name;
      self.edit.deptlvl = temp.level_of_Department;
      self.edit.section = temp.section_Name;
    },
    Deletedia(id) {
      this.department_ID = id;
      this.Deletedialog = true;
    },
    DeleteDepartment() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.department_ID;
      let temp = {
        department_ID: id,
      };
      axios.post(`${self.url}Department/DeleteDepartment`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.Deletedialog = false;
          self.LoadingDialog = false;
          self.GetDepartment();
          self.defaultSort();
          self.logtrace = response.data.message;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Delete Department Fail", 2, "High");
        //alert(error + "Correlation ID is 2");
        self.LoadingDialog = false;
      });
    },
    UpdateDepartment() {
      let self = this;
      self.LoadingDialog = true;
      self.deptcodeEdit = [(v) => !!v || "Department Code is required"];
      self.deptnameEdit = [(v) => !!v || "Department Name is required"];
      self.divisionEdit = [(v) => !!v || "Division is required"];
      self.sectionEdit = [(v) => !!v || "Section is required"];
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.edit.deptcode != "" &&
        self.edit.deptcode != null &&
        self.edit.deptname != "" &&
        self.edit.deptname != null &&
        self.edit.division != "" &&
        self.edit.division != null &&
        self.edit.section != "" &&
        self.edit.section != null
      ) {
        let tempsection = self.EditValidateSection();
        if (tempsection == true) {
          let temp = {
            department_ID: self.edit.department_ID,
            department_Code: self.edit.deptcode,
            department_Name: self.edit.deptname,
            level: self.edit.deptlvl,
            division_Name: self.edit.division,
            section_Name: self.edit.section,
            company_ID: store.state.companyID,
          };
          axios
            .post(`${self.url}Department/UpdateDepartment`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.edit.deptcode = "";
                self.edit.deptname = "";
                self.edit.division = "";
                self.edit.deptlvl = "";
                self.edit.section = "";
                self.Editdialog = false;
                self.LoadingDialog = false;
                self.GetDepartment();
                self.defaultSort();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update  Department Fail", 2, "Medium");
              //alert(error + "Correlation ID is 2");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        // alert("Please fill in the fields");
        self.LoadingDialog = false;
      }
    },
  },
};
</script>
<style scoped>
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
tbody tr:hover {
  background-color: #fff9f0 !important;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 16px;
}
@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
    /* margin-top: -1rem !important; */
  }
}
@media (max-width: 415px) {
  .sear {
    margin-left: 0.2rem !important;
  }
}
@media (max-width: 375px) {
  .btnres {
    margin-top: 1rem !important;
  }
}
@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
}
</style>
<style >
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #87787f;
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f0f0f7;
}
>>> .v-dialog::-webkit-scrollbar {
  width: 2px;
  background-color: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Track */
>>> .v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle */
.v-input__slot ::-webkit-scrollbar-thumb {
  background: #87787f;
  border-radius: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
body::-webkit-scrollbar-thumb {
  background-color: #f0f0f7 !important;
}
/* Handle on hover */
>>> .v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0f0f7;
}
</style>