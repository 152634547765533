<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 1.5rem">
    <div v-if="!showReq">
        <p class="txt-header">
          {{ $t("MyOTRequest") }}
        </p>
      <div style="height: auto" class="mt-9">
        <v-card min-height="60vh">
          <div style="margin-left: 2%; margin-right: 2%">
            <br />
            <div>
              <v-row>
                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                  <v-row>
                    <div class="mr-8 d-flex" style="
                        background-color: #f99d20;
                        border-radius: 5px;
                        margin-left: 12px;
                      ">
                      <v-autocomplete class="selectboxs minizebox ml-4 mt-1 mr-4 mb-1 pl-0" v-model="todaymonths"
                        height="34px" single-line :items="monthNames" item-text="txt" item-value="val"
                        background-color="#FFFFFF" return-object persistent-hint
                        v-on:change="ChangeMonth(`${todaymonths.val}`)"></v-autocomplete>
                      <date-picker v-model="todayyears" type="year" class="selectboxs minizebox ml-1 mr-4 mt-1 mb-1 pl-0 today_years custom-vuedatepicker1"
                        v-on:change="ChangeYear(todayyears)">
                        <!-- <template slot="icon-calendar">
                          <img src="@/assets/images/down1@2x.png" style="
                              width: 30px;
                              height: 18px;
                              margin-top: 4px;
                              margin-left: 10px;
                            " class="pl-2" />
                        </template> -->
                      </date-picker>
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="5">
                  <div class="d-flex">
                    <div class="mr-4 mt-2">
                      <p>{{ $t("status") }}</p>
                    </div>
                    <div>
                      <v-autocomplete v-model="itemInner" :items="status" item-text="txt" item-value="val" outlined
                        rounded dense color="#F99D20"></v-autocomplete>
                    </div>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="3" md="2" lg="2" xl="2">
                  <v-btn color="#F99D20" @click="OTapprovedialog = true" class="white--text mr-5 text-capitalize btnres"
                    block max-height="35" :disabled="permission.add">{{ $t("newRequest") }}</v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="mt-5">
              <v-data-table :headers="headers" :items="itemFilter" hide-default-footer hide-default-header
                :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event" :mobile-breakpoint="0">
                <template v-slot:header="{ props: { } }">
                  <thead>
                    <tr>
                      <!-- <th>Ot Date</th> -->
                      <th>{{ $t("oTHours") }}</th>
                      <th>{{ $t("startdate") }}</th>
                      <th>{{ $t("enddate") }}</th>
                      <th>{{ $t("purposeofOT") }}</th>
                      <th>{{ $t("RequestDate") }}</th>
                      <th>{{ $t("status") }}</th>
                      <th>{{ $t("Supervisor") }}</th>

                      <th class="pl-6">{{ $t("Actions") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <!-- <td>{{ formatDate(item.requested_Date) }}</td> -->

                    <td>{{ item.oT_Hours }}</td>
                    <td>{{ formatDate(item.start_Date) }}</td>
                    <td>{{ formatDate(item.end_Date) }}</td>
                    <td>{{ item.purpose_OT }}</td>
                    <td>
                      {{ convertUTCDateToLocalDate(item.requested_Date) }}
                    </td>
                    <td :style="{
                      color:
                        item.status == 'Approved'
                          ? '#22A534'
                          : item.status == 'Waiting Approval'
                            ? '#F99D20'
                            : '#FF6565',
                    }">
                      {{
                        item.status == "Approved"
                        ? $t("approved")
                        : item.status == "Denied"
                          ? $t("denied")
                          : $t("waitingApproval")
                      }}
                    </td>
                    <td>{{ item.approvername }}</td>
                    <td>
                      <v-btn color="#F99D20" @click="Detailrequest(item)" class="white--text text-capitalize"
                        max-width="120" max-height="35">
                        {{ $t("view") }}</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="text-right" v-if="itemFilter.length">
              <v-row justify="end" class="ml-5 mt-5 mr-5">
                <v-col cols="12" md="3">
                  <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
                </v-col>
                <v-col cols="12" md="2" class="mt-1">
                  <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
                    :items="items" @input="itemsPerPage = parseInt($event, 10)"></v-select>
                </v-col>
              </v-row>
            </div>
          </div>
          <!-- requestdialog -->
          <v-dialog v-model="OTapprovedialog" persistent max-width="580px">
            <v-card style="border-radius: 15px" class="pb-4">
              <v-card-title>
                <span> {{ $t("OTRequests") }}</span>
                <v-spacer></v-spacer>
                <v-btn @click="closerequestdialog()" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("EmployeeName")
                        }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field autocomplete="off" v-model="requestOT.empname" readonly height="10px" color="#F99D20"
                        single-line outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-form ref="formSent" lazy-validation>
                    <v-row>
                      <v-col>
                        <div class="mb-2 ml-2">
                          <v-checkbox class="custom-checkbox" color="#F99D20" v-model="requestOT.wholeday" @click="showstartdate()"></v-checkbox>
                          <!-- <input v-model="requestOT.wholeday" @click="showstartdate()" type="checkbox" /> -->
                          <label class="ml-3"> {{ $t("wholeDay") }}</label>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-show="showandhide" class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTDate") }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <!-- <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                          fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field single-line autocomplete="off" dense outlined :rules="OTdateSent"
                              v-model="computedDateFormatted1" append-icon="mdi-calendar-month-outline" color="#F99D20"
                              persistent-hint v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="requestOT.OTdate" no-title :allowed-dates="allowedDates"
                            @input="menu1 = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="requestOT.OTdate" 
                        :disable-date="allowedDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      </v-col>
                    </v-row>

                    <v-row v-show="!showandhide" class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTStartDate")
                          }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <!-- <v-menu v-model="menu7" :close-on-content-click="false" transition="scale-transition" offset-y
                          fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field single-line autocomplete="off" dense outlined v-model="computedDateFormatted3"
                              :rules="OTstartdateSent" append-icon="mdi-calendar-month-outline" color="#F99D20"
                              persistent-hint v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="requestOT.OTstartdate" no-title :allowed-dates="allowedDates"
                            @input="menu7 = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="requestOT.OTstartdate" 
                        no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      </v-col>
                    </v-row>
                    <v-row v-show="!showandhide" class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTEndDate") }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <!-- <v-menu v-model="menu8" :close-on-content-click="false" transition="scale-transition" offset-y
                          fluid max-width="290px" min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field single-line autocomplete="off" dense outlined v-model="computedDateFormatted4"
                              :rules="OTenddateSent" append-icon="mdi-calendar-month-outline" color="#F99D20"
                              persistent-hint v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="requestOT.OTenddate" no-title :allowed-dates="allowedDates"
                            @input="menu8 = false"></v-date-picker>
                        </v-menu> -->
                        <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="requestOT.OTenddate" 
                        :disable-date="allowedDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTStartTime")
                          }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                          :return-value.sync="time1" transition="scale-transition" offset-y max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field autocomplete="off" v-model="requestOT.OTstart" append-icon="access_time"
                              single-line outlined dense :rules="OTstartSent" color="#F99D20" readonly
                              v-on="on"></v-text-field>
                          </template>
                          <v-time-picker v-if="menu2" v-model="requestOT.OTstart" no-title fluid
                            @click:minute="$refs.menu2.save(requestOT.OTstart)"></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTEndTime") }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                          :return-value.sync="time2" transition="scale-transition" offset-y max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field autocomplete="off" v-model="requestOT.OTend" :rules="OTendSent"
                              append-icon="access_time" single-line outlined dense color="#F99D20" readonly
                              v-on="on"></v-text-field>
                          </template>
                          <v-time-picker v-if="menu3" v-model="requestOT.OTend" no-title fluid
                            @click:minute="$refs.menu3.save(requestOT.OTend)"></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("oTHours") }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-text-field v-model="requestOT.OThours" autocomplete="off" auto readonly :rules="OThoursSent"
                          height="10px" color="#F99D20" single-line outlined dense required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("purposeofOT")
                          }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-text-field autocomplete="off" v-model="requestOT.purposeOT" height="10px"
                          :rules="purposeOTSent" color="#F99D20" single-line outlined dense required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("approvalSentTo")
                        }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field autocomplete="off" v-model="requestOT.approver" :value="requestOT.approverID" readonly
                        height="10px" color="#F99D20" single-line outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">Status</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field height="10px" color="#F99D20" single-line outlined dense required></v-text-field>
                </v-col>
              </v-row>-->
                </v-container>
              </v-card-text>
              <div class="ml-11 mr-11">
                <v-row justify="center">
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <v-btn color="#F99D20" class="white--text mr-5 text-capitalize btnres" block max-height="35"
                      @click="RequestedOT()">
                      {{ $t("SendRequest") }}</v-btn>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <v-btn color="#F99D20" class="white--text mr-5 text-capitalize" block max-height="35"
                      @click="SavedraftOT()">{{ $t("SaveDraft") }}</v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-dialog>
          <!-- viewdialog -->
          <v-dialog v-model="viewdialog" persistent max-width="580px">
            <v-card style="border-radius: 15px" class="pb-4">
              <v-card-title>
                <span>{{ $t("OTRequests") }}</span>
                <v-spacer></v-spacer>
                <v-btn @click="hidedetaildialog()" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("EmployeeName")
                        }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field :disabled="disablestatus" v-model="EditOT.empname" height="10px" color="#F99D20"
                        single-line outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="mb-2 ml-2">
                        <!-- <input v-model="EditOT.wholeday" @click="showeditdate()" :disabled="disableviewdata"
                          type="checkbox" /> -->
                        <v-checkbox class="custom-checkbox" color="#F99D20" v-model="EditOT.wholeday" @click="showeditdate()" :disabled="disableviewdata"></v-checkbox>
                        <label class="ml-3"> {{ $t("wholeDay") }}</label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-show="editshowandhide" class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTDate") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <!-- <v-menu v-model="menu4" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" single-line dense outlined
                            v-model="computedDateFormatted2" append-icon="mdi-calendar-month-outline" color="#F99D20"
                            persistent-hint v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="EditOT.OTdate" no-title @input="menu4 = false"></v-date-picker>
                      </v-menu> -->
                      <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="EditOT.OTdate" 
                      no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                    </v-col>
                  </v-row>

                  <v-row v-show="!editshowandhide" class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTStartDate") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <!-- <v-menu v-model="menu9" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" single-line autocomplete="off" dense outlined
                            v-model="computedDateFormatted5" append-icon="mdi-calendar-month-outline" color="#F99D20"
                            persistent-hint v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="EditOT.OTstartdate" no-title @input="menu9 = false"></v-date-picker>
                      </v-menu> -->
                      <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="EditOT.OTstartdate" 
                      no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                    </v-col>
                  </v-row>
                  <v-row v-show="!editshowandhide" class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTEndDate") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <!-- <v-menu v-model="menu10" :close-on-content-click="false" transition="scale-transition" offset-y
                        fluid max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" single-line autocomplete="off" dense outlined
                            v-model="computedDateFormatted6" append-icon="mdi-calendar-month-outline" color="#F99D20"
                            persistent-hint v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="EditOT.OTenddate" no-title @input="menu10 = false"></v-date-picker>
                      </v-menu> -->
                      <date-picker class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="EditOT.OTenddate" 
                      :disable-date="allowedDates" no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                    </v-col>
                  </v-row>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTStartTime") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-menu ref="menu5" v-model="menu5" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="time3" transition="scale-transition" offset-y max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" v-model="viewOtstart" append-icon="access_time"
                            single-line outlined dense color="#F99D20" readonly v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="menu5" v-model="viewOtstart" no-title fluid
                          @click:minute="$refs.menu5.save(viewOtstart)"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTEndTime") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-menu ref="menu6" v-model="menu6" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="time4" transition="scale-transition" offset-y max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="disableviewdata" v-model="viewOtend" append-icon="access_time"
                            single-line outlined dense color="#F99D20" readonly v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="menu6" v-model="viewOtend" no-title fluid
                          @click:minute="$refs.menu6.save(viewOtend)"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("oTHours") }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field :disabled="disableviewdata" v-model="EditOT.OThours" height="10px" readonly
                        color="#F99D20" single-line outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-form ref="formEdit" lazy-validation>
                    <v-row class="pl-6">
                      <v-col cols="3" class="deletepadd">
                        <p class="mt-2">
                          {{ $t("purposeofOT")
                          }}<span class="red--text"> *</span>
                        </p>
                      </v-col>
                      <v-col cols="8" class="deletepadd">
                        <v-text-field :disabled="disableviewdata" v-model="EditOT.purposeOT" :rules="purposeOTEdit"
                          height="10px" color="#F99D20" single-line outlined dense required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">
                        {{ $t("approvalSentTo")
                        }}<span class="red--text"> *</span>
                      </p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field :disabled="disableviewdata" v-model="EditOT.approver" readonly height="10px"
                        color="#F99D20" single-line outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-6">
                    <v-col cols="3" class="deletepadd">
                      <p class="mt-2">{{ $t("status") }}</p>
                    </v-col>
                    <v-col cols="8" class="deletepadd">
                      <v-text-field :disabled="disablestatus" v-model="EditOT.status" height="10px" color="#F99D20"
                        single-line outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <div class="ml-11 mr-11" v-show="!showeditbtn">
                <v-row justify="center">
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <div v-show="!showeditbtn">
                      <v-btn v-show="!hideeditbtn" color="#F99D20" class="white--text mr-5 text-capitalize btnres" block
                        max-height="35" :disabled="disableeditbtn || permission.edit"
                        @click="showdetaildata()">Edit</v-btn>
                      <v-btn v-show="hideeditbtn" color="#F99D20" class="white--text mr-5 text-capitalize btnres" block
                        max-height="35" :disabled="disableeditbtn || permission.edit"
                        @click="EditOTrequest()">Save</v-btn>
                    </div>
                  </v-col>
                  <v-col v-show="!showeditbtn" cols="12" xs="12" sm="4" md="4">
                    <v-btn color="#F99D20" class="white--text text-capitalize btnres" block max-height="35"
                      :disabled="disablesendbtn || permission.add" @click="SendOTrequest()">{{ $t("SendRequest")
                      }}</v-btn>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <v-btn color="#FF0000" class="white--text text-capitalize" block max-height="35"
                      @click="deleteOTrequest()" :disabled="disalbecanclebtn">
                      {{ $t("cancelRequest") }}</v-btn>
                  </v-col>
                </v-row>
              </div>
              <div class="ml-11 mr-11" v-show="showeditbtn">
                <v-row justify="center">
                  <v-col cols="12" xs="12" sm="4" md="4">
                    <v-btn color="#FF0000" v-if="EditOT.status != 'Approved' && EditOT.status != 'Denied'
                      " class="white--text text-capitalize" block max-height="35" @click="deleteOTrequest()"
                      :disabled="disalbecanclebtn">{{ $t("cancelRequest") }}</v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-dialog>

          <!-- deletedialog -->
          <v-dialog v-model="Deletedialog" persistent max-width="532">
            <v-card style="border-radius: 15px">
              <v-card-title>
                <p style="margin-top: 10px; margin-bottom: -30px">
                  {{ $t("ConfirmToCancelRequest") }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col align="center" cols="12">
                      <p style="font-size: 16px; color: #444444" class="pt-4">
                        {{ $t("DoYouWantToCancelThisRequest") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions style="margin-right: 3%" class="pb-6">
                <v-spacer></v-spacer>
                <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="DeleteCancel" text>
                  {{ $t("Cancel") }}</v-btn>
                <v-btn color="#FF2727" class="mr-2 white--text text-capitalize" width="120" max-height="35"
                  @click="DeleteOTrequestlist()">{{ $t("Confirm") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Loadingdialog -->
          <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

          <!-- OT-request dialog -->
        </v-card>
        <v-dialog v-model="showOT" persistent max-width="600px">
          <v-card style="border-radius: 15px">
            <v-card-title>
              <span> {{ $t("OTRequests") }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeNoti()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("EmployeeName") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field :disabled="true" height="5px" color="#F99D20" outlined dense v-model="OTDetail.empname"
                    single-line>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <div class="mb-2">
                    <v-checkbox class="custom-checkbox" color="#F99D20" v-model="OTDetail.wholeday" :disabled="true"></v-checkbox>
                    <!-- <input v-model="OTDetail.wholeday" :disabled="true" type="checkbox" /> -->
                    <label class="ml-3">{{ $t("wholeDay") }}</label>
                  </div>
                </v-col>
              </v-row>
              <div v-show="OTDetail.wholeday">
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTStartDate") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <!-- <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                      max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="true" v-model="computedDateFormatted8" height="10px" color="#F99D20"
                          outlined dense single-line append-icon="mdi-calendar-month-outline" persistent-hint v-on="on">
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="OTDetail.OTstartdate" no-title @input="menu = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker :disabled="true" class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="OTDetail.OTstartdate" 
                    no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTEndDate") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <!-- <v-menu v-model="menu4" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                      max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="true" v-model="computedDateFormatted9" height="10px" color="#F99D20"
                          outlined dense single-line append-icon="mdi-calendar-month-outline" persistent-hint v-on="on">
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="OTDetail.OTenddate" no-title @input="menu4 = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker :disabled="true" class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="OTDetail.OTenddate" 
                    no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTStartTime") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="time1" transition="scale-transition" offset-y max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="true" v-model="OTDetail.OTstart" append-icon="access_time" single-line
                          outlined dense color="#F99D20" readonly v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="menu1" v-model="OTDetail.OTstart" no-title fluid></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTEndTime") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <v-menu ref="mymenu2" v-model="mymenu2" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="time2" transition="scale-transition" offset-y max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="true" v-model="OTDetail.OTend" append-icon="access_time" single-line
                          outlined dense color="#F99D20" readonly v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-model="OTDetail.OTend" no-title fluid></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTHours") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <v-text-field :disabled="true" v-model="OTDetail.OThours" height="10px" color="#F99D20" single-line
                      outlined dense required></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-show="!OTDetail.wholeday">
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTDate") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <!-- <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                      max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="true" v-model="computedDateFormatted7" height="10px" color="#F99D20"
                          outlined dense single-line append-icon="mdi-calendar-month-outline" persistent-hint v-on="on">
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="OTDetail.OTdate" no-title @input="menu = false"></v-date-picker>
                    </v-menu> -->
                    <date-picker :disabled="true" class="custom-vuedatepicker1" v-bind:clearable="false" value-type="format" v-model="OTDetail.OTdate" 
                    no-title format="DD/MM/YYYY" :style="{ width: '100%' }"></date-picker>
                  </v-col>
                </v-row>
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTStartTime") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="time1" transition="scale-transition" offset-y max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="true" v-model="OTDetail.OTstart" append-icon="access_time" single-line
                          outlined dense color="#F99D20" readonly v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="menu1" v-model="OTDetail.OTstart" no-title fluid></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTEndTime") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <v-menu ref="mymenu2" v-model="mymenu2" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="time2" transition="scale-transition" offset-y max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="true" v-model="OTDetail.OTend" append-icon="access_time" single-line
                          outlined dense color="#F99D20" readonly v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-model="OTDetail.OTend" no-title fluid></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pl-6">
                  <v-col cols="3" class="deletepadd">
                    <p class="mt-2">{{ $t("oTHours") }}</p>
                  </v-col>
                  <v-col cols="8" class="deletepadd">
                    <v-text-field :disabled="true" v-model="OTDetail.OThours" height="10px" color="#F99D20" single-line
                      outlined dense required></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("purposeofOT") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field :disabled="true" v-model="OTDetail.purposeOT" height="10px" color="#F99D20" single-line
                    outlined dense required></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("approvalSentTo") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field :disabled="true" v-model="OTDetail.approver" height="10px" color="#F99D20" single-line
                    outlined dense required></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("status") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field :disabled="true" v-model="OTDetail.status" height="10px" color="#F99D20" single-line
                    outlined dense required></v-text-field>
                  <!-- :style="{color : showOTrequest.status  == 'Approved' ? '#22A534':' #FF6565' }" -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <!-- New Employee Request for Employee -->
    <div v-else>
      <div style="display: flex; align-items: center;">
        <v-btn icon @click="goback()" color="white" class="mt-3 ml-1">
          <img src="@/assets/images/back button.png" class="mb-1" />
        </v-btn>
        <p class="mb-0" text style="
            cursor: pointer;
            font-size: 25px;
            margin-top: 0.2rem;
            color: #444444;
            padding: 15px;">
          {{ $t("OTreq") }}
        </p>
      </div>
      
      <div style="height: auto" class="mt-6">
        <v-card min-height="60vh" class="pl-12 pr-12 pt-12 pb-12">
          <v-form ref="formSent" lazy-validation>
            <v-row>
              <!-- Request Date -->
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" style="font-size: 15px">{{
                $t("RequestDate") }}:</v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-text-field v-model="requestOT.RequestedDate" autocomplete="off" readonly height="10px" color="#F99D20"
                  single-line outlined dense required class="readonly"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- Employee Name -->
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" style="font-size: 15px">{{
                $t("EmployeeName")
              }}<span class="red--text">*</span>:</v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-autocomplete autocomplete=" off" @change="chooseemp()" single-line outlined dense :items="EmpList"
                  v-model="requestOT.EmpID" :error-messages="error.EmpID" item-value="employee_ID"
                  :item-text="(item) => ` ${item.fullName}`" append-icon="mdi-chevron-down" color="#F99D20">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar size="30px">
                        <img :src="data.item.image">
                      </v-list-item-avatar>
                      <v-list-item-content style="height:40px; margin-bottom:8px">
                        <v-list-item-title>{{ data.item.nickname == null ? data.item.fullName : data.item.fullName
                          + '(' + data.item.nickname + ')' }}</v-list-item-title>
                        <p style="font-size:11px">{{ data.item.position_Name }}</p>
                      </v-list-item-content>
                    </template>
                  </template>
                  <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span>{{ error.EmpID }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="d-lg-none" cols="12" xs="1" sm="1" md="1" lg="1" xl="1"></v-col>

              <!-- Position Name -->
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" :style="$vuetify.breakpoint.lgAndUp?'text-align:right':'text-align:left'" style="font-size: 15px">{{
                $t("PositionName") }}:</v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-text-field v-model="requestOT.poistionName" autocomplete="off" readonly height="10px" color="#F99D20"
                  class="readonly" single-line outlined dense required></v-text-field>
              </v-col>
              
              <!-- Employment Type -->
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" :style="$vuetify.breakpoint.lgAndUp?'text-align:right':'text-align:left'" style="font-size:15px">
                {{  $t("EmploymentType") }}:
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-text-field v-model="emptype" autocomplete="off" readonly height="10px" color="#F99D20"
                  class="readonly" single-line outlined dense required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- Start Date -->
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" style="font-size: 15px">
                {{ $t("oTStartDate") }}<span class="red--text">*</span>:
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <!-- <v-menu v-model="menu7" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                  max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field single-line autocomplete="off" dense outlined :value="computedDateFormatted3"
                      :error-messages="error.start_Date" append-icon="mdi-calendar-month-outline" color="#F99D20"
                      persistent-hint v-on="on">
                      <template v-slot:message="{ }">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>{{ error.start_Date }}</span>
                      </template></v-text-field>
                  </template>
                  <v-date-picker v-model="requestOT.OTstartdate" no-title :allowed-dates="allowedDates"
                    @input="menu7 = false"></v-date-picker>
                </v-menu> -->         
                  <date-picker :editable="false" :class="OTstartdateSent ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'" style="width:100%; cursor:pointer;" :clearable="false" 
                  :disabled-date="disabledates" width="100%" @change="datechange(1)" name="datetimepicker" color="#F99D20" v-model="requestOT.OTstartdate" type="datetime" format="DD/MM/YYYY HH:mm"></date-picker>
                  <span v-if="OTstartdateSent" class="error-message ml-2">
                  <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                  <span>{{ OTstartdateSent }}</span>
                </span>
              </v-col>
              <v-col class="d-lg-none" cols="12" xs="1" sm="1" md="1" lg="1" xl="1"></v-col>
              <!-- End Date -->
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" :style="$vuetify.breakpoint.lgAndUp?'text-align:right':'text-align:left'" style="font-size: 15px">
                {{ $t("oTEndDate") }}<span class="red--text">*</span>:
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <!-- <v-menu v-model="menu8" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                  max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field single-line autocomplete="off" dense outlined :value="computedDateFormatted4"
                      :error-messages="error.end_Date" append-icon="mdi-calendar-month-outline" color="#F99D20"
                      persistent-hint v-on="on">
                      <template v-slot:message="{ }">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>{{ error.end_Date }}</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="requestOT.OTenddate" no-title :allowed-dates="allowedDates"
                    @input="menu8 = false"></v-date-picker>
                </v-menu> -->
                
                <date-picker :editable="false" :class="OTenddateSent ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'" style="width:100%" :disabled="requestOT.OTstartdate!=''?false:true" 
                :clearable="false" :disabled-time="disabletime" :disabled-date="disabledates2"  @change="datechange(2)"  name="datetimepicker" color="#f299d0" v-model="requestOT.OTenddate" type="datetime" format="DD/MM/YYYY HH:mm"></date-picker>
                <span v-if="OTenddateSent" class="error-message ml-2">
                  <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                  <span>{{ OTenddateSent }}</span>
                </span>
              </v-col>
            </v-row>
           <br><br>
            <!-- <v-row>
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" style="font-size: 15px">
                {{ $t("oTStartTime") }}<span class="red--text">*</span>:
              </v-col>

              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="time1" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" v-model="requestOT.OTstart" append-icon="access_time" single-line
                      :error-messages="error.start_Time" outlined dense color="#F99D20" readonly v-on="on">
                      <template v-slot:message="{ }">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>{{ error.start_Time }}</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker v-if="menu2" v-model="requestOT.OTstart" no-title fluid
                    @click:minute="$refs.menu2.save(requestOT.OTstart)"></v-time-picker>
                </v-menu>
              </v-col>
              <v-col class="d-lg-none" cols="12" xs="1" sm="1" md="1" lg="1" xl="1"></v-col>
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" :style="$vuetify.breakpoint.lgAndUp?'text-align:right':'text-align:left'" style="font-size: 15px">
                {{ $t("oTEndTime") }}<span class="red--text">*</span>:
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="time2" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" v-model="requestOT.OTend" :error-messages="error.end_Time"
                      append-icon="access_time" single-line outlined dense color="#F99D20" readonly v-on="on">
                      <template v-slot:message="{ }">
                        <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                        <span>{{ error.end_Time }}</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker v-if="menu3" v-model="requestOT.OTend" no-title fluid
                    @click:minute="$refs.menu3.save(requestOT.OTend)"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row> -->

            <v-row>
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" style="font-size: 15px">
                {{ $t("oTHours") }}:
              </v-col>             
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-text-field  v-model="requestOT.OThours" autocomplete="off" auto readonly height="10px" color="#F99D20"
                  class="readonly" single-line outlined dense required background-color=""></v-text-field>
              </v-col>
              <v-col class="d-lg-none" cols="12" xs="1" sm="1" md="1" lg="1" xl="1"></v-col>
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" :style="$vuetify.breakpoint.lgAndUp?'text-align:right':'text-align:left'" style="font-size: 15px">
                 {{ $t("otType") }}<span class="red--text">*</span>:  <span class="ml-1 d-sm-none">
                <v-tooltip  right>
      <template v-slot:activator="{ on, attrs }">
        <v-img  :src="isHovered ? outlineinfoimg : infoimg" @mouseover="handleMouseOver" v-bind="attrs"
          v-on="on" @mouseleave="handleMouseLeave"  class="infoimg"></v-img>
      </template>
      <span>
        {{ $t("ot1") }}<br>
        {{ $t("ot2") }}<br>
        {{ $t("ot3") }}<br>
        {{ $t("ot4") }}<br> 
      </span>
      
    </v-tooltip>
              </span>
              
              </v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                
                  <v-autocomplete v-model="requestOT.OTType" :items="ottype"  :item-text="(item) => `${item.txt}`" :item-value="(item) => `${item.txt}`" outlined
                   dense color="#F99D20" :rules="otTypesent" :error-messages="error.otType">
                   <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span>{{ error.otType }}</span>
                  </template>
                   </v-autocomplete>
              </v-col>
              <div  class="mt-4 d-none d-sm-block">
                <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-img :src="isHovered ? outlineinfoimg : infoimg" @mouseover="handleMouseOver" v-bind="attrs"
          v-on="on" @mouseleave="handleMouseLeave"  class="infoimg"></v-img>
      </template>
      <span>
        {{ $t("ot1") }}<br>
        {{ $t("ot2") }}<br>
        {{ $t("ot3") }}<br>
        {{ $t("ot4") }}<br> 
      </span>
      
    </v-tooltip>
              </div>
             
            </v-row>

            <v-row>
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" style="font-size: 15px">
                {{ $t("DescriptionOT") }}<span class="red--text">*</span>:
              </v-col>
              <v-col cols="12" xs="3" sm="9" md="9" lg="6" xl="6">
                <v-textarea v-model="requestOT.purposeOT" :error-messages="error.purposeOT"
                :placeholder="$t('Providefurtherdetailshere')" no-resize rows="3" min-height="70" color="#F99D20"
                  single-line outlined dense required>
                  <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span>{{ error.purposeOT }}</span>
                  </template></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" style="font-size: 15px">
                {{ $t("AttachmentOT") }}:
              </v-col>
              <v-col cols="12" xs="3" sm="9" md="9" lg="6" xl="6">
                <div>
                  <div v-if="uploaddefaultshow == true" @click="onpickfile" class="drop-area"
                    @dragover.prevent="handleDragOver" @drop="handleFileDrop">
                    <div style="text-align: center">
                      <v-icon color="#A3A7AA">mdi-cloud-upload</v-icon>
                      <p style="color:#A3A7AA">{{ $t("draganddrop") }}</p>
                    </div>
                  </div>
                  <div class="container">
                    <div style="width: 130px;height:120px;" v-if="uploaddefaultshow == false" @click="onpickfile"
                      class="drop-area" @dragover.prevent="handleDragOver" @drop="handleFileDrop">
                      <div style="text-align: center;" class="mt-2">
                        <v-icon style="color:#A3A7AA">mdi-cloud-upload</v-icon>
                        <p style="color:#A3A7AA">{{ $t("choosefile") }}</p>
                      </div>
                    </div>
                    <div v-for="file in uploadedFiles" :key="file.name" class="mr-0 pa-3 relative"
                      style="width: 130px;height:120px; border: 1px solid #ccc;border-radius: 7px; ">
                      <v-btn @click="removefile(file)" class="close-button top-0 right-0"
                        icon><v-icon>close</v-icon></v-btn>
                      <div @click="showiframe(file)" style="margin-top:-1.9rem;">
                        <v-img class="mb-3 ml-5" v-if="file.type == 'image/png'" src="@/assets/images/png.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.type == 'image/jpeg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.type == 'image/jpg'" src="@/assets/images/jpg.png"
                          width="50px"></v-img>
                        <v-img class="mb-3 ml-5" v-if="file.type == 'application/pdf'" src="@/assets/images/pdf.png"
                          width="50px"></v-img>
                        <div class="text-center">{{ file.name ? file.name.substring(0, 10) +
                          " ..." : '' }}</div>
                      </div>
                    </div>
                    <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none"
                      id="profile_picture" accept=".pdf, .png, .jpeg, .jpg" />
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="3" sm="2" md="2" lg="2" xl="2" class="mt-2" style="font-size: 15px">{{ $t("Approver")
              }}:</v-col>
              <v-col cols="12" xs="3" sm="3" md="3" lg="2" xl="2">
                <v-text-field autocomplete="off" v-model="requestOT.approver" :value="requestOT.approverID" readonly
                  class="readonly" height="10px" color="#F99D20" single-line outlined dense required></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-2 commoncancelbtn text-capitalize" text outlined @click="goback()">
              {{ $t("Cancel") }}</v-btn>
            <v-btn class="text-capitalize commonsavebtn" 
              @click="RequestedOT()">
              {{ $t("Submit") }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
      
      <template>
        <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg"
          style="overflow: hidden">
          <v-card style="padding-left:1.5rem; padding-right:1.5rem; padding-bottom:1.5rem;">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon @click="downloadImage()"><v-icon size="30">mdi-download</v-icon></v-btn>

              <v-btn icon @click="dialogforDownload = false"><v-icon size="30">mdi-window-close</v-icon></v-btn>
            </v-card-actions>

            <v-img :src="imgForshow" max-width="500"></v-img>

          </v-card>
        </v-dialog>
      </template>
      <v-dialog v-model="printdialogforupdate" max-width="75%" style="overflow: hidden">
        <v-card style="background-color: white">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn @click="
              printdialogforupdate = false;
            iframeShow = false;
            " icon style="margin-right: 3rem; margin-top: 1rem">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-row>

          <v-card class="mt-4 pdf-container">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <iframe :src="pdfString" width="100%" height="1000px" frameborder="0" scrolling="no" />
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import store from "../../store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import ReportTrace from "@/function/Report.js";
import moment from "moment";
import CheckViewAddEdit from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

import Axios from 'axios';
export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, ReportTrace, utils, CheckViewAddEdit],
  data: () => ({
    allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    permission: {
      add: true,
      edit: true,
    },
    infoimg : require("@/assets/images/Vector.png"),
    outlineinfoimg: require("@/assets/images/information-variant-circle 1.png"),
    otInfoList:[],
    fixflag : false,
    salarylist:null,
    emptype:"",
    isHovered:false,
    showReq: true,
    printdialogforupdate: false,
    dialogforDownload: false,
    imgName: "",
    imgString: "",
    imgForshow: "",
    dllink: null,
    pdfString: "",
    iframeShow: false,
    EmpList: [],
    selectedFiles: [],
    uploadedFiles: [],
    uploaddefaultshow: true,
    OTEmpIDSent: [],
    OTdateSent: [],
    OTstartdateSent: null,
    OTenddateSent: null,
    OTstartSent: [],
    OTendSent: [],
    OThoursSent: [],
    otTypesent: [],
    purposeOTSent: [],
    purposeOTEdit: [],
    url: enurl.apiUrl,
    focus: new Date().toISOString().substr(0, 10),
    page: 1,
    OTapprovedialog: false,

    viewdialog: false,
    pageCount: 15,
    itemsPerPage: 10,
    items: [10, 20, 30],
    todaymonths: null,
    todayyears: null,
    menu1: false,
    menu2: false,
    mymenu2: false,
    time1: null,
    Otstart: "",
    menu3: false,
    time2: null,
    menu4: false,
    viewdate: "",
    Otend: "",
    viewOtstart: "",
    viewOtend: "",
    menu5: false,
    menu6: false,
    time3: null,
    time4: null,

    menu7: false,
    menu8: false,
    menu9: false,
    menu10: false,
    LoadingDialog: false,

    itemInner: "Waiting Approval",
    desserts: [
      {
        Otdate: "5/04/2020",
        Othour: "5",
        startdate: "4/04/2020",
        enddate: "14/04/2020",
        purposeot: "Complete PO",
        requestdate: "10/04/2020",
        status: "Waiting for Approval",
        approver: "David",
      },
    ],
    approverlist: [],
    requestedlist: [],
    listrequest: [],
    OTdate: "",
    showandhide: true,
    editshowandhide: true,
    hideeditbtn: false,
    mystartdate: "",
    myenddate: "",
    disalbecanclebtn: false,
    disableeditbtn: false,
    disablesendbtn: false,
    disablestatus: true,
    showeditbtn: false,
    disableviewdata: true,
    Deletedialog: false,
    requestOT: {
      EmpID: 0,
      poistionName: "",
      empname: "",
      OTdate: "",
      OTstartdate: "",
      OTenddate: "",
      OTstart: "",
      wholeday: false,
      OTend: "",
      OThours: null,
      purposeOT: null,
      approver: null,
      approverID: null,
      attachmentOT: [],
      RequestedDate: '',
      OTType:null,
      maxHour:null,
      maxMinute:null
    },
    requesttrue: false,
    EditOT: {
      OTrequest_ID: "",
      empname: "",
      OTdate: "",
      OTstartdate: null,
      OTenddate: null,
      OTstart: "",
      wholeday: false,
      OTend: "",
      OThours: "",
      purposeOT: null,
      approver: null,
      approverID: null,
      status: null,
      monthDay: 0,
    },
    showOT: false,
    OTDetail: {
      OTrequest_ID: "",
      empname: "",
      OTdate: "",
      OTstartdate: "",
      OTenddate: "",
      OTstart: "",
      wholeday: false,
      OTend: "",
      OThours: "",
      purposeOT: null,
      approver: null,
      approverID: null,
      status: null,
      wfID: null,
    },
    error: {
      EmpID: "",
      start_Date: null,
      end_Date: null,
      start_Time: "",
      end_Time: "",
      purposeOT: "",
      otType:""
    },
  }),

  mounted() {
    let d = new Date();
    this.todaymonths = this.monthNames[d.getMonth()].val;
    //this.requestOT.RequestedDate = this.convertUTCDateToLocalDateForReqfornew();
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    const updateFormattedDate = () => {
      const date = new Date();
      this.requestOT.RequestedDate = formatDate(date);
    };
    // Update the formatted date every second
    setInterval(updateFormattedDate, 1000);
    // Initial formatting
    updateFormattedDate();
    this.todayyears = new Date();
    //this.Getapprovername();
    this.Getrequest();
    this.GetOTSettinginfo();
    this.getDataFromParams();
    this.Getallemployeeinfo();
    this.permission = this.CheckViewAddEdit(46, 47, 48);
    this.itemInner = this.$route.params.condition
      ? this.$route.params.condition
      : "Waiting Approval";
  },

  computed: {
    disabledates(){
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return date => date <= yesterday;
    },

    disabledates2(){
      let startdate=new Date(this.requestOT.OTstartdate);
      startdate.setDate(startdate.getDate()-1);
      let limitdate=new Date(this.requestOT.OTstartdate);
      limitdate.setDate(limitdate.getDate());
      // return date=> date <= startdate;
      return date => {
      return date <=startdate || date > limitdate;
    };
    },

    disabletime()
    {
      let starttime=new Date(this.requestOT.OTstartdate);
      starttime.getHours();
      let limittime=new Date(this.requestOT.OTstartdate);
      limittime.setHours(limittime.getHours()+8);
      //   return time => {
      //   return time<starttime && time>limittime;
      // };
      return time => {
        return time < starttime || time > limittime;
      };
    },

    ottype(){
      if(this.emptype == "Monthly Payment")
      {
        return[
        {val:1, txt:"OT1"},
        {val:2, txt:"OT2"},
        {val:4, txt:"OT4"},
      ]
      }
      else{
        return[
        {val:1, txt:"OT2"},
        {val:3, txt:"OT3"},
        {val:4, txt:"OT4"},
      ]
      }
    },
    status() {
      return [
        { val: "Waiting Approval", txt: this.$t("waitingApproval") },
        { val: "Approved", txt: this.$t("approved") },
        { val: "Denied", txt: this.$t("denied") },
      ];
    },
    monthNames() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
    itemFilter() {
      if (!this.itemInner) return this.requestedlist;
      return this.requestedlist.filter((x) => x.status == this.itemInner);
    },
    computedDateFormatted1() {
      return this.formatDate(this.requestOT.OTdate);
    },
    computedDateFormatted2() {
      return this.formatDate(this.EditOT.OTdate);
    },
    computedDateFormatted3() {
      return this.formatDate(this.requestOT.OTstartdate);
    },
    computedDateFormatted4() {
      return this.formatDate(this.requestOT.OTenddate);
    },
    computedDateFormatted5() {
      return this.formatDate(this.EditOT.OTstartdate);
    },
    computedDateFormatted6() {
      return this.formatDate(this.EditOT.OTenddate);
    },
    computedDateFormatted7() {
      return this.formatDate(this.OTDetail.OTdate);
    },
    computedDateFormatted8() {
      return this.formatDate(this.OTDetail.OTstartdate);
    },
    computedDateFormatted9() {
      return this.formatDate(this.OTDetail.OTenddate);
    },
  },
  watch: {
    "requestOT.OTstart"(v) {
      if (v) this.error.start_Time = "";
      //this.changetime(v, this.requestOT.OTend);
      //this.calculateOThours(v, this.requestOT.OTend);
      this.OTstartSent = [];
    },

    "requestOT.OTend"(v) {
      if (v) this.error.end_Time = "";
      //this.changetime(this.requestOT.OTstart, v);
      //this.calculateOThours(this.requestOT.OTstart, v);
      this.OTendSent = [];
    },
    viewOtstart(v) {
      this.changetimeofview(v, this.viewOtend);
    },
    viewOtend(v) {
      this.changetimeofview(this.viewOtstart, v);
    },
    "requestOT.OTstartdate"(v) {
      if (v) this.error.start_Date = "";
      //this.changetime(this.requestOT.OTstart, this.requestOT.OTend);
      this.calculateOThours(this.requestOT.OTstart, this.requestOT.OTend);
      this.OTstartdateSent = null;
    },
    "requestOT.OTdate"() {
      //this.changetime(this.requestOT.OTstart, this.requestOT.OTend);
      this.calculateOThours(this.requestOT.OTstart, this.requestOT.OTend);
      this.OTdateSent = [];
    },
    "requestOT.OTenddate"(v) {
      if (v) this.error.end_Date = "";
      //this.changetime(this.requestOT.OTstart, this.requestOT.OTend);
      this.calculateOThours(this.requestOT.OTstart, this.requestOT.OTend);
      this.OTenddateSent = null;
    },
    "EditOT.OTstartdate"() {
      this.changetimeofview(this.viewOtstart, this.viewOtend);
    },
    "EditOT.OTenddate"() {
      this.changetimeofview(this.viewOtstart, this.viewOtend);
    },
    "requestOT.EmpID"(v) {
      if (v) this.error.EmpID = "";
      this.OTEmpIDSent = [];
    },
    "requestOT.purposeOT"(v) {
      if (v) this.error.purposeOT = "";
      this.purposeOTSent = [];
    },
    "requestOT.OThours"() {
      this.OThoursSent = [];
    },
    "requestOT.OTType"(v) {
      if (v) this.error.otType = "";
      this.otTypesent = [];
    },
    "EditOT.purposeOT"() {
      this.purposeOTEdit = [];
    },
    $route() {
      this.getDataFromParams();
    },
  },
  methods: {
    datechange(time){
      //console.log(this.requestOT.OTstartdate);
      let temptime="";
      if(time==1)
      {
        if (this.requestOT.OTstartdate && this.requestOT.OTenddate) {
          if (new Date(this.requestOT.OTstartdate) > new Date(this.requestOT.OTenddate)) {
            this.requestOT.OTenddate = ""; // Clear end date if start date is greater
          }
        }
        //tempdate=this.requestOT.OTstartdate.toLocaleDateString('en-GB');
        this.requestOT.OTstart=this.requestOT.OTstartdate.toLocaleString('en-GB');
        temptime=this.requestOT.OTstartdate.toLocaleTimeString('en-GB',{hour12:false});
      }
      else
      {
        //tempdate=this.requestOT.OTenddate.toLocaleDateString('en-GB');
        this.requestOT.OTend=this.requestOT.OTenddate.toLocaleString('en-GB');
        temptime=this.requestOT.OTenddate.toLocaleTimeString('en-GB',{hour12:false});
      }
      //console.log(tempdate);
      //console.log(temptime);
      let [hours, minutes] = temptime.split(':');
      // Create a new Date object and set the time components
      let dateObject = new Date();
      dateObject.setHours(hours);
      dateObject.setMinutes(minutes);
      // Format the time as HH:mm
      let formattedHours = dateObject.getHours().toString().padStart(2, '0');
      let formattedMinutes = dateObject.getMinutes().toString().padStart(2, '0');
      temptime = `${formattedHours}:${formattedMinutes}`;
      if(time == 1)
      {
        this.requestOT.OTstart=temptime;
      }
      else
      {
        this.requestOT.OTend=temptime;
      }
    },

    calculateOThours (start, end)
    {
      const startTime = new Date(this.requestOT.OTstartdate);
      const endTime = new Date(this.requestOT.OTenddate);
      const timeDifference = endTime - startTime;
      if (!isNaN(timeDifference) && start!=null && end!=null) 
      {
        let totalMinutes = timeDifference / (1000 * 60);
        let hours=null;
        hours = Math.floor(totalMinutes / 60);
        let minutes=null;
        minutes = Math.floor(totalMinutes % 60);
        this.requestOT.maxHour=hours;
        this.requestOT.maxMinute=minutes;
        if (minutes === 0) 
        {
          this.requestOT.OThours = `${this.formatTimeUnit(hours, 'hour')}`;
        } 
        else 
        {
          this.requestOT.OThours = `${this.formatTimeUnit(hours, 'hour')} ${this.formatTimeUnit(minutes, 'minute')}`;
        }
      } 
      else 
      {
        this.requestOT.OThours = '';
        this.requestOT.maxHour=null;
        this.requestOT.maxMinute=null;
      }
    },

    formatTimeUnit(value, unit) {
      return `${value} ${value === 1 ? unit : unit + 's'}`;
    },

    handleMouseOver() {
      this.isHovered = true;
    },

    handleMouseLeave() {
      this.isHovered = false;
    },

    onpickfile() {
      this.$refs.fileupload.click();
    },

    chooseemp() {
      let self=this;
      if (self.requestOT.EmpID != null) 
      {
        self.LoadingDialog = true;
        let result = self.EmpList.filter(
          (x) => x.employee_ID === self.requestOT.EmpID
        );
        // console.log("Result",result);
        let temp = null;
        self.emptype = "";
        temp = {
          employee_ID:self.requestOT.EmpID,
          company_ID:store.state.companyID
        } 
        //console.log("Temp", temp);
        Axios.post(`${self.url}Salary/GetSalaryInfo`, temp)
          .then(function (response) {
          self.salarylist = response.data.data;
          if(self.salarylist.length > 0)
          {
            self.emptype=self.salarylist[0].employmentTypeID==1?self.$t("Monthly Payment"):self.$t("Daily Payment");
          }
          else
          {
            self.emptype=self.$t("Daily Payment");
          }
        });
        self.requestOT.poistionName = result[0].position_Name;
        self.Getspecificapprovername(self.requestOT.EmpID);
      }
    },

    GetOTSettinginfo() {
      let self = this;
      let temp= {
        company_ID: store.state.companyID
      }
      axios.post(`${self.url}OT_Setting/GetOTSetting`, temp)
      .then(function (response) {
          self.otInfoList = response.data.data;
          self.fixflag=self.otInfoList.fixRateFlag;
          //console.log(self.EmpList);
        });
    },

    Getallemployeeinfo() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetPersonalInfoByCompanyID`, temp)
        .then(function (response) {
          self.EmpList = response.data.data;
          //console.log(self.EmpList);
        });
    },

    goback() {
      this.$router.push({ name: "EmployeeOTrequest" });
    },

    convertUTCDateToLocalDateForReqfornew() {
      let date = new Date();
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = null;
      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) 
      {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } 
      else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) 
      {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } 
      else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) 
      {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } 
      else 
      {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }
      return output;
    },

    handleDragOver(event) {
      event.preventDefault();
    },

    handleFileDrop(event) {
      event.preventDefault();
      //const files = event.dataTransfer.files;
      this.handleFiledrag(event);
      // this.uploadFiles(files);
    },

    // handleFileSelect(e) {
    //    e.preventDefault();
    //   this.uploadFiles(this.selectedFiles);
    // },
    showiframe(item) {
      let self = this;
      //console.log(item);
      if (item.type == 'image/png' || item.type == 'image/jpeg' || item.type == 'image/jpg') 
      {
        self.imgForshow = 'data:image/png;base64,' + item.imgurl;
        //console.log(self.imgForshow);
        self.dialogforDownload = true;
        self.imgString = item.imgurl;
        self.dllink = item.pdfurl;
        self.imgName = item.name;
        //console.log(self.uploadedFiles);
      }
      else 
      {
        self.printdialogforupdate = true;
        self.iframeShow = true;
        self.pdfString = item.pdfurl;
      }
      //console.log(self.uploadedFiles);
    },

    downloadImage() {
      let self = this;
      const link = document.createElement('a');
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },

    removefile(file) {
      const index = this.uploadedFiles.findIndex((item) => item.id === file.id);
      if (index !== -1) 
      {
        this.uploadedFiles.splice(index, 1);
      }
      if (this.uploadedFiles.length == 0) 
      {
        this.uploaddefaultshow = true;
      }
    },

    async handleFileSelect(event) {
      event.preventDefault();
      const file = event.target.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) 
      {
        if (file.type === "application/pdf") 
        {
          pdfString = URL.createObjectURL(file);
          // console.log("pdfstring", pdfString);
        } 
        else if (file.type.startsWith("image/")) 
        {
          //const pdfBlob = await this.convertImageToPdf(file);
          // console.log("pdfblob", pdfBlob);
          pdfString = URL.createObjectURL(file);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];
          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    async handleFiledrag(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file) 
      {
        if (file.type === "application/pdf") 
        {
          pdfString = URL.createObjectURL(file);
          // console.log("pdfstring", pdfString);
        } 
        else if (file.type.startsWith("image/")) 
        {
          //const pdfBlob = await this.convertImageToPdf(file);
          // console.log("pdfblob", pdfBlob);
          pdfString = URL.createObjectURL(file);
          //this.pdfString = pdfData;
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          let imageUrl = fr.result.split(",")[1];
          this.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    async convertImageToPdf(selectedFile) {
      const pdf = new jsPDF();
      const imgData = await this.readFileAsDataUrl(selectedFile);
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed
      const pdfData = pdf.output("blob");
      return pdfData;
    },

    readFileAsDataUrl(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    uploadFilesall(imgname, type, url, pdfurl) {
      let plusid = this.uploadedFiles.length;
      this.uploadedFiles.push({
        id: plusid,
        name: imgname,
        type: type,
        imgurl: url,
        pdfurl: pdfurl,
      });
      this.uploaddefaultshow = false;
    },

    async getDataFromParams() {
      try {
        let notiSring = this.$route.params.notiString;
        if (notiSring) 
        {
          this.showOT = true;
          let temp1 = {
            wF_ID: parseInt(notiSring),
          };
          const response = await axios.post(`${this.url}OT_Request/GetOTByWorkFlowId`,temp1);
          let temp = response.data.data[0];
          let self = this;
          self.showOT = true;
          self.OTDetail.OTrequest_ID = temp.oT_Request_ID;
          if (temp.name && temp.lastName) 
          {
            self.OTDetail.empname = `${temp.name} ${temp.lastName}`;
          } 
          else if (temp.requestername) 
          {
            self.OTDetail.empname = temp.requestername;
          }

          if (temp.firstName && temp.myLastName) 
          {
            self.OTDetail.approver = `${temp.firstName} ${temp.myLastName}`;
          } 
          else if (temp.requestername) 
          {
            self.OTDetail.approver = temp.approvername;
          }
          self.OTDetail.wholeday = temp.wholeDay;
          self.OTDetail.OTdate = temp.start_Date;
          self.OTDetail.OTstart = temp.oT_Start_Time;
          self.OTDetail.OTend = temp.oT_End_Time;
          self.OTDetail.OTstartdate = temp.start_Date;
          self.OTDetail.OTenddate = temp.end_Date;
          self.OTDetail.OThours = temp.oT_Hours;
          self.OTDetail.purposeOT = temp.purpose_OT;
          self.OTDetail.status = temp.status;
          self.OTDetail.wfID = temp.wF_ID;
        }
      } 
      catch (error) {
        throw error;
      }
    },

    closeNoti() {
      this.showOT = false;
      this.$router.replace({
        name: "MyOTrequest",
        params: { condition: this.$route.params.condition },
      });
    },

    dialogToggle(item) {
      let self = this;
      if (item.workFlow_Name == "OT") 
      {
        self.DetailrequestForOT(item);
      } 
      else 
      {
        self.DetailTimeOff(item);
      }
    },

    DetailrequestForOT(temp) {
      let self = this;
      self.showOT = true;
      self.OTDetail.OTrequest_ID = temp.oT_Request_ID;
      if (temp.name && temp.lastName) {
        self.OTDetail.empname = `${temp.name} ${temp.lastName}`;
      } 
      else if (temp.requestername) 
      {
        self.OTDetail.empname = temp.requestername;
      }
      if (temp.firstName && temp.myLastName) 
      {
        self.OTDetail.approver = `${temp.firstName} ${temp.myLastName}`;
      } 
      else if (temp.requestername) 
      {
        self.OTDetail.approver = temp.approvername;
      }
      self.OTDetail.wholeday = temp.wholeDay;
      self.OTDetail.OTdate = temp.start_Date;
      self.OTDetail.OTstart = temp.oT_Start_Time;
      self.OTDetail.OTend = temp.oT_End_Time;
      self.OTDetail.OTstartdate = temp.start_Date;
      self.OTDetail.OTenddate = temp.end_Date;
      self.OTDetail.OThours = temp.oT_Hours;
      self.OTDetail.purposeOT = temp.purpose_OT;
      self.OTDetail.status = temp.status;
      self.OTDetail.wfID = temp.wF_ID;
    },

    async sendNotification() {
      let self = this;
      let TempReportID = null;
      let userInfoTemp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await axios.post(`${this.url}PersonalInfo/GetShortInfo`, userInfoTemp)
        .then(function (response) {
          var username = `${response.data.data[0].name} ${response.data.data[0].lastName}`;
          var reportID = response.data.data[0].report_ID;
          self.username = username;
          self.reportID = reportID;
          TempReportID = reportID;
        });
      if (TempReportID) 
      {
        let temp = {
          user_ID: TempReportID,
        };
        let token = [];
        await axios.post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`, temp)
          .then(function (response) {
            var temp = response.data.data;
            if (temp[0]) 
            {
              temp.map((t) => token.push(t.fireBase_Token));
            }
          });
        self.tokenList = token;
      }
    },

    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "OT Request",
        body: "OT request from " + self.username,
        reportID: recipientID,
      };
      await axios.post(`${this.url}Firebasetoken/sendNotification`, noti_temp)
        .then(function () { });
    },

    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}OT_Request/GetworkFlowOfOTRequest`, temp)
        .then(function (response) {
          if (response) 
          {
            let WF_ID = response.data.data.wF_ID;
            let senderID = response.data.data.requester_ID;
            let recipientID = response.data.data.approver_ID;
            self.newnotiList(WF_ID, senderID, recipientID);
          }
        });
    },

    async newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated: false,
        description: `requested for OT`,
      };
      await axios.post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) 
          {
            self.pushNotification(recipientID);
          }
        });
    },

    ChangeMonth(mth) {
      let self = this;
      let d = new Date();
      d.setMonth(mth - 1);
      let year = self.todayyears.getFullYear();
      d.setFullYear(year);
      self.focus = d;
      self.Getrequest();
    },

    closerequestdialog() {
      let self = this;
      self.OTapprovedialog = false;
      self.requestOT.wholeday = false;
      self.requestOT.OTstartdate = "";
      self.requestOT.OTenddate = "";
      self.requestOT.OTdate = "";
      self.requestOT.OTstart = "";
      self.requestOT.OTend = "";
      self.requestOT.OThours = "";
      self.requestOT.purposeOT = null;
      self.showandhide = true;
      this.$refs.formSent.reset();
    },

    ChangeYear(year) {
      let self = this;
      let d = new Date();
      d.setMonth(self.todaymonths - 1);
      let y = year.getFullYear();
      d.setFullYear(y);
      self.focus = d;
      self.Getrequest();
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    myformatdate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[0] + "/" + finaldate[1] + "/" + finaldate[2];
    },

    convertUTCDateToLocalDate(date) {
      date = new Date(date);
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output =
        newDate.getDate() +
        "/" +
        (newDate.getMonth() + 1) +
        "/" +
        newDate.getFullYear();

      return output;
    },

    Getspecificapprovername(empID) {
      let self = this;
      let temp = {
        employee_ID: empID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetShortInfo`, temp)
        .then(function (response) {
          let approverlist = response.data.data;
          self.requestOT.approver = approverlist[0].reportName;
          self.requestOT.approverID = approverlist[0].report_ID;
          self.LoadingDialog = false;
        });
    },

    Getapprovername() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetShortInfo`, temp)
        .then(function (response) {
          self.approverlist = response.data.data;
          self.requestOT.approver = self.approverlist[0].reportName;
          self.requestOT.empname =
            self.approverlist[0].name + " " + self.approverlist[0].lastName;
          //self.requestOT.empname = store.state.username;
          self.requestOT.approverID = self.approverlist[0].report_ID;
        });
    },

    changetimeofview(start, end) {
      let self = this;
      var otstartdate = "";
      var otenddate = "";
      let start_Time = moment(start, "h:mma");
      let end_time = moment(end, "h:mma");
      if (end_time.isBefore(start_Time)) 
      {
        self.EditOT.OThours = " 00 minutes";
      } 
      else 
      {
        if (self.EditOT.wholeday == false) 
        {
          otstartdate = self.EditOT.OTdate;
          otenddate = self.EditOT.OTdate;
        } 
        else 
        {
          otstartdate = self.EditOT.OTstartdate;
          otenddate = self.EditOT.OTenddate;
        }
        if (otstartdate != "" && otenddate != "") 
        {
          if (start != "" && end != "") 
          {
            let from = new Date();
            let to = new Date();
            let [frhours, frminutes] = start.split(":");
            let [tohours, tominutes] = end.split(":");
            from.setHours(+frhours);
            from.setMinutes(frminutes);
            to.setHours(+tohours);
            to.setMinutes(tominutes);
            let frtimezone = from.getTimezoneOffset() * 60000;
            let totimezone = to.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let tonewDate = new Date(to.getTime() - totimezone);
            var timeDiff = Math.abs(frnewDate - tonewDate);
            var a = moment(self.myformatdate(otstartdate), "YYYY-MM-DD");
            var b = moment(self.myformatdate(otenddate), "YYYY-MM-DD");
            var differentday = parseInt(b.diff(a, "days")) + 1;
            var hh = Math.floor(timeDiff / 1000 / 60 / 60);
            timeDiff -= hh * 1000 * 60 * 60;
            var mm = Math.floor(timeDiff / 1000 / 60);
            if (self.EditOT.wholeday == false) 
            {
              if (mm < 10) 
              {
                mm = "0" + mm;
              }
              if (hh == "00") 
              {
                self.EditOT.OThours = mm + "minutes";
              } 
              else if (mm == "00") 
              {
                self.EditOT.OThours = hh + " " + "hours";
              } 
              else 
              {
                self.EditOT.OThours =
                  hh + " " + "hours" + " " + mm + " " + "minutes ";
              }
            } 
            else 
            {
              var totalhour = hh * differentday;
              var totalminute = mm * differentday;
              var gethour = 0;
              var getminute = 0;
              if (totalminute >= 60) 
              {
                var gethourandminute = self.timeConvert(totalminute);
                gethour = parseInt(gethourandminute.split("hour")[0]);
                getminute = parseInt(gethourandminute.split("hour")[1]);
              }
              if (gethour != 0 || getminute != 0) 
              {
                totalhour = totalhour + gethour;
                totalminute = getminute;
              }
              if (totalminute < 10) 
              {
                totalminute = "0" + totalminute;
              }
              if (totalhour == 0) 
              {
                self.EditOT.OThours = totalminute + "minutes";
              } 
              else if (totalminute == 0) 
              {
                self.EditOT.OThours = totalhour + " " + "hours";
              } 
              else 
              {
                self.EditOT.OThours =
                  totalhour +
                  " " +
                  "hours" +
                  " " +
                  totalminute +
                  " " +
                  "minutes ";
              }
            }
          }
        } 
        else 
        {
          alert("Please Fill the Date first");
        }
      }
    },
    // changetime(start, end) {
    //   let self = this;
    //   var otstartdate = "";
    //   var otenddate = "";
    //   let start_Time = moment(start, "h:mma");
    //   let end_time = moment(end, "h:mma");
    //   if (end_time.isBefore(start_Time)) {
    //     self.requestOT.OThours = " 00 minutes";
    //   } else {
    //     // if (self.requestOT.wholeday == false) {
    //     //   otstartdate = self.requestOT.OTdate;
    //     //   otenddate = self.requestOT.OTdate;
    //     // } else {
    //     otstartdate = self.requestOT.OTstartdate;
    //     otenddate = self.requestOT.OTenddate;
    //     //  }
    //     if (otstartdate != "" && otenddate != "") {
    //       if (start != "" && end != "") {
    //         let from = new Date();
    //         let to = new Date();
    //         let [frhours, frminutes] = start.split(":");
    //         let [tohours, tominutes] = end.split(":");
    //         from.setHours(+frhours);
    //         from.setMinutes(frminutes);
    //         to.setHours(+tohours);
    //         to.setMinutes(tominutes);
    //         let frtimezone = from.getTimezoneOffset() * 60000;
    //         let totimezone = to.getTimezoneOffset() * 60000;

    //         let frnewDate = new Date(from.getTime() - frtimezone);
    //         let tonewDate = new Date(to.getTime() - totimezone);
    //         var timeDiff = Math.abs(frnewDate - tonewDate);

    //         var a = moment(self.myformatdate(otstartdate), "YYYY-MM-DD");
    //         var b = moment(self.myformatdate(otenddate), "YYYY-MM-DD");
    //         var differentday = parseInt(b.diff(a, "days")) + 1;

    //         var hh = Math.floor(timeDiff / 1000 / 60 / 60);

    //         timeDiff -= hh * 1000 * 60 * 60;
    //         var mm = Math.floor(timeDiff / 1000 / 60);

    //         if (self.requestOT.wholeday == false) {
    //           if (mm < 10) {
    //             mm = "0" + mm;
    //           }
    //           if (hh == "00") {
    //             self.requestOT.OThours = mm + "minutes";
    //           } else if (mm == "00") {
    //             self.requestOT.OThours = hh + " " + "hours";
    //           } else {
    //             self.requestOT.OThours =
    //               hh + " " + "hours" + " " + mm + " " + "minutes ";
    //           }
    //         } else {
    //           var totalhour = hh * differentday;
    //           var totalminute = mm * differentday;
    //           var gethour = 0;
    //           var getminute = 0;
    //           if (totalminute >= 60) {
    //             var gethourandminute = self.timeConvert(totalminute);
    //             gethour = parseInt(gethourandminute.split("hour")[0]);
    //             getminute = parseInt(gethourandminute.split("hour")[1]);
    //           }
    //           if (gethour != 0 || getminute != 0) {
    //             totalhour = totalhour + gethour;
    //             totalminute = getminute;
    //           }

    //           if (totalminute < 10) {
    //             totalminute = "0" + totalminute;
    //           }
    //           if (totalhour == 0) {
    //             self.requestOT.OThours = totalminute + "minutes";
    //           } else if (totalminute == 0) {
    //             self.requestOT.OThours = totalhour + " " + "hours";
    //           } else {
    //             self.requestOT.OThours =
    //               totalhour +
    //               " " +
    //               "hours" +
    //               " " +
    //               totalminute +
    //               " " +
    //               "minutes ";
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hour " + rminutes;
    },

    clearOThours()
    {
      this.requestOT.OThours=null;
    },

    async RequestedOT() {
      let self = this;
      //self.requestOT.attachmentOT=self.uploadedFiles;
      for (var i = 0; i < self.uploadedFiles.length; i++) 
      {
        self.requestOT.attachmentOT.push({
          attachment_file: self.uploadedFiles[i].imgurl,
          filename: self.uploadedFiles[i].name,
          filetype: self.uploadedFiles[i].type
        })
      }
      self.LoadingDialog = true;
      if (self.requestOT.approver != "") {
        self.OTstartdateSent = !self.requestOT.OTstartdate ? self.$t("PleaseSpecify") : "";
        //self.error.end_Date = !self.requestOT.OTenddate ? self.$t("PleaseSpecify") : "";
        self.OTenddateSent = !self.requestOT.OTenddate ? self.$t("PleaseSpecify") : "";
        let myDate=new Date(self.requestOT.OTstartdate);
        self.mystartdate = myDate.getFullYear() +
        '-' + ('0' + (myDate.getMonth() + 1)).slice(-2) +
        '-' + ('0' + myDate.getDate()).slice(-2) +
        'T' + '00'  +
        ':' + '00'  +
        ':' + '00' +
        '.' + '000' +
        'Z';
        // self.mystartdate = self.requestOT.OTstartdate;
        let myEndDate=new Date(self.requestOT.OTenddate);
        self.myenddate = myEndDate.getFullYear() +
        '-' + ('0' + (myEndDate.getMonth() + 1)).slice(-2) +
        '-' + ('0' + myEndDate.getDate()).slice(-2) +
        'T' + '00'  +
        ':' + '00'  +
        ':' + '00' +
        '.' + '000' +
        'Z';
        
        //}
        self.error.otType = !self.requestOT.OTType
          ? " "+self.$t("PleaseSpecify")
          : "";
        self.error.EmpID = !self.requestOT.EmpID
          ?  " "+self.$t("PleaseSpecify")
          : "";
        self.error.start_Date = !self.mystartdate
          ?  " "+self.$t("PleaseSpecify")
          : "";
        self.error.end_Date = !self.myenddate ? self.$t("PleaseSpecify") : "";
        self.error.start_Time = !self.requestOT.OTstart
          ?  " "+self.$t("PleaseSpecify")
          : "";
        self.error.end_Time = !self.requestOT.OTend
          ? " "+self.$t("PleaseSpecify")
          : "";
        self.error.purposeOT = !self.requestOT.purposeOT
          ?  " "+self.$t("PleaseSpecify")
          : "";
        self.otTypesent = [(v) => !!v || "OT Type of OT is required"];
        // let tempvalidate = self.$refs.formSent.validate();
        if (
          self.requestOT.EmpID != null &&
          self.mystartdate != "" &&
          self.myenddate != "" &&
          self.requestOT.OTstart != null &&
          self.requestOT.OTend != null &&
          self.requestOT.OThours != null &&
          self.requestOT.OTstart != "" &&
          self.requestOT.OTend != "" &&
          self.requestOT.OThours != "" &&
          self.requestOT.purposeOT != null &&
          self.requestOT.purposeOT != "" &&
          self.requestOT.approver != null &&
          self.requestOT.approver != "" &&
          self.requestOT.OTType != null &&
          self.requestOT.OTType != ""
        ) 
        {
          if (self.requestOT.OThours != " 00 minutes") 
          {
            if ( moment(this.mystartdate) < moment(this.myenddate).add(1, "day")) 
            {
              if(self.requestOT.maxHour<=8 && self.requestOT.maxHour>-1 && self.requestOT.maxMinute>-1)
              {
                if(self.requestOT.maxHour==8 && self.requestOT.maxMinute>0)
                {
                  self.LoadingDialog=false;
                  alert("Maximum Overtime limit is 8 hours")
                }
                else
                {
                  let validate = {
                  start_Date: self.mystartdate,
                  end_Date: self.myenddate,
                  oT_Start_Time: self.requestOT.OTstart,
                  oT_End_Time: self.requestOT.OTend,
                  company_ID: store.state.companyID,
                  employee_ID: self.requestOT.EmpID,
                };
              axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
                .then(function (response) {
                  if (response.data.status == 0) 
                  {
                    if (response.data.message == "Successfully") 
                    {
                      let temp = {
                        wholeDay: self.requestOT.wholeday,
                        start_Date: self.mystartdate,
                        end_Date: self.myenddate,
                        oT_Start_Time: self.requestOT.OTstart,
                        oT_End_Time: self.requestOT.OTend,
                        oT_Hours: self.requestOT.OThours,
                        purpose_OT: self.requestOT.purposeOT,
                        saveDraft: false,
                        requester_ID: self.requestOT.EmpID,
                        approver_ID: self.requestOT.approverID,
                        company_ID: store.state.companyID,
                        attachments: self.requestOT.attachmentOT,
                        oT_Type: self.requestOT.OTType
                      };
                      axios.post(`${self.url}OT_Request/StaffOT_Request`, temp)
                        .then(async function (response) {
                          if (response.data.status == 0) 
                          {
                            if (response.data.message == "Posted invalid data.") 
                            {
                              self.LoadingDialog = false;
                              alert("Please recheck the date since there seems to be a clash with another request’ for clash with duplicated ot request");
                            }
                            if (response.data.message == "Add Successfully") 
                            {
                              alert(response.data.message);
                              //console.log(self.requestOT.attachmentOT);
                              if (response.data.status === 0) 
                              {
                                await self.getLatestData();
                              }
                              self.uploadedFiles = [];
                              self.LoadingDialog = false;
                              self.Getrequest();
                              self.OTapprovedialog = false;
                              self.showandhide = true;
                              self.requestOT.wholeday = false;
                              self.requestOT.OTdate = "";
                              self.requestOT.OTstartdate = "";
                              self.requestOT.OTenddate = "";
                              self.requestOT.OTstart = "";
                              self.requestOT.OTend = "";
                              self.requestOT.OThours=null;
                              self.requestOT.OTType = null;
                              self.requestOT.purposeOT = null;
                              self.requestOT.approver = null;
                              self.requestOT.empname = "";
                              self.requestOT.EmpID=null;
                              self.requestOT.poistionName=null;
                              self.$refs.fileupload.value="";
                              self.emptype="";
                              self.ReportTrace(5);
                            }
                          }
                        })
                        .catch(function (error) {
                          self.LogTrace(
                            error,
                            "Add OT request Fail",
                            24,
                            "Low"
                          );
                          alert(error);
                          self.LoadingDialog = false;
                        });
                    } else {
                      self.LoadingDialog = false;
                      alert(
                        "You have either not been assigned your shift yet or the OT time you requested clashes with your regular shift time. Please check"
                      );
                    }
                  }
                });
                }
       
              }
              else{
                self.LoadingDialog=false;
                alert("Maximum Overtime limit is 8 hours")
              }
        
            } else {
              self.LoadingDialog = false;
              alert("Start date can’t be after end date");
            }
          } else {
            alert("Start time can't be after end time");
            self.LoadingDialog = false;
          }
        } else {
          self.LoadingDialog = false;
          alert("Please fill all the fields");
        }
      } else {
        self.LoadingDialog = false;
        alert("Please fill Report to Field first in Personal Profile");
      }
    },

    SavedraftOT() {
      let self = this;
      self.LoadingDialog = true;
      if (self.requestOT.approver != "") {
        // if (self.requestOT.wholeday == false) {
        //   (self.mystartdate = self.requestOT.OTdate),
        //     (self.myenddate = self.requestOT.OTdate);
        //   self.requestOT.OTstartdate = self.requestOT.OTdate;
        //   self.requestOT.OTenddate = self.requestOT.OTdate;
        // } else {
        self.requestOT.OTdate = self.requestOT.OTstartdate;
        let myDate=new Date(self.requestOT.OTstartdate);
        self.mystartdate = myDate.getFullYear() +
        '-' + ('0' + (myDate.getMonth() + 1)).slice(-2) +
        '-' + ('0' + myDate.getDate()).slice(-2) +
        'T' + '00'  +
        ':' + '00'  +
        ':' + '00' +
        '.' + '000' +
        'Z';
            // self.mystartdate = self.requestOT.OTstartdate;
        let myEndDate=new Date(self.requestOT.OTenddate);
        self.myenddate = myEndDate.getFullYear() +
        '-' + ('0' + (myEndDate.getMonth() + 1)).slice(-2) +
        '-' + ('0' + myEndDate.getDate()).slice(-2) +
        'T' + '00'  +
        ':' + '00'  +
        ':' + '00' +
        '.' + '000' +
        'Z';
        //}
        //self.OTdateSent = [(v) => !!v || "OT Date is required"];
        self.OTstartdateSent = !self.requestOT.OTstartdate ? "OT Start Date is required" : "";
        self.OTenddateSent = !self.requestOT.OTenddate ? "OT End Date is required" : "";
        self.OTstartSent = [(v) => !!v || "OT Start Time is required"];
        self.OTendSent = [(v) => !!v || "OT End Time Date is required"];
        self.OThoursSent = [(v) => !!v || "OT Hours is required"];
        self.purposeOTSent = [(v) => !!v || "Purpose Of OT is required"];
        self.otTypesent = [(v) => !!v || "OT Type of OT is required"];

        let tempvalidate = self.$refs.formSent.validate();
        if (
          (tempvalidate == true || tempvalidate == false) &&
          self.requestOT.empname != "" &&
          self.mystartdate != "" &&
          self.myenddate != "" &&
          self.requestOT.OTstart != null &&
          self.requestOT.OTstart != "" &&
          self.requestOT.OTend != null &&
          self.requestOT.OTend != "" &&
          self.requestOT.OThours != null &&
          self.requestOT.OThours != "" &&
          self.requestOT.purposeOT != null &&
          self.requestOT.purposeOT != "" &&
          self.requestOT.approver != null &&
          self.requestOT.approver != "" &&
          self.requestOT.OTType != null &&
          self.requestOT.OTType != ""
        ) 
        {
          if (self.requestOT.OThours != " 00 minutes") 
          {
            if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day")) 
            {
              let validate = {
                start_Date: self.mystartdate,
                end_Date: self.myenddate,
                oT_Start_Time: self.requestOT.OTstart,
                oT_End_Time: self.requestOT.OTend,
                company_ID: store.state.companyID,
                employee_ID: store.state.employeeID,
              };
              axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
                .then(function (response) {
                  if (response.data.status == 0) 
                  {
                    if (response.data.message == "Successfully") 
                    {
                      let temp = {
                        wholeDay: self.requestOT.wholeday,
                        start_Date: self.mystartdate,
                        end_Date: self.myenddate,
                        oT_Start_Time: self.requestOT.OTstart,
                        oT_End_Time: self.requestOT.OTend,
                        oT_Hours: self.requestOT.OThours,
                        purpose_OT: self.requestOT.purposeOT,
                        saveDraft: true,
                        requester_ID: store.state.employeeID,
                        approver_ID: self.requestOT.approverID,
                        company_ID: store.state.companyID,
                      };
                      axios.post(`${self.url}OT_Request/StaffOT_Request`, temp)
                        .then(function (response) {
                          if (response.data.status == 0) 
                          {
                            if (response.data.message == "Posted invalid data.") 
                            {
                              self.LoadingDialog = false;
                              alert("Please recheck the date since there seems to be a clash with another request");
                            }
                            if (response.data.message == "Add Successfully") 
                            {
                              alert(response.data.message);
                              self.LoadingDialog = false;
                              self.Getrequest();
                              self.OTapprovedialog = false;
                              self.requestOT.wholeday = false;
                              self.showandhide = true;
                              self.requestOT.OTdate = "";
                              self.requestOT.OTstartdate = "";
                              self.requestOT.OTenddate = "";
                              self.requestOT.OTstart = "";
                              self.requestOT.OTend = "";
                              self.requestOT.OThours = null;
                              self.requestOT.purposeOT = null;
                              self.requestOT.OTType = null;
                            }
                          }
                        });
                    } 
                    else 
                    {
                      self.LoadingDialog = false;
                      alert("You have either not been assigned your shift yet or the OT time you requested clashes with your regular shift time. Please check");
                    }
                  }
                });
            } 
            else 
            {
              alert("Start date can’t be after end date");
              self.LoadingDialog = false;
            }
          } 
          else 
          {
            alert("Start time can't be after end time");
            self.LoadingDialog = false;
          }
        } 
        else 
        {
          alert("Please fill all the fields");
          self.LoadingDialog = false;
        }
      } 
      else 
      {
        self.LoadingDialog = false;
        alert("Please fill Report to Field first in Personal Profile");
      }
    },

    showstartdate() {
      let self = this;
      if (self.requestOT.wholeday == false) 
      {
        self.showandhide = false;
      } 
      else 
      {
        self.showandhide = true;
      }
    },

    showeditdate() {
      let self = this;
      if (self.EditOT.wholeday == false) 
      {
        self.editshowandhide = false;
      } 
      else
       {
        self.editshowandhide = true;
      }
    },

    showdetaildata() {
      let self = this;
      self.disableviewdata = false;
      self.hideeditbtn = true;
      self.showeditbtn = false;
      self.disablesendbtn = true;
      self.disalbecanclebtn = true;
    },

    Getrequest() {
      let self = this;
      self.LoadingDialog = true;
      let myyear = self.todayyears;
      let y = myyear.getFullYear();
      let thisyear = "";
      if (self.todaymonths.val == undefined) 
      {
        thisyear = self.todaymonths;
      } 
      else
      {
        thisyear = self.todaymonths.val;
      }
      let temp = {
        month_Num: thisyear,
        year_Num: y,
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}OT_Request/GetOT_Request`, temp)
        .then(function (response) {
          self.requestedlist = response.data.data;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get OT request Fail", 24, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },

    hidedetaildialog() {
      let self = this;
      self.viewdialog = false;
      self.hideeditbtn = false;
      self.disablesendbtn = false;
      self.disalbecanclebtn = false;
      self.disableviewdata = true;
    },

    Detailrequest(temp) {
      let self = this;
      self.viewdialog = true;
      self.EditOT.OTrequest_ID = temp.oT_Request_ID;
      if (temp.saveDraft) 
      {
        self.showeditbtn = false;
      } 
      else 
      {
        self.showeditbtn = true;
      }
      self.EditOT.empname = temp.requestername;
      self.EditOT.wholeday = temp.wholeDay;
      if (temp.wholeDay) 
      {
        self.editshowandhide = false;
        let date1 = new Date(temp.start_Date);
        let plusday1 = date1.getDate() + 1;
        date1.setDate(plusday1);
        self.EditOT.OTdate = new Date(date1).toISOString().substr(0, 10);
        self.viewOtstart = temp.oT_Start_Time;
        self.viewOtend = temp.oT_End_Time;
      } 
      else 
      {
        self.editshowandhide = true;
        let date1 = new Date(temp.start_Date);
        let plusday1 = date1.getDate() + 1;
        date1.setDate(plusday1);
        self.EditOT.OTdate = new Date(date1).toISOString().substr(0, 10);
        self.viewOtstart = temp.oT_Start_Time;
        self.viewOtend = temp.oT_End_Time;
      }
      let date1 = new Date(temp.start_Date);
      let plusday1 = date1.getDate() + 1;
      date1.setDate(plusday1);
      let date2 = new Date(temp.end_Date);
      let plusday2 = date2.getDate() + 1;
      date2.setDate(plusday2);
      self.EditOT.OTstartdate = new Date(date1).toISOString().substr(0, 10);
      self.EditOT.OTenddate = new Date(date2).toISOString().substr(0, 10);
      self.EditOT.OThours = temp.oT_Hours;
      self.EditOT.purposeOT = temp.purpose_OT;
      self.EditOT.approver = temp.approvername;
      self.EditOT.status = temp.status;
    },

    EditOTrequest() {
      let self = this;
      self.LoadingDialog = true;
      self.EditOT.OTstart = self.viewOtstart;
      self.EditOT.OTend = self.viewOtend;
      if (self.EditOT.wholeday == false) {
        self.mystartdate = self.EditOT.OTdate;
        self.myenddate = self.EditOT.OTdate;
      } else {
        self.mystartdate = self.EditOT.OTstartdate;
        self.myenddate = self.EditOT.OTenddate;
      }
      self.purposeOTEdit = [(v) => !!v || "Purpose Of OT is required"];
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.EditOT.empname != "" &&
        self.mystartdate != "" &&
        self.myenddate != "" &&
        self.EditOT.OTstart != "" &&
        self.EditOT.OTend != "" &&
        self.EditOT.OThours != "" &&
        self.EditOT.purposeOT != "" &&
        self.EditOT.purposeOT != null &&
        self.EditOT.approver != null
      ) {
        if (self.EditOT.OThours != " 00 minutes") 
        {
          if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day")) 
          {
            let validate = {
              start_Date: self.mystartdate,
              end_Date: self.myenddate,
              oT_Start_Time: self.EditOT.OTstart,
              oT_End_Time: self.EditOT.OTend,
              company_ID: store.state.companyID,
              employee_ID: store.state.employeeID,
            };
            axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
              .then(function (response) {
                if (response.data.status == 0) 
                {
                  if (response.data.message == "Successfully") 
                  {
                    let temp = {
                      wholeDay: self.EditOT.wholeday,
                      oT_Request_ID: self.EditOT.OTrequest_ID,
                      start_Date: self.mystartdate,
                      end_Date: self.myenddate,
                      oT_Start_Time: self.EditOT.OTstart,
                      oT_End_Time: self.EditOT.OTend,
                      oT_Hours: self.EditOT.OThours,
                      purpose_OT: self.EditOT.purposeOT,
                      saveDraft: true,
                      requester_ID: store.state.employeeID,

                      company_ID: store.state.companyID,
                    };
                    axios.post(`${self.url}OT_Request/UpdateOT_Request`, temp)
                      .then(function (response) {
                        if (response.data.status == 0) 
                        {
                          if (response.data.message == "Posted invalid data.") 
                          {
                            self.LoadingDialog = false;
                            alert("Please recheck the date since there seems to be a clash with another request’ for clash with duplicated ot request");
                          } 
                          else 
                          {
                            alert(response.data.message);
                            self.LoadingDialog = false;
                            self.Getrequest();
                            self.hideeditbtn = false;
                            self.disablesendbtn = false;
                            self.disalbecanclebtn = false;
                            self.disableviewdata = true;
                          }
                        }
                      })
                      .catch(function (error) {
                        self.LogTrace(error,"Update OT Request Fail",24, "Medium");
                        alert(error);
                        self.LoadingDialog = false;
                      });
                  } 
                  else 
                  {
                    alert("You have either not been assigned your shift yet or the OT time you requested clashes with your regular shift time. Please check");
                    self.LoadingDialog = false;
                  }
                }
              });
          } 
          else 
          {
            self.LoadingDialog = false;
            alert("Start date can’t be after end date");
          }
        } 
        else 
        {
          alert("Start time can't be after end time");
          self.LoadingDialog = false;
        }
      } 
      else 
      {
        alert("Please fill all the fields");
        self.LoadingDialog = false;
      }
    },

    SendOTrequest() {
      let self = this;
      if (self.EditOT.wholeday == false) 
      {
        self.mystartdate = self.EditOT.OTdate;
        self.myenddate = self.EditOT.OTdate;
        self.EditOT.OTstart = "0";
        self.EditOT.OTend = "0";
      } 
      else 
      {
        self.mystartdate = self.EditOT.OTstartdate;
        self.myenddate = self.EditOT.OTenddate;
        self.EditOT.OTstart = "0";
        self.EditOT.OTend = "0";
      }
      if (
        self.EditOT.empname != "" &&
        self.mystartdate != "" &&
        self.myenddate != "" &&
        self.viewOtstart != "" &&
        self.viewOtend != "" &&
        self.EditOT.OThours != "" &&
        self.EditOT.purposeOT != null &&
        self.EditOT.approver != null
      ) 
      {
        let temp = {
          wholeDay: self.EditOT.wholeday,
          oT_Request_ID: self.EditOT.OTrequest_ID,
          start_Date: self.mystartdate,
          end_Date: self.myenddate,
          oT_Start_Time: self.viewOtstart,
          oT_End_Time: self.viewOtend,
          oT_Hours: self.EditOT.OThours,
          purpose_OT: self.EditOT.purposeOT,
          saveDraft: false,
          requester_ID: store.state.employeeID,
          approver_ID: self.EditOT.approverID,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}OT_Request/UpdateOT_Request`, temp)
          .then(async function (response) {
            if (response.data.status == 0) 
            {
              await self.getLatestData();
              alert(response.data.message);
              self.Getrequest();
              self.viewdialog = false;
              self.hideeditbtn = false;
              self.disablesendbtn = false;
              self.disalbecanclebtn = false;
              self.disableviewdata = true;
              self.LoadingDialog = false;
            }
          });
      } 
      else 
      {
        alert("Please fill all the fields");
      }
    },

    deleteOTrequest() {
      let self = this;
      self.Deletedialog = true;
    },

    DeleteCancel() {
      let self = this;
      self.Deletedialog = false;
    },

    DeleteOTrequestlist() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        oT_Request_ID: self.EditOT.OTrequest_ID,
      };
      axios.post(`${self.url}OT_Request/DeleteOT_Request`, temp)
        .then(function (response) {
          if (response.data.status == 0) 
          {
            alert(response.data.message);
            self.Getrequest();
            self.LoadingDialog = false;
            self.Deletedialog = false;
            self.viewdialog = false;
            //self.LogTrace(null, "Delete OT request", 24, "High");
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete OT Request Fail", 24, "High");
          alert(error);
          self.LoadingDialog = false;
        });
    },
  },
};
</script>
<style>
.readonly.v-text-field>.v-input__control>.v-input__slot {
  background-color: #eeeeee !important;
}
</style>
<style scoped>
>>>.drop-area {
  border: 2px dashed #ccc !important;
  border-radius: 7px;
  padding: 20px;
  cursor: pointer;
}

.close-button {
  position: relative;
  top: -15px;
  left: 80px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.container {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}

>>>.selectboxs {
  width: 145px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
}

>>>.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  height: 70px;
}

.deletepadd {
  padding: 0;
}

>>>.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

>>>.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

>>>.mdi-download:hover {
  color: #f99d20;
}

>>>.mdi-menu-down::before {
  color: black;
}

>>>.v-select.v-text-field input {
  margin-left: 10px;
}

>>>.v-text-field>.v-input__control>.v-input__slot:before {
  border: none;
}

.infoimg{
  display: inline-block;
  width: 18px!important;
  position: relative;
  transition: transform 0.6s ease-in-out;
  margin-bottom: -4px!important;
}


@media (max-width: 320px) {
  .minizebox {
    width: 105px;
    height: 35px;
  }
}

@media (max-width: 280px) {
  .minizebox {
    width: 80px;
    height: 35px;
  }
}

@media only screen and (min-width: 1024px) {
  @media only screen and (max-width:1024px)
  {
  .selectbox {
  width: 138px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
  }
}
}
@media only screen and (min-width: 768px) {
  @media only screen and (max-width:768px)
  {
    .selectbox {
  width: 105px !important;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

  }
}

    .selectbox {
  width: 137px;
  max-width: 300px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

@media only screen and (min-width: 820px) and (max-width:820px)
  {
  .selectbox{
  width: 137.6px !important;
  margin: 0px;
  padding: 0px;
  width: 268px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

  }

@media only screen and (min-width: 375px) and (max-width:375px)
  {
  .selectbox{
  width: 231px !important;
  margin: 0px;
  padding: 0px;
  width: 268px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

  }

@media only screen and (min-width: 412px) and (max-width:412px)
  {
  .selectbox{
  width: 268px !important;
  margin: 0px;
  padding: 0px;
  width: 268px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

  }

@media only screen and (min-width: 540px) and (max-width:540px)
  {
  .selectbox{
  width: 396px !important;
  margin: 0px;
  padding: 0px;
  width: 268px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

  }


@media only screen and (min-width: 1280px) and (max-width:1280px)
  {
  .selectbox{
  width: 126.6px !important;
  margin: 0px;
  padding: 0px;
  width: 268px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

  }

  @media (min-width: 375px) and (max-width:375px)
  {
    .selectbox {
  width:231px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

  }

  @media (min-width: 360px) and (max-width:360px)
  {
    .selectbox {
  width: 216.2px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

}

@media (min-width: 1920px) and (max-width:1920px)
  {
    .selectbox {
  width: 232.2px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

}
@media (min-width: 1536px) and (max-width:1536px)
  {
    .selectbox {
  width: 168.66px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

}
@media (min-width: 1680px) and (max-width:1720px)
  {
    .selectbox {
  width: 197.66px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

}

@media (min-width: 1300px) and (max-width:1400px)
  {
    .selectbox {
  width: 145.66px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}

}
@media (min-width: 1129px) and (max-width:1229px)
  {
    .selectbox {
  width: 188.26px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border-color:orange;
}
}
</style>