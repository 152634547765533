var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(
      _vm.$route.path != '/' &&
      _vm.$route.path != '/new' &&
      _vm.$route.path != '/join' &&
      _vm.$route.path != '/forgetPassword' &&
      _vm.$route.name != 'ResetPassword' &&
      _vm.$route.name != 'AnnouncementDetail'
    )?_c('navbar'):_vm._e(),_c('v-main',{staticStyle:{"background-color":"#f0f8fc"}},[(_vm.isIdle && _vm.$route.path != '/')?_c('SessionTimeOut'):_vm._e(),(_vm.$route.path != '/' && _vm.cookDialog)?_c('CookiePolicy',{attrs:{"cookDialog":_vm.cookDialog},on:{"changeTitle":function($event){return _vm.UpdateTitle($event)}}}):_vm._e(),(_vm.$route.path != '/' && _vm.UserGuideDialog)?_c('UserGuide',{attrs:{"UserGuideDialog":_vm.UserGuideDialog},on:{"changeTitle":function($event){return _vm.UpdateTitle1($event)}}}):_vm._e(),_c('router-view',{key:_vm.$route.path})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }