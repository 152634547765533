<template>
  <div style="background: #fff2e3; width: 100%; height: 100%; display: flex">
    <v-container fill-height>
      <v-row>
        <v-col cols="12" align="right">
          <v-spacer></v-spacer>
          <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-btn text dark v-on="on" @click="changeLang">
                <country-flag :country="currentflag" size="normal" />
                <span class="text-capitalize lang-title hidden-sm-and-down">&nbsp;&nbsp;{{ currentLangTitle }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group v-model="lang" color="#F89D1F">
                <v-list-item v-for="entry in languages" :key="entry.title"
                  @click="changeLocale(entry.language, entry.flag, entry.title)">
                  <v-list-item-action>
                    <country-flag :country="entry.flag" size="normal" />
                  </v-list-item-action>
                  <v-list-item-title>{{ entry.title }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center">
          <p class="text-center welcomeToHeader">Welcome to</p>
          <br />
          <p class="text-center optimisticHeader" style>OPTIMISTIC</p>
        </v-col>
      </v-row>

      <v-row justify="center" align="center" style="">
        <v-col cols="12" style="flex-basis: 500px">
          <v-alert type="error" v-if="rememberme" v-model="erroralert">{{
            errorMessageComputed
          }}</v-alert>
          <v-alert type="info" v-model="successalert">{{
            successMessage
          }}</v-alert>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" style="flex-basis: 500px">
          <v-text-field autocomplete="off" color="warning" v-model="input.companyCode" :label="$t('companyid')"
            v-on:keydown.enter="getLock()">
            <template v-slot:prepend-inner>
              <img src="@/assets/company id.png" alt />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="rowjust">
        <v-col cols="12" style="flex-basis: 500px">
          <v-text-field autocomplete="off" color="warning" v-model="input.username" class="textFieldCustom"
            :label="$t('username')" v-on:keydown.enter="getLock()">
            <template v-slot:prepend-inner>
              <img width="24" height="24" src="@/assets/icons/Mask Group 140.svg"
                style="margin-left: 12px; margin-right: 12px" alt />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="rowjust">
        <v-col cols="12" style="flex-basis: 500px">
          <v-text-field autocomplete="off" color="warning" :label="$t('password')" v-model="input.password"
            class="textFieldCustom" :type="show1 ? 'text' : 'password'" v-on:keydown.enter="getLock()">
            <template v-slot:prepend-inner>
              <img width="24" height="24" src="@/assets/icons/Mask Group 141.svg"
                style="margin-left: 12px; margin-right: 12px" alt />
            </template>
            <template v-slot:append>
              <v-btn icon color="white">
                <img width="40" height="40" @click="show1 = !show1" :src="show1 ? pwdimage.showpwd : pwdimage.hidepwd"
                  alt />
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="rowjust">
        <v-col cols="12" style="flex-basis: 500px">
          <v-checkbox class="text custom-checkbox" color="#F99D20" :label="$t('rememberMe')" required v-model="rememberMe"></v-checkbox>
        </v-col>

        <!-- <a class="forgotPassword">Forgot Password</a> -->
      </v-row>
      <v-row justify="center">
        <v-col cols="12" style="flex-basis: 428px">
          <v-btn rounded color="#F99D20" block @click="getLock()" :loading="loading"
            style="height: 52px; font-size: 18px; color: white" depressed class="text-capitalize kanit">{{ $t("login")
            }}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <a class="kanit font17" @click="goForget()" style="color: #0fa7d8">{{
            $t("forgetpsw")
          }}</a>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" persistent width="300">
        <v-card color="#1976D2" dark>
          <v-card-text class="white--text">
            Loading Please Wait...
            <v-progress-linear indeterminate color="#FAA91B" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="loadingdialog" persistent width="300">
        <v-card color="#FFF4EB" dark>
          <v-card-text class="black--text">
            Loading Please Wait...
            <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import CountryFlag from "vue-country-flag";
import enurl from "@/api/environment";
import store from "@/store";
import { mask } from "vue-the-mask";
import i18n from "@/translate/i18n";
import axios from "axios";
import LogTrace from "@/function/Log.js";
// import store from "@/store";
export default {
  components: {
    CountryFlag,
  },
  directives: {
    mask,
  },
  mixins: [LogTrace],
  data() {
    return {
      currentflag: "us",
      currentLangTitle: "English",
      lang: 1,
      checkuser: null,
      url: enurl.apiUrl,
      checkuserCount: 1,
      secretKey: "JarvisSecretPassphrase@2020",
      rememberMe: false,
      tempdata: [],
      expiryDate: "",
      rememberme: "false",
      loadingdialog: false,
      languages: [
        { flag: "th", language: "th", title: "ประเทศไทย" },
        { flag: "us", language: "en", title: "English" },
        { flag: "CN", language: "cn", title: "中文" },
        { flag: "VN", language: "vn", title: "Tiếng Việt" },
        { flag: "MM", language: "mm", title: "မြန်မာဘာသာ" },
      ],
      items: [
        {
          getid: 0,
          route: "/home",
          Check: false,
        },
        {
          getid: 1,
          Check: false,
          children: [
            {
              text: "Company Profile",
              route: "/organization/companyprofile/companyinformation",
              Checkitem: true,
            },
            {
              text: "Organization",
              route: "/organization/org/department",
              Checkitem: true,
            },
            {
              text: "Attendance",
              route: "/organization/attendance/timeworkdaycalender",
              Checkitem: true,
            },
            {
              text: "Time Off",
              route: "/organization/timeoff/timeofftype",
              Checkitem: true,
            },
            {
              text: "Common Master",
              route: "/masterdata",
              Checkitem: true,
            },
          ],
        },
        // { getid: 2, Check: false, route: "/PersonnelManagement" },
        {
          getid: 2,
          Check: false,
          children: [
            {
              route: "/PersonnelManagement",
              Checkitem: false,
            },
            {
              route: "/ShiftAssignment",
              Checkitem: false,
            }
          ],
        },
        {
          getid: 3,
          Check: false,
          children: [
            {
              route: "/StaffTimeAttendance",
              Checkitem: false,
            },
            {
              route: "/SyncTimedata",
              Checkitem: false,
            },
            {
              route: "/EmployeeOTrequest",
              Checkitem: false,
            },
            // {
            //   route: "/TimeAttendance/Setting/timeworkdaycalendar",
            //   Checkitem: false,
            // },
          ],
        },
        {
          getid: 4,
          Check: false,
          children: [
            {
              route: "/EmployeesTimeOffRequest",
              Checkitem: false,
            },
            // {
            //   route: "/TimeOff/Setting/TimeOffTap",
            //   Checkitem: false,
            // },
          ],
        },
        {
          getid: 5,
          Check: false,
          children: [
            {
              route: "/Payrolls",
              Checkitem: false,
            },
            {
              route: "/PayRoll/Setting/payrollcalculationperiod",
              Checkitem: false,
            },
          ],
        },
        {
          getid: 6,
          Check: false,
          children: [
            {
              route: "/employmentreport",
              Checkitem: false,
            },
            {
              route: "/timeattendancereport",
              Checkitem: false,
            },
            {
              route: "/timeoffreport",
              Checkitem: false,
            },
            {
              route: "/payrollreport",
              Checkitem: false,
            },
            {
              route: "/annualreport",
              Checkitem: false,
            },
          ],
        },

        {
          getid: 7,
          Check: false,
          children: [
            {
              route: "/usermanagement/usergroup",
              Checkitem: false,
            },
            { route: "/OtherSetting", Checkitem: false },
            {
              text: "Default Language",
              route: "/DefaultLanguage",
              Checkitem: false,
            },

            {
              route: "/HelpFeedback/NewFeedback",
              Checkitem: false,
            },
          ],
        },
        {
          getid: 8,
          Check: false,
          children: [
            {
              route: "/selfservice/PersonalProfile/PersonalInformation",
              Checkitem: false,
            },
            {
              route: "/selfservice/timeentries",
              Checkitem: false,
            },
            {
              route: "/selfservice/myotrequest",
              Checkitem: false,
            },
            {
              route: "/selfservice/mytimeoffrequest",
              Checkitem: false,
            },
            {
              route: "/selfservice/changepassword",
              Checkitem: false,
            },
          ],
        },
        {
          getid: 9,
          route: "/Version",
          Check: false,
        },
      ],
      input: {
        username: "",
        password: "",
        companyCode: null,
      },
      pwdimage: {
        showpwd: require("@/assets/images/reveal icon@2x.png"),
        hidepwd: require("@/assets/images/pass@2x.png"),
      },
      successMessage: "",
      errorMessage: "",
      erroralert: false,
      successalert: false,
      loading: false,
      dialog: false,
      show1: false,
      mycompany: null,
      is_Locked: false,
      company_status: null,
    };
  },
  computed: {
    errorMessageComputed() {
      let value = this.errorMessage;
      return value ==
        "You have failed logging in for 5 times already.Please click on Forgot Password to reset and try again"
        ? this.$t("YouHaveFailed")
        : value == "Invalid login credentials. Please check again."
          ? this.$t("InvalidLoginCredentials")
          : value == "Expire Account !! Please contact to Product Admin"
            ? this.$t("ExpireAccount")
            : this.$t("YourAccountHasExpired");
    },
  },

  mounted() {
    let self = this;
    if (self.readCookie("lang") != null) {
      i18n.locale = self.readCookie("lang");
      self.currentflag = self.readCookie("flag");
      self.currentLangTitle = self.readCookie("title");
      if (self.currentLangTitle == "English") {
        self.lang = 1;
        i18n.locale = "en";
        self.currentflag = "us";
        self.currentLangTitle = "English";
        sessionStorage.setItem("Lang", self.currentLangTitle);
      } else if (self.currentLangTitle == "ประเทศไทย") {
        self.lang = 0;
        i18n.locale = "th";
        self.currentflag = "th";
        self.currentLangTitle = "ประเทศไทย";
        sessionStorage.setItem("Lang", self.currentLangTitle);
      } else if (self.currentLangTitle == "Tiếng Việt") {
        self.lang = 3;
        i18n.locale = "vn";
        self.currentflag = "VN";
        self.currentLangTitle = "Tiếng Việt";
        sessionStorage.setItem("Lang", self.currentLangTitle);
      } else if (self.currentLangTitle == "中文") {
        self.lang = 2;
        i18n.locale = "cn";
        self.currentflag = "CN";
        self.currentLangTitle = "中文";
        sessionStorage.setItem("Lang", self.currentLangTitle);
      }
      // } else if (self.currentLangTitle == "မြန်မာဘာသာ") {
      //   self.lang = 4;
      //   i18n.locale = "mm";
      //   self.currentflag = "MM";
      //   self.currentLangTitle = "မြန်မာဘာသာ";
      //   sessionStorage.setItem("Lang", self.currentLangTitle);
      // }
      else {
        i18n.locale = "en";
        self.currentflag = "us";
        self.currentLangTitle = "English";
        self.lang = 1;
      }
    } else {
      i18n.locale = "en";
      self.currentflag = "us";
      self.currentLangTitle = "English";
      self.lang = 1;
    }
  },
  created() {
    let self = this;
    let login_username = self.readCookie("username");
    let login_password = self.readCookie("password");
    self.rememberme = self.readCookie("rememberme");
    let login_abb = self.readCookie("abb");
    if (self.rememberme == "true") {
      self.expiryDate = self.readCookie("expiredate");
      let today = new Date();
      if (today >= self.expiryDate) {
        document.cookie = "username=" + ";expires=" + self.expiryDate;
        document.cookie = "password=" + ";expires=" + self.expiryDate;
        document.cookie = "rememberme='false'" + ";expires=" + self.expiryDate;
        document.cookie = "abb=" + ";expires=" + self.expiryDate;
      } else {
        if (
          login_username != null &&
          login_password != null &&
          login_abb != null
        ) {
          self.input.companyCode = self.cryptoMethods("decrypt", login_abb);
          self.input.username = self.cryptoMethods("decrypt", login_username);
          self.input.password = self.cryptoMethods("decrypt", login_password);
          self.login();
          self.getLock();
          // self.getFirebaseToken();
        }
      }
    } else {
      self.rememberme = "false";
      self.expiryDate = new Date();
      self.expiryDate.setMonth(self.expiryDate.getMonth() + 1);
      document.cookie = `expiredate=${self.expiryDate};expires=${self.expiryDate}`;
    }
  },
  methods: {
    changeLang() {
      if (localStorage.TempLng == "English") {
        self.lang = 1;
        i18n.locale = "en";
        self.currentflag = "us";
        self.currentLangTitle = "English";
      } else if (localStorage.TempLng == "ประเทศไทย") {
        self.lang = 0;
        i18n.locale = "th";
        self.currentflag = "th";
        self.currentLangTitle = "ประเทศไทย";
      } else if (localStorage.TempLng == "Tiếng Việt") {
        self.lang = 3;
        i18n.locale = "vn";
        self.currentflag = "VN";
        self.currentLangTitle = "Tiếng Việt";
      } else if (localStorage.TempLng == "中文") {
        self.lang = 2;
        i18n.locale = "cn";
        self.currentflag = "CN";
        self.currentLangTitle = "中文";
      } else if (localStorage.TempLng == "မြန်မာဘာသာ") {
        self.lang = 4;
        i18n.locale = "mm";
        self.currentflag = "MM";
        self.currentLangTitle = "မြန်မာဘာသာ";
      } else {
        if (sessionStorage.flag == "English") {
          self.lang = 1;
          i18n.locale = "en";
          self.currentflag = "us";
          self.currentLangTitle = "English";
        } else if (sessionStorage.flag == "Thai") {
          self.lang = 0;
          i18n.locale = "th";
          self.currentflag = "th";
          self.currentLangTitle = "ประเทศไทย";
        } else if (sessionStorage.flag == "Vietnam") {
          self.lang = 3;
          i18n.locale = "vn";
          self.currentflag = "VN";
          self.currentLangTitle = "Tiếng Việt";
        } else if (sessionStorage.flag == "Chinese") {
          self.lang = 2;
          i18n.locale = "cn";
          self.currentflag = "CN";
          self.currentLangTitle = "中文";
        } else if (sessionStorage.flag == "မြန်မာဘာသာ") {
          self.lang = 4;
          i18n.locale = "mm";
          self.currentflag = "MM";
          self.currentLangTitle = "မြန်မာဘာသာ";
        } else {
          self.lang = 1;
          i18n.locale = "en";
          self.currentflag = "us";
          self.currentLangTitle = "English";
        }
      }
    },
    ValidateAddTrue(obj) {
      return Object.values(obj).every((x) => x);
    },
    ValidateAddFalse(obj) {
      if (Object.values(obj).some((x) => !x)) {
        alert("Please fill in the required fields!");
      }
    },
    changeLocale(locale, flag, title) {
      this.currentflag = flag;
      i18n.locale = locale;
      this.currentLangTitle = title;
      this.currentLangTitle = title;
      localStorage.setItem("TempLng", title);
    },
    goForget() {
      let self = this;
      self.$router.push("/forgetPassword").catch(() => { });
    },
    readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    cryptoMethods(type, value) {
      if (value != null || value != "" || value != undefined) {
        if (type == "encrypt") {
          const encryptedText = CryptoJS.AES.encrypt(
            value,
            this.secretKey
          ).toString();
          return encryptedText;
        } else {
          const decryptedText = CryptoJS.AES.decrypt(
            value,
            this.secretKey
          ).toString(CryptoJS.enc.Utf8);
          return decryptedText;
        }
      }
    },

    goTo(value) {
      this.$router.push(value).catch(() => { });
    },
    login: function () {
      let self = this;
      self.is_Locked = false;
      self.clearMessage();
      self.loading = true;
      let userName = self.input.username.trim().toLowerCase();
      let password = self.input.password;
      let companyCode = self.input.companyCode.trim().toLowerCase();
      if (self.is_Locked == true) {
        self.loading = false;
        self.errorMessage = false;
      }
      if (self.company_status == false)
      {
        self.loading = false;
        self.erroralert = true;
        self.errorMessage =
          "Your account has expired. Please contact Optimistic support.";
        self.LogTrace(
          "Company expired",
          "Unsuccessfully Logged In",
          0,
          "Medium"
        );
      }
      if (self.is_Locked == false && self.company_status == true) {
        self.$store
          .dispatch("login", { userName, password, companyCode })
          .then(function (response) {
            if (response.data.data.employeeStatus !== 2) {
              if (response.data.data.role_Name == null) {
                self.successalert = false;
                self.erroralert = true;
                self.errorMessage =
                  "Invalid login credentials. Please check again.";
                self.loading = false;
              } else {
                if (response.data.message == "Successfully") {
                  self.validateTerminate(response.data.data.company_ID);
                  self.successalert = true;
                  localStorage.removeItem("TempLng");
                  if (store.state.flag == "Thai") {
                    localStorage.setItem("Templang", "ประเทศไทย");
                  } else {
                    localStorage.setItem("Templang", "English");
                  }
                  self.tempdata = response.data.data;
                  const permissionID = response.data.data.permission_ID;
                  const roleID = response.data.data.role_Name;
                  self.successMessage = self.$t("successlogin");
                  document.cookie =
                    "username=" +
                    self.cryptoMethods("encrypt", userName) +
                    ";expires=" +
                    self.expiryDate;
                  document.cookie =
                    "password=" +
                    self.cryptoMethods("encrypt", password) +
                    ";expires=" +
                    self.expiryDate;
                  document.cookie =
                    "abb=" +
                    self.cryptoMethods("encrypt", companyCode) +
                    ";expires=" +
                    self.expiryDate;
                  let tempRem =
                    self.rememberMe || self.rememberme == "true"
                      ? "true"
                      : "false";
                  document.cookie =
                    "rememberme=" + tempRem + ";expires=" + self.expiryDate;
                  self.addReport();
                  self.model = { username: "", password: "" };

                  //First Route
                  if (roleID == "Admin" || roleID == "HR") {
                    localStorage.setItem("FirstLogin", 1);
                    setTimeout(() => {
                      self.$router.push({ name: "home" }).catch(() => { });
                      //
                    }, 1000);
                  } else {
                    if (permissionID != null) {
                      let perid = permissionID.split(",");
                      //Home
                      if (perid.some((e) => e == 1)) {
                        setTimeout(() => {
                          self.$router
                            .push(self.items[0].route)
                            .catch(() => { });
                        }, 1000);
                      }
                      //Organization
                      else if (
                        perid.some(
                          (e) =>
                            e == 116 ||
                            e == 99 ||
                            e == 54 ||
                            e == 59 ||
                            e == 20 ||
                            e == 23 ||
                            e == 30 ||
                            e == 35 ||
                            e == 89 ||
                            e == 39 ||
                            e == 64 ||
                            e == 79 ||
                            e == 69 ||
                            e == 74 ||
                            e == 84 ||
                            e == 94 ||
                            e == 168
                        )
                      ) {
                        self.items[1].Check = true;
                        if (self.items[1].children) {
                          if (perid.some((e) => e == 116 || e == 99)) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[1].children[0].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 54 || e == 59)) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[1].children[1].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (
                            perid.some(
                              (e) =>
                                e == 20 ||
                                e == 23 ||
                                e == 30 ||
                                e == 35
                            )
                          ) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[1].children[2].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 89 || e == 39)) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[1].children[3].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (
                            perid.some(
                              (e) =>
                                e == 64 ||
                                e == 79 ||
                                e == 69 ||
                                e == 74 ||
                                e == 84 ||
                                e == 94 ||
                                e == 168
                            )
                          ) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[1].children[4].route)
                                .catch(() => { });
                            }, 1000);
                          }
                        }
                      }
                      //Personnel Management
                      // else if (perid.some((e) => e == 7)) {
                      //   setTimeout(() => {
                      //     self.$router
                      //       .push(self.items[2].route)
                      //       .catch(() => {});
                      //   }, 1000);
                      // }

                      else if (
                        perid.some((e) => e == 7 || e == 27)
                      ) {
                        self.items[2].Check = true;
                        if (self.items[2].children) {
                          if (perid.some((e) => e == 7)) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[2].children[0].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 27)) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[2].children[1].route)
                                .catch(() => { });
                            }, 1000);
                          }
                        }
                      }

                      //Time Attendance
                      else if (
                        perid.some((e) => e == 10 || e == 14 || e == 18)
                      ) {
                        self.items[3].Check = true;
                        if (self.items[3].children) {
                          if (perid.some((e) => e == 10)) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[3].children[0].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 14)) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[3].children[1].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 18)) {
                            setTimeout(() => {
                              self.$router
                                .push(self.items[3].children[2].route)
                                .catch(() => { });
                            }, 1000);
                          }
                        }
                      }

                      //Time Off
                      else if (perid.some((e) => e == 37)) {
                        self.items[4].Check = true;
                        if (self.items[4].children) {
                          if (perid.some((e) => e == 37)) {
                            self.items[4].children[0].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[4].children[0].route)
                                .catch(() => { });
                            }, 1000);
                          }
                          // } else if (perid.some((e) => e == 39)) {
                          //   self.items[3].children[1].Checkitem = true;
                          //   setTimeout(() => {
                          //     self.$router
                          //       .push(self.items[3].children[1].route)
                          //       .catch(() => {});
                          //   }, 1000);
                          // }
                        }
                      }

                      //payroll
                      else if (
                        perid.some((e) => e == 41 || e == 45 || e == 197)
                      ) {
                        self.items[5].Check = true;
                        if (self.items[5].children) {
                          if (perid.some((e) => e == 41)) {
                            self.items[5].children[0].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[5].children[0].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 45 || e == 197)) {
                            self.items[5].children[1].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[5].children[1].route)
                                .catch(() => { });
                            }, 1000);
                          }
                        }
                      }
                      //Reports
                      // else if (
                      //   perid.some(
                      //     (e) =>
                      //       e == 172 ||
                      //       e == 174 ||
                      //       e == 176 ||
                      //       e == 178 ||
                      //       e == 180 ||
                      //       e == 182 ||
                      //       e == 184 ||
                      //       e == 186
                      //   )
                      // ) {
                      //   self.items[5].Check = true;
                      //   setTimeout(() => {
                      //     self.$router.push(self.items[5].route).catch(()=>{});
                      //   }, 1000);
                      // }
                      else if (
                        perid.some(
                          (e) =>
                            e == 172 ||
                            e == 174 ||
                            e == 176 ||
                            e == 178 ||
                            e == 180 ||
                            e == 182 ||
                            e == 184 ||
                            e == 186 ||
                            e == 225
                        )
                      ) {
                        self.items[6].Check = true;
                        if (self.items[5].children) {
                          if (
                            perid.some((e) => e == 172 || e == 174 || e == 176)
                          ) {
                            self.items[6].children[0].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[6].children[0].route)
                                .catch(() => { });
                            }, 1000);
                          }
                          if (perid.some((e) => e == 225)) {
                            self.items[6].children[1].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[6].children[1].route)
                                .catch(() => { });
                            }, 1000);
                          }
                          if (perid.some((e) => e == 182)) {
                            self.items[6].children[2].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[6].children[2].route)
                                .catch(() => { });
                            }, 1000);
                          }
                          if (
                            perid.some((e) => e == 178 || e == 180 || e == 184)
                          ) {
                            self.items[6].children[3].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[6].children[3].route)
                                .catch(() => { });
                            }, 1000);
                          }
                          if (perid.some((e) => e == 186)) {
                            self.items[6].children[4].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[6].children[4].route)
                                .catch(() => { });
                            }, 1000);
                          }
                        }
                      }

                      //Master Data
                      // else if (
                      //   perid.some(
                      //     (e) =>
                      //       // e == 49 ||
                      //       e == 54 ||
                      //       e == 59 ||
                      //       e == 64 ||
                      //       e == 69 ||
                      //       e == 74 ||
                      //       e == 79 ||
                      //       e == 84 ||
                      //       e == 89 ||
                      //       e == 94 ||
                      //       e == 99 ||
                      //       e == 168
                      //   )
                      // ) {
                      //   self.items[6].Check = true;
                      //   setTimeout(() => {
                      //     self.$router
                      //       .push(self.items[6].route)
                      //       .catch(() => {});
                      //   }, 1000);
                      // }

                      //Setting
                      else if (
                        perid.some(
                          (e) =>
                            e == 107 ||
                            e == 111 ||
                            e == 112 ||
                            e == 118 ||
                            e == 120 ||
                            e == 195 ||
                            e == 227
                        )
                      ) {
                        self.items[7].Check = true;
                        if (self.items[7].children) {
                          if (
                            perid.some((e) => e == 107 || e == 111 || e == 112)
                          ) {
                            self.items[7].children[0].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[7].children[0].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 195)) {
                            self.items[7].children[1].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[7].children[1].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 118)) {
                            self.items[7].children[2].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[7].children[2].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 120)) {
                            self.items[7].children[3].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[7].children[3].route)
                                .catch(() => { });
                            }, 1000);
                          }
                          else if (perid.some((e) => e == 227)) {
                            self.items[7].children[4].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[7].children[4].route)
                                .catch(() => { });
                            }, 1000);
                          }
                        }
                      }

                      //Self service
                      else if (
                        perid.some(
                          (e) =>
                            e == 123 ||
                            e == 126 ||
                            e == 129 ||
                            e == 132 ||
                            e == 136 ||
                            e == 139 ||
                            e == 143 ||
                            e == 146 ||
                            e == 149 ||
                            e == 152 ||
                            e == 155 ||
                            e == 159 ||
                            e == 188
                        )
                      ) {
                        self.items[8].Check = true;
                        if (self.items[8].children) {
                          if (
                            perid.some(
                              (e) =>
                                e == 123 ||
                                e == 126 ||
                                e == 129 ||
                                e == 132 ||
                                e == 136 ||
                                e == 139 ||
                                e == 143 ||
                                e == 146 ||
                                e == 149
                            )
                          ) {
                            self.items[8].children[0].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[8].children[0].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 152)) {
                            self.items[8].children[1].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[8].children[1].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 155)) {
                            self.items[8].children[2].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[8].children[2].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 159)) {
                            self.items[8].children[3].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[8].children[3].route)
                                .catch(() => { });
                            }, 1000);
                          } else if (perid.some((e) => e == 188)) {
                            self.items[8].children[4].Checkitem = true;
                            setTimeout(() => {
                              self.$router
                                .push(self.items[8].children[4].route)
                                .catch(() => { });
                            }, 1000);
                          }
                        }
                      } else if (perid.some((e) => e == 167)) {
                        self.items[9].Check = true;
                        setTimeout(() => {
                          self.$router
                            .push(self.items[9].route)
                            .catch(() => { });
                        }, 1000);
                      }
                    }
                  }
                }
              }
            } else {
              self.loading = false;
              self.erroralert = true;
              self.errorMessage =
                "Your account has expired. Please contact Optimistic support.";
              self.LogTrace(
                "Company expired",
                "Unsuccessfully Logged In",
                0,
                "Medium"
              );
            }
          })
          .catch(function () {
            if (self.checkuserCount == 4) {
              let temp = {
                username: userName,
                companyCode: companyCode,
              };
              axios
                .post(`${self.url}Login/LockAccountUser`, temp)
                .then(function (response) {
                  self.erroralert = true;
                  self.errorMessage = response.data.message;
                  self.loading = false;
                });
            } else {
              self.erroralert = true;
              if (self.checkuser == self.input.username) {
                self.checkuserCount++;
              } else {
                self.checkuserCount = 1;
              }

              if (self.is_Locked == true) {
                self.erroralert = true;
                self.errorMessage =
                  "You have failed logging in for 5 times already.Please click on Forgot Password to reset and try again";

                self.loading = false;
              } else if (self.company_status == false) {
                self.erroralert = true;
                self.errorMessage =
                  "Expire Account.Please contact to Product Admin";
                self.LogTrace(
                  "Company expired",
                  "Unsuccessfully Logged In",
                  0,
                  "Medium"
                );
              } else {
                self.errorMessage =
                  "Invalid login credentials. Please check again.";
              }
              self.checkuser = self.input.username;
            }
            self.loading = false;
          });
      }
    },
    clearMessage: function () {
      this.erroralert = false;
      this.successalert = false;
      this.errorMessage = "";
      this.successMessage = "";
    },
    async validateTerminate(com) {
      let self = this;
      let temp = {
        company_ID: com,
      };
      await axios
        .post(`${self.url}PersonalInfo/ValidateTerminate`, temp)
        .then(function () {
          // alert(response.data.message);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    getLock() {
      let self = this;
      let tempvalid = self.ValidateAddTrue(self.input);
      self.erroralert = false;
      self.loadingdialog = true;
      if (tempvalid == true) {
        if (self.input.username != null && self.input.companyCode) {
          let userName = self.input.username.toLowerCase();

          let companyCode = self.input.companyCode.toLowerCase();

          let tempLock = {
            username: userName,
            companyCode: companyCode,
          };
          axios
            .post(`${self.url}Login/GetUserByCode`, tempLock)
            .then(function (response) {
              let res = response.data.data;
              if (res) {
                self.is_Locked = res.is_Locked;
                self.company_status = res.status;
                self.login();
                self.loadingdialog = false;
              } else {
                self.erroralert = true;
                self.loadingdialog = false;
                self.errorMessage =
                  "Invalid login credentials. Please check again.";
              }
            });
        }
      } else {
        self.ValidateAddFalse(self.input);
        self.loadingdialog = false;
      }
    },
    addReport() {
      let tempReport = {
        user_ID: this.tempdata.user_ID,
        company_ID: this.tempdata.company_ID,
        feature: 1,
      };
      axios
        .post(`${this.url}FeatureReport/AddReport`, tempReport)
        .then(function () {
          // alert(response.data.message);
        })
        .catch(function (error) {
          alert(error);
        });
    },
  },
};
</script>

<style scoped>
@media (max-width: 450px) {
  .alert_box {
    height: 80px !important;
  }
}

.lang-title {
  color: #f89d1f;
  font-size: 16px;
  font-family: Kanit, sans-serif;
}

.rowjust {
  margin-top: -1.5rem;
}

.welcomeToHeader {
  color: #0fa7d8;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  text-shadow: 0px 3px 6px #00000029;
  font-weight: bold;
}

.optimisticHeader {
  font-size: 60px;
  color: #f99d20;
  font-family: "Montserrat", sans-serif;
  margin-top: -30px;
  font-weight: bold;
}

.companyLogoCustom {
  min-width: 428px;
  height: 180px;
  background-color: #ffffff;
  padding-top: 80px;
}

.p-custom {
  padding: 0px;
}

@media (min-width: 1179px) {
  .hello-right {
    margin-left: 100px;
  }
}

@media (max-height: 600px) and (max-width: 350px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }

  .optimisticHeader {
    font-size: 60px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}

@media (max-height: 660px) and (max-width: 300px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }

  .optimisticHeader {
    font-size: 20px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}

@media (max-height: 570px) and (max-width: 370px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }

  .optimisticHeader {
    font-size: 40px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}
</style>
