<template>
    <div style="margin-left: 1.5rem;margin-bottom:1.5rem;">
      <div style="margin-right: 1.5rem">
        <p style="font-size: 26px; color: #43425d; margin-top: 20px">
          {{  $t("myTasks") }}
        </p>
        <v-tabs class="mt-6" grey--text background-color="#f0f8fc" slider-color="#F99D20">
          <v-tab class="text-capitalize" style="
            color:white;
              border-radius: 10px 10px 0px 5px;
              width: 200px;
              font-size: 15px;">{{
                $t("DocumentMT")
              }}</v-tab>
          <v-tab-item>
              <mytask />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </template>
  <script>
 import enurl from "@/api/environment"
 import mytask from "./MyTask.vue"
 import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  components: {
    mytask
  },
  data() {
    return {
      url: enurl.apiUrl,
    };
  },


};
  </script>