<template>
    <div>
        <div style="margin-left: 1%;display:flex;">
            <p class="ml-4 headtext txt-header">
                {{ $t("PayrollSetting") }}
            </p>
        </div>

        <div style="margin-left: 1.5rem; margin-right: 1.5rem">
            <v-tabs class="mt-3" grey--text background-color="#f0f8fc" show-arrows slider-color="#F99D20">
            <v-tab class="text-capitalize" style="border-radius: 10px 10px 0px 5px;width: 340px;margin-right: 3px;background-color: white;font-size: 16px;">
                {{ $t("IncomeDeductionCondition") }}
            </v-tab>
        </v-tabs>
        
        <v-card style="height: 650px">
        <v-tabs v-model="theTab" show-arrows class="pt-2 ml-2 mr-2 mb-7 inner" style="width: 99%">
            <v-tab class="text-capitalize inner" style="font-size: 16px;">{{ $t("overt") }}</v-tab>
            <v-tab class="text-capitalize inner" style="font-size: 16px;">{{ $t("Late") }}</v-tab>
            <v-tab class="text-capitalize inner" style="font-size: 16px;">{{ $t("NotStamp(days)") }}</v-tab>
            <v-tab-item style="height: 100%;" class="mb-7">
                <div class="mt-5 ml-4 mr-4 mb-5" style="border:#D0CECE 1px solid; border-radius: 5px;">
                    <v-row class="mt-1 ml-0 mr-0" style=" height: 75px;">
                        <p class="mt-4 ml-5">{{ $t("FixRateOT") }}</p>
                        <v-spacer></v-spacer>
                        <div class="mt-3 ml-1 mr-5" v-if="showOvertimeCard == true">
                            <input type="checkbox" v-model="showOvertimeCard" />
                        </div>
                        <div class="mt-3 ml-1 mr-5" v-else>
                            <input type="checkbox" style="cursor: default" v-model="showOvertimeCard" />
                        </div>
                    </v-row>
                    <div v-show="showOvertimeCard">
                        <hr color="#DCDCDC" style="height: 1px !important; transform: scaleY(0.5);"
                            class="ml-5 mr-5">
                        <v-row class="ml-4 mr-4 mt-4">
                            <p class="mt-2">{{ $t("OTperhour") }}</p>
                            <v-spacer></v-spacer>
                            <v-text-field :key="otfixratekey" v-model="overtimefixrate" color="#F99D20" outlined
                                dense class="custom-text-field-1"
                                @keypress="NumberOrFloat($event, overtimefixrate)">
                                <template v-slot:prepend-inner>
                                    ฿
                                </template>
                            </v-text-field>
                        </v-row>
                    </div>
                </div>
                <v-data-table class="mt-4 ml-5 mr-5" style="max-height: 630px; overflow-y: auto;"
                    :items="leaveTypeList" disable-pagination hide-default-footer hide-default-header
                    :mobile-breakpoint="0">
                    <template v-slot:header="{ props: { } }">
                        <thead>
                            <tr class="Rheader">
                                <th style="max-width: 250px !important;" scope="col">
                                    {{ $t("Code") }}
                                </th>
                                <th class="pr-4" scope="col">{{ $t("Work Duration") }}</th>
                                <th class=" text-end" scope="col">{{ $t("Times") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    OT1
                                </td>
                                <td>
                                    {{ $t("OTinHoliday") }}
                                </td>
                                <td class="text-end">
                                    <v-text-field v-model="oTone" color="#F99D20" outlined dense hide-details
                                        class="custom-text-field-1 OTone" :key="oTonekey"
                                        @keypress="NumberOrFloat($event, oTone)" :disabled="showOvertimeCard"
                                        :class="showOvertimeCard ? 'readonly' : 'unread'"></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OT2
                                </td>
                                <td>
                                    {{ $t("OTonwork") }}
                                </td>
                                <td class="text-end">
                                    <v-text-field v-model="oTtwo" color="#F99D20" outlined dense hide-details
                                        class="custom-text-field-1 OTone" :key="oTtwokey"
                                        @keypress="NumberOrFloat($event, oTtwo)" :disabled="showOvertimeCard"
                                        :class="showOvertimeCard ? 'readonly' : 'unread'"></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OT3
                                </td>
                                <td>
                                    {{ $t("OTpaidHoliday") }}
                                </td>
                                <td>
                                    <v-text-field v-model="oTthree" color="#F99D20" outlined dense hide-details
                                        class="custom-text-field-1 OTone" :key="oTthreekey"
                                        @keypress="NumberOrFloat($event, oTthree)" :disabled="showOvertimeCard"
                                        :class="showOvertimeCard ? 'readonly' : 'unread'"></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OT4
                                </td>
                                <td>
                                    {{ $t("OTuotHoliday") }}
                                </td>
                                <td class="text-end right" style="text-align: right; margin-right: 1em;">
                                    <v-text-field v-model="oTfour" color="#F99D20" outlined dense :key="oTfourkey"
                                        hide-details class="custom-text-field-1 OTone"
                                        @keypress="NumberOrFloat($event, oTfour)" :disabled="showOvertimeCard"
                                        :class="showOvertimeCard ? 'readonly' : 'unread'"></v-text-field>
                                </td>
                            </tr>
                        </tbody>

                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item style="height: 100%;" class="mb-7">
                <div class="mt-5 ml-4 mr-4 mb-5" style="border:#D0CECE 1px solid; border-radius: 5px; width: 97%;">
                    <v-row class="mt-4 ml-4 mr-4">
                        <p class="mt-2">{{ $t("DeductionType") }}</p>
                        <v-spacer></v-spacer>
                        <v-select v-model="lateDT" :items="deductionList" item-text="txt" item-value="val" outlined
                            dense color="#F99D20" class="custom-select" v-on:change="changelateDT(lateDT)"
                            style="font-size: 14px; max-width: 300px; min-width: 50px;">
                        </v-select>
                    </v-row>
                    <div v-show="showlateCard">
                        <hr color="#DCDCDC" style="height: 1px !important; transform: scaleY(0.5);"
                            class="ml-5 mr-5">
                        <v-row class="ml-4 mr-4 mt-4">
                            <p class="mt-2">{{ $t("Amtdeductedperday") }}</p>
                            <v-spacer></v-spacer>
                            <v-text-field :key="lateDeductkey" v-model="lateDeduct" color="#F99D20" outlined dense
                                class="custom-text-field-1" @keypress="NumberOrFloat($event, lateDeduct)">
                                <template v-slot:prepend-inner>
                                    ฿
                                </template></v-text-field>
                        </v-row>
                    </div>
                </div>
            </v-tab-item>
            <v-tab-item style="height: 100%;" class="mb-7">
                <div class="mt-5 ml-4 mr-4 mb-5" style="border:#D0CECE 1px solid; border-radius: 5px; width: 97%;">
                    <v-row class="mt-4 ml-4 mr-4">
                        <p class="mt-2">{{ $t("DeductionType") }}</p>
                        <v-spacer></v-spacer>
                        <v-select v-model="notChekInDT" :items="deductionList" item-text="txt" item-value="val"
                            outlined dense color="#F99D20" class="custom-select"
                            v-on:change="changeNCIDT(notChekInDT)"
                            style="font-size: 14px; max-width: 300px; min-width: 50px;">
                        </v-select>
                    </v-row>
                    <div v-show="shownotCICard">
                        <hr color="#DCDCDC" style="height: 1px !important; transform: scaleY(0.5);"
                            class="ml-5 mr-5">
                        <v-row class="ml-4 mr-4 mt-4">
                            <p class="mt-2">{{ $t("Amtdeductedperday") }}</p>
                            <v-spacer></v-spacer>
                            <v-text-field :key="clockInDeductkey" v-model="clockInDeduct" color="#F99D20" outlined
                                dense class="custom-text-field-1" @keypress="NumberOrFloat($event, clockInDeduct)">
                                <template v-slot:prepend-inner>
                                    ฿
                                </template></v-text-field>
                        </v-row>
                    </div>
                </div>
            </v-tab-item>
        </v-tabs>
        </v-card>
        
        <v-footer style="background-color:#FFFFFF" elevation="20" height="60px" fixed>
            <v-row style="flex-direction: row-reverse">
                <!-- Save & Cancel -->
                <v-btn class="pa-2 mr-5 text-capitalize commonsavebtn" 
                    :disabled="isdisabled || permission.edit"
                    @click="confirmdia = true">{{ $t("save") }}</v-btn>
                <v-btn class="pa-2 mr-7 text-capitalize commoncancelbtn"
                    outlined text @click="cancelAll">{{ $t("cancel") }}</v-btn>
            </v-row>
        </v-footer>
        </div>
    
        <!-- Loading Dialog -->
        <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
        
        <v-dialog v-model="confirmdia" persistent width="450">
            <v-card style="max-height: 200px;">
                <v-card-text class="pt-3">
                    <v-card-title class="justify-center mb-2">
                        {{ $t("confirmation") }}
                    </v-card-title>
                    <v-card-subtitle style="text-align: center !important;">
                        {{ $t("wanttoEdit") }}
                    </v-card-subtitle>
                </v-card-text>
                <v-card-actions>
                    <v-row class="justify-space-around mb-3">
                        <!-- Save & Cancel -->
                        <v-btn outlined style="margin-left: auto;"
                            class="pa-2 mr-3 text-capitalize commoncancelbtn" @click="confirmdia = false" text>{{
                $t("cancel")
            }}</v-btn>
                        <v-btn class="pa-2 text-capitalize commonsavebtn"
                            style="margin-right: auto"
                            @click="updateOTSetting">{{
                $t("Confirm") }}</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import CheckViewEdit from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
    components:{
    LoadingDialog,
    },
    mixins: [LogTrace, CheckViewEdit],
    data() {
        return {
            url: enurl.apiUrl,
            LoadingDialog: false,
            theTab: 0,
            showOvertimeCard: true,
            showlateCard: false,
            shownotCICard: false,
            showCard: false,
            oT_SettingID: 0,
            overtimefixrate: 0.00,
            oTone: 0,
            oTonekey: 1,
            oTtwo: 0,
            oTtwokey: 1,
            oTthree: 0,
            oTthreekey: 1,
            oTfour: 0,
            oTfourkey: 1,
            mask: "########",
            lateDT: 2,
            isdisabled: true,
            confirmdia: false,
            lateDeduct: 0.00,
            notChekInDT: 2,
            clockInDeduct: 0.00,
            leaveTypeList: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
            otfixratekey: 1,
            lateDeductkey: 1,
            clockInDeductkey: 1,
            permission: {
                edit: true,
            },
        };
    },
    computed: {
        deductionList() {
            return [
                { val: 1, txt: this.$t("Nodeductions") },
                { val: 2, txt: this.$t("Amountdeducted") },
            ];
        },
    },
    watch: {
        oTone() {
            let self = this;
            if (self.oTone >= 999.99) {
                self.oTone = 999.99;
            }
            const newval = self.deletezero(self.oTone, self.oTonekey);
            self.oTone = newval.value;
            self.oTonekey = newval.inckey;
        },
        oTtwo() {
            let self = this;
            if (self.oTtwo >= 999.99) {
                self.oTtwo = 999.99;
            }
            const newval = self.deletezero(self.oTtwo, self.oTtwokey);
            self.oTtwo = newval.value;
            self.oTtwokey = newval.inckey;
        },
        oTthree() {
            let self = this;
            if (self.oTthree >= 999.99) {
                self.oTthree = 999.99;
            }
            const newval = self.deletezero(self.oTthree, self.oTthreekey);
            self.oTthree = newval.value;
            self.oTthreekey = newval.inckey;
        },
        oTfour() {
            let self = this;
            if (self.oTfour >= 999.99) {
                self.oTfour = 999.99;
            }
            const newval = self.deletezero(self.oTfour, self.oTfourkey);
            self.oTfour = newval.value;
            self.oTfourkey = newval.inckey;
        },
        lateDeduct() {
            let self = this;
            self.saveBtnIsDisable();
            if (self.lateDeduct >= 999.99) {
                self.lateDeduct = 999.99;
            }
            const newval = self.deletezero(self.lateDeduct, self.lateDeductkey);
            self.lateDeduct = newval.value;
            self.lateDeductkey = newval.inckey;
        },
        clockInDeduct() {
            let self = this;
            self.saveBtnIsDisable();
            if (self.clockInDeduct >= 999.99) {
                self.clockInDeduct = 999.99;
            }
            const newval = self.deletezero(self.clockInDeduct, self.clockInDeductkey);
            self.clockInDeduct = newval.value;
            self.clockInDeductkey = newval.inckey;
        },
        overtimefixrate() {
            let self = this;
            self.saveBtnIsDisable();
            if (self.overtimefixrate >= 1000000) {
                self.overtimefixrate = 1000000;
            }
            const newval = self.deletezero(self.overtimefixrate, self.otfixratekey);
            self.overtimefixrate = newval.value;
            self.otfixratekey = newval.inckey;
        },
        theTab() {
            this.cancelAll();
            this.saveBtnIsDisable();
        },
        showOvertimeCard() {
            if (this.showOvertimeCard == false) {
                this.isdisabled = false;
            } else {
                this.saveBtnIsDisable();
            }
        },
        lateDT() {
            if (this.lateDT == 1) {
                this.isdisabled = false;
            } else {
                this.saveBtnIsDisable();
            }
        },
        notChekInDT() {
            if (this.notChekInDT == 1) {
                this.isdisabled = false;
            } else {
                this.saveBtnIsDisable();
            }
        }
    },
    mounted() {
        this.changelateDT(this.lateDT);
        this.changeNCIDT(this.notChekInDT);
        this.getOTSetting();
        this.getLateSetting();
        this.getClockInSetting();
        this.permission = this.CheckViewEdit(230, 231);
    },
    methods: {
        deletezero(val, key) {
            if (val && val[0] === '0' && val[1] != '.' && val.length != 1) {
                let newval = val.substring(1);
                this.$forceUpdate();
                let newkey = key + 1;
                return { value: newval, inckey: newkey };
            } else {
                return { value: val, inckey: key };
            }
        },
        NumberOrFloat($event, value) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (((keyCode < 48 || keyCode > 57) && keyCode !== 46) ||
                (keyCode == 46 && String(value).includes(".")) || parseFloat(value) >= 1000000) {
                $event.preventDefault();
            }
        },
        changelateDT(v) {
            let self = this;
            if (v == 1) {
                self.showlateCard = false;
            } else {
                self.showlateCard = true;
            }
        },
        changeNCIDT(v) {
            let self = this;
            if (v == 1) {
                self.shownotCICard = false;
            } else {
                self.shownotCICard = true;
            }
        },
        getOTSetting() {
            let self = this;
            self.LoadingDialog = true;
            let temp = {
                company_ID: store.state.companyID,
            }
            axios
                .post(`${self.url}OT_Setting/GetOTSetting`, temp)
                .then(function (response) {
                    let res = response.data.data;
                    self.oT_SettingID = res.oT_Setting_ID;
                    self.overtimefixrate = res.fixRate == null || parseFloat(res.fixRate) == 0 ? "0.00" : parseFloat(res.fixRate);
                    self.showOvertimeCard = res.fixRateFlag;
                    self.oTone = res.oT1 == null ? 0 : parseFloat(res.oT1);
                    self.oTtwo = res.oT2 == null ? 0 : parseFloat(res.oT2);
                    self.oTthree = res.oT3 == null ? 0 : parseFloat(res.oT3);
                    self.oTfour = res.oT4 == null ? 0 : parseFloat(res.oT4);
                    self.LoadingDialog = false;
                });

        },
        updateOTSetting() {
            let self = this;
            self.confirmdia = false;
            self.LoadingDialog = true;
            //update OverTime Tab
            if (self.theTab == 0) {
                let temp = {
                    oT_Setting_ID: self.oT_SettingID,
                    company_ID: store.state.companyID,
                    oT1: self.oTone == "" ? "0" : self.oTone + "",
                    oT2: self.oTtwo == "" ? "0" : self.oTtwo + "",
                    oT3: self.oTthree == "" ? "0" : self.oTthree + "",
                    oT4: self.oTfour == "" ? "0" : self.oTfour + "",
                    fixRateFlag: self.showOvertimeCard,
                    fixRate: parseFloat(self.overtimefixrate) + "",
                }
                axios
                    .post(`${self.url}OT_Setting/UpdateOTSetting`, temp)
                    .then(function (response) {
                        alert(response.data.message);
                        self.getOTSetting();
                        self.LoadingDialog = false;
                    });
            }
            //update Late Tab
            if (self.theTab == 1) {
                let latetemp = {
                    company_ID: store.state.companyID,
                    lateFlag: self.lateDT != 1,
                    lateDeduct: parseFloat(self.lateDeduct),
                }
                axios
                    .post(`${self.url}Company/UpdateLateByCompanyId`, latetemp)
                    .then(function (response) {
                        alert(response.data.message);
                        self.getLateSetting();
                        self.LoadingDialog = false;
                    });
            }
            //update Not Clock-In Tab
            if (self.theTab == 2) {
                let clockIntemp = {
                    company_ID: store.state.companyID,
                    clockInFlag: self.notChekInDT != 1,
                    clockInDeduct: parseFloat(self.clockInDeduct),
                }
                axios
                    .post(`${self.url}Company/UpdateClockInByCompanyId`, clockIntemp)
                    .then(function (response) {
                        alert(response.data.message);
                        self.getClockInSetting();
                        self.LoadingDialog = false;
                    });
            }
        },
        cancelAll() {
            let self = this;
            if (self.theTab == 0) {
                self.getOTSetting();
            }
            if (self.theTab == 1) {
                self.getLateSetting();
            }
            if (self.theTab == 2) {
                self.getClockInSetting();
            }
        },
        getLateSetting() {
            let self = this;
            self.LoadingDialog = true;
            let temp = {
                company_ID: store.state.companyID,
            }
            axios
                .post(`${self.url}Company/GetLateByCompanyId`, temp)
                .then(function (response) {
                    let data = response.data.data;
                    self.lateDT = data.lateFlag == false ? 1 : 2;
                    self.lateDeduct = data.lateDeduct == null ? 0.00 : data.lateDeduct.toFixed(2);
                    self.changelateDT(self.lateDT);
                    self.LoadingDialog = false;
                });

        },
        getClockInSetting() {
            let self = this;
            self.LoadingDialog = true;
            let temp = {
                company_ID: store.state.companyID,
            }
            axios
                .post(`${self.url}Company/GetClockInByCompanyId`, temp)
                .then(function (response) {
                    let data = response.data.data;
                    self.notChekInDT = data.clockInFlag == false ? 1 : 2;
                    self.clockInDeduct = data.clockInDeduct == null || 0 ? 0.00 : data.clockInDeduct.toFixed(2);
                    self.changeNCIDT(self.notChekInDT);
                    self.LoadingDialog = false;
                });
        },
        saveBtnIsDisable() {
            let self = this;
            if ((self.theTab == 0 && (self.overtimefixrate == "" || self.overtimefixrate == null || self.overtimefixrate == ".")) ||
                (self.theTab == 1 && (self.lateDeduct == "" || self.lateDeduct == null || self.lateDeduct == ".")) ||
                (self.theTab == 2 && (self.clockInDeduct == "" || self.clockInDeduct == null || self.clockInDeduct == "."))
            ) {
                self.isdisabled = true;
            } else {
                self.isdisabled = false;
            }
            if (self.theTab == 0 && self.showOvertimeCard == false) {
                self.isdisabled = false;
            }
        },
    }
};
</script>

<style scoped>
::v-deep .inner.v-tabs .v-tab.v-tab--active {
    background-color: white !important;
    color: #F99D20;
}

::v-deep .inner .v-tabs-slider {
    background-color: #F99D20;
    height: 100%;
    width: 100%;
}

::v-deep .v-tabs-slider {
    background-color: currentColor;
    height: 0;
    width: 0;
}

/* for on/off check  */
@media only screen {
    input {
        -webkit-appearance: none;
        appearance: none;
        width: 64px;
        padding-left: 8px;
        margin: 0;
        border-radius: 16px;
        background: radial-gradient(circle 12px,
                white 100%,
                transparent calc(100% + 1px)) #ccc 16px;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        color: transparent;
        /* Hide the text by making it transparent */
    }

    input::before {
        content: "ON";
        font: 12px/32px Kanit;
        /* color: white; */
    }

    input:checked {
        padding-left: 33px;
        padding-right: -16px;
        background-color: #f99d20;
        background-position: -16px;
        cursor: pointer;
    }

    input:checked::before {
        content: "OFF";
    }
}

/* for on/off check  */

.custom-text-field-1 {
    max-width: 300px;
}

::v-deep .custom-text-field-1>.v-input__control>.v-input__slot>.v-input__prepend-inner {
    padding-top: 3px;
}

::v-deep .custom-text-field-1 input {
    /* direction: rtl; Set text direction to right-to-left */
    text-align: right;
    /* Align text to the right */
}

::v-deep .Rheader {
    font-weight: normal;
    background-color: #EEEEEE;
    color: #707070;
    border-radius: 10px 10px 0px 0px;
}

::v-deep .Rheader>th {
    font-size: 16px !important;
}

.OTone {
    float: right;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}

::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
    color: #d3d3d3;
}

/* ::v-deep .v-list-item--link::before { background-color: #F99D20; color: #F99D20;  } */

/* ::v-deep .theme--light.v-list-item { opacity: 0.64; } */
.unread.v-text-field>.v-input__control>.v-input__slot {
    background-color: none !important;
}

.readonly.v-text-field>.v-input__control>.v-input__slot {
    background-color: #eeeeee !important;
}
</style>