<template>
    <div style="margin-left: 1.5rem; margin-right: 1.5rem">
        <div>
            <p text class="ml-5 txt-header">
                {{ $t("usageplan") }}
            </p>
        </div>
        <v-card class="mt-6" style="margin-bottom: 5rem; margin-left: 1.5rem; margin-right: 1.5rem; color:#ffffff">
            <p style="color:#A3A7AA; padding-top:20px; padding-left:25px; font-size:19px">{{ $t("CurrentPlan") }}</p>
            <v-row cols="12" style="margin-left:2rem;margin-right:2rem;" class=" d-flex-row
                flex-wrap
                justify-space-between">
                <v-col style="padding-left:4px">
                    <p style="padding-top:10px; color:#F99D20">{{ $t("Package") }} :<span class="mx-5"
                            style="color:#444444">{{ this.package.plan }}</span></p>
                    <p style="padding-top:10px; color:#F99D20">{{ $t("ActiveEmployee") }} :<span class="mx-5"
                            style="color:#444444">{{ this.package.emp }}</span></p>
                    <p style="padding-top:10px; color:#F99D20">{{ $t("ExpiryDate") }} :<span class="mx-5"
                            style="color:#444444">{{ this.package.expdate }}</span></p>

                </v-col>
                <v-col>
                    <v-card width="220" height="120" color="#FBECD7"
                        style="text-align:center; margin-left:auto; margin-bottom: 5px;">
                        <v-card-subtitle style="font-size:40px; color:#FF0000; padding-top:20px">{{
                            this.remainDate.trialDate }}</v-card-subtitle>
                        <v-card-subtitle class="remain" style="font-size:16px; color:#444444">{{ $t("DayRemaining")
                        }}</v-card-subtitle>
                    </v-card>

                </v-col>
            </v-row>
            <hr>
            <v-row class="ml-2" align="center" style="margin-top:5px; justify-content:center">
                <p style="color:#444444; font-size:25px;" class="renew">{{ $t("ToRenewPlan") }}</p><br>
            </v-row>
            <v-row align="center" style="justify-content:center;">
                <v-btn plain color="transparent" style="text-transform:none;; padding-top:2px"><v-icon color="#F99D20"
                        size="25px">mdi mdi-email</v-icon></v-btn><span style="color:#A3A7AA">sales@fusionsol.com</span>
            </v-row>
            <v-row align="center" style="margin-top:5px; justify-content:center;padding-bottom:20px;">
                <div style="margin-left:-50px">
                    <v-btn plain color="transparent" style="text-transform:none;"><v-icon color="#F99D20" size="23px">mdi
                            mdi-phone</v-icon></v-btn><span style="color:#A3A7AA; margin-right:">02-440-0408</span>
                </div>
            </v-row>
        </v-card>
        <v-row>
        </v-row>

        <!-- Loadingdialog -->
        <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
    </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LoadingDialog from "@/components/Loading.vue";

export default {
    components:{
    LoadingDialog,
  },
    data() {
        return {
            url: enurl.apiUrl,
            LoadingDialog: false,
            companyID: store.state.Company_ID,
            plan: null,
            package: {
                plan: "",
                emp: null,
                days: null,
                expdate: ""
            },
            remainDate: {
                trialDate: ""
            }
        }
    },
    mounted() {
        let self = this;
        self.getPackageId();
    },
    methods: {
        getPackageId() {
            let self = this;
            self.LoadingDialog = true;
            let temp = { company_ID: store.state.companyID };
            // self.LoadingDialog=false;
            axios.post(`${self.url}Company/GetCompanyID`, temp)
            .then(function (response) {
                self.plan = response.data.data;
                self.package.plan = self.plan.pkgName;
                self.package.emp = self.plan.activeEmp;
                self.package.expdate = self.plan.expired_Date;
                self.formatDate(self.package.expdate);
                self.calculateDay(self.plan.expired_Date);
                self.LoadingDialog = false;
            })
        },

        formatDate(date) {
            let spliteDateAndTime = date.split("T");
            let Date = spliteDateAndTime[0].split("-");
            this.package.expdate = Date[2] + "/" + Date[1] + "/" + Date[0];
        },

        calculateDay(end) {
            let self = this;
            let expdate = new Date(end).getTime();
            let currentdate = new Date().getTime();
            let timeDiff = expdate - currentdate;
            self.remainDate.trialDate = Math.floor((timeDiff / (1000 * 60 * 60 * 24)) + 1).toString();
        }
    }
}

</script>
<style >
@media only screen and (max-width:360px) {
    .v-card__subtitle.remain {
        font-size: 11px !important;
    }

    .renew {
        font-size: 19px !important;
    }
}
</style>