<template>
    <v-row justify="end" class="ml-5 mt-5 mr-5">
      <v-col cols="12" md="4" lg="4" sm="6">
        <v-pagination
          v-model="localPage"
          :length="pageCount"
          color="#F89D1F"
          @input="onPageChange"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="2" class="mt-1">
        <v-select
          dense
          style="width: 120px; float: right"
          solo
          label="10"
          v-model="localItemsPerPage"
          :items="items"
          @input="onItemsPerPageChange"
        ></v-select>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    props: {
      page: {
        type: Number,
        default: 1
      },
      pageCount: {
        type: Number,
        required: true
      },
      itemsPerPage: {
        type: Number,
        default: 10
      }
    },
    data() {
      return {
        localPage: this.page,
        localItemsPerPage: this.itemsPerPage,
        items: [10, 20, 30, 50],
      };
    },
    watch: {
      page(newVal) {
        this.localPage = newVal;
      },
      itemsPerPage(newVal) {
        this.localItemsPerPage = newVal;
      }
    },
    methods: {
      onPageChange(newPage) {
        this.$emit('update:page', newPage);
      },
      onItemsPerPageChange(newItemsPerPage) {
        this.$emit('update:itemsPerPage', parseInt(newItemsPerPage, 10));
      }
    }
  };
  </script>

<style scoped>
::v-deep .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
</style>
  