import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import  lineClamp from "vue-line-clamp";

Vue.use(Vuetify);
Vue.use(lineClamp, {
    // plugin options
  });

export default new Vuetify({
});
