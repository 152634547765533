/* eslint-disable no-console */
import axios from "axios";
import enurl from "@/api/environment";
import store from "../store";
export default {
  data() {
    return {
      url: enurl.apiUrl,
    };
  },
  methods: {
    LogTrace(exception, logtrace, correlation, logtype) {
      if (
        exception != null &&
        exception != undefined &&
        exception != "Company expired"
      ) {
        var description = exception.message;
      } else {
        description = "-";
      }

      let tempLog = {
        user_ID:
          store.state.userid != ""
            ? store.state.userid
            : localStorage.getItem("uid"),
        company_ID:
          store.state.companyID != ""
            ? store.state.companyID
            : localStorage.getItem("cid"),
        correlation_ID: correlation,
        logType: logtype,
        exception: description,
        logTrace: logtrace,
        application: "Optimistic Web",
      };
      axios

        .post(`${this.url}Log/AddLog`, tempLog)
        .then(function () {
          // alert(response.data.message);
        })
        .catch(function (error) {
          alert(error);
        });
    },
  },
};
