<template>
  <div style="margin-left:1.5rem;margin-right:1.5rem">
    <div>
      <p style="font-size:25px;font-family:'Kanit', sans-serif; color:#43425D;margin-top:15px"
      >Work Schedule</p>
      <v-tabs class="mt-6" grey--text background-color="#f0f8fc" slider-color="#F99D20" show-arrows>
        <v-tab
          class="text-capitalize"
          style="border-radius: 10px 10px 0px 5px;width:150px;margin-right:3px;font-size:16px"
        >Plan</v-tab>
        <v-tab
          class="text-capitalize"
          style="border-radius: 10px 10px 0px 5px;width:150px;margin-right:3px;font-size:16px"
        >Action</v-tab>

        <v-tab-item>
          <v-card>
            <Plan />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <Action />
          </v-card>
        </v-tab-item>
      </v-tabs>
      <br />
      <br />
    </div>
  </div>
</template>
<script>
import Plan from "./Plan";
import Action from "./Action";
export default {
  components: {
    Plan,
    Action
  },
  data: () => ({}),
  methods: {}
};
</script>
<style scoped>
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
}
.active {
  cursor: pointer !important;
}
.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}
/* 
 >>> .element.style {
  background-color: rgb(44, 255, 70);
  border-color: rgb(54, 41, 130);
} */
</style>

