<template>
  <div style="margin-left: 0.1%; margin-right: 0.1%">
    <br />
    <v-row>
      <v-col style="padding-left: 5px">
        <v-col class="d-flex" style="margin-left: 2rem; margin-top: -0.5rem">
          <p style="font-size: 18px; color: #3c4043">
            {{ $t("TimeEntriesDefaultView") }}
          </p>
        </v-col>
      </v-col>
    </v-row>

    <v-row style="margin-left: 2.4rem; margin-top: -1rem">
      <v-col cols="12" md="12">
        <v-row class="pl-md-0" style="margin-top: -2rem">
          <v-row class="mt-2">
            <v-col cols="6" md="4" style="font-size: 18px">
              <v-radio-group
                :disabled="disradio"
                v-model="radios"
                :mandatory="false"
              >
                <v-radio :label="$t('CalendarView')" :value="true"></v-radio>
                <v-radio
                  class="mt-4"
                  :label="$t('TimesheetView')"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <div style="height: 23rem">
      <div
        class="d-flex flex-row-reverse"
        style="margin-top: 3rem; padding-bottom: 3rem"
      >
        <div v-show="showedit">
          <v-btn
            color="#F89D1F"
            class="pa-2 mr-7 text_transform_none"
            rounded
            width="160px"
            height="50px"
            :disabled="permission.edit"
            @click="editgone"
            >{{ $t("edit") }}</v-btn
          >
        </div>
        <v-btn
          color="#F89D1F"
          v-show="!showedit"
          class="pa-2 mr-7 text_transform_none fix-btn"
          rounded
          width="160px"
          height="50px"
          :disabled="permission.edit"
          @click="CalOrSheet"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          color="#3C4043"
          v-show="!showedit"
          class="pa-2 mr-5 text_transform_none fix-btn"
          rounded
          width="160px"
          height="50px"
          text
          router
          @click="openedit"
          >{{ $t("cancel") }}</v-btn
        >
      </div>
    </div>
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewEdit from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils, CheckViewEdit],
  data() {
    return {
      permission: {
        edit: true,
      },
      url: enurl.apiUrl,
      LoadingDialog: false,
      TimeSheetList: [],
      radios: false,
      timeid: null,
      showedit: true,
      disradio: true,
    };
  },
  mounted() {
    this.GetTimesheet();
    this.permission = this.CheckViewEdit(135, 136);
  },
  methods: {
    GetTimesheet() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        employee_ID: store.state.employeeID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceView/GetTimeAttendanceViewByEmployeeID`,
          temp
        )
        .then(function (response) {
          self.TimeSheetList = response.data.data;
          self.timeid = self.TimeSheetList[0].timeAttendanceView_ID;
          self.radios = self.TimeSheetList[0].calendar_View;
          self.LoadingDialog = false;
          // self.LogTrace(
          //   null,
          //   "Get Company Set Up, Time Attendance",
          //   30,
          //   "Critical"
          // );
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Get Company Set Up, Time Attendance Fail",
            30,
            "Critical"
          );
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    openedit() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceView/GetTimeAttendanceViewByEmployeeID`,
          temp
        )
        .then(function (response) {
          self.TimeSheetList = response.data.data;
          self.timeid = self.TimeSheetList[0].timeAttendanceView_ID;
          self.radios = self.TimeSheetList[0].calendar_View;
          self.LoadingDialog = false;
          //self.LogTrace(null, "Get Timesheet", 30, "Critical");
        });
      self.disradio = true;
      self.showedit = true;
    },
    editgone() {
      let self = this;
      self.disradio = false;
      self.showedit = false;
    },
    CalOrSheet() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        timeAttendanceView_ID: self.timeid,
        calendar_View: self.radios,
        timeSheet_View: !self.radios,
        employee_ID: store.state.employeeID,
      };
      axios
        .post(`${self.url}TimeAttendanceView/UpdateTimeAttendanceView`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
            alert("Change Successfully!");
            self.showedit = true;
            self.disradio = true;
            // self.LogTrace(
            //   null,
            //   "Update Company Set Up, Time Attendance",
            //   30,
            //   "Medium"
            // );
          }
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Update Company Set Up, Time Attendance Fail",
            30,
            "Medium"
          );
          alert(error);
          self.LoadingDialog = false;
        });
    },
  },
};
</script>

<style scoped>
.empID {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: #8a8c8e;
  font-family: "Kanit", sans-serif;
}
@media (max-width: 414px) {
  .fix-btn {
    width: 100px !important;
  }
}
</style>
