export const utils = {
  mounted: function() {
    this.authenticatedOrRedirect();
  },
  methods: {
    authenticatedOrRedirect() {
      const token = sessionStorage.getItem("token");
      if (!token) this.$router.push("/");
    },
  },
};
