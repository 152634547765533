<template>
  <div>
    <div class="pt-7">
      <v-row class="pl-md-0 pt-9" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3">
          <v-text-field
            :placeholder="$t('Search') + ' with Role Name'"
            v-model="searchtxt"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.7rem; margin-left:1rem;"
            @keyup.enter="Search()"></v-text-field>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-text-field
            autocomplete="off"
            :placeholder="$t('Search') + ' with Role Name'"
            v-model.lazy="searchtxt"
            color="#F99D20"
            dense
            style="margin-top: -0.3rem; margin-left: 1rem"
          ></v-text-field>
        </v-col>
        <v-btn
          color="#F99D20"
          @click="Search"
          class="white--text text-capitalize ml-6 mb-2"
          width="120"
          max-height="35"
        >
          <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn
          class="text-capitalize mr-5 ml-5 btnlef commonsavebtn"
          @click="NewRole()"
          :disabled="permission.add"
        >
          {{ $t("NewRole") }}</v-btn
        >
      </v-row>
    </div>
    <v-data-table
      :items="roleList"
      style="margin-left: 40px; margin-right: 40px"
      class="tbheader"
      :mobile-breakpoint="0"
      :headers="headers"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          icon
          router
          @click="EditRole(item.role_ID)"
          :disabled="permission.edit"
        >
          <img class="editIcon" :src="images.edit" alt="editIcon"/>
        </v-btn>
        <v-btn
          icon
          class="ml-3"
          @click="DeleteRole(item.role_ID, item.role_Name)"
          :disabled="permission.delete"
        >
          <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-right">
      <PaginationComponent
          :page.sync="page"
          :pageCount="pageCount"
          :itemsPerPage.sync="itemsPerPage"
      />
      <!-- <v-row justify="end" class="ml-5 mt-5 mr-5">
        <v-col cols="12" md="3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="#FFCD2C"
            :total-visible="6"
          ></v-pagination>
        </v-col>
        <v-col cols="12" md="2" class="mt-1">
          <v-select
            dense
            style="width: 120px; float: right"
            solo
            label="10/page"
            v-model="itemsPerPage"
            :items="items"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-select>
        </v-col>
      </v-row> -->
    </div>
    <!-- For Default Role Delete dialog -->
    <v-dialog v-model="DefaultDeletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="left" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("NotDeleted") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5 text-capitalize"
            width="120"
            max-height="35"
            @click="DefaultDeletedialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete Role dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="left" cols="12" v-if="role_User.length != 0">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("roleconfirmdelete") }}
                </p>
              </v-col>
              <v-col align="left" cols="12" v-else>
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5 text-capitalize"
            width="120"
            max-height="35"
            @click="Deletedialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-5 white--text text-capitalize delete_btn"
            width="120"
            max-height="35"
            @click="Deleterow()"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- loadingdailog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
<script>
import store from "@/store";
import enurl from "@/api/environment";
import axios from "axios";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, utils, CheckViewAddEditDelete],
  data() {
    return {
      DefaultDeletedialog: false,
      permission: {
        add: true,
        edit: true,
        delete: true,
      },
      url: enurl.apiUrl,
      searchtxt: "",
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      rid: 1,
      deleteRoleId: 0,
      Deletedialog: false,
      loadingdialog: false,
      thesearchnum: 0,
      roleList: [],
      error: {
        roleid: null,
        rolename: null,
      },
      add: {
        roleid: "",
        rolename: "",
      },
      edit: {
        roleid: null,
        rolename: null,
      },
      role_User: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("RoleCode"),
          value: "role_Code",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("RoleName"),
          value: "role_Name",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("action"),
          value: "action",
          align: "center",
          sortable: true,
        },
      ];
    },
    images() {
      return this.$store.state.images;
    }
  },

  watch: {
    searchtxt: function () {
      if(this.thesearchnum == 1){
        this.getRole();
      }
    },
  },
  mounted: function () {
    this.getRole();
    this.permission = this.CheckViewAddEditDelete(112, 113, 114, 115);
  },
  methods: {
    Search() {
      let self = this;
      self.thesearchnum = 1;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.roleList;
        self.roleList = [];
        let temp = List.filter(
          (v) =>
            v.role_Name.toLowerCase().indexOf(self.searchtxt.toLowerCase()) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.roleList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.getRole();
      }
    },
    NewRole() {
      this.$router.push({ name: "roleadd", params: { roleadd: "roleadd" } }).catch(()=>{});
    },
    EditRole(id) {
      this.$router.push({
        name: "roleedit",
        params: { roleedit: "roleedit", rid: id },
      }).catch(()=>{});
    },
    getRole() {
      let self = this;
      self.loadingdialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}Role/GetRoleByCompanyID`, tempp)
        .then(function (response) {
          self.roleList = response.data.data;
          self.thesearchnum = 0;
          self.LogTrace(null, "Get Role", 37, "Critical");
          self.loadingdialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Role Fail", 37, "Critical");
          //alert(error);
          self.loadingdialog = false;
        });
    },
    addRole() {
      let self = this;
      self.loadingdialog = true;
      self.error.roleid = !self.add.roleid
        ? "Please fill in the required fields"
        : "";
      self.error.rolename = !self.add.rolename
        ? "Please fill in the required fields"
        : "";
      let tempvali = self.ValidateAddTrue();
      if (tempvali == true) {
        let roleshift = {
          rolename: self.add.rolename,
          roleid: self.add.roleid,
        };
        axios
          .post(`${self.url}Role/AddRole`, roleshift)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.add.shift_Code = null;
              self.add.start_Time = null;
              self.add.end_Time = null;
              self.addDialog = false;
              self.GetShiftCode();
              self.LogTrace(null, "Add Role", 37, "Low");
              self.loadingdialog = false;
            }
          })
          .catch(function (error) {
            alert(error);
            self.LogTrace(error, "Add Role Fail", 37, "Low");
            self.loadingdialog = false;
          });
      } else {
        this.ValidateAddFalse();
        self.loadingdialog = false;
      }
    },

    DeleteRole(id, name) {
      let self = this;
      this.deleteRoleId = id;
      let tempp = {
        role_ID: id,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroupMember/GetUserRoleInfo`, tempp)
        .then(function (response) {
          self.role_User = response.data.data;
        })
        .catch(function (error) {
          alert(error);
        });
      if (name == "Admin" || name == "HR" || name == "Employee") {
        self.Deletedialog = false;
        self.DefaultDeletedialog = true;
      } else {
        self.DefaultDeletedialog = false;
        setTimeout(() => {
          self.Deletedialog = true;
        }, 200);
      }
    },
    Deleterow() {
      let self = this;
      let id = self.deleteRoleId;
      self.loadingdialog = true;
      let tempdel = {
        role_ID: id,
      };
      axios
        .post(`${self.url}Role/DeleteRole`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.Deletedialog = false;
            self.getRole();
            self.loadingdialog = false;
            self.LogTrace(null, "Delete Row", 37, "High");
          }
        })
        .catch(function (error) {
          this.LogTrace(error, "Delete Row Fail", 37, "High");
          alert(error);
          self.Deletedialog = false;
          self.loadingdialog = false;
        });
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 320px) {
  .delete_btn {
    margin-left: -10px !important;
  }
}
@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
  .btnlef {
    margin-left: 1.5rem !important;
  }
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .v-data-table tr td {
  height: 70px;
  text-align: center;
  width: 800px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
