<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="ml-4 headtext txt-header">
        {{ $t("AnnualReport") }}
      </p>
    </div>

    <v-row style="margin-right: 0; margin-left: -2px">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pt-1 mb-5 card" height="100%">
          <div class="di">
            <v-divider v-show="withhold" />
            <v-list v-show="withhold">
              <v-list-item router to="/annualreport/taxcertificate">
                <img
                  width="25px;"
                  height="25px;"
                  :src="item.taxwithhold"
                  class="mr-4"
                />
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("withholdtax") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="withhold" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      off: "Time Off",
      age: "Employees by Age and Gender",
      salaryhis: "Salary History​",
      paychange: "Last Pay Change",
      timeoff: "Time Off Used",
      item: {
        addition: require("@/assets/images/Retention_1_@2x.png"),
        termination: require("@/assets/images/user@2x.png"),
        agegender: require("@/assets/images/group@2x.png"),
        lastpay: require("@/assets/images/Icon metro-money@2x.png"),
        salaryhis: require("@/assets/images/salary@2x.png"),
        Timeoffused: require("@/assets/images/clock@2x.png"),
        socialreport: require("@/assets/images/Icon metro-security.png"),
        taxwithhold: require("@/assets/images/Rectangle 2352.png"),
      },
      addition: false,
      termination: false,
      agegender: false,
      salaryhistory: false,
      lastpaychange: false,
      timeoffused: false,
      ssf: false,
      withhold: false,
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      this.addition =
        this.termination =
        this.agegender =
        this.salaryhistory =
        this.lastpaychange =
        this.timeoffused =
        this.ssf =
        this.withhold =
          true;
    }
    // if (perid.some((e) => e == 49)) {
    //   this.items[0].showthis = true;
    // }
    if (perid.some((e) => e == 172)) {
      this.addition = true;
    }
    if (perid.some((e) => e == 174)) {
      this.termination = true;
    }
    if (perid.some((e) => e == 176)) {
      this.agegender = true;
    }
    if (perid.some((e) => e == 178)) {
      this.salaryhistory = true;
    }
    if (perid.some((e) => e == 180)) {
      this.lastpaychange = true;
    }
    if (perid.some((e) => e == 182)) {
      this.timeoffused = true;
    }
    if (perid.some((e) => e == 184)) {
      this.ssf = true;
    }
    if (perid.some((e) => e == 186)) {
      this.withhold = true;
    }
  },
};
</script>
<style scoped>
.card {
  /* margin-bottom: 5rem; */
  margin-left: 1%;
  margin-right: 0.3%;
  /* height: 1000px; */
  /* margin-top: -1.5rem; */
}
.titles {
  color: #f89d1f;
  font-weight: bold;
  font-size: 17px;
  margin-top: -1rem;
}
.di {
  margin: 20px;
  margin-bottom: 3rem;
}
</style>

