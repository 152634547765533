<template>
  <div>
    <div style="padding-left: 0px;padding-right: 1%;margin-bottom: 0.5rem;">
      <v-row>
        <v-btn icon @click="back()" color="white" class="mt-6 ml-6">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" alt=""/>
        </v-btn>
        <p text
          style="
            cursor: pointer;
            font-size: 26px;
            margin-top: 0.2rem;
            margin-left: 5px;
            padding: 18px;"
          @click="back()">
          {{ $t("personal_id") }}
        </p>
      </v-row>
    </div>
    <v-card class="pt-5" height="100vh" style="margin-bottom: 5rem; margin-left: 2rem; margin-right: 2rem">
      <v-row>
        <p class="ml-10" style="font-size: 21px; color: black">
          {{ $t("personal_id") }}
        </p>
        <v-spacer></v-spacer>
        <v-btn
          color="#F99D20"
          class="orange--text text-capitalize mr-5 btn"
          width="150"
          max-height="35"
          outlined
          @click="uploaddialog2 = true">{{ $t("upload") }}
        </v-btn>
      </v-row>
      <v-row>
        <v-col cols="12" v-show="showthis">
          <v-row justify="center">
            <v-col cols="6" md="6">
              <v-img v-if="imageUrl" v-show="!checkImage"
                id="hidden"
                :src="'data:image/png;base64,' + imageUrl"
                class="preview_img_file"
                contain
                aspect-ratio="2"
                style="background: #ffffff;
                  color: #707070;
                  font-size: 25px;
                  margin-top: 140px;
                  margin-left: auto;
                  margin-right: auto;">
              </v-img>
              <v-img v-show="checkImage" :src="imageUrl1" id="hidden"
                class="preview_img_file"
                contain
                aspect-ratio="2"
                style="
                  background: #ffffff;
                  color: #707070;
                  font-size: 25px;
                  margin-top: 140px;
                  margin-left: auto;
                  margin-right: auto;">
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="uploaddialog2" persistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: -15px; padding-top: 20px" cols="5" md="5">
                <p class="ml-4 mt-2" v-show="showfile">{{ showfilename }}</p>
              </v-col>
              <v-col style="margin-left: -10px; padding-top: 20px" cols="5" md="5">
                <v-btn v-show="showfile" @click="closeimage()" icon>
                  <img width="20" height="20" src="@/assets/closered.png" alt />
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mb-6">
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  style="text-align: left; float: left; margin-top: -10px"
                  width="150"
                  max-height="35"
                  @click="onPickFile">
                  <p style="margin-top: 1px;
                      letter-spacing: 0;
                      color: #3c4043;
                      font-size: 16px;" class="text-capitalize pt-3">
                    {{ $t("browse") }}
                  </p>
                  <input
                    ref="image"
                    @change="onFilePicked"
                    accept="image/*"
                    type="file"
                    style="display: none"
                    id="profile_picture"/>
                </v-btn>
              </v-col>
              <v-col cols="12" md="9">
                <p style="margin-top: 0px; color: #f74747">
                  **{{ $t("TheImageType") }}**
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            height="35"
            @click="closeall()"
            text>
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            v-show="!showedit"
            class="white--text mr-2 text-capitalize"
            width="120"
            height="35"
            @click="uploadimage($event)">
            {{ $t("upload") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            v-show="showedit"
            class="white--text mr-2 text-capitalize"
            width="120"
            height="35"
            @click="editimage($event)">
            {{ $t("upload") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      url: enurl.apiUrl,
      showthis: true,
      localImageUrl3: "",
      checkImage: false,
      showfile: false,
      showfilename: null,
      showedit: false,
      tempImageUrl: "",
      uploaddialog2: false,
      imageUrl1: "",
      chosenfile: null,
      imageName: "",
      imageUrl: localStorage.LocalPersonalidUrl,
      imageFile: "",
      fileName: null,
      workpermit: [],
      work: "",
      ind: 0,
    };
  },
  mounted() {
    let self = this;
    if (self.$route.params.personalid != null)
    {
      this.showedit = true;
    }
    let task1 = localStorage.LocaleditPersonalidUrl;
    this.localImageUrl = task1;
  },

  created: function () {
    let taskwork = JSON.parse(localStorage.getItem("edit"));
    if (this.$route.params.personalid != null)
    {
      let task = localStorage.LocaleditPersonalidUrl;
      if (task == null)
      {
        this.imageUrl1 = taskwork.personalid;
        this.checkImage = true;
        this.localImageUrl3 = "";
      }
      else
      {
        taskwork = "";
        this.imageUrl = task;
        this.localImageUrl3 = task;
      }
    }
    else
    {
      let taskDB = localStorage.getItem("LocalPersonalidUrl");
      if (taskDB == null)
      {
        this.imageUrl1 = taskwork.personalid;
        this.checkImage = true;
        this.localImageUrl3 = "";
      }
      else
      {
        this.localImageUrl3 = taskDB;
      }
    }
  },
  methods: {
    back() {
      let workim = localStorage.localwork;
      if (this.$route.path == "/selfservice/PersonalProfile/PersonalInformation/PersonalInfoID")
      {
        this.$router.push("/selfservice/PersonalProfile/PersonalInformation").catch(()=>{});
      }
      else if (workim != "undefined")
      {
        this.$router.push(`/PersonnelManagement/EditEmployee/${this.$route.params.id}/EditPersonalInformation`).catch(()=>{});
      }
      else if (this.$route.name == "newpersonalid")
      {
        this.$router.push("/PersonnelManagement/NewEmployee/NewPersonalInformation").catch(()=>{});
      }
    },
    
    onPickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      e.preventDefault();
      const files = e.target.files;
      const MAX_SIZE = 2;
      this.checkImage = false;
      this.validImage = false; // Reset validImage flag

      if (files[0] !== undefined)
      {
        this.imageName = files[0].name;
        // Check for valid file extension
        const validExtensions = ['.png', '.jpeg', '.jpg'];
        const fileExtension = this.imageName.substring(this.imageName.lastIndexOf('.')).toLowerCase();
        if (!validExtensions.includes(fileExtension))
        {
          alert("Invalid file type! Only PNG and JPEG files are allowed.");
          return;
        }
        let imageSize = parseFloat(files[0].size / (1024 * 1024)).toFixed(0);
        if (imageSize > MAX_SIZE)
        {
          alert(`Your image size is ${imageSize}MB! Maximum is ${MAX_SIZE}MB. Please choose again!`);
          return;
        }
        // If validation passes, set validImage to true
        this.validImage = true;
        this.showfilename = files[0].name;
        this.showfile = true;
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.tempImageUrl = fr.result;
          this.imageUrl = fr.result.split(",")[1];
          this.showthis = false;
          this.imageFile = files[0];
        });
      }
      else
      {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
        this.tempImageUrl = "";
      }
    },

    uploadimage(b) {
      b.preventDefault();
      // Check if image passed validation
      if (!this.validImage) {
        alert("Please select a valid image before uploading.");
        return; // Do not proceed with the upload if not valid
      }
      // this.localImageUrl3 = this.imageUrl;
      this.showthis = true;
      this.uploaddialog2 = false;
      let tempurl = {
            image: this.imageUrl,
          };
           axios.post(`${this.url}ClockInOut/GetUrlFromImage`, tempurl)
           .then((response) => {
          if (response.data.status === 0) {
            this.localImageUrl3 = response.data.data;
            // Save to localStorage if validation is true
            localStorage.setItem("LocalPersonalidUrl", this.localImageUrl3);
            localStorage.setItem("filename", this.imageName);
          }
          })
      
      // Reset validImage flag after upload
      this.validImage = false;
    },

    editimage(b) {
      b.preventDefault();
      // Check if image passed validation
      if (!this.validImage) {
        alert("Please select a valid image before uploading.");
        return; // Do not proceed with the upload if not valid
      }
      // this.localImageUrl3 = this.imageUrl;
      let tempurl = {
            image: this.imageUrl,
          };
           axios.post(`${this.url}ClockInOut/GetUrlFromImage`, tempurl)
           .then((response) => {
            if (response.data.status === 0) {
              this.localImageUrl3 = response.data.data;
              // Save to localStorage if validation is true
              localStorage.setItem("LocalPersonalidUrl", this.localImageUrl3);
              localStorage.setItem("filename", this.imageName);
            }
          })
      this.showthis = true;
      this.uploaddialog2 = false;
      
      this.validImage = false;
    },

    closeall() {
      if (this.$route.params.personalid != null)
      {
        if (localStorage.LocaleditPersonalidUrl == null)
        {
          this.checkImage = true;
        }
        else
        {
          let ff = localStorage.LocaleditPersonalidUrl;
          this.imageUrl = ff;
        }
      }
      else
      {
        let gg = localStorage.LocalPersonalidUrl;
        this.imageUrl = gg;
      }
      this.showthis = true;
      this.uploaddialog2 = false;
      this.showfile = false;
    },
    
    closeimage() {
      this.showfile = false;
    },
  },
};
</script>
<style scoped>
>>> .mdi-paperclip::before {
  content: none;
}
@media (min-width: 1179px) {
  .hello-right {
    margin-left: 100px;
  }
}
@media (min-width: 280px) and (max-width: 1025px) {
  .btn {
    margin-left: 2.5rem !important;
  }
}
</style>