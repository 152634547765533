<template>
  <div>
    <v-col cols="12">
      <v-row class="ml-5 mr-5">
        <gmap-map
          :center="center"
          ref="map"
          :zoom="15"
          style="width: 100%; height: 500px"
        >
          <gmap-marker
            :position="marker.position"
            @click="center = marker.position"
          ></gmap-marker>
        </gmap-map>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      map: "",
      marker: "",
      center: { lat: 45.508, lng: -73.587 },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.map.$gmapApiPromiseLazy().then(this.geolocate);
    });
  },
  methods: {
    createMarker: function (latlng) {
      let lats = latlng;
      this.marker = new window.google.maps.Marker({
        setMap: this.$refs.map.$mapObject,
        position: lats,
        animation: window.google.maps.Animation.DROP,
      });
      //   this.addYourLocationButton();
      // var latlngStr = lats.split(",");
      // var latlnga = {
      //   lat: latlngStr[0],
      //   lng: latlngStr[1]
      // };
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: lats }, function (results, status) {
        if (status === "OK") {
          if (!results[0]) {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    },
    // geolocate: function() {
    //   navigator.geolocation.getCurrentPosition(position => {
    //     let latlng = new window.google.maps.LatLng(
    //       parseFloat(position.coords.latitude),
    //       parseFloat(position.coords.longitude)
    //     );
    //     this.center = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude
    //     };
    //     this.createMarker(latlng);
    //   });
    // },
    geolocate: function () {
      var controlDiv = document.createElement("div");
      var firstChild = document.createElement("button");
      firstChild.style.backgroundColor = "#fff";
      firstChild.style.border = "none";
      firstChild.style.outline = "none";
      firstChild.style.width = "28px";
      firstChild.style.height = "28px";
      firstChild.style.borderRadius = "2px";
      firstChild.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
      firstChild.style.cursor = "pointer";
      firstChild.style.marginRight = "10px";
      firstChild.style.padding = "0px";
      firstChild.title = "Your Location";
      controlDiv.appendChild(firstChild);
      var secondChild = document.createElement("div");
      secondChild.style.margin = "5px";
      secondChild.style.width = "18px";
      secondChild.style.height = "18px";
      secondChild.style.backgroundImage =
        "url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)";
      secondChild.style.backgroundSize = "180px 18px";
      secondChild.style.backgroundPosition = "0px 0px";
      secondChild.style.backgroundRepeat = "no-repeat";
      secondChild.id = "you_location_img";
      firstChild.appendChild(secondChild);
      window.google.maps.event.addListener(
        this.$refs.map.$mapObject,
        "center_changed",
        function () {
          secondChild.style["background-position"] = "0 0";
        }
      );
      var ref = this;
      firstChild.addEventListener("click", function () {
        navigator.geolocation.getCurrentPosition((position) => {
          let latlng = new window.google.maps.LatLng(
            parseFloat(position.coords.latitude),
            parseFloat(position.coords.longitude)
          );

          ref.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          ref.createMarker(latlng);
        });
      });
      controlDiv.index = 1;
      this.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(controlDiv);
    },
  },
};
</script>
