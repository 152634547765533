
<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 15px">
        {{ $t("NewUser") }}
      </p>
    </div>
    <v-card class="mt-6">
      <v-card-subtitle
        class="mt-3 ml-2"
        style="font-size: 20px; font-weight: 300; color: black"
        >{{ $t("UserDetails") }}</v-card-subtitle
      >
      <div>
        <v-row class="ml-1 mr-4" wrap>
          <v-col cols="12" sm="6" md="3" class="ml-4 mr-4">
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="adduser.employeename"
              @click="back"
              ><template v-slot:label>
                <span>
                  {{ $t("EmployeeName")
                  }}<span class="red--text"> *</span></span
                >
              </template></v-text-field
            >
          </v-col>
        </v-row>
        <v-row class="ml-1 mr-4" wrap>
          <v-col cols="12" sm="6" md="3" class="ml-4 mr-4">
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="adduser.usergroup"
              readonly
              ><template v-slot:label>
                <span> {{ $t("UserGroup") }}</span>
              </template></v-text-field
            >
          </v-col>
          <v-col class="mt-3">
            <v-btn
              color="#C6E8CB"
              width="110px"
              style="border-radius: 15px; color: #22a534"
              class="text-capitalize"
              @click="JoinUserGroup"
              >{{ $t("Join") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="ml-1 mr-4" wrap>
          <v-col cols="12" sm="6" md="3" class="ml-4 mr-4">
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="adduser.userrole"
              readonly
              ><template v-slot:label>
                <span> {{ $t("Role") }}<span class="red--text"> *</span></span>
              </template></v-text-field
            >
          </v-col>
          <v-col class="mt-3">
            <v-btn
              color="#C6E8CB"
              width="110px"
              style="border-radius: 15px; color: #22a534"
              class="text-capitalize"
              router
              :to="{ name: 'newuserassignrole' }"
              >{{ $t("Assign") }}</v-btn
            >
          </v-col>
        </v-row>

        <div>
          <v-row class="mt-3">
            <!-- <v-col cols="12" sm="6" md="1"></v-col> -->
            <v-col cols="12" sm="6" md="4" align="right">
              <v-btn
                color="#444"
                class="text-capitalize mr-2"
                style="font-weight: normal"
                text
              >
                {{ $t("Advance") }} &nbsp;
                <img width="30" height="30" :src="Advanceimage.up" alt />
              </v-btn>
            </v-col>
          </v-row>
          <div v-show="arrow">
            <v-row class="ml-1 mr-4" wrap>
              <v-col cols="12" sm="6" md="4" class="ml-4 mr-4">
                <v-text-field
                  autocomplete="off"
                  :label="$t('Permission')"
                  color="#F99D20"
                  v-model="adduser.userpermis"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col class="mt-3">
                <v-btn
                  router
                  :to="{ name: 'newuserassignper' }"
                  color="#C6E8CB"
                  width="110px"
                  style="border-radius: 15px; color: #22a534"
                  class="text-capitalize"
                  >{{ $t("Assign") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          style="border-radius: 15px"
          class="mr-2 text-capitalize"
          width="120"
          height="40"
          @click="goto"
          text
          >{{ $t("Cancel") }}</v-btn
        >
        <v-btn
          color="#F99D20"
          class="mr-4 text-capitalize"
          width="120"
          height="40"
          style="border-radius: 15px"
          @click="AddUser"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils],
  data() {
    return {
      url: enurl.apiUrl,
      size: 10,
      arrow: true,
      showbtn: true,
      loadingdialog: false,
      characters: "a-z,A-Z,0-9",
      adduser: {
        employeename: [],
        usergroup: [],
        userrole: [],
        userpermis: [],
      },
      user_ID: 0,
      emp: [],
      getusergp: [],
      TempGpList: [],
      updateempname: [],
      updateusergp: [],
      updaterole: [],
      updateper: [],
      show: false,
      Advanceimage: {
        up: require("@/assets/images/up1@2x.png"),
        down: require("@/assets/images/down1@2x.png"),
      },
      tempuser: null,
      temprole: null,
      tempper: null,
      tempempname: [],
      tempusergpname: [],
      tempuserrole: [],
      tempuserper: [],
      tempid: [],
      rolelist: [],
    };
  },
  mounted() {
    this.GetEmpByID();
    this.GetRoleByUserGroup();
  },

  methods: {
    JoinUserGroup() {
      let self = this;
      self.adduser.userrole[0] = [];
      localStorage.setItem("NewUserEditRole", JSON.stringify(null));
      self.$router.push({ name: "newuserassignugp" }).catch(() => {});
    },
    back() {
      this.$router.push("/usermanagement/user/newuser/newuser").catch(() => {});
    },
    GetRoleByUserGroup() {
      let self = this;
      let ugpId = JSON.parse(localStorage.getItem("NewUserEditUserGp"));
      let tempp = {
        company_ID: store.state.companyID,
        user_Group_ID: ugpId[0].user_Group_ID,
      };
      axios
        .post(`${self.url}UserGroupRole/GetUserGroupRole`, tempp)
        .then(function (response) {
          self.rolelist = response.data.data;
        });
    },
    ValidateUserGroupRole() {
      let self = this;
      if (
        self.adduser.userrole.length != 0 &&
        self.adduser.usergroup.length != 0
      ) {
        let RoleId = JSON.parse(localStorage.getItem("NewUserEditRole"));
        if (self.rolelist[0].role_ID == RoleId[0].role_ID) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    DeleteOldUserGroupMember() {
      let self = this;
      self.LoadingDialog = true;
      axios
        .post(
          `${self.url}UserGroupMember/DeleteOldUserGroupMember`,
          self.updateempname
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
          }
        });
    },
    AddUser() {
      let self = this;
      self.loadingdialog = true;
      let CheckMatch = self.ValidateUserGroupRole();
      if (self.adduser.userrole != null && self.adduser.userrole != "") {
        if (CheckMatch == true) {
          if (self.tempempname.length != 0) {
            self.DeleteoldUserGP();
            for (let j = 0; j < self.tempempname.length; j++) {
              self.tempid.push({
                employee_ID: self.tempempname[j],
                empUserGroup: self.updateusergp,
                empRoleData: self.updaterole,
                empPermisData: self.updateper,
                company_ID: store.state.companyID,
              });
            }
            // self.DeleteOldUserGroupMember();

            axios
              .post(
                `${self.url}Confirmemployee/AddConfirmEmployee`,
                self.tempid
              )
              .then(function (response) {
                alert(response.data.message);
                self.loadingdialog = false;
                localStorage.setItem("NewUserEditUserGp", JSON.stringify(null));
                localStorage.setItem(
                  "NewUserEditUserPer",
                  JSON.stringify(null)
                );
                localStorage.setItem("NewUserEditRole", JSON.stringify(null));
                localStorage.setItem("NewUserAssign", JSON.stringify(null));
                //self.LogTrace(null, "Add User", 34, "Low");
                self.$router.push("/usermanagement/user").catch(() => {});
              })
              .catch(function (error) {
                //alert(error);
                self.LogTrace(error, "Add User Fail", 34, "Low");
                self.loadingdialog = false;
              });
          }
        } else {
          alert("Selected UserGroup and Role doesn't match.Please recheck");
          self.loadingdialog = false;
        }
      } else {
        alert("Please select Role");
        self.loadingdialog = false;
      }
    },
    keymonitor: function (event) {
      if (event.key == "Backspace") {
        this.edituser.password = null;
        this.show = true;
      }
    },
    GetEmpByID() {
      let self = this;
      //EmployeeNameLocalStorage
      self.tempuser = JSON.parse(localStorage.getItem("NewUserAssign"));
      if (self.tempuser != null) {
        if (self.tempuser.length == 1) {
          self.adduser.employeename = [...self.tempuser.map((x) => x.emmpname)];
        } else {
          self.adduser.employeename = "Selected Individuals";
        }
        self.tempempname = [...self.tempuser.map((x) => x.emmpid)];
        if (self.tempempname != null) {
          for (let i = 0; i < self.tempempname.length; i++) {
            self.updateempname.push({
              employee_ID: self.tempempname[i],
            });
          }
        }
      }

      //GroupNameLocalStorage
      self.tempuser = JSON.parse(localStorage.getItem("NewUserEditUserGp"));
      if (self.tempuser != null) {
        self.adduser.usergroup = [
          ...self.tempuser.map((x) => x.user_Group_Name),
        ];
        self.tempusergpname = [...self.tempuser.map((x) => x.user_Group_ID)];
        if (self.tempusergpname != null) {
          for (let i = 0; i < self.tempusergpname.length; i++) {
            self.updateusergp.push({
              user_Group_ID: self.tempusergpname[i],
            });
          }
        }
      }
      //RoleNameLocalStorage

      self.temprole = JSON.parse(localStorage.getItem("NewUserEditRole"));
      if (self.temprole != null) {
        self.adduser.userrole = [...self.temprole.map((x) => x.role_Name)];
        self.tempuserrole = [...self.temprole.map((x) => x.role_ID)];
        if (self.tempuserrole != null) {
          for (let j = 0; j < self.tempuserrole.length; j++) {
            self.updaterole.push({
              role_ID: self.tempuserrole[j],
            });
          }
        }
      }
      //PermissionNameLocalStore
      self.tempper = JSON.parse(localStorage.getItem("NewUserEditUserPer"));
      if (self.tempper != null) {
        self.adduser.userpermis = [...self.tempper.map((x) => x.pername)];
        self.tempuserper = [...self.tempper.map((x) => x.perid)];
        if (self.tempuserper != null) {
          for (let j = 0; j < self.tempuserper.length; j++) {
            self.updateper.push({
              permission_ID: self.tempuserper[j],
            });
          }
        }
      }
    },
    goto() {
      localStorage.setItem("NewUserEditUserGp", JSON.stringify(null));
      localStorage.setItem("NewUserEditUserPer", JSON.stringify(null));
      localStorage.setItem("NewUserEditRole", JSON.stringify(null));
      localStorage.setItem("NewUserAssign", JSON.stringify(null));
      this.$router.push("/usermanagement/user").catch(() => {});
    },
    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < this.size; i++) {
        tempPassword += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return tempPassword;
    },
    generateUserID() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      for (let i = 0; i < this.size; i++) {
        tempPassword += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return tempPassword;
    },
    DeleteoldUserGP() {
      let self = this;
      self.loadingdialog = true;
      axios
        .post(
          `${self.url}Confirmemployee/DeleteOldUsersData`,
          self.updateempname
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    Validation() {
      let self = this;
      if (
        self.edituser.employeename != null &&
        self.edituser.usergroup != null &&
        self.edituser.userrole != null &&
        self.edituser.userid != null &&
        self.edituser.password != null &&
        self.edituser.userpermis != null &&
        self.edituser.employeename != "" &&
        self.edituser.usergroup != "" &&
        self.edituser.userrole != "" &&
        self.edituser.userid != "" &&
        self.edituser.password != "" &&
        self.edituser.userpermis != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    UpdateEmpInfo() {
      let self = this;
      self.loadingdialog = true;
      //let tempvali = self.Validation();
      //if (tempvali == true) {
      self.DeleteoldUserGP();
      let temp = {
        employee_ID: parseInt(self.$route.params.uid),
        user_ID: self.user_ID,
        company_ID: store.state.companyID,
        user_Name: self.edituser.userid,
        password: self.edituser.password,
        empUserGroup: self.updateusergp,
        empRoleData: self.updaterole,
        empPermisData: self.updateper,
      };

      axios
        .post(`${self.url}Confirmemployee/EditConfirmEmployee`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.loadingdialog = false;
            localStorage.setItem("NewUserEditUserGp", JSON.stringify(null));
            localStorage.setItem("NewUserEditUserPer", JSON.stringify(null));
            localStorage.setItem("NewUserEditRole", JSON.stringify(null));
            localStorage.setItem("NewUserAssign", JSON.stringify(null));
            //self.LogTrace(null, "Update User", 34, "Medium");
            self.$router.push("/usermanagement/user").catch(() => {});
          }
        })
        .catch(function (error) {
          alert(error);
          self.LogTrace(error, "Update User Fail", 34, "Medium");
          self.loadingdialog = false;
        });
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>