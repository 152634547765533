<template>
  <div style="margin-left: 2rem; margin-right: 2rem; margin-bottom: 2rem">
    <div class="pt-6">
      <p style="font-size: 18px">{{ $t("SettingForClockInAndOut") }}</p>
      <div>
        <p class="mt-4" style="font-size: 16px">
          {{ $t("AcceptableStartTime") }}
        </p>
        <v-row>
          <v-col cols="12" md="1">
            <p class="mt-5" style="color: #8a8c8e">{{ $t("Before") }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="1" class="mt-2">
            <v-text-field
              v-model="timedata.startbefore"
              :disabled="diasbleall"
              :error-messages="error.errormsgstartbefore"
              dense
              type="number"
              color="#F99D20"
              style="
                font-weight: normal;
                font-size: 16px;
                opacity: 0.8;
                margin-top: 0.5rem;
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
              :placeholder="$t('Hour')"
              v-model="timedata.startbeforetime"
              :items="startTimeitems"
              :disabled="diasbleall"
              outlined
              item-text="txt"
              item-value="val"
              filled
              dense
              color="#F99D20"
              background-color="#F8F8F8"
              style="
              width:120px;
                font-weight: normal;
                font-size: 16px;
                opacity: 0.8;
                margin-top: 0.5rem;
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="1">
            <p class="mt-5" style="color: #8a8c8e">{{ $t("After") }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="1" class="mt-2">
            <v-text-field
              v-model="timedata.startafter"
              :disabled="diasbleall"
              :error-messages="error.errormsgstartafter"
              dense
              type="number"
              color="#F99D20"
              style="
                font-weight: normal;
                font-size: 16px;
                opacity: 0.8;
                margin-top: 0.5rem;
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
              :placeholder="$t('Min')"
              v-model="timedata.startaftertime"
              :items="startTimeitems"
              :disabled="diasbleall"
              outlined
              filled
              item-text="txt"
              item-value="val"
              dense
              color="#F99D20"
              background-color="#F8F8F8"
              style="
                 width:120px;
                font-weight: normal;
                font-size: 16px;
                opacity: 0.8;
                margin-top: 0.5rem;
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- <v-row style="margin-top: -25px">
        
        </v-row> -->
      </div>
      <div>
        <p style="font-size: 16px">{{ $t("AcceptableEndTime") }}</p>
        <v-row>
          <v-col cols="12" md="1">
            <p class="mt-5" style="color: #8a8c8e">{{ $t("Anotherbefore") }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="1" class="mt-2">
            <v-text-field
              v-model="timedata.endbefore"
              :disabled="diasbleall"
              :error-messages="error.errormsgendbefore"
              dense
              type="number"
              color="#F99D20"
              style="
                font-weight: normal;
                font-size: 16px;
                opacity: 0.8;
                margin-top: 0.5rem;
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
              :placeholder="$t('Hour')"
              v-model="timedata.endbeforetime"
              :items="startTimeitems"
              :disabled="diasbleall"
              outlined
              filled
              item-text="txt"
              item-value="val"
              dense
              color="#F99D20"
              background-color="#F8F8F8"
              style="
                 width:120px;
                font-weight: normal;
                font-size: 16px;
                opacity: 0.8;
                margin-top: 0.5rem;
              "
            ></v-autocomplete>
          </v-col>
           <v-col cols="12" md="1">
            <p class="mt-5" style="color: #8a8c8e">{{ $t("AnotherAfter") }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="1" class="mt-2">
            <v-text-field
              v-model="timedata.endafter"
              :disabled="diasbleall"
              :error-messages="error.errormsgendafter"
              dense
              type="number"
              color="#F99D20"
              style="
                font-weight: normal;
                font-size: 16px;
                opacity: 0.8;
                margin-top: 0.5rem;
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
              :placeholder="$t('Min')"
              v-model="timedata.endaftertime"
              :items="startTimeitems"
              :disabled="diasbleall"
              item-text="txt"
              item-value="val"
              outlined
              filled
              dense
              color="#F99D20"
              background-color="#F8F8F8"
              style="
                 width:120px;
                font-weight: normal;
                font-size: 16px;
                opacity: 0.8;
                margin-top: 0.5rem;
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- <v-row style="margin-top: -25px">
         
        </v-row> -->
      </div>
      <div style="height: 18vh"></div>
      <v-row style="padding-bottom: 2rem">
        <v-spacer></v-spacer>
        <div v-if="permission.add == false">
        <div v-show="showEdit" style="margin-right: 1.5rem">
          <v-btn
            class="pa-2 text_transform_none save_btn commonsavebtn"
            @click="CloseEdit"
            >{{ $t("edit") }}</v-btn
          >
        </div>
        </div>
        <div v-show="showAdd" style="margin-right: 1.5rem">
          <v-btn
            class="pa-2 mr-5 text_transform_none commonsavebtn"
            :disabled="permission.add"
            @click="AddNewSetting"
            >{{ $t("save") }}</v-btn
          >
        </div>
        <div v-show="showUpdate" style="margin-right: 1.5rem">
          <v-btn
            outlined
            class="pa-2 mr-5 text_transform_none commoncancelbtn"
            text
            @click="CloseCancel"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="pa-2 mr-5 text_transform_none commonsavebtn"
            :disabled="permission.add"
            @click="UpdateSetting"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-row>
    </div>

    <!-- Loading dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAdd from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils, CheckViewAdd],
  data() {
    return {
      permission: {
        add: true,
      },
      url: enurl.apiUrl,
      LoadingDialog: false,
      TimeSettingList: [],
      timedata: {
        Id: null,
        startbefore: null,
        startafter: null,
        endbefore: null,
        endafter: null,
        startbeforetime: null,
        startaftertime: null,
        endbeforetime: null,
        endaftertime: null,
      },
      showEdit: false,
      showAdd: false,
      showUpdate: true,
      diasbleall: false,
      error: {
        errormsgstartbefore: null,
        errormsgstartafter: null,
        errormsgendbefore: null,
        errormsgendafter: null,
      },
    };
  },
  computed: {
    startTimeitems() {
      return [
        { val: "hour", txt: this.$t("Hour") },
        { val: "min", txt: this.$t("Min") },
      ];
    },
  },
  watch: {
    "timedata.startbefore"(){
      this.error.errormsgstartbefore = [];
    },
    "timedata.startafter"(){
      this.error.errormsgstartafter = [];
    },
    "timedata.endbefore"(){
      this.error.errormsgendbefore = [];
    },
    "timedata.endafter"(){
      this.error.errormsgendafter = [];
    },
  },
  mounted() {
    this.GetTimeAttSetting();
    this.permission = this.CheckViewAdd(35,36);
  },
  methods: {
    CloseEdit() {
      let self = this;
      self.showEdit = false;
      self.showAdd = false;
      self.showUpdate = true;
      self.diasbleall = false;
    },
    CloseCancel() {
      let self = this;
      self.showEdit = true;
      self.showAdd = false;
      self.showUpdate = false;
      self.diasbleall = true;
    },
    GetTimeAttSetting() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceSetting/GetTimeAttendanceSettingByCompanyID`,
          temp
        )
        .then(function(response) {
          self.TimeSettingList = response.data.data;
          if (self.TimeSettingList != 0) {
            self.timedata.Id = self.TimeSettingList[0].id;
            let [
              stbefore,
              stbeforetime,
            ] = self.TimeSettingList[0].start_Time_Before.split(" ");
            let [
              stafter,
              staftertime,
            ] = self.TimeSettingList[0].start_Time_After.split(" ");
            let [
              edbefore,
              edbeforetime,
            ] = self.TimeSettingList[0].end_Time_Before.split(" ");
            let [
              edafter,
              edaftertime,
            ] = self.TimeSettingList[0].end_Time_After.split(" ");
            self.timedata.startbefore = stbefore;
            self.timedata.startbeforetime = stbeforetime;
            self.timedata.startafter = stafter;
            self.timedata.startaftertime = staftertime;
            self.timedata.endbefore = edbefore;
            self.timedata.endbeforetime = edbeforetime;
            self.timedata.endafter = edafter;
            self.timedata.endaftertime = edaftertime;
            self.diasbleall = true;
            self.showEdit = true;
            self.showAdd = false;
            self.showUpdate = false;
          } else {
            self.showEdit = false;
            self.showAdd = true;
            self.showUpdate = false;
            self.diasbleall = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function(error) {
          self.LogTrace(
            error,
            "Unsucessfully Get TimeAttSetting",
            28,
            "Critical"
          );
          //alert(error + "Correlation ID is 28");
          self.LoadingDialog = false;
        });
    },
    AddNewSetting() {
      let self = this;

      self.error.errormsgstartbefore = !self.timedata.startbefore
        ? "Please fill in the required fields"
        : "";
      self.error.errormsgstartafter = !self.timedata.startafter
        ? "Please fill in the required fields"
        : "";
      self.error.errormsgendafter = !self.timedata.endafter
        ? "Please fill in the required fields"
        : "";
      self.error.errormsgendbefore = !self.timedata.endbefore
        ? "Please fill in the required fields"
        : "";
      if (
        self.timedata.startbeforetime == null ||
        self.timedata.startbeforetime == ""
      ) {
        self.timedata.startbeforetime = "hour";
      }
      if (
        self.timedata.startaftertime == null ||
        self.timedata.startaftertime == ""
      ) {
        self.timedata.startaftertime = "min";
      }
      if (
        self.timedata.endbeforetime == null ||
        self.timedata.endbeforetime == ""
      ) {
        self.timedata.endbeforetime = "hour";
      }
      if (
        self.timedata.endaftertime == null ||
        self.timedata.endaftertime == ""
      ) {
        self.timedata.endaftertime = "min";
      }
      var tempvalidate = self.ValideTrue();
      if (tempvalidate == true) {
        let inbefore =
          self.timedata.startbefore + " " + self.timedata.startbeforetime;
        let inafter =
          self.timedata.startafter + " " + self.timedata.startaftertime;
        let outbefore =
          self.timedata.endbefore + " " + self.timedata.endbeforetime;
        let outafter =
          self.timedata.endafter + " " + self.timedata.endaftertime;
        self.LoadingDialog = true;
        let tempdata = {
          start_Time_Before: inbefore,
          start_Time_After: inafter,
          end_Time_Before: outbefore,
          end_Time_After: outafter,
          company_ID: store.state.companyID,
        };
        axios
          .post(
            `${self.url}TimeAttendanceSetting/AddTimeAttendanceSetting`,
            tempdata
          )
          .then(function(response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.GetTimeAttSetting();
            }
          })
          .catch(function(error) {
            self.LogTrace(error, "Add TimeAttSetting Fail", 28, "Low");
            alert(error + "Correlation ID is 28");
          });
      } else {
        self.ValideFalse();
      }
    },
    UpdateSetting() {
      let self = this;
      self.LoadingDialog = true;
      self.error.errormsgstartbefore = !self.timedata.startbefore
        ? "Please fill in the required fields"
        : "";
      self.error.errormsgstartafter = !self.timedata.startafter
        ? "Please fill in the required fields"
        : "";
      self.error.errormsgendafter = !self.timedata.endafter
        ? "Please fill in the required fields"
        : "";
      self.error.errormsgendbefore = !self.timedata.endbefore
        ? "Please fill in the required fields"
        : "";
      var tempvalidate = self.ValideTrue();
      if (tempvalidate == true) {
        let inbefore =
          self.timedata.startbefore + " " + self.timedata.startbeforetime;
        let inafter =
          self.timedata.startafter + " " + self.timedata.startaftertime;
        let outbefore =
          self.timedata.endbefore + " " + self.timedata.endbeforetime;
        let outafter =
          self.timedata.endafter + " " + self.timedata.endaftertime;
        let tempdata = {
          id: self.timedata.Id,
          start_Time_Before: inbefore,
          start_Time_After: inafter,
          end_Time_Before: outbefore,
          end_Time_After: outafter,
          company_ID: store.state.companyID,
        };
        axios
          .post(
            `${self.url}TimeAttendanceSetting/UpdateTimeAttendanceSetting`,
            tempdata
          )
          .then(function(response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.LoadingDialog = false;
              self.GetTimeAttSetting();
            }
          })
          .catch(function(error) {
            self.LogTrace(error, "Update TimeAttSetting Fail", 28, "Medium");
            self.LoadingDialog = false;
          });
      } else {
        self.ValideFalse();
        self.LoadingDialog = false;
      }
    },
    ValideTrue() {
      let self = this;
      if (
        self.timedata.startbefore != null &&
        self.timedata.startafter != null &&
        self.timedata.endafter != null &&
        self.timedata.endbefore != null &&
        self.timedata.startbefore != "" &&
        self.timedata.startafter != "" &&
        self.timedata.endafter != "" &&
        self.timedata.endbefore != ""
      ) {
        let stbfe = false;
        let staft = false;
        let edbfe = false;
        let edaft = false;
        if (
          (self.timedata.startbefore < 60 &&
            self.timedata.startbeforetime == "min") ||
          (self.timedata.startbefore <= 24 &&
            self.timedata.startbeforetime == "hour")
        ) {
          stbfe = true;
        }
        if (
          (self.timedata.startafter < 60 &&
            self.timedata.startaftertime == "min") ||
          (self.timedata.startafter <= 24 &&
            self.timedata.startaftertime == "hour")
        ) {
          staft = true;
        }
        if (
          (self.timedata.endbefore < 60 &&
            self.timedata.endbeforetime == "min") ||
          (self.timedata.endbefore <= 24 &&
            self.timedata.endbeforetime == "hour")
        ) {
          edbfe = true;
        }
        if (
          (self.timedata.endafter < 60 &&
            self.timedata.endaftertime == "min") ||
          (self.timedata.endafter <= 24 && self.timedata.endaftertime == "hour")
        ) {
          edaft = true;
        }
        if (self.timedata.startbefore.includes(".")) {
          stbfe = false;
        }
        if (self.timedata.startafter.includes(".")) {
          stbfe = false;
        }
        if (self.timedata.endbefore.includes(".")) {
          stbfe = false;
        }
        if (self.timedata.endafter.includes(".")) {
          stbfe = false;
        }
        if (stbfe == true && staft == true && edbfe == true && edaft == true) {
          return true;
        }
      } else {
        return false;
      }
    },
    ValideFalse() {
      let self = this;
      let stbfe = true;
      let staft = true;
      let edbfe = true;
      let edaft = true;
      if (
        self.timedata.startbefore.includes(".") ||
        self.timedata.startafter.includes(".") ||
        self.timedata.endbefore.includes(".") ||
        self.timedata.endafter.includes(".")
      ) {
        alert("Input can't be decimal");
      }
      if (
        self.timedata.startbefore == null ||
        self.timedata.startafter == null ||
        self.timedata.endafter == null ||
        self.timedata.endbefore == null ||
        self.timedata.startbefore == "" ||
        self.timedata.startafter == "" ||
        self.timedata.endafter == "" ||
        self.timedata.endbefore == ""
      ) {
        alert("Please fill the required fields");
      }
      if (
        (self.timedata.startbefore >= 60 &&
          self.timedata.startbeforetime == "min") ||
        (self.timedata.startbefore > 24 &&
          self.timedata.startbeforetime == "hour")
      ) {
        stbfe = false;
        self.error.errormsgstartbefore =
          self.timedata.startbefore != stbfe
            ? "Please recheck the input."
            : self.timedata.startbefore;
      }
      if (
        (self.timedata.startafter >= 60 &&
          self.timedata.startaftertime == "min") ||
        (self.timedata.startafter > 24 &&
          self.timedata.startaftertime == "hour")
      ) {
        staft = false;
        self.error.errormsgstartafter =
          self.timedata.startafter != staft
            ? "Please recheck the input."
            : self.timedata.startafter;
      }
      if (
        (self.timedata.endbefore >= 60 &&
          self.timedata.endbeforetime == "min") ||
        (self.timedata.endbefore > 24 && self.timedata.endbeforetime == "hour")
      ) {
        edbfe = false;
        self.error.errormsgendbefore =
          self.timedata.endbefore != edbfe
            ? "Please recheck the input."
            : self.timedata.endbefore;
      }
      if (
        (self.timedata.endafter >= 60 && self.timedata.endaftertime == "min") ||
        (self.timedata.endafter > 24 && self.timedata.endaftertime == "hour")
      ) {
        edaft = false;
        self.error.errormsgendafter =
          self.timedata.endafter != edaft
            ? "Please recheck the input."
            : self.timedata.endafter;
      }
      if (
        stbfe == false ||
        staft == false ||
        edbfe == false ||
        edaft == false
      ) {
        alert("Please recheck the input.");
      }
    },
  },
};
</script>

<style scoped>
>>> .mdi-menu-down::before {
  color: #f99d20;
}
@media only screen and (max-width: 414px) {
  .save_btn {
    margin-top: 1rem !important;
  }
}
</style>
