<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        style="margin-left: -1rem"
        dark
        v-on="on"
        @click="changenoti()"
      >
        <v-badge
          :content="notiCount"
          :value="notiCount"
          color="red"
          overlap
          style="margin-bottom: 1.5rem; margin-left: 1.5rem; position: absolute"
          v-if="actualNotiState"
        >
        </v-badge>
        <v-icon color="#F89D1F">mdi-bell</v-icon>
      </v-btn>
    </template>

    <v-list v-if="requestList.length > 0">
      <div class="notiHeading">
        <v-list-item-group class="ma-0 white">
          <v-row class="mx-2 my-2">
            <v-col cols="6"
              ><div style="color: #f89d1f">{{ $t("notifications") }}</div>
            </v-col>

            <v-col cols="6">
              <!-- <div class="btnForMarkAllAsRead">Mark all as read</div> -->
            </v-col>
          </v-row>

          <v-list-item
            v-for="(item, i) in requestList"
            :key="i"
            @click="
              sendDataWithParams(
                item.wF_ID,
                item.notificationID,
                i,
                item.seen,
                item
              )
            "
            style="margin-top: 1px"
            v-bind:style="{ background: colorList[i] }"
          >
            <img
              :src="item.senderImage"
              v-if="item.senderImage"
              style="
                border: 1px solid #43425d;
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 25px;
              "
            />
            <img
              src="@/assets/profile.svg"
              v-if="!item.senderImage"
              style="
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 25px;
              "
            />
            <v-list-item-title style="background:item.seen?white:#FAF5F4">
              <div>
                <div class="notiText" style="margin-bottom: 2px">
                  {{ item.senderName }}
                  {{ checkingTranslate(item.description) }}
                </div>
                <div class="notiText">
                  {{ convertUTCDateToLocalDate(item.noti_date) }}
                </div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <v-row class="ma-0 white" style="align-items: center">
          <v-col cols="12">
            <div class="notiButton">
              <v-btn
                small
                height="30"
                width="100%"
                color="#F99d20"
                route
                to="/Notification"
              >
                {{ $t("SeeAll") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </v-list>
    <v-list>
      <div
        class="notiHeading"
        style="align-items: center; text-align: center; color: #f99d20"
      >
        <p v-if="show == true">{{ $t("NoNotifications") }}</p>
      </div>
    </v-list>
  </v-menu>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import firebase from "firebase/app";
export default {
  data: () => ({
    show: false,
    url: enurl.apiUrl,
    requestList: [],
    mixins: JSON.parse(store.state.noti),
    profileimage: store.state.profileimage,
    token: null,
    reportID: null,
    tokenList: [],
    noti: false,
    unseenList: 0,
    colorList: [],
    notiCount: 0,
    firstTime: 0,
    actualNotiState: false,
    NotiFeedback: [],
  }),
  created() {
    this.noti = JSON.parse(store.state.noti);
    this.noti = JSON.parse(localStorage.getItem("firebasenotification"));
  },
  computed: {
    checkingTranslate() {
      return function (value) {
        return value == "requested for Time Off"
          ? this.$t("requestedTime")
          : value == "has replied to your feedback"
          ? this.$t("Replyyourfeedback")
          : value == "requested for OT"
          ? this.$t("requestedOT")
          : value == "approved your OT request"
          ? this.$t("approvedOT")
          : value == "approved your Time Off request"
          ? this.$t("approvedTimeOff")
          : value == "denied your Time Off request"
          ? this.$t("deniedTimeOff")
          : this.$t("deniedOT");
      };
    },
  },

  mounted() {
    //this.GetfeedbackNotiList();
    this.requestList = [];
    this.GetrequestList();
    this.sendNotification();
    this.generateToken();
    let checkimage = store.state.profileimage;
    if (checkimage == "null") {
      self.profileimage = "@/assets/profile.svg";
    }
  },

  watch: {
    SearchReportTo(e) {
      this.noti = e;
    },
  },

  methods: {
    formatDate(date) {
      let spliteDateAndTime = date.split("T");
      let Date = spliteDateAndTime[0].split("-");
      let time = spliteDateAndTime[1].split(":");

      return (
        Date[0] + "-" + Date[1] + "-" + Date[2] + " " + time[0] + ":" + time[1]
      );
    },
    convertUTCDateToLocalDate(date) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);

      let output = null;

      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }

      return output;
    },

    async GetfeedbackNotiList() {
      let self = this;
      self.NotiFeedback = [];
      let temp = {
        company_ID: store.state.companyID,
        receiver_ID: store.state.employeeID,
      };
      await axios
        .post(`${self.url}NotificationInfo/GetLatestNotiFeedback`, temp)
        .then(function (response) {
          let temp = response.data.data;
          //console.log(temp);
          temp
            .slice()
            .reverse()
            .forEach(function (item) {
              if (self.NotiFeedback.length < 10) {
                self.NotiFeedback.push({
                  noti_date: item.noti_date,
                  notificationID: item.notificationID,
                  feedback_ID: item.feedback_ID,
                  seen: item.seen,
                  senderID: item.senderID,
                  senderImage: item.senderImage,
                  senderName: item.senderName,
                  status: "replied",
                  wF_ID: item.wF_ID,
                  wF_Name: item.wF_Name,
                  description: item.description,
                  delicated: item.delicated,
                });
              }
            });
        });
    },
    async GetrequestList() {
      let self = this;
      self.requestList = [];
      self.notiCount = 0;
      self.unseenList = 0;
      self.colorList = [];
      //let tempdata = [];
      let tempresult = [];
      self.NotiFeedback = [];
      self.GetfeedbackNotiList();
      let temps = {
        company_ID: store.state.companyID,
        receiver_ID: store.state.employeeID,
      };
      await axios
        .post(`${self.url}NotificationInfo/GetLatestNoti`, temps)
        .then(function (response) {
          let temp = response.data.data;
          tempresult = [...temp, ...self.NotiFeedback];
          let order = tempresult
            .slice()
            .sort((a, b) => b.notificationID - a.notificationID);
          order.forEach(function (item) {
            if (self.requestList.length < 10) {
              self.requestList.push({
                noti_date: item.noti_date,
                notificationID: item.notificationID,
                feedback_ID: item.feedback_ID,
                seen: item.seen,
                senderID: item.senderID,
                senderImage: item.senderImage,
                senderName: item.senderName,
                status: item.status,
                wF_ID: item.wF_ID,
                wF_Name: item.wF_Name,
                description: item.description,
                delicated: item.delicated,
              });
              // self.requestList = tempdata.sort(function (a, b) {
              //   return b.notificationID - a.notificationID;
              // });

              if (item.seen == false) {
                if (self.unseenList <= 10) {
                  self.unseenList++;
                }

                self.colorList.push("#D0D0D0");
              } else {
                self.colorList.push("white");
              }
            }
          });
          if (self.requestList.length == 0) {
            self.show = true;
          }
          // console.log(self.requestList);
        });

      if (self.unseenList > 0) {
        self.notiCount = self.unseenList;
      }
      this.actualNotiState = true;
    },

    async changenoti() {
      let self = this;
      self.notiCount = 0;
      if (self.requestList) {
        self.GetrequestListForNotiOff();
      }
    },

    async GetrequestListForNotiOff() {
      let self = this;
      self.requestList = [];
      self.colorList = [];
      //let tempdata = [];
      let tempresult = [];
      self.NotiFeedback = [];
      self.GetfeedbackNotiList();
      let temps = {
        company_ID: store.state.companyID,
        receiver_ID: store.state.employeeID,
      };
      await axios
        .post(`${self.url}NotificationInfo/GetLatestNoti`, temps)
        .then(function (response) {
          let temp = response.data.data;
          tempresult = [...temp, ...self.NotiFeedback];
          let order = tempresult
            .slice()
            .sort((a, b) => b.notificationID - a.notificationID);
          order.forEach(function (item) {
            if (self.requestList.length < 10) {
              self.requestList.push({
                noti_date: item.noti_date,
                notificationID: item.notificationID,
                feedback_ID: item.feedback_ID,
                seen: item.seen,
                senderID: item.senderID,
                senderImage: item.senderImage,
                senderName: item.senderName,
                status: item.status,
                wF_ID: item.wF_ID,
                wF_Name: item.wF_Name,
                description: item.description,
                delicated: item.delicated,
              });
              // self.requestList = tempdata.sort(function (a, b) {
              //   return b.notificationID - a.notificationID;
              // });

              if (item.seen == false) {
                if (self.unseenList <= 10) {
                  self.unseenList++;
                }

                self.colorList.push("#D0D0D0");
              } else {
                self.colorList.push("white");
              }
            }
          });
          // console.log("off" + self.requestList);
        });
      this.actualNotiState = false;
    },

    changeState(index, status) {
      if (status == false) {
        if (this.unseenList >= 1) {
          this.unseenList--;
          this.colorList.push("white");
          this.colorList[index] = "white";
        }
      }
    },

    async sendDataWithParams(input, notificationID, index, status, item) {
      await this.changeState(index, status);

      let temp = {
        notificationID: notificationID,
      };

      await axios
        .post(`${this.url}NotificationInfo/EditnotificationState`, temp)
        .then(function () {});

      if (item.delicated) {
        alert(
          "This task was delegated to someone else so it's no longer available"
        );
      } else if (!item.delicated) {
        if (
          item.description === "requested for Time Off" ||
          item.description === "requested for OT"
        ) {
          this.$router
            .push({
              name: "mytaskNoti",
              params: { notiString: input, condition: "Approved" },
            })
            .catch(() => {});
        } else {
          if (item.wF_Name === "OT") {
            this.$router.push({
              name: "MyOTrequestNotification",
              params: {
                notiString: input,
                condition:
                  item.description === "denied your OT request"
                    ? "Denied"
                    : "Approved",
              },
            });
          } else if (item.wF_Name === "Feedback") {
            //<router-link :to="{ name: 'newsitem', params: { id: 'item-2' }}">Item 2</router-link>
            // self.$router.push({ name: "HelpFeedback", params: { id: 2 } });
            this.$router
              .push({
                path: `/HelpFeedback/NewFeedback/SentFeedback/${input}`,
              })
              .catch(() => {});
            // this.$router.push({
            //   name: "HelpFeedback",
            //   params: {
            //     notiString: input,
            //     condition: item.description = "Replied",
            //   },
            // });
          } else {
            this.$router
              .push({
                name: "MyTimeOffRequestNotification",
                params: {
                  notiString: input,
                  condition:
                    item.description === "approved your Time Off request"
                      ? "Approved"
                      : "Denied",
                },
              })
              .catch(() => {});
          }
        }
      }
    },

    updatePathParams($router, newParams) {
      // Retrieve current params
      const currentParams = $router.currentRoute.params;

      // Create new params object
      const mergedParams = { ...currentParams, newParams };

      // When router is not supplied path or name,
      // it simply tries to update current route with new params or query
      // Almost everything is optional.
      $router.push({ params: mergedParams }).catch(() => {});
    },

    async sendNotification() {
      let temp = {
        user_ID: store.state.employeeID,
      };

      let token = [];
      await axios
        .post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`, temp)
        .then(function (response) {
          var temp = response.data.data;

          if (temp[0]) {
            temp.map((t) => token.push(t.fireBase_Token));
          }
        });

      // this.tokenList = token;
      return token;
    },

    async generateToken() {
      var tempTokenList = await this.sendNotification();
      let self = this;
      var firebaseConfig = {
        apiKey: "AIzaSyAI4HkLNx1R3atL7BxB7qwvEKQqjiY8k4Y",
        authDomain: "optimistic-cca96.firebaseapp.com",
        databaseURL: "https://optimistic-cca96.firebaseio.com",
        projectId: "optimistic-cca96",
        storageBucket: "optimistic-cca96.appspot.com",
        messagingSenderId: "116479546535",
        appId: "1:116479546535:web:c6cbe479b93035b75eb782",
        measurementId: "G-0L3GT2B6LB",
      };

      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
      }

      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then((token) => {
          self.AddFirebaseToken(token, tempTokenList);
        });

      messaging.onMessage(async (payload) => {
        //alert(payload);
        if (payload.notification.title === "Expire") {
          if (parseInt(payload.notification.icon) == store.state.companyID) {
            if (payload) {
              await alert(payload.notification.body);
              setTimeout(function () {
                self.$router.push({ name: "welcome" }).catch(() => {});
              }, 500);
            }
          }
        } else if (payload.notification.title !== "Expire") {
          //alert(payload.notification.title);
          if (store.state.employeeID == parseInt(payload.notification.icon)) {
            self.notiCount = 0;
            self.unseenList = 0;

            if (self.unseenList > 0) {
              self.notiCount = self.unseenList;
              self.actualNotiState = true;
            }
            self.GetrequestList();
          }
        }
      });
    },

    async AddFirebaseToken(token, tempTokenList) {
      let n = 0;
      await tempTokenList.forEach((element) => {
        if (element == token) {
          n = n + 1;
        }
      });

      if (n == 0) {
        let tempLock = {
          user_ID: store.state.employeeID,
          fireBase_Token: token,
        };

        axios
          .post(`${this.url}Firebasetoken/AddFirebaseToken`, tempLock)
          .then(function () {});
      }
    },
  },
};
</script>
<style scoped>
.notiText {
  color: #444444;
  font-size: 13px;
  font-family: Kanit, sans-serif;
}

.notiHeading {
  width: 500px;
  height: auto;
  max-height: 500px;
  background: white;
}

.btnForMarkAllAsRead {
  margin-left: 20px;
  color: black;
  font-size: 13px;
  margin-top: 2px;
}

.notiButton {
  width: 250px;
  margin-left: 130px;
}

@media (max-width: 330px) and (max-height: 570px) {
  .notiButton {
    width: 220px;
    margin-left: 10px;
  }
}

@media (max-width: 330px) and (max-height: 570px) {
  .notiHeading {
    width: 260px;

    margin-left: 10px;
    font-size: 10px;
  }
  .notiText {
    font-size: 7px;
  }
  .btnForMarkAllAsRead {
    margin-left: 20px;
    color: black;
    font-size: 10px;
    margin-top: 2px;
  }
  .notiButton {
    width: 200px;
    margin-left: 10px;
  }
}

@media (max-width: 380px) and (max-height: 680px) {
  .notiHeading {
    width: 260px;
    margin-left: 10px;
    font-size: 10px;
  }
  .notiText {
    font-size: 7px;
  }
  .btnForMarkAllAsRead {
    margin-left: 20px;
    color: black;
    font-size: 10px;
    margin-top: 2px;
  }
  .notiButton {
    width: 200px;
    margin-left: 10px;
  }
}

@media (max-width: 415px) and (max-height: 740px) {
  .notiHeading {
    width: 260px;
    margin-left: 10px;
    font-size: 10px;
  }
  .notiText {
    font-size: 7px;
  }
  .btnForMarkAllAsRead {
    margin-left: 20px;
    color: black;
    font-size: 10px;
    margin-top: 2px;
  }
  .notiButton {
    width: 200px;
    margin-left: 10px;
  }
}

@media (max-width: 380px) and (max-height: 820px) {
  .notiHeading {
    width: 260px;
    margin-left: 10px;
    font-size: 10px;
  }
  .notiText {
    font-size: 7px;
  }
  .btnForMarkAllAsRead {
    margin-left: 20px;
    color: black;
    font-size: 10px;
    margin-top: 2px;
  }
  .notiButton {
    width: 200px;
    margin-left: 10px;
  }
}
</style>
