<template>
    <div>
        <v-dialog v-model="UserGuideDialog" persistent max-width="1080">
            <v-card style="border-radius: 8px;">

                <v-card-title primary-title class="justify-center">
                    <v-spacer></v-spacer>
                    <div class="text-center pl-5">
                        <span style="color:#0FA7D8">{{ $t("Instruction") }}</span>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn @click="CloseUserGuideDialog()" icon>
                        <img :src="images.picx" class="pr-2" />
                    </v-btn>
                </v-card-title>
                <v-container class="pt-8 pl-8 pr-8">
                    <p class="body-2" style=" line-height: 1.8;">
                        {{ $t("InstructionGuideParagraph") }}<br />
                    </p>
                    <p></p>
                    <div class="pl-8 pt-1" style="background-color: #FFF0D9; ">

                        <v-row justify="center" align="center" class="ml-5 mt-8">
                            <v-col>
                                <img :src="images.pic1" class="pl-2" />
                                <p style="color:#0FA7D8">1. {{ $t("CompanyProfile") }}</p>
                            </v-col>
                            <v-col>
                                <img :src="images.picarrow" />
                            </v-col>
                            <v-col>
                                <img :src="images.pic2" class="pr-2" />
                                <p style="color:#0FA7D8">2. {{ $t("organization") }}</p>
                            </v-col>
                            <v-col>
                                <img :src="images.picarrow"/>
                            </v-col>
                            <v-col>
                                <img :src="images.pic3" class="pl-1" />
                                <p style="color:#0FA7D8">3. {{ $t("Attendance") }}</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" align="center" class="ml-5">
                            <v-col>
                                <img :src="images.pic4" class="pl-2" />
                                <p style="color:#0FA7D8">4. {{ $t("TimeOff") }}</p>
                            </v-col>
                            <v-col>
                                <img :src="images.picarrow" />
                            </v-col>
                            <v-col>
                                <img :src="images.pic5" class="pr-2" />
                                <p style="color:#0FA7D8">5. {{ $t("CommonMasterG") }}</p>
                            </v-col>
                            <v-col>
                                <img :src="images.picarrow" />
                            </v-col>
                            <v-col>
                                <img :src="images.pic6" class="mr-2" />
                                <p style="color:#0FA7D8">6. {{ $t("PersonnelManagement") }}</p>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>

                <v-card-actions class=" d-flex justify-center mt-6 pb-6">
                    <v-btn color="#F99D20" class="mr-2 white--text text-capitalize" @click="GoToDefault()"
                        max-height="35">{{ $t("GoToDefault") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ["UserGuideDialog"],
    data() {
        return {
            thai: false,
            images: {
                pic1: require("@/assets/images/Picture1.png"),
                pic2: require("@/assets/images/Picture2.png"),
                pic3: require("@/assets/images/Picture7.png"),
                pic4: require("@/assets/images/Picture4.png"),
                pic5: require("@/assets/images/Picture5.png"),
                pic6: require("@/assets/images/Picture6.png"),
                picarrow: require("@/assets/images/Icon awesome-long-arrow-alt-right.png"),
                picx: require("@/assets/images/Icon metro-cross.png"),
            },
        };
    },
    methods: {
        CloseUserGuideDialog(){
            this.UserGuideDialog = false;
            localStorage.setItem("FirstLogin", 2);
        },
        GoToDefault(){
            let self = this;
            self.UserGuideDialog = false;
            localStorage.setItem("FirstLogin", 2);
            self.$router.push("/organization/companyprofile/companyinformation").catch(() => {});
        },
    },
};
</script>
  
<style scoped>
>>>.v-dialog::-webkit-scrollbar {
    width: 6px;
    background-color: none;
}

>>>.v-dialog::-webkit-scrollbar-thumb {
    background: #707070;
    outline: 1px solid #654321;
}
</style>
  