<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" style="cursor: pointer" class="mt-6 ml-1" @click="back()">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("EmployeeLeaveSummary") }}
        </p>
    </div>
    
    <v-card class="pt-4 commonmb" style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-0" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <p class="mt-0">{{ $t("startdate_report") }}</p>
          <date-picker v-bind:clearable="false" v-model="chartStartDate" format="DD/MM/YYYY" placeholder="DD/MM/YYYY"
            class="fromdate mt-1" style="width: 150px" @change="OnChangeStartDate()">
            <template slot="icon-calendar">
              <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
            </template>
          </date-picker>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <p class="mt-0">{{ $t("enddata") }}</p>
          <date-picker v-bind:clearable="false" class="todate mt-1" v-model="chartEndDate" :disabled-date="allowedEndDates"  format="DD/MM/YYYY"
            width="100%" placeholder="DD/MM/YYYY" readonly style="width: 150px" @change="OnChangeEndDate()">
            <template slot="icon-calendar">
              <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
            </template>
          </date-picker>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
          <p class="mt-0">{{ $t("emplosname") }}</p>
          <v-autocomplete :items="employeeNames" item-text="fullName" item-value="fullName" v-model="whatthename"
            color="#F99D20" outlined style="min-width: 200px; max-width: 220px" dense :placeholder="$t(`searchempname`)"
            v-on:change="chooseName()">
            <v-icon slot="append" class="v-icon notranslate mdi mdi-chevron-down theme--light" />
          </v-autocomplete>
        </v-col>
        <v-col v-if="showapply" cols="12" xs="12" sm="4" md="4" lg="3" xl="2">
          <v-row class="lefz" style="margin-top: 2.6rem">
            <v-btn color="#F99D20" class="white--text text-capitalize ml-3 mr-5" width="150" max-height="35"
              max-width="100" min-width="100" @click="searchTable()">{{ $t("Apply") }}</v-btn>
            <p class="mt-2" style="cursor: pointer" @click="reseto()">
              {{ $t("Reset") }}
            </p>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-autocomplete :items="exportItem" item-text="exptxt" item-value="expval" color="#F99D20" class="movelf ddl"
            outlined v-model="expit" :placeholder="$t(`Export`)" style="
                min-width: 150px;
                max-width: 150px;
                border-radius: 5px;
                margin-top: 2rem;
              " dense v-on:change="changetoexport(expit)" :disabled="permission.export">
            <v-icon slot="append" class="v-icon notranslate mdi mdi-chevron-down theme--light" />
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="mt-5">
        <v-data-table style="margin-left: 2rem; margin-right: 2rem" :items="showList" hide-default-footer
          hide-default-header :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event"
          :mobile-breakpoint="0">
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>{{ $t("EmployeeID") }}</th>
                <th>{{ $t("employee_name") }}</th>
                <th>{{ $t("TypeOfTimeOff") }}</th>
                <th>{{ $t("datesoff") }}</th>
                <th>{{ $t("StartTime") }}</th>
                <th>{{ $t("EndTime") }}</th>
                <th>{{ $t("RequDate") }}</th>
                <th>{{ $t("AprovDate") }}</th>
                <th>{{ $t("timeoffday") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.employee_Code }}</td>
              <td>{{ item.fullName }}</td>
              <td v-show="!thethaihead">{{ item.timeoff_Name }}</td>
              <td v-show="thethaihead">{{ item.timeoff_NameTH }}</td>
              <td>{{ item.offdays }}</td>
              <td>{{ item.start_Time }}</td>
              <td>{{ item.end_Time }}</td>
              <td>{{ DateFormat(item.theReqDate) }}</td>
              <td>{{ DateFormat(item.theAprvDate) }}</td>
              <td v-show="item.toton">{{ item.toffday }} {{ $t("days") }}</td>
              <td v-show="!item.toton">
                {{ $t("Total") }} {{ item.toffday }} {{ $t("days") }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <div v-show="showtablerow" class="text-right">
          <PaginationComponent
            :page.sync="page"
            :pageCount="pageCount"
            :itemsPerPage.sync="itemsPerPage"
        /> 
        </div>
      </div>
    </v-card>

    <!-- print dialog -->
    <v-dialog v-model="printDialog" max-width="100%">
      <v-card style="min-width: 100%">
        <div class="canvas_div_pdf">
          <v-card-text class="print-break-page" ref="content">
            <div style="margin-left: 1rem">
              <v-row class="pl-md-0 mt-0 pt-2">
                <v-col cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                  <p class="headtext" style="font-size: 28px; font-family: 'Kanit'">
                    {{ $t("timeoffused") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="mr-0 pr-0" cols="3" xs="6" sm="2" md="1" lg="1" xl="1">
                  <v-img class="ggg" style="padding: -10px; padding-right: 0px" :src="logo.image" max-height="60"
                    max-width="60" min-width="60" min-height="60" contain />
                </v-col>
                <v-col style="min-width: 150px" cols="4" xs="6" sm="1" md="1" lg="1" xl="1" class="mt-2 ml-0 pl-0">
                  <p style="
                        padding-left: 0px;
                        margin-bottom: 0px;
                        font-size: 18px;
                        color: #0fa7d8 !important;
                      ">
                    Optimistic
                  </p>
                  <p style="
                        padding-left: 0px;
                        margin-bottom: -5px;
                        font-size: 12px;
                        color: #f99d20 !important;
                      ">
                    Powered by Optimistic
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-md-0 mt-0" style="margin-right: 10px">
                <v-col class="mt-0" cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-0">{{ $t("Dates") }}</p>
                  <p class="headtext">
                    {{ DateFormatonTop(chartStartDate) }} -
                    {{ DateFormatonTop(chartEndDate) }}
                  </p>
                </v-col>
              </v-row>
              <hr />
            </div>
            <div id="middlecol">
              <v-data-table style="margin-left: 2rem; margin-right: 2rem" :items="showList" hide-default-footer
                hide-default-header :items-per-page="itemsPerPage1">
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th>{{ $t("EmployeeID") }}</th>
                      <th>{{ $t("employee_name") }}</th>
                      <th>{{ $t("TypeOfTimeOff") }}</th>
                      <th>{{ $t("datesoff") }}</th>
                      <th>{{ $t("RequDate") }}</th>
                      <th>{{ $t("AprovDate") }}</th>
                      <th>{{ $t("timeoffday") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.employee_Code }}</td>
                    <td>{{ item.fullName }}</td>
                    <td v-show="!thethaihead">{{ item.timeoff_Name }}</td>
                    <td v-show="thethaihead">{{ item.timeoff_NameTH }}</td>
                    <td>{{ item.offdays }}</td>
                    <td>{{ DateFormat(item.theReqDate) }}</td>
                    <td>{{ DateFormat(item.theAprvDate) }}</td>
                    <td v-show="item.toton">
                      {{ item.toffday }} {{ $t("days") }}
                    </td>
                    <td v-show="!item.toton">
                      {{ $t("Total") }} {{ item.toffday }} {{ $t("days") }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="150" max-height="35" text @click="
            printDialog = false;
          expit = '';
                        ">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="#F99D20" class="mr-3 text-capitalize white--text fix-btn" width="150" max-height="35"
            style="font-family: kanit, Regular; font-weight: normal" @click="downloadPDF()">
            {{ $t("Print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>

<script>
//import Exporter from "@/function/exporter.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DatePicker from "vue2-datepicker";
import axios from "axios";
import moment from "moment";
import enurl from "@/api/environment";
import "vue2-datepicker/index.css";
import CheckViewExport from "@/function/RolePermissions.js";
import store from "@/store";
import XLSX from "xlsx";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';
import $ from "jquery";
export default {
  components: {
    DatePicker, LoadingDialog, PaginationComponent
  },
  mixins: [CheckViewExport],
  data() {
    return {
      permission: {
        export: true,
      },
      url: enurl.apiUrl,
      loaded: true,
      tot: 0,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      itemsPerPage1: 500,
      showtablerow: false,
      LoadingDialog: false,
      chartStartDate: null,
      chartEndDate: new Date(),
      todaydate: new Date(),
      expit: "",
      sheetTitle: null,
      exportItem: [
        { expval: 1, exptxt: "Excel" },
        { expval: 2, exptxt: "PDF" },
        { expval: 3, exptxt: "CSV" },
      ],
      printDialog: false,
      timeoffusedList: [],
      showList: [],
      employeeNames: [],
      whatthename: null,
      logo: {
        image: require("@/assets/images/logo@2x.png"),
      },
      showapply: false,
      thelang: "",
    };
  },
  mounted() {
    this.chartStartDate = new Date();
    this.chartStartDate.setDate(1);
    this.chartStartDate.setMonth(0);
    this.getstart();
    this.permission = this.CheckViewExport(182, 183);
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  computed: {
    thethaihead() {
      return this.whatthehead();
    },
  },
  watch: {
    thelang() {
      this.whatthehead()
    },
    chartStartDate(v) {
      if (new Date(v) > new Date(this.chartEndDate)) {
        this.chartEndDate = null;
      }
    }
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    back() {
      this.$router.push(`/timeoffreport`).catch(() => { });
    },
    reseto() {
      this.showapply = false;
      this.whatthename = null;
      this.getstart();
    },
    chooseName() {
      this.showapply = true;
    },
    searchTable() {
      let self = this;
      self.showList = [];
      let randomarray = [];
      self.timeoffusedList.map(function (data) {
        if (data.fullName == self.whatthename) {
          randomarray.push(data.employee_Code);
        }
      });
      let unique = [...new Set(randomarray)];

      let TTypearray = [];
      self.timeoffusedList.map(function (data) {
        if (data.timeoff_Name) {
          TTypearray.push(data.timeoff_Name);
        }
      });
      let unique1 = [...new Set(TTypearray)];

      for (let j = 0; j < unique.length; j++) {
        let total = 0;
        let firstrow = 0;
        let Filtercode = self.timeoffusedList.filter(
          (data) => data.employee_Code == unique[j]
        );
        for (let l = 0; l < unique1.length; l++) {
          let FilterType = Filtercode.filter(
            (data) => data.timeoff_Name == unique1[l]
          );
          for (let k = 0; k < FilterType.length; k++) {
            if (unique[j] == FilterType[k].employee_Code) {
              if (firstrow == 0) {
                self.showList.push({
                  employee_Code: FilterType[k].employee_Code,
                  fullName: FilterType[k].fullName,
                  timeoff_Name: FilterType[k].timeoff_Name,
                  timeoff_NameTH: FilterType[k].timeoff_NameTH,
                  offdays: FilterType[k].offdays,
                  theReqDate: FilterType[k].theReqDate,
                  theAprvDate: FilterType[k].theAprvDate,
                  start_Time: FilterType[k].start_Time,
                  end_Time: FilterType[k].end_Time,
                  toton: true,
                  toffday: FilterType[k].toffday,
                });
                total = total + FilterType[k].toffday;
                firstrow++;
                if (k == FilterType.length - 1) {
                  self.showList.push({
                    employee_Code: "",
                    fullName: "",
                    timeoff_Name: "",
                    timeoff_NameTH: "",
                    offdays: "",
                    theReqDate: "",
                    theAprvDate: "",
                    start_Time: "",
                    end_Time: "",
                    justtext: "Total",
                    toton: false,
                    toffday: total,
                  });
                  total = 0;
                  firstrow = 0;
                }
              } else if (firstrow != 0) {
                self.showList.push({
                  employee_Code: "",
                  fullName: "",
                  timeoff_Name: FilterType[k].timeoff_Name,
                  timeoff_NameTH: FilterType[k].timeoff_NameTH,
                  offdays: FilterType[k].offdays,
                  theReqDate: FilterType[k].theReqDate,
                  theAprvDate: FilterType[k].theAprvDate,
                  start_Time: FilterType[k].start_Time,
                  end_Time: FilterType[k].end_Time,
                  toton: true,
                  toffday: FilterType[k].toffday,
                });
                firstrow++;
                total = total + FilterType[k].toffday;
                if (k == FilterType.length - 1) {
                  self.showList.push({
                    employee_Code: "",
                    fullName: "",
                    timeoff_Name: "",
                    timeoff_NameTH: "",
                    offdays: "",
                    theReqDate: "",
                    theAprvDate: "",
                    start_Time: "",
                    end_Time: "",
                    justtext: "Total",
                    toton: false,
                    toffday: total,
                  });
                  total = 0;
                  firstrow = 0;
                }
              }
            } else {
              if (
                firstrow != 0 ||
                (k == FilterType.length - 1 &&
                  FilterType[k].toton == true)
              ) {
                self.showList.push({
                  employee_Code: "",
                  fullName: "",
                  timeoff_Name: "",
                  timeoff_NameTH: "",
                  offdays: "",
                  theReqDate: "",
                  theAprvDate: "",
                  start_Time: "",
                  end_Time: "",
                  justtext: "Total",
                  toton: false,
                  toffday: total,
                });
                total = 0;
                firstrow = 0;
              }
            }
          }
        }

      }
    },
    OnChangeStartDate() {
      let self = this;
      if (
        moment(self.chartStartDate) < moment(self.chartEndDate).add(1, "day")
      ) {
        self.getstart();
      } else {
        alert("Start date can’t be after end date.");
      }
    },
    OnChangeEndDate() {
      let self = this;
      if (
        moment(self.chartStartDate) < moment(self.chartEndDate).add(1, "day")
      ) {
        self.getstart();
      } else {
        alert("Start date can’t be after end date.");
      }
    },
    allowedEndDates(date) {
      if (this.chartStartDate) {
        return new Date(date) <= new Date(this.chartStartDate);
      }
      return false;
    },
    async getstart() {
      try {
        let self = this;
        this.LoadingDialog = true;
        self.timeoffusedList = [];
        self.employeeNames = [];
        self.showList = [];
        let temp = {
          company_ID: store.state.companyID,
        };
        const response = await axios.post(
          `${this.url}TimeOff_Request/GetTimeOff_RequestForReport`,
          temp
        );

        let filterList = response.data.data;

        let list = filterList.filter((v) => {
          let stillUtc = moment.utc(v.apporve_Date).toDate(); //still utc
          let request_On = moment(stillUtc).local().format("YYYY-MM-DD");

          return (
            request_On >= moment(this.chartStartDate).format("YYYY-MM-DD") &&
            request_On <= moment(this.chartEndDate).format("YYYY-MM-DD")
          );
        });

        for (let i = 0; i < list.length; i++) {
          self.timeoffusedList.push({
            employee_Code: list[i].employee_Code,
            fullName: list[i].employee_Name,
            timeoff_Name: list[i].timeoff_Name,
            timeoff_NameTH: list[i].timeoff_NameTH,
            offdays:
              self.DateFormat(list[i].start_Date) +
              " - " +
              self.DateFormat(list[i].end_Date),
            theReqDate: list[i].request_On,
            theAprvDate: list[i].apporve_Date,
            start_Time: self.isitblank(list[i].start_Time),
            end_Time: self.isitblank(list[i].end_Time),
            toton: false,
            toffday: self.howMany(
              list[i].start_Date,
              list[i].end_Date,
              list[i].wholeDay
            ),
          });
          self.employeeNames.push({
            employee_Code: list[i].employee_Code,
            fullName: list[i].employee_Name,
            timeoff_Name: list[i].timeoff_Name,
            timeoff_NameTH: list[i].timeoff_NameTH,
          });
        }
        self.employeeNames.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
        let randomarray = [];
        self.employeeNames.map(function (data) {
          if (data.employee_Code) {
            randomarray.push(data.employee_Code);
          }
        });
        let unique = [...new Set(randomarray)];

        let TTypearray = [];
        self.employeeNames.map(function (data) {
          if (data.timeoff_Name) {
            TTypearray.push(data.timeoff_Name);
          }
        });
        let unique1 = [...new Set(TTypearray)];

        for (let j = 0; j < unique.length; j++) {
          let total = 0;
          let firstrow = 0;
          let Filtercode = self.timeoffusedList.filter(
            (data) => data.employee_Code == unique[j]
          );
          for (let l = 0; l < unique1.length; l++) {
            let FilterType = Filtercode.filter(
              (data) => data.timeoff_Name == unique1[l]
            );
            for (let k = 0; k < FilterType.length; k++) {
              if (unique[j] == FilterType[k].employee_Code) {
                if (firstrow == 0) {
                  self.showList.push({
                    employee_Code: FilterType[k].employee_Code,
                    fullName: FilterType[k].fullName,
                    timeoff_Name: FilterType[k].timeoff_Name,
                    timeoff_NameTH: FilterType[k].timeoff_NameTH,
                    offdays: FilterType[k].offdays,
                    theReqDate: FilterType[k].theReqDate,
                    theAprvDate: FilterType[k].theAprvDate,
                    start_Time: FilterType[k].start_Time,
                    end_Time: FilterType[k].end_Time,
                    toton: true,
                    toffday: FilterType[k].toffday,
                  });
                  total = total + FilterType[k].toffday;
                  firstrow++;
                  if (k == FilterType.length - 1) {
                    self.showList.push({
                      employee_Code: "",
                      fullName: "",
                      timeoff_Name: "",
                      timeoff_NameTH: "",
                      offdays: "",
                      theReqDate: "",
                      theAprvDate: "",
                      start_Time: "",
                      end_Time: "",
                      justtext: "Total",
                      toton: false,
                      toffday: total,
                    });
                    total = 0;
                    firstrow = 0;
                  }
                } else if (firstrow != 0) {
                  self.showList.push({
                    employee_Code: "",
                    fullName: "",
                    timeoff_Name: FilterType[k].timeoff_Name,
                    timeoff_NameTH: FilterType[k].timeoff_NameTH,
                    offdays: FilterType[k].offdays,
                    theReqDate: FilterType[k].theReqDate,
                    theAprvDate: FilterType[k].theAprvDate,
                    start_Time: FilterType[k].start_Time,
                    end_Time: FilterType[k].end_Time,
                    toton: true,
                    toffday: FilterType[k].toffday,
                  });
                  firstrow++;
                  total = total + FilterType[k].toffday;
                  if (k == FilterType.length - 1) {
                    self.showList.push({
                      employee_Code: "",
                      fullName: "",
                      timeoff_Name: "",
                      timeoff_NameTH: "",
                      offdays: "",
                      theReqDate: "",
                      theAprvDate: "",
                      start_Time: "",
                      end_Time: "",
                      justtext: "Total",
                      toton: false,
                      toffday: total,
                    });
                    total = 0;
                    firstrow = 0;
                  }
                }
              } else {
                if (
                  firstrow != 0 ||
                  (k == FilterType.length - 1 &&
                    FilterType[k].toton == true)
                ) {
                  self.showList.push({
                    employee_Code: "",
                    fullName: "",
                    timeoff_Name: "",
                    timeoff_NameTH: "",
                    offdays: "",
                    theReqDate: "",
                    theAprvDate: "",
                    start_Time: "",
                    end_Time: "",
                    justtext: "Total",
                    toton: false,
                    toffday: total,
                  });
                  total = 0;
                  firstrow = 0;
                }
              }
            }
          }

        }
        if (self.timeoffusedList.length != 0) {
          self.tot = self.timeoffusedList.length;
          self.showtablerow = true;
        } else {
          self.showtablerow = false;
        }

        this.LoadingDialog = false;
      } catch (ex) {
        throw ex;
      }
    },
    changetoexport(v) {
      let self = this;
      self.LoadingDialog = true;
      if (v == 1) {
        // Excel
        if (self.showList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.showList) {
              if (v.toton == true) {
                wsdata.push({
                  ["รหัสพนักงาน"]: v.employee_Code,
                  ["ชื่อพนักงาน"]: v.fullName,
                  ["ประเภทวันลา"]: v.timeoff_NameTH,
                  ["วันที่ลา"]: v.offdays,
                  ["เวลาเริ่มต้น"]: v.start_Time,
                  ["เวลาสิ้นสุด"]: v.end_Time,
                  ["วันที่ขอ"]: self.DateFormat(v.theReqDate),
                  ["วันที่อนุมัติ"]: self.DateFormat(v.theAprvDate),
                  ["จำนวนวันที่ลา"]: v.toffday,
                });
              } else {
                wsdata.push({
                  ["รหัสพนักงาน"]: v.employee_Code,
                  ["ชื่อพนักงาน"]: v.fullName,
                  ["ประเภทวันลา"]: v.timeoff_NameTH,
                  ["วันที่ลา"]: v.offdays,
                  ["เวลาเริ่มต้น"]: v.start_Time,
                  ["เวลาสิ้นสุด"]: v.end_Time,
                  ["วันที่ขอ"]: self.DateFormat(v.theReqDate),
                  ["วันที่อนุมัติ"]: v.justtext,
                  ["จำนวนวันที่ลา"]: v.toffday,
                });
              }
            }
          } else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.showList) {
                if (v.toton == true) {
                  wsdata.push({
                    ["รหัสพนักงาน"]: v.employee_Code,
                    ["ชื่อพนักงาน"]: v.fullName,
                    ["ประเภทวันลา"]: v.timeoff_NameTH,
                    ["วันที่ลา"]: v.offdays,
                    ["เวลาเริ่มต้น"]: v.start_Time,
                    ["เวลาสิ้นสุด"]: v.end_Time,
                    ["วันที่ขอ"]: self.DateFormat(v.theReqDate),
                    ["วันที่อนุมัติ"]: self.DateFormat(v.theAprvDate),
                    ["จำนวนวันที่ลา"]: v.toffday,
                  });
                } else {
                  wsdata.push({
                    ["รหัสพนักงาน"]: v.employee_Code,
                    ["ชื่อพนักงาน"]: v.fullName,
                    ["ประเภทวันลา"]: v.timeoff_NameTH,
                    ["วันที่ลา"]: v.offdays,
                    ["เวลาเริ่มต้น"]: v.start_Time,
                    ["เวลาสิ้นสุด"]: v.end_Time,
                    ["วันที่ขอ"]: self.DateFormat(v.theReqDate),
                    ["วันที่อนุมัติ"]: v.justtext,
                    ["จำนวนวันที่ลา"]: v.toffday,
                  });
                }
              }
            } else {
              for (let v of self.showList) {
                if (v.toton == true) {
                  wsdata.push({
                    ["Employee ID"]: v.employee_Code,
                    ["Employee Name"]: v.fullName,
                    ["Type Of Time Off"]: v.timeoff_Name,
                    ["Date(s) Off"]: v.offdays,
                    ["Start Time"]: v.start_Time,
                    ["End Time"]: v.end_Time,
                    ["Requested Date"]: self.DateFormat(v.theReqDate),
                    ["Approved Date"]: self.DateFormat(v.theAprvDate),
                    ["Time Off Day"]: v.toffday,
                  });
                } else {
                  wsdata.push({
                    ["Employee ID"]: v.employee_Code,
                    ["Employee Name"]: v.fullName,
                    ["Type Of Time Off"]: v.timeoff_Name,
                    ["Date(s) Off"]: v.offdays,
                    ["Start Time"]: v.start_Time,
                    ["End Time"]: v.end_Time,
                    ["Requested Date"]: self.DateFormat(v.theReqDate),
                    ["Approved Date"]: v.justtext,
                    ["Time Off Day"]: v.toffday,
                  });
                }
              }
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Time Off Used Report`;
          self.sheetTitle = `Time Off Used`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      } else if (v == 2) {
        // PDF
        self.printDialog = true;
        self.todaydate = new Date();
        self.itemsPerPage1 = self.showList.length;
        self.LoadingDialog = false;
      } else {
        //CSV
        if (self.showList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.showList) {
              if (v.toton == true) {
                wsdata.push({
                  ["รหัสพนักงาน"]: v.employee_Code,
                  ["ชื่อพนักงาน"]: v.fullName,
                  ["ประเภทวันลา"]: v.timeoff_NameTH,
                  ["วันที่ลา"]: v.offdays,
                  ["เวลาเริ่มต้น"]: v.start_Time,
                  ["เวลาสิ้นสุด"]: v.end_Time,
                  ["วันที่ขอ"]: self.DateFormat(v.theReqDate),
                  ["วันที่อนุมัติ"]: self.DateFormat(v.theAprvDate),
                  ["จำนวนวันที่ลา"]: v.toffday,
                });
              } else {
                wsdata.push({
                  ["รหัสพนักงาน"]: v.employee_Code,
                  ["ชื่อพนักงาน"]: v.fullName,
                  ["ประเภทวันลา"]: v.timeoff_NameTH,
                  ["วันที่ลา"]: v.offdays,
                  ["เวลาเริ่มต้น"]: v.start_Time,
                  ["เวลาสิ้นสุด"]: v.end_Time,
                  ["วันที่ขอ"]: self.DateFormat(v.theReqDate),
                  ["วันที่อนุมัติ"]: v.justtext,
                  ["จำนวนวันที่ลา"]: v.toffday,
                });
              }
            }
          } else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.showList) {
                if (v.toton == true) {
                  wsdata.push({
                    ["รหัสพนักงาน"]: v.employee_Code,
                    ["ชื่อพนักงาน"]: v.fullName,
                    ["ประเภทวันลา"]: v.timeoff_NameTH,
                    ["วันที่ลา"]: v.offdays,
                    ["เวลาเริ่มต้น"]: v.start_Time,
                    ["เวลาสิ้นสุด"]: v.end_Time,
                    ["วันที่ขอ"]: self.DateFormat(v.theReqDate),
                    ["วันที่อนุมัติ"]: self.DateFormat(v.theAprvDate),
                    ["จำนวนวันที่ลา"]: v.toffday,
                  });
                } else {
                  wsdata.push({
                    ["รหัสพนักงาน"]: v.employee_Code,
                    ["ชื่อพนักงาน"]: v.fullName,
                    ["ประเภทวันลา"]: v.timeoff_NameTH,
                    ["วันที่ลา"]: v.offdays,
                    ["เวลาเริ่มต้น"]: v.start_Time,
                    ["เวลาสิ้นสุด"]: v.end_Time,
                    ["วันที่ขอ"]: self.DateFormat(v.theReqDate),
                    ["วันที่อนุมัติ"]: v.justtext,
                    ["จำนวนวันที่ลา"]: v.toffday,
                  });
                }
              }
            } else {
              for (let v of self.showList) {
                if (v.toton == true) {
                  wsdata.push({
                    ["Employee ID"]: v.employee_Code,
                    ["Employee Name"]: v.fullName,
                    ["Type Of Time Off"]: v.timeoff_Name,
                    ["Date(s) Off"]: v.offdays,
                    ["Start Time"]: v.start_Time,
                    ["End Time"]: v.end_Time,
                    ["Requested Date"]: self.DateFormat(v.theReqDate),
                    ["Approved Date"]: self.DateFormat(v.theAprvDate),
                    ["Time Off Day"]: v.toffday,
                  });
                } else {
                  wsdata.push({
                    ["Employee ID"]: v.employee_Code,
                    ["Employee Name"]: v.fullName,
                    ["Type Of Time Off"]: v.timeoff_Name,
                    ["Date(s) Off"]: v.offdays,
                    ["Start Time"]: v.start_Time,
                    ["End Time"]: v.end_Time,
                    ["Requested Date"]: self.DateFormat(v.theReqDate),
                    ["Approved Date"]: v.justtext,
                    ["Time Off Day"]: v.toffday,
                  });
                }
              }
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Time Off Used Report`;
          self.sheetTitle = `Time Off Used`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.csv`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      }
    },
    downloadPDF() {
      html2canvas($(".canvas_div_pdf")[0], {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save("Time Off Used Report.pdf");
      });
    },
    howMany(start, end, wholeday) {
      let offdays = 0;
      if (wholeday == true) {
        if (start == end) {
          offdays = 1;
        } else {
          const diffInDays = moment(end).diff(moment(start), "days");
          offdays = diffInDays + 1;
        }
      } else {
        offdays = 0.5;
      }
      return offdays;
    },
    DateFormat(value) {
      let output = "-";
      if (value != "") {
        let temp = new Date(value);
        try {
          let day = temp.getDate().toString().padStart(2, 0);
          let month = (temp.getMonth() + 1).toString().padStart(2, 0);
          let year = temp.getFullYear();
          output = `${day}/${month}/${year}`;
          return output;
        } catch (err) {
          return output;
        }
      } else {
        return "";
      }
    },
    DateFormatonTop(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${day}-${month}-${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    isitblank(v) {
      let res = null;
      if (v == "") {
        res = "-";
      } else {
        res = v;
      }
      return res;
    }
  },
};
</script>
<style scoped>
>>>.mx-input {
  color: #f99d20;
  border: 2px solid #f99d20 !important;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}

.backGG {
  background-color: #f8f8f8;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}

.linecht {
  height: 368px;
  width: 800px;
  position: "relative";
}

.ddl {
  float: right;
}

.container {
  display: flex;
  justify-content: center;
  height: 350px;
  width: 930px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  opacity: 1;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}

.print-break-page {
  page-break-after: always;
}

.ggg {
  margin-left: 30px;
}

@media print {
  body {
    overflow: auto;
    height: auto;
  }

  .scroll-y {
    height: auto;
    overflow: visible;
  }
}

@media only screen and (max-width: 850px) {
  .linecht {
    width: 200px !important;
  }

  .ddl {
    float: left;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .linecht {
    width: 600px !important;
  }
}

@media (min-width: 960px) and (max-width: 1400px) {
  .ddl {
    float: left;
  }
}

@media (max-width: 860px) {
  .ggg {
    margin-left: 0px;
  }
}

@media (min-width: 950px) and (max-width: 1200px) {
  .ggg {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1900px) {
  .movelf {
    margin-right: 2rem;
  }
}
</style>