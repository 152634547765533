<template>
  <div>
    <!-- <div style="margin-left: 1%;display:flex;">
      <v-row class="mr-0" style="cursor: pointer" @click="back()">
        <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="headtext"
          style="
            font-size: 25px;
            margin-left: 4px;
            margin-top: 20px;
            cursor: pointer;">
          {{ $t("employeesbyageandgender") }}
        </p>
      </v-row>
    </div> -->
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" style="cursor: pointer" class="mt-6 ml-1" @click="back()">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("employeesbyageandgender") }}
        </p>
    </div>

    <div style="margin-left: 1.5rem; margin-right: 1.5rem;" class="commonmb">
      <v-tabs
      grey--text
      background-color="#f0f8fc"
      show-arrows
      slider-color="#F99D20"
      >
      <v-tab
        class="text-capitalize"
        @click="changetab('age')"
        style="
          border-radius: 10px 10px 5px 5px;
          width: 160px;
          background-color: white;
        "
      >
        {{ $t("age") }}</v-tab
      >
      <v-tab
        class="text-capitalize"
        @click="changetab('gender')"
        style="
          border-radius: 10px 10px 5px 5px;
          width: 160px;
          background-color: white;
        "
      >
        {{ $t("gender") }}</v-tab
      >
      <v-tab-item>
        <v-card class="pt-4" height="100%">
          <v-row
            class="pl-md-0 mt-0"
            style="margin-left: 15px; margin-right: 10px"
          >
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <p class="mt-0">{{ $t("startdate_report") }}</p>
              <date-picker
                :disabled="disableagedate"
                v-bind:clearable="false"
                v-model="chartStartDate"
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                class="fromdate"
                readonly
                style="width: 150px; color: "
                @change="GetAgelist()"
              >
                <template slot="icon-calendar">
                  <img
                    src="@/assets/images/calendaricon.png"
                    style="width: 20px; height: 20px"
                  />
                </template>
              </date-picker>
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <p class="mt-0">{{ $t("enddata") }}</p>
              <date-picker
                :disabled="disableagedate"
                v-bind:clearable="false"
                :allowed-dates="allowedEndDates"
                class="todate"
                v-model="chartEndDate"
                format="DD/MM/YYYY"
                width="100%"
                placeholder="DD/MM/YYYY"
                readonly
                style="width: 150px"
                @change="GetAgelist()"
              >
                <template slot="icon-calendar">
                  <img
                    src="@/assets/images/calendaricon.png"
                    style="width: 20px; height: 20px"
                  />
                </template>
              </date-picker>
            </v-col>
            <v-col class="pl-0" cols="12" xs="12" sm="6" md="3" lg="3">
              <div class="d-flex mt-5 ml-3">
                <v-checkbox class="custom-checkbox" color="#F99D20" v-model="showalldateforage"></v-checkbox>
                <!-- <v-checkbox v-model="showalldateforage"></v-checkbox> -->
                <p class="mt-4" style="font-size: 17px; color: #8a8c8e">
                  {{ $t("showalldate") }}
                </p>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              {{ $t("agestatus") }}
              <v-autocomplete
                :items="agestatusitems"
                item-text="text"
                item-value="val"
                v-model="selectedstatusforage"
                color="#F99D20"
                class="text"
                clearable
                outlined
                style="
                  min-width: 140px;
                  border-radius: 5px;
                  margin-top: 0.2rem;
                  margin-right: 1rem;
                "
                dense
              >
                <v-icon
                  slot="append"
                  class="v-icon notranslate mdi mdi-chevron-down theme--light"
                />
              </v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="2" lg="2">
              <v-autocomplete
                :items="exportItem"
                item-text="exptxt"
                item-value="expval"
                v-model="expit"
                color="#F99D20"
                class="text"
                clearable
                outlined
                :placeholder="$t(`Export`)"
                style="
                  min-width: 140px;
                  border-radius: 5px;
                  margin-top: 1.8rem;
                  margin-right: 1rem;
                "
                dense
                v-on:change="changetoexport(expit)"
                :disabled="permission.export"
              >
                <v-icon
                  slot="append"
                  class="v-icon notranslate mdi mdi-chevron-down theme--light"
                />
              </v-autocomplete>
            </v-col>
          </v-row>
          <div class="backGG">
            <bar-chart
              class="chartbox"
              justify="center"
              :chartData="chartData"
              :options="options"
            ></bar-chart>
            <!-- <line-chart
          :chartData="chartdata"
          :options="options"
          
        ></line-chart> -->
          </div>
          <div class="mt-5">
            <v-data-table
              style="margin-left: 2rem; margin-right: 2rem"
              :items="TerminationList"
              hide-default-footer
              hide-default-header
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th>{{ $t("EmployeeID") }}</th>
                    <th>{{ $t("employee_name") }}</th>
                    <th>{{ $t("age") }}</th>

                    <th>{{ $t("position") }}</th>
                    <th>{{ $t("department") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.employee_Code }}</td>
                  <td>{{ item.employee_Name }}</td>
                  <td>{{ getAge(item.birthDate) }}</td>
                  <td>{{ item.position_Name }}</td>
                  <td>{{ item.department_Name }}</td>
                </tr>
              </template>
            </v-data-table>
            <div v-if="TerminationList.length != 0" class="text-right">
              <PaginationComponent
                  :page.sync="page"
                  :pageCount="pageCount"
                  :itemsPerPage.sync="itemsPerPage"
              />
            </div>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="pt-4">
          <v-row
            class="pl-md-0 mt-0"
            style="margin-left: 15px; margin-right: 10px"
          >
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <p class="mt-0">{{ $t("start_date") }}</p>
              <date-picker
                :disabled="disablegenderdate"
                v-bind:clearable="false"
                v-model="GenderStartDate"
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                class="fromdate"
                style="width: 150px"
                @change="GetGenderList()"
              >
                <template slot="icon-calendar">
                  <img
                    src="@/assets/images/calendaricon.png"
                    style="width: 20px; height: 20px"
                  />
                </template>
              </date-picker>
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <p class="mt-0">{{ $t("enddata") }}</p>
              <date-picker
                :disabled="disablegenderdate"
                v-bind:clearable="false"
                class="todate"
                v-model="GenderEndDate"
                format="DD/MM/YYYY"
                width="100%"
                placeholder="DD/MM/YYYY"
                style="width: 150px"
                @change="GetGenderList()"
              >
                <template slot="icon-calendar">
                  <img
                    src="@/assets/images/calendaricon.png"
                    style="width: 20px; height: 20px"
                  />
                </template>
              </date-picker>
            </v-col>
            <v-col class="pl-0" cols="12" xs="12" sm="6" md="3" lg="3">
              <div class="d-flex mt-5 ml-3">
                <v-checkbox class="custom-checkbox" color="#F99D20" v-model="showalldateforgender"></v-checkbox>
                <!-- <v-checkbox v-model="showalldateforgender"></v-checkbox> -->
                <p class="mt-4" style="font-size: 17px; color: #8a8c8e">
                  {{ $t("showalldate") }}
                </p>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="2" md="2" lg="2">
              {{ $t("agestatus") }}
              <v-autocomplete
                :items="genderstatusitems"
                item-text="text"
                item-value="val"
                v-model="selectedstatusforgender"
                color="#F99D20"
                class="text"
                clearable
                outlined
                style="
                  min-width: 140px;
                  border-radius: 5px;
                  margin-top: 0.2rem;
                  margin-right: 1rem;
                "
                dense
              >
                <v-icon
                  slot="append"
                  class="v-icon notranslate mdi mdi-chevron-down theme--light"
                />
              </v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2" lg="2">
              <v-autocomplete
                :items="exportItem"
                item-text="exptxt"
                item-value="expval"
                color="#F99D20"
                class="text"
                v-model="expitgender"
                clearable
                outlined
                :placeholder="$t(`Export`)"
                style="
                  min-width: 140px;
                  border-radius: 5px;
                  margin-top: 1.8rem;
                  margin-right: 1rem;
                "
                dense
                v-on:change="changetoexportforgender(expitgender)"
                :disabled="permission.export"
              >
                <v-icon
                  slot="append"
                  class="v-icon notranslate mdi mdi-chevron-down theme--light"
                />
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <div class="container">
              <bar-chart
                justify="center"
                class="chartbox1"
                :chartData="chartData1"
                :options="options1"
              ></bar-chart>
              <!-- <line-chart
          :chartData="chartdata"
          :options="options"
          
        ></line-chart> -->
            </div>
          </v-row>
          <div class="mt-5">
            <v-data-table
              style="margin-left: 2rem; margin-right: 2rem"
              :items="GenderList"
              hide-default-footer
              hide-default-header
              :page.sync="page1"
              :items-per-page="itemsPerPage1"
              @page-count="pageCount1 = $event"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th>{{ $t("EmployeeID") }}</th>
                    <th>{{ $t("employee_name") }}</th>
                    <!-- <th>{{ $t("age") }}</th> -->
                    <th>{{ $t("gender") }}</th>
                    <th>{{ $t("position") }}</th>
                    <th>{{ $t("department") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.employee_Code }}</td>
                  <td>{{ item.employee_Name }}</td>
                  <!-- <td>{{ getAge(item.birthDate) }}</td> -->
                  <td v-show="item.isitmale">{{ $t("Male") }}</td>
                  <td v-show="!item.isitmale">{{ $t("Female") }}</td>
                  <td>{{ item.position_Name }}</td>
                  <td>{{ item.department_Name }}</td>
                </tr>
              </template>
            </v-data-table>
            <div v-if="GenderList.length != 0" class="text-right">
              <PaginationComponent
                  :page.sync="page1"
                  :pageCount="pageCount1"
                  :itemsPerPage.sync="itemsPerPage1"
              />
            </div>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs>
    </div>
    
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

    <v-dialog v-model="printDialog" max-width="100%">
      <v-card style="min-width: 100%">
        <div class="canvas_div_pdf">
          <v-card-text class="print-break-page" ref="content">
            <div style="margin-left: 1rem">
              <v-row class="pl-md-0 mt-0 pt-2">
                <v-col cols="12" xs="12" sm="6" md="4" lg="4">
                  <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                  <p
                    class="headtext"
                    style="font-size: 28px; font-family: 'Kanit', sans-serif"
                  >
                    {{ $t("employeesbyageandgender") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  class="mr-0 pr-0"
                  cols="3"
                  xs="6"
                  sm="2"
                  md="1"
                  lg="1"
                  xl="1"
                >
                  <v-img
                    class="ggg"
                    style="padding: -10px; padding-right: 0px"
                    @click="(drawer = !drawer), (isExpand = !isExpand)"
                    :src="logo.image"
                    max-height="60"
                    max-width="60"
                    min-width="60"
                    min-height="60"
                    contain
                  />
                </v-col>
                <v-col
                  style="min-width: 150px"
                  cols="4"
                  xs="6"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="mt-2 ml-0 pl-0"
                >
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: 0px;
                      font-size: 18px;
                      color: #0fa7d8 !important;
                    "
                  >
                    Optimistic
                  </p>
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: -5px;
                      font-size: 12px;
                      color: #f99d20 !important;
                    "
                  >
                    Powered by Optimistic
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-md-0 mt-0" style="margin-right: 10px">
                <v-col class="mt-0" cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-0">{{ $t("Dates") }}</p>
                  <p class="headtext">
                    {{ DateFormatonTopbet(chartStartDate) }} -
                    {{ DateFormatonTopbet(chartEndDate) }}
                  </p>
                </v-col>
              </v-row>
              <hr />
            </div>
            <v-row class="backGG mt-4" justify="center">
              <bar-chart
                class="chartbox"
                justify="center"
                :chartData="chartData"
                :options="options"
              ></bar-chart>
            </v-row>
            <p
              style="
                font-size: 18px;
                font-family: 'Kanit', sans-serif;
                margin-left: 20px;
                margin-top: 20px;
                color: #f99d20;
              "
            >
              {{ $t("employeesbyageandgender") }} ({{ TerminationList.length }})
            </p>
            <div id="middlecol">
              <v-data-table
                style="margin-left: 2rem; margin-right: 2rem"
                :items="TerminationList"
                hide-default-footer
                hide-default-header
               
                :items-per-page="TerminationList.length"
                
              >
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th>{{ $t("EmployeeID") }}</th>
                      <th>{{ $t("employee_name") }}</th>
                      <th>{{ $t("age") }}</th>

                      <th>{{ $t("position") }}</th>
                      <th>{{ $t("department") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.employee_Code }}</td>
                    <td>{{ item.employee_Name }}</td>
                    <td>{{ getAge(item.birthDate) }}</td>
                    <td>{{ item.position_Name }}</td>
                    <td>{{ item.department_Name }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-3 text-capitalize fix-btn"
            width="150"
            max-height="35"
            text
            @click="
              printDialog = false;
              expit = '';
            "
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-3 text-capitalize white--text fix-btn"
            width="150"
            max-height="35"
            style="font-family: kanit, Regular; font-weight: normal"
            @click="downloadPDF()"
          >
            {{ $t("Print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="printDialogforgender" max-width="100%">
      <v-card style="min-width: 100%">
        <div class="canvas_div_pdf1">
          <v-card-text class="print-break-page" ref="content">
            <div style="margin-left: 1rem">
              <v-row class="pl-md-0 mt-0 pt-2">
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                  <p
                    class="headtext"
                    style="font-size: 28px; font-family: 'Kanit', sans-serif"
                  >
                    {{ $t("employeesbyageandgender") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  class="mr-0 pr-0"
                  cols="3"
                  xs="6"
                  sm="2"
                  md="1"
                  lg="1"
                  xl="1"
                >
                  <v-img
                    class="ggg"
                    style="padding: -10px; padding-right: 0px"
                    @click="(drawer = !drawer), (isExpand = !isExpand)"
                    :src="logo.image"
                    max-height="60"
                    max-width="60"
                    min-width="60"
                    min-height="60"
                    contain
                  />
                </v-col>
                <v-col
                  style="min-width: 150px"
                  cols="4"
                  xs="6"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="mt-2 ml-0 pl-0"
                >
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: 0px;
                      font-size: 18px;
                      color: #0fa7d8 !important;
                    "
                  >
                    Optimistic
                  </p>
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: -5px;
                      font-size: 12px;
                      color: #f99d20 !important;
                    "
                  >
                    Powered by Optimistic
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-md-0 mt-0" style="margin-right: 10px">
                <v-col class="mt-0" cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-0">{{ $t("Dates") }}</p>
                  <p class="headtext">
                    {{ DateFormatonTopbet(GenderStartDate) }} -
                    {{ DateFormatonTopbet(GenderStartDate) }}
                  </p>
                </v-col>
              </v-row>
              <hr />
            </div>
            <v-row class="backGG mt-4" justify="center">
              <bar-chart
                justify="center"
                class="chartbox1"
                :chartData="chartData1"
                :options="options1"
              ></bar-chart>
            </v-row>
            <p
              style="
                font-size: 18px;
                font-family: 'Kanit', sans-serif;
                margin-left: 20px;
                margin-top: 20px;
                color: #f99d20;
              "
            >
              {{ $t("employeesbyageandgender") }} ({{ GenderList.length }})
            </p>
            <div id="middlecol">
              <v-data-table
                style="margin-left: 2rem; margin-right: 2rem"
                :items="GenderList"
                hide-default-footer
                hide-default-header
               
                :items-per-page="GenderList.length"
               
              >
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th>{{ $t("EmployeeID") }}</th>
                      <th>{{ $t("employee_name") }}</th>
                      <th>{{ $t("gender") }}</th>

                      <th>{{ $t("position") }}</th>
                      <th>{{ $t("department") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.employee_Code }}</td>
                    <td>{{ item.employee_Name }}</td>
                    <td v-show="item.isitmale">{{ $t("Male") }}</td>
                    <td v-show="!item.isitmale">{{ $t("Female") }}</td>
                    <td>{{ item.position_Name }}</td>
                    <td>{{ item.department_Name }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-3 text-capitalize fix-btn"
            width="150"
            max-height="35"
            text
            @click="
              printDialogforgender = false;
              expitgender = '';
            "
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-3 text-capitalize white--text fix-btn"
            width="150"
            max-height="35"
            style="font-family: kanit, Regular; font-weight: normal"
            @click="downloadPDFforgender()"
          >
            {{ $t("Print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BarChart from "@/components/BarChart";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import $ from "jquery";
import DatePicker from "vue2-datepicker";
import axios from "axios";
import moment from "moment";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import "vue2-datepicker/index.css";
import CheckViewExport from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components: {
    BarChart,
    DatePicker,
    LoadingDialog,
    PaginationComponent
  },
  mixins: [CheckViewExport],
  data() {
    return {
      permission: {
        export: true,
      },
      url: enurl.apiUrl,
      LoadingDialog: false,
      agetab: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      page1: 1,
      pageCount1: 0,
      itemsPerPage1: 10,
      chartStartDate: null,
      chartEndDate: new Date(),
      todaydate: new Date(),
      sheetTitle: null,
      expit: "",
      GenderStartDate: null,
      GenderEndDate: new Date(),
      sheetTitleforgender: null,
      expitgender: "",
      selectedstatusforage: 0,
      selectedstatusforgender: 0,
      showalldateforage: false,
      disableagedate: false,
      showalldateforgender: false,
      disablegenderdate: false,
      logo: {
        image: require("@/assets/images/logo@2x.png"),
      },
      exportItem: [
        { expval: 1, exptxt: "Excel" },
        { expval: 2, exptxt: "PDF" },
        { expval: 3, exptxt: "CSV" },
      ],
      printDialog: false,
      printDialogforgender: false,
      agestatusitems: [
        { text: "Show All", val: 0 },
        { text: "Active", val: 1 },
        { text: "InActive", val: 2 },
      ],
      genderstatusitems: [
        { text: "Show All", val: 0 },
        { text: "Active", val: 1 },
        { text: "InActive", val: 2 },
      ],
      TerminationList: [],
      GenderList: [],
      chartData: {
        labels: [
          "20-25y",
          "26-30y",
          "31-35y",
          "36-40y",
          "41-45y",
          "46-50y",
          "Above 50y",
        ],
        datasets: [
          {
            borderWidth: 1,

            backgroundColor: "#F89D1F",
            barPercentage: 1,
            pointBorderColor: "red",
            data: [0, 0, 0, 0, 0],
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                reverse: false,
                min: 0,
                max: 250,
                stepSize: 50,
              },
              gridLines: {
                drawBorder: true,
                lineWidth: 0,
                borderWidth: 200,
              },
              scaleLabel: {
                display: true,
                labelString: "No. of Employees",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: true,
                lineWidth: 0,
              },
              scaleLabel: {
                display: true,
                labelString: "Age Groups",
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,

        maintainAspectRatio: false,
      },
      chartData1: {
        labels: ["Male", "Female"],
        datasets: [
          {
            borderWidth: 1,
            barThickness: 80,

            pointBorderColor: "red",
            data: [0, 0],
            backgroundColor: ["#0FA7D8", "#F89D1F"],
          },
        ],
      },
      options1: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                reverse: false,
                min: 0,
                max: 250,
                stepSize: 50,
              },
              gridLines: {
                drawBorder: true,
                lineWidth: 0,
                borderWidth: 200,
              },
              scaleLabel: {
                display: true,
                labelString: "No. of Employees",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: true,
                lineWidth: 0,
              },
              scaleLabel: {
                display: true,
                labelString: "Gender",
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,

        maintainAspectRatio: false,
      },
    };
  },
  watch: {
    showalldateforage(v) {
      if (v == true) {
        this.disableagedate = true;
      } else {
        this.disableagedate = false;
      }
      this.GetAgelist();
    },
    selectedstatusforage(v) {
      if (v == 0) {
        this.GetAgelist();
      } else {
        this.GetAgelist();
      }
    },
    showalldateforgender(v) {
      if (v == true) {
        this.disablegenderdate = true;
      } else {
        this.disablegenderdate = false;
      }
      this.GetGenderList();
    },
    selectedstatusforgender(v) {
      if (v == 0) {
        this.GetGenderList();
      } else {
        this.GetGenderList();
      }
    },
    chartStartDate(newStartDate) {
      if (new Date(newStartDate) > new Date(this.chartEndDate)) {
        this.chartEndDate = null;
      }
    }
  },
  mounted() {
    let self = this;
    self.permission = self.CheckViewExport(176, 177);
    self.chartStartDate = new Date();
    self.chartStartDate.setDate(1);
    self.chartStartDate.setMonth(0);
    self.chartData;
    self.GenderStartDate = new Date();
    self.GenderStartDate.setDate(1);
    self.GenderStartDate.setMonth(0);
    self.chartData1;
    self.GetAgelist();
    self.GetGenderList();
  },
  methods: {
    back() {
      this.$router.push(`/employmentreport`).catch(()=>{});
    },
    allowedEndDates(date) {
      if (!this.chartStartDate) return true;

      const startDate = new Date(this.chartStartDate);
      const endDate = new Date(date);

      return endDate >= startDate;
    },
    DateFormatonTopbet(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${year}-${month}-${day}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    DateFormatonTop(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${month}/${day}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    changetab(e) {
      if (e == "age") {
        this.GetAgelist();
      }
      if (e == "gender") {
        this.GetGenderList();
      }
    },
    TerDateFormat(value) {
      let temp = new Date(value + "Z");
      let day = temp.getDate().toString().padStart(2, 0);
      let output = `${day}`;
      return output;
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    GetAgelist() {
      try {
        let self = this;
        let startyear = null;
        let endyear = null;
        let company_ID = store.state.companyID;
        self.TerminationList = [];
        self.expit = "";
        self.expitgender = "";
        if (self.chartStartDate != null || self.chartEndDate != null) {
          let tempsdate = self.chartStartDate.setHours(0);
          self.chartStartDate.setMinutes(0);
          self.chartStartDate.setSeconds(0);
          startyear = moment(tempsdate).utc().format("DD/MM/YYYY");
          let tempedate = self.chartEndDate.setHours(0);
          self.chartEndDate.setMinutes(0);
          self.chartEndDate.setSeconds(0);
          endyear = moment(tempedate).utc().format("DD/MM/YYYY");
        }
        let url = `${self.url}Addition/GetAgelist?companyID=${company_ID}`;
        if (startyear != null) {
          url += `&startDate=${startyear}`;
        }
        if (endyear != null) {
          url += `&endDate=${endyear}`;
        }
        url += `&showalldate=${self.showalldateforage}`;

        self.LoadingDialog = true;
        axios.get(url).then(function (response) {
          if (response.data.data == 0 || response.data.data == null) {
            self.TerminationList = [];
          }
          let templog = response.data.data;

          // self.selectedstatusforage=1;
          if (self.selectedstatusforage != 0) {
            if (self.selectedstatusforage == 1) {
              self.TerminationList = templog.filter((item) => item.status == 1);
            } else {
              self.TerminationList = templog.filter((item) => item.status == 2);
            }
          } else {
            self.TerminationList = templog;
          }
          // .filter(
          //   (d) =>
          //     self.TerDateFormat(d.termination_Date) >=
          //       self.chartStartDate.getDate() &&
          //     self.TerDateFormat(d.termination_Date) <=
          //       self.chartEndDate.getDate()
          // );
          let agelist = [];
          let ageunder25 = self.TerminationList.filter(
            (item) => item.age <= 25 && item.age >= 20
          );
          agelist.push(ageunder25.length);
          let ageabove25 = self.TerminationList.filter(
            (item) => item.age <= 30 && item.age >= 26
          );
          agelist.push(ageabove25.length);
          let ageabove30 = self.TerminationList.filter(
            (item) => item.age <= 35 && item.age >= 31
          );
          agelist.push(ageabove30.length);
          let ageabove35 = self.TerminationList.filter(
            (item) => item.age <= 36 && item.age >= 40
          );
          agelist.push(ageabove35.length);
          let ageabove40 = self.TerminationList.filter(
            (item) => item.age <= 45 && item.age >= 41
          );
          agelist.push(ageabove40.length);
          let ageabove45 = self.TerminationList.filter(
            (item) => item.age <= 50 && item.age >= 46
          );
          agelist.push(ageabove45.length);
          let ageabove50 = self.TerminationList.filter((item) => item.age > 50);
          agelist.push(ageabove50.length);
          let barDataSets = [];
          barDataSets.push({
            data: agelist,
            borderWidth: 1,

            backgroundColor: "#F89D1F",
            barPercentage: 1,
            pointBorderColor: "red",
          });
          self.chartData = {
            labels: [
              "20-25y",
              "26-30y",
              "31-35y",
              "36-40y",
              "41-45y",
              "46-50y",
              "Above 50y",
            ],
            datasets: barDataSets,
          };

          // self.chartData.datasets[0].data.push(1);
          // this.datasets.data[0]=1;
          //  self.renderChart(self.data, self.options);
          self.LoadingDialog = false;
        });
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },
    GetGenderList() {
      try {
        let self = this;
        let startyear = null;
        let endyear = null;
        let company_ID = store.state.companyID;
        self.GenderList = [];
        self.expit = "";
        self.expitgender = "";
        if (self.GenderStartDate != null || self.GenderEndDate != null) {
          let tempsdate = self.GenderStartDate.setHours(0);
          self.GenderStartDate.setMinutes(0);
          self.GenderStartDate.setSeconds(0);
          startyear = moment(tempsdate).utc().format("DD/MM/YYYY");
          let tempedate = self.GenderEndDate.setHours(0);
          self.GenderEndDate.setMinutes(0);
          self.GenderEndDate.setSeconds(0);
          endyear = moment(tempedate).utc().format("DD/MM/YYYY");
        }
        let url = `${self.url}Addition/GetAgelist?companyID=${company_ID}`;
        if (startyear != null) {
          url += `&startDate=${startyear}`;
        }
        if (endyear != null) {
          url += `&endDate=${endyear}`;
        }
        url += `&showalldate=${self.showalldateforgender}`;

        self.LoadingDialog = true;
        axios.get(url).then(function (response) {
          if (response.data.data == 0 || response.data.data == null) {
            self.GenderList = [];
          }
          let templog = response.data.data;

          if (self.selectedstatusforgender != 0) {
            if (self.selectedstatusforgender == 1) {
              templog = templog.filter((item) => item.status == 1);
            } else {
              templog = templog.filter((item) => item.status == 2);
            }
          }

          let genderlist = [];
          let Male = templog.filter((item) => item.gender == "Male");
          genderlist.push(Male.length);
          let Female = templog.filter((item) => item.gender == "Female");
          genderlist.push(Female.length);

          //self.GenderList = templog;
          for (let i = 0; i < templog.length; i++) {
            let ye = true;
            if (templog[i].gender == "Male") {
              ye = true;
            } else {
              ye = false;
            }
            let Lists = {
              ...templog[i],
              isitmale: ye,
            };
            self.GenderList.push(Lists);
          }
          self.LoadingDialog = false;

          let barDataSets = [];
          barDataSets.push({
            data: genderlist,
            borderWidth: 1,

            backgroundColor: ["#0FA7D8", "#F89D1F"],
            barPercentage: 1,
            pointBorderColor: "red",
          });
          self.chartData1 = {
            labels: ["Male", "Female"],
            datasets: barDataSets,
          };
        });
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },
    changetoexport(v) {
      let self = this;
      self.LoadingDialog = true;
      if (v == 1) {
        // Excel
        if (self.TerminationList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.TerminationList) {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["อายุ"]: this.getAge(v.birthDate),
                ["ตำแหน่ง"]: v.position_Name,
                ["แผนก"]: v.department_Name,
              });
            }
          }
          else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.TerminationList) {
                wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["อายุ"]: this.getAge(v.birthDate),
                ["ตำแหน่ง"]: v.position_Name,
                ["แผนก"]: v.department_Name,
              });
              }
            } else {
              for (let v of self.TerminationList) {
                 wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.employee_Name,
                ["Age"]: this.getAge(v.birthDate),
                ["Position"]: v.position_Name,
                ["Department"]: v.department_Name,
              });
              }
            }
          }
          // for (let v of self.TerminationList) {
          //   if (localStorage.Templang == "ประเทศไทย") {
          //     wsdata.push({
          //       ["รหัสพนักงาน"]: v.employee_Code,
          //       ["ชื่อพนักงาน"]: v.employee_Name,
          //       ["อายุ"]: this.getAge(v.birthDate),
          //       ["ตำแหน่ง"]: v.position_Name,
          //       ["แผนก"]: v.department_Name,
          //     });
          //   } else {
          //     wsdata.push({
          //       ["Employee ID"]: v.employee_Code,
          //       ["Employee Name"]: v.employee_Name,
          //       ["Age"]: this.getAge(v.birthDate),
          //       ["Position"]: v.position_Name,
          //       ["Department"]: v.department_Name,
          //     });
          //   }
          // }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Age and Gender Report`;
          self.sheetTitle = `Age and Gender`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      } else if (v == 2) {
        // PDF
        self.printDialog = true;
        self.todaydate = new Date();
        self.LoadingDialog = false;
      } else {
        //CSV
        if (self.TerminationList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.TerminationList) {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["อายุ"]: this.getAge(v.birthDate),
                ["ตำแหน่ง"]: v.position_Name,
                ["แผนก"]: v.department_Name,
              });
            }
          }
          else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.TerminationList) {
                wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["อายุ"]: this.getAge(v.birthDate),
                ["ตำแหน่ง"]: v.position_Name,
                ["แผนก"]: v.department_Name,
              });
              }
            } else {
              for (let v of self.TerminationList) {
                 wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.employee_Name,
                ["Age"]: this.getAge(v.birthDate),
                ["Position"]: v.position_Name,
                ["Department"]: v.department_Name,
              });
              }
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Age and Gender Report`;
          self.sheetTitle = `Age and Gender`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.csv`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      }
    },
    GenderTrans(v) {
      if (v == "Male") {
        return "ชาย";
      } else {
        return "หญิง";
      }
    },
    changetoexportforgender(v) {
      let self = this;
      self.LoadingDialog = true;
      if (v == 1) {
        // Excel
        if (self.GenderList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
           if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.GenderList) {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                // ["อายุ"]: this.getAge(v.birthDate),
                 ["เพศ"]: self.GenderTrans(v.gender),
                ["ตำแหน่ง"]: v.position_Name,
                ["แผนก"]: v.department_Name,
              });
            }
          }
          else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.GenderList) {
                wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                // ["อายุ"]: this.getAge(v.birthDate),
                 ["เพศ"]: self.GenderTrans(v.gender),
                ["ตำแหน่ง"]: v.position_Name,
                ["แผนก"]: v.department_Name,
              });
              }
            } else {
              for (let v of self.GenderList) {
                 wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.employee_Name,
               
                ["Gender"]: v.gender,
                ["Position"]: v.position_Name,
                ["Department"]: v.department_Name,
              });
              }
            }
          }
         
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Gender Report`;
          self.sheetTitleforgender = `Gender`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitleforgender}`);
          XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      } else if (v == 2) {
        // PDF
        self.printDialogforgender = true;
        self.todaydate = new Date();
        self.LoadingDialog = false;
      } else {
        //CSV
        if (self.GenderList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
           if (localStorage.Templang == "ประเทศไทย") {
            for (let v of self.GenderList) {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                // ["อายุ"]: this.getAge(v.birthDate),
                 ["เพศ"]: self.GenderTrans(v.gender),
                ["ตำแหน่ง"]: v.position_Name,
                ["แผนก"]: v.department_Name,
              });
            }
          }
          else {
            if (sessionStorage.flag == "Thai") {
              for (let v of self.GenderList) {
                wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                // ["อายุ"]: this.getAge(v.birthDate),
                 ["เพศ"]: self.GenderTrans(v.gender),
                ["ตำแหน่ง"]: v.position_Name,
                ["แผนก"]: v.department_Name,
              });
              }
            } else {
              for (let v of self.GenderList) {
                 wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.employee_Name,
               
                ["Gender"]: v.gender,
                ["Position"]: v.position_Name,
                ["Department"]: v.department_Name,
              });
              }
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Gender Report`;
          self.sheetTitleforgender = `Gender`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitleforgender}`);
          XLSX.writeFile(wb, `${tempsheetname}.csv`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      }
    },
    downloadPDF() {
      html2canvas($(".canvas_div_pdf")[0], {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save("Age Report.pdf");
      });
    },
    downloadPDFforgender() {
      html2canvas($(".canvas_div_pdf1")[0], {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save("Gender Report.pdf");
      });
    },
  },
};
</script>
<style scoped>
::v-deep .v-window{
  overflow: visible!important;
}
.chartbox {
  width: 1000px;
  height: 300px;

  background-color: #f8f8f8;
}
.chartbox1 {
  width: 500px;
  height: 340px;

  background-color: #f8f8f8;
}
@media only screen and (max-width: 850px) {
  .chartbox {
    width: 220px !important;
  }
  .backGG {
    margin-right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .chartbox {
    width: 600px !important;
  }
  .backGG {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 850px) {
  .chartbox1 {
    width: 220px !important;
  }
  .backGG {
    margin-right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .chartbox1 {
    width: 600px !important;
  }
  .backGG {
    margin-right: 0px;
  }
}
/* .container {
    max-width: 1570px;
} */
.container {
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  height: 350px;
  width: 100;
  margin-left: 2rem;

  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}
.backGG {
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}
.card {
  margin-bottom: 5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  height: auto;
}
.ddl {
  width: 150px;
  margin-right: 0 !important;
}
>>> .mx-input {
  border: 2px solid #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
}
v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
}
.active {
  cursor: pointer !important;
}
.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}
>>> .mx-input {
  border: 2px solid #f99d20 !important;
  color: #f99d20 !important;
}
>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
</style>