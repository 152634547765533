<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <p text style="font-size: 25px; margin-top: 15px">{{ $t("OT") }}</p>
    </div>
    <div style="height: auto" class="mt-9">
      <v-card min-height="60vh">
        <div style="margin-left: 2%; margin-right: 2%">
          <br />
          <div>
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                <v-row>
                  <div
                    class="mr-8 d-flex"
                    style="
                      background-color: #f99d20;
                      border-radius: 5px;
                      margin-left: 12px;
                    "
                  >
                    <v-autocomplete
                      class="selectboxs minizebox ml-4 mt-1 mr-4 mb-1 pl-0"
                      v-model="todaymonths"
                      height="34px"
                      single-line
                      :items="monthNames"
                      item-text="txt"
                      item-value="val"
                      background-color="#FFFFFF"
                      return-object
                      persistent-hint
                      v-on:change="ChangeMonth(`${todaymonths.val}`)"
                    ></v-autocomplete>
                    <date-picker
                      v-model="todayyears"
                      type="year"
                      color="#0FA7D8"
                      class="selectboxs minizebox ml-1 mr-4 mt-1 mb-1 pl-0 today_years"
                      v-on:change="ChangeYear(todayyears)"
                    >
                      <template slot="icon-calendar">
                        <img
                          src="@/assets/images/down1@2x.png"
                          style="width: 20px; height: 20px"
                          class="pl-2"
                        />
                      </template>
                    </date-picker>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="5">
                <div class="d-flex">
                  <div class="mr-4 mt-2">
                    <p>{{ $t("status") }}</p>
                  </div>
                  <div>
                    <v-autocomplete
                      v-model="itemInner"
                      :items="status"
                      outlined
                      rounded
                      dense
                      color="#F99D20"
                    ></v-autocomplete>
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="3" md="2" lg="2" xl="2">
                <v-btn
                  color="#F99D20"
                  @click="OTapprovedialog = true"
                  class="white--text mr-5 text-capitalize btnres"
                  block
                  max-height="35"
                  :disabled="permission.add"
                  >{{ $t("newRequest") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>

          <div class="mt-5">
            <v-data-table
              :headers="headers"
              :items="itemFilter"
              hide-default-footer
              hide-default-header
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <!-- <th>Ot Date</th> -->
                    <th>{{ $t("oTHours") }}</th>
                    <th>{{ $t("oTStartDate") }}</th>
                    <th>{{ $t("oTEndDate") }}</th>
                    <th>{{ $t("purposeofOT") }}</th>
                    <th>{{ $t("requestedDate") }}</th>
                    <th>{{ $t("status") }}</th>
                    <th>{{ $t("approver") }}</th>

                    <th class="pl-6">{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <!-- <td>{{ formatDate(item.requested_Date) }}</td> -->

                  <td>{{ item.oT_Hours }}</td>
                  <td>{{ formatDate(item.start_Date) }}</td>
                  <td>{{ formatDate(item.end_Date) }}</td>
                  <td>{{ item.purpose_OT }}</td>
                  <td>{{ convertUTCDateToLocalDate(item.requested_Date) }}</td>
                  <td
                    :style="{
                      color:
                        item.status == 'Approved'
                          ? '#22A534'
                          : item.status == 'Waiting Approval'
                          ? '#F99D20'
                          : '#FF6565',
                    }"
                  >
                    {{ item.status }}
                  </td>
                  <td>{{ item.approvername }}</td>
                  <td>
                    <v-btn
                      color="#F99D20"
                      @click="Detailrequest(item)"
                      class="white--text text-capitalize"
                      max-width="120"
                      max-height="35"
                      >{{ $t("view") }}</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <div class="text-right">
            <v-row justify="end" class="ml-5 mt-5 mr-5">
              <v-col cols="12" md="3">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  color="#FFCD2C"
                  :total-visible="6"
                ></v-pagination>
              </v-col>
              <v-col cols="12" md="2" class="mt-1">
                <v-select
                  dense
                  style="width: 120px; float: right"
                  solo
                  label="10/page"
                  v-model="itemsPerPage"
                  :items="items"
                  @input="itemsPerPage = parseInt($event, 10)"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- requestdialog -->
        <v-dialog v-model="OTapprovedialog" persistent max-width="580px">
          <v-card style="border-radius: 15px" class="pb-4">
            <v-card-title>
              <span>{{ $t("oTRequests") }}</span>
              <v-spacer></v-spacer>
              <v-btn @click="closerequestdialog()" icon>
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("employeeName") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    autocomplete="off"
                    v-model="requestOT.empname"
                    readonly
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <div class="mb-2 ml-2">
                    <input
                      v-model="requestOT.wholeday"
                      @click="showstartdate()"
                      type="checkbox"
                    />
                    <label class="ml-3">{{ $t("wholeDay") }}</label>
                  </div>
                </v-col>
              </v-row>
              <v-row v-show="showandhide" class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTDate") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        single-line
                        autocomplete="off"
                        dense
                        outlined
                        v-model="computedDateFormatted1"
                        append-icon="mdi-calendar-month-outline"
                        color="#F99D20"
                        persistent-hint
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="requestOT.OTdate"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row v-show="!showandhide" class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTStartDate") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu7"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        single-line
                        autocomplete="off"
                        dense
                        outlined
                        v-model="computedDateFormatted3"
                        append-icon="mdi-calendar-month-outline"
                        color="#F99D20"
                        persistent-hint
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="requestOT.OTstartdate"
                      no-title
                      @input="menu7 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row v-show="!showandhide" class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTEndDate") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu8"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        single-line
                        autocomplete="off"
                        dense
                        outlined
                        v-model="computedDateFormatted4"
                        append-icon="mdi-calendar-month-outline"
                        color="#F99D20"
                        persistent-hint
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="requestOT.OTenddate"
                      no-title
                      @input="menu8 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTStartTime") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        v-model="requestOT.OTstart"
                        @change="changetime(requestOT.OTstart, requestOT.OTend)"
                        append-icon="access_time"
                        single-line
                        outlined
                        dense
                        color="#F99D20"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="requestOT.OTstart"
                      no-title
                      fluid
                      @click:minute="$refs.menu2.save(requestOT.OTstart)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTEndTime") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time2"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        autocomplete="off"
                        v-model="requestOT.OTend"
                        @change="changetime(requestOT.OTstart, requestOT.OTend)"
                        append-icon="access_time"
                        single-line
                        outlined
                        dense
                        color="#F99D20"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu3"
                      v-model="requestOT.OTend"
                      no-title
                      fluid
                      @click:minute="$refs.menu3.save(requestOT.OTend)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTHours") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    v-model="requestOT.OThours"
                    autocomplete="off"
                    auto
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("purposeofOT") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    autocomplete="off"
                    v-model="requestOT.purposeOT"
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("approvalSentTo") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    autocomplete="off"
                    v-model="requestOT.approver"
                    :value="requestOT.approverID"
                    readonly
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{$t('status')}}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field height="10px" color="#F99D20" single-line outlined dense required></v-text-field>
                </v-col>
              </v-row>-->
            </v-card-text>
            <div class="ml-11 mr-11">
              <v-row justify="center">
                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-btn
                    color="#F99D20"
                    class="white--text mr-5 text-capitalize btnres"
                    block
                    max-height="35"
                    @click="RequestedOT()"
                    >{{ $t("sendRequest") }}</v-btn
                  >
                </v-col>
                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-btn
                    color="#F99D20"
                    class="white--text mr-5 text-capitalize"
                    block
                    max-height="35"
                    @click="SavedraftOT()"
                    >{{ $t("saveDraft") }}</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-dialog>
        <!-- viewdialog -->
        <v-dialog v-model="viewdialog" persistent max-width="580px">
          <v-card style="border-radius: 15px" class="pb-4">
            <v-card-title>
              <span>{{ $t("oTRequests") }}</span>
              <v-spacer></v-spacer>
              <v-btn @click="hidedetaildialog()" icon>
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("employeeName") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    :disabled="disablestatus"
                    v-model="EditOT.empname"
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <div class="mb-2 ml-2">
                    <input
                      v-model="EditOT.wholeday"
                      @click="showeditdate()"
                      :disabled="disableviewdata"
                      type="checkbox"
                    />
                    <label class="ml-3">{{ $t("wholeDay") }}</label>
                  </div>
                </v-col>
              </v-row>
              <v-row v-show="editshowandhide" class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTDate") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu4"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="disableviewdata"
                        single-line
                        dense
                        outlined
                        v-model="computedDateFormatted2"
                        append-icon="mdi-calendar-month-outline"
                        color="#F99D20"
                        persistent-hint
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="EditOT.OTdate"
                      no-title
                      @input="menu4 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row v-show="!editshowandhide" class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTStartDate") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu9"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="disableviewdata"
                        single-line
                        autocomplete="off"
                        dense
                        outlined
                        v-model="computedDateFormatted5"
                        append-icon="mdi-calendar-month-outline"
                        color="#F99D20"
                        persistent-hint
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="EditOT.OTstartdate"
                      no-title
                      @input="menu9 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row v-show="!editshowandhide" class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTEndDate") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu10"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="disableviewdata"
                        single-line
                        autocomplete="off"
                        dense
                        outlined
                        v-model="computedDateFormatted6"
                        append-icon="mdi-calendar-month-outline"
                        color="#F99D20"
                        persistent-hint
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="EditOT.OTenddate"
                      no-title
                      @input="menu10 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTStartTime") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time3"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="disableviewdata"
                        @change="changetimeofview(viewOtstart, viewOtend)"
                        v-model="viewOtstart"
                        append-icon="access_time"
                        single-line
                        outlined
                        dense
                        color="#F99D20"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu5"
                      v-model="viewOtstart"
                      no-title
                      fluid
                      @click:minute="$refs.menu5.save(viewOtstart)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTEndTime") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    ref="menu6"
                    v-model="menu6"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time4"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="disableviewdata"
                        @change="changetimeofview(viewOtstart, viewOtend)"
                        v-model="viewOtend"
                        append-icon="access_time"
                        single-line
                        outlined
                        dense
                        color="#F99D20"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu6"
                      v-model="viewOtend"
                      no-title
                      fluid
                      @click:minute="$refs.menu6.save(viewOtend)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("oTHours") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    :disabled="disableviewdata"
                    v-model="EditOT.OThours"
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("purposeofOT") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    :disabled="disableviewdata"
                    v-model="EditOT.purposeOT"
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("approvalSentTo") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    :disabled="disableviewdata"
                    v-model="EditOT.approver"
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">{{ $t("status") }}</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    :disabled="disablestatus"
                    v-model="EditOT.status"
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <div class="ml-11 mr-11">
              <v-row justify="center">
                <v-col cols="12" xs="12" sm="4" md="4">
                  <div v-show="!showeditbtn">
                    <v-btn
                      v-show="!hideeditbtn"
                      color="#F99D20"
                      class="white--text mr-5 text-capitalize btnres"
                      block
                      max-height="35"
                      :disabled="disableeditbtn || permission.edit"
                      @click="showdetaildata()"
                      >{{ $t("edit") }}</v-btn
                    >
                    <v-btn
                      v-show="hideeditbtn"
                      color="#F99D20"
                      class="white--text mr-5 text-capitalize btnres"
                      block
                      max-height="35"
                      :disabled="disableeditbtn || permission.edit"
                      @click="EditOTrequest()"
                      >{{ $t("save") }}</v-btn
                    >
                  </div>
                </v-col>
                <v-col v-show="!showeditbtn" cols="12" xs="12" sm="4" md="4">
                  <v-btn
                    color="#F99D20"
                    class="white--text text-capitalize btnres"
                    block
                    max-height="35"
                    :disabled="disablesendbtn || permission.add"
                    @click="SendOTrequest()"
                    >{{ $t("sendRequest") }}</v-btn
                  >
                </v-col>
                <v-col cols="12" xs="12" sm="4" md="4">
                  <v-btn
                    color="#FF0000"
                    class="white--text text-capitalize"
                    block
                    max-height="35"
                    @click="deleteOTrequest()"
                    :disabled="disalbecanclebtn"
                    >{{ $t("cancelRequest") }}</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-dialog>

        <!-- deletedialog -->
        <v-dialog v-model="Deletedialog" persistent max-width="532">
          <v-card style="border-radius: 15px">
            <v-card-title>
              <p style="margin-top: 10px; margin-bottom: -30px">
                Confirm to Cancel Request
              </p>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col align="center" cols="12">
                    <p style="font-size: 16px; color: #444444" class="pt-4">
                      Do you want to cancel these request?
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions style="margin-right: 3%" class="pb-6">
              <v-spacer></v-spacer>
              <v-btn
                class="mr-2 text-capitalize"
                width="120"
                max-height="35"
                @click="DeleteCancel"
                text
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                color="#FF2727"
                class="mr-2 white--text text-capitalize"
                width="120"
                max-height="35"
                @click="DeleteOTrequestlist()"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Loadingdialog -->
        <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
      </v-card>

      <v-dialog v-model="showOT" persistent max-width="600px">
        <v-card style="border-radius: 15px">
          <v-card-title>
            <span>OT request</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeNoti()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row class="pl-6">
              <v-col cols="3" class="deletepadd">
                <p class="mt-2">Employee Name</p>
              </v-col>
              <v-col cols="8" class="deletepadd">
                <v-text-field
                  :disabled="true"
                  height="5px"
                  color="#F99D20"
                  outlined
                  dense
                  v-model="OTDetail.empname"
                  single-line
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pl-6">
              <v-col cols="3" class="deletepadd">
                <div class="mb-2">
                  <input
                    v-model="OTDetail.wholeday"
                    :disabled="true"
                    type="checkbox"
                  />
                  <label class="ml-3">Whole day</label>
                </div>
              </v-col>
            </v-row>
            <div v-show="OTDetail.wholeday">
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT Start Date</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="true"
                        v-model="computedDateFormatted5"
                        height="10px"
                        color="#F99D20"
                        outlined
                        dense
                        single-line
                        append-icon="mdi-calendar-month-outline"
                        persistent-hint
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="OTDetail.OTstartdate"
                      no-title
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT End Date</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu4"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="true"
                        v-model="computedDateFormatted6"
                        height="10px"
                        color="#F99D20"
                        outlined
                        dense
                        single-line
                        append-icon="mdi-calendar-month-outline"
                        persistent-hint
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="OTDetail.OTenddate"
                      no-title
                      @input="menu4 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT Start Time</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="true"
                        v-model="OTDetail.OTstart"
                        append-icon="access_time"
                        single-line
                        outlined
                        dense
                        color="#F99D20"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu1"
                      v-model="OTDetail.OTstart"
                      no-title
                      fluid
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT End Time</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time2"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="true"
                        v-model="OTDetail.OTend"
                        append-icon="access_time"
                        single-line
                        outlined
                        dense
                        color="#F99D20"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="OTDetail.OTend"
                      no-title
                      fluid
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT Hours</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    :disabled="true"
                    v-model="OTDetail.OThours"
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div v-show="!OTDetail.wholeday">
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT Date</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="true"
                        v-model="computedDateFormatted7"
                        height="10px"
                        color="#F99D20"
                        outlined
                        dense
                        single-line
                        append-icon="mdi-calendar-month-outline"
                        persistent-hint
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="OTDetail.OTdate"
                      no-title
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT Start Time</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="true"
                        v-model="OTDetail.OTstart"
                        append-icon="access_time"
                        single-line
                        outlined
                        dense
                        color="#F99D20"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu1"
                      v-model="OTDetail.OTstart"
                      no-title
                      fluid
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT End Time</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time2"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="true"
                        v-model="OTDetail.OTend"
                        append-icon="access_time"
                        single-line
                        outlined
                        dense
                        color="#F99D20"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="OTDetail.OTend"
                      no-title
                      fluid
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="3" class="deletepadd">
                  <p class="mt-2">OT Hours</p>
                </v-col>
                <v-col cols="8" class="deletepadd">
                  <v-text-field
                    :disabled="true"
                    v-model="OTDetail.OThours"
                    height="10px"
                    color="#F99D20"
                    single-line
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-row class="pl-6">
              <v-col cols="3" class="deletepadd">
                <p class="mt-2">Purpose of OT</p>
              </v-col>
              <v-col cols="8" class="deletepadd">
                <v-text-field
                  :disabled="true"
                  v-model="OTDetail.purposeOT"
                  height="10px"
                  color="#F99D20"
                  single-line
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pl-6">
              <v-col cols="3" class="deletepadd">
                <p class="mt-2">Approval sent to</p>
              </v-col>
              <v-col cols="8" class="deletepadd">
                <v-text-field
                  :disabled="true"
                  v-model="OTDetail.approver"
                  height="10px"
                  color="#F99D20"
                  single-line
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pl-6">
              <v-col cols="3" class="deletepadd">
                <p class="mt-2">Status</p>
              </v-col>
              <v-col cols="8" class="deletepadd">
                <v-text-field
                  :disabled="true"
                  v-model="OTDetail.status"
                  height="10px"
                  color="#F99D20"
                  single-line
                  outlined
                  dense
                  required
                ></v-text-field>
                <!-- :style="{color : showOTrequest.status  == 'Approved' ? '#22A534':' #FF6565' }" -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import ReportTrace from "@/function/Report.js";
import moment from "moment";
import CheckViewAddEdit from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, ReportTrace, utils, CheckViewAddEdit],
  data: () => ({
    permission: {
      add: true,
      edit: true,
    },
    url: enurl.apiUrl,
    focus: new Date().toISOString().substr(0, 10),
    page: 1,
    OTapprovedialog: false,
    status: ["Waiting Approval", "Approved", "Denied"],
    viewdialog: false,
    pageCount: 15,
    itemsPerPage: 10,
    items: [10, 20, 30],
    todaymonths: null,
    todayyears: null,
    menu1: false,
    menu2: false,
    time1: null,
    Otstart: "",
    menu3: false,
    time2: null,
    menu4: false,
    viewdate: "",
    Otend: "",
    viewOtstart: "",
    viewOtend: "",
    menu5: false,
    menu6: false,
    time3: null,
    time4: null,
    menu7: false,
    menu8: false,
    menu9: false,
    menu10: false,
    LoadingDialog: false,
    tokenList: [],
    username: null,
    reportID: null,

    monthNames: [
      { val: 1, txt: "January" },
      { val: 2, txt: "February" },
      { val: 3, txt: "March" },
      { val: 4, txt: "April" },
      { val: 5, txt: "May" },
      { val: 6, txt: "June" },
      { val: 7, txt: "July" },
      { val: 8, txt: "August" },
      { val: 9, txt: "September" },
      { val: 10, txt: "October" },
      { val: 11, txt: "November" },
      { val: 12, txt: "December" },
    ],
    headers: [
      {
        text: "Ot Date",
        value: "Otdate",
        align: "center",
        sortable: false,
      },
      {
        text: "OT Hours",
        value: "Othour",
        align: "center",
        sortable: false,
      },
      {
        text: "Start Date",
        value: "startdate",
        align: "center",
        sortable: false,
      },
      {
        text: "End Date",
        value: "enddate",
        align: "center",
        sortable: false,
      },
      {
        text: "Purpose of OT",
        value: "purposeot",
        align: "center",
        sortable: false,
      },
      {
        text: "Requested Date",
        value: "requestdate",
        align: "center",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: "Approver",
        value: "approver",
        align: "center",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        align: "center",
        sortable: false,
      },
    ],
    itemInner: "Waiting Approval",
    desserts: [
      {
        Otdate: "5/04/2020",
        Othour: "5",
        startdate: "4/04/2020",
        enddate: "14/04/2020",
        purposeot: "Complete PO",
        requestdate: "10/04/2020",
        status: "Waiting for Approval",
        approver: "David",
      },
    ],
    approverlist: [],
    requestedlist: [],
    listrequest: [],
    OTdate: "",
    showandhide: true,
    editshowandhide: true,
    hideeditbtn: false,
    mystartdate: "",
    myenddate: "",
    disalbecanclebtn: false,
    disableeditbtn: false,
    disablesendbtn: false,
    disablestatus: true,
    showeditbtn: false,
    disableviewdata: true,
    Deletedialog: false,
    requestOT: {
      empname: "",
      OTdate: "",
      OTstartdate: "",
      OTenddate: "",
      OTstart: "",
      wholeday: false,
      OTend: "",
      OThours: "",
      purposeOT: null,
      approver: null,
      approverID: null,
    },
    requesttrue: false,
    EditOT: {
      OTrequest_ID: "",
      empname: "",
      OTdate: "",
      OTstartdate: "",
      OTenddate: "",
      OTstart: "",
      wholeday: false,
      OTend: "",
      OThours: "",
      purposeOT: null,
      approver: null,
      approverID: null,
      status: null,
      monthDay: 0,
    },
    showOT: false,
    OTDetail: {
      OTrequest_ID: "",
      empname: "",
      OTdate: "",
      OTstartdate: "",
      OTenddate: "",
      OTstart: "",
      wholeday: false,
      OTend: "",
      OThours: "",
      purposeOT: null,
      approver: null,
      approverID: null,
      status: null,
      wfID: null,
    },
  }),

  async mounted() {
    let d = new Date();
    this.todaymonths = this.monthNames[d.getMonth()].val;
    this.todayyears = new Date();
    this.LoadingDialog = true;
    await this.Getapprovername();
    await this.Getrequest();
    await this.sendNotification();
    await this.getDataFromParams();
    this.permission = this.CheckViewAddEdit(49, 50, 51);
    this.LoadingDialog = false;
  },

  computed: {
    itemFilter() {
      if (!this.itemInner) return this.requestedlist;
      return this.requestedlist.filter((x) => x.status == this.itemInner);
    },
    computedDateFormatted1() {
      return this.formatDate(this.requestOT.OTdate);
    },
    computedDateFormatted2() {
      return this.formatDate(this.EditOT.OTdate);
    },
    computedDateFormatted3() {
      return this.formatDate(this.requestOT.OTstartdate);
    },
    computedDateFormatted4() {
      return this.formatDate(this.requestOT.OTenddate);
    },
    computedDateFormatted5() {
      return this.formatDate(this.EditOT.OTstartdate);
    },
    computedDateFormatted6() {
      return this.formatDate(this.EditOT.OTenddate);
    },
    computedDateFormatted7() {
      return this.formatDate(this.OTDetail.OTdate);
    },
  },
  watch: {
    $route() {
      this.getDataFromParams();
    },
  },
  methods: {
    async getDataFromParams() {
      try 
      {
        let self = this;
        let notiSring = this.$route.params.notiString;
        if (notiSring) 
        {
          this.showOT = true;
          let temp1 = {
            wF_ID: parseInt(notiSring),
          };
          const response = await axios.post(`${this.url}OT_Request/GetOTByWorkFlowId`,temp1);
          if(response.data.status == 0)
          {
            let temp = response.data.data[0];
            self.showOT = true;
            self.OTDetail.OTrequest_ID = temp.oT_Request_ID;
            self.OTDetail.empname = temp.name && temp.lastName ? `${temp.name} ${temp.lastName}` : temp.requestername;
            self.OTDetail.approver = temp.firstName && temp.myLastName ? `${temp.firstName} ${temp.myLastName}` : temp.approvername;
            self.OTDetail.wholeday = temp.wholeDay;
            self.OTDetail.OTdate = temp.start_Date;
            self.OTDetail.OTstart = temp.oT_Start_Time;
            self.OTDetail.OTend = temp.oT_End_Time;
            self.OTDetail.OTstartdate = temp.start_Date;
            self.OTDetail.OTenddate = temp.end_Date;
            self.OTDetail.OThours = temp.oT_Hours;
            self.OTDetail.purposeOT = temp.purpose_OT;
            self.OTDetail.status = temp.status;
            self.OTDetail.wfID = temp.wF_ID;
          }
          else
          {
            alert(response.data.message)
            this.LoadingDialog = true;
          }
        }
      }
      catch (error) 
      {
        throw error;
      }
    },

    closeNoti() {
      this.showOT = false;
      this.$router.replace({
        name: "OT",
      });
    },

    dialogToggle(item) {
      let self = this;
      if (item.workFlow_Name == "OT") 
      {
        self.DetailrequestForOT(item);
      } 
      else 
      {
        self.DetailTimeOff(item);
      }
    },

    DetailrequestForOT(temp) {
      let self = this;
      self.showOT = true;
      self.OTDetail.OTrequest_ID = temp.oT_Request_ID;
      if (temp.name && temp.lastName) 
      {
        self.OTDetail.empname = `${temp.name} ${temp.lastName}`;
      } 
      else if (temp.requestername) 
      {
        self.OTDetail.empname = temp.requestername;
      }
      if (temp.firstName && temp.myLastName) 
      {
        self.OTDetail.approver = `${temp.firstName} ${temp.myLastName}`;
      } 
      else if (temp.requestername) 
      {
        self.OTDetail.approver = temp.approvername;
      }
      self.OTDetail.wholeday = temp.wholeDay;
      self.OTDetail.OTdate = temp.start_Date;
      self.OTDetail.OTstart = temp.oT_Start_Time;
      self.OTDetail.OTend = temp.oT_End_Time;
      self.OTDetail.OTstartdate = temp.start_Date;
      self.OTDetail.OTenddate = temp.end_Date;
      self.OTDetail.OThours = temp.oT_Hours;
      self.OTDetail.purposeOT = temp.purpose_OT;
      self.OTDetail.status = temp.status;
      self.OTDetail.wfID = temp.wF_ID;
    },

    async sendNotification() {
      let self = this;
      let TempReportID = null;
      let userInfoTemp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await axios.post(`${this.url}PersonalInfo/GetShortInfo`, userInfoTemp)
        .then(function (response) {
          var username = `${response.data.data[0].name} ${response.data.data[0].lastName}`;
          var reportID = response.data.data[0].report_ID;
          self.username = username;
          self.reportID = reportID;
          TempReportID = reportID;
        });
      if (TempReportID) 
      {
        let temp = {
          user_ID: TempReportID,
        };
        let token = [];
        await axios.post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`, temp)
          .then(function (response) {
            var temp = response.data.data;
            if (temp[0]) 
            {
              temp.map((t) => token.push(t.fireBase_Token));
            }
          })
          .catch(function(error)
          {
            alert(error);
            self.LoadingDialog = false;
          });
        self.tokenList = token;
      }
    },

    async pushNotification(recipientID) {
      let self = this;
      let noti_temp = {
        registerIDs: self.tokenList,
        title: "OT Request",
        body: "OT request from " + self.username,
        reportID: recipientID,
      };
      await axios.post(`${this.url}Firebasetoken/sendNotification`, noti_temp)
        .then(function () {
        });
    },

    async ChangeMonth(mth) {
      let self = this;
      let d = new Date();
      d.setMonth(mth - 1);
      let year = self.todayyears.getFullYear();
      d.setFullYear(year);
      self.focus = d;
      await self.Getrequest();
    },

    closerequestdialog() {
      let self = this;
      self.OTapprovedialog = false;
      self.requestOT.wholeday = false;
      self.requestOT.OTstartdate = "";
      self.requestOT.OTenddate = "";
      self.requestOT.OTdate = "";
      self.requestOT.OTstart = "";
      self.requestOT.OTend = "";
      self.requestOT.OThours = "";
      self.requestOT.purposeOT = null;
      self.showandhide = true;
    },

    async ChangeYear(year) {
      let self = this;
      let d = new Date();
      d.setMonth(self.todaymonths - 1);
      let y = year.getFullYear();
      d.setFullYear(y);
      self.focus = d;
      await self.Getrequest();
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    myformatdate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[0] + "/" + finaldate[1] + "/" + finaldate[2];
    },

    convertUTCDateToLocalDate(date) {
      date = new Date(date);
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      return output;
    },

    async Getapprovername() {
      let self = this;
      let temp = {
        employee_ID: store.state.reportID,
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}PersonalInfo/GetShortInfo`, temp)
        .then(function (response) {
          self.approverlist = response.data.data;
          self.requestOT.approver = self.approverlist[0].name + " " + self.approverlist[0].lastName;
          self.requestOT.empname = store.state.username;
          self.requestOT.approverID = self.approverlist[0].employee_ID;
        })
        .catch(function(error)
        {
          alert(error);
          self.LoadingDialog = false;
        });
    },

    changetimeofview(start, end) {
      let self = this;
      var otstartdate = "";
      var otenddate = "";
      if (!self.EditOT.wholeday) 
      {
        otstartdate = self.EditOT.OTdate;
        otenddate = self.EditOT.OTdate;
      } 
      else 
      {
        otstartdate = self.EditOT.OTstartdate;
        otenddate = self.EditOT.OTenddate;
      }
      if (start != "" && end != "") 
      {
        let from = new Date();
        let to = new Date();
        let [frhours, frminutes] = start.split(":");
        let [tohours, tominutes] = end.split(":");
        from.setHours(+frhours);
        from.setMinutes(frminutes);
        to.setHours(+tohours);
        to.setMinutes(tominutes);
        let frtimezone = from.getTimezoneOffset() * 60000;
        let totimezone = to.getTimezoneOffset() * 60000;
        let frnewDate = new Date(from.getTime() - frtimezone);
        let tonewDate = new Date(to.getTime() - totimezone);
        var timeDiff = Math.abs(frnewDate - tonewDate);
        var a = moment(self.myformatdate(otstartdate), "YYYY-MM-DD");
        var b = moment(self.myformatdate(otenddate), "YYYY-MM-DD");
        var differentday = parseInt(b.diff(a, "days")) + 1;
        var hh = Math.floor(timeDiff / 1000 / 60 / 60);
        timeDiff -= hh * 1000 * 60 * 60;
        var mm = Math.floor(timeDiff / 1000 / 60);
        if (!self.EditOT.wholeday) 
        {
          if (mm < 10) 
          {
            mm = "0" + mm;
          }
          if (hh == "00") 
          {
            self.EditOT.OThours = mm + "minutes";
          } 
          else if (mm == "00") 
          {
            self.EditOT.OThours = hh + " " + "hours";
          } 
          else 
          {
            self.EditOT.OThours = hh + " " + "hours" + " " + mm + " " + "minutes ";
          }
        } 
        else 
        {
          var totalhour = hh * differentday;
          var totalminute = mm * differentday;
          var gethour = 0;
          var getminute = 0;
          if (totalminute > 60) 
          {
            var gethourandminute = self.timeConvert(totalminute);
            gethour = parseInt(gethourandminute.split("hour")[0]);
            getminute = parseInt(gethourandminute.split("hour")[1]);
          }
          if (gethour != 0 || getminute != 0) 
          {
            totalhour = totalhour + gethour;
            totalminute = getminute;
          }
          if (totalminute < 10) 
          {
            totalminute = "0" + totalminute;
          }
          if (totalhour == 0) 
          {
            self.EditOT.OThours = totalminute + "minutes";
          } 
          else if (totalminute == 0) 
          {
            self.EditOT.OThours = totalhour + " " + "hours";
          } 
          else 
          {
            self.EditOT.OThours = totalhour + " " + "hours" + " " + totalminute + " " + "minutes ";
          }
        }
      }
    },

    async getLatestData() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}OT_Request/GetworkFlowOfOTRequest`, temp)
        .then(function (response) {
          if (response) 
          {
            let WF_ID = response.data.data.wF_ID;
            let senderID = response.data.data.requester_ID;
            let recipientID = response.data.data.approver_ID;
            self.newnotiList(WF_ID, senderID, recipientID);
          }
        });
    },

    newnotiList(WF_ID, senderID, recipientID) {
      let self = this;
      let temp = {
        wF_ID: WF_ID,
        senderID: senderID,
        recipientID: recipientID,
        seen: false,
        delicated:false,
        description: `requested for OT`,
      };
      axios.post(`${self.url}NotificationInfo/AddNotificationInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) 
          {
            self.pushNotification(recipientID);
          }
        });
    },

    changetime(start, end) {
      let self = this;
      var otstartdate = "";
      var otenddate = "";
      if (!self.requestOT.wholeday) 
      {
        otstartdate = self.requestOT.OTdate;
        otenddate = self.requestOT.OTdate;
      } 
      else 
      {
        otstartdate = self.requestOT.OTstartdate;
        otenddate = self.requestOT.OTenddate;
      }
      if (start != "" && end != "") 
      {
        let from = new Date();
        let to = new Date();
        let [frhours, frminutes] = start.split(":");
        let [tohours, tominutes] = end.split(":");
        from.setHours(+frhours);
        from.setMinutes(frminutes);
        to.setHours(+tohours);
        to.setMinutes(tominutes);
        let frtimezone = from.getTimezoneOffset() * 60000;
        let totimezone = to.getTimezoneOffset() * 60000;
        let frnewDate = new Date(from.getTime() - frtimezone);
        let tonewDate = new Date(to.getTime() - totimezone);
        var timeDiff = Math.abs(frnewDate - tonewDate);
        var a = moment(self.myformatdate(otstartdate), "YYYY-MM-DD");
        var b = moment(self.myformatdate(otenddate), "YYYY-MM-DD");
        var differentday = parseInt(b.diff(a, "days")) + 1;
        var hh = Math.floor(timeDiff / 1000 / 60 / 60);
        timeDiff -= hh * 1000 * 60 * 60;
        var mm = Math.floor(timeDiff / 1000 / 60);
        if (!self.requestOT.wholeday) 
        {
          if (mm < 10) {
            mm = "0" + mm;
          }
          if (hh == "00") 
          {
            self.requestOT.OThours = mm + "minutes";
          } 
          else if (mm == "00") 
          {
            self.requestOT.OThours = hh + " " + "hours";
          } 
          else 
          {
            self.requestOT.OThours =
              hh + " " + "hours" + " " + mm + " " + "minutes ";
          }
        } 
        else 
        {
          var totalhour = hh * differentday;
          var totalminute = mm * differentday;
          var gethour = 0;
          var getminute = 0;
          if (totalminute > 60) 
          {
            var gethourandminute = self.timeConvert(totalminute);
            gethour = parseInt(gethourandminute.split("hour")[0]);
            getminute = parseInt(gethourandminute.split("hour")[1]);
          }
          if (gethour != 0 || getminute != 0) 
          {
            totalhour = totalhour + gethour;
            totalminute = getminute;
          }
          if (totalminute < 10) 
          {
            totalminute = "0" + totalminute;
          }
          if (totalhour == 0) 
          {
            self.requestOT.OThours = totalminute + "minutes";
          } 
          else if (totalminute == 0) 
          {
            self.requestOT.OThours = totalhour + " " + "hours";
          } 
          else 
          {
            self.requestOT.OThours = totalhour + " " + "hours" + " " + totalminute + " " + "minutes ";
          }
        }
      }
    },

    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hour " + rminutes;
    },

    async RequestedOT() {
      let self = this;
      self.LoadingDialog = true;
      if (self.requestOT.wholeday == false) 
      {
        self.mystartdate = self.requestOT.OTdate;
        self.myenddate = self.requestOT.OTdate;
      } 
      else 
      {
        self.mystartdate = self.requestOT.OTstartdate;
        self.myenddate = self.requestOT.OTenddate;
      }
      if (
        self.requestOT.empname != "" &&
        self.mystartdate != "" &&
        self.myenddate != "" &&
        self.requestOT.OTstart != "" &&
        self.requestOT.OTend != "" &&
        self.requestOT.OThours != "" &&
        self.requestOT.purposeOT != null &&
        self.requestOT.approver != null
      ) 
      {
        if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day")) 
        {
          let validate = {
            start_Date: self.mystartdate,
            end_Date: self.myenddate,
            oT_Start_Time: self.requestOT.OTstart,
            oT_End_Time: self.requestOT.OTend,
            company_ID: store.state.companyID,
            employee_ID: store.state.employeeID,
          };
          await axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
            .then(async function (response) {
              if (response.data.status == 0) 
              {
                if (response.data.message == "Successfully") 
                {
                  let temp = {
                    wholeDay: self.requestOT.wholeday,
                    start_Date: self.mystartdate,
                    end_Date: self.myenddate,
                    oT_Start_Time: self.requestOT.OTstart,
                    oT_End_Time: self.requestOT.OTend,
                    oT_Hours: self.requestOT.OThours,
                    purpose_OT: self.requestOT.purposeOT,
                    saveDraft: false,
                    requester_ID: store.state.employeeID,
                    approver_ID: self.requestOT.approverID,
                    company_ID: store.state.companyID,
                  };
                  await axios.post(`${self.url}OT_Request/StaffOT_Request`, temp)
                    .then(async function (response) {
                      if (response.data.status == 0) 
                      {
                        if (response.data.message == "Posted invalid data.") 
                        {
                          self.LoadingDialog = false;
                          alert("Please recheck the date since there seems to be a clash with another request’ for clash with duplicated ot request");
                        }
                        if (response.data.message == "Add Successfully") 
                        {
                          alert(response.data.message);
                          if (response.data.status === 0) 
                          {
                            await self.getLatestData();
                          }
                          self.LoadingDialog = false;
                          await self.Getrequest();
                          self.OTapprovedialog = false;
                          self.requestOT.wholeday = false;
                          self.requestOT.OTdate = "";
                          self.requestOT.OTstartdate = "";
                          self.requestOT.OTenddate = "";
                          self.requestOT.OTstart = "";
                          self.requestOT.OTend = "";
                          self.requestOT.OThours = null;
                          self.requestOT.purposeOT = null;
                          //self.ReportTrace(5);
                          //self.LogTrace(null, "Add OT request", 24, "Low");
                        }
                      }
                    })
                    .catch(function (error) {
                      self.LogTrace(error, "Add OT request Fail", 24, "Low");
                      alert(error);
                      self.LoadingDialog = false;
                    });
                } 
                else 
                {
                  self.LoadingDialog = false;
                  alert("The OT time requested clashes with the regular shift time. Please check again");
                }
              }
            });
        } 
        else 
        {
          self.LoadingDialog = false;
          alert("Start date can’t be after end date");
        }
      } 
      else 
      {
        alert("Please fill all the fields");
      }
    },

    async SavedraftOT() {
      let self = this;
      self.LoadingDialog = true;
      if (!self.requestOT.wholeday) 
      {
        self.mystartdate = self.requestOT.OTdate;
        self.myenddate = self.requestOT.OTdate;
      } 
      else 
      {
        self.mystartdate = self.requestOT.OTstartdate;
        self.myenddate = self.requestOT.OTenddate;
      }
      if (
        self.requestOT.empname != "" &&
        self.mystartdate != "" &&
        self.myenddate != "" &&
        self.requestOT.OTstart != "" &&
        self.requestOT.OTend != "" &&
        self.requestOT.OThours != "" &&
        self.requestOT.purposeOT != null &&
        self.requestOT.approver != null
      ) 
      {
        if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day")) 
        {
          let validate = {
            start_Date: self.mystartdate,
            end_Date: self.myenddate,
            oT_Start_Time: self.requestOT.OTstart,
            oT_End_Time: self.requestOT.OTend,
            company_ID: store.state.companyID,
            employee_ID: store.state.employeeID,
          };
          await axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
            .then(function (response) {
              if (response.data.status == 0) 
              {
                if (response.data.message == "Successfully") 
                {
                  let temp = {
                    wholeDay: self.requestOT.wholeday,
                    start_Date: self.mystartdate,
                    end_Date: self.myenddate,
                    oT_Start_Time: self.requestOT.OTstart,
                    oT_End_Time: self.requestOT.OTend,
                    oT_Hours: self.requestOT.OThours,
                    purpose_OT: self.requestOT.purposeOT,
                    saveDraft: true,
                    requester_ID: store.state.employeeID,
                    approver_ID: self.requestOT.approverID,
                    company_ID: store.state.companyID,
                  };
                  axios.post(`${self.url}OT_Request/StaffOT_Request`, temp)
                    .then(function (response) {
                      if (response.data.status == 0) 
                      {
                        if (response.data.message == "Posted invalid data.") 
                        {
                          self.LoadingDialog = false;
                          alert("Please recheck the date since there seems to be a clash with another request");
                        }
                        if (response.data.message == "Add Successfully") 
                        {
                          alert(response.data.message);
                          self.LoadingDialog = false;
                          self.Getrequest();
                          self.OTapprovedialog = false;
                          self.requestOT.wholeday = false;
                          self.requestOT.OTdate = "";
                          self.requestOT.OTstartdate = "";
                          self.requestOT.OTenddate = "";
                          self.requestOT.OTstart = "";
                          self.requestOT.OTend = "";
                          self.requestOT.OThours = null;
                          self.requestOT.purposeOT = null;
                        }
                      }
                    });
                } 
                else 
                {
                  self.LoadingDialog = false;
                  alert("The OT time requested clashes with the regular shift time. Please check again");
                }
              }
            });
        } 
        else 
        {
          alert("Start date can’t be after end date");
          self.LoadingDialog = false;
        }
      } 
      else 
      {
        alert("Please fill all the fields");
        self.LoadingDialog = false;
      }
    },

    showstartdate() {
      let self = this;
      if (!self.requestOT.wholeday) 
      {
        self.showandhide = false;
      } 
      else 
      {
        self.showandhide = true;
      }
    },

    showeditdate() {
      let self = this;
      if (!self.EditOT.wholeday) 
      {
        self.editshowandhide = false;
      } 
      else 
      {
        self.editshowandhide = true;
      }
    },

    showdetaildata() {
      let self = this;
      self.disableviewdata = false;
      self.hideeditbtn = true;
      self.showeditbtn = false;
      self.disablesendbtn = true;
      self.disalbecanclebtn = true;
    },

    async Getrequest() {
      let self = this;
      self.LoadingDialog = true;
      let myyear = self.todayyears;
      let y = myyear.getFullYear();
      let thisyear = "";
      if (self.todaymonths.val == undefined) 
      {
        thisyear = self.todaymonths;
      } 
      else 
      {
        thisyear = self.todaymonths.val;
      }
      let temp = {
        month_Num: thisyear,
        year_Num: y,
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}OT_Request/GetOT_Request`, temp)
        .then(function (response) 
        {
          self.requestedlist = response.data.data;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get OT request Fail", 24, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },

    hidedetaildialog() {
      let self = this;
      self.viewdialog = false;
      self.hideeditbtn = false;
      self.disablesendbtn = false;
      self.disalbecanclebtn = false;
      self.disableviewdata = true;
    },

    Detailrequest(temp) {
      let self = this;
      self.viewdialog = true;
      self.EditOT.OTrequest_ID = temp.oT_Request_ID;
      if (temp.saveDraft) 
      {
        self.showeditbtn = false;
      } 
      else 
      {
        self.showeditbtn = true;
      }

      self.EditOT.empname = temp.requestername;
      self.EditOT.wholeday = temp.wholeDay;
      if (temp.wholeDay) 
      {
        self.editshowandhide = false;
        self.EditOT.OTdate = temp.start_Date;
        self.viewOtstart = temp.oT_Start_Time;
        self.viewOtend = temp.oT_End_Time;
      } 
      else 
      {
        self.editshowandhide = true;
        self.EditOT.OTdate = temp.start_Date;
        self.viewOtstart = temp.oT_Start_Time;
        self.viewOtend = temp.oT_End_Time;
      }
      self.EditOT.OTstartdate = temp.start_Date;
      self.EditOT.OTenddate = temp.end_Date;
      self.EditOT.OThours = temp.oT_Hours;
      self.EditOT.purposeOT = temp.purpose_OT;
      self.EditOT.approver = temp.approvername;
      self.EditOT.status = temp.status;
    },

    EditOTrequest() {
      let self = this;
      self.LoadingDialog = true;
      self.EditOT.OTstart = self.viewOtstart;
      self.EditOT.OTend = self.viewOtend;
      if (!self.EditOT.wholeday) 
      {
        self.mystartdate = self.EditOT.OTdate;
        self.myenddate = self.EditOT.OTdate;
      } 
      else 
      {
        self.mystartdate = self.EditOT.OTstartdate;
        self.myenddate = self.EditOT.OTenddate;
      }
      if (
        self.EditOT.empname != "" &&
        self.mystartdate != "" &&
        self.myenddate != "" &&
        self.EditOT.OTstart != "" &&
        self.EditOT.OTend != "" &&
        self.EditOT.OThours != "" &&
        self.EditOT.purposeOT != null &&
        self.EditOT.approver != null
      ) 
      {
        if (moment(this.mystartdate) < moment(this.myenddate).add(1, "day")) 
        {
          let validate = {
            start_Date: self.mystartdate,
            end_Date: self.myenddate,
            oT_Start_Time: self.EditOT.OTstart,
            oT_End_Time: self.EditOT.OTend,
            company_ID: store.state.companyID,
            employee_ID: store.state.employeeID,
          };
          axios.post(`${self.url}OT_Request/Getbyworkday`, validate)
            .then(function (response) {
              if (response.data.status == 0) 
              {
                if (response.data.message == "Successfully") 
                {
                  let temp = {
                    wholeDay: self.EditOT.wholeday,
                    oT_Request_ID: self.EditOT.OTrequest_ID,
                    start_Date: self.mystartdate,
                    end_Date: self.myenddate,
                    oT_Start_Time: self.EditOT.OTstart,
                    oT_End_Time: self.EditOT.OTend,
                    oT_Hours: self.EditOT.OThours,
                    purpose_OT: self.EditOT.purposeOT,
                    saveDraft: true,
                    requester_ID: store.state.employeeID,
                    approver_ID: this.EditOT.approverID,
                    company_ID: store.state.companyID,
                  };
                  axios.post(`${self.url}OT_Request/UpdateOT_Request`, temp)
                    .then(function (response) {
                      if (response.data.status == 0) 
                      {
                        alert(response.data.message);
                        self.LoadingDialog = false;
                        self.Getrequest();
                        self.viewdialog = false;
                        self.hideeditbtn = false;
                        self.disablesendbtn = false;
                        self.disalbecanclebtn = false;
                        self.disableviewdata = true;
                      }
                    })
                    .catch(function (error) {
                      self.LogTrace(error,"Update OT Request Fail",24, "Medium");
                      alert(error);
                      self.LoadingDialog = false;
                    });
                } 
                else 
                {
                  alert("The OT time requested clashes with the regular shift time. Please check again");
                  self.LoadingDialog = false;
                }
              }
            });
        } 
        else 
        {
          self.LoadingDialog = false;
          alert("Start date can’t be after end date");
        }
      } 
      else 
      {
        alert("Please fill all the fields");
      }
    },

    SendOTrequest() {
      let self = this;
      if (!self.EditOT.wholeday) 
      {
        self.mystartdate = self.EditOT.OTdate;
        self.myenddate = self.EditOT.OTdate;
        self.EditOT.OTstart = "0";
        self.EditOT.OTend = "0";
      } 
      else 
      {
        self.mystartdate = self.EditOT.OTstartdate;
        self.myenddate = self.EditOT.OTenddate;
        self.EditOT.OTstart = "0";
        self.EditOT.OTend = "0";
      }
      if (
        self.EditOT.empname != "" &&
        self.mystartdate != "" &&
        self.myenddate != "" &&
        self.EditOT.OTstart != "" &&
        self.EditOT.OTend != "" &&
        self.EditOT.OThours != "" &&
        self.EditOT.purposeOT != null &&
        self.EditOT.approver != null
      ) 
      {
        let temp = {
          wholeDay: self.EditOT.wholeday,
          oT_Request_ID: self.EditOT.OTrequest_ID,
          start_Date: self.mystartdate,
          end_Date: self.myenddate,
          oT_Start_Time: self.EditOT.OTstart,
          oT_End_Time: self.EditOT.OTend,
          oT_Hours: self.EditOT.OThours,
          purpose_OT: self.EditOT.purposeOT,
          saveDraft: false,
          requester_ID: store.state.employeeID,
          approver_ID: self.EditOT.approverID,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}OT_Request/UpdateOT_Request`, temp)
          .then(function (response) {
            if (response.data.status == 0) 
            {
              alert(response.data.message);
              self.Getrequest();
              self.viewdialog = false;
              self.hideeditbtn = false;
              self.disablesendbtn = false;
              self.disalbecanclebtn = false;
              self.disableviewdata = true;
              self.LoadingDialog = false;
              // self
              //   .LogTrace(null, "Update  OT Request", 24, "Medium")
              //   .catch(function (error) {
              //     self.LogTrace(error, "Update OT Request Fail", 24, "Medium");
              //     alert(error);
              //     self.LoadingDialog = false;
              //   });
            }
          });
      } 
      else 
      {
        alert("Please fill all the fields");
      }
    },

    deleteOTrequest() {
      let self = this;
      self.Deletedialog = true;
    },

    DeleteCancel() {
      let self = this;
      self.Deletedialog = false;
    },

    DeleteOTrequestlist() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        oT_Request_ID: self.EditOT.OTrequest_ID,
      };
      axios.post(`${self.url}OT_Request/DeleteOT_Request`, temp)
        .then(function (response) {
          if (response.data.status == 0) 
          {
            alert(response.data.message);
            self.Getrequest();
            self.LoadingDialog = false;
            self.Deletedialog = false;
            self.viewdialog = false;
            //self.LogTrace(null, "Delete OT request", 24, "High");
          }
        })
        .catch(function (error) 
        {
          self.LogTrace(error, "Delete OT Request Fail", 24, "High");
          alert(error);
          self.LoadingDialog = false;
        });
    },
  },
};
</script>
<style scoped>
>>> .selectboxs {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 70px;
}
.deletepadd {
  padding: 0;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
</style>