<template>
  <div style="margin-left: 1.5rem">
    <div style="margin-right: 1.5rem">
      <p style="font-size: 26px; color: #43425d; margin-top: 20px">
        {{ $t("timeoff-org") }}
      </p>
      <v-tabs
        class="mt-6"
        grey--text
        background-color="#f0f8fc"
        slider-color="#F99D20"
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          class="text-capitalize"
          style="
            border-radius: 10px 10px 0px 5px;
            width: 150px;
            margin-right: 3px;
            font-size: 16px;
          "
          v-for="tab in tabs"
          :key="tab.id"
          :to="tab.route"
          v-show="tab.showthis"
          exact
          >{{
            tab.name == "Time Off Type"
              ? $t("TimeOffType")
              : $t("TimeOffCounts")
          }}</v-tab
        >
      </v-tabs>
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  name: "TimeOff_Organization",
  mixins: [utils],
  props: ["id"],
  data() {
    return {
      activeTab: `/organization/timeoff/${this.id}`,
      showthis: false,
      tabs: [
        {
          id: 1,
          name: "Time Off Type",
          route: `/organization/timeoff/${this.id}`,
          showthis: false,
        },
        {
          id: 2,
          name: "Time Off Day Count",
          route: `/organization/timeoff/${this.id}/timeoffdaycount`,
          showthis: false,
        },
      ],
    };
  },
  watch: {
    activeTab(tabId) {
      const tab = this.tabs.find((t) => t.id === tabId);
      if (tab) {
        if (tabId === 0) {
          this.activeTab = `/organization/timeoff/${this.id}`;
        } else {
          window.location.hash = `/organization/timeoff/${this.id}/${tab.name
            .toLowerCase()
            .replace(" ", "")}`;
          // localStorage.setItem("activeTab", tabId.toString());
        }
      }
    },
  },
  // beforeDestroy() {
  //   localStorage.removeItem("activeTab");
  // },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleid = sessionStorage.getItem("roleID");
    if (roleid == "Admin") {
      for (let j = 0; j < this.tabs.length; j++) {
        this.tabs[j].showthis = true;
      }
    }
    if (perid.some((e) => e == 89)) {
      this.tabs[0].showthis = true;
    }
    if (perid.some((e) => e == 39)) {
      this.tabs[1].showthis = true;
    }
    if (this.$route.path == "/organization/timeoff/timeofftype") {
      if (this.tabs[0].showthis == true) {
        this.activeTab = this.tabs[0].route;
        this.$router.push(this.tabs[0].route).catch(() => {});
      } else {
        this.activeTab = this.tabs[1].route;
        this.$router.push(this.tabs[1].route).catch(() => {});
      }
    } else if (this.$route.path == "/organization/timeoff/timeofftype/add") {
      if (this.tabs[0].showthis == true) {
        const newTabRoute = `/organization/timeoff/${this.id}/add`;
        this.tabs[0].route = newTabRoute;
        this.tabs[0].name = this.tabs[0].name;
        this.activeTab = 0;
      }
    } else if (
      this.$route.path == "/organization/timeoff/timeofftype/timeoffdaycount"
    ) {
      if (this.tabs[1].showthis == true) {
        this.activeTab = this.tabs[1].route;
        this.$router.push(this.tabs[1].route).catch(() => {});
      }
    } else {
      const newTabRoute = this.$route.path;
      this.tabs[0].route = newTabRoute;
      this.tabs[0].name = this.tabs[0].name;
      this.activeTab = 0;
    }
  },
  methods: {
    clickthis(index) {
      this.activeTab = index;
      // this.$router.push(`/organization/timeoff/timeofftype`).catch(() => {});
    },
  },
};
</script>
<style scoped>
.v-tab:before {
  background: none !important;
}

.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}

.v-tabs-bar .v-tab {
  background-color: white;
}

.active {
  cursor: pointer !important;
}

.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}

>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
</style>