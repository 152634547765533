<template>
  <v-card class="mycardthree">
    <div class="d-flex ml-2 fixed-header">
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="7"
            class="ml-auto d-flex align-center">
            <img class="commonstatisticsIcon" :src="images.commonstatistics" alt="commonstatistics" />
            <div style="margin-left: 10px;">
              <p style="color: #F99D20; font-size: 17px;">
                {{ $t("Common_Statistics") }}
              </p>
            </div>
          </v-col>
          <!-- <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="7" class="ml-auto">
            <img class="birthdayIcon" :src="images.commonstatistics" alt="commonstatistics" />
            <div>
              <p style="color: #F99D20; font-size: 17px">
                {{ $t("Common_Statistics") }}
              </p>
            </div>
          </v-col> -->
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="5">
            <v-select class="mt-3 mr-5" v-model="itemInner" :items="status" item-text="txt" item-value="val" outlined 
              @change="(v) => onInputHandler(v)" dense color="#F99D20"></v-select>
          </v-col>
        </v-row>
      </div>
    <div class="mr-5 ml-2">
      <v-container>
        
        <v-row v-show="this.personalList != 0">
          <v-col lg="7" sm="12" xs="12" xl="8" no-gutters>
            <div style="
                max-width: 280px;
                max-height: 300px;
                min-width: 280px;
                min-height: 300px;
                margin-left: -20px;
                margin-top: -15px;
              ">
              <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
              
            </div>
          </v-col>
          <v-col lg="5" sm="12" md="12" xs="12" xl="4">
            <div v-if="itemInner == 'User Status' || itemInner == 'Employee Status'
              ">
              <Chart chipStyle="fullStyle" :Time="firstChart"></Chart>
              <Chart chipStyle="daily" v-if="employeeSecond || itemInner == 'User Status'" :Time="secondChart">
              </Chart>
              <Chart chipStyle="parttime" v-if="employeeFourth && itemInner == 'Employee Status'" :Time="fourthChart">
              </Chart>
              <Chart chipStyle="contract" v-if="employeeFifth && itemInner == 'Employee Status'" :Time="fifthChart">
              </Chart>
              <Chart chipStyle="fixedpay" v-if="employeeSixth && itemInner == 'Employee Status'" :Time="sixthChart">
              </Chart>
              <!-- <Chart chipStyle="deptStyle" v-if="employeeThird && itemInner == 'Employee Status'" :Time="thirdChart">
              </Chart> -->
            </div>

            <div v-if="itemInner == 'Department'">
              <div class="pt-3 pb-6" v-for="(item, index) in deptLabels" :key="index">
                <span :style="switchStyle(index)" class="borderStyle"></span>
                <span class="label">{{ deptLabels[index] }} </span>
                <div class="float-right" style="color: #0fa7d8">
                  {{ deptSeries[index] }}
                </div>
              </div>
            </div>
            <div v-if="itemInner == 'Position'">
              <div class="pt-3 pb-6" v-for="(item, index) in positionLabels" :key="index">
                <span :style="switchStyle(index)" class="borderStyle"></span>
                <span class="label">{{ positionLabels[index] }} </span>
                <div class="float-right" style="color: #0fa7d8">
                  {{ positionSeries[index] }}

                </div>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="mt-3">
              <p>{{ $t("Total") }}</p>
              <div class="float-right" style="color: #0fa7d8; margin-top: -2.5rem">
                {{ itemInner == "User Status" ? personalList : activeemp }}
                <!-- 100% -->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-show="this.personalList == 0">
          <v-col cols="12">
            <div style="min-height: 250px; margin-left: -1rem">
              <v-card-text style="color: #f99d20">{{
                $t("nodata")
              }}</v-card-text>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Chart from "@/views/Home/Chart.vue";
import enurl from "@/api/environment";
import store from "@/store";
import Axios from "axios";
export default {
  name: 'ApexDonutChart',
  components: {
    apexchart: VueApexCharts,
    Chart,
  },
  data() {
    return {
      colors: [
        "#869D00",
        "#F99D20",
        "#FF0000",
        "#258039",
        "#31A9B8",
        "#4C3F54",
      ],
      chartOptions: {
        labels: ["Full Time", "Daily", "Part-time", "Contract", "Fixed Pay"],
        colors: [
          "#869D00",
          "#F99D20",
          "#FF0000",
          "#258039",
          "#31A9B8",
          "#4C3F54",
        ],
        chart: {
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  color: "#0fa7d8",
                },
                total: {
                  show: true,
                  label: "Totals",
                  color: "#ffa500",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
          expandOnClick: true,
        },

        dataLabels: {
          formatter: (val) => {
            return [val.toFixed(1) + "%"];
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true,
          theme: 'light',
        },
      },
      personalList: 0,
      url: enurl.apiUrl,
      //status: ["Employee Status", "User Status", "Department", "Position"],
      request: {
        company_ID: store.state.companyID,
      },
      itemInner: "Employee Status",
      employeeFirst: 0,
      employeeSecond: 0,
      employeeThird: 0,
      employeeFourth: 0,
      employeeFifth: 0,
      employeeSixth: 0,

      deptSeries: [],
      deptLabels: [],
      positionLabels: [],
      positionSeries: [],

      firstSeries: 6,
      secondSeries: 4,
      arrList: [],
      thirdColor: "#FF0000",
      first: 10,

      firstChart: {
        isFull: "Full Time",
        percent: 0,
      },
      secondChart: {
        isFull: "Daily",
        percent: 0,
      },
      fourthChart: {
        isFull: "Part-time",
        percent: 0,
      },
      fifthChart: {
        isFull: "Contract",
        percent: 0,
      },
      sixthChart: {
        isFull: "Fixed Pay",
        percent: 0,
      },
      activeemp: 0,
      // thirdChart: {
      //   isFull: "Unspecified",
      //   percent: 0,
      // },
    };
  },
  methods: {
    async onInputHandler(v) {
      let self = this;
      const { firstChart, secondChart, fourthChart, fifthChart, sixthChart } = this;
      if (v == "Employee Status") {
        firstChart.isFull = "Full Time";
        secondChart.isFull = "Daily";
        fourthChart.isFull = "Part-time";
        fifthChart.isFull = "Contract";
        sixthChart.isFull = "Fixed Pay";
        // thirdChart.isFull = "Unspecified";
        // self.chartOptions.plotOptions.pie.donut.labels.total.label = 'Employee Status';
        // self.chartOptions.labels = [
        //     self.firstChart.isFull,
        //     self.secondChart.isFull,
        //     self.fourthChart.isFull,
        //     self.fifthChart.isFull,
        //     self.sixthChart.isFull
        //     // self.thirdChart.isFull,
        //   ];
        self.chartOptions = {
          labels: [
            self.firstChart.isFull,
            self.secondChart.isFull,
            self.fourthChart.isFull,
            self.fifthChart.isFull,
            self.sixthChart.isFull
            // self.thirdChart.isFull,
          ],
        };
        
        //this.chartOptions.plotOptions.pie.donut.labels.total.formatter = () => '59';

        self.firstChart.percent = self.employeeFirst;
        //self.thirdChart.percent = self.employeeThird;
        self.secondChart.percent = self.employeeSecond;
        self.fourthChart.percent = self.employeeFourth;
        self.fifthChart.percent = self.employeeFifth;
        self.sixthChart.percent = self.employeeSixth;

        //self.chartOptions.colors.push(self.thirdColor);
      }

      if (v == "User Status") {
        self.firstChart.percent = self.userFirst;
        self.secondChart.percent = self.userSecond;
        //self.chartOptions.plotOptions.pie.donut.labels.total.label = 'User Status';
        firstChart.isFull = "Active";
        secondChart.isFull = "Inactive";
        //self.chartOptions.labels = ["Active", "Inactive"];
        self.chartOptions = {
          labels: ["Active", "Inactive"],
        };
        
      }
      
      if (v == "Department") {
        //self.chartOptions.plotOptions.pie.donut.labels.total.label = 'Department';
        // this.chartOptions.plotOptions.pie.donut.labels.total.formatter = () =>  `<div style="text-align: center;">
        //      <img src='https://example.com/icon1.svg' alt='Position 1' width="40" />
        //    </div>`;
        //self.chartOptions.labels = self.deptLabels;
        self.chartOptions = {
          labels: self.deptLabels,
        };
      }
      
      if (v == "Position") {
        // self.chartOptions.plotOptions.pie.donut.labels.total.label = 'Position';
        // self.chartOptions.labels = self.positionLabels;
        self.chartOptions = {
          labels: self.positionLabels,
        };
        
      }
      //self.chartOptions = { ...self.chartOptions };
    },
    positionSeries1() {
      const total = this.positionSeries.reduce((a, b) => a + b, 0); // Total sum of series (63)
      return this.positionSeries.map(value => ((value / total) * 100).toFixed(1)); // Convert each value to percentage
    },
    deptSeries1() {
      const total = this.deptSeries.reduce((a, b) => a + b, 0); // Total sum of series (63)
      return this.deptSeries.map(value => ((value / total) * 100).toFixed(1)); // Convert each value to percentage
    },
    calcuPercent(val) {
      let value = (val / this.employeeTotal) * 100;
      return value.toFixed(1) + "%";
    },
    UsercalcuPercent(val) {
      let value = (val / (this.userFirst + this.userSecond)) * 100;
      return value.toFixed(1) + "%";
    },

    async GetPersonal() {
      try {
        let { company_ID } = this.request;
        let temp = {
          company_ID: company_ID,
        };
        const response = await Axios.post(
          `${this.url}PersonalInfo/GetCommonStatistics`,
          temp
        );

        this.personalList = response.data.data.length;
        let personalList = response.data.data;

        let activeemp = personalList.filter((e) => e.employeeStatus == 1);
        this.activeemp = activeemp.length;
        this.employeeFirst = activeemp.filter(
          (e) => e.employeeType == "Full Time"
        ).length;
        //this.employeeThird = personalList.filter((e) => !e.employeeType).length;
        this.employeeFourth = activeemp.filter(
          (e) => e.employeeType == "Part-time"
        ).length;
        this.employeeSecond = activeemp.filter(
          (e) => e.employeeType == "Daily"
        ).length;
        this.employeeFifth = activeemp.filter(
          (e) => e.employeeType == "Contract"
        ).length;
        this.employeeSixth = activeemp.filter(
          (e) => e.employeeType == "Fixed Pay"
        ).length;
        this.employeeTotal = this.employeeFirst + this.employeeSecond + this.employeeFourth + this.employeeFifth + this.employeeSixth;
        this.userFirst = personalList.filter(
          (e) => e.employeeStatus == 1
        ).length;
        this.userSecond = personalList.filter(
          (e) => e.employeeStatus == 2
        ).length;

        this.firstChart.percent = this.employeeFirst;
        this.secondChart.percent = this.employeeSecond;
        // this.thirdChart.percent = this.employeeThird;
        this.fourthChart.percent = this.employeeFourth;
        this.fifthChart.percent = this.employeeFifth;
        this.sixthChart.percent = this.employeeSixth;
        this.deptConfig(activeemp);
        this.positionConfig(activeemp);
      } catch (error) {
        throw error;
      }
    },
    positionConfig(list) {
      const length = list.length;
      const counts = {};
      list.forEach(function (x) {
        counts[x.position_Name] = (counts[x.position_Name] || 0) + 1;
      });
      let modifyArray = Object.keys(counts).map((key) => ({
        position_Name: key,
        postion_Count: counts[key],
      }));
      let newArray = modifyArray.sort(
        (a, b) => b.postion_Count - a.postion_Count
      );
      var result = newArray.map((el) =>
        el.position_Name == "null"
          ? { ...el, position_Name: "Unspecified" }
          : el
      );
      this.positionLabels = result.map((el) => el.position_Name);
      this.positionSeries = result.map((el) => el.postion_Count);
      if (this.positionLabels.length > 5) {
        let my5Labels = this.positionLabels.slice(0, 5);
        my5Labels.push("Others");
        let my5Series = this.positionSeries.slice(0, 5);
        const reducer = my5Series.reduce((a, b) => a + b);
        let total = length - reducer;
        my5Series.push(total);
        this.positionLabels = [...my5Labels];
        this.positionSeries = [...my5Series];
      }
    },
    deptConfig(list) {
      const length = list.length;
      const counts = {};
      list.forEach(function (x) {
        counts[x.department_Name] = (counts[x.department_Name] || 0) + 1;
      });
      let modifyArray = Object.keys(counts).map((key) => ({
        department_Name: key,
        dept_Count: counts[key],
      }));
      let newArray = modifyArray.sort((a, b) => b.dept_Count - a.dept_Count);
      var result = newArray.map((el) =>
        el.department_Name == "null"
          ? { ...el, department_Name: "Unspecified" }
          : el
      );
      this.deptLabels = result.map((el) => el.department_Name);
      this.deptSeries = result.map((el) => el.dept_Count);
      if (this.deptLabels.length > 5) {
        let my5Labels = this.deptLabels.slice(0, 5);
        my5Labels.push("Others");
        let my5Series = this.deptSeries.slice(0, 5);
        const reducer = my5Series.reduce((a, b) => a + b);
        let total = length - reducer;
        my5Series.push(total);
        this.deptLabels = [...my5Labels];
        this.deptSeries = [...my5Series];
      }
    },
  },

  mounted() {
    this.GetPersonal();
    this.$emit('component-ready');
  },
  computed: {
    status() {
      return [
        { val: "Employee Status", txt: this.$t("EmployeeStatus") },
        { val: "User Status", txt: this.$t("UserStatus") },
        { val: "Department", txt: this.$t("department") },
        { val: "Position", txt: this.$t("position") },
      ];
    },
    switchStyle() {
      return function (salut) {
        return `border-left: 10px solid  ${this.colors[salut]}`;
      };
    },
    autoCompleteStyle() {
      return `width: ${status.length * 50}vw;`;
    },
    series() {
      if (this.itemInner == "Department") return this.deptSeries;
      if (this.itemInner == "Position") return this.positionSeries;
      if (this.itemInner == "User Status")
        return [this.userFirst, this.userSecond];
      return [this.employeeFirst, this.employeeSecond, this.employeeFourth, this.employeeFifth, this.employeeSixth];
    },
    images() {
      return this.$store.state.images;
    },
  },
};
</script>
<style scoped>
::v-deep .v-select.v-input--dense .v-select__selection--comma {
  font-size: 15px;
}

.borderStyle {
  padding: 10px 0px;
  margin-right: 15px;
  height: 3rem;
}
.mycardthree {
  overflow-y: auto;
  height: 460px;
  overflow-x: hidden;
  scrollbar-width: none;
}
.mycardthree::-webkit-scrollbar {
  display: none;
}
.label {
  white-space: normal;
  word-break: break-word;
  display: inline-block;
  vertical-align: middle;
  /* float: right; */
  /* position:absolute ; */
  max-width: 140px;
}
.fixed-header {
  position: sticky;
  top: 0;
  background-color: white; /* or your card background color */
  z-index: 5;
  padding-top: 10px; /* optional padding */
}
</style>
