<template>
  <div>
    <!-- <navber /> -->
    <div style="padding-left:1.4%;padding-right:1%; ">
      <div style=";margin-right:0.5%; ">
        <p
          style="font-size:28px;font-family:'Kanit'; color:#43425D;padding:18px;"
        >Benefit</p>
      </div>
      <v-tabs grey--text background-color="#f0f8fc" slider-color="#F89D1F" show-arrows>
        <v-tab
          class="text-capitalize "
          style="border-radius: 10px 10px 0px 5px;width:150px;clip-path: polygon(87% 0, 94% 81%, 100% 100%, 0 100%, 0 0);"
        >
         {{$t('Guarantee')}}
        </v-tab>
        <v-tab
          class="text-capitalize "
          style="border-radius: 10px 10px 0px 5px;width:150px;clip-path: polygon(87% 0, 94% 81%, 100% 100%, 0 100%, 0 0);margin-left:-10px"
        >{{$t('loan_installment')}}</v-tab>
        <v-tab
          class="text-capitalize "
          style="border-radius: 10px 10px 0px 0px;width:150px;clip-path: polygon(87% 0, 94% 81%, 100% 100%, 0 100%, 0 0);margin-left:-10px"
        >
          {{$t('uniform_fee')}}
        </v-tab>
        <v-tab
          class="text-capitalize "
          style="border-radius: 10px 10px 0px 5px;width:150px;clip-path: polygon(87% 0, 94% 81%, 100% 100%, 0 100%, 0 0); margin-left:-10px"
        >
          {{$t('tuition_loan')}}
        </v-tab>
        <v-tab
          class="text-capitalize "
          style="border-radius: 10px 10px 0px 5px;width:150px;clip-path: polygon(87% 0, 94% 81%, 100% 100%, 0 100%, 0 0);margin-left:-10px"
        >
          {{$t('other_benefits')}}
        </v-tab>
        <v-tab
          class="text-capitalize "
          style="border-radius: 10px 10px 0px 5px;width:150px;clip-path: polygon(87% 0, 94% 81%, 100% 100%, 0 100%, 0 0); margin-left:-10px"
        >{{$t('your_property')}}</v-tab>
        <v-tab-item>
          <v-card>
            <Guarantee />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <LoanInstallment />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <UniformFee />
        </v-tab-item>
        <v-tab-item>
          <TuitionLoan />
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <OtherBenefits />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <YourProperty />
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-layout justify-end>
        <v-flex>
          <div style="margin-top:2.5rem;">
            <img src="@/assets/images/Group 202.png" style="float:right;" />
          </div>
        </v-flex>
      </v-layout>
      <br />
      <br />
    </div>
  </div>
</template>
<script>
import Guarantee from "./Guarantee";
import LoanInstallment from "./LoanInstallment";
import TuitionLoan from "./TuitionLoan";
import UniformFee from "./UniformFee";
import OtherBenefits from "./OtherBenefits";
import YourProperty from "./YourProperty";
export default {
  name: "personal",
  components: {
    Guarantee,
    LoanInstallment,
    TuitionLoan,
    UniformFee,
    YourProperty,
    OtherBenefits,
  },
  data: () => ({})
};
</script>
<style scoped>
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #F89D1F !important;
  border-radius: 15px 50px 0px 0px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}
.v-tabs-bar .v-tab {
  background-color: white;
}
.active {
  cursor: pointer !important;
}
  .v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
  }
</style>

