<template>
  <div style="margin-left: 2rem; margin-right: 2rem; margin-bottom: 2rem">
    <div class="pt-6">
      <v-row class="d-flex">
        <v-col cols="12" xs="12" sm="4" md="3" lg="3" xl="3">
          <div class="d-flex align-items-center">
            <div class="pt-2">{{ $t("Year") }}</div>
            <div>
              <v-autocomplete class="ml-2" v-model="filteryear" :items="yearitems" outlined dense color="#F99D20"
                @change="onChangeYear(filteryear)">
              </v-autocomplete>
            </div>
            <v-btn class="ml-2" icon @click="showEye = !showEye">
              <img class="visibilityIcon" v-show="showEye" :src="images.visibility" alt="" />
              <img class="visibilityIcon" v-show="!showEye" :src="images.visibility_off" alt="" />
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <div class="d-flex align-items-center">
            <div class="square-box-holiday mr-2"></div>
            <span class="mr-4">{{ $t("Holiday") }}</span>

            <div class="square-box mr-2"></div>
            <span>{{ $t("WorkingDay") }}</span>
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="5" lg="5" xl="5">
          <div class="d-flex justify-end align-items-center">
            <v-btn color="#F99D20" class="white--text text-capitalize pl-7 pr-8 mr-2"
              style="max-width: 160px; height: 35px; margin-bottom: 1rem;" :disabled="permission.upload"
              @click="uploadholidaysDialog = true">
              <v-icon>mdi-plus</v-icon>
              {{ $t("UploadHolidays") }}
            </v-btn>
            <v-btn color="#F99D20" class="white--text text-capitalize" style="max-width: 160px; height: 35px;"
              :disabled="permission.upload" @click="exportHolidaysPDF">
              <img class="exportIcon mr-2" :src="images.export" alt=""/>
              {{ $t("ExportFile") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex">
        <p style="color:#F99D20;">{{ $t("SetHolidays") }}</p>
      </v-row>

      <div class="calendar-container" v-show="showEye">
        <vc-calendar :locale="currentLocale" :masks="customMasks" :attributes='attributes' ref="calendar" :columns="$screens({ lg: 3 }, 1)"
          :rows="$screens({ lg: 4 }, 1)" @dayclick="dayclickHoliday" />
      </div>
      <div v-show="!showEye">
        <div class="calendar-container1">
          <div v-for="(month, index) in months" :key="index" class="card">
            <div class="vc-pane">
              <div class="vc-header align-center">
                <div class="vc-title">{{ month.title }}</div>
              </div>
              <v-divider></v-divider>

              <div class="holiday-list-container">
                <div v-for="holiday in holidaysByMonth[index]" :key="holiday.wC_ID" class="ml-3">
                  <div style="cursor:pointer;" @click="monthclicksetHoliday(holiday)">{{ formatDate(holiday.wC_Date) }} - 
                    {{ holiday.wC_Name || 'Holiday' }}</div>
                </div>
              </div>

              <div class="footer-icon">
                <v-img :src="images.plusEmp" class="plusicon" style="cursor: pointer;" @click="monthclickHoliday(month.year,index)"></v-img>
              </div>
            </div>
          </div>
        </div>    
      </div>

      <!-- set holidays/wrokday -->
      <v-dialog v-model="setDayTo" presistent max-width="786">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p v-if="setHoliday">
              {{ $t("SetUpHolidays") }}
            </p>
            <p v-else>
              {{ $t("SetUpWorkdays") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-text-field v-show="false" readonly color="#F99D20"></v-text-field>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" v-if="!monthsetHolidays">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("date") }}</label>
                <v-text-field
                  readonly
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  filled
                  outlined
                  v-model="clickedDate">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" v-else>
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("date") }}</label>
                <date-picker v-bind:clearable="false" type="date" :disabled-date="disableDates" 
                format="DD/MM/YYYY" :style="{ width: '100%' }" readonly v-model="clickedDate" 
                value-type="format" class="custom-vuedatepicker1"></date-picker>
              </v-col>
            </v-row>   
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" v-if="!dayStatus">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("HolidayName") }}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  outlined
                  v-model="holidayName">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" v-if="!dayStatus">
                <label style="font-size: 16px; color: #7F7F7F;">{{ $t("HolidayNameENG") }}<span class="red--text"> *</span></label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  outlined
                  v-model="holidayNameEN"
                  type="text">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <div class="flex-container" v-if="!setHoliday" style="font-family:Kanit;">
                  <span>Changed to working day:</span>
                  <v-switch color="#F99D20" inset v-model="dayStatus"></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="setDayTo = false">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="mr-2 text-capitalize commonsavebtn" @click="setDays()">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- uploadholidaysDialog -->
      <v-dialog v-model="uploadholidaysDialog" presistent max-width="786">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p style="margin-top: 10px; margin-left: 6px">
              {{ $t("UploadFile") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-row style="margin-top: -1.5rem">
              <v-col style="margin-left: 6px" align="center" cols="5">
                <v-file-input v-show="showup" color="white" class="myfile" id="myFileInput" v-model="chosenfile"
                  prepend-icon accept=".xlsx">
                  <template v-slot:append>
                    <v-btn icon>
                      <img width="20" height="20" @click="closeinput" src="@/assets/closered.png" alt />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row style="margin-top: -2rem">
              <v-col cols="12" md="3">
                <v-btn @click="getupload" color="#ACACAC" class="white--text text-capitalize" width="150"
                  max-height="35">
                  {{ $t("browse") }}
                </v-btn>
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("filetype") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("DocumentTemplate") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a :href="CheckLang()" style="color: #f99d20">
                  {{ $t("download") }}
                </a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-2 text-capitalize commoncancelbtn" text @click="closeUpload">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="mr-2 text-capitalize commonsavebtn" @click="UploadHolidays()">
              {{ $t("Upload") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- Loading Dialog -->
      <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

    </div>
  </div>
</template>

<script>
import store from "@/store";
import XLSX from "xlsx";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddUpload from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";
import { Calendar } from 'v-calendar'; // Import the specific component
import 'vue2-datepicker/index.css'; // Import default styles
import jsPDF from "jspdf";
import ThaiFontBase64 from "@/function/global.js";

export default {
  components: {
    LoadingDialog,
    'vc-calendar': Calendar,
  },
  mixins: [LogTrace, utils, CheckViewAddUpload,ThaiFontBase64],
  data() {
    return {
      annualholidaysDialog: false,
      showEye: true,
      permission: {
        add: true,
        upload: true,
      },
      currentLocale: 'en-US',
      customMasks: {
        title: 'MMMM YYYY', // Month and Year format
        weekdays: 'WWW', // Short day names
      },
      enabledYear: null,
      enabledMonth: null,
      monthsetHolidays: false,
      monthNamesTH: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      monthNamesEN: ['January', 'February', 'March', 'April', 'May', 'June','July', 'August', 'September', 'October', 'November', 'December'],
      months: [],
      holidayLists: [],
      companyName: null,
      holidayName: null,
      holidayNameEN: null,
      tempList: [],
      clickedDate: null,
      dayStatus: false,
      setHoliday: false,
      setDayTo: false,
      currentYearDate: null,
      attributes: [],
      HolidayName: null,
      Nameshow: true,
      LoadingDialog: false,
      WDataList: [],
      url: enurl.apiUrl,
      holidayname: null,
      HolidayList: [],
      tempimport: [],
      WToHolidayDialog: false,
      enday: [],
      starday: [],
      date: new Date().toISOString().substr(0, 10),
      filteryear: new Date().getFullYear(),
      exportyear: new Date().getFullYear(),
      uploadyear: new Date().getFullYear(),
      yearitems: [],
      uploadyearitems: [],
      clickdate: null,
      uploadholidaysDialog: false,
      sunOption: false,
      disableday: 0,
      dayinfeb: null,
      NewholidaysDialog: false,
      showup: false,
      chosenfile: null,
      startdate: "",
      date1: "",
      menu: false,
      menu5: false,
      headers: [
        {
          value: "date1",
          align: "left",
          sortable: false,
        },
        {
          value: "holiday",
          align: "left",
          sortable: false,
        },
      ],
      YearList: [],
      finalList: [],
      filterdate: [],
      AnnualHolidaysList: [],
    }
  },

  async mounted() {
    this.LoadingDialog = true;
    try
    {
      let self = this;
      await self.GetHolidaysByCompany();
      await self.getCompanyName();
      self.updateMonths();
      self.permission = self.CheckViewAddUpload(20, 21, 22);
      //const currentYear1 = new Date().getFullYear();
      //self.months = self.monthNames.map(month => ({ title: `${month} ${currentYear1}`, year: `${currentYear1}` }));
      const currentYear = new Date().getFullYear() + 1;
      // Set the date to January 1st of the previous year
      this.currentYearDate = new Date(currentYear - 1, 0, 1); // January 1st of the previous year
      const calendar = this.$refs.calendar;
      await calendar.move(this.currentYearDate, { position: 1 });
      // const calendar1 = this.$refs.calendar1;
      // await calendar1.move(this.currentYearDate, { position: 1 });
      // Create an array of the last 6 years including the next year
      self.yearitems = Array.from({ length: 6 }, (v, i) => currentYear - i);
    }
    catch (error)
    {    
      console.error("Error loading data:", error);
    }
    finally
    {
      // End loading
      this.LoadingDialog = false;
    }
  },

  watch: {
    startdate() {
      this.starday = [];
    },

    currentLocale() {
      //this.updateMonths();
      this.updateMonthsChangeYear(this.filteryear);
    },

    date1() {
      this.enday = [];
    },

    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      }
      else {
        this.showup = false;
      }
    },

    // dayStatus(newValue) {
    // }
  },

  computed: {
    images() {
      return this.$store.state.images;
    },
    holidaysByMonth() {
      // Group holidays by month (0 = January, 11 = December)
      const grouped = Array.from({ length: 12 }, () => []);
      this.AnnualHolidaysList.forEach(holiday => {
        const holidayMonth = new Date(holiday.wC_Date).getMonth();
        grouped[holidayMonth].push(holiday);
      });
      return grouped;
    }
  },

  methods: {
    generateDays(daysInMonth, isInMonth) {
      const days = [];
      for (let i = 1; i <= daysInMonth; i++) {
        days.push({ date: i, isInMonth });
      }
      return days;
    },

    updateMonths() {
      const currentYear = new Date().getFullYear();
      if (this.currentLocale === 'th-TH')
      {
        // If Thai, add 543 to the year to convert to Buddhist calendar year
        this.months = this.monthNamesTH.map(month => ({
          title: `${month} ${currentYear}`,
          year: `${currentYear}`
        }));
      }
      else
      {
        // Default to Gregorian calendar (English)
        this.months = this.monthNamesEN.map(month => ({
          title: `${month} ${currentYear}`,
          year: `${currentYear}`
        }));
      }
    },

    updateMonthsChangeYear(item) {
      if (this.currentLocale === 'th-TH')
      {
        // If Thai, add 543 to the year to convert to Buddhist calendar year
        this.months = this.monthNamesTH.map(month => ({
          title: `${month} ${item}`,
          year: `${item}`
        }));
      }
      else
      {
        // Default to Gregorian calendar (English)
        this.months = this.monthNamesEN.map(month => ({
          title: `${month} ${item}`,
          year: `${item}`
        }));
      }
    },

    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },

    // Helper method to check if the clicked date is within any highlighted date ranges
    isHighlighted(date) {
      return this.attributes[0].dates.some(range => {
        const startDate = new Date(range.start);
        const endDate = new Date(range.end);
        // Check if the clicked date is between start and end dates (inclusive)
        return date >= startDate && date <= endDate;
      });
    },

    checkWeekEnd(tempCheck){
      //alert(tempCheck)
      const dayOfWeek = tempCheck.getDay();
      return dayOfWeek === 0 || dayOfWeek === 6 ? 'weekend' : 'weekday';
    },

    monthclickHoliday(temp,index){
      this.enabledYear = temp; // Set enabled year from temp
      this.enabledMonth = index + 1; // Set enabled month from index
      this.clickedDate = `01/${this.enabledMonth < 10 ? '0' : ''}${this.enabledMonth}/${this.enabledYear}`;
      this.holidayName = null;
      this.holidayNameEN = null;
      this.setDayTo = true;
      this.setHoliday = true;
      this.monthsetHolidays = true;
    },

    disableDates(date) {
      if (!this.clickedDate)
      {
        return false;
      }
      const [day, month, year] = this.clickedDate.split('/').map(Number);
      const clickedDateObj = new Date(year, month - 1, day); // month is 0-indexed in JavaScript
      return date.getMonth() !== clickedDateObj.getMonth() || date.getFullYear() !== clickedDateObj.getFullYear();
    },

    dayclickHoliday(day) {
      const tempDate = day.date;
      this.monthsetHolidays = false;
      this.holidayName = null;
      this.holidayNameEN = null;
      this.clickedDate = this.$moment(new Date(tempDate)).format('DD/MM/YYYY');
      //const dayOfWeek = tempDate.getDay();
      const dayType= this.checkWeekEnd(tempDate);
      const isHighlighted = this.isHighlighted(tempDate);
      if(dayType === 'weekday' && !isHighlighted)
      {
        this.setHoliday = true;
        this.dayStatus = false;
      }
      else if(dayType === 'weekend' && !isHighlighted)
      {
        this.setHoliday = false;
        this.dayStatus = false;
        this.holidayName = this.holidayName == null ? 'สุดสัปดาห์' : this.holidayName;
        this.holidayNameEN = this.holidayNameEN == null ? 'Weekend' : this.holidayNameEN;
      }
      else if(dayType === 'weekend' && isHighlighted)
      {
        this.setHoliday = true;
        this.dayStatus = false;
      }
      else
      {
        this.setHoliday = false;
        this.dayStatus = false;
        const clickedDateFormatted = this.$moment(new Date(tempDate)).format('YYYY-MM-DD');
        const result = this.tempList.find(item => this.$moment(item.wC_Date).format('YYYY-MM-DD') === clickedDateFormatted);
        this.holidayName = result.wC_Name;
        this.holidayNameEN = result.wC_Name_EN;
      }
      this.setDayTo = true;
    },

    monthclicksetHoliday(item){
      this.setDayTo = true;
      this.dayStatus = false;
      this.setHoliday = false;
      this.clickedDate = this.$moment(new Date(item.wC_Date)).format('DD/MM/YYYY');
      this.holidayName = item.wC_Name;
      this.holidayNameEN = item.wC_Name_EN;
    },

    async getCompanyName(){
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}Company/GetCompanyID`, temp)
      .then(function (response) 
      {
        self.companyName = response.data.data.companyName;
      });
    },

    exportHolidaysPDF() {
      // Create a new jsPDF instance
      var self = this;
      const doc = new jsPDF({
        orientation: 'portrait', // or 'landscape'
        unit: 'mm',
        format: 'a4'
      });
      // Your header and company name
      const Header = "Holidays Calendar " + self.filteryear;
      //const CompanyName = "[ "+ "" + self.companyName + "" + " ]";
      const CompanyName = self.companyName;
      // Get the page width
      const pageWidth = doc.internal.pageSize.width;
      // Get the width of the Header text
      const headerTextWidth = doc.getTextWidth(Header);
      // Calculate the X coordinate to center the Header text
      const headerXPosition = (pageWidth - headerTextWidth) / 2;
      // Get the width of the CompanyName text
      const companyTextWidth = doc.getTextWidth(CompanyName);
      // Calculate the X coordinate to center the CompanyName text
      const companyXPosition = (pageWidth - companyTextWidth) / 2;
      // Set font style, size, and color for Header
      doc.setFont('Kanit', 'bold');    
      doc.setFontSize(18);                 
      doc.setTextColor(0, 0, 0);         
      // Add the Header text at the calculated X position (centered horizontally)
      doc.text(Header, headerXPosition, 20); // (text, x, y)
      // Set font style, size, and color for CompanyName
      doc.setFont('Kanit', 'normal');   
      doc.setFontSize(14);                 
      //doc.setTextColor(255, 0, 0);     
      // Add the CompanyName text at the calculated X position (centered horizontally)
      doc.text(CompanyName, companyXPosition, 35); // (text, x, y)
      // Add two horizontal lines
      doc.setDrawColor(0);  // Set the line color to black
      doc.setLineWidth(0.8); // Set line width to 0.5mm
      // First line (starts at x=10, y=45 and ends at x=200, y=45)
      doc.line(20, 40, 190, 40);
      // Second line (starts at x=10, y=50 and ends at x=200, y=50)
      //doc.line(10, 41, 200, 41);
      // List of holidays (from self.AnnualHolidaysList)
      const holidays = self.AnnualHolidaysList;  // Assuming this is an array of holiday objects
      let yPosition = 55;  // Initial Y position to start writing holidays
      const lineSpacing = 10;  // Space between lines
      // doc.setFont('Kanit', 'normal');   
      // doc.setFontSize(14);
      doc.addFileToVFS("THSarabunPSK.ttf", self.ThaiFontBase64());
      doc.addFileToVFS("THSarabunPSKBold.ttf", self.ThaiFontBoldBase64());
      doc.addFont("THSarabunPSKBold.ttf", "THSarabunPSKBold", "normal");
      doc.addFont("THSarabunPSK.ttf", "THSarabunPSK", "normal");
      let normalfont = "THSarabunPSK";
      doc.setTextColor(0, 0, 0);
      // Loop through the holidays and add them to the PDF
      holidays.forEach((holiday, index) => {
        const date = new Date(holiday.wC_Date);
        // Get the day of the week, day of the month, and month name
        const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' });
        const dayOfMonth = date.toLocaleString('en-US', { day: 'numeric' });
        const monthName = date.toLocaleString('en-US', { month: 'long' });
        const holidayDayWeek = `${dayOfWeek}`;
        const holidayDayMonth = `${dayOfMonth}`;
        const holidayMonthName = `${monthName}`;
        //const holidayText = `${formattedDate}`;
        const holidayName = holiday.wC_Name ? `${holiday.wC_Name}` : '-';
        const holidayNameEN = holiday.wC_Name_EN ? `${holiday.wC_Name_EN}` : '-';
        // Add the holiday text to the PDF
        doc.setFont('Kanit', 'normal');
        doc.setFontSize(14);
        doc.text(`${index + 1}.`, 20, yPosition);
        doc.text(holidayDayWeek, 29, yPosition);
        doc.text(holidayDayMonth, 55, yPosition);
        doc.text(holidayMonthName, 70, yPosition);
        //doc.text(holidayText, 10, yPosition);
        doc.setFont('Kanit', 'normal');
        doc.setFontSize(14);   
        doc.text(holidayNameEN, 100, yPosition);
        doc.setFont(normalfont);
        doc.setFontSize(16);  
        doc.text(holidayName, 140, yPosition);
        // Move down for the next holiday entry
        yPosition += lineSpacing;
        // Check if Y-position is going off the page, then add a new page
        if (yPosition > 280)
        {  // Near the bottom of the A4 page
          doc.addPage();
          yPosition = 20;  // Reset Y position for new page
        }
      });

      let fileName = "Holidays" + "-" + self.filteryear;
      // Save the generated PDF
      doc.save(fileName);
    },

    CancelExport() {
      let self = this;
      self.annualholidaysDialog = false;
      self.exportyear = new Date().getFullYear();
    },

    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย")
      {
        this.currentLocale = 'th-TH';
        url = "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Holiday.xlsx"; //dev
        //url = "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Holiday.xlsx"; //stg
      }
      else
      {
        this.currentLocale = 'en-US';
        url = "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Holiday.xlsx"; //dev
        //url = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Holiday.xlsx"; //stg
      }
      return url;
    },

    async GetHolidaysByCompany() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}WorkdayCalendar/GetWorkDayByCompanyID`, temp)
      .then(function (response) {
        if (response.data.status == 0) 
        {
          self.tempList = response.data.data;
          if (self.tempList.length != 0) 
          {
            // Create a temporary array to hold the new dates
            let newDates = [];
            //self.holidayLists = 
            self.AnnualHolidaysList = self.tempList.filter(item => {
              // Convert wC_Date to a Date object and pass it to checkWeekEnd
              const isWeekday = self.checkWeekEnd(new Date(item.wC_Date)) === 'weekday';
              const itemYear = new Date(item.wC_Date).getFullYear();
              // Filter based on wC_Type, checkWeekEnd result, and year matching filteryear
              return item.wC_Type === 'H' && isWeekday && itemYear === self.filteryear;
            }).sort((a, b) => {
              // Convert wC_Date to Date objects and compare them
              const dateA = new Date(a.wC_Date);
              const dateB = new Date(b.wC_Date);
              return dateA - dateB; // Ascending order (earlier dates first)
            });
            self.tempList.forEach(item => {
              const dayType= self.checkWeekEnd(new Date(item.wC_Date));
              if (item.wC_Type === 'H' && dayType === 'weekday')
              {
                const holidayDate = new Date(item.wC_Date);
                newDates.push({ start: holidayDate, end: holidayDate });
              }
              else if(item.wC_Type === 'W' && dayType === 'weekend')
              {
                const workingDate = new Date(item.wC_Date);
                newDates.push({ start: workingDate, end: workingDate });
              }
            });
            self.attributes = [
              {
                highlight: true,
                dates: newDates
              }
            ];
          }
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    setDays()
    {
      let self = this;
      if(self.holidayNameEN != null && self.holidayNameEN != null)
      {
        self.LoadingDialog = true;
        let dayInList = self.tempList.find(item => this.$moment(item.wC_Date).format('DD/MM/YYYY') === self.clickedDate)
        if(self.setHoliday || !self.dayStatus)
        {
          if(dayInList)
          {
            let updateworkdaytemp = {
              wC_Name: self.holidayName,
              wC_Name_EN: self.holidayNameEN,
              wC_Type: "H",
              wC_Date: self.$moment(self.clickedDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              company_ID: store.state.companyID
            };
            axios.post(`${self.url}WorkdayCalendar/UpdateWorkdayCalendar`, updateworkdaytemp)
            .then(function (response) {
              self.LoadingDialog = false;
              if (response.data.status == 0)
              {
                self.setDayTo = false;
                self.GetHolidaysByCompany();
                alert(response.data.message)
              }
              else
              {
                alert(response.data.message)
              }
            });
          }
          else
          {
            let updateworkdaytemp = {
              wC_Name: self.holidayName,
              wC_Name_EN: self.holidayNameEN,
              wC_Type: "H",
              wC_Date: self.$moment(self.clickedDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              company_ID: store.state.companyID
            };
            axios.post(`${self.url}WorkdayCalendar/AddWorkdayCalendar`, updateworkdaytemp)
            .then(function (response) {
              self.LoadingDialog = false;
              if (response.data.status == 0)
              {
                self.setDayTo = false;
                self.GetHolidaysByCompany();
                alert(response.data.message)
              }
              else
              {
                alert(response.data.message)
              }
            });
          }
        }
        else
        {
          if(dayInList)
          {
            let updateworkdaytemp = {
              wC_Name: self.holidayName,
              wC_Name_EN: self.holidayNameEN,
              wC_Type: "W",
              wC_Date: self.$moment(self.clickedDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              company_ID: store.state.companyID
            };
            axios.post(`${self.url}WorkdayCalendar/UpdateWorkdayCalendar`, updateworkdaytemp)
            .then(function (response) {
              self.LoadingDialog = false;
              if (response.data.status == 0)
              {
                self.setDayTo = false;
                self.GetHolidaysByCompany();
                alert(response.data.message)
              }
              else
              {
                alert(response.data.message)
              }
            });
          }
          else
          {
            let updateworkdaytemp = {
              wC_Name: self.holidayName,
              wC_Name_EN: self.holidayNameEN,
              wC_Type: "W",
              wC_Date: self.$moment(self.clickedDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
              company_ID: store.state.companyID
            };
            axios.post(`${self.url}WorkdayCalendar/AddWorkdayCalendar`, updateworkdaytemp)
            .then(function (response) {
              self.LoadingDialog = false;
              if (response.data.status == 0)
              {
                self.setDayTo = false;
                self.GetHolidaysByCompany();
                alert(response.data.message)
              }
              else
              {
                alert(response.data.message)
              }
            });
          }
        }
      }
      else
      {
        alert("Fill all informations.")
      }
    },

    closeUpload() {
      this.uploadholidaysDialog = false;
      this.HolidayList = [];
      this.startdate = null;
      this.date1 = null;
      this.starday = [];
      this.enday = [];
      (this.chosenfile = null), (this.showup = false);
    },

    getupload() {
      document.getElementById("myFileInput").click();
      //this.showup = true;
    },

    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
 
    excelDateToISO(excelDate) {
      // Calculate the JS date from the Excel date
      const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
      // Use moment.js to set time to '00:00:00' and format the date
      return this.$moment(jsDate).startOf('day').format('YYYY-MM-DDT00:00:00');
    },

    UploadHolidays() {
      var self = this;
      if (self.chosenfile)
      {
        const files = self.chosenfile;
        // Your file processing logic goes here
        const reader = new FileReader();
        reader.onload = (e) => {
          let filename = files.name;
          if (filename == "Holiday.xlsx")
          {
            const data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.importData = XLSX.utils.sheet_to_json(worksheet);
            const file_data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            self.HolidayList = self.importData.map((_, index) => {
              const [wC_Date, wC_Name, wC_Name_EN] = file_data[index + 1];
              return {
                wC_Name,
                wC_Name_EN,
                wC_Type: "H",
                company_ID: store.state.companyID,
                wC_Date: self.excelDateToISO(wC_Date)
              };
            });
            if(self.HolidayList.length > 0)
            {
              self.ConfirmUploadHoliday();
            }
          }
          else
          {
            alert("File name must be Holiday.xlsx");
          }
        };
        reader.readAsArrayBuffer(self.chosenfile);
      }
      else
      {
        alert("Please choose File");
      }
    },

    onChangeYear(item) {
      let self = this;
      const calendar = self.$refs.calendar;
      calendar.move(new Date(item, 0, 1), { position: 1 });
      self.updateMonthsChangeYear(item);
      const selectedYear = item;
      self.AnnualHolidaysList = self.tempList.filter(item => {
        // Convert wC_Date to a Date object and pass it to checkWeekEnd
        const isWeekday = self.checkWeekEnd(new Date(item.wC_Date)) === 'weekday';
        const itemYear = new Date(item.wC_Date).getFullYear();
        // Filter based on wC_Type, checkWeekEnd result, and year matching filteryear
        return item.wC_Type === 'H' && isWeekday && itemYear === selectedYear;
      }).sort((a, b) => {
        // Convert wC_Date to Date objects and compare them
        const dateA = new Date(a.wC_Date);
        const dateB = new Date(b.wC_Date);
        return dateA - dateB; // Ascending order (earlier dates first)
      });
    },

    ConfirmUploadHoliday() {
      let self = this;
      self.LoadingDialog = true;
      axios.post(`${self.url}WorkdayCalendar/ImportWorkdayCalendar`, self.HolidayList)
      .then(function (response) {
        self.LoadingDialog = false;
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.GetHolidaysByCompany();
          self.uploadholidaysDialog = false;
          self.HolidayList = [];
          self.showup = false;
          self.chosenfile = null;
        }
        else
        {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Upload Holidays Fail", 25, "Verbose");
        self.LoadingDialog = false;
      });
    }
  },
};
</script>

<style scoped>
.simpletb table,
th,
td {
  border: 1px solid #f0f0f7;
  height: 4rem;
  width: 3rem;
}

.txt {
  color: #444;
  text-align: center;
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

>>>.v-dialog::-webkit-scrollbar {
  width: 2px;
  background-color: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Track */
>>>.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle */
>>>.v-dialog::-webkit-scrollbar-thumb {
  background: #f0f0f7;
  border-radius: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle on hover */
>>>.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0f0f7;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c9c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

@media only screen and (min-width: 1024px) {
  .w {
    margin-left: 8.9rem !important;
  }
}

@media only screen and (max-width: 1281px) {
  .holidaytable {
    overflow-y: auto;
    height: 300px !important;
    overflow-x: hidden;
  }
}

.holidaytable {
  overflow-y: auto;
  height: 550px;
  overflow-x: hidden;
}

.square-box {
  width: 20px;
  /* Set the width */
  height: 20px;
  /* Set the height to the same as width to make it a square */
  border: 1px solid #000000;
  background-color: #FFFFFF;
  opacity: 0.1;
}

.square-box-holiday {
  width: 20px;
  /* Set the width */
  height: 20px;
  /* Set the height to the same as width to make it a square */
  background-color: #FFF2E3;
  opacity: 1;
}

.vc-container {
  width: 100% !important;
  margin-bottom: 1rem !important;
  border: none !important;
}

>>>.vc-header{
  pointer-events: none !important;
}

>>>.vc-arrows-container {
  display: none !important;
}

>>>.vc-pane {
  border: 1px solid #acacac;
  background-color: #FFFFFF;
  border-radius: 7px;
  margin: 2rem;
  box-sizing: border-box;
}

>>>.vc-title {
  color: #F99D20;
}

/* Hide the original single-letter text */
>>>.vc-weekday {
  /*color: transparent !important;
   Hide the original text */
  background-color: #EEEEEE;
  color: #000000 !important;
}

>>>.vc-weeks {
  padding: 0 !important;
}

/* General day styles */
>>>.vc-day {
  position: relative;
  /* Required for the pseudo-element positioning */
  box-sizing: border-box;
  /* Ensure borders are included in width/height */
}

/* Pseudo-element for the border */
>>>.vc-day::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #000000;
  opacity: 0.1;
  /* Apply opacity to the border */
  pointer-events: none;
  /* Ensure pseudo-element does not interfere with interactions */
}

/* First Column: Every 1st day in a week */
>>>.vc-day:nth-child(7n+1) {
  border-left: none;
  /* Example: add left border or custom style */
  background-color: #EEEEEE;
}

/* Last Column: Every 7th day in a week (when it has highlights) */
::v-deep .vc-day:nth-child(7n+1) .vc-highlights {
  background-color: #FFFFFF !important;
}

::v-deep .vc-day:nth-child(7n+1) .vc-highlight {
  border-radius: 0 !important;
  background-color: #FFFFFF !important;
  color: #020202 !important; /* Ensure text color is set for visibility */
  font-weight: bold !important; /* Increase font weight for readability */
}

>>> .vc-day:nth-child(7n) {
  border-right: none;
  background-color: #EEEEEE;
}

::v-deep .vc-day:nth-child(7n) .vc-highlights {
  background-color: #FFFFFF !important; /* Slightly darker background for contrast */
}

::v-deep .vc-day:nth-child(7n) .vc-highlight {
  border-radius: 0 !important;
  background-color: #FFFFFF !important;
  color: #020202 !important; /* Ensure text color is set for visibility */
  font-weight: bold !important; /* Increase font weight for readability */
}

/* Last Row: Apply specific border style for last row */
>>>.vc-day:nth-last-child(-n+7)::before {
  border-bottom: none;
}

/* Content styles */
>>>.vc-day-content {
  color: #020202;
}

>>>.vc-highlights {
  background-color: #FFF2E3 !important;
}

>>>.vc-highlight {
  background-color: #FFF2E3 !important;
  border-radius: 0 !important;
  color: #020202 !important;
  font-weight: 500 !important;
}

>>>.vc-day-content {
  color: #020202 !important;
  font-weight: 500 !important;
}

.flex-container {
  display: flex;
  align-items: center; /* Align items vertically center in the row */
  gap: 8px; /* Optional: Adds space between the text and the switch */
  margin-top: -2rem;
}

.calendar-container1 >>>.vc-pane{
  height: 250px;
}

.calendar-container1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust the grid for 12 cards */
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.vc-title {
  font-size: 18px;
  color: #F99D20;
  font-weight: 600;
  line-height: 28px;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  margin-top: 10px;
}

.holiday-list-container {
  max-height: 200px; /* Set your desired max height */
  overflow-y: auto;  /* Enable vertical scrolling */
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
.holiday-list-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for Firefox */
.holiday-list-container {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Internet Explorer and Edge */
.holiday-list-container {
  -ms-overflow-style: none;  /* IE and Edge */
}

/* Footer icon at the bottom-right */
.footer-icon {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  margin-top: auto; /* Push footer to the bottom */
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
}
</style>