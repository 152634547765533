<template>
  <div>
    <div class="pt-3 pb-6">
      <span :class="[chipStyle, 'borderStyle']"></span>
      <span>{{ Time.isFull }}</span>
      <div class="float-right" style="color: #0fa7d8"> {{ Time.percent }}
      </div>

    </div>
  </div>
</template>


<script>
export default {
  props: {
    chipStyle: {
      type: String
    },
    Time: {
      type: Object
    }
  }



}
</script>

<style scoped>
.fullStyle {
  border-left: 10px solid #869D00;
}

.partStyle {
  border-left: 10px solid #F99D20;
}

.deptStyle {
  border-left: 10px solid #FF0000;
}


.daily {
  border-left: 10px solid #F99D20;
}

.parttime {
  border-left: 10px solid #FF0000;
}

.contract {
  border-left: 10px solid #258039;
}

.fixedpay {
  border-left: 10px solid #31A9B8;
}

.borderStyle {
  padding: 10px 0px;
  margin-right: 15px;
}
</style>