<template>
  <div>
    <div style="padding-left: 20px; padding-right: 1%; margin-bottom: 1rem">
      <v-row>
        <v-btn icon router color="white" @click="back()" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p style="font-size: 26px; margin-left: 4px; margin-top: 20px">
          {{ $t("Educational_background") }}
        </p>
      </v-row>
    </div>
    <v-card
      class="mt-6 mb-5"
      style="margin-bottom: 5rem; margin-left: 1.5rem; margin-right: 1.5rem"
    >
      <v-row class="pt-5 ma-3">
        <v-col cols="12" xs="12" sm="6" md="8">
          <p style="font-size: 21px">
            {{ $t("Educational_background") }}
          </p>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="4">
          <v-row>
            <v-col cols="12" xs="12" sm="6" xl="4" class="offset-xl-4">
              <v-btn
                color="#F99D20"
                class="orange--text text-capitalize mr-5"
                block
                max-height="35"
                outlined
                @click="EbUploaddialog = true"
                >{{ $t("upload") }}</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="6" xl="4">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mr-5"
                block
                max-height="35"
                router
                @click="addDialogForEB = true"
                >{{ $t("Add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        style="margin-left: 20px; margin-right: 40px; margin-top: 30px"
        :headers="header1"
        class="tbheader"
        :items="EdBackgroundEdu"
        hide-default-footer
        hide-default-header
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th>{{ $t("institute") }}</th>

              <th>{{ $t("degree") }}</th>
              <th>{{ $t("GPA") }}</th>
              <th>{{ $t("start_year") }}</th>
              <th>{{ $t("end_year") }}</th>
              <th style="text-align: center">{{ $t("action") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, index }">
          <tr>
            <td>{{ item.institute }}</td>
            <td>{{ item.degree }}</td>
            <td>{{ item.gpa }}</td>
            <td>{{ item.start_Year }}</td>
            <td>{{ item.end_Year }}</td>
            <td style="text-align: center">
              <div>
                <v-btn @click="editTask(item, index)" icon>
                  <img class="editIcon" :src="images.edit" alt="editIcon"/>
                </v-btn>
                <v-btn @click="OpenDelDig(item)" icon>
                  <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- short training -->

      <v-row class="mt-12 ma-3">
        <v-col cols="12" xs="12" sm="6" md="8">
          <p style="font-size: 21px">
            {{ $t("short_training") }}
          </p>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-row>
            <v-col cols="12" xs="12" sm="6" xl="4" class="offset-xl-4">
              <v-btn
                color="#F99D20"
                class="orange--text text-capitalize mr-5"
                block
                max-height="35"
                outlined
                @click="StUploaddialog = true"
                >{{ $t("upload") }}</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="6" xl="4">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mr-5"
                block
                max-height="35"
                router
                @click="addDialogForST = true"
                >{{ $t("Add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        style="margin-left: 20px; margin-right: 40px; margin-top: 30px"
        :headers="header2"
        :items="EdShortTraining"
        hide-default-footer
        hide-default-header
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th>{{ $t("training_program") }}</th>
              <th>{{ $t("institute") }}</th>
              <th></th>
              <th>{{ $t("duration") }}</th>
              <th>{{ $t("Year") }}</th>
              <th></th>

              <th></th>
              <th style="text-align: center">{{ $t("action") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, index }">
          <tr>
            <td>{{ item.training_Program }}</td>
            <td>{{ item.institute }}</td>
            <td></td>
            <td>{{ item.duration }}</td>

            <td>{{ item.year }}</td>
            <td></td>
            <td></td>

            <td align="center">
              <div>
                <v-btn @click="editTaskSt(item, index)" icon>
                  <img :src="images.edit" />
                </v-btn>

                <v-btn @click="DeletedialogShort(item)" icon>
                  <img :src="images.delete" />
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-row class="mt-12 ma-3">
        <v-col cols="12" xs="12" sm="6" md="8">
          <p style="font-size: 21px">
            {{ $t("languages") }}
          </p>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <v-row>
            <v-col cols="12" xs="12" sm="6" xl="4" class="offset-xl-4">
              <v-btn
                color="#F99D20"
                class="orange--text text-capitalize mr-5"
                block
                max-height="35"
                outlined
                @click="LgUploaddialog = true"
                >{{ $t("upload") }}</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="6" xl="4">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mr-5"
                block
                max-height="35"
                router
                @click="addDialogForLang = true"
                >{{ $t("Add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        style="margin-left: 20px; margin-right: 40px; margin-top: 30px"
        :headers="header3"
        :items="EdLanguages"
        hide-default-footer
        hide-default-header
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th>{{ $t("language") }}</th>
              <th></th>
              <th>{{ $t("proficiency_lvl") }}</th>
              <th>{{ $t("test_type") }}</th>
              <th>{{ $t("score") }}</th>
              <th>{{ $t("date") }}</th>
              <th style="text-align: center">{{ $t("action") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, index }">
          <tr>
            <td>{{ item.language }}</td>
            <td></td>
            <td>{{ item.proficiency_Lvl }}</td>
            <td>{{ item.testType }}</td>
            <td>{{ item.score }}</td>
            <td>{{ formatDate1(item.test_Date) }}</td>
            <td align="center">
              <div>
                <v-btn @click="editTaskLg(item, index)" icon>
                  <img :src="images.edit" />
                </v-btn>

                <v-btn @click="DeleteLanguages(item)" icon class="ml-2">
                  <img :src="images.delete" />
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div style="height: 3vh"></div>
    </v-card>

    <!-- add Education Background dialog -->
    <v-dialog v-model="addDialogForEB" persistent max-width="700px">
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 26px;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("NewEducationalBackground") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("institute") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="error.errorinstitute"
                  v-model="addEd.institute"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("degree") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="addEd.degree"
                  :error-messages="error.errordegree"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("GPA") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="error.errorgpa"
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                  v-model="addEd.gpa"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("start_year") }} <span class="red--text"> *</span>
                <v-text-field
                  class="kanit-medium"
                  color="#F99D20"
                  v-mask="mask"
                  :error-messages="error.errorstart_Year"
                  v-model="addEd.start_Year"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("end_year") }} <span class="red--text"> *</span>
                <v-text-field
                  class="kanit-medium"
                  color="#F99D20"
                  v-mask="mask"
                  :error-messages="error.errorend_Year"
                  v-model="addEd.end_Year"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="text-capitalize mr-2 commoncancelbtn"
              text
              @click="CloseDialog"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="text-capitalize mr-2 commonsavebtn"
              @click="
                addTask(institute, degree, gpa, start_Year, end_Year, $event)
              "
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edited Education Background dialog -->
    <v-dialog
      v-model="editDialogForEB"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 26px;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("EditEducationBackground") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("institute") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editEd.institute"
                  :error-messages="error.errorinstitute"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("degree") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editEd.degree"
                  :error-messages="error.errordegree"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("GPA") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="error.errorgpa"
                  v-model="editEd.gpa"
                  dense
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("start_year") }} <span class="red--text"> *</span>
                <v-text-field
                  class="kanit-medium"
                  color="#F99D20"
                  v-mask="mask"
                  :error-messages="error.errorstart_Year"
                  v-model="editEd.start_Year"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("end_year") }} <span class="red--text"> *</span>
                <v-text-field
                  class="kanit-medium"
                  color="#F99D20"
                  v-mask="mask"
                  :error-messages="error.errorend_Year"
                  v-model="editEd.end_Year"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              text
              @click="closeEditDialog"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commmonsavebtn"
              @click="updateTask($event)"
            >
              {{ $t("update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add Short Traning dialog -->
    <v-dialog
      v-model="addDialogForST"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 26px;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("NewShortTraining") }}
        </p>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("training_program") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="errorST.errorTraining"
                  v-model="addSt.Training"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("institute") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="errorST.errorStinstitute"
                  v-model="addSt.Stinstitute"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("duration") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="addSt.Duration"
                  :error-messages="errorST.errorDuration"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("Year") }} <span class="red--text"> *</span>
                <v-text-field
                  dense
                  v-model="addSt.Year"
                  :error-messages="errorST.errorYear"
                  v-mask="mask"
                  class="kanit-medium"
                  color="#F99D20"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              text
              @click="CloseDialogSt"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="
                addTaskSt(
                  st.training,
                  st.institute,
                  st.duration,
                  st.year,
                  $event
                )
              "
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Short Traning dialog -->
    <v-dialog
      v-model="editDialogForSt"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 26px;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("EditShortTraining") }}
        </p>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("training_program") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editSt.Training"
                  :error-messages="errorST.errorTraining"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                {{ $t("institute") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editSt.Stinstitute"
                  :error-messages="errorST.errorStinstitute"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("duration") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="editSt.Duration"
                  :error-messages="errorST.errorDuration"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                {{ $t("Year") }} <span class="red--text"> *</span>
                <v-text-field
                  dense
                  v-model="editSt.Year"
                  :error-messages="errorST.errorYear"
                  v-mask="mask"
                  class="kanit-medium"
                  color="#F99D20"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              text
              @click="cancelSt"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="updateShortTraining"
            >
              {{ $t("update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Languages -->
    <v-dialog
      v-model="editDialogForLang"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 26px;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("EditLanguages") }}
        </p>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("language") }} <span class="red--text"> *</span>
                <v-text-field
                  v-model="editLang.language"
                  :error-messages="errorLang.errorlanguage"
                  color="#F99D20"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("proficiency_lvl") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  :error-messages="errorLang.errorproficiency_Lvl"
                  v-model="editLang.proficiency_Lvl"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("test_type") }}
                <v-text-field
                  color="#F99D20"
                  v-model="editLang.testType"
                  :error-messages="errorLang.errortestType"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("score") }}
                <v-text-field
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                  color="#F99D20"
                  :error-messages="errorLang.errorscore"
                  v-model="editLang.score"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("date") }}
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedDateFormatted3"
                      :error-messages="errorLang.errortest_Date"
                      class="kanit-medium"
                      color="#F99D20"
                      persistent-hint
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editLang.test_Date"
                    no-title
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              text
              @click="cancelLang"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="updateLanguage($event)"
            >
              {{ $t("update") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add Languages -->
    <v-dialog
      v-model="addDialogForLang"
      persistent
      width="660"
      style="height: 440"
      max-width="700px"
    >
      <v-card style="border-radius: 15px">
        <p
          style="
            font-size: 26px;
            margin-left: 2rem;
            padding-top: 20px;
            margin-right: 2rem;
          "
        >
          {{ $t("NewLanguages") }}
        </p>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                {{ $t("language") }} <span class="red--text"> *</span>
                <v-text-field
                  v-model="addLanguage.language"
                  color="#F99D20"
                  :error-messages="errorLang.errorlanguage"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                {{ $t("proficiency_lvl") }} <span class="red--text"> *</span>
                <v-text-field
                  color="#F99D20"
                  v-model="addLanguage.proficiency_Lvl"
                  :error-messages="errorLang.errorproficiency_Lvl"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                {{ $t("test_type") }}
                <v-text-field
                  color="#F99D20"
                  v-model="addLanguage.testType"
                  :error-messages="errorLang.errortestType"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("score") }}
                <v-text-field
                  color="#F99D20"
                  type="number"
                  v-model="addLanguage.score"
                  :error-messages="errorLang.errorscore"
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t("date") }}
                <v-menu
                  v-model="menu4"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      append-icon="mdi-calendar-month-outline"
                      v-model="computedDateFormatted4"
                      :error-messages="errorLang.errortest_Date"
                      class="kanit-medium"
                      readonly
                      color="#F99D20"
                      persistent-hint
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addLanguage.test_Date"
                    no-title
                    @input="menu4 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              text
              @click="CloseDialogLang"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commonsavebtn"
              @click="
                addLang(
                  language,
                  proficiency_Lvl,
                  testType,
                  score,
                  test_Date,
                  $event
                )
              "
              >{{ $t("Add") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Languages -->

    <!-- upload Education Background -->
    <v-dialog v-model="EbUploaddialog" presistent max-width="700">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  v-show="Ebshowup"
                  color="white"
                  class="myfile"
                  id="EbfileInputButton"
                  v-model="Ebchosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="Ebcloseinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  style="text-align: left; float: left"
                  width="150"
                  max-height="35"
                  @click="Ebgetupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>

            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("EducationBackgroundTemplate.xlsx") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  style="color: #f99d20"
                  href="https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Education Background.xlsx"
                  >{{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize commoncancelbtn"
            outlined
            text
            @click="EbcloseUpload"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="white--text mr-2 text-capitalize commonsavebtn"
            @click="previewFiles()"
            >{{ $t("upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- upload Short Training  -->
    <v-dialog v-model="StUploaddialog" presistent max-width="700">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  v-show="Stshowup"
                  color="white"
                  class="myfile"
                  id="StfileInputButton"
                  v-model="Stchosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="Stcloseinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="Stgetupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("ShortTrainingTemplate.xlsx") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  style="color: #f99d20"
                  href="https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Short Training.xlsx"
                  >{{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="text-capitalize mr-2 commoncancelbtn"
              @click="StcloseUpload"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="commonsavebtn text-capitalize mr-2"
              @click="previewFileSt"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- upload Languages -->
    <v-dialog v-model="LgUploaddialog" presistent max-width="700">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  v-show="Lgshowup"
                  color="white"
                  class="myfile"
                  id="LgfileInputButton"
                  v-model="Lgchosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="Lgcloseinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="Lggetupload"
                  >{{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("filetype") }}**
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("LanguagesTemplate.xlsx") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a
                  style="color: #f99d20"
                  href="https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Languages.xlsx"
                  >{{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="text-capitalize mr-2 commoncancelbtn"
              @click="LgcloseUpload"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="commonsavebtn text-capitalize mr-2"
              @click="previewFileLang"
              >{{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete Education Dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("DeleteEducationBackground") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              @click="Deletedialog = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 text-capitalize commondeletebtn"
              @click="deleteTask()"
              >{{ $t("delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete Short Training -->
    <v-dialog v-model="DeletedialogSt" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("DeleteShortTraining") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="text-capitalize mr-2 commoncancelbtn"
              @click="DeletedialogSt = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 commondeletebtn text-capitalize"
              @click="DeleteSt()"
              >{{ $t("delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete Languages -->
    <v-dialog v-model="DeletedialogLang" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("DeleteLanguages") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-2 text-capitalize commoncancelbtn"
              @click="DeletedialogLang = false"
              text
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-2 commondeletebtn text-capitalize"
              width="120"
              max-height="35"
              @click="DeleteLang()"
              >{{ $t("delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
// import LogTrace from "@/function/Log.js";
import XLSX from "xlsx";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  watch: {
    Ebchosenfile() {
      if (this.Ebchosenfile != null) {
        this.Ebshowup = true;
      } else {
        this.Ebshowup = false;
      }
    },
    Stchosenfile() {
      if (this.Stchosenfile != null) {
        this.Stshowup = true;
      } else {
        this.Stshowup = false;
      }
    },
    Lgchosenfile() {
      if (this.Lgchosenfile != null) {
        this.Lgshowup = true;
      } else {
        this.Lgshowup = false;
      }
    },
  },
  directives: {
    mask,
  },
  data: (vm) => {
    return {
      mask: "####",
      gpamask: "##############",
      addDialogForEB: false,
      addDialogForST: false,
      addDialogForLang: false,
      EbUploaddialog: false,
      Ebchosenfile: null,
      StUploaddialog: false,
      Stchosenfile: null,
      LgUploaddialog: false,
      Lgchosenfile: null,
      editDialogForEB: false,
      editDialogForSt: false,
      editDialogForLang: false,
      menu4: false,
      menu3: false,

      Ebshowup: false,
      Stshowup: false,
      Lgshowup: false,
      Deletedialog: false,
      DeletedialogSt: false,
      DeletedialogLang: false,

      id2: 0,
      ind: 0,
      EdBackground: [],
      EdBackgroundEdu: [],
      TaskConcat: [],
      EdimportData: [],

      st: {
        institute: "",
        training_Program: "",
        duration: "",
        year: "",
      },

      EdShortTraining: [],
      chosenfile: null,
      institute: "",
      degree: "",
      gpa: "",
      start_Year: "",
      end_Year: "",
      deleteShortId: 0,
      deleteEdId: 0,
      deletelgId: 0,
      oldAbbr: null,
      number: null,

      error: {
        errorinstitute: "",
        errordegree: "",
        errorgpa: "",
        errorstart_Year: "",
        errorend_Year: "",
      },

      addEd: {
        institute: null,
        degree: null,
        gpa: null,
        start_Year: null,
        end_Year: null,
      },
      editEd: {
        institute: null,
        degree: null,
        gpa: null,
        start_Year: null,
        end_Year: null,
      },

      errorST: {
        errorStinstitute: "",
        errorTraining: "",
        errorDuration: "",
        errorYear: "",
      },
      addSt: {
        Stinstitute: null,
        Training: null,
        Year: null,
        Duration: null,
      },
      editSt: {
        Stinstitute: null,
        Training: null,
        Duration: null,
        errorYear: null,
      },

      errorLang: {
        errorlanguage: "",
        errorproficiency_Lvl: "",
        errortestType: "",
        errorscore: "",
        errortest_Date: "",
      },
      addLanguage: {
        language: null,
        proficiency_Lvl: null,
        testType: null,
        score: null,
        test_Date: null,
      },
      editLang: {
        language: null,
        proficiency_Lvl: null,
        testType: null,
        score: null,
        test_Date: null,
      },

      EdLanguages: [],
      localEdubackground: [],
      localShiftCode: [],
      localLanguages: [],
      dateFormatted1: vm.formatDate1(new Date().toISOString().substr(0, 10)),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      header1: [
        {
          text: "Institute/University",
          value: "institute",
          sortable: false,
          align: "left",
        },
        {
          text: "Degree",
          value: "degree",
          sortable: false,
          align: "left",
        },
        {
          text: "GPA",
          value: "gpa",
          sortable: false,
          align: "left",
        },
        {
          text: "Start Year",
          value: "start_Year",
          sortable: false,
          align: "left",
        },
        {
          text: "End Year",
          value: "end_Year",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],
      header2: [
        {
          text: "Training Program",
          value: "st.training_Program",
          sortable: false,
          align: "left",
        },
        {
          text: "Institute",
          value: "st.institute",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          align: "left",
        },
        {
          text: "Duration",
          value: "st.duration",
          sortable: false,
          align: "left",
        },
        {
          text: "Year",
          value: "st.year",
          sortable: false,
          align: "left",
        },

        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],

      header3: [
        {
          text: "Language",

          sortable: false,
          align: "left",
        },
        {
          text: "",
          align: "center",
        },
        {
          text: "Proficiency Level",

          sortable: false,
          align: "left",
        },
        {
          text: "Test Type",

          sortable: false,
          align: "left",
        },
        {
          text: "Score",

          sortable: false,
          align: "left",
        },
        {
          text: "Date",

          align: "left",
          sortable: false,
        },
        {
          text: "Action",

          align: "center",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    computedDateFormatted4() {
      return this.formatDate(this.addLanguage.test_Date);
    },
    computedDateFormatted3() {
      return this.formatDate1(this.editLang.test_Date);
    },
    images() {
      return this.$store.state.images;
    },
    form() {
      return {
        institute: this.institute,
        degree: this.degree,
        gpa: this.gpa,
        Start: this.start_Year,
        end_Year: this.end_Year,
      };
    },
  },

  methods: {
    onlyalpha(e) {
      var regex = /^\d*\.?\d*$/;
      var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(key)) {
        e.preventDefault();
        return false;
      }
    },
    Calculate(s) {
      var rgx = /^[0-9]*\.?[0-9]*$/;
      return s.match(rgx);
    },
    DeletedialogShort(id) {
      this.DeletedialogSt = true;
      this.deleteShortId = this.EdShortTraining.indexOf(id);
    },
    OpenDelDig(id) {
      this.Deletedialog = true;
      this.deleteEdId = this.EdBackgroundEdu.indexOf(id);
    },
    DeleteLanguages(id) {
      this.DeletedialogLang = true;
      this.deletelgId = this.EdLanguages.indexOf(id);
    },

    back() {
      if (this.$route.path == "/personal/educationbackground") {
        this.$router.push({ name: "personal" }).catch(()=>{});
      } else if (this.$route.name == "neweducationbackground") {
        this.$router.push(
          "/PersonnelManagement/NewEmployee/NewPersonalInformation"
        ).catch(()=>{});
      }
    },
    EbcloseUpload() {
      this.EbUploaddialog = false;
      this.Ebchosenfile = null;
      this.Ebshowup = false;
    },
    Ebcloseinput() {
      this.Ebshowup = false;
      this.Ebchosenfile = null;
    },
    Ebgetupload() {
      document.getElementById("EbfileInputButton").click();
      //this.Ebshowup = true;
    },
    cancelTank() {
      let self = this;
      this.editDialogForEB = false;
      self.institute = null;
      self.degree = null;
      self.gpa = null;
      self.start_Year = null;
      self.end_Year = null;
    },
    cancelSt() {
      let self = this;
      this.editDialogForSt = false;
      self.st.training_Program = null;
      self.st.institute = null;
      self.st.duration = null;
      self.st.year = null;
      self.resetFormSt();
    },
    cancelLang() {
      let self = this;
      this.editDialogForLang = false;
      self.editLang.language = null;
      self.editLang.proficiency_Lvl = null;
      self.editLang.testType = null;
      self.editLang.score = null;
      self.editLang.test_Date = null;
      self.resetFormLang();
    },

    StcloseUpload() {
      this.StUploaddialog = false;
      (this.Stchosenfile = null), (this.Stshowup = false);
    },
    Stcloseinput() {
      this.Stshowup = false;
      this.Stchosenfile = null;
    },
    Stgetupload() {
      document.getElementById("StfileInputButton").click();
      // this.Stshowup = true;
    },
    LgcloseUpload() {
      this.LgUploaddialog = false;
      (this.Stchosenfile = null), (this.Lgshowup = false);
    },
    Lgcloseinput() {
      this.Lgshowup = false;
      this.Lgchosenfile = null;
    },
    Lggetupload() {
      document.getElementById("LgfileInputButton").click();
      //  this.Lgshowup = true;
    },
    localeProbation() {
      return "";
    },

    formatDate(date) {
      if (date == "-") {
        return "-";
      } else if (!date) {
        return null;
      } else {
        let finaldate = date.slice(0, 10).split("-");
        return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
      }
    },
    formatDate1(date) {
      if (date == "-") {
        return "-";
      } else if (date != null) {
        let finaldate = date.slice(0, 10).split("-");
        return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
      } else {
        return "-";
      }
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    alphaOnly(event) {
      let alp = /^[a-zA-Z\s]+$/;
      return alp.test(event) || "Alphabet only";
    },
    special(event) {
      let alp = /^[A-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]*$/i;
      return alp.test(event) || "Alphabet only";
    },
    resetForm() {
      this.error.errorinstitute = [];
      this.error.errordegree = [];
      this.error.errorgpa = [];
      this.error.errorstart_Year = [];
      this.error.errorend_Year = [];
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    CloseDialog() {
      this.addDialogForEB = false;
      this.addEd.institute = null;
      this.addEd.degree = null;
      this.addEd.gpa = null;
      this.addEd.start_Year = null;
      this.addEd.end_Year = null;
      this.resetForm();
    },
    closeEditDialog() {
      this.editDialogForEB = false;
      this.resetForm();
    },

    resetFormSt() {
      this.errorST.errorTraining = [];
      this.errorST.errorDuration = [];
      this.errorST.errorYear = [];
      this.errorST.errorStinstitute = [];
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    CloseDialogSt() {
      this.addDialogForST = false;
      this.addSt.Training = null;
      this.addSt.Stinstitute = null;
      this.addSt.Year = null;
      this.addSt.Duration = null;

      this.resetFormSt();
    },

    resetFormLang() {
      this.errorLang.errorlanguage = [];
      this.errorLang.errortest_Date = [];
      this.errorLang.errortestType = [];
      this.errorLang.errorproficiency_Lvl = [];
      this.errorLang.errorscore = [];
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    CloseDialogLang() {
      this.addDialogForLang = false;
      this.addLanguage.language = null;
      this.addLanguage.proficiency_Lvl = null;
      this.addLanguage.test_Date = null;
      this.addLanguage.testType = null;
      this.addLanguage.score = null;

      this.resetFormLang();
    },

    addTask(a, b, c, d, e, f) {
      let self = this;

      self.error.errorinstitute = !self.addEd.institute
        ? "Please fill in the required fields"
        : "";
      self.error.errordegree = !self.addEd.degree
        ? "Please fill in the required fields"
        : "";
      self.error.errorgpa = !self.addEd.gpa
        ? "Please fill in the required fields"
        : "";
      self.error.errorstart_Year = !self.addEd.start_Year
        ? "Please fill in the required fields"
        : "";
      self.error.errorend_Year = !self.addEd.end_Year
        ? "Please fill in the required fields"
        : "";

      let tempvali = self.ValidateAddTrueEd();
      if (tempvali == true) {
        let subtxt =
          self.addEd.institute.charAt(0).toUpperCase() +
          self.addEd.institute.slice(1);
        let distxt =
          self.addEd.degree.charAt(0).toUpperCase() +
          self.addEd.degree.slice(1);

        f.preventDefault();

        self.EdBackgroundEdu.unshift({
          institute: subtxt,
          degree: distxt,
          gpa: this.addEd.gpa,
          start_Year: parseInt(this.addEd.start_Year),
          end_Year: parseInt(this.addEd.end_Year),
        });

        self.addDialogForEB = false;
        localStorage.setItem(
          "EdBackgroundEdu",
          JSON.stringify(self.EdBackgroundEdu)
        );

        self.addEd.institute = null;
        self.addEd.degree = null;
        self.addEd.gpa = null;
        self.addEd.start_Year = null;
        self.addEd.end_Year = null;
      } else {
        self.ValidateAddFalseEd();
      }
    },
    editTask: function (a, index) {
      this.editDialogForEB = true;
      this.editEd.institute = a.institute;
      this.editEd.degree = a.degree;
      this.editEd.gpa = a.gpa;
      this.editEd.start_Year = a.start_Year;
      this.editEd.end_Year = a.end_Year;
      this.ind = index;
    },
    updateTask: function (e) {
      let self = this;

      self.error.errorinstitute = !self.editEd.institute
        ? "Please fill in the required fields"
        : "";
      self.error.errordegree = !self.editEd.degree
        ? "Please fill in the required fields"
        : "";
      self.error.errorgpa = !self.editEd.gpa
        ? "Please fill in the required fields"
        : "";
      self.error.errorstart_Year = !self.editEd.start_Year
        ? "Please fill in the required fields"
        : "";
      self.error.errorend_Year = !self.editEd.end_Year
        ? "Please fill in the required fields"
        : "";

      let tempvali = self.ValidateEditTrue();
      if (tempvali == true) {
        let subtxt =
          self.editEd.institute.charAt(0).toUpperCase() +
          self.editEd.institute.slice(1);
        let distxt =
          self.editEd.degree.charAt(0).toUpperCase() +
          self.editEd.degree.slice(1);
        e.preventDefault();
        this.editDialogForEB = false;
        let tankdb = {
          institute: subtxt,
          degree: distxt,
          gpa: this.editEd.gpa,
          start_Year: parseInt(this.editEd.start_Year),
          end_Year: parseInt(this.editEd.end_Year),
        };
        this.EdBackgroundEdu[this.ind] = tankdb;
        localStorage.setItem(
          "EdBackgroundEdu",
          JSON.stringify(this.EdBackgroundEdu)
        );
        let taskDB = JSON.parse(localStorage.getItem("EdBackgroundEdu"));
        this.EdBackgroundEdu = taskDB;
        this.editEd.institute = "";
        this.editEd.degree = "";
        this.editEd.gpa = "";
        this.editEd.start_Year = "";
        this.editEd.end_Year = "";
      } else {
        this.ValidateEditFalse();
      }
    },

    deleteTask: function () {
      this.Deletedialog = false;

      this.EdBackgroundEdu.splice(this.deleteEdId, 1);
      localStorage.setItem(
        "EdBackgroundEdu",
        JSON.stringify(this.EdBackgroundEdu)
      );
    },

    previewFiles() {
      let self = this;
      let files = self.Ebchosenfile;
      let reader = new FileReader();

      if (self.Ebchosenfile != null && self.Ebchosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Education Background.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.localEdubackground = XLSX.utils.sheet_to_json(worksheet);

            self.EdBackgroundEdu = self.localEdubackground.concat(
              self.EdBackgroundEdu
            );
            // self.EdBackgroundEdu.push({
            //   institute: self.EdimportData.institute,
            //   degree: self.EdimportData.degree,
            //   gpa: self.EdimportData.gpa,
            //   start_Year: self.EdimportData.start_Year,
            //   EndYear: self.EdimportData.EndYear,
            // });
            localStorage.setItem(
              "EdBackgroundEdu",
              JSON.stringify(self.EdBackgroundEdu)
            );
            // self.institute = "";
            // self.degree = "";
            // self.gpa = "";
            // self.start_Year = "";
            // self.EndYear = "";
            self.chosenfile = null;
            self.Ebshowup = false;
            self.EbUploaddialog = false;
            // self.getItem();
          } else {
            alert("File name must be Education Background.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
    ValidateAddTrueEd() {
      let self = this;

      if (
        self.addEd.institute != null &&
        self.addEd.gpa != null &&
        self.addEd.degree != null &&
        self.addEd.start_Year != null &&
        self.addEd.end_Year != null &&
        self.addEd.institute != "" &&
        self.addEd.gpa != "" &&
        self.addEd.degree != "" &&
        self.addEd.start_Year != "" &&
        self.addEd.End_year != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    ValidateAddFalseEd() {
      let self = this;

      if (
        self.addEd.institute == null ||
        self.addEd.gpa == null ||
        self.addEd.degree == null ||
        self.addEd.start_Year == null ||
        self.addEd.end_Year == null
      ) {
        alert("Please fill in the required fields!");
      }
    },

    ValidateEditTrue() {
      let self = this;

      if (
        self.editEd.institute != null &&
        self.editEd.gpa != null &&
        self.editEd.degree != null &&
        self.editEd.start_Year != null &&
        self.editEd.end_Year != null &&
        self.editEd.institute != "" &&
        self.editEd.gpa != "" &&
        self.editEd.degree != "" &&
        self.editEd.start_Year != "" &&
        self.editEd.end_year != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    ValidateEditFalse() {
      if (
        this.editEd.institute == null ||
        this.editEd.gpa == null ||
        this.editEd.degree == null ||
        this.editEd.start_Year == null ||
        this.editEd.end_Year == null ||
        this.editEd.institute == "" ||
        this.editEd.gpa == "" ||
        this.editEd.degree == "" ||
        this.editEd.start_Year == "" ||
        this.editEd.end_Year == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },

    addTaskSt(a, b, c, d, f) {
      let self = this;

      self.errorST.errorDuration = !self.addSt.Duration
        ? "Please fill in the required fields"
        : "";
      self.errorST.errorStinstitute = !self.addSt.Stinstitute
        ? "Please fill in the required fields"
        : "";
      self.errorST.errorYear = !self.addSt.Year
        ? "Please fill in the required fields"
        : "";
      self.errorST.errorTraining = !self.addSt.Training
        ? "Please fill in the required fields"
        : "";

      let tempvalistSt = self.ValidateAddTrueSt();
      if (tempvalistSt == true) {
        let subtxt =
          self.addSt.Training.charAt(0).toUpperCase() +
          self.addSt.Training.slice(1);

        f.preventDefault();
        self.EdShortTraining.unshift({
          training_Program: subtxt,
          institute: self.addSt.Stinstitute,
          duration: self.addSt.Duration,
          year: parseInt(self.addSt.Year),
        });
        self.addDialogForST = false;
        localStorage.setItem(
          "EdShortTraining",
          JSON.stringify(self.EdShortTraining)
        );
        self.addSt.Training = null;
        self.addSt.Stinstitute = null;
        self.addSt.Duration = null;
        self.addSt.Year = null;
      } else {
        self.ValidateAddFalseSt();
      }
    },
    editTaskSt: function (a, index) {
      this.editDialogForSt = true;
      this.editSt.Training = a.training_Program;
      this.editSt.Stinstitute = a.institute;
      this.editSt.Duration = a.duration;
      (this.editSt.Year = a.year), (this.ind = index);
    },

    updateShortTraining: function (e) {
      let self = this;

      self.errorST.errorDuration = !self.editSt.Duration
        ? "Please fill in the required fields"
        : "";
      self.errorST.errorStinstitute = !self.editSt.Stinstitute
        ? "Please fill in the required fields"
        : "";
      self.errorST.errorYear = !self.editSt.Year
        ? "Please fill in the required fields"
        : "";
      self.errorST.errorTraining = !self.editSt.Training
        ? "Please fill in the required fields"
        : "";
      let tempvalistSt = self.validateEditTrueSt();
      if (tempvalistSt == true) {
        let subtxt =
          self.editSt.Training.charAt(0).toUpperCase() +
          self.editSt.Training.slice(1);
        e.preventDefault();
        this.editDialogForSt = false;

        let tankst = {
          index: this.ind,
          training_Program: subtxt,
          institute: this.editSt.Stinstitute,
          duration: this.editSt.Duration,
          year: parseInt(this.editSt.Year),
        };
        this.EdShortTraining[this.ind] = tankst;
        localStorage.setItem(
          "EdShortTraining",
          JSON.stringify(this.EdShortTraining)
        );

        let taskSt = JSON.parse(localStorage.getItem("EdShortTraining"));
        this.EdShortTraining = taskSt;
        this.training_Program = "";
        this.institute = "";
        this.duration = "";
        this.year = null;
      } else {
        this.validateEditFalseSt();
      }
    },

    DeleteSt() {
      this.DeletedialogSt = false;

      this.EdShortTraining.splice(this.deleteShortId, 1);
      localStorage.setItem(
        "EdShortTraining",
        JSON.stringify(this.EdShortTraining)
      );
    },

    previewFileSt() {
      let self = this;
      let files = self.Stchosenfile;
      let reader = new FileReader();
      if (self.Stchosenfile != null && self.Stchosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Short Training.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.localShiftCode = XLSX.utils.sheet_to_json(worksheet);

            self.EdShortTraining = self.localShiftCode.concat(
              self.EdShortTraining
            );
            // self.EdBackgroundEdu.push({
            //   institute: self.EdimportData.institute,
            //   degree: self.EdimportData.degree,
            //   gpa: self.EdimportData.gpa,
            //   start_Year: self.EdimportData.start_Year,
            //   EndYear: self.EdimportData.EndYear,
            // });
            localStorage.setItem(
              "EdShortTraining",
              JSON.stringify(self.EdShortTraining)
            );
            // self.institute = "";
            // self.degree = "";
            // self.gpa = "";
            // self.start_Year = "";
            // self.EndYear = "";
            self.Stchosenfile = null;
            self.Stshowup = false;
            self.StUploaddialog = false;
            // self.getItem();
          } else {
            alert("File name must be Short Training .xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },

    ValidateAddTrueSt() {
      let self = this;

      if (
        self.addSt.Training != null &&
        self.addSt.Stinstitute != null &&
        self.addSt.Year != null &&
        self.addSt.Duration != null &&
        self.addSt.Training != "" &&
        self.addSt.Stinstitute != "" &&
        self.addSt.Year != "" &&
        self.addSt.Duration != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    ValidateAddFalseSt() {
      if (
        this.addSt.Stinstitute == null ||
        this.addSt.Year == null ||
        this.addSt.Training == null ||
        this.addSt.Duration == null
      ) {
        alert("Please fill in the required fields!");
      }
    },

    validateEditTrueSt() {
      let self = this;

      if (
        self.editSt.Training != null &&
        self.editSt.Stinstitute != null &&
        self.editSt.Year != null &&
        self.editSt.Duration != null &&
        self.editSt.Training != "" &&
        self.editSt.Stinstitute != "" &&
        self.editSt.Year != "" &&
        self.editSt.Duration != ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    validateEditFalseSt() {
      let self = this;

      if (
        self.editSt.Training == null ||
        self.editSt.Stinstitute == null ||
        self.editSt.Year == null ||
        self.editSt.Duration == null
        // self.editSt.Training == "" ||
        // self.editSt.Stinstitute == "" ||
        // self.editSt.Year == "" ||
        // self.editSt.Duration == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },

    addLang(a, b, c, d, e, f) {
      let self = this;

      self.errorLang.errorlanguage = !self.addLanguage.language
        ? "Please fill in the required fields"
        : "";
      self.errorLang.errorproficiency_Lvl = !self.addLanguage.proficiency_Lvl
        ? "Please fill in the required fields"
        : "";
      // self.errorLang.errorscore = !self.addLanguage.score
      //   ? "Please fill in the required fields"
      //   : "";
      // self.errorLang.errortestType = !self.addLanguage.testType
      //   ? "Please fill in the required fields"
      //   : "";
      // self.errorLang.errortest_Date = !self.addLanguage.test_Date
      //   ? "Please fill in the required fields"
      //   : "";

      let tempvaliLang = self.ValidateAddTrueLg();
      if (tempvaliLang == true) {
        let subtxt =
          self.addLanguage.language.charAt(0).toUpperCase() +
          self.addLanguage.language.slice(1);
        let distxt =
          self.addLanguage.proficiency_Lvl.charAt(0).toUpperCase() +
          self.addLanguage.proficiency_Lvl.slice(1);

        if (
          self.addLanguage.testType == "" ||
          self.addLanguage.testType == null
        ) {
          self.addLanguage.testType = "-";
        }
        if (self.addLanguage.score == "" || self.addLanguage.score == null) {
          self.addLanguage.score = "-";
        }
        if (
          self.addLanguage.test_Date == "" ||
          self.addLanguage.test_Date == null
        ) {
          self.addLanguage.test_Date = null;
        }
        f.preventDefault();
        self.EdLanguages.unshift({
          language: subtxt,
          proficiency_Lvl: distxt,
          testType: self.addLanguage.testType,
          score: parseInt(self.addLanguage.score),
          test_Date: self.addLanguage.test_Date,
        });

        self.addDialogForLang = false;
        localStorage.setItem("EdLanguages", JSON.stringify(self.EdLanguages));

        self.addLanguage.language = null;
        self.addLanguage.proficiency_Lvl = null;
        self.addLanguage.score = null;
        self.addLanguage.test_Date = null;
        self.addLanguage.testType = null;
      } else {
        self.ValidateAddFalseLang();
      }
    },
    editTaskLg(a, index) {
      this.editDialogForLang = true;
      this.editLang.language = a.language;
      this.editLang.testType = a.testType;
      this.editLang.proficiency_Lvl = a.proficiency_Lvl;
      this.editLang.score = a.score;
      if (this.editLang.score == "-") {
        this.editLang.score = 0;
      }
      this.editLang.test_Date = a.test_Date;
      // if(this.editLang.test_Date=="-"){
      //   this.editLang.test_Date=0
      // }
      this.ind = index;
    },

    updateLanguage: function (e) {
      let self = this;
      self.errorLang.errorlanguage = !self.editLang.language
        ? "Please fill in the required fields"
        : "";
      self.errorLang.errorproficiency_Lvl = !self.editLang.proficiency_Lvl
        ? "Please fill in the required fields"
        : "";
      // self.errorLang.errorscore = !self.editLang.score
      //   ? "Please fill in the required fields"
      //   : "";
      // self.errorLang.errortestType = !self.editLang.testType
      //   ? "Please fill in the required fields"
      //   : "";
      // self.errorLang.errordate = !self.editLang.test_Date
      //   ? "Please fill in the required fields"
      //   : "";
      let tempvaliLang = self.ValidateEditTrueLang();
      if (tempvaliLang == true) {
        let subtxt =
          self.editLang.language.charAt(0).toUpperCase() +
          self.editLang.language.slice(1);
        let distxt =
          self.editLang.proficiency_Lvl.charAt(0).toUpperCase() +
          self.editLang.proficiency_Lvl.slice(1);
        e.preventDefault();
        this.editDialogForLang = false;
        if (self.editLang.score == "0") {
          self.editLang.score = "-";
        }
        let tankLg = {
          language: subtxt,
          proficiency_Lvl: distxt,
          testType: this.editLang.testType,
          score: this.editLang.score,
          test_Date: this.editLang.test_Date,
        };
        this.EdLanguages[this.ind] = tankLg;
        localStorage.setItem("EdLanguages", JSON.stringify(this.EdLanguages));

        let taskLg = JSON.parse(localStorage.getItem("EdLanguages"));
        this.EdLanguages = taskLg;
        this.editLang.language = null;
        this.editLang.proficiency_Lvl = null;
        this.editLang.testType = null;
        this.editLang.score = null;
        this.editLang.data = null;
      } else {
        this.ValidateEditFalseLang();
      }
    },

    DeleteLang() {
      this.DeletedialogLang = false;

      this.EdLanguages.splice(this.deletelgId, 1);
      localStorage.setItem("EdLanguages", JSON.stringify(this.EdLanguages));
    },

    ValidateAddTrueLg() {
      let self = this;

      if (
        self.addLanguage.language != null &&
        self.addLanguage.proficiency_Lvl != null &&
        // self.addLanguage.testType != null &&
        // self.addLanguage.score != null &&
        // self.addLanguage.test_Date != null &&
        self.addLanguage.language != "" &&
        self.addLanguage.proficiency_Lvl != ""
        // self.addLanguage.testType != "" &&
        // self.addLanguage.score != "" &&
        // self.addLanguage.test_Date != "" &&
      ) {
        return true;
      } else {
        return false;
      }
    },

    ValidateAddFalseLang() {
      if (
        this.addLanguage.language == null ||
        this.addLanguage.proficiency_Lvl == null
        // this.addLanguage.testType == null ||
        // this.addLanguage.score == null ||
        // this.addLanguage.test_Date == null
      ) {
        alert("Please fill in the required fields!");
      }
    },

    ValidateEditTrueLang() {
      let self = this;

      if (
        self.editLang.language != null &&
        self.editLang.proficiency_Lvl != null &&
        // self.editLang.testType != null &&
        // self.editLang.score != null &&
        // self.editLang.test_Date != null &&
        self.editLang.language != "" &&
        self.editLang.proficiency_Lvl != ""
        // self.editLang.testType != "" &&
        // self.editLang.score != "" &&
        // self.editLang.test_Date != "" &&
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateEditFalseLang() {
      if (
        this.editLang.language == null ||
        this.editLang.proficiency_Lvl == null ||
        this.editLang.testType == null ||
        this.editLang.score == null ||
        this.editLang.test_Date == null ||
        this.editLang.language == " " ||
        this.editLang.proficiency_Lvl == "" ||
        this.editLang.testType == "" ||
        this.editLang.score == "" ||
        this.editLang.test_Date == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },

    previewFileLang() {
      let self = this;
      let files = self.Lgchosenfile;
      let reader = new FileReader();
      if (self.Lgchosenfile != null && self.Lgchosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Languages.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            self.localLanguages = XLSX.utils.sheet_to_json(worksheet);

            for (let i = 0; i < self.localLanguages.length; i++) {
              if (self.localLanguages[i].test_Date) {
                let newdate = self.localLanguages[i].test_Date
                  .split("/")
                  .reverse()
                  .join("-");
                self.localLanguages[i].test_Date = newdate;
              }
            }

            self.EdLanguages = self.localLanguages.concat(self.EdLanguages);

            localStorage.setItem(
              "EdLanguages",
              JSON.stringify(self.EdLanguages)
            );
            // self.institute = "";
            // self.degree = "";
            // self.gpa = "";
            // self.start_Year = "";
            // self.EndYear = "";
            self.Lgchosenfile = null;
            self.Lgshowup = false;
            self.LgUploaddialog = false;
            // self.getItem();
          } else {
            alert("File name must be Languages.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
  },

  mounted: function () {
    let taskDB = JSON.parse(localStorage.getItem("EdBackgroundEdu"));
    if (taskDB == null) {
      this.EdBackgroundEdu = [];
    } else {
      this.EdBackgroundEdu = taskDB;
    }
    let taskSt = JSON.parse(localStorage.getItem("EdShortTraining"));

    if (taskSt == null) {
      this.EdShortTraining = [];
    } else {
      this.EdShortTraining = taskSt;
    }

    let taskLg = JSON.parse(localStorage.getItem("EdLanguages"));

    if (taskLg == null) {
      this.EdLanguages = [];
    } else {
      this.EdLanguages = taskLg;
    }
  },
};
</script>

<style scoped>
.inputscore input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.a {
  text-decoration: none;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 17px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
</style>
<style>
.mdi-close::before {
  /* content: "\F0156"; */
  color: red !important;
  display: none !important;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
@media (max-width: 375px) {
  .btnres {
    margin-top: 1rem !important;
  }
}
</style>
