<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem" class="fix-calender">
    <div>
      <v-row>
        <v-btn
          icon
          router
          color="white"
          :to="{ name: 'StaffTimeAttendance' }"
          class="mt-5"
        >
          <img src="@/assets/images/back button.png" />
        </v-btn>
        <p
          style="
            font-size: 25px;
            margin-left: 4px;
            margin-top: 20px;
          "
        >
         {{ $t("TimesheetDetails") }}
        </p>
      </v-row>
    </div>
    <div class="mt-5">
      <v-card class="pt-5" style="margin-bottom: 5rem">
        <div
          style="margin-left: 2rem; margin-right: 2rem"
          class="fixed-calendar"
        >
          <v-row>
            <!-- <div class="d-flex ml-2">
              <v-icon color="#0FA7D8">mdi-checkbox-blank</v-icon>
              <p class="fontwei ml-1 mt-2">Shift Time</p>
              <v-icon color="#F99D20" class="ml-3">mdi-checkbox-blank</v-icon>
              <p class="fontwei ml-1 mt-2">Clock-In/Clock-Out</p>
            </div>-->
            <v-col cols="12" sm="3" md="2" class="d-flex">
              <div
                style="
                  width: 20px;
                  height: 20px;
                  background-color: #ff4b55;
                  border-radius: 4px;
                "
              ></div>
              <p class="ml-3" style="margin-top: -3px">{{ $t("Irregular") }}</p>
            </v-col>
            <v-col cols="12" sm="9" md="4" class="d-flex">
              <div
                style="
                  width: 20px;
                  height: 20px;
                  background-color: #46d95a;
                  border-radius: 4px;
                "
              ></div>
              <p class="ml-3" style="margin-top: -3px">{{ $t("Normal") }}</p>
            </v-col>
            <v-spacer></v-spacer>
            <div
              class="mr-8"
              style="
                background-color: #f99d20;
                border-radius: 5px;
                margin-left: 12px;
              "
            >
              <v-row>
                <v-autocomplete
                  class="selectboxs minizebox ml-4 mt-1 mb-1 mr-4 pl-0"
                  v-model="todaymonths"
                  height="34px"
                  single-line
                  :items="monthNames1"
                  item-text="txt"
                  item-value="val"
                  background-color="#ffff"
                  return-object
                  persistent-hint
                  v-on:change="ChangeMonth(`${todaymonths.val}`)"
                ></v-autocomplete>
                <date-picker
                  v-model="todayyears"
                  type="year"
                  color="#0FA7D8"
                  class="selectboxs minizebox ml-1 mr-4 mt-1 mb-1 pl-0 today_years"
                  v-on:change="ChangeYear(todayyears)"
                >
                  <template slot="icon-calendar">
                    <img
                      src="@/assets/images/down1@2x.png"
                      style="
                        width: 20px;
                        height: 20px;
                        margin-right: -7px;
                        padding-top: 3px;
                      "
                    />
                  </template>
                </date-picker>
              </v-row>
            </div>
          </v-row>
          <v-row justify="center" class="mt-5">
            <v-col>
              <v-card class style="height: 700px">
                <v-sheet height="700px">
                  <v-calendar
                    :now="focus"
                    ref="calendar"
                    type="month"
                    :value="focus"
                    color="primary"
                    :events="events"
                  >
                    <template v-slot:event="{ event }">
                      <div class="pa-1 my-event" layout="column">
                        <div v-show="event.showview">
                          <v-chip
                            :style="{
                              background:
                                ShiftValis(event) == true
                                  ? '#DBF1DE'
                                  : ' #deb1b1',
                              color:
                                ShiftValis(event) == true
                                  ? '#46D95A'
                                  : ' #FF4B55',
                            }"
                            v-show="!thethaihead"
                            color="#18A32B"
                            class="chiptoggle"
                            small
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remark }}</v-chip
                          >
                          <v-chip
                            :style="{
                              background:
                                ShiftValis(event) == true
                                  ? '#DBF1DE'
                                  : ' #deb1b1',
                              color:
                                ShiftValis(event) == true
                                  ? '#46D95A'
                                  : ' #FF4B55',
                            }"
                            v-show="thethaihead"
                            color="#18A32B"
                            class="chiptoggle"
                            small
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remarkTH }}</v-chip
                          >
                        </div>
                        <div v-show="event.showholiday">
                          <v-chip
                            style="background: #daf2f9"
                            color="#0FA7D8"
                            class="blue--text chiptoggle"
                            small
                            v-show="!thethaihead"
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remark }}</v-chip
                          >
                          <v-chip
                            style="background: #daf2f9"
                            color="#0FA7D8"
                            class="blue--text chiptoggle"
                            small
                            v-show="thethaihead"
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remarkTH }}</v-chip
                          >
                        </div>
                        <div v-show="event.showOT">
                          <v-chip
                            :style="{
                              background:
                                ShiftValis(event) == true
                                  ? '#DBF1DE'
                                  : ' #deb1b1',
                              color:
                                ShiftValis(event) == true
                                  ? '#46D95A'
                                  : ' #FF4B55',
                            }"
                            v-show="!thethaihead"
                            color="#18A32B"
                            small
                            class="chiptoggle"
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remark }}</v-chip
                          >
                          <v-chip
                            :style="{
                              background:
                                ShiftValis(event) == true
                                  ? '#DBF1DE'
                                  : ' #deb1b1',
                              color:
                                ShiftValis(event) == true
                                  ? '#46D95A'
                                  : ' #FF4B55',
                            }"
                            v-show="thethaihead"
                            color="#18A32B"
                            small
                            class="chiptoggle"
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remarkTH }}</v-chip
                          >
                        </div>
                        <!-- <div v-show="event.showTimeOff">
                          <v-chip
                            style="background: #ff000027"
                            color="#FF0000"
                            small
                            class="red--text chiptoggle"
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remark }}</v-chip
                          >
                        </div> -->
                        <div v-show="event.showTimeOff">
                          <v-chip
                            style="background: #dbf1de"
                            color="#18A32B"
                            small
                            v-show="!thethaihead"
                            class="green--text chiptoggle"
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remark }}</v-chip
                          >
                          <v-chip
                            style="background: #dbf1de"
                            color="#18A32B"
                            small
                            v-show="thethaihead"
                            class="green--text chiptoggle"
                            @click="GoViewTimeSheet(event)"
                            >{{ event.remarkTH }}</v-chip
                          >
                        </div>

                        <v-row
                          class="hidetxts"
                          :style="{
                            color:
                              ShiftValis(event) == true ? '#46D95A' : ' #FF4B55',
                          }"
                        >
                          <v-col style="text-align: center">
                            <!-- <p
                              class="ml-2"
                              style="margin-top:-30px; color:#0FA7D8"
                            >{{event.shift_StartTime}}</p> -->
                            <p class="ml-2" style="margin-top: -30px">
                              {{ Converttolocals(event.clock_In_Time) }}
                            </p>
                            <p class="ml-2" style="cursor: pointer">
                              {{ Converttolocals(event.oT_Clock_In_Time) }}
                            </p>
                          </v-col>
                          <v-divider
                            style="margin-top: -10px; height: 40px"
                            vertical
                          ></v-divider>
                          <v-col style="text-align: center">
                            <p style="margin-top: -30px">
                              {{ Converttolocals(event.clock_Out_Time) }}
                            </p>
                            <p style="cursor: pointer">
                              {{ Converttolocals(event.oT_Clock_Out_Time) }}
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                    </template>
                    <!-- <template v-slot:day="{present, past, date}">
                        <v-col class="fill-height">
                          <template v-if="present || past && CalCinCoutLis[date]">
                            <v-sheet
                              v-for="i in CalCinCoutList"
                              :key="i"
                            >
                            <v-chip
                              style="margin-top:-90px; margin-left: 40px;"
                              color="orange"
                              outlined
                              @click="gotoview"
                            >Clock-In</v-chip>
                            <v-row>
                              <v-col>
                                <p class="ml-2" style="margin-top:-30px; color:#0FA7D8">8:30</p>
                                <p
                                  class="ml-5"
                                  @click="OpenClockInDialig(item)"
                                  style="cursor:pointer;color:#F89D1F"
                                >-</p>
                              </v-col>
                              <v-divider style="margin-top:-10px; height:40px;" vertical></v-divider>
                              <v-col style="align:center">
                                <p class="ml-2" style=" margin-top:-30px; color:#0FA7D8">17:00</p>
                                <p
                                  class="ml-5"
                                  @click="OpenClockOutDialig(item)"
                                  style="cursor:pointer;color:#F89D1F"
                                >-</p>
                              </v-col>
                            </v-row>
                            </v-sheet>
                    </template>-->
                    <!-- <template v-if="past && tracked[date]">
                            <v-sheet
                              v-for="(percent, i) in tracked[date]"
                              :key="i"
                              :title="category[i]"
                              :color="colors[i]"
                              :width="`${percent}%`"
                              height="20%"
                              tile
                            ></v-sheet>
                    </template>-->
                    <!-- </v-col>
                    </template>-->
                  </v-calendar>
                </v-sheet>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div style="height: 10vh"></div>
      </v-card>
    </div>

    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  name: "GoogleMap",
  mixins: [LogTrace],
  data() {
    return {
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 15, 20],
      value3: new Date(),
      reportDateFrom: new Date(),
      map: "",
      marker: "",
      center: { lat: 45.508, lng: -73.587 },
      location: "",
      locationlat: null,
      locationlang: null,
      showlocation: "",
      showlocationlat: null,
      showlocationlang: null,
      outlocation: "",
      outlocationlat: null,
      outlocationlang: null,
      focus: new Date().toISOString().substr(0, 10),
      images: {
        minus: require("@/assets/images/Mask Group 71.png"),
      },
      searchtxt: "",
      showview: false,
      todaymonth: "",
      showsheetview: false,
      showholiday: true,
      showTimeOff: true,
      showOT: true,
      LoadingDialog: false,
      TimeViewList: [],
      CinCoutList: [],
      clock: {
        clockintime: "",
        clockouttime: "",
        timeID: null,
        clockID: null,
        timeType: null,
        clockinlocate: null,
        clockoutlocate: null,
        cinlang: null,
        coutlang: null,
        cinlat: null,
        coutlat: null,
      },
      cintime: "",
      coutime: "",
      totaltime: null,
      headers: [
        {
          text: "Date",
          value: "timeEntry_Date",
          align: "left",
          sortable: true,
        },
        {
          text: "Shift Start Time",
          value: "shift_StartTime",
          align: "center",
          sortable: true,
        },
        {
          text: "Shift End Time",
          value: "shft_EndTime",
          align: "center",
          sortable: true,
        },
        {
          text: "Clock In",
          value: "clock_In_Time",
          align: "center",
          sortable: true,
        },
        {
          text: "Clock Out",
          value: "clock_Out_Time",
          align: "center",
          sortable: true,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
      CalCinCoutList: [],
      error: {
        errormessagetimetype: "",
      },
      timeTypeItem: ["Regular", "Overtime"],
      type: "month",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      years: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025],
      todaymonths: null,
      todayyears: null,
      events: [],
      SortingDate: "",
      SortedDateList: [],
      MySortDateList: [],
      DataShowList: [],
      count: 0,
      countnext: 0,
      countcom: 0,
      countcomnext: 0,
      thelang: "",
    };
  },
  computed:{
    monthNames1 () {
       return [
      { val: 1, txt: this.$t("January") },
      { val: 2, txt: this.$t("February") },
      { val: 3, txt: this.$t("March") },
      { val: 4, txt: this.$t("April")  },
      { val: 5, txt: this.$t("May") },
      { val: 6, txt:  this.$t("June") },
      { val: 7, txt: this.$t("July") },
      { val: 8, txt: this.$t("August") },
      { val: 9, txt: this.$t("September") },
      { val: 10, txt: this.$t("October") },
      { val: 11, txt: this.$t("November") },
      { val: 12, txt:this.$t("December") },
    ]},
    thethaihead() {
      return this.whatthehead();
    },
  },
  watch: {
    thelang() {
      this.whatthehead()
    },
  },
  mounted() {
    
    let d = new Date();
    //this.todaymonth = this.monthNames[d.getMonth()] + " " + d.getFullYear();
    this.$refs.calendar.checkChange();
    this.todaymonths = this.monthNames1[d.getMonth()].val;
    this.todayyears = new Date();
    this.GetTimeView();
    this.GetTimeCalendar();
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  created() {
    this.events.push({
      name: "Vacation",
      start: "2019-07-08",
      end: "2019-07-08",
    });
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    GetTimeView() {
      let self = this;
      let temp = {
        employee_ID: store.state.employeeID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceView/GetTimeAttendanceViewByEmployeeID`,
          temp
        )
        .then(function (response) {
          self.TimeViewList = response.data.data;
          self.showsheetview = self.TimeViewList[0].calendar_View;

        });
    },
    // gotoview(id) {
    //   this.$router.push({ name: "timeentriessheetview", params: { id: id } });
    // },
    Converttolocals(item) {
      if (item != "-") {
        let todate = new Date(Date.parse(item));
        // let [hours, minutes] = item.split(":");
        // todate.setHours(+hours);
        // todate.setMinutes(minutes);
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        return output;
      } else {
        return "-";
      }
    },
    GetTimeCalendar() {
      let self = this;
      self.LoadingDialog = true;
      let num = self.todaymonths;
      let yer = self.todayyears.getFullYear();
      let temp = {
        company_ID: store.state.companyID,
        employee_ID: parseInt(self.$route.params.id),
        month_Num: num,
        year_Num: yer,
      };

      axios
        .post(`${self.url}TimeEntries/GetTimeEntriesByEmployeeIDAndTime`, temp)
        .then(function (response) {
          self.CinCoutList = response.data.data;
          for (let i = 0; i < self.CinCoutList.length; i++) {
            let intime = "-";
            let outtime = "-";
            let otintime = "-";
            let otouttime = "-";
            let cinoutcount = 0;
            let otcinoutcount = 0;
            // if (self.CinCoutList[i].regCinoutData.length != 0){
            //   let leg = self.CinCoutList[i].regCinoutData.length - 1;
            //   intime = self.CinCoutList[i].regCinoutData[0].clock_In_Time;
            //   outtime = self.CinCoutList[i].regCinoutData[leg].clock_Out_Time;
            //   cinoutcount = self.CinCoutList[i].regCinoutData.length;
            // }
            // if (self.CinCoutList[i].otCinoutData.length != 0){
            //   let leg = self.CinCoutList[i].otCinoutData.length - 1;
            //   intime = self.CinCoutList[i].otCinoutData[0].oT_Clock_In_Time;
            //   outtime = self.CinCoutList[i].otCinoutData[leg].oT_Clock_Out_Time;
            //   otcinoutcount = self.CinCoutList[i].otCinoutData.length;
            // }
            let regin = "-";
            let regout1 = "-";
            let latein = "-";
            let regout = "-";
            let otin = "-";
            let otout = "-";
            let lateotin = "-";
            let outlist = [];
            let otoutlist = [];
            let yesc = false;
            let inregular = [];
            let otinregular = [];
            
            if (self.CinCoutList[i].irregularRegCinoutData.length != 0) {
              latein = self.CinCoutList[i].irregularRegCinoutData[0].clock_In_Time;
              for(let c = 0; c < self.CinCoutList[i].irregularRegCinoutData.length; c++){
                outlist.unshift({
                  time: self.CinCoutList[i].irregularRegCinoutData[c].clock_Out_Time
                });
              }
            }

            if (self.CinCoutList[i].regCinoutData.length != 0) {
              let leg = self.CinCoutList[i].regCinoutData.length - 1;
              regin = self.CinCoutList[i].regCinoutData[0].clock_In_Time;
              regout = self.CinCoutList[i].regCinoutData[leg].clock_Out_Time;
              regout1 = self.CinCoutList[i].regCinoutData[0].clock_Out_Time;
              cinoutcount = self.CinCoutList[i].regCinoutData.length;
              for(let a = 0; a < self.CinCoutList[i].regCinoutData.length; a++){
                outlist.unshift({
                  time: self.CinCoutList[i].regCinoutData[a].clock_Out_Time
                });
              }
              inregular = self.CinCoutList[i].regCinoutData.filter(
                (val) => val.clock_In_Time != "-"
              );
            }

            if (self.CinCoutList[i].irregularOTCinoutData.length != 0) {
              lateotin = self.CinCoutList[i].irregularOTCinoutData[0].oT_Clock_In_Time;
              for(let d = 0; d < self.CinCoutList[i].irregularOTCinoutData.length; d++){
                otoutlist.unshift({
                  time: self.CinCoutList[i].irregularOTCinoutData[d].oT_Clock_Out_Time
                });
              }
            }

            if (self.CinCoutList[i].otCinoutData.length != 0) {
              let leg = self.CinCoutList[i].otCinoutData.length - 1;
              otin = self.CinCoutList[i].otCinoutData[0].oT_Clock_In_Time;
              otout = self.CinCoutList[i].otCinoutData[leg].oT_Clock_Out_Time;
              otcinoutcount = self.CinCoutList[i].otCinoutData.length;
              for(let b = 0; b < self.CinCoutList[i].otCinoutData.length; b++){
                otoutlist.unshift({
                  time: self.CinCoutList[i].otCinoutData[b].oT_Clock_Out_Time
                });
              }
              otinregular = self.CinCoutList[i].otCinoutData.filter(
                (val) => val.oT_Clock_In_Time != "-"
              );
            }

            

            
            if (regin != "-" && regin != null) {
              intime = regin;
            } else if (latein != "-" && latein != null) {
              intime = latein;
            }

           for(let e = 0; e < outlist.length; e++){
              if(outlist[e].time != "-"){
                outtime = outlist[e].time;
                break;
              }
            }

            if (otin != "-" && otin != null) {
              otintime = otin;
            } else if (lateotin != "-" && lateotin != null) {
              otintime = lateotin;
            }

            for(let f = 0; f < otoutlist.length; f++){
              if(otoutlist[f].time != "-"){
                otouttime = otoutlist[f].time;
                break;
              }
            }

            let maincondi = false;
            if (inregular.length == 0 && otinregular.length == 0) {
              maincondi = true;
            }

            if (
              (regin != "-" &&
              regin != null &&
              ((regout != "-" &&
              regout != null) || (regout1 != "-" &&
              regout1 != null))) ||
              (otin != "-" &&
              otin != null &&
              otout != "-" &&
              otout != null)
            ) {
              yesc = true;
            }

            if (
              self.CinCoutList[i].oT_Shift_StartTime == null ||
              self.CinCoutList[i].oT_Shift_StartTime == ""
            ) {
              self.CinCoutList[i].oT_Shift_StartTime = "-";
            }
            if (
              self.CinCoutList[i].oT_Shift_EndTime == null ||
              self.CinCoutList[i].oT_Shift_EndTime == ""
            ) {
              self.CinCoutList[i].oT_Shift_EndTime = "-";
            }
            self.events.push({
              name: "Vacation",
              start: self.formatDate1(self.CinCoutList[i].timeEntry_Date),
              end: self.formatDate1(self.CinCoutList[i].timeEntry_Date),
              regCinoutData: self.CinCoutList[i].regCinoutData,
              otCinoutData: self.CinCoutList[i].otCinoutData,
              clock_In_Time: intime,
              clock_Out_Time: outtime,
              oT_Clock_In_Time: otintime,
              oT_Clock_Out_Time: otouttime,
              regcount: cinoutcount,
              otcount: otcinoutcount,
              yescondi: yesc,
              maincondi: maincondi,
              shift_StartTime: self.CinCoutList[i].shift_StartTime,
              shft_EndTime: self.CinCoutList[i].shft_EndTime,
              oT_Shift_StartTime: self.CinCoutList[i].oT_Shift_StartTime,
              oT_Shift_EndTime: self.CinCoutList[i].oT_Shift_EndTime,
              tO_StartTime: self.CinCoutList[i].tO_StartTime,
              tO_EndTime: self.CinCoutList[i].tO_EndTime,
              timeEntry_Date: self.CinCoutList[i].timeEntry_Date,
              time_ID: self.CinCoutList[i].time_ID,
              clock_ID: self.CinCoutList[i].clock_ID,
              time_Type: self.CinCoutList[i].time_Type,
              // clock_In_Location: self.CinCoutList[i].clock_In_Location,
              // clock_In_Lattitude: self.CinCoutList[i].clock_In_Lattitude,
              // clock_In_Longitude: self.CinCoutList[i].clock_In_Longitude,
              // clock_Out_Location: self.CinCoutList[i].clock_Out_Location,
              // clock_Out_Lattitude: self.CinCoutList[i].clock_Out_Lattitude,
              // clock_Out_Longitude: self.CinCoutList[i].clock_Out_Longitude,
              approve: self.CinCoutList[i].approve,
              remark: self.CinCoutList[i].remark,
              remarkTH: self.CinCoutList[i].remarkTH,
              company_ID: self.CinCoutList[i].company_ID,
              showview: false,
              showTimeOff: false,
              showOT: false,
              showholiday: false,
            });
          }
          for (let k = 0; k < self.events.length; k++) {
            if (self.events[k].remark == "View") {
              self.events[k].showview = true;
              self.events[k].showTimeOff = false;
              self.events[k].showOT = false;
              self.events[k].showholiday = false;
            } else if (self.events[k].remark == "Holiday") {
              self.events[k].showview = false;
              self.events[k].showTimeOff = false;
              self.events[k].showOT = false;
              self.events[k].showholiday = true;
            } else if (self.events[k].remark == "Over Time") {
              self.events[k].showview = false;
              self.events[k].showTimeOff = false;
              self.events[k].showOT = true;
              self.events[k].showholiday = false;
            } else {
              self.events[k].showview = false;
              self.events[k].showTimeOff = true;
              self.events[k].showOT = false;
              self.events[k].showholiday = false;
            }
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Timesheet Fail", 23, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    ChangeMonths(mth) {
      let self = this;
      let d = new Date();
      d.setMonth(mth - 1);
      let year = self.todayyears.getFullYear();
      d.setFullYear(year);
      let mm = parseInt(mth);
      self.todaymonths = mm;
      self.focus = d;
      self.events = [];
      self.GetTimeCalendar();
    },
    ChangeYears(year) {
      let self = this;
      let d = new Date();
      d.setMonth(self.todaymonths - 1);
      let y = year.getFullYear();
      d.setFullYear(y);
      self.focus = d;
      self.events = [];
      self.GetTimeCalendar();
    },
    ShiftValis(item) {
      let self = this;
      if (item.maincondi == false || item.maincondi == "false") {
        if (item.yescondi == "true" || item.yescondi == true) {
          return true;
        } else {
          if (item.shift_StartTime != "-" && item.oT_Shift_StartTime == "-") {
            let from = new Date(Date.parse(item.clock_In_Time));
            let to = new Date(Date.parse(item.clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.shft_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }
            return sslatetime;
          } else if (item.shift_StartTime == "-" && item.oT_Shift_StartTime != "-") {
            let from = new Date(Date.parse(item.oT_Clock_In_Time));
            let to = new Date(Date.parse(item.oT_Clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.oT_Shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.oT_Shift_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }
            return sslatetime;
          } else if (item.shift_StartTime != "-" && item.oT_Shift_StartTime != "-") {
            let from = new Date(Date.parse(item.clock_In_Time));
            let to = new Date(Date.parse(item.clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.shft_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }

            let otfrom = new Date(Date.parse(item.oT_Clock_In_Time));
            let otto = new Date(Date.parse(item.oT_Clock_Out_Time));

            //ot in time
            let otshiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let otshiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [otsshours, otssminutes] = item.oT_Shift_StartTime.split(":");
            otshiftstartbefore.setHours(+otsshours);
            otshiftstartbefore.setMinutes(otssminutes);
            otshiftstartbefore.setSeconds("0");
            otshiftstartbefore.setMilliseconds("0");
            otshiftstartafter.setHours(+otsshours);
            otshiftstartafter.setMinutes(otssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                otshiftstartbefore.getHours() - parseInt(self.startbefore);
              otshiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                otshiftstartbefore.getMinutes() - parseInt(self.startbefore);
              otshiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds =
                otshiftstartafter.getHours() + parseInt(self.startafter);
              otshiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                otshiftstartafter.getMinutes() + parseInt(self.startafter);
              otshiftstartafter.setMinutes(adds);
            }
            //ot out time
            let otshiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let otshiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [otsehours, otseminutes] = item.oT_Shift_EndTime.split(":");
            otshiftendbefore.setHours(+otsehours);
            otshiftendbefore.setMinutes(otseminutes);
            otshiftendbefore.setSeconds("0");
            otshiftendbefore.setMilliseconds("0");
            otshiftendafter.setHours(+otsehours);
            otshiftendafter.setMinutes(otseminutes);
            if (self.endbeforetime == "hour") {
              let adds = otshiftendbefore.getHours() - parseInt(self.endbefore);
              otshiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds =
                otshiftendbefore.getMinutes() - parseInt(self.endbefore);
              otshiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = otshiftendafter.getHours() + parseInt(self.endafter);
              otshiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = otshiftendafter.getMinutes() + parseInt(self.endafter);
              otshiftendafter.setMinutes(adds);
            }

            let otfrtimezone = otfrom.getTimezoneOffset() * 60000;
            let otfrnewDate = new Date(otfrom.getTime() - otfrtimezone);
            let ottotimezome = otto.getTimezoneOffset() * 60000;
            let ottonewDate = new Date(otto.getTime() - ottotimezome);
            let otssnewDate = new Date(otshiftstartafter.getTime());
            let otssbnewDate = new Date(otshiftstartbefore.getTime());
            let otsenewDate = new Date(otshiftendafter.getTime());
            let otsebnewDate = new Date(otshiftendbefore.getTime());
            let otsslatetime = true;
            if (item.approve != true) {
              if (item.oT_Clock_In_Time != "-") {
                if (otfrnewDate > otssnewDate) {
                  otsslatetime = false;
                } else if (otssbnewDate > otfrnewDate) {
                  otsslatetime = false;
                } else {
                  otsslatetime = true;
                }
                if (item.oT_Clock_Out_Time != "-" && otsslatetime == true) {
                  if (ottonewDate > otsenewDate) {
                    otsslatetime = false;
                  } else if (otsebnewDate > ottonewDate) {
                    otsslatetime = false;
                  } else {
                    otsslatetime = true;
                  }
                }
              }
            } else {
              otsslatetime = true;
            }
            if (sslatetime == true && otsslatetime == true) {
              return true;
            } else if (item.clock_In_Time != "-" && item.clock_Out_Time != "-" && item.oT_Clock_In_Time == "-" && item.oT_Clock_Out_Time == "-"){
              if(sslatetime == true){
                return true;
              }else{
                return false;
              }
            } else if (item.clock_In_Time == "-" && item.clock_Out_Time == "-" && item.oT_Clock_In_Time != "-" && item.oT_Clock_Out_Time != "-"){
              if(otsslatetime == true){
                return true;
              }else{
                return false;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } else {
        if (item.clock_In_Time == "-" && item.clock_Out_Time == "-" && item.oT_Clock_In_Time == "-" && item.oT_Clock_Out_Time == "-") {
          return true;
        } else {
          return false;
        }
      }
    },
    GoViewTimeSheet(item) {
      let tgetdate = "";
      let tempdate = new Date( Date.parse(item.timeEntry_Date) );
      let day = tempdate.getDate();
      let month = tempdate.getMonth() + 1;
      let year = tempdate.getFullYear();
      tgetdate = `${year}-${month}-${day}`
      localStorage.setItem(
        "get_date_of_today",
        JSON.stringify(tgetdate)
      );
      localStorage.setItem("shift_start", JSON.stringify(item.shift_StartTime));
      localStorage.setItem("shift_end", JSON.stringify(item.shft_EndTime));
      localStorage.setItem(
        "otshift_start",
        JSON.stringify(item.oT_Shift_StartTime)
      );
      localStorage.setItem(
        "otshift_end",
        JSON.stringify(item.oT_Shift_EndTime)
      );
      localStorage.setItem("toshift_start", JSON.stringify(item.tO_StartTime));
      localStorage.setItem("toshift_end", JSON.stringify(item.tO_EndTime));
      localStorage.setItem(
        "timoffcon",
        JSON.stringify(item.time_Off_Whole_Day)
      );
      localStorage.setItem("local_remark", JSON.stringify(item.remark));
      this.$router.push({
        name: "Timesheetdetail1",
        params: { id: this.$route.params.id },
      }).catch(()=>{});
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    formatDate1(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[0] + "-" + finaldate[1] + "-" + finaldate[2];
    },
  },
};
</script>
<style scoped>
.mr-8 {
  height: 45px !important;
}
@media only screen and (max-width: 375px) {
  .mr-8 {
    height: 90px !important;
  }
}
>>> .v-calendar-weekly__head-weekday {
  color: black !important;
}
/* @media only screen and (min-width: 1024) and (max-width: 1366px) {
  .day-monthtoggle {
    height: 25px !important;
  }
} */
@media only screen and (max-width: 414px) {
  .table_data {
    margin-top: 1rem !important;
  }
}
@media only screen and (max-width: 375px) {
  .today_years {
    width: 306px !important;
    margin-left: 1rem !important;
  }
}
@media only screen and (max-width: 320px) {
  .today_years {
    width: 252px !important;
    margin-left: 1rem !important;
  }
}
@media only screen and (max-width: 280px) {
  .today_years {
    width: 210px !important;
    margin-left: 1rem !important;
  }
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
>>> .selectboxs {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}
>>> .selectboxs.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
>>> .selectboxs.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
>>> .selectboxs.theme--light.v-select .v-select__selections {
  color: #444444;
}
.fontwei {
  font-weight: normal;
  /* margin-left: 15px; */
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 24px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 16px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
/* >>> .theme--light.v-data-table thead tr th {
  font-size: 15px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
} */
>>> .v-calendar-weekly__day-label {
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: left;
  margin: 4px 0 0 0;
}
>>> .v-application .primary {
  margin-left: 10px;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
>>> .v-calendar .v-event {
  position: relative;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  margin-top: 0px;
  margin-right: 15px;
  margin-left: 2px;
  background: transparent !important;
}
/* >>>.v-calendar .v-event.v-event-start {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
 >>>.v-calendar .v-event.v-event-start .v-application .secondary {
      background-color: #424242 !important; 
     border-color: #424242 !important; 
} */
>>> .element.style {
  height: 60px;
  top: 10px;
  margin-bottom: 1px;
}
>>> .v-calendar-weekly__head-weekday {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 20px;
  flex: 1 0 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 15px px 0px 4px;
  font-size: 18px;
  background-color: bisque;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}
>>> .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: bisque;
}
.my-event {
  overflow: visible;
  text-overflow: ellipsis;
  border-radius: 2px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  width: 100%;
  height: 50px;
  font-size: 12px;
  cursor: pointer;
  padding-left: 20px;
  padding-top: 0px;
  margin-bottom: 1px;
}
.line-clamp {
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chiptoggle {
  margin-top: -85px;
  margin-left: 35px;
}

>>> .v-application .white--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
  background: transparent !important;
}
@media (max-width: 900px) {
  .tbheader {
    margin-top: 1rem !important;
  }
  .chiptoggle {
    transform: rotate(-90deg);
    margin-top: 5px;
    margin-left: -5px;
    margin-right: 5px;
    max-width: 45px;
  }
  .hidetxts {
    visibility: hidden;
  }
}
@media (max-width: 280px) {
  .minizebox {
    width: 100px;
    height: 30px;
  }
}
@media (max-width: 320px) {
  .fix-calender {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .chiptoggle {
    transform: rotate(-90deg);
    margin-top: 5px;
    margin-left: -8px;
    margin-right: 5px;
    max-width: 40px;
  }
  .fixed-calendar {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}
@media (max-width: 375px) {
  .fix-calender {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .fixed-calendar {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
>>> .mx-input {
  color: black;
}

>>> .mdi:before {
  color: black;
}

>>> .theme--light.v-calendar-weekly .v-calendar-weekly__day {
  /* border-right: #ccc8c8 1px solid; */
  /* border-bottom: #e0e0e0 1px solid; */
  color: #000000;
  border: #ccc8c8 1px solid;
}
</style>