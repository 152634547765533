<template>
  <div class="mb-5">
    <div class="ml-5 mr-5">
      <v-expansion-panels v-model="blah" flat>
        <v-expansion-panel
          class="mt-8 ml-4 mr-4 mb-5"
          style="width:auto; border:#D0CECE 1px solid; border-radius: 5px;"
        >
          <v-expansion-panel-header style="height:95px">
            <template v-slot:default>
              <v-row no-gutters>
                <v-col cols="6" class="headers">
                  {{ $t("checkin checkout setting") }}
                  <v-fade-transition leave-absolute></v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-divider class="custom-divider"></v-divider>

            <p class="subheaders pt-5" style="color:#444444">{{ $t("allow checkout at different location") }}</p>
            <v-row>
              <v-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
                xs="12"
                style="text-align:left !important; padding-top:0px"
              >
                <v-radio-group
                  style="position:relative"
                  class="location"
                  v-model="settingLocation.checkOut"
                  row
                >
                  <v-radio class="mb-3" @change="isAllowLocation" color="#F99D20" value="true">
                    <template v-slot:label>
                      <span class="custom-label">{{ $t("allow") }}</span>
                    </template>
                  </v-radio>
                  <v-radio class="mb-3" @change="isAllowLocation" color="#F99D20" value="false">
                    <template v-slot:label>
                      <span class="custom-label">{{ $t("block") }}</span>
                    </template>
                  </v-radio>

                  <!-- <span
    cols="12" md="12" sm="12"
    class="subheaders"
    v-if="settingLocation.checkOut == 'false'"
    style="padding-top: 10px;
    margin-left:140px;
    display:block;
    "
  >{{ $t("Distance allow for checkin location") }} :</span>

  <span v-if="settingLocation.checkOut == 'false'" class="ml-3" style="padding-top: 7px;">
    <v-text-field
      v-model="distance"
      style="max-width:100px !important; height:48px;"
      type="number"
      color="#F99D20"
      outlined
    ></v-text-field>
    <span
      v-if="distance < 50"
      style="font-size:14px;
     font-weight:300; color:#FF4B55;
     position:absolute;
     top:40px"
    >{{ $t("Distance limit alert") }}</span>
  </span>

  <span
    class="subheaders"
    v-if="settingLocation.checkOut == 'false'"
    style="padding-top: 10px;
    margin-left:15px;
   ;
    "
                  >{{$t("meter(s)")}}</span>-->
                </v-radio-group>
              </v-col>
              <v-col
                class="margin:auto !important"
                cols="8"
                lg="8"
                md="12"
                sm="12"
                xs="12"
                style="padding-top:0px"
              >
                <v-row style="margin:auto; margin-top:30px; position:relative;">
                  <span
                    class="subheaders mt-2"
                    v-if="settingLocation.checkOut == 'false'"
                  >{{ $t("Distance allow for checkin location") }} :</span>

                  <span v-if="settingLocation.checkOut == 'false'" class="ml-3">
                    <v-text-field
                      v-model="settingLocation.distance"
                      style="max-width:100px !important; height:48px;"
                      type="number"
                      color="#F99D20"
                      outlined
                      dense
                    ></v-text-field>
                  </span>

                  <span
                    class="subheaders mt-2"
                    v-if="settingLocation.checkOut == 'false'"
                    style="margin-left:15px"
                  >{{$t("meter(s)")}}</span>

                  <span
                    v-if="settingLocation.distance < 50 && settingLocation.checkOut=='false'"
                    style="font-size:14px;
                      font-weight:300; color:#FF4B55;
                      position:absolute;
                      left:19rem;
                      top:40px"
                  >{{ $t("Distance limit alert") }}</span>
                </v-row>
              </v-col>
            </v-row>

            <p class="mt-5 subheaders" style="color:#444444">{{ $t("allow checkin offsite") }}</p>
            <v-radio-group v-model="settingLocation.checkIn" row>
              <v-radio color="#F99D20" value="true">
                <template v-slot:label>
                  <span class="custom-label">{{ $t("allow") }}</span>
                </template>
              </v-radio>
              <v-radio color="#F99D20" value="false">
                <template v-slot:label>
                  <span class="custom-label">{{ $t("block") }}</span>
                </template>
              </v-radio>
            </v-radio-group>

            <v-row style="margin:auto">
              <p class="subheaders" style="color:#444444">{{ $t("check in attendance picture") }}</p>
              <div class="ml-10">
                <input type="checkbox" v-model="settingLocation.checkInToggle" />
              </div>
            </v-row>

            <v-radio-group
              row
              v-if="settingLocation.checkInToggle == true"
              v-model="settingLocation.checkInPic"
            >
              <v-radio color="#F99D20" value="true">
                <template v-slot:label>
                  <span class="custom-label">{{ $t("required") }}</span>
                </template>
              </v-radio>
              <v-radio color="#F99D20" value="false">
                <template v-slot:label>
                  <span class="custom-label">{{ $t("not required") }}</span>
                </template>
              </v-radio>
            </v-radio-group>

            <br />

            <v-row
              :style="settingLocation.checkInToggle == true ? 'margin:auto;' : 'margin:auto; margin-top:10px;'"
            >
              <p class="subheaders" style="color:#444444">{{ $t('check out attendance picture') }}</p>
              <div class="ml-7">
                <input type="checkbox" v-model="settingLocation.checkOutToggle" />
              </div>
            </v-row>
            <v-radio-group
              row
              v-if="settingLocation.checkOutToggle == true"
              v-model="settingLocation.checkOutPic"
            >
              <v-radio color="#F99D20" value="true">
                <template v-slot:label>
                  <span class="custom-label">{{ $t("required") }}</span>
                </template>
              </v-radio>
              <v-radio color="#F99D20" value="false">
                <template v-slot:label>
                  <span class="custom-label">{{ $t("not required") }}</span>
                </template>
              </v-radio>
            </v-radio-group>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                text
                outlined
                class="text-capitalize mb-5 mr-5 commoncancelbtn"
                @click="reset()"
              >{{ $t("Cancel") }}</v-btn>
              <v-btn
                class="text-capitalize commonsavebtn mb-5 mr-3"
                width="131px"
                @click="addsetting()"
                :disabled="permission.edit"
              >
                {{
                $t("save") }}
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-divider class="ml-5 mr-5 mt-5 custom-divider"></v-divider>
    <div class="ml-5 mr-5 mt-5">
      <p class="headers">{{ $t('workinlocation') }}</p>
      <p style="font-size:16px; color:#5F5B5B; font-weight:300; line-height:23.92px;">{{ $t("Set the work") }}</p>
    </div>
    <br />
    <v-row class="ml-3 mr-5">
      <!-- <v-text-field
        class="searchbar"
        v-model="search"
        outlined
        color="#F99D20"
        prepend-inner-icon="mdi-magnify"
        :placeholder="searchholder"
      ></v-text-field> -->
      <v-col cols="12" xs="12" sm="6" md="4" lg="3">
        <v-text-field
          :placeholder="$t('Search')"
          v-model="search"
          color="#F99D20"
          dense
          outlined
          autocomplete="off"
          prepend-inner-icon="mdi-magnify"
          style="font-size:14px; margin-top:-0.7rem;"
          @keyup.enter="Search()">
        </v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        color="#F99D20"
        @click="addLocation()"
        style="max-width:226px"
        class="text-capitalize white--text"
        :disabled="permission.add">
        <v-icon>mdi-plus</v-icon>
        <span>
          {{ $t("Add Workin Location") }}
          </span>
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      class="mt-4 ml-5 mr-5 pb-4"
      style="max-height: 630px; overflow-y: auto;"
      hide-default-header
      hide-default-footer
      disable-pagination
    >
      <template v-slot:header="{ props: { } }">
        <thead>
          <tr class="Rheader">
            <th>{{ $t("workinlocation") }}</th>
            <th>{{ $t("Description") }}</th>
            <th>{{ $t("Radius") }}</th>
            <th>{{ $t("status") }}</th>
            <th style="text-align:right;">{{ $t("Actions") }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr style="height: 61px;">
          <td>
            <div class="horizontal-content">
              <img src="@/assets/images/map-marker-radius 1.png" alt="Icon" width="27px" height="27px" />
              <p class="pt-3 ml-2">{{ item.workinLocationName }}</p>
            </div>
          </td>
          <td>
            <p class="pt-3">{{ item.description }}</p>
          </td>
          <td>
            <p class="pt-3">{{ item.radius }}</p>
          </td>
          <td>
            <p class="pt-3">{{ item.activeString }}</p>
          </td>
          <td style="text-align:right;">
            <v-btn icon @click="openEditlocation(item)" :disabled="permission.edit">
            <img class="editIcon" :src="images.edit" alt="editIcon"/>
          </v-btn>
          <v-btn icon @click="deleteItem(item.workinLocationID)" :disabled="permission.delete">
            <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
          </v-btn>
          </td>
        </tr>
      </template>
      <!-- <template v-slot:item.workingLocation="{ item }">
        <v-row cols="12">
          <v-btn fab text  icon class="gps">
          <img src="@/assets/images/map-marker-radius 1.png" alt="Icon" width="27px" height="27px" />
        </v-btn>
        <p class="pt-3 ml-2">{{ item.workinLocationName }}</p>
        </v-row>
      </template>
      <template v-slot:item.description="{ item }">
        <v-row style="margin:auto" col="12">
          <p class="pt-3">{{ item.description }}</p>
        </v-row>
      </template>
      <template v-slot:item.radius="{ item }">
        <v-row style="margin:auto" col="12">
          <p class="pt-3">{{ item.radius }}</p>
        </v-row>
      </template>
      <template v-slot:item.status="{ item }">
        <v-row style="margin:auto" col="12">
          <p class="pt-3">{{ item.activeString }}</p>
        </v-row>
      </template>
      <template v-slot:item.actions="{ item }">
        <div style="width:100%; text-align:center;">
          <v-btn icon @click="openEditlocation(item)" :disabled="permission.edit">
            <img class="editIcon" :src="images.edit" />
          </v-btn>
          <v-btn icon @click="deleteItem(item.workinLocationID)" :disabled="permission.delete">
            <img class="deleteIcon" :src="images.delete" />
          </v-btn>
        </div>
      </template> -->
    </v-data-table>
    <v-dialog v-model="deleteDialog" class="dialog" max-width="590">
      <v-card>
        <v-card-title>
          <p
            class="ml-8 mt-6"
            style="margin-top: 10px; margin-bottom: -30px; font-size:24px; color:#000"
          >{{ $t("Please confirm the operation")}}</p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row style="margin:auto;">
              <v-col cols="12">
                <p
                  style="font-size:20px; color:#7D7A7A"
                  class="mt-2 ml-2"
                >{{ $t("Are you sure to delete?") }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            style="color:#838395"
            outlined
            width="135"
            max-height="40"
            @click="deleteDialog = false; deleteID=null;"
            text
          >
            {{
            $t("cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-2 white--text text-capitalize"
            width="135"
            max-height="40"
            @click="deleteLocation()"
          >
            {{ $t("delete")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" class="adddialog" max-width="930">
      <v-card style="border-radius: 15px;">
        <v-card-actions class="ml-2" style="height:60px">
          <p class="pt-5 mr-2" style="font-size: 22px;">
            {{ $t("Add Workin Location") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancelAddLocation()">
            <img src="@/assets/images/close 1.png" />
          </v-btn>
        </v-card-actions>
        <v-divider class="custom-divider"></v-divider>
        <v-card-text class="mt-2">
          <!-- <v-container>
            <v-row style="margin:auto;">
              <v-col cols="12">
                <p
                  style="font-size:20px; color:#7D7A7A"
                  class="mt-2 ml-2"
                >Are you sure you want to delete the data?</p>
              </v-col>
            </v-row>
          </v-container>-->
          <v-row class="mt-1">
            <v-col>
              <p class="subheaders">{{ $t("Workin Location Name") }}<span class="red--text"> *</span></p>
              <v-text-field
                v-model="requestLocation.locationName"
                outlined
                color="#F99D20"
                :placeholder="locationholder"
                :error-messages="error.locationName"
                required
              >
              <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span>{{ error.locationName }}</span>
                  </template>
              </v-text-field>
            </v-col>
            <v-col>
              <p class="subheaders">{{ $t("Description") }}</p>
              <v-text-field
                v-model="requestLocation.description"
                outlined
                color="#F99D20"
                :placeholder="descriptionholder"
              ></v-text-field>
            </v-col>
          </v-row>
          <p class="subheaders" style="position:relative;">
            {{ $t("Location Coordinates") }}<span class="red--text"> *</span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <img
                  :src="isHovered ? outlineinfoimg : infoimg"
                  @mouseover="handleMouseOver"
                  max-width="30"
                  max-height="30"
                  v-bind="attrs"
                  v-on="on"
                  @mouseleave="handleMouseLeave"
                  class="infoimg"
                />
              </template>

              <span>
                <span style="font-weight:bold">{{ $t("Howtocheckin") }}</span>
                <br />
                {{ $t("checkin1") }}
                <br />
                {{ $t("checkin2") }}
                <br />
                {{ $t("checkin3") }}
                <br />
                {{ $t("checkin4") }}
                <br />
              </span>
            </v-tooltip>
          </p>
          <v-text-field
            v-model="requestLocation.coordinates"
            outlined
            color="#F99D20"
            :placeholder="coordinatesholder"
            required
            :error-messages="error.coordinates"
          >
          <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span>{{ error.coordinates }}</span>
                  </template>
              </v-text-field>
          <p class="subheaders">{{ $t("Radius") }}</p>
          <v-row class="margin:auto !important">
            <v-col cols="10" style="position:relative;">
              <v-slider
                color="#F99D20"
                track-color="#EEEEEE"
                v-model="requestLocation.radius"
                :max="maxRange"
                :min="minRange"
              ></v-slider>
              <span
                v-if="requestLocation.radius < 100"
                style="position:absolute; font-size:14px; color:#F74747; left:20px; top:35px"
              >{{ $t("Radius limit alert")}}</span>
            </v-col>
            <v-col cols="2" style="text-align:center;">
              <p
                style="color:#000000; font-size:16px;"
              >{{ requestLocation.radius + " " + $t("meter") }}</p>
            </v-col>
          </v-row>
          <p class="subheaders">{{ $t("status") }}</p>
          <v-btn-toggle v-model="requestLocation.status">
            <v-btn
              class="text-capitalize"
              :class="requestLocation.status == 0 ? 'clicked' : 'unclicked'"
              width="87px"
              style="height:33px"
            >
              <span :style="requestLocation.status == 0 ? 'color:#ffffff' : 'color:#7D7A7A'">
                {{ $t("Active")
                }}
              </span>
            </v-btn>
            <v-btn
              class="text-capitalize"
              :class="requestLocation.status == 1 ? 'clicked' : 'unclicked'"
              width="87px"
              style="height:33px"
            >
              <span :style="requestLocation.status == 1 ? 'color:#ffffff' : 'color:#7D7A7A'">
                {{
                $t("Inactive") }}
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
        <v-card-actions class="pb-5 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            style="color:#838395"
            outlined
            width="135"
            max-height="40"
            @click="cancelAddLocation()"
            text
          >
            {{
            $t("cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-2 white--text text-capitalize"
            width="135"
            max-height="40"
            @click="saveWorkinLocation()"
          >
            {{ $t("save")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" class="adddialog" max-width="930">
      <v-card>
        <v-card-actions class="ml-2" style="height:60px">
          <p class="pt-4" style="color: #7D7A7A;font-size: 18px;">{{ $t("editworkinlocation") }}</p>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancelEditLocation()">
            <img src="@/assets/images/close 1.png" />
          </v-btn>
        </v-card-actions>
        <v-divider class="custom-divider"></v-divider>
        <v-card-text class="mt-2">
          <!-- <v-container>
            <v-row style="margin:auto;">
              <v-col cols="12">
                <p
                  style="font-size:20px; color:#7D7A7A"
                  class="mt-2 ml-2"
                >Are you sure you want to delete the data?</p>
              </v-col>
            </v-row>
          </v-container>-->
          <v-row class="mt-1">
            <v-col>
              <p class="subheaders">{{ $t("Workin Location Name") }}<span class="red--text"> *</span></p>
              <v-text-field
                v-model="editLocation.locationName"
                outlined
                color="#F99D20"
                :placeholder="locationholder"
                :error-messages="error.locationName"
              >
              <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span>{{ error.locationName }}</span>
                  </template>
              </v-text-field>
            </v-col>
            <v-col>
              <p class="subheaders">{{ $t("Description") }}</p>
              <v-text-field
                v-model="editLocation.description"
                outlined
                color="#F99D20"
                :placeholder="descriptionholder"
              ></v-text-field>
            </v-col>
          </v-row>
          <p class="subheaders" style="position:relative;">
            {{ $t("Location Coordinates") }}<span class="red--text"> *</span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <img
                  :src="isHovered ? outlineinfoimg : infoimg"
                  @mouseover="handleMouseOver"
                  max-width="30"
                  max-height="30"
                  v-bind="attrs"
                  v-on="on"
                  @mouseleave="handleMouseLeave"
                  class="infoimg"
                />
              </template>

              <span>
                <span style="font-weight:bold">{{ $t("Howtocheckin") }}</span>
                <br />
                {{ $t("checkin1") }}
                <br />
                {{ $t("checkin2") }}
                <br />
                {{ $t("checkin3") }}
                <br />
                {{ $t("checkin4") }}
                <br />
              </span>
            </v-tooltip>
          </p>
          <v-text-field
            v-model="editLocation.coordinates"
            outlined
            color="#F99D20"
            :placeholder="coordinatesholder"
            :error-messages="error.coordinates"
          >
          <template v-slot:message="{ }">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" />
                    <span>{{ error.coordinates }}</span>
                  </template>
          </v-text-field>
          <p class="subheaders">{{ $t("Radius") }}</p>
          <v-row class="margin:auto !important">
            <v-col cols="10" style="position:relative;">
              <v-slider
                color="#F99D20"
                track-color="#EEEEEE"
                v-model="editLocation.radius"
                :max="maxRange"
                :min="minRange"
              ></v-slider>
              <span
                v-if="editLocation.radius < 100"
                style="position:absolute; font-size:14px; color:#F74747; left:20px; top:35px"
              >{{ $t("Radius limit alert")}}</span>
            </v-col>
            <v-col cols="2" style="text-align:center;">
              <p
                style="color:#000000; font-size:16px;"
              >{{ editLocation.radius + " " + $t("meter") }}</p>
            </v-col>
          </v-row>
          <p class="subheaders">{{ $t("status") }}</p>
          <v-btn-toggle v-model="editLocation.status">
            <v-btn
              class="text-capitalize"
              :class="editLocation.status == 0 ? 'clicked' : 'unclicked'"
              width="87px"
              style="height:33px"
            >
              <span :style="editLocation.status == 0 ? 'color:#ffffff' : 'color:#7D7A7A'">
                {{ $t("Active")
                }}
              </span>
            </v-btn>
            <v-btn
              class="text-capitalize"
              :class="editLocation.status == 1 ? 'clicked' : 'unclicked'"
              width="87px"
              style="height:33px"
            >
              <span :style="editLocation.status == 1 ? 'color:#ffffff' : 'color:#7D7A7A'">
                {{
                $t("Inactive") }}
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
        <v-card-actions class="pb-5 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            style="color:#838395"
            outlined
            width="135"
            max-height="40"
            @click="cancelEditLocation()"
            text
          >
            {{
            $t("cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-2 white--text text-capitalize"
            width="135"
            max-height="40"
            @click="editWorkinLocation()"
          >
            {{ $t("save")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>
<script>
import ThaiFontBoldBase64 from "@/function/global.js";
import ThaiFontBase64 from "@/function/global.js";
import LoadingDialog from "@/components/Loading.vue";
import { utils } from "@/function/AuthenticatedRedirect.js";
import store from "@/store"
import axios from "axios"
import enurl from "@/api/environment"
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
export default {
  components:{
    LoadingDialog,
  },
  mixins: [ThaiFontBoldBase64, ThaiFontBase64, utils, CheckViewAddEditDelete],
  data: () => ({
    blah: false,
    addDialog:false,
    search: "",
    //distance: null,
    deleteDialog: false,
    deleteID:null,
    deletedUser:null,
    editDialog: false,
    infoimg: require("@/assets/images/Vector.png"),
    outlineinfoimg: require("@/assets/images/information-variant-circle 1.png"),
    isHovered: false,
    minRange: 0,
    maxRange: 1000,
    toggleValue: 0,
    url: enurl.apiUrl,
    LoadingDialog : false,
    settingLocation: {
      checkOut: "",
      checkIn: "",
      checkInToggle: { Type: Boolean },
      checkOutToggle: { Type: Boolean },
      checkInPic: "",
      checkOutPic: "",
      default: true,
      distance:0
    },
    requestLocation: {
      locationName: null,
      description: null,
      coordinates: null,
      radius: 100,
      status: 0
    },
    editLocation:{
      locationID:null,
      locationName:null,
      description:null,
      coordinates:null,
      radius:100,
      status:0
    },
    showLocation:[],
    error:{
      locationName:"",
      coordinates:""
    },
    headers: [
      { text: "Working Location", value: "workingLocation" },
      { text: "Description", value: "description" },
      { text: "Radius", value: "radius" },
      { text: "Status", value: "status" },
      { text: "", value: "actions", sortable: false }
    ],
    locationNamesent:[],
    coordinatesSent:[],
    permission: {
        add: true,
        edit: true,
        delete: true
      },
  }),
  methods: {
    reset(){
    this.getdefaultsetting();
  },
  async getdefaultsetting()
  {
    let self=this;
      self.LoadingDialog=true;
      let temp={
        companyID:store.state.companyID
      }
      //console.log(temp);
      await axios
        .post (`${self.url}CheckIn_Out_Master/GetDefaultSetting`, temp)
        .then(async function (response)
        {
         
        let showdata=response.data.data;
         //if there is default
         if(showdata){
        self.settingLocation.checkOut =showdata.checkOutLocation==1? "true":"false";
        self.settingLocation.checkIn =showdata.checkInOffSite==1? "true":"false";
        self.settingLocation.checkInToggle =showdata.isRequireCheckIn==1? true:false;
        self.settingLocation.checkOutToggle =showdata.isRequireCheckOut==1? true:false;
        self.settingLocation.checkInPic =showdata.isCheckInPicture==true?"true":"false";
        self.settingLocation.checkOutPic =showdata.isCheckOutPicture==true? "true":"false";
        self.settingLocation.distance = showdata.distanceCheckInLocation;
        self.LoadingDialog=false;
         }
         else{
          //if no default, this is default
          self.settingLocation.checkOut="true";
          self.settingLocation.checkIn="true";
          self.settingLocation.checkInToggle=true;
          self.settingLocation.checkOutToggle=true;
          self.settingLocation.checkInPic="false";
          self.settingLocation.checkOutPic="true";
          self.LoadingDialog=false;
         }
        
        });
  },  
async addsetting(){
    let self=this;
    let temp={
      companyID:store.state.companyID,
      emp_ID:store.state.employeeID,
      checkOutLocation:self.settingLocation.checkOut=="true"?1:0,
      distanceCheckInLocation:self.settingLocation.checkOut=="false"?parseInt(self.settingLocation.distance):0,
      checkInOffSite:self.settingLocation.checkIn=="true"?1:0,
      isCheckInPicture:self.settingLocation.checkInPic=="true"?true:false,
      isCheckOutPicture:self.settingLocation.checkOutPic=="true"?true:false,
      isRequireCheckIn:self.settingLocation.checkInToggle==true?1:0,
      isRequireCheckOut:self.settingLocation.checkOutToggle==true?1:0
    };
   
    if((temp.checkOutLocation==true&&temp.distanceCheckInLocation==0)||(temp.checkOutLocation==false&&temp.distanceCheckInLocation>49))
    {
      
      self.LoadingDialog=true;
        await axios
        .post(`${self.url}CheckIn_Out_Master/AddCheck_InOut_Setting`, temp)
        .then(async function (response)
        {
          if (response.data.status==0)
          {
            if(response.data.message=="Add Successfully")
            {
              alert(response.data.message);
              self.getdefaultsetting();
              self.LoadingDialog=false;
            }
          }
          self.LoadingDialog=false;
        })
        .catch(async function(error){
          alert(error);
          self.getdefaultsetting();
          self.LoadingDialog=false;
          //self.LoadingDialog=false;
        });
    }
    //console.log(temp);
   // console.log(this.settingLocation);
  },
    handleMouseOver() {
      this.isHovered = true;
    },
    handleMouseLeave() {
      this.isHovered = false;
    },
    addLocation() {
      this.addDialog = true;
    },
    cancelAddLocation()
    {
      this.addDialog=false;
      this.requestLocation.locationName=null;
      this.requestLocation.description=null;
      this.requestLocation.coordinates=null;
      this.requestLocation.radius=100;
      this.requestLocation.status=0
      this.error.coordinates="";
      this.error.locationName="";
    },
    openEditlocation(item) {
      this.editDialog = true;
      this.editLocation.locationID=item.workinLocationID;
      this.editLocation.locationName=item.workinLocationName;
      this.editLocation.description=item.description;
      this.editLocation.coordinates=item.coordinates;
      this.editLocation.radius=parseInt(item.radius);
      this.editLocation.status=item.activeStatus==true?0:1;
    },
    cancelEditLocation(){
      this.editLocation.locationID=null;
      this.editLocation.locationName=null;
      this.editLocation.description=null;
      this.editLocation.coordinates=null;
      this.editLocation.radius=100;
      this.editLocation.status=0;
      this.editDialog=false;
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.deleteID=item;
    },
    async deleteLocation(){
      let self=this;
      self.LoadingDialog=true;
      let temp={
        userID:store.state.userid.toString(),
        workinLocationID:self.deleteID
      };
      await axios
      .post(`${self.url}WorkinLocation/DeleteWorkinLocation`, temp)
        .then(async function (response) {
          if(response.data.status==0)
          {
          alert(response.data.message);
          self.getLocations();
          self.LoadingDialog=false;
          self.deleteDialog=false;

          }
        })
        .catch(async function(error){
          alert(error);
          self.deleteDialog=false;
          //self.LoadingDialog=false;
        });
    },
    async saveWorkinLocation() {
      let self=this;
      let temp = {
        workinLocationName: self.requestLocation.locationName,
        description: self.requestLocation.description,
        coordinates: self.requestLocation.coordinates,
        radius:self.requestLocation.radius.toString(),
        activeStatus: self.requestLocation.status == 0 ? true : false,
        userID:store.state.userid,
        companyID:store.state.companyID
      };
      //("company",typeof(temp.companyID));
      //console.log("status",typeof(temp.activeStatus));
      self.error.locationName = !self.requestLocation.locationName
        ? " "+self.$t("PleaseSpecify")
        : "";
      self.error.coordinates = !self.requestLocation.coordinates
        ? " "+self.$t("PleaseSpecify")
        : "";
      if(self.error.locationName==""&&self.error.coordinates==""&&self.requestLocation.locationName!=null&&self.requestLocation.coordinates!=null&&self.requestLocation.radius>=100)
      {
        self.LoadingDialog=true;
        await axios
        .post(`${self.url}WorkinLocation/AddWorkinLocation`, temp)
        .then(async function (response)
        {
          if (response.data.status==0)
          {
            if(response.data.message=="Add Successfully")
            {
              alert(response.data.message);
              {
                self.getLocations();
                self.addDialog=false;
                self.LoadingDialog=false;  
                self.requestLocation.locationName=null;
                self.requestLocation.description=null;
                self.requestLocation.coordinates=null;
                self.requestLocation.radius=100;
                self.requestLocation.status=0;
              }
            }
          }
        })
        .catch(async function (error) {
          alert(error);
          //console.log(error);
          self.LoadingDialog = false;
          self.addDialog=false;
        });
      }
    },
    async editWorkinLocation(){
      let self=this;
      let temp = {
        workinLocationID:self.editLocation.locationID,
        workinLocationName: self.editLocation.locationName,
        description: self.editLocation.description,
        coordinates: self.editLocation.coordinates,
        radius:self.editLocation.radius.toString(),
        activeStatus: self.editLocation.status == 0 ? true : false,
        userID:store.state.userid,
        companyID:store.state.companyID
      };
      self.error.locationName = !self.editLocation.locationName
        ? " "+self.$t("PleaseSpecify")
        : "";
      self.error.coordinates = !self.editLocation.coordinates
        ? " "+self.$t("PleaseSpecify")
        : "";
      if(self.error.locationName==""&&self.error.coordinates==""&&self.editLocation.locationName!=null&&self.editLocation.coordinates!=null&&self.editLocation.radius>=100)
      {
        self.LoadingDialog=true;
        await axios
          .post(`${self.url}WorkinLocation/UpdateWorkinLocation`, temp)
          .then(async function (response)
          {
            //console.log(response.data.status);
            if (response.data.status==0)
            {
              if(response.data.message=="Upload Successfully")
              {
                alert("Update Successfully");
                {
                  self.getLocations();
                  self.editDialog=false;
                  self.LoadingDialog=false;  
                  self.editLocation.locationName=null;
                  self.editLocation.description=null;
                  self.editLocation.coordinates=null;
                  self.editLocation.radius=100;
                  self.editLocation.status=0;
                }
              }
            }
          })
          .catch(async function (error) {
            alert(error);
            //console.log(error);
            self.LoadingDialog = false;
            self.editDialog=false;
          });
      }
      //console.log(temp);
      self.addDialog = false;

    },
    async getLocations(){
      let self=this;
      self.LoadingDialog=true;
      let temp={
        companyID:store.state.companyID
      }
      await axios
      .post (`${self.url}WorkinLocation/GetAllLocationsByCompanyID`, temp)
    .then(async function (response)
    {
      let temp=[];
      temp=response.data.data;
      self.showLocation=temp.map((x)=>({
        ... x,
        activeString:"",
        hover:false,
        hover2:false
      }));
      for(var i=0;  i<self.showLocation.length; i++)
      {
        self.showLocation[i].activeString=self.showLocation[i].activeStatus==true?"Active":"Inactive";
      }
      //console.log(self.showLocation);
      self.LoadingDialog=false;
    });
  },
    isAllowLocation() {
      if (this.settingLocation.checkOut == "true") {
        this.settingLocation.distance = parseInt(0);
      }
    }
  },
  computed: {
    filteredItems() {
      let searchLowerCase = this.search.toLowerCase();
      return this.items.filter(
        item =>
          item.workinLocationName.toLowerCase().includes(searchLowerCase) ||
          item.description.toLowerCase().includes(searchLowerCase) ||
          item.radius.toString().includes(searchLowerCase) 
          //item.activeStatus.toLowerCase().includes(searchLowerCase)
      );
    },
    items() {
      return this.showLocation;
    },
    searchholder() {
      return this.$t("workinlocation");
    },
    locationholder() {
      return this.$t("Workin Location Name");
    },
    descriptionholder() {
      return this.$t("Description");
    },
    coordinatesholder() {
      return this.$t("latitude,longitude");
    },
    images() {
      return this.$store.state.images;
    }
  },
  mounted() {
    this.getLocations();
    this.getdefaultsetting();
    this.permission = this.CheckViewAddEditDelete(233, 234, 235, 236);
  },
  watch:{
    "requestLocation.locationName"(v)
    {
      if(v)this.error.locationName="";
      this.locationNamesent=[];
    },
    "requestLocation.coordinates"(v)
    {
      if(v)this.error.coordinates="";
      this.coordinatesSent=[];
    },
    "editLocation.locationName"(v)
    {
      if(v)this.error.locationName="";
      this.locationNamesent=[];
    },
    "editLocation.coordinates"(v)
    {
      if(v)this.error.coordinates="";
      this.coordinatesSent=[];
    }
  }
};
</script>

<style scoped>
::v-deep .Rheader {
    font-weight: normal;
    background-color: #EEEEEE;
    color: #707070;
    border-radius: 10px 10px 0px 0px;
}

::v-deep .Rheader>th {
    font-size: 16px !important;
}

.horizontal-content {
  display: flex;
  align-items: center; /* Center the items vertically */
}

.horizontal-content img {
  margin-right: 8px; /* Adjust the space between the image and text as needed */
}

.headers {
  color: #4d4f5c;
  font-weight: 400;
  font-size: 18px;
}

.subheaders {
  color: #7d7a7a;
  font-weight: 300;
  font-size: 16px;
}

* >>> .dialog {
  min-height: 235px !important;
  border-radius: 9px;
}

* >>> .adddialog {
  min-height: 501px !important;
  border-radius: 9px;
}

.location {
  height: 48px !important;
}

@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 53px;
    padding-right: 7px;
    margin: 0;
    border-radius: 15px;
    background: radial-gradient(
        circle 10px,
        white 100%,
        transparent calc(100% + 1px)
      )
      #ccc 15px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: transparent;
    /* Hide the text by making it transparent */
  }

  input::before {
    content: "ON";
    font: 8px/20px Kanit;
    /* color: white; */
  }

  input:checked {
    padding-left: 24px;
    padding-right: -13px;
    background-color: #f99d20;
    background-position: -13px;
    cursor: pointer;
  }

  input:checked::before {
    content: "OFF";
  }
}

.infoimg {
  display: inline-block;
  width: 15px !important;
  position: absolute;
  top: 3px;
  left: 20;
  transition: transform 0.6s ease-in-out;
  margin-bottom: -4px !important;
  margin-left: 3px;
}

* >>> .v-slider__thumb {
  height: 20px;
  width: 20px;
}

* >>> .v-slider--horizontal .v-slider__track-container {
  height: 5px;
}

* >>> .theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
  opacity: 0 !important;
}

.clicked {
  background-color: #f99d20 !important;
  /* color:#ffffff; */
}

.unclicked {
  background-color: #EEEEEE !important;
  /* color:#7D7A7A; */
}
.gps{
  color:transparent;
  background-color:transparent;
}
.gps:hover{
  color:transparent;
  background-color:transparent;
}
.gps:focus{
  color:transparent;
  background-color:transparent;
}
.gps:active{
  color:transparent;
  background-color:transparent;
}

::v-deep .v-dialog {
  border-radius: 15px !important;
}
*>>> .mdi-chevron-down {
    content: "\F0140";
    font-size: 27px;
}

*>>>.custom-divider {
  background-color: rgba(53, 51, 51, 0.137); /* Change the color and transparency here */
}
</style>