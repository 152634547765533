<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("commonmaster") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("IncomeTax") }}
        </p>
    </div>

    <v-card
      class="pt-5 pl-10"
      style="margin-bottom: 2.5rem; margin-left: 1.5rem; margin-right: 1.5rem"
    >
      <v-tabs show-arrows grey--text class="mt-5" slider-color="#F99D20">
        <v-tab @click="originalOne" class="text-capitalize">
          {{ $t("PersonalAllowance") }}</v-tab
        >
        <!-- <v-tab @click="originalOne" class="text-capitalize">{{
          $t("TaxRateDetail")
        }}</v-tab> -->

        <!-- Personal allowance Tab -->
        <v-tab-item>
          <v-row
            class="pl-md-0 mt-5"
            style="margin-left: 15px; margin-right: 10px"
          >
            <v-col cols="12" xs="12" sm="12" md="2" lg="4"></v-col>
            <v-spacer></v-spacer>
            <!-- <v-btn
              color="#F99D20"
              class="orange--text text-capitalize mr-5"
              width="150"
              max-height="35"
              :disabled="permission.upload"
              outlined
              @click="Uploaddialog = true"
            >
              {{ $t("Upload") }}</v-btn
            > -->
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize mr-5 btnres"
              width="150"
              max-height="35"
              @click="adddialog = true"
              :disabled="permission.add"
            >
              {{ $t("AddNewItem") }}</v-btn
            >
          </v-row>
          <v-data-table
            class="ml-4 mr-8 mt-4 tbheader"
            :headers="headers"
            :items="PersonalAllowanceList"
            hide-default-footer
            @page-count="pageCount = $event"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-header
            :mobile-breakpoint="0"
          >
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th
                    style="cursor: pointer; min-width: 180px !important"
                    class="b-text"
                    @click="sortFunPersonal('pA_Name')"
                  >
                    {{ $t("PersonalAllowance") }}
                    <v-icon small v-if="!pA_Name">mdi-arrow-down</v-icon>
                    <v-icon small v-if="pA_Name">mdi-arrow-up</v-icon>
                  </th>
                  <th
                    style="cursor: pointer; min-width: 180px !important"
                    class="b-text"
                    @click="sortFunPersonal('amount')"
                  >
                    {{ $t("AmountOfMoney") }}
                    <v-icon small v-if="!amount">mdi-arrow-down</v-icon>
                    <v-icon small v-if="amount">mdi-arrow-up</v-icon>
                  </th>
                  <th
                    style="cursor: pointer; min-width: 180px !important"
                    class="b-text"
                    @click="sortFunPersonal('number_of_People')"
                  >
                    {{ $t("Limit") }}
                    <v-icon small v-if="!number_of_People"
                      >mdi-arrow-down</v-icon
                    >
                    <v-icon small v-if="number_of_People">mdi-arrow-up</v-icon>
                  </th>
                  <th>{{ $t("Actions") }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.pA_Name }}</td>
                <td>
                  {{
                    item.amount == "-"
                      ? item.pA_Type == 2
                        ? "(Manual) "
                        : item.number_of_Percent +
                          "%" +
                          ", Not over than " +
                          numberWithCommas(item.notoverthan)
                      : "(Fixed) " + numberWithCommas(item.amount)
                  }}
                </td>
                <td>
                  {{
                    item.number_of_People == 0
                      ? "-"
                      : item.number_of_People > 1
                      ? item.number_of_People + " Persons"
                      : item.number_of_People + " Person"
                  }}
                </td>
                <td>
                  <v-btn icon @click="Editpersonal(item)" :disabled="permission.edit">
                    <img class="editIcon" :src="images.edit" />
                  </v-btn>

                  <v-btn :disabled="permission.delete" @click="Deletepersonal(item.pA_ID)" icon>
                    <img class="deleteIcon" :src="images.delete" />
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-right">
            <PaginationComponent
              :page.sync="page"
              :pageCount="pageCount"
              :itemsPerPage.sync="itemsPerPage"
            />
          </div>
        </v-tab-item>

        <!-- tax rate tab -->
        <v-tab-item>
          <v-row
            class="pl-md-0 mt-5"
            style="margin-left: 15px; margin-right: 10px"
          >
            <v-col cols="12" xs="12" sm="12" md="2" lg="4"></v-col>
            <v-spacer></v-spacer>
            <!-- <v-btn
              color="#F99D20"
              class="orange--text text-capitalize mr-5"
              width="150"
              max-height="35"
              outlined
              @click="Uploaddialog1 = true"
              :disabled="permission.upload"
            >
              {{ $t("Upload") }}</v-btn
            > -->
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize mr-5 btnres"
              width="150"
              max-height="35"
              :disabled="permission.add"
              @click="Adddialog1 = true"
              >{{ $t("AddNewItem") }}</v-btn
            >
          </v-row>
          <v-data-table
            class="ml-4 mr-8 taxrate tbheader mt-4"
            :headers="header1"
            :items="TaxList"
            hide-default-footer
            :page.sync="page1"
            :items-per-page="itemsPerPage1"
            @page-count="pageCount1 = $event"
            hide-default-header
            :mobile-breakpoint="0"
          >
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th
                    style="cursor: pointer; min-width: 180px !important"
                    class="b-text"
                    @click="sortFunTax('net_come')"
                  >
                    {{ $t("NetIncomePerYear(THB)") }}
                    <v-icon small v-if="!net_come">mdi-arrow-down</v-icon>
                    <v-icon small v-if="net_come">mdi-arrow-up</v-icon>
                  </th>
                  <th
                    style="cursor: pointer; min-width: 180px !important"
                    class="b-text"
                    @click="sortFunTax('income_tax_rate')"
                  >
                    {{ $t("IncomeTaxRate(%)") }}
                    <v-icon small v-if="!income_tax_rate"
                      >mdi-arrow-down</v-icon
                    >
                    <v-icon small v-if="income_tax_rate">mdi-arrow-up</v-icon>
                  </th>
                  <th>{{ $t("Actions") }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ addcomma(item.net_come) }}&nbsp;THB</td>
                <td>
                  {{
                    item.income_tax_rate == 0
                      ? "Tax Exempt"
                      : item.income_tax_rate + " %"
                  }}
                </td>

                <td>
                  <v-btn icon @click="Edittax(item)" :disabled="permission.edit">
                    <img class="editIcon" :src="images.edit" />
                  </v-btn>
                  <v-btn :disabled="permission.delete" @click="Deletetax(item.tax_Rate_ID)" icon>
                    <img class="deleteIcon" :src="images.delete" />
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-right" v-if="TaxList.length">
            <PaginationComponent
              :page.sync="page1"
              :pageCount="pageCount1"
              :itemsPerPage.sync="itemsPerPage1"
            />
          </div>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="adddialog" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <p class="pt-5 ml-8 mr-2" style="font-size: 22px">
          {{ $t("NewDeductions/Allowances") }}
        </p>
        <v-card-text>
          <v-container>
            <v-form ref="formAdd" lazy-validation>
              <v-col cols="12" style="margin-left: -1rem">
                {{ $t("Deductions/Allowances") }}
                <span class="red--text"> *</span>
                <v-text-field
                  autocomplete="off"
                  v-model="addpersonal.personalallow"
                  color="#F89D1F"
                  :rules="personalallowAdd"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n8 py-0 alignt-center">
                <v-checkbox class="custom-checkbox" color="#F89D1F" style="margin-left: -1rem" hide-details v-model="addpersonal.checkalldefault">
                  <template v-slot:label>
                    {{ $t("SetDefaulttoAllemp") }}
                  </template>
                </v-checkbox>
              </v-col>
              <v-col>
                <div style="margin-left: -1rem">
                  {{ $t("DeductionType") }}
                  <span class="red--text"> *</span>
                </div>
                <v-radio-group
                  v-model="addpersonal.ActiveRadio"
                  @change="ChangeRadio(addpersonal.ActiveRadio)"
                >
                  <v-row cols="12">
                    <v-col cols="6" style="margin-left: -1rem">
                      <v-radio :value="1" color="#F99D20">
                        <template v-slot:label>
                          <p class="mt-4">
                            {{ $t("Deductequallyforeveryone") }}
                          </p>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :disabled="addpersonal.ActiveRadio == 1 ? false : true"
                        autocomplete="off"
                        v-model="addpersonal.deductequally"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        color="#F99D20"
                        :rules="deductequallyAdd"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-radio
                    :value="2"
                    color="#F99D20"
                    style="margin-left: -1rem"
                  >
                    <template v-slot:label>
                      <p class="mt-4">
                        {{ $t("Deductbyindividualperson") }}
                      </p>
                    </template></v-radio
                  >
                  <v-col style="margin-left: -1rem">
                    <v-radio
                      :value="3"
                      color="#F99D20"
                      style="margin-left: -0.6rem"
                    >
                      <template v-slot:label>
                        <p class="mt-4">
                          {{
                            $t(
                              "Deducted as a percentage of total Incomes (for calculate tax)"
                            )
                          }}
                        </p>
                      </template></v-radio
                    >
                    <v-row style="margin-left: 1.5rem">
                      <span
                        style="
                          color: #545454;
                          font-size: 14px;
                          margin-right: 2.1rem;
                        "
                        >{{ $t("Percent") }}</span
                      >
                      <v-col cols="4" style="margin-top: -1.7rem">
                        <v-text-field
                          :disabled="
                            addpersonal.ActiveRadio == 3 ? false : true
                          "
                          autocomplete="off"
                          v-model="addpersonal.percent"
                          onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                          color="#F99D20"
                          :rules="percentAdd"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row style="margin-left: 1.5rem">
                      <span style="color: #545454; font-size: 14px">{{
                        $t("Not over than")
                      }}</span>
                      <v-col cols="4" style="margin-top: -1.7rem">
                        <v-text-field
                          :disabled="
                            addpersonal.ActiveRadio == 3 ? false : true
                          "
                          autocomplete="off"
                          v-model="addpersonal.notoverthan"
                          onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                          color="#F99D20"
                          :rules="notoverthanAdd"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-radio-group>
              </v-col>
              <v-col cols="12" style="margin-left: -0.9rem; margin-top: -3rem">
                {{ $t("Limit (Person)") }}
                <v-row style="margin-left: 0.2rem">
                  <v-checkbox style="padding-top: 0.5rem;" class="custom-checkbox" color="#F99D20" v-model="addpersonal.checkbox" :disabled="addpersonal.ActiveRadio == 1 ? false : true">            
                  </v-checkbox>
                  <span style="color: #545454; font-size: 14px; margin-top: 1.4rem">
                    {{ $t("Number of People") }}
                  </span>
                  <v-col cols="4" style="margin-top: -0.3rem">
                    <v-text-field
                      autocomplete="off"
                      v-model="addpersonal.number_of_People"
                      color="#F99D20"
                      :rules="number_of_PeopleAdd"
                      onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                      :disabled="addpersonal.ActiveRadio == 1 ? false : true"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="closeadddialog()"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            @click="AddPersonal()"
            class="mr-4 text-capitalize commonsavebtn"
          >
            {{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog1 -->
    <v-dialog v-model="editdialog" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <p
          class="pt-5"
          style="font-size: 22px; margin-left: 2rem; margin-right: 2rem"
        >
          {{ $t("EditPersonalAllowance") }}
        </p>
        <v-card-text>
          <v-container>
            <v-form ref="formEdit" lazy-validation>
              <v-col cols="12" style="margin-left: -1rem">
                {{ $t("Deductions/Allowances") }}
                <span class="red--text"> *</span>
                <v-text-field
                  autocomplete="off"
                  v-model="editpersonal.personalallow"
                  color="#F99D20"
                  :rules="personalallowEdit"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n8 py-0 alignt-center">
                <v-checkbox color="#F99D20" class="custom-checkbox" style="margin-left: -1rem" hide-details v-model="editpersonal.checkalldefault">
                  <template v-slot:label>
                    {{ $t("SetDefaulttoAllemp") }}
                  </template>
                </v-checkbox>
              </v-col>
              <v-col>
                <div style="margin-left: -1rem">
                  {{ $t("DeductionType") }}
                  <span class="red--text"> *</span>
                </div>
                <v-radio-group
                  v-model="editpersonal.ActiveRadio"
                  @change="ChangeRadioEdit(editpersonal.ActiveRadio)"
                >
                  <v-row cols="12">
                    <v-col cols="6" style="margin-left: -1rem">
                      <v-radio :value="1" color="#F99D20">
                        <template v-slot:label>
                          <p class="mt-4">
                            {{ $t("Deductequallyforeveryone") }}
                          </p>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :disabled="editpersonal.ActiveRadio == 1 ? false : true"
                        autocomplete="off"
                        v-model="editpersonal.deductequally"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        color="#F99D20"
                        :rules="deductequallyEdit"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-radio
                    :value="2"
                    color="#F99D20"
                    style="margin-left: -1rem"
                  >
                    <template v-slot:label>
                      <p class="mt-4">
                        {{ $t("Deductbyindividualperson") }}
                      </p>
                    </template></v-radio
                  >
                  <v-col style="margin-left: -1rem">
                    <v-radio
                      :value="3"
                      color="#F99D20"
                      style="margin-left: -0.6rem"
                    >
                      <template v-slot:label>
                        <p class="mt-4">
                          {{
                            $t(
                              "Deducted as a percentage of total Incomes (for calculate tax)"
                            )
                          }}
                        </p>
                      </template></v-radio
                    >
                    <v-row style="margin-left: 1.5rem">
                      <span
                        style="
                          color: #545454;
                          font-size: 14px;
                          margin-right: 2.1rem;
                        "
                        >{{ $t("Percent") }}</span
                      >
                      <v-col cols="4" style="margin-top: -1.7rem">
                        <v-text-field
                          :disabled="
                            editpersonal.ActiveRadio == 3 ? false : true
                          "
                          autocomplete="off"
                          v-model="editpersonal.percent"
                          onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                          color="#F99D20"
                          :rules="percentEdit"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row style="margin-left: 1.5rem">
                      <span style="color: #545454; font-size: 14px">{{
                        $t("Not over than")
                      }}</span>
                      <v-col cols="4" style="margin-top: -1.7rem">
                        <v-text-field
                          :disabled="
                            editpersonal.ActiveRadio == 3 ? false : true
                          "
                          autocomplete="off"
                          onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                          v-model="editpersonal.notoverthan"
                          color="#F99D20"
                          :rules="notoverthanEdit"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-radio-group>
              </v-col>
              <v-col cols="12" style="margin-left: -0.9rem; margin-top: -3rem">
                {{ $t("Limit (Person)") }}
                <v-row style="margin-left: 0.2rem;">
                  <v-checkbox style="padding-top: 0.5rem;" class="custom-checkbox" color="#F99D20" v-model="editpersonal.checkbox" :disabled="editpersonal.ActiveRadio == 1 ? false : true">
                  </v-checkbox>
                  <span style="color: #545454; font-size: 14px; margin-top: 1.4rem">
                    {{ $t("Number of People") }}
                  </span>
                  <v-col cols="4" style="margin-top: -0.3rem">
                    <v-text-field
                      autocomplete="off"
                      v-model="editpersonal.number_of_People"
                      color="#F99D20"
                      :rules="number_of_PeopleEdit"
                      onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                      :disabled="editpersonal.ActiveRadio == 1 ? false : true"
                      dense>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="editdialog = false"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            @click="UpdatePersonal()"
            class="mr-4 text-capitalize commonsavebtn"
          >
            {{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- uploaddialog1 -->
    <v-dialog v-model="Uploaddialog" persistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInput"
                  class="inputbot"
                  v-model="chosenfile"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                >
                  {{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  ** {{ $t("filetype") }} **
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>Taxes Template.xlsx</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="whatthePAlang()">
                  {{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="closeUpload"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="commonsavebtn mr-4 text-capitalize"
            @click="previewFiles"
          >
            {{ $t("Upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- deletedialog1 -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("DeletePersonalAllowance?") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="Deletedialog = false"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 commondeletebtn text-capitalize"
            @click="Deletepersonalrow"
          >
            {{ $t("Delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="Cantdeletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("Can'tDeleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="left" cols="12" class="pl-0">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("inuse") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="Cantdeletedialog = false"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog for tax rate detail -->
    <v-dialog v-model="editdialog1" persistent max-width="800px">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 15px">
            {{ $t("EditTaxRateDetails") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formEdit1" lazy-validation>
              <v-row class="mt-4">
                <v-col cols="12" md="6">
                  {{ $t("StartAmount") }}
                  <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="numberanddash"
                    v-model="edittaxrate.start"
                    color="#F99D20"
                    :rules="StartEdit"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("EndAmount") }}
                  <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="numberanddash"
                    v-model="edittaxrate.end"
                    color="#F99D20"
                    :rules="EndEdit"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12" md="6" style="margin-left: -0.7rem">
                {{ $t("IncomeTaxRate(%)") }} <span class="red--text"> *</span>
                <v-text-field
                  autocomplete="off"
                  @keypress="onlyNumber"
                  v-model="edittaxrate.incometaxrate"
                  color="#F99D20"
                  :rules="incometaxrateEdit"
                  dense
                ></v-text-field>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="editdialog1 = false"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            @click="UpdateTax()"
            class="mr-4 text-capitalize commonsavebtn"
          >
            {{ $t("Update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- upload dialog for tax rate detail -->
    <v-dialog v-model="Uploaddialog1" persistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("UploadFile") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup1"
                  id="fileInputButton1"
                  class="inputbot"
                  v-model="chosenfile1"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput1"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload1"
                >
                  {{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p class="mt-2" style="color: #f74747">
                  **{{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("DocumentTemplate") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="whattheTRlang()">
                  {{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="closeUpload1"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="commonsavebtn mr-4 text-capitalize"
            @click="previewFiles1"
          >
            {{ $t("Upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete dialog for tax rate detail -->
    <v-dialog v-model="Deletedialog1" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("DeleteTax?") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="Deletedialog1 = false"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 white--text text-capitalize commondeletebtn"
            @click="Deletetaxrow()"
          >
            {{ $t("Delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add dialog for tax rate detail -->
    <v-dialog v-model="Adddialog1" persistent width="800">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 15px">
            {{ $t("NewTaxRate") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formAdd1" lazy-validation>
              <v-row class="mt-4">
                <v-col cols="12" md="6">
                  {{ $t("StartAmount") }}
                  <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="numberanddash"
                    v-model="addtaxrate.start"
                    color="#F99D20"
                    :rules="StartAdd"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("EndAmount") }}
                  <span class="red--text"> *</span>
                  <v-text-field
                    autocomplete="off"
                    @keypress="numberanddash"
                    v-model="addtaxrate.end"
                    color="#F99D20"
                    :rules="EndAdd"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12" md="6" style="margin-left: -0.7rem">
                {{ $t("IncomeTaxRate(%)") }} <span class="red--text"> *</span>
                <v-text-field
                  autocomplete="off"
                  @keypress="onlyNumber"
                  v-model="addtaxrate.incomerate"
                  color="#F99D20"
                  :rules="incomerateAdd"
                  dense
                ></v-text-field>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-3">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            style="margin-left: -15px"
            @click="closeAdddialog1"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            @click="Addtaxrate"
            class="text-capitalize delete_btn commonsavebtn"
          >
            {{ $t("Add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  watch: {
    "addpersonal.personalallow"() {
      this.personalallowAdd = [];
    },
    "addpersonal.deductequally"(v) {
      this.deductequallyAdd = [];
      if (v) this.addpersonal.deductequally = this.removecomma(v);
      this.addpersonal.deductequally = this.numberWithCommas(
        this.addpersonal.deductequally
      );
    },
    "addpersonal.notoverthan"(v) {
      this.notoverthanAdd = [];
      if (v) this.addpersonal.notoverthan = this.removecomma(v);
      this.addpersonal.notoverthan = this.numberWithCommas(
        this.addpersonal.notoverthan
      );
    },
    "addpersonal.number_of_People"() {
      this.number_of_PeopleAdd = [];
    },
    "addpersonal.percent"() {
      this.percentAdd = [];
    },

    "editpersonal.personalallow"() {
      this.personalallowEdit = [];
    },
    "editpersonal.number_of_People"() {
      this.number_of_PeopleEdit = [];
    },
    "editpersonal.deductequally"(v) {
      this.deductequallyEdit = [];
      if (v) this.editpersonal.deductequally = this.removecomma(v);
      this.editpersonal.deductequally = this.numberWithCommas(
        this.editpersonal.deductequally
      );
    },
    "editpersonal.notoverthan"(v) {
      this.notoverthanEdit = [];
      if (v) this.editpersonal.notoverthan = this.removecomma(v);
      this.editpersonal.notoverthan = this.numberWithCommas(
        this.editpersonal.notoverthan
      );
    },
    "editpersonal.percent"() {
      this.percentEdit = [];
    },

    "addtaxrate.start"(v) {
      this.StartAdd = [];
      if (v) this.addtaxrate.start = this.removecomma(v);
      this.addtaxrate.start = this.numberWithCommas(this.addtaxrate.start);
    },
    "addtaxrate.end"(v) {
      this.EndAdd = [];
      if (v) this.addtaxrate.end = this.removecomma(v);
      this.addtaxrate.end = this.numberWithCommas(this.addtaxrate.end);
    },
    "addtaxrate.incomerate"() {
      this.incomerateAdd = [];
    },
    "edittaxrate.start"(v) {
      this.StartEdit = [];
      if (v) this.edittaxrate.start = this.removecomma(v);
      this.edittaxrate.start = this.numberWithCommas(this.edittaxrate.start);
    },
    "edittaxrate.end"(v) {
      this.EndEdit = [];
      if (v) this.edittaxrate.end = this.removecomma(v);
      this.edittaxrate.end = this.numberWithCommas(this.edittaxrate.end);
    },
    "edittaxrate.incometaxrate"() {
      this.incometaxrateEdit = [];
    },
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
    chosenfile1() {
      if (this.chosenfile1 != null) {
        this.showup1 = true;
      } else {
        this.showup1 = false;
      }
    },
  },
  data() {
    return {
      StartAdd: [],
      EndAdd: [],
      StartEdit: [],
      EndEdit: [],
      notoverthanAdd: [],
      notoverthanEdit: [],
      personalallowAdd: [],
      deductequallyAdd: [],
      amountAdd: [],
      percentAdd: [],
      percentEdit: [],
      number_of_PeopleAdd: [],
      personalallowEdit: [],
      number_of_PeopleEdit: [],
      deductequallyEdit: [],
      deductperentageEdit: [],
      incomeperyearAdd: [],
      incomerateAdd: [],
      incometaxrateEdit: [],
      errorMessage: [],
      errorMessage1: [],
      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      url: enurl.apiUrl,
      page: 1,
      pageCount: 10,
      itemsPerPage: 10,
      page1: 1,
      pageCount1: 10,
      itemsPerPage1: 10,
      // items1: [10, 20, 30],
      // items: [10, 20, 30],
      chosenfile: null,
      chosenfile1: null,
      showup1: false,
      showup: false,
      adddialog: false,
      Uploaddialog: false,
      editdialog: false,
      editdialog1: false,
      Uploaddialog1: false,
      Deletedialog1: false,
      Adddialog1: false,
      Deletedialog: false,
      LoadingDialog: false,
      Cantdeletedialog: false,
      TaxList: [],
      pA_Name: null,
      amount: null,
      number_of_People: null,
      income_tax_rate: null,
      net_come: null,
      duplicatepersonalallowancelist: [],
      duplicatetaxlist: [],
      PersonalAllowanceList: [],
      TaxID: 0,
      PersonalID: 0,
      headers: [
        {
          text: "Personal Allowance",
          value: "personalallow",
          sortable: false,
        },
        {
          text: "Amount Of Money(THB)",
          value: "amountof",
          align: "left",
        },

        {
          text: "number_of_People",
          value: "number_of_People",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
        },
      ],
      header1: [
        {
          text: "Net Income Per Year (THB)",
          value: "neticome",
          sortable: false,
        },
        {
          text: "Income Tax Rate(%)",
          value: "incometax",
          sortable: false,
        },
        {
          text: "Action",
          value: "action1",
          sortable: false,
        },
      ],
      edittaxrate: {
        taxrateID: null,
        start: null,
        end: null,
        incometaxrate: null,
      },
      addpersonal: {
        personalallow: null,
        ActiveRadio: 1,
        deductequally: null,
        checkalldefault: false,
        percent: null,
        notoverthan: null,
        checkbox: true,
        number_of_People: null,
      },
      editpersonal: {
        personalID: null,
        personalallow: null,
        ActiveRadio: 1,
        deductequally: null,
        checkalldefault: false,
        percent: null,
        notoverthan: null,
        checkbox: true,
        number_of_People: null,
      },
      addtaxrate: {
        incomeperyear: null,
        start: null,
        end: null,
        incomerate: null,
      },
    };
  },

  mounted() {
    let self = this;
    self.itemsPerPage;
    self.pageCount;
    self.addpersonal.ActiveRadio = 1;
    self.addpersonal.checkbox = true;
    self.GetIncomeTaxes();
    //self.GetIncomeTaxes();
    self.GetPersonalAllowance();
    self.permission = self.CheckViewAddEditDeleteUpload(94, 95, 96, 97, 98);
  },

  computed: {
    images() {
      return this.$store.state.images;
    }
  },

  methods: {
    BeforeEnd(e, s) {
      let self = this;
      if (s) {
        let start = self.removecomma(s);
        let end = self.removecomma(e);
        if (start > end) {
          return false;
        }
      }
      return true;
    },
    AfterStart(e, s) {
      let self = this;
      if (e) {
        let start = self.removecomma(s);
        let end = self.removecomma(e);
        if (end < start) {
          return false;
        }
        return true;
      }
      return true;
    },
    ChangeRadio(e) {
      let self = this;
      let input = e;
      self.percentAdd = [];
      self.notoverthanAdd = [];
      self.number_of_PeopleAdd = [];
      self.deductequallyAdd = [];
      self.addpersonal.deductequally = null;
      self.addpersonal.percent = null;
      self.addpersonal.notoverthan = null;
      self.addpersonal.number_of_People = null;
      if (input == 1) {
        self.addpersonal.checkbox = true;
      } else if (input == 2) {
        self.addpersonal.checkbox = false;
      } else {
        self.addpersonal.checkbox = false;
      }
    },

    originalOne() {
      this.GetIncomeTaxes();
      this.GetPersonalAllowance();
      this.defaultSort();
      this.page = 1;
      this.itemsPerPage = 10;
    },
    addcomma(x) {
      //let self=this;
      if (x.includes("-")) {
        let y = x.split("-")[0];
        let z = x.split("-")[1];

        y = y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        z = z.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        let finalresult = y + "-" + z;
        return finalresult;
      } else {
        let g = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return g;
      }
    },
    numberWithCommas(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    removecomma(x) {
      if (x != 0 && x != null) {
        return x.replace(/,/g, "");
      } else {
        return "0";
      }
    },
    sortFunTax(rowName) {
      let self = this;
      let keyName = ["net_come", "income_tax_rate"];
      if (rowName == "net_come") {
        self.TaxList = self.TaxList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.TaxList = self.TaxList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.net_come = null;
      this.income_tax_rate = null;
      this.pA_Name = null;
      this.amount = null;
      this.number_of_People = null;
    },
    whatthePAlang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Personal Allowance Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink = "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Personal Allowance Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Personal Allowance Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Personal Allowance Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Personal Allowance Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Personal Allowance Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink = "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Personal Allowance Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Personal Allowance Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Personal Allowance Master Data.xlsx";
        } else {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Personal Allowance Master Data.xlsx";
        }
      }
      return thelink;
    },
    whattheTRlang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Tax Rate Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink = "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Tax Rate Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Tax Rate Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Tax Rate Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Tax Rate Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Tax Rate Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink = "https://optimisticstg.blob.core.windows.net/uploadexcelthaifiles/Tax Rate Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Tax Rate Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Tax Rate Master Data.xlsx";
        } else {
          thelink = "https://optimisticstg.blob.core.windows.net/optimisticpreproductionfiles/Tax Rate Master Data.xlsx";
        }
      }
      return thelink;
    },
    sortFunPersonal(rowName) {
      let self = this;
      let keyName = ["pA_Name", "amount", "number_of_People"];
      if (rowName == "pA_Name" || rowName == "amount") {
        self.PersonalAllowanceList = self.PersonalAllowanceList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.PersonalAllowanceList = self.PersonalAllowanceList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    back() {
      this.$router.push({ name: "Masterdata" }).catch(()=>{});
    },
    getupload() {
      document.getElementById("fileInput").click();
      //this.showup = true;
    },
    closeUpload() {
      this.Uploaddialog = false;
      this.chosenfile = null;
      this.showup = false;
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    numberanddash($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keyCode < 48 || keyCode > 57) && keyCode != 45 && keyCode > 31) {
        $event.preventDefault();
      }
    },
    GetPersonalAllowance() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}PersonalAllowance/GetPersonalAllowance`, tempp)
        .then(function (response) {
          self.PersonalAllowanceList = response.data.data;
          self.LoadingDialog = false;
          self.defaultSort();
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Personal Allowance Fail", 11, "Critical");
          alert(error + "Correlation ID is 11");
          self.LoadingDialog = false;
        });
    },
    validatepersonalallowance(name) {
      let self = this;

      var i;
      for (i = 0; i < self.PersonalAllowanceList.length; i++) {
        if (
          name.toLowerCase() ==
          self.PersonalAllowanceList[i].pA_Name.toLowerCase()
        ) {
          self.duplicatepersonalallowancelist.push(name);
        }
      }
      if (self.duplicatepersonalallowancelist.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    validateeditpersonalallowance(id, name) {
      let self = this;

      var i;
      for (i = 0; i < self.PersonalAllowanceList.length; i++) {
        if (id != self.PersonalAllowanceList[i].pA_ID) {
          if (
            name.toLowerCase() ==
            self.PersonalAllowanceList[i].pA_Name.toLowerCase()
          ) {
            self.duplicatepersonalallowancelist.push(name);
          }
        }
      }
      if (self.duplicatepersonalallowancelist.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    //Check no of people is not null when uncheck checkbox
    CheckNotNullNoOfPeople() {
      let self = this;
      if (
        self.addpersonal.number_of_People != null &&
        self.addpersonal.number_of_People != "" &&
        self.addpersonal.number_of_People != undefined
      ) {
        if (self.addpersonal.checkbox == false) {
          alert("Check the checkbox if you want to add Number of People");
          return false;
        }
      }
      return true;
    },
    CheckNotNullNoOfPeopleEdit() {
      let self = this;
      if (
        self.editpersonal.number_of_People != null &&
        self.editpersonal.number_of_People != "" &&
        self.editpersonal.number_of_People != undefined
      ) {
        if (self.editpersonal.checkbox == false) {
          alert("Check the checkbox if you want to add Number of People");
          return false;
        }
      }
      return true;
    },
    async AddPersonal() {
      let self = this;
      self.personalallowAdd = [
        (v) => !!v || "Deductions/Allowances is required",
      ];
      if (self.addpersonal.ActiveRadio == 1) {
        self.deductequallyAdd = [(v) => !!v || "Amount is required"];
      }
      if (self.addpersonal.ActiveRadio == 3) {
        self.notoverthanAdd = [(v) => !!v || "Not over than is required"];
        self.percentAdd = [(v) => !!v || "Percent is required"];
      }

      if (self.addpersonal.checkbox == true) {
        self.number_of_PeopleAdd = [
          (v) => !!v || "Number of People is required",
        ];
      }

      if (
        self.addpersonal.number_of_People == null ||
        self.addpersonal.number_of_People == "" ||
        self.addpersonal.number_of_People == undefined
      ) {
        if (self.addpersonal.checkbox == false) {
          self.number_of_PeopleAdd = [];
        }
      }

      // let checkboxvalidate = self.CheckNotNullNoOfPeople();
      let tempvalidate = self.$refs.formAdd.validate();
      let checkboxvalidate = self.CheckNotNullNoOfPeople();
      let checkdeductequally = self.CheckdeductequallyAdd();
      let checkpercent = self.CheckpercentAdd();
      let checknotoverthan = self.ChecknotoverthanAdd();
      let checknoofpeople = self.Checknumber_of_PeopleAdd();
      let duplicatecheck = self.validatepersonalallowance(
        self.addpersonal.personalallow
      );

      if (duplicatecheck == true) {
        if (
          checkdeductequally == true &&
          checkpercent == true &&
          checknotoverthan == true &&
          checknoofpeople == true &&
          tempvalidate == true &&
          checkboxvalidate == true
        ) {
          let tempersonal = {
            pA_Name: self.addpersonal.personalallow,
            amount:
              self.addpersonal.deductequally == null
                ? "-"
                : self.addpersonal.deductequally,
            pA_Type: parseInt(self.addpersonal.ActiveRadio),
            number_of_People:
              self.addpersonal.number_of_People == null
                ? 0
                : self.addpersonal.number_of_People == ""
                ? 0
                : parseInt(self.addpersonal.number_of_People),
            limit:
              self.addpersonal.checkbox == null
                ? false
                : self.addpersonal.checkbox,
            number_of_Percent:
              self.addpersonal.percent == null
                ? 0
                : parseFloat(self.addpersonal.percent),
            notoverthan:
              self.addpersonal.notoverthan == null
                ? "-"
                : self.addpersonal.notoverthan,
            set_Default: self.addpersonal.checkalldefault,
            company_ID: store.state.companyID,
          };
          let tempcompany = {
            company_ID: store.state.companyID,
            user_ID: parseInt(store.state.userid),
          };
          self.LoadingDialog = true;
          // Checkbox Default
          if (self.addpersonal.checkalldefault == true) {
            await axios.post(
              `${self.url}PersonalAllowance/AddPAllowanceSetdefault`,
              tempersonal
            );
            await axios
              .post(
                `${self.url}Emp_PersonalAllowance/AddEmp_PersonalWithoutEID`,
                tempcompany
              )
              .then(function (response) {
                if (response.data.status == 0) {
                  self.adddialog = false;
                  alert(response.data.message);
                  self.addpersonal.personalallow = null;
                  self.duplicatepersonalallowancelist = [];
                  self.deductequallyAdd = [];
                  self.percentAdd = [];
                  self.notoverthanAdd = [];
                  self.number_of_PeopleAdd = [];
                  self.addpersonal.ActiveRadio = 1;
                  self.addpersonal.checkbox = true;
                  self.addpersonal.personalallow = null;
                  self.addpersonal.deductequally = null;
                  self.addpersonal.number_of_People = null;
                  self.addpersonal.percent = null;
                  self.addpersonal.notoverthan = null;
                  self.addpersonal.checkalldefault = false;
                  self.LoadingDialog = false;
                  self.GetPersonalAllowance();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Add Income Taxes Fail ", 11, "Low");
                alert(error + "Colleration ID is 11");
                self.LoadingDialog = false;
              });
          } else {
            axios
              .post(
                `${self.url}PersonalAllowance/AddPersonalAllowance`,
                tempersonal
              )
              .then(function (response) {
                if (response.data.status == 0) {
                  self.adddialog = false;
                  alert(response.data.message);
                  self.addpersonal.personalallow = null;
                  self.duplicatepersonalallowancelist = [];
                  self.deductequallyAdd = [];
                  self.percentAdd = [];
                  self.notoverthanAdd = [];
                  self.number_of_PeopleAdd = [];
                  self.addpersonal.ActiveRadio = 1;
                  self.addpersonal.checkbox = true;
                  self.addpersonal.personalallow = null;
                  self.addpersonal.deductequally = null;
                  self.addpersonal.number_of_People = null;
                  self.addpersonal.percent = null;
                  self.addpersonal.notoverthan = null;
                  self.addpersonal.checkalldefault = false;
                  self.LoadingDialog = false;
                  self.GetPersonalAllowance();
                }
              })
              .catch(function (error) {
                self.LogTrace(error, "Add Income Taxes Fail ", 11, "Low");
                alert(error + "Colleration ID is 11");
                self.LoadingDialog = false;
              });
          }
        } else {
          alert("Please fill in required fields");
          self.LoadingDialog = false;
        }
      } else {
        alert("Personal Allowance already exists");
        self.duplicatepersonalallowancelist = [];
      }
    },
    closeadddialog() {
      let self = this;
      self.adddialog = false;
      self.personalallowAdd = [];
      self.deductequallyAdd = [];
      self.percentAdd = [];
      self.notoverthanAdd = [];
      self.number_of_PeopleAdd = [];
      self.addpersonal.checkbox = true;
      self.addpersonal.number_of_People = null;
      self.addpersonal.ActiveRadio = 1;
      self.addpersonal.deductequally = null;
      self.addpersonal.percent = null;
      self.addpersonal.notoverthan = null;
      self.addpersonal.personalallow = null;
    },
    Editpersonal(temp) {
      let self = this;
      self.editdialog = true;
      self.editpersonal.personalID = temp.pA_ID;
      self.editpersonal.personalallow = temp.pA_Name;
      self.editpersonal.deductequally = temp.amount;
      self.editpersonal.number_of_People = temp.number_of_People;
      self.editpersonal.checkbox = temp.limit;
      self.editpersonal.percent = temp.number_of_Percent;
      self.editpersonal.notoverthan = temp.notoverthan;
      self.editpersonal.checkalldefault = temp.set_Default;
      self.editpersonal.ActiveRadio = temp.pA_Type;
    },
    ChangeRadioEdit(e) {
      let self = this;
      let input = e;
      self.percentEdit = [];
      self.notoverthanEdit = [];
      self.number_of_PeopleEdit = [];
      self.deductequallyEdit = [];
      self.editpersonal.deductequally = null;
      self.editpersonal.percent = null;
      self.editpersonal.notoverthan = null;
      self.editpersonal.number_of_People = null;
      if (input == 1) {
        self.editpersonal.checkbox = true;
      } else if (input == 2) {
        self.editpersonal.checkbox = false;
      } else {
        self.editpersonal.checkbox = false;
      }
    },
    CheckdeductequallyAdd() {
      let self = this;
      if (self.addpersonal.ActiveRadio == 1) {
        if (
          self.addpersonal.deductequally == null ||
          self.addpersonal.deductequally == ""
        ) {
          return false;
        }
      }
      return true;
    },
    CheckpercentAdd() {
      let self = this;
      if (self.addpersonal.ActiveRadio == 3) {
        if (
          self.addpersonal.percent == null ||
          self.addpersonal.percent == ""
        ) {
          return false;
        }
      }
      return true;
    },
    ChecknotoverthanAdd() {
      let self = this;
      if (self.addpersonal.ActiveRadio == 3) {
        if (
          self.addpersonal.notoverthan == null ||
          self.addpersonal.notoverthan == ""
        ) {
          return false;
        }
      }
      return true;
    },
    Checknumber_of_PeopleAdd() {
      let self = this;
      if (
        self.addpersonal.ActiveRadio == 1 &&
        self.addpersonal.checkbox == true
      ) {
        if (
          self.addpersonal.number_of_People == null ||
          self.addpersonal.number_of_People == ""
        ) {
          return false;
        }
      }
      return true;
    },
    CheckdeductequallyEdit() {
      let self = this;
      if (self.editpersonal.ActiveRadio == 1) {
        if (
          self.editpersonal.deductequally == null ||
          self.editpersonal.deductequally == ""
        ) {
          return false;
        }
      }
      return true;
    },
    CheckpercentEdit() {
      let self = this;
      if (self.editpersonal.ActiveRadio == 3) {
        if (
          self.editpersonal.percent == null ||
          self.editpersonal.percent == ""
        ) {
          return false;
        }
      }
      return true;
    },
    ChecknotoverthanEdit() {
      let self = this;
      if (self.editpersonal.ActiveRadio == 3) {
        if (
          self.editpersonal.notoverthan == null ||
          self.editpersonal.notoverthan == ""
        ) {
          return false;
        }
      }
      return true;
    },
    Checknumber_of_PeopleEdit() {
      let self = this;
      if (
        self.editpersonal.ActiveRadio == 1 &&
        self.editpersonal.checkbox == true
      ) {
        if (
          self.editpersonal.number_of_People == null ||
          self.editpersonal.number_of_People == ""
        ) {
          return false;
        }
      }
      return true;
    },
    UpdatePersonal() {
      let self = this;
      self.personalallowEdit = [
        (v) => !!v || "Deductions/Allowances is required",
      ];
      if (self.editpersonal.ActiveRadio == 1) {
        self.deductequallyEdit = [(v) => !!v || "Amount is required"];
      }
      if (self.editpersonal.ActiveRadio == 3) {
        self.notoverthanEdit = [(v) => !!v || "Not over than is required"];
        self.percentEdit = [(v) => !!v || "Percent is required"];
      }

      if (self.editpersonal.checkbox == true) {
        self.number_of_PeopleEdit = [
          (v) => !!v || "Number of People is required",
        ];
      }

      if (
        self.editpersonal.number_of_People == null ||
        self.editpersonal.number_of_People == "" ||
        self.editpersonal.number_of_People == undefined
      ) {
        if (self.editpersonal.checkbox == false) {
          self.number_of_PeopleEdit = [];
        }
      }
      let tempvalidate = self.$refs.formEdit.validate();
      let checkboxvalidate = self.CheckNotNullNoOfPeopleEdit();
      let checkdeductequally = self.CheckdeductequallyEdit();
      let checkpercent = self.CheckpercentEdit();
      let checknotoverthan = self.ChecknotoverthanEdit();
      let checknoofpeople = self.Checknumber_of_PeopleEdit();
      let duplicatecheck = self.validateeditpersonalallowance(
        self.editpersonal.personalID,
        self.editpersonal.personalallow
      );
      if (duplicatecheck == true) {
        if (
          self.editpersonal.personalallow != null &&
          self.editpersonal.personalallow != "" &&
          checkdeductequally == true &&
          checkpercent == true &&
          checknotoverthan == true &&
          checknoofpeople == true &&
          tempvalidate == true &&
          checkboxvalidate == true
        ) {
          let tempupdate = {
            pA_ID: self.editpersonal.personalID,
            pA_Name: self.editpersonal.personalallow,
            amount:
              self.editpersonal.deductequally == null
                ? "-"
                : self.editpersonal.deductequally,
            pA_Type: parseInt(self.editpersonal.ActiveRadio),
            number_of_People:
              self.editpersonal.number_of_People == null
                ? 0
                : self.editpersonal.number_of_People == ""
                ? 0
                : parseInt(self.editpersonal.number_of_People),
            limit:
              self.editpersonal.checkbox == null
                ? false
                : self.editpersonal.checkbox,
            number_of_Percent:
              self.editpersonal.percent == null
                ? 0
                : parseFloat(self.editpersonal.percent),
            notoverthan:
              self.editpersonal.notoverthan == null
                ? "-"
                : self.editpersonal.notoverthan,
            set_Default: self.editpersonal.checkalldefault,
            company_ID: store.state.companyID,
          };
          self.LoadingDialog = true;

          axios
            .post(
              `${self.url}PersonalAllowance/UpdatePersonalAllowance`,
              tempupdate
            )
            .then(function (response) {
              if (response.data.status == 0) {
                self.editdialog = false;
                alert(response.data.message);
                self.duplicatepersonalallowancelist = [];

                self.deductequallyEdit = [];
                self.percentEdit = [];
                self.notoverthanEdit = [];
                self.number_of_PeopleEdit = [];
                self.editpersonal.personalallow = null;
                self.editpersonal.deductequally = null;
                self.editpersonal.number_of_People = null;
                self.editpersonal.percent = null;
                self.editpersonal.notoverthan = null;
                self.editpersonal.checkalldefault = false;
                self.LoadingDialog = false;
                self.GetPersonalAllowance();
              }
            })
            .catch(function (error) {
              self.LogTrace(error, "Update Income Taxes Fail", 11, "Medium");
              alert(error + "Correlation ID is 11");
              self.LoadingDialog = false;
            });
        } else {
          alert("Please fill in required fields");
          self.LoadingDialog = false;
        }
      } else {
        alert("Personal Allowance already exists");
        self.duplicatepersonalallowancelist = [];
      }
    },
    checkexcel(data, data1) {
      for (let i = 0; i < data.length; i++) {
        if (data1[i + 1][0] == undefined) {
          this.errorMessage.push("pA_Name");
        }
        if (data1[i + 1][1] == undefined) {
          this.errorMessage.push("amount");
        }
        if (data1[i + 1][2] == undefined) {
          this.errorMessage.push("number_of_People");
        }
      }
    },
    previewFiles() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      let filename = files.name;
      if (filename == "Personal Allowance Master Data.xlsx") {
        if (files != null) {
          let reader = new FileReader();
          reader.onload = function (e) {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            this.importData = XLSX.utils.sheet_to_json(worksheet);
            let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            const file_data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            self.checkexcel(this.importData, file_data);
            if (self.errorMessage.length == 0 && temp.length != 0) {
              let combileArray = [];
              for (let i = 0; i < this.importData.length; i++) {
                this.tempimport = {
                  pA_Name: file_data[i + 1][0],
                  amount: file_data[i + 1][1],
                  number_of_People: file_data[i + 1][2],
                  company_ID: store.state.companyID,
                };
                this.tempimport.amount = this.tempimport.amount.toString();
                combileArray.push(this.tempimport);
              }
              axios.post(`${self.url}PersonalAllowance/ImportPersonalAllowanceMasterData`,combileArray)
                .then(function (response) {
                  if (response.data.status == 0)
                  {
                    alert(response.data.message);
                    self.Uploaddialog = false;
                    self.chosenfile = null;
                    self.showup = false;
                    self.LoadingDialog = false;
                    self.GetPersonalAllowance();
                  }
                })
                .catch(function (error) {
                  self.LogTrace(
                    error,
                    "Upload Income Taxes Fail",
                    11,
                    "Verbose"
                  );
                  alert(error + "Correlation ID is 11");
                  self.LoadingDialog = false;
                });
            } else {
              self.LoadingDialog = false;
              if (temp.length == 0)
              {
                alert("Please fill pA_Name, amount and number_of_People.");
              }
              else
              {
                let Message = [...new Set(self.errorMessage)];
                alert(`Please fill ${Message.join(", ")}`);
              }
            }
          };
          reader.readAsArrayBuffer(files);
          self.LoadingDialog = false;
        } else {
          alert("Please choose File");
          self.LoadingDialog = false;
        }
      } else {
        alert("File name must be Personal Allowance Master Data.xlsx");
        self.LoadingDialog = false;
      }
    },
    Deletepersonal(id) {
      let self = this;
      self.PersonalID = id;
      let temp = {
        pA_Master_ID: id,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}Emp_PersonalAllowance/Deletevalidation`, temp)
        .then(function (response) {
          let getdata = response.data.data;
          if (getdata.validation == true) {
            //alert("Gg");
            self.Cantdeletedialog = true;
          } else {
            self.Deletedialog = true;
          }
        });
      // self.Deletedialog = true;
    },
    Deletepersonalrow() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.PersonalID;
      let tempdel = {
        pA_ID: id,
      };
      axios
        .post(`${self.url}PersonalAllowance/DeletePersonalAllowance`, tempdel)
        .then(function (response) {
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.Deletedialog = false;
            self.LoadingDialog = false;
            self.GetPersonalAllowance();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Income Taxes Fail", 11, "High");
          alert(error);
          self.LoadingDialog = false;
        });
    },
    getupload1() {
      document.getElementById("fileInputButton1").click();
      //this.showup1 = true;
    },
    closeUpload1() {
      this.Uploaddialog1 = false;
      (this.chosenfile1 = null), (this.showup1 = false);
    },
    closeinput1() {
      this.showup1 = false;
      this.chosenfile1 = null;
    },
    checkexcel1(data, data1) {
      for (let i = 0; i < data.length; i++) {
        if (data1[i + 1][0] == undefined) {
          this.errorMessage1.push("net_come");
        }
        if (data1[i + 1][1] == undefined) {
          this.errorMessage1.push("income_tax_rate");
        }
      }
    },
    previewFiles1() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile1;
      if (files != null) {
        let reader = new FileReader();
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          this.importData = XLSX.utils.sheet_to_json(worksheet);
          let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
          const file_data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          self.checkexcel1(this.importData, file_data);
          if (self.errorMessage1.length == 0 && temp.length != 0) {
            let combileArray = [];
            for (let i = 0; i < this.importData.length; i++) {
              this.tempimport = {
                net_come: file_data[i + 1][0].toString(),
                income_tax_rate: (file_data[i + 1][1] * 100).toString(),
                company_ID: store.state.companyID,
              };
              combileArray.push(this.tempimport);
              // if (this.tempimport.income_tax_rate != "") {
              //   this.tempimport.income_tax_rate = (
              //     this.tempimport.income_tax_rate * 100
              //   ).toString();
              // } else {
              //   this.tempimport.income_tax_rate =
              //     this.tempimport.income_tax_rate.toString();
              // }
            }
            if (filename == "Tax Rate Master Data.xlsx") {
              axios
                .post(
                  `${self.url}Taxrate/ImportTaxrateMasterData`,
                  combileArray
                )
                .then(function (response) {
                  if (response.data.status == 0) {
                    alert(response.data.message);
                    self.Uploaddialog1 = false;
                    self.showup1 = false;
                    self.chosenfile1 = null;
                    self.LoadingDialog = false;
                    self.GetIncomeTaxes();
                  }
                })
                .catch(function (error) {
                  self.LogTrace(
                    error,
                    "Upload Income Taxes,Tax Rate Fail",
                    11,
                    "Critical"
                  );
                  alert(error + "Correlation ID is 11");
                  self.LoadingDialog = false;
                });
            } else {
              alert("File name must be Tax Rate Master Data .xlsx");
              self.LoadingDialog = false;
            }
          } else {
            self.LoadingDialog = false;
            if (temp.length == 0) {
              alert("Please fill net_come and income_tax_rate.");
            } else {
              let Message = [...new Set(self.errorMessage1)];
              alert(`Please fill ${Message.join(", ")}`);
            }
          }
        };
        reader.readAsArrayBuffer(files);
        self.LoadingDialog = false;
      } else {
        alert("Please choose File");
        self.LoadingDialog = false;
      }
    },
    GetIncomeTaxes() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Taxrate/GetTaxrate`, tempp)
        .then(function (response) {
          self.TaxList = response.data.data;
          self.LoadingDialog = false;
          self.defaultSort();
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Get Income Taxes,Tax Rate Fail",
            11,
            "Critical"
          );
          alert(error + "Correlation ID is 11");
          self.LoadingDialog = false;
        });
    },
    Edittax(temp) {
      let self = this;
      self.editdialog1 = true;
      self.edittaxrate.taxrateID = temp.tax_Rate_ID;
      self.edittaxrate.incometaxrate = temp.income_tax_rate;
      if (temp.net_come.includes("-")) {
        let y = temp.net_come.split("-")[0];
        let z = temp.net_come.split("-")[1];

        self.edittaxrate.start = y
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        self.edittaxrate.end = z
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    UpdateTax() {
      let self = this;
      self.LoadingDialog = true;
      self.StartEdit = [(v) => !!v || "Start amount is required"];
      self.EndEdit = [(v) => !!v || "End amount is required"];
      self.incometaxrateEdit = [(v) => !!v || "Income Tax Rate is required"];
      let tempvalidate = self.$refs.formEdit1.validate();
      let BeforeEnd = self.BeforeEnd(
        self.edittaxrate.end,
        self.edittaxrate.start
      );
      let AfterStart = self.AfterStart(
        self.edittaxrate.end,
        self.edittaxrate.start
      );
      let duplicatecheck = self.validateeditincometaxlist(
        self.edittaxrate.taxrateID,
        self.edittaxrate.start,
        self.edittaxrate.end,
        self.edittaxrate.incometaxrate
      );
      if (BeforeEnd == true) {
        if (AfterStart == true) {
          if (duplicatecheck == true) {
            if (
              tempvalidate == true &&
              self.edittaxrate.start != null &&
              self.edittaxrate.end != null &&
              self.edittaxrate.incometaxrate != null &&
              self.edittaxrate.start != "" &&
              self.edittaxrate.end != "" &&
              self.edittaxrate.incometaxrate != ""
            ) {
              let income =
                self.edittaxrate.start + " - " + self.edittaxrate.end;
              let temptax = {
                tax_Rate_ID: self.edittaxrate.taxrateID,
                net_come: income,
                income_tax_rate: self.edittaxrate.incometaxrate,
                company_ID: store.state.companyID,
              };
              axios
                .post(`${self.url}Taxrate/UpdateTaxrate`, temptax)
                .then(function (response) {
                  if (response.data.status == 0) {
                    self.editdialog1 = false;
                    alert(response.data.message);
                    self.edittaxrate.start = null;
                    self.edittaxrate.end = null;
                    self.edittaxrate.incometaxrate = null;
                    self.duplicatetaxlist = [];
                    self.LoadingDialog = false;
                    self.GetIncomeTaxes();
                  }
                })
                .catch(function (error) {
                  self.LogTrace(
                    error,
                    "Update Income Taxes,Tax Rate Fail",
                    11,
                    "Low"
                  );
                  alert(error + "Correlation ID is 11");
                  self.LoadingDialog = false;
                });
            } else {
              alert("please fill all the fields");
              self.LoadingDialog = false;
            }
          } else {
            alert("Tax Rate already exists");
            self.LoadingDialog = false;
            self.duplicatetaxlist = [];
          }
        } else {
          alert("End amount should be bigger than Start amount.");
          self.LoadingDialog = false;
        }
      } else {
        alert("Start amount should be smaller than End amount.");
        self.LoadingDialog = false;
      }
    },
    Deletetax(id) {
      let self = this;
      self.TaxID = id;
      self.Deletedialog1 = true;
    },
    Deletetaxrow() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.TaxID;
      let tempdel = {
        tax_Rate_ID: id,
      };
      axios
        .post(`${self.url}Taxrate/DeleteTaxrate`, tempdel)
        .then(function (response) {
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.Deletedialog1 = false;
            self.LoadingDialog = false;
            self.GetIncomeTaxes();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Income Taxes,Tax Rate Fail", 11, "High");
          alert(error);
          self.LoadingDialog = false;
        });
    },
    closeAdddialog1() {
      this.Adddialog1 = false;
      this.$refs.formAdd1.reset();
    },
    validateincometaxexist(start, end, rate) {
      let self = this;
      let income = start + " - " + end;
      for (let i = 0; i < self.TaxList.length; i++) {
        if (
          income == self.TaxList[i].net_come &&
          rate == self.TaxList[i].income_tax_rate
        ) {
          self.duplicatetaxlist.push(income);
        }
      }
      if (self.duplicatetaxlist.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    validateeditincometaxlist(id, start, end, rate) {
      let self = this;
      let income = start + " - " + end;
      for (let i = 0; i < self.TaxList.length; i++) {
        if (id != self.TaxList[i].tax_Rate_ID) {
          if (
            income == self.TaxList[i].net_come &&
            rate == self.TaxList[i].income_tax_rate
          ) {
            self.duplicatetaxlist.push(income);
          }
        }
      }
      if (self.duplicatetaxlist.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    Addtaxrate() {
      let self = this;
      self.LoadingDialog = true;
      self.StartAdd = [(v) => !!v || "Start amount is required"];
      self.EndAdd = [(v) => !!v || "End amount is required"];
      self.incomerateAdd = [(v) => !!v || "Income Tax Rate is required"];
      let tempvalidate = self.$refs.formAdd1.validate();
      let BeforeEnd = self.BeforeEnd(
        self.addtaxrate.end,
        self.addtaxrate.start
      );
      let AfterStart = self.AfterStart(
        self.addtaxrate.end,
        self.addtaxrate.start
      );
      let duplicatecheck = self.validateincometaxexist(
        self.addtaxrate.start,
        self.addtaxrate.end,
        self.addtaxrate.incomerate
      );
      if (BeforeEnd == true) {
        if (AfterStart == true) {
          if (duplicatecheck == true) {
            if (
              tempvalidate == true &&
              self.addtaxrate.start != null &&
              self.addtaxrate.end != null &&
              self.addtaxrate.incomerate != null &&
              self.addtaxrate.start != "" &&
              self.addtaxrate.end != "" &&
              self.addtaxrate.incomerate != ""
            ) {
              let income = self.addtaxrate.start + " - " + self.addtaxrate.end;
              let taxadd = {
                net_come: income,
                income_tax_rate: self.addtaxrate.incomerate,
                company_ID: store.state.companyID,
              };
              axios
                .post(`${self.url}Taxrate/AddTaxrate`, taxadd)
                .then(function (response) {
                  if (response.data.status == 0) {
                    self.Adddialog1 = false;
                    alert(response.data.message);
                    self.addtaxrate.start = null;
                    self.addtaxrate.end = null;
                    self.addtaxrate.incomerate = null;
                    self.duplicatetaxlist = [];
                    self.LoadingDialog = false;
                    self.GetIncomeTaxes();
                  }
                });
            } else {
              alert("Please fill all the fields");
              self.LoadingDialog = false;
            }
          } else {
            alert("Tax Rate already exists");
            self.LoadingDialog = false;
            self.duplicatetaxlist = [];
          }
        } else {
          alert("End amount should be bigger than Start amount.");
          self.LoadingDialog = false;
        }
      } else {
        alert("Start amount should be smaller than End amount.");
        self.LoadingDialog = false;
      }
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
tbody tr:hover {
  background-color: #fff9f0 !important;
}
@media (max-width: 414px) {
  .btnres {
    margin-top: 1rem !important;
  }
}
@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
  }
}
@media (max-width: 770px) {
  .tbheader {
    margin-top: 1rem !important;
  }
}
.v-tab:before {
  background: none !important;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
}

.v-tabs-bar .v-tab {
  background-color: white;
  width: 350px;
  border: #f99d20 solid thin;
}
.active {
  cursor: pointer !important;
}

.v-tab--active {
  color: #3c4043;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .taxrate .v-data-table tbody tr td {
  width: 700px;
}
</style>
