<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="margin-bottom: 1rem">
      <v-row>
        <v-btn icon @click="back()" color="white" class="mt-6">
          <img src="@/assets/images/back button.png" class="mb-1" />
        </v-btn>
        <p
          text
          style="
            cursor: pointer;
            font-size: 26px;
            margin-top: 0.2rem;
            margin-left: 5px;
            margin-top: 20px;
          "
          @click="back()"
        >
          {{ $t("salarychangehistory") }}
        </p>
      </v-row>
    </div>
    <v-card class="mt-6 mb-5" style="margin-bottom: 5rem">
      <v-row class="pt-5" style="margin-left: 10px; margin-right: 10px">
        <v-col cols="12">
          <v-data-table
            style="margin-left: 1rem; margin-right: 1rem"
            :items="desserts"
            :page.sync="page"
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            hide-default-footer
            hide-default-header
            :mobile-breakpoint="0"
          >
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th>{{ $t("totalincome") }}</th>
                  <th>{{ $t("date_ofchange") }}</th>
                  <th>{{ $t("hr_incharge") }}</th>
                  <th class="b-text text-end">{{ $t("action") }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.totalFixedIncome }}</td>
                <td>{{ formatDate(item.createDate) }}</td>
                <td>{{ item.full_Nmae }}</td>
                <td class="text-end">
                  <v-btn @click="EditHistory(item)" icon>
                    <img class="editIcon" :src="images.edit" alt="editIcon"/>
                  </v-btn>
                  <v-btn @click="DeleteRow(item.salary_ID)" icon>
                    <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <div class="text-right" v-if="desserts.length">
            <v-row justify="end" class="ml-5 mt-5 mr-5">
              <v-col cols="12" md="3">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  color="#FFCD2C"
                  :total-visible="6"
                ></v-pagination>
              </v-col>
              <v-col cols="12" md="2" class="mt-1">
                <v-select
                  dense
                  style="width: 120px; float: right"
                  solo
                  label="10/page"
                  v-model="itemsPerPage"
                  :items="items"
                  @input="itemsPerPage = parseInt($event, 10)"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <div style="height: 45vh"></div>

      <!-- edit shift code dialog -->
      <v-dialog v-model="Editdialog" persistent max-width="400px">
        <v-card style="border-radius: 15px">
          <p class="pt-5 ml-8 mr-2" style="font-size: 23px">
            {{ $t("EditSalaryChangeHistory") }}
          </p>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    autocomplete="off"
                    :label="$t('amount')"
                    required
                    v-model="salary_Amount"
                    color="#F99D20"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              text
              @click="Editcancel()"
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="UpdateSlaryChange()"
              >{{ $t("update") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete Shift dialog -->
      <v-dialog v-model="Deletedialog" persistent max-width="532">
        <v-card style="border-radius: 15px">
          <v-card-title>
            <p style="margin-top: 10px; margin-bottom: -30px">
              {{ $t("deleteitem") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col align="center" cols="12">
                  <p style="font-size: 17px; color: #444444" class="pt-4">
                    {{ $t("confirmdelete") }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="Deletedialog = false"
              text
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="120"
              max-height="35"
              @click="DeletingHistory()"
              >{{ $t("delete") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
export default {
  data() {
    return {
      Editdialog: false,
      salary_Amount: "",
      Deletedialog: false,
      url: enurl.apiUrl,
      employee_ID: "",
      page: 1,
      pageCount: 10,
      itemsPerPage: 10,
      items: [5, 10],
      department: [],
      desserts: [],
      salary: [],
    };
  },

  async mounted() {
    let self = this;
    let id = self.$route.params.id;
    let code = localStorage.getItem("empcode");
    if (!id && code)
    {
      await this.changeCodeToId();
      self.GetSalaryWithCode();
    }
    else
    {
      self.GetSalary();
    }
  },

  computed: {
    images() {
      return this.$store.state.images;
    }
  },

  methods: {
    async changeCodeToId() {
      let Id = localStorage.getItem("empcode");
      let temp = {
        employee_Code: Id,
        company_ID: store.state.companyID,
      };
      const response = await axios.post(
        `${this.url}PersonalInfo/GetPersonalInformationWithCode`,
        temp
      );
      this.employee_ID = response.data.data.employee_ID;
    },
    EditHistory(v) {
      this.salary_Amount = v.totalFixedIncome;
      this.salary_ID = v.salary_ID;
      this.Editdialog = true;
    },
    async UpdateSlaryChange() {
      try {
        this.temp = {
          salary_Amount: this.salary_Amount,
          salary_ID: this.salary_ID,
          isEdit: "Yes",
        };
        const response = await axios.post(
          `${this.url}Salary/UpdateSalary`,
          this.temp
        );
        if (response.data.status == 0) {
          this.Editdialog = false;
          alert(response.data.message);
          if (this.employee_ID) {
            this.GetSalaryWithCode();
          } else {
            this.GetSalary();
          }
        }
      } catch (ex) {
        throw ex;
      }
    },
    DeleteRow(id) {
      this.Deletedialog = true;
      this.salary_ID = id;
    },
    Editcancel() {
      this.salary_Amount = "";
      this.Editdialog = false;
    },
    async DeletingHistory() {
      try {
        this.temp = {
          salary_ID: this.salary_ID,
        };
        const response = await axios.post(
          `${this.url}Salary/DeleteSalary`,
          this.temp
        );
        if (response.data.status == 0) {
          this.Deletedialog = false;
          alert(response.data.message);

          if (this.employee_ID) {
            this.GetSalaryWithCode();
          } else {
            this.GetSalary();
          }
        }
      } catch (ex) {
        throw ex;
      }
    },
    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    back() {
      let workim = this.$route.params.id;
      if (this.$route.name == "PersonalSalaryHistory") {
        this.$router
          .push("/selfservice/PersonalProfile/PersonalInformation/Salary")
          .catch(() => {});
      } else if (workim != undefined && workim != "undefined") {
        this.$router
          .push(
            `/PersonnelManagement/EditEmployee/${this.$route.params.id}/EditPersonalInformation/Salary`
          )
          .catch(() => {});
      } else if (this.$route.name == "newsalarychangehistory") {
        this.$router
          .push(
            "/PersonnelManagement/NewEmployee/NewPersonalInformation/Salary"
          )
          .catch(() => {});
      }
    },
    GetSalaryWithCode() {
      let self = this;
      let temp = { employee_ID: parseInt(this.employee_ID) };
      axios
        .post(`${self.url}Salary/GetSalaryAll`, temp)
        .then(function (response) {
          self.salary = response.data.data;
          self.desserts = response.data.data;
        });
    },
    GetSalary() {
      let self = this;
      let temp;

      if (self.$route.name == "PersonalSalaryHistory") {
        temp = { employee_ID: store.state.employeeID };
      } else if (self.$route.params.id != undefined) {
        temp = { employee_ID: parseInt(self.$route.params.salaryid) };
      }

      axios
        .post(`${self.url}Salary/GetSalaryAll`, temp)
        .then(function (response) {
          self.salary = response.data.data;
          self.desserts = response.data.data;
          // self.desserts = [];
          //console.log(self.salary);
          //console.log(self.ShiftCodeList);
          // for (let i = 0; i < self.salary.length; i++) {
          //   if (
          //     self.salary[i].salary_Amount > self.salary[i + 1].salary_Amount
          //   ) {
          //     self.desserts.push({
          //       prevdept: "Raise",
          //       salary_Amount: self.salary[i].salary_Amount,

          //       salary_ID: self.salary[i].salary_ID,
          //       createDate: self.salary[i + 1].createDate,
          //     });
          //   }
          //   if (
          //     self.salary[i].salary_Amount < self.salary[i + 1].salary_Amount
          //   ) {
          //     self.desserts.push({
          //       prevdept: "Deduction",
          //       salary_Amount: self.salary[i].salary_Amount,
          //       salary_ID: self.salary[i].salary_ID,
          //       createDate: self.salary[i + 1].createDate,
          //     });
          //   }
          // }
        });
    },
  },
};
</script>
<style scoped>
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  font-size: 17px;
  color: #f99d20;
  font-weight: normal;
}
</style>
