<template>
  <div>
    <v-card style="margin-bottom: 5rem">
      <v-container>
        <v-card flat class="mt-5 ml-4 mr-4 mb-5 probationDiv">
          <v-row class="mt-2 ml-0 mr-0">
            <v-col cols="12" lg="1" md="2" class="pr-0">
              <v-img src="@/assets/images/workingcondition.svg" class="wcImg"></v-img>
            </v-col>
            <v-col cols="12" lg="5" md="6" sm="8" class="pt-2">
              <v-card-title class="pt-0 textPro">
                {{ $t("Probation") }}
              </v-card-title>
              <v-card-subtitle class="textsub">
                {{ $t("DetermineProbation") }}
              </v-card-subtitle>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="mx-2 mr-4 mt-1 btn" icon small @click="showCard = !showCard">
              <v-icon>{{ this.showCard ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
            </v-card-actions>
          </v-row>
          <v-expand-transition>
              <div v-show="showCard" class="">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="4" md="12">
                      <span class="spanalert"><v-badge class="mt-1" color="white" :value="alertdialog" grow transition="slide-y-transition">
                        <template v-slot:badge>
                          <div class="my-badge">{{$t("DurationofProbation")}}</div>
                        </template>
                      </v-badge></span>
                      <v-card-subtitle class="textsub pt-0 pl-0">{{$t("DaysofProbation")}} 
                        <v-img :src="isHovered ? outlineinfoimg : infoimg" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"  class="infoimg"></v-img>
                      </v-card-subtitle>
                      <v-text-field label v-model="entitleday" color="#F99D20" outlined dense v-mask="mask" class="custom-text-field-1 textPro">
                        <template v-slot:append>
                          {{ $t("day") }}
                        </template></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-expand-transition>
        </v-card>
      </v-container>
    </v-card>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

    <v-dialog v-model="confirmDialog" persistent width="400" >
      <v-card class="confirmDialog">
        <v-card-text>
          <v-card-title class="justify-center">
            {{ $t("confirmation")}}
          </v-card-title>
          <v-card-subtitle class="wantto">
            {{$t("wanttoEdit")}}
          </v-card-subtitle>
        </v-card-text>
        <v-card-actions>
          <v-row class="justify-space-around">
          <!-- Save & Cancel -->
          <v-btn
            outlined
            style="border-radius: 5px"
            class="pa-2 text-capitalize"
            width="120"
            max-height="40"
            @click="confirmDialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            :disabled="btnDisabled"
            color="#F99D20"
            depressed
            class="pa-2 text-capitalize white--text"
            width="120"
            max-height="40"
            style="border-radius: 5px"
            @click="updateProbationDay"
            >{{ $t("Confirm") }}</v-btn
          >
        </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-footer v-if="showCard" style="background-color:#FFFFFF; border-top:1px solid #D9D5D5;" elevation="0" height="80px" fixed >
      <v-row style="flex-direction: row-reverse">
        <!-- Save & Cancel -->
        <v-btn
          :disabled="btnDisabled"
          class="pa-2 mr-7 text-capitalize commonsavebtn"
          @click="confirmDialog = true"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          outlined
          class="pa-2 mr-7 text-capitalize commoncancelbtn"
          @click="cancelGone"
          text
          >{{ $t("cancel") }}</v-btn
        >
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import { mask } from "vue-the-mask";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  directives: {
    mask,
  },
  mixins: [utils, CheckViewAddEditDelete],
  data() {
    return {
      url: enurl.apiUrl,
      showCard: false,
      mask: "########",
      entitleday: 0,
      infoimg: require("@/assets/images/1Information.svg"),
      outlineinfoimg: require("@/assets/images/information-variant-circle 1.svg"),
      isHovered: false,
      alertdialog: false,
      probationList: [],
      LoadingDialog: false,
      confirmDialog: false
    }
  },
  methods:{
    handleMouseOver() {
      this.isHovered = true;
      this.openDialog();
    },
    handleMouseLeave() {
      this.isHovered = false;
      this.closeDialog();
    },
    openDialog() {
      this.alertdialog = true;
    },
    closeDialog() {
      this.alertdialog = false;
    },
    cancelGone(){
      this.showCard = false;
      this.getProbationDay();
    },
    getProbationDay(){
      let self = this;
      self.LoadingDialog = true;
      axios
        .get(
          `${self.url}Company/GetProbationByCompanyID?Company_ID=${store.state.companyID}`
        )
        .then(function (response) {
          self.probationList = response.data.data;
          self.entitleday = self.probationList[0].probation_Days;
          self.LoadingDialog = false;
          if(self.entitleday == null){
            self.entitleday = 0;
          }
          else{
            return parseInt(self.entitleday);
          }
        })
        .catch(function (ex) {
          console.log(ex);
          self.LoadingDialog = false;
        });
    },
    async updateProbationDay(){
      let self = this;
      self.LoadingDialog = true;
      let request = {
        company_ID : store.state.companyID,
        probation_Days : parseInt(self.entitleday)
      };
      axios
      .post(`${self.url}Company/UpdateProbationDay`, request)
      .then(function (response) {
          if (response.data.status == 0) {
            alert("Save Probation Days Successfully");
            self.LoadingDialog = false;
            self.getProbationDay();
            self.showCard = false;
            self.confirmDialog = false;
          }
        })
        .catch(function (ex) {
          console.log(ex);
          self.LoadingDialog = false;
        });
    }
  },
  computed: {
    btnDisabled(){
      return !this.entitleday;
    },
  },
  mounted(){
    this.getProbationDay();
  }
};
</script>
<style scoped>
.savebtn{
  transition: 0.3s all ease;
  background-color: #F99D20!important;
}

.container {
  max-width: 100%;
}
.probationcard {
  height: 100vh;
}
.probationDiv {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 98%;
}
.wcImg {
  max-width: 45px !important;
  height: 40px;
  margin-left: 15px;
  margin-top: 5px;
}
.textPro {
  color: #707070;
  font-size: 16px;
}
.textsub {
  color: #8a8c8e;
}
.btn {
  margin: 0;
  position: absolute;
  left: 96%;
  transform: translate(-50%, -50%);
}

::v-deep .custom-text-field-1>.v-input__control>.v-input__slot>.v-input__append-inner {
  padding-top: 4px;
}

::v-deep .custom-text-field-1 input {
  /* direction: rtl; Set text direction to right-to-left */
  text-align: right;
  /* Align text to the right */
}
::v-deep .v-text-field__details{
  display: none;
}
.infoimg{
  display: inline-block;
  width: 20px!important;
  position: relative;
  transition: transform 0.6s ease-in-out;
  margin-bottom: -4px!important;
}
.my-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  height: 34px;
  width: 400px;
  color: #FFFF;
  background: #707070;
  white-space: pre-wrap;
  border-radius: 5px;
}
.spanalert{
  left: 3%;
  top: 30%!important;
  position: absolute!important;
}
.wantto{
  text-align: center!important;
}
.confirmDialog{
  height: 160px;
}
</style>

<style scoped>
@media (max-width: 1265px) and (min-width: 320px) {
  .probationDiv {
      width: 95%!important;

  }
}
@media (max-width: 450px) {
  .btn{
    left: 90%;
  }
}
@media (max-width: 600px) {
  .btn{
    top: 30%;
  }
}
</style>
