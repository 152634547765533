<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="ml-4 headtext txt-header">
        {{ $t("EmploymentReport") }}
      </p>
    </div>
    
    <v-row style="margin-right: 0; margin-left: -2px">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pt-1 mb-5 card" height="100%">
          <div class="di">
            <v-divider
              v-if="
                addition == true || termination == true || agegender == true
              "
            />
            <v-list v-show="agegender">
              <v-list-item router to="/employmentreport/agegender">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.agegender"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("employeesbyageandgender")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="agegender" />
            <v-list>
              <v-list-item v-show="addition" router to="/employmentreport/addition">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.addition"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("EmpRep")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="addition" />
            <v-list v-show="termination">
              <v-list-item router to="/employmentreport/termination">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.termination"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("EmployementLayOffReport")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="termination" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      age: "Employees by Age and Gender",
      item: {
        addition: require("@/assets/images/Retention_1_@2x.png"),
        termination: require("@/assets/images/user@2x.png"),
        agegender: require("@/assets/images/group@2x.png"),
      },
      addition: false,
      termination: false,
      agegender: false,
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      this.addition = this.termination = this.agegender = true;
    }
    if (perid.some((e) => e == 172)) {
      this.addition = true;
    }
    if (perid.some((e) => e == 174)) {
      this.termination = true;
    }
    if (perid.some((e) => e == 176)) {
      this.agegender = true;
    }
  },
};
</script>
<style scoped>
.card {
  /* margin-bottom: 5rem; */
  margin-left: 1%;
  margin-right: 0.3%;
  /* height: 100vh; */
  /* margin-top: -1.5rem; */
}
.titles {
  color: #f89d1f;
  font-weight: bold;
  font-size: 17px;
  margin-top: -1rem;
}
.di {
  margin: 20px;
  margin-bottom: 3rem;
}
</style>

