<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="margin-bottom: 1rem">
      <v-row>
        <p
          class="ml-2"
          text
          style="
            cursor: pointer;
            font-size: 25px;
            color: #43425d;
            margin-top: 20px;
            padding-left: 9px;
          "
        >
          {{ $t("VersionAndRelease") }}
        </p>
      </v-row>
      <v-card class="mt-4">
        <v-row style="margin-left: -0.3rem">
          <img
            src="@/assets/images/Mask Group 139 (5)@2x.png"
            width="30"
            height="30"
            class="mt-3 ml-4"
          />
          <p
            class="ml-1"
            text
            style="
              cursor: pointer;
              font-size: 16px;
              margin-top: 1.2rem;
              padding-left: 9px;
              color: #f89d1f;
            "
          >
            {{ $t("VersionAndRelease") }}
          </p>
        </v-row>

        <v-row style="margin-left: -0.3rem; margin-right: 1.5rem">
          <p
            class="ml-2"
            text
            style="
              cursor: pointer;
              font-size: 16px;
              margin-top: 1.2rem;
              padding-left: 9px;
            "
          >
            {{ $t("CurrentVersion") }} {{ $t("November 11,2022") }}
          </p>
        </v-row>
        <v-row class="#F0F0F7 conrow">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="3"
            lg="3"
            class="cardpadding2 col"
          >
            <div class="CardLayoutOne">
              1.0.1<br />
              {{ $t("April 08,2021") }}
            </div>
            <div class="cardwith">
              <div class="row">
                <div class="card">
                  <p class="card-text">
                    <br />
                    1.0.1 <br /><br />
                    1. {{ $t("PersonnelManagement(Personal Info)") }} <br />
                    2. {{ $t("TimeAttendance") }}({{ $t("timeEntries") }}/{{
                      $t("StaffTimeAttendance")
                    }}/<br />
                    {{ $t("OTRequests") }}/{{ $t("ShiftAssignment") }}/{{
                      $t("WorkdayCalendar")
                    }}/{{ $t("TimeGroups") }}/{{ $t("TimeEntriesSetting") }})
                    <br />
                    3. {{ $t("TimeOffRequest") }}<br />
                    4. {{ $t("Payroll(Predefined and Customized)") }} <br />
                    5. {{ $t("masterData") }} <br />
                    6. {{ $t("UserManagement") }} <br />
                    7. {{ $t("My Tasks(Approve/Deny/Delegate tasks)") }} <br />
                    8. {{ $t("Notifications for Time off and OT requests") }}
                    <br />
                    9. {{ $t("announcements") }} <br />
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="3"
            lg="3"
            class="cardpadding3 mt-4 col"
          >
            <div class="CardLayoutTwo">
              1.0.2<br />
              {{ $t("January 10,2022") }}
            </div>
            <div class="cardwith">
              <div class="row">
                <div class="card">
                  <p class="card-text2">
                    <br />
                    1.0.2 <br /><br />
                    1.
                    {{
                      $t(
                        "Sync Time Attendance Data- txt and csv template files"
                      )
                    }}
                    <br />
                    2. {{ $t("Sync Time Attendance Data -Import from Jarviz")
                    }}<br />
                    3. {{ $t("Brand Identity") }}<br />
                    4. {{ $t("Default Language Setting") }} <br />
                    5. {{ $t("Common Statistics") }} <br />
                    6. {{ $t("PDPA") }} <br />
                    7. {{ $t("Autoregistration") }} <br />
                    8.
                    {{
                      $t(
                        "Issue Fixes in Workday Calendar, Personnel Management"
                      )
                    }}
                    <br />
                    9.
                    {{
                      $t("Change Batch Upload required field in Template file")
                    }}
                    <br />
                    10. {{ $t("Reorganize and create Self Service menu") }}
                    <br />
                    11.
                    {{
                      $t(
                        "Effective shift dates can be set before current date and time attendance from before can be synced"
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            flex
            shrink
            cols="12"
            xs="12"
            sm="12"
            md="3"
            lg="3"
            class="cardpadding1 mt-4 col"
          >
            <div class="CardLayoutThree">
              2.0.0<br />
              {{ $t("November 11,2022") }}
            </div>
            <div class="cardwith">
              <div class="row">
                <div class="card">
                  <p class="card-text3">
                    <br />
                    2.0.0 <br /><br />
                    1.
                    {{ $t("Update Look and Feel Theme") }}
                    <br />
                    2. {{ $t("Fill Clock in / Clock out by HR admin") }}<br />
                    3.
                    {{
                      $t(
                        "Update clock-in (days) in pay roll after update Time attendance"
                      )
                    }}<br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 2rem">
          <div class="my-2 ml-3 mt-5">
            <v-btn
              depressed
              class="text-capitalize"
              style="background-color: #f99d20; color: white"
              @click="showListChange()"
              >{{ $t("AllVersion") }}
              <img
                class="ml-2 mt-1"
                width="15"
                height="6"
                :src="showLists ? Advanceimage.down : Advanceimage.up"
                alt
            /></v-btn>
          </div>
        </v-row>
        <v-row
          v-if="showLists"
          style="margin-left: 2.2rem; margin-right: 2.2rem"
        >
          <v-col cols="12" md="12" sm="12">
            <v-card class="mx-auto mb-3">
              <v-row class="ml-1 mr-1">
                <v-card-subtitle class="pb-0" style="color: #f99d20"
                  >2.0.0</v-card-subtitle
                >
                <v-spacer></v-spacer>
                <v-card-subtitle class="pb-0" style="color: #f99d20">{{
                  $t("November 11,2022")
                }}</v-card-subtitle>
              </v-row>

              <v-card-text class="text--primary">
                <div>
                  1.
                  {{ $t("Update Look and Feel Theme") }}
                  <br />
                  2. {{ $t("Fill Clock in / Clock out by HR admin") }}<br />
                  3.
                  {{
                    $t(
                      "Update clock-in (days) in pay roll after update Time attendance"
                    )
                  }}<br />
                </div>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto mb-3 mt-5">
              <v-row class="ml-1 mr-1">
                <v-card-subtitle class="pb-0" style="color: #f99d20"
                  >1.0.2</v-card-subtitle
                >
                <v-spacer></v-spacer>
                <v-card-subtitle class="pb-0" style="color: #f99d20">{{
                  $t("January 10,2022")
                }}</v-card-subtitle>
              </v-row>

              <v-card-text class="text--primary">
                <div>
                  1.
                  {{
                    $t("Sync Time Attendance Data- txt and csv template files")
                  }}
                  <br />
                  2. {{ $t("Sync Time Attendance Data -Import from Jarviz")
                  }}<br />
                  3. {{ $t("Brand Identity") }}<br />
                  4. {{ $t("Default Language Setting") }} <br />
                  5. {{ $t("Common Statistics") }} <br />
                  6. {{ $t("PDPA") }} <br />
                  7. {{ $t("Autoregistration") }} <br />
                  8.
                  {{
                    $t("Issue Fixes in Workday Calendar, Personnel Management")
                  }}
                  <br />
                  9.
                  {{
                    $t("Change Batch Upload required field in Template file")
                  }}
                  <br />
                  10. {{ $t("Reorganize and create Self Service menu") }} <br />
                  11.
                  {{
                    $t(
                      "Effective shift dates can be set before current date and time attendance from before can be synced"
                    )
                  }}
                </div>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto mb-3 mt-5">
              <v-row class="ml-1 mr-1">
                <v-card-subtitle class="pb-0" style="color: #f99d20"
                  >1.0.1</v-card-subtitle
                >
                <v-spacer></v-spacer>
                <v-card-subtitle class="pb-0" style="color: #f99d20">{{
                  $t("April 08,2021")
                }}</v-card-subtitle>
              </v-row>

              <v-card-text class="text--primary">
                <div>
                  1. {{ $t("PersonnelManagement(Personal Info)") }} <br />
                  2. {{ $t("TimeAttendance") }}({{ $t("timeEntries") }}/{{
                    $t("StaffTimeAttendance")
                  }}/ {{ $t("OTRequests") }}/{{ $t("ShiftAssignment") }}/{{
                    $t("WorkdayCalendar")
                  }}/{{ $t("TimeGroups") }}/{{ $t("TimeEntriesSetting") }})
                  <br />
                  3. {{ $t("TimeOffRequest") }} <br />
                  4.{{ $t("Payroll(Predefined and Customized)") }} <br />
                  5.{{ $t("masterData") }} <br />
                  6. {{ $t("UserManagement") }} <br />
                  7. {{ $t("My Tasks(Approve/Deny/Delegate tasks)") }} <br />
                  8. {{ $t("Notifications for Time off and OT requests") }}
                  <br />
                  9. {{ $t("announcements") }} <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- </div> -->
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      Advanceimage: {
        up: require("@/assets/images/UPP.png"),
        down: require("@/assets/images/Group 3494_0.png"),
      },
      customImgHeight: "100%",
      rememberme: "false",
      showLists: false,
    };
  },
  mounted() {},
  methods: {
    showListChange() {
      let self = this;
      self.showLists = !self.showLists;
    },
  },

  watch: {},
};
</script> 

<style scoped>
.CardLayoutOne {
  width: 244px;
  height: 50px;
  background-color: #06b11d;
  padding-top: 0.3rem;
  color: white;
  text-align: center;
  font-size: 14px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.CardLayoutTwo {
  width: 244px;
  height: 50px;
  background-color: #ff4b55;
  color: white;
  padding-top: 0.3rem;
  text-align: center;
  font-size: 14px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.CardLayoutThree {
  width: 244px;
  height: 50px;
  background-color: #0fa7d8;
  font-size: 14px;
  color: white;
  padding-top: 0.3rem;
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.CardLayoutFour {
  width: 244px;
  height: 50px;
  background-color: #f99d20;
  color: white;
  padding-top: 0.3rem;
  font-size: 14px;
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.row {
  /* align-items: stretch;
  display: flex;
  flex-wrap: nowrap; */
  overflow-x: auto;
  overflow-y: auto;
}
.card {
  /*float: left;*/
  max-width: 100%;
  /* padding-top: 0.2rem; */
  padding-left: 0.75rem;
  padding-right: 0.06rem;
  margin-bottom: 2rem;
  border: 0;
  flex-basis: 100%;
  /* flex-grow: 0;
  flex-shrink: 0; */
  max-height: 280px;
}

.card > img {
  margin-bottom: 0.75rem;
}

.card-text {
  font-size: 13px;
  border-left: #06b11d 5px solid;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
.card-text2 {
  font-size: 13px;
  border-left: #ff4b55 5px solid;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
.card-text3 {
  font-size: 13px;
  border-left: #0fa7d8 5px solid;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
.card-text4 {
  font-size: 13px;
  border-left: #f99d20 5px solid;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
.cardpadding1 {
  margin-top: 1rem;
}
.cardpadding2 {
  margin-top: 10rem;
}
.cardpadding3 {
  margin-top: 6rem !important;
}
.conrow {
  margin-left: 2.5rem;
  display: flex !important;
  flex-wrap: wrap !important;
}
.cardwith {
  width: 230px;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  .conrow {
    margin-left: 0rem !important;
  }
  .cardpadding1 {
    margin-top: 1rem !important;
  }
  .cardpadding2 {
    margin-top: 1rem !important;
  }
  .cardpadding3 {
    margin-top: 1rem !important;
  }
  .cardpadding4 {
    margin-top: 1rem !important;
  }
}
@media only screen and (min-width: 375px) {
  .conrow {
    margin-left: 1.8rem !important;
  }
}
@media (min-width: 1024px) and (max-width: 1025px) {
  .conrow {
    margin-left: 0rem !important;
  }
  .cardwith {
    width: 200px !important;
  }
  .CardLayoutOne {
    width: 214px !important;
  }
  .CardLayoutTwo {
    width: 214px !important;
  }
  .CardLayoutThree {
    width: 214px !important;
  }
  .CardLayoutFour {
    width: 214px !important;
  }
}
@media (min-width: 958px) and (max-width: 1260px) {
  /* .cardpadding3 {
    margin-left: 5rem !important;
  } */
  /* .CardLayoutThree {
    width: 200px !important;
  } */
  /* .row {
    width: 50% !important;
  } */
  .conrow {
    flex-direction: column !important;
  }
  .col {
    flex: 50% !important;
  }
}
@media (min-width: 1264px) and (max-width: 1285px) {
  .conrow {
    margin-left: 0rem !important;
  }
  .cardwith {
    width: 200px !important;
  }
  .CardLayoutOne {
    width: 210px !important;
  }
  .CardLayoutTwo {
    width: 210px !important;
  }
  .CardLayoutThree {
    width: 210px !important;
  }
  .CardLayoutFour {
    width: 210px !important;
  }
}
</style>