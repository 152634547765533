/* eslint-disable no-console */
import enurl from "@/api/environment";
export default {
  data() {
    return {
      url: enurl.apiUrl,
    };
  },
  methods: {
    getrange(salary) {
      if (salary > 0 && salary <= 150000) {
        return 1;
      }
      if (salary > 150000 && salary <= 300000) {
        return 2;
      }
      if (salary > 300000 && salary <= 500000) {
        return 3;
      }
      if (salary > 500000 && salary <= 750000) {
        return 4;
      }
      if (salary > 750000 && salary <= 1000000) {
        return 5;
      }
      if (salary > 1000000 && salary <= 2000000) {
        return 6;
      }
      if (salary > 2000000 && salary <= 5000000) {
        return 7;
      }
      if (salary > 5000000) {
        return 8;
      }
    },
    GetIncome(salary,allowance) {
      try {
        let residual = salary * 12;
        let tax = 0;
        // let deduct = 60000;
        // let deduction = 0;
        // if (residual < 100000) {
        //   deduction = residual * 0.5;
        // }
        // else {
        //   deduction = 100000;
        // }

        let range = this.getrange(residual);
        let residualincome = residual - allowance;
        for (let i = 0; i < range; i++) {
          let mytax = 0;
          let myrange = range - 1;
          if (i == 0) {
            tax = 0;
          }
          if (i == 1) {
            if (residualincome >150000) {
              residualincome = residualincome - 150000;
              if (i == myrange) {
                mytax = residualincome * 0.05;
              }
              else {
                if(residualincome>150000)
                {
                mytax = 150000 * 0.05;
                }
                else{
                  mytax = residualincome * 0.05;
                }

              }
            }
            tax = tax + mytax;

          }
          if (i == 2) {
            if (residualincome >150000) {
              residualincome = residualincome - 150000;
              if (i == myrange) {
                mytax = residualincome * 0.1;
              }
              else {
                if(residualincome>200000)
                {
                mytax = 200000 * 0.1;
                }
                else{
                  mytax = residualincome * 0.1;
                }

              }
            }
            tax = tax + mytax;


          }
          if (i == 3) {
            if (residualincome >200000) {
              residualincome = residualincome - 200000;
              if (i == myrange) {
                mytax = residualincome * 0.15;
              }
              else {
                if(residualincome>250000)
                {
                mytax = 250000 * 0.15;
                }else{
                  mytax = residualincome * 0.15;
                }

              }
            }
            tax = tax + mytax;

          }
          if (i == 4) {
            if (residualincome >250000) {
              residualincome = residualincome - 250000;
              if (i == myrange) {
                mytax = residualincome * 0.2;
              }
              else {
                if(residualincome>250000)
                {
                mytax = 250000 * 0.2;
                }
                else{
                  mytax = residualincome * 0.2;
                }

              }
            }

            tax = tax + mytax;
          }
          if (i == 5) {
            if (residualincome >250000) {
              residualincome = residualincome - 250000;
              if (i == myrange) {
                mytax = residualincome * 0.25;
              }
              else {
                if(residualincome>1000000)
                {
                mytax = 1000000 * 0.25;
                }
                else{
                  mytax = residualincome * 0.25;
                }

              }
            }
            tax = tax + mytax;

          }
          if (i == 6) {
            if (residualincome >1000000) {
              residualincome = residualincome - 1000000;
              if (i == myrange) {
                mytax = residualincome * 0.3;
              }
              else {
                if(residualincome>3000000)
                {
                  mytax = 3000000 * 0.3;
                }
                else{
                  mytax = residualincome * 0.3;
                }

              }
            }
            tax = tax + mytax;

          }
          if (i == 7) {
            if (residualincome >3000000) {
              residualincome = residualincome - 3000000;
              // if(i==myrange)
              // {

              // }
              // else{

              // }
            
              mytax = residualincome * 0.35;
                
            }
            tax = tax + mytax;

          }

        }
        return tax;
        // if (residual < 150000) {
        //    return 0;
        // } else {
        //   this.range = 150000 - 0;
        //   let secondRange = 300000 - 150000;
        //   residual = residual - this.range; //367000
        //   if (residual > secondRange) {
        //     tax = 150000 * 0.05;
        //   } else {
        //     tax = residual * 0.05;
        //   }

        //   let thirdRange = 500000 - 300000;
        //   if (residual > secondRange) {
        //     residual = residual - secondRange;
        //     if (residual > thirdRange) {
        //       tax += thirdRange * 0.1;
        //     } else {
        //       tax += residual * 0.1;
        //     }
        //   }

        //   let fourRange = 750000 - 500000;
        //   if (residual > thirdRange) {
        //     residual = residual - thirdRange;
        //     if (residual > fourRange) {
        //       tax += fourRange * 0.15;
        //     } else {
        //       tax += residual * 0.15;
        //     }
        //   }

        //   let fiveRange = 1000000 - 750000;
        //   if (residual > fourRange) {
        //     residual = residual - fourRange;
        //     if (residual > fiveRange) {
        //       tax += fiveRange * 0.2;
        //     } else {
        //       tax += residual * 0.2;
        //     }
        //   }

        //   let sixRange = 2000000 - 1000000;
        //   if (residual > fiveRange) {
        //     residual = residual - fiveRange;
        //     if (residual > sixRange) {
        //       tax += sixRange * 0.25;
        //     } else {
        //       tax += residual * 0.25;
        //     }
        //   }

        //   let sevenRange = 5000000 - 2000000;
        //   if (residual > sixRange) {
        //     residual = residual - sixRange;
        //     if (residual > sevenRange) {
        //       tax += sevenRange * 0.3;
        //     } else {
        //       tax += residual * 0.3;
        //     }
        //   }
        // }
        // return tax


      } catch (error) {
        throw error;
      }
    },

  }
};
