<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <v-row>
        <v-btn
          icon
          router
          color="white"
          :to="{ name: 'StaffTimeAttendance' }"
          class="mt-5"
        >
          <img src="@/assets/images/back button.png" />
        </v-btn>
        <p
          style="
            font-size: 25px;
            font-family: 'Kanit', sans-serif;
            margin-left: 4px;
            margin-top: 18px;
          "
        >
          {{ $t("TimesheetDetails") }}
        </p>
      </v-row>
    </div>
    <div v-show="showsheetview === true">
      <v-card class="mt-6" style="margin-bottom: 5rem">
        <div style="margin-left: 2rem; margin-right: 2rem">
          <v-row class="pt-7" style="margin-left: 0.2%">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
              style="margin-top: -0.8rem"
            >
              <div class="ml-0">
                <p class="mt-1 txt14">
                  {{ $t("Name") }}:<span class="ml-2">{{ EName }}</span>
                </p>
                <p class="txt14">
                  {{ $t("EmployeeID") }}: <span class="ml-2">{{ EId }}</span>
                </p>
                <p class="txt14">
                  {{ $t("position") }}:
                  <span class="ml-2">{{ EPosition }}</span>
                </p>
                <p class="txt14">
                  {{ $t("department") }}:
                  <span class="ml-2">{{ EDepartment }}</span>
                </p>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="4" md="4" lg="4">
              <v-row
                justify="end"
                class="alil"
                style="margin-top: -1rem; margin-right: 1rem"
              >
                <v-radio-group v-model="radios">
                  <v-row style="font-size: 16px">
                    <v-radio
                      :value="false"
                      @click="GetTimeView(false)"
                      color="#F99D20"
                      class="mt-1 mr-3"
                      :label="$t('CalendarView')"
                    ></v-radio>
                    <v-radio
                      :value="true"
                      @click="GetTimeView(true)"
                      color="#F99D20"
                      :label="$t('TimesheetView')"
                    ></v-radio>
                  </v-row>
                </v-radio-group>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2" xl="2" lg="2">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize empID float-right fix-left"
                max-height="34"
                :disabled="permission.approve"
                @click="OpenApprovedialog()"
              >
                <img src="@/assets/images/correct.png" class="pr-2" />{{
                  $t("Approve")
                }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" xs="2" sm="2" md="2" lg="1" xl="1">
              <p class="mt-2 ml-2 pb-0">{{ $t("Month") }}:</p>
            </v-col>
            <v-col cols="12" xs="2" sm="4" md="4" lg="2" xl="2">
              <v-select
                class="mt-1"
                style="min-width: 150px"
                v-model="todaymonth"
                dense
                solo
                outlined
                :items="monthNames"
                item-text="txt"
                item-value="val"
                return-object
                v-on:change="ChangeMonth(`${todaymonth.val}`)"
              ></v-select>
            </v-col>
            <v-col class="pb-0" cols="12" xs="2" sm="3" md="2" lg="1" xl="1">
              <p class="mt-2 ml-5 pb-0">{{ $t("Year") }}:</p>
            </v-col>
            <v-col cols="12" xs="2" sm="3" md="3" lg="2" xl="2">
              <date-picker
                v-model="todayyears"
                type="year"
                color="#0FA7D8"
                class="ml-1 mr-4 mt-1 mb-1 pl-0"
                v-on:change="ChangeYear(todayyears)"
              >
                <template slot="icon-calendar">
                  <img
                    src="@/assets/images/down1@2x.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin-right: -7px;
                      padding-top: 3px;
                    "
                  />
                </template>
              </date-picker>
            </v-col>
            <v-col class="pb-0" cols="12" xs="2" sm="2" md="3" lg="2" xl="1">
              <p class="mt-2 ml-5 pb-0">{{ $t("TimeType") }}:</p>
            </v-col>
            <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
              <v-autocomplete
                v-model="FilterDay"
                :items="TimeOption"
                item-text="time"
                item-value="timeval"
                color="#F99D20"
                class="text mt-1"
                clearable
                outlined
                style="width: 200px; border-radius: 5px; margin-right: 1rem"
                dense
                v-on:change="filtertable(FilterDay)"
              >
                <v-icon
                  slot="append"
                  class="v-icon notranslate mdi mdi-chevron-down theme--light"
                />
              </v-autocomplete>
            </v-col>
          </v-row>
          <div class="mt-5 pb-8">
            <v-data-table
              :items="CinCoutListForRealThisTime"
              hide-default-footer
              hide-default-header
              :headers="headers"
              :items-per-page="itemsPerPage"
              :mobile-breakpoint="0"
            >
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th style="width: 50px">
                      <v-checkbox class="custom-checkbox" color="#F99D20" v-model="selectAll" @click="select"></v-checkbox>
                    </th>
                    <th
                      style="width: 130px; vertical-align: top; cursor: pointer"
                      @click="sortFun('timeEntry_Date')"
                    >
                      {{ $t("date") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="width: 170px; vertical-align: top; cursor: pointer"
                      @click="sortFun('shift_StartTime')"
                    >
                      {{ $t("shiftStartTime") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="vertical-align: top; cursor: pointer"
                      @click="sortFun('shft_EndTime')"
                    >
                      {{ $t("shiftEndTime") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="vertical-align: top; cursor: pointer"
                      @click="sortFun('oT_Shift_StartTime')"
                    >
                      {{ $t("otStartTime") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="vertical-align: top; cursor: pointer"
                      @click="sortFun('oT_Shift_EndTime')"
                    >
                      {{ $t("otEndTime") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="vertical-align: top; cursor: pointer"
                      @click="sortFun('clock_In_Time')"
                    >
                      {{ $t("clockIn") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="vertical-align: top; cursor: pointer"
                      @click="sortFun('clock_Out_Time')"
                    >
                      {{ $t("clockOut") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="vertical-align: top; cursor: pointer"
                      @click="sortFun('oT_Clock_In_Time')"
                    >
                      {{ $t("otClockIn") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="vertical-align: top; cursor: pointer"
                      @click="sortFun('oT_Clock_Out_Time')"
                    >
                      {{ $t("otClockOut") }}
                      <img
                        :src="images.sort"
                        style="width: 14px; height: 14px"
                        class="mt-2 ml-1"
                      />
                    </th>
                    <th
                      style="
                        vertical-align: top;
                        padding-top: 5px;
                        cursor: pointer;
                      "
                    >
                      {{ $t("Remark") }}
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr
                  :style="{
                    color: ShiftVali(item) == true ? '#46D95A' : ' #FF4B55',
                  }"
                  style="cursor: pointer;"
                >
                  <td style="text-align: center">
                    <v-checkbox class="custom-checkbox" color="#F99D20" v-model="selected"></v-checkbox>
                    <!-- <label class="form-checkbox">
                      <input
                        type="checkbox"
                        :value="{
                          tid: item.time_ID,
                          clkid: item.clocklist,
                          apv: item.approve,
                          cond: item.yescondi,
                          mcond: item.maincondi,
                          intime: item.clock_In_Time,
                          outtime: item.clock_Out_Time,
                          otintime: item.oT_Clock_In_Time,
                          otouttime: item.oT_Clock_Out_Time,
                          begin: item.shift_StartTime,
                          end: item.shift_EndTime,
                        }"
                        v-model="selected"
                      />
                      <i class="form-icon"></i>
                    </label> -->
                  </td>
                  <td @click="handleclick(item)">
                    {{ formatDate(item.timeEntry_Date) }}
                  </td>
                  <td @click="handleclick(item)">{{ item.shift_StartTime }}</td>
                  <td @click="handleclick(item)">{{ item.shft_EndTime }}</td>
                  <td @click="handleclick(item)">
                    {{ item.oT_Shift_StartTime }}
                  </td>
                  <td @click="handleclick(item)">
                    {{ item.oT_Shift_EndTime }}
                  </td>
                  <!-- <td @click="handleclick(item)">{{Converttolocal(item.clock_In_Time)}}</td> -->
                  <td @click="handleclick(item)">
                    {{ Converttolocal(item.clock_In_Time) }}
                  </td>
                  <td @click="handleclick(item)">
                    {{ Converttolocal(item.clock_Out_Time) }}
                  </td>
                  <td @click="handleclick(item)">
                    {{ Converttolocal(item.oT_Clock_In_Time) }}
                  </td>
                  <td @click="handleclick(item)">
                    {{ Converttolocal(item.oT_Clock_Out_Time) }}
                  </td>
                  <td v-show="!thethaihead" @click="handleclick(item)">{{ item.remark }}</td>
                  <td v-show="thethaihead" @click="handleclick(item)">{{ item.remarkTH }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </div>
    <div v-show="showsheetview === false">
      <div class="pt-6">
        <v-card class="pt-5" style="margin-bottom: 5rem">
          <div
            style="margin-left: 2rem; margin-right: 2rem"
            class="fixed-calendar"
          >
            <v-row>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="2"
                lg="2"
                xl="2"
                class="d-flex mt-2"
              >
                <div
                  style="
                    width: 20px;
                    height: 20px;
                    background-color: #ff4b55;
                    border-radius: 4px;
                  "
                ></div>
                <p class="ml-3" style="margin-top: -3px">
                  {{ $t("Irregular") }}
                </p>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="2"
                lg="2"
                xl="2"
                class="d-flex mt-2"
              >
                <div
                  style="
                    width: 20px;
                    height: 20px;
                    background-color: #46d95a;
                    border-radius: 4px;
                  "
                ></div>
                <p class="ml-3" style="margin-top: -3px">{{ $t("Normal") }}</p>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" xl="4" lg="4">
                <v-row style="margin-top: -1.5rem; margin-left: 1rem">
                  <v-radio-group v-model="radios">
                    <v-row style="font-size: 18px">
                      <v-radio
                        :value="false"
                        @click="GetTimeView(false)"
                        color="#F99D20"
                        class="mt-2 mr-3"
                        :label="$t('CalendarView')"
                      ></v-radio>
                      <v-radio
                        :value="true"
                        @click="GetTimeView(true)"
                        color="#F99D20"
                        :label="$t('TimesheetView')"
                      ></v-radio>
                    </v-row>
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" xl="4" lg="4">
                <v-row class="d-flex">
                  <v-card
                    style="
                      background-color: #f99d20;
                      border-radius: 5px;
                      margin-left: 12px;
                      margin-top: 5px;
                      margin-right: 12px;
                    "
                  >
                    <v-row class="d-flex">
                      <v-col>
                        <v-autocomplete
                          class="selectboxs minizebox ml-2 mt-2 mb-2 mr-2 pl-0"
                          v-model="todaymonths"
                          height="34px"
                          single-line
                          :items="monthNames1"
                          item-text="txt"
                          item-value="val"
                          background-color="#ffff"
                          return-object
                          persistent-hint
                          v-on:change="ChangeMonths(`${todaymonths.val}`)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <date-picker
                          v-model="todayyears"
                          type="year"
                          color="#0FA7D8"
                          class="selectboxs minizebox ml-2 mr-2 mt-2 mb-2 pl-0"
                          v-on:change="ChangeYears(todayyears)"
                        >
                          <template slot="icon-calendar">
                            <img
                              src="@/assets/images/down1@2x.png"
                              style="
                                width: 20px;
                                height: 20px;
                                margin-right: -7px;
                                padding-top: 3px;
                              "
                            />
                          </template>
                        </date-picker>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-5">
              <v-col>
                <v-card class style="height: 700px">
                  <v-sheet height="700px">
                    <v-calendar
                      :now="focus"
                      ref="calendar"
                      type="month"
                      :value="focus"
                      color="primary"
                      :events="events"
                    >
                      <template v-slot:event="{ event }">
                        <div class="pa-1 my-event" layout="column">
                          <div v-show="event.showview">
                            <v-chip
                              :style="{
                                background:
                                  ShiftValis(event) == true
                                    ? '#DBF1DE'
                                    : ' #deb1b1',
                                color:
                                  ShiftValis(event) == true
                                    ? '#46D95A'
                                    : ' #FF4B55',
                              }"
                              v-show="!thethaihead"
                              color="#18A32B"
                              class="chiptoggle"
                              small
                              @click="GoViewTimeSheet(event)"
                              >{{ event.remark }}</v-chip
                            >
                            <v-chip
                              :style="{
                                background:
                                  ShiftValis(event) == true
                                    ? '#DBF1DE'
                                    : ' #deb1b1',
                                color:
                                  ShiftValis(event) == true
                                    ? '#46D95A'
                                    : ' #FF4B55',
                              }"
                              v-show="thethaihead"
                              color="#18A32B"
                              class="chiptoggle"
                              small
                              @click="GoViewTimeSheet(event)"
                              >{{ event.remarkTH }}</v-chip
                            >
                          </div>
                          <div v-show="event.showholiday">
                            <v-chip
                              style="background: #daf2f9"
                              color="#0FA7D8"
                              class="blue--text chiptoggle"
                              small
                              v-show="!thethaihead"
                              @click="GoViewTimeSheet(event)"
                              >{{ event.remark }}</v-chip
                            >
                            <v-chip
                              style="background: #daf2f9"
                              color="#0FA7D8"
                              class="blue--text chiptoggle"
                              small
                              v-show="thethaihead"
                              @click="GoViewTimeSheet(event)"
                              >{{ event.remarkTH }}</v-chip
                            >
                          </div>
                          <div v-show="event.showOT">
                            <v-chip
                              :style="{
                                background:
                                  ShiftValis(event) == true
                                    ? '#DBF1DE'
                                    : ' #deb1b1',
                                color:
                                  ShiftValis(event) == true
                                    ? '#46D95A'
                                    : ' #FF4B55',
                              }"
                              v-show="!thethaihead"
                              color="#18A32B"
                              small
                              class="chiptoggle"
                              @click="GoViewTimeSheet(event)"
                              >{{ event.remark }}</v-chip
                            >
                            <v-chip
                              :style="{
                                background:
                                  ShiftValis(event) == true
                                    ? '#DBF1DE'
                                    : ' #deb1b1',
                                color:
                                  ShiftValis(event) == true
                                    ? '#46D95A'
                                    : ' #FF4B55',
                              }"
                              v-show="thethaihead"
                              color="#18A32B"
                              small
                              class="chiptoggle"
                              @click="GoViewTimeSheet(event)"
                              >{{ event.remarkTH }}</v-chip
                            >
                          </div>
                          <div v-show="event.showTimeOff">
                            <v-chip
                              style="background: #dbf1de"
                              color="#18A32B"
                              small
                              v-show="!thethaihead"
                              class="green--text chiptoggle"
                              @click="GoViewTimeSheet(event)"
                              >{{ event.remark }}</v-chip
                            >
                            <v-chip
                              style="background: #dbf1de"
                              color="#18A32B"
                              small
                              v-show="thethaihead"
                              class="green--text chiptoggle"
                              @click="GoViewTimeSheet(event)"
                              >{{ event.remarkTH }}</v-chip
                            >
                          </div>

                          <v-row
                            class="hidetxts"
                            :style="{
                              color:
                                ShiftValis(event) == true
                                  ? '#46D95A'
                                  : ' #FF4B55',
                            }"
                          >
                            <v-col style="text-align: center">
                              <p class="ml-2" style="margin-top: -30px">
                                {{ Converttolocals(event.clock_In_Time) }}
                              </p>
                              <p class="ml-2" style="cursor: pointer">
                                {{ Converttolocals(event.oT_Clock_In_Time) }}
                              </p>
                            </v-col>
                            <v-divider
                              style="margin-top: -10px; height: 40px"
                              vertical
                            ></v-divider>
                            <v-col style="text-align: center">
                              <p style="margin-top: -30px">
                                {{ Converttolocals(event.clock_Out_Time) }}
                              </p>
                              <p style="cursor: pointer">
                                {{ Converttolocals(event.oT_Clock_Out_Time) }}
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                      </template>
                    </v-calendar>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div style="height: 10vh"></div>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="Approvedialog" persistent max-width="500px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span class="pl-2"> {{ $t("ConfrimToApprove") }}</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row justify="center">
            <p class="pl-5">{{ $t("ApproveTimeAttendance") }}</p>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize mr-2"
              max-height="35"
              width="120px"
              @click="Approvedialog = false"
              text
            >
              {{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 white--text text-capitalize"
              max-height="35"
              width="120px"
              @click="ApproveTime()"
            >
              {{ $t("Confirm") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import DatePicker from "vue2-datepicker";
import CheckViewApproveSyncTimeExportEdit from "@/function/RolePermissions.js";
import "vue2-datepicker/index.css";
import LoadingDialog from "@/components/Loading.vue";

export default {
  mixins: [LogTrace, CheckViewApproveSyncTimeExportEdit],
  components: { DatePicker, LoadingDialog},
  data() {
    return {
      page: 1,
      pageCount: 15,
      // itemsPerPage: 10,
      items: [10, 15, 20],
      value3: new Date(),
      reportDateFrom: new Date(),
      map: "",
      marker: "",
      center: { lat: 45.508, lng: -73.587 },
      location: "",
      locationlat: null,
      locationlang: null,
      showlocation: "",
      showlocationlat: null,
      showlocationlang: null,
      outlocation: "",
      outlocationlat: null,
      outlocationlang: null,
      focus: new Date().toISOString().substr(0, 10),
      searchtxt: "",
      showview: false,
      showholiday: true,
      showTimeOff: true,
      showOT: true,
      TimeViewList: [],
      CinCoutList: [],
      clock: {
        clockintime: "",
        clockouttime: "",
        timeID: null,
        clockID: null,
        timeType: null,
        clockinlocate: null,
        clockoutlocate: null,
        cinlang: null,
        coutlang: null,
        cinlat: null,
        coutlat: null,
      },
      cintime: "",
      coutime: "",
      totaltime: null,

      CalCinCoutList: [],
      error: {
        errormessagetimetype: "",
      },
      timeTypeItem: ["Regular", "Overtime"],
      type: "month",
      years: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025],
      todaymonths: null,
      todayyears: null,
      events: [],
      SortingDate: "",
      MySortDateList: [],
      count: 0,
      countnext: 0,
      countcom: 0,
      countcomnext: 0,
      radios: true,
      showsheetview: true,
      permission: {
        approve: true,
        export: true,
        edit: true,
      },
      url: enurl.apiUrl,
      CinCoutListForRealThisTime: [],
      FilterDay: null,
      EName: "",
      EId: "",
      com: 0,
      itemsPerPage: 50,
      EPosition: "",
      EDepartment: "",
      LoadingDialog: false,
      todaymonth: null,
      SortedDateList: [],
      DataShowList: [],
      TimeSettingList: [],
      timeEntry_Date: null,
      shift_StartTime: null,
      shft_EndTime: null,
      oT_Shift_StartTime: null,
      oT_Shift_EndTime: null,
      clock_In_Time: null,
      clock_Out_Time: null,
      oT_Clock_In_Time: null,
      oT_Clock_Out_Time: null,
      startbefore: null,
      startbeforetime: null,
      startafter: null,
      startaftertime: null,
      endbefore: null,
      endbeforetime: null,
      endafter: null,
      endaftertime: null,
      Approvedialog: false,
      images: {
        sort: require("@/assets/images/sort@2x.png"),
        selectallimg: require("@/assets/images/selectall@2x.png"),
        minus: require("@/assets/images/Mask Group 71.png"),
      },
      headers: [
        {
          value: "timeEntry_Date",
          align: "center",
          sortable: false,
        },
        {
          value: "shift_StartTime",
          align: "left",
          sortable: false,
        },
        {
          value: "shft_EndTime",
          align: "left",
          sortable: false,
        },
        {
          value: "oT_Shift_StartTime",
          align: "left",
          sortable: false,
        },
        {
          value: "oT_Shift_EndTime",
          align: "left",
          sortable: false,
        },
        {
          value: "clock_In_Time",
          align: "left",
          sortable: false,
        },
        {
          value: "clock_Out_Time",
          align: "left",
          sortable: false,
        },
        {
          value: "oT_Clock_In_Time",
          align: "left",
          sortable: false,
        },
        {
          value: "oT_Clock_Out_Time",
          align: "left",
          sortable: false,
        },
        {
          value: "action",
          align: "left",
          sortable: false,
        },
      ],
      // monthNames: [
      //   { val: 1, txt: "   January" },
      //   { val: 2, txt: "February" },
      //   { val: 3, txt: "   March" },
      //   { val: 4, txt: "   April" },
      //   { val: 5, txt: "   May" },
      //   { val: 6, txt: "   June" },
      //   { val: 7, txt: "   July" },
      //   { val: 8, txt: "   August" },
      //   { val: 9, txt: "   September" },
      //   { val: 10, txt: "   October" },
      //   { val: 11, txt: "November" },
      //   { val: 12, txt: "   December" },
      // ],
      selected: [],
      selectAll: false,
      thelang: "",
      // TimeOption: [
      //   { timeval: 1, time: this.$t("Normal") },
      //   { timeval: 2, time: this.$t("Irregular") },
      // ],
    };
  },
  computed: {
    TimeOption() {
      return [
        { timeval: 1, time: this.$t("Normal") },
        { timeval: 2, time: this.$t("Irregular") },
      ];
    },
    monthNames() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
    monthNames1() {
      return [
        { val: 1, txt: this.$t("January") },
        { val: 2, txt: this.$t("February") },
        { val: 3, txt: this.$t("March") },
        { val: 4, txt: this.$t("April") },
        { val: 5, txt: this.$t("May") },
        { val: 6, txt: this.$t("June") },
        { val: 7, txt: this.$t("July") },
        { val: 8, txt: this.$t("August") },
        { val: 9, txt: this.$t("September") },
        { val: 10, txt: this.$t("October") },
        { val: 11, txt: this.$t("November") },
        { val: 12, txt: this.$t("December") },
      ];
    },
    thethaihead() {
      return this.whatthehead();
    },
  },
  watch: {
    selected() {
      this.hellothere();
    },
    thelang() {
      this.whatthehead()
    },
  },
  mounted() {
    let self = this;
    let d = new Date();
    self.$refs.calendar.checkChange();
    self.todaymonth = self.monthNames[d.getMonth()].val;
    self.todayyears = new Date();
    self.todaymonths = this.monthNames1[d.getMonth()].val;
    self.GetTimeAttSetting();
    // self.GetTimesheet();
    self.GetPersonInfo();
    self.GetTimeView(true);
    self.permission = self.CheckViewApproveSyncTimeExportEdit(10, 11, 12, 13);
    this.com = 1;
    this.timerInterval = setInterval(() => {
      this.thelang = localStorage.getItem('Templang')
    }, 100);
  },
  created() {
    this.events.push({
      name: "Vacation",
      start: "2019-07-08",
      end: "2019-07-08",
    });
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    GetTimeView(value) {
      let self = this;
      self.showsheetview = value;
      if (self.showsheetview === true) {
        self.GetTimesheet();
      } else {
        self.GetTimeCalendar();
      }
    },
    Converttolocals(item) {
      if (item != "-") {
        let todate = new Date(Date.parse(item));
        // let [hours, minutes] = item.split(":");
        // todate.setHours(+hours);
        // todate.setMinutes(minutes);
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        return output;
      } else {
        return "-";
      }
    },
    GetTimeCalendar() {
      let self = this;
      self.events = [];
      self.CinCoutList = [];
      self.LoadingDialog = true;
      let num = self.todaymonths;
      let yer = self.todayyears.getFullYear();
      let temp = {
        company_ID: store.state.companyID,
        employee_ID: parseInt(self.$route.params.id),
        month_Num: num,
        year_Num: yer,
      };

      axios
        .post(`${self.url}TimeEntries/GetTimeEntriesByEmployeeIDAndTime`, temp)
        .then(function (response) {
          self.CinCoutList = response.data.data;
          for (let i = 0; i < self.CinCoutList.length; i++) {
            let intime = "-";
            let outtime = "-";
            let otintime = "-";
            let otouttime = "-";
            let cinoutcount = 0;
            let otcinoutcount = 0;
            let regin = "-";
            let regout1 = "-";
            let latein = "-";
            let regout = "-";
            let otin = "-";
            let otout = "-";
            let lateotin = "-";
            let outlist = [];
            let otoutlist = [];
            let yesc = false;
            let inregular = [];
            let otinregular = [];

            if (self.CinCoutList[i].irregularRegCinoutData.length != 0) {
              latein =
                self.CinCoutList[i].irregularRegCinoutData[0].clock_In_Time;
              for (
                let c = 0;
                c < self.CinCoutList[i].irregularRegCinoutData.length;
                c++
              ) {
                outlist.unshift({
                  time: self.CinCoutList[i].irregularRegCinoutData[c]
                    .clock_Out_Time,
                });
              }
            }

            if (self.CinCoutList[i].regCinoutData.length != 0) {
              let leg = self.CinCoutList[i].regCinoutData.length - 1;
              regin = self.CinCoutList[i].regCinoutData[0].clock_In_Time;
              regout = self.CinCoutList[i].regCinoutData[leg].clock_Out_Time;
              regout1 = self.CinCoutList[i].regCinoutData[0].clock_Out_Time;
              cinoutcount = self.CinCoutList[i].regCinoutData.length;
              for (
                let a = 0;
                a < self.CinCoutList[i].regCinoutData.length;
                a++
              ) {
                outlist.unshift({
                  time: self.CinCoutList[i].regCinoutData[a].clock_Out_Time,
                });
              }
              inregular = self.CinCoutList[i].regCinoutData.filter(
                (val) => val.clock_In_Time != "-"
              );
            }

            if (self.CinCoutList[i].irregularOTCinoutData.length != 0) {
              lateotin =
                self.CinCoutList[i].irregularOTCinoutData[0].oT_Clock_In_Time;
              for (
                let d = 0;
                d < self.CinCoutList[i].irregularOTCinoutData.length;
                d++
              ) {
                otoutlist.unshift({
                  time: self.CinCoutList[i].irregularOTCinoutData[d]
                    .oT_Clock_Out_Time,
                });
              }
            }

            if (self.CinCoutList[i].otCinoutData.length != 0) {
              let leg = self.CinCoutList[i].otCinoutData.length - 1;
              otin = self.CinCoutList[i].otCinoutData[0].oT_Clock_In_Time;
              otout = self.CinCoutList[i].otCinoutData[leg].oT_Clock_Out_Time;
              otcinoutcount = self.CinCoutList[i].otCinoutData.length;
              for (
                let b = 0;
                b < self.CinCoutList[i].otCinoutData.length;
                b++
              ) {
                otoutlist.unshift({
                  time: self.CinCoutList[i].otCinoutData[b].oT_Clock_Out_Time,
                });
              }
              otinregular = self.CinCoutList[i].otCinoutData.filter(
                (val) => val.oT_Clock_In_Time != "-"
              );
            }

            if (regin != "-" && regin != null) {
              intime = regin;
            } else if (latein != "-" && latein != null) {
              intime = latein;
            }

            for (let e = 0; e < outlist.length; e++) {
              if (outlist[e].time != "-") {
                outtime = outlist[e].time;
                break;
              }
            }

            if (otin != "-" && otin != null) {
              otintime = otin;
            } else if (lateotin != "-" && lateotin != null) {
              otintime = lateotin;
            }

            for (let f = 0; f < otoutlist.length; f++) {
              if (otoutlist[f].time != "-") {
                otouttime = otoutlist[f].time;
                break;
              }
            }

            let maincondi = false;
            if (inregular.length == 0 && otinregular.length == 0) {
              maincondi = true;
            }

            if (
              (regin != "-" &&
                regin != null &&
                ((regout != "-" && regout != null) ||
                  (regout1 != "-" && regout1 != null))) ||
              (otin != "-" && otin != null && otout != "-" && otout != null)
            ) {
              yesc = true;
            }

            if (
              self.CinCoutList[i].oT_Shift_StartTime == null ||
              self.CinCoutList[i].oT_Shift_StartTime == ""
            ) {
              self.CinCoutList[i].oT_Shift_StartTime = "-";
            }
            if (
              self.CinCoutList[i].oT_Shift_EndTime == null ||
              self.CinCoutList[i].oT_Shift_EndTime == ""
            ) {
              self.CinCoutList[i].oT_Shift_EndTime = "-";
            }
            self.events.push({
              name: "Vacation",
              start: self.formatDate1(self.CinCoutList[i].timeEntry_Date),
              end: self.formatDate1(self.CinCoutList[i].timeEntry_Date),
              regCinoutData: self.CinCoutList[i].regCinoutData,
              otCinoutData: self.CinCoutList[i].otCinoutData,
              clock_In_Time: intime,
              clock_Out_Time: outtime,
              oT_Clock_In_Time: otintime,
              oT_Clock_Out_Time: otouttime,
              regcount: cinoutcount,
              otcount: otcinoutcount,
              yescondi: yesc,
              maincondi: maincondi,
              shift_StartTime: self.CinCoutList[i].shift_StartTime,
              shft_EndTime: self.CinCoutList[i].shft_EndTime,
              oT_Shift_StartTime: self.CinCoutList[i].oT_Shift_StartTime,
              oT_Shift_EndTime: self.CinCoutList[i].oT_Shift_EndTime,
              tO_StartTime: self.CinCoutList[i].tO_StartTime,
              tO_EndTime: self.CinCoutList[i].tO_EndTime,
              timeEntry_Date: self.CinCoutList[i].timeEntry_Date,
              time_ID: self.CinCoutList[i].time_ID,
              clock_ID: self.CinCoutList[i].clock_ID,
              time_Type: self.CinCoutList[i].time_Type,
              approve: self.CinCoutList[i].approve,
              remark: self.CinCoutList[i].remark,
              remarkTH: self.CinCoutList[i].remarkTH,
              company_ID: self.CinCoutList[i].company_ID,
              showview: false,
              showTimeOff: false,
              showOT: false,
              showholiday: false,
            });
          }
          for (let k = 0; k < self.events.length; k++) {
            if (self.events[k].remark == "View") {
              self.events[k].showview = true;
              self.events[k].showTimeOff = false;
              self.events[k].showOT = false;
              self.events[k].showholiday = false;
            } else if (self.events[k].remark == "Holiday") {
              self.events[k].showview = false;
              self.events[k].showTimeOff = false;
              self.events[k].showOT = false;
              self.events[k].showholiday = true;
            } else if (self.events[k].remark == "Over Time") {
              self.events[k].showview = false;
              self.events[k].showTimeOff = false;
              self.events[k].showOT = true;
              self.events[k].showholiday = false;
            } else {
              self.events[k].showview = false;
              self.events[k].showTimeOff = true;
              self.events[k].showOT = false;
              self.events[k].showholiday = false;
            }
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Timesheet Fail", 23, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    ChangeMonths(mth) {
      let self = this;
      let d = new Date();
      d.setMonth(mth - 1);
      let year = self.todayyears.getFullYear();
      d.setFullYear(year);
      let mm = parseInt(mth);
      self.todaymonths = mm;
      self.focus = d;
      self.events = [];
      self.GetTimeCalendar();
    },
    ChangeYears(year) {
      let self = this;
      let d = new Date();
      d.setMonth(self.todaymonths - 1);
      let y = year.getFullYear();
      d.setFullYear(y);
      self.focus = d;
      self.events = [];
      self.GetTimeCalendar();
    },
    hellothere() {
      if (
        this.selected.length == this.CinCoutListForRealThisTime.length &&
        this.com == 0
      ) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
      this.com = 0;
    },
    ShiftValis(item) {
      let self = this;
      if (item.maincondi == false || item.maincondi == "false") {
        if (item.yescondi == "true" || item.yescondi == true) {
          return true;
        } else {
          if (item.shift_StartTime != "-" && item.oT_Shift_StartTime == "-") {
            let from = new Date(Date.parse(item.clock_In_Time));
            let to = new Date(Date.parse(item.clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.shft_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }
            return sslatetime;
          } else if (
            item.shift_StartTime == "-" &&
            item.oT_Shift_StartTime != "-"
          ) {
            let from = new Date(Date.parse(item.oT_Clock_In_Time));
            let to = new Date(Date.parse(item.oT_Clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.oT_Shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.oT_Shift_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }
            return sslatetime;
          } else if (
            item.shift_StartTime != "-" &&
            item.oT_Shift_StartTime != "-"
          ) {
            let from = new Date(Date.parse(item.clock_In_Time));
            let to = new Date(Date.parse(item.clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.shft_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }

            let otfrom = new Date(Date.parse(item.oT_Clock_In_Time));
            let otto = new Date(Date.parse(item.oT_Clock_Out_Time));

            //ot in time
            let otshiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let otshiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [otsshours, otssminutes] = item.oT_Shift_StartTime.split(":");
            otshiftstartbefore.setHours(+otsshours);
            otshiftstartbefore.setMinutes(otssminutes);
            otshiftstartbefore.setSeconds("0");
            otshiftstartbefore.setMilliseconds("0");
            otshiftstartafter.setHours(+otsshours);
            otshiftstartafter.setMinutes(otssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                otshiftstartbefore.getHours() - parseInt(self.startbefore);
              otshiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                otshiftstartbefore.getMinutes() - parseInt(self.startbefore);
              otshiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds =
                otshiftstartafter.getHours() + parseInt(self.startafter);
              otshiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                otshiftstartafter.getMinutes() + parseInt(self.startafter);
              otshiftstartafter.setMinutes(adds);
            }
            //ot out time
            let otshiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let otshiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [otsehours, otseminutes] = item.oT_Shift_EndTime.split(":");
            otshiftendbefore.setHours(+otsehours);
            otshiftendbefore.setMinutes(otseminutes);
            otshiftendbefore.setSeconds("0");
            otshiftendbefore.setMilliseconds("0");
            otshiftendafter.setHours(+otsehours);
            otshiftendafter.setMinutes(otseminutes);
            if (self.endbeforetime == "hour") {
              let adds = otshiftendbefore.getHours() - parseInt(self.endbefore);
              otshiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds =
                otshiftendbefore.getMinutes() - parseInt(self.endbefore);
              otshiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = otshiftendafter.getHours() + parseInt(self.endafter);
              otshiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = otshiftendafter.getMinutes() + parseInt(self.endafter);
              otshiftendafter.setMinutes(adds);
            }

            let otfrtimezone = otfrom.getTimezoneOffset() * 60000;
            let otfrnewDate = new Date(otfrom.getTime() - otfrtimezone);
            let ottotimezome = otto.getTimezoneOffset() * 60000;
            let ottonewDate = new Date(otto.getTime() - ottotimezome);
            let otssnewDate = new Date(otshiftstartafter.getTime());
            let otssbnewDate = new Date(otshiftstartbefore.getTime());
            let otsenewDate = new Date(otshiftendafter.getTime());
            let otsebnewDate = new Date(otshiftendbefore.getTime());
            let otsslatetime = true;
            if (item.approve != true) {
              if (item.oT_Clock_In_Time != "-") {
                if (otfrnewDate > otssnewDate) {
                  otsslatetime = false;
                } else if (otssbnewDate > otfrnewDate) {
                  otsslatetime = false;
                } else {
                  otsslatetime = true;
                }
                if (item.oT_Clock_Out_Time != "-" && otsslatetime == true) {
                  if (ottonewDate > otsenewDate) {
                    otsslatetime = false;
                  } else if (otsebnewDate > ottonewDate) {
                    otsslatetime = false;
                  } else {
                    otsslatetime = true;
                  }
                }
              }
            } else {
              otsslatetime = true;
            }
            if (sslatetime == true && otsslatetime == true) {
              return true;
            } else if (
              item.clock_In_Time != "-" &&
              item.clock_Out_Time != "-" &&
              item.oT_Clock_In_Time == "-" &&
              item.oT_Clock_Out_Time == "-"
            ) {
              if (sslatetime == true) {
                return true;
              } else {
                return false;
              }
            } else if (
              item.clock_In_Time == "-" &&
              item.clock_Out_Time == "-" &&
              item.oT_Clock_In_Time != "-" &&
              item.oT_Clock_Out_Time != "-"
            ) {
              if (otsslatetime == true) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } else {
        if (
          item.clock_In_Time == "-" &&
          item.clock_Out_Time == "-" &&
          item.oT_Clock_In_Time == "-" &&
          item.oT_Clock_Out_Time == "-"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    GoViewTimeSheet(item) {
      let tgetdate = "";
      let tempdate = new Date(Date.parse(item.timeEntry_Date));
      let day = tempdate.getDate();
      let month = tempdate.getMonth() + 1;
      let year = tempdate.getFullYear();
      tgetdate = `${year}-${month}-${day}`;
      localStorage.setItem("get_date_of_today", JSON.stringify(tgetdate));
      localStorage.setItem("shift_start", JSON.stringify(item.shift_StartTime));
      localStorage.setItem("shift_end", JSON.stringify(item.shft_EndTime));
      localStorage.setItem(
        "otshift_start",
        JSON.stringify(item.oT_Shift_StartTime)
      );
      localStorage.setItem(
        "otshift_end",
        JSON.stringify(item.oT_Shift_EndTime)
      );
      localStorage.setItem("toshift_start", JSON.stringify(item.tO_StartTime));
      localStorage.setItem("toshift_end", JSON.stringify(item.tO_EndTime));
      localStorage.setItem(
        "timoffcon",
        JSON.stringify(item.time_Off_Whole_Day)
      );
      localStorage.setItem("local_remark", JSON.stringify(item.remark));
      this.$router.push({
        name: "Timesheetdetail1",
        params: { id: this.$route.params.id },
      }).catch(()=>{});
    },
    filtertable(val) {
      let that = this;
      that.CinCoutListForRealThisTime = [];
      if (val == 1) {
        for (let i = 0; i < that.CinCoutList.length; i++) {
          let youdontfindthis = that.ShiftVali(that.CinCoutList[i]);
          if (youdontfindthis == true) {
            that.CinCoutListForRealThisTime.push(that.CinCoutList[i]);
          }
        }
      } else if (val == 2) {
        for (let i = 0; i < that.CinCoutList.length; i++) {
          let youdontfindthis = that.ShiftVali(that.CinCoutList[i]);
          if (youdontfindthis == false) {
            that.CinCoutListForRealThisTime.push(that.CinCoutList[i]);
          }
        }
      }
    },
    GetTimesheet() {
      let self = this;
      self.CinCoutList = [];
      self.CinCoutListForRealThisTime = [];
      self.LoadingDialog = true;
      let yer = self.todayyears.getFullYear();
      let temp = {
        company_ID: store.state.companyID,
        employee_ID: parseInt(self.$route.params.id),
        month_Num: self.todaymonth,
        year_Num: yer,
      };

      axios
        .post(`${self.url}TimeEntries/GetTimeEntriesByEmployeeIDAndTime`, temp)
        .then(function (response) {
          let templist = response.data.data;
          for (let i = 0; i < templist.length; i++) {
            let intime = "-";
            let outtime = "-";
            let otintime = "-";
            let otouttime = "-";
            let cinoutcount = 0;
            let otcinoutcount = 0;
            let regin = "-";
            let latein = "-";
            let regout = "-";
            let regout1 = "-";
            let otin = "-";
            let otout = "-";
            let lateotin = "-";
            let yesc = false;
            let outlist = [];
            let otoutlist = [];
            let inregular = [];
            let otinregular = [];
            let clist = [];

            if (templist[i].irregularRegCinoutData.length != 0) {
              latein = templist[i].irregularRegCinoutData[0].clock_In_Time;
              for (
                let c = 0;
                c < templist[i].irregularRegCinoutData.length;
                c++
              ) {
                outlist.unshift({
                  time: templist[i].irregularRegCinoutData[c].clock_Out_Time,
                });
              }
            }

            if (templist[i].regCinoutData.length != 0) {
              let leg = templist[i].regCinoutData.length - 1;
              regin = templist[i].regCinoutData[0].clock_In_Time;
              regout = templist[i].regCinoutData[leg].clock_Out_Time;
              regout1 = templist[i].regCinoutData[0].clock_Out_Time;
              cinoutcount = templist[i].regCinoutData.length;
              for (let a = 0; a < templist[i].regCinoutData.length; a++) {
                outlist.unshift({
                  time: templist[i].regCinoutData[a].clock_Out_Time,
                });
                clist.push({
                  clock_ID: templist[i].regCinoutData[a].clock_ID,
                });
              }
              inregular = templist[i].regCinoutData.filter(
                (val) => val.clock_In_Time != "-"
              );
            }

            if (templist[i].irregularOTCinoutData.length != 0) {
              lateotin = templist[i].irregularOTCinoutData[0].oT_Clock_In_Time;
              for (
                let d = 0;
                d < templist[i].irregularOTCinoutData.length;
                d++
              ) {
                otoutlist.unshift({
                  time: templist[i].irregularOTCinoutData[d].oT_Clock_Out_Time,
                });
              }
            }

            if (templist[i].otCinoutData.length != 0) {
              let leg = templist[i].otCinoutData.length - 1;
              otin = templist[i].otCinoutData[0].oT_Clock_In_Time;
              otout = templist[i].otCinoutData[leg].oT_Clock_Out_Time;
              otcinoutcount = templist[i].otCinoutData.length;
              for (let b = 0; b < templist[i].otCinoutData.length; b++) {
                otoutlist.unshift({
                  time: templist[i].otCinoutData[b].oT_Clock_Out_Time,
                });
                clist.push({
                  clock_ID: templist[i].otCinoutData[b].clock_ID,
                });
              }
              otinregular = templist[i].otCinoutData.filter(
                (val) => val.oT_Clock_In_Time != "-"
              );
            }

            if (regin != "-" && regin != null) {
              intime = regin;
            } else if (latein != "-" && latein != null) {
              intime = latein;
            }

            for (let e = 0; e < outlist.length; e++) {
              if (outlist[e].time != "-") {
                outtime = outlist[e].time;
                break;
              }
            }

            if (otin != "-" && otin != null) {
              otintime = otin;
            } else if (lateotin != "-" && lateotin != null) {
              otintime = lateotin;
            }

            for (let f = 0; f < otoutlist.length; f++) {
              if (otoutlist[f].time != "-") {
                otouttime = otoutlist[f].time;
                break;
              }
            }
            let maincondi = false;
            if (inregular.length == 0 && otinregular.length == 0) {
              maincondi = true;
            }
            if (
              (regin != "-" &&
                regin != null &&
                ((regout != "-" && regout != null) ||
                  (regout1 != "-" && regout1 != null))) ||
              (otin != "-" && otin != null && otout != "-" && otout != null)
            ) {
              yesc = true;
            }
            if (
              templist[i].oT_Shift_StartTime == null ||
              templist[i].oT_Shift_StartTime == ""
            ) {
              templist[i].oT_Shift_StartTime = "-";
            }
            if (
              templist[i].oT_Shift_EndTime == null ||
              templist[i].oT_Shift_EndTime == ""
            ) {
              templist[i].oT_Shift_EndTime = "-";
            }
            let Lists = {
              ...templist[i],
              clock_In_Time: intime,
              clock_Out_Time: outtime,
              oT_Clock_In_Time: otintime,
              oT_Clock_Out_Time: otouttime,
              regcount: cinoutcount,
              otcount: otcinoutcount,
              clocklist: clist,
              yescondi: yesc,
              maincondi: maincondi,
            };
            self.CinCoutList.push(Lists);
            self.CinCoutListForRealThisTime.push(Lists);
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Timesheet Fail", 23, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    GetPersonInfo() {
      let self = this;
      let temp = {
        employee_ID: parseInt(self.$route.params.id),
      };

      axios
        .post(`${self.url}PersonalInfo/GetPersonalInformation`, temp)
        .then(function (response) {
          self.EName = response.data.data.fullName;
          self.EId = response.data.data.employee_Code;
          self.EPosition = response.data.data.position_Name;
          self.EDepartment = response.data.data.department_Name;
        });
    },
    GetTimeAttSetting() {
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(
          `${self.url}TimeAttendanceSetting/GetTimeAttendanceSettingByCompanyID`,
          temp
        )
        .then(function (response) {
          self.TimeSettingList = response.data.data;
          let [stbefore, stbeforetime] =
            self.TimeSettingList[0].start_Time_Before.split(" ");
          let [stafter, staftertime] =
            self.TimeSettingList[0].start_Time_After.split(" ");
          self.startbefore = stbefore;
          self.startbeforetime = stbeforetime;
          self.startafter = stafter;
          self.startaftertime = staftertime;

          let [edbefore, edbeforetime] =
            self.TimeSettingList[0].end_Time_Before.split(" ");
          let [edafter, edaftertime] =
            self.TimeSettingList[0].end_Time_After.split(" ");
          self.endbefore = edbefore;
          self.endbeforetime = edbeforetime;
          self.endafter = edafter;
          self.endaftertime = edaftertime;
        });
    },
    ChangeMonth(mth) {
      let self = this;
      let today = new Date();
      let mon = today.getMonth() + 1;
      if (mth > mon) {
        alert("This month doesn't have data yet!");
        let d = new Date();
        self.todaymonth = self.monthNames[d.getMonth()].val;
      } else {
        self.CinCoutList = [];
        self.CinCoutListForRealThisTime = [];
        self.todaymonth = parseInt(mth);
        self.FilterDay = null;
        self.GetTimesheet();
      }
    },
    ChangeYear(year) {
      let self = this;
      let d = new Date();
      let ty = d.getFullYear();
      let y = year.getFullYear();
      if (y > ty) {
        alert("This year doesn't have data yet!");
        self.todayyears = new Date();
      } else {
        self.CinCoutListForRealThisTime = [];
        self.CinCoutList = [];
        self.FilterDay = null;
        self.GetTimesheet();
      }
    },
    sortFun(rowName) {
      let self = this;

      let keyName = [
        "timeEntry_Date",
        "shift_StartTime",
        "shft_EndTime",
        "oT_Shift_StartTime",
        "oT_Shift_EndTime",
        "clock_In_Time",
        "clock_Out_Time",
        "oT_Clock_In_Time",
        "oT_Clock_Out_Time",
      ];
      if (
        rowName == "timeEntry_Date" ||
        rowName == "shift_StartTime" ||
        rowName == "shft_EndTime" ||
        rowName == "oT_Shift_StartTime" ||
        rowName == "oT_Shift_EndTime" ||
        rowName == "clock_In_Time" ||
        rowName == "clock_Out_Time" ||
        rowName == "oT_Clock_In_Time" ||
        rowName == "oT_Clock_Out_Time"
      ) {
        self.DataShowList = self.DataShowList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.DataShowList = self.DataShowList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.timeEntry_Date = null;
      this.shift_StartTime = null;
      this.shft_EndTime = null;
      this.oT_Shift_StartTime = null;
      this.oT_Shift_EndTime = null;
      this.clock_In_Time = null;
      this.clock_Out_Time = null;
      this.oT_Clock_In_Time = null;
      this.oT_Clock_Out_Time = null;
    },
    select() {
      this.selected = [];
      //this.selectAll = !this.selectAll;
      if (!this.selectAll) {
        for (let i = 0; i < this.CinCoutListForRealThisTime.length; i++) {
          this.selected.push({
            tid: this.CinCoutListForRealThisTime[i].time_ID,
            clkid: this.CinCoutListForRealThisTime[i].clocklist,
            apv: this.CinCoutListForRealThisTime[i].approve,
            cond: this.CinCoutListForRealThisTime[i].yescondi,
            mcond: this.CinCoutListForRealThisTime[i].maincondi,
            intime: this.CinCoutListForRealThisTime[i].clock_In_Time,
            outtime: this.CinCoutListForRealThisTime[i].clock_Out_Time,
            otintime: this.CinCoutListForRealThisTime[i].oT_Clock_In_Time,
            otouttime: this.CinCoutListForRealThisTime[i].oT_Clock_Out_Time,
            begin: this.CinCoutListForRealThisTime[i].shift_StartTime,
            end: this.CinCoutListForRealThisTime[i].shift_EndTime,
          });
        }
      }
    },
    tearraylist() {
      let self = this;
      let temparr = [
        {
          employee_ID: null,
          clockidlist: null,
          user_ID: null,
          approver_ID: null,
          time_ID: null,
          shift_StartTime: null,
          shift_EndTime: null,
        },
      ];
      for (let i = 0; i < self.selected.length; i++) {
        if (i == 0) {
          temparr[i].employee_ID = parseInt(self.$route.params.id);
          temparr[i].clockidlist = self.selected[i].clkid;
          temparr[i].user_ID = store.state.userid;
          temparr[i].approver_ID = store.state.employeeID;
          temparr[i].time_ID = self.selected[i].tid;
          temparr[i].shift_StartTime = self.selected[i].begin;
          temparr[i].shift_EndTime = self.selected[i].end;
        } else {
          temparr.push({
            employee_ID: parseInt(self.$route.params.id),
            clockidlist: self.selected[i].clkid,
            user_ID: store.state.userid,
            approver_ID: store.state.employeeID,
            time_ID: self.selected[i].tid,
            shift_StartTime: self.selected[i].begin,
            shift_EndTime: self.selected[i].end,
          });
        }
      }
      return temparr;
    },
    OpenApprovedialog() {
      if (this.selected.length == 0) {
        alert("Please select time attendance!");
      } else {
        this.Approvedialog = true;
      }
    },
    ApproveTime() {
      let self = this;
      let con = [];
      self.LoadingDialog = true;
      for (let i = 0; i < self.selected.length; i++) {
        if (self.selected[i].apv == true) {
          con.push(false);
        } else if (
          self.selected[i].cond == "true" ||
          self.selected[i].cond == true
        ) {
          con.push(true);
        } else if (
          self.selected[i].mcond == "true" ||
          self.selected[i].mcond == true
        ) {
          if (
            self.selected[i].intime == "-" &&
            self.selected[i].outtime == "-" &&
            self.selected[i].otintime == "-" &&
            self.selected[i].otouttime == "-"
          ) {
            con.push(true);
          } else if (
            self.selected[i].intime != "-" &&
            self.selected[i].outtime != "-" &&
            self.selected[i].otintime == "-" &&
            self.selected[i].otouttime == "-"
          ) {
            con.push(true);
          } else if (
            self.selected[i].intime == "-" &&
            self.selected[i].outtime == "-" &&
            self.selected[i].otintime != "-" &&
            self.selected[i].otouttime != "-"
          ) {
            con.push(true);
          } else {
            con.push(false);
          }
        } else {
          con.push(false);
        }
      }
      if (con.some((e) => e == false)) {
        alert("Please check again, there are some irregular time attendance");
        self.LoadingDialog = false;
      } else {
        let tempdata = self.tearraylist();
        axios
          .post(
            `${self.url}Stafftimeattendance/UpdateMutipleStaffTimeAttendance`,
            tempdata
          )
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              alert("Approved Successfully!");
              self.Approvedialog = false;
              self.CinCoutList = [];
              self.CinCoutListForRealThisTime = [];
              self.selected = [];
              self.GetTimesheet();
            }
          });
      }
    },
    ShiftVali(item) {
      let self = this;
      if (item.maincondi == false || item.maincondi == "false") {
        if (item.yescondi == "true" || item.yescondi == true) {
          return true;
        } else {
          if (item.shift_StartTime != "-" && item.oT_Shift_StartTime == "-") {
            let from = new Date(Date.parse(item.clock_In_Time));
            let to = new Date(Date.parse(item.clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.shft_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }
            return sslatetime;
          } else if (
            item.shift_StartTime == "-" &&
            item.oT_Shift_StartTime != "-"
          ) {
            let from = new Date(Date.parse(item.oT_Clock_In_Time));
            let to = new Date(Date.parse(item.oT_Clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.oT_Shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.oT_Shift_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }
            return sslatetime;
          } else if (
            item.shift_StartTime != "-" &&
            item.oT_Shift_StartTime != "-"
          ) {
            let from = new Date(Date.parse(item.clock_In_Time));
            let to = new Date(Date.parse(item.clock_Out_Time));

            //in time
            let shiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [sshours, ssminutes] = item.shift_StartTime.split(":");
            shiftstartbefore.setHours(+sshours);
            shiftstartbefore.setMinutes(ssminutes);
            shiftstartbefore.setSeconds("0");
            shiftstartbefore.setMilliseconds("0");
            shiftstartafter.setHours(+sshours);
            shiftstartafter.setMinutes(ssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                shiftstartbefore.getHours() - parseInt(self.startbefore);
              shiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                shiftstartbefore.getMinutes() - parseInt(self.startbefore);
              shiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds = shiftstartafter.getHours() + parseInt(self.startafter);
              shiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                shiftstartafter.getMinutes() + parseInt(self.startafter);
              shiftstartafter.setMinutes(adds);
            }
            //out time
            let shiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let shiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [sehours, seminutes] = item.shft_EndTime.split(":");
            shiftendbefore.setHours(+sehours);
            shiftendbefore.setMinutes(seminutes);
            shiftendbefore.setSeconds("0");
            shiftendbefore.setMilliseconds("0");
            shiftendafter.setHours(+sehours);
            shiftendafter.setMinutes(seminutes);
            if (self.endbeforetime == "hour") {
              let adds = shiftendbefore.getHours() - parseInt(self.endbefore);
              shiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds = shiftendbefore.getMinutes() - parseInt(self.endbefore);
              shiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = shiftendafter.getHours() + parseInt(self.endafter);
              shiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = shiftendafter.getMinutes() + parseInt(self.endafter);
              shiftendafter.setMinutes(adds);
            }

            let frtimezone = from.getTimezoneOffset() * 60000;
            let frnewDate = new Date(from.getTime() - frtimezone);
            let totimezome = to.getTimezoneOffset() * 60000;
            let tonewDate = new Date(to.getTime() - totimezome);
            let ssnewDate = new Date(shiftstartafter.getTime());
            let ssbnewDate = new Date(shiftstartbefore.getTime());
            let senewDate = new Date(shiftendafter.getTime());
            let sebnewDate = new Date(shiftendbefore.getTime());
            let sslatetime = true;
            if (item.approve != true) {
              if (item.clock_In_Time != "-") {
                if (frnewDate > ssnewDate) {
                  sslatetime = false;
                } else if (ssbnewDate > frnewDate) {
                  sslatetime = false;
                } else {
                  sslatetime = true;
                }
                if (item.clock_Out_Time != "-" && sslatetime == true) {
                  if (tonewDate > senewDate) {
                    sslatetime = false;
                  } else if (sebnewDate > tonewDate) {
                    sslatetime = false;
                  } else {
                    sslatetime = true;
                  }
                }
              }
            } else {
              sslatetime = true;
            }

            let otfrom = new Date(Date.parse(item.oT_Clock_In_Time));
            let otto = new Date(Date.parse(item.oT_Clock_Out_Time));

            //ot in time
            let otshiftstartbefore = new Date(Date.parse(item.timeEntry_Date));
            let otshiftstartafter = new Date(Date.parse(item.timeEntry_Date));
            let [otsshours, otssminutes] = item.oT_Shift_StartTime.split(":");
            otshiftstartbefore.setHours(+otsshours);
            otshiftstartbefore.setMinutes(otssminutes);
            otshiftstartbefore.setSeconds("0");
            otshiftstartbefore.setMilliseconds("0");
            otshiftstartafter.setHours(+otsshours);
            otshiftstartafter.setMinutes(otssminutes);
            if (self.startbeforetime == "hour") {
              let adds =
                otshiftstartbefore.getHours() - parseInt(self.startbefore);
              otshiftstartbefore.setHours(+adds);
            } else if (self.startbeforetime == "min") {
              let adds =
                otshiftstartbefore.getMinutes() - parseInt(self.startbefore);
              otshiftstartbefore.setMinutes(adds);
            }
            if (self.startaftertime == "hour") {
              let adds =
                otshiftstartafter.getHours() + parseInt(self.startafter);
              otshiftstartafter.setHours(+adds);
            } else if (self.startaftertime == "min") {
              let adds =
                otshiftstartafter.getMinutes() + parseInt(self.startafter);
              otshiftstartafter.setMinutes(adds);
            }
            //ot out time
            let otshiftendbefore = new Date(Date.parse(item.timeEntry_Date));
            let otshiftendafter = new Date(Date.parse(item.timeEntry_Date));
            let [otsehours, otseminutes] = item.oT_Shift_EndTime.split(":");
            otshiftendbefore.setHours(+otsehours);
            otshiftendbefore.setMinutes(otseminutes);
            otshiftendbefore.setSeconds("0");
            otshiftendbefore.setMilliseconds("0");
            otshiftendafter.setHours(+otsehours);
            otshiftendafter.setMinutes(otseminutes);
            if (self.endbeforetime == "hour") {
              let adds = otshiftendbefore.getHours() - parseInt(self.endbefore);
              otshiftendbefore.setHours(+adds);
            } else if (self.endbeforetime == "min") {
              let adds =
                otshiftendbefore.getMinutes() - parseInt(self.endbefore);
              otshiftendbefore.setMinutes(adds);
            }
            if (self.endaftertime == "hour") {
              let adds = otshiftendafter.getHours() + parseInt(self.endafter);
              otshiftendafter.setHours(+adds);
            } else if (self.endaftertime == "min") {
              let adds = otshiftendafter.getMinutes() + parseInt(self.endafter);
              otshiftendafter.setMinutes(adds);
            }

            let otfrtimezone = otfrom.getTimezoneOffset() * 60000;
            let otfrnewDate = new Date(otfrom.getTime() - otfrtimezone);
            let ottotimezome = otto.getTimezoneOffset() * 60000;
            let ottonewDate = new Date(otto.getTime() - ottotimezome);
            let otssnewDate = new Date(otshiftstartafter.getTime());
            let otssbnewDate = new Date(otshiftstartbefore.getTime());
            let otsenewDate = new Date(otshiftendafter.getTime());
            let otsebnewDate = new Date(otshiftendbefore.getTime());
            let otsslatetime = true;
            if (item.approve != true) {
              if (item.oT_Clock_In_Time != "-") {
                if (otfrnewDate > otssnewDate) {
                  otsslatetime = false;
                } else if (otssbnewDate > otfrnewDate) {
                  otsslatetime = false;
                } else {
                  otsslatetime = true;
                }
                if (item.oT_Clock_Out_Time != "-" && otsslatetime == true) {
                  if (ottonewDate > otsenewDate) {
                    otsslatetime = false;
                  } else if (otsebnewDate > ottonewDate) {
                    otsslatetime = false;
                  } else {
                    otsslatetime = true;
                  }
                }
              }
            } else {
              otsslatetime = true;
            }
            if (sslatetime == true && otsslatetime == true) {
              return true;
            } else if (
              item.clock_In_Time != "-" &&
              item.clock_Out_Time != "-" &&
              item.oT_Clock_In_Time == "-" &&
              item.oT_Clock_Out_Time == "-"
            ) {
              if (sslatetime == true) {
                return true;
              } else {
                return false;
              }
            } else if (
              item.clock_In_Time == "-" &&
              item.clock_Out_Time == "-" &&
              item.oT_Clock_In_Time != "-" &&
              item.oT_Clock_Out_Time != "-"
            ) {
              if (otsslatetime == true) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } else {
        if (
          item.clock_In_Time == "-" &&
          item.clock_Out_Time == "-" &&
          item.oT_Clock_In_Time == "-" &&
          item.oT_Clock_Out_Time == "-"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    handleclick(item) {
      let tgetdate = "";
      let tempdate = new Date(Date.parse(item.timeEntry_Date));
      let day = tempdate.getDate();
      let month = tempdate.getMonth() + 1;
      let year = tempdate.getFullYear();
      tgetdate = `${year}-${month}-${day}`;
      localStorage.setItem("get_date_of_today", JSON.stringify(tgetdate));
      localStorage.setItem("shift_start", JSON.stringify(item.shift_StartTime));
      localStorage.setItem("shift_end", JSON.stringify(item.shft_EndTime));
      localStorage.setItem(
        "otshift_start",
        JSON.stringify(item.oT_Shift_StartTime)
      );
      localStorage.setItem(
        "otshift_end",
        JSON.stringify(item.oT_Shift_EndTime)
      );
      localStorage.setItem("toshift_start", JSON.stringify(item.tO_StartTime));
      localStorage.setItem("toshift_end", JSON.stringify(item.tO_EndTime));
      localStorage.setItem(
        "timoffcon",
        JSON.stringify(item.time_Off_Whole_Day)
      );
      localStorage.setItem("local_remark", JSON.stringify(item.remark));
      this.$router.push({
        name: "Timesheetdetail1",
        params: { id: this.$route.params.id },
      }).catch(()=>{});
    },
    Converttolocal(item) {
      if (item != "-") {
        let todate = new Date(Date.parse(item));
        let timezone = todate.getTimezoneOffset() * 60000;
        let newDate = new Date(todate.getTime() - timezone);
        let output = "";
        let inhours = newDate.getHours();
        let inmins = newDate.getMinutes();
        if (inhours < 10) {
          if (inmins < 10) {
            output =
              "0" + newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = "0" + newDate.getHours() + ":" + newDate.getMinutes();
          }
        } else {
          if (inmins < 10) {
            output = newDate.getHours() + ":" + "0" + newDate.getMinutes();
          } else {
            output = newDate.getHours() + ":" + newDate.getMinutes();
          }
        }
        return output;
      } else {
        return "-";
      }
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    formatDate1(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[0] + "-" + finaldate[1] + "-" + finaldate[2];
    },
  },
};
</script>
<style scoped>
>>> .mx-input-wrapper:hover .mx-icon-clear {
  display: none;
}

>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
}
>>> .mx-input {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 2px 2px rgb(0 0 0 / 8%);
  box-shadow: 0px 0px 0px 2px rgb(0 0 0 / 8%);
}
>>> .mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
@media (max-width: 1904px) {
  .fix-left {
    float: left !important;
  }
}
.mr-8 {
  height: 45px !important;
}
@media only screen and (max-width: 375px) {
  .mr-8 {
    height: 90px !important;
  }
}
>>> .v-calendar-weekly__head-weekday {
  color: black !important;
}
@media only screen and (max-width: 414px) {
  .table_data {
    margin-top: 1rem !important;
  }
}
@media only screen and (max-width: 375px) {
  .today_years {
    width: 306px !important;
    margin-left: 1rem !important;
  }
}
@media only screen and (max-width: 320px) {
  .today_years {
    width: 252px !important;
    margin-left: 1rem !important;
  }
}
@media only screen and (max-width: 280px) {
  .today_years {
    width: 210px !important;
    margin-left: 1rem !important;
  }
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
>>> .selectboxs {
  width: 120px;
  height: 34px;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}
>>> .selectboxs.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
>>> .selectboxs.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
>>> .selectboxs.theme--light.v-select .v-select__selections {
  color: #444444;
}
.fontwei {
  font-weight: normal;
  /* margin-left: 15px; */
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 24px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 16px;
  font-weight: normal;
  font-family: "Kanit", serif;
  color: #f99d20;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
/* >>> .theme--light.v-data-table thead tr th {
    font-size: 15px;
    font-weight: normal;
    font-family: "Kanit", serif;
    color: #f99d20;
  } */
>>> .v-calendar-weekly__day-label {
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: left;
  margin: 4px 0 0 0;
}
>>> .v-application .primary {
  margin-left: 10px;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
>>> .v-calendar .v-event {
  position: relative;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  margin-top: 0px;
  margin-right: 15px;
  margin-left: 2px;
  background: transparent !important;
}
>>> .element.style {
  height: 60px;
  top: 10px;
  margin-bottom: 1px;
}
>>> .v-calendar-weekly__head-weekday {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 20px;
  flex: 1 0 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 15px px 0px 4px;
  font-size: 18px;
  background-color: bisque;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}
>>> .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: bisque;
}
.my-event {
  overflow: visible;
  text-overflow: ellipsis;
  border-radius: 2px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  width: 100%;
  height: 50px;
  font-size: 12px;
  cursor: pointer;
  padding-left: 20px;
  padding-top: 0px;
  margin-bottom: 1px;
}
.line-clamp {
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chiptoggle {
  margin-top: -85px;
  margin-left: 35px;
}

>>> .v-application .white--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
  background: transparent !important;
}
@media (max-width: 900px) {
  .tbheader {
    margin-top: 1rem !important;
  }
  .chiptoggle {
    transform: rotate(-90deg);
    margin-top: 5px;
    margin-left: -5px;
    margin-right: 5px;
    max-width: 45px;
  }
  .hidetxts {
    visibility: hidden;
  }
}
@media (max-width: 400px) {
  .minizebox {
    width: auto;
    height: 30px;
  }
}
@media (min-width: 1260px) and (max-width: 1284px) {
  .minizebox {
    width: auto;
    height: 30px;
    margin-bottom: 5px;
  }
}
@media (max-width: 320px) {
  .fix-calender {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .chiptoggle {
    transform: rotate(-90deg);
    margin-top: 5px;
    margin-left: -8px;
    margin-right: 5px;
    max-width: 40px;
  }
  .fixed-calendar {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}
@media (max-width: 375px) {
  .fix-calender {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .fixed-calendar {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
/* >>> .mx-input {
    color: black;
  }

  >>> .mdi:before {
    color: black;
  } */

>>> .theme--light.v-calendar-weekly .v-calendar-weekly__day {
  color: #000000;
  border: #ccc8c8 1px solid;
}
</style>