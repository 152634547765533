<template>
  <div>
    <div style="margin-left: 1%;display:flex;">
      <p class="txt-header2" @click="back()">
        {{ $t("home") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1" @click="goAnno()">
        <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
      </v-btn>
      <p class="ml-4 headtext txt-header" style="cursor: pointer;" @click="goAnno()">
        {{ $t("announcement") }}
      </p>
      <v-btn icon disabled color="white" class="mt-6 ml-1">
        <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
      </v-btn>
      <p class="ml-4 headtext txt-header">
        {{ $t("newPost") }}
      </p>
    </div>

    <v-card class="pt-4" style="margin-bottom: 5rem; margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-5" style="margin-left: 15px; margin-right: 15px">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3">
          <v-row>
            <!-- {{ $t("PinAnnouncement") }} -->
            <img class="pinIcon" :src="images.pinIcon" alt="pinIcon" />
            <p style="font-size: 20px;margin-left:5px;">
              {{ $t("PinAnnouncement") }}
            </p>
            <div class="ml-4" v-if="pinThis == true">
              <input type="checkbox" v-model="pinThis" />
            </div>
            <div class="ml-4" v-else>
              <input type="checkbox" style="cursor: default" v-model="pinThis" />
            </div>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <div class="pl-3" style="margin-right:1rem;">
          <img class="mobileViewIcon" :src="images.mobileView" alt="mobileViewIcon" />
        </div>
      </v-row>
      <v-divider class="mr-4 ml-4 mt-6"></v-divider>
      <!-- <hr class="mt-7"> -->
      <div style="padding: 1rem;">
        <v-row class="pl-7 mt-5">
          {{ $t("Cover photo*") }}
        </v-row>
        <v-row>
        <v-col cols="12" xs="3" sm="9" md="10" lg="10" xl="10" class="pb-0 pl-7">
          <div class="container">
            <div v-if="!uploadcv" style="width: 160px; height: 120px; margin-right: 5px; text-align: center"
              @dragover.prevent="handleDragOver" @drop="handlecvDrop">
              <div v-if="!selectedImage" @click="onpickcv" class="align-center drop-area">
                <v-img :src="images.camera" class="cvicon"></v-img>
                <!-- <v-icon style="color: #a3a7aa">mdi-cloud-upload</v-icon> -->
                <p style="color: #a3a7aa; font-size: 14px;">{{ $t("drag1") }}</p>
              </div>
              <div v-else style="position: relative;max-width: 100%;max-height: 100%;width: 180px;height: 120px;">
                <img :src="selectedImage" class="cvphoto"/>
                <v-btn icon small @click="onpickcv" style="position: absolute; right: -22%;">
                  <v-img :src="images.edit" class="editcv"></v-img>
                </v-btn>
              </div>
            </div>
          </div>
          <input ref="cvupload" @change="handlecv" type="file" style="display: none" id="profile_picture"
            accept="image/*" />
        </v-col>
        </v-row>
        <v-row>
          <p class="ml-7 mt-5">{{ $t("title")}}*</p>
        </v-row>     
        <v-row>
          <v-text-field
            class="shrink custom-width ml-7"
            :placeholder="'Title'"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            style="font-size: 14px"
            v-model="title"
            :rules="titleAdd"
          ></v-text-field>
          <!-- <v-col></v-col> -->
          <!-- <v-text-field class="ml-7 custom-width" :placeholder="'Title'" color="#F99D20" dense outlined autocomplete="off" style="font-size:14px;"></v-text-field> -->
        </v-row>
        
        <v-row>
          <p class="ml-7 mt-5">{{ $t("details")}}*</p>
        </v-row> 
        <v-row>
          <vue-editor class="editorboz custom-width" @keyup.enter="whatisit()" v-model="content"></vue-editor>
        </v-row>
        <v-row>
          <v-col cols="6" lg="4" md="6" sm="12">
            <p class="ml-5 mt-5">{{ $t("StartDateTime")}}*</p>
            <date-picker v-bind:clearable="false" type="datetime" format="DD/MM/YYYY HH:mm"  placeholder="Select Start DateTime" :editable="false"
                    :style="{ width: '100%' }" readonly v-model="startdate" value-type="format"
                    :disabled-date="allowedDates" class="custom-vuedatepicker1 px-3 ml-2"></date-picker>
          </v-col>
          <v-col cols="6" lg="4" md="6" sm="12">
            <p class="mt-5">{{ $t("EndDateTime")}}*</p>
            <date-picker v-bind:clearable="false" type="datetime" format="DD/MM/YYYY HH:mm" placeholder="Select End DateTime" :editable="false"
                    :style="{ width: '100%' }" readonly v-model="enddate" value-type="format"
                    :disabled-date="allowedEndDates" class="custom-vuedatepicker1"></date-picker>
          </v-col>
        </v-row>
        
        <v-row class="pl-7 mt-9">
          {{ $t("AttachmentOT") }}*
        </v-row>  
        <v-row>
          <v-col cols="12" xs="3" sm="9" md="10" lg="10" xl="10" class="pb-0 pl-7">
            <div v-if="uploaddefaultshow" @click="onpickfile" class="drop-area"
              style="width: 130px; height: 120px; text-align: center" @dragover.prevent="handleDragOver"
              @drop="handleFileDrop">
              <div style="text-align: center">
                <!-- <v-icon color="#A3A7AA">mdi-cloud-upload</v-icon> -->
                 <v-img :src="images.attachment" class="cvicon"></v-img>
                <p style="color: #a3a7aa">{{ $t("drag2") }}</p>
              </div>
            </div>

            <div class="file-container">
              <div class="container d-flex align-center">
                  <div style="width: 130px; height: 120px;" v-if="!uploaddefaultshow"
                    @click="onpickfile" class="drop-area" @dragover.prevent="handleDragOver" @drop="handleFileDrop">
                    <div style="text-align: center">
                      <!-- <v-icon style="color: #a3a7aa">mdi-cloud-upload</v-icon> -->
                      <v-img :src="images.attachment" class="cvicon"></v-img>
                      <p style="color: #a3a7aa">{{ $t("choosefile") }}</p>
                    </div>
                  </div>
              
                  <div v-for="file in uploadedFiles" :key="file.name" class="file-box ml-2 pa-3 relative">
                    <v-btn @click="removeFile(file)" class="close-button" icon style="position: absolute; top: -20px; right: -17px; padding: 4px;">
                      <v-img class="deleteicon" :src="images.deleteEmp"></v-img>
                    </v-btn>
                    <div @click="showIframe(file)">
                      <v-img v-if="file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg'" :src="file.imgurl"
                      width="100px" height="100px"></v-img>
                      <v-img class="mb-2 ml-2" v-if="file.type == 'application/pdf'" src="@/assets/images/pdf.png" width="50px" height="50px"></v-img>
                      <div class="text-center" v-if="file.type == 'application/pdf'">
                        {{ file.name ? file.name.substring(0, 10) + " ..." : "" }}
                      </div>
                    </div>
                  </div>
              </div>
              <!-- <div v-for="file in uploadedFiles" :key="file.name" class="ml-2 pa-3 relative" style="
                width: 130px;
                height: 120px;
                border: 1px solid #ccc;
                border-radius: 7px;
                position: relative;">
                <v-btn @click="removeFile(file)" class="close-button" icon style="position: absolute; top: -20px; right: -17px; padding: 4px;">
                  <v-img class="deleteicon" :src="images.deleteEmp"></v-img>
                </v-btn>
                <div @click="showIframe(file)">
                  <v-img v-if="file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg'" :src="file.imgurl"
                  width="100px" height="100px"></v-img>
                  <v-img class="mb-2 ml-2" v-if="file.type == 'application/pdf'" src="@/assets/images/pdf.png" width="50px" height="50px"></v-img>
                  <div class="text-center" v-if="file.type == 'application/pdf'">
                    {{ file.name ? file.name.substring(0, 10) + " ..." : "" }}
                  </div>
                </div>
              </div> -->
            </div>
            <input ref="fileupload" @change="handleFileSelect" type="file" style="display: none" id="profile_picture"
              accept=".pdf, .png, .jpeg, .jpg" />
          </v-col>
        </v-row>

        <v-row class="pl-7 mt-9">
          {{ $t("Audience") }}
        </v-row>
        <v-row class="pl-7 d-flex flex-row align-center">
          <v-radio-group v-model="inline" inline row>
            <v-radio color="#F99D20" :label="$t('sendToAll')" value="1"></v-radio>
            <v-radio color="#F99D20" :label="$t('sendToSpecificEmployees')" value="2"></v-radio>
          </v-radio-group>
        </v-row>

        <div v-if="checkedEmp && selectedEmps.length !=0">
          <v-row class="d-flex justify-end customemp-width" >
            <v-col cols="2" class="d-flex justify-end" >
              <v-img :src="images.plusEmp" class="plusicon" style="cursor: pointer;" @click="addEmp" ></v-img>
            </v-col>
          </v-row>
            
          <v-data-table class="mr-2 ml-2 customemp-width" style="border:1px solid #D9D5D5; border-radius: 9px; " :items="selectedEmps" hide-default-header hide-default-footer
            disable-pagination :mobile-breakpoint="0">
            <template v-slot:header="{ props: { } }">
              <thead>
                <tr style="background-color: #f99d20;" >
                  <td style="color: #ffffff; border-radius: 9px 0 0 0;" >{{ $t("no") }}</td>
                  <td style="color: #ffffff"></td>
                  <td style="color: #ffffff">{{ $t("EmployeeID") }}</td>
                  <td style="color: #ffffff">{{ $t("EmployeeName") }}</td>
                  <td style="color: #ffffff">{{ $t("Position") }}</td>
                  <td style="color: #ffffff">{{ $t("department") }}</td>
                  <td style="color: #ffffff; border-radius: 0 9px 0 0;"></td>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item, index }">
              <tr style="height: 60px; border:1px solid #D9D5D5">
                <td > {{ index + 1 }}</td>
                <td ><v-avatar width="45" height="45" style="margin-left: auto; margin-right: auto">
                        <img :src="item.image" alt="profileImage"/>
                      </v-avatar>
                </td>
                <td>{{ item.employee_Code }}</td>
                <td width="200px">{{ item.fullName }} <span v-if="item.nickname">({{ item.nickname }})</span></td>
                <td>{{ item.position_Name }}</td>
                <td>{{ item.department_Name }}</td>
                <td> <v-img :src="images.deleteEmp" class="deleteicon" style="cursor: pointer;" @click="removeEmp(index)"></v-img></td>
              </tr>
            </template>
          </v-data-table>
        </div>

        <v-row class="pl-7 mt-9">
          {{ $t("Comments") }}*
          <div class="ml-4" v-if="cmtThis == false">
          <input type="checkbox" v-model="cmtThis" />
          </div>
          <div class="ml-4" v-else>
            <input type="checkbox" style="cursor: default" v-model="cmtThis" />
          </div>
        </v-row>

        <v-card-actions style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize commoncancelbtn" outlined @click="clickCancelDialog()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn class="commonsavebtn text-capitalize" @click="AddAnnouncement()">
            {{ $t("publish") }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>

    <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg" style="overflow: hidden">
      <v-card style="
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.5rem">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="downloadImage()"><v-icon size="30">mdi-download</v-icon></v-btn>
          <v-btn icon @click="dialogforDownload = false"><v-icon size="30">mdi-window-close</v-icon></v-btn>
        </v-card-actions>
        <v-img :src="imgForshow" max-width="500"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cancelDialog" class="dialog" max-width="532">
      <v-card style="border-radius: 15px;">
        <v-card-title class="d-flex justify-center align-center">
          {{ $t("DiscardChanges") }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("Changeswillnotsavedtxt") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center pb-4">
          <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="cancelDialog = false">
            {{ $t("keepEditing") }}
          </v-btn>
          <v-btn color="#F99D20" class="mr-2 white--text text-capitalize" width="120" max-height="35"
            @click="cancelAnnouncement">
            {{ $t("yesDiscard") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

    <!-- Add-staff dialog -->
    <v-dialog v-model="staffDialog" persistent max-width="724px" :retain-focus="false" class="staffdialog" >
      <v-card style="border-radius: 15px;">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="clear()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row style="padding-top: 1rem">
            <v-col lg="9" md="9" cols="12" class="pr-0">
              <v-text-field class="search" color="#F99D20" v-model="searchText" :textFieldProps="{
                placeholder: 'Search',
                color: '#F99D20',
                dense: false,
              }" dense outlined :placeholder="$t('SearchEmployeeIDDepartment')"></v-text-field>
            </v-col>
            <v-col>
              <v-btn color="#F99D20" @click="searchEmp()" class="white--text text-capitalize ml-5" width="120"
                max-height="35">
                <v-icon class="pr-1">mdi-magnify</v-icon>Search
              </v-btn>
            </v-col>
          </v-row>
          <div>
            <v-data-table height="600px" class="mt-2" :items="filteredEmps" hide-default-header hide-default-footer
              disable-pagination :mobile-breakpoint="0">
              <template v-slot:header="{ props: { } }">
                <thead>
                  <tr style="background-color: #f99d20">
                    <td style="color: #ffffff">
                      <v-checkbox class="custom-checkbox"  style="color: #ffffff!important; background-color: #f99d20;" v-model="selectAll_Emp" @change="checkall1(selectAll_Emp)"></v-checkbox>
                      </td>
                    <td style="color: #ffffff"></td>
                    <td style="color: #ffffff">{{ $t("EmployeeID") }}</td>
                    <td style="color: #ffffff">{{ $t("EmployeeName") }}</td>
                    <td style="color: #ffffff">{{ $t("Position") }}</td>
                    <td style="color: #ffffff">{{ $t("department") }}</td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td style="border: none">
                    <v-checkbox class="custom-checkbox" color="#F99D20" v-model="item.checked" @change="checkmyemp1()" ></v-checkbox>  
                  </td>
                  <td style="border: none">
                    <v-avatar width="45" height="45" style="margin-left: auto; margin-right: auto">
                      <img :src="item.image" alt="profileImage"/>
                    </v-avatar>
                  </td>
                  <td style="border: none">{{ item.employee_Code }}</td>
                  <td style="border: none" width="200px">{{ item.fullName }} <span v-if="item.nickname">({{ item.nickname }})</span></td>
                  <td style="border: none">{{ item.position_Name }}</td>
                  <td style="border: none">{{ item.department_Name }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-3 text-capitalize fix-btn commoncancelbtn" text
            @click="clear()">{{ $t("Cancel") }}</v-btn>
          <v-btn class="mr-3 text-capitalize commonsavebtn  fix-btn"  @click="checkStaff()">{{ $t("Save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-quill-editor'
import moment from 'moment';
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components: {
    VueEditor, 
    LoadingDialog,
  },
  data() {
    return {
      LoadingDialog:false,
      titleAdd: [],
      url: enurl.apiUrl,
      pinThis: false,
      cmtThis: false,
      content: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['image', 'code-block']
      ],
      uploaddefaultshow: true,
      uploadedFiles: [],
      dialogforDownload: false,
      printdialogforupdate: false,
      iframeShow: false,
      dllink: null,
      imgForshow: "",
      imgString: "",
      pdfString: "",
      inline: "1",
      cancelDialog: false,
      // Requestannouncement: {
      //   title: null,
      //   detail: null,
      //   startdate: "",
      //   enddate: "",
      //   uploadimage: "",
      //   userid: null,
      //   disablecomment: false,
      // },
      title: "",
      startdate:null,
      enddate:null,
      staffDialog: false,
      empList: [],
      selectAll_Emp: false,
      selectedEmps: [],
      checkedEmp: false,
      searchText: '',
      filteredEmps: [],
      uploadcv: false,
      selectedImage: null,
      coverImage: null,
    }
  },

  computed: {
    images() {
      return this.$store.state.images;
    },
  },
  async mounted() {
    this.getempList();
  },

  watch:{
    startdate(newStartDate) {
      // console.log("Start date", newStartDate);
      if (moment(newStartDate, 'DD/MM/YYYY HH:mm') > moment(this.enddate, 'DD/MM/YYYY HH:mm'))
      {
        this.enddate = null;
      }
    },
    title() {
      this.titleAdd = [];
    },
    
    inline(value) {
      //console.log("radio", value);
      if (value == 2)
      {
        this.staffDialog = true;
      }
      else if (value == 1)
      {
        this.staffDialog = false;
        this.selectedEmps = [];
        this.filteredEmps.forEach(emp => {
        emp.checked = false;
        });
        this.selectAll_Emp = false;
      }
    },

    selectedEmps(newSelectedEmps) {
      if (newSelectedEmps.length === 0)
      {
        this.inline = "1";
      }
    }
  },

  methods: {
    whatisit() {
      // console.log("afsdf")
      // console.log(this.content);
    },

    onpickfile() {
      this.$refs.fileupload.click();
    },

    onpickcv(){
      this.$refs.cvupload.click();
    },

    handlecv(event) {
      const file = event.target.files[0];
      if (file)
      {
        const reader = new FileReader();
        reader.onload = (e) => {
          let base64String = e.target.result;
          this.selectedImage = base64String;
          const base64Prefix = base64String.indexOf('base64,') + 'base64,'.length;
          this.coverImage = base64String.substring(base64Prefix);
          this.uploadcv = false;
        };
        reader.readAsDataURL(file);
      }
    },

    handlecvDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let base64String = e.target.result;
          this.selectedImage = base64String;
          this.uploadcv = false;
          const base64Prefix = base64String.indexOf('base64,') + 'base64,'.length;
          this.coverImage = base64String.substring(base64Prefix);
        };
        reader.readAsDataURL(file);
      }
    },

    handleDragOver(event) {
      event.preventDefault();
    },

    handleFileDrop(event) {
      event.preventDefault();
      this.handleFiledrag(event);
    },

    goAnno(){
      this.$router.push({ name: "AnnouncementList"});
    },

    allowedDates(date) {
      const today = moment().startOf('day');
      const selectedDate = moment(date).startOf('day');
      return selectedDate < today;
    },

    allowedEndDates(date) {
      if (!this.startdate) return true;
      const startDateTime = moment(this.startdate, 'DD/MM/YYYY HH:mm');
      const endDateTime = moment(date, 'DD/MM/YYYY HH:mm');
      // console.log('Start Date:', startDateTime.toString());
      // console.log('End Date:', endDateTime.toString());
      return endDateTime <= startDateTime;
    },

    searchEmp() {
      this.filteredEmps = this.empList.filter(emp => 
        emp.employee_Code.toLowerCase().includes(this.searchText.toLowerCase()) ||
        emp.fullName.toLowerCase().includes(this.searchText.toLowerCase()) ||
        emp.department_Name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
    
    clear() {
      this.filteredEmps.forEach(emp => {
        emp.checked = false;
      });
      this.selectAll_Emp = false;
      this.staffDialog = false;
      this.selectedEmps = [];
      this.inline = "1";
      this.filteredEmps = this.empList;
      this.searchText = "";

    },

    addEmp(){
      this.staffDialog = true;
    },

    removeEmp(index) {
      this.selectedEmps.splice(index, 1);
    },

    async getempList(){
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetEMPInfoAnno`, tempp)
      .then(function (response) {
        let arr = response.data.data;
        self.empList = arr.filter(item => item.employee_ID != store.state.employeeID);
        self.filteredEmps = self.empList;
      });
    },

    checkmyemp() {
      this.updateSelectedEmps();
    },
    
    checkall(value) {
      this.empList.forEach(emp => {
        emp.checked = value;
      });
      //this.updateSelectedEmps();
    },

    checkall1(selectAll) {
      this.filteredEmps.forEach(emp => {
        emp.checked = selectAll;
      });
    },
    
    checkmyemp1() {
      this.selectAll_Emp = this.filteredEmps.every(emp => emp.checked);
    },

    updateSelectedEmps() {
      this.selectedEmps = this.empList.filter(emp => emp.checked);
    },

    async checkStaff() {
      if(this.empList.filter(emp => emp.checked) == 0)
      {
        alert("Please select Employee");
      }
      else
      {
        this.selectedEmps = this.empList.filter(emp => emp.checked);
        this.staffDialog = false;
        this.checkedEmp = true;
        this.filteredEmps = this.empList;
      }
      // You can now use this.selectedEmps to show in another table or do other tasks
      //console.log('Selected Employees:', this.selectedEmps);
    },

    async handleFileSelect(event) {
      let self = this;
      event.preventDefault();
      const file = event.target.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file)
      {
        if (file.type === "application/pdf")
        {
          pdfString = URL.createObjectURL(file);
        }
        else if (file.type.startsWith("image/"))
        {
          pdfString = URL.createObjectURL(file);
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          //let imageUrl = fr.result.split(",")[1];
          let imageUrl = fr.result;
          self.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    async handleFiledrag(event) {
      let self = this;
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      let imageName = file.name;
      let filetype = file.type;
      let pdfString = "";
      if (file)
      {
        if (file.type === "application/pdf")
        {
          pdfString = URL.createObjectURL(file);
        }
        else if (file.type.startsWith("image/"))
        {
          pdfString = URL.createObjectURL(file);
        }
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          //let imageUrl = fr.result.split(",")[1];
          let imageUrl = fr.result;
          self.uploadFilesall(imageName, filetype, imageUrl, pdfString);
        });
      }
    },

    uploadFilesall(imgname, type, url, pdfurl) {
      let self = this;
      let plusid = self.uploadedFiles.length + 1;
      self.uploadedFiles.push({
        id: plusid,
        name: imgname,
        imgurl: url,
        pdfurl: pdfurl,
        type: type, 
      });
      self.uploaddefaultshow = false;
    },

    removeFile(file) {
      let self = this;
      const index = self.uploadedFiles.findIndex((item) => item.id === file.id);
      if (index !== -1)
      {
        self.uploadedFiles.splice(index, 1);
      }
      if (self.uploadedFiles.length == 0)
      {
        self.uploaddefaultshow = true;
      }
    },

    showiframe(item) {
      let self = this;
      if (
        item.type == "image/png" ||
        item.type == "image/jpeg" ||
        item.type == "image/jpg"
      )
      {
        self.imgForshow = "data:image/png;base64," + item.imgurl;
        self.dialogforDownload = true;
        self.imgString = item.imgurl;
        self.dllink = item.pdfurl;
        self.imgName = item.name;
      }
      else
      {
        self.printdialogforupdate = true;
        self.iframeShow = true;
        self.pdfString = item.pdfurl;
      }
    },

    downloadImage() {
      let self = this;
      const link = document.createElement("a");
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },

    clickCancelDialog()
    {
      this.cancelDialog = true;
    },

    cancelAnnouncement() {
      this.cancelDialog = false;
      this.$router.push({ name: "home" }).catch(() => { });
    },

    back() {
      this.$router.push({ name: "home" }).catch(() => {});
    },

    removeDataType(base64String) {
      var parts = base64String.split(",");
      if(parts.length > 1)
      {
          return parts[1];
      }
      return base64String;
    },

    AddAnnouncement()
    {
      let self = this;
      self.LoadingDialog = true;
      self.titleAdd = [(v) => !!v || "Title is required"];

      if(
        self.title != null &&
        self.title != "" &&
        self.content != null &&
        self.content != "" &&
        self.startdate != null &&
        self.startdate != "" &&
        self.enddate != null &&
        self.enddate != "" &&
        self.selectedImage != "" &&
        self.uploadedFiles != []
      )
      {
        let tempFiles = self.uploadedFiles.map((file) => ({
          Announcement_ID: 0,
          Attachment_Name: file.name,
          Attachment_Url: this.removeDataType(file.imgurl),
          Filetype: file.type
        }));
        let tempSpecificEmp = self.selectedEmps.map((file) => ({
          employee_ID: file.employee_ID
        }));
        if(self.inline == '2'){
          tempSpecificEmp.push({
            employee_ID: store.state.employeeID
          });
        }
        let temp = {
          announcement_Title: self.title,
          announcement_Detail: self.content,
          announcement_Start_Date: moment(self.startdate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
          announcement_Expiry_Date: moment(self.enddate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss'),
          announcement_Image: self.coverImage,
          announcement_Send_All: self.inline == '1' ? true : false,
          specific_Person: tempSpecificEmp,
          disable_Comments: !self.cmtThis,
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
          pin: self.pinThis,
          attachments: tempFiles,
          user_ID: store.state.userid,
        };
        //console.log(temp);
        axios.post(`${self.url}Announcement/AddAnnouncementForCustWeb`, temp)
        .then(function (response) {
          self.LoadingDialog = true;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.$router.push({ name: "home" }).catch(() => {});
          }
          else
          {
            alert(response.data.messag);
          }
        })
        .catch(function (error) {
          alert(error);
          self.LogTrace(error, "Add Announcement", 39, "Low");
        });
      }
      else
      {
        if (
          self.title == null ||
          self.title == "" ||
          self.detail == null ||
          self.detail == "" ||
          self.startdate == null ||
          self.startdate == "" ||
          self.enddate == null ||
          self.enddate == ""
        )
        {
          alert("Please fill all the fields");
          self.LoadingDialog = false;
        }
        else
        {
          alert("Please Select Attachment");
          self.LoadingDialog = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 100%;
}

.editorboz {
  margin-left: 30px;
  /* height: 318px; */
}
.v-icon .notranslate .mdi.mdi-checkbox-marked .theme--light .primary--text {
  color:#ffffff!important;
}

/* Switch Enable login */
@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 60px;
    height: 30px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(circle 12px,
        white 100%,
        transparent calc(100% + 1px)) #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: transparent;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
    color: transparent;
  }
}

/* Switch Enable login */
.drop-area {
  border: 2px dashed #ccc;
  border-radius: 7px;
  padding: 30px 20px;
  cursor: pointer;
}

.custom-width
{
  width: 70%;
}
.customemp-width{
  width: 68%;
}

.deleteicon{
  width: 24px;
  height: 24px;
}
.cvicon{
  max-width:24px; 
  height: 24px; 
  margin: auto;
}
::v-deep .v-dialog {
  scrollbar-width: none!important;
}
.file-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
.file-box {
  width: 130px;
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 7px;
  position: relative;
  flex-shrink: 0;
  padding: 4px;
}
</style>