<template>
  <div>
    <div v-if="changeview == 'timeoff' || back_t=='true'">
      <v-card class="pt-5" style="margin-bottom: 5rem">
        <v-row
          class="pl-md-0 mt-5"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="permission.add"
            color="#F99D20"
            class="white--text text-capitalize mr-5 px-2"
            min-width="192"
            max-height="35"
            @click="AddTimeOff"
          >
            {{ $t("AddTimeOffType") }}</v-btn
          >
        </v-row>
        <v-data-table
          class="mt-4"
          style="margin-left: 40px; margin-right: 40px"
          :headers="headers"
          :items="TOTList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th
                  style="cursor: pointer; min-width: 180px !important"
                  class="b-text"
                  @click="sortFun('timeoff_Type')"
                >
                  {{ $t("TimeOffType") }}
                  <v-icon small v-if="!timeoff_Type">mdi-arrow-down</v-icon>
                  <v-icon small v-if="timeoff_Type">mdi-arrow-up</v-icon>
                </th>
                <th class="text-end">{{ $t("Actions") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td style="border-bottom: 1px solid #fff">
                {{ item.timeoff_Type }}
              </td>
              <td class="text-end" style="border-bottom: 1px solid #fff">
                <v-btn
                  @click="EditTOT(item.timeOff_ID)"
                  icon
                  :disabled="permission.edit"
                >
                  <img :src="images.editpic" class="mr-2 mb-1" />
                </v-btn>
                <v-btn
                  :disabled="permission.delete"
                  @click="TOTODeletedialog(item.timeOff_ID)"
                  v-show="item.setDefault"
                  icon
                  text
                  class="ml-2"
                >
                  <img :src="images.deletepic" class="mr-2 mb-1" />
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-right" v-if="TOTList.length">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    <div v-else-if="changeview == 'addtimeoff'">
      <Timeoffadd />
    </div>
    <div v-else>
      <Timeoffedit :pass_id="pass_id" />
    </div>

    <!-- Delete Dialog -->
    <v-dialog v-model="TOTDeletedialog" class="dialog" max-width="532">
      <v-card>
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("DeleteTimeOffType") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="TOTDeletedialog = false"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-5 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="DeleteTOT()"
            >{{ $t("Delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Upload Dialog -->
    <v-dialog v-model="TOTUploaddialog" presistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            Upload File
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -30px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-file-input
                  truncate-length="100"
                  v-show="showup"
                  id="fileInputButton"
                  class="inputbot"
                  v-model="chosenfile"
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon>
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row class="mb-6">
              <v-col cols="4" md="4">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  style="text-align: left; float: left; margin-top: -10px"
                  width="150"
                  max-height="35"
                  @click="getupload"
                >
                  {{ $t("Browse") }}</v-btn
                >
              </v-col>
              <v-col cols="6" md="6">
                <p style="margin-top: 0px; margin-left: -40px; color: #f74747">
                  **{{ $t("excelFileFormat") }}
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" />
            <v-row class="mt-7">
              <v-col style="margin-top: -20px" align="left" cols="12">
                <p style="font-size: 19px; color: #444444">
                  {{ $t("excelUploadFormat") }}
                </p>
              </v-col>
              <v-col cols="3">
                <p>{{ $t("DocumentTemplate") }}</p>
              </v-col>
              <v-col cols="2">
                <a
                  style="color: #f99d20"
                  href="https://optimisticpathfile.blob.core.windows.net/optimisticfile/Address Master Data Sample.xlsx"
                >
                  {{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <hr class="mt-4" />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="159"
            height="35"
            @click="closeUpload"
            text
          >
            {{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="white--text mr-4 text-capitalize"
            width="159"
            height="35"
            @click="previewFiles"
          >
            {{ $t("Upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import CheckViewAddEditDeleteUpload from "@/function/RolePermissions.js";
import Timeoffadd from "./Timeoffadd.vue";
import Timeoffedit from "./Timeoffedit.vue";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  name: "Timeoff",
  mixins: [LogTrace, CheckViewAddEditDeleteUpload, utils],
  components: { Timeoffadd, Timeoffedit, LoadingDialog },
  props:["back_t"],
  data() {
    return {
      pass_id: 0,
      changeview: "timeoff",
      permission: {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      },
      url: enurl.apiUrl,
      timeoff_Type: null,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      TImeOFFID: null,
      TOTDeletedialog: false,
      TOTUploaddialog: false,
      LoadingDialog: false,
      showup: false,
      setDefault: true,
      TOTList: [],
      tempimport: [],
      importData: [],
      chosenfile: null,
      images: {
        editpic: require("@/assets/images/Mask Group 178.png"),
        deletepic: require("@/assets/images/Mask Group 177.png"),
      },
      headers: [
        {
          text: "Time Off Type",
          value: "timeoff_Type",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          value: "actionadd",
          align: "right",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    let self = this;
    self.GetTimeOffType();
    //self.GetTimeOffType();
    self.permission = self.CheckViewAddEditDeleteUpload(89, 90, 91, 92, 93);
  },
  methods: {
    AddTimeOff() {
      let self = this;
      self.changeview = "addtimeoff";
      self.activeTab = `/organization/timeoff/timeofftype/add`;
      this.$router.push(`/organization/timeoff/timeofftype/add`).catch(() => {});
      
    },
    sortFun(rowName) {
      let self = this;
      let keyName = ["timeoff_Type"];
      if (rowName == "timeoff_Type") {
        self.TOTList = self.TOTList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.TOTList = self.TOTList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },
    defaultSort() {
      this.timeoff_Type = null;
    },
    back() {
      this.$router.push({ name: "Masterdata" });
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      this.showup = true;
    },
    closeUpload() {
      this.TOTUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    TOTODeletedialog(id) {
      this.TImeOFFID = id;
      this.TOTDeletedialog = true;
    },
    EditTOT(id) {
      this.changeview = "timeoffedit";
      this.pass_id = id;
      this.$router
        .push(`/organization/timeoff/timeofftype/edittoff/${id}`)
        .catch(() => {});
        //this.$router.push(`/organization/timeoff/timeofftype/add`).catch(() => {});  
    },
    GetTimeOffType() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOff/GetTimeOffByCompanyID`, tempp)
        .then(function (response) {
          self.TOTList = response.data.data;
          for (let i = 0; i < self.TOTList.length; i++) {
            self.TOTList[i].setDefault = true;
          }
          if (self.TOTList[4].vacation_Status == true) {
            self.TOTList[0].setDefault = false;
            self.TOTList[1].setDefault = false;
            self.TOTList[2].setDefault = false;
            self.TOTList[3].setDefault = false;
            self.TOTList[4].setDefault = false;
          }
          self.LoadingDialog = false;
          self.defaultSort();
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Time Off Type Fail", 10, "Critical");
          //alert(error);
          self.LoadingDialog = false;
        });
    },
    DeleteTOT() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.TImeOFFID;
      let tempads = {
        timeoff_ID: id,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}TimeOffType/DeleteTimeOffType`, tempads)
        .then(function (response) {
          if (response.data.status == 0)
          {
            alert("Delete Successfully");
            self.TOTDeletedialog = false;
            self.LoadingDialog = false;
            self.GetTimeOffType();
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Delete Time Off Type Fail", 10, "High");
          alert(error);
          self.LoadingDialog = false;
        });
    },
    previewFiles() {
      let self = this;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          this.importData = XLSX.utils.sheet_to_json(worksheet);
          let combileArray = [];
          for (let i = 0; i < this.importData.length; i++) {
            this.tempimport = {
              ...this.importData[i],
              company_ID: store.state.companyID,
            };
            combileArray.push(this.tempimport);
            //console.log(combileArray);
          }
          if (filename == "Time Off Type Master Data.xlsx") {
            // axios
            //   .post(
            //     `https://optimisticwebapi.azurewebsites.net/api/TimeOffType/AddTimeOffType`,
            //     combileArray
            //   )
            //   .then(function(response) {
            //     if (response.data.status == 0) {
            //       alert(response.data.message);
            //       self.chosenfile = null;
            //       self.showup = false;
            //     }
            //   })
            //   .catch(function(error) {
            //     alert(error);
            //   });
          } else {
            alert("File name must be Time Off Type Master Data.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
  },
};
</script>
<style scoped>
@media (max-width: 378px) {
  .headtext {
    margin-left: 2rem !important;
  }
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  font-weight: normal;
  font-size: 16px;
  color: #f99d20;
}
>>> .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(0, 0, 0, 0.54);
  background-color: #00000029;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
}
.v-input__icon--append .v-icon {
  color: purple;
}
</style>
<style>
.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
</style>
