<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <br />
    <div class="d-flex">
      <div>
        <img
          width="24px"
          height="24px"
          style="margin-top:auto;margin:bottom:auto;"
          src="@/assets/images/Group 412@2x.png"
        />
      </div>
      <div class="ml-3">
        <label style="font-size: 17px; color: #f89d1f"
          >{{ $t("guarantor") }} 1</label
        >
      </div>
    </div>
    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="mt-3">
        <!-- <label style="font-size:14px;color:#8A8C8E;margin-top:0rem;">Name Of Guarantor</label> -->
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :label="$t('guarantorname')"
          @keypress="onlyalpha"
          :error-messages="error1.name"
          v-model="guarantor1.name"
          :disabled="disableall"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="mt-3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          @keypress="onlyalpha"
          :disabled="disableall"
          :error-messages="error1.surname"
          v-model="guarantor1.surname"
          :label="$t('surnameguarantor')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="mt-3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :error-messages="error1.IDcard"
          :disabled="disableall"
          @keypress="onlyNumber"
          v-model="guarantor1.cardID"
          :label="$t('idcardno')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <!-- <v-menu
          v-model="menu6"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          fluid
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              readonly
              autocomplete="off"
              append-icon="mdi-calendar-month-outline"
              :disabled="disableall"
              :error-messages="error1.expireddate"
              v-model="computedDateFormatted6"
              :label="$t('expdate')"
              class="kanit-medium"
              color="#F99D20"
              persistent-hint
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date6"
            no-title
            @input="menu6 = false"
          ></v-date-picker>
        </v-menu> -->
        <label style="color: #8a8c8e;font-size:12px;">{{ $t("expdate") }}</label>
        <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="date6" :disabled="disableall"
        no-title format="DD/MM/YYYY" :editable="false" :style="{ width: '100%' }"></date-picker>
      </v-col>
    </v-row>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          @keypress="onlyalpha"
          :disabled="disableall"
          :error-messages="error1.issue"
          v-model="guarantor1.issueplace"
          class="empID"
          :label="$t('issuingplace')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :error-messages="error1.address"
          :disabled="disableall"
          v-model="guarantor1.address"
          class="empID"
          :label="$t('address')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :error-messages="error1.mobile"
          :disabled="disableall"
          v-model="guarantor1.mobile"
          @keypress="onlyNumber"
          class="empID"
          :label="$t('mobile_number')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          @keypress="onlyalpha"
          :disabled="disableall"
          :error-messages="error1.career"
          v-model="guarantor1.carrer"
          class="empID"
          :label="$t('career')"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :error-messages="error1.workplace"
          :disabled="disableall"
          v-model="guarantor1.workplace"
          :label="$t('workplace')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          v-model="guarantor1.position"
          :disabled="disableall"
          @keypress="onlyalpha"
          :error-messages="error1.position"
          :label="$t('position')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :disabled="disableall"
          v-model="guarantor1.telephone"
          :error-messages="error1.telephone"
          :label="$t('telephone_number')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-select
          :items="relationitems"
          item-text="txt"
          item-value="val"
          :error-messages="error1.relationship"
          :disabled="disableall"
          v-model="guarantor1.relationship"
          :label="$t('relationship')"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-select>
      </v-col>
    </v-row>

    <div class="d-flex">
      <div>
        <img
          width="24px"
          height="24px"
          style="margin-top:auto;margin:bottom:auto;"
          src="@/assets/images/Group 413@2x.png"
        />
      </div>
      <div class="ml-3">
        <label style="font-size: 17px; color: #f89d1f"
          >{{ $t("guarantor") }} 2</label
        >
      </div>
    </div>
    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col class="mt-3" cols="12" xs="12" sm="12" md="3" lg="3">
        <!-- <label style="font-size:14px;color:#8A8C8E;margin-top:0rem;">Name Of Guarantor</label> -->
        <v-text-field
          autocomplete="off"
          :label="$t('guarantorname')"
          @keypress="onlyalpha"
          :error-messages="error2.name"
          :disabled="disableall"
          v-model="guarantor2.name"
          color="#F99D20"
        ></v-text-field>
      </v-col>
      <v-col class="mt-3" cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          @keypress="onlyalpha"
          :disabled="disableall"
          :error-messages="error2.surname"
          v-model="guarantor2.surname"
          :label="$t('surnameguarantor')"
        ></v-text-field>
      </v-col>
      <v-col class="mt-3" cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          @keypress="onlyNumber"
          :disabled="disableall"
          v-model="guarantor2.cardID"
          :error-messages="error2.IDcard"
          :label="$t('idcardno')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <!-- <v-menu
          v-model="menu7"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          fluid
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              readonly
              autocomplete="off"
              append-icon="mdi-calendar-month-outline"
              v-model="computedDateFormatted7"
              :disabled="disableall"
              :error-messages="error2.expireddate"
              :label="$t('expdate')"
              class="kanit-medium"
              color="#F99D20"
              persistent-hint
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date7"
            no-title
            @input="menu7 = false"
          ></v-date-picker>
        </v-menu> -->
        <label style="color: #8a8c8e;font-size:12px;">{{ $t("expdate") }}</label>
        <date-picker class="custom-vuedatepicker" v-bind:clearable="false" value-type="format" v-model="date7" :disabled="disableall"
        no-title format="DD/MM/YYYY" :editable="false" :style="{ width: '100%' }"></date-picker>
      </v-col>
    </v-row>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :disabled="disableall"
          :error-messages="error2.issue"
          @keypress="onlyalpha"
          v-model="guarantor2.issueplace"
          class="empID"
          :label="$t('issuingplace')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :disabled="disableall"
          v-model="guarantor2.address"
          :error-messages="error2.address"
          class="empID"
          :label="$t('address')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          v-model="guarantor2.mobile"
          :disabled="disableall"
          @keypress="onlyNumber"
          :error-messages="error2.mobile"
          class="empID"
          :label="$t('mobilenumber')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          :error-messages="error2.career"
          :disabled="disableall"
          @keypress="onlyalpha"
          v-model="guarantor2.carrer"
          class="empID"
          :label="$t('career')"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="pl-md-0" style="margin-left: 0px; margin-right: 10px">
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          v-model="guarantor2.workplace"
          :disabled="disableall"
          :error-messages="error2.workplace"
          :label="$t('workplace')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          v-model="guarantor2.position"
          :disabled="disableall"
          @keypress="onlyalpha"
          :error-messages="error2.position"
          :label="$t('position')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-text-field
          autocomplete="off"
          color="#F99D20"
          v-model="guarantor2.telephone"
          :disabled="disableall"
          :error-messages="error2.telephone"
          :label="$t('telephone_number')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-select
          :items="relationitems"
          item-text="txt"
          item-value="val"
          :error-messages="error2.relationship"
          :disabled="disableall"
          v-model="guarantor2.relationship"
          :label="$t('relationship')"
          append-icon="mdi-chevron-down"
          color="#F99D20"
        ></v-select>
      </v-col>
    </v-row>
    <div
      style="
        margin-top: 3rem;
        padding-bottom: 3rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      "
    >
      <v-row v-show="showedit">
        <v-col offset-md="10">
          <v-btn
            class="pa-2 float-right text_transform_none commonsavebtn"
            @click="Showandhide"
            :disabled="
              $route.path ==
              '/selfservice/PersonalProfile/PersonalInformation/Guarantor'
                ? permission.edit
                : permission_management.edit
            "
            >{{ $t("edit") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="showsavewithid">
        <v-col offset-md="10">
          <v-btn
            class="pa-2 float-right text_transform_none commonsavebtn"
            @click="AddnewGuarantor"
            :disabled="permission_management.add"
            >{{ $t("save") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="showsavewithsid">
        <v-col offset-md="10">
          <v-btn
            class="pa-2 float-right text_transform_none commonsavebtn"
            @click="AddnewGuarantorWithSid"
            :disabled="permission.add"
            >{{ $t("save") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="showsavewithcode">
        <v-col offset-md="10">
          <v-btn
            class="pa-2 float-right text_transform_none commonsavebtn"
            @click="Addguarantor"
            :disabled="permission_management.add"
            >{{ $t("save") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="showbyid">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            outlined
            @click="hidethis"
            class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="pa-2 mr-2 text_transform_none save_btn commonsavebtn"
            :disabled="permission_management.edit"
            @click="Editguarantor"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-row>
      <v-row v-show="showbysid">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            outlined
            @click="hidethis"
            class="pa-2 mr-7 text_transform_none cancle_btn commoncancelbtn"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="pa-2 mr-2 text_transform_none save_btn commonsavebtn"
            :disabled="permission.edit"
            @click="EditguarantorWithSid"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-row>
      <v-row v-show="showbycode">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            outlined
            @click="hidethis"
            class="pa-2 mr-7 text_transform_none cancle_btn"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="pa-2 mr-2 text_transform_none btn_edit save_btn commonsavebtn"
            :disabled="permission_management.edit"
            @click="EditguarantorWithCode"
            >{{ $t("save") }}</v-btn
          >
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import CheckViewAddEdit from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [CheckViewAddEdit, LogTrace, utils],
  data: (vm) => ({
    permission: {
      add: true,
      edit: true,
    },
    permission_management: {
      add: true,
      edit: true,
    },
    url: enurl.apiUrl,
    disableall: false,
    showedit: false,
    showsavewithid: false,
    showsavewithsid: false,
    showsavewithcode: false,
    showbyid: false,
    showbysid: false,
    showbycode: false,
    loadingdialog: false,
    date6: null,
    date7: null,
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu6: false,
    menu7: false,
    guarantor1: {
      name: null,
      surname: null,
      cardID: null,
      issueplace: null,
      address: null,
      mobile: null,
      carrer: null,
      workplace: null,
      position: null,
      telephone: null,
      relationship: "",
    },
    guarantor2: {
      name: null,
      surname: null,
      cardID: null,
      issueplace: null,
      address: null,
      mobile: null,
      carrer: null,
      workplace: null,
      position: null,
      telephone: null,
      relationship: "",
    },
    forguarantor1: [],
    forguarantor2: [],
    GuarantorList: [],

    error1: {
      name: null,
      surname: null,
      IDcard: null,
      expireddate: null,
      issue: null,
      address: null,
      mobile: null,
      career: null,
      workplace: null,
      position: null,
      telephone: null,
      relationship: null,
    },
    error2: {
      name: null,
      surname: null,
      IDcard: null,
      expireddate: null,
      issue: null,
      address: null,
      mobile: null,
      career: null,
      workplace: null,
      position: null,
      telephone: null,
      relationship: null,
    },
  }),
  watch: {
    "guarantor1.name"(v) {
      if (v) this.error1.name = "";
    },
    "guarantor1.surname"(v) {
      if (v) this.error1.surname = "";
    },
    "guarantor1.cardID"(v) {
      if (v) this.error1.IDcard = "";
    },
    "guarantor1.issueplace"(v) {
      if (v) this.error1.issue = "";
    },
    "guarantor1.address"(v) {
      if (v) this.error1.address = "";
    },
    "guarantor1.mobile"(v) {
      if (v) this.error1.mobile = "";
    },
    "guarantor1.carrer"(v) {
      if (v) this.error1.career = "";
    },
    "guarantor1.relationship"(v) {
      if (v) this.error1.relationship = "";
    },

    "guarantor1.workplace"(v) {
      if (v) this.error1.workplace = "";
    },
    "guarantor1.position"(v) {
      if (v) this.error1.position = "";
    },
    "guarantor1.telephone"(v) {
      if (v) this.error1.telephone = "";
    },
    date6(v) {
      if (v) this.error1.expireddate = "";
    },
    "guarantor2.name"(v) {
      if (v) this.error2.name = "";
    },
    "guarantor2.surname"(v) {
      if (v) this.error2.surname = "";
    },
    "guarantor2.cardID"(v) {
      if (v) this.error2.IDcard = "";
    },
    "guarantor2.issueplace"(v) {
      if (v) this.error2.issue = "";
    },
    "guarantor2.address"(v) {
      if (v) this.error2.address = "";
    },
    "guarantor2.mobile"(v) {
      if (v) this.error2.mobile = "";
    },
    "guarantor2.carrer"(v) {
      if (v) this.error2.career = "";
    },
    "guarantor2.relationship"(v) {
      if (v) this.error2.relationship = "";
    },
    "guarantor2.workplace"(v) {
      if (v) this.error2.workplace = "";
    },
    "guarantor2.position"(v) {
      if (v) this.error2.position = "";
    },
    "guarantor2.telephone"(v) {
      if (v) this.error2.telephone = "";
    },
    date7(v) {
      if (v) this.error2.expireddate = "";
    },
  },
  computed: {
    relationitems() {
      return [
        { val: "Father", txt: this.$t("Father") },
        { val: "Mother", txt: this.$t("Mother") },
        { val: "Sister", txt: this.$t("Sister") },
        { val: "Brother", txt: this.$t("Brother") },
        { val: "Spouse", txt: this.$t("Spouse") },
      ];
    },
  },
  mounted() {
    if (
      this.$route.path ==
      "/selfservice/PersonalProfile/PersonalInformation/Guarantor"
    ) {
      this.GetguarantorBySid();
    } else if (this.$route.params.id != null) {
      this.Getguarantor();
    } else if (localStorage.empcode != null) {
      this.showsavewithcode = true;
      this.GetguarantorByCode();
    } else {
      this.showsavewithcode = true;
    }
    this.permission = this.CheckViewAddEdit(146, 147, 148);
    this.permission_management = this.CheckViewAddEdit(218, 219, 220);
    this.disable_btn();
  },
  methods: {
    //permission
    disable_btn() {
      let self = this;
      if (
        self.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/Guarantor"
      ) {
        if (self.permission.add == false) {
          self.permission.add = false;
        }
        if (self.permission.edit == false) {
          self.permission.edit = false;
        }
      } else {
        if (self.permission_management.add == false) {
          self.permission_management.add = false;
        }
        if (self.permission_management.edit == false) {
          self.permission_management.edit = false;
        }
      }
    },
    //click cancel button
    hidethis() {
      this.disableall = true;
      this.showedit = true;
      this.showsavewithid = false;
      this.showsavewithsid = false;
      this.showsavewithcode = false;
      this.showbyid = false;
      this.showbysid = false;
      this.showbycode = false;
    },
    // show and hide add and edit button
    Showandhide() {
      let self = this;
      self.showedit = false;
      self.disableall = false;
      if (
        self.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/Guarantor"
      ) {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = false;
        self.showbysid = true;
        self.showbycode = false;
      } else if (self.$route.params.id != undefined) {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = true;
        self.showbysid = false;
        self.showbycode = false;
      } else {
        self.showsavewithid = false;
        self.showsavewithsid = false;
        self.showsavewithcode = false;
        self.showbyid = false;
        self.showbysid = false;
        self.showbycode = true;
      }
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    //only allow alphabetic
    onlyalpha(e) {
      var regex = /^\d*\.?\d*$/;
      var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(key)) {
        e.preventDefault();
        return false;
      }
    },
    // validate telephone number
    telephonenumbervalidation(tele) {
      let teleph = /^\d{9}$/;
      if (teleph.test(tele)) {
        return true;
      } else {
        return "Invalid Telephone number format";
      }
    },
    // validate Card ID number
    cardIDvalidation(card) {
      let teleph = /^\d{13}$/;
      if (teleph.test(card)) {
        return true;
      } else {
        return "Invalid Telephone number format";
      }
    },
    // validate mobile number
    mobilenumbervalidation(mobil) {
      let phoneno1 = /^\d{10}$/;
      let phoneno2 = /^\d{11}$/;
      let phoneno3 = /^\d{9}$/;
      if (
        phoneno1.test(mobil) ||
        phoneno2.test(mobil) ||
        phoneno3.test(mobil)
      ) {
        return true;
      } else {
        return "Invalid Mobile format";
      }
    },
    // Get guarantor by employee iD
    Getguarantor() {
      if (this.$route.params.id != "undefined")
      {
        let self = this;
        self.loadingdialog = true;
        let tempp = {
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}Guarantor/GetGuarantor`, tempp)
        .then(function (response) {
          self.GuarantorList = response.data.data;
          self.loadingdialog = false;
          if (self.$route.params.id != null && self.GuarantorList.length == 0)
          {
            self.disableall = false;
            self.showedit = false;
            self.showsavewithid = true;
            self.showsavewithsid = false;
            self.showsavewithcode = false;
            self.showbyid = false;
            self.showbysid = false;
            self.showbycode = false;
          }
          else
          {
            self.disableall = true;
            self.showedit = true;
            self.showsavewithid = false;
            self.showsavewithsid = false;
            self.showsavewithcode = false;
            self.showbyid = false;
            self.showbysid = false;
            self.showbycode = false;
          }

          if (self.GuarantorList.length != 0)
          {
            self.guarantor1.name = self.GuarantorList[0].gurantor_Name;
            self.guarantor1.surname = self.GuarantorList[0].surname;
            self.guarantor1.cardID = self.GuarantorList[0].iD_Card;
            self.date6 = self.$moment(self.GuarantorList[0].expire_Date).format('DD/MM/YYYY');
            self.guarantor1.issueplace = self.GuarantorList[0].issuing_Place;
            self.guarantor1.address = self.GuarantorList[0].address;
            self.guarantor1.mobile = self.GuarantorList[0].mobile_Number;
            self.guarantor1.carrer = self.GuarantorList[0].career;
            self.guarantor1.workplace = self.GuarantorList[0].work_Place;
            self.guarantor1.position = self.GuarantorList[0].position;
            self.guarantor1.telephone = self.GuarantorList[0].telephone;
            self.guarantor1.relationship = self.GuarantorList[0].relationship;
            if (self.GuarantorList.length > 1)
            {
              self.guarantor2.name = self.GuarantorList[1].gurantor_Name;
              self.guarantor2.surname = self.GuarantorList[1].surname;
              self.guarantor2.cardID = self.GuarantorList[1].iD_Card;
              // self.date7 = self.GuarantorList[1].expire_Date;
              self.date7 = self.$moment(self.GuarantorList[1].expire_Date).format('DD/MM/YYYY');
              self.guarantor2.issueplace = self.GuarantorList[1].issuing_Place;
              self.guarantor2.address = self.GuarantorList[1].address;
              self.guarantor2.mobile = self.GuarantorList[1].mobile_Number;
              self.guarantor2.carrer = self.GuarantorList[1].career;
              self.guarantor2.workplace = self.GuarantorList[1].work_Place;
              self.guarantor2.position = self.GuarantorList[1].position;
              self.guarantor2.telephone = self.GuarantorList[1].telephone;
              self.guarantor2.relationship = self.GuarantorList[1].relationship;
            }
          }
          //self.LogTrace(null, "Get Guarantor", 20, "Critical");
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Guarantor Fail", 20, "Critical");
          self.loadingdialog = false;
          //alert(error);
        });
      }
    },

    // Get guarantor information by session employee ID
    GetguarantorBySid() {
      let self = this;
      self.loadingdialog = true;
      let tempp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Guarantor/GetGuarantor`, tempp)
      .then(function (response) {
        self.GuarantorList = response.data.data;
        self.loadingdialog = false;
        if (store.state.employeeID != null && self.GuarantorList.length == 0)
        {
          self.disableall = false;
          self.showedit = false;
          self.showsavewithid = false;
          self.showsavewithsid = true;
          self.showsavewithcode = false;
          self.showbyid = false;
          self.showbysid = false;
          self.showbycode = false;
        }
        else
        {
          self.disableall = true;
          self.showedit = true;
          self.showsavewithid = false;
          self.showsavewithsid = false;
          self.showsavewithcode = false;
          self.showbyid = false;
          self.showbysid = false;
          self.showbycode = false;
        }
        if (self.GuarantorList.length != 0)
        {
          self.guarantor1.name = self.GuarantorList[0].gurantor_Name;
          self.guarantor1.surname = self.GuarantorList[0].surname;
          self.guarantor1.cardID = self.GuarantorList[0].iD_Card;
          self.date6 = self.$moment(self.GuarantorList[0].expire_Date).format('DD/MM/YYYY');
          self.guarantor1.issueplace = self.GuarantorList[0].issuing_Place;
          self.guarantor1.address = self.GuarantorList[0].address;
          self.guarantor1.mobile = self.GuarantorList[0].mobile_Number;
          self.guarantor1.carrer = self.GuarantorList[0].career;
          self.guarantor1.workplace = self.GuarantorList[0].work_Place;
          self.guarantor1.position = self.GuarantorList[0].position;
          self.guarantor1.telephone = self.GuarantorList[0].telephone;
          self.guarantor1.relationship = self.GuarantorList[0].relationship;

          if (self.GuarantorList.length > 1) 
          {
            self.guarantor2.name = self.GuarantorList[1].gurantor_Name;
            self.guarantor2.surname = self.GuarantorList[1].surname;
            self.guarantor2.cardID = self.GuarantorList[1].iD_Card;
            self.date7 = self.$moment(self.GuarantorList[1].expire_Date).format('DD/MM/YYYY');
            self.guarantor2.issueplace = self.GuarantorList[1].issuing_Place;
            self.guarantor2.address = self.GuarantorList[1].address;
            self.guarantor2.mobile = self.GuarantorList[1].mobile_Number;
            self.guarantor2.carrer = self.GuarantorList[1].career;
            self.guarantor2.workplace = self.GuarantorList[1].work_Place;
            self.guarantor2.position = self.GuarantorList[1].position;
            self.guarantor2.telephone = self.GuarantorList[1].telephone;
            self.guarantor2.relationship = self.GuarantorList[1].relationship;
          }
        }
        //self.LogTrace(null, "Get Guarantor", 20, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Guarantor Fail", 20, "Critical");
        self.loadingdialog = false;
        //alert(error);
      });
    },

    // get guarantor information by employee code
    GetguarantorByCode() {
      let self = this;
      self.loadingdialog = true;
      let tempp = {
        employee_ID: localStorage.empcode,
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Guarantor/GetGuarantorWithCode`, tempp)
      .then(function (response) {
        self.GuarantorList = response.data.data;
        self.loadingdialog = false;
        if (self.GuarantorList.length == 0)
        {
          self.disableall = false;
          self.showedit = false;
          self.showsavewithid = false;
          self.showsavewithsid = false;
          self.showsavewithcode = true;
          self.showbyid = false;
          self.showbysid = false;
          self.showbycode = false;
        }
        else
        {
          self.disableall = true;
          self.showedit = true;
          self.showsavewithid = false;
          self.showsavewithsid = false;
          self.showsavewithcode = false;
          self.showbyid = false;
          self.showbysid = false;
          self.showbycode = false;
        }
        if (self.GuarantorList.length != 0)
        {
          self.guarantor1.name = self.GuarantorList[0].gurantor_Name;
          self.guarantor1.surname = self.GuarantorList[0].surname;
          self.guarantor1.cardID = self.GuarantorList[0].iD_Card;
          self.date6 = self.$moment(self.GuarantorList[0].expire_Date).format('DD/MM/YYYY');
          self.guarantor1.issueplace = self.GuarantorList[0].issuing_Place;
          self.guarantor1.address = self.GuarantorList[0].address;
          self.guarantor1.mobile = self.GuarantorList[0].mobile_Number;
          self.guarantor1.carrer = self.GuarantorList[0].career;
          self.guarantor1.workplace = self.GuarantorList[0].work_Place;
          self.guarantor1.position = self.GuarantorList[0].position;
          self.guarantor1.telephone = self.GuarantorList[0].telephone;
          self.guarantor1.relationship = self.GuarantorList[0].relationship;

          if (self.GuarantorList.length > 1)
          {
            self.guarantor2.name = self.GuarantorList[1].gurantor_Name;
            self.guarantor2.surname = self.GuarantorList[1].surname;
            self.guarantor2.cardID = self.GuarantorList[1].iD_Card;
            self.date7 = self.$moment(self.GuarantorList[1].expire_Date).format('DD/MM/YYYY');
            self.guarantor2.issueplace = self.GuarantorList[1].issuing_Place;
            self.guarantor2.address = self.GuarantorList[1].address;
            self.guarantor2.mobile = self.GuarantorList[1].mobile_Number;
            self.guarantor2.carrer = self.GuarantorList[1].career;
            self.guarantor2.workplace = self.GuarantorList[1].work_Place;
            self.guarantor2.position = self.GuarantorList[1].position;
            self.guarantor2.telephone = self.GuarantorList[1].telephone;
            self.guarantor2.relationship = self.GuarantorList[1].relationship;
          }
        }
        //self.LogTrace(null, "Get Guarantor", 20, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Guarantor Fail", 20, "Critical");
        self.loadingdialog = false;
        //alert(error);
      });
    },

    // delete old guarantor
    DeleteOldArray() {
      let self = this;
      let temparr = [
        {
          gurantor_ID: null,
        },
      ];
      for (let i = 0; i < self.GuarantorList.length; i++)
      {
        if (i == 0)
        {
          temparr[i].gurantor_ID = self.GuarantorList[i].gurantor_ID;
        }
        else
        {
          temparr.push({
            gurantor_ID: self.GuarantorList[i].gurantor_ID,
          });
        }
      }
      axios.post(`${self.url}Guarantor/DeleteOldGuarantor`, temparr)
      .then(function (response) {
        if (response.data.status == 0)
        {
          // alert("gg")
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    // Edit guarantor by employee iD
    Editguarantor() {
      let combilearray1 = [];
      let tempeditvalidate1 = this.validateaddguarantor1();
      let tempeditvalidate2 = this.validateaddguarantor2();
      let self = this;
      self.loadingdialog = true;
      if (tempeditvalidate1)
      {
        self.forguarantor1 = {
          gurantor_Name: self.guarantor1.name,
          surname: self.guarantor1.surname,
          iD_Card: self.guarantor1.cardID,
          // expire_Date: self.date6,
          expire_Date: self.$moment(self.date6, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          issuing_Place: self.guarantor1.issueplace,
          address: self.guarantor1.address,
          mobile_Number: self.guarantor1.mobile,
          career: self.guarantor1.carrer,
          work_Place: self.guarantor1.workplace,
          position: self.guarantor1.position,
          telephone: self.guarantor1.telephone,
          relationship: self.guarantor1.relationship,
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        combilearray1.push(self.forguarantor1);
      }
      else
      {
        self.validateerrorguarantor1();
        self.loadingdialog = false;
      }

      if (
        self.guarantor2.name != null ||
        self.guarantor2.surname != null ||
        self.guarantor2.cardID != null ||
        self.date7 != null ||
        self.guarantor2.issueplace != null ||
        self.guarantor2.address != null ||
        self.guarantor2.mobile != null ||
        self.guarantor2.carrer != null ||
        self.guarantor2.workplace != null ||
        self.guarantor2.position != null ||
        self.guarantor2.telephone != null ||
        self.guarantor2.relationship != ""
      )
      {
        if (tempeditvalidate2)
        {
          self.forguarantor2 = {
            gurantor_Name: self.guarantor2.name,
            surname: self.guarantor2.surname,
            iD_Card: self.guarantor2.cardID,
            // expire_Date: self.date7,
            expire_Date: self.$moment(self.date7, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            issuing_Place: self.guarantor2.issueplace,
            address: self.guarantor2.address,
            mobile_Number: self.guarantor2.mobile,
            career: self.guarantor2.carrer,
            work_Place: self.guarantor2.workplace,
            position: self.guarantor2.position,
            telephone: self.guarantor2.telephone,
            relationship: self.guarantor2.relationship,
            employee_ID: parseInt(self.$route.params.id),
            company_ID: store.state.companyID,
          };
          combilearray1.push(self.forguarantor2);
        }
        else
        {
          self.validateerrorguarantor2();
          self.loadingdialog = false;
        }
      }
      else
      {
        tempeditvalidate2 = true;
      }
      if (combilearray1.length > 0 && tempeditvalidate1 == true && tempeditvalidate2 == true)
      {
        self.DeleteOldArray();
        axios.post(`${self.url}Guarantor/AddNewGuarantor`, combilearray1)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.resetformguarantor1();
            if (tempeditvalidate1)
            {
              self.error1.name = null;
              self.error1.surname = null;
              self.error1.expireddate = null;
              self.error1.issue = null;
              self.error1.address = null;
              self.error1.telephone = null;
              self.error1.career = null;
              self.error1.workplace = null;
              self.error1.position = null;
              self.error1.IDcard = null;
              self.error1.relationship = null;
              self.error1.mobile = null;
            }
            if (tempeditvalidate2 == true)
            {
              self.error2.name = null;
              self.error2.surname = null;
              self.error2.expireddate = null;
              self.error2.issue = null;
              self.error2.address = null;
              self.error2.telephone = null;
              self.error2.career = null;
              self.error2.workplace = null;
              self.error2.position = null;
              self.error2.IDcard = null;
              self.error2.relationship = null;
              self.error2.mobile = null;
            }
            self.Getguarantor();
            //self.LogTrace(null, "Update Guarantor ", 20, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Update Guarantor Fail", 20, "Medium");
          alert(error);
        });
      }
    },

    // edit guarantor with session ID
    EditguarantorWithSid() {
      let combilearray1 = [];
      let tempeditvalidate1 = this.validateaddguarantor1();
      let tempeditvalidate2 = this.validateaddguarantor2();
      let self = this;
      self.loadingdialog = true;
      if (tempeditvalidate1)
      {
        self.forguarantor1 = {
          gurantor_Name: self.guarantor1.name,
          surname: self.guarantor1.surname,
          iD_Card: self.guarantor1.cardID,
          // expire_Date: self.date6,
          expire_Date: self.$moment(self.date6, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          issuing_Place: self.guarantor1.issueplace,
          address: self.guarantor1.address,
          mobile_Number: self.guarantor1.mobile,
          career: self.guarantor1.carrer,
          work_Place: self.guarantor1.workplace,
          position: self.guarantor1.position,
          telephone: self.guarantor1.telephone,
          relationship: self.guarantor1.relationship,
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        combilearray1.push(self.forguarantor1);
      }
      else
      {
        self.validateerrorguarantor1();
        self.loadingdialog = false;
      }

      if (
        self.guarantor2.name != null ||
        self.guarantor2.surname != null ||
        self.guarantor2.cardID != null ||
        self.date7 != null ||
        self.guarantor2.issueplace != null ||
        self.guarantor2.address != null ||
        self.guarantor2.mobile != null ||
        self.guarantor2.carrer != null ||
        self.guarantor2.workplace != null ||
        self.guarantor2.position != null ||
        self.guarantor2.telephone != null ||
        self.guarantor2.relationship != ""
      )
      {
        if (tempeditvalidate2)
        {
          self.forguarantor2 = {
            gurantor_Name: self.guarantor2.name,
            surname: self.guarantor2.surname,
            iD_Card: self.guarantor2.cardID,
            // expire_Date: self.date7,
            expire_Date: self.$moment(self.date7, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            issuing_Place: self.guarantor2.issueplace,
            address: self.guarantor2.address,
            mobile_Number: self.guarantor2.mobile,
            career: self.guarantor2.carrer,
            work_Place: self.guarantor2.workplace,
            position: self.guarantor2.position,
            telephone: self.guarantor2.telephone,
            relationship: self.guarantor2.relationship,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
          };
          combilearray1.push(self.forguarantor2);
        }
        else
        {
          self.validateerrorguarantor2();
          self.loadingdialog = false;
        }
      }
      else
      {
        tempeditvalidate2 = true;
      }
      if (combilearray1.length > 0 && tempeditvalidate1 == true && tempeditvalidate2 == true)
      {
        self.DeleteOldArray();
        axios.post(`${self.url}Guarantor/AddNewGuarantor`, combilearray1)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.resetformguarantor1();
            self.GetguarantorBySid();
            if (tempeditvalidate1)
            {
              self.error1.name = null;
              self.error1.surname = null;
              self.error1.expireddate = null;
              self.error1.issue = null;
              self.error1.address = null;
              self.error1.telephone = null;
              self.error1.career = null;
              self.error1.workplace = null;
              self.error1.position = null;
              self.error1.IDcard = null;
              self.error1.relationship = null;
              self.error1.mobile = null;
            }
            if (tempeditvalidate2 == true)
            {
              self.error2.name = null;
              self.error2.surname = null;
              self.error2.expireddate = null;
              self.error2.issue = null;
              self.error2.address = null;
              self.error2.telephone = null;
              self.error2.career = null;
              self.error2.workplace = null;
              self.error2.position = null;
              self.error2.IDcard = null;
              self.error2.relationship = null;
              self.error2.mobile = null;
            }
            //self.LogTrace(null, "Update Guarantor", 20, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Update Guarantor Fail", 20, "Medium");
          alert(error);
        });
      }
    },

    // edit guarantor with employee code
    EditguarantorWithCode() {
      let combilearray1 = [];
      let self = this;
      let tempeditvalidate1 = this.validateaddguarantor1();
      let tempeditvalidate2 = this.validateaddguarantor2();
      self.loadingdialog = true;
      if (tempeditvalidate1)
      {
        self.forguarantor1 = {
          gurantor_Name: self.guarantor1.name,
          surname: self.guarantor1.surname,
          iD_Card: self.guarantor1.cardID,
          // expire_Date: self.date6,
          expire_Date: self.$moment(self.date6, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          issuing_Place: self.guarantor1.issueplace,
          address: self.guarantor1.address,
          mobile_Number: self.guarantor1.mobile,
          career: self.guarantor1.carrer,
          work_Place: self.guarantor1.workplace,
          position: self.guarantor1.position,
          telephone: self.guarantor1.telephone,
          relationship: self.guarantor1.relationship,
          employee_ID: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        combilearray1.push(self.forguarantor1);
      }
      else
      {
        self.validateerrorguarantor1();
        self.loadingdialog = false;
      }

      if (
        self.guarantor2.name != null ||
        self.guarantor2.surname != null ||
        self.guarantor2.cardID != null ||
        self.date7 != null ||
        self.guarantor2.issueplace != null ||
        self.guarantor2.address != null ||
        self.guarantor2.mobile != null ||
        self.guarantor2.carrer != null ||
        self.guarantor2.workplace != null ||
        self.guarantor2.position != null ||
        self.guarantor2.telephone != null ||
        self.guarantor2.relationship != ""
      )
      {
        if (tempeditvalidate2)
        {
          self.forguarantor2 = {
            gurantor_Name: self.guarantor2.name,
            surname: self.guarantor2.surname,
            iD_Card: self.guarantor2.cardID,
            // expire_Date: new Date(self.date7),
            expire_Date: self.$moment(self.date7,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            issuing_Place: self.guarantor2.issueplace,
            address: self.guarantor2.address,
            mobile_Number: self.guarantor2.mobile,
            career: self.guarantor2.carrer,
            work_Place: self.guarantor2.workplace,
            position: self.guarantor2.position,
            telephone: self.guarantor2.telephone,
            relationship: self.guarantor2.relationship,
            employee_ID: localStorage.empcode,
            company_ID: store.state.companyID,
          };
          combilearray1.push(self.forguarantor2);
        }
        else
        {
          self.validateerrorguarantor2();
          self.loadingdialog = false;
        }
      }
      else
      {
        tempeditvalidate2 = true;
      }
      if (combilearray1.length > 0 && tempeditvalidate1 == true && tempeditvalidate2 == true)
      {
        self.DeleteOldArray();
        axios.post(`${self.url}Guarantor/AddGuarantor`, combilearray1)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.resetformguarantor1();
            self.GetguarantorByCode();
            //self.LogTrace(null, "Update Guarantor", 20, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Update Guarantor Fail", 20, "Medium");
          alert(error);
        });
      }
    },

    // add new guarantor
    Addguarantor() {
      let combilearray = [];
      let self = this;
      let tempvalidate1 = this.validateaddguarantor1();
      let tempvalidate2 = this.validateaddguarantor2();
      self.loadingdialog = true;
      if (tempvalidate1)
      {
        self.forguarantor1 = {
          gurantor_Name: self.guarantor1.name,
          surname: self.guarantor1.surname,
          iD_Card: self.guarantor1.cardID,
          // expire_Date: self.date6,
          expire_Date: self.$moment(self.date6, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          issuing_Place: self.guarantor1.issueplace,
          address: self.guarantor1.address,
          mobile_Number: self.guarantor1.mobile,
          career: self.guarantor1.carrer,
          work_Place: self.guarantor1.workplace,
          position: self.guarantor1.position,
          telephone: self.guarantor1.telephone,
          relationship: self.guarantor1.relationship,
          employee_ID: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        combilearray.push(self.forguarantor1);
      }
      else
      {
        self.validateerrorguarantor1();
        self.loadingdialog = false;
      }
      if (
        self.guarantor2.name != null ||
        self.guarantor2.surname != null ||
        self.guarantor2.cardID != null ||
        self.date7 != "" ||
        self.guarantor2.issueplace != null ||
        self.guarantor2.address != null ||
        self.guarantor2.mobile != null ||
        self.guarantor2.carrer != null ||
        self.guarantor2.workplace != null ||
        self.guarantor2.position != null ||
        self.guarantor2.telephone != null ||
        self.guarantor2.relationship != ""
      )
      {
        if (tempvalidate2)
        {
          self.forguarantor2 = {
            gurantor_Name: self.guarantor2.name,
            surname: self.guarantor2.surname,
            iD_Card: self.guarantor2.cardID,
            // expire_Date: self.date7,
            expire_Date: self.$moment(self.date7,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            issuing_Place: self.guarantor2.issueplace,
            address: self.guarantor2.address,
            mobile_Number: self.guarantor2.mobile,
            career: self.guarantor2.carrer,
            work_Place: self.guarantor2.workplace,
            position: self.guarantor2.position,
            telephone: self.guarantor2.telephone,
            relationship: self.guarantor2.relationship,
            employee_ID: localStorage.empcode,
            company_ID: store.state.companyID,
          };
          combilearray.push(self.forguarantor2);
        }
        else
        {
          self.validateerrorguarantor2();
          self.loadingdialog = false;
        }
      } 
      else
      {
        tempvalidate2 = true;
      }

      if (tempvalidate1 == true && tempvalidate2 == true)
      {
        axios.post(`${self.url}Guarantor/AddGuarantor`, combilearray)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.resetformguarantor1();
            self.GetguarantorByCode();
            self.loadingdialog = false;
            if (tempvalidate1 == true)
            {
              self.error1.name = null;
              self.error1.surname = null;
              self.error1.expireddate = null;
              self.error1.issue = null;
              self.error1.address = null;
              self.error1.telephone = null;
              self.error1.career = null;
              self.error1.workplace = null;
              self.error1.position = null;
              self.error1.IDcard = null;
              self.error1.relationship = null;
              self.error1.mobile = null;
            }
            if (tempvalidate2 == true)
            {
              self.error2.name = null;
              self.error2.surname = null;
              self.error2.expireddate = null;
              self.error2.issue = null;
              self.error2.address = null;
              self.error2.telephone = null;
              self.error2.career = null;
              self.error2.workplace = null;
              self.error2.position = null;
              self.error2.IDcard = null;
              self.error2.relationship = null;
              self.error2.mobile = null;
            }

            // self.LogTrace(null, "Add  Guarantor", 20, "Low");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Guarantor Fail", 20, "Low");
          alert(error);
        });
      }
    },

    // add guarantor with route employeeID
    AddnewGuarantor() {
      let combilearray1 = [];
      let tempnewvalidate1 = this.validateaddguarantor1();
      let tempnewvalidate2 = this.validateaddguarantor2();
      let self = this;
      self.loadingdialog = true;
      if (tempnewvalidate1)
      {
        self.forguarantor1 = {
          gurantor_Name: self.guarantor1.name,
          surname: self.guarantor1.surname,
          iD_Card: self.guarantor1.cardID,
          // expire_Date: self.date6,
          expire_Date: self.$moment(self.date6, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          issuing_Place: self.guarantor1.issueplace,
          address: self.guarantor1.address,
          mobile_Number: self.guarantor1.mobile,
          career: self.guarantor1.carrer,
          work_Place: self.guarantor1.workplace,
          position: self.guarantor1.position,
          telephone: self.guarantor1.telephone,
          relationship: self.guarantor1.relationship,
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        combilearray1.push(self.forguarantor1);
      }
      else 
      {
        self.validateerrorguarantor1();
        self.loadingdialog = false;
      }

      if (
        self.guarantor2.name != null ||
        self.guarantor2.surname != null ||
        self.guarantor2.cardID != null ||
        self.date7 != null ||
        self.guarantor2.issueplace != null ||
        self.guarantor2.address != null ||
        self.guarantor2.mobile != null ||
        self.guarantor2.carrer != null ||
        self.guarantor2.workplace != null ||
        self.guarantor2.position != null ||
        self.guarantor2.telephone != null ||
        self.guarantor2.relationship != ""
      )
      {
        if (tempnewvalidate2)
        {
          self.forguarantor2 = {
            gurantor_Name: self.guarantor2.name,
            surname: self.guarantor2.surname,
            iD_Card: self.guarantor2.cardID,
            // expire_Date: self.date7,
            expire_Date: self.$moment(self.date7,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            issuing_Place: self.guarantor2.issueplace,
            address: self.guarantor2.address,
            mobile_Number: self.guarantor2.mobile,
            career: self.guarantor2.carrer,
            work_Place: self.guarantor2.workplace,
            position: self.guarantor2.position,
            telephone: self.guarantor2.telephone,
            relationship: self.guarantor2.relationship,
            employee_ID: parseInt(self.$route.params.id),
            company_ID: store.state.companyID,
          };
          combilearray1.push(self.forguarantor2);
        }
        else
        {
          self.validateerrorguarantor2();
          self.loadingdialog = false;
        }
      }
      else
      {
        tempnewvalidate2 = true;
      }

      if (tempnewvalidate1 == true && tempnewvalidate2 == true)
      {
        axios.post(`${self.url}Guarantor/AddNewGuarantor`, combilearray1)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.resetformguarantor1();
            self.Getguarantor();
            if (tempnewvalidate1 == true)
            {
              self.error1.name = null;
              self.error1.surname = null;
              self.error1.expireddate = null;
              self.error1.issue = null;
              self.error1.address = null;
              self.error1.telephone = null;
              self.error1.career = null;
              self.error1.workplace = null;
              self.error1.position = null;
              self.error1.IDcard = null;
              self.error1.relationship = null;
              self.error1.mobile = null;
            }
            if (tempnewvalidate2 == true)
            {
              self.error2.name = null;
              self.error2.surname = null;
              self.error2.expireddate = null;
              self.error2.issue = null;
              self.error2.address = null;
              self.error2.telephone = null;
              self.error2.career = null;
              self.error2.workplace = null;
              self.error2.position = null;
              self.error2.IDcard = null;
              self.error2.relationship = null;
              self.error2.mobile = null;
            }
            //self.LogTrace(null, "Add Guarantor", 20, "Low");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Guarantor Fail", 20, "Low");
          alert(error);
        });
      }
    },

    // add guarantor with session employee id
    AddnewGuarantorWithSid() {
      let combilearray1 = [];
      let tempnewvalidate1 = this.validateaddguarantor1();
      let tempnewvalidate2 = this.validateaddguarantor2();
      let self = this;
      self.loadingdialog = true;
      if (tempnewvalidate1)
      {
        self.forguarantor1 = {
          gurantor_Name: self.guarantor1.name,
          surname: self.guarantor1.surname,
          iD_Card: self.guarantor1.cardID,
          // expire_Date: self.date6,
          expire_Date: self.$moment(self.date6, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          issuing_Place: self.guarantor1.issueplace,
          address: self.guarantor1.address,
          mobile_Number: self.guarantor1.mobile,
          career: self.guarantor1.carrer,
          work_Place: self.guarantor1.workplace,
          position: self.guarantor1.position,
          telephone: self.guarantor1.telephone,
          relationship: self.guarantor1.relationship,
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        combilearray1.push(self.forguarantor1);
      }
      else
      {
        self.validateerrorguarantor1();
        self.loadingdialog = false;
      }

      if (
        self.guarantor2.name != null ||
        self.guarantor2.surname != null ||
        self.guarantor2.cardID != null ||
        self.date7 != null ||
        self.guarantor2.issueplace != null ||
        self.guarantor2.address != null ||
        self.guarantor2.mobile != null ||
        self.guarantor2.carrer != null ||
        self.guarantor2.workplace != null ||
        self.guarantor2.position != null ||
        self.guarantor2.telephone != null ||
        self.guarantor2.relationship != ""
      )
      {
        if (tempnewvalidate2)
        {
          self.forguarantor2 = {
            gurantor_Name: self.guarantor2.name,
            surname: self.guarantor2.surname,
            iD_Card: self.guarantor2.cardID,
            // expire_Date: self.date7,
            expire_Date: self.$moment(self.date7,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
            issuing_Place: self.guarantor2.issueplace,
            address: self.guarantor2.address,
            mobile_Number: self.guarantor2.mobile,
            career: self.guarantor2.carrer,
            work_Place: self.guarantor2.workplace,
            position: self.guarantor2.position,
            telephone: self.guarantor2.telephone,
            relationship: self.guarantor2.relationship,
            employee_ID: store.state.employeeID,
            company_ID: store.state.companyID,
          };
          combilearray1.push(self.forguarantor2);
        }
        else
        {
          self.validateerrorguarantor2();
          self.loadingdialog = false;
        }
      }
      else
      {
        tempnewvalidate2 = true;
      }

      if (tempnewvalidate1 == true && tempnewvalidate2 == true)
      {
        axios.post(`${self.url}Guarantor/AddNewGuarantor`, combilearray1)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.resetformguarantor1();
            self.GetguarantorBySid();
            if (tempnewvalidate1)
            {
              self.error1.name = null;
              self.error1.surname = null;
              self.error1.expireddate = null;
              self.error1.issue = null;
              self.error1.address = null;
              self.error1.telephone = null;
              self.error1.career = null;
              self.error1.workplace = null;
              self.error1.position = null;
              self.error1.IDcard = null;
              self.error1.relationship = null;
              self.error1.mobile = null;
            }
            if (tempnewvalidate2 == true)
            {
              self.error2.name = null;
              self.error2.surname = null;
              self.error2.expireddate = null;
              self.error2.issue = null;
              self.error2.address = null;
              self.error2.telephone = null;
              self.error2.career = null;
              self.error2.workplace = null;
              self.error2.position = null;
              self.error2.IDcard = null;
              self.error2.relationship = null;
              self.error2.mobile = null;
            }
            //self.LogTrace(null, "Add Guarantor", 20, "Low");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Guarantor Fail", 20, "Low");
          alert(error);
        });
      }
    },

    // validation of fields for guarantor 1
    validateaddguarantor1() {
      let self = this;
      let mobilevali = self.mobilenumbervalidation(self.guarantor1.mobile);
      // let televali = self.telephonenumbervalidation(self.guarantor1.telephone);
      let cardvali = self.cardIDvalidation(self.guarantor1.cardID);
      if (
        cardvali == true &&
        mobilevali == true
        // self.guarantor1.name != null &&
        // self.guarantor1.surname != null &&
        // self.guarantor1.cardID != null &&
        // self.date6 != "" &&
        // self.guarantor1.issueplace != null &&
        // self.guarantor1.address != null &&
        // self.guarantor1.mobile != null &&
        // self.guarantor1.carrer != null &&
        // self.guarantor1.workplace != null &&
        // self.guarantor1.position != null &&
        // self.guarantor1.telephone != null &&
        // self.guarantor1.relationship != "" &&
        // self.guarantor1.name != "" &&
        // self.guarantor1.surname != "" &&
        // self.guarantor1.cardID != "" &&
        // self.date6 != null &&
        // self.guarantor1.issueplace != "" &&
        // self.guarantor1.address != "" &&
        // self.guarantor1.mobile != "" &&
        // self.guarantor1.carrer != "" &&
        // self.guarantor1.workplace != "" &&
        // self.guarantor1.position != "" &&
        // self.guarantor1.telephone != "" &&
        // self.guarantor1.relationship != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    //validate error for guarantor 1
    validateerrorguarantor1() {
      let self = this;
      let mobilevali = self.mobilenumbervalidation(self.guarantor1.mobile);
      // let televali = self.telephonenumbervalidation(self.guarantor1.telephone);
      let cardvali = self.cardIDvalidation(self.guarantor1.cardID);
      // if (
      //   self.guarantor1.name == null ||
      //   self.guarantor1.surname == null ||
      //   self.guarantor1.cardID == null ||
      //   self.date6 == "" ||
      //   self.guarantor1.issueplace == null ||
      //   self.guarantor1.address == null ||
      //   self.guarantor1.mobile == null ||
      //   self.guarantor1.carrer == null ||
      //   self.guarantor1.workplace == null ||
      //   self.guarantor1.position == null ||
      //   self.guarantor1.telephone == null ||
      //   self.guarantor1.relationship == "" ||
      //   self.guarantor1.name == "" ||
      //   self.guarantor1.surname == "" ||
      //   self.guarantor1.cardID == "" ||
      //   self.date6 == null ||
      //   self.guarantor1.issueplace == "" ||
      //   self.guarantor1.address == "" ||
      //   self.guarantor1.mobile == "" ||
      //   self.guarantor1.carrer == "" ||
      //   self.guarantor1.workplace == "" ||
      //   self.guarantor1.position == "" ||
      //   self.guarantor1.telephone == "" ||
      //   self.guarantor1.relationship == null
      // ) {
      //   alert("Please fill all the guarantor 1 fields");
      //   this.error1.name = !this.guarantor1.name
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error1.surname = !this.guarantor1.surname
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error1.IDcard = !this.guarantor1.cardID
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error1.expireddate = !this.date6
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error1.issue = !this.guarantor1.issueplace
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error1.address = !this.guarantor1.address
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error1.mobile = !this.guarantor1.mobile
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error1.career = !this.guarantor1.carrer
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error1.workplace = !this.guarantor1.workplace
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error1.position = !this.guarantor1.position
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error1.telephone = !this.guarantor1.telephone
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error1.relationship = !this.guarantor1.relationship
      //     ? "Please fill in the required fields"
      //     : "";
      // } else
      if (mobilevali != true && cardvali != true) {
        alert("Please fill the correct format");
        self.error1.mobile =
          self.guarantor1.mobile != mobilevali
            ? "Please fill at least 9 or 11 mobile numbers"
            : self.guarantor1.mobile;

        // self.error1.telephone =
        //   self.guarantor1.telephone != televali
        //     ? "Please fill 9 digit telephone numbers"
        //     : self.guarantor1.telephone;

        self.error1.IDcard =
          self.guarantor1.cardID != cardvali
            ? "Please fill 13 digits ID card numbers"
            : self.guarantor1.cardID;
        self.error1.name = null;
        self.error1.surname = null;
        self.error1.expireddate = null;
        self.error1.issue = null;
        self.error1.address = null;
        self.error1.career = null;
        self.error1.workplace = null;
        self.error1.position = null;

        self.error1.relationship = null;
      } else if (mobilevali != true) {
        alert("Please fill the correct format");
        self.error1.mobile =
          self.guarantor1.mobile != mobilevali
            ? "Please fill at least 9 or 11 mobile numbers"
            : self.guarantor1.mobile;
        self.error1.name = null;
        self.error1.surname = null;
        self.error1.expireddate = null;
        self.error1.issue = null;
        self.error1.address = null;
        self.error1.telephone = null;
        self.error1.career = null;
        self.error1.workplace = null;
        self.error1.position = null;
        self.error1.IDcard = null;
        self.error1.relationship = null;
      }
      // else if (televali != true) {
      //   alert("Please fill the correct format");
      //   self.error1.name = null;
      //   self.error1.surname = null;
      //   self.error1.expireddate = null;
      //   self.error1.issue = null;
      //   self.error1.address = null;
      //   self.error1.mobile = null;
      //   self.error1.career = null;
      //   self.error1.workplace = null;
      //   self.error1.position = null;
      //   self.error1.IDcard = null;
      //   self.error1.relationship = null;
      //   self.error1.telephone =
      //     self.guarantor1.telephone != televali
      //       ? "Please fill 9 digit telephone numbers"
      //       : self.guarantor1.telephone;
      // }
      else if (cardvali != true) {
        alert("Please fill the correct format");
        self.error1.name = null;
        self.error1.surname = null;
        self.error1.expireddate = null;
        self.error1.issue = null;
        self.error1.address = null;
        self.error1.mobile = null;
        self.error1.career = null;
        self.error1.workplace = null;
        self.error1.position = null;
        self.error1.telephone = null;
        self.error1.relationship = null;
        self.error1.IDcard =
          self.guarantor1.cardID != cardvali
            ? "Please fill 13 digits ID card numbers"
            : self.guarantor1.cardID;
      }
    },
    // validation of fields for guarantor 1
    validateaddguarantor2() {
      let self = this;
      let mobilevali1 = self.mobilenumbervalidation(self.guarantor2.mobile);
      // let televali1 = self.telephonenumbervalidation(self.guarantor2.telephone);
      let cardvali = self.cardIDvalidation(self.guarantor2.cardID);
      if (
        mobilevali1 == true &&
        cardvali == true

        // self.guarantor2.name != null &&
        // self.guarantor2.surname != null &&
        // self.guarantor2.cardID != null &&
        // self.date7 != "" &&
        // self.guarantor2.issueplace != null &&
        // self.guarantor2.address != null &&
        // self.guarantor2.mobile != null &&
        // self.guarantor2.carrer != null &&
        // self.guarantor2.workplace != null &&
        // self.guarantor2.position != null &&
        // self.guarantor2.telephone != null &&
        // self.guarantor2.relationship != "" &&
        // self.guarantor2.name != "" &&
        // self.guarantor2.surname != "" &&
        // self.guarantor2.cardID != "" &&
        // self.date7 != null &&
        // self.guarantor2.issueplace != "" &&
        // self.guarantor2.address != "" &&
        // self.guarantor2.mobile != "" &&
        // self.guarantor2.carrer != "" &&
        // self.guarantor2.workplace != "" &&
        // self.guarantor2.position != "" &&
        // self.guarantor2.telephone != "" &&
        // self.guarantor2.relationship != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    //validate error for guarantor 2
    validateerrorguarantor2() {
      let self = this;
      let mobilevali1 = self.mobilenumbervalidation(self.guarantor2.mobile);
      // let televali1 = self.telephonenumbervalidation(self.guarantor2.telephone);
      let cardvali = self.cardIDvalidation(self.guarantor2.cardID);
      // if (
      //   self.guarantor2.name == null ||
      //   self.guarantor2.surname == null ||
      //   self.guarantor2.cardID == null ||
      //   self.date7 == "" ||
      //   self.guarantor2.issueplace == null ||
      //   self.guarantor2.address == null ||
      //   self.guarantor2.mobile == null ||
      //   self.guarantor2.carrer == null ||
      //   self.guarantor2.workplace == null ||
      //   self.guarantor2.position == null ||
      //   self.guarantor2.telephone == null ||
      //   self.guarantor2.relationship == "" ||
      //   self.guarantor2.name == "" ||
      //   self.guarantor2.surname == "" ||
      //   self.guarantor2.cardID == "" ||
      //   self.date7 == null ||
      //   self.guarantor2.issueplace == "" ||
      //   self.guarantor2.address == "" ||
      //   self.guarantor2.mobile == "" ||
      //   self.guarantor2.carrer == "" ||
      //   self.guarantor2.workplace == "" ||
      //   self.guarantor2.position == "" ||
      //   self.guarantor2.telephone == "" ||
      //   self.guarantor2.relationship == null
      // ) {
      //   alert("Please fill all the required guarantor 2 fields");
      //   this.error2.name = !this.guarantor2.name
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error2.surname = !this.guarantor2.surname
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error2.IDcard = !this.guarantor2.cardID
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error2.expireddate = !this.date7
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error2.issue = !this.guarantor2.issueplace
      //     ? "Please fill in the required fields"
      //     : "";

      //   this.error2.address = !this.guarantor2.address
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error2.mobile = !this.guarantor2.mobile
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error2.career = !this.guarantor2.carrer
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error2.workplace = !this.guarantor2.workplace
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error2.position = !this.guarantor2.position
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error2.telephone = !this.guarantor2.telephone
      //     ? "Please fill in the required fields"
      //     : "";
      //   this.error2.relationship = !this.guarantor2.relationship
      //     ? "Please fill in the required fields"
      //     : "";
      // } else if (mobilevali1 != true && cardvali != true) {
      //   alert("Please fill the correct format");
      //   self.error2.mobile =
      //     self.guarantor2.mobile != mobilevali1
      //       ? "Please fill at least 9 or 11 mobile numbers"
      //       : self.guarantor2.mobile;

      //   // self.error2.telephone =
      //   //   self.guarantor2.telephone != televali1
      //   //     ? "Please fill 9 digit telephone numbers"
      //   //     : self.guarantor2.telephone;

      //   self.error2.IDcard =
      //     self.guarantor2.cardID != cardvali
      //       ? "Please fill 13 digits ID card numbers"
      //       : self.guarantor1.cardID;
      //   self.error2.name = null;
      //   self.error2.surname = null;
      //   self.error2.expireddate = null;
      //   self.error2.issue = null;
      //   self.error2.address = null;
      //   self.error2.career = null;
      //   self.error2.workplace = null;
      //   self.error2.position = null;
      //   self.error2.relationship = null;
      // } else
      if (mobilevali1 != true && cardvali != true) {
        alert("Please fill the correct format");
        self.error2.mobile =
          self.guarantor2.mobile != mobilevali1
            ? "Please fill at least 9 or 11 mobile numbers"
            : self.guarantor2.mobile;

        // self.error1.telephone =
        //   self.guarantor1.telephone != televali
        //     ? "Please fill 9 digit telephone numbers"
        //     : self.guarantor1.telephone;

        self.error2.IDcard =
          self.guarantor1.cardID != cardvali
            ? "Please fill 13 digits ID card numbers"
            : self.guarantor1.cardID;
        self.error2.name = null;
        self.error2.surname = null;
        self.error2.expireddate = null;
        self.error2.issue = null;
        self.error2.address = null;
        self.error2.career = null;
        self.error2.workplace = null;
        self.error2.position = null;

        self.error2.relationship = null;
      } else if (mobilevali1 != true) {
        alert("Please fill the correct format");
        self.error2.mobile =
          self.guarantor2.mobile != mobilevali1
            ? "Please fill at least 9 or 11 mobile numbers"
            : self.guarantor2.mobile;

        self.error2.name = null;
        self.error2.surname = null;
        self.error2.expireddate = null;
        self.error2.issue = null;
        self.error2.address = null;
        self.error2.telephone = null;
        self.error2.career = null;
        self.error2.workplace = null;
        self.error2.position = null;
        self.error2.IDcard = null;
        self.error2.relationship = null;
      }
      // else if (televali1 != true) {
      //   alert("Please fill the correct format");
      //   self.error2.telephone =
      //     self.guarantor2.telephone != televali1
      //       ? "Please fill 9 digit telephone numbers"
      //       : self.guarantor2.telephone;
      //   self.error2.name = null;
      //   self.error2.surname = null;
      //   self.error2.expireddate = null;
      //   self.error2.issue = null;
      //   self.error2.address = null;
      //   self.error2.mobile = null;
      //   self.error2.career = null;
      //   self.error2.workplace = null;
      //   self.error2.position = null;
      //   self.error2.IDcard = null;
      //   self.error2.relationship = null;
      // }
      else if (cardvali != true) {
        alert("Please fill the correct format");
        self.error2.name = null;
        self.error2.surname = null;
        self.error2.expireddate = null;
        self.error2.issue = null;
        self.error2.address = null;
        self.error2.mobile = null;
        self.error2.career = null;
        self.error2.workplace = null;
        self.error2.position = null;
        self.error2.telephone = null;
        self.error2.relationship = null;
        self.error2.IDcard =
          self.guarantor2.cardID != cardvali
            ? "Please fill 13 digits ID card numbers"
            : self.guarantor2.cardID;
      }
    },
    resetformguarantor1() {
      let self = this;
      self.error1.name = null;
      self.error1.surname = null;
      self.error1.IDcard = null;
      self.error1.expireddate = null;
      self.error1.issue = null;
      self.error1.address = null;
      self.error1.mobile = null;
      self.error1.career = null;
      self.error1.workplace = null;
      self.error1.position = null;
      self.error1.telephone = null;
      self.error1.relationship = null;
    },
    resertformguarantor2() {
      let self = this;
      self.error2.name = null;
      self.error2.surname = null;
      self.error2.IDcard = null;
      self.error2.expireddate = null;
      self.error2.issue = null;
      self.error2.address = null;
      self.error2.mobile = null;
      self.error2.career = null;
      self.error2.workplace = null;
      self.error2.position = null;
      self.error2.telephone = null;
      self.error2.relationship = null;
    },
  },
};
</script>

<style scoped>
>>> .v-select__selections {
  padding-left: unset !important;
}
.labInfo {
  color: #f89d1f;
  font-size: 15px;
  margin-left: -770px;
}
.empID {
  margin: 0px;
  padding: 0px;
  font-size: 15px;
}
.content {
  padding-top: 0;
  margin-top: -1.5rem;
}
@media only screen and (max-width: 414px) {
  .save_btn {
    margin-top: 1rem !important;
    margin-left: 1.5rem !important;
  }
  .cancle_btn {
    margin-left: 1.5rem !important;
  }
}
</style>
