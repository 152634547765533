<script>
  import { Line } from 'vue-chartjs'

  export default {
    extends: Line,
    props: ['chartData', 'options'],
    mounted() {
      this.renderBarChart();
    },
    methods: {
        renderBarChart: function(){
            this.renderChart(this.chartData, this.options);
        }
    },
    watch: {
        chartData: function () {
            this.renderBarChart();
        }
    }
  }
</script>