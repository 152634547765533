<template>
  <div style="margin-left: 1.5rem;margin-bottom:3.5rem;margin-right: 1.5rem">
    <p class="txt-header">
      {{ $t("overt") }}
    </p>
    <v-tabs class="mt-6" grey--text background-color="#f0f8fc" slider-color="#F99D20" v-model="activeTab">
      <v-tab class="text-capitalize" style="border-radius: 10px 10px 0px 5px;width: 250px;font-size: 16px; margin-bottom: 11px;">
          {{ $t("DocumentsRequest") }}
      </v-tab>
    <v-tab-item>
        <v-card>
          <Request />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
// import store from "@/store";
// import axios from "axios";
import enurl from "@/api/environment";
import Request from "./EmpOTRequest.vue";
// import Staffrequest from "./StaffOT";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  components: {
    // Welcome,
    // NewCompany,
    Request,
    // Staffrequest,
  },
  data() {
    return {
      // show: false,
      personallist: [],
      url: enurl.apiUrl,
      activeTab: true,
    };
  },


};
</script>

<style scoped>
::v-deep .v-window{
  overflow: visible!important;
}
</style>