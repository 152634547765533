<template>
    <div>
        <v-row class="mb-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                <v-card class="secondrowcard">
                    <v-row class="headrow1">
                        <v-col cols="5">
                            <p class="ml-3 mb-0">
                                {{ $t("UserAttendanceStatus") }}
                            </p>
                        </v-col>

                        <v-spacer></v-spacer>
                        <v-col cols="4" class="pt-1 mr-3">
                            <date-picker class="customicon-vuedatepicker" v-bind:clearable="false"
                                v-model="gettodaydate" format="DD/MM/YYYY" :disabled-date="(date) => date > new Date()"
                                placeholder="DD/MM/YYYY" @change="GetAttendance(1)">
                            </date-picker>
                        </v-col>
                    </v-row>
                    <v-divider class="ml-3 mr-3 mt-2"></v-divider>

                    <v-container class="mt-3">
                        <v-row>
                            <v-col cols="6" align="center" class="colpd">
                                <div class="status-box">
                                    <p class="status-number">
                                        {{ PresentEmp }}
                                    </p>
                                    <p class="status-text present">
                                        {{ $t("Present") }}
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="6" align="center" class="colpd">
                                <div class="status-box">
                                    <p class="status-number">
                                        {{ LateEmp }}
                                    </p>
                                    <p class="status-text late">
                                        {{ $t("Late") }}
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" align="center" class="colpd">
                                <div class="status-box">
                                    <p class="status-number">
                                        {{ AbsentEmp }}
                                    </p>
                                    <p class="status-text absent">
                                        {{ $t("Absent") }}
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="6" align="center" class="colpd">
                                <div class="status-box">
                                    <p class="status-number">
                                        {{ onLeaveEmp }}
                                    </p>
                                    <p class="status-text leave-request">
                                        {{ $t("leaverequest") }}
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card>
            </v-col>
            <v-col class="px-sm-and-up-0" cols="12" xs="12" sm="12" md="12" lg="6">
                <v-card class="secondrowcard">

                    <v-row class="headrow1">
                        <v-col cols="4">
                            <p class="ml-3 mb-0">
                                {{ $t("LeaveToken") }}
                            </p>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="pt-1 mr-3">
                            <date-picker class="customicon-vuedatepicker" v-bind:clearable="false"
                                v-model="gettodaydate1" format="DD/MM/YYYY" :disabled-date="(date) => date > new Date()"
                                placeholder="DD/MM/YYYY" @change="GetAttendance(2)">
                            </date-picker>
                        </v-col>
                    </v-row>
                    <v-divider class="ml-3 mr-3 mt-2"></v-divider>
                    <v-row class="mt-5">
                        <v-col cols="12" xs="12" sm="6" md="5" lg="5" align="center" class="mycol1">
                            <div style="
                                max-width: 280px;
                                max-height: 150px;
                                min-width: 280px;
                                min-height: 150px;
                                margin-left: 10px;
                                margin-top: 5px;
                            ">
                                <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
                            </div>
                        </v-col>
                        <v-col align="center" cols="12" xs="12" sm="6" md="6" lg="6" class="mycol">
                            <div class="mt-5">
                                <v-row v-for="item in LeaveList" :key="item.leaveType_Name_Eng"
                                    class="d-flex-row flex-wrap align-start ml-4 mr-4">
                                    <p v-show="!thethaihead" class="ml-5">
                                        {{ item.leaveType_Name_Eng }}
                                    </p>
                                    <p v-show="thethaihead" class="ml-5">
                                        {{ item.leaveType_Name }}
                                    </p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ item.leaveTake }}
                                    </p>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
    </div>
</template>
<script>
import Axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import VueApexCharts from "vue-apexcharts";
import LoadingDialog from "@/components/Loading.vue";
export default {
    name: 'ApexDonutChart',
    components: {
        apexchart: VueApexCharts,
        LoadingDialog
    },
    data() {
        return {
            url: enurl.apiUrl,
            LoadingDialog: false,
            gettodaydate: new Date(),
            gettodaydate1: new Date(),
            showcal: false,
            AttendanceOption: 1,
            PresentEmp: 0,
            LateEmp: 0,
            AbsentEmp: 0,
            onLeaveEmp: 0,
            LeaveList: [],
            thelang: "",
            LeaveSeries: [],
            chartOptions: {
                labels: [],
                chart: {
                    type: 'pie',
                    width: 280,
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false,
                },
                colors: [
                    "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51",
                    "#40ba9e", "#7978b1", "#e5da6c", "#ee749b",
                    "#00afb9", "#fdfcdc", "#fed9b7", "#f07167"
                ],
                plotOptions: {
                    expandOnClick: true,
                },
            }
        };
    },
    mounted() {
        //this.shuffleArray(this.chartOptions.colors);
        this.GetAttendance(3);
        this.timerInterval = setInterval(() => {
            this.thelang = localStorage.getItem('Templang')
        }, 100);
    },
    computed: {
        thethaihead() {
            return this.whatthehead();
        },
        series() {
            return this.LeaveSeries;
        },
    },
    watch: {
        thelang() {
            this.whatthehead();
        },
    },
    methods: {
        whatthehead() {
            if (this.thelang == "ประเทศไทย") {
                return true;
            } else {
                return false;
            }
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        },
        GetAttendance(v) {
            try {
                let self = this;
                self.LoadingDialog = true;
                let abouttoday = new Date();
                if (v == 1) {
                    abouttoday = new Date(self.gettodaydate);
                } else if (v == 2) {
                    abouttoday = new Date(self.gettodaydate1);
                }
                let month = String(abouttoday.getMonth() + 1);
                let day = String(abouttoday.getDate());
                const year = String(abouttoday.getFullYear());
                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;
                let getdate = `${day}/${month}/${year}`;
                let temp = {
                    company_ID: store.state.companyID,
                    callType: v,
                    today_Date: getdate
                };
                Axios
                    .post(`${self.url}Dashboard/GetEmployeeAttendancenLeave`, temp)
                    .then(function (response) {
                        if (response.data.status == 0) {
                            let result = response.data.data;
                            if (v == 1 || v == 3) {
                                self.PresentEmp = result.present;
                                self.LateEmp = result.late;
                                self.AbsentEmp = result.absent;
                                self.onLeaveEmp = result.onLeave;
                            }

                            if (v == 2 || v == 3) {
                                self.LeaveList = result.leaveTypeList;
                                self.LeaveSeries = self.LeaveList.map(item => item.leaveTake);
                                self.chartOptions = {
                                    ...self.chartOptions,
                                    labels: self.LeaveList.map(item => item.leaveType_Name_Eng || "On Leave")
                                };
                            }
                            self.LoadingDialog = false;
                        }
                    })
                    .catch(function (error) {
                        alert(error);
                        self.LoadingDialog = false;
                    });

            } catch (ex) {
                throw ex;
            }
        },
        showCalendar(v) {
            if (v != 1) {
                this.showcal = true;
            } else {
                this.showcal = false;
            }
        }
    },

};
</script>
<style scoped>
.secondrowcard {
    min-height: 400px;
    margin-top: 12px;
}

.secondrowcard::-webkit-scrollbar {
    display: none;
    /* Hides scrollbar in Chrome, Safari and Opera */
}

.date-container {
    margin-top: auto;
    padding: 0 16px;
    /* Adjust as needed to align with the card's padding */
    text-align: center;
}

.colpd {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.status-box {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fafafa;
    height: 100%;
}

.status-number {
    font-size: 45px;
    margin: 0;
    font-weight: lighter;
}

.status-text {
    margin-top: 8px;
    font-size: 20px;
    font-weight: lighter;
}

.present {
    color: #0EB425;
}

.late {
    color: #FF4B55;
}

.absent {
    color: #707070;
}

.leave-request {
    color: #0FA7D8;
}

.headrow {
    max-height: 50px;
    padding-top: 5px;
}

.mycol1 {
    height: 230px;
}
.mycol {
    height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
}

.mycol::-webkit-scrollbar {
    display: none;
}

::v-deep .mx-datepicker {
    position: relative;
    display: inline-block;
    width: 100%;
}
</style>