export default {
  methods: {
    //Home-Staff Time Attendance Pie Chart,Upcoming Time Off
    //Version and Release
    //Notification
    CheckHomeStaffTimeAttPieChart_UpcomingTimeOff(a) {
      let temp = {
        view: false,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.view = true;
      } else {
        if (permissionID.some(e => e == a)) {
          temp.view = true;
        }
      }
      return temp;
    },

    // Master Data(Organization,Department,Position,Address,SSF,ProvidentFund,Bank,ShiftCode,Benefits,Timeoff,IncomeTax)
    CheckViewAddEditDeleteUpload(a, b, c, d, f) {
      let temp = {
        add: true,
        edit: true,
        delete: true,
        upload: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.add = false;
        temp.edit = false;
        temp.delete = false;
        temp.upload = false;
      } else {
        if (permissionID.some(e => e == a)) {
          temp.add = true;
          temp.edit = true;
          temp.delete = true;
          temp.upload = true;
        }
        if (permissionID.some(e => e == b)) {
          temp.add = false;
        }
        if (permissionID.some(e => e == c)) {
          temp.edit = false;
        }
        if (permissionID.some(e => e == d)) {
          temp.delete = false;
        }
        if (permissionID.some(e => e == f)) {
          temp.upload = false;
        }
      }
      return temp;
    },

    //Home
    CheckViewAddEditDeleteClockInOut(n, o, p, q, r) {
      let temp = {
        add: true,
        edit: true,
        delete: true,
        clockinout: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.add = false;
        temp.edit = false;
        temp.delete = false;
        temp.clockinout = false;
      } else {
        if (permissionID.some(e => e == n)) {
          temp.add = true;
          temp.edit = true;
          temp.delete = true;
          temp.clockinout = true;
        }
        if (permissionID.some(e => e == o)) {
          temp.add = false;
        }
        if (permissionID.some(e => e == p)) {
          temp.edit = false;
        }
        if (permissionID.some(e => e == q)) {
          temp.delete = false;
        }
        if (permissionID.some(e => e == r)) {
          temp.clockinout = false;
        }
      }
      return temp;
    },

    //Personal Profile (Social Security , Health Information)
    //TimeAttendance' Setting (Time Groups)
    //Payroll' Setting (Payroll Calculation Period)
    //Setting' Usermanagement (User,UserGroup,Role)
    //Payroll
    //Master Data (Payroll Account)
    //MyTimeOffRequest / MyOTRequest
    //Income Type master data
    CheckViewAddEditDelete(j, k, l, m) {
      let temp = {
        add: true,
        edit: true,
        delete: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.add = false;
        temp.edit = false;
        temp.delete = false;
      } else {
        if (permissionID.some(e => e == j)) {
          temp.add = true;
          temp.edit = true;
          temp.delete = true;
        }
        if (permissionID.some(e => e == k)) {
          temp.add = false;
        }
        if (permissionID.some(e => e == l)) {
          temp.edit = false;
        }
        if (permissionID.some(e => e == m)) {
          temp.delete = false;
        }
      }
      return temp;
    },

    //Personal Profile (Personal information,Salary,IncomeTax,Provident fund,Benefit,Guarantor,Family)
    //Time Attendance' Setting (Shift Assignment)
    CheckViewAddEdit(h, i, n) {
      let temp = {
        add: true,
        edit: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.add = false;
        temp.edit = false;
      } else {
        if (permissionID.some(e => e == h)) {
          temp.add = true;
          temp.edit = true;
        }
        if (permissionID.some(e => e == i)) {
          temp.add = false;
        }
        if (permissionID.some(e => e == n)) {
          temp.edit = false;
        }
      }
      return temp;
    },

    //Time Entries Setting
    //Change Password
    //Withholding tax certificate report
    CheckViewAdd(s, t) {
      let temp = {
        add: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.add = false;
      } else {
        if (permissionID.some(e => e == s)) {
          temp.add = true;
        }
        if (permissionID.some(e => e == t)) {
          temp.add = false;
        }
      }
      return temp;
    },
    //enable_login
    CheckViewAdd_enablelogin(s, t) {
      let temp = {
        add: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin") {
        temp.add = false;
      } else {
        if (permissionID.some(e => e == s)) {
          temp.add = true;
        }
        if (permissionID.some(e => e == t)) {
          temp.add = false;
        }
      }
      return temp;
    },

    //Time Attendance (Setting)
    //Time Off
    //Brand Identity
    //Default Language
    //Other Setting
    //Payroll Deduction Setting
    //Employee Management Salary
    //Employee Management Account
    //Employee Management PersonalProfile
    //Employee Management Income Tax
    //Employee Management Social Security
    //Employee Management Provident Fund
    //Employee Management Health Information
    //Employee Management Benefit
    //Employee Management Guarantor
    //Employee Management Family
    CheckViewEdit(o, p) {
      let temp = {
        edit: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.edit = false;
      } else {
        if (permissionID.some(e => e == o)) {
          temp.edit = true;
        }
        if (permissionID.some(e => e == p)) {
          temp.edit = false;
        }
      }
      return temp;
    },

    //HRTimeOff
    //StaffTimeOff
    //HelpAndFeedback
    CheckViewAddDelete(s, t, u) {
      let temp = {
        add: true,
        delete: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.add = false;
        temp.delete = false;
      } else {
        if (permissionID.some(e => e == s)) {
          temp.add = true;
          temp.delete = true;
        }
        if (permissionID.some(e => e == t)) {
          temp.add = false;
        }
        if (permissionID.some(e => e == u)) {
          temp.delete = false;
        }
      }
      return temp;
    },

    //TimeEntries
    CheckViewClockInSetting(oo, pp, vv) {
      let temp = {
        clockin: true,
        viewsetting: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.clockin = false;
        temp.viewsetting = false;
      } else {
        if (permissionID.some(e => e == oo)) {
          temp.clockin = true;
          temp.viewsetting = true;
        }
        if (permissionID.some(e => e == pp)) {
          temp.clockin = false;
        }
        if (permissionID.some(e => e == vv)) {
          temp.viewsetting = false;
        }
      }
      return temp;
    },

    //Employee OT Request
    // Addition , Termination , Age and Gender , Salary History , last Pay Change , Time Off Used , Social Security REport
    CheckViewExport(o, p) {
      let temp = {
        export: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.export = false;
      } else {
        if (permissionID.some(e => e == o)) {
          temp.export = true;
        }
        if (permissionID.some(e => e == p)) {
          temp.export = false;
        }
      }
      return temp;
    },

    //Employee Time Off request
    CheckViewExportDelete(o, p , d) {
      let temp = {
        export: true,
        delete: true
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.export = false;
        temp.delete = false;
      } else {
        if (permissionID.some(e => e == o)) {
          temp.export = true;
          temp.delete = true;
        }
        if (permissionID.some(e => e == p)) {
          temp.export = false;
        }
        if(permissionID.some(e =>e == d)){
          temp.delete = false;
        }
      }
      return temp;
    },

    //Staff Time Attendance
    CheckViewApproveSyncTimeExportEdit(r, s, o, p) {
      let temp = {
        approve: true,
        export: true,
        edit: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.approve = false;
        temp.export = false;
        temp.edit = false;
      } else {
        if (permissionID.some(e => e == r)) {
          temp.approve = true;
          temp.export = true;
          temp.edit = true;
        }
        if (permissionID.some(e => e == s)) {
          temp.approve = false;
        }
        if (permissionID.some(e => e == o)) {
          temp.export = false;
        }
        if (permissionID.some(e => e == p)) {
          temp.edit = false;
        }
      }
      return temp;
    },

    //Organization Holidays
    //Personal Management
    CheckViewAddUpload(q, r, s) {
      let temp = {
        add: true,
        upload: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.add = false;
        temp.upload = false;
      } else {
        if (permissionID.some(e => e == q)) {
          temp.add = true;
          temp.upload = true;
        }
        if (permissionID.some(e => e == r)) {
          temp.add = false;
        }
        if (permissionID.some(e => e == s)) {
          temp.upload = false;
        }
      }
      return temp;
    },

    //MyTasks
    CheckViewApproveDeny(q, r, s) {
      let temp = {
        view: true,
        approve: true,
        deny: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.view = false;
        temp.approve = false;
        temp.deny = false;
      } else {
        if (permissionID.some(e => e == q)) {
          temp.view = false;
          temp.approve = true;
          temp.deny = true;
        }
        if (permissionID.some(e => e == r)) {
          temp.approve = false;
        }
        if (permissionID.some(e => e == s)) {
          temp.deny = false;
        }
      }
      return temp;
    },

    //SyncTimeData
    CheckViewRetrieveCreateAdd(kk, ss, tt, ww) {
      let temp = {
        retrieve: true,
        create: true,
        add: true,
      };
      let permissionID = sessionStorage.getItem("permissionID").split(",");
      let roleID = sessionStorage.getItem("roleID");
      if (roleID == "Admin" || roleID == "HR") {
        temp.retrieve = false;
        temp.create = false;
        temp.add = false;
      } else {
        if (permissionID.some(e => e == kk)) {
          temp.retrieve = true;
          temp.create = true;
          temp.add = true;
        }
        if (permissionID.some(e => e == ss)) {
          temp.retrieve = false;
        }
        if (permissionID.some(e => e == tt)) {
          temp.create = false;
        }
        if (permissionID.some(e => e == ww)) {
          temp.add = false;
        }
      }
      return temp;
    },
  },
};
