<template>
  <div>
    <div
      style="
        padding-left: 0px;
        padding-right: 1%;
        margin-bottom: 1rem;
      "
    >
      <v-row>
        <v-btn icon @click="back()" color="white" class="mt-6 ml-6">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p
          text
          style="
            cursor: pointer;
            font-size: 26px;
            margin-top: 0.2rem;
            margin-left: 5px;
            padding: 18px;
          "
          @click="back()"
        >
          {{ $t("passport") }}
        </p>
      </v-row>
    </div>
    <v-card class="ml-4 pt-5 mb-5" height="100vh" style="margin-bottom: 5rem">
      <v-row class="pl-md-0 mt-3" style="margin-left: 15px; margin-right: 10px">
        <p class="ml-2" style="font-size: 26px; color: black">
          {{ $t("passport") }}
        </p>
        <v-spacer></v-spacer>
        <v-btn
          color="#F99D20"
          class="orange--text text-capitalize mr-5"
          width="150"
          max-height="35"
          outlined
          @click="uploaddialog2 = true"
          >{{ $t("upload") }}</v-btn
        >
      </v-row>
      <v-row>
        <!-- <v-col md="2" style="padding-left:0;padding-right:0"></v-col> -->
        <v-col cols="12" v-show="showthis">
          <v-row justify="center">
            <v-col cols="6" md="6">
              <v-img
                v-if="imageUrl"
                v-show="!ss"
                id="hidden"
                contain
                aspect-ratio="2"
                :src="'data:image/png;base64,' + imageUrl"
                class="preview_img_file"
                style="
                  background: #ffffff;
                  color: #707070;
                  font-size: 25px;
                  margin-top: 140px;
                  margin-left: auto;
                  margin-right: auto;
                "
              ></v-img>
              <v-img
                v-show="ss"
                :src="imageUrl1"
                id="hidden"
                class="preview_img_file"
                contain
                aspect-ratio="2"
                style="
                  background: #ffffff;
                  color: #707070;
                  font-size: 25px;
                  margin-top: 140px;
                  margin-left: auto;
                  margin-right: auto;
                "
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <div v-show="showthis" style="width:800px;margin-left:250px">
    
      </div>-->
    </v-card>

    <v-dialog v-model="uploaddialog2" persistent max-width="786">
      <v-card style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            Upload File
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                style="margin-left: -15px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <p class="ml-4 mt-2" v-show="showfile">{{ showfilename }}</p>
              </v-col>
              <v-col
                style="margin-left: -10px; padding-top: 20px"
                cols="5"
                md="5"
              >
                <v-btn v-show="showfile" @click="closeimage()" icon>
                  <img width="20" height="20" src="@/assets/closered.png" alt />
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mb-6">
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  style="text-align: left; float: left; margin-top: -10px"
                  width="150"
                  max-height="35"
                  @click="onPickFile"
                >
                  <p
                    style="
                      margin-top: 1px;
                      letter-spacing: 0;
                      color: #3c4043;
                      font-size: 16px;
                    "
                    class="text-capitalize pt-3"
                  >
                    {{ $t("browse") }}
                  </p>
                  <input
                    ref="image"
                    @change="onFilePicked"
                    accept="image/*"
                    type="file"
                    style="display: none"
                    id="profile_picture"
                  />
                </v-btn>
              </v-col>
              <v-col cols="12" md="9">
                <p style="margin-top: 0px; color: #f74747">
                  **{{ $t("TheImageType") }}**
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" />
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            height="35"
            @click="closeall()"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#F99D20"
            v-show="!showedit"
            class="white--text mr-2 text-capitalize"
            width="120"
            height="35"
            @click="uploadimage(ImageUrl, $event)"
            >{{ $t("upload") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            v-show="showedit"
            class="white--text mr-2 text-capitalize"
            width="120"
            height="35"
            @click="editimage(ImageUrl, $event)"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import checkPermissionOne from "@/function/PersonalManagementPermission.js";
export default {
  //mixins: [checkPermissionOne],
  data() {
    return {
      // permission: {
      //   upload: true,
      // },
      showthis: true,
      showfile: false,
      showfilename: null,
      localImageUrl2: "",
      tempImageUrl: "",
      uploaddialog2: false,
      chosenfile: null,
      imageName: "",
      imageUrl: localStorage.localPassportUrl,
      imageUrl1: "",
      imageFile: "",
      fileName: null,
      workpermit: [],
      work: "",
      ind: 0,
      ss: false,
      showedit: false,
    };
  },
  mounted() {
    let self = this;
    if (self.$route.params.passportid != null) {
      this.showedit = true;
    }
    //this.permission = this.checkPermissionFive(118);
    let task1 = localStorage.localeditPassportUrl;
    this.localImageUrl = task1;
  },
  created: function () {
    let taskwork = JSON.parse(localStorage.getItem("edit"));
    if (this.$route.params.passportid != null) {
      let task = localStorage.localeditPassportUrl;
      if (task == null) {
        this.imageUrl1 = taskwork.passport;
        this.ss = true;
        this.localImageUrl2 = "";
      } else {
        taskwork = "";
        this.imageUrl = task;
        this.localImageUrl2 = task;
      }
    } else {
      let taskDB = JSON.parse(localStorage.getItem("localPassportUrl"));

      if (taskDB == null) {
        this.localImageUrl2 = "";
        this.imageUrl1 = taskwork.passport;
        this.ss = true;
      } else {
        this.localImageUrl2 = taskDB;
      }
    }
  },
  methods: {
    back() {
      let workim = this.$route.params.id;
      if (
        this.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/Passport"
      ) {
        this.$router.push("/selfservice/PersonalProfile/PersonalInformation").catch(()=>{});
      } else if (this.$route.name == "newpassport") {
        this.$router.push(
          "/PersonnelManagement/NewEmployee/NewPersonalInformation"
        ).catch(()=>{});
      } else if (workim != "undefined") {
        this.$router.push(
          `/PersonnelManagement/EditEmployee/${this.$route.params.id}/EditPersonalInformation`
        ).catch(()=>{});
      }
    },
    onPickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      e.preventDefault();
      const files = e.target.files;
      const MAX_SIZE = 2;
      this.ss = false;
      this.showfilename = files[0].name;
      this.showfile = true;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        let imageSize = parseFloat(files[0].size / (1024 * 1024)).toFixed(0);
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        if (imageSize > MAX_SIZE) {
          alert(
            `Your image size is ${imageSize}MB! Maximum is ${MAX_SIZE}MB.Please choose again!`
          );
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.tempImageUrl = fr.result;

          this.imageUrl = fr.result.split(",")[1];
          (this.showthis = false), (this.imageFile = files[0]);
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
        this.tempImageUrl = "";
      }
    },
    uploadimage(a, b) {
      b.preventDefault();
      // this.fileshow = true;
      this.localImageUrl2 = this.imageUrl;
      (this.showthis = true), (this.uploaddialog2 = false);
      localStorage.setItem("localPassportUrl", this.localImageUrl2);
      localStorage.setItem("passportname", this.imageName);
    },
    editimage(a, b) {
      b.preventDefault();
      // this.fileshow = true;
      this.localImageUrl2 = this.imageUrl;
      (this.showthis = true), (this.uploaddialog2 = false);
      localStorage.setItem("localeditPassportUrl", this.localImageUrl2);
      localStorage.setItem("editpassportname", this.imageName);
    },
    closeall() {
      if (this.$route.params.passportid != null) {
        if (localStorage.localeditPassportUrl == null) {
          this.ss = true;
        } else {
          let ff = localStorage.localeditPassportUrl;
          this.imageUrl = ff;
        }
      } else {
        let gg = localStorage.localPassportUrl;

        this.imageUrl = gg;
      }
      this.showthis = true;
      this.uploaddialog2 = false;
      this.showfile = false;
    },
    closeimage() {
      this.showfile = false;
    },
  },
};
</script>
<style scoped>
>>> .mdi-paperclip::before {
  content: none;
}
</style>