var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","origin":"center center","nudge-bottom":10,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"-1rem"},attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.changenoti()}}},on),[(_vm.actualNotiState)?_c('v-badge',{staticStyle:{"margin-bottom":"1.5rem","margin-left":"1.5rem","position":"absolute"},attrs:{"content":_vm.notiCount,"value":_vm.notiCount,"color":"red","overlap":""}}):_vm._e(),_c('v-icon',{attrs:{"color":"#F89D1F"}},[_vm._v("mdi-bell")])],1)]}}])},[(_vm.requestList.length > 0)?_c('v-list',[_c('div',{staticClass:"notiHeading"},[_c('v-list-item-group',{staticClass:"ma-0 white"},[_c('v-row',{staticClass:"mx-2 my-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticStyle:{"color":"#f89d1f"}},[_vm._v(_vm._s(_vm.$t("notifications")))])]),_c('v-col',{attrs:{"cols":"6"}})],1),_vm._l((_vm.requestList),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"margin-top":"1px"},style:({ background: _vm.colorList[i] }),on:{"click":function($event){return _vm.sendDataWithParams(
              item.wF_ID,
              item.notificationID,
              i,
              item.seen,
              item
            )}}},[(item.senderImage)?_c('img',{staticStyle:{"border":"1px solid #43425d","width":"40px","height":"40px","margin-right":"10px","border-radius":"25px"},attrs:{"src":item.senderImage}}):_vm._e(),(!item.senderImage)?_c('img',{staticStyle:{"width":"40px","height":"40px","margin-right":"10px","border-radius":"25px"},attrs:{"src":require("@/assets/profile.svg")}}):_vm._e(),_c('v-list-item-title',{staticStyle:{"background":"item.seen?white:#FAF5F4"}},[_c('div',[_c('div',{staticClass:"notiText",staticStyle:{"margin-bottom":"2px"}},[_vm._v(" "+_vm._s(item.senderName)+" "+_vm._s(_vm.checkingTranslate(item.description))+" ")]),_c('div',{staticClass:"notiText"},[_vm._v(" "+_vm._s(_vm.convertUTCDateToLocalDate(item.noti_date))+" ")])])])],1)})],2),_c('v-row',{staticClass:"ma-0 white",staticStyle:{"align-items":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"notiButton"},[_c('v-btn',{attrs:{"small":"","height":"30","width":"100%","color":"#F99d20","route":"","to":"/Notification"}},[_vm._v(" "+_vm._s(_vm.$t("SeeAll")))])],1)])],1)],1)]):_vm._e(),_c('v-list',[_c('div',{staticClass:"notiHeading",staticStyle:{"align-items":"center","text-align":"center","color":"#f99d20"}},[(_vm.show == true)?_c('p',[_vm._v(_vm._s(_vm.$t("NoNotifications")))]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }