<template>
  <div class="home" style="margin-top: 3rem">
    <ejs-grid
      id="mytable"
      :load="load"
      style="padding-left: 2rem; padding-right: 2rem"
      ref="grid"
      :rowSelected="rowSelected"
      :dataSource="data"
      :allowPaging="true"
      :allowSorting="true"
      :pageSettings="pageSettings"
      :editSettings="editSettings"
      :toolbar="toolbar"
      :showColumnChooser="true"
      :allowSelection="true"
      :selectionSettings="selectOptions"
      :toolbarClick="clickHandler"
    >
      <!-- <ejs-button @click.native='deleteAction'>Delete</ejs-button> -->
      <e-columns>
        <!-- <e-column field="department_ID" width="50"></e-column> -->
        <e-column type="checkbox" textAlign="Center" width="50"></e-column>
        <e-column
          field="department_Code"
          headerText="Dept Code"
          textAlign="Center"
          width="90"
        ></e-column>
        <e-column
          field="department_Name"
          headerText="Name"
          textAlign="Center"
          width="120"
        ></e-column>
        <e-column
          field="level_of_Department"
          headerText="Level of Department"
          textAlign="Center"
          width="90"
        ></e-column>
        <e-column
          field="division_Name"
          headerText="Division"
          textAlign="Center"
          width="50"
        ></e-column>
        <e-column
          field="section_Name"
          headerText="Section"
          textAlign="Center"
          width="50"
        ></e-column>
        <e-column
          headerText="Actions"
          :commands="commands"
          textAlign="Center"
          width="50"
        ></e-column>
      </e-columns>
    </ejs-grid>

    <!-- Delete Dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">Delete item</p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  Are you sure you want to delete this department?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5 text-capitalize"
            width="150"
            max-height="35"
            @click="Deletedialog = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-5 white--text text-capitalize"
            width="150"
            max-height="35"
            @click="DeleteDepartment()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit department dialog -->
    <v-dialog v-model="Editdialog" persistent max-width="700px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span class="headline">Edit Department</span>
        </v-card-title>
        <v-card-text>
          <v-container style="margin-left: -1.5rem; margin-top: -1.3rem">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Department Code"
                v-model="edit.deptcode"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                label="Department Name"
                v-model="edit.deptname"
                required
              ></v-text-field>
            </v-col>
            <v-row style="margin-left: 0.2rem">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Level of Department"
                  v-model="edit.deptlvl"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Division"
                  v-model="edit.division"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-left: 0.2rem">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Level of Divison in Department"
                  v-model="edit.divisionlvl"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Section"
                  v-model="edit.section"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Level of Section in Division"
                v-model="edit.sectionlvl"
                required
              ></v-text-field>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mb-4 mr-7 text-capitalize"
            width="150"
            max-height="35"
            text
            @click="Editdialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#F99D20"
            class="mb-5 mr-5 text-capitalize"
            width="150"
            max-height="35"
            @click="UpdateDepartment()"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import Vue from "vue";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import {
  GridPlugin,
  Page,
  Edit,
  Toolbar,
  ColumnChooser,
  CommandColumn,
} from "@syncfusion/ej2-vue-grids";
// import { DropDownList, DropDownListPlugin, ChangeEventArgs } from '@syncfusion/ej2-vue-dropdowns';
Vue.use(GridPlugin);
Vue.use(ButtonPlugin);
// Vue.use(DropDownListPlugin);
export default {
  name: "Home",
  data() {
    return {
      edit: {
        department_ID: null,
        deptcode: null,
        deptname: null,
        deptlvl: null,
        division: null,
        section: null,
        divisionlvl: null,
        sectionlvl: null,
      },
      department_ID: 0,
      Editdialog: false,
      Deletedialog: false,
      companyid: store.state.companyID,
      url: enurl.apiUrl,
      requesttype: null,
      newRowPositionDataSource: [
        { value: "Top", text: "Top" },
        { value: "Bottom", text: "Bottom" },
      ],
      fields: { text: "text", value: "value" },
      commands: [
        {
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-edit e-icons",
            click: this.EditDia,
          },
        },
        {
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-delete e-icons",
            click: this.DeleteDia,
          },
        },
      ],
      selectOptions: { type: "Multiple" },
      editSettings: {
        allowEditing: true,
        allowDeleting: true,
        allowEditOnDblClick: false,
      },
      toolbar: ["Edit", "Delete", "Update", "ColumnChooser"],
      editparams: { params: { popupHeight: "300px" } },
      pageSettings: { pageCount: 5 },
      data: [],
    };
  },
  provide: {
    grid: [Edit, Toolbar, Page, ColumnChooser, CommandColumn],
  },
  mounted() {
    this.GetDepartment();
  },
  methods: {
    load: function () {
      this.$refs.grid.ej2Instances.element.addEventListener(
        "mousedown",
        function (e) {
          var instance = this.ej2_instances[0];

          if (e.target.classList.contains("e-rowcell")) {
            if (instance.isEdit) instance.endEdit();
            let index = parseInt(e.target.getAttribute("Index"));
            instance.selectRow(index);
            instance.startEdit();
          }
        }
      );
    },

    // actionBegin(args) {
    //   this.requesttype = args.requestType;
    //   //console.log(args.cancel)
    //   if (args.requestType === "beginEdit") {
    //     args.cancel = true;
    //   }
    // },
    // actionComplete(e) {
    //   if ( e.requestType== "beginEdit" && e.requestType === "Edit" ) {
    //     e.cancel = false;
    //   }else if(e.requestType === "refresh"){
    //     e.cancel=true;
    //   }
    // },
    rowSelected(args) {
      // alert(args.data.department_ID)
      this.department_ID = args.data.department_ID;
      this.edit.deptcode = args.data.department_Code;
      this.edit.deptname = args.data.department_Name;
      this.edit.deptlvl = args.data.level_of_Department;
      this.edit.sectionlvl = args.data.level_of_Section;
      this.edit.divisionlvl = args.data.level_of_Division;
      this.edit.section = args.data.section_Name;
      this.edit.division = args.data.department_Name;
    },
    DeleteDia: function () {
      alert(this.department_ID);
      this.department_ID = this.department_ID;
      this.Deletedialog = true;
      // var rowEle = this.parentsUntil(event.target, "e-row");
      // var rowIndex = parseInt(rowEle.getAttribute("aria-rowindex"));
      // this.$refs.grid.selectRow(rowIndex);
      // this.$refs.grid.deleteRecord();
    },
    EditDia() {
      var self = this;
      self.Editdialog = true;
    },
    GetDepartment() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      axios
        .post(`${self.url}Department/GetDepartmentsByCompanyID`, temp)
        .then(function (response) {
          self.data = response.data.data;
        });
    },
    DeleteDepartment() {
      let self = this;
      let id = self.department_ID;
      let temp = {
        department_ID: id,
      };
      axios
        .post(`${self.url}Department/DeleteDepartment`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.Deletedialog = false;
            self.GetDepartment();
          }
        });
    },
    UpdateDepartmentToolbar() {
      let self = this;
      if (
        self.edit.deptcode != null &&
        self.edit.deptname != null &&
        self.edit.deptcode != "" &&
        self.edit.deptname != ""
      ) {
        let temp = {
          department_ID: self.department_ID,
          department_Code: self.edit.deptcode,
          department_Name: self.edit.deptname,
          level: self.edit.deptlvl,
          division_Name: self.edit.division,
          level_of_Division: self.edit.divisionlvl,
          section_Name: self.edit.section,
          level_of_Section: self.edit.sectionlvl,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}Department/UpdateDepartment`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.GetDepartment();
              self.data = [...self.data];
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        alert("Please fill in the fields");
      }
    },
    UpdateDepartment() {
      let self = this;
      if (
        self.edit.deptcode != null &&
        self.edit.deptname != null &&
        self.edit.deptcode != "" &&
        self.edit.deptname != ""
      ) {
        let temp = {
          department_ID: self.department_ID,
          department_Code: self.edit.deptcode,
          department_Name: self.edit.deptname,
          level: self.edit.deptlvl,
          division_Name: self.edit.division,
          level_of_Division: self.edit.divisionlvl,
          section_Name: self.edit.section,
          level_of_Section: self.edit.sectionlvl,
          company_ID: store.state.companyID,
        };
        axios
          .post(`${self.url}Department/UpdateDepartment`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.edit.deptcode = null;
              self.edit.deptname = null;
              self.edit.division = null;
              self.edit.divisionlvl = null;
              self.edit.deptlvl = null;
              self.edit.section = null;
              self.edit.sectionlvl = null;

              self.Editdialog = false;
              self.GetDepartment();
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        alert("Please fill in the fields");
      }
    },
    clickHandler: function (args) {
      if (args.item.id === "mytable_delete") {
        this.DeleteDepartment();
      }
      if (args.item.id === "mytable_update") {
        this.UpdateDepartmentToolbar();
      }

      // if (args.item.id === "mytable_edit") {
      //      this.$refs.grid.ej2Instances.element.classList.remove('disablegrid');
      // }
    },
    // cellEdit(args) {
    //   if (args.value == "2" && args.value == "ww" && args.value == "1") {
    //     args.cancel = true;
    //   }
    // }
  },
};
</script>

