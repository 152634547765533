<template>
    <div>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                <v-card class="thirdrowcard">
                    <p class="ml-3 mb-0 pt-3">
                        {{ $t("EmployeeExpensesCategory") }}
                    </p>
                    <v-divider class="ml-3 mr-3 mb-2 mt-2"></v-divider>
                    <v-row class="pt-2" style="height: 30px; padding-top: 5px">
                        <p style="margin-left: 25px;">{{ $t("Year") }}</p>
                        <p :style="thethaihead ? 'padding-left:90px;' : ''" style="padding-left: 70px;">{{ $t("Month")
                            }}</p>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="5" md="4" lg="4" xl="4" class="pt-0">
                            <div id="duo" class="mr-5 ml-3 d-flex"
                                style="background-color: #f99d20; border-radius: 5px">
                                <date-picker v-model="todayyears" type="year" color="#F99D20" @change="GetExpense(1)"
                                    class="selectbox ml-1 mr-1 mt-1 mb-1 pl-0">
                                    <template slot="icon-calendar">
                                        <img src="@/assets/images/down1@2x.png" style="
                                        width: 30px;
                                        height: 15px;
                                        margin-top: 4px;
                                        margin-right: -6px;
                                        " class="pl-2" alt="down" />
                                    </template>
                                </date-picker>
                                <v-autocomplete id="monthbox" class="selectbox ml-0 mt-1 mr-1 mb-1 pl-1"
                                    v-model="todaymonths" height="34px" 
                                    single-line 
                                    :items="monthNames"
                                    item-text="txt" 
                                    item-value="val"
                                    background-color="#FFFFFF" 
                                    color="#f99d20" 
                                    @change="GetExpense(1)"
                                    persistent-hint></v-autocomplete>
                            </div>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pt-0">
                            <v-row class="pt-3 mr-6 ml-5">
                                <div style="
                                    width: 20px;
                                    height: 20px;
                                    background-color: #F89D1F;
                                    border-radius: 25px;
                                    margin-top: 10px;
                                "></div>
                                <p class="ml-2 mt-2">
                                    {{ $t("Total") }}:
                                </p>
                                <v-spacer></v-spacer>
                                <p class="Number-text">
                                    {{ Expense_Total }}
                                </p>

                            </v-row>

                        </v-col>
                    </v-row>
                    <v-row class="mt-5">
                        <v-col align="center" cols="12" xs="12" sm="5" md="5" lg="5" class="mycol1">
                            <div style="
                                max-width: 280px;
                                max-height: 150px;
                                min-width: 280px;
                                min-height: 150px;
                                margin-left: 10px;
                                margin-top: 5px;
                            ">
                                <apexchart type="donut" :options="chartOptions" :series="Aseries"></apexchart>
                            </div>
                        </v-col>
                        <v-col align="center" cols="12" xs="12" sm="7" md="7" lg="7" class="mycol">
                            <div class="mt-5 mr-5">
                                <v-row>
                                    <p class="ml-5">
                                        {{ $t("salary") }}
                                    </p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ SalaryValue }}
                                    </p>
                                </v-row>
                                <v-row>
                                    <p class="ml-5">
                                        {{ $t("overt") }}</p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ OTValue }}

                                    </p>
                                </v-row>
                                <v-row v-for="item in AddIncomeList" :key="item.income_Type_ID"
                                    >
                                    <p v-show="!thethaihead" class="ml-5">
                                        {{ item.income_Name_Eng }}
                                    </p>
                                    <p v-show="thethaihead" class="ml-5">
                                        {{ item.income_Name }}
                                    </p>
                                    <v-spacer></v-spacer>
                                    <p class="mr-5">
                                        {{ item.amount }}
                                    </p>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col class="px-sm-and-up-0" cols="12" xs="12" sm="12" md="12" lg="6">
                <v-card class="thirdrowcard">
                    <p class="ml-3 mb-0 pt-3">
                        {{ $t("PayrollPaymentSummary") }}
                    </p>
                    <v-divider class="ml-3 mr-3 mb-2 mt-2"></v-divider>
                    <v-row>
                        <v-col cols="12" xs="12" sm="5" md="3" lg="4" xl="4" class="pt-0">
                            <p class="mb-0 ml-3 mt-3">{{ $t("Year") }}</p>
                            <div id="duo" class="mr-5 ml-3 d-flex"
                                style="background-color: #f99d20; border-radius: 5px; width: 120px;">
                                <date-picker v-model="payyears" type="year" color="#F99D20" @change="changePayYear"
                                    class="selectbox ml-1 mr-1 mt-1 mb-1 pl-0">
                                    <template slot="icon-calendar">
                                        <img src="@/assets/images/down1@2x.png" style="
                                    width: 30px;
                                    height: 15px;
                                    margin-top: 4px;
                                    margin-right: -6px;
                                    " class="pl-2" alt="down" />
                                    </template>
                                </date-picker>
                            </div>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pt-5 pr-8 mr-2">
                            <v-row class="mt-3 ml-5">
                                <div style="
                                    width: 20px;
                                    height: 20px;
                                    background-color: #F89D1F;
                                    border-radius: 25px;
                                    margin-top: 10px;
                                "></div>
                                <p class="ml-2 mt-2 mb-0">
                                    {{ $t("paid") }}:
                                </p>
                                <v-spacer></v-spacer>
                                <p class="Number-text mb-0">
                                    {{ yearly_Paid_Total }}
                                </p>

                            </v-row>
                            <v-row class="pt-1 ml-5">
                                <div style="
                                    width: 20px;
                                    height: 20px;
                                    background-color: #BDBDBD;
                                    border-radius: 25px;
                                    margin-top: 10px;
                                "></div>
                                <p class="ml-2 mt-2 mb-0">
                                    {{ $t("Total") }}:
                                </p>
                                <v-spacer></v-spacer>
                                <p class="Number-text mb-0">
                                    {{ yearly_Unpaid_Total }}
                                </p>

                            </v-row>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <apexchart type="bar" height="300" :options="barchartOptions" :series="barchartSeries">
                            </apexchart>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-card class="thirdrowcard">
                    <v-row class="mt-2">
                        <p class="ml-6 mb-0 pt-3 mt-4">
                            {{ $t("EmploymentReport") }}
                        </p>
                        <v-spacer></v-spacer>
                        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" align="right" class="pt-0">
                            <div style="width:200px;">
                                <v-row class="ml-5 mt-2 pl-6">
                                <p class="mb-0 ml-3 mt-3">{{ $t("Year") }}</p></v-row>
                                <div id="duo" class="mr-5 ml-3 mt-2 d-flex"
                                    style="background-color: #f99d20; border-radius: 5px; width: 120px;">
                                    <date-picker v-model="EmployYears" type="year" color="#F99D20" @change="GetEmployment()"
                                        class="selectbox ml-1 mr-1 mt-1 mb-1 pl-0">
                                        <template slot="icon-calendar">
                                            <img src="@/assets/images/down1@2x.png" style="
                                        width: 30px;
                                        height: 15px;
                                        margin-top: 4px;
                                        margin-right: -6px;
                                        " class="pl-2" alt="down" />
                                        </template>
                                    </date-picker>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <div class="employee-count" style="text-align: center; font-size: 18px; font-weight: light;">
                                <span style="color: #FFA726;">●</span> {{ $t("CurrentEmployees") }}: <span>{{ currentEmployeeCount }}</span>
                            </div>
                            <apexchart type="line" :options="linechartOptions" :series="linechartSeries" height="300" />
                        </v-col>

                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
    </div>
</template>
<script>
import Axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import VueApexCharts from "vue-apexcharts";
import LoadingDialog from "@/components/Loading.vue";
import { CommaNumber } from '@/function/NumberConvert.js';

export default {
    name: 'ApexDonutChart',
    components: {
        apexchart: VueApexCharts,
        LoadingDialog
    },
    data() {
        return {
            url: enurl.apiUrl,
            LoadingDialog: false,
            todaymonths: 13,
            todayyears: new Date(),
            payyears: new Date(),
            EmployYears: new Date(),
            thelang: "",
            AddIncomeList: [],
            Addseries: [],
            SalaryValue: 0,
            OTValue: 0,
            Expense_Total: 0,
            yearly_Unpaid_Total: 0,
            yearly_Paid_Total: 0,
            MonthList: [],
            chartOptions: {
                labels: ["Full Time", "Daily", "Part-time", "Contract", "Fixed Pay"],
                colors: [
                    '#f4a261', "#2a9d8f", "#e9c46a", "#e76f51",
                    "#40ba9e", "#7978b1", "#e5da6c", "#ee749b",
                    "#00afb9", "#fdfcdc", "#fed9b7", "#f07167"
                ],
                chart: {
                    type: 'donut',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: false,
                            },
                        },
                    },
                    expandOnClick: true,
                },

                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false,
                },
                tooltip: {
                    enabled: true,
                    theme: 'light',
                    y: {
                        formatter: function (value) {
                            return value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        }
                    }
                },
            },
            barchartOptions: {
                chart: {
                    stacked: true,
                    toolbar: { show: false },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "35%",
                    },
                },
                dataLabels: {
                    enabled: false, // Disable numbers inside the bars
                },
                xaxis: {
                    title: {
                        show: false, // Hide the x-axis title
                    },
                    categories: this.generateMonthCategories(new Date().getFullYear())
                    //["Jan 2024", "Feb 2024", "Mar 2024", "Apr 2024"],
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                    labels: {
                        formatter: (value) => value.toLocaleString() // Adds commas to the y-axis values
                    },
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    show: false, // Hide the legend
                },
                colors: ["#ffa726", "#bdbdbd"],
            },
            barSeries: [],
            lineSeries: [],
            currentEmployeeCount: 0,
            linechartOptions: {
                chart: {
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    curve: 'straight',
                    width: 3,
                },
                xaxis: {
                    categories: this.generateMonthCategories(new Date().getFullYear()),
                    title: {
                        show: false,
                    },
                    labels: {
                        rotate: -45,
                    },
                },
                yaxis: {
                    title: {
                        text: '',
                    },
                    labels: {
                        formatter: (value) => value.toLocaleString(),
                    },
                },
                markers: {
                    size: 5,
                    colors: ['#FFA726'],
                    strokeWidth: 2,
                },
                
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: 'vertical',
                        gradientToColors: ['#36A2EB'], // Set gradient end color
                        stops: [0, 100],
                        opacityFrom: 1,
                        opacityTo: 0.5,
                    },
                },
                colors: ['#FFA726'],
                legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'right',
                    labels: {
                        colors: '#000',
                    },
                    markers: {
                        fillColors: ['#FFA726'],
                    },
                },
            },
        };
    },
    mounted() {
        //this.shuffleArray(this.chartOptions.colors);
        this.GetExpense(3);
        this.GetEmployment();
        this.timerInterval = setInterval(() => {
            this.thelang = localStorage.getItem('Templang')
        }, 100);
    },
    computed: {
        Aseries() {
            return this.Addseries;
        },
        barchartSeries() {
            return this.barSeries;
        },
        linechartSeries() {
            return this.lineSeries;
        },
        // currentEmployeeCount() {
        //     return this.linechartSeries[0].data.reduce((a, b) => a + b, 0); // Summing up all values
        // },
        thethaihead() {
            return this.whatthehead();
        },
        monthNames() {
            return [
                { val: 13, txt: this.$t("All") },
                { val: 1, txt: this.$t("January") },
                { val: 2, txt: this.$t("February") },
                { val: 3, txt: this.$t("March") },
                { val: 4, txt: this.$t("April") },
                { val: 5, txt: this.$t("May") },
                { val: 6, txt: this.$t("June") },
                { val: 7, txt: this.$t("July") },
                { val: 8, txt: this.$t("August") },
                { val: 9, txt: this.$t("September") },
                { val: 10, txt: this.$t("October") },
                { val: 11, txt: this.$t("November") },
                { val: 12, txt: this.$t("December") }
            ];
        },
    },
    watch: {
        thelang() {
            this.whatthehead();
        },
        payyears() {
            this.changePayYear();
        },
    },
    methods: {
        whatthehead() {
            if (this.thelang == "ประเทศไทย") {
                return true;
            } else {
                return false;
            }
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        },
        generateMonthCategories() {
            // return [
            //     `Jan ${year}`, `Feb ${year}`, `Mar ${year}`, `Apr ${year}`,
            //     `May ${year}`, `Jun ${year}`, `Jul ${year}`, `Aug ${year}`,
            //     `Sep ${year}`, `Oct ${year}`, `Nov ${year}`, `Dec ${year}`
            // ];
            return [
                `Jan`, `Feb`, `Mar`, `Apr`,
                `May`, `Jun`, `Jul`, `Aug`,
                `Sep`, `Oct`, `Nov`, `Dec`
            ];
        },
        changePayYear() {
            this.barchartOptions = {
                ...this.barchartOptions,
                xaxis: {
                    ...this.barchartOptions.xaxis,
                    categories: this.generateMonthCategories(this.payyears)
                }
            };
            this.GetExpense(2);
        },
        GetExpense(v) {
            let self = this;
            let yearnum = 0;
            if (v == 1) {
                self.LoadingDialog = true;
                yearnum = self.todayyears.getFullYear();
            } else if (v == 2) {
                self.LoadingDialog = true;
                yearnum = self.payyears.getFullYear();
            } else {
                yearnum = self.todayyears.getFullYear();
            }
            let temp = {
                company_ID: store.state.companyID,
                callType: v,
                year: yearnum,
                month: self.todaymonths
            };
            Axios
                .post(`${self.url}Dashboard/GetEmployeeExpense`, temp)
                .then(function (response) {
                    if (response.data.status == 0) {
                        let result = response.data.data;
                        if (v == 1 || v == 3) {
                            self.SalaryValue = result.salary;
                            self.OTValue = result.overtime;
                            self.Expense_Total = CommaNumber(result.monthly_Total);
                            self.AddIncomeList = result.additionalIncomeList;
                            self.Addseries = [self.SalaryValue, self.OTValue, ...self.AddIncomeList.map(item => item.amount)]
                            self.SalaryValue = CommaNumber(self.SalaryValue);
                            self.OTValue = CommaNumber(self.OTValue);
                            self.AddIncomeList = self.AddIncomeList.map(item => ({
                                ...item,
                                amount: CommaNumber(item.amount)
                            }));
                            self.chartOptions = {
                                ...self.chartOptions,
                                labels: ["Salary", "Overtime", ...self.AddIncomeList.map(item => item.income_Name_Eng || "Other Additional Income")]
                            };

                        }
                        if (v == 2 || v == 3) {
                            self.yearly_Paid_Total = CommaNumber(result.yearly_Paid_Total);
                            self.yearly_Unpaid_Total = CommaNumber(result.yearly_Total);

                            self.MonthList = result.monthlyList;
                            self.barSeries = [
                                {
                                    name: "Paid",
                                    data: self.MonthList.map(item => item.paid_Total),
                                },
                                {
                                    name: "Await",
                                    data: self.MonthList.map(item => item.awaiting_Total),
                                }
                            ];
                        }
                        self.LoadingDialog = false;
                    }
                })
                .catch(function (error) {
                    alert(error);
                });
        },
        GetEmployment() {
            let self = this;
            let yearnum = self.EmployYears.getFullYear();
            let temp = {
                company_ID: store.state.companyID,
                year: yearnum,
            };
            Axios
                .post(`${self.url}Dashboard/GetEmployeeEmployment`, temp)
                .then(function (response) {
                    if (response.data.status == 0) {
                        let result = response.data.data;
                        self.lineSeries = [
                            {
                                name: 'Employees',
                                data: result.yearly_Employee.map(item => item.hired_Total)
                            }
                        ];
                        self.currentEmployeeCount = result.current_Employees;
                    }
                })
                .catch(function (error) {
                    alert(error);
                });
        },
    },

};
</script>
<style scoped>
.thirdrowcard {
    min-height: 495px;
}

.Number-text {
    font-size: 26px;
    color: #F89D1F;
    margin-left: 10px;
    font-weight: 400;
}
.mycol1{
    height: 230px;
}
.mycol {
    height: 330px;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
}

.mycol::-webkit-scrollbar {
    display: none;
}
::v-deep.selectbox {
    width: 120px;
    height: 34px;
    margin: 0px;
    padding: 0px;
    border-radius: 3px;
    border: none;
}
::v-deep.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
    pointer-events: inherit;
    padding-left: 5px;
}
</style>