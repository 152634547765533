<template>
  <div>
    <div v-show="back_t"><Timeoff :back_t="back_t" /></div>
    <div v-show="showbasic">
      <!-- <div style="padding-left: 0px; padding-right: 1%; margin-bottom: 2rem">
        <v-row class="mr-0">
          <v-btn
            icon
            router
            color="white"
            :to="{ name: 'MasterTimeOff' }"
            class="mt-6 ml-4"
          >
            <img src="@/assets/images/back button.png" class="ml-3 mb-1" />
          </v-btn>
          <p style="font-size: 26px; margin-left: 5px; margin-top: 20px">
            {{ $t("EditTimeOffType") }}
          </p>
        </v-row>
      </div> -->
      <v-card class="pt-5" style="margin-bottom: 5rem">
        <v-row
          class="pl-md-0 mt-5"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="8" md="3" lg="3" xl="2">
            <p style="margin-top: -5px" class="text-right company_text ml-1">
              {{ $t("CompanyPolicy") }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" xs="12" sm="4" md="3" lg="3" xl="2">
            <v-select
              class="txttype1 pl-0"
              :items="itemselect"
              v-model="select"
              single-line
              item-text="txt"
              item-value="val"
              return-object
              persistent-hint
              v-on:change="changeshow(`${select.val}`)"
            >
              <template v-slot:append>
                <img
                  class="mr-2"
                  style="margin-top: 3px"
                  width="20px"
                  height="20px"
                  src="@/assets/images/down@2x.png"
                />
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row style="margin-left: 1rem; margin-right: 1rem">
          <v-col class="d-flex">
            <p style="margin-top: 10px; font-size: 16px">
              {{ $t("TypeOfTimeOff") }} :
            </p>
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="typename"
              class="txttypee"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem"
          :headers="headers"
          :items="TOTypeList"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :mobile-breakpoint="0"
        >
          <template v-slot:item.availability_Days="{ item }">
            <!-- :disabled="txtdismiss" -->
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="item.availability_Days"
              class="txtnumbox pt-1 pl-3 ml-0"
              type="number"
              @keypress="onlyNumber"
              min="0"
              max="98"
            >
              <template v-slot:append>
                <div style="margin-right: 5px; margin-top: 13px">
                  <v-row>
                    <v-btn
                      style="width: 13px; height: 8px"
                      @click="addnum(item)"
                      icon
                      text
                    >
                      <img src="@/assets/images/tb-up-arrow.png" alt />
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-btn
                      style="width: 13px; height: 8px"
                      @click="subnum(item)"
                      icon
                      text
                    >
                      <img
                        src="@/assets/images/tb-down-arrow.png"
                        style="margin-top: 5px"
                        alt
                      />
                    </v-btn>
                  </v-row>
                </div>
              </template>
            </v-text-field>
          </template>
        </v-data-table>
        <v-row style="margin-left: 2rem; margin-right: 2rem; margin-top: 30px">
          <v-spacer></v-spacer>
          <v-btn
            color="#F99D20"
            class="text-capitalize mr-5"
            width="150"
            max-height="35"
            @click="back"
          >
            {{ $t("cancel") }}</v-btn
          >
          <!-- v-show="showbtn1" -->
          <v-btn
            color="#F99D20"
            class="text-capitalize mr-5"
            width="150"
            max-height="35"
            @click="UpdateBasic"
          >
            {{ $t("Save") }}</v-btn
          >
        </v-row>
        <div style="height: 5vh"></div>
      </v-card>

      <!-- Upload Dialog -->
      <v-dialog v-model="TOTBasicUploaddialog" presistent max-width="786">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p
              style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px"
            >
              {{ $t("UploadFile") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  style="margin-left: -30px; padding-top: 20px"
                  cols="5"
                  md="5"
                >
                  <v-file-input
                    truncate-length="100"
                    v-show="showup"
                    id="fileInputButton"
                    class="inputbot"
                    v-model="chosenfile"
                    accept=".xlsx"
                  >
                    <template v-slot:append>
                      <v-btn icon>
                        <img
                          width="20"
                          height="20"
                          @click="closeinput"
                          src="@/assets/closered.png"
                          alt
                        />
                      </v-btn>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-btn
                    color="#ACACAC"
                    class="white--text text-capitalize"
                    width="150"
                    max-height="35"
                    @click="getupload"
                  >
                    {{ $t("Browse") }}</v-btn
                  >
                </v-col>
                <v-col cols="12" md="9">
                  <p class="mt-2" style="color: #f74747">
                    **{{ $t("filetype") }}**
                  </p>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
              <v-row>
                <v-col cols="12" md="4">
                  <p>{{ $t("DocumentTemplate") }}</p>
                </v-col>
                <v-col cols="12" md="8">
                  <a
                    style="color: #f99d20"
                    href="https://optimisticpathfile.blob.core.windows.net/optimisticfile/New Time Off Type Master Data.xlsx"
                  >
                    {{ $t("download") }}</a
                  >
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br />
              <br />
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              max-width="120"
              min-width="90"
              height="35"
              @click="closeBasicUpload"
              text
            >
              {{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="white--text mr-2 text-capitalize"
              max-width="120"
              min-width="90"
              height="35"
              @click="previewBasicFiles"
            >
              {{ $t("Upload") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-show="showlengthofservice">
      <!-- <div style="margin-bottom: 2rem">
        <v-row class="mr-0">
          <v-btn
            icon
            router
            color="white"
            :to="{ name: 'MasterTimeOff' }"
            class="mt-6"
          >
            <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
          </v-btn>
          <p style="font-size: 26px; margin-left: 4px; margin-top: 20px">
            {{ $t("EditTimeOffType") }}
          </p>
        </v-row>
      </div> -->
      <v-card class="pt-5" style="margin-bottom: 5rem">
        <v-row class="mt-5 ml-5">
          <v-col class="pt-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="1">
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize"
              width="150"
              max-height="35"
              @click="TOEAddDialog = true"
            >
              {{ $t("Add") }}</v-btn
            >
          </v-col>
          <v-col class="pt-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize ml-7 upload_btn"
              width="150"
              max-height="35"
              @click="TOTLSUploaddialog = true"
            >
              {{ $t("Upload") }}</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="2" lg="2" xl="2">
            <p style="margin-top: -5px" class="text-right company_text">
              {{ $t("CompanyPolicy") }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" xs="12" sm="6" md="3" lg="3" xl="2">
            <v-select
              class="txttype1"
              :items="itemselect"
              v-model="select"
              single-line
              item-text="txt"
              item-value="val"
              return-object
              persistent-hint
              v-on:change="changeshow(`${select.val}`)"
            >
              <template v-slot:append>
                <img
                  class="mr-2"
                  style="margin-top: 3px"
                  width="20px"
                  height="20px"
                  src="@/assets/images/down@2x.png"
                />
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row style="margin-left: 1rem; margin-right: 1rem">
          <v-col class="d-flex">
            <p style="margin-top: 10px; font-size: 16px">
              {{ $t("TypeOfTimeOff") }} :
            </p>
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="typename"
              class="txttypee"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem"
          :headers="headers1"
          :items="TOTypeList1"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item.length="{ item }"
            >{{
              item.employment_length == 1
                ? $t("LessThan")
                : "null" && item.employment_length == 2
                ? $t("GreaterThan")
                : "null" && item.employment_length == 3
                ? $t("Between")
                : "null" && item.employment_length == 4
                ? $t("EqualTo")
                : "null" && item.employment_length == 3
                ? item.endYear
                : "null"
            }}&nbsp;{{ item.start_Year }}&nbsp;{{
              item.employment_length == 3 ? "-" : ""
            }}&nbsp;{{ item.employment_length == 3 ? item.end_Year : "" }}&nbsp;
            {{ $t("Year") }}</template
          >
          <template v-slot:item.txtxt="{ item }">
            <!-- :disabled="txtdismiss" -->
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              class="txtnumbox pt-1 pl-3 ml-0"
              v-model="item.availability_Days"
              v-on:click="Savebtntxt = 'Save'"
              type="number"
              @keypress="onlyNumber"
              value="0"
              min="0"
              max="98"
            >
              <template v-slot:append>
                <div style="margin-right: 5px; margin-top: 13px">
                  <v-row>
                    <v-btn
                      style="width: 13px; height: 8px"
                      @click="addnum(item)"
                      icon
                      text
                    >
                      <img src="@/assets/images/tb-up-arrow.png" alt />
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-btn
                      style="width: 13px; height: 8px"
                      @click="subnum(item)"
                      icon
                      text
                    >
                      <img
                        src="@/assets/images/tb-down-arrow.png"
                        style="margin-top: 5px"
                        alt
                      />
                    </v-btn>
                  </v-row>
                </div>
              </template>
            </v-text-field>
          </template>
          <template v-slot:item.actionadd="{ item }">
            <!-- :disabled="txtdismiss" -->
            <v-btn
              @click="TOTODeletedialog(item.tid, item.availability_Day_ID)"
              icon
              text
              class="ml-2"
            >
              <img :src="images.deletepic" class="mr-2 mb-1" />
            </v-btn>
          </template>
        </v-data-table>
        <v-row style="margin-left: 2rem; margin-right: 2rem; margin-top: 30px">
          <v-spacer></v-spacer>
          <v-btn
            color="#F99D20"
            class="text-capitalize mr-5"
            width="150"
            max-height="35"
            @click="back"
          >
            {{ $t("cancel") }}</v-btn
          >
          <!-- v-show="showbtn" -->
          <v-btn
            color="#F99D20"
            class="text-capitalize"
            width="150"
            max-height="35"
            @click="Updatepolicy"
          >
            {{ $t("Save") }}</v-btn
          >
        </v-row>
        <div style="height: 5vh"></div>
      </v-card>

      <!--LOS Add Dialog -->
      <v-dialog v-model="TOEAddDialog" persistent max-width="800px">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p
              style="margin-top: 10px; margin-bottom: -30px; margin-left: 15px"
            >
              {{ $t("NewEntry") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p class="mt-8">{{ $t("EmployeeLengthService") }}</p>
              <v-row>
                <v-col>
                  <v-tabs
                    show-arrows
                    v-model="addtempLS.employment_length"
                    fixed-tabs
                    light
                  >
                    <v-tab
                      v-for="item in items"
                      @click="showtab(item.val)"
                      :key="item.val"
                      class="text-capitalize"
                      style="margin-left: 2px"
                      >{{ item.text }}</v-tab
                    >
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-show="!show">
                  {{ $t("Year") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempLS.start_Year"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show">
                  {{ $t("start_year") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempLS.start_Year"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show">
                  {{ $t("end_year") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempLS.end_Year"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("NumberOfTimeOff") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempLS.availability_Days"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              max-width="120"
              min-width="90"
              max-height="35"
              @click="TOEAddDialogCancel"
              text
            >
              {{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 text-capitalize"
              max-width="120"
              min-width="90"
              height="35"
              @click="addtemparrls"
            >
              {{ $t("Add") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--LOS Upload Dialog -->
      <v-dialog v-model="TOTLSUploaddialog" presistent max-width="786">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p
              style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px"
            >
              {{ $t("UploadFile") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  style="margin-left: -30px; padding-top: 20px"
                  cols="5"
                  md="5"
                >
                  <v-file-input
                    truncate-length="100"
                    v-show="showup"
                    id="fileInputButton"
                    class="inputbot"
                    v-model="chosenfile"
                    accept=".xlsx"
                  >
                    <template v-slot:append>
                      <v-btn icon>
                        <img
                          width="20"
                          height="20"
                          @click="closeinput"
                          src="@/assets/closered.png"
                          alt
                        />
                      </v-btn>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3">
                  <v-btn
                    color="#ACACAC"
                    class="white--text text-capitalize"
                    width="150"
                    max-height="35"
                    @click="getupload"
                    >{{ $t("browse") }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="9">
                  <p class="mt-2" style="color: #f74747">
                    **{{ $t("excelFileFormat") }}
                  </p>
                </v-col>
              </v-row>
              <v-divider></v-divider>

              <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
              <v-row>
                <v-col cols="12" md="4">
                  <p>Document Template.xlsx</p>
                </v-col>
                <v-col cols="12" md="8">
                  <a style="color: #f99d20" :href="whatthelang()">
                    {{ $t("Download") }}</a
                  >
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br />
              <br />
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              max-width="120"
              min-width="90"
              height="35"
              @click="closeLSUpload"
              text
            >
              {{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="white--text mr-2 text-capitalize"
              max-width="120"
              min-width="90"
              height="35"
              @click="previewLSFiles"
              >{{ $t("Upload") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-show="showprovacation">
      <!-- <div style="margin-bottom: 2rem">
        <v-row class="mr-0">
          <v-btn
            icon
            router
            color="white"
            :to="{ name: 'MasterTimeOff' }"
            class="mt-6"
          >
            <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
          </v-btn>
          <p style="font-size: 26px; margin-left: 4px; margin-top: 20px">
            {{ $t("EditTimeOffType") }}
          </p>
        </v-row>
      </div> -->
      <v-card class="pt-5 mb-5" style="margin-bottom: 5rem">
        <v-row class="mt-5 ml-5">
          <v-col class="pt-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="1">
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize"
              width="150"
              max-height="35"
              @click="TOEPAddDialog = true"
              >{{ $t("Add") }}</v-btn
            >
          </v-col>
          <v-col class="pt-0" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <v-btn
              color="#F99D20"
              class="white--text text-capitalize ml-7 upload_btn"
              width="150"
              max-height="35"
              @click="TOTUploaddialog = true"
              >{{ $t("Upload") }}</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="3" lg="3" xl="2">
            <p style="margin-top: -5px" class="text-right company_text">
              {{ $t("companypolicy") }}
            </p>
          </v-col>
          <v-col class="pt-0" cols="12" xs="12" sm="4" md="3" lg="3" xl="2">
            <v-select
              class="txttype1"
              :items="itemselect"
              v-model="select"
              single-line
              item-text="txt"
              item-value="val"
              return-object
              persistent-hint
              v-on:change="changeshow(`${select.val}`)"
            >
              <template v-slot:append>
                <img
                  class="mr-2"
                  style="margin-top: 3px"
                  width="20px"
                  height="20px"
                  src="@/assets/images/down@2x.png"
                />
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row style="margin-left: 1rem; margin-right: 1rem">
          <v-col class="d-flex">
            <p style="margin-top: 10px; font-size: 16px">
              {{ $t("TypeOfTimeOff") }} :
            </p>
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              v-model="typename"
              class="txttypee"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          style="margin-left: 2rem; margin-right: 2rem"
          :headers="headers2"
          :items="TOTypeList1"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item.length="{ item }"
            >{{
              item.employment_length == 1
                ? "Less than"
                : "null" && item.employment_length == 2
                ? "Greater than"
                : "null" && item.employment_length == 3
                ? "Between"
                : "null" && item.employment_length == 4
                ? "Equal To"
                : "null" && item.employment_length == 3
                ? item.endYear
                : "null"
            }}&nbsp;{{ item.start_Year }}&nbsp;{{
              item.employment_length == 3 ? "-" : ""
            }}&nbsp;{{
              item.employment_length == 3 ? item.end_Year : ""
            }}&nbsp;Years</template
          >
          <template v-slot:item.txtxt="{ item }">
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              class="txtnumbox pt-1 pl-3 ml-0"
              :disabled="txtdismiss1"
              v-model="item.availability_Days"
              type="number"
              value="0"
              @keypress="onlyNumber"
              min="0"
              max="98"
            >
              <template v-slot:append>
                <div style="margin-right: 5px">
                  <v-row>
                    <v-btn
                      style="width: 13px; height: 8px"
                      @click="addnum(item)"
                      icon
                      text
                    >
                      <img src="@/assets/images/tb-up-arrow.png" alt />
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-btn
                      style="width: 13px; height: 8px"
                      @click="subnum(item)"
                      icon
                      text
                    >
                      <img
                        src="@/assets/images/tb-down-arrow.png"
                        style="margin-top: 5px"
                        alt
                      />
                    </v-btn>
                  </v-row>
                </div>
              </template>
            </v-text-field>
          </template>
          <template v-slot:item.actionadd="{ item }">
            <v-btn
              @click="TOTODeletedialog(item.tid, item.availability_Day_ID)"
              icon
              text
              :disabled="txtdismiss1"
              class="ml-2"
            >
              <img :src="images.deletepic" class="mr-2 mb-1" />
            </v-btn>
          </template>
        </v-data-table>
        <v-row style="margin-left: 2rem; margin-right: 2rem; margin-top: 30px">
          <v-spacer></v-spacer>
          <v-btn
            color="#F99D20"
            class="text-capitalize mr-5"
            width="150"
            max-height="35"
            @click="back"
          >
            {{ $t("cancel") }}</v-btn
          >
          <!-- v-show="showbtn3" -->
          <v-btn
            color="#F99D20"
            class="text-capitalize"
            width="150"
            max-height="35"
            @click="Updatepolicy"
          >
            {{ $t("Save") }}</v-btn
          >
        </v-row>
        <div style="height: 5vh"></div>
      </v-card>

      <!--PV Add Dialog -->
      <v-dialog v-model="TOEPAddDialog" persistent max-width="800px">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p
              style="margin-top: 10px; margin-bottom: -30px; margin-left: 15px"
            >
              {{ $t("NewEntry") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p class="mt-8">{{ $t("EmployeeLengthService") }}</p>
              <v-row>
                <v-col>
                  <v-tabs
                    v-model="addtempPV.employment_length"
                    fixed-tabs
                    light
                  >
                    <v-tab
                      v-for="item in items"
                      @click="showtab(item.val)"
                      :key="item.val"
                      class="text-capitalize"
                      style="margin-left: 2px"
                      >{{ item.text }}</v-tab
                    >
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-show="!show">
                  {{ $t("Year") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempPV.start_Year"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show">
                  {{ $t("start_year") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempPV.start_Year"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="3" v-show="show">
                  {{ $t("end_year") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempPV.end_Year"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $t("NumberOfTimeOff") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempPV.availability_Days"
                    @keypress="onlyNumber"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  {{ $t("Period") }}
                  <v-text-field
                    autocomplete="off"
                    v-model="addtempPV.anuual"
                    color="#F99D20"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="TOEPAddDialogCancel"
              text
            >
              {{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 text-capitalize"
              width="120"
              height="35"
              @click="addtemparrpv"
            >
              {{ $t("Add") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--PV Upload Dialog -->
      <v-dialog v-model="TOTUploaddialog" presistent max-width="786">
        <v-card style="border-radius: 15px">
          <v-card-title style="text-align: center">
            <p
              style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px"
            >
              {{ $t("UploadFile") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  style="margin-left: -30px; padding-top: 20px"
                  cols="5"
                  md="5"
                >
                  <v-file-input
                    truncate-length="100"
                    v-show="showup"
                    id="fileInputButton"
                    class="inputbot"
                    v-model="chosenfile"
                    accept=".xlsx"
                  >
                    <template v-slot:append>
                      <v-btn icon>
                        <img
                          width="20"
                          height="20"
                          @click="closeinput"
                          src="@/assets/closered.png"
                          alt
                        />
                      </v-btn>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3">
                  <v-btn
                    color="#ACACAC"
                    class="white--text text-capitalize"
                    width="150"
                    max-height="35"
                    @click="getupload"
                  >
                    {{ $t("browse") }}</v-btn
                  >
                </v-col>
                <v-col cols="12" md="9">
                  <p class="mt-2" style="color: #f74747">
                    **{{ $t("excelFileFormat") }}
                  </p>
                </v-col>
              </v-row>
              <v-divider></v-divider>

              <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
              <v-row>
                <v-col cols="12" md="4">
                  <p>{{ $t("DocumentTemplate") }}</p>
                </v-col>
                <v-col cols="12" md="8">
                  <a
                    style="color: #f99d20"
                    href="https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Vacation Prorate Master Data.xlsx"
                  >
                    {{ $t("Download") }}</a
                  >
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br />
              <br />
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-6" style="margin-right: 3%">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              height="35"
              @click="closeUpload"
              text
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="white--text mr-4 text-capitalize"
              width="120"
              height="35"
              @click="previewFiles"
              >{{ $t("Upload") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- delete dialog -->
    <v-dialog v-model="TOTDeletedialog" class="dialog" max-width="532">
      <v-card>
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="TOTDeletedialog = false"
            text
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="DeleteTOT()"
            >{{ $t("Delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import Timeoff from "./Timeoff.vue";
import LoadingDialog from "@/components/Loading.vue";

export default {
  name: "Timeoffedit",
  components: { Timeoff, LoadingDialog },
  // props: ["pass_id"],
  mixins: [LogTrace, utils],
  data() {
    return {
      pass_id: 0,
      back_t: false,
      url: enurl.apiUrl,
      itemsPerPage: 1000,
      TOEAddDialog: false,
      TOEPAddDialog: false,
      TOTBasicUploaddialog: false,
      TOTUploaddialog: false,
      TOTDeletedialog: false,
      TOTLSUploaddialog: false,
      LoadingDialog: false,
      showup: false,
      show: false,
      showbasic: true,
      Savebtntxt: "Edit",
      showlengthofservice: false,
      showprovacation: false,
      showbtn: false,
      showbtn1: false,
      showbtn2: false,
      showbtn3: false,
      txtdismiss: true,
      txtdismiss1: true,
      tempimport: [],
      importData: [],
      chosenfile: null,
      TOTList: [],
      TOTypeList: [],
      TOTypeList1: [],
      TOTypeList2: [],
      TempToDelete: [],
      errorMessage: [],
      delcon: 0,
      images: {
        editpic: require("@/assets/images/Mask Group 178.png"),
        deletepic: require("@/assets/images/Mask Group 177.png"),
      },
      lengthof: 1,
      select: null,
      addtempPV: {
        employment_length: null,
        start_Year: null,
        end_Year: null,
        availability_Days: null,
        anuual: null,
      },
      addtempLS: {
        employment_length: null,
        start_Year: null,
        end_Year: null,
        availability_Days: null,
      },
      numtxt: 0,
      typename: null,
      Ids: null,
      tid: 0,
      tempnum: 2,
    };
  },
  computed: {
    items() {
      return [
        { text: this.$t("LessThan"), val: 1 },
        { text: this.$t("GreaterThan"), val: 2 },
        { text: this.$t("Between"), val: 3 },
        { text: this.$t("EqualTo"), val: 4 },
      ];
    },
    itemselect() {
      return [
        { txt: this.$t("Basic"), val: 1, disabled: false },
        { txt: this.$t("LengthOfService"), val: 2, disabled: false },
        // { txt: this.$t("ProrateVacation"), val: 3, disabled: false },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("positonLevel"),
          value: "position_lvl",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("position"),
          value: "position_Name",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("NumberOfDaysYear"),
          value: "availability_Days",
          align: "left",
          sortable: false,
        },
      ];
    },
    headers1() {
      return [
        {
          text: this.$t("EmployeeLengthService"),
          value: "length",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("NumberOfTimeOff"),
          value: "txtxt",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("action"),
          value: "actionadd",
          align: "center",
          sortable: false,
        },
      ];
    },
    headers2() {
      return [
        {
          text: this.$t("Period"),
          value: "anuual",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("EmployeeLengthService"),
          value: "length",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("accrualrate"),
          value: "txtxt",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("action"),
          value: "actionadd",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    chosenfile() {
      if (this.chosenfile != null) {
        this.showup = true;
      } else {
        this.showup = false;
      }
    },
  },
  mounted() {
    this.pass_id =parseInt(this.$route.params.to);
    this.GetTOT();
    //this.GetTOT1();
    this.GetTOT2();
    this.GetTempold();
    this.GetTimeOffType();
  },
  methods: {
    back() {
      let self = this;
      self.back_t = true;
      self.showbasic = false;
      self.showprovacation = false;
      self.showlengthofservice = false;
      self.$router.replace({ path: "/organization/timeoff/timeofftype" }).then(() => {
        self.$nextTick(() => {
          self.$router.replace({ path: self.$route.path });
        });
      });
    },
    TOEPAddDialogCancel() {
      (this.addtempPV = {
        employment_length: null,
        start_Year: null,
        end_Year: null,
        availability_Days: null,
        anuual: null,
      }),
        (this.TOEPAddDialog = false);
    },
    TOEAddDialogCancel() {
      (this.addtempLS = {
        employment_length: null,
        start_Year: null,
        end_Year: null,
        availability_Days: null,
      }),
        (this.TOEAddDialog = false);
    },
    addnum(item) {
      if (item.availability_Days < 98 && item.availability_Days >= 0) {
        item.availability_Days++;
      }
    },
    subnum(item) {
      if (item.availability_Days > 0 && item.availability_Days <= 98) {
        item.availability_Days--;
      }
    },
    // btnhideedit() {
    //   this.showbtn = false;
    //   this.showbtn1 = true;
    //   this.txtdismiss = false;
    // },
    btnhideedit1() {
      this.showbtn2 = false;
      this.showbtn3 = true;
      this.txtdismiss1 = false;
    },
    btneedit() {
      this.showbtn = true;
      this.showbtn1 = false;
      this.txtdismiss = true;
    },
    btneedit1() {
      this.showbtn2 = true;
      this.showbtn3 = false;
      this.txtdismiss1 = true;
    },
    changeshow(t) {
      let self = this;
      let txtc = t;
      self.tempnum = txtc;
      if (txtc == 1) {
        self.showprovacation = false;
        self.showlengthofservice = false;
        self.showbasic = true;
      } else if (txtc == 2) {
        self.showbasic = false;
        self.showprovacation = false;
        self.showlengthofservice = true;
        // self.txtdismiss = true;
        this.GetTOT1();
        this.GetTempold();
      } else if (txtc == 3) {
        self.showbasic = false;
        self.showlengthofservice = false;
        self.showprovacation = true;
        // self.txtdismiss1 = true;
        this.GetTOT1();
        this.GetTempold();
      }
    },
    TOTODeletedialog(id, id1) {
      if (id != null && id1 != "") {
        this.Ids = id;
      } else {
        this.Ids = id1;
      }
      this.TOTDeletedialog = true;
    },
    getupload() {
      document.getElementById("fileInputButton").click();
      //this.showup = true;
    },
    closeUpload() {
      this.TOTUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeBasicUpload() {
      this.TOTBasicUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeLSUpload() {
      this.TOTLSUploaddialog = false;
      (this.chosenfile = null), (this.showup = false);
    },
    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    showtab(mytab) {
      if (mytab == 3) {
        this.show = true;
      } else {
        this.show = false;
      }
      this.lengthof = mytab;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    whatthelang() {
      let thelink = "";
      if (localStorage.Templang == "English") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Vacation Length Of Employment Master Data.xlsx";
      } else if (localStorage.Templang == "ประเทศไทย") {
        thelink =
          "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Vacation Length Of Employment Master Data.xlsx";
      } else if (localStorage.Templang == "Tiếng Việt") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Vacation Length Of Employment Master Data.xlsx";
      } else if (localStorage.Templang == "中文") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Vacation Length Of Employment Master Data.xlsx";
      } else if (localStorage.Templang == "မြန်မာဘာသာ") {
        thelink =
          "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Vacation Length Of Employment Master Data.xlsx";
      } else {
        if (sessionStorage.flag == "English") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Vacation Length Of Employment Master Data.xlsx";
        } else if (sessionStorage.flag == "Thai") {
          thelink =
            "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Vacation Length Of Employment Master Data.xlsx";
        } else if (sessionStorage.flag == "Vietnam") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Vacation Length Of Employment Master Data.xlsx";
        } else if (sessionStorage.flag == "Chinese") {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Vacation Length Of Employment Master Data.xlsx";
        } else {
          thelink =
            "https://optimisticprdstorage.blob.core.windows.net/optimisticprdfiles/Vacation Length Of Employment Master Data.xlsx";
        }
      }
      return thelink;
    },
    GetTimeOffType() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOff/GetTimeOffByCompanyID`, tempp)
        .then(function (response) {
          self.TOTList = response.data.data;
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    GetTOT() {
      let self = this;
      self.LoadingDialog = true;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      let tempp = {
        timeoff_ID: tempID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOffType/GetTimeOffTypePloicy`, tempp)
        .then(function (response) {
          let TTOTypeList = response.data.data;
          self.LoadingDialog = false;
          self.delcon = 0;
          //self.typename = self.TOTypeList1[0].timeoff_Type;
          //self.typename = self.TOTypeList[0].timeoff_Type;
          let pol = TTOTypeList[0].company_Policy;
          self.select = pol;
          if (pol == 1) {
            self.itemselect[0].disabled = false;
            self.itemselect[1].disabled = true;
            // self.itemselect[2].disabled = true;
            self.showbasic = true;
            self.showlengthofservice = false;
            self.showprovacation = false;
            self.tempnum = 1;
            self.GetTOTWithPolicy1();
          } else if (pol == 2) {
            self.itemselect[0].disabled = true;
            self.itemselect[1].disabled = false;
            // self.itemselect[2].disabled = false;
            self.showbasic = false;
            self.showlengthofservice = true;
            self.showprovacation = false;
            self.tempnum = 2;
            self.GetTOT1();
          } else if (pol == 3) {
            self.itemselect[0].disabled = true;
            self.itemselect[1].disabled = false;
            // self.itemselect[2].disabled = false;
            self.showbasic = false;
            self.showlengthofservice = false;
            self.showprovacation = true;
            self.tempnum = 3;
            self.GetTOT1();
          }
        });
    },
    GetTOTWithPolicy1() {
      let self = this;
      self.LoadingDialog = true;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      let tempp = {
        timeoff_ID: tempID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOffType/GetTimeOffTypeByTimeOffID`, tempp)
        .then(function (response) {
          self.TOTypeList = response.data.data;
          self.LoadingDialog = false;
          self.typename = self.TOTypeList[0].timeoff_Type;
          let pol = self.TOTypeList[0].company_Policy;
          self.select = pol;
          if (pol == 1) {
            self.itemselect[0].disabled = false;
            self.itemselect[1].disabled = true;
            // self.itemselect[2].disabled = true;
            // self.showbtn = true;
            self.showbtn1 = false;
          } else {
            self.itemselect[0].disabled = true;
            self.itemselect[1].disabled = false;
            // self.itemselect[2].disabled = false;
          }
        });
    },
    GetTOT1() {
      let self = this;
      self.LoadingDialog = true;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      let tempp = {
        timeoff_ID: tempID,
        company_ID: store.state.companyID,
        company_Policy: self.tempnum,
      };
      axios
        .post(`${self.url}TimeOffType/GetTimeOffTypeByTimeOffIDAndCPID`, tempp)
        .then(function (response) {
          self.TOTypeList1 = response.data.data;
          self.LoadingDialog = false;
          let pol = 0;
          if (self.TOTypeList1.length != 0) {
            self.typename = self.TOTypeList1[0].timeoff_Type;
            pol = self.TOTypeList1[0].company_Policy;
          } else {
            self.typename = "Vacation";
            pol = parseInt(self.tempnum);
          }

          self.select = pol;
          if (pol == 1) {
            self.itemselect[0].disabled = false;
            self.itemselect[1].disabled = true;
            // self.itemselect[2].disabled = true;
            if (self.TOTypeList1.length == 0) {
              self.showbtn = false;
              self.showbtn1 = true;
              self.txtdismiss = false;
            } else {
              self.showbtn = true;
              self.showbtn1 = false;
              // self.txtdismiss = true;
            }
          } else if (pol == 2) {
            self.itemselect[0].disabled = true;
            self.itemselect[1].disabled = false;
            // self.itemselect[2].disabled = false;
            self.showbasic = false;
            self.showlengthofservice = true;
            self.showprovacation = false;
            if (self.TOTypeList1.length == 0) {
              self.showbtn = false;
              self.showbtn1 = true;
              self.txtdismiss = false;
            } else {
              self.showbtn = true;
              self.showbtn1 = false;
              // self.txtdismiss = true;
            }
          } else if (pol == 3) {
            self.itemselect[0].disabled = true;
            self.itemselect[1].disabled = false;
            // self.itemselect[2].disabled = false;
            self.showbasic = false;
            self.showlengthofservice = false;
            self.showprovacation = true;
            if (self.TOTypeList1.length == 0) {
              self.showbtn2 = false;
              self.showbtn3 = true;
              self.txtdismiss = false;
            } else {
              self.showbtn2 = true;
              self.showbtn3 = false;
              // self.txtdismiss1 = true;
            }
          }
        });
    },
    GetTOT2() {
      let self = this;
      self.LoadingDialog = true;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      let tempp = {
        timeoff_ID: tempID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOff/GetTimeOffByTimeOffIDAndCompanyID`, tempp)
        .then(function (response) {
          self.TOTypeList2 = response.data.data;
          self.LoadingDialog = false;
          self.typename = self.TOTypeList2[0].timeoff_Type;
          if (self.TOTypeList2[0].vacation_Status == true) {
            self.select = 2;
            self.showbasic = false;
            self.showlengthofservice = true;
            self.itemselect[0].disabled = true;
            self.itemselect[1].disabled = false;
            // self.itemselect[2].disabled = false;
            // self.showbtn = false;
            // self.showbtn1 = true;
          } else {
            self.select = 1;
            self.itemselect[0].disabled = false;
            self.itemselect[1].disabled = true;
            // self.itemselect[2].disabled = true;
            self.showbtn2 = false;
            self.showbtn3 = true;
          }
        });
    },
    GetTempold() {
      let self = this;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      let tempp = {
        timeoff_ID: tempID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}TimeOffType/GetTimeOffTypePloicy`, tempp)
        .then(function (response) {
          self.TempToDelete = response.data.data;
        });
    },

    UpdateBasic() {
      let self = this;
      self.LoadingDialog = true;
      if (self.typename != "" && self.typename != null) {
        let temparr = self.pusharr();
        self.toID = parseInt(self.pass_id);
        let tempID = self.toID;
        // let samecondi = false;
        // let FilterTOT = self.TOTList.filter(
        //   (data) => data.timeOff_ID != self.toID
        // );
        // for (let j = 0; j < FilterTOT.length; j++) {
        //   if (self.typename.toLowerCase() == FilterTOT[j].timeoff_Type.toLowerCase()) {
        //     samecondi = false;
        //     break;
        //   } else {
        //     samecondi = true;
        //   }
        // }
        // if (samecondi == true) {
        let temptype = {
          staffData1: temparr,
          timeOffTypeData1: {
            timeoff_ID: tempID,
            timeoff_Type: self.typename,
            company_ID: store.state.companyID,
          },
        };
        axios
          .post(`${self.url}TimeOffType/UpdateTimeOffType`, temptype)
          .then(function (response) {
            if (response.data.status == 0)
            {
              alert(response.data.message);
              self.LoadingDialog = false;
              self.btneedit();
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Time Off Basic Fail", 10, "Medium");
            //alert(error + "Correlation ID is 10");
            self.LoadingDialog = false;
          });
      } else {
        alert("Enter Time off type");
        self.LoadingDialog = false;
      }
    },
    pusharr() {
      let self = this;
      let temparr = [
        {
          availability_Day_ID: null,
          availability_Days: null,
          employment_length: null,
          start_Year: null,
          end_Year: null,
          company_Policy: null,
          anuual: "",
          timeoff_ID: null,
          position_ID: null,
          company_ID: null,
        },
      ];
      for (let i = 0; i < self.TOTypeList.length; i++) {
        if (i == 0) {
          temparr[i].availability_Day_ID =
            self.TOTypeList[i].availability_Day_ID;
          //   if(self.PositionList[i].availability_Days!=undefined)
          // {
          temparr[i].availability_Days = parseInt(
            self.TOTypeList[i].availability_Days
          );
          //}
          //temparr[i].availability_Days = self.TOTypeList[i].availability_Days;
          temparr[i].employment_length = 0;
          temparr[i].start_Year = 0;
          temparr[i].end_Year = 0;
          temparr[i].company_Policy = 1;
          temparr[i].anuual = "";
          temparr[i].timeoff_ID = self.TOTypeList[i].timeoff_ID;
          temparr[i].position_ID = self.TOTypeList[i].position_ID;
          temparr[i].company_ID = store.state.companyID;
        } else {
          let availabledays = null;
          if (self.TOTypeList[i].availability_Days == undefined) {
            availabledays = 0;
          } else {
            availabledays = parseInt(self.TOTypeList[i].availability_Days);
          }
          temparr.push({
            availability_Day_ID: self.TOTypeList[i].availability_Day_ID,
            availability_Days: availabledays,
            employment_length: 0,
            start_Year: 0,
            end_Year: 0,
            company_Policy: 1,
            anuual: null,
            timeoff_ID: self.TOTypeList[i].timeoff_ID,
            position_ID: self.TOTypeList[i].position_ID,
            company_ID: store.state.companyID,
          });
        }
      }
      return temparr;
    },
    addtemparrpv() {
      let self = this;
      self.LoadingDialog = true;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      if (
        self.addtempPV.availability_Days != null &&
        self.addtempPV.start_Year != null &&
        self.addtempPV.anuual != null &&
        self.addtempPV.availability_Days != "" &&
        self.addtempPV.start_Year != "" &&
        self.addtempPV.anuual != ""
      ) {
        let tetid = self.tid++;
        if (self.addtempPV.end_Year == "" || self.addtempPV.end_Year == null) {
          self.addtempPV.end_Year = 0;
        }
        self.TOTypeList1.unshift({
          tid: tetid,
          availability_Days: self.addtempPV.availability_Days,
          employment_length: self.lengthof,
          start_Year: self.addtempPV.start_Year,
          end_Year: self.addtempPV.end_Year,
          company_Policy: self.tempnum,
          anuual: self.addtempPV.anuual,
          timeoff_ID: tempID,
          position_ID: 0,
          company_ID: store.state.companyID,
        });
        self.TOEPAddDialog = false;
        self.addtempPV.availability_Days = null;
        self.addtempPV.employment_length = null;
        self.addtempPV.start_Year = null;
        self.addtempPV.end_Year = null;
        self.addtempPV.anuual = null;
        self.LoadingDialog = false;
      } else {
        alert("Fill all Fields");
        self.LoadingDialog = false;
      }
      self.Savebtntxt = "Save";
    },
    addtemparrls() {
      let self = this;
      self.LoadingDialog = true;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      if (
        self.addtempLS.availability_Days != null &&
        self.addtempLS.start_Year != null &&
        self.addtempLS.availability_Days != "" &&
        self.addtempLS.start_Year != ""
      ) {
        let tetid = self.tid++;
        if (self.addtempLS.end_Year == "" || self.addtempLS.end_Year == null) {
          self.addtempLS.end_Year = 0;
        }
        // if (self.showbtn == false) {
        //   self.txtdismiss = false;
        // } else {
        //   self.txtdismiss = true;
        // }
        self.TOTypeList1.unshift({
          tid: tetid,
          availability_Days: self.addtempLS.availability_Days,
          employment_length: self.lengthof,
          start_Year: self.addtempLS.start_Year,
          end_Year: self.addtempLS.end_Year,
          company_Policy: self.tempnum,
          anuual: "",
          timeoff_ID: tempID,
          position_ID: 0,
          company_ID: store.state.companyID,
        });
        self.TOEAddDialog = false;
        self.addtempLS.availability_Days = null;
        self.addtempLS.employment_length = null;
        self.addtempLS.start_Year = null;
        self.addtempLS.end_Year = null;
        self.LoadingDialog = false;
      } else {
        alert("Fill all Fields");
        self.LoadingDialog = false;
      }
      self.Savebtntxt = "Save";
    },
    DeleteOldArray() {
      let self = this;
      self.GetTempold();
      let temparr = [
        {
          availability_Day_ID: null,
        },
      ];
      for (let i = 0; i < self.TempToDelete.length; i++) {
        if (i == 0) {
          temparr[i].availability_Day_ID =
            self.TempToDelete[i].availability_Day_ID;
        } else {
          temparr.push({
            availability_Day_ID: self.TempToDelete[i].availability_Day_ID,
          });
        }
      }
      axios
        .post(
          `${self.url}TimeOffType/DeleteOldTimeOffTypeVacationList`,
          temparr
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.TOTDeletedialog = false;
            self.TempToDelete = [];
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
    Updatepolicy() {
      let self = this;
      self.LoadingDialog = true;
      self.DeleteOldArray();
      let temparr = self.tearraylist();
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      let temptype = {
        datalist: temparr,
        timeOffTypeVData: {
          timeoff_ID: tempID,
          timeoff_Type: self.typename,
          company_ID: store.state.companyID,
        },
      };
      let temp = {
        timeOffTypeVData: {
          timeoff_ID: tempID,
          timeoff_Type: self.typename,
          company_ID: store.state.companyID,
        },
      };
      let parameter = self.TOTypeList1.length == 0 ? temp : temptype;
      let urlname =
        self.TOTypeList1.length == 0
          ? "UpdateTimeOffTypeVacationName"
          : "UpdateTimeOffTypeVacation";

      axios.post(`${self.url}TimeOffType/${urlname}`, parameter)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          self.GetTOT();
          self.btneedit();
          self.btneedit1();
          self.GetTempold();
          self.LoadingDialog = false;
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Update Time Off Fail", 10, "Medium");
        self.LoadingDialog = false;
      });
      if (self.TOTypeList1.length != 0)
      {
        if (self.delcon == 1)
        {
          // alert("Delete successfully!");
          self.delcon = 0;
          self.LoadingDialog = false;
        }
      }
    },
    tearraylist() {
      let self = this;
      let temparr = [
        {
          availability_Days: null,
          employment_length: null,
          start_Year: null,
          end_Year: null,
          company_Policy: null,
          anuual: "",
          timeoff_ID: null,
          position_ID: null,
          company_ID: null,
        },
      ];
      for (let i = 0; i < self.TOTypeList1.length; i++) {
        if (i == 0) {
          temparr[i].availability_Days = parseInt(
            self.TOTypeList1[i].availability_Days
          );
          temparr[i].employment_length = self.TOTypeList1[i].employment_length;
          temparr[i].start_Year = parseInt(self.TOTypeList1[i].start_Year);
          temparr[i].end_Year = parseInt(self.TOTypeList1[i].end_Year);
          temparr[i].company_Policy = self.TOTypeList1[i].company_Policy;
          temparr[i].anuual = self.TOTypeList1[i].anuual;
          temparr[i].timeoff_ID = self.TOTypeList1[i].timeoff_ID;
          temparr[i].position_ID = self.TOTypeList1[i].position_ID;
          temparr[i].company_ID = store.state.companyID;
        } else {
          temparr.push({
            availability_Days: parseInt(self.TOTypeList1[i].availability_Days),
            employment_length: self.TOTypeList1[i].employment_length,
            start_Year: parseInt(self.TOTypeList1[i].start_Year),
            end_Year: parseInt(self.TOTypeList1[i].end_Year),
            company_Policy: self.TOTypeList1[i].company_Policy,
            anuual: self.TOTypeList1[i].anuual,
            timeoff_ID: self.TOTypeList1[i].timeoff_ID,
            position_ID: self.TOTypeList1[i].position_ID,
            company_ID: store.state.companyID,
          });
        }
      }
      //console.log(temparr);
      return temparr;
    },
    previewBasicFiles() {
      let self = this;
      let files = this.chosenfile;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "New Time Off Type Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            this.importData = XLSX.utils.sheet_to_json(worksheet);
            let combileArray = [];
            for (let i = 0; i < this.importData.length; i++) {
              this.tempimport = {
                ...this.importData[i],
                company_ID: store.state.companyID,
              };
              self.TOTypeList[i].availability_Days =
                combileArray[i].availability_Days;
              combileArray.push(this.tempimport);
            }
            self.TOTBasicUploaddialog = false;
          } else {
            alert("File name must be New Time Off Type Master Data.xlsx");
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
      }
    },
    checkexcel(data, data1) {
      for (let i = 0; i < data.length; i++) {
        if (data1[i + 1][0] == undefined) {
          this.errorMessage.push("employment_length");
        }
        if (data1[i + 1][1] == undefined) {
          this.errorMessage.push("start_Year");
        }
        if (data1[i + 1][2] == undefined) {
          this.errorMessage.push("end_Year");
        }
        if (data1[i + 1][3] == undefined) {
          this.errorMessage.push("availability_Days");
        }
      }
    },
    previewLSFiles() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Vacation Length Of Employment Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            this.importData = XLSX.utils.sheet_to_json(worksheet);
            let temp = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            const file_data = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            self.checkexcel(this.importData, file_data);
            if (temp.length != 0 && self.errorMessage.length == 0) {
              for (let i = 0; i < this.importData.length; i++) {
                let tetid = self.tid++;
                this.tempimport = {
                  employment_length: file_data[i + 1][0],
                  start_Year: file_data[i + 1][1],
                  end_Year: file_data[i + 1][2],
                  availability_Days: file_data[i + 1][3],
                  tid: tetid,
                  company_Policy: 2,
                  timeoff_ID: tempID,
                  company_ID: store.state.companyID,
                };
                if (this.tempimport.employment_length == "Less than") {
                  this.tempimport.employment_length = 1;
                } else if (
                  this.tempimport.employment_length == "Greater than"
                ) {
                  this.tempimport.employment_length = 2;
                } else if (this.tempimport.employment_length == "Between") {
                  this.tempimport.employment_length = 3;
                } else if (this.tempimport.employment_length == "Equal to") {
                  this.tempimport.employment_length = 4;
                }
                self.TOTypeList1.unshift(this.tempimport);
              }
              self.TOTLSUploaddialog = false;
              self.LoadingDialog = false;
              // self.btnhideedit();
            } else {
              self.LoadingDialog = false;
              if (temp.length == 0) {
                alert(
                  "Please fill employment_length, start_Year, end_Year and availability_Days."
                );
              } else {
                let Message = [...new Set(self.errorMessage)];
                alert(`Please fill ${Message.join(", ")}`);
              }
            }
          } else {
            alert(
              "File name must be Vacation Length Of Employment Master Data.xlsx"
            );
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
        self.LoadingDialog = false;
      }
    },
    previewFiles() {
      let self = this;
      self.LoadingDialog = true;
      let files = this.chosenfile;
      self.toID = parseInt(self.pass_id);
      let tempID = self.toID;
      let reader = new FileReader();
      if (self.chosenfile != null && self.chosenfile != "") {
        reader.onload = function (e) {
          let filename = files.name;
          if (filename == "Vacation Prorate Master Data.xlsx") {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let sheetName = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[sheetName];
            this.importData = XLSX.utils.sheet_to_json(worksheet);
            for (let i = 0; i < this.importData.length; i++) {
              let tetid = self.tid++;
              this.tempimport = {
                ...this.importData[i],
                tid: tetid,
                company_Policy: 3,
                timeoff_ID: tempID,
                company_ID: store.state.companyID,
              };
              if (this.tempimport.employment_length == "Less than") {
                this.tempimport.employment_length = 1;
              } else if (this.tempimport.employment_length == "Greater than") {
                this.tempimport.employment_length = 2;
              } else if (this.tempimport.employment_length == "Between") {
                this.tempimport.employment_length = 3;
              } else if (this.tempimport.employment_length == "Equal to") {
                this.tempimport.employment_length = 4;
              }
              self.TOTypeList1.unshift(this.tempimport);
            }
            self.TOTUploaddialog = false;
            self.LoadingDialog = false;
            self.btnhideedit1();
          } else {
            alert("File name must be Vacation Prorate Master Data.xlsx");
            self.LoadingDialog = false;
          }
        };
        reader.readAsArrayBuffer(files);
      } else {
        alert("Please Input File!");
        self.LoadingDialog = false;
      }
    },
    DeleteTOT() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.Ids;
      for (var i = 0; i < self.TOTypeList1.length; i++)
        if (
          self.TOTypeList1[i].tid == id ||
          self.TOTypeList1[i].availability_Day_ID == id
        ) {
          self.TOTypeList1.splice(i, 1);
        }
      self.delcon = 1;
      self.TOTDeletedialog = false;
      self.LoadingDialog = false;
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
  .upload_btn {
    margin: unset !important;
    margin-left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .company_text {
    text-align: unset !important;
  }
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
  width: 17rem !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}
.posbtn {
  width: 22px;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
>>> .v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
>>> .mdi-paperclip::before {
  content: none;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .v-dialog {
  border-radius: 15px !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 10px !important;
}
>>> .theme--light.v-data-table thead tr th {
  font-weight: normal;
  font-size: 16px;
  color: #f99d20;
}
>>> .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(0, 0, 0, 0.54);
  background-color: #00000029;
}
.v-tabs .v-tab--active {
  color: #ffffff;
  background-color: #f99d20 !important;
  border-radius: 5px 5px 5px 5px;
  width: 135px;
  height: 40px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 5px 5px 5px 5px;
  width: 135px;
  height: 40px;
}
.v-input__icon--append .v-icon {
  color: purple;
  display: none;
}
</style>
<style>
/* .txttype1.v-text-field .v-input__prepend-inner,
.v-input__append-inner {
  display: none;
} */
.inputbot.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputbot.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
.txttype1.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.txttype1.v-text-field > .v-input__control > .v-input__slot::after {
  border-style: none;
}
.txttype1.theme--light.v-select .v-select__selections {
  color: #f89d1f;
}
.txttype1 {
  font-family: "Kanit", sans-serif;
  color: orange;
}
.txtnumbox {
  text-decoration: none !important;
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  height: 37px;
  opacity: 1 !important;
  border-radius: 4px !important;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 0px;
}
.txtnumbox.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.txtnumbox.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.txttypee {
  text-decoration: none !important;
  color: #3c4043;
  border-style: solid;
  border-color: #f99d20;
  color: #f99d20;
  max-width: 152px;
  height: 37px;
  border-radius: 4px !important;
  padding-top: 0px;
  padding-left: 10px;
  margin-left: 20px;
}
.txttype1 {
  text-decoration: none !important;
  border-style: solid;
  border-color: #f99d20;
  color: #f99d20;
  width: 209px;
  height: 37px;
  border-radius: 4px !important;
  margin-top: -1px;
  padding-top: 0px;
  padding-left: 0px;
}
.txttypee.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.txttypee.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
