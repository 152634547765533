<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
      <p class="txt-header">
        {{ $t("changepassword") }}
      </p>
    <div style="height: auto" class="mt-6">
      <v-card min-height="60vh" class="d-flex flex-wrap flex-column pa-6">
        <!-- ChangePassword Field -->
        <v-col ref="form">
          <!-- Current password -->
          <v-col cols="12" sm="6" md="4">
            <label
              >{{ $t("currentpassword")
              }}<span class="red--text"> *</span></label
            >
            <v-text-field
              :disabled="permission.add"
              autocomplete="off"
              v-model="old_Pass"
              required
              ref="inputRef"
              :type="show1 ? 'text' : 'password'"
              :rules="rules.old_Pass"
              clearable
              color="#F99D20"
              dense
            >
              <template v-slot:append>
                <v-btn icon color="white">
                  <img
                    width="40"
                    height="40"
                    @click="show1 = !show1"
                    :src="show1 ? pwdimage.showpwd : pwdimage.hidepwd"
                    alt
                  />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <!-- New password -->
          <v-col cols="12" sm="6" md="4">
            <label
              >{{ $t("newpassword") }}<span class="red--text"> *</span></label
            >
            <v-text-field
              :disabled="permission.add"
              autocomplete="off"
              v-model="new_Password"
              color="#F99D20"
              :type="show2 ? 'text' : 'password'"
              :rules="rules.new_Password"
              required
              dense
            >
              <template v-slot:append>
                <v-btn icon color="white">
                  <img
                    width="40"
                    height="40"
                    @click="show2 = !show2"
                    :src="show2 ? pwdimage.showpwd : pwdimage.hidepwd"
                    alt
                  />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <!-- Confirm new password -->
          <v-col cols="12" sm="5" md="4">
            <label
              >{{ $t("confirmNewpassword") }}
              <span class="red--text"> *</span>
            </label>
            <v-text-field
              :disabled="permission.add"
              autocomplete="off"
              v-model="confirm_Password"
              color="#F99D20"
              :type="show3 ? 'text' : 'password'"
              :rules="rules.confirm_Password"
              required
              dense
            >
              <template v-slot:append>
                <v-btn icon color="white">
                  <img
                    width="40"
                    height="40"
                    @click="show3 = !show3"
                    :src="show3 ? pwdimage.showpwd : pwdimage.hidepwd"
                    alt
                  />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-col>
        <!-- BUTTON CANCEL&SAVE -->
        <div class="d-flex flex-row-reverse">
          <v-btn
            class="text_transform_none fix-btn sc commonsavebtn"
            @click="Newpassword"
            :disabled="permission.add"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            outlined
            class="text_transform_none fix-btn sc commoncancelbtn"
            text
            router
            :disabled="permission.add"
            @click="cancel"
            >{{ $t("cancel") }}</v-btn
          >
        </div>
        <!-- POPUP Status -->
        <v-dialog v-model="dialog" width="500"
          ><v-card class="d-flex rounded-xl pa-4 flex-wrap">
            <v-card-title style="font-size: 22px" class="px-3 pt-2">
              {{ $t("changepassword") }}
            </v-card-title>
            <v-card-text class="d-inline-flex mb-6 ml-6">
              {{ $t("confirmtoChangepassword") }}
            </v-card-text>

            <v-card-actions class="d-flex pa-auto ml-auto">
              <v-spacer></v-spacer>
              <!-- Cancel button in dialog -->
              <v-btn
                class="text_transform_none fix-btn sc commoncancelbtn"
                text
                router
                @click="dialog = false"
                :disabled="permission.add"
              >
                {{ $t("Cancel") }}
              </v-btn>
              <!-- Confirm button in dialog -->
              <v-btn
                class="text_transform_none fix-btn sc commonsavebtn"
                @click="saveNewpass"
                :disabled="permission.add"
              >
                {{ $t("Confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>
<script>
// import store from "../../store";
import axios from "axios";
import enurl from "@/api/environment";
import CheckViewAdd from "@/function/RolePermissions.js";
import LogTrace from "@/function/Log.js";
export default {
  mixins: [CheckViewAdd, LogTrace],
  name: "ChangePassword",
  data() {
    return {
      permission: {
        add: true,
      },
      url: enurl.apiUrl,
      dialog: false,
      user_ID: 0,
      old_Pass: "",
      user_Name: "",
      new_Password: "",
      confirm_Password: "",
      show1: false,
      show2: false,
      show3: false,
      rules: {
        old_Pass: [(val) => (val || "").length > 0 || "This field is required"],
        new_Password: [
          (val) => (val || "").length > 0 || "This field is required",
        ],
        confirm_Password: [
          (val) => (val || "").length > 0 || "This field is required",
        ],
      },
      pwdimage: {
        showpwd: require("@/assets/images/reveal icon@2x.png"),
        hidepwd: require("@/assets/images/pass@2x.png"),
      },
    };
  },
  mounted() {
    let self = this;
    self.permission = self.CheckViewAdd(188, 189);
  },
  methods: {
    //clear text field
    cancel() {
      let self = this;
      self.old_Pass = null;
      self.confirm_Password = null;
      self.new_Password = null;
    },
    // Save button
    Newpassword() {
      let self = this;
      let tempvali = self.validateTrue();
      if (tempvali == true) {
        self.dialog = true;
      } else {
        self.ValidateFalse();
      }
    },
    chekText(newPass, confirm) {
      if (newPass.length === confirm.length && newPass === confirm) {
        return true;
      } else {
        alert("The password is not same. ");
        return false;
      }
    },

    validateTrue() {
      let self = this;
      if (
        self.new_Password != null &&
        self.new_Password != "" &&
        self.old_Pass != null &&
        self.old_Pass != "" &&
        self.confirm_Password != null &&
        self.confirm_Password != ""
      ) {
        return true;
      } else if (
        self.new_Password == null ||
        self.new_Password == "" ||
        self.old_Pass == null ||
        self.old_Pass == "" ||
        self.confirm_Password == null ||
        self.confirm_Password == ""
      ) {
        return false;
      }
      self.chekText(self.new_Password, self.confirm_Password);
    },
    ValidateFalse() {
      let self = this;
      if (
        self.new_Password == null ||
        self.new_Password == "" ||
        self.old_Pass == null ||
        self.old_Pass == "" ||
        self.confirm_Password == null ||
        self.confirm_Password == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },
    //Save button in dialog
    saveNewpass() {
      let self = this;
      let tempvali = self.validateTrue();
      let check = self.chekText(self.new_Password, self.confirm_Password);
      self.dialog = false;
      if (tempvali == true && check == true) {
        let temp = {
          user_ID: parseInt(self.$store.state.userid),
          OldPassword: self.old_Pass,
          password: self.confirm_Password,
        };
        axios
          .post(`${self.url}Login/ChangePassword`, temp)
          .then(function (response) {
            alert(response.data.message);
            self.old_Pass = null;
            self.confirm_Password = null;
            self.new_Password = null;
            //self.LogTrace(null, "Update New Password", 65, "Medium");
          });
      } else {
        self.ValidateFalse();
      }
    },
  },
};
</script>

<style scoped>
label {
  font-size: 15px;
}
.sc {
  padding: 8px;
  margin: 8px;
  font-size: 14px;
}
@media (max-width: 414px) {
  .fix-btn {
    width: 100px !important;
  }
}
</style>