<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 2.5rem;">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 20px">
        {{ $t("PersonnelManagement") }}
      </p>
    </div>

    <v-card class="mt-6 mb-5" style="margin-bottom: 5rem">
      <v-row class="ma-3 pt-3 ml-6">
        <v-tabs class="typetabs" style="border-bottom: 1px solid #EAEAEA;">
          <v-tabs-slider color="#F89D27"></v-tabs-slider>
          <v-tab
            class="text-capitalize emptabs"
            @click="changeTab(0)"
          > 
            {{ this.$t("All") }}
            <v-badge
              :color="activeTabIndex === 0 ? '#F89D1F' : '#E0E0E0'"
              class="badgetype"
              style="margin-top: 10px; margin-left: 10px;"
            >
            <template v-slot:badge>
              <span :style="{ color: activeTabIndex === 0 ? 'white' : '#7D7A7A' }">{{ itemCount }}</span>
            </template>
            </v-badge>
          </v-tab>
          <v-tab
            class="text-capitalize emptabs"
            @click="changeTab(1)"
          > 
            {{ this.$t("Probation") }}
            <v-badge
              :color="activeTabIndex === 1 ? '#F89D1F' : '#E0E0E0'"
              class="badgetype"
              style="margin-top: 10px; margin-left: 10px;"
            >
            <template v-slot:badge>
              <span :style="{ color: activeTabIndex === 1 ? 'white' : '#7D7A7A' }">{{ itemCountByProbation }}</span>
            </template>
            </v-badge>
          </v-tab>
          <v-tab
            class="text-capitalize emptabs"
            @click="changeTab(2)"
          > 
            {{ this.$t("Permanent") }}
            <v-badge
              :color="activeTabIndex === 2 ? '#F89D1F' : '#E0E0E0'"
              class="badgetype"
              style="margin-top: 10px; margin-left: 10px;"
            >
            <template v-slot:badge>
              <span :style="{ color: activeTabIndex === 2 ? 'white' : '#7D7A7A' }">{{ itemCountByPermanent }}</span>
            </template>
            </v-badge>
          </v-tab>
          <v-tab
            class="text-capitalize emptabs"
            @click="changeTab(3)"
          > 
            {{ this.$t("Resign") }}
            <v-badge
              :color="activeTabIndex === 3 ? '#F89D1F' : '#E0E0E0'"
              class="badgetype"
              style="margin-top: 10px; margin-left: 10px;"
            >
            <template v-slot:badge>
              <span :style="{ color: activeTabIndex === 3 ? 'white' : '#7D7A7A' }">{{ itemCountByResign }}</span>
            </template>
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </v-row>
      <v-row class="ma-3">
        <!-- <v-col cols="12" xs="12" sm="4" md="5" lg="5" xl="4"> -->
        <v-col cols="12" xs="12" sm="6" md="5" lg="4">
          <v-text-field
            :placeholder="$t('SearchEmployeeIDDepartment')"
            v-model.lazy="searchtxt"
            color="#F99D20"
            dense
            class="pt-1"
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size: 14px; margin-top:21px;"
            @keyup.enter="searchemp()"
            @input="searchempEmpty()">
          </v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="2"><p class="ma-0 pb-1" style="font-size:14px; font-weight: 100;">{{ $t("employeetype") }}</p>
          <v-autocomplete
            v-model="itemInner"
            :items="FieldList1"
            item-text="txt"
            item-value="val"
            outlined
            dense
            color="#F99D20"
            style="font-size: 14px; font-weight: 100;"
          ></v-autocomplete>
        </v-col>
        <v-btn @click="exportexcel()" style="margin-top:40px;" icon>
          <v-img src="@/assets/images/empexportexcel.svg" class="exportimg" ></v-img>
        </v-btn>
       <!-- <p class="ma-0 pb-1" style="font-size: 14px; font-weight: 100;">{{ $t("status") }}</p>
          <v-autocomplete
            v-model="itemInnerStatus"
            :items="Activelist"
            item-text="txt"
            item-value="val"
            outlined
            dense
            color="#F99D20"
            hide-details
            style="font-size: 14px; font-weight: 100;"
          ></v-autocomplete> -->
        <v-spacer></v-spacer>
        <v-col cols="12" xs="12" sm="8" md="6" lg="3" class="pt-6 mr-5 ">
          <v-row>
            <v-col cols="12" xs="12" sm="4" xl="6" lg="4">
            </v-col>
            <v-col cols="12" xs="12" sm="4" xl="6" lg="6">
              <v-btn
                color="#F99D20"
                class="white--text text-capitalize mr-5 ml-2 mt-3 px-1"
                style="font-weight: normal"
                :disabled="permission.add"
                block
                max-height="35"
                @click="addsnakbar"
                > <v-icon right dark class="mr-2">mdi-plus</v-icon>{{ $t("New Employee") }} 
                <v-icon small v-if="!addtrue" class="ml-2">mdi-chevron-down</v-icon>
                <v-icon small v-if="addtrue" class="ml-2">mdi-chevron-up</v-icon>
              </v-btn>
              <v-card
                v-model="snackbar"
                v-if="addtrue == true"
                style="z-index: 99; position: absolute; margin-left: 7px; width: 176px;"
                class="addempcard"
              >
                <v-list>
                  <v-list-item-group>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title @click="addnewstaff()">{{ $t("AddEmployee") }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title @click="batchupload()">{{ $t("BatchUpload") }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  </v-list-item-group>
              </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        style="margin-left: 25px; margin-right: 25px; z-index: 1;"
        :headers="headers"
        :items="PersonalList"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        hide-default-header
        :custom-filter="filterOnlyCapsText"
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th
                style="cursor: pointer; min-width: 150px !important;"
                class="b-text"
                @click="sortFun('employee_Code')"
              >
                {{ $t("EmployeeID") }}
                <v-icon small v-if="!fullName">mdi-arrow-down</v-icon>
                <v-icon small v-if="fullName">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 210px !important"
                class="b-text"
                @click="sortFun('fullName')"
              >
                {{ $t("Name") }} / {{ $t("position") }}
                <v-icon small v-if="!fullName">mdi-arrow-down</v-icon>
                <v-icon small v-if="fullName">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 210px !important"
                class="b-text"
                @click="sortFun('department_Name')"
              >
                {{ $t("Department") }}
                <v-icon small v-if="!position_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="position_Name">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 200px !important"
                class="b-text"
                @click="sortFun('email')"
              >
                {{ $t("employeetype") }}
                <v-icon small v-if="!email">mdi-arrow-down</v-icon>
                <v-icon small v-if="email">mdi-arrow-up</v-icon>
              </th>
               <th
                style="cursor: pointer; min-width: 200px !important"
                class="b-text"
                @click="sortFun('employeeType')"
              >
                {{ $t("employee_status") }}

                <v-icon small v-if="!employeeType">mdi-arrow-down</v-icon>
                <v-icon small v-if="employeeType">mdi-arrow-up</v-icon>
              </th>
              <th
                style="
                  cursor: pointer;
                  min-width: 120px !important;
                  text-align: center;
                "
                class="b-text"
                @click="sortFun('employeeStatus')"
              >
                {{ $t("status") }}
                <v-icon small v-if="!employeeStatus">mdi-arrow-down</v-icon>
                <v-icon small v-if="employeeStatus">mdi-arrow-up</v-icon>
              </th>
              <th
                v-show="permission_enableLogin.add == false"
                style="
                  cursor: pointer;
                  min-width: 160px !important;
                  text-align: center;
                "
                class="b-text"
                @click="sortFun('enable_Login')"
              >
                {{ $t("EnableLogin") }}
                <v-icon
                  color="orange"
                  :disabled="itemInnerStatus == 'Inactive'"
                  @click.stop="
                    itemInnerStatus == 'Active'
                      ? (enableAlldialog = true)
                      : (prevent, (enableAlldialog = false))
                  "
                  >mdi-information-outline</v-icon
                >
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr style="cursor: pointer; height: 75px;" @click="handleClick(item.employee_ID)">
            <td style="justify-content:center;display:table; padding-top:12px">
              <div class="d-flex">
                <img
                  :src="item.image != null ? item.image : 'https://optimisticpathfiles.blob.core.windows.net/optimisticimagefile/20231121140413.jpeg'"
                  style="border-radius: 20px"
                  width="40px"
                  height="40px"
                  class="mt-1"
                  alt="profile"
                />
                <p class="ml-3 mt-3">{{ item.employee_Code }}</p>
              </div>
            </td>
            <td><span style="color: #444; font-family: Kanit; font-size:14px; font-style:normal; font-weight:400; line-height:normal;">{{ item.fullName }}</span> <br> 
                <span style="color: #817F7F; font-family:kanit; font-size:13px; font-style:normal; font-weight:100; line-height:normal;">{{ item.position_Name }}</span>
            </td>
            <td>{{ item.department_Name }}</td>
            <td>{{ $t(item.employeeType) }}</td>
            <td>{{ item.activeStatus == "2" ? $t("Resign") : checkprobationdate(item.employeeStatus) ? $t("Probation") : $t("Permanent") }}</td>
            <td align="center">
              <v-btn
                v-if="item.activeStatus != 0"
                class="text-capitalize"
                style="font-weight: normal"
                rounded
                width="100"
                text
                :style="{
                  color: item.activeStatus == '1' ? '#22A534' : ' #FF6565',
                  background:
                    item.activeStatus == '1' ? '#C6E8CB' : '#FFD8D8',
                }"
                >{{ item.activeStatus == "1" ? "Active" : "Inactive" }}</v-btn
              >
              <v-btn
                v-if="item.activeStatus == 0"
                class="text-capitalize"
                style="font-weight: normal"
                rounded
                width="100"
                text
                :style="{
                  color: item.activeStatus == '0' ? '#22A534' : ' #FF6565',
                  background:
                    item.activeStatus == '0' ? '#C6E8CB' : '#FFD8D8',
                }"
                >{{ item.activeStatus == "0" ? "Active" : "Inactive" }}</v-btn
              >
            </td>
            <td
              align="center"
              v-if="item.activeStatus == '1'"
              @click.stop="ChangeEnableLogin(item)"
              v-show="permission_enableLogin.add == false"
            >
              <div v-if="item.activeStatus == '1'">
                <input type="checkbox" v-model="item.enable_Login" />
              </div>
            </td>
            <td align="center" v-else @click.prevent="item">
              <input
                type="checkbox"
                style="cursor: default"
                @click.prevent
                v-model="item.enable_Login"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right" v-if="PersonalList.length">
        <PaginationComponent
          :page="page"
          :pageCount="pageCount"
          :itemsPerPage="itemsPerPage"
          @update:page="onPageChanged"
          @update:itemsPerPage="onItemsPerPageChanged"
        />
        <!-- <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination
              @input="clickpagi()"
              v-model="page"
              :length="pageCount"
              color="#FFCD2C"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPage"
              :items="items"
              @input="itemsPerPage = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row> -->
      </div>
    </v-card>
    <!-- loadingDialog -->
    <LoadingDialog :LoadingDialog="loadingDialog"></LoadingDialog>

    <!-- Enable Login All Dialog -->
    <v-dialog v-model="enableAlldialog" max-width="550">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("Enable/Disable Login") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-radio-group
              v-model="ActiveRadio"
              class="pa-0 ma-0"
              hide-details
              column
            >
              <v-radio :value="true">
                <template v-slot:label>
                  {{ $t("Apply Enable login to all employee") }}
                </template>
              </v-radio>
              <v-radio :value="false">
                <template v-slot:label>
                  {{ $t("Apply Disable login to all employee") }}
                </template>
              </v-radio>
            </v-radio-group>
            <v-col ma-2>
              <v-text pl-4>
                {{
                  $t(
                    "Employees with Inactive status cannot allow Enable login."
                  )
                }}
              </v-text>
            </v-col>
            <v-card-actions class="d-flex pa-auto ml-auto">
              <v-spacer></v-spacer>
              <!-- Cancel button in dialog -->
              <v-btn
                color="#3C4043"
                class="text_transform_none fix-btn sc"
                rounded
                width="150px"
                height="30px"
                text
                router
                @click="enableAlldialog = false"
              >
                <span>{{ $t("Cancel") }}</span>
              </v-btn>
              <!-- Update button in dialog -->
              <v-btn
                color="#F89D1F"
                class="text_transform_none fix-btn sc"
                rounded
                width="150px"
                height="30px"
                white--text
                @click="EnableLoginAll(false)"
              >
                <span class="white--text">{{ $t("Update") }}</span>
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Upload Dialog -->
    <v-dialog v-model="PIUploaddialog" presistent max-width="1000">
      <v-card background-color="primary" style="border-radius: 15px">
        <v-card-title style="text-align: center">
          <p class="mb-2" style="margin-top: 10px; margin-left: 10px; font-family-kanit; font-size:20px; color:#716A6A;">
            {{ $t("Downloadtemplate") }}
          </p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-sheet
           outlined
           elevation="0"
           class="uploadsheet">
            <v-row class="d-flex">
              <v-col cols="3" xl="2" lg="3">
                <v-img src="@/assets/images/excelbackground.png" class="excelbg"></v-img>
              </v-col>
              <v-col>
                <p class="datatext">{{$t("EmployeeData")}}</p>
              </v-col>
              <v-col class="d-flex ml-auto justify-end">
                <v-btn dense depressed class="text-capitalize downdloadbtn" :href="CheckLang()">
                  <v-img src="@/assets/images/cloud-download 1.svg" class="mr-3"></v-img>{{$t("Download")}}
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
          <v-card class="choosecard" elevation="0">
            <v-row>
              <v-col cols="4" xl="4" lg="5">
                <v-row class="ml-5">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-img src="@/assets/images/choosefile.svg" class="uploadimg"></v-img>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center pt-0">
                    <p class="mb-0 font-weight-thin" style="font-style:kanit; color:#7D7A7A; font-size:16px; font-weight:300;">{{$t("Importemp")}}</p>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn class="text-capitalize px-4" depressed style="border-radius:5px; background-color:#959595; color:#ffffff; " @click="getupload">
                      {{$t("ChooseFile")}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="8" xl="8" lg="7" class="">
                <v-col cols="12" xl="8" lg="9" class="d-flex justify-center">
                    <v-img src="@/assets/images/xls-file 1.svg" class="xlsimg" v-show="chosenfile"></v-img>
                    <v-file-input
                      truncate-length="100"
                      v-show="chosenfile"
                      color="white"
                      class="inputbot"
                      id="fileInputButton"
                      v-model="chosenfile"
                      prepend-icon
                      accept=".xlsx"
                    >
                      <template v-slot:append>
                        <v-btn icon v-show="chosenfile">
                          <img
                            width="20"
                            height="20"
                            @click="closeinput"
                            src="@/assets/closered.png"
                            alt
                          />
                        </v-btn>
                      </template>
                    </v-file-input>
                  </v-col>
                <div class="alertmsg" style="transform: translate(0%, 30%);">
                  <p style="color: #f74747; font-size:14px;" class="mb-0">**{{ $t("excelFileFormat") }}</p>
                  <p style="color: #f74747; font-size:14px;" class="mb-0">**{{ $t("na") }}**</p>
                  <p style="color: #f74747; font-size:14px;" class="mb-0">**{{ $t("dateformat") }}**</p>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-container>
            <v-row>
              <v-col style="margin-left: 3px" align="center" cols="5">
                <v-file-input
                  truncate-length="100"
                  v-show="chosenfile"
                  color="white"
                  class="inputbot"
                  id="fileInputButton"
                  v-model="chosenfile"
                  prepend-icon
                  accept=".xlsx"
                >
                  <template v-slot:append>
                    <v-btn icon v-show="chosenfile">
                      <img
                        width="20"
                        height="20"
                        @click="closeinput"
                        src="@/assets/closered.png"
                        alt
                      />
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-btn
                  color="#ACACAC"
                  class="white--text text-capitalize"
                  width="150"
                  max-height="35"
                  @click="getupload"
                >
                  {{ $t("browse") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="9">
                <p style="color: #f74747">**{{ $t("excelFileFormat") }}</p>
                <p style="color: #f74747">**{{ $t("na") }}**</p>
                <p style="color: #f74747">**{{ $t("dateformat") }}**</p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-2">{{ $t("excelUploadFormat") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <p>{{ $t("DocumentTemplate") }}</p>
              </v-col>
              <v-col cols="12" md="8">
                <a style="color: #f99d20" :href="CheckLang()">
                  {{ $t("download") }}</a
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
            <br />
          </v-container> -->
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <p style="font-size:14px; color:#838395;" class="font-weight-thin mb-0 ml-6 mt-2">{{$t("Remarkbefore")}}</p>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              height="35"
              @click="closeUpload"
              text
              outlined
            >
              {{ $t("cancel") }}</v-btn
            >
            <v-btn
              color="#F99D20"
              class="mr-2 white--text text-capitalize"
              width="120"
              height="35"
              @click="PreviewFiles"
              :disabled="disableupload"
            >
              {{ $t("upload") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="erroruploaddialog"
      class="uploaderror"
      scrollable
      persistent
      max-width="600"
    >
      <v-card style="border-radius: 15px">
        <v-card-text>
          <v-row>
            <v-col align="left" cols="12">
              <p style="font-size: 17px; color: #444444" class="pt-4">
                Upload employees Unsuccess:
              </p>
            </v-col>
          </v-row>
          <v-row v-for="(other, o) in validateerrormessage" :key="o">
            <v-col cols="12"> {{ other }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-spacer></v-spacer>

            <v-btn
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="120"
              max-height="35"
              @click="erroruploaddialog = false"
              >OK</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Email Confrim dialog -->
    <v-dialog v-model="confirmdialog" class="dialog" max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px">
            {{ $t("EnableLogin") }}
          </p>
        </v-card-title>
        <v-card-text>
          <p style="font-size: 17px; margin-top: 1rem; color: #444444">
            {{ $t("confirmemail") }}
          </p>
        </v-card-text>
        <v-card-actions class="pb-6 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            color="#EEEEEE"
            class="mr-5 black--text text-capitalize"
            width="150"
            depressed
            max-height="35"
            style="border-radius: 2rem"
            @click="(confirmdialog = false), EnableLoginAll(false)"
            >{{ $t("NotsendEmail") }}</v-btn
          >
          <v-btn
            depressed
            color="#F99D20"
            class="mr-5 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="EnableLoginAll(true)"
            style="border-radius: 2rem"
            >{{ $t("Confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import XLSX from "xlsx";
import CheckViewAddUpload from "@/function/RolePermissions.js";
import CheckViewAdd_enablelogin from "@/function/RolePermissions.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LogTrace from "@/function/Log.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [CheckViewAddUpload, CheckViewAdd_enablelogin, LogTrace, utils],
  data() {
    return {
      itemCount: 0,
      itemCountByResign: 0,
      itemCountByProbation: 0,
      itemCountByPermanent: 0,
      addtrue: false,
      snackbar:false,
      Addemp: null,
      permission: {
        add: true,
        upload: true,
      },
      permission_enableLogin: {
        add: true,
      },
      confirmdialog: false,
      erroruploaddialog: false,
      disableupload: false,
      employeeStatus: null,
      enableLogin: null,
      importData: [],
      tempimport: [],
      importSalary: [],
      importSocialSecurity: [],
      importProvident: [],
      fullName: null,
      position_Name: null,
      email: null,
      employeeType: null,
      enable_Login: null,
      // permissionone: {
      //   view: false,
      // },
      searchByType: "",
      itemInner: "All",
      loadingDialog: false,
      url: enurl.apiUrl,
      page: 1,
      itemsPerPage: 10,
      addempitems: ["Add Employee", "Batch Upload"],
      searchtxt: "",
      PIUploaddialog: false,
      showup: false,
      chosenfile: null,
      PersonalList: [],
      actstatus: false,
      year: ["Active", "Inactive"],
      itemStatus: ["Active", "Inactive"],
      itemInnerStatus: "Active",
      enableStatus: "",
      statusCriteria: [
        "Employee ID",
        "Employee Name",
        "Position",
        "Email",
        "Employee Type",
      ],

      headers: [
        {
          text: "EmployeeID",
          value: "employee_Code",
          align: "left",
          sortable: true,
          width: "180px",
        },
        {
          text: "Employee Name",
          value: "fullName",
          align: "left",
          sortable: true,
          width: "180px",
        },
        {
          text: "Position",
          value: "position_Name",
          align: "left",
          sortable: true,
          width: "130px",
        },
        {
          text: "Email",
          value: "email",
          align: "left",
          sortable: true,
          width: "180px",
        },
        {
          text: "Employee Status",
          value: "employeeType",
          align: "left",
          sortable: true,
          width: "180px",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: true,
          width: "120px",
        },
        {
          text: "EnableLogin",
          value: "enable_Login",
          align: "left",
          sortable: true,
          width: "160px",
        },
      ],
      currentactiveemp: 0,
      packageemp: 0,
      AllPersonalList: [],
      Addresslist: [],
      Searchlist: [],
      Activeemplist: [],
      Reportlist: [],
      ShiftCodeList: [],
      HospitalList: [],
      Bank: [],
      PositionList: [],
      departmentList: [],
      CountryList: [],
      ProvinceList: [],
      DistrictList: [],
      SubDistrictList: [],
      usernamelist: [],
      ComList: [],
      EmpList: [],
      errorMessage: [],
      errormessagefordepinculde: [],
      errormessageforaddrress: [],
      errormessagefortaxandpassport: [],
      errormessageforrequired: [],
      nullerrormessage: [],
      validateerrormessage: [],
      checkduplicate: false,
      enableAlldialog: false,
      ActiveRadio: false,
      disableloading: false,
      duplicateID: "",
      addressexist: "",
      taxorpassexist: "",
      defaultactiveemp: 0,
      tabpagination: 1,
      pagnum: null,
      importEmp: false,
      activeTabIndex: 0
    };
  },
  watch: {
    // searchByType(){
    // let Inner = this.itemInner;
    //   if (!this.itemInner) {
    //     return this.PersonalList;
    //   } else if (Inner == "Employee Name" && this.searchByType) {
    //     return this.PersonalList.filter(
    //       (x) =>
    //         x.employee_Name
    //           .toLowerCase()
    //           .indexOf(this.searchByType.toLowerCase()) !== -1
    //     );
    //   } else {
    //     return this.PersonalList;
    //   }
    // }
    itemInner(){
      this.page = 1;
      this.GetPersonal();
    },
    itemInnerStatus() {
      this.page = 1;
      this.GetPersonal();
    },
    itemsPerPage() {
      this.page = 1;
      this.GetPersonal();
    },
  },

  async mounted() {
    try{
    let self = this;
    self.permission = self.CheckViewAddUpload(7, 8, 9);
    self.permission_enableLogin = self.CheckViewAdd_enablelogin(190, 190);
    self.loadingDialog = true;
    let apiCallsCount = 20;
    
    const handleAPICall = async (apiCall) => {
      await apiCall();
      apiCallsCount--;
      if (apiCallsCount === 0) {
        self.loadingDialog = false; 
      }
    };
    await handleAPICall(self.GetempbyPermanent);
    await handleAPICall(self.GetempbyProbation);
    await handleAPICall(self.Getempbyresign);
    await handleAPICall(self.GetAlladdressinfo);
    await handleAPICall(self.GetReportPersonal);
    await handleAPICall(self.GetShiftCode);
    await handleAPICall(self.GetHospital);
    await handleAPICall(self.GetBank);
    await handleAPICall(self.GetPosition);
    await handleAPICall(self.GetDepartment);
    await handleAPICall(self.GetCountry);
    await handleAPICall(self.GetProvince);
    await handleAPICall(self.GetDistrict);
    await handleAPICall(self.GetSubDistrict);
    await handleAPICall(self.GetCompanyList);
    await handleAPICall(self.GetEmplist);
    await handleAPICall(self.GetUserName);
    //await handleAPICall(self.GetPersonal);
    
    //localStorage.clear();
    localStorage.removeItem("gf");
    localStorage.removeItem("gg");
    localStorage.removeItem("localworkpermitUrl");
    localStorage.removeItem("workpermitname");
    localStorage.removeItem("localworkeditpermitUrl");
    localStorage.removeItem("editworkpermitname");
    localStorage.removeItem("LocalPersonalidUrl");
    localStorage.removeItem("filename");
    localStorage.removeItem("LocaleditPersonalidUrl");
    localStorage.removeItem("localotherinfoUrl");
    localStorage.removeItem("othername");
    localStorage.removeItem("localeditotherinfoUrl");
    localStorage.removeItem("editothername");
    localStorage.removeItem("localPassportUrl");
    localStorage.removeItem("passportname");
    localStorage.removeItem("localeditPassportUrl");
    localStorage.removeItem("editpassportname");
    
    }
    catch(error)
    {
      console.error("Error in Mounted", error);
    }
  },
  computed: {
    itemFilter() {
      this.GetPersonal();
      return this.PersonalList;
    },
    FieldList1() {
      return [
        { val: "All", txt: this.$t("All") },
        { val: "Full Time", txt: this.$t("Full Time") },
        { val: "Daily", txt: this.$t("Daily") },
        { val: "Part-time", txt: this.$t("Part-time") },
        { val: "Contract", txt: this.$t("Contract") },
        { val: "Fixed Pay", txt: this.$t("Fixed Pay") },
      ];
    },
    typeitems(){
      return [
        { val:"All", txt: this.$t("All") },
        { val:"Probation", txt: this.$t("Probation") },
        { val:"Permanent", txt: this.$t("Permanent") },
        { val:"Resign", txt: this.$t("Resign") },
      ]
    },
    Activelist() {
      return [
        { val: "Active", txt: this.$t("Active") },
        { val: "Inactive", txt: this.$t("Inactive") },
      ];
    },
  },
  created() {
    this.changeTab(0);
    this.tabpagination = 1;
  },
  methods: {
    onPageChanged(newPage) {
      this.page = newPage;
      this.GetPersonal();
    },
    onItemsPerPageChanged(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.page = 1;
      this.GetPersonal();
    },
    searchemp() {
      this.GetPersonal();
    },

    searchempEmpty(){
      if(this.searchtxt == '')
      {
        this.GetPersonal();
      }
    },

    changeTab(tabIndex) {
      switch (tabIndex) {
        case 0:
          this.searchtxt = "";
          this.itemInner = "All";
          this.itemInnerStatus = "Active";
          this.tabpagination = 1;
          this.page = 1;
          this.GetPersonal();
          this.activeTabIndex = 0;
          break;
        case 1:
          this.searchtxt = "";
          this.itemInner = "All";
          this.itemInnerStatus = "Active";
          this.tabpagination = 2;
          this.page = 1;
          this.activeTabIndex = 1;
          this.GetPersonal();
          break;
        case 2:
          this.searchtxt = "";
          this.itemInner = "All";
          this.itemInnerStatus = "Active";
          this.tabpagination = 3;
          this.page = 1;
          this.activeTabIndex = 2;
          this.GetPersonal();
          break;
        case 3:
          this.searchtxt = "";
          this.itemInner = "All";
          this.itemInnerStatus = "Inactive";
          this.page = 1;
          this.tabpagination = 1;
          this.activeTabIndex = 3;
          this.GetPersonal();
          break;
        default:
          break;
      }
    },

    async exportexcel() {
      let self = this;
      self.loadingDialog = true;
      let active = this.itemInnerStatus == "Active" ? 1 : 2;
      let temp = {
        Company_ID: store.state.companyID,
        ShowTab: self.tabpagination,
        SearchText: self.searchtxt,
        EmployeeType: self.itemInner,
        EmployeeStatus: active,
      };
      await axios.post(`${self.url}PersonalInfo/GetPersonalInfoToExport`, temp)
        .then(function (response) {
          self.PersonalList = response.data.data;
          self.loadingDialog = false;
        })
        let tempsheetname = "";
        let wb = XLSX.utils.book_new();
        let wsdata = [];
        for (let v of self.PersonalList) {
          wsdata.push({
            [self.$t("EmployeeID")]: v.employee_Code,
            [self.$t("Prefix")]: v.prefix,
            [self.$t("Name")]: v.name,
            [self.$t("gender")]: v.gender,
            [self.$t("religion")]: v.religion,
            [self.$t("date_of_birth")]: v.birthDate,
            [self.$t("martialstatus")]: v.status,
            [self.$t("PersonalID")]: self.personalID_Number,
            [self.$t("taxpayerid")]: v.taxpayerID,
            [self.$t("foreignemp")]: v.foreign_Employee == false ? "No" : "Yes",
            [self.$t("passport")]: v.passport,
            [self.$t("workpermit")]: self.work_Permit,
            [self.$t("employeetype")]: v.employeeType,
            [self.$t("hired_date")]: v.hiredDate,
            [self.$t("employee_status")]: v.employeeStatus,
            [self.$t("Department")]: v.department_Name,
            [self.$t("Division")]: v.division_Name,
            [self.$t("Section")]: v.section_Name,
            [self.$t("Position")]: v.position_Name,
            [self.$t("level")]: v.level,
            [self.$t("terminate_date")]: v.terminateDate,
            [self.$t("terminate_effective_date")]: v.terminateEffectiveDate,
            // ["Supervisor(EmployeeID)"]: v.supervisorID,
            [self.$t("status")]: v.employee_Status == "1" ? "Active" : "Inactive",
            [self.$t("house_number")]: v.houseNumber,
            [self.$t("subdistrict")]: v.sub_District,
            [self.$t("province")]: v.province,
            [self.$t("country")]: v.country,
            [self.$t("postalcode")]: v.postal_Code,
            [self.$t("mobilenumber")]: v.mobile,
            [self.$t("email_address")]: v.email,
            [self.$t("salary")]: v.salary_Amount,
            [self.$t("paymenttype")]: v.payment_Type,
            [self.$t("bankname")]: v.bank_Name,
            [self.$t("AccountNumber")]: v.bank_Acc_Num,
            [self.$t("social_security_account")]: v.sS_Num,
            [self.$t("effectivedate")]: v.effective_Date,
            [self.$t("hospital")]: v.hospital,
            // ["Provident Fund Account Number"]: v.providentfundnumber,
            [self.$t("shiftcode")]: v.shift_Code
          });
        }

        const ws = XLSX.utils.json_to_sheet(wsdata);
        ws["!cols"] = [
          { wch: 10 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 10 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ];
        

        if (localStorage.Templang == "ประเทศไทย") 
        {
          const today = new Date();
          const formattedDate = `${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;
          tempsheetname = ` รายงานพนักงาน_${formattedDate}`;
          self.sheetTitle = ` รายงานพนักงาน_${formattedDate}.xlsx`;
        } 
        else 
        {
          const today = new Date();
          const formattedDate = `${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;
          tempsheetname = `Employee report_${formattedDate}`;
          self.sheetTitle = `Employee report_${formattedDate}.xlsx`;
        }
        XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
        XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
          compression: true,
        });
    },

    checkprobationdate(val){
      const today = new Date().toISOString().split('T')[0];
      const probationdate = val;
      if (today < probationdate)
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    searchtext(){
      this.PersonalList=[];
      let list=[];
      if(this.searchtxt!="" || this.searchtxt!=undefined)
      {
        this.Searchlist.forEach(element => {
          let inId = RegExp(this.searchtxt, "i").test(element.employee_Code);
          let inName = RegExp(this.searchtxt, "i").test(element.fullName);
          let inDep = RegExp(this.searchtxt, "i").test(element.department_Name);
          if(inId || inName || inDep)
          {
            list.push(element);
          }
        });
      }
      else
      {
        list = this.Searchlist;
      }

      let active = this.itemInnerStatus == "Active" ? 1 : 2;
      if(this.itemInner == "All")
      {
        this.PersonalList = list.filter((x) => x.employeeStatus == active);
      }
      else if (this.itemInner == "Employee ID") 
      {
        this.PersonalList = list.filter((x) => x.employeeType == "Full Time" && x.employeeStatus == active);
      } 
      else if (this.itemInner == "Employee Name") 
      {
        this.PersonalList = list.filter((x) => x.employeeType == "Daily" && x.employeeStatus == active);
      }
      else if (this.itemInner == "Position") 
      {
        this.PersonalList = list.filter((x) => x.employeeType == "Part-time" && x.employeeStatus == active);
      }
      else if (this.itemInner == "Email") 
      {
        this.PersonalList = list.filter((x) => x.employeeType == "Contract" && x.employeeStatus == active);
      }
      else if (this.itemInner == "Employee Type")
      {
        this.PersonalList = list.filter((x) => x.employeeType == "Fixed Pay" && x.employeeStatus == active);
      }
      if(this.PersonalList.length)
      {
        this.pageCount = this.PersonalList.length / this.itemsPerPage;
        if (this.pageCount % 1 != 0) 
        {
          this.pageCount = this.pageCount + 1;
        } 
        else 
        {
          this.pageCount;
        }
      }
    },

    addsnakbar(){
      this.addtrue = !this.addtrue;
      this.snackbar = !this.snackbar;
    },

    batchupload(){
      this.PIUploaddialog = true
      this.addtrue = false;
    },

    clickpagi() {
      this.GetPersonal();
    },

    CheckLang() {
      let url = "";
      if (localStorage.Templang == "ประเทศไทย") {
        url = "https://optimisticpathfiles.blob.core.windows.net/uploadthtemplate/Personal Batch Upload.xlsx";
      } 
      else 
      {
        url = "https://optimisticpathfiles.blob.core.windows.net/optimisticfile/Personal Batch Upload.xlsx";
      }
      return url;
    },

    async ChangeEnableLogin(item) {
      let self = this;
      item.enable_Login = !item.enable_Login;
      let tempp = {
        employee_ID: item.employee_ID,
        company_ID: store.state.companyID,
        enable_Login: item.enable_Login,
        user_ID: store.state.userid,
        apply_Enable_All: false,
      };
      await axios.post(`${self.url}PersonalInfo/ChangeStatusEnableLogin`, tempp)
        .then(function () {
          self.GetPersonal();
          self.enableAlldialog = false;
          self.confirmdialog = false;
        });
    },

    async EnableLoginAll(check) {
      let self = this;
      self.loadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
        enable_Login: self.ActiveRadio,
        user_ID: store.state.userid,
        apply_Enable_All: true,
      };
      if (check == true) 
      {
        await axios.post(`${self.url}PersonalInfo/ApplyAllEnableLogin`, tempp)
          .then(function () {
            self.GetPersonal();
            self.SendEmail();
            self.ActiveRadio = false;
          });
      } 
      else 
      {
        await axios.post(`${self.url}PersonalInfo/ApplyAllEnableLogin`, tempp)
          .then(function () {
            self.GetPersonal();
            self.loadingDialog = false;
            self.enableAlldialog = false;
            self.ActiveRadio = false;
            self.confirmdialog = false;
          });
      }
    },
    // loading() {
    //   let self = this;
    //   self.loadingDialog = true;
    //   return self.loadingDialog;
    // },

    Search() {
      let self = this;
      self.loadingDialog = true;
      if (self.searchtxt != "" && self.searchtxt != undefined) 
      {
        let List = self.Searchlist;
        self.PersonalList = [];
        for (let i = 0; i < List.length; i++) 
        {
          let validate = self.filterOnlyCapsText(
            List[i].fullName,
            self.searchtxt,
            List[i]
          );
          if (validate == true) 
          {
            self.PersonalList.push(List[i]);
          }
        }
        let itemInnerStatus = self.itemInnerStatus == "Active" ? 1 : 2;
        self.PersonalList = self.PersonalList.filter((x) => x.employeeStatus == itemInnerStatus);
        self.pageCount = self.PersonalList.length / self.itemsPerPage;
        if (self.pageCount % 1 != 0)
        {
          self.pageCount = self.pageCount + 1;
        }
        else
        {
          self.pageCount;
        }
        self.pageCount = parseInt(self.pageCount);
      }
      else
      {
        alert("Please fill in search field");
        self.GetPersonal();
      }
      setTimeout(() => {
        self.loadingDialog = false;
      }, 2000);
    },

    filterOnlyCapsText(value, search, item) {
      if (this.itemInner == "Employee ID")
      {
        let inName = RegExp(search, "i").test(item.employee_Code);
        // let inTags = false;
        return inName;
      }
      if (this.itemInner == "Employee Name")
      {
        let inName = RegExp(search, "i").test(item.fullName);
        // let inTags = false;
        return inName;
      }
      if (this.itemInner == "Position")
      {
        let inName = RegExp(search, "i").test(item.position_Name);
        // let inTags = false;
        return inName;
      }
      if (this.itemInner == "Email")
      {
        let inName = RegExp(search, "i").test(item.email);
        //  let inTags = false;
        return inName;
      }
      if (this.itemInner == "Employee Type")
      {
        let inName = RegExp(search, "i").test(item.employeeType);
        //  let inTags = false;
        return inName;
      }
    },

    sortFun(rowName) {
      let self = this;
      let keyName = [
        "fullName",
        "department_Name",
        "email",
        "employeeType",
        "employeeStatus",
        "employee_Code",
        "enable_Login",
      ];
      if (rowName == "fullName" || rowName == "email" || rowName == "department_Name" || rowName == "employeeType") 
      {
        self.PersonalList = self.PersonalList.sort((a, b) =>
          self[rowName] ? b[rowName].localeCompare(a[rowName]) : a[rowName].localeCompare(b[rowName])
        );
      }
      else
      {
        self.PersonalList = self.PersonalList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }
      for (let x of keyName) 
      {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },

    itemFilterfordefault() {
      let itemInnerStatus = 1;
      if (!itemInnerStatus) return this.itemFilter;
      return this.itemFilter.filter((x) => x.employeeStatus == itemInnerStatus);
    },

    addnewstaff() {
      this.$router.push("/PersonnelManagement/NewEmployee/NewPersonalInformation").catch(() => {});
    },

    handleClick(e) {
      let self = this;
      let per = sessionStorage.getItem("permissionID").split(",");
      let role = sessionStorage.getItem("roleID");
      if (role == "Admin" || role == "HR") 
      {
        self.$router
          .push(
            `/PersonnelManagement/EditEmployee/${e}/EditPersonalInformation`
          )
          .catch(() => {});
      }
      else 
      {
        if (per.some((e) => e == 7))
        {
          if (per.some((e) => e == 199 || e == 193 || e == 202 || e == 205 || e == 208 || e == 211 || e == 215 || e == 218 || e == 221 || e == 191)) 
          {
            self.$router.push(`/PersonnelManagement/EditEmployee/${e}/EditPersonalInformation`).catch(() => {});
          }
        }
      }
    },

    GetCompanyList() {
      let self = this;
      axios.get(`${self.url}Company/GetCompany`).then(function (response) {
        let conlist = response.data.data;
        self.ComList = conlist.filter((e) => e.company_ID != 0 && e.activeFlag != false);
      });
    },

    GetEmplist() {
      let self = this;
      axios.get(`${self.url}EmployeeInfo/GetallEmployeeInfo`)
        .then(function (response) {
          self.EmpList = response.data.data;
        });
    },

    GetUserName() {
      let self = this;
      axios.get(`${self.url}UserInfo/GetPersonalInfos`)
        .then(function (response) {
          self.usernamelist = response.data.data;
        });
    },

    getupload() {
      document.getElementById("fileInputButton").click();
      this.showup = true;
    },

    closeUpload() {
      this.PIUploaddialog = false;
      this.chosenfile = null;
      this.showup = false;
      this.disableupload = false;
      this.errorMessage = [];
    },

    closeinput() {
      this.showup = false;
      this.chosenfile = null;
    },
    
    Getempbyresign(){
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetPersonalInfowithResign`, tempp)
        .then(function (response) {
          self.AllPersonalList = response.data.data;
          self.itemCountByResign = self.AllPersonalList.length;
        });
    },

    GetempbyProbation(){
      let self = this;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetPersonalInfowithProbation`, temp)
        .then(function (response){
          self.AllPersonalList = response.data.data;
          self.itemCountByProbation = self.AllPersonalList.length;
          self.loadingDialog = false;
        })
    },

    GetempbyPermanent(){
      let self = this;
      self.loadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetPersonalInfowithPermanent`, tempp)
        .then(function (response) {
          self.AllPersonalList = response.data.data;
          self.Searchlist = response.data.data;
          self.itemCountByPermanent = self.AllPersonalList.length;
        });
    },

    GetPersonal() {
      let self = this;
      self.loadingDialog = true;
      let active = self.itemInnerStatus == "Active" ? 1 : 2;
      let temp = {
        Company_ID: store.state.companyID,
        empStatus: active,
        perpage: self.itemsPerPage,
        page: self.page,
        tabNumber: self.tabpagination,
        searchValue: self.searchtxt,
        empType: self.itemInner,
      };
      axios.post(`${self.url}PersonalInfo/GetEmpInfowithlist`, temp)
        .then(function (response) {
          self.PersonalList = response.data.data.data;
          self.pageCount = response.data.data.resultCount / self.itemsPerPage;
          if (self.pageCount % 1 != 0)
          {
            self.pageCount = parseInt(self.pageCount + 1);
          }
          else
          {
            self.pageCount;
          }
          self.Activeemplist = response.data.data.data;
          self.itemCount = response.data.data.currentCount;
          self.packageemp = response.data.data.packageCount;
          self.currentactiveemp =  response.data.data.currentCount;
          self.defaultactiveemp =  response.data.data.currentCount;
          self.loadingDialog = false;
          //self.LogTrace(null, "Get Personal Management", 38, "Critical");
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Personal Management Fail", 38, "Critical");
          self.loadingDialog = false;
        });
    },

    GetAlladdressinfo() {
      let self = this;
      // self.loadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetAddressInformation`, tempp)
        .then(function (response) {
          self.Addresslist = response.data.data;
        });
    },

    GetReportPersonal() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}PersonalInfo/GetPersonalInfowithuploadlist`, tempp)
        .then(function (response) {
          self.Reportlist = response.data.data;
        })
        .catch(function (error) {
          self.LogTrace(error, "Get Personal Management Fail", 38, "Critical");
          self.loadingDialog = false;
        });
    },

    GetShiftCode() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}ShiftCode/GetShiftCodebyCompanyID`, tempp)
        .then(function (response) {
          self.ShiftCodeList = response.data.data;
        });
    },

    GetHospital() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Hospital/GetHospital`, tempp)
        .then(function (response) {
          self.HospitalList = response.data.data;
        });
    },

    GetBank() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      axios.post(`${self.url}Bank/GetALLBanksByCompanyID`, temp)
        .then(function (response) {
          self.Bank = response.data.data;
        });
    },

    GetPosition() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Position/GetPosition`, tempp)
        .then(function (response) {
          self.PositionList = response.data.data;
        });
    },

    GetDepartment() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      axios.post(`${self.url}Department/GetDepartmentsByCompanyID`, temp)
        .then(function (response) {
          self.departmentList = response.data.data;
        });
    },

    GetCountry() {
      let self = this;
      axios.get(`${self.url}Country/GetCountryByCompanyID?Company_ID=${store.state.companyID}`)
        .then(function (response) {
          self.CountryList = response.data.data;
        });
    },

    GetProvince() {
      let self = this;
      axios.get(`${self.url}Province/GetProvinceWithCountryName?Company_ID=${store.state.companyID}`)
        .then(function (response) {
          self.ProvinceList = response.data.data;
        });
    },

    GetDistrict() {
      let self = this;
      axios.get(`${self.url}District/GetDistrictWithNames?Company_ID=${store.state.companyID}`)
        .then(function (response) {
          self.DistrictList = response.data.data;
        });
    },

    async GetSubDistrict() {
      let self = this;
      await axios.get(`${self.url}Sub_District/GetSubDistrictWithNames?Company_ID=${store.state.companyID}`)
        .then(function (response) {
          self.SubDistrictList = response.data.data;
        });
    },

    validateerror() {
      let self = this;
      if (self.errormessagefordepinculde.length != 0)
      {
        self.validateerrormessage.push("\u2022 It appears you have filled in one of these fields i.e, department, division and section fields. For better use, please fill in for all of them");
      }
      else if (self.errormessageforaddrress.length != 0)
      {
        self.validateerrormessage.push("\u2022 It appears you have filled in one of these fields i.e, Country, Province, District, Sub district and Postal Code fields. For better use, please fill in for all of them");
      }
      else if (self.errormessagefortaxandpassport.length != 0)
      {
        alert("Please fill in either one of the field of Passport Number or Personal ID number");
        self.validateerrormessage.push("\u2022 Please fill in either one of the field of Passport Number or Personal ID number");
      }
    },

    taxvalidation(tax) {
      let taxpay = /^\d{13}$/;
      if (taxpay.test(tax))
      {
        return true;
      }
      else
      {
        return "Invalid Tax format";
      }
    },

    objectIsEmpty(obj) {
      return Object.keys(obj).every(function (key) {
        var val = obj[key];
        if (Array.isArray(val) && val.length === 0)
        {
          return true;
        }
        // Other rules go here:
        // ...
        return false;
      });
    },

    checkemailforentirecom(mydata, row, column) {
      let self = this;
      if (mydata != null && mydata != "N/A" && mydata != undefined && mydata != "") 
      {
        for (let i = 0; i < this.usernamelist.length; i++)
        {
          if (this.usernamelist[i].email == mydata)
          {
            self.validateerrormessage.push(
              "\u2022 Duplicated email.Please check the data in column " +
                column +
                "," +
                " row " +
                row
            );
          }
        }
        for (let j = 0; j < self.EmpList.length; j++)
        {
          if (self.EmpList[j].email == mydata)
          {
            self.validateerrormessage.push(
              "\u2022 Duplicated email.Please check the data in column " +
                column +
                "," +
                " row " +
                row
            );
          }
        }
        for (let e = 0; e < self.ComList.length; e++)
        {
          if (self.ComList[e].company_Email == mydata)
          {
            self.validateerrormessage.push(
              "\u2022 Duplicated email.Please check the data in column " +
                column +
                "," +
                " row " +
                row
            );
          }
        }
      }
    },

    async SendEmail() {
      let self = this;
      let tempData = {
        company_ID: store.state.companyID,
      };
      await axios.post(`${self.url}Confirmemployee/SendemailallComfirmEmployee`,tempData)
      .then(function (response) {
        if (response.data.status == 0) 
        {
          alert("Update and Mail Sent Successfully");
          self.loadingDialog = false;
          self.enableAlldialog = false;
          self.confirmdialog = false;
        }
      });
    },

    validateAddress(C, P, D, S, Pos) {
      let self = this;
      let countryID = 0;
      let provinceID = 0;
      let districtID = 0;
      let subdistrictID = 0;
      let postalcode = 0;
      let filteredCountry = self.Addresslist.filter(x =>x.country == C);
      countryID = filteredCountry[0].country_ID;
      let filteredProvince = self.Addresslist.filter(x =>x.province == P);
      provinceID = filteredProvince[0].province_ID;
      let filteredDistrict = self.Addresslist.filter(x =>x.district == D);
      districtID = filteredDistrict[0].district_ID;
      let filteredSub_District = self.Addresslist.filter(x =>x.sub_District == S);
      subdistrictID = filteredSub_District[0].sub_District_ID;
      let filteredPostal_CodeName = self.Addresslist.filter(x =>x.postal_CodeName == Pos);
      postalcode = filteredPostal_CodeName[0].postal_Code;
      return [countryID, provinceID, districtID, subdistrictID, postalcode];
    },
    
    PreviewFiles() {
      let self = this;
      self.PIUploaddialog = true;
      self.disableupload = true;
      self.loadingDialog = true;
      self.checkduplicate = false;
      self.validateerrormessage = [];
      var files = this.chosenfile;
      if (files != null)
      {
        var reader = new FileReader();
        reader.onload = function (e) {
          let filename = files.name;
          let thaitrue = false;
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          let range = XLSX.utils.decode_range(
            workbook.Sheets[sheetName]["!ref"]
          );
          let new_range = XLSX.utils.encode_range(range);
          const file_data = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            blankRows: false,
            defval: "",
            range: new_range,
          });
          // first column first row value

          if (filename == "Personal Batch Upload.xlsx")
          {
            if (localStorage.Templang == "ประเทศไทย")
            {
              thaitrue = true;
            }
            self.loadingDialog = true;
            this.importData = XLSX.utils.sheet_to_json(worksheet);
            let combileArray = [];
            let SalaryArray = [];
            let SocialSecurityArray = [];
            let ProvidentArray = [];
            const formatDate = function (excelDate) {
              var date = new Date((excelDate - (25567 + 2)) * 86400 * 1000);
              return date.toISOString();
            };
            const formatstringdate = function (excelDate) {
              let splitdate = excelDate.split("/");
              if (splitdate[0].length < 2)
              {
                splitdate[0] = "0" + splitdate[0];
              }
              if (splitdate[1].length < 2)
              {
                splitdate[1] = "0" + splitdate[0];
              }
              return splitdate[2] + "-" + splitdate[0] + "-" + splitdate[1];
            };

            const checkpaymenttype = function (mydata, row, column, type) {
              if (type == "Cash")
              {
                return "";
              }
              if (type == "Bank Transfer")
              {
                if (mydata == null || mydata == "N/A" || mydata == undefined || mydata == "")
                {
                  if (thaitrue != true)
                  {
                    self.validateerrormessage.push(
                      "\u2022 Please fill the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  }
                  else
                  {
                    self.validateerrormessage.push(
                      "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                }
                else
                {
                  if (store.state.country != "Thailand" && store.state.country != "Thai" && store.state.country != "ไทย" && store.state.country != "ประเทศไทย")
                  {
                    mydata = trimemp(mydata);
                    if (mydata == "-")
                    {
                      return "";
                    }
                    else
                    {
                      return mydata;
                    }
                  }
                  else
                  {
                    let account = isNaN(mydata);
                    if (account == true)
                    {
                      if (thaitrue != true)
                      {
                        self.validateerrormessage.push(
                          "\u2022 Please fill digit only.Please check the data in column " +
                            column +
                            "," +
                            " row " +
                            row
                        );
                      }
                      else
                      {
                        self.validateerrormessage.push(
                          "\u2022 กรุณากรอกตัวเลขเท่านั้น โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                            column +
                            "," +
                            " แถว " +
                            row
                        );
                      }
                    }
                    else
                    {
                      return mydata;
                    }
                  }
                }
              }
            };
            const checkdata = function (mydata) {
              if (
                mydata == null ||
                mydata == "N/A" ||
                mydata == undefined ||
                mydata == ""
              )
              {
                var result = "";
                return result;
              }
              else
              {
                mydata = trimemp(mydata);
                return mydata;
              }
            };
            const checkdataforhypen = function (mydata) {
              if (
                mydata == null ||
                mydata == "N/A" ||
                mydata == undefined ||
                mydata == "" ||
                mydata == "-"
              ) {
                var result = "";
                return result;
              } else {
                mydata = trimemp(mydata);
                return mydata;
              }
            };
            const checkdatafornumber = function (mydata, row, column) {
              if (mydata == "N/A" || mydata == undefined) {
                //var result = 0;
                //return result;
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
              } else {
                mydata = trimemp(mydata.toString());
                return parseInt(mydata);
              }
            };
            const checkstatusexist = function (mydata, row, column) {
              if (
                mydata != null ||
                mydata != "N/A" ||
                mydata != undefined ||
                mydata != ""
              ) {
                mydata = trimemp(mydata);
              }
              if (mydata == "Active" || mydata == "ใช้งาน") {
                return 1;
              }
              if (mydata == "Inactive" || mydata == "ไม่ใช้งาน") {
                return 2;
              }
              if (
                mydata == null ||
                mydata == "N/A" ||
                mydata == undefined ||
                mydata == ""
              ) {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                // self.validateerrormessage.push(
                //   "\u2022 Please fill the data in column " +
                //     column +
                //     "," +
                //     " row " +
                //     row
                // );
              }
            };
            const trimemp = function (myfiledata) {
              var result;
              if (typeof myfiledata === "string") {
                result = myfiledata.trim();
              } else {
                result = myfiledata;
              }
              return result;
            };

            const checknullexmp = function (mydata, row, column) {
              if (
                mydata == null ||
                mydata == "N/A" ||
                mydata == undefined ||
                mydata == ""
              ) {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
              } else {
                mydata = trimemp(mydata);
                return mydata;
              }
            };
            const checkreqdate = function (mydate, row, column) {
              if (
                mydate == null ||
                mydate == "N/A" ||
                mydate == undefined ||
                mydate == ""
              ) {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                // return result;
              } else {
                let validate = isNaN(mydate);
                var result1 = "";
                if (validate == true) {
                  mydate = trimemp(mydate);
                  result1 = formatstringdate(mydate);
                } else {
                  result1 = formatDate(mydate);
                }

                return result1;
              }
            };

            const checkdate = function (mydate) {
              if (
                mydate == null ||
                mydate == "N/A" ||
                mydate == undefined ||
                mydate == ""
              ) {
                var result = null;
                return result;
              } else {
                let validate = isNaN(mydate);
                var result1 = "";
                if (validate == true) {
                  mydate = trimemp(mydate);
                } else {
                  result1 = formatDate(mydate);
                }

                return result1;
              }
            };

            const checkEmployeeID = function (id, row, column) {
              var newList = [];
              var i;
              if (id != "N/A" && id != undefined && id != "") {
                id = trimemp(id);
                for (i = 0; i < self.AllPersonalList.length; i++) {
                  newList.push(self.AllPersonalList[i].employee_Code);
                }

                if (newList.includes(id)) {
                  self.checkduplicate = true;
                  // self.validateerrormessage.push(
                  //   "\u2022 Employee ID is duplicated.Please fill the right data in column " +
                  //     column +
                  //     "," +
                  //     " row " +
                  //     row
                  // );
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Employee ID is duplicated.Please fill the right data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 รหัสพนักงานซ้ำกัน กรุณากรอกข้อมูลให้ถูกต้องในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                  return "error";
                } else {
                  return id.toString();
                }
              } else {
                self.validateerrormessage.push(
                  "\u2022 Please fill the data in column " +
                    column +
                    "," +
                    " row " +
                    row
                );
              }
            };
            const checkimportaddress = function (
              country,
              province,
              district,
              subdistrict,
              postalcode
            ) {
              if (
                country == "N/A" ||
                province == "N/A" ||
                district == "N/A" ||
                subdistrict == "N/A" ||
                postalcode == "N/A"
              ) {
                if (
                  country != "N/A" ||
                  province != "N/A" ||
                  district != "N/A" ||
                  subdistrict != "N/A" ||
                  postalcode != "N/A"
                ) {
                  self.errormessageforaddrress.push(false);
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            };
            const checktaxandpassport = function (tax, passport) {
              let result;
              if (tax == "N/A" && passport == "N/A") {
                self.errormessagefortaxandpassport.push(false);
              } else {
                if (tax == "N/A" || passport == "N/A") {
                  result = "";
                  return result;
                }
              }
            };
            const checkEmptype = function (mydata, row, column) {
              if (
                mydata == null ||
                mydata == "N/A" ||
                mydata == undefined ||
                mydata == ""
              ) {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
              } else {
                if (
                  mydata == "Full Time" ||
                  mydata == "Part-time" ||
                  mydata == "Daily" ||
                  mydata == "Fixed Pay" ||
                  mydata == "Contract"
                ) {
                  return mydata;
                } else {
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Employee type information is invalid.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ข้อมูลประเภทพนักงานไม่ถูกต้อง โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                }
              }
            };
            const checkReportToID = function (id, row, column) {
              var result;
              var i;
              if (id != null && id != "N/A" && id != undefined && id != "") {
                id = trimemp(id);
                for (i = 0; i < self.Reportlist.length; i++) {
                  if (id == self.Reportlist[i].employee_Code) {
                    result = id;
                    return result;
                  }
                }
                if (result == null) {
                  // self.errorMessage.push(id);
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Report to ID doesn't exist in system.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มี ID สำหรับผู้ได้รับรายงานในระบบ โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                }
              } else {
                // if (thaitrue != true) {
                //   self.validateerrormessage.push(
                //     "\u2022 Please fill the data in column " +
                //       column +
                //       "," +
                //       " row " +
                //       row
                //   );
                // } else {
                //   self.validateerrormessage.push(
                //     "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                //       column +
                //       "," +
                //       " แถว " +
                //       row
                //   );
                // }
                result = 0;
                return result;
              }
            };

            const checkmobile = function (mobil, row, column) {
              let result;
              if (
                mobil != "N/A" &&
                mobil != undefined &&
                mobil != null &&
                mobil != "" &&
                mobil != "-"
              ) {
                mobil = trimemp(mobil);
                let phoneno1 = /^\d{10}$/;
                let phoneno2 = /^\d{11}$/;
                let phoneno3 = /^\d{9}$/;
                if (
                  phoneno1.test(mobil) ||
                  phoneno2.test(mobil) ||
                  phoneno3.test(mobil)
                ) {
                  return mobil;
                } else {
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Invalid Mobile Format.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 รูปแบบมือถือไม่ถูกต้อง โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }

                  return mobil;
                }
              } else {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                result = "";
                return result;
              }
            };
            const checkBank = function (bank, type, row, column) {
              var result;
              var i;
              if (type == "Bank Transfer") {
                if (
                  bank != null &&
                  bank != "N/A" &&
                  bank != undefined &&
                  bank != ""
                ) {
                  bank = trimemp(bank);
                  // console.log("one Bank", bank);
                  // console.log("Banks", self.Bank);
                  for (i = 0; i < self.Bank.length; i++) {
                    if (bank == self.Bank[i].bank_Name || bank == self.Bank[i].bankName_EN) {
                      result = self.Bank[i].bank_ID;
                      return result;
                    }
                  }
                  if (result == null) {
                    self.errorMessage.push(bank);
                    if (thaitrue != true) {
                      self.validateerrormessage.push(
                        "\u2022 Bank doesn't exist in master data.Please check the data in column " +
                          column +
                          "," +
                          " row " +
                          row
                      );
                    } else {
                      self.validateerrormessage.push(
                        "\u2022 ไม่มีธนาคารนี้อยู่ในข้อมูลหลัก โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                          column +
                          "," +
                          " แถว " +
                          row
                      );
                    }
                  }
                } else {
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Please fill the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }

                  result = "";
                  return result;
                }
              } else {
                return "";
              }
            };

            const checkPosition = function (position, row, column) {
              var result;
              var i;
              if (
                position != "N/A" &&
                position != undefined &&
                position != null &&
                position != ""
              ) {
                position = trimemp(position);
                for (i = 0; i < self.PositionList.length; i++) {
                  if (position == self.PositionList[i].position_Name) {
                    result = position;
                    return result;
                  }
                }
                if (result == null || result == undefined) {
                  self.errorMessage.push(position);
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Position doesn't exist in master data.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีตำแหน่งนี้อยู่ในข้อมูลหลัก โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }

                  return "";
                }
              } else {
                // self.validateerrormessage.push(
                //   "\u2022 Please fill the data in column " +
                //     column +
                //     "," +
                //     " row " +
                //     row
                // );
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                return result;
              }
            };

            const checkDepartment = function (depart, row, column) {
              var result;
              var i;
              if (
                depart != "N/A" &&
                depart != undefined &&
                depart != null &&
                depart != ""
              ) {
                depart = trimemp(depart);
                for (i = 0; i < self.departmentList.length; i++) {
                  if (depart == self.departmentList[i].department_Name) {
                    result = depart;
                    return result;
                  }
                }
                if (result == undefined || result == null) {
                  // self.validateerrormessage.push(
                  //   "\u2022 Department doesn't exist in master data.Please check the data in column " +
                  //     column +
                  //     "," +
                  //     " row " +
                  //     row
                  // );
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Department doesn't exist in master data.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีแผนกนี้อยู่ในข้อมูลหลัก โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }

                  self.errorMessage.push(depart);
                }
              } else {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }

                result = "";
                return result;
              }
            };

            const checkDivision = function (temp, row, column) {
              var result;
              var i;
              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != ""
              ) {
                temp = trimemp(temp);
                for (i = 0; i < self.departmentList.length; i++) {
                  if (temp == self.departmentList[i].division_Name) {
                    result = temp;
                    return result;
                  }
                }
                if (result == null || result == undefined) {
                  // self.validateerrormessage.push(
                  //   "\u2022 Division doesn't exist in master data.Please check the data in column " +
                  //     column +
                  //     "," +
                  //     " row " +
                  //     row
                  // );
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Division doesn't exist in master data.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีฝ่ายนี้อยู่ในข้อมูลหลัก โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                  self.errorMessage.push(temp);
                  return "";
                }
              } else {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                result = "";
                return result;
              }
            };
            const checkSection = function (temp, row, column) {
              var result;
              var i;
              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != ""
              ) {
                temp = trimemp(temp);
                for (i = 0; i < self.departmentList.length; i++) {
                  if (temp == self.departmentList[i].section_Name) {
                    result = temp;
                    return result;
                  }
                }
                if (result == null || result == undefined) {
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Section doesn't exist in master data.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีหน่วยนี้อยู่ในข้อมูลหลัก โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                  // self.validateerrormessage.push(
                  //   "\u2022 Section doesn't exist in master data.Please check the data in column " +
                  //     column +
                  //     "," +
                  //     " row " +
                  //     row
                  // );
                  self.errorMessage.push(temp);
                  return "";
                }
              } else {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                result = "";
                return result;
              }
            };
            const checkCountry = function (temp, row, column) {
              var result;
              var i;
              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != ""
              ) {
                temp = trimemp(temp);
                for (i = 0; i < self.SubDistrictList.length; i++) {
                  if (temp == self.SubDistrictList[i].country_Name) {
                    result = temp;
                    return result;
                  }
                }
                if (result == null) {
                  self.errorMessage.push(temp);
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Country doesn't exist in the system.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีประเทศนี้อยู่ในระบบ โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                }
              } else {
                result = "";
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                return result;
              }
            };
            const checkDistrict = function (temp, row, column) {
              var result;
              var i;
              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != ""
              ) {
                temp = trimemp(temp);
                for (i = 0; i < self.SubDistrictList.length; i++) {
                  if (temp == self.SubDistrictList[i].district_Name) {
                    result = temp;
                    return result;
                  }
                }
                if (result == null) {
                  self.errorMessage.push(temp);
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 District doesn't exist in the system.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีเขต/อำเภอนี้อยู่ในระบบ โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                  // self.validateerrormessage.push(
                  //   "\u2022 District doesn't exist in the system.Please check the data in column " +
                  //     column +
                  //     "," +
                  //     " row " +
                  //     row
                  // );
                }
              } else {
                result = "";
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                // self.validateerrormessage.push(
                //   "\u2022 Please fill the data in column " +
                //     column +
                //     "," +
                //     " row " +
                //     row
                // );
                return result;
              }
            };
            const checkProvince = function (temp, row, column) {
              var result;
              var i;

              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != ""
              ) {
                temp = trimemp(temp);
                for (i = 0; i < self.SubDistrictList.length; i++) {
                  if (temp == self.SubDistrictList[i].province_Name) {
                    result = temp;
                    return result;
                  }
                }
                if (result == null) {
                  self.errorMessage.push(temp);
                  // self.validateerrormessage.push(
                  //   "\u2022 Province doesn't exist in the system.Please check the data in column " +
                  //     column +
                  //     "," +
                  //     " row " +
                  //     row
                  // );
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Province doesn't exist in the system.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีจังหวัดนี้อยู่ในระบบ โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                }
              } else {
                result = "";
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                // self.validateerrormessage.push(
                //   "\u2022 Please fill the data in column " +
                //     column +
                //     "," +
                //     " row " +
                //     row
                // );
                return result;
              }
            };
            const checkSubDistrict = function (temp, row, column) {
              var result;
              var i;

              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != ""
              ) {
                temp = trimemp(temp);
                for (i = 0; i < self.SubDistrictList.length; i++) {
                  if (temp == self.SubDistrictList[i].sub_District_Name) {
                    result = temp;
                    return result;
                  }
                }
                if (result == null) {
                  self.errorMessage.push(temp);
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Subdistrict doesn't exist in the system.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีแขวง/ตำบลนี้อยู่ในระบบ โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                }
              } else {
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                result = "";
                return result;
              }
            };
            const checkPostalCode = function (temp, row, column) {
              var result;
              var i;

              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != ""
              ) {
                temp = parseInt(trimemp(temp));
                for (i = 0; i < self.SubDistrictList.length; i++) {
                  if (temp == self.SubDistrictList[i].postal_Code) {
                    result = self.SubDistrictList[i].postal_Code;
                    return result;
                  }
                }
                if (result == null) {
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Postal code doesn't exist in the system.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีรหัสไปรษณีย์นี้อยู่ในระบบ โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }

                  self.errorMessage.push(temp);
                }
              } else {
                result = 0;
                if (thaitrue != true) {
                  self.validateerrormessage.push(
                    "\u2022 Please fill the data in column " +
                      column +
                      "," +
                      " row " +
                      row
                  );
                } else {
                  self.validateerrormessage.push(
                    "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                      column +
                      "," +
                      " แถว " +
                      row
                  );
                }
                return result;
              }
            };
            const checktaxpayer = function (temp, row, column) {
              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != "" &&
                temp != "-"
              ) {
                if (
                  store.state.country == "Thailand" ||
                  store.state.country == "Thai" ||
                  store.state.country == "ไทย" ||
                  store.state.country == "ประเทศไทย"
                ) {
                  let tax = isNaN(temp);

                  if (tax == true) {
                    if (thaitrue != true) {
                      self.validateerrormessage.push(
                        "\u2022 Please fill digit only.Please check the data in column " +
                          column +
                          "," +
                          " row " +
                          row
                      );
                    } else {
                      self.validateerrormessage.push(
                        "\u2022 กรุณากรอกตัวเลขเท่านั้น โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                          column +
                          "," +
                          " แถว " +
                          row
                      );
                    }
                    // self.validateerrormessage.push(
                    //   "\u2022 Please fill digit only.Please check the data in column " +
                    //     column +
                    //     "," +
                    //     " row " +
                    //     row
                    // );
                  }
                  if (tax == false) {
                    //let changeint=parseInt(temp);
                    temp = trimemp(temp);
                    let taxvali = self.taxvalidation(temp);
                    if (taxvali != true) {
                      if (thaitrue != true) {
                        self.validateerrormessage.push(
                          "\u2022 Please fill 13 digits.Please check the data in column " +
                            column +
                            "," +
                            " row " +
                            row
                        );
                      } else {
                        self.validateerrormessage.push(
                          "\u2022 กรุณากรอกตัวเลข 13 หลักโปรดตรวจสอบข้อมูลในคอลัมน์ " +
                            column +
                            "," +
                            " แถว " +
                            row
                        );
                      }
                    } else {
                      return temp;
                    }
                  }
                } else {
                  return temp;
                }
              } else {
                return "";
              }
            };
            const checkpersonal = function (temp, row, column) {
              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != "" &&
                temp != "-"
              ) {
                temp = trimemp(temp);
                if (
                  store.state.country == "Thailand" ||
                  store.state.country == "Thai" ||
                  store.state.country == "ไทย" ||
                  store.state.country == "ประเทศไทย"
                ) {
                  let tax = isNaN(temp);
                  if (tax == true) {
                    if (thaitrue != true) {
                      self.validateerrormessage.push(
                        "\u2022 Please fill digit only.Please check the data in column " +
                          column +
                          "," +
                          " row " +
                          row
                      );
                    } else {
                      self.validateerrormessage.push(
                        "\u2022 กรุณากรอกตัวเลขเท่านั้น โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                          column +
                          "," +
                          " แถว " +
                          row
                      );
                    }
                    // self.validateerrormessage.push(
                    //   "\u2022 Please fill digit only.Please check the data in column " +
                    //     column +
                    //     "," +
                    //     " row " +
                    //     row
                    // );
                  }
                  if (tax == false) {
                    //  let changeint=parseInt(temp);
                    let taxvali = self.taxvalidation(temp);
                    if (taxvali != true) {
                      if (thaitrue != true) {
                        self.validateerrormessage.push(
                          "\u2022 Please fill 13 digits.Please check the data in column " +
                            column +
                            "," +
                            " row " +
                            row
                        );
                      } else {
                        self.validateerrormessage.push(
                          "\u2022 กรุณากรอกตัวเลข 13 หลักโปรดตรวจสอบข้อมูลในคอลัมน์ " +
                            column +
                            "," +
                            " แถว " +
                            row
                        );
                      }
                    } else {
                      return temp;
                    }
                  }
                } else {
                  return temp;
                }
              } else {
                return "";
              }
            };
            const Hospitals = function (hospitalName, row, column) {
              var result;

              var i;
              if (
                hospitalName != "N/A" &&
                hospitalName != undefined &&
                hospitalName != null &&
                hospitalName != ""
              ) {
                hospitalName = trimemp(hospitalName);
                for (i = 0; i < self.HospitalList.length; i++) {
                  if (self.HospitalList[i].hospital_Name == hospitalName) {
                    result = self.HospitalList[i].hospital_ID;
                    return result;
                  }
                }
                if (result == null) {
                  self.errorMessage.push(hospitalName);
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Hospital doesn't exist in the system.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีโรงพยาบาลนี้อยู่ในระบบ โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                  // self.validateerrormessage.push(
                  //   "\u2022 Hospital doesn't exist in the system.Please check the data in column " +
                  //     column +
                  //     "," +
                  //     " row " +
                  //     row
                  // );
                }
              } else {
                result = 0;

                return result;
              }
            };
            const checkShiftCode = function (temp, row, column) {
              var result;
              var i;
              if (
                temp != "N/A" &&
                temp != undefined &&
                temp != null &&
                temp != ""
              ) {
                //temp = parseInt(trimemp(temp));
                for (i = 0; i < self.ShiftCodeList.length; i++) {
                  if (temp == self.ShiftCodeList[i].shift_Code) {
                    result = self.ShiftCodeList[i].shift_ID;
                    return result;
                  }
                }
                if (result == null) {
                  if (thaitrue != true) {
                    self.validateerrormessage.push(
                      "\u2022 Shift code doesn't exist in the system.Please check the data in column " +
                        column +
                        "," +
                        " row " +
                        row
                    );
                  } else {
                    self.validateerrormessage.push(
                      "\u2022 ไม่มีรหัสกะการทำงานนี้อยู่ในระบบ โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                        column +
                        "," +
                        " แถว " +
                        row
                    );
                  }
                  // self.validateerrormessage.push(
                  //   "\u2022 Shift code doesn't exist in the system.Please check the data in column " +
                  //     column +
                  //     "," +
                  //     " row " +
                  //     row
                  // );
                  self.errorMessage.push(temp);
                }
              } else {
                result = 0;
                // if (thaitrue != true) {
                //   self.validateerrormessage.push(
                //     "\u2022 Please fill the data in column " +
                //       column +
                //       "," +
                //       " row " +
                //       row
                //   );
                // } else {
                //   self.validateerrormessage.push(
                //     "\u2022 กรุณากรอกข้อมูลในคอลัมน์ " +
                //       column +
                //       "," +
                //       " แถว " +
                //       row
                //   );
                // }
                // self.validateerrormessage.push(
                //   "\u2022 Please fill the data in column " +
                //     column +
                //     "," +
                //     " row " +
                //     row
                // );
                return result;
              }
            };
            let duplicatecheck = [];
            let duplicateemailcheck = [];
            const checkID = function (ID, row, column) {
              if (duplicatecheck.length > 0) {
                for (let i = 0; i < duplicatecheck.length; i++) {
                  if (ID == duplicatecheck[i]) {
                    if (thaitrue != true) {
                      self.validateerrormessage.push(
                        "\u2022 There is duplicated Employee ID in excel.Please check the data in column " +
                          column +
                          "," +
                          " row " +
                          row
                      );
                    } else {
                      self.validateerrormessage.push(
                        "\u2022 มีรหัสพนักงานที่ซ้ำกันใน excel โปรดตรวจสอบข้อมูลในคอลัมน์ " +
                          column +
                          "," +
                          " แถว " +
                          row
                      );
                    }

                    return false;
                  } else {
                    return true;
                  }
                }
              }
            };

            const translategender = function (data) {
              if (data == "ชาย") {
                return "Male";
              } else if (data == "หญิง") {
                return "Female";
              } else {
                return data;
              }
            };
            const translatereligion = function (data) {
              if (data == "ศาสนาพุทธ") {
                return "Buddhism";
              } else if (data == "ศาสนาอิสลาม") {
                return "Islam";
              } else if (data == "ศาสนาคริสต์") {
                return "Christianity";
              } else if (data == "ศาสนาฮินดู") {
                return "Hinduism";
              } else if (data == "ศาสนาซิกข์") {
                return "Sikhism";
              } else if (data == "ศาสนายูดาห์") {
                return "Judaism";
              } else {
                return data;
              }
            };
            const translatemartial = function (data) {
              if (data == "โสด") {
                return "Single";
              } else if (data == "สมรส") {
                return "Married";
              } else if (data == "หย่า") {
                return "Divorced";
              } else if (data == "เป็นหม้าย") {
                return "Widowed";
              } else {
                return data;
              }
            };
            const translateforeign = function (data) {
              if (data == "เป็น") {
                return "Yes";
              } else if (data == "ไม่เป็น") {
                return "No";
              } else {
                return data;
              }
            };
            const translatepaymenttype = function (data) {
              if (data == "โอนเงินผ่านธนาคาร") {
                return "Bank Transfer";
              } else if (data == "เงินสด") {
                return "Cash";
              } else {
                return data;
              }
            };
            const translateemploymentType = function (data) {
              if (data == "รายเดือน") {
                return 1;
              } else if (data == "รายวัน") {
                return 2;
              } else if (data == "Monthly Payment") {
                return 1;
              } else if (data == "Daily Payment") {
                return 2;
              } else {
                return data;
              }
            };
            const translateemptype = function (data) {
              if (data == "Full Time") {
                return "Full Time";
              } else if (data == "Part-time") {
                return "Part-time";
              } else if (data == "Daily") {
                return "Daily";
              } else if (data == "Contract") {
                return "Contract";
              } else if (data == "Fixed Pay") {
                return "Fixed Pay";
              } else if (data == "ประจำ") {
                return "Full Time";
              } else if (data == "พาร์ททาม") {
                return "Part-time";
              } else if (data == "รายวัน") {
                return "Daily";
              } else if (data == "สัญญาจ้าง") {
                return "Contract";
              } else if (data == "เหมาจ่าย") {
                return "Fixed Pay";
              } else {
                return data;
              }
            };

            for (let j = 0; j < file_data.length; j++) {
              if (j != file_data.length - 1) {
                if (j > 0) {
                  duplicatecheck.push(file_data[j][0]);
                  duplicateemailcheck.push(file_data[j][30]);
                }

                let isValid = file_data[j + 1].every((item) => item === "");
                // alert("Alert" + isValid);
                if (isValid == false) {
                  self.currentactiveemp = parseInt(self.currentactiveemp) + 1;
                  let hasDuplicateID = checkID(
                    file_data[j + 1][0].toString(),
                    j + 1,
                    "A"
                  );
                  const [
                    countryID,
                    provinceID,
                    districtID,
                    subdistrictID,
                    postalcode,
                  ] = self.validateAddress(
                    file_data[j + 1][29],
                    file_data[j + 1][28],
                    file_data[j + 1][27],
                    file_data[j + 1][26],
                    file_data[j + 1][30]
                  );
                  // alert(countryID);
                  let checkaddressexist = checkimportaddress(
                    file_data[j + 1][29],
                    file_data[j + 1][28],
                    file_data[j + 1][27],
                    file_data[j + 1][26],
                    file_data[j + 1][30]
                  );
                  let checktaxorpassexist = checktaxandpassport(
                    file_data[j + 1][7],
                    file_data[j + 1][10]
                  );
                  // self.checkemailforentirecom(
                  //   file_data[j + 1][30],
                  //   j + 1,
                  //   "AG"
                  // );
                  self.duplicateID = hasDuplicateID;
                  self.addressexist = checkaddressexist;
                  self.taxorpassexist = checktaxorpassexist;
                  file_data[j + 1][4] = translategender(file_data[j + 1][4]);
                  file_data[j + 1][5] = translatereligion(file_data[j + 1][5]);
                  file_data[j + 1][7] = translatemartial(file_data[j + 1][7]);
                  file_data[j + 1][10] = translateforeign(file_data[j + 1][10]);
                  file_data[j + 1][13] = translateemptype(file_data[j + 1][13]);
                  file_data[j + 1][35] = translatepaymenttype(
                    file_data[j + 1][35]
                  );
                  file_data[j + 1][33] = translateemploymentType(
                    file_data[j + 1][33]
                  );
                  self.tempimport = {
                    company_ID: parseInt(store.state.companyID),
                    employee_Code: checkEmployeeID(
                      file_data[j + 1][0].toString(),
                      j + 1,
                      "A"
                    ),
                    prefix: checknullexmp(file_data[j + 1][1], j + 1, "B"),
                    name: checknullexmp(file_data[j + 1][2], j + 1, "C"),

                    lastName: checknullexmp(file_data[j + 1][3], j + 1, "D"),
                    gender: checknullexmp(file_data[j + 1][4], j + 1, "E"),
                    religion: checknullexmp(file_data[j + 1][5], j + 1, "F"),
                    birthDate: checkreqdate(file_data[j + 1][6], j + 1, "G"),
                    status: checknullexmp(file_data[j + 1][7], j + 1, "H"),
                    personalID_Number: checkpersonal(
                      file_data[j + 1][8].toString(),
                      j + 1,
                      "I"
                    ),
                    taxpayerID: checktaxpayer(
                      file_data[j + 1][9].toString(),
                      j + 1,
                      "J"
                    ),
                    foreign_Employee: trimemp(file_data[j + 1][10]),
                    passport: trimemp(file_data[j + 1][11]),
                    work_Permit: checkdata(file_data[j + 1][12]),
                    employeeType: checkEmptype(
                      file_data[j + 1][13],
                      j + 1,
                      "N"
                    ),
                    hiredDate: checkreqdate(file_data[j + 1][14], j + 1, "O"),
                    departmentname: checkDepartment(
                      file_data[j + 1][16],
                      j + 1,
                      "Q"
                    ),
                    divisionname: checkDivision(
                      file_data[j + 1][17].toString(),
                      j + 1,
                      "R"
                    ),
                    sectionname: checkSection(
                      file_data[j + 1][18].toString(),
                      j + 1,
                      "S"
                    ),
                    positionname: checkPosition(
                      file_data[j + 1][19],
                      j + 1,
                      "T"
                    ),
                    level: checkdatafornumber(file_data[j + 1][20], j + 1, "U"),
                    terminateDate: checkdate(file_data[j + 1][21]),
                    terminateEffectiveDate: checkdate(file_data[j + 1][22]),
                    workday_Calendar: 0,
                    report_ID: checkReportToID(
                      file_data[j + 1][23],
                      j + 1,
                      "X"
                    ),
                    employeeStatus: checkstatusexist(
                      file_data[j + 1][24],
                      j + 1,
                      "Y"
                    ),
                    houseNumber: checknullexmp(
                      file_data[j + 1][25].toString(),
                      j + 1,
                      "Z"
                    ),
                    sub_District: checkSubDistrict(
                      file_data[j + 1][26],
                      j + 1,
                      "AA"
                    ),
                    district: checkDistrict(file_data[j + 1][27], j + 1, "AB"),
                    province: checkProvince(file_data[j + 1][28], j + 1, "AC"),
                    country: checkCountry(file_data[j + 1][29], j + 1, "AD"),
                    postal_Code: checkPostalCode(
                      file_data[j + 1][30],
                      j + 1,
                      "AE"
                    ),
                    sub_DistrictID: subdistrictID,
                    districtID: districtID,
                    provinceID: provinceID,
                    countryID: countryID,
                    postal_CodeID: postalcode,
                    mobile: checkmobile(
                      file_data[j + 1][31].toString(),
                      j + 1,
                      "AF"
                    ),
                    email: checknullexmp(file_data[j + 1][32], j + 1, "AG"),
                    shiftcode: checkShiftCode(
                      file_data[j + 1][42],
                      j + 1,
                      "AQ"
                    ),
                  };
                  self.importSalary = {
                    salary_Amount: checknullexmp(
                      file_data[j + 1][34].toString(),
                      j + 1,
                      "AI"
                    ),
                    payment_Type: checknullexmp(
                      file_data[j + 1][35],
                      j + 1,
                      "AJ"
                    ),
                    
                    bank_ID: checkBank(
                      file_data[j + 1][36],
                      file_data[j + 1][35],
                      j + 1,
                      "AK"
                    ),
                    toatalFixedIncome: checkdata(
                      file_data[j + 1][34].toString()
                    ),
                    bank_Acc_Num: checkpaymenttype(
                      file_data[j + 1][37].toString(),
                      j + 1,
                      "AL",
                      file_data[j + 1][35]
                    ),
                    employmentTypeID: checknullexmp(
                      file_data[j + 1][33],
                      j + 1,
                      "AH"
                    ),
                    employee_ID: self.tempimport.employee_Code,
                    active: true,
                    updateEmployee_ID: store.state.employeeID,
                    company_ID: parseInt(store.state.companyID),
                  };
                  self.importSocialSecurity = {
                    sS_Num: checkdataforhypen(file_data[j + 1][39].toString()),
                    effective_Date: checkdate(file_data[j + 1][40]),
                    hospital_ID: Hospitals(file_data[j + 1][41], j + 1, "AO"),
                    employee_ID: self.tempimport.employee_Code,
                    company_ID: parseInt(store.state.companyID),
                  };

                  self.importProvident = {
                    fund_Acc_Num: checkdataforhypen(
                    ),
                    employee_ID: self.tempimport.employee_Code,
                    company_ID: parseInt(store.state.companyID),
                  };
                  combileArray.push(self.tempimport);
                  SalaryArray.push(self.importSalary);
                  SocialSecurityArray.push(self.importSocialSecurity);
                  ProvidentArray.push(self.importProvident);
                }
              }
            }
            self.validateerror();
            if (self.currentactiveemp < self.packageemp) 
            {
              if (self.validateerrormessage.length == 0) 
              {
                if (self.errorMessage.length == 0) 
                {
                  axios.post(`${self.url}PersonalInfo/ImportEmplist`, combileArray)
                  .then(function (response) {
                    if (response.data.status == 0)
                    {
                      let two = `${self.url}Salary/ImportSalarylist`;
                      let three = `${self.url}SocialSecurity/ImportSocialSecurity`;
                      let four = `${self.url}Emp_Provident/ImportEmpProvidentlist`;
                      const requestTwo = axios.post(two, SalaryArray);
                      const requestThree = axios.post(three,SocialSecurityArray);
                      const requestFour = axios.post(four, ProvidentArray);
                      axios.all([requestTwo, requestThree, requestFour]).then(
                        axios.spread((...responses) => {
                          const responseTwo = responses[0];
                          const responseThree = responses[1];
                          const responseFour = responses[2];
                          if (responseTwo.data.status == 0 && responseThree.data.status == 0 && responseFour.data.status == 0) 
                          {
                            self.importEmp = true;
                            self.GetPersonal();
                            alert(response.data.message);
                          }
                        })
                      );
                      self.loadingDialog = false;
                      self.disableupload = false;
                      self.GetPersonal();
                      self.closeUpload();
                    }
                  })
                  .catch((errors) => {
                    self.LogTrace(null,"Batch Upload Personal Information Fail",14,"High");
                    console.error(errors);
                  });
                }
              }
              else
              {
                self.erroruploaddialog = true;
                self.disableupload = false;
                self.loadingDialog = false;
                self.errormessagefordepinculde = [];
                self.errormessagefortaxandpassport = [];
                self.errormessageforaddrress = [];
                self.errormessageforrequired = [];
                self.nullerrormessage = [];
                self.currentactiveemp = self.defaultactiveemp;
              }
            }
            else
            {
              if (thaitrue != true)
              {
                alert("Your active employees have reached the limit. To increase the number of employees, please contact the Optimistic administrator.");
              }
              else
              {
                alert("พนักงานที่ใช้งานของคุณมีจำนวนถึงขีดจำกัดแล้ว หากต้องการเพิ่มจำนวนพนักงานกรุณาติดต่อผู้ดูแลระบบ Optimistic");
              }
              self.errormessagefordepinculde = [];
              self.errormessagefortaxandpassport = [];
              self.errormessageforaddrress = [];
              self.errormessageforrequired = [];
              self.nullerrormessage = [];
              self.disableupload = false;
              self.loadingDialog = false;
              self.currentactiveemp = self.defaultactiveemp;
            }
          }
          else
          {
            alert("File name must be Personal Batch Upload.xlsx");
            self.loadingDialog = false;
            this.closeUpload();
          }
        };
        reader.readAsArrayBuffer(files);
        self.loadingDialog = false;
        //this.closeUpload();
      }
      else
      {
        alert("Please choose File");
        self.loadingDialog = false;
        self.disableupload = false;
      }
    },
  },
};
</script>
<style scoped>
.emptabs{
  width: 150px;
}

.xlsimg{
  margin-right: 30px;
  margin-top: 20px;
  max-width: 30px!important;
  height: 30px;
}

::v-deep .v-tabs .v-tab--active {
  color: #F89D1F!important;
  background-color: #ffffff !important;
}

.uploadsheet{
  padding: 15px;
  border-radius: 7px;
  margin-top: 20px;
}

.excelbg {
  margin-left: 20px;
  width: 45px;
  height: 45px;
}

.downdloadbtn{
  margin-top: 5px;
  background: #fff!important;
  border-radius: 5px;
  border: 1px solid #C1BDBD!important;
  padding: 0px 20px!important;
}

.choosecard{
  border: 2px dashed #D9D5D5;
  background-color: #EDEDED;
  margin-top: 35px;
  height: 250px;
}

.uploadimg {
    height: 90px!important;
    max-width: 90px!important;
    margin-top: 20px;
}

.datatext {
    color: #7D7A7A;
    font-weight: 300;
    font-family: 'Kanit';
    margin-top: 13px;
    margin-bottom: 0px!important;
}

.exportimg {
  height: 30px;
  max-width: 30px!important;
}

tbody tr {
  height: 75px;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}

.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

.uploaderror {
  box-shadow: none;
}

.mycardthree {
  overflow-y: auto;
  height: 250px;
  overflow-x: hidden;
}

>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}

>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 17px;
  font-weight: normal;
  font-family: "Kanit";
  color: #f99d20;
}

>>> .v-data-table tr td {
  height: 70px;
}
/* >>> .theme--light.v-data-table thead tr th {
  
} */
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.875rem;
  height: 60px;
}

>>> .mdi-paperclip::before {
  content: none;
}

>>> .v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 0px;
}

/* Switch Enable login */
@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(
        circle 12px,
        white 100%,
        transparent calc(100% + 1px)
      )
      #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: white;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
  }
}
/* Switch Enable login */
.fix-btn {
  width: 100px !important;
}
.sc {
  padding: 8px;
  margin: 8px;
  font-size: 14px;
}
::-webkit-scrollbar-thumb {
  background: none;
  border-radius: 10px;
}
/* ::-webkit-scrollbar {
  width: 6px;
  height: 0px;
} */
</style>