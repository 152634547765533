<template>
  <div>
    <div style="margin-left: 1%">
      <p
        style="
          font-size: 25px;
          color: #43425d;
          padding: 18px;
          margin-top: 0.2rem;
        "
      >
        {{ $t("Reports") }}
      </p>
    </div>
    <v-row style="margin-right: 0; margin-left: -2px">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pt-1 mb-5 card" height="100%">
          <div class="di">
            <v-card-text
              class="titles"
              v-if="addition == true || termination == true"
              >{{ $t("Employeeadditionandtermination") }}</v-card-text
            >
            <v-divider v-if="addition == true || termination == true" />
            <v-list>
              <v-list-item v-show="addition" router to="/reports/addition">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.addition"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Addition") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="addition" />
            <v-list v-show="termination">
              <v-list-item router to="/reports/termination">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.termination"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("Terminations")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="termination" />
          </div>
          <div class="di">
            <v-card-text class="titles" v-if="agegender == true">{{
              $t("Employeedemographics")
            }}</v-card-text>
            <v-divider v-if="agegender == true" />
            <v-list v-show="agegender">
              <v-list-item router to="/reports/agegender">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.agegender"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("employeesbyageandgender")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="agegender" />
          </div>
          <div class="di">
            <v-card-text
              class="titles"
              v-if="salaryhistory == true || lastpaychange == true"
              >{{ $t("Payrolls") }}</v-card-text
            >
            <v-divider v-if="salaryhistory == true || lastpaychange == true" />
            <v-list v-show="salaryhistory">
              <v-list-item router to="/reports/salaryhistory">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.salaryhis"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("salaryhistory")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="salaryhistory" />
            <v-list v-show="lastpaychange">
              <v-list-item router to="/reports/lastpaychange">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.lastpay"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("lastpaychange")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="lastpaychange" />
          </div>
          <div class="di">
            <v-card-text v-if="timeoffused == true" class="titles">{{
              $t("timeoff")
            }}</v-card-text>
            <v-divider v-show="timeoffused" />
            <v-list v-show="timeoffused">
              <v-list-item router to="/reports/timeoffused">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.Timeoffused"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>{{ $t("timeoffused") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-show="timeoffused" />
          </div>
          <div class="di">
            <v-card-text
              v-if="ssf == true || withhold == true"
              class="titles"
              >{{ $t("otherdoc") }}</v-card-text
            >
            <v-divider v-if="ssf == true" class="titles" />
            <v-list v-show="ssf">
              <v-list-item router to="/reports/socialreport">
                <img
                  width="20px;"
                  height="20px;"
                  :src="item.socialreport"
                  class="mr-5"
                />
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("socialsecreport") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list>
              <v-list-item router to="/reports/taxcertificate">
                <img
                  width="25px;"
                  height="25px;"
                  :src="item.taxwithhold"
                  class="mr-4"
                />
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("withholdtax") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      off: "Time Off",
      age: "Employees by Age and Gender",
      salaryhis: "Salary History​",
      paychange: "Last Pay Change",
      timeoff: "Time Off Used",
      item: {
        addition: require("@/assets/images/Retention_1_@2x.png"),
        termination: require("@/assets/images/user@2x.png"),
        agegender: require("@/assets/images/group@2x.png"),
        lastpay: require("@/assets/images/Icon metro-money@2x.png"),
        salaryhis: require("@/assets/images/salary@2x.png"),
        Timeoffused: require("@/assets/images/clock@2x.png"),
        socialreport: require("@/assets/images/Icon metro-security.png"),
        taxwithhold: require("@/assets/images/Rectangle 2352.png"),
      },
      addition: false,
      termination: false,
      agegender: false,
      salaryhistory: false,
      lastpaychange: false,
      timeoffused: false,
      ssf: false,
      withhold: false,
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      this.addition =
        this.termination =
        this.agegender =
        this.salaryhistory =
        this.lastpaychange =
        this.timeoffused =
        this.ssf =
        this.withhold =
          true;
    }
    // if (perid.some((e) => e == 49)) {
    //   this.items[0].showthis = true;
    // }
    if (perid.some((e) => e == 172)) {
      this.addition = true;
    }
    if (perid.some((e) => e == 174)) {
      this.termination = true;
    }
    if (perid.some((e) => e == 176)) {
      this.agegender = true;
    }
    if (perid.some((e) => e == 178)) {
      this.salaryhistory = true;
    }
    if (perid.some((e) => e == 180)) {
      this.lastpaychange = true;
    }
    if (perid.some((e) => e == 182)) {
      this.timeoffused = true;
    }
    if (perid.some((e) => e == 184)) {
      this.ssf = true;
    }
    if (perid.some((e) => e == 186)) {
      this.withhold = true;
    }
  },
};
</script>
<style scoped>
.card {
  /* margin-bottom: 5rem; */
  margin-left: 1%;
  margin-right: 0.3%;
  /* height: 1000px; */
  /* margin-top: -1.5rem; */
}
.titles {
  color: #f89d1f;
  font-weight: bold;
  font-size: 17px;
  margin-top: -1rem;
}
.di {
  margin: 20px;
  margin-bottom: 3rem;
}
</style>

