<template>
  <div>
    <div style="margin-left: 1%">
      <p class="txt-header1">
        {{ $t("commonmaster") }}
      </p>
    </div>
    <v-row style="margin-right: 0; margin-left: -2px">
      <v-col cols="12" md="12" sm="12">
        <v-card class="pt-5 pb-5 mb-5" style="margin-bottom: 5rem;margin-left: 1%;margin-right: 0.3%;">
          <div style="margin: 20px">
            <v-row justify="center" align="center">
              <v-col>
                <div @click="goto(1)" v-if="!address" justify="center" align="center" style="border: 1.5px solid #E3E3E3; border-radius: 7px; height: 190px; cursor: pointer;">
                  <img height="130" :src="images.pic1" class="pl-2 pt-4" />
                  <p>{{ $t("address") }}</p>
                </div>
              </v-col>
              <v-col>
                <div @click="goto(2)" v-if="!bank" justify="center" align="center" style="border: 1.5px solid #E3E3E3; border-radius: 7px; height: 190px; cursor: pointer;">
                  <img height="130" :src="images.pic2" class="pl-2 pt-6" />
                  <p style="color:#444444">{{ $t("bank") }}</p>
                </div>
              </v-col>
              <v-col>
                <div @click="goto(3)" v-if="!ssf" justify="center" align="center" style="border: 1.5px solid #E3E3E3; border-radius: 7px; height: 190px; cursor: pointer;">
                  <img height="130" :src="images.pic3" class="pl-2 pt-6" />
                  <p style="color:#444444">{{ $t("socialSecurityFund") }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col>
                <div @click="goto(4)" v-if="!provident_f" justify="center" align="center" style="border: 1.5px solid #E3E3E3; border-radius: 7px; height: 190px; cursor: pointer;">
                  <img height="130" :src="images.pic4" class="pl-2 pt-6" />
                  <p style="color:#444444">{{ $t("provident_fund") }}</p>
                </div>
              </v-col>
              <v-col>
                <div @click="goto(5)" v-if="!benefit" justify="center" align="center" style="border: 1.5px solid #E3E3E3; border-radius: 7px; height: 190px; cursor: pointer;">
                  <img height="130" :src="images.pic5" class="pl-2 pt-6" />
                  <p style="color:#444444">{{ $t("benefits") }}</p>
                </div>
              </v-col>
              <v-col>
                <div @click="goto(6)" v-if="!income_tax" justify="center" align="center" style="border: 1.5px solid #E3E3E3; border-radius: 7px; height: 190px; cursor: pointer;">
                  <img height="130" :src="images.pic6" class="pl-2 pt-6" />
                  <p style="color:#444444">{{ $t("incomeTaxes") }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="4" md="4" lg="4">
                <div @click="goto(7)" v-if="!income_type" justify="center" align="center" style="border: 1.5px solid #E3E3E3; border-radius: 7px; height: 190px; cursor: pointer;">
                  <img height="130" :src="images.pic7" class="pl-2 pt-7" />
                  <p style="color:#444444">{{ $t("incometype1") }}</p>
                </div>
              </v-col>
              <v-col>
              </v-col>
              <v-col>
              </v-col>
              <!-- <div v-for="item in items1"
                  :key="item.id" class="ml-2 mr-2 mt-4" style="border: 1.5px solid #E3E3E3; height: 190px; width: 450px;">
                  <v-col  justify="center" align="center" >
                    <img height="130" :src="item.pic" class="pl-2 pt-7" />
                  <p style="color:#444444">{{ item.text }}</p>
                  </v-col>
              </div> -->
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  mixins: [utils],
  data() {
    return {
      address: true,
      bank: true,
      ssf: true,
      provident_f: true,
      benefit:true,
      income_tax:true,
      income_type:true,
      images: {
          pic1: require("@/assets/images/Address.png"),
          pic2: require("@/assets/images/Bank.png"),
          pic3: require("@/assets/images/Social Security Fund.png"),
          pic4: require("@/assets/images/Provident Fund.png"),
          pic5: require("@/assets/images/Benefit.png"),
          pic6: require("@/assets/images/Income Tax.png"),
          pic7: require("@/assets/images/Income Type.png"),
      },
    };
  },
 
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      
      this.address= false;
      this.bank= false;
      this.ssf= false;
      this.provident_f= false;
      this.benefit=false;
      this.income_tax=false;
      this.income_type=false;
    }
  
 
    if (perid.some((e) => e == 64)) {
      this.address = false;
    }
    if (perid.some((e) => e == 69)) {
      this.ssf = false;
    }
    if (perid.some((e) => e == 74)) {
      this.provident_f= false;
    }
    if (perid.some((e) => e == 79)) {
      this.bank = false;
    }
    if (perid.some((e) => e == 84)) {
      this.benefit = false;
    }
    if (perid.some((e) => e == 94)) {
      this.income_tax = false;
    }
    if (perid.some((e) => e == 168)) {
      this.income_type = false;
    }
  },
  methods:{
    goto(v){
      if(v == 1){
        this.$router.push(`/masterdata/Address`).catch(() => {});
      } else if(v == 2){
        this.$router.push(`/masterdata/Bank`).catch(() => {});
      } else if(v == 3){
        this.$router.push(`/masterdata/SocialSecurityFund`).catch(() => {});
      } else if(v == 4){
        this.$router.push(`/masterdata/providentfund`).catch(() => {});
      } else if(v == 5){
        this.$router.push(`/masterdata/MBenefits`).catch(() => {});
      } else if(v == 6){
        this.$router.push(`/masterdata/incometax`).catch(() => {});
      } else if(v == 7){
        this.$router.push(`/masterdata/incometype`).catch(() => {});
      }
    }
  }
};
</script>
<style scoped>
.list:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}
.myimg {
  width: 30px !important;
}
a:active,
a:hover {
  background-color: #fff9f0 !important;
}
.forlist {
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
>>> .v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.v-application p {
  margin-bottom: 0 !important;
}
/* .forlist{
line-clamp: [2 |5];
} */
/* @media screen and (min-width: 320px) {
.forlist {
 
    font-size: 10px;
}
.myimg{
  width: 25px !important;
}
} */

/* 
.list :hover {
  background-color: #FFF9F0 !important;
} */
/* >>>.v-list-item.v-list-item--link.v-list:nth-of-type(even){
  background-color: #f8f8f8 !important;
} */
</style>
