import "@babel/polyfill";
import Vue from "vue";
import IdleVue from "idle-vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "@/translate/i18n";
import lineClamp from "vue-line-clamp";
import FlagIcon from "vue-flag-icon";
import JwPagination from "jw-vue-pagination";
import CountryFlag from "vue-country-flag";
import store from "./store";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import firebase from "firebase/app";
import "@firebase/messaging";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import './plugins/moment'; // Import the moment plugin
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VCalendar from 'v-calendar';
// import style
import 'swiper/dist/css/swiper.css';

// Create your Thai and English locale settings
const thaiLocale = {
  firstDayOfWeek: 1,
  masks: {
    L: 'DD/MM/YYYY',
    title: 'MMMM YYYY',
    weekdays: 'WWW',
    navMonths: 'MMM',
  },
  dayNames: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'],
  dayNamesShort: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
  monthNames: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
  monthNamesShort: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
};

const englishLocale = {
  firstDayOfWeek: 1,
  masks: {
    L: 'MM/DD/YYYY',
    title: 'MMMM YYYY',
    weekdays: 'WWW',
    navMonths: 'MMM',
  },
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};

window.$ = window.jQuery = require("jquery");
Vue.use(VueMask);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCFKIXnUCz1rgcvPELdc6ffGs23NfrxirQ",
    libraries: "places", // necessary for places input
  },
});
// Swiper
Vue.use(VueAwesomeSwiper);
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  locales: {
    'th-TH': thaiLocale,
    'en-US': englishLocale,
  }
});
// }
Vue.config.productionTip = false;
Vue.component("country-flag", CountryFlag);
Vue.component('date-picker', DatePicker);
Vue.use(FlagIcon);
Vue.use(lineClamp);
Vue.use(JwPagination);
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 43200000, //  12 hours
  startAtIdle: false,
});
new Vue({
  i18n,
  vuetify,
  router,
  lineClamp,
  store,
  IdleVue,
  render: h => h(App),
}).$mount("#app");

if (firebase.messaging.isSupported()) {
  var firebaseConfig = {
    apiKey: "AIzaSyAI4HkLNx1R3atL7BxB7qwvEKQqjiY8k4Y",
    authDomain: "optimistic-cca96.firebaseapp.com",
    databaseURL: "https://optimistic-cca96.firebaseio.com",
    projectId: "optimistic-cca96",
    storageBucket: "optimistic-cca96.appspot.com",
    messagingSenderId: "116479546535",
    appId: "1:116479546535:web:c6cbe479b93035b75eb782",
    measurementId: "G-0L3GT2B6LB",
  };

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
    // firebase.analytics();

    Vue.prototype.$messaging = firebase.messaging();

    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(registration => {
        Vue.prototype.$messaging.useServiceWorker(registration);
        // console.log(registration);
      });
  }
}
