<template>
  <div>
    <div style="margin-left:2%;margin-right:2%;padding-top:2%">
      <v-row class="flex-row-reverse">
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="1">
          <v-btn
            width="100%"
            color="#F99D20"
            class="text-capitalize"
            style="color:#ffffff"
            depressed
            @click="uploadDialog = true"
          >Upload</v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="5" lg="4" xl="3">
          <v-btn
            width="10px"
            class="white--text text-capitalize"
            @click="prev"
            max-height="30"
            color="#F99D20"
          >
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <!-- <p
            style="display:inline;"
            class="pl-10 pr-10"
            v-if="$refs.calendar"
          >{{ $refs.calendar.title }}</p>-->
          <p style="display:inline;" class="pl-10 pr-10">April 2020</p>
          <v-btn
            @click="next"
            width="30"
            class="white--text text-capitalize"
            max-height="30"
            color="#F99D20"
          >
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- <div>
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :type="type"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
      </div>-->
      <div>
        <v-data-table   :mobile-breakpoint="0" :headers="headers" :items="desserts" hide-default-footer class="elevation-0"></v-data-table>
      </div>

      <div class="mt-6">
        <v-data-table
        :mobile-breakpoint="0"
          :headers="headersDetails"
          :items="dessertsDetails"
          hide-default-footer
          class="elevation-0"
        ></v-data-table>
      </div>
      <div class="mt-6">
        <v-row>
          <v-col cols="12" md="2">
            <p>Supervisor</p>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn color="#F99D20" block class="text-capitalize" depressed outlined>Approved</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <p>Supervisor above 1 level</p>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn color="#F99D20" block class="text-capitalize" depressed outlined>Approved</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <p>HR</p>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn color="#F99D20" block class="text-capitalize" depressed outlined>Approved</v-btn>
          </v-col>
        </v-row>
      </div>
      <br />
      <br />
    </div>
    <!-- uploadDialog -->
    <v-dialog v-model="uploadDialog" max-width="786">
      <v-card>
        <v-card-title class="headline">Upload FIle</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-btn
                style="background-color:#ACACAC"
                width="120"
                class="white--text text-capitalize"
                text
              >Browse</v-btn>
            </v-col>
            <v-col cols="12" md="10">
              <p class="mt-2" style="color:#F74747">** The file type must be “xlsx, xls only.</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <h4 class="mt-2">Excel file upload formats</h4>
          <v-row>
            <v-col cols="12" md="3">
              <p>Document Template.xlxs</p>
            </v-col>
            <v-col cols="12" md="9">
              <a href style="color:#F99D20;">Download</a>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br />
          <br />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="text-capitalize" width="120" text @click="uploadDialog = false">Cancel</v-btn>

          <v-btn
            style="background-color:#F99D20"
            width="120"
            class="white--text text-capitalize"
            text
            @click="uploadDialog = false"
          >Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    uploadDialog: false,
    items: [
      { text: "Employee ID" },
      { text: "Employee Name" },
      { text: "Work Type" }
    ],
    headers: [
      {
        text: "Employee ID",
        align: "start",
        sortable: false,
        value: "name"
      },
      { text: "Employee Name", value: "calories" },
      { text: "Work Type", value: "fat" },
      { text: 1, value: "carbs" },
      { text: 2, value: "protein" },
      { text: 3, value: "iron" },
      { text: 4, value: "carbs" },
      { text: 5, value: "protein" },
      { text: 6, value: "iron" },
      { text: 7, value: "carbs" },
      { text: 8, value: "protein" },
      { text: 9, value: "iron" },
      { text: 10, value: "carbs" },
      { text: 11, value: "protein" },
      { text: 12, value: "iron" },
      { text: 13, value: "iron" },
      { text: 14, value: "carbs" },
      { text: 15, value: "protein" },
      { text: 16, value: "iron" },
      { text: 17, value: "carbs" },
      { text: 18, value: "protein" },
      { text: 19, value: "iron" },
      { text: 20, value: "carbs" },
      { text: 21, value: "protein" },
      { text: 22, value: "iron" },
      { text: 23, value: "carbs" },
      { text: 24, value: "protein" },
      { text: 25, value: "iron" },
      { text: 26, value: "carbs" },
      { text: 27, value: "protein" },
      { text: 28, value: "iron" },
      { text: 29, value: "carbs" },
      { text: 30, value: "protein" },
      { text: "Total Days", value: "iron" },
      { text: "Day Off", value: "carbs" },
      { text: "Vacation", value: "protein" },
      { text: "Total Hours", value: "iron" },
      { text: "Edit", value: "carbs" }
    ],
    desserts: [
      {
        name: "Dioe",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%"
      },
      {
        name: "June",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%"
      }
    ],
    headersDetails: [
      {
        text: "Shift Details",
        align: "start",
        sortable: false,
        value: "name"
      },
      { text: 1, value: "carbs" },
      { text: 2, value: "protein" },
      { text: 3, value: "iron" },
      { text: 4, value: "carbs" },
      { text: 5, value: "protein" },
      { text: 6, value: "iron" },
      { text: 7, value: "carbs" },
      { text: 8, value: "protein" },
      { text: 9, value: "iron" },
      { text: 10, value: "carbs" },
      { text: 11, value: "protein" },
      { text: 12, value: "iron" },
      { text: 13, value: "iron" },
      { text: 14, value: "carbs" },
      { text: 15, value: "protein" },
      { text: 16, value: "iron" },
      { text: 17, value: "carbs" },
      { text: 18, value: "protein" },
      { text: 19, value: "iron" },
      { text: 20, value: "carbs" },
      { text: 21, value: "protein" },
      { text: 22, value: "iron" },
      { text: 23, value: "carbs" },
      { text: 24, value: "protein" },
      { text: 25, value: "iron" },
      { text: 26, value: "carbs" },
      { text: 27, value: "protein" },
      { text: 28, value: "iron" },
      { text: 29, value: "carbs" },
      { text: 30, value: "protein" },
      { text: "", value: "totaldays" }
    ],
    dessertsDetails: [
      {
        name: "A 08:00-18:00",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%",
        totaldays: "Total days off : 16 days"
      },
      {
        name: "B 07:00-19:00",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%",
        totaldays: "Total Shift : 360 hrs"
      },
      {
        name: "N 19:00-07:00",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%",
        totaldays: "Total Regular Shift : 356 hrs"
      },
      {
        name: "F 18:00-20:00",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%",
        totaldays: "Total OT Shift : 4 hrs"
      }
    ]
  })
};
</script>

<style scoped>
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
</style>