/* eslint-disable no-console */
import axios from "axios";
import enurl from "@/api/environment";
import store from "../store";
export default {
  data() {
    return {
      url: enurl.apiUrl,
    };
  },
  methods: {
    ReportTrace(feature) {
      let tempReport = {
        user_ID: store.state.userid,
        company_ID: store.state.companyID,
        feature: feature,
      };
      axios

        .post(`${this.url}FeatureReport/AddReport`, tempReport)
        .then(function () {
          // alert(response.data.message);
        })
        .catch(function (error) {
          alert(error);
        });
    },
  },
};
